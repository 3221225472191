import { Component, Input, OnInit } from '@angular/core';
import { CommonMessages } from '@app/constants/common.messages';
import { GoalsMessages } from '@app/goals/goals.messages';
import { GoalKeyResultMeasureUnitPlacement } from '@app/models/goals/goal-key-result-measure-unit-placement.model';
import { GoalKeyResultType } from '@app/models/goals/goal-key-result-type.model';
import { GoalKeyResult } from '@app/models/goals/goal-key-result.model';

@Component({
  selector: 'app-key-results-list',
  templateUrl: './key-results-list.component.html',
  styleUrls: ['./key-results-list.component.scss']
})
export class KeyResultsListComponent implements OnInit {
  public readonly eGoalKeyResultType = GoalKeyResultType;
  public readonly eGoalKeyResultMeasureUnitPlacement = GoalKeyResultMeasureUnitPlacement;
  public readonly eGoalMessages = GoalsMessages;
  public readonly eCommonMessages = CommonMessages;

  @Input() keyResults: GoalKeyResult[];

  @Input() showIndex: boolean;
  @Input() showTitle: boolean;
  @Input() showDueDate: boolean;
  @Input() showResultType: boolean;
  @Input() showUnit: boolean;
  @Input() showUnitPlacement: boolean;
  @Input() showProgress: boolean;
  @Input() showDetailedProgress: boolean;

  constructor() {
    this.keyResults = [];

    this.showIndex = true;
    this.showTitle = true;
    this.showDueDate = true;
    this.showResultType = true;
    this.showUnit = true;
    this.showUnitPlacement = true;
    this.showProgress = true;
    this.showDetailedProgress = true;
  }

  ngOnInit(): void {
  }

}
