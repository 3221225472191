import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { SweetAlertOptions, SweetAlertResult } from 'sweetalert2';
import { Globals } from '../globals/globals';
import { SwalUtils } from '../utils/swal.utils';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnDestroy {

  @Input() background: 'light' | 'dark' | 'none';
  @Input() width: string;
  @Input() minWidth: string;
  @Input() maxWidth: string;
  @Input() sidePanel: boolean;
  @Input() showCloseButton: boolean;

  // Confirm Closing
  @Input() confirmClosing: boolean;
  @Input() confirmCloseModalSettings: SweetAlertOptions;
  @Output() onConfirmCloseModalResponse: EventEmitter<SweetAlertResult>;

  // Show/Hide callbacks
  @Output() modalShown: EventEmitter<boolean>;
  @Output() modalHidden: EventEmitter<boolean>;

  public visible = false;
  public visibleAnimate = false;

  constructor(
    public globals: Globals,
    private swalUtils: SwalUtils
  ) {
    this.background= 'dark';
    this.width = '45%';
    this.minWidth = '300px';
    this.maxWidth = '100vw';
    this.sidePanel = false;

    this.confirmClosing = false;
    this.confirmCloseModalSettings = {
      titleText: 'Are you sure you want to close this window?',
      text: 'You may lose data',
    };
    this.onConfirmCloseModalResponse = new EventEmitter<SweetAlertResult>();

    this.modalShown = new EventEmitter<boolean>();
    this.modalHidden = new EventEmitter<boolean>();
    this.showCloseButton = true;
  }

  ngOnDestroy() {
    setTimeout(() => {
      this.modalHidden.emit(true);
      this.globals.modalOpen = false;
    }, 1);
  }

  public show(): void {
    if (this.visible) { return; }

    this.visible = true;
    this.globals.modalOpen = true;
    this.modalShown.emit(true);

    setTimeout(() => this.visibleAnimate = true, 100);
  }

  public hide(): void {
    if (!this.visibleAnimate) { return; }

    this.visibleAnimate = false;
    this.globals.modalOpen = false;
    this.modalHidden.emit(true);

    setTimeout(() => this.visible = false, 300);
  }

  public onContainerClicked(event: MouseEvent): void {
    if ((<HTMLElement>event.target).classList.contains('modal')) {
      this.hide();
    }
  }

  tryClose() {
    if (!this.confirmClosing) {
      this.hide();
      return;
    }

    this.swalUtils.displayWarningConfirmationSwal(this.confirmCloseModalSettings)
      .then((response) => {
        this.onConfirmCloseModalResponse.emit(response);
      });
  }
}
