<!--
    TODO: Add left border to indicate status:
     - Archived = Navy <-- This takes priority over all others
     - Complete = Green
     - In progress = Yellow
     - Not started = Gray
-->
<div class="task-display-container" *ngIf="task" [ngClass]="'task-status-' + (task.archived ? 'ARCHIVED' : task.status)">
    <div class="top-row">
        <div class="col-details">
            <h5 class="standard-heading">
                <span>{{task.title}}</span>
                <ng-container *ngIf="task.status === eTaskStatus.COMPLETE">
                    <span class="m-right-5"></span>
                    <app-check [text]="task.completionDate | momentPipe: 'Do MMM YYYY'"></app-check>
                </ng-container>
            </h5>
            <ng-container *ngIf="canSeeDueDate || canSeeCreateDate">
                <div class="standard-description area-task-dates">
                    <ng-container *ngIf="canSeeDueDate">
                        <span class="display-due-date" [ngClass]="dueDateHighlight">
                            <small translate>{{eTasksMessages.DUE_DATE}}</small>
                            <small>:&nbsp;</small>
                            <small>{{task.dueDate | momentPipe: 'Do MMM YYYY'}}</small>
                        </span>
                    </ng-container>
                    <ng-container *ngIf="canSeeCreateDate">
                        <span class="display-create-date">
                            <small translate>{{eTasksMessages.CREATE_DATE}}</small>
                            <small>:&nbsp;</small>
                            <small>{{task.creationDate | momentPipe: 'Do MMM YYYY'}}</small>
                        </span>
                    </ng-container>
                </div>
            </ng-container>
            <div class="standard-description">{{task.description}}</div>
        </div>
        <div class="col-owners">
            <app-person-picture-list [users]="taskOwnersOrdered"></app-person-picture-list>
        </div>
        <!-- TODO: Improve this UI - Get the associated piece of data -->
        <div class="col-alignment">
            <app-pill [type]="ePillType.BLUE">
                <ng-container [ngSwitch]="task.alignmentType">
                    <span *ngSwitchCase="eTaskAlignmentType.GOAL" translate>{{eTasksMessages.ALIGNMENT_GOAL}}</span>
                    <span *ngSwitchCase="eTaskAlignmentType.ONE_TO_ONE" translate>{{eTasksMessages.ALIGNMENT_ONE_TO_ONE}}</span>
                    <span *ngSwitchCase="eTaskAlignmentType.TODO" translate>{{eTasksMessages.ALIGNMENT_TODO}}</span>
                    <span *ngSwitchCase="eTaskAlignmentType.MILESTONE" translate>{{eTasksMessages.ALIGNMENT_MILESTONE}}</span>
                </ng-container>
            </app-pill>
        </div>
        <div class="col-status" *ngIf="actionsShown.includes('status') && canUpdateTask">
            <ng-container *ngIf="!task.archived; else statusArchived">
                <select [formControl]="controlTaskStatus">
                    <option [ngValue]="eTaskStatus.NOT_STARTED" translate>{{eTasksMessages.STATUS_NOT_STARTED}}</option>
                    <option [ngValue]="eTaskStatus.IN_PROGRESS" translate>{{eTasksMessages.STATUS_IN_PROGRESS}}</option>
                    <option [ngValue]="eTaskStatus.COMPLETE" translate>{{eTasksMessages.STATUS_COMPLETE}}</option>
                </select>
            </ng-container>
            <ng-template #statusArchived>
                <span class="archived-status" translate>{{eCommonMessages.ARCHIVED}}</span>
            </ng-template>
        </div>
        <div class="col-actions">
            <app-table-action-icon *ngIf="actionsShown.includes('view')" [icon]="'fa-eye'" [tooltipText]="getTooltipView()" (click)="clickAction('view')"></app-table-action-icon>
            <app-table-action-icon *ngIf="canSeeActionEdit" [icon]="'fa-edit'" [tooltipText]="getTooltipEdit()" (click)="clickAction('edit')"></app-table-action-icon>
            <app-table-action-icon *ngIf="canSeeActionUnlink" [icon]="'fa-unlink'" [tooltipText]="eTasksMessages.UNLINK_TASK" (click)="clickAction('unlink')"></app-table-action-icon>
            <app-table-action-icon *ngIf="canSeeActionArchive" [icon]="'fa-archive'" [tooltipText]="getTooltipArchive()" (click)="clickAction('archive')"></app-table-action-icon>
        </div>
    </div>
</div>