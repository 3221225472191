<div class="activity-item-container" *ngIf="comment">
  <div class="activity-intro">
      <span [innerHTML]="eTasksMessages.ACTIVITY_TASK_COMMENTED | translate: {user: (comment.creator.firstName + ' ' + comment.creator.lastName)}"></span>
  </div>

  <div class="activity-detail-container">
    <div class="p-top-10">
      <div class="activity-comment">
        <span>{{comment.text}}</span>
      </div>
    </div>
  </div>
</div>
