import { Component, Input } from '@angular/core';
import { UserMinimal } from '@app/models/user/user-minimal.model';
import { Qualification } from '../../model/qualification.model';

@Component({
  selector: 'app-qualification-sidebar-display',
  templateUrl: './qualification-sidebar-display.component.html',
  styleUrls: ['./qualification-sidebar-display.component.scss']
})
export class QualificationSidebarDisplayComponent {

  @Input() user: UserMinimal;
  @Input() qualifications: Qualification[];

  constructor() {
    this.user = undefined;
    this.qualifications = [];
  }

}
