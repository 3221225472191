<div class="card">
  <ng-container *ngIf="!loading; else stateLoading">
    <ng-container *ngIf="!error; else stateError">
      <div class="content scroll-praise">
        <div class="row no-gutters p-right-15 m-bottom-10" *ngFor="let praise of praiseList">
          <div class="row">
            <div class="col-xs-11">
              <div class="row no-gutters">
                <div class="col-xs-1 p-right-0 text-right z-10">
                    <span class="praise-user clickable" [routerLink]="'/profile/' + praise.senderUser.id">
                      <!-- TODO: Add firstName and lastName -->
                      <app-profile-photo [src]="praise.senderUser.imageUrl"></app-profile-photo>
                    </span>
                </div>
                <div class="col-xs-11 p-left-0 praise-card">
                  <div class="row no-gutters">
                    <div class="m-left-20 col-xs-9">
                      <label>{{praise.senderUser.firstName + ' ' + praise.senderUser.lastName}} wrote:</label>
                      <div class="wrap-text praise-message-text">
                        <app-rich-display [text]="praise.message"></app-rich-display>
                      </div>
                    </div>
                  </div>
                  <div class="border-top"></div>
                  <div class="row no-gutters m-top-10">
                    <div class="col-xs-5 reaction-group m-left-20">
                      <span id="reactionStart" class="clickable" (mouseenter)="mouseInPop = true" (mouseleave)="mouseInPop = false" [popover]="reactionTemplate" [delay]="300" [popoverContext]="{praise:praise}" #pop="bs-popover" placement="top" outsideClick="true" triggers="mouseenter" (onShown)="hideOtherPopovers($event, praise)">
                        <span *ngIf="!userHasReacted(praise)" (click)="toggleReaction(praise, eReactionType.LIKE)"><span class="fal fa-thumbs-up"></span>Like</span>
                        <span *ngIf="userHasReacted(praise, eReactionType.LIKE)" (click)="toggleReaction(praise, eReactionType.LIKE)"><span class="fas fa-thumbs-up"></span>Liked</span>
                        <span *ngIf="userHasReacted(praise, eReactionType.CELEBRATE)" (click)="toggleReaction(praise, eReactionType.CELEBRATE)"><span class="fas fa-hands-wash"></span>Celebrated</span>
                        <span *ngIf="userHasReacted(praise, eReactionType.LOVE)" (click)="toggleReaction(praise, eReactionType.LOVE)"><span class="fas fa-heart"></span>Loved</span>
                      </span>
                      <ng-container *ngIf="praise.reactions?.length > 0">
                        <span><b> | </b></span>
                        <span class="clickable reaction-group-reactions" (click)="loadReactionUserModal(praise.reactions)" [title]="'This praise has ' + praise.reactions?.length + ' reactions'">
                        <span *ngIf="getUniqueReactions(praise.reactions).has(eReactionType.LIKE)" class="fas fa-thumbs-up like-icon"></span>
                        <span *ngIf="getUniqueReactions(praise.reactions).has(eReactionType.CELEBRATE)" class="fas fa-hands-wash celebrate-icon"></span>
                        <span *ngIf="getUniqueReactions(praise.reactions).has(eReactionType.LOVE)" class="fas fa-heart love-icon"></span>
                        <span id="reactionLink">{{ praise.reactions?.length }}</span>
                      </span>
                      </ng-container>
                    </div>
                    <div class="col-xs-6 text-right" *ngIf="praise.values?.length > 0">
                      <div>
                        <span class="praise-values p-left-10" *ngFor="let v of praise?.values">{{v.text | titlecase}}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </ng-container>
</div>

<!-- #region - ALTERNATIVE PAGE STATES -->
<ng-template #stateLoading>
  <div class="content text-center">
    <span class="fas fa-spinner fa-spin fa-4x fa-fw"></span>
  </div>
</ng-template>

<ng-template #stateError>
  <div class="content text-center">
    <img class="icon-padding new-icon-height" src="/assets/img/NoSearchFound.svg" />
    <div>An error occured getting this persons praise</div>
  </div>
</ng-template>
<!-- #endregion -->
<!-- Reaction Template -->
<ng-template #reactionTemplate let-praise='praise'>
  <div (mouseenter)="mouseInPop = true" (mouseleave)="mouseInPop = false">
    <span class="clickable expand-on-hover fa-thumbs-up like-icon" title="Like" [ngClass]="userHasReacted(praise, eReactionType.LIKE) ? 'fas' : 'fal'" (click)="toggleReaction(praise, eReactionType.LIKE)"></span>
    <span class="clickable expand-on-hover fa-hands-wash celebrate-icon" title="Celebrate" [ngClass]="userHasReacted(praise, eReactionType.CELEBRATE) ? 'fas' : 'fal'" (click)="toggleReaction(praise, eReactionType.CELEBRATE)"></span>
    <span class="clickable expand-on-hover fa-heart love-icon" title="Love" [ngClass]="userHasReacted(praise, eReactionType.LOVE) ? 'fas' : 'fal'" (click)="toggleReaction(praise, eReactionType.LOVE)"></span>
  </div>
</ng-template>
<!-- Reaction user modal -->
<app-modal #reactionUserModal [background]="'light'" [width]="'20%'">
  <div class="modal-body-content">
    <h5 class="text-center">Reactions</h5>
    <!-- Tabs -->
    <ul class="nav nav-justified text-center">
      <li title="All" class="nav-link clickable tab-option" [class.tab-selected]="modalTab === eModalTab.ALL" (click)="changeTab(eModalTab.ALL)">
        <span>All {{ reactionUsers.length }}</span>
      </li>
      <li title="Like" class="nav-link clickable tab-option like-icon" [class.tab-selected]="modalTab === eModalTab.LIKE" (click)="changeTab(eModalTab.LIKE)">
        <span class="fas fa-thumbs-up"></span>
        <span> {{ getReactionUsersForType(eReactionType.LIKE).length }}</span>
      </li>
      <li title="Celebrate" class="nav-link clickable tab-option celebrate-icon" [class.tab-selected]="modalTab === eModalTab.CELEBRATE" (click)="changeTab(eModalTab.CELEBRATE)">
        <span class="fas fa-hands-wash"></span>
        <span> {{ getReactionUsersForType(eReactionType.CELEBRATE).length}}</span>
      </li>
      <li title="Love" class="nav-link clickable tab-option love-icon" [class.tab-selected]="modalTab === eModalTab.LOVE" (click)="changeTab(eModalTab.LOVE)">
        <span class="fas fa-heart"></span>
        <span> {{ getReactionUsersForType(eReactionType.LOVE).length }}</span>
      </li>
    </ul>
    <div class="user-reaction-list">
      <div class="m-top-10" *ngFor="let reactionUser of reactionUsersDisplay">
        <span class="owner-img clickable" [routerLink]="'/profile/' + reactionUser.user.id">
          <app-profile-photo class="profile-picture" [src]="reactionUser.user.imageUrl" [firstName]="reactionUser.user.firstName" [lastName]="reactionUser.user.lastName"></app-profile-photo>
        </span>
        <span class="lh-40 m-left-5">{{reactionUser.user.firstName}} {{reactionUser.user.lastName}}</span>
      </div>
      <div *ngIf="reactionUsersDisplay.length === 0">
        <div class="p-top-20 p-bottom-20 text-center">
          <span>No reactions</span>
        </div>
      </div>
    </div>
  </div>
</app-modal>