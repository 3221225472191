import { Component, EventEmitter, Output } from '@angular/core';
import { Globals } from '@app/shared/globals/globals';
import { GoalCreateService } from '../goals-create-old/goals-create.service';

@Component({
  selector: 'app-goal-modal',
  templateUrl: './goals-modal.component.html',
  styleUrls: ['./goals-modal.component.scss']
})
export class GoalsModalComponent {
  @Output() modalShown: EventEmitter<boolean>;
  @Output() modalHidden: EventEmitter<boolean>;

  public visible = false;
  public visibleAnimate = false;
  public sidebarCollapsed = false;

  constructor(
    public globals: Globals,
    private goalCreateService: GoalCreateService
  ) {
    this.modalShown = new EventEmitter<boolean>();
    this.modalHidden = new EventEmitter<boolean>();
  }

  public show(): void {
    this.visible = true;
    this.globals.modalOpen = true;
    this.modalShown.emit(true);
    setTimeout(() => this.visibleAnimate = true, 100);
  }

  public hide(): void {
    this.visibleAnimate = false;
    this.globals.modalOpen = false;
    this.modalHidden.emit(true);
    setTimeout(() => this.visible = false, 300);
  }

  public onContainerClicked(event: MouseEvent): void {
    if ((<HTMLElement>event.target).classList.contains('modal')) {
      this.hide();
    }
  }

  public toggleSidebar() {
    this.sidebarCollapsed = !this.sidebarCollapsed;
  }
}
