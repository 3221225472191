import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { User } from 'app/models/user/user.model';

@Injectable()
export class FrankliAdminAPIService {
  baseURL = '/api/frankli-admin/';

  constructor(private http: HttpClient) { }

  /**
   * Gets all users by companyId
   * @param companyId
   */
  getUsersByCompanyId(companyId: number): Observable<Array<User>> {
    const url = this.baseURL + `company/${companyId}/users`;
    return this.http.get<Array<User>>(url);
  }

}
