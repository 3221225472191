<div class="container-sidebar-vincere">
  <ng-container *ngIf="userStatus">
    <div class="card card-yellow">
      <div class="content">
        <span class="fal fa-fw fa-info-circle"></span>
        <span class="m-right-15"></span>
        <ng-container [ngSwitch]="userStatus.type">
          <span *ngSwitchCase="eVincereUserStatusType.NOT_SYNCED" class="context-text">We will get your data from Vincere soon...</span>
          <span *ngSwitchCase="eVincereUserStatusType.USER_SYNCED" class="context-text">We will get your data from Vincere soon...</span>
          <span *ngSwitchCase="eVincereUserStatusType.DATA_SYNCED" class="context-text">Last updated {{userStatus.lastSync! | dateAgo | lowercase}} </span>
          <span *ngSwitchCase="eVincereUserStatusType.NO_VINCERE_USER" class="context-text">We could not find any information in Vincere relating to your email</span>
          <span *ngSwitchCase="eVincereUserStatusType.ERROR" class="context-text">There was an error when trying to get data from Vincere (last tried {{userStatus.lastSync! | dateAgo | lowercase}})</span>
        </ng-container>
      </div>
    </div>
  </ng-container>

  <app-state [state]="state">
    <ng-container *ngIf="!state.loading && !state.error">
      <ng-container *ngIf="metrics && metrics.length > 0; else stateEmpty">
        <!-- Filters -->
        <div class="p-bottom-10">
          <div class="frankli-form-group frankli-form-group-filled">
            <label for="month">Month</label>
            <select id="month" name="month" class="form-control" [formControl]="monthControl">
              <option [ngValue]="null" selected *ngIf="monthControl.value === null">Please select...
              </option>
              <option *ngFor="let dt of dates" [ngValue]="dt">{{ dt | momentPipe: 'MMMM YYYY'}}</option>
            </select>
          </div>
        </div>

        <!-- Data Output -->
        <div class="metric-list">
          <ng-container *ngFor="let metric of metrics">
            <div class="metric-item">
              <div class="metric-item-top">
                <div class="metric-value">{{metric.result}}</div>
                <div class="metric-icon">
                  <span class="fal fa-fw" [ngClass]="metric.vincereMetric.iconClass!"></span>
                </div>
              </div>
              <div class="metric-title">{{metric.vincereMetric.name}}</div>
            </div>
          </ng-container>
        </div>
      </ng-container>

      <ng-template #stateEmpty>
        <app-empty-state [height]="'200px'"></app-empty-state>
      </ng-template>
    </ng-container>
  </app-state>
</div>