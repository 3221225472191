import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'links_blank'
})
export class LinksBlankPipe implements PipeTransform {

  constructor(private _domSanitizer: DomSanitizer) {}

  transform(value: any, args?: any): any {
    return this.convert(value);
  }

  convert(str: string) {
    const a_tag = /<a (.*)>/g;
    return str.replace(a_tag, '<a target="_blank" $1>');
  }
}
