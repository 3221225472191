import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PipesModule } from 'app/shared/pipes/pipes.module';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { Angulartics2Module } from 'angulartics2';
import { SharedModule } from 'app/shared/shared.module';
import { UserProfileComponent } from '../page/user-profile/user-profile.component';
import { MapsAPIService } from 'app/shared/api/maps.api.service';
import { ProfilePraiseFullComponent } from '../component/profile-praise-full/profile-praise-full.component';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { ImageCropperModule } from 'ngx-image-cropper';
import { ManagerGoalsComponentsModule } from '@app/manager/sub-modules/manager-goals/manager-goals-components/manager-goals-components.module';
import { GoalsComponentsModule } from '@app/goals/goals-components/goals-components.module';
import { TranslateModule } from '@ngx-translate/core';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { QualificationComponentsModule } from '@app/domain/qualification/module/qualification-components.module';
import { OneToOneComponentsModule } from '@app/domain/one_to_one/module/one-to-one-components.module';
import { ProfileBasicInfoComponent } from '../component/profile-basic-info/profile-basic-info.component';
import { ProfileSkillsCompetenciesComponent } from '../component/profile-skills-competencies/profile-skills-competencies.component';
import { ProfileInterestsComponent } from '../component/profile-interests/profile-interests.component';
import { ProfileDevelopmentComponent } from '../component/profile-development/profile-development.component';
import { ProfileGoalsComponent } from '../component/profile-goals/profile-goals.component';
import { ProfilePraiseComponent } from '../component/profile-praise/profile-praise.component';

const components = [
  UserProfileComponent,
  ProfilePraiseFullComponent,
  ProfileBasicInfoComponent,
  ProfileSkillsCompetenciesComponent,
  ProfileInterestsComponent,
  ProfileGoalsComponent,
  ProfilePraiseComponent,
  ProfileDevelopmentComponent
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    PipesModule,
    SweetAlert2Module,
    Angulartics2Module,
    SharedModule,
    ManagerGoalsComponentsModule,
    TooltipModule,
    ImageCropperModule,
    GoalsComponentsModule,
    TranslateModule,
    TypeaheadModule.forRoot(),
    QualificationComponentsModule,
    OneToOneComponentsModule,
    PopoverModule.forRoot()
  ],
  declarations: components,
  exports: components,
  providers: [
    MapsAPIService
  ]
})
export class ProfileComponentsModule { }
