import { TitleCasePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { CommonMessages } from '@app/constants/common.messages';
import { GoalStatus } from '@app/models/goals/goal-status.model';
import { Goal } from '@app/models/goals/goal.model';
import { FilterCreation, FilterMethod, FilterOption } from '@app/models/universal-filter-option.model';
import { GoalsAPIService } from '@app/shared/api/goals.api.service';
import { Globals } from '@app/shared/globals/globals';
import { PaginationNewComponent } from '@app/shared/pagination/pagination-new/pagination-new.component';
import { forkJoin } from 'rxjs';

export enum FeatureLinking {
  GOALS = 'GOALS'
}


interface LinkOptionData<T> {
  display: T[];
  filtered: T[];
  raw: T[];
}

interface LinkOptions {
  [FeatureLinking.GOALS]: LinkOptionData<PageGoal>;
}

interface PageState {
  loading: boolean;
}

interface UniversalFilterData {
  filterOptions: FilterOption[];
  searchProps: string[];
}

interface PageGoal extends Goal {
  selected: boolean;
}

@Component({
  selector: 'app-rich-link-modal',
  templateUrl: './rich-link-modal.component.html',
  styleUrls: ['./rich-link-modal.component.scss'],
  providers: [
    TitleCasePipe
  ]
})
export class RichLinkModalComponent implements OnInit {
  public readonly FeatureLinking = FeatureLinking;
  public readonly eCommonMessages = CommonMessages;

  @ViewChild('pagination') pagination?: PaginationNewComponent;

  selectedType: FormControl;

  linkData: LinkOptions;
  state: PageState;
  universalFilterData?: UniversalFilterData;

  get selectedCount(): number {
    switch (this.selectedType.value as FeatureLinking) {
      case FeatureLinking.GOALS:
        return this.linkData[FeatureLinking.GOALS].filtered.filter(d => d.selected).length;
    }
  }

  constructor(
    public globals: Globals,
    private goalsAPIService: GoalsAPIService,
    private titlecasePipe: TitleCasePipe
  ) {
    this.selectedType = new FormControl(null, []); // TODO: Add a way to change this in future
    this.linkData = this.initLinkData();
    this.state = {
      loading: true
    };
  }

  // #region - INIT
  ngOnInit(): void {
    this.initListeners();
    setTimeout(() => {
      this.selectedType.setValue(FeatureLinking.GOALS);
    }, 1);
  }

  initLinkData(): LinkOptions {
    return {
      [FeatureLinking.GOALS]: {
        display: [],
        filtered: [],
        raw: []
      }
    };
  }

  initListeners(): void {
    this.selectedType.valueChanges.subscribe((value: FeatureLinking) => this.onFeatureChange(value));
  }
  // #endregion

  // #region - FEATURE
  onFeatureChange(value: FeatureLinking): void {
    switch (value) {
      case FeatureLinking.GOALS:
        this.onStartLinkGoals();
        break;
      default:
        console.error('Unknown feature');
    }
  }

  onStartLinkGoals() {
    forkJoin([
      this.goalsAPIService.getIndividualGoals() // TODO: Might need to get goals you're not an owner on
    ]).subscribe(([goalsRes]) => {
      const resWithCompletion = Goal.getGoalArrayCompletionPercentage(goalsRes);
      let goals = this.parseGoalToPageGoalMultiple(resWithCompletion);
      
      goals = goals.sort((a, b) => {
        return b.endDate.getTime() - a.endDate.getTime();
      });

      this.linkData[FeatureLinking.GOALS] = {
        raw: goals,
        filtered: goals,
        display: []
      };

      this.universalFilterData = this.getFilterDataGoals(goals);

      this.state.loading = false;
    });
  }
  // #endregion

  // #region - RICH LINK CALLBACKS
  onGoalSelect(data: PageGoal): void {
    data.selected = !data.selected;
  }

  finishRichLink(): void {
    const feature = this.selectedType.value as FeatureLinking;
    
    switch (feature) {
      case FeatureLinking.GOALS:
        const goals: Goal[] = this.linkData[feature].filtered.filter(d => d.selected);
        window.parent.postMessage({
          mceAction: 'insertRichLink',
          content: {
            feature: feature,
            data: goals
          }
        }, '*');

        break;
    }
  }
  cancelRichLink(): void {
    window.parent.postMessage({
      mceAction: 'closeRichLink',
      content: {}
    }, '*');
  }
  // #endregion

  // #region - UNIVERSAL FILTER
  // TODO: translate
  getFilterDataGoals(goals: PageGoal[]): UniversalFilterData {
    const filterOptions: FilterOption[] = goals.map(g => {
      return {
        id: g.id,
        properties: {
          'goal_name': {value: g.title, filterMethod: FilterMethod.OR},
          // 'visibility': {value: this.titlecasePipe.transform(g.visibility), filterMethod: FilterMethod.OR},
          // 'priority': {value: this.titlecasePipe.transform(g.priority), filterMethod: FilterMethod.OR},
          'status': {value: this.getGoalStateFilter(g), filterMethod: FilterMethod.OR},
          'key_result_count': {value: FilterCreation.getRangeFilter(g.keyResults.length), filterMethod: FilterMethod.OR},
          'department': {value: this.getGoalDepartmentFilter(g), filterMethod: FilterMethod.OR},
          'site': {value: this.getGoalSiteFilter(g), filterMethod: FilterMethod.OR},
          'type': {value: this.titlecasePipe.transform(g.type), filterMethod: FilterMethod.OR}
        }
      };
    });

    return {
      filterOptions: filterOptions,
      searchProps: [
        'goal_name'
      ]
    };
  }

  universalFilterEmit(IDs: number[]) {
    switch (this.selectedType.value as FeatureLinking) {
      case FeatureLinking.GOALS:
        this.linkData[FeatureLinking.GOALS].filtered = this.linkData[FeatureLinking.GOALS].raw.filter(g => IDs.includes(g.id));
    }

    setTimeout(() => {
      this.refreshPagination();
    }, 1);
  }

  getGoalStateFilter(goal: PageGoal) {
    if (goal.archived) {
      return 'Archived';
    }

    if (goal.complete) {
      return 'Complete';
    }

    switch(goal.status) {
      case GoalStatus.OFF_TRACK:
        return 'Off Track';
      case GoalStatus.PROGRESSING:
        return 'Progressing';
      case GoalStatus.ON_TRACK:
        return 'Off Track';
    }
    
    return 'Unknown';
  }

  getGoalDepartmentFilter(goal: PageGoal) {
    if (!goal.department) {
      return undefined;
    }

    return goal.department.name;
  }

  getGoalSiteFilter(goal: PageGoal) {
    if (!goal.officeLocation) {
      return undefined;
    }

    return goal.officeLocation.name;
  }
  // #endregion

  // #region - GOAL PARSING
  parseGoalToPageGoalMultiple(goals: Goal[]): PageGoal[] {
    return goals.map(g => this.parseGoalToPageGoalSingle(g));
  }

  parseGoalToPageGoalSingle(goal: Goal): PageGoal {
    const output = goal as PageGoal;

    if (output) {
      output.selected = false;
    }

    return output;
  }
  // #endregion

  refreshPagination(): void {
    if (this.pagination) {
      this.pagination.update();
    }
  }
}
