import { Component, OnInit } from '@angular/core';
import { ErrorService } from '@app/shared/interceptors/error.service';
import { StatusAPIService } from 'app/shared/api/status.api.service';
import { WebsocketService } from 'app/shared/api/websocket.service';

@Component({
  selector: 'app-overlay-server-unreachable-component',
  templateUrl: './overlay-server-unreachable.component.html',
  styleUrls: ['./overlay-server-unreachable.component.scss']
})
export class OverlayServerUnavailableComponent implements OnInit {

  constructor(
    private statusAPIService: StatusAPIService,
    private websocketService: WebsocketService,
    private errorService: ErrorService
  ) { }

  ngOnInit() {
    this.websocketService.deactivate();
    this.checkStatus();
  }

  private checkStatus() {
    setTimeout(() => {
      this.statusAPIService.getStatus()
        .toPromise()
        .then(() => {
          // server back online
          this.errorService.sendStatus(null);
          this.websocketService.activate();
        })
        .catch(() => {
          this.checkStatus();
        });
    }, 5000);
  }
}
