import { MockService } from '@app/mock/api/mock-service';
import { NewsActivity } from '@app/models/news-activity.model';
import { mockNewsActivity } from '@app/mock/api/data/mockNewsActivity';
import { Injectable } from '@angular/core';
import { HttpRequest } from '@angular/common/http';
import { NewsActivityService } from '@app/shared/api/interfaces/news-activity.service';
import { sanitizeUrl } from '@app/shared/utils/helpers';

@Injectable()
export class NewsActivityMockService implements MockService, NewsActivityService {
    handleRoute(req: HttpRequest<any>): any {
        const url = sanitizeUrl(req.urlWithParams);

        switch (true) {
            case url.endsWith('api/news'):
                return this.getNewsActivity();
        }
    }

    getNewsActivity(): Array<NewsActivity> {
        return mockNewsActivity;
    }

}