import { Injectable } from '@angular/core';
import { MockService } from '@app/mock/api/mock-service';
import { HttpRequest } from '@angular/common/http';
import { ConnectActivityService } from '@app/shared/api/interfaces/connect/connect-activity.service';
import { ConnectActivity } from '@app/models/connect/connect-activity/connect-activity.model';
import { CreateConnectActivity } from '@app/models/connect/connect-activity/create-connect-activity.model';
import { Observable } from 'rxjs';
import { Globals } from '@app/shared/globals/globals';
import { sanitizeUrl } from '@app/shared/utils/helpers';
import { mockConnectActivity } from '@app/mock/api/data/mockConnectActivity';
import { ConnectActivityCategory } from '@app/models/connect/type/connect-activity-category.enum';

@Injectable()
export class ConnectActivityMockService implements MockService, ConnectActivityService {
    constructor(private readonly globals: Globals) { }

    handleRoute(req: HttpRequest<any>): any {
        const url = sanitizeUrl(req.urlWithParams);

        switch (true) {
            case url.endsWith('api/connect/activity') && req.method === 'POST':
                return this.createActivity(null);
            case url.match(/api\/connect\/activity\/coaching\?limit=\d+$/) !== null:
                const coachingActivityLimit = +req.params.get('limit');
                return this.getLatestCoachingActivity(coachingActivityLimit);
            case url.match(/api\/connect\/activity\/mentoring\?limit=\d+$/) !== null:
                const mentoringActivityLimit = +req.params.get('limit');
                return this.getLatestMentoringActivity(mentoringActivityLimit);
        }
    }

    getLatestCoachingActivity(limit: number): Observable<Array<ConnectActivity>> | ConnectActivity[] {
        return mockConnectActivity.filter(a =>
            a.category === ConnectActivityCategory.COACHING &&
            a.userId === this.globals.user.id
        ).splice(0, limit);
    }

    getLatestMentoringActivity(limit: number): Observable<Array<ConnectActivity>> | ConnectActivity[] {
        return mockConnectActivity.filter(a =>
            a.category === ConnectActivityCategory.MENTORING &&
            a.userId === this.globals.user.id
        ).splice(0, limit);
    }

    // No Ops listed below
    createActivity(createConnectActivity: CreateConnectActivity): Observable<ConnectActivity> {
        return null;
    }
}