import { Component, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Globals } from '@app/shared/globals/globals';
import { SentimentScaleBusinessService } from '../../service/sentiment-scale-business.service';
import { SentimentScale } from '../../model/sentiment-scale.model';
import { BreadcrumbService } from '@app/shared/breadcrumbs/breadcrumbs.service';
import { Breadcrumb } from '@app/models/breadcrumb.model';
import { IState } from '@app/models/state/state.model';
import { CommonMessages } from '@app/constants/common.messages';
import { SwalUtils } from '@app/shared/utils/swal.utils';
import { SentimentScaleMessages } from '../../locale/sentiment-scale.messages';
import { ButtonType } from '@app/shared/components/inputs/button/button.component';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FilterMethod, FilterOption } from '@app/models/universal-filter-option.model';
import { PaginationComponent } from '@app/shared/pagination/pagination.component';
import { DefaultFilter } from '@app/shared/universal-filter/universal-filter.component';

interface FilterData {
  filterOptions: FilterOption[];
  searchProps: string[];
  lastFilterResult: number[];
  defaultFilters: DefaultFilter
}

@Component({
  selector: 'app-sentiment-scale-overview',
  templateUrl: './sentiment-scale-overview.component.html',
  styleUrls: ['./sentiment-scale-overview.component.scss']
})
export class SentimentScaleOverviewComponent implements OnDestroy {

  public readonly eSentimentScaleMessages = SentimentScaleMessages;
  public readonly eButtonType = ButtonType;

  private readonly ngUnsubscribe$ = new Subject<void>();

  @ViewChild('pagination') pagination?: PaginationComponent;

  data: {
    master: SentimentScale[],
    filtered: SentimentScale[],
    display: SentimentScale[]
  };

  state: IState;
  breadcrumb: Breadcrumb;
  filterData: FilterData;

  constructor(
    public globals: Globals,
    public activatedRoute: ActivatedRoute,
    private swalUtils: SwalUtils,
    private breadcrumbService: BreadcrumbService,
    private router: Router,
    private sentimentScaleBusinessService: SentimentScaleBusinessService
  ) {
    this.data = {
      master: [],
      filtered: [],
      display: [] 
    };

    this.state = {
      loading: false,
      error: false,
      errorMessage: ''
    };

    this.filterData = {
      filterOptions: [],
      searchProps: ['Name'],
      lastFilterResult: [],
      defaultFilters: {
        'Archived': ['No']
      }
    };

    this.breadcrumb = this.breadcrumbService.init(this.activatedRoute);
    this.router.events
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(event => {
        if (event instanceof NavigationEnd && this.activatedRoute.children.length === 0) {
          this.getData();
        }
      });
  }

  ngOnDestroy(): void {
    this.breadcrumbService.remove(this.breadcrumb);
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.unsubscribe();
  }

  getData(): void {
    this.sentimentScaleBusinessService.getAll(true)
      .subscribe(scales => {
        this.data.master = scales;
        this.filterData.filterOptions = this.getFilterOptions(scales);
        this.refreshPagination();
      });
  }

  getFilterOptions(scale: SentimentScale[]): FilterOption[] {
    const filterOptions: FilterOption[] = [];
    scale.forEach(scale => {
      filterOptions.push({
        id: scale.id,
        properties: {
          'Name': { value: scale.name },
          'Archived': { value: scale.archived ? 'Yes' : 'No', filterMethod: FilterMethod.OR }
        }
      });
    });
    return filterOptions;
  }

  filterEmit(ids: number[], doRefresh = true) {
    this.filterData.lastFilterResult = ids;
    this.data.filtered = this.data.master.filter(p => ids.includes(p.id));

    if (doRefresh) {
      this.refreshPagination();
    }
  }

  refreshPagination(): void{
    if (!this.pagination) { return; }
    this.pagination.update();
  }

  onAction(event: string, task: SentimentScale): void {
    switch (event) {
      case 'edit':
        this.startEdit(task);
        break;
      case 'archive':
        this.startArchive(task);
        break;
    }
  }

  startCreate(): void {
    this.router.navigate(['./create'], { relativeTo: this.activatedRoute });
  }

  startEdit(scale: SentimentScale): void {
    this.router.navigate(['./edit', scale.id], { relativeTo: this.activatedRoute });
  }

  startArchive(scale: SentimentScale): void {
    if (this.sentimentScaleBusinessService.isModifyingScale(scale.id)) { return; }

    this.swalUtils.displayWarningConfirmationSwal({
      text: 'Are you sure you want to archive this sentiment scale? You can always restore it later.',
      confirmButtonText: CommonMessages.ARCHIVE
    }).then(swalResult => {
      if (swalResult.isConfirmed) {
        this.sentimentScaleBusinessService.archive(scale.id)
          .then(() => {
            this.getData();
          });
      }
    });
  }
  
}
