
export enum TerminologyEntity {
  DEPARTMENT = 'DEPARTMENT',
  DEPARTMENT_PLURAL = 'DEPARTMENT_PLURAL',
  TEAM = 'TEAM',
  TEAM_PLURAL = 'TEAM_PLURAL',
  SECONDARY = 'SECONDARY',
  SECONDARY_MANAGER = 'SECONDARY_MANAGER',
  SECONDARY_MANAGER_PLURAL = 'SECONDARY_MANAGER_PLURAL',
  SITE = 'SITE',
  SITE_PLURAL = 'SITE_PLURAL',
  SKILL = 'SKILL',
  SKILL_PLURAL = 'SKILL_PLURAL',
  COMPETENCY = 'COMPETENCY',
  COMPETENCY_PLURAL = 'COMPETENCY_PLURAL'
}
