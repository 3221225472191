import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Component({
  selector: 'app-form-input-dropdown-component',
  templateUrl: './form-input-dropdown.component.html',
  styleUrls: ['./form-input-dropdown.component.scss']
})
export class FormInputDropdownComponent implements OnInit, OnChanges {
  @Input() control !: AbstractControl;
  @Input() values !: Array<string>;
  @Input() placeholder !: string;
  @Input() enabled !: boolean;
  @Input() idInput !: string;

  displayDropdown: boolean;
  valuesDisplay: Array<string>;

  constructor() {
    this.displayDropdown = false;
    this.valuesDisplay = new Array<string>();
  }

  ngOnInit() {
    if (!this.idInput) {
      this.idInput = '';
    }
    this.control.valueChanges.subscribe(e => {
      this.filter();
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    this.filter();
  }

  filter() {
    this.valuesDisplay = this.values.filter(value => {
      const v = (this.control.value ? this.control.value : '')
      return value.trim().toLocaleLowerCase().includes(v.trim().toLocaleLowerCase());
    });
  }

  selectTemplate(value: string) {
    this.control.setValue(value);
  }

  // as the input loses focus, the dropdown will hide,
  // so we add a timeout to ensure that the click event is registered
  hideDropdown() {
    const component = this;
    setTimeout(function () {
      component.displayDropdown = false;
    }, 200)
  }

  showDropdown() {
    this.displayDropdown = true;
  }
}
