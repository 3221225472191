import {Component, Input} from '@angular/core';
import {PreferredContactMethod} from '@app/models/user/preferred-contact-method.model';
import {Globals} from '@app/shared/globals/globals';
import {PersonDetails} from '@app/shared/components/person-display/person-details';
import { CompanyFeatures } from '@app/models/company-features.model';
import {ScimUserApiService} from '@app/shared/api/scim-user.api.service';
import { TerminologyEntity } from '@app/domain/terminology/model/terminology-entity.enum';

@Component({
  selector: 'app-person-display',
  templateUrl: './person-display.component.html',
  styleUrls: ['./person-display.component.scss']
})
export class PersonDisplayComponent {

  public readonly eTerminologyEntity = TerminologyEntity;
  public readonly ePreferredContactMethod = PreferredContactMethod;
  public readonly eCompanyFeatures = CompanyFeatures;

  @Input() personDetails: PersonDetails;

  constructor(
    public globals: Globals,
    private scimUserApiService: ScimUserApiService
  ) { }

  openLinkedIn(event: Event, url: string) {
    window.open(`https://www.${url}`, '_blank');
    event.stopPropagation();
  }

  openTeams(event: Event) {
    event.stopPropagation();
    this.scimUserApiService.getTeamsCallURIForUserByUserId(this.personDetails.id).subscribe(teamsUrl => {
      // Open up the chat if we couldn't find their UPN
      let urlValue = 'https://teams.microsoft.com/l/chat/0/0';
      if (teamsUrl) {
        // Start a call directly if we were able to find their UPN
        urlValue = teamsUrl.toString();
      }
      window.open(urlValue, '_blank');
    });
  }

  openEmail(event: Event, emailAddr: string) {
    window.open(`mailto:${emailAddr}`, '_self');
    event.stopPropagation();
  }

  openPhone(event: Event, phoneNo: string) {
    window.open(`tel:${phoneNo}`, '_self');
    event.stopPropagation();
  }

  openTwitter(event: Event, handle: string) {
    window.open(`https://twitter.com/${handle}`, '_blank');
    event.stopPropagation();
  }
}
