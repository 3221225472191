import { MockService } from '@app/mock/api/mock-service';
import { NotificationService } from '@app/shared/api/interfaces/events.service';
import { Injectable } from '@angular/core';
import { Globals } from '@app/shared/globals/globals';
import { HttpRequest } from '@angular/common/http';
import { FrankliNotificationType } from '@app/models/notifications/frankli-event-type.enum';
import { FrankliNotification } from '@app/models/notifications/frankli-notification.model';
import { Observable } from 'rxjs';
import { sanitizeUrl } from '@app/shared/utils/helpers';
import { mockNotifications } from '@app/mock/api/data/mockNotifications';

@Injectable()
export class NotificationMockService implements MockService, NotificationService {
    constructor(private readonly globals: Globals) { }

    handleRoute(req: HttpRequest<any>): any {
        const url = sanitizeUrl(req.url);

        switch (true) {
            case url.endsWith('api/notifications'):
                return this.getEvents();
            case url.match(/api\/notifications\/\d+$/) !== null:
                const eventForeignId = +url.split('/')[2];
                return this.getEventByTypeAndEventForeignId(null, eventForeignId);
        }
    }

    getEventByTypeAndEventForeignId(type: FrankliNotificationType, foreignId: number): Observable<FrankliNotification | null> | FrankliNotification {
        return mockNotifications.find(n => n.eventForeignId === foreignId) || null;
    }

    getEvents(): Observable<Array<FrankliNotification>> | FrankliNotification[] {
        return mockNotifications.filter(n => n.userId === this.globals.user.id);
    }

    getEventsByType(type: FrankliNotificationType): Observable<Array<FrankliNotification>> | FrankliNotification[] {
        return mockNotifications.filter(n => n.userId === this.globals.user.id);
    }

    // No Ops listed below
    frankliAdminSendTestInformNotification(): Observable<FrankliNotification> {
        return undefined;
    }

    frankliAdminSendTestTaskNotification(): Observable<FrankliNotification> {
        return undefined;
    }

    markAllAsRead(): Observable<Array<FrankliNotification>> {
        return undefined;
    }

    markAsRead(notificationId: number): Observable<FrankliNotification> {
        return undefined;
    }

    recordClick(notificationId: number): Observable<FrankliNotification> {
        return undefined;
    }
}