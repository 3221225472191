import { MockService } from '@app/mock/api/mock-service';
import { Injectable } from '@angular/core';
import { HttpRequest } from '@angular/common/http';

@Injectable()
export class SpellcheckMockService implements MockService {
    handleRoute(req: HttpRequest<any>): any {
        // Spellchecking is a complete No-Op on the public demo
        return undefined
    }
}