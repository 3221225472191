export enum EvaluationCycleCreationStep {
    TYPE,
    DETAILS,
    STEPS,
    RECIPIENTS,
    QUESTION_BANKS,
    ASSESSMENT_GROUPS,
    PREVIEW,
    COMPLETE
}
