import { CompanyDirectoryService } from '@app/shared/api/interfaces/company-directory.service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CreatedCompanyDirectory } from '@app/models/company/company-directory/CreatedCompanyDirectory';
import { CompanyDirectory } from '@app/models/company/company-directory/CompanyDirectory';
import { CreateCompanyDirectory } from '@app/models/company/company-directory/CreateCompanyDirectory';

@Injectable()
export class CompanyDirectoryApiService implements CompanyDirectoryService {
  private readonly baseUrl = 'api/company-directories';

  constructor(private readonly http: HttpClient) { }

  createCompanyDirectory(createCompanyDirectory: CreateCompanyDirectory): Observable<CreatedCompanyDirectory> {
    const url = `${this.baseUrl}`;
    return this.http.post<CreatedCompanyDirectory>(url, createCompanyDirectory);
  }

  deleteCompanyDirectoryById(id:number): Observable<void> {
    const url = `${this.baseUrl}/${id}`;
    return this.http.delete<void>(url);
  }

  getCompanyDirectories(): Observable<CompanyDirectory[]> {
    const url = `${this.baseUrl}`;
    return this.http.get<CompanyDirectory[]>(url);
  }

  getCompanyDirectoryById(id:number): Observable<CompanyDirectory> {
    const url = `${this.baseUrl}/${id}`;
    return this.http.get<CompanyDirectory>(url);
  }

}