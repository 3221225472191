<div class="goal-create-templates-container">
  <div class="heading-area">
    <div class="modal-title" translate>{{eGoalsMessages.GOAL_CREATE_TEMPLATES_TITLE}}</div>
    <div class="modal-description" translate>{{eGoalsMessages.GOAL_CREATE_TEMPLATES_DESCRIPTION}}</div>
  </div>
  <div class="templates-list">
    <ng-container *ngIf="!state.loading; else stateLoading;">
      <ng-container *ngIf="templatesMaster.length > 0; else stateEmpty;">
        <div class="filter-container">
          <app-universal-filter
            [newUI]="true"
            [searchControl]="universalFilterData.searchControl" [filterOptions]="universalFilterData.filterOptions"
            [searchProps]="universalFilterData.searchProps" [defaultFilters]="universalFilterData.defaultFilters"
            (resultEmit)="filterEmit($event)" (categoriesChanged)="filterCategoriesChanged($event)"></app-universal-filter>
        </div>

        <ng-container *ngIf="templatesFiltered.length > 0; else stateSearchEmpty;">
          <div class="templates-list-inner">
            <div class="templates-list-flex">
              <ng-container *ngFor="let template of templatesDisplay">
                <div class="template-item">
                  <app-goal-template-display [template]="template" (onButtonClicked)="onSelectTemplate(template)"></app-goal-template-display>  
                </div>
              </ng-container>
            </div>
          </div>
        </ng-container>
        <div class="pagination-wrapper">
          <app-pagination-new-component [arrayData]="templatesFiltered" [arrayDisplay]="templatesDisplay" #pagination></app-pagination-new-component>
        </div>
      </ng-container>
    </ng-container>
  </div>
</div>

<ng-template #stateLoading>
  <app-loading-state [showMessage]="false" [height]="'calc(100vh - 246px)'"></app-loading-state>
</ng-template>

<ng-template #stateSearchEmpty>
  <app-empty-state [height]="'calc(100vh - 243px)'" [message]="eGoalsMessages.GOAL_CREATE_TEMPLATES_EMPTY_STATE_SEARCH"></app-empty-state>
</ng-template>

<ng-template #stateEmpty>
  <app-empty-state [height]="'calc(100vh - 243px)'" [message]="eGoalsMessages.GOAL_CREATE_TEMPLATES_EMPTY_STATE" [primaryButtonShown]="true" [primaryButtonText]="eGoalsMessages.CREATE_A_TEMPLATE" (primaryButtonPressed)="onClickCreateTemplate()"></app-empty-state>
</ng-template>