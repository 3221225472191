import { TerminologyCollection } from '@app/domain/terminology/model/terminology-collection.model';
import { Company, CompanyServerSide } from '../company.model';
import { SecondaryManagerAny } from '../company/company-secondary-manager/secondary-manager-any.model';
import { User, UserServerSide } from '../user/user.model';

export interface UserInitServerSide {
  company: CompanyServerSide;
  user: UserServerSide;
  accessToken: string;
  secondaryManagerAny: SecondaryManagerAny;
  terminologyCollection: TerminologyCollection;
}

export class UserInit {
  company: Company;
  user: User;
  accessToken: string;
  secondaryManagerAny: SecondaryManagerAny;
  terminologyCollection: TerminologyCollection;

  constructor(userInitServerSide: UserInitServerSide){
    this.company = new Company(userInitServerSide.company);
    this.user = new User(userInitServerSide.user);
    this.accessToken = userInitServerSide.accessToken;
    this.secondaryManagerAny = userInitServerSide.secondaryManagerAny;
    this.terminologyCollection = userInitServerSide.terminologyCollection;
  }
}
