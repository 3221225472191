import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {CreateCompanyDto} from 'app/models/company/create-company.dto';
import {CreateCustomerAccountDto} from 'app/models/customer-account/create-customer-account.dto';
import {CustomerAccount} from 'app/models/customer-account/customer-account.model';
import {UpdateCustomerAccountDto} from 'app/models/customer-account/update-customer-account.dto';
import {UpdateCompanyDto} from 'app/models/company/update-company.dto';
import {CreateCompanyChampionUserDto, CreateUserDto} from 'app/models/user/create-user.dto';
import {UpdateUserDto} from 'app/models/user/update-user.dto';
import {Company, CompanyServerSide} from 'app/models/company.model';
import {CreateSandboxDto} from 'app/models/company/create-sandbox.dto';
import {User} from 'app/models/user/user.model';
import {Department} from '@app/models/department.model';
import {UserRole} from '@app/models/user-role.model';
import {CreateCompanyTrialDto} from 'app/models/company/create-company-trial.dto';
import {UpdateCompanyTrialDto} from 'app/models/company/update-company-trial.dto';
import { Role } from '@app/domain/role/model/role.model';
import {FrankliActivity} from '@app/models/company/frankli-activity/frankli-activity.model';
import {CompanyAPIService} from './company/company.api.service';
import {map} from 'rxjs/operators';
import {FrankliActivityServerSide} from '@app/models/company/frankli-activity/frankli-activity-server-side.model';
import {
  UpdateCompanyConfigurationDto
} from '@app/models/company/company-configuration/update-company-configuration.dto';
import {FrankliActivityType} from '@app/models/frankli-activity-type.model';

@Injectable()
export class CustomerAccountAPIService {
  private readonly BASE_URL = 'api/customer-accounts/';
  private readonly HEADERS = new HttpHeaders({
    'Content-Type': 'application/json',
  });

  constructor(
      private http: HttpClient,
    private companyAPIService: CompanyAPIService
  ) { }

  /**
   * Creates a customer account for an anonymous user
   * @param createSandboxDto
   */
  anonymousUserCreateSandbox(createSandboxDto: CreateSandboxDto): Observable<CustomerAccount> {
    const url = `${this.BASE_URL}demo`;
    return this.http.post<CustomerAccount>(url, createSandboxDto);
  }

  /**
   * Creates a customer account
   * @param createCustomerAccountDto
   */
  createCustomerAccount(createCustomerAccountDto: CreateCustomerAccountDto): Observable<CustomerAccount> {
    const url = this.BASE_URL;
    return this.http.post<CustomerAccount>(url, createCustomerAccountDto);
  }

  /**
   * Gets all customer accounts
   */
  getAllCustomerAccounts(): Observable<Array<CustomerAccount>> {
    const url = this.BASE_URL;
    return this.http.get<Array<CustomerAccount>>(url);
  }

  /**
   * Gets a customer account by id
   * @param accountId
   */
  getCustomerAccountById(accountId: number): Observable<CustomerAccount> {
    const url = this.BASE_URL + `${accountId}`;
    return this.http.get<CustomerAccount>(url);
  }

  /**
   * Updates a customer account by id, if it exists
   * @param accountId
   * @param updateCustomerAccountDto
   */
  updateCustomerAccountById(accountId: number, updateCustomerAccountDto: UpdateCustomerAccountDto)
    : Observable<CustomerAccount> {
    const url = this.BASE_URL + `${accountId}`;
    return this.http.put<CustomerAccount>(url, updateCustomerAccountDto);
  }

  /**
   * Deletes a customer account and all companies associated with it
   * @param accountId
   */
  deleteCustomerAccountById(accountId: number): Observable<CustomerAccount> {
    const url = this.BASE_URL + `${accountId}`;
    return this.http.delete<CustomerAccount>(url);
  }

  /**
   * Adds a company that is not already part of an account to an account
   * @param accountId
   * @param companyId
   */
  addLegacyCompanyToAccount(accountId: number, companyId: number): Observable<CustomerAccount> {
    const url = this.BASE_URL + `legacy/${accountId}/company/${companyId}/`;
    return this.http.post<CustomerAccount>(url, null);
  }


  /**
   * Creates a new company and adds it to a specified account
   * @param accountId
   * @param createCompanyDto
   */
  createCompany(accountId: number, createCompanyDto: CreateCompanyDto): Observable<Company> {
    const url = this.BASE_URL + `${accountId}/company`;
    return this.http.post<CompanyServerSide>(url, createCompanyDto).pipe(map(company => new Company(company)));
  }

  /**
   * Creates a new demo company and adds it to a specified account
   * @param accountId
   * @param createCompanyDto
   */
  createDemoCompany(accountId: number, createCompanyDto: CreateSandboxDto): Observable<Company> {
    const url = this.BASE_URL + `${accountId}/company/demo`;
    return this.http.post<CompanyServerSide>(url, createCompanyDto).pipe(map(company => new Company(company)));
  }

  /**
   * Get all companies by account id
   * @param accountId
   */
  getAllCompaniesByAccountId(accountId: number): Observable<Array<Company>> {
    const url = this.BASE_URL + `${accountId}/company`;
    return this.http.get<Array<CompanyServerSide>>(url).pipe(map(companies => companies.map(company => new Company(company))));
  }

  /**
   * Get all companies by account id
   * @param accountId
   */
  getCompanyByAccountIdAndCompanyId(accountId: number, companyId: number): Observable<Company> {
    const url = this.BASE_URL + `${accountId}/company/${companyId}`;
    return this.http.get<CompanyServerSide>(url).pipe(map(company => new Company(company)));
  }

  /**
   * Gets all companies that are not associated with an account
   */
  getAllLegacyCompanies(): Observable<Array<Company>> {
    const url = this.BASE_URL + 'legacy';
    return this.http.get<Array<CompanyServerSide>>(url).pipe(map(companies => companies.map(company => new Company(company))));
  }

  /**
   * Updates a company by account id and company id
   * @param accountId
   * @param companyId
   * @param updateCompanyDto
   */
  updateCompanyByAccountIdAndCompanyId(accountId: number, companyId: number, updateCompanyDto: UpdateCompanyDto)
    : Observable<Company> {
    const url = this.BASE_URL + `${accountId}/company/${companyId}`;
    return this.http.put<CompanyServerSide>(url, updateCompanyDto).pipe(map(company => new Company(company)));
  }

  /**
   * Adds a trial period to a company
   * @param accountId
   * @param companyId
   * @param endDate
   */
  addTrialByAccountIdAndCompanyId(accountId: number, companyId: number, createCompanyTrialDto: CreateCompanyTrialDto): Observable<Company> {
    const url = this.BASE_URL + `${accountId}/company/${companyId}/trial/`;
    return this.http.post<CompanyServerSide>(url, createCompanyTrialDto).pipe(map(company => new Company(company)));
  }

  /**
   * updates a trial period for a company
   * @param accountId
   * @param companyId
   * @param endDate
   */
  updateTrialByAccountIdAndCompanyId(accountId: number, companyId: number, updateCompanyTrialDto: UpdateCompanyTrialDto)
    : Observable<Company> {
    const url = this.BASE_URL + `${accountId}/company/${companyId}/trial/`;
    return this.http.put<CompanyServerSide>(url, updateCompanyTrialDto).pipe(map(company => new Company(company)));
  }

  /**
   * Removes the trial period from a company
   * @param accountId
   * @param companyId
   */
  removeTrialByAccountIdAndCompanyId(accountId: number, companyId: number): Observable<Company> {
    const url = this.BASE_URL + `${accountId}/company/${companyId}/trial/`;
    return this.http.delete<CompanyServerSide>(url).pipe(map(company => new Company(company)));
  }

  /**
   * Get all organisational untits by account id and company id
   * @param accountId
   */
  getOrganisationalUnitsByAccountIdAndCompanyId(accountId: number, companyId: number)
    : Observable<Array<Department>> {
    const url = this.BASE_URL + `${accountId}/company/${companyId}/organisational-unit`;
    return this.http.get<Array<Department>>(url);
  }

  /**
   * Get all roles by account id and company id
   * @param accountId
   */
  getRolesByAccountIdAndCompanyId(accountId: number, companyId: number): Observable<Array<UserRole>> {
    const url = this.BASE_URL + `${accountId}/company/${companyId}/role`;
    return this.http.get<Array<UserRole>>(url);
  }

  /**
 * Get all roles by account id and company id
 * @param accountId
 */
  getPositionsByAccountIdAndCompanyId(accountId: number, companyId: number): Observable<Array<Role>> {
    const url = this.BASE_URL + `${accountId}/company/${companyId}/position`;
    return this.http.get<Array<Role>>(url);
  }

  /**
   * Update company configuration by account id and company id
   * @param accountId 
   * @param companyId 
   * @param updateCompanyConfigurationDto 
   * @returns 
   */
  updateConfigurationByAccountIdAndCompanyId(accountId: number, companyId: number, updateCompanyConfigurationDto: UpdateCompanyConfigurationDto): Observable<Company> {
    const url = this.BASE_URL + `${accountId}/company/${companyId}/configuration`;
    return this.http.put<Company>(url, updateCompanyConfigurationDto);
  }

  /**
  * Creates a company champion (will error if company state is not NEW)
  * @param accountId
  * @param companyId
  * @param createUserDto
  */
  createCompanyChampionUser(accountId: number, companyId: number, createCompanyChampionUser: CreateCompanyChampionUserDto): Observable<User> {
    const url = this.BASE_URL + `${accountId}/company/${companyId}/champion`;
    return this.http.post<User>(url, createCompanyChampionUser);
  }

  /**
   * Creates a new user for a company (will error if company state is NEW)
   * @param accountId
   * @param companyId
   * @param createUserDto
   */
  createUser(accountId: number, companyId: number, createUserDto: CreateUserDto): Observable<User> {
    const url = this.BASE_URL + `${accountId}/company/${companyId}/user`;
    return this.http.post<User>(url, createUserDto);
  }


  /**
   * Gets all users by account id and company id
   * @param accountId
   * @param companyId
   */
  getAllUsersByAccountIdAndCompanyId(accountId: number, companyId: number): Observable<Array<User>> {
    const url = this.BASE_URL + `${accountId}/company/${companyId}/user`;
    return this.http.get<Array<User>>(url);
  }

  /**
   * Gets a user by account id, company id, and user id
   * @param accountId
   * @param companyId
   * @param userId
   */
  getUserByAccountIdAndCompanyIdAndUserId(accountId: number, companyId: number, userId: number): Observable<User> {
    const url = this.BASE_URL + `${accountId}/company/${companyId}/user/${userId}`;
    return this.http.get<User>(url);
  }

  /**
   * Updates a user by account id, company id and user id
   * @param accountId
   * @param customerAccount
   * @param userId
   * @param updateUserDto
   */
  updateUserByAccountIdAndCompanyIdAndUserId(accountId: number, companyId: number, userId: number,
    updateUserDto: UpdateUserDto): Observable<User> {
    const url = this.BASE_URL + `${accountId}/company/${companyId}/user/${userId}`;
    return this.http.put<User>(url, updateUserDto);
  }

  /**
   * Deletes a user by account id, company id and user id
   * @param accountId
   * @param companyId
   * @param userId
   */
  deleteUserByAccountIdAndCompanyIdAndUserId(accountId: number, companyId: number, userId: number): Observable<User> {
    const url = this.BASE_URL + `${accountId}/company/${companyId}/user/${userId}`;
    return this.http.delete<User>(url);
  }

  /**
   * Invites a user by account id, company id and user id
   * @param accountId
   * @param companyId
   * @param userId
   */
  inviteUserByAccountIdAndCompanyIdAndUserId(accountId: number, companyId: number, userId: number): Observable<User> {
    const url = this.BASE_URL + `${accountId}/company/${companyId}/user/${userId}/invite`;
    return this.http.post<User>(url, null);
  }

  /**
   * Archive meetings for user
   * @param accountId
   * @param companyId
   * @param userId
   */
  archiveUserMeetingsByAccountIdAndCompanyIdAndUserId(accountId: number, companyId: number, userId: number): Observable<User> {
    const url = this.BASE_URL + `${accountId}/company/${companyId}/user/${userId}/archiveMeetings`;
    return this.http.post<User>(url, null);
  }


  /**
   * Returns a list of all frankli activity filterable by type, starting date and ending date
   */
  getFrankliActivity(group: string, frankliActivityType: string | null, fromDate: Date | null, toDate: Date | null): Observable<Array<FrankliActivity>> {
    let url = this.BASE_URL + `activity?group=${group}`;

    if (frankliActivityType != null) {
      url += `&type=${frankliActivityType}`;
    }

    if (fromDate !== null) {
      url += `&from=${fromDate.getTime()}`;
    }

    if (toDate !== null) {
      url += `&to=${toDate.getTime()}`;
    }

    return this.http.get<Array<FrankliActivityServerSide>>(url).pipe(map(activityList => activityList.map(activity => new FrankliActivity(activity))));
  }

  /**
   * Returns a list of FrankliActivity types
   */
  getFrankliActivityTypes(): Observable<Array<FrankliActivityType>> {
    const url = this.BASE_URL + 'activity/types';
    return this.http.get<Array<FrankliActivityType>>(url);
  }

  /**
   * Returns all activity of a provided type for a company between provided dates 
   * @param accountId
   * @param companyId
   * @param frankliActivityType
   * @param fromDate
   * @param toDate
   */
  getFrankliActivityByAccountIdAndCompanyIdAndTypeAndFromDateAndToDate(
    accountId: number,
    companyId: number,
    group: string,
    frankliActivityType: string | null,
    fromDate: Date | null,
    toDate: Date | null
  ): Observable<Array<FrankliActivity>> {
    let url = this.BASE_URL + `${accountId}/company/${companyId}/activity?group=${group}`;

    if(frankliActivityType!=null){
      url +=`&type=${frankliActivityType}`;
    }
    
    if (fromDate !== null) {
      url += `&from=${fromDate.getTime()}`;
    }

    if (toDate !== null) {
      url += `&to=${toDate.getTime()}`;
    }

    return this.http.get<Array<FrankliActivityServerSide>>(url).pipe(map(activityList => activityList.map(activity => new FrankliActivity(activity))));
  }

  unlinkCompanyFromCustomerAccount(accountId: number, companyId: number): Observable<CustomerAccount> {
    const url = this.BASE_URL + `${accountId}/company/${companyId}/unlink`;
    return this.http.post<CustomerAccount>(url, null);
  }
}
