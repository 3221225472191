<ng-container *ngIf="src && !hasErrors; else templateErrors;">
  <ng-container *ngIf="!hasDefaultAvatar; else templateErrors">
    <ng-container *ngIf="srcRequiresAuthentication; else templateNoAuthAvatar;">
      <img class="profile-photo-component-image" [src]="src | secure | async" [style.width]="width" [style.height]="width" (error)="onErrorLoadingImage($event)" alt="Avatar"/>
    </ng-container>
    <ng-template #templateNoAuthAvatar>
      <img class="profile-photo-component-image" [src]="src" [style.width]="width" [style.height]="width" (error)="onErrorLoadingImage($event)" alt="Avatar"/>
      <!-- <img class="profile-photo-component-image" src="{{ src }}" [style.width]="width" [style.height]="width"/> -->
    </ng-template>
  </ng-container>
</ng-container>

<ng-template #templateErrors>
  <ng-container *ngIf="canUseLetterAvatar; else templateDefaultAvatar;">
    <!-- LETTER AVATAR -->
    <div class="profile-photo-component-letter-avatar" [style.width]="width" [style.height]="width" [style.fontSize]="letterAvatarFontSize">
      <span *ngIf="firstName?.length > 0">{{firstName[0]}}</span>
      <span *ngIf="lastName?.length > 0">{{lastName[0]}}</span>
    </div>  
  </ng-container>

  <ng-template #templateDefaultAvatar>
    <!-- DEFAULT AVATAR -->
    <img class="profile-photo-component-image" [src]="DEFAULT_USER_IMAGE" [style.width]="width" [style.height]="width" alt="Default avatar"/>
  </ng-template>
</ng-template>
