import { CompletedSurvey } from '@app/models/survey/completed-survey.model';
import { mockPublishedSurveys } from '@app/mock/api/data/mockPublishedSurveys';
import moment from 'moment';

export const mockCompletedSurveys: CompletedSurvey[] = [
  {
    "id": 136,
    "publishedSurveyId": mockPublishedSurveys[0].id,
    "surveyQuestionAnswers": [
      {
        "id": 827,
        "surveyQuestionId": 13,
        "textAnswer": "4",
        "comment": null
      },
      {
        "id": 828,
        "surveyQuestionId": 76,
        "textAnswer": "4",
        "comment": "Certainly flexible in this respect "
      },
      {
        "id": 829,
        "surveyQuestionId": 40,
        "textAnswer": "2",
        "comment": "We could do a lot more here "
      },
      {
        "id": 830,
        "surveyQuestionId": 41,
        "textAnswer": "5",
        "comment": null
      },
      {
        "id": 831,
        "surveyQuestionId": 81,
        "textAnswer": "4",
        "comment": null
      },
      {
        "id": 832,
        "surveyQuestionId": 82,

        "textAnswer": "4",
        "comment": null
      },
      {
        "id": 833,
        "surveyQuestionId": 83,

        "textAnswer": "3",
        "comment": null
      },
      {
        "id": 834,
        "surveyQuestionId": 63,

        "textAnswer": "4",
        "comment": null
      },
      {
        "id": 835,
        "surveyQuestionId": 84,

        "textAnswer": "4",
        "comment": null
      },
      {
        "id": 836,
        "surveyQuestionId": 85,

        "textAnswer": "1",
        "comment": "Certainly an area worth improving upon "
      },
      {
        "id": 837,
        "surveyQuestionId": 86,

        "textAnswer": "4",
        "comment": null
      },
      {
        "id": 838,
        "surveyQuestionId": 77,

        "textAnswer": "0",
        "comment": null
      },
      {
        "id": 839,
        "surveyQuestionId": 91,

        "textAnswer": "4",
        "comment": null
      }
    ],
    "startedDateTime": moment().subtract(3, 'days').toDate(),
    "startedDateTimeSeconds": 0,
    "completedDateTime": moment().subtract(2, 'days').toDate(),
    "officeLocation": {
      "id": 395,
      "name": "Auckland Office ",
      "officeAddress": {
        "id": 1754,
        "street": "Main drive",
        "city": "Auckland ",
        "district": "",
        "postalCode": "",
        "country": "New Zealand",
        timezone: 'Europe/Dublin'
      }
    },
    "organisationalUnit": {
      "id": 91,
      "level": 1,
      "parentOrg": {
        "id": 90,
        "level": 0,
        "parentOrg": null,
        "organisationalUnitType": {
          "id": 2,
          "name": "Department"
        },
        "managerUserId": null,
        "feedbackUserId": null,
        "name": "Leadership"
      },
      "organisationalUnitType": {
        "id": 2,
        "name": "Department"
      },
      "managerUserId": null,
      "feedbackUserId": null,
      "name": "Engineering"
    }
  },
  {
    "id": 137,
    "publishedSurveyId": mockPublishedSurveys[0].id,
    "surveyQuestionAnswers": [
      {
        "id": 840,
        "surveyQuestionId": 13,

        "textAnswer": "4",
        "comment": null
      },
      {
        "id": 841,
        "surveyQuestionId": 76,

        "textAnswer": "2",
        "comment": null
      },
      {
        "id": 842,
        "surveyQuestionId": 40,

        "textAnswer": "4",
        "comment": null
      },
      {
        "id": 843,
        "surveyQuestionId": 41,

        "textAnswer": "4",
        "comment": null
      },
      {
        "id": 844,
        "surveyQuestionId": 81,

        "textAnswer": "3",
        "comment": null
      },
      {
        "id": 845,
        "surveyQuestionId": 82,

        "textAnswer": "1",
        "comment": null
      },
      {
        "id": 846,
        "surveyQuestionId": 83,

        "textAnswer": "5",
        "comment": null
      },
      {
        "id": 847,
        "surveyQuestionId": 63,

        "textAnswer": "4",
        "comment": "This is certainly true "
      },
      {
        "id": 848,
        "surveyQuestionId": 84,

        "textAnswer": "5",
        "comment": null
      },
      {
        "id": 849,
        "surveyQuestionId": 85,

        "textAnswer": "1",
        "comment": null
      },
      {
        "id": 850,
        "surveyQuestionId": 86,

        "textAnswer": "2",
        "comment": null
      },
      {
        "id": 851,
        "surveyQuestionId": 77,

        "textAnswer": "4",
        "comment": null
      },
      {
        "id": 852,
        "surveyQuestionId": 91,

        "textAnswer": "0",
        "comment": null
      }
    ],
    "startedDateTime": moment().subtract(3, 'days').toDate(),
    "startedDateTimeSeconds": 0,
    "completedDateTime": moment().subtract(2, 'days').toDate(),
    "officeLocation": {
      "id": 725,
      "name": "Fairmont, USA",
      "officeAddress": {
        "id": 3883,
        "street": "Dame lane",
        "city": "USA",
        "district": "",
        "postalCode": "",
        "country": "United States",
        timezone: 'Europe/Dublin'
      }
    },
    "organisationalUnit": {
      "id": 298,
      "level": 0,
      "parentOrg": {
        "id": 90,
        "level": 0,
        "parentOrg": null,
        "organisationalUnitType": {
          "id": 2,
          "name": "Department"
        },
        "managerUserId": null,
        "feedbackUserId": null,
        "name": "Leadership"
      },
      "organisationalUnitType": {
        "id": 2,
        "name": "Department"
      },
      "managerUserId": null,
      "feedbackUserId": null,
      "name": "Interior Design Team"
    }
  },
  {
    "id": 138,
    "publishedSurveyId": mockPublishedSurveys[0].id,
    "surveyQuestionAnswers": [
      {
        "id": 853,
        "surveyQuestionId": 13,

        "textAnswer": "2",
        "comment": "I don't see much of her"
      },
      {
        "id": 854,
        "surveyQuestionId": 76,

        "textAnswer": "1",
        "comment": null
      },
      {
        "id": 855,
        "surveyQuestionId": 40,

        "textAnswer": "3",
        "comment": null
      },
      {
        "id": 856,
        "surveyQuestionId": 41,

        "textAnswer": "4",
        "comment": null
      },
      {
        "id": 857,
        "surveyQuestionId": 81,

        "textAnswer": "4",
        "comment": null
      },
      {
        "id": 858,
        "surveyQuestionId": 82,

        "textAnswer": "4",
        "comment": null
      },
      {
        "id": 859,
        "surveyQuestionId": 83,

        "textAnswer": "2",
        "comment": null
      },
      {
        "id": 860,
        "surveyQuestionId": 63,

        "textAnswer": "0",
        "comment": null
      },
      {
        "id": 861,
        "surveyQuestionId": 84,

        "textAnswer": "0",
        "comment": null
      },
      {
        "id": 862,
        "surveyQuestionId": 85,

        "textAnswer": "1",
        "comment": null
      },
      {
        "id": 863,
        "surveyQuestionId": 86,

        "textAnswer": "5",
        "comment": null
      },
      {
        "id": 864,
        "surveyQuestionId": 77,

        "textAnswer": "5",
        "comment": null
      },
      {
        "id": 865,
        "surveyQuestionId": 91,

        "textAnswer": "4",
        "comment": null
      }
    ],
    "startedDateTime": moment().subtract(3, 'days').toDate(),
    "startedDateTimeSeconds": 0,
    "completedDateTime": moment().subtract(2, 'days').toDate(),
    "officeLocation": {
      "id": 95,
      "name": "Working remotely",
      "officeAddress": null
    },
    "organisationalUnit": {
      "id": 91,
      "level": 1,
      "parentOrg": {
        "id": 90,
        "level": 0,
        "parentOrg": null,
        "organisationalUnitType": {
          "id": 2,
          "name": "Department"
        },
        "managerUserId": null,
        "feedbackUserId": null,
        "name": "Leadership"
      },
      "organisationalUnitType": {
        "id": 2,
        "name": "Department"
      },
      "managerUserId": null,
      "feedbackUserId": null,
      "name": "Engineering"
    }
  },
  {
    "id": 139,
    "publishedSurveyId": mockPublishedSurveys[0].id,
    "surveyQuestionAnswers": [
      {
        "id": 866,
        "surveyQuestionId": 13,

        "textAnswer": "4",
        "comment": null
      },
      {
        "id": 867,
        "surveyQuestionId": 76,

        "textAnswer": "2",
        "comment": null
      },
      {
        "id": 868,
        "surveyQuestionId": 40,

        "textAnswer": "1",
        "comment": null
      },
      {
        "id": 869,
        "surveyQuestionId": 41,

        "textAnswer": "2",
        "comment": null
      },
      {
        "id": 870,
        "surveyQuestionId": 81,

        "textAnswer": "4",
        "comment": null
      },
      {
        "id": 871,
        "surveyQuestionId": 82,

        "textAnswer": "5",
        "comment": "One strength across company "
      },
      {
        "id": 872,
        "surveyQuestionId": 83,

        "textAnswer": "5",
        "comment": null
      },
      {
        "id": 873,
        "surveyQuestionId": 63,

        "textAnswer": "4",
        "comment": null
      },
      {
        "id": 874,
        "surveyQuestionId": 84,

        "textAnswer": "4",
        "comment": null
      },
      {
        "id": 875,
        "surveyQuestionId": 85,

        "textAnswer": "4",
        "comment": null
      },
      {
        "id": 876,
        "surveyQuestionId": 86,

        "textAnswer": "3",
        "comment": null
      },
      {
        "id": 877,
        "surveyQuestionId": 77,

        "textAnswer": "1",
        "comment": null
      },
      {
        "id": 878,
        "surveyQuestionId": 91,

        "textAnswer": "3",
        "comment": null
      }
    ],
    "startedDateTime": moment().subtract(3, 'days').toDate(),
    "startedDateTimeSeconds": 0,
    "completedDateTime": moment().subtract(2, 'days').toDate(),
    "officeLocation": {
      "id": 93,
      "name": "London Office",
      "officeAddress": {
        "id": 362,
        "street": "13 Kingly Court",
        "city": "London",
        "district": "Carnaby",
        "postalCode": "W1B 5PG",
        "country": "UK",
        timezone: 'Europe/Dublin'
      }
    },
    "organisationalUnit": {
      "id": 261,
      "level": 0,
      "parentOrg": {
        "id": 90,
        "level": 0,
        "parentOrg": null,
        "organisationalUnitType": {
          "id": 2,
          "name": "Department"
        },
        "managerUserId": null,
        "feedbackUserId": null,
        "name": "Leadership"
      },
      "organisationalUnitType": {
        "id": 2,
        "name": "Department"
      },
      "managerUserId": null,
      "feedbackUserId": null,
      "name": "Finance"
    }
  },
  {
    "id": 140,
    "publishedSurveyId": mockPublishedSurveys[0].id,
    "surveyQuestionAnswers": [
      {
        "id": 879,
        "surveyQuestionId": 13,

        "textAnswer": "4",
        "comment": "Great support & leadership "
      },
      {
        "id": 880,
        "surveyQuestionId": 76,

        "textAnswer": "4",
        "comment": null
      },
      {
        "id": 881,
        "surveyQuestionId": 40,

        "textAnswer": "4",
        "comment": null
      },
      {
        "id": 882,
        "surveyQuestionId": 41,

        "textAnswer": "4",
        "comment": null
      },
      {
        "id": 883,
        "surveyQuestionId": 81,

        "textAnswer": "4",
        "comment": null
      },
      {
        "id": 884,
        "surveyQuestionId": 82,

        "textAnswer": "4",
        "comment": null
      },
      {
        "id": 885,
        "surveyQuestionId": 83,

        "textAnswer": "3",
        "comment": null
      },
      {
        "id": 886,
        "surveyQuestionId": 63,

        "textAnswer": "5",
        "comment": null
      },
      {
        "id": 887,
        "surveyQuestionId": 84,

        "textAnswer": "2",
        "comment": null
      },
      {
        "id": 888,
        "surveyQuestionId": 85,

        "textAnswer": "2",
        "comment": null
      },
      {
        "id": 889,
        "surveyQuestionId": 86,

        "textAnswer": "3",
        "comment": null
      },
      {
        "id": 890,
        "surveyQuestionId": 77,

        "textAnswer": "4",
        "comment": null
      },
      {
        "id": 891,
        "surveyQuestionId": 91,

        "textAnswer": "2",
        "comment": null
      }
    ],
    "startedDateTime": moment().subtract(3, 'days').toDate(),
    "startedDateTimeSeconds": 0,
    "completedDateTime": moment().subtract(2, 'days').toDate(),
    "officeLocation": {
      "id": 93,
      "name": "London Office",
      "officeAddress": {
        "id": 362,
        "street": "13 Kingly Court",
        "city": "London",
        "district": "Carnaby",
        "postalCode": "W1B 5PG",
        "country": "UK",
        timezone: 'Europe/Dublin'
      }
    },
    "organisationalUnit": {
      "id": 93,
      "level": 1,
      "parentOrg": {
        "id": 90,
        "level": 0,
        "parentOrg": null,
        "organisationalUnitType": {
          "id": 2,
          "name": "Department"
        },
        "managerUserId": null,
        "feedbackUserId": null,
        "name": "Leadership"
      },
      "organisationalUnitType": {
        "id": 2,
        "name": "Department"
      },
      "managerUserId": null,
      "feedbackUserId": null,
      "name": "Projects"
    },
  },
  // {
  //   "id": 141,
  //   "publishedSurveyId": mockPublishedSurveys[2].id,
  //   "surveyQuestionAnswers": [
  //     {
  //       "id": 827,
  //       "surveyQuestionId": 140,
  //       "textAnswer": "8",
  //       "comment": null
  //     }
  //   ],
  //   "startedDateTime": moment().subtract(3, 'days').toDate(),
  //   "startedDateTimeSeconds": 0,
  //   "completedDateTime": moment().subtract(2, 'days').toDate(),
  //   "officeLocation": {
  //     "id": 395,
  //     "name": "Auckland Office ",
  //     "officeAddress": {
  //       "id": 1754,
  //       "street": "Main drive",
  //       "city": "Auckland ",
  //       "district": "",
  //       "postalCode": "",
  //       "country": "New Zealand",
  //       timezone: 'Europe/Dublin'
  //     }
  //   },
  //   "organisationalUnit": {
  //     "id": 91,
  //     "level": 1,
  //     "parentOrg": {
  //       "id": 90,
  //       "level": 0,
  //       "parentOrg": null,
  //       "organisationalUnitType": {
  //         "id": 2,
  //         "name": "Department"
  //       },
  //       "managerUserId": null,
  //       "feedbackUserId": null,
  //       "name": "Leadership"
  //     },
  //     "organisationalUnitType": {
  //       "id": 2,
  //       "name": "Department"
  //     },
  //     "managerUserId": null,
  //     "feedbackUserId": null,
  //     "name": "Engineering"
  //   }
  // },
  // {
  //   "id": 142,
  //   "publishedSurveyId": mockPublishedSurveys[2].id,
  //   "surveyQuestionAnswers": [
  //     {
  //       "id": 840,
  //       "surveyQuestionId": 140,
  //       "textAnswer": "7",
  //       "comment": null
  //     },
  //   ],
  //   "startedDateTime": moment().subtract(3, 'days').toDate(),
  //   "startedDateTimeSeconds": 0,
  //   "completedDateTime": moment().subtract(2, 'days').toDate(),
  //   "officeLocation": {
  //     "id": 725,
  //     "name": "Fairmont, USA",
  //     "officeAddress": {
  //       "id": 3883,
  //       "street": "Dame lane",
  //       "city": "USA",
  //       "district": "",
  //       "postalCode": "",
  //       "country": "United States",
  //       timezone: 'Europe/Dublin'
  //     }
  //   },
  //   "organisationalUnit": {
  //     "id": 298,
  //     "level": 0,
  //     "parentOrg": {
  //       "id": 90,
  //       "level": 0,
  //       "parentOrg": null,
  //       "organisationalUnitType": {
  //         "id": 2,
  //         "name": "Department"
  //       },
  //       "managerUserId": null,
  //       "feedbackUserId": null,
  //       "name": "Leadership"
  //     },
  //     "organisationalUnitType": {
  //       "id": 2,
  //       "name": "Department"
  //     },
  //     "managerUserId": null,
  //     "feedbackUserId": null,
  //     "name": "Interior Design Team"
  //   }
  // },
  // {
  //   "id": 143,
  //   "publishedSurveyId": mockPublishedSurveys[2].id,
  //   "surveyQuestionAnswers": [
  //     {
  //       "id": 853,
  //       "surveyQuestionId": 140,
  //       "textAnswer": "7",
  //       "comment": null
  //     }
  //   ],
  //   "startedDateTime": moment().subtract(3, 'days').toDate(),
  //   "startedDateTimeSeconds": 0,
  //   "completedDateTime": moment().subtract(2, 'days').toDate(),
  //   "officeLocation": {
  //     "id": 95,
  //     "name": "Working remotely",
  //     "officeAddress": null
  //   },
  //   "organisationalUnit": {
  //     "id": 91,
  //     "level": 1,
  //     "parentOrg": {
  //       "id": 90,
  //       "level": 0,
  //       "parentOrg": null,
  //       "organisationalUnitType": {
  //         "id": 2,
  //         "name": "Department"
  //       },
  //       "managerUserId": null,
  //       "feedbackUserId": null,
  //       "name": "Leadership"
  //     },
  //     "organisationalUnitType": {
  //       "id": 2,
  //       "name": "Department"
  //     },
  //     "managerUserId": null,
  //     "feedbackUserId": null,
  //     "name": "Engineering"
  //   }
  // },
  // {
  //   "id": 144,
  //   "publishedSurveyId": mockPublishedSurveys[2].id,
  //   "surveyQuestionAnswers": [
  //     {
  //       "id": 853,
  //       "surveyQuestionId": 140,
  //       "textAnswer": "9",
  //       "comment": null
  //     }
  //   ],
  //   "startedDateTime": moment().subtract(3, 'days').toDate(),
  //   "startedDateTimeSeconds": 0,
  //   "completedDateTime": moment().subtract(2, 'days').toDate(),
  //   "officeLocation": {
  //     "id": 95,
  //     "name": "Working remotely",
  //     "officeAddress": null
  //   },
  //   "organisationalUnit": {
  //     "id": 91,
  //     "level": 1,
  //     "parentOrg": {
  //       "id": 90,
  //       "level": 0,
  //       "parentOrg": null,
  //       "organisationalUnitType": {
  //         "id": 2,
  //         "name": "Department"
  //       },
  //       "managerUserId": null,
  //       "feedbackUserId": null,
  //       "name": "Leadership"
  //     },
  //     "organisationalUnitType": {
  //       "id": 2,
  //       "name": "Department"
  //     },
  //     "managerUserId": null,
  //     "feedbackUserId": null,
  //     "name": "Engineering"
  //   }
  // },
  // {
  //   "id": 145,
  //   "publishedSurveyId": mockPublishedSurveys[2].id,
  //   "surveyQuestionAnswers": [
  //     {
  //       "id": 840,
  //       "surveyQuestionId": 140,
  //       "textAnswer": "5",
  //       "comment": "null"
  //     },
  //     {
  //       "id": 840,
  //       "surveyQuestionId": 141,
  //       "textAnswer": "7",
  //       "comment": "null"
  //     },
  //     {
  //       "id": 840,
  //       "surveyQuestionId": 142,
  //       "textAnswer": "5",
  //       "comment": "null"
  //     },
  //     {
  //       "id": 840,
  //       "surveyQuestionId": 143,
  //       "textAnswer": "7",
  //       "comment": "null"
  //     }
  //   ],
  //   "startedDateTime": moment().subtract(3, 'days').toDate(),
  //   "startedDateTimeSeconds": 0,
  //   "completedDateTime": moment().subtract(2, 'days').toDate(),
  //   "officeLocation": {
  //     "id": 725,
  //     "name": "Fairmont, USA",
  //     "officeAddress": {
  //       "id": 3883,
  //       "street": "Dame lane",
  //       "city": "USA",
  //       "district": "",
  //       "postalCode": "",
  //       "country": "United States",
  //       timezone: 'Europe/Dublin'
  //     }
  //   },
  //   "organisationalUnit": {
  //     "id": 298,
  //     "level": 0,
  //     "parentOrg": {
  //       "id": 90,
  //       "level": 0,
  //       "parentOrg": null,
  //       "organisationalUnitType": {
  //         "id": 2,
  //         "name": "Department"
  //       },
  //       "managerUserId": null,
  //       "feedbackUserId": null,
  //       "name": "Leadership"
  //     },
  //     "organisationalUnitType": {
  //       "id": 2,
  //       "name": "Department"
  //     },
  //     "managerUserId": null,
  //     "feedbackUserId": null,
  //     "name": "Interior Design Team"
  //   }
  // },
];