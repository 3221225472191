import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { MockApiService } from '@app/mock/api/mock-api.service';
import { delay, dematerialize, materialize, mergeMap } from 'rxjs/operators';
import { sanitizeUrl } from '@app/shared/utils/helpers';
import { environment } from 'environments/environment';

@Injectable()
export class HttpMockApiInterceptor implements HttpInterceptor {
    constructor(private readonly mockApi: MockApiService) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const url = req.urlWithParams;
        const sanitizedUrl = sanitizeUrl(url); // Remove trailing slashes from URL
        const splitPath = sanitizedUrl.split(/[\/?]+/); // Splits path using forward slash and question mark
        const rootPath = splitPath[0] === 'api' ? splitPath[1] : splitPath[0]; // Skip over API prefix if it exists for this URL

        // Check if route is allowed to contact server
        if (this.mockApi.allowedRoutes.includes(sanitizedUrl)) {
            const modifiedRequest = req.clone({
                url: `${environment.mock.apiUrl}/${req.url}`
            });
            return next.handle(modifiedRequest)
        }

        const mockService = this.mockApi.mockServices[rootPath];

        const handleRequest = (): Observable<HttpEvent<any>> => {
            // Note that we keep the thrown error user-friendly as that is what gets displayed on the UI
            if (!mockService) {
                console.error('No mock service exists for root path: ' + rootPath);
                return MockApiService.error('You\'re interacting with a read-only version of Frankli', 'Unable to perform this action on the demo version of Frankli');
            }
            const body = mockService.handleRoute(req);
            if (body === undefined) {
                console.error('No Op for request: ' + req.method + ' ' + sanitizedUrl);
                return MockApiService.error('You\'re interacting with a read-only version of Frankli', 'Unable to perform this action on the demo version of Frankli');
            }

            // For use with debugging only
            // console.debug({
            //     method: req.method,
            //     sanitizedUrl: sanitizedUrl,
            //     rootPath: rootPath,
            //     responseBody: body
            // });

            return MockApiService.ok(body);
        };

        return of(null)
            .pipe(mergeMap(handleRequest))
            .pipe(materialize())
            .pipe(delay(100)) // This delay simulates a HTTP request delay.
            .pipe(dematerialize());
    }
}