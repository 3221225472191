<div class="tag-picker-multiple-container">
  <ng-container *ngIf="value">
    <ng-container *ngFor="let tag of value">
      <ng-container [ngTemplateOutlet]="tagPillTemplate" [ngTemplateOutletContext]="{ tag: tag, icon: 'fa-times', iconColor: '#FB946E', cursor: 'pointer', action: 'remove'}"></ng-container>
    </ng-container>
  </ng-container>
  <div class="search-input" [class.no-selection]="(!value || (value.length === 0))">
    <input type="text" class="form-control" [formControl]="searchControl" [class.input-no-results]="(searchResults.length === 0) && searchControl.value && !searchRunning" #searchInput/>
    <div class="search-results">
      <ng-container *ngFor="let result of searchResults">
        <div class="search-results-item">
          <ng-container [ngTemplateOutlet]="tagPillTemplate" [ngTemplateOutletContext]="{ tag: result, icon: 'fa-plus', iconColor: '#149189', cursor: 'pointer', action: 'add'}"></ng-container>
        </div>
      </ng-container>
    </div>
  </div>
  <span class="search-icon">
    <span class="fal fa-fw fa-search"></span>
  </span>
</div>

<ng-template let-tag="tag" let-icon="icon" let-iconColor="iconColor" let-cursor="cursor" let-action="action" #tagPillTemplate>
  <div class="tag-item" [style.cursor]="cursor" (click)="onClickTag(tag, action)">
    <div class="row m-left-0 m-right-0">
      <div class="tag-details-container">
        <div>{{tag.text}}</div>
        <div class="tag-role">
          <span>{{eTagMessages.TYPE[tag.type]}}</span>
        </div>
      </div>
      <div class="tag-icon-container">
        <span class="fal fa-fw" [ngClass]="icon" [style.color]="iconColor"></span>
      </div>
    </div>
  </div>
</ng-template>