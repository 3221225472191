import { SortingParams } from '@app/models/api/sorting-params.model';
import { PagingParams } from '@app/models/api/paging-params.model';
import { SortDirection } from '@app/models/api/sort-direction.enum';

export const staticUserSortingParams: SortingParams = {
  sortAttributes: ['firstName', 'lastName'],
  sortDirection: SortDirection.ASC
}

export const staticUserPagingParams: PagingParams = {
  pageSize: 15,
  pageNumber: 0
}