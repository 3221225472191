import { Injectable } from '@angular/core';
import { ILongRunningTaskAPI } from './long-running-task-api.model';
import { HttpClient } from '@angular/common/http';
import { ILongRunningTaskServerside, LongRunningTask } from '../model/long-running-task.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class LongRunningTaskAPIService implements ILongRunningTaskAPI {

  public readonly BASE_URL = 'api/long-running-task';

  constructor(
    private http: HttpClient
  ) { }

  get(taskId: number): Observable<LongRunningTask> {
    const url = `${this.BASE_URL}/${taskId}`;
    return this.http.get<ILongRunningTaskServerside>(url).pipe(map(t => new LongRunningTask(t)));
  }

  getInProgress(): Observable<LongRunningTask[]> {
    const url = `${this.BASE_URL}/in-progress`;
    return this.http.get<ILongRunningTaskServerside[]>(url).pipe(map(tasks => tasks.map(t => new LongRunningTask(t))));
  }
  
  dismiss(taskId: number): Observable<LongRunningTask> {
    const url = `${this.BASE_URL}/${taskId}/dismiss`;
    return this.http.put<ILongRunningTaskServerside>(url, null).pipe(map(t => new LongRunningTask(t)));
  }

  retry(eventId: number): Observable<LongRunningTask> {
    const url = `${this.BASE_URL}/event/${eventId}/retry`;
    return this.http.post<ILongRunningTaskServerside>(url, null).pipe(map(t => new LongRunningTask(t)));
  }

}
