export class GoalsMessages {
  public static readonly GOALS = 'goals.goals';
  public static readonly PERSONAL_DEVELOPMENTAL = 'goals.personal-developmental';
  public static readonly PERSONAL_OPERATIONAL = 'goals.personal-operational';
  public static readonly OFF_TRACK = 'goals.off-track';
  public static readonly ON_TRACK = 'goals.on-track';
  public static readonly PROGRESSING = 'goals.progressing';
  public static readonly CREATE_A_GOAL = 'goals.create-a-goal';
  public static readonly USE_A_TEMPLATE = 'goals.use-a-template';
  public static readonly COPY_A_GOAL = 'goals.copy-a-goal';
  public static readonly CLASSIC_VIEW = 'goals.classic-view';
  public static readonly GOAL_ADMIN = 'goals.goal-admin';
  public static readonly GOAL_ADMIN_TOOLTIP = 'goals.goal-admin-tooltip';
  public static readonly COMPANY_GOALS = 'goals.company-goals';
  public static readonly COMPANY_GOALS_TOOLTIP = 'goals.company-goals-tooltip';
  public static readonly DRAFT_GOALS = 'goals.draft-goals';
  public static readonly DRAFT_GOALS_TOOLTIP = 'goals.draft-goals-tooltip';
  public static readonly MY_GOALS = 'goals.my-goals';
  public static readonly MY_GOALS_TOOLTIP = 'goals.my-goals-tooltip';
  public static readonly SITE_GOAL = 'goals.site-goal';
  public static readonly SITE_GOAL_PLURAL = 'goals.site-goal-plural';
  public static readonly SITE_GOALS_TOOLTIP = 'goals.site-goals-tooltip';
  public static readonly PERSONAL_GOALS = 'goals.personal-goals';
  public static readonly PERSONAL_GOALS_TOOLTIP = 'goals.personal-goals-tooltip';
  public static readonly GOAL_TAGS = 'goals.goal-tags';
  public static readonly GOAL_TAGS_TOOLTIP = 'goals.goal-tags-tooltip';
  public static readonly ACTIVE_GOALS = 'goals.active-goals';
  public static readonly COMPLETE = 'goals.complete';
  public static readonly INCOMPLETE = 'goals.incomplete';
  public static readonly DUE_IN_THE_NEXT_30_DAYS = 'goals.due-in-the-next-30-days';
  public static readonly COMPLETED_IN_THE_PAST_3_MONTHS = 'goals.completed-in-the-past-3-months';
  public static readonly GOAL_TYPE = 'goals.goal-type';
  public static readonly TEAM_GOAL = 'goals.team-goal';
  public static readonly TEAM_GOAL_PLURAL = 'goals.team-goal-plural';
  public static readonly TEAM_GOALS_TOOLTIP = 'goals.team-goals-tooltip';
  public static readonly DEPARTMENT_GOAL = 'goals.department-goal';
  public static readonly DEPARTMENT_GOALS = 'goals.department-goal-plural';
  public static readonly DEPARTMENT_GOALS_TOOLTIP = 'goals.department-goals-tooltip';
  public static readonly OWNERS = 'goals.owners';
  public static readonly TYPE = 'goals.type';
  public static readonly TITLE = 'goals.title';
  public static readonly PRIORITY = 'goals.priority';
  public static readonly KEY_RESULT = 'goals.key-result';
  public static readonly KEY_RESULTS = 'goals.key-results';
  public static readonly KEY_RESULTS_COUNT = 'goals.key-results-count';
  public static readonly PROGRESS = 'goals.progress';
  public static readonly DUE_DATE = 'goals.due-date';
  public static readonly STATUS = 'goals.status';
  public static readonly CURRENT_STATUS = 'goals.current-status';
  public static readonly TAGS = 'goals.tags.tag-plural';
  public static readonly VISIBILITY = 'goals.visibility';
  public static readonly ARCHIVED = 'goals.archived';
  public static readonly COMPLETED = 'goals.completed';
  public static readonly PRIORITY_P1 = 'goals.priority-levels.p1';
  public static readonly PRIORITY_P1_TEXT = 'goals.priority-levels.p1-text';
  public static readonly PRIORITY_P2 = 'goals.priority-levels.p2';
  public static readonly PRIORITY_P2_TEXT = 'goals.priority-levels.p2-text';
  public static readonly PRIORITY_P3 = 'goals.priority-levels.p3';
  public static readonly PRIORITY_P3_TEXT = 'goals.priority-levels.p3-text';
  public static readonly PRIORITY_P4 = 'goals.priority-levels.p4';
  public static readonly PRIORITY_P4_TEXT = 'goals.priority-levels.p4-text';
  public static readonly PRIORITY_P5 = 'goals.priority-levels.p5';
  public static readonly PRIORITY_P5_TEXT = 'goals.priority-levels.p5-text';
  public static readonly PUBLIC = 'goals.public';
  public static readonly PRIVATE = 'goals.private';
  public static readonly MEASURE_TYPE = 'goals.measure-type';
  public static readonly UNIT = 'goals.unit';
  public static readonly UNIT_PLACEMENT = 'goals.unit-placement';
  public static readonly COMPLETE_INCOMPLETE = 'goals.complete-incomplete';
  public static readonly NUMERIC = 'goals.numeric';
  public static readonly BEFORE = 'goals.before';
  public static readonly AFTER = 'goals.after';
  public static readonly ALIGNED_PARENT = 'goals.aligned-parent';
  public static readonly ALIGNED_CHILDREN = 'goals.aligned-children';
  public static readonly START_EDITING_TOOLTIP = 'goals.start-editing-tooltip';
  public static readonly ARCHIVE_GOAL = 'goals.archive-goal';
  public static readonly ARCHIVE_TOOLTIP = 'goals.archive-tooltip';
  public static readonly UNARCHIVE_TOOLTIP = 'goals.unarchive-tooltip';
  public static readonly DELETE_TOOLTIP = 'goals.delete-tooltip';
  public static readonly OBJECTIVE = 'goals.objective';
  public static readonly DEVELOPMENT_NEEDS = 'goals.development-needs';
  public static readonly DEVELOPMENT_NEEDS_PLACEHOLDER = 'goals.development-needs-placeholder';
  public static readonly TASKS = 'goals.tasks';
  public static readonly START_VALUE = 'goals.start-value';
  public static readonly CURRENT_VALUE = 'goals.current-value';
  public static readonly GOAL_VALUE = 'goals.goal-value';
  public static readonly GOAL_PROGRESS_PLACEHOLDER = 'goals.goal-progress-placeholder';
  public static readonly UPDATE_PROGRESS = 'goals.update-progress';
  public static readonly NO_TASKS_FOR_GOAL_OWNER = 'goals.no-tasks-for-goal-owner';
  public static readonly NO_TASKS_FOR_GOAL = 'goals.no-tasks-for-goal';
  public static readonly NO_ALIGNMENT = 'goals.no-alignment';
  public static readonly INSERT_KEY_RESULT = 'goals.insert-key-result';
  public static readonly SEARCH_FOR_GOAL_BY_OBJECTIVE = 'goals.search-for-goal-by-objective';
  public static readonly SEARCH_FOR_GOAL_BY_OBJECTIVE_OR_SLASH = 'goals.search-for-goal-by-objective-or-slash';

  // Goal activity
  public static readonly ACTIVITY_HISTORY = 'goals.activity.heading';
  public static readonly ACTIVITY_COMMENT_LABEL = 'goals.activity.comment-label';
  public static readonly ACTIVITY_COMMENT_SUBMIT_PROMPT = 'goals.activity.comment-submit-prompt';
  public static readonly ACTIVITY_COMMENT_SUBMITTING = 'goals.activity.comment-submitting';
  public static readonly ACTIVITY_COMMENT_DELETE_PROMPT = 'goals.activity.comment-delete-prompt';
  public static readonly ACTIVITY_COMMENT_DELETING = 'goals.activity.comment-deleting';
  public static readonly ACTIVITY_MESSAGE_ARCHIVED_GOAL = 'goals.activity.message-archived-goal';
  public static readonly ACTIVITY_MESSAGE_UNARCHIVED_GOAL = 'goals.activity.message-unarchived-goal';
  public static readonly ACTIVITY_MESSAGE_COMPLETED_GOAL = 'goals.activity.message-completed-goal';
  public static readonly ACTIVITY_MESSAGE_UNCOMPLETED_GOAL = 'goals.activity.message-uncompleted-goal';
  public static readonly ACTIVITY_MESSAGE_CREATED_GOAL = 'goals.activity.message-created-goal';
  public static readonly ACTIVITY_MESSAGE_EDITED_GOAL = 'goals.activity.message-edited-goal';
  public static readonly ACTIVITY_MESSAGE_COMMENTED_ON_GOAL = 'goals.activity.message-commented-on-goal';
  public static readonly ACTIVITY_MESSAGE_PROGRESSED_KEY_RESULTS = 'goals.activity.message-progressed-key-results';
  public static readonly ACTIVITY_MESSAGE_PROGRESS_UPDATE = 'goals.activity.message-progress-update';
  public static readonly ACTIVITY_MESSAGE_STATUS_UPDATE = 'goals.activity.message-status-update';
  public static readonly ACTIVITY_EDITED_OBJECTIVE = 'goals.activity.edited-objective';
  public static readonly ACTIVITY_EDITED_TYPE = 'goals.activity.edited-type';
  public static readonly ACTIVITY_EDITED_DEVELOPMENT_NEEDS = 'goals.activity.edited-development-needs';
  public static readonly ACTIVITY_EDITED_DUE_DATE = 'goals.activity.edited-due-date';
  public static readonly ACTIVITY_EDITED_VISIBILITY = 'goals.activity.edited-visibility';
  public static readonly ACTIVITY_EDITED_OFFICE_LOCATION = 'goals.activity.edited-office-location';
  public static readonly ACTIVITY_EDITED_PRIORITY = 'goals.activity.edited-priority';
  public static readonly ACTIVITY_EDITED_OWNERS_ADDED = 'goals.activity.edited-owners-added';
  public static readonly ACTIVITY_EDITED_OWNERS_REMOVED = 'goals.activity.edited-owners-removed';
  public static readonly ACTIVITY_EDITED_TAGS_REMOVED = 'goals.activity.edited-tags-removed';
  public static readonly ACTIVITY_EDITED_TAGS_ADDED = 'goals.activity.edited-tags-added';
  public static readonly ACTIVITY_EDITED_ALIGNMENT = 'goals.activity.edited-alignment';
  public static readonly ACTIVITY_EDITED_RESULTS_ADDED = 'goals.activity.edited-results-added';
  public static readonly ACTIVITY_EDITED_RESULTS_REMOVED = 'goals.activity.edited-results-removed';
  public static readonly ACTIVITY_EDITED_RESULT_RESULT = 'goals.activity.edited-result-result';
  public static readonly ACTIVITY_EDITED_RESULT_TYPE = 'goals.activity.edited-result-type';
  public static readonly ACTIVITY_EDITED_RESULT_TYPE_INCLUDE_RESULT = 'goals.activity.edited-result-type-include-result';
  public static readonly ACTIVITY_EDITED_RESULT_CURRENT_VALUE = 'goals.activity.edited-result-current-value';
  public static readonly ACTIVITY_EDITED_RESULT_CURRENT_VALUE_INCLUDE_RESULT = 'goals.activity.edited-result-current-value-include-result';
  public static readonly ACTIVITY_EDITED_RESULT_GOAL_VALUE = 'goals.activity.edited-result-goal-value';
  public static readonly ACTIVITY_EDITED_RESULT_GOAL_VALUE_INCLUDE_RESULT = 'goals.activity.edited-result-goal-value-include-result';
  public static readonly ACTIVITY_EDITED_RESULT_DUE_DATE = 'goals.activity.edited-result-due-date';
  public static readonly ACTIVITY_EDITED_RESULT_DUE_DATE_INCLUDE_RESULT = 'goals.activity.edited-result-due-date-include-result';
  public static readonly ACTIVITY_EDITED_RESULT_START_VALUE = 'goals.activity.edited-result-start-value';
  public static readonly ACTIVITY_EDITED_RESULT_START_VALUE_INCLUDE_RESULT = 'goals.activity.edited-result-start-value-include-result';
  public static readonly ACTIVITY_EDITED_RESULT_UNIT = 'goals.activity.edited-result-unit';
  public static readonly ACTIVITY_EDITED_RESULT_UNIT_INCLUDE_RESULT = 'goals.activity.edited-result-unit-include-result';
  public static readonly ACTIVITY_EDITED_RESULT_UNIT_PLACEMENT = 'goals.activity.edited-result-unit-placement';
  public static readonly ACTIVITY_EDITED_RESULT_UNIT_PLACEMENT_INCLUDE_RESULT = 'goals.activity.edited-result-unit-placement-include-result';
  public static readonly TAG_NAME = 'goals.tags.name';
  public static readonly TAG_DESCRIPTION = 'goals.tags.description';
  public static readonly TAG_NUMBER_OF_GOALS = 'goals.tags.number-of-goals';
  public static readonly TAG_SEARCH_PROMPT = 'goals.tags.search-prompt';
  public static readonly TAG_EMPTY_STATE_MESSAGE = 'goals.tags.empty-state-message';
  public static readonly EDIT_DRAFT_GOAL = 'goals.edit-draft-goal';
  public static readonly DELETE_DRAFT_GOAL = 'goals.delete-draft-goal';
  public static readonly TEMPLATES = 'goals.templates';
  public static readonly GOAL_TIPS = 'goals.goal-tips';
  public static readonly CREATE_A_TEMPLATE = 'goals.create-a-template';
  public static readonly COMPLETE_GOAL = 'goals.complete-goal';
  public static readonly UNARCHIVE_GOAL = 'goals.unarchive-goal';
  public static readonly DELETE_GOAL = 'goals.delete-goal';
  public static readonly ACTIVATE_GOAL = 'goals.activate-goal';

  public static readonly GOAL_CREATE_TYPE_TITLE = 'goals.goal-create.type-title';
  public static readonly GOAL_CREATE_TYPE_DESCRIPTION = 'goals.goal-create.type-description';
  public static readonly GOAL_CREATE_TYPE_VALIDATION_REQUIRED = 'goals.goal-create.type-validation-required';

  public static readonly GOAL_CREATE_DETAILS_TITLE = 'goals.goal-create.details-title';
  public static readonly GOAL_CREATE_DETAILS_DESCRIPTION = 'goals.goal-create.details-description';
  public static readonly GOAL_CREATE_DETAILS_VALIDATION_OBJECTIVE_REQUIRED = 'goals.goal-create.details-validation-objective-required';
  public static readonly GOAL_CREATE_DETAILS_VALIDATION_DUE_DATE_REQUIRED = 'goals.goal-create.details-validation-due-date-required';
  public static readonly GOAL_CREATE_DETAILS_VALIDATION_VISIBILITY_REQUIRED = 'goals.goal-create.details-validation-visibility-required';
  public static readonly GOAL_CREATE_DETAILS_EXPLANATION_VISIBILTIY_PUBLIC = 'goals.goal-create.details-explanation-visibility-public';
  public static readonly GOAL_CREATE_DETAILS_EXPLANATION_VISIBILTIY_PRIVATE = 'goals.goal-create.details-explanation-visibility-private';
  public static readonly GOAL_CREATE_DETAILS_EMPTY_STATE_TITLE = 'goals.goal-create.details-empty-state-title';
  public static readonly GOAL_CREATE_DETAILS_EMPTY_STATE_DUE_DATE = 'goals.goal-create.details-empty-state-due-date';

  public static readonly GOAL_CREATE_DEVELOPMENT_NEEDS_TITLE = 'goals.goal-create.development-needs-title';
  public static readonly GOAL_CREATE_DEVELOPMENT_NEEDS_DESCRIPTION = 'goals.goal-create.development-needs-description';
  public static readonly GOAL_CREATE_DEVELOPMENT_NEEDS_PLACEHOLDER = 'goals.goal-create.development-needs-placeholder';

  public static readonly GOAL_CREATE_SITE_TITLE = 'goals.goal-create.site-title';
  public static readonly GOAL_CREATE_SITE_DESCRIPTION = 'goals.goal-create.site-description';
  public static readonly GOAL_CREATE_SITE_VALIDATION_REQUIRED = 'goals.goal-create.site-validation-required';
  public static readonly GOAL_CREATE_SITE_EMPTY_STATE = 'goals.goal-create.site-empty-state';

  public static readonly GOAL_CREATE_DEPARTMENT_TITLE = 'goals.goal-create.department-title';
  public static readonly GOAL_CREATE_DEPARTMENT_DESCRIPTION = 'goals.goal-create.department-description';
  public static readonly GOAL_CREATE_DEPARTMENT_VALIDATION_REQUIRED = 'goals.goal-create.department-validation-required';
  public static readonly GOAL_CREATE_DEPARTMENT_EMPTY_STATE = 'goals.goal-create.department-empty-state';

  public static readonly GOAL_CREATE_KEY_RESULTS_TITLE = 'goals.goal-create.key-results-title';
  public static readonly GOAL_CREATE_KEY_RESULTS_DESCRIPTION = 'goals.goal-create.key-results-description';
  public static readonly GOAL_CREATE_KEY_RESULTS_COUNT_SINGLE = 'goals.goal-create.key-results-count-single';
  public static readonly GOAL_CREATE_KEY_RESULTS_COUNT_MULTIPLE = 'goals.goal-create.key-results-count-multiple';
  public static readonly GOAL_CREATE_KEY_RESULTS_VALIDATION_MINIMUM = 'goals.goal-create.key-results-validation-minimum';
  public static readonly GOAL_CREATE_KEY_RESULTS_VALIDATION_RESULT_REQUIRED = 'goals.goal-create.key-results-validation-result-required';
  public static readonly GOAL_CREATE_KEY_RESULTS_VALIDATION_DUE_DATE_MAX = 'goals.goal-create.key-results-validation-due-date-max';
  public static readonly GOAL_CREATE_KEY_RESULTS_VALIDATION_MEASURE_TYPE_REQUIRED = 'goals.goal-create.key-results-validation-measure-type-required';
  public static readonly GOAL_CREATE_KEY_RESULTS_VALIDATION_CURRENT_VALUE_REQUIRED = 'goals.goal-create.key-results-validation-current-value-required';
  public static readonly GOAL_CREATE_KEY_RESULTS_VALIDATION_CURRENT_VALUE_FORBIDDEN_CHARACTER = 'goals.goal-create.key-results-validation-current-value-forbidden-character';
  public static readonly GOAL_CREATE_KEY_RESULTS_VALIDATION_START_VALUE_REQUIRED = 'goals.goal-create.key-results-validation-start-value-required';
  public static readonly GOAL_CREATE_KEY_RESULTS_VALIDATION_START_VALUE_FORBIDDEN_CHARACTER = 'goals.goal-create.key-results-validation-start-value-forbidden-character';
  public static readonly GOAL_CREATE_KEY_RESULTS_VALIDATION_GOAL_VALUE_REQUIRED = 'goals.goal-create.key-results-validation-goal-value-required';
  public static readonly GOAL_CREATE_KEY_RESULTS_VALIDATION_GOAL_VALUE_FORBIDDEN_CHARACTER = 'goals.goal-create.key-results-validation-goal-value-forbidden-character';
  public static readonly GOAL_CREATE_KEY_RESULTS_VALIDATION_START_EQUALS_GOAL = 'goals.goal-create.key-results-validation-start-equals-goal';
  public static readonly GOAL_CREATE_KEY_RESULTS_VALIDATION_UNIT_PLACEMENT_REQUIRED = 'goals.goal-create.key-results-validation-unit-placement-required';
  public static readonly GOAL_CREATE_KEY_RESULTS_EXPLANATION_COMPLETE_INCOMPLETE = 'goals.goal-create.key-results-explanation-complete-incomplete';
  public static readonly GOAL_CREATE_KEY_RESULTS_EXPLANATION_NUMERIC = 'goals.goal-create.key-results-explanation-numeric';
  public static readonly GOAL_CREATE_KEY_RESULTS_EXPLANATION_GENERAL = 'goals.goal-create.key-results-explanation-general';
  public static readonly GOAL_CREATE_KEY_RESULTS_ADD_KEY_RESULT = 'goals.goal-create.key-results-add-key-result';
  public static readonly GOAL_CREATE_KEY_RESULTS_REMOVE_KEY_RESULT = 'goals.goal-create.key-results-remove-key-result';
  public static readonly GOAL_CREATE_KEY_RESULTS_EMPTY_STATE = 'goals.goal-create.key-results-empty-state';

  public static readonly GOAL_CREATE_OWNERS_TITLE = 'goals.goal-create.owners-title';
  public static readonly GOAL_CREATE_OWNERS_AND_DISTRIBUTION_TITLE = 'goals.goal-create.owners-and-distribution-title';
  public static readonly GOAL_CREATE_OWNERS_DESCRIPTION = 'goals.goal-create.owners-description';
  public static readonly GOAL_CREATE_OWNERS_SECOND_DESCRIPTION = 'goals.goal-create.owners-second-description';
  public static readonly GOAL_CREATE_OWNERS_COUNT_SINGLE = 'goals.goal-create.owners-count-single';
  public static readonly GOAL_CREATE_OWNERS_COUNT_MULTIPLE = 'goals.goal-create.owners-count-multiple';
  public static readonly GOAL_CREATE_OWNERS_COUNT_NONE = 'goals.goal-create.owners-count-none';
  public static readonly GOAL_CREATE_OWNERS_VALIDATION_MINIMUM = 'goals.goal-create.owners-validation-minimum';

  public static readonly GOAL_CREATE_DISTRIBUTION_TITLE = 'goals.goal-create.distribution-title';
  public static readonly GOAL_CREATE_DISTRIBUTION_DESCRIPTION = 'goals.goal-create.distribution-description';
  public static readonly GOAL_CREATE_DISTRIBUTION_EXPLANATION = 'goals.goal-create.distribution-explanation';
  public static readonly GOAL_CREATE_DISTRIBUTION_NONE = 'goals.goal-create.distribution-none';
  public static readonly GOAL_CREATE_DISTRIBUTION_OWNERS = 'goals.goal-create.distribution-owners';
  public static readonly GOAL_CREATE_DISTRIBUTION_COMPANY = 'goals.goal-create.distribution-company';
  public static readonly GOAL_CREATE_DISTRIBUTION_EXPLANATION_NONE = 'goals.goal-create.distribution-explanation-none';
  public static readonly GOAL_CREATE_DISTRIBUTION_EXPLANATION_OWNERS = 'goals.goal-create.distribution-explanation-owners';
  public static readonly GOAL_CREATE_DISTRIBUTION_EXPLANATION_COMPANY = 'goals.goal-create.distribution-explanation-company';

  public static readonly GOAL_CREATE_ALIGNMENT_TITLE = 'goals.goal-create.alignment-title';
  public static readonly GOAL_CREATE_ALIGNMENT_DESCRIPTION = 'goals.goal-create.alignment-description';
  public static readonly GOAL_CREATE_ALIGNMENT_EXPLANATION_PERSONAL_DEVELOPMENTAL = 'goals.goal-create.alignment-explanation-personal-developmental';
  public static readonly GOAL_CREATE_ALIGNMENT_EXPLANATION_PERSONAL_OPERATIONAL = 'goals.goal-create.alignment-explanation-personal-operational';
  public static readonly GOAL_CREATE_ALIGNMENT_EXPLANATION_DEPARTMENT = 'goals.goal-create.alignment-explanation-department';
  public static readonly GOAL_CREATE_ALIGNMENT_EXPLANATION_COMPANY = 'goals.goal-create.alignment-explanation-company';
  public static readonly GOAL_CREATE_ALIGNMENT_EXPLANATION_OFFICE_LOCATION = 'goals.goal-create.alignment-explanation-office-location';
  public static readonly GOAL_CREATE_ALIGNMENT_EXPLANATION_TEAM = 'goals.goal-create.alignment-explanation-team';

  public static readonly GOAL_CREATE_PRIORITY_TITLE = 'goals.goal-create.priority-title';
  public static readonly GOAL_CREATE_PRIORITY_DESCRIPTION = 'goals.goal-create.priority-description';

  public static readonly GOAL_CREATE_TAGS_TITLE = 'goals.goal-create.tags-title';
  public static readonly GOAL_CREATE_TAGS_DESCRIPTION = 'goals.goal-create.tags-description';
  public static readonly GOAL_CREATE_TAGS_COUNT_SINGLE = 'goals.goal-create.tags-count-single';
  public static readonly GOAL_CREATE_TAGS_COUNT_MULTIPLE = 'goals.goal-create.tags-count-multiple';

  public static readonly GOAL_CREATE_TASKS_TITLE = 'goals.goal-create.tasks-title';
  public static readonly GOAL_CREATE_TASKS_DESCRIPTION = 'goals.goal-create.tasks-description';
  public static readonly GOAL_CREATE_TASKS_COUNT_SINGLE = 'goals.goal-create.tasks-count-single';
  public static readonly GOAL_CREATE_TASKS_COUNT_MULTIPLE = 'goals.goal-create.tasks-count-multiple';
  public static readonly GOAL_CREATE_TASKS_ADD_TASK = 'goals.goal-create.tasks-add-task';
  public static readonly GOAL_CREATE_TASKS_EMPTY_STATE = 'goals.goal-create.tasks-empty-state';
  public static readonly GOAL_CREATE_TASKS_VALIDATION_DUE_DATE_MAX = 'goals.goal-create.tasks-validation-due-date-max';

  public static readonly GOAL_CREATE_TEMPLATES_TITLE = 'goals.goal-create.templates-title';
  public static readonly GOAL_CREATE_TEMPLATES_DESCRIPTION = 'goals.goal-create.templates-description';
  public static readonly GOAL_CREATE_TEMPLATES_EMPTY_STATE = 'goals.goal-create.templates-empty-state';
  public static readonly GOAL_CREATE_TEMPLATES_EMPTY_STATE_SEARCH = 'goals.goal-create.templates-empty-state-search';
    
  public static readonly GOAL_CREATE_COPYING_TITLE = 'goals.goal-create.copying-title';
  public static readonly GOAL_CREATE_COPYING_DESCRIPTION = 'goals.goal-create.copying-description';
  public static readonly GOAL_CREATE_COPYING_EMPTY_STATE = 'goals.goal-create.copying-empty-state';
  public static readonly GOAL_CREATE_COPYING_EMPTY_STATE_SEARCH = 'goals.goal-create.copying-empty-state-search';

  public static readonly TEMPLATE_EXPLANATION_RESULT_TYPE_COMPLETE_INCOMPLETE = 'goals.template-explanation-result-type-complete-incomplete';
  public static readonly TEMPLATE_EXPLANATION_RESULT_TYPE_NUMERIC = 'goals.template-explanation-result-type-numeric';
  public static readonly TEMPLATE_EXPLANATION_RESULT_UNIT_BEFORE = 'goals.template-explanation-result-unit-before'; // Params: unit
  public static readonly TEMPLATE_EXPLANATION_RESULT_UNIT_AFTER = 'goals.template-explanation-result-unit-after'; // Params: unit
  public static readonly USE_TEMPLATE = 'goals.use-template';

  public static readonly EDIT_KEY_RESULT_WARNING = 'goals.edit-key-result-warning';
  public static readonly EDIT_KEY_RESULT_UPDATE_PROMPT = 'goals.edit-key-result-update-prompt';

  public static readonly SUCCESS_COMMENT_ADD = 'goals.success-comment-add';
  public static readonly SUCCESS_COMMENT_DELETE = 'goals.success-comment-delete';
  public static readonly SUCCESS_GOAL_UPDATE = 'goals.success-goal-update';

  public static readonly WARNING_GOAL_MARKED_COMPLETE = 'goals.warning-goal-marked-complete';
  public static readonly WARNING_GOAL_NOT_MARKED_COMPLETE = 'goals.warning-goal-not-marked-complete';
  public static readonly SUCCESS_GOAL_MARKED_COMPLETE = 'goals.success-goal-marked-complete';

  public static readonly WARNING_GOAL_ARCHIVE = 'goals.warning-goal-archived';
  public static readonly WARNING_GOAL_NOT_ARCHIVE = 'goals.warning-goal-not-archived';
  public static readonly WARNING_GOAL_ARCHIVE_MULTIPLE_OWNERS = 'goals.warning-goal-archived-multiple-owners';
  public static readonly SUCCESS_GOAL_ARCHIVE = 'goals.success-goal-archived';

  public static readonly WARNING_GOAL_UNARCHIVE = 'goals.warning-goal-unarchived';
  public static readonly WARNING_GOAL_NOT_UNARCHIVE = 'goals.warning-goal-not-unarchived';
  public static readonly SUCCESS_GOAL_UNARCHIVE = 'goals.success-goal-unarchived';

  public static readonly WARNING_GOAL_DELETE = 'goals.warning-goal-deleted';
  public static readonly WARNING_GOAL_NOT_DELETE = 'goals.warning-goal-not-deleted';
  public static readonly WARNING_GOAL_DELETE_MULTIPLE_OWNERS = 'goals.warning-goal-deleted';
  public static readonly WARNING_GOAL_DELETE_ALIGNED_CHILDREN = 'goals.warning-goal-deleted-aligned-children';
  public static readonly SUCCESS_GOAL_DELETE = 'goals.success-goal-deleted';

  public static readonly WARNING_GOAL_ACTIVATE = 'goals.warning-goal-activated';
  public static readonly WARNING_GOAL_NOT_ACTIVATE = 'goals.warning-goal-not-activated';
  public static readonly SUCCESS_GOAL_ACTIVATE = 'goals.success-goal-activated';

  public static readonly GOAL_COUNT = 'goals.goal-count';
  public static readonly ACTIVE_GOAL_COUNT = 'goals.active-goal-count';
  public static readonly COMPLETE_GOAL_COUNT = 'goals.complete-goal-count';
  public static readonly TOTAL_OBJECTIVES = 'goals.total-objectives';
  public static readonly AVERAGE_COMPLETION = 'goals.average-completion';
  public static readonly VIEW_AS_ADMIN = 'goals.view-as-admin';
  public static readonly VIEW_USERS_GOALS = 'goals.view-users-goals';
  public static readonly PERSON_HAS_NO_GOALS = 'goals.person-has-no-goals';
  public static readonly TEAM_GOALS_EMPTY_STATE = 'goals.team-goals-empty-state';
  public static readonly USERS_GOALS = 'goals.users-goals';

  public static readonly TOTAL_GOALS = 'goals.total-goals';
  public static readonly COMPLETE_GOALS = 'goals.complete-goals';

  public static readonly EMPTY_STATE_SEARCH = 'goals.empty-state-search';
  public static readonly EMPTY_STATE_TAG_GOALS = 'goals.empty-state-tag-goals';

  public static readonly SUCCESS_GOAL_CREATED = 'goals.success-goal-created';
  public static readonly SUCCESS_GOAL_CREATED_MULTIPLE = 'goals.success-goal-created-multiple';
  public static readonly SUCCESS_GOAL_DRAFT_CREATED = 'goals.success-goal-draft-created';
  public static readonly SUCCESS_GOAL_DRAFT_UPDATED = 'goals.success-goal-draft-updated';

  public static readonly ACTIVATE = 'goals.activate';
  public static readonly ACTIVATE_TOOLTIP = 'goals.activate-tooltip';

  public static readonly CANNOT_CHANGE_WHILE_ALIGNED_CHILDREN = 'goals.cannot-change-while-aligned-children';

  public static readonly VIEW_GOAL = 'goals.view-goal';

  public static readonly MANAGE_GOAL_ADMINS = 'goals.manage-goal-admins';
  public static readonly MANAGE_GOAL_ADMINS_DESCRIPTION = 'goals.manage-goal-admins-description';
  public static readonly MANAGE_GOAL_ADMINS_DESCRIPTION_SHORT = 'goals.manage-goal-admins-description-short';
  public static readonly CURRENT_GOAL_ADMINS = 'goals.current-goal-admins';
  public static readonly MANAGE_GOAL_ADMINS_SUCCESS = 'goals.manage-goal-admins-success';
  public static readonly VISIBILITY_SET_TO_PRIVATE = 'goals.visibility-set-to-private';
  public static readonly VISIBILITY_SET_TO_PUBLIC = 'goals.visibility-set-to-public';

}
