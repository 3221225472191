import { Component, Input, OnInit } from '@angular/core';
import { Tag } from '@app/domain/tag/model/tag.model';
import { GoalsAPIService } from '@app/shared/api/goals.api.service';
import { Goal } from '@app/models/goals/goal.model';
import { State } from '@app/shared/utils/state.model';
import { CompanyFeatures } from '@app/models/company-features.model';
import { Globals } from '@app/shared/globals/globals';
import { GoalType } from '@app/models/goals/goal-type.enum';
import { ActivatedRoute, Router } from '@angular/router';
import { PagingParams } from '@app/models/api/paging-params.model';
import { Page } from '@app/models/api/page.model';
import { staticGoalSortingParams } from '@app/shared/utils/api-filter/static-goal-params';
import { GoalUtils } from '@app/shared/utils/goal.utils';
import { GoalsMessages } from '@app/goals/goals.messages';
import { TranslateService } from '@ngx-translate/core';
import { DateUtils } from '@app/shared/utils/date.utils';

interface PageGoal extends Goal {
  dueDuration: string;
}

@Component({
  selector: 'app-goal-tag-dropdown-list',
  templateUrl: './goal-tag-dropdown-list.component.html',
  styleUrls: ['./goal-tag-dropdown-list.component.scss']
})
export class GoalTagDropdownListComponent implements OnInit {
  public readonly eGoalsMessages = GoalsMessages;
  public readonly eCompanyFeatures = CompanyFeatures;
  public readonly eGoalUtils = GoalUtils;
  public readonly eGoalType = GoalType;
  public readonly eDateUtils = DateUtils;

  @Input() goalTag: Tag;

  goalsPage?: Page<PageGoal>;
  goals: PageGoal[];
  state: State;

  pagingParams: PagingParams;

  constructor(
    public globals: Globals,
    private goalsAPIService: GoalsAPIService,
    private router: Router,
    private route: ActivatedRoute,
    public translateService: TranslateService
  ) {
    this.goalTag = undefined!;

    this.goals = [];

    this.state = new State(true);

    this.pagingParams = {
      pageSize: 15,
      pageNumber: 0
    };
  }

  ngOnInit(): void {
    this.searchGoals();
  }

  searchGoals() {
    this.state.setLoading();
    this.goalsAPIService.getTagGoals(this.pagingParams, staticGoalSortingParams, this.goalTag.id).subscribe(goalsPage => {
      const pageParsed = this.parsePageToPageGoal(goalsPage);

      this.goalsPage = pageParsed;
      this.goals = Goal.getGoalArrayCompletionPercentage(pageParsed.content) as PageGoal[];
      this.state.setSuccess();
    });
  }

  navigateToGoal(id: number) {
    this.router.navigate(['individual/' + id], { relativeTo: this.route });
  }

  parsePageToPageGoal(page: Page<Goal>): Page<PageGoal> {
    const pageParsed = page as Page<PageGoal>;

    if (pageParsed) {
      pageParsed.content = this.parseGoalToPageGoalMultiple(pageParsed.content);
    }

    return pageParsed;
  }

  parseGoalToPageGoalMultiple(goals: Goal[]): PageGoal[] {
    return goals.map(g => this.parseGoalToPageGoalSingle(g));
  }

  parseGoalToPageGoalSingle(goal: Goal): PageGoal {
    const goalParsed = goal as PageGoal;

    if (goalParsed) {
      goalParsed.dueDuration = DateUtils.getDaysRemaining(this.translateService, goal.endDate);
    }

    return goalParsed;
  }

  changePageSize($event: number) {
    this.pagingParams.pageSize = $event;
    this.searchGoals();
  }

  changePageNumber($event: number) {
    this.pagingParams.pageNumber = $event;
    this.searchGoals();
  }
  
}
