export enum SearchMode {
    NONE,
    DATE,
    DATE_RANGE,
    LOCAL,
    USER,
    DEPARTMENT,
    OFFICE_LOCATION,
    TALKING_POINT_TEMPLATE,
    POSITION,
    QUESTION_TEMPLATE,
    SENTIMENT_SCALE
}