<div class="container-sidebar-sentiment-scale-timeline">
  <app-state [state]="state">
    <ng-container *ngIf="!state.loading && !state.error;">
      <div class="area-meeting-outer">
        <div class="area-main-chart">
          <div class="card no-margin">
            <div class="area-meeting-participants">
              <div class="spacer-meeting-info"></div>
              <div class="wrapper-profile-pictures">
                <ng-container *ngFor="let user of uniqueUsers;">
                  <app-profile-photo [src]="user.imageUrl" [width]="'40px'"></app-profile-photo>
                </ng-container>        
              </div>
            </div>
    
            <div class="area-meetings-chart" #chartOuter>
              <ng-container *ngFor="let meetingId of uniqueMeetingIds; first as isFirst; last as isLast;">
                <ng-container *ngIf="meetingId === firstMeetingIdOfFiscalYear">
                  <div class="wrapper-meeting-item">
                    <div class="display-new-fiscal-year">
                      <span class="fal fa-fw fa-calendar"></span>
                      <span class="m-right-5"></span>
                      <div>Start of fiscal year</div>
                    </div>
                  </div>
                </ng-container>
                <ng-container *ngIf="!isFirst && data[meetingId].usesNewScale">
                  <div class="wrapper-meeting-item">
                    <div class="display-uses-new-scale" [tooltip]="tooltipScaleChanged" container="body">
                      <span class="fal fa-fw fa-info-circle"></span>
                      <span class="m-right-5"></span>
                      <span>Scale changed</span>
                    </div>
                    <ng-template #tooltipScaleChanged>
                      <div class="text-left">The sentiment scale used in this meeting is different the the one used by the previous meeting. This is because it was either edited by the schedule manager or the scale itself was edited by an admin</div>
                    </ng-template>
                  </div>
                </ng-container>
                <div class="wrapper-meeting-item">
                  <app-information-sidebar-sentiment-scale-timeline-meeting-item [meetingId]="meetingId" [meetingData]="data[meetingId]" [uniqueUserIds]="uniqueUserIds" [isLast]="isLast"></app-information-sidebar-sentiment-scale-timeline-meeting-item>
                </div>
              </ng-container>
            </div>
          </div>
        </div>

        <div class="area-year-averages">
          <div class="card card-offwhite no-margin">
            <div class="spacer-meeting-info">
              <div>{{currentFiscalYearStart.getFullYear()}} Average:</div>
              <small class="text-info" [tooltip]="tooltipSentimentAverageExplanation" container="body" [triggers]="globals.tooltipTriggers">More info</small>
              <ng-template #tooltipSentimentAverageExplanation>
                <div class="text-left">
                  <div>This is the average of sentiment scale values given for all meetings this <strong>fiscal</strong> year.</div>
                  <div>The average will always be shown on the sentiment scale used in the latest meeting.</div>
                </div>
              </ng-template>
            </div>
            <div class="wrapper-average-numbers">
              <ng-container *ngFor="let user of uniqueUsers;">
                <ng-container *ngIf="userYearAverages[user.id]; else templateNoAverage;">
                  <app-sentiment-scale-option-display [option]="userYearAverages[user.id]" [showNumbers]="latestMeetingSentimentScale?.showNumbers" [showIndent]="false" [showName]="false" [showDescription]="false" [metadataInTooltip]="true"></app-sentiment-scale-option-display>
                </ng-container>
                <ng-template #templateNoAverage>
                  <div class="average-number-item" tooltip="This person has not responded to any sentiment scales this year" container="body">
                    <span class="fal fa-fw fa-dash"></span>
                  </div>
                </ng-template>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </app-state>
</div>