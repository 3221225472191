import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-slider-input',
  templateUrl: './slider-input.component.html',
  styleUrls: ['./slider-input.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => SliderInputComponent),
    multi: true
  }],
})
export class SliderInputComponent implements ControlValueAccessor {
  @Input() disabled: boolean;

  _value: boolean;

  onChange = (_: any) => {};
  onTouched = () => {};

  get value(): boolean {
    return this._value;
  }

  set value(v: boolean) {
    if (v !== this._value) {
      this._value = v;
      this.onChange(v);
    }
  }

  constructor() {
    this.disabled = false;
    this._value = false;
  }

  writeValue(value: any): void {
    this.value = value;
  }

  registerOnChange(fn: (_: any) => {}): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  onClickSlider() {
    if (this.disabled) {
      return;
    }

    this.value = !this.value;
  }
}
