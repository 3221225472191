<div class="container-talking-point-display" *ngIf="talkingPoint">
  <div class="area-talking-point-index">
    <ng-container *ngIf="index !== undefined">
      <span>{{index + 1}}.</span>
    </ng-container>
  </div>

  <div class="area-talking-point-contents">
    <div class="area-talking-point-title">{{talkingPoint.title}}</div>
  
    <ng-container *ngIf="talkingPoint.comments && talkingPoint.comments.length > 0">
      <div class="area-talking-point-comments">
        <ng-container *ngFor="let commentItem of talkingPoint.comments;">
          <app-one-to-one-note-display [note]="commentItem"></app-one-to-one-note-display>
        </ng-container>
      </div>
    </ng-container>
  </div>
</div>