import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CreateMissionStatement } from '../model/create-mission-statement.model';
import { MissionStatement } from '../model/mission-statement.model';
import { MissionStatementService } from './mission-statement-api.model';

@Injectable()
export class MissionStatementAPIService implements MissionStatementService {
  private readonly BASE_URL = 'api/mission-statement';

  constructor(
    private http: HttpClient
  ) { }

  getMission(): Observable<MissionStatement> {
    const url = `${this.BASE_URL}`;
    return this.http.get<MissionStatement>(url);
  }

  setMission(missionStatement: CreateMissionStatement): Observable<MissionStatement> {
    const url = `${this.BASE_URL}`;
    return this.http.post<MissionStatement>(url, missionStatement);
  }

  deleteMission(): Observable<MissionStatement> {
    const url = `${this.BASE_URL}`;
    return this.http.delete<MissionStatement>(url);
  }
}
