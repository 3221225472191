import { Component, Input, OnInit } from '@angular/core';
import { CommonMessages } from '@app/constants/common.messages';
import { Competency } from '@app/domain/competency/model/competency.model';
import { Globals } from '@app/shared/globals/globals';
import { PillType } from '../../pill/pill.component';
import { SkillRating } from '@app/domain/skill_rating/model/skill-rating.model';
import { CompanyFeatures } from '@app/models/company-features.model';
import { SkillRatingConfiguration } from '@app/domain/skill_rating/model/skill-rating-configuration.model';
import { SkillRatingOption } from '@app/domain/skill_rating/model/skill-rating-option.model';

@Component({
  selector: 'app-competency-display',
  templateUrl: './competency-display.component.html',
  styleUrls: ['./competency-display.component.scss']
})
export class CompetencyDisplayComponent implements OnInit {

  public readonly eCompanyFeatures = CompanyFeatures;
  public readonly eCommonMessages = CommonMessages;
  public readonly ePillType = PillType;

  @Input() competency: Competency;
  @Input() skillRating?: SkillRating;
  @Input() skillRatingConfiguration?: SkillRatingConfiguration;
  @Input() showScore: boolean;

  metadata: SkillRatingOption;

  dropdownExpanded: boolean;

  constructor(
    public globals: Globals
  ) {
    this.competency = undefined!;
    this.dropdownExpanded = false;
    this.showScore = true;
  }

  toggleDropdown(): void {
    this.dropdownExpanded = !this.dropdownExpanded;
  }

  ngOnInit(): void {
    if (this.skillRatingConfiguration && this.skillRating && this.skillRating.scoreValue !== -1) {
      this.metadata = this.skillRatingConfiguration.getOption(this.skillRating.scoreValue);
    }
  }

}
