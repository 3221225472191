import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { debounceTime, tap } from 'rxjs/operators';
import { UserAPIService } from '@app/shared/api/user.api.service';
import { User } from '@app/models/user/user.model';
import { State } from '@app/shared/utils/state.model';
import { UserMinimal } from '@app/models/user/user-minimal.model';
import { RoleName } from '@app/models/user-role.model';
import { FilterOperator } from '@app/models/api/filter-operator.enum';
import { ParentFilter } from '@app/models/api/parent-filter.model';
import { CriterionOperator } from '@app/models/api/criterion-operator.enum';
import { ChildFilter } from '@app/models/api/child-filter.model';
import { staticUserPagingParams, staticUserSortingParams } from '@app/shared/utils/api-filter/static-user-params';

export interface CustomUserPickerOption {
  id: any;
  imageUrl?: string;
  firstName: string;
  lastName: string;
}

@Component({
  selector: 'app-user-picker-minimal',
  templateUrl: './user-picker-minimal.component.html',
  styleUrls: ['./user-picker-minimal.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => UserPickerMinimalComponent),
    multi: true,
  }],
})
export class UserPickerMinimalComponent implements OnInit, ControlValueAccessor {
  @Input() userPool: UserMinimal[];
  @Input() customOptions: CustomUserPickerOption[];
  @Input() searchPlaceholder: string;
  @Input() ignoredUserIds: number[] = [];
  @Input() ignoredRoles: RoleName[] = [];

  state: State = new State(false);

  disabled: boolean;
  dropdownOpen: boolean;

  usersListed: UserMinimal[];
  customOptionsListed: CustomUserPickerOption[];
  searchControl: FormControl;

  _value: UserMinimal | CustomUserPickerOption;

  searchFilter: ChildFilter = {
    operator: FilterOperator.AND,
    filterCriteria: []
  }
  roleFilter: ChildFilter = {
    operator: FilterOperator.AND,
    filterCriteria: []
  }
  parentFilter: ParentFilter = {
    operator: FilterOperator.AND,
    childFilters: [this.searchFilter, this.roleFilter]
  }

  onChange = (_: any) => {};
  onTouched = () => {};

  get value(): (User | CustomUserPickerOption) {
    return this._value;
  }

  set value(u: (User | CustomUserPickerOption)) {
    this._value = u;
    this.onChange(u)
  }

  constructor(private userApiService: UserAPIService) {
    this.userPool = [];
    this.customOptions = [];
    this.customOptionsListed = [];
    this.searchControl = new FormControl();
    this.usersListed = [];
    this._value = undefined!;
    this.disabled = false;
    this.dropdownOpen = false;
    this.searchPlaceholder = '';
  }

  writeValue(val: User) {
    this._value = val;
  }

  // Register callbacks
  registerOnChange(fn: (_: any) => {}): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  ngOnInit() {
    // Show all if not searching
    if (this.customOptions) {
      this.customOptionsListed = this.customOptions;
    }

    this.searchControl.valueChanges
      .pipe(tap(() => this.state.setLoading()))
      .pipe(debounceTime(500))
      .subscribe((v: string) => this.doSearch(v));
    this.setDisabledState(this.disabled);
    this.usersListed = this.userPool;
  }

  doSearch(searchArgument: string) {
    if (searchArgument && searchArgument.length > 0) {
      // If supplied with a user pool, search on that, otherwise call the user search route
      if (this.userPool.length > 0) {
        this.state.setLoading();
        this.usersListed = this.userPool.filter((u) => (`${u.firstName} ${u.lastName}`).toLocaleLowerCase().includes(searchArgument.toLocaleLowerCase()));
        this.state.setSuccess();
      } else {
        this.state.setLoading();
        this.setFilters(searchArgument);
        this.userApiService.searchUsersPaginated(staticUserPagingParams, staticUserSortingParams, this.parentFilter).subscribe(pagedUsers => {
          this.usersListed = pagedUsers.content;
          this.state.setSuccess();
        });
      }
    } else {
      this.state.setLoading();
      if (this.customOptions) {
        if (this.userPool.length > 0) {
          this.customOptionsListed = this.customOptions;
        } else {
          this.customOptionsListed = [];
        }
      }
      this.state.setSuccess();
    }
  }

  setFilters(searchArgument: string) {
    if (searchArgument) {
      this.searchFilter.filterCriteria = [];
      this.searchFilter.filterCriteria.push({
        field: "name",
        operator: CriterionOperator.LIKE,
        value: searchArgument.trim()
      });
      if (this.ignoredUserIds.length > 0) {
        this.searchFilter.filterCriteria.push({
          field: "id",
          operator: CriterionOperator.NOT_IN,
          values: this.ignoredUserIds.map(id => String(id))
        });
      }
      for (const role of this.ignoredRoles) {
        this.roleFilter.filterCriteria.push({
          field: "roles",
          operator: CriterionOperator.NOT_CONTAINS,
          value: role
        });
      }
    }
  }

  selectUser(u: User | CustomUserPickerOption) {
    if (!this.disabled) {
      this.dropdownOpen = false;
      this.searchControl.reset();
      this.onTouched();
      this.value = u;
    }
  }

  trySelectFirst() {
    if (this.usersListed.length > 0) {
      this.selectUser(this.usersListed[0])
    }
  }
  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }

  hideDropdownDelayed() {
    setTimeout(() => {
      this.dropdownOpen = false
    }, 200);
  }
}
