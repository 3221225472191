<div class="container-sidebar-reviews-self-reflection">
  <app-state [state]="state">
    <ng-container *ngIf="!state.loading && !state.error">
      <ng-container *ngIf="selfReflection && selfReflection.completed; else noSelfReflection">
        <app-self-reflection-display [cycle]="cycle" [selfReflection]="selfReflection" [assessmentGroups]="assessmentGroupsFiltered.assessmentGroupInfo[eEvaluationModuleType.SELF_REFLECTION]" [startExpanded]="true"></app-self-reflection-display>
      </ng-container>
      
      <ng-template #noSelfReflection>
        <app-empty-state [height]="'200px'" [message]="'No self reflection'"></app-empty-state>
      </ng-template>
    </ng-container>
  </app-state>
</div>