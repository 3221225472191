<div class="card outer-card">
  <ng-container *ngIf="personDetails; else templateNoData;">
    <div class="card-header">
      <!-- Background colour -->
      <div class="background-colour" [style.background]="personDetails.cardColor"></div>
      <!-- Location -->
      <div class="location-display">
        <span class="fal fa-fw fa-map-marker-alt"></span>
        <span>
          <span [title]="globals.getTerminology(eTerminologyEntity.SITE)">{{personDetails.officeLocationName ? personDetails.officeLocationName : 'No ' + globals.getTerminology(eTerminologyEntity.SITE)}}</span>
        </span>
      </div>

      <div class="img-container" [style.border-color]="personDetails.cardColor" [title]="personDetails.roleName">
        <app-profile-photo [src]="personDetails.imageUrl" [firstName]="personDetails.firstName" [lastName]="personDetails.lastName"></app-profile-photo>
      </div>
    </div>
    <div class="card-body">
      <!-- Social links -->
      <div class="position-social-links">
        <!-- Preferred contact method -->

        <!-- MS Teams -->
        <div class="d-inline-block contact-icon"
             *ngIf="globals.hasFeature(eCompanyFeatures.SSO_MICROSOFT) || globals.hasFeature(eCompanyFeatures.AZURE_ACTIVE_DIRECTORY)"
             [tooltip]="teamsTooltip"
             [class.preferred-contact]="personDetails.preferredContactMethod === ePreferredContactMethod.MICROSOFT_TEAMS"
             (click)="openTeams($event)"
        >
          <span class="expand-on-hover frankli-view fab fa-fw fa-microsoft"></span>
          <ng-template #teamsTooltip>
            <div class="text-nowrap">
              <div>
                <ng-container [ngTemplateOutlet]="requiredLabel" *ngIf="personDetails.preferredContactMethod === ePreferredContactMethod.MICROSOFT_TEAMS"></ng-container>
              </div>
              <div>Call {{personDetails.fullName}} on Teams</div>
            </div>
          </ng-template>
        </div>

        <!-- Email -->
        <!--  && ((user.preferredContactMethod === ePreferredContactMethod.EMAIL) || (user.preferredContactMethod === ePreferredContactMethod.NONE)) -->
        <div class="d-inline-block contact-icon" *ngIf="personDetails.email" [tooltip]="emailTooltip" (click)="openEmail($event, personDetails.email)"
             [class.preferred-contact]="personDetails.preferredContactMethod === ePreferredContactMethod.EMAIL">
          <span class="expand-on-hover frankli-view fas fa-fw fa-envelope"></span>
          <ng-template #emailTooltip>
            <div class="text-nowrap">
              <div>
                <ng-container [ngTemplateOutlet]="requiredLabel" *ngIf="personDetails.preferredContactMethod === ePreferredContactMethod.EMAIL"></ng-container>
              </div>
              <div>{{personDetails.email}}</div>
            </div>
          </ng-template>
        </div>

        <!-- Phone -->
        <div class="d-inline-block contact-icon" *ngIf="personDetails.phoneNumber" [tooltip]="phoneTooltip" (click)="openPhone($event, personDetails.phoneNumber)"
             [class.preferred-contact]="((personDetails.preferredContactMethod === ePreferredContactMethod.PHONE) || (personDetails.preferredContactMethod === ePreferredContactMethod.TEXT))">
          <span class="expand-on-hover frankli-view fas fa-fw fa-phone"></span>
          <ng-template #phoneTooltip>
            <div class="text-nowrap">
              <div>
                <ng-container [ngTemplateOutlet]="requiredLabel" *ngIf="((personDetails.preferredContactMethod === ePreferredContactMethod.PHONE) || (personDetails.preferredContactMethod === ePreferredContactMethod.TEXT))"></ng-container>
              </div>
              <div>{{personDetails.phoneNumber}}</div>
            </div>
          </ng-template>
        </div>

        <span *ngIf="personDetails.linkedIn || personDetails.twitterHandle">|</span>

        <!-- LinkedIn -->
        <div class="d-inline-block contact-icon" *ngIf="personDetails.linkedIn" [tooltip]="linkedInTooltip" (click)="openLinkedIn($event, personDetails.linkedIn)"
             [class.preferred-contact]="personDetails.preferredContactMethod === ePreferredContactMethod.LINKEDIN">
          <span class="expand-on-hover frankli-view fab fa-fw fa-linkedin"></span>
          <ng-template #linkedInTooltip>
            <div class="text-nowrap">
              <div>
                <ng-container [ngTemplateOutlet]="requiredLabel" *ngIf="personDetails.preferredContactMethod === ePreferredContactMethod.LINKEDIN"></ng-container>
              </div>
              <div>{{personDetails.linkedIn}}</div>
            </div>
          </ng-template>
        </div>

        <!-- Twitter -->
        <div class="d-inline-block contact-icon" *ngIf="personDetails.twitterHandle" [tooltip]="twitterTooltip" (click)="openTwitter($event, personDetails.twitterHandle)"
             [class.preferred-contact]="personDetails.preferredContactMethod === ePreferredContactMethod.TWITTER">
          <span class="expand-on-hover frankli-view fab fa-fw fa-twitter"></span>
          <ng-template #twitterTooltip>
            <div class="text-nowrap">
              <div>
                <ng-container [ngTemplateOutlet]="requiredLabel" *ngIf="personDetails.preferredContactMethod === ePreferredContactMethod.TWITTER"></ng-container>
              </div>
              <div>&#64;{{personDetails.twitterHandle}}</div>
            </div>
          </ng-template>
        </div>

        <ng-template #requiredLabel>
          <!-- <div class="preferred-label">(PREFERED)</div> -->
        </ng-template>

        <!-- TODO: Slack -->
      </div>

      <!-- Position and dept -->
      <div class="position-department-display">
        <!-- Position -->
        <span class="user-position">
            <span [title]="'Position'">{{personDetails.positionName ? personDetails.positionName : 'No Position'}}</span>
        </span>

        <!-- Department -->
        <span class="user-department">
          <span>, </span>
          <span [title]="globals.getTerminology(eTerminologyEntity.DEPARTMENT)">{{personDetails.organisationalUnitName ? personDetails.organisationalUnitName : 'No ' + globals.getTerminology(eTerminologyEntity.DEPARTMENT)}}</span>
        </span>
      </div>

      <!-- Name -->
      <div class="name-display" [title]="'Name'">{{personDetails.fullName}}
      </div>
    </div>
  </ng-container>

  <ng-template #templateNoData>
    <div class="empty-state">
      Invalid user
    </div>
  </ng-template>
</div>
