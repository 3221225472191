import { Component, Input, OnInit } from '@angular/core';
import { Globals } from '@app/shared/globals/globals';

interface ChartData {
  value: string; // Raw value
  text: string; // Formatted value
  label: string; // Strongly Disagree, Neutral, Agree, etc.
  height: string; // Relative height for progress bar
  tooltip: string; // Tooltip text
  color: string; // Bar color
  colorAlt: string; // Background color
}

interface WidthData {
  value: string;
  text: string;
  label: string;
  percentOfTotal: string;
}

@Component({
  selector: 'app-likert-reporting-chart',
  templateUrl: './likert-reporting-chart.component.html',
  styleUrls: ['./likert-reporting-chart.component.scss']
})
export class LikertReportingChartComponent implements OnInit {
  @Input() data?: WidthData[];

  dataParsed: ChartData[];

  constructor(
    public globals: Globals
  ) {
    this.dataParsed = [];
  }

  ngOnInit() {
    this.dataParsed = this.parseData(this.data);
  }

  parseData(input?: WidthData[]): ChartData[] {
    if (!input) {
      return [];
    }
    const dataUsing = this.parseWidthsToChartData(input);
    return dataUsing;
  }

  parseWidthsToChartData(widths: WidthData[]): ChartData[] {
    const maxHeight = this.getChartMaxHeight(widths);

    return widths.map(d => {
      const output: ChartData = {
        value: d.value,
        text: d.text,
        label: d.label,
        height: this.getChartHeight(+d.value, maxHeight),
        tooltip: this.getChartTooltip(+d.value),
        color: this.getChartColor(d.label),
        colorAlt: this.getChartColorAlt(d.label)
      }

      return output;
    })

  }

  getChartHeight(value: number, maxHeight: number): string {
    const ratio = (value / maxHeight);
    const ratioPercent = (ratio * 100);

    if (ratioPercent < 5) {
      return `5%`;
    }

    return `${ratioPercent}%`;
  }

  getChartMaxHeight(data: any[]): number {
    let maxValue = 0;

    data.forEach(d => {
      if (+d.value > maxValue) {
        maxValue = +d.value;
      }
    });

    return maxValue;
  }

  getChartTooltip(value: number): string {
    if (value > 1) {
      return `${value} people`
    }

    if (value > 0) {
      return `${value} person`
    }

    return 'None';
  }

  // TODO: Make these classes so that we don't have styling in the TS file
  getChartColor(label: string): string {
    switch (label) {
      case 'Strongly Agree':
        return '#008168';
      case 'Agree':
        return '#54c7ba';
      case 'Neutral':
        return '#ffbd36';
      case 'Disagree':
        return '#f75757';
      case 'Strongly Disagree':
        return '#d0352c';
      case 'No Answer':
        return '#EEEEEE';
      default:
        return '#AAAAAA';
    }
  }

  getChartColorAlt(label: string): string {
    // return '#D8ECE9';
    // return '#FFEEF2';
    // return '#FEF5E2';
    switch (label) {
      case 'Strongly Agree':
        return '#D8ECE9';
      case 'Agree':
        return '#D8ECE9';
      case 'Neutral':
        return '#FEF5E2';
      case 'Disagree':
        return '#FFEEF2';
      case 'Strongly Disagree':
        return '#FFEEF2';
      case 'No Answer':
        return '#FFEEF2';
      default:
        return '#EEEEEE';
    }
  }
}
