import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { richLinkRemoveLinks } from '@app/shared/tinymce/plugins/richLink.plugin';
import tinymce, { Editor, EditorEvent, RawEditorSettings } from 'tinymce';
import { environment } from '../../../../environments/environment';
import { Globals } from '@app/shared/globals/globals';

const TOOLBAR_DEFAULTS = {
  desktop:  'undo redo | formatselect | bold italic underline strikethrough | alignleft aligncenter alignright | bullist numlist | outdent indent | table | btnFrankliLink | help',
  mobile:   'formatselect | bold italic underline strikethrough | alignleft aligncenter alignright | bullist numlist | outdent indent | btnFrankliLink'
};
const MENUBAR_DEFAULTS = {
  desktop: false,
  mobile: 'edit view insert format tools'
};
export const PLUGIN_DEFAULTS = {
  desktop:  'lists table help wordcount autolink link frankliRichLink',
  mobile:   'lists table help wordcount autolink link frankliRichLink'
};
// TODO: Try get this in a stylesheet somewhere
const CONTENT_STYLES = `
  .mce-content-body[data-mce-placeholder]:not(.mce-visualblocks)::before {
    color: #AAAAAA;
  }
`;
// `
//   .rl-hidden {
//     display: none;
//   }

//   .rich-link {
//     display: inline-block;
//     background-color: #F7F7F7;
//     position: relative;
//     border-radius: 6px;
//     border: 1px solid #EEEEEE;
//   }

//   .rich-link.goal {
//     border: 1px solid blue;
//     border-left-width: 3px;
//     border-left-style: solid;
//     border-left-color: transparent;
//   }

//   .rich-link.goal.status-OFF_TRACK {
//     border-left-color: #FF5630;
//   }
//   .rich-link.goal.status-PROGRESSING {
//     border-left-color: #FFAB00;
//   }
//   .rich-link.goal.status-ON_TRACK {
//     border-left-color: #36B37E;
//   }
//   .rich-link.goal.status-COMPLETE {
//     border-left-color: #254DC6;
//   }
//   .rich-link.goal.status-ARCHIVED {
//     border-left-color: #D3D3D3 !important;
//   }
// `

@Component({
  selector: 'app-editor-response',
  templateUrl: './editor-response.component.html',
  styleUrls: ['./editor-response.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: EditorResponseComponent,
    multi: true,
  }]
})
export class EditorResponseComponent implements OnInit, OnDestroy, OnChanges {
  @Input() disabled = false;
  @Input() display = false;
  @Input() resize: boolean;
  @Input() height: number | string;
  @Input() maxCharacters: number;
  // @deprecated
  @Input() maxlength?: number;
  @Input() hardMax: number;
  @Input() inline: boolean;
  @Output() autoEvent: EventEmitter<boolean>;
  @Input() wordcountChanged: EventEmitter<number>;
  @ViewChild('editor') editorElement!: ElementRef;
  @Input() toolbar: string | boolean;
  @Input() toolbarMobile: string | boolean;
  @Input() menubar: string | boolean;
  @Input() menubarMobile: string | boolean;
  @Input() plugins: string;
  @Input() pluginsMobile: string;
  @Input() contextMenu: string;
  @Input() columnIndent: boolean;
  @Input() showErrorBorder: boolean;
  @Input() enforceCharacterLimit: boolean;
  @Input() placeholder: string;
  @Input() autofocus: boolean;
  @Input() id: string;

  wordcount: number;

  warnWordcount: boolean;

  cachedValue: string;

  editor: any;
  _value: string;

  loading: boolean;
  firstInitRun: boolean;

  doRichLinkParsing: boolean;

  onChange = (_: any) => {};
  onTouched = () => {};

  get value(): string {
    return this._value;
  }

  set value(val: string) {
    if (this._value !== val && !this.disabled) {
      if (this.doRichLinkParsing) {
        val = richLinkRemoveLinks(val);
      }

      this.cachedValue = val;
      this._value = val;
      this.onChange(val);
      this.autoEvent.emit(true);
    }
  }

  constructor(
      private cdRef: ChangeDetectorRef,
      public globals: Globals
  ) {
    this._value = '';
    this.resize = false;
    this.warnWordcount = false;
    this.inline = false;
    this.columnIndent = true;
    this.showErrorBorder = false;
    this.autofocus = false;
    this.enforceCharacterLimit = true;
    this.doRichLinkParsing = false;

    this.loading = false;
    this.firstInitRun = false;

    this.autoEvent = new EventEmitter<boolean>();
    this.wordcountChanged = new EventEmitter<number>();

    this.wordcount = 0;
    this.height = 300;
    this.maxCharacters = 2000;
    this.hardMax = 40000;
    this.cachedValue = undefined!;
    this.contextMenu = 'paste spellchecker tinymcespellchecker';

    this.menubar = undefined!;
    this.menubarMobile = undefined!;

    this.plugins = undefined!;
    this.pluginsMobile = undefined!;

    this.toolbar = undefined!;
    this.toolbarMobile = undefined!;

    this.placeholder = '';
    this.id = 'rich-editor';
  }

  ngOnInit() {
    if (this.maxCharacters > this.hardMax) {
      this.hardMax = this.maxCharacters + 500;
    }

    this.setDefaults();

    this.initRichLinkParsing();

    setTimeout(() => {
      this.initEditor();
    }, 1);
  }

  initRichLinkParsing(): void {
    if (this.plugins.includes('frankliRichLink')) {
      this.doRichLinkParsing = true;
    }
  }

  setDefaults() {
    // PLUGINS
    (() => {
      // No plugins specified - Use defaults for both
      if ((this.plugins === undefined) && (this.pluginsMobile === undefined)) {
        this.plugins = PLUGIN_DEFAULTS.desktop;
        this.pluginsMobile = PLUGIN_DEFAULTS.mobile;
        return;
      }

      // Mobile defined but desktop arent - Use desktop defaults and set mobile ones
      if (this.plugins === undefined) {
        this.plugins = PLUGIN_DEFAULTS.desktop;
        return;
      }

      // Plugins defined but mobile ones arent - Use same as desktop
      if (this.pluginsMobile === undefined) {
        this.pluginsMobile = this.plugins;
      }
    })();

    // TOOLBAR
    (() => {
      // No toolbar specified - Use defaults for both
      if ((this.toolbar === undefined) && (this.toolbarMobile === undefined)) {
        this.toolbar = TOOLBAR_DEFAULTS.desktop;
        this.toolbarMobile = TOOLBAR_DEFAULTS.mobile;
        return;
      }

      // Mobile defined but desktop arent - Use desktop defaults and set mobile ones
      if (this.toolbar === undefined) {
        this.toolbar = TOOLBAR_DEFAULTS.desktop;
        return;
      }

      // toolbar defined but mobile ones arent - Use same as desktop
      if (this.toolbarMobile === undefined) {
        this.toolbarMobile =
        (<string>this.toolbar)
          .replace(/redo/g, '')
          .replace(/undo/g, '')
          .replace(/table/g, '')
          .replace(/help/g, '');
      }
    })();

    // MENUBAR
    (() => {
      // No menubar specified - Use defaults for both
      if ((this.menubar === undefined) && (this.menubarMobile === undefined)) {
        this.menubar = MENUBAR_DEFAULTS.desktop;
        this.menubarMobile = MENUBAR_DEFAULTS.mobile;
        return;
      }

      // Mobile defined but desktop arent - Use desktop defaults and set mobile ones
      if (this.menubar === undefined) {
        this.menubar = MENUBAR_DEFAULTS.desktop;
        return;
      }

      // menubar defined but mobile ones arent - Use same as desktop
      if (this.menubarMobile === undefined) {
        this.menubarMobile = this.menubar;
      }
    })();

    if (this.maxlength) {
      console.error('Dont use [maxlength] on editor-response - use [maxCharacters] instead');
    }
  }

  ngOnDestroy() {
    this.removeEditor();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.height) {
      if (!changes.height.firstChange) {
        this.removeEditor();
        setTimeout(() => {
          this.initEditor();
        }, 1);
      }
    }
  }

  // Set value without emitting events
  writeValue(value: any) {
    if (value === null || value === undefined) {
      value = '';
    }

    this._value = value;
    setTimeout(() => {
      this.trySetEditorValue(value);
    }, 1);
  }

  // Register callbacks
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setWordCount(count: number) {
    this.wordcount = count;
    this.wordcountChanged.emit(count);
    this.cdRef.detectChanges();
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;

    if (this.editor && this.editorElement) {
      this.disabled = isDisabled;

      if (isDisabled) {
        this.editor.mode.set('readonly');
        // tinymce.activeEditor.setMode('readonly');
      } else {
        this.editor.mode.set('design');
        // tinymce.activeEditor.setMode('design');
      }
    }

    // this.initEditor();
  }

  initEditor() {
    const locale = this.globals.user.configuration.preferredLocale;
 
    const comp = this;
    if (this.editorElement && this.editorElement.nativeElement) {
      const config: RawEditorSettings = {
        // TODO: May need to re-add if weant to test editors
        // id: this.id,
        target: this.editorElement.nativeElement,
        base_url: '/assets/tinymce', // For loading TinyMCE locally
        suffix: '.min',       // For loading TinyMCE locally
        // icons_url: '/assets/tinymce/frankli-icons/icons.js',
        icons: 'frankli-icons',
        // theme_url: '/tinymce/themes/silver/theme.min.js', // Load theme locally
        // skin_url: '/tinymce/skins/ui/oxide/skin.min.css', // Load skin locally
        height: this.height,
        menubar: this.menubar,
        browser_spellcheck: true,
        // gecko_spellcheck: true,
        plugins: [ this.plugins ],
        toolbar: this.toolbar,
        toolbar_items_size : 'small',
        statusbar: false,
        contextmenu: this.contextMenu,
        link_context_toolbar: true,
        visual: false, // Removes dotted borders on table with unspecified borders and makes solid
        inline: this.inline,
        placeholder: this.placeholder,
        toolbar_mode: 'floating',
        mobile: {
          menubar: this.menubarMobile,
          // menu : { // this is the complete default configuration
          //   file   : {title : 'File'  , items : 'newdocument'},
          //   edit   : {title : 'Edit'  , items : 'undo redo | cut copy paste pastetext | selectall'},
          //   insert : {title : 'Insert', items : 'link media | template hr'},
          //   view   : {title : 'View'  , items : 'visualaid'},
          //   format : {title : 'Format', items : 'bold italic underline strikethrough superscript subscript | formats | removeformat'},
          //   table  : {title : 'Table' , items : 'inserttable deletetable | cell row column'},
          //   tools  : {title : 'Tools' , items : 'spellchecker'}
          // },
          plugins: [ this.pluginsMobile ],
          toolbar: this.toolbarMobile,
          toolbar_mode: 'sliding',
          contextmenu: false
        },
        setup: (editor: any) => {
          comp.editor = editor;

          editor.on('init', this.onEditorInit(editor)); // Editor loads
          editor.on('KeyDown', this.onEditorKeyDown(editor)); // User presses key down
          editor.on('KeyUp', this.onEditorKeyUp(editor)); // User lifts the key
          editor.on('SetContent', this.onEditorSetContent(editor)); // Run when Editor content is set - used for tests
          editor.on('ExecCommand', this.onEditorExecCommand(editor)); // Command is run (for debugging)
          editor.on('Change', this.onEditorChange(editor)); // Editor content changes
        },
        table_default_attributes: {
          border: '1px solid black'
        },
        elementpath: false,
        valid_styles : {
          '*' : 'font-size,font-weight,font-style,text-decoration,border'
        },
        resize: this.resize,
        auto_focus: (this.autofocus ? true : undefined),
        external_plugins: {
          ...(environment.mock.enabled ? {} : { // Disable plugins on the public sandbox
            'tinymcespellchecker': '/assets/tinymce/tinymce_plugins/tinymcespellchecker/plugin.min.js',
            'powerpaste': '/assets/tinymce/tinymce_plugins/powerpaste/plugin.min.js'
          })
        },
        spellchecker_language: locale,
        spellchecker_rpc_url: 'api/spellcheck',
        theme_advanced_resizing : true,
        theme_advanced_resize_horizontal : false,
        autoresize_min_height: this.height,
        autoresize_max_height: 800,
        content_style: CONTENT_STYLES,
        default_link_target: '_blank',

        // POWERPASTE
        // https://www.tiny.cloud/docs/plugins/premium/powerpaste/
        paste_merge_formats: true,
        paste_tab_spaces: 4,
        powerpaste_word_import: 'clean',
        powerpaste_googledocs_import: 'clean',
        powerpaste_html_import: 'clean',
        powerpaste_allow_local_images: false,
        powerpaste_block_drop: true,
        smart_paste: true,
        images_file_types: '',
        valid_elements: undefined,
        language: locale
        // TODO: Set word and docs imports to 'prompt' and configure paste_postproccess to do the same as the keyDown/KeyUp actions
        // https://www.tiny.cloud/docs/plugins/premium/powerpaste/#paste_postprocess
      };
      tinymce.init(config);
    }
  }

  // #region - EDITOR COMMANDS / CALLBACKS
  onEditorInit(editor: Editor): Function {
    return (e: EditorEvent<void>) => {
      this.loading = false;
      editor.setContent(this._value);
      this.setWordCount(editor.plugins.wordcount.body.getCharacterCount());
      editor.getBody().setAttribute('contenteditable', `${!this.disabled}`);
      if (this.disabled) {
        editor.setMode('readonly');
      } else {
        editor.setMode('design');
      }

      this.firstInitRun = true;
    };
  }

  onEditorKeyDown(editor: Editor): Function {
    return (e: EditorEvent<KeyboardEvent>) => {
      this.warnWordcount = false;
      if (e.ctrlKey) { // Allow any keyboard shortcuts with ctrl
        return true;
      }

      switch (e.key) {
        case 'Backspace': // Allow backspace
        case 'ArrowLeft': // Allow Arrows
        case 'ArrowRight':
        case 'ArrowUp':
        case 'ArrowDown':
          return true;
        default:
          if ( // Stop characters if over limit
            this.enforceCharacterLimit &&
              (
                (this.wordcount >= this.maxCharacters)
                  || (editor.getContent().length >= this.hardMax)
                  || (editor.plugins.wordcount.body.getCharacterCount() >= this.maxCharacters)
              )
          ) {
            this.warnWordcount = true;
            e.preventDefault();
            e.stopPropagation();
            return false;
          }
          break;
      }

    };
  }

  onEditorKeyUp(editor: Editor): Function {
    return (e: EditorEvent<void>) => {
      const value = editor.getContent();
      if (value !== this.cachedValue) {
        this.value = value;
        this.setWordCount(editor.plugins.wordcount.body.getCharacterCount());
      }
    };
  }

  onEditorChange(editor: Editor): Function {
    return (e: EditorEvent<void>) => {
      const value = editor.getContent();
      if (value !== this.cachedValue) {
        this.value = value;
        this.setWordCount(editor.plugins.wordcount.body.getCharacterCount());
      }
    };
  }

  onEditorSetContent(editor: Editor): Function {
    return (res: EditorEvent<{ content: string, format?: string, paste?: boolean, selection?: boolean }>) => {
      if (this.firstInitRun) {
        const value = editor.getContent();
        if (value !== this.cachedValue) {
          this.value = value;
          this.setWordCount(editor.plugins.wordcount.body.getCharacterCount());
        }
      }
    };
  }

  onEditorExecCommand(editor: Editor): Function {
    return (e: EditorEvent<any>) => {
      // console.info('The ' + e.command + ' command was fired.', e);
    };
  }
  // #endregion

  removeEditor() {
    if (this.editor) {
      this.editor.remove();
    }
  }

  trySetEditorValue(value: string) {
    if (value === null || value === undefined) {
      value = '';
    }

    if (this.editor) {
      this.editor.setContent(value);
    }
  }
}
