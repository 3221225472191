<ng-container *ngIf="state.isSuccessful()">
  <div class="goal-list-container" *ngIf="goals.length > 0; else noGoals">
    <div class="goal-list-scroll">
      <div class="scroll-x">
        <table width="100%">
          <!-- Table headings -->
          <tr class="frankli-table frankli-table-header frankli-table-header-large noselect">
            <!-- State ribbon -->
            <th width="1em" class="text-left"></th>

            <th width="1em" class="text-left" translate>{{eGoalsMessages.OWNERS}}</th>

            <th width="1em" class="text-center" *ngIf="globals.hasFeature(eCompanyFeatures.GOAL_PRIORITY)" translate>{{eGoalsMessages.PRIORITY}}</th>

            <th width="1em" class="text-center" translate>{{eGoalsMessages.TYPE}}</th>

            <th class="text-left" translate>{{eGoalsMessages.TITLE}}</th>

            <th width="1em" class="text-left" translate>{{eGoalsMessages.KEY_RESULTS}}</th>

            <th width="200px" class="text-left" translate>{{eGoalsMessages.PROGRESS}}</th>

            <th width="1em" class="text-center" translate>{{eGoalsMessages.DUE_DATE}}</th>

          </tr>
          <ng-container *ngFor="let goal of goals">
            <tr class="frankli-table frankli-table-hover frankli-table-single-line no-top-border clickable" (click)="navigateToGoal(goal.id)">
              <!-- Status highlight -->
              <td class="p-relative goal-status-col">
                <div class="goal-status-highlight" [title]="eGoalUtils.getStatusTitle(goal)" [ngClass]="eGoalUtils.getStatusHighlight(goal)"></div>
              </td>

              <!-- Owners -->
              <td>
                <app-person-picture-list [users]="goal.owners" [maxListed]="5"></app-person-picture-list>
              </td>
  
              <!-- Priority -->
              <td class="text-center" *ngIf="globals.hasFeature(eCompanyFeatures.GOAL_PRIORITY)">
                <app-goal-priority-display [priority]="goal.priority"></app-goal-priority-display>
              </td>

              <!-- Type -->
              <td class="text-center">
                <app-goal-type-display [type]="goal.type"></app-goal-type-display>
              </td>

              <!-- Title -->
              <td>
                <span class="wrap-title">{{goal.title}}</span>
              </td>

              <!-- Key result count -->
              <td class="text-nowrap">
                <span translate>{{eGoalsMessages.KEY_RESULTS_COUNT | translate: { count: goal.keyResults.length} }}</span>
              </td>

              <!-- Progress bar -->
              <td>
                <app-progress-bar [currentValue]="goal.completionPercentage" [showTextOnHover]="false"></app-progress-bar>
              </td>

              <!-- Due Date -->
              <td class="text-center" [class.text-danger]="(eDateUtils.getDaysRemainingTotal(goal.endDate) < 0) && !goal.complete && !goal.archived">
                <div class="date-line-height">
                  <span>{{goal.endDate | momentPipe: 'Do MMMM YYYY'}}</span>

                  <span *ngIf="goal.complete === false && goal.archived === false">
                    <br/>
                    ({{goal.dueDuration}})
                  </span>
                </div>
              </td>
            </tr>
          </ng-container>
        </table>
      </div>
      <app-pagination-server
        *ngIf="goalsPage"
        [page]="goalsPage"
        [pageSize]="pagingParams.pageSize"
        (pageSizeChanged)="changePageSize($event)"
        (pageNumberChanged)="changePageNumber($event)"
      ></app-pagination-server>
    </div>
  </div>
  <ng-template #noGoals>
    <app-empty-state [height]="'200px'" [message]="eGoalsMessages.EMPTY_STATE_TAG_GOALS"></app-empty-state>
  </ng-template>
</ng-container>
<ng-container *ngIf="state.isLoading()">
  <app-loading-state [height]="'200px'" [showMessage]="false"></app-loading-state>
</ng-container>
