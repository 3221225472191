import { Component, Input, OnInit } from '@angular/core';
import { CommonMessages } from '@app/constants/common.messages';
import { TaskActivityType } from '@app/domain/task/model/task-activity-type.enum';
import { TaskActivity } from '@app/domain/task/model/task-activity.model';
import { TaskStatus } from '@app/domain/task/model/task-status.enum';
import { TasksMessages } from '@app/domain/task/locale/tasks.messages';

@Component({
  selector: 'app-task-activity-display',
  templateUrl: './task-activity-display.component.html',
  styleUrls: ['./task-activity-display.component.scss']
})
export class TaskActivityDisplayComponent implements OnInit {
  public readonly eTaskActivityType = TaskActivityType;
  public readonly eTasksMessages = TasksMessages;
  public readonly eCommonMessages = CommonMessages;
  public readonly eTaskStatus = TaskStatus;

  @Input() activity: TaskActivity;

  constructor() {
    this.activity = undefined!;
  }

  ngOnInit(): void {
  }

}
