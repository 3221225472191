import { User, UserServerSide } from '@app/models/user/user.model';
import { ISODateString } from '@app/shared/utils/date-string.util';
import moment from 'moment';
import { TaskActivityType } from './task-activity-type.enum';
import { TaskAlignmentType } from './task-alignment-type.enum';
import { TaskStatus } from './task-status.enum';

export interface TaskActivityServerSide {
  id: number;
  type: TaskActivityType;
  user: UserServerSide;
  timestamp: ISODateString

  titlePrevious: string | null;
  titleCurrent: string | null;
  descriptionPrevious: string | null;
  descriptionCurrent: string | null;
  statusPrevious: TaskStatus | null;
  statusCurrent: TaskStatus | null;
  alignmentTypePrevious: TaskAlignmentType | null;
  alignmentTypeCurrent: TaskAlignmentType | null;
  alignmentIdPrevious: number | null;
  alignmentIdCurrent: number | null;
  dueDatePrevious: ISODateString | null;
  dueDateCurrent: ISODateString | null;
  ownersRemoved: Array<UserServerSide>;
  ownersAdded: Array<UserServerSide>;
}

export class TaskActivity {
  id: number;
  type: TaskActivityType;
  user: User;
  timestamp: Date;

  titlePrevious: string | null;
  titleCurrent: string | null;
  descriptionPrevious: string | null;
  descriptionCurrent: string | null;
  statusPrevious: TaskStatus | null;
  statusCurrent: TaskStatus | null;
  alignmentTypePrevious: TaskAlignmentType | null;
  alignmentTypeCurrent: TaskAlignmentType | null;
  alignmentIdPrevious: number | null;
  alignmentIdCurrent: number | null;
  dueDatePrevious: Date | null;
  dueDateCurrent: Date | null;
  ownersRemoved: Array<User>;
  ownersAdded: Array<User>;

  constructor(taskActivityServerSide: TaskActivityServerSide) {
    this.id = taskActivityServerSide.id;
    this.type = taskActivityServerSide.type;
    this.user = new User(taskActivityServerSide.user);
    this.timestamp = moment.utc(taskActivityServerSide.timestamp).toDate();
    this.titlePrevious = taskActivityServerSide.titlePrevious;
    this.titleCurrent = taskActivityServerSide.titleCurrent;
    this.descriptionPrevious = taskActivityServerSide.descriptionPrevious;
    this.descriptionCurrent = taskActivityServerSide.descriptionCurrent;
    this.statusPrevious = taskActivityServerSide.statusPrevious;
    this.statusCurrent = taskActivityServerSide.statusCurrent;
    this.alignmentTypePrevious = taskActivityServerSide.alignmentTypePrevious;
    this.alignmentTypeCurrent = taskActivityServerSide.alignmentTypeCurrent;
    this.alignmentIdPrevious = taskActivityServerSide.alignmentIdPrevious;
    this.alignmentIdCurrent = taskActivityServerSide.alignmentIdCurrent;
    this.dueDatePrevious = taskActivityServerSide.dueDatePrevious === null ? null : moment.utc(taskActivityServerSide.dueDatePrevious).toDate();
    this.dueDateCurrent = taskActivityServerSide.dueDateCurrent === null ? null : moment.utc(taskActivityServerSide.dueDateCurrent).toDate();
    this.ownersRemoved = taskActivityServerSide.ownersRemoved.map(o => new User(o));
    this.ownersAdded = taskActivityServerSide.ownersAdded.map(o => new User(o));
  }
}