<div class="goal-create-details-container" [class.parent-dropdown-closed]="!parentDropdownOpen" [class.datepicker-open]="datepickerOpen">
  <ng-container *ngIf="parentDropdownOpen; else templateDisplayValues">
    <div class="card no-margin">
      <div class="content">
        <div class="row-title">
          <div class="frankli-form-group" [class.frankli-form-group-invalid]="submitted && controlTitle.invalid" [class.frankli-form-group-filled]="controlTitle.value">
            <label for="goalTitle" translate>{{eGoalsMessages.OBJECTIVE}}</label>
            <input type="text" id="goalTitle" [formControl]="controlTitle" class="form-control"/>
            <div class="frankli-form-group-alert">
              <div *ngIf="controlTitle.hasError('maxlength')" translate [translateParams]="{ maxValue: eGoalCreateValidationLimits.title.max }">{{eCommonMessages.FORM_MAX_CHARACTER_VALUE}}</div>
              <ng-container *ngIf="submitted">
                <div *ngIf="controlTitle.hasError('required')" translate>{{eGoalsMessages.GOAL_CREATE_DETAILS_VALIDATION_OBJECTIVE_REQUIRED}}</div>
                <div *ngIf="controlTitle.hasError('minlength')" translate [translateParams]="{ minValue: eGoalCreateValidationLimits.title.min }">{{eCommonMessages.FORM_MIN_CHARACTER_VALUE}}</div>
              </ng-container>
            </div>
          </div>
        </div>
        <div class="row-date-visibility">
          <div class="row">
            <div class="col-sm-6 col-xs-12">
              <div class="frankli-form-group frankli-form-group-filled" [class.frankli-form-group-invalid]="submitted && controlEndDate.invalid">
                <label for="goalEndDate" translate>{{eGoalsMessages.DUE_DATE}}</label>
                <app-date-picker [name]="'goalEndDate'" [formControl]="controlEndDate" [displayFormat]="dateDisplayFormat" [disableUntil]="minDueDate" (calendarToggle)="onCalendarToggle($event)"></app-date-picker>
                <div class="frankli-form-group-alert">
                  <ng-container *ngIf="submitted">
                    <div *ngIf="controlEndDate.hasError('required')" translate>{{eGoalsMessages.GOAL_CREATE_DETAILS_VALIDATION_DUE_DATE_REQUIRED}}</div>
                    <div *ngIf="controlEndDate.hasError('mindate')" translate>{{eCommonMessages.CANNOT_BE_IN_THE_PAST}}</div>
                  </ng-container>
                </div>
              </div>
            </div>
            <div class="col-sm-6 col-xs-12">
              <div class="frankli-form-group frankli-form-group-filled" [class.frankli-form-group-invalid]="submitted && controlVisibility.invalid">
                <label for="goalVisibility">
                  <span translate>{{eGoalsMessages.VISIBILITY}}</span>
                  <span class="fal fa-fw fa-question-circle m-left-5" [tooltip]="tooltipVisibility" placement="bottom" container="body"></span>
                </label>
                <app-button-group [formControl]="controlVisibility" [options]="visbilityOptions" [consistentWidths]="true"></app-button-group>
                <div class="frankli-form-group-alert">
                  <ng-container *ngIf="submitted">
                    <div *ngIf="controlVisibility.hasError('required')" translate>{{eGoalsMessages.GOAL_CREATE_DETAILS_VALIDATION_VISIBILITY_REQUIRED}}</div>
                  </ng-container>
                </div>
              </div>
              <ng-template #tooltipVisibility>
                <div class="text-left">
                  <div [innerHtml]="eGoalsMessages.GOAL_CREATE_DETAILS_EXPLANATION_VISIBILTIY_PUBLIC | translate"></div>
                  <div [innerHtml]="eGoalsMessages.GOAL_CREATE_DETAILS_EXPLANATION_VISIBILTIY_PRIVATE | translate"></div>
                </div>
              </ng-template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-template #templateDisplayValues>
    <div class="display-values" (click)="setParentDropdown(true)">
      <div class="display-title" [class.display-invalid]="((submitted && controlTitle.invalid) || (submittedDraft && controlTitle.hasError('maxlength')))" translate>{{controlTitle.value ? controlTitle.value : eGoalsMessages.GOAL_CREATE_DETAILS_EMPTY_STATE_TITLE}}</div>
      <div class="display-due-date" [class.due-set]="controlEndDate.value" [class.display-invalid]="submitted && controlEndDate.invalid" translate>{{controlEndDate.value ? (controlEndDate.value | date) : eGoalsMessages.GOAL_CREATE_DETAILS_EMPTY_STATE_DUE_DATE}}</div>
      <div class="display-visibility" [class.display-invalid]="submitted && controlVisibility.invalid">
        <app-goal-visibility-display [visibility]="controlVisibility.value"></app-goal-visibility-display>
      </div>
    </div>
  </ng-template>
</div>
