import { MockService } from '@app/mock/api/mock-service';
import { NewFeature } from '@app/models/new-feature/new-feature.model';
import { mockNewFeatures } from '@app/mock/api/data/mockNewFeatures';
import { Injectable } from '@angular/core';
import { HttpRequest } from '@angular/common/http';
import { NewFeatureService } from '@app/shared/api/interfaces/new-feature.service';
import { Observable } from 'rxjs';
import { sanitizeUrl } from '@app/shared/utils/helpers';

@Injectable()
export class NewFeatureMockService implements MockService, NewFeatureService {
    handleRoute(req: HttpRequest<any>): any {
        const url = sanitizeUrl(req.urlWithParams);

        switch (true) {
            case url.endsWith('api/new-features'):
                return this.getAllNewFeatures();
            case url.match(/api\/new-features\/\d+$/) !== null:
                const newFeatureId = +url.split('/')[2];
                return this.getNewFeatureById(newFeatureId);
        }
    }

    getAllNewFeatures(): NewFeature[] {
        return mockNewFeatures;
    }

    getNewFeatureById(id: number): Observable<NewFeature> | NewFeature {
        return mockNewFeatures.find(f => f.id === id);
    }

    archiveNewFeature(id: number): Observable<NewFeature> {
        return undefined;
    }

    createNewFeature(newFeature: NewFeature): Observable<NewFeature> {
        return undefined;
    }

    deleteNewFeature(id: number): Observable<null> {
        return undefined;
    }

    markAllAsRead(): Observable<NewFeature[]> {
        return undefined;
    }

    unarchiveNewFeature(id: number): Observable<NewFeature> {
        return undefined;
    }

    updateNewFeature(newFeature: NewFeature): Observable<NewFeature> {
        return undefined;
    }
}