import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { RoleApplicationConfiguration } from '@app/domain/role_application_configuration/model/role-application-configuration.model';
import { CreateRoleApplicationConfiguration } from '@app/domain/role_application_configuration/model/create-role-application-configuration.model';
import { IRoleApplicationConfigurationAPI } from '@app/domain/role_application_configuration/api/role-application-configuration-api.model';
import { Page } from '@app/models/api/page.model';
import { PagingParams } from '@app/models/api/paging-params.model';
import { SortingParams } from '@app/models/api/sorting-params.model';
import { ApiUtils } from '@app/shared/utils/api.utils';

@Injectable()
export class RoleApplicationConfigurationAPIService implements IRoleApplicationConfigurationAPI {

  private readonly BASE_URL = 'api/role-application-configuration';

  constructor(
    private http: HttpClient
  ) { }

  get(ids: number[]): Observable<RoleApplicationConfiguration[]> {
    const url = `${this.BASE_URL}`;

    let params = new HttpParams();

    if (ids) {
      ids.forEach(id => {
        params = params.append('id', id);
      });
    }

    return this.http.get<RoleApplicationConfiguration[]>(url, { params: params });
  }

  getPaginated(pagingParams: PagingParams, sortingParams: SortingParams, ids: number[]): Observable<Page<RoleApplicationConfiguration>> {
    const url = `${this.BASE_URL}/paged`;

    let params = ApiUtils.createPageAndSortParams(pagingParams, sortingParams);

    if (ids) {
      ids.forEach(id => {
        params = params.append('id', id);
      });
    }

    return this.http.get<Page<RoleApplicationConfiguration>>(url, { params: params });
  }

  create(createRoleApplicationConfigurationDto: CreateRoleApplicationConfiguration): Observable<RoleApplicationConfiguration> {
    const url = `${this.BASE_URL}`;
    return this.http.post<RoleApplicationConfiguration>(url, createRoleApplicationConfigurationDto);
  }

  update(id: number, createRoleApplicationConfigurationDto: CreateRoleApplicationConfiguration): Observable<RoleApplicationConfiguration> {
    const url = `${this.BASE_URL}/${id}`;
    return this.http.put<RoleApplicationConfiguration>(url, createRoleApplicationConfigurationDto);
  }

  delete(id: number): Observable<RoleApplicationConfiguration> {
    const url = `${this.BASE_URL}/${id}`;
    return this.http.delete<RoleApplicationConfiguration>(url);
  }

  getApplicableConfigurationForRole(roleId: number): Observable<RoleApplicationConfiguration> {
    const url = `${this.BASE_URL}/most-relevant/${roleId}`;
    return this.http.get<RoleApplicationConfiguration>(url);
  }
}
