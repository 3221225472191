<div class="container-meeting-files">
  <app-state [state]="state">
    <ng-container *ngIf="!state.loading && !state.error">
      <ng-container *ngIf="meetingFiles.length > 0; else templateEmptyState">
        <div class="area-file-list">
          <ng-container *ngFor="let fileItem of meetingFiles; index as index;">
            <div class="wrapper-file-item">
              <div class="area-file-name">
                <span>{{fileItem.name}}</span>
              </div>
              <div class="area-file-actions">
                <ng-container *ngIf="!state.filesDeleting.includes(fileItem.name); else templateDeletingFile;">
                  <app-table-action-icon [icon]="'fa-cloud-download'" [tooltipText]="eCommonMessages.DOWNLOAD" (click)="startDownloadingFile(fileItem)"></app-table-action-icon>
                  <app-table-action-icon [icon]="'fa-trash'" [tooltipText]="eCommonMessages.DELETE" (click)="startDeletingFile(fileItem)"></app-table-action-icon>
                </ng-container>
                <ng-template #templateDeletingFile>
                  <div class="p-left-30 p-right-30">
                    <span class="fal fa-fw fa-spinner fa-spin"></span>
                  </div>
                </ng-template>
              </div>
            </div>
          </ng-container>
        </div>

        <ng-container *ngIf="canAddFiles">
          <div class="text-center p-top-15">
            <app-button [type]="eButtonType.GREEN_INVERTED" (onClick)="openFilePicker()" [loading]="state.uploadingFile">
              <span class="fal fa-fw fa-upload"></span>
              <span class="m-right-5"></span>
              <span translate>{{eCommonMessages.UPLOAD_FILE}}</span>
            </app-button>
          </div>
        </ng-container>
      </ng-container>

      <ng-template #templateEmptyState>
        <app-empty-state [height]="'200px'" [message]="eOneToOneMessages.NO_FILES" [secondaryButtonShown]="canAddFiles" [secondaryButtonText]="eCommonMessages.UPLOAD_FILE" (secondaryButtonPressed)="openFilePicker()"></app-empty-state>
      </ng-template>
    
      <input style="display: none" type="file" (change)="onAddFile($event)" #filePicker/>
    </ng-container>
  </app-state>
</div>