<span class="priority-display-container">
  <ng-container *ngIf="priority !== eGoalPriority.NOT_SET; else templateNotSet">
    <span class="display-priority-icon" [class]="'priority-' + priority" [tooltip]="priorityTooltip | translate" [triggers]="globals.tooltipTriggers" placement="bottom">
      <span translate>{{priorityTitleShort}}</span>
    </span>
  </ng-container>
  <ng-template #templateNotSet>
    <span class="display-not-set">
      <app-pill [type]="ePillType.GRAY">
        <span translate>{{eCommonMessages.NOT_SET}}</span>
      </app-pill>
    </span>
  </ng-template>
</span>