import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-goals',
  template: '<router-outlet></router-outlet>'
})
export class GoalsComponent {

  constructor(private route: ActivatedRoute) { }

}
