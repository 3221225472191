import { Component, Input, OnInit } from '@angular/core';
import { CommonMessages } from '@app/constants/common.messages';
import { AssessmentGroupInfoFiltered } from '@app/models/evaluation/assessment-group-info.model';
import { EvaluationCycle } from '@app/models/evaluation/evaluation-cycle.model';
import { EvaluationFeedbackRequestState } from '@app/models/evaluation/evaluation-feedback-request-state.model';
import { EvaluationFeedbackRequestType } from '@app/models/evaluation/evaluation-feedback-request-type.model';
import { EvaluationFeedbackRequest } from '@app/models/evaluation/evaluation-feedback-request.model';
import { EvaluationModuleType } from '@app/models/evaluation/evaluation-module-type.model';
import { IState } from '@app/models/state/state.model';
import { SurveyQuestionType } from '@app/models/survey-question-type.enum';
import { EvaluationCycleAPIService } from '@app/shared/api/evaluation-cycle.api.service';
import { Globals } from '@app/shared/globals/globals';

@Component({
  selector: 'app-information-sidebar-reviews-manager-review',
  templateUrl: './information-sidebar-reviews-manager-review.component.html',
  styleUrls: ['./information-sidebar-reviews-manager-review.component.scss']
})
export class InformationSidebarReviewsManagerReviewComponent implements OnInit {

  public readonly eEvaluationFeedbackRequestState = EvaluationFeedbackRequestState;
  public readonly eEvaluationModuleType = EvaluationModuleType;
  public readonly eSurveyQuestionType = SurveyQuestionType;
  public readonly eCommonMessages = CommonMessages;

  @Input() cycle: EvaluationCycle;
  @Input() userIds: number[];
  @Input() userIdsManaging: number[];
  @Input() assessmentGroupsFiltered: AssessmentGroupInfoFiltered;
  
  state: IState;
  managerReviews: EvaluationFeedbackRequest[];

  constructor(
    public globals: Globals,
    private evaluationCycleAPIService: EvaluationCycleAPIService
  ) {
    this.cycle = undefined;
    this.userIds = [];
    this.userIdsManaging = [];

    this.state = {
      loading: true,
      error: false,
      errorMessage: ''
    };

    this.managerReviews = [];
  }

  ngOnInit(): void {
    this.getData();
  }

  doError(message: string): void {
    this.state.loading = false;
    this.state.error = true;
    this.state.errorMessage = message;
  }

  getData(): void {
    if (!this.cycle || !this.userIds || this.userIds.length === 0) return this.doError('No data');

    this.getRequest();
  }

  getRequest(): void {
    this.evaluationCycleAPIService.getEvaluationUserByCycleIdAndUserId(this.cycle.id, this.userIds[0])
      .toPromise()
      .then(evaluationUser => {
        this.managerReviews = evaluationUser.requestsAboutUser.filter(request => request.type === EvaluationFeedbackRequestType.MANAGER_REVIEW && [EvaluationFeedbackRequestState.APPROVED, EvaluationFeedbackRequestState.COMPLETED, EvaluationFeedbackRequestState.PENDING_PEER].includes(request.state));
        if (this.managerReviews.length === 0) {
          this.doError('No manager reviews found');
        }
        this.state.loading = false;
      });
  }

}
