<div class="container-qualifications">
  <app-state [state]="state">
    <ng-container *ngIf="!state.loading && !state.error;">
      <div class="content">
        <app-header [title]="eQualificationMessages.QUALIFICATIONS" [description]="eQualificationMessages.QUALIFICATIONS_DESCRIPTION" [helpLink]="'https://help.frankli.io/en/articles/6522341'"></app-header>

        <app-qualification-select></app-qualification-select>
      </div>
    </ng-container>
  </app-state>
</div>
