import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-goals-modal-header',
  templateUrl: './goals-modal-header.component.html',
  styleUrls: ['./goals-modal-header.component.scss']
})
export class GoalsModalHeaderComponent implements OnInit {
  @Input() modal: any;
  @Input() headerText: string;
  constructor(private router: Router) { }

  ngOnInit() { }
  showTour() {
    (<any>window).Intercom('startTour', 104908);
  }

}
