<button class="frankli-button" [class.frankli-button-small]="small" [ngClass]="'button-type-' + type" [class.button-disabled]="disabled" (click)="clickButton($event)" [tooltip]="tooltipText ? templateTooltip : undefined" [triggers]="tooltipTriggers" [placement]="tooltipPlacement" [container]="tooltipContainer" [style.width]="buttonWidth">
  <ng-template #templateTooltip>
    <div class="text-left">{{tooltipText}}</div>
  </ng-template>
  <ng-container *ngIf="!loading; else templateLoading">
    <ng-content></ng-content>
  </ng-container>
  <ng-template #templateLoading>
    <span class="fas fa-fw fa-spinner fa-spin"></span>
  </ng-template>
</button>