<div class="goal-update-container">
  <ng-container *ngIf="goal && !goal.complete && !goal.archived && showControls">
    <div [class.grayed-out]="editing">
      <div class="display-goal-status">
        <div class="metadata-label">
          <span class="fal fa-fw fa-flag"></span>
          <span translate>{{eGoalsMessages.CURRENT_STATUS}}</span>
        </div>

        <div class="metadata-control-indent">
          <div class="row p-bottom-10">
            <div class="col-xs-12 col-sm-12 col-md-8 col-lg-6">
              <app-button-group [formControl]="controlStatus" [options]="formStatusOptions" [consistentWidths]="true"></app-button-group>
            </div>
          </div>
        </div>

        <div class="frankli-form-group-alert"></div>
      </div>

      <div class="display-goal-key-results">
        <div class="metadata-label">
          <span class="fal fa-fw fa-list"></span>
          <span translate>{{eGoalsMessages.KEY_RESULTS}}</span>
        </div>

        <div class="row m-left-0 m-right-0 kr-headings">
          <div class="col-kr-index"></div>
          <div class="col-kr-data">
            <div class="row m-left-0 m-right-0">
              <div class="col-kr-title" translate>{{eGoalsMessages.KEY_RESULT}}</div>
              <div class="col-kr-start" translate>{{eGoalsMessages.START_VALUE}}</div>
              <div class="col-kr-current" translate>{{eGoalsMessages.CURRENT_VALUE}}</div>
              <div class="col-kr-slash"></div>
              <div class="col-kr-goal" translate>{{eGoalsMessages.GOAL_VALUE}}</div>
            </div>
          </div>
        </div>

        <!-- TODO: Each iteration should be it's own component to fix abstractControl type errors -->
        <ng-container *ngFor="let keyResult of controlKeyResultsList; index as i">
          <div class="row kr-row" [class.kr-binary]="keyResult.controls.type.value === eGoalKeyResultType.BINARY">
            <div class="col-kr-index">
              <span>{{i+1}}.</span>
            </div>
  
            <div class="col-kr-data">
              <div class="row m-left-0 m-right-0">
                <div class="col-kr-title kr-result">
                  <span class="kr-title">{{keyResult.controls.result.value}}</span>
                </div>
      
                <ng-container [ngSwitch]="keyResult.controls.type.value">
                  <!-- Incomplete / Complete -->
                  <ng-container *ngIf="keyResult.controls.type.value === eGoalKeyResultType.BINARY">
                    <div class="col-kr-start"></div>
        
                    <div class="col-kr-current">
                      <div class="frankli-form-group">
                        <select class="form-control" [formControl]="keyResult.controls.measureCurrentValue">
                          <option [ngValue]="0" translate>{{eGoalsMessages.INCOMPLETE}}</option>
                          <option [ngValue]="1" translate>{{eGoalsMessages.COMPLETE}}</option>
                        </select>
                      </div>
                    </div>
      
                    <div class="col-kr-slash"></div>
                    <div class="col-kr-goal"></div>
                    <div class="col-kr-progress"></div>
                  </ng-container>
      
                  <!-- Other Current / Goal Values -->
                  <ng-container *ngIf="keyResult.controls.type.value !== eGoalKeyResultType.BINARY">
                    <div class="col-kr-start">
                      <div class="frankli-form-group">
                        <span *ngIf="keyResult.controls.measureUnitPlacement.value === eGoalKeyResultMeasureUnitPlacement.BEFORE">{{keyResult.controls.measureUnit.value}}&nbsp;</span>
                        <span>{{ keyResult.controls.measureStartValue.value }}</span>
                        <span *ngIf="keyResult.controls.measureUnitPlacement.value === eGoalKeyResultMeasureUnitPlacement.AFTER">&nbsp;{{keyResult.controls.measureUnit.value}}</span>
                      </div>
                    </div>
      
                    <div class="col-kr-current">
                      <div class="frankli-form-group" [class.frankli-form-group-invalid]="keyResult.controls.measureCurrentValue.invalid && state.submitted">
                        <input id="current-value" class="form-control" [formControl]="keyResult.controls.measureCurrentValue" [placeholder]="eGoalsMessages.CURRENT_VALUE | translate" />

                        <div class="frankli-form-group-alert">
                          <ng-container *ngIf="keyResult.controls.measureCurrentValue.invalid && state.submitted">
                            <div *ngIf="keyResult.controls.measureCurrentValue.hasError('required')" translate>{{eGoalsMessages.GOAL_CREATE_KEY_RESULTS_VALIDATION_CURRENT_VALUE_REQUIRED}}</div>
                            <div *ngIf="keyResult.controls.measureCurrentValue.hasError('forbiddenCharacter')" translate>{{eGoalsMessages.GOAL_CREATE_KEY_RESULTS_VALIDATION_CURRENT_VALUE_FORBIDDEN_CHARACTER}}</div>
                          </ng-container>
                        </div>
                      </div>
                    </div>
      
                    <div class="col-kr-slash">
                      <span>/</span>
                    </div>
      
                    <div class="col-kr-goal">
                      <div class="frankli-form-group" [ngSwitch]="keyResult.controls.measureUnitPlacement.value">
                        <span *ngSwitchCase="eGoalKeyResultMeasureUnitPlacement.BEFORE">{{keyResult.controls.measureUnit.value}} {{ keyResult.controls.measureGoalValue.value }}</span>
                        <span *ngSwitchCase="eGoalKeyResultMeasureUnitPlacement.AFTER">{{ keyResult.controls.measureGoalValue.value }} {{keyResult.controls.measureUnit.value}}</span>
                      </div>
                    </div>
  
                    <div class="col-kr-progress text-nowrap">
                      <app-progress-bar [startValue]="keyResult.controls.measureStartValue.value" [targetValue]="keyResult.controls.measureGoalValue.value" [currentValue]="keyResult.controls.measureCurrentValue.value" [unitDetail]="keyResult.controls.measureUnit.value" [showOverflowText]="true"></app-progress-bar>
                    </div>
                  </ng-container>
                </ng-container>
              </div>
            </div>
          </div>
        </ng-container>

        <div class="frankli-form-group-alert"></div>
      </div>

      <div class="display-goal-progress">
        <div class="metadata-label">
          <span class="fal fa-fw fa-badge-check"></span>
          <span translate>{{eGoalsMessages.PROGRESS}}</span>
        </div>

        <div class="metadata-control-indent">
          <div class="row p-bottom-10">
            <div class="col-xs-12 col-sm-12 col-md-8 col-lg-6">
              <div class="frankli-form-group frankli-form-group-filled" [class.frankli-form-group-invalid]="controlProgress.invalid">
                <app-editor-response [columnIndent]="false" [height]="200" [toolbar]="editorToolbar"
                  [enforceCharacterLimit]="false"
                  [autofocus]="false"
                  [formControl]="controlProgress" [maxCharacters]="editorMaxLengthSoft"
                  [placeholder]="eGoalsMessages.GOAL_PROGRESS_PLACEHOLDER | translate"></app-editor-response>
              </div>
              <div class="frankli-form-group-alert">
                <div *ngIf="controlProgress.hasError('required')" translate>{{eCommonMessages.FORM_PROVIDE_DETAIL}}</div>
                <div *ngIf="controlProgress.hasError('maxlength')" translate>{{eCommonMessages.TOO_MUCH_FORMATTING}}</div>
                <div *ngIf="controlProgress.hasError('softmax')" translate [translateParams]="{ minValue: editorMaxLengthSoft }">{{eCommonMessages.FORM_MIN_CHARACTER_VALUE}}</div>
              </div>
            </div>
          </div>
        </div>

        <div class="frankli-form-group-alert"></div>
      </div>

      <!-- Buttons -->
      <div class="area-action-buttons">
        <app-button [loading]="state.submitting" [type]="eButtonType.GREEN" [disabled]="controlProgress.invalid" (onClick)="updateGoal()">
          <span translate>{{eGoalsMessages.UPDATE_PROGRESS}}</span>
        </app-button>
        <app-button [loading]="state.submitting" [type]="eButtonType.BLUE" [disabled]="controlProgress.invalid" (onClick)="completeGoal()" *ngIf="!goal.complete">
          <!-- <span translate>{{eGoalsMessages.COMPLETE_GOAL}}</span> -->
          <span translate>{{eCommonMessages.COMPLETE}}</span>
          <span class="fal fa-check m-left-5"></span>
        </app-button>
      </div>

    </div>
  </ng-container>
</div>