<div class="row">
  <div class="col-xs-12">
    <strong>
      Users by time of day
    </strong>
  </div>
</div>

<ng-container *ngIf="state.isLoading()">
  <div class="p-top-15 p-bottom-15 text-center">
    <span class="fas fa-spin fa-spinner fa-2x fa-fw"></span>
  </div>
</ng-container>

<ng-container *ngIf="state.isSuccessful() && data.length > 0">

  <div class="row">
    <div class="col-xs-12">
      <table class="table-session-log-weekly-breakdown">
        <!-- <tr>
        <th>Date / Hour</th>
        <th *ngFor="let hour of [].constructor(24); let i = index">{{ (i < 10) ? ('0' + i + ':00' ) : (i + ':00' )
            }}</th>
      </tr> -->

        <tr *ngFor="let hour of [].constructor(24); let i = index;">
          <td [title]="getCellTitle(day.hours[i].totalUsers, day.totalDays, day.day, i)"
            class="table-session-log-weekly-breakdown-cell" *ngFor="let day of week.days"
            [class.background-group-100]="getAverageUsers(day.hours[i].totalUsers, day.totalDays) >= RANGE_100"
            [class.background-group-80]="getAverageUsers(day.hours[i].totalUsers, day.totalDays) < RANGE_100 && getAverageUsers(day.hours[i].totalUsers, day.totalDays) >= RANGE_80"
            [class.background-group-60]="getAverageUsers(day.hours[i].totalUsers, day.totalDays) < RANGE_80 && getAverageUsers(day.hours[i].totalUsers, day.totalDays) >= RANGE_60"
            [class.background-group-40]="getAverageUsers(day.hours[i].totalUsers, day.totalDays) < RANGE_60 && getAverageUsers(day.hours[i].totalUsers, day.totalDays) >= RANGE_40"
            [class.background-group-20]="getAverageUsers(day.hours[i].totalUsers, day.totalDays) < RANGE_40 && getAverageUsers(day.hours[i].totalUsers, day.totalDays) >= RANGE_20"
            [class.background-group-0]="getAverageUsers(day.hours[i].totalUsers, day.totalDays) < RANGE_20">
            <!-- {{getAverageUsers(day.hours[i].totalUsers, day.totalDays)}} -->
          </td>
          <td class="table-session-log-weekly-breakdown-text">{{ getHourStringByIndex(i) }}</td>
        </tr>
        <tr>
          <td *ngFor="let day of week.days">{{day.day | slice:0:3}}</td>
        </tr>

        <!-- <tr *ngFor="let day of data.days"> -->
        <!-- <th>{{day.day}}/{{month.month}}/{{year.year}}</th>
            <td (click)="sendClickEvent(hour)" *ngFor="let hour of day.hours"
              [title]="'(' + hour.hour + ':00 - ' + hour.hour + ':59) users: ' + hour.users"
              [class.cell-group-100]="hour.users >= RANGE_100"
              [class.cell-group-80]="hour.users < RANGE_100 && hour.users >= RANGE_80"
              [class.cell-group-60]="hour.users < RANGE_80 && hour.users >= RANGE_60"
              [class.cell-group-40]="hour.users < RANGE_60 && hour.users >= RANGE_40"
              [class.cell-group-20]="hour.users < RANGE_40 && hour.users >= RANGE_20"
              [class.cell-group-0]="hour.users < RANGE_20">{{showUserCount === true ? hour.users : ''}}
            </td> -->
        <!-- </tr> -->


      </table>
    </div>
  </div>

  <div class="row">
    <div class="col-xs-12">

      <table class="table-session-log-weekly-breakdown-legend">
        <tr>
          <td class="background-group-0">
            0
          </td>
          <td class="background-group-20">
            {{RANGE_20}}
          </td>
          <td class="background-group-40">
            {{RANGE_40}}
          </td>
          <td class="background-group-60">
            {{RANGE_60}}
          </td>
          <td class="background-group-80">
            {{RANGE_80}}
          </td>
          <td class="background-group-100">
            {{RANGE_100}}
          </td>
        </tr>
      </table>

    </div>
  </div>



</ng-container>