import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { GoalAnalyticsFilterParams, GoalAnalyticsItem } from '@app/models/analytics/goal-analytics.model';
import { OneToOneAnalyticsFilterParams, OneToOneAnalyticsItem } from '@app/models/analytics/one-to-one-analytics.model';
import { SurveyAnalytics } from '@app/models/analytics/survey-analytics-model';
import { ReviewAnalytics } from '@app/models/analytics/review-analytics.model';
import { IAnalyticsAPI } from './interfaces/analytics-api.model';

@Injectable()
export class AnalyticsAPIService implements IAnalyticsAPI {

  private readonly BASE_URL = '/api/statistics';

  constructor(
    private http: HttpClient
  ) { }

  // GOAL ANALYTICS

  getGoalAnalytics(filterParams: GoalAnalyticsFilterParams): Observable<GoalAnalyticsItem> {
    const url = `${this.BASE_URL}/goals`;

    let params = new HttpParams();

    // Add all props of GoalAnalyticsFilterParams to params
    Object.keys(filterParams).forEach(key => {
      if (filterParams[key]) {
        if (key === 'periodStart' || key === 'periodEnd') {
          params = params.append(key, filterParams[key].toISOString());
        } else {
          params = params.append(key, filterParams[key]);
        }
      }
    });

    return this.http.get<GoalAnalyticsItem>(url, { params: params });
  }

  getGoalReportGoalData(filterParams: GoalAnalyticsFilterParams): Observable<Blob> {
    const url = `${this.BASE_URL}/goals/report/goal-data`;

    let params = new HttpParams();

    // Add all props of OneToOneAnalyticsFilterParams to params
    Object.keys(filterParams).forEach(key => {
      if (filterParams[key]) {
        if (key === 'periodStart' || key === 'periodEnd') {
          params = params.append(key, filterParams[key].toISOString());
        } else {
          params = params.append(key, filterParams[key]);
        }
      }
    });

    return this.http.get(url, { responseType: 'blob', params: params });
  }

  getGoalReportGoalCounts(filterParams: GoalAnalyticsFilterParams): Observable<Blob> {
    const url = `${this.BASE_URL}/goals/report/goal-counts`;

    let params = new HttpParams();

    // Add all props of OneToOneAnalyticsFilterParams to params
    Object.keys(filterParams).forEach(key => {
      if (filterParams[key]) {
        if (key === 'periodStart' || key === 'periodEnd') {
          params = params.append(key, filterParams[key].toISOString());
        } else {
          params = params.append(key, filterParams[key]);
        }
      }
    });

    return this.http.get(url, { responseType: 'blob', params: params });
  }

  //  1:1 ANALYTICS

  getOneToOneAnalytics(filterParams: OneToOneAnalyticsFilterParams): Observable<OneToOneAnalyticsItem> {
    const url = `${this.BASE_URL}/one-to-ones`;

    let params = new HttpParams();

    // Add all props of OneToOneAnalyticsFilterParams to params
    Object.keys(filterParams).forEach(key => {
      if (filterParams[key]) {
        if (key === 'periodStart' || key === 'periodEnd') {
          params = params.append(key, filterParams[key].toISOString());
        } else {
          params = params.append(key, filterParams[key]);
        }
      }
    });

    return this.http.get<OneToOneAnalyticsItem>(url, { params: params });
  }

  getOneToOneOverviewReport(filterParams: OneToOneAnalyticsFilterParams): Observable<Blob> {
    const url = `${this.BASE_URL}/one-to-ones/report`;

    let params = new HttpParams();

    // Add all props of OneToOneAnalyticsFilterParams to params
    Object.keys(filterParams).forEach(key => {
      if (filterParams[key]) {
        if (key === 'periodStart' || key === 'periodEnd') {
          params = params.append(key, filterParams[key].toISOString());
        } else {
          params = params.append(key, filterParams[key]);
        }
      }
    });

    return this.http.get(url, { responseType: 'blob', params: params });
  }

  // #region - SURVEYS
  getSurveyAnalyticsOverview(): Observable<SurveyAnalytics> {
    const url = `${this.BASE_URL}/surveys`;
    const params = new HttpParams();
    return this.http.get<SurveyAnalytics>(url, {params});
  }

  getSurveyOverviewReport(): Observable<Blob> {
    const url = `${this.BASE_URL}/surveys/report`;
    return this.http.get(url, {responseType: 'blob'});
  }

  // #endregion

  // #region - REVIEW CYCLES
  getReviewAnalyticsForPeriod(periodStart: Date, periodEnd: Date): Observable<ReviewAnalytics> {
    const url = `${this.BASE_URL}/evaluations`;
    const params = new HttpParams()
      .append('periodStart', `${periodStart.toISOString()}`)
      .append('periodEnd', `${periodEnd.toISOString()}`);
    return this.http.get<ReviewAnalytics>(url, {params});
  }

  getReviewAnalyticsForSelectedCycles(selectedCycleIds: number[]): Observable<ReviewAnalytics> {
    const url = `${this.BASE_URL}/evaluations/selected-cycles`;
    return this.http.post<ReviewAnalytics>(url, selectedCycleIds);
  }

  // #endregion
}
