<div class="container text-center">
      <h1 class="p-bottom-15">Licenses for OSS used in Frankli</h1>
      <div class="scrollable-content">
        <table class="table">
          <thead>
            <tr>
              <th scope="col">Package</th>
              <td scope="col">License</td>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let p of packages">
              <th scope="row">{{ p.name }}</th>
              <td scope="row">
                <a href="{{ p.link }}" target="_blank"> {{ p.license }} </a>
              </td>
            </tr>
          </tbody>
        </table>
  </div>
</div>
