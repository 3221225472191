import { Pipe, PipeTransform } from '@angular/core';

// Converts a length of time in words to a numerical value
// e.g. 'FIFTEEN_MINUTES' => '15 Minutes'

@Pipe({
  name: 'wordMinsToNumerical'
})
export class WordMinsToNumericalPipe implements PipeTransform {

  transform(value: string): string {
    switch (value) {
      case 'FIFTEEN_MINUTES':
        return '15 Minutes';
      case 'THIRTY_MINUTES':
        return '30 Minutes';
      case 'FORTY_FIVE_MINUTES':
        return '45 Minutes';
      case 'SIXTY_MINUTES':
        return '1 Hour';
      default:
        return this.advancedTransform(value);
    }
  }
    
  // Above is a simple and quick way to do this, but doesn't adapt to new values
  advancedTransform(value): string {
    // Map words to numbers
    const wordMap: {
      [key: string]: number
    } = {
      'one': 1,
      'two': 2,
      'three': 3,
      'four': 4,
      'five': 5,
      'six': 6,
      'seven': 7,
      'eight': 8,
      'nine': 9,
      'ten': 10,
      'eleven': 11,
      'twelve': 12,
      'thirteen': 13,
      'fourteen': 14,
      'fifteen': 15,
      'sixteen': 16,
      'seventeen': 17,
      'eighteen': 18,
      'nineteen': 19,
      'twenty': 20,
      'thirty': 30,
      'forty': 40,
      'fifty': 50,
      'sixty': 60
    };
    
    const words = value.toLowerCase().split('_');
    let totalMinutes = 0;

    for (const word of words) {
      const number = wordMap[word];
      if (number) {
        totalMinutes += number;
      }
    }

    if (totalMinutes === 60) {
      return '1 hour';
    } else {
      const hours = Math.floor(totalMinutes / 60);
      const minutes = totalMinutes % 60;

      let result = '';

      if (hours > 0) {
        result += `${hours} ${hours === 1 ? 'hour' : 'hours'}`;
      }

      if (minutes > 0) {
        result += result ? ' ' : '';
        result += `${minutes} ${minutes === 1 ? 'minute' : 'minutes'}`;
      }

      return result;
    }
  }
}
