import { Component, Input, OnInit } from '@angular/core';
import { PageScheduleSentimentScaleTimelineMeetingScore } from '../information-sidebar-sentiment-scale-timeline.component';
import { SentimentScale } from '@app/domain/sentiment_scale/model/sentiment-scale.model';
import { SentimentScaleOption } from '@app/domain/sentiment_scale/model/sentiment-scale-option.model';

@Component({
  selector: 'app-information-sidebar-sentiment-scale-timeline-answer-item',
  templateUrl: './information-sidebar-sentiment-scale-timeline-answer-item.component.html',
  styleUrls: ['./information-sidebar-sentiment-scale-timeline-answer-item.component.scss']
})
export class InformationSidebarSentimentScaleTimelineAnswerItemComponent implements OnInit {

  @Input() userId: number;
  @Input() answerData?: PageScheduleSentimentScaleTimelineMeetingScore;
  @Input() sentimentScale?: SentimentScale;
  @Input() isLastMeeting: boolean;

  scoreOption: SentimentScaleOption;
  shouldInvertNumber: boolean;

  constructor() {
    this.userId = undefined;
    this.answerData = undefined;
    this.sentimentScale = undefined;
    this.isLastMeeting = false;
    this.scoreOption = undefined;
    this.shouldInvertNumber = false;
  }

  ngOnInit(): void {
    this.scoreOption = this.getScoreOption();
    if (this.scoreOption) {
      this.shouldInvertNumber = this.checkShouldInvertNumber();
    }
  }

  getScoreOption(): SentimentScaleOption {
    if (!this.answerData) { return null; }
    if (!this.sentimentScale) { return null; }
    return this.sentimentScale.options.find(option => option.id === this.answerData.answerId);
  }

  // If the colour is dark, the number should be inverted
  // If the colour is light, the number should not be inverted
  checkShouldInvertNumber(): boolean {
    const rgb = this.hexToRgb(this.scoreOption.colour);
    const brightness = this.getBrightness(rgb.r, rgb.g, rgb.b);
    // return brightness < 128;
    // return brightness < 150;
    return brightness < 200;
  }

  hexToRgb(hex: string): { r: number, g: number, b: number } {
    const bigint = parseInt(hex.substring(1), 16);
    return {
      r: (bigint >> 16) & 255,
      g: (bigint >> 8) & 255,
      b: bigint & 255
    };
  }

  getBrightness(r: number, g: number, b: number): number {
    return Math.round(((r * 299) + (g * 587) + (b * 114)) / 1000);
  }

}
