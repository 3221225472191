<div class="container-create-sentiment-scale-option">
  <div class="area-drag-handle" cdkDragHandle>
    <span class="fal fa-fw fa-ellipsis-v"></span>
  </div>

  <div class="area-score">
    <span>{{controlScore.value}}</span>
  </div>

  <div class="area-colour">
    <div class="frankli-form-group frankli-form-group-filled" [class.frankli-form-group-invalid]="submitted && controlColour.invalid">
      <label for="option-color">{{eSentimentScaleMessages.OPTION_COLOUR | translate}}</label>
      <input type="color" id="option-color" class="form-control" [formControl]="controlColour"/>
      <div class="frankli-form-group-alert">
        <ng-container *ngIf="submitted">
          <span *ngIf="controlColor.hasError('required')">{{eCommonMessages.FORM_REQUIRED}}</span>
        </ng-container>
      </div>
    </div>
  </div>

  <div class="area-details">
    <div class="frankli-form-group" [class.frankli-form-group-filled]="controlName.value" [class.frankli-form-group-invalid]="submitted && controlName.invalid">
      <label for="option-name">{{eSentimentScaleMessages.NAME | translate}}</label>
      <input type="text" id="option-name" class="form-control" [formControl]="controlName"/>
      <div class="frankli-form-group-alert">
        <ng-container *ngIf="submitted">
          <span *ngIf="controlName.hasError('required')">{{eCommonMessages.FORM_REQUIRED}}</span>
          <span *ngIf="controlName.hasError('maxlength')" translate [translateParams]="{ maxValue: 255 }">{{eCommonMessages.FORM_MAX_CHARACTER_VALUE}}</span>
        </ng-container>
      </div>
    </div>

    <div class="frankli-form-group frankli-form-group-filled" [class.frankli-form-group-invalid]="submitted && controlDescription.invalid">
      <label for="option-description">{{eSentimentScaleMessages.DESCRIPTION | translate}}</label>
      <textarea id="option-description" class="form-control noresize" [formControl]="controlDescription"></textarea>
      <div class="frankli-form-group-alert">
        <ng-container *ngIf="submitted">
          <span *ngIf="controlDescription.hasError('required')">{{eCommonMessages.FORM_REQUIRED}}</span>
          <span *ngIf="controlDescription.hasError('maxlength')" translate [translateParams]="{ maxValue: 1000 }">{{eCommonMessages.FORM_MAX_CHARACTER_VALUE}}</span>
        </ng-container>
      </div>
    </div>
  </div>

  <div class="area-actions">
    <app-table-action-icon [icon]="'fa-times'" [tooltipText]="'Remove option'" (click)="clickAction(eCreateSentimentScaleOptionComponentAction.REMOVE)"></app-table-action-icon>
  </div>
</div>