export enum PreferredContactMethod {
  NONE = 'NONE',
  IN_PERSON = 'IN_PERSON',
  EMAIL = 'EMAIL',
  PHONE = 'PHONE',
  TEXT = 'TEXT',
  MICROSOFT_TEAMS = 'MICROSOFT_TEAMS',
  SLACK = 'SLACK',
  TWITTER = 'TWITTER',
  LINKEDIN = 'LINKEDIN'
}
