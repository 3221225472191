<app-modal #modal [background]="'light'" [width]="'70%'">
  <div class="modal-body-content text-left">
    <app-state [state]="state">
      <ng-container *ngIf="!state.loading && !state.error">
        <ng-container *ngIf="(templates?.length > 0); else templateNoTalkingPointTemplates;">
          <div class="text-center p-bottom-15">
            <div class="round-search" role="search" *ngIf="templates.length > 6">
              <div class="input-group">
                <span class="input-group-addon">
                  <span class="fal fa-search"></span>
                </span>
                <input id="search" class="form-control" [formControl]="searchForm" [placeholder]="eOneToOneMessages.SEARCH_TEMPLATES | translate"/>
              </div>
            </div>
          </div>

          <ng-container *ngIf="(templatesFiltered?.length > 0); else templateNoSearchResults;">
            <div class="scroll-x">
              <div class="table-templates">
                <div class="row m-left-0 m-right-0 m-bottom-10 template-headings">
                  <div class="col-xs-6 p-left-0">
                    <div class="template-header" translate>{{eOneToOneMessages.TEMPLATE}}</div>
                  </div>
                  <div class="col-xs-6 p-right-0">
                    <div class="template-header" translate>{{eOneToOneMessages.TALKING_POINTS}}</div>
                  </div>
                </div>

                <div class="list-templates">
                  <div class="row m-left-0 m-right-0 template-clickable" *ngFor="let t of templatesFiltered" (click)="selectTemplate(t)">
                    <div class="col-xs-6 p-left-0">
                      <h5 class="standard-heading">{{t.name}}</h5>
                      <div class="standard-description">{{t.description}}</div>
                    </div>
                    <div class="col-xs-6 p-right-0">
                      <ng-container *ngIf="t.talkingPoints.length > 0; else templateNoTalkingPoints;">
                        <ul class="p-left-15">
                          <li class="template-talking-point" *ngFor="let tp of t.talkingPoints">{{tp.text}}</li>
                        </ul>
                      </ng-container>
                      <ng-template #templateNoTalkingPoints>
                        <div class="text-grey" translate>{{eOneToOneMessages.NO_TALKING_POINTS_FOR_TEMPLATE}}</div>
                      </ng-template>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>

          <ng-template #templateNoSearchResults>
            <app-empty-state [height]="'300px'" [message]="eOneToOneMessages.NO_RESULTS_FOR_TEMPLATE_SEARCH"></app-empty-state>
          </ng-template>
        </ng-container>

        <ng-template #templateNoTalkingPointTemplates>
          <app-empty-state [height]="'300px'" [message]="eOneToOneMessages.NO_TEMPLATES_FOR_COMPANY"></app-empty-state>
        </ng-template>
      </ng-container>
    </app-state>
  </div>
</app-modal>
