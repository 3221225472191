<ng-container *ngIf="goal">
  <div class="goal-dropdown-container">
    <!-- Key Results -->
    <div class="display-key-results">
      <label class="label-heading">
        <span class="fal fa-list m-right-5"></span>
        <span translate>{{eGoalsMessages.KEY_RESULTS}}</span>
      </label>
      <div class="results-list">
        <ng-container *ngIf="(goal.keyResults && (goal.keyResults.length > 0)); else noKeyResults">
          <app-key-results-list [showUnit]="false" [showUnitPlacement]="false" [showResultType]="false" [keyResults]="goal.keyResults"></app-key-results-list>
        </ng-container>
        <ng-template #noKeyResults>
          <div translate>{{eCommonMessages.NOT_SET}}</div>
        </ng-template>  
      </div>
    </div>
  
    <!-- Aligned goal -->
    <ng-container *ngIf="alignedGoal">
      <div class="display-aligned-parent">
        <label class="label-heading">
          <span class="fal fa-diagram-previous m-right-5"></span>
          <span translate>{{eGoalsMessages.ALIGNED_PARENT}}</span>
        </label>
        <div class="aligned-parent-goal">
          <ng-container [ngTemplateOutlet]="goalRow" [ngTemplateOutletContext]="{ goal: alignedGoal }"></ng-container>
        </div>
      </div>
    </ng-container>
  
    <!-- Aligned to this -->
    <div class="display-aligned-children" *ngIf="alignedGoals.length > 0">
      <label class="label-heading">
        <span class="fal fa-diagram-next m-right-5"></span>
        <span translate>{{eGoalsMessages.ALIGNED_CHILDREN}}</span>
      </label>
      <ng-container *ngFor="let g of alignedGoals">
        <div class="aligned-child-goal">
          <ng-container [ngTemplateOutlet]="goalRow" [ngTemplateOutletContext]="{ goal: g }"></ng-container>
        </div>
      </ng-container>
    </div>
  </div>
</ng-container>

<ng-template #goalRow let-goal="goal">
  <app-goal-display [goal]="goal"></app-goal-display>

  <!-- <div class="row clickable" (click)="navigateToGoal(goal.id)">
    <div class="col-xs-8">
      <span>{{goal.title}}</span>
    </div>
    <div class="col-xs-4">
      <app-progress-bar [startValue]="0" [targetValue]="100" [currentValue]="goal.completionPercentage" [showTextOnHover]="false"></app-progress-bar>
    </div>
  </div> -->
</ng-template>