<div class="frankli-form-group" [class.frankli-form-group-invalid]="inputInvalid">
  <input type="text" class="datetimepicker" readonly="readonly" autocomplete="off" [class.has-reset-icon]="viewMode === 'default'" [ngClass]="(viewMode === 'inline') ? 'form-control' : 'fake-date-input'" [title]="getTitleText()" [id]="'datetimepicker-' + index" [disabled]="disabled" (focusout)="emitFocusout()" #inputField/>
  <ng-container [ngSwitch]="viewMode">
    <ng-container *ngSwitchCase="'no-reset'" [ngTemplateOutlet]="noResetPickerTemplate"></ng-container>
    <ng-container *ngSwitchCase="'default'" [ngTemplateOutlet]="defaultPickerTemplate"></ng-container>
  </ng-container>

  <!-- Picker with no reset button -->
  <ng-template #noResetPickerTemplate>
    <div [class.editing-styles]="editing">
      <input class="form-control" readonly="readonly" [value]="valueFormatted" [placeholder]="emptyText" [disabled]="disabled"/>
    </div>
  </ng-template>

  <!-- Default picker -->
  <ng-template #defaultPickerTemplate>
    <div class="input-group"  [class.editing-styles]="editing">
      <input class="form-control" readonly="readonly" [value]="valueFormatted" [placeholder]="emptyText" [disabled]="disabled"/>
      <span *ngIf="canClearValue" [tooltip]="eDatePickerMessages.CLEAR_DATE | translate" [triggers]="globals.tooltipTriggers" placement="top" container="body" [triggers]="globals.tooltipTriggers" [class.input-group-addon-error]="invalid" class="input-group-addon clickable box-right" (click)="clearDate()">
        <span class="fal fa-undo btn-clear-date"></span>
      </span>
    </div>
  </ng-template>
</div>