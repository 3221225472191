<div class="circles form-group row">
  <div class="col-md-4 text-center">
    <span (click)="select(1)" [ngClass]="{'picked': selection === 1, 'not-picked': selection !== 1}">😢</span>
  </div>
  <div class="col-md-4 text-center">
    <span (click)="select(2)" [ngClass]="{'picked': selection === 2, 'not-picked': selection !== 2}">😐</span>
  </div>
  <div class="col-md-4 text-center">
    <span (click)="select(3)" [ngClass]="{'picked': selection === 3, 'not-picked': selection !== 3}">😃</span>
  </div>
</div>
