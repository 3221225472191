import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { PollResponse } from 'app/models/poll/poll-response.model';
import { PollService } from '@app/shared/api/interfaces/poll.service';
import { PollDraft, PollDraftServerSide } from '@app/models/poll/poll-draft/poll-draft.model';
import { map } from 'rxjs/operators';
import { UpdatePollDraftDto } from '@app/models/poll/poll-draft/update-poll-draft.dto';
import { CreatePollDraftDto } from '@app/models/poll/poll-draft/create-poll-draft.dto';
import { CreatePollSeriesDto } from '@app/models/poll/poll-series/create-poll-series.dto';
import { UpdatePollSeriesDto } from '@app/models/poll/poll-series/update-poll-series.dto';
import { PollReceivedDto, PollReceivedServerSideDto } from '@app/models/poll/poll-received.dto';
import { CreatePollResponseDto } from '@app/models/poll/create-poll-response.dto';
import { PollSeries, PollSeriesServerSide } from '@app/models/poll/poll-series/poll-series.model';

@Injectable()
export class PollAPIService implements PollService {
  private readonly BASE_URL = 'api/poll';

  constructor(
    private http: HttpClient
  ) { }

  /* Poll */

  getPollsReceived(): Observable<Array<PollReceivedDto>> {
    const url = `${this.BASE_URL}/received`;

    return this.http.get<Array<PollReceivedServerSideDto>>(url).pipe(
      map(pollReceivedServerSideDtos => pollReceivedServerSideDtos.map(pollReceivedServerSideDto => new PollReceivedDto(pollReceivedServerSideDto)))
    );
  }

  getPollReceivedByPollId(pollId: number): Observable<PollReceivedDto> {
    const url = `${this.BASE_URL}/received/${pollId}`;

    return this.http.get<PollReceivedServerSideDto>(url).pipe(
      map(pollReceivedServerSideDto => new PollReceivedDto(pollReceivedServerSideDto))
    );
  }

  createPollResponseByPollId(pollId: number, createPollResponseDto: CreatePollResponseDto): Observable<PollResponse> {
    const url = `${this.BASE_URL}/received/${pollId}`;

    return this.http.post<PollResponse>(url, createPollResponseDto);
  }

  /* Poll Series */

  // getAllPolls(): Observable<Array<Poll>> {
  //   const url = `${this.BASE_URL}`;
  //   return this.http.get<Array<Poll>>(url);
  // }

  // getPollById(id: number): Observable<Poll> {
  //   const url = `${this.BASE_URL}/${id}`;
  //   return this.http.get<Poll>(url);
  // }

  // getPollResponses(id: number): Observable<PollResponse[]> {
  //   const url = `${this.BASE_URL}/responses/${id}`;
  //   return this.http.get<PollResponse[]>(url);
  // }

  createPollSeries(createPollSeriesDto: CreatePollSeriesDto, draftId?: number): Observable<PollSeries> {
    const url = `${this.BASE_URL}/series`;

    let params = new HttpParams();
    if (draftId) {
      params = params.set('draftId', draftId);
    }

    return this.http.post<PollSeriesServerSide>(url, createPollSeriesDto, { params: params }).pipe(
      map(pollSeriesServerSide => new PollSeries(pollSeriesServerSide))
    );
  }

  updatePollSeries(id: number, updatePollSeriesDto: UpdatePollSeriesDto): Observable<PollSeries> {
    const url = `${this.BASE_URL}/series/${id}`
    return this.http.put<PollSeriesServerSide>(url, updatePollSeriesDto).pipe(
      map(pollSeriesServerSide => new PollSeries(pollSeriesServerSide))
    );
  }

  archivePollSeries(id: number): Observable<PollSeries> {
    const url = `${this.BASE_URL}/series/${id}`;
    return this.http.delete<PollSeriesServerSide>(url).pipe(
      map(pollSeriesServerSide => new PollSeries(pollSeriesServerSide))
    );
  }

  // getAllPollsPublishedToUser(): Observable<Array<Poll>> {
  //   const url = `${this.BASE_URL}/published`;
  //   return this.http.get<Array<Poll>>(url);
  // }

  exportBySeriesIdAndPollId(seriesId: number, pollId: number, exportType: 'CSV'): Observable<Blob> {
    const url = `${this.BASE_URL}/series/${seriesId}/poll/${pollId}/export`;
    const params = new HttpParams().set('type', exportType);

    return this.http.get(url, { responseType: 'blob', params: params });
  }

  getPollSeries(): Observable<Array<PollSeries>> {
    const url = `${this.BASE_URL}/series`;

    return this.http.get<Array<PollSeriesServerSide>>(url).pipe(
      map(polls => polls.map(pollSeriesServerSide => new PollSeries(pollSeriesServerSide)))
    );
  }

  getPollSeriesById(id: number): Observable<PollSeries> {
    const url = `${this.BASE_URL}/series/${id}`;
    
    return this.http.get<PollSeriesServerSide>(url).pipe(
      map(pollSeriesServerSide => new PollSeries(pollSeriesServerSide))
    );
  }

  /* Poll Drafts */

  createPollDraft(createPollDraftDto: CreatePollDraftDto): Observable<PollDraft> {
    const url = `${this.BASE_URL}/draft`;
    return this.http.post<PollDraftServerSide>(url, createPollDraftDto).pipe(
      map(draft => new PollDraft(draft))
    );
  }

  updatePollDraftById(id: number, updatePollDraftDTO: UpdatePollDraftDto): Observable<PollDraft> {
    const url = `${this.BASE_URL}/draft/${id}`;
    return this.http.put<PollDraftServerSide>(url, updatePollDraftDTO).pipe(
      map(draft => new PollDraft(draft))
    );
  }

  deletePollDraftById(id: number): Observable<PollDraft> {
    const url = `${this.BASE_URL}/draft/${id}`;
    return this.http.delete<PollDraftServerSide>(url).pipe(
      map(draft => new PollDraft(draft))
    );
  }

  getPollDraftById(id: number): Observable<PollDraft> {
    const url = `${this.BASE_URL}/draft/${id}`;
    return this.http.get<PollDraftServerSide>(url).pipe(
      map(draft => new PollDraft(draft))
    );
  }

  getPollDrafts(): Observable<Array<PollDraft>> {
    const url = `${this.BASE_URL}/draft`;
    return this.http.get<Array<PollDraftServerSide>>(url).pipe(
      map(drafts => drafts.map(draft => new PollDraft(draft)))
    );
  }

}
