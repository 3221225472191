import { CompilerOptions, enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { SentryService } from '@app/shared/utils/sentry.service';
import { MicrosoftTeamsService } from '@app/domain/microsoft/service/microsoft-teams.service';

if (environment.production) {
  enableProdMode();
}

SentryService.initSentry();
MicrosoftTeamsService.initalizeApplication();

const compilerOptions: CompilerOptions = {
  preserveWhitespaces: false
};

platformBrowserDynamic()
  .bootstrapModule(AppModule, compilerOptions)
  .then(success => console.log('AppModule bootstrap was successful'))
  .catch(err => console.error(err)); // This should catch any initialization errors
