import { CdkDragDrop, CDK_DRAG_CONFIG } from '@angular/cdk/drag-drop';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import {
  FormArray,
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms';
import { CommonMessages } from '@app/constants/common.messages';
import { GoalsMessages } from '@app/goals/goals.messages';
import { GoalKeyResultMeasureUnitPlacement } from '@app/models/goals/goal-key-result-measure-unit-placement.model';
import { GoalKeyResultType } from '@app/models/goals/goal-key-result-type.model';
import { DateUtils } from '@app/shared/utils/date.utils';
import { FrankliValidators } from '@app/shared/validators/validators';
import { IMyDate } from 'angular-mydatepicker';
import { CommonGoalCreateFormPart } from '../../goal-create-views/goal-create-main/goal-create-main.component';

export const GoalCreateResultValidationLimits = {
  result: {
    min: 1,
    max: 255
  },
  measureUnit: {
    max: 255
  },
};

export const initKeyResultFormGroup = (date?: Date): FormGroup => {
  const minDate = new Date();
  minDate.setHours(0, 0, 0, 0);

  let maxDate: Date;

  if (date) {
    maxDate = new Date(date);
  } else {
    maxDate = new Date();
  }
  maxDate.setHours(0, 0, 0, 0);
  maxDate.setDate(maxDate.getDate() + 1);

  return new FormGroup({
    id: new FormControl(
      null,
      []
    ),
    result: new FormControl(
      '',
      [Validators.required, Validators.maxLength(GoalCreateResultValidationLimits.result.max)]
    ),
    type: new FormControl(
      GoalKeyResultType.BINARY,
      [Validators.required]
    ),
    measureStartValue: new FormControl(
      0,
      [Validators.required, FrankliValidators.realNumberValidator()]
    ),
    measureCurrentValue: new FormControl(
      0,
      []
    ),
    measureGoalValue: new FormControl(
      1,
      [Validators.required, FrankliValidators.realNumberValidator()]
    ),
    endDate: new FormControl(
      null,
      [FrankliValidators.minDate(minDate), FrankliValidators.maxDate(maxDate)]
    ),
    reversed: new FormControl(
      false,
      []
    ),
    measureUnit: new FormControl(
      '',
      [Validators.maxLength(GoalCreateResultValidationLimits.measureUnit.max)]
    ),
    measureUnitPlacement: new FormControl(
      GoalKeyResultMeasureUnitPlacement.AFTER,
      []
    ),
    orderIndex: new FormControl(
      0,
      []
    )
  });
};

const DragConfig = {
  dragStartThreshold: 0,
  pointerDirectionChangeThreshold: 5,
  zIndex: 10000
};

@Component({
  selector: 'app-goal-create-part-key-results',
  templateUrl: './goal-create-part-key-results.component.html',
  styleUrls: ['./goal-create-part-key-results.component.scss'],
  providers: [{ provide: CDK_DRAG_CONFIG, useValue: DragConfig }]
})
export class GoalCreatePartKeyResultsComponent implements OnInit, CommonGoalCreateFormPart {
  public readonly eGoalsMessages = GoalsMessages;
  public readonly eCommonMessages = CommonMessages;

  @Input() controlKeyResults: FormArray;
  @Input() controlDueDate: FormControl;
  @Input() submitted: boolean;
  @Input() submittedDraft: boolean;
  @Input() parentDropdownOpen: boolean;
  @Input() minDueDate: IMyDate;
  @Input() maxDueDate: IMyDate | null;

  @Output() onSetParentDropdown: EventEmitter<boolean>;

  calendarOpen: boolean;

  get keyResultList(): FormGroup[] {
    return this.controlKeyResults.controls as FormGroup[];
  }

  constructor() {
    this.controlKeyResults = new FormArray([]);
    this.controlDueDate = new FormControl(new Date(), []);
    this.calendarOpen = false;
    this.submitted = false;
    this.submittedDraft = false;
    this.parentDropdownOpen = true;
    this.minDueDate = DateUtils.convertDateToPickerFormat(new Date());
    this.maxDueDate = null;
    this.onSetParentDropdown = new EventEmitter<boolean>();
  }

  ngOnInit(): void {
  }

  onCalendarToggle(isOpen: boolean, index: number, isLast: boolean): void {
    const resultCount = this.keyResultList.length;    
    if ((resultCount > 0) && !isLast) {
      this.calendarOpen = false;
      return;
    }

    this.calendarOpen = isOpen;
  }

  onRemoveKeyResult(index: number): void {
    this.controlKeyResults.removeAt(index);
  }

  onAddRowClick(index: number): void {
    this.addNewKeyResultAtIndex(index);
  }

  addNewKeyResultAtIndex(index: number): void {
    const goalDueDate = this.controlDueDate.value;
    const newFormGroup = goalDueDate ? initKeyResultFormGroup(goalDueDate) :  initKeyResultFormGroup();
    this.controlKeyResults.insert(index, newFormGroup);
  }

  dropDraggableKeyResult(event: CdkDragDrop<FormGroup[]>): void {
    const resultGroup = this.controlKeyResults.at(event.previousIndex) as FormGroup;
    this.controlKeyResults.removeAt(event.previousIndex);
    this.controlKeyResults.insert(event.currentIndex, resultGroup);
  }

  setParentDropdown(expanded: boolean): void {
    this.onSetParentDropdown.emit(expanded);
  }

  focus(): void {
    // TODO:
  }
}
