import { ConnectQuestionAnswered } from '@app/models/connect/question/connect-question.answered.model';
import { mockConnectQuestions } from '@app/mock/api/data/mockConnectQuestions';

export const mockCoachApplicationAnswer: ConnectQuestionAnswered = {
  id: 1,
  question: mockConnectQuestions[0],
  answer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras rhoncus erat mi, suscipit ullamcorper mauris ullamcorper rhoncus. Cras quis velit consequat, suscipit magna id, gravida lectus. Nunc fermentum, neque a sodales posuere, ligula felis interdum neque, eget tristique nulla dolor sed arcu.'
};

export const mockCoachingRequestAnswers: ConnectQuestionAnswered[] = [
  {
    id: 2,
    question: mockConnectQuestions[1],
    answer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras rhoncus erat mi, suscipit ullamcorper mauris ullamcorper rhoncus. Cras quis velit consequat, suscipit magna id, gravida lectus. Nunc fermentum, neque a sodales posuere, ligula felis interdum neque, eget tristique nulla dolor sed arcu.'
  },
  {
    id: 3,
    question: mockConnectQuestions[2],
    answer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras rhoncus erat mi, suscipit ullamcorper mauris ullamcorper rhoncus. Cras quis velit consequat, suscipit magna id, gravida lectus. Nunc fermentum, neque a sodales posuere, ligula felis interdum neque, eget tristique nulla dolor sed arcu.'
  },
  {
    id: 4,
    question: mockConnectQuestions[3],
    answer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras rhoncus erat mi, suscipit ullamcorper mauris ullamcorper rhoncus. Cras quis velit consequat, suscipit magna id, gravida lectus. Nunc fermentum, neque a sodales posuere, ligula felis interdum neque, eget tristique nulla dolor sed arcu.'
  },
  {
    id: 5,
    question: mockConnectQuestions[4],
    answer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras rhoncus erat mi, suscipit ullamcorper mauris ullamcorper rhoncus. Cras quis velit consequat, suscipit magna id, gravida lectus. Nunc fermentum, neque a sodales posuere, ligula felis interdum neque, eget tristique nulla dolor sed arcu.'
  }
];

export const mockMentoringRequestAnswers: ConnectQuestionAnswered[] = [
  {
    id: 6,
    question: mockConnectQuestions[5],
    answer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras rhoncus erat mi, suscipit ullamcorper mauris ullamcorper rhoncus. Cras quis velit consequat, suscipit magna id, gravida lectus. Nunc fermentum, neque a sodales posuere, ligula felis interdum neque, eget tristique nulla dolor sed arcu.'
  },
  {
    id: 7,
    question: mockConnectQuestions[6],
    answer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras rhoncus erat mi, suscipit ullamcorper mauris ullamcorper rhoncus. Cras quis velit consequat, suscipit magna id, gravida lectus. Nunc fermentum, neque a sodales posuere, ligula felis interdum neque, eget tristique nulla dolor sed arcu.'
  },
  {
    id: 8,
    question: mockConnectQuestions[7],
    answer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras rhoncus erat mi, suscipit ullamcorper mauris ullamcorper rhoncus. Cras quis velit consequat, suscipit magna id, gravida lectus. Nunc fermentum, neque a sodales posuere, ligula felis interdum neque, eget tristique nulla dolor sed arcu.'
  },
  {
    id: 9,
    question: mockConnectQuestions[8],
    answer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras rhoncus erat mi, suscipit ullamcorper mauris ullamcorper rhoncus. Cras quis velit consequat, suscipit magna id, gravida lectus. Nunc fermentum, neque a sodales posuere, ligula felis interdum neque, eget tristique nulla dolor sed arcu.'
  }
];

export const mockConnectQuestionsAnswered: ConnectQuestionAnswered[] = [
  mockCoachApplicationAnswer,
  ...mockCoachingRequestAnswers,
  ...mockMentoringRequestAnswers,
];