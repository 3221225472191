<app-modal #modal [background]="'light'" [width]="'80%'" (modalShown)="onModalShown($event)" (modalHidden)="onModalHidden($event)">
    <ng-container *ngIf="owner">
        <h3>{{eGoalsMessages.USERS_GOALS | translate:{userFullName: owner.firstName + ' ' + owner.lastName} }}</h3>

        <!-- Stats -->
        <ng-container *ngIf="statsState.isSuccessful()">
            <app-goal-stats [stats]="goalStats"></app-goal-stats>
        </ng-container>
        <ng-container *ngIf="statsState.isLoading()">
            <div class="text-center">
                <span class="fas fa-spin fa-spinner fa-3x fa-fw m-top-10 m-bottom-10"></span>
            </div>
        </ng-container>

        <!-- Table -->
        <div class="goal-modal-table">
            <app-goal-search-table
                    (navigatedToGoal)="hide()"
                    [hiddenFilterCategories]="hiddenFilterCategories"
                    [startDisabledColumns]="startDisabledColumns"
                    [selectedFilters]="selectedFilters">
            </app-goal-search-table>
        </div>
    </ng-container>
</app-modal>