<!-- Chart Legend -->
<div class="row m-bottom-10">
  <div class="col-xs-12">
    <table class="table-session-log-chart table-session-log-chart-legend">
      <td class="cell-group-0">
        0
      </td>
      <td class="cell-group-20">
        {{RANGE_20}} - {{RANGE_40 - 1}}
      </td>
      <td class="cell-group-40">
        {{RANGE_40}} - {{RANGE_60 - 1}}
      </td>
      <td class="cell-group-60">
        {{RANGE_60}} - {{RANGE_80 - 1}}
      </td>
      <td class="cell-group-80">
        {{RANGE_80}} - {{RANGE_100 - 1}}
      </td>
      <td class="cell-group-100">
        {{RANGE_100}}+
      </td>
    </table>
  </div>
</div>

<!-- Chart -->
<div class="row">
  <div class="col-xs-12">
    <div class="table-session-log-chart-wrapper">

      <table class="table-session-log-chart">
        <tr>
          <th>Date / Hour</th>
          <th *ngFor="let hour of [].constructor(24); let i = index">{{ (i < 10) ? ('0' + i + ':00' ) : (i + ':00' )
              }}</th>
        </tr>

        <ng-container *ngFor="let year of data">
          <ng-container *ngFor="let month of year.months">
            <ng-container *ngFor="let day of month.days">
              <tr>
                <th>{{day.day}}/{{month.month}}/{{year.year}}</th>
                <td (click)="sendClickEvent(hour)" *ngFor="let hour of day.hours"
                  [title]="'(' + hour.hour + ':00 - ' + hour.hour + ':59) users: ' + hour.users"
                  [class.cell-group-100]="hour.users >= RANGE_100"
                  [class.cell-group-80]="hour.users < RANGE_100 && hour.users >= RANGE_80"
                  [class.cell-group-60]="hour.users < RANGE_80 && hour.users >= RANGE_60"
                  [class.cell-group-40]="hour.users < RANGE_60 && hour.users >= RANGE_40"
                  [class.cell-group-20]="hour.users < RANGE_40 && hour.users >= RANGE_20"
                  [class.cell-group-0]="hour.users < RANGE_20">{{showUserCount === true ? hour.users : ''}}
                </td>
              </tr>
            </ng-container>
          </ng-container>
        </ng-container>

      </table>

    </div>
  </div>
</div>