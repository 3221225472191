import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { debounceTime } from 'rxjs/operators';
import { FilterOperator } from '@app/models/api/filter-operator.enum';
import { ParentFilter } from '@app/models/api/parent-filter.model';
import { FormControl } from '@angular/forms';
import { CriterionOperator } from '@app/models/api/criterion-operator.enum';
import { ChildFilter } from '@app/models/api/child-filter.model';
import { FilterCategory } from '@app/shared/dynamic-filter/interfaces/filter-category';
import { SelectedFilter } from '@app/shared/dynamic-filter/interfaces/selected-filter';
import { FilterOption } from '@app/shared/dynamic-filter/interfaces/filter-option';
import { FilterType } from '@app/shared/dynamic-filter/types/filter-type';
import { Subscription } from 'rxjs';
import { UniversalFilterMessages } from '@app/shared/universal-filter/universal-filter.messages';
import { TranslateService } from '@ngx-translate/core';
import { ButtonType } from '../components/inputs/button/button.component';
import { SearchMode } from './types/search-mode';
import moment from 'moment';
import { deepClone } from '../utils/helpers';

const SEARCH_MODES_WITH_CUSTOM_CONTROLS = [
  SearchMode.DATE,
  SearchMode.DATE_RANGE
];

@Component({
  selector: 'app-dynamic-filter',
  templateUrl: './dynamic-filter.component.html',
  styleUrls: ['./dynamic-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DynamicFilterComponent implements OnInit, OnDestroy {
  public readonly eUniversalFilterMessages = UniversalFilterMessages;
  public readonly eButtonType = ButtonType;

  @Input() placeholderText: string;
  @Input() searchProperties: string[] = [];
  @Input() filterCategories: FilterCategory[] = [];
  @Input() selectedFilters: SelectedFilter[] = [];
  @Input() hiddenCategoriesClientside: string[] = []; // Used to hide filters related to disabled column toggle columns
  @Input() parentFilter: ParentFilter;
  @Input() refreshOnInit: boolean;

  @Output() filtersChanged: EventEmitter<ParentFilter> = new EventEmitter<ParentFilter>();

  searchControl: FormControl = new FormControl();
  searchSubscription: Subscription;
  selectedFiltersInner: SelectedFilter[] = [];

  constructor(
    private translateService: TranslateService
  ) {
    this.refreshOnInit = true;
    this.parentFilter = {
      operator: FilterOperator.AND,
      childFilters: []
    };
    this.placeholderText = this.translateService.instant(this.eUniversalFilterMessages.DEFAULT_PLACEHOLDER);
    this.searchSubscription = this.searchControl.valueChanges.pipe(debounceTime(500)).subscribe(() => this.refreshFilters());
  }

  ngOnInit(): void {
    if (this.refreshOnInit) {
      this.selectedFiltersInner = deepClone(this.selectedFilters);

      // If any of the category items have a date or date range picker, we need to refresh their values to initialize the defaults
      let filtersToggled = false;
      const filtersWithCustomControls = this.filterCategories.filter(filter => SEARCH_MODES_WITH_CUSTOM_CONTROLS.includes(filter.searchMode));
      filtersWithCustomControls?.forEach(filter => {
        if (filter.searchMode === SearchMode.DATE_RANGE) {
          const option: FilterOption = {
            displayName: 'timePeriod',
            range: {
              min: moment().subtract(6, 'month').toDate().toISOString(),
              max: moment().toDate().toISOString()
            }
          };
          this.toggleFilterOption(filter, option);
          filtersToggled = true;
        }
      });
  
      setTimeout(() => {
        if (!filtersToggled) {
          this.refreshFilters();
        }
      }, 10);
    }
  }

  ngOnDestroy(): void {
    this.searchSubscription.unsubscribe();
  }

  getSelectedFiltersForCategory(category: FilterCategory): SelectedFilter[] {
    return this.selectedFiltersInner.filter(f => f.category.fieldName === category.fieldName);
  }

  toggleFilterOption(category: FilterCategory, option: FilterOption): void {
    let selectedFilter = undefined;

    if (SEARCH_MODES_WITH_CUSTOM_CONTROLS.includes(category.searchMode)) {
      this.selectedFiltersInner = this.selectedFiltersInner.filter(sf => sf.category.fieldName !== category.fieldName);
      this.selectedFiltersInner.push({ category, option });
    } else {
      selectedFilter = this.selectedFiltersInner.find(sf => sf.option.value === option.value && sf.category.fieldName === category.fieldName && sf.option.range === option.range);
      if (selectedFilter) {
        this.selectedFiltersInner = this.selectedFiltersInner.filter(f => f !== selectedFilter);
      } else {
        this.selectedFiltersInner.push({ category, option });
      }
    }

    this.refreshFilters();
  }

  resetFilters(): void {
    this.selectedFiltersInner = deepClone(this.selectedFilters);
    this.searchControl.reset('', { emitEvent: false }); // Prevent the search from triggering when reset
    this.refreshFilters();
  }

  refreshFilters(): void {
    this.parentFilter.childFilters = this.getChildFilters();
    this.filtersChanged.emit(this.parentFilter);
  }

  private getChildFilters(): ChildFilter[] {
    let filters = [];
    filters = this.addSearchChildFilter(filters, this.searchControl.value, this.searchProperties);
    filters = this.addCombinedPropertyChildFilter(filters, this.selectedFiltersInner);
    filters = this.addIndividualPropertyChildFilter(filters, this.selectedFiltersInner);
    filters = this.addRangePropertyChildFilter(filters, this.selectedFiltersInner);
    return filters;
  }

  private addSearchChildFilter(filters: ChildFilter[], searchArgument: string, searchProperties: string[]): ChildFilter[] {
    if (!searchArgument) { return filters; }
    
    const searchChildFilter: ChildFilter = {
      operator: FilterOperator.OR,
      filterCriteria: []
    };
    
    for (const searchProp of searchProperties) {
      searchChildFilter.filterCriteria.push({
        field: searchProp,
        operator: CriterionOperator.LIKE,
        value: searchArgument
      });
    }

    return [...filters, searchChildFilter];
  }

  private addCombinedPropertyChildFilter(filters: ChildFilter[], selectedFiltersInner: SelectedFilter[]): ChildFilter[] {
    const combinedFilters = selectedFiltersInner.filter(sf => sf.category.type === FilterType.COMBINED);
    if (combinedFilters.length === 0) { return filters; }

    // Group all combined properties under one child filter
    const propertyChildFilter: ChildFilter = {
      operator: FilterOperator.AND,
      filterCriteria: []
    };

    for (const combinedFilter of combinedFilters) {
      const foundFilterForField = propertyChildFilter.filterCriteria.find(f => f.field === combinedFilter.category.fieldName);
      if (foundFilterForField) {
        foundFilterForField.values.push(combinedFilter.option.value);
      } else {
        propertyChildFilter.filterCriteria.push({
          field: combinedFilter.category.fieldName,
          operator: CriterionOperator.IN,
          values: [combinedFilter.option.value]
        });
      }
    }

    return [...filters, propertyChildFilter];
  }

  private addIndividualPropertyChildFilter(filters: ChildFilter[], selectedFiltersInner: SelectedFilter[]): ChildFilter[] {
    const individualFilters = selectedFiltersInner.filter(f => f.category.type === FilterType.INDIVIDUAL);

    if (individualFilters.length === 0) { return filters; }

    let childFilters: ChildFilter[] = [];

    // Group by field and create new child filter for each grouping (for collections)
    const fields = new Set(individualFilters.map(f => f.category.fieldName));
    for (const field of fields) {
      const individualFiltersForField = individualFilters.filter(f => f.category.fieldName === field);

      const individualChildFilter: ChildFilter = {
        operator: FilterOperator.OR,
        filterCriteria: []
      };

      for (const individualFilter of individualFiltersForField) {
        individualChildFilter.filterCriteria.push({
          field: individualFilter.category.fieldName,
          operator: CriterionOperator.CONTAINS,
          value: individualFilter.option.value
        });
      }

      childFilters = [...childFilters, individualChildFilter];
    }

    return [...filters, ...childFilters];
  }

  private addRangePropertyChildFilter(filters: ChildFilter[], selectedFiltersInner: SelectedFilter[]): ChildFilter[] {
    const rangeFilters = selectedFiltersInner.filter(f => f.category.type === FilterType.RANGE);

    if (rangeFilters.length === 0) { return filters; }

    let childFilters: ChildFilter[] = [];

    // Group by field and create new child filter for each grouping (for ranges)
    const fields = new Set(rangeFilters.map(f => f.category.fieldName));

    for (const field of fields) {
      const rangeFiltersForField = rangeFilters.filter(f => f.category.fieldName === field);

      const rangeChildFilter: ChildFilter = {
        operator: FilterOperator.OR,
        filterCriteria: []
      };

      for (const individualFilter of rangeFiltersForField) {
        // this.addRangeFilter(individualFilter, rangeChildFilter);
        if (individualFilter.option.range.min && individualFilter.option.range.max) {
          rangeChildFilter.filterCriteria.push({
            field: individualFilter.category.fieldName,
            operator: CriterionOperator.BETWEEN,
            values: [individualFilter.option.range.min, individualFilter.option.range.max]
          });
        } else if (individualFilter.option.range.min && !individualFilter.option.range.max) {
          rangeChildFilter.filterCriteria.push({
            field: individualFilter.category.fieldName,
            operator: CriterionOperator.GREATER_THAN_EQ,
            value: individualFilter.option.range.min
          });
        } else if (!individualFilter.option.range.min && individualFilter.option.range.max) {
          rangeChildFilter.filterCriteria.push({
            field: individualFilter.category.fieldName,
            operator: CriterionOperator.EQUALS,
            value: individualFilter.option.range.max
          });
        }
      }

      childFilters = [...childFilters, rangeChildFilter];
    }

    return [...filters, ...childFilters];
  }
}
