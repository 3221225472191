import {MockService} from '@app/mock/api/mock-service';
import {Observable, of} from 'rxjs';
import {mockTasks} from '@app/domain/task/mock/tasks.mock';
import {Injectable} from '@angular/core';
import {HttpRequest} from '@angular/common/http';
import {sanitizeUrl} from '@app/shared/utils/helpers';
import { CreateTaskDto } from '@app/domain/task/model/create-task.dto';
import { ITaskAPI } from '@app/domain/task/api/task-api.model';
import { Task } from '@app/domain/task/model/task.model';
import { UpdateTaskStatusDto } from '../model/update-task-status.dto';
import { CreateTaskCommentDto } from '../model/create-task-comment.dto';
import { TaskAlignmentType } from '../model/task-alignment-type.enum';
import { TaskComment } from '../model/task-comment.model';
import { TaskStatus } from '../model/task-status.enum';
import { TaskActivity } from '../model/task-activity.model';
import { TeamTaskReportOverview } from '../model/team-task-report-overview.model';
import { Page } from '@app/models/api/page.model';
import { PagingParams } from '@app/models/api/paging-params.model';
import { ParentFilter } from '@app/models/api/parent-filter.model';
import { TaskStatsScope, TaskStats } from '../component/task-stats/task-stats.component';

@Injectable()
export class TaskAPIServiceMock implements MockService, ITaskAPI {
  handleRoute(req: HttpRequest<any>): any {
    const url = sanitizeUrl(req.urlWithParams);

    // switch (true) {
    //   case url.endsWith('api/todo/incomplete'):
    // }
  }

  create(todo: CreateTaskDto): Observable<Task> {
    return of(mockTasks[0]);
  }

  update(id: number, updateTodoDto: CreateTaskDto): Observable<Task> {
    return of(mockTasks[0]);
  }

  updateStatus(id: number, updateTaskStatusDto: UpdateTaskStatusDto): Observable<Task> {
    return of(mockTasks[0]);
  }

  archive(id: number): Observable<Task> {
    return of(mockTasks[0]);
  }

  getTasks(title: string, creatorIds: number[], ownerIds: number[], statuses: TaskStatus[], alignmentTypes: TaskAlignmentType[], alignmentIds: number[], archived: boolean): Observable<Task[]> {
    return of(mockTasks);
  }

  getTaskActivity(taskId: number): Observable<TaskActivity[]> {
    return of(mockTasks[0].activity);
  }

  getTaskComments(taskId: number): Observable<TaskComment[]> {
    return of(mockTasks[0].comments);
  }

  // TODO: Return undefined for these if on demo env
  createTaskCommentByTaskId(taskId: number, createTaskCommentDto: CreateTaskCommentDto): Observable<TaskComment> {
    return of(mockTasks[0].comments[0]);
  }

  deleteTaskCommentByTaskIdAndCommentId(taskId: number, commentId: number): Observable<TaskComment> {
    return of(mockTasks[0].comments[0]);
  }

  getOwnedTasksWithPositionAlignments(): Observable<Task[]> {
    return of(mockTasks);
  }

  getTeamTaskReportOverview(rootUserId: number, depth: number, viewAsAdmin: boolean): Observable<TeamTaskReportOverview[]> {
    return of([]);
  }

  exportTeamTaskReportOverview(rootUserId: number, depth: number, viewAsAdmin: boolean): Observable<Blob> {
    return of(undefined);
  }

  getSecondarySubordinatesTasksOverview(): Observable<TeamTaskReportOverview[]> {
    return of([]);
  }

  searchTeamTaskReportOverviews(pageParams: PagingParams, parentFilter: ParentFilter, viewAsAdmin: boolean): Observable<Page<TeamTaskReportOverview>> {
    const tasks = [];
    return of({
      first: true,
      last: false,
      empty: false,
      number: 1,
      numberOfElements: tasks.length,
      content: tasks,
      pageable: null,
      size: tasks.length,
      totalElements: tasks.length,
      totalPages: 1
    });
  }

  getStatsForScope(scope: TaskStatsScope): Observable<TaskStats> {
    return of(undefined);
  }
}