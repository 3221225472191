import { LongRunningTaskEventType } from './long-running-task-event-type.model';

export class LongRunningTaskEvent implements ILongRunningTask {
  id: number;
  parentTaskId: number;
  eventType: LongRunningTaskEventType;
  message: string;
  metadata?: string;
  progress: number;
  createdAt: Date;
  updatedAt: Date;

  constructor(
    serverside: LongRunningTaskEventServerside
  ) {
    this.id = serverside.id;
    this.parentTaskId = serverside.parentTaskId;
    this.eventType = serverside.eventType;
    this.message = serverside.message;
    this.metadata = serverside.metadata;
    this.progress = serverside.progress;

    if (serverside.createdAt) {
      this.createdAt = new Date(serverside.createdAt);
    }
    if (serverside.updatedAt) {
      this.updatedAt = new Date(serverside.updatedAt);
    }
  }

  // Tasks are usually completed asynchonously, so these dates aren't always the correct order to put things in.
  public static sortByDate(events: LongRunningTaskEvent[]): LongRunningTaskEvent[] {
    return events.sort((a, b) => {
      return a.createdAt.getTime() - b.createdAt.getTime();
    });
  }
  
  public static sortByProgress(events: LongRunningTaskEvent[]): LongRunningTaskEvent[] {
    return events.sort((a, b) => {
      return a.progress - b.progress;
    });
  }

}

export interface ILongRunningTask {
  id: number;
  parentTaskId: number;
  eventType: LongRunningTaskEventType;
  message: string;
  metadata?: string;
  progress: number;
  createdAt: Date;
  updatedAt: Date;
}

export interface LongRunningTaskEventServerside {
  id: number;
  parentTaskId: number;
  eventType: LongRunningTaskEventType;
  message: string;
  metadata: string;
  progress: number;
  createdAt: string;
  updatedAt: string;
}
