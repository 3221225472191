import { Component, Input, OnInit } from '@angular/core';
import { CompanyFeatures } from '@app/models/company-features.model';
import { Role } from '@app/domain/role/model/role.model';
import { UserMinimal } from '@app/models/user/user-minimal.model';
import { Globals } from '@app/shared/globals/globals';
import { User } from '@app/models/user/user.model';

@Component({
  selector: 'app-user-display',
  templateUrl: './user-display.component.html',
  styleUrls: ['./user-display.component.scss']
})
export class UserDisplayComponent implements OnInit {
  public readonly eCompanyFeatures = CompanyFeatures;

  @Input() user: UserMinimal;
  @Input() position: Role | null;

  // Icon
  @Input() showRole: boolean;
  @Input() showIcon: boolean;
  @Input() iconColor: string;
  @Input() iconClass: string;

  constructor(
    public globals: Globals
  ) {
    this.user = undefined;
    this.position = undefined;
    this.showRole = true;
    this.showIcon = false;
    this.iconColor = '#FB946E';
    this.iconClass = 'fa-times';
  }

  ngOnInit(): void {
    this.tryGetPositionIfNotPopulated();
  }

  tryGetPositionIfNotPopulated(): void {
    if (!this.position) { return; }
    if (!this.user) { return; }

    const userFull = (this.user as User);
    if (!userFull.position) { return; }

    this.position = userFull.position;
  }
}
