import { Injectable } from '@angular/core';
import { NotifyUtils } from '@app/shared/utils/notify.utils';
import { TaskAPIService } from '../api/task-api.service';
import { TasksMessages } from '../locale/tasks.messages';
import { CreateTaskCommentDto } from '../model/create-task-comment.dto';
import { TaskComment } from '../model/task-comment.model';
import { TaskStatus } from '../model/task-status.enum';
import { UpdateTaskStatusDto } from '../model/update-task-status.dto';
import { Task } from '../model/task.model';
import { CreateTaskDto } from '../model/create-task.dto';
import { TaskAlignmentType } from '../model/task-alignment-type.enum';
import { Observable } from 'rxjs';
import { TaskActivity } from '../model/task-activity.model';

@Injectable()
export class TaskBusinessService {

  private static _archiving: number[];
  private static _updating: number[];

  public get archiving(): number[] {
    return TaskBusinessService._archiving;
  }

  public get updating(): number[] {
    return TaskBusinessService._archiving;
  }

  public get modifying(): number [] {
    return [
      ...TaskBusinessService._archiving,
      ...TaskBusinessService._updating
    ];
  }

  constructor(
    private taskAPIService: TaskAPIService,
    private notifyUtils: NotifyUtils
  ) {
    TaskBusinessService._archiving = [];
    TaskBusinessService._updating = [];
  }

  private isModifying(taskId: number): boolean {
    return this.modifying.includes(taskId);
  }

  private startUpdatingTask(taskId: number): void {
    TaskBusinessService._updating.push(taskId);
  }

  private finishUpdatingTask(taskId: number): void {
    TaskBusinessService._updating = TaskBusinessService._updating.filter(tid => tid !== taskId);
  }

  private startArchivingTask(taskId: number): void {
    TaskBusinessService._archiving.push(taskId);
  }

  private finishArchivingTask(taskId: number): void {
    TaskBusinessService._archiving = TaskBusinessService._archiving.filter(tid => tid !== taskId);
  }

  createNewTask(createTaskDto: CreateTaskDto): Promise<Task> {
    return this.taskAPIService.create(createTaskDto).toPromise()
      .then(t => {
        this.notifyUtils.notify(TasksMessages.SUCCESS_TASK_CREATED);
        return t;
      });
  }

  updateExistingTask(taskId: number, createTaskDto: CreateTaskDto): Promise<Task> {
    if (this.isModifying(taskId)) { return new Promise((resolve, reject) => reject()); }

    this.startUpdatingTask(taskId);

    return this.taskAPIService.update(taskId ,createTaskDto).toPromise()
      .then(t => {
        this.notifyUtils.notify(TasksMessages.SUCCESS_TASK_UPDATED);
        return t;
      })
      .finally(() => {
        this.finishUpdatingTask(taskId);
      });
  }

  removeTaskAlignment(task: Task): Promise<Task> {
    if (this.isModifying(task.id)) { return new Promise((resolve, reject) => reject()); }

    this.startUpdatingTask(task.id);

    const createTaskDto: CreateTaskDto = {
      title: task.title,
      description: task.description,
      dueDate: task.dueDate,
      alignmentType: TaskAlignmentType.TODO,
      alignmentId: null,
      ownerIds: task.owners.map(o => o.id),
      createdFrom: task.createdFrom
    };

    return this.taskAPIService.update(task.id ,createTaskDto).toPromise()
      .then(t => {
        this.notifyUtils.notify(TasksMessages.SUCCESS_UNLINK_TASK);
        return t;
      })
      .finally(() => {
        this.finishUpdatingTask(task.id);
      });
  }

  updateStatusOfTask(taskId: number, newStatus: TaskStatus): Promise<Task> {
    if (this.isModifying(taskId)) { return new Promise((resolve, reject) => reject()); }

    this.startUpdatingTask(taskId);

    const updateTaskStatusDto: UpdateTaskStatusDto = {
      taskStatus: newStatus
    };

    return this.taskAPIService.updateStatus(taskId, updateTaskStatusDto)
      .toPromise()
      .then(t => {
        this.notifyUtils.notify(TasksMessages.SUCCESS_TASK_UPDATED);
        return t;
      })
      .finally(() => {
        this.finishUpdatingTask(taskId);
      });
  }

  archiveTask(taskId: number): Promise<Task> {
    if (this.isModifying(taskId)) { return new Promise((resolve, reject) => reject()); }

    this.startArchivingTask(taskId);

    return this.taskAPIService.archive(taskId).toPromise()
      .then(t => {
        this.notifyUtils.notify(TasksMessages.SUCCESS_ARCHIVE_TASK);
        return t;
      })
      .finally(() => {
        this.finishArchivingTask(taskId);
      });
  }

  commentOnTask(taskId: number, comment: string): Promise<TaskComment> {
    if (this.isModifying(taskId)) { return new Promise((resolve, reject) => reject()); }

    this.startUpdatingTask(taskId);

    const createTaskCommentDto: CreateTaskCommentDto = {
      comment: comment
    };

    return this.taskAPIService.createTaskCommentByTaskId(taskId, createTaskCommentDto).toPromise()
      .then(t => {
        this.notifyUtils.notify(TasksMessages.SUCCESS_COMMENT_ADDED);
        return t;
      })
      .finally(() => {
        this.finishUpdatingTask(taskId);
      });
  }

  deleteCommentOnTask(taskId: number , commentId: number): Promise<TaskComment> {
    if (this.isModifying(taskId)) { return new Promise((resolve, reject) => reject()); }

    this.startUpdatingTask(taskId);

    const taskComment = this.taskAPIService.deleteTaskCommentByTaskIdAndCommentId(taskId, commentId).toPromise()
      .then(t => {
        this.notifyUtils.notify(TasksMessages.SUCCESS_COMMENT_DELETED);
        return t;
      })
      .finally(() => {
        this.finishUpdatingTask(taskId);
      });

    return taskComment;
  }

  getTasks(
    title: string,
    creatorIds: number[],
    ownerIds: number[],
    statuses: TaskStatus[],
    alignmentTypes: TaskAlignmentType[],
    alignmentIds: number[],
    archived: boolean | null
  ): Observable<Task[]> {
    return this.taskAPIService.getTasks(title, creatorIds, ownerIds, statuses, alignmentTypes, alignmentIds, archived);
  }

  getTaskActivity(taskId: number): Observable<TaskActivity[]> {
    return this.taskAPIService.getTaskActivity(taskId);
  }

  getTaskComments(taskId: number): Observable<TaskComment[]> {
    return this.taskAPIService.getTaskComments(taskId);
  }

  getOwnedTasksWithPositionAlignments(): Observable<Task[]> {
    return this.taskAPIService.getOwnedTasksWithPositionAlignments();
  }
}