<div class="container-review-step-summary-display" *ngIf="summary">
  <div class="top-row" (click)="toggleDropdown()">
    <div class="col-dropdown">
      <app-table-dropdown-toggle [opened]="dropdownOpen" [size]="'30px'"></app-table-dropdown-toggle>
    </div>
    <div class="col-user">
      <div class="owner-img">
        <app-profile-photo [src]="authorUser.imageUrl" [firstName]="authorUser.firstName" [lastName]="authorUser.lastName"></app-profile-photo>
      </div>
      <span class="m-right-10"></span>
      <span>Summary by {{authorUser.firstName}} {{authorUser.lastName}}</span>
    </div>
    <div class="col-submitted-date">
      <!-- This data no exist :( -->
    </div>
  </div>
  <div class="questions-list" *ngIf="dropdownOpen">
    <ng-container *ngIf="summary.shared; else templateNoResponse;">
      <div class="question-item">
        <app-rich-display [text]="summary.summaryText"></app-rich-display>
      </div>
    </ng-container>
    <ng-template #templateNoResponse>
      <app-empty-state [message]="'Response is not visible yet'" [height]="'200px'"></app-empty-state>
    </ng-template>
  </div>
</div>