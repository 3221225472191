import { UserMinimal } from '@app/models/user/user-minimal.model';
import { LongRunningTaskEvent, LongRunningTaskEventServerside } from './long-running-task-event.model';
import { LongRunningTaskType } from './long-running-task-type.model';
import { LongRunningTaskStatus } from './long-running-task-status.model';
import { LongRunningTaskEventType } from './long-running-task-event-type.model';

export class LongRunningTask implements ILongRunningTask {
  id: number;
  companyId: number;
  sourceUser: UserMinimal;
  sourceUserId: number;
  taskType: LongRunningTaskType;
  requestBody?: string;
  events: LongRunningTaskEvent[];
  status: LongRunningTaskStatus;
  createdAt: Date;
  updatedAt: Date;

  constructor(
    serverside: ILongRunningTaskServerside
  ) {
    this.id = serverside.id;
    this.companyId = serverside.companyId;
    this.sourceUserId = serverside.sourceUserId;
    this.taskType = serverside.taskType;
    this.requestBody = serverside.requestBody;
    this.status = serverside.status;
    // this.events = LongRunningTaskEvent.sortByDate(serverside.events.map(e => new LongRunningTaskEvent(e)));
    this.events = LongRunningTaskEvent.sortByProgress(serverside.events.map(e => new LongRunningTaskEvent(e)));
    this.createdAt = new Date(serverside.createdAt);
    this.updatedAt = new Date(serverside.updatedAt);
  }

  getMostRecentEvent(): LongRunningTaskEvent {
    if (!this.events || this.events.length === 0) {
      return null;
    }
    return this.events[this.events.length - 1];
  }

  getHighestProgress(): number {
    if (!this.events) { return 0; }
    if (this.events.length === 0) { return 0; }
    return Math.max(...this.events.map(e => e.progress));
  }

  getHasErroredEvents(): boolean {
    if (!this.events) { return false; }
    if (this.events.length === 0) { return false; }
    return this.events.some(e => e.eventType === LongRunningTaskEventType.EVENT_FAILED);
  }

  hasEvents(): boolean {
    if (!this.events) { return false; }
    if (this.events.length === 0) { return false; }
    return true;
  }

  getIsCompleted(): boolean {
    if (!this.events) { return false; }
    if (this.events.length === 0) { return false; }
    return this.events.some(e => e.eventType === LongRunningTaskEventType.TASK_COMPLETED);
  }

  Apply(task: LongRunningTask): LongRunningTask {
    if (!task) { return this; }
    if (task.id !== this.id) { return this; }
    if (task.companyId !== this.companyId) { return this; }
    
    this.sourceUser = task.sourceUser;
    this.sourceUserId = task.sourceUserId;
    this.taskType = task.taskType;
    this.requestBody = task.requestBody;
    this.events = task.events;
    this.status = task.status;
    this.createdAt = task.createdAt;
    this.updatedAt = task.updatedAt;

    return this;
  }
}

export interface ILongRunningTask {
  id: number;
  companyId: number;
  sourceUser: UserMinimal;
  sourceUserId: number;
  taskType: LongRunningTaskType;
  requestBody?: string;
  events: LongRunningTaskEvent[];
  status: LongRunningTaskStatus;
  createdAt: Date;
  updatedAt: Date;
}

export interface ILongRunningTaskServerside {
  id: number;
  companyId: number;
  sourceUserId: number;
  taskType: LongRunningTaskType;
  requestBody: string;
  status: LongRunningTaskStatus;
  events: LongRunningTaskEventServerside[];
  createdAt: string;
  updatedAt: string;
}
