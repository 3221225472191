<div class="goal-create-tags-container">
  <ng-container *ngIf="parentDropdownOpen; else templateDisplayValues">
    <div class="p-15">
      <div class="frankli-form-group frankli-form-group-filled">
        <app-tag-picker [formControl]="controlTags" [canSelectMultiple]="true" [customOptions]="customOptions" [showResultsFloating]="false" #tagPicker></app-tag-picker>
        <div class="frankli-form-group-alert"></div>
      </div>
    </div>
  </ng-container>
  <ng-template #templateDisplayValues>
    <ng-container *ngIf="controlTags.value && controlTags.value.length > 0">
      <div class="p-15">
        <div class="display-values" (click)="setParentDropdown(true)">
          <div class="tag-count">
            <ng-container *ngIf="(controlTags.value.length === 1); else templateDisplayMultipleKRs">
              <span translate>{{eGoalsMessages.GOAL_CREATE_TAGS_COUNT_SINGLE}}</span>
            </ng-container>
            <ng-template #templateDisplayMultipleKRs>
              <span translate [translateParams]="{count: controlTags.value.length}">{{eGoalsMessages.GOAL_CREATE_TAGS_COUNT_MULTIPLE}}</span>
            </ng-template>
          </div>
          <span class="display-check">
            <span class="fal fa-fw fa-check"></span>
          </span>
        </div>
      </div>
    </ng-container>
  </ng-template>
</div>
