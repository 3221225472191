import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NewFeature } from '@app/models/new-feature/new-feature.model';
import { Observable } from 'rxjs';
import { NewFeatureService } from '@app/shared/api/interfaces/new-feature.service';

@Injectable({
  providedIn: 'root'
})
export class NewFeatureAPIService implements NewFeatureService {
  BASE_URL = '/api/new-features';
  headers = new HttpHeaders({
    'Content-Type': 'application/json'
  });

  constructor(
    private http: HttpClient
  ) { }

  getAllNewFeatures(): Observable<NewFeature[]> {
    const url = `${this.BASE_URL}/`;
    return this.http.get<NewFeature[]>(url);
  }

  getNewFeatureById(id: number): Observable<NewFeature> {
    const url = `${this.BASE_URL}/${id}`;
    return this.http.get<NewFeature>(url);
  }

  createNewFeature(newFeature: NewFeature): Observable<NewFeature> {
    const url = `${this.BASE_URL}/create`;
    return this.http.post<NewFeature>(url, newFeature);
  }

  updateNewFeature(newFeature: NewFeature): Observable<NewFeature> {
    const url = `${this.BASE_URL}/update/${newFeature.id}`;
    return this.http.post<NewFeature>(url, newFeature);
  }

  archiveNewFeature(id: number): Observable<NewFeature> {
    const url = `${this.BASE_URL}/archive/${id}`;
    return this.http.get<NewFeature>(url);
  }

  unarchiveNewFeature(id: number): Observable<NewFeature> {
    const url = `${this.BASE_URL}/unarchive/${id}`;
    return this.http.get<NewFeature>(url);
  }

  deleteNewFeature(id: number): Observable<null> {
    const url = `${this.BASE_URL}/${id}`;
    return this.http.delete<null>(url);
  }

  markAllAsRead(): Observable<NewFeature[]> {
    const url = `${this.BASE_URL}/markasread`;
    return this.http.get<NewFeature[]>(url);
  }
}
