import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Competency } from '@app/domain/competency/model/competency.model';
import { Interest } from '@app/domain/interest/model/interest.model';
import { SkillRatingConfiguration } from '@app/domain/skill_rating/model/skill-rating-configuration.model';
import { SkillRatingOption } from '@app/domain/skill_rating/model/skill-rating-option.model';
import { SkillRating } from '@app/domain/skill_rating/model/skill-rating.model';
import { CompanyFeatures } from '@app/models/company-features.model';
import { Globals } from '@app/shared/globals/globals';

type SkillRatingItem = (Interest | Competency);

@Component({
  selector: 'app-skill-rating-display',
  templateUrl: './skill-rating-display.component.html',
  styleUrls: ['./skill-rating-display.component.scss']
})
export class SkillRatingDisplayComponent implements OnInit {

  public readonly eCompanyFeatures = CompanyFeatures;

  @Input() entity: SkillRatingItem;
  @Input() skillRating: SkillRating;
  @Input() showScore: boolean;
  @Input() canAddRating: boolean;
  @Input() skillRatingConfiguration: SkillRatingConfiguration;

  @Output() onStartRatingSkill: EventEmitter<SkillRatingItem>;

  metadata: SkillRatingOption;

  constructor(
    public globals: Globals
  ) {
    this.entity = undefined;
    this.skillRating = undefined;
    this.showScore = true;
    this.canAddRating = false;
    this.onStartRatingSkill = new EventEmitter<SkillRatingItem>();
  }

  ngOnInit(): void {
    if (this.skillRatingConfiguration && this.skillRating && this.skillRating.scoreValue !== -1) {
      this.metadata = this.skillRatingConfiguration.getOption(this.skillRating.scoreValue);
    }
  }

  startRatingSkill(): void {
    this.onStartRatingSkill.emit(this.entity);
  }
}
