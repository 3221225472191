export enum EvaluationModuleType {
  SELF_REFLECTION = 'SELF_REFLECTION',
  PEER_REVIEW = 'PEER_REVIEW',
  MANAGER_REVIEW = 'MANAGER_REVIEW',
  UPWARD_REVIEW = 'UPWARD_REVIEW'
}

// TODO: Index this by EvaluationModuleType instead of string if possible
export interface CycleStepMap<T> {
  [type: string]: T
}
