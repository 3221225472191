import {Injectable} from '@angular/core';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';
import {Subscription} from 'rxjs';
import moment from 'moment';
import {UserAPIService} from '@app/shared/api/user.api.service';
import {Globals} from '@app/shared/globals/globals';

import * as en from '../../assets/translations/en.json';
import * as esLatam from '../../assets/translations/es-latam.json';
import * as de from '../../assets/translations/de.json';

const languageKey = 'language';

@Injectable({
  providedIn: 'root',
})
export class I18nService {
  defaultLanguage!: string;
  supportedLanguages!: string[];

  private langChangeSubscription!: Subscription;

  constructor(private translateService: TranslateService, private userAPIService: UserAPIService, public globals: Globals) {
    // Embed languages to avoid extra HTTP requests
    translateService.setTranslation('en', en);
    translateService.setTranslation('es-latam', esLatam);
    translateService.setTranslation('de', de);
  }

  /**
   * Initializes i18n for the application.
   * Loads language from local storage if present, or sets default language.
   * @param defaultLanguage The default language to use.
   * @param supportedLanguages The list of supported languages.
   */
  init(defaultLanguage: string, supportedLanguages: string[]) {
    this.defaultLanguage = defaultLanguage;
    this.supportedLanguages = supportedLanguages;

    this.language = this.globals.user ? this.globals.user.configuration.preferredLocale : '';

    // Warning: this subscription will always be alive for the app's lifetime
    this.langChangeSubscription = this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
      localStorage.setItem(languageKey, event.lang);
    });
  }

  /**
   * Cleans up language change subscription.
   */
  destroy() {
    if (this.langChangeSubscription) {
      this.langChangeSubscription.unsubscribe();
    }
  }

  /**
   * Sets the current language.
   * Note: The current language is saved to the local storage.
   * If no parameter is specified, the language is loaded from local storage (if present).
   * @param language The IETF language code to set.
   */
  set language(language: string) {
    let newLanguage = language || localStorage.getItem(languageKey) || this.translateService.getBrowserCultureLang() || '';
    let isSupportedLanguage = this.supportedLanguages.includes(newLanguage);

    // If no exact match is found, search without the region
    if (newLanguage && !isSupportedLanguage) {
      newLanguage = newLanguage.split('-')[0];
      newLanguage = this.supportedLanguages.find((supportedLanguage) => supportedLanguage.startsWith(newLanguage)) || '';
      isSupportedLanguage = Boolean(newLanguage);
    }

    // Fallback if language is not supported
    if (!isSupportedLanguage || !newLanguage) {
      newLanguage = this.defaultLanguage;
    }

    language = newLanguage;
    moment.locale(language);

    // Set this language as the user's preferred language
    if (this.globals.user && this.globals.user.configuration.preferredLocale !== language) {
      this.userAPIService.updateUserPreferredLocale({ preferredLocale: language }).subscribe();
    }

    console.debug(`Language set to ${language}`);
    this.translateService.use(language);
  }

  /**
   * Gets the current language.
   * @return The current language code.
   */
  get language(): string {
    return this.translateService.currentLang;
  }

  getFlagClassForLanguage(languageCode: string): string {
    switch (languageCode) {
      case 'en':
        return 'fi-gb';
      case 'es-latam':
        return 'fi-es';
      case 'de':
        return 'fi-de';
      default:
        return '';
    }
  }
}
