import { Injectable } from '@angular/core';
import { MockService } from '@app/mock/api/mock-service';
import { HttpRequest } from '@angular/common/http';
import { MentorService } from '@app/shared/api/interfaces/connect/mentor.service';
import { Mentor } from '@app/models/connect/mentor.model';
import { Observable } from 'rxjs';
import { Globals } from '@app/shared/globals/globals';
import { sanitizeUrl } from '@app/shared/utils/helpers';
import { mockMentors } from '@app/mock/api/data/mockMentors';

@Injectable()
export class MentorMockService implements MockService, MentorService {
    constructor(private readonly globals: Globals) { }

    handleRoute(req: HttpRequest<any>): any {
        const url = sanitizeUrl(req.urlWithParams);

        switch (true) {
            case url.endsWith('api/connect/mentors/enabled'):
                return this.getEnabledMentors();
            case url.endsWith('api/connect/mentors/current-user'):
                return this.getMentorForCurrentUser();
            case url.match(/api\/connect\/mentors\/user\/\d+$/) !== null:
                const mentorId = +url.split('/')[4];
                return this.getMentorByUserId(mentorId);
        }
    }

    getEnabledMentors(): Observable<Array<Mentor>> | Mentor[] {
        return mockMentors.filter(m => m.enabled);
    }

    getMentorByUserId(mentorId: number): Observable<Mentor> | Mentor {
        return mockMentors.find(m => m.userId === mentorId);
    }

    getMentorForCurrentUser(): Observable<Mentor> | Mentor {
        return mockMentors.find(m => m.userId === this.globals.user.id) || null;
    }

    // No Ops listed below
    createMentorForCurrentUser(): Observable<Mentor> {
        return undefined;
    }

    toggleMentorEnabledForCurrentUser(): Observable<Mentor> {
        return undefined;
    }

}