import { CreateTaskDto } from '@app/domain/task/model/create-task.dto';
import { CreateGoalKeyResultDto } from './create-goal-key-result.dto';
import { GoalDistribution } from './goal-distribution.model';
import { GoalPriority } from './goal-priority.model';
import { GoalType } from './goal-type.enum';
import { GoalVisibility } from './goal-visibility.model';

export interface IGoalCreateDto {
  title: string;
  type: GoalType;
  departmentId: number | null;
  officeLocationId: number | null;
  endDate: Date;
  visibility: GoalVisibility;
  priority: GoalPriority;
  tagIds: Array<number>;
  ownerIds: Array<number>;
  keyResults: Array<CreateGoalKeyResultDto>;
  alignmentId: number | null;
  developmentNeeds: string | null;
  distribute: GoalDistribution;
  tasks: CreateTaskDto[];
  draftId?: number;
}

export class CreateGoalDto implements IGoalCreateDto {
  tasks: CreateTaskDto[];

  constructor(
    public title: string,
    public type: GoalType,
    public departmentId: number | null,
    public officeLocationId: number | null,
    public endDate: Date,
    public visibility: GoalVisibility,
    public priority: GoalPriority,
    public tagIds: Array<number>,
    public ownerIds: Array<number>,
    public keyResults: Array<CreateGoalKeyResultDto>,
    public alignmentId: number | null,
    public developmentNeeds: string | null,
    public distribute: GoalDistribution,
    public draftId?: number,
    tasks?: CreateTaskDto[]
  ) {
    // Can remove this once we remove the old goal create stuff
    // Shouldnt use classes like this because it suuucks
    this.tasks = (tasks ? tasks : []);
  }

}