<div class="create-form-container">
  <!-- Action icons from new workflow -->
  <div class="extra-actions-row">
    <div class="row m-left-0 m-right-0">
      <div class="actions-row-left">
        <div class="actions-row-pill">
          <ng-container [ngSwitch]="!!formCreate.controls.id.value">
            <ng-container *ngSwitchCase="true">
              <ng-container [ngSwitch]="controlTaskAlignmentType.value">
                <span *ngSwitchCase="eTaskAlignmentType.TODO" translate>{{eTasksMessages.UPDATE_TODO}}</span>
                <span *ngSwitchCase="eTaskAlignmentType.ONE_TO_ONE" translate>{{eTasksMessages.UPDATE_ACTION_POINT}}</span>
                <span *ngSwitchCase="eTaskAlignmentType.MILESTONE" translate>{{eTasksMessages.UPDATE_MILESTONE}}</span>
                <span *ngSwitchDefault translate>{{eTasksMessages.UPDATE_TASK}}</span>
              </ng-container>
            </ng-container>
            <ng-container *ngSwitchCase="false">
              <ng-container [ngSwitch]="controlTaskAlignmentType.value">
                <span *ngSwitchCase="eTaskAlignmentType.TODO" translate>{{eTasksMessages.CREATE_TODO}}</span>
                <span *ngSwitchCase="eTaskAlignmentType.ONE_TO_ONE" translate>{{eTasksMessages.CREATE_ACTION_POINT}}</span>
                <span *ngSwitchCase="eTaskAlignmentType.MILESTONE" translate>{{eTasksMessages.CREATE_MILESTONE}}</span>
                <span *ngSwitchDefault translate>{{eTasksMessages.CREATE_TASK}}</span>
              </ng-container>
            </ng-container>
          </ng-container>
        </div>
      </div>
      <div class="actions-row-right">
        <!-- <div class="actions-row-item" (click)="openHelpArticle()">
          <span class="fal fa-fw fa-lightbulb-on"></span>
          <span class="m-right-5"></span>
          <span translate>{{eCommonMessages.LEARN_MORE}}</span>
        </div> -->
        <div class="actions-row-item frankli-close" (click)="closeCreateModal()">
          <span class="fal fa-fw fa-times"></span>
        </div>
      </div>
    </div>
  </div>

  <div class="main-form-contents" cdk-scrollable>
    <div class="main-form">
      <div class="p-left-15 p-right-15 p-top-15">
        <!-- Title -->
        <div class="p-bottom-15">
          <div class="frankli-form-group" [class.frankli-form-group-invalid]="controlTaskTitle.invalid && submitted" [class.frankli-form-group-filled]="controlTaskTitle.value">
            <label for="title" translate>{{eTasksMessages.TITLE}}</label>
            <input id="title" class="form-control" type="text" placeholder="title" [formControl]="controlTaskTitle">
          </div>
          <div class="frankli-form-group-alert">
            <ng-container *ngIf="submitted && controlTaskTitle.invalid">
              <div *ngIf="controlTaskTitle.hasError('required')" translate>{{eTasksMessages.TITLE_VALIDATION_REQUIRED}}</div>
              <div *ngIf="controlTaskTitle.hasError('minlength')" translate [translateParams]="{minValue: eTaskConstants.TITLE_MIN_LENGTH}">{{eCommonMessages.FORM_MIN_CHARACTER_VALUE}}</div>
              <div *ngIf="controlTaskTitle.hasError('maxlength')" translate [translateParams]="{maxValue: eTaskConstants.TITLE_MAX_LENGTH}">{{eCommonMessages.FORM_MAX_CHARACTER_VALUE}}</div>
            </ng-container>
          </div>
        </div>
  
        <!-- Description -->
        <div class="p-bottom-15">
          <div class="frankli-form-group frankli-form-group-filled frankli-form-group-placeholder-shown p-relative" [class.frankli-form-group-invalid]="controlTaskDescription.invalid && submitted">
            <label for="description">
              <span translate>{{eTasksMessages.DESCRIPTION}}</span>
              <span class="m-right-5"></span>
              <span translate>({{eCommonMessages.OPTIONAL | translate}})</span>
            </label>
            <textarea id="description" class="form-control" rows="5" [formControl]="controlTaskDescription" [placeholder]="eTasksMessages.PLACEHOLDER_TASK_DESCRIPTION| translate"></textarea>
            <div class="description-character-count" *ngIf="controlTaskDescription.value">
              <span [class.text-danger]="controlTaskDescription.value.length > eTaskConstants.DESCRIPTION_MAX_LENGTH">{{controlTaskDescription.value.length}}</span>
              <span> / </span>
              <span>{{eTaskConstants.DESCRIPTION_MAX_LENGTH}}</span>
            </div>
          </div>
          <div class="frankli-form-group-alert">
            <ng-container *ngIf="submitted && controlTaskDescription.invalid">
              <div *ngIf="description.hasError('maxlength')" translate [translateParams]="{maxValue: eTaskConstants.DESCRIPTION_MAX_LENGTH}">{{eCommonMessages.FORM_MAX_CHARACTER_VALUE}}</div>
            </ng-container>
          </div>
        </div>
  
        <!-- Owners -->
        <div class="p-bottom-15">
          <div class="frankli-form-group frankli-form-group-filled frankli-form-group-placeholder-shown part-owners" [class.frankli-form-group-invalid]="submitted && controlTaskOwners.invalid">
            <label translate>{{eTasksMessages.TASK_OWNERS}}</label>
            <!-- TODO: Restrict to 1:1 participants when creating/editing action points -->
            <!-- !ownersAllowAPISearch on the old code-->
            <app-user-picker [formControl]="controlTaskOwners" [canSelectMultiple]="true" [customOptions]="ownersCustomOptions"></app-user-picker>
          </div>
          <div class="frankli-form-group-alert">
            <ng-container *ngIf="submitted && controlTaskOwners.invalid">
              <div *ngIf="ownersControl.hasError('required')" translate>{{eTasksMessages.TASK_OWNERS_VALIDATION_REQUIRED}}</div>
            </ng-container>
          </div>
        </div>
  
        <!-- Due date -->
        <div class="p-bottom-15">
          <div class="frankli-form-group frankli-form-group-filled part-due-date" [class.frankli-form-group-invalid]="controlTaskDueDate.invalid && submitted">
            <label for="dueDate">
              <span translate>{{eTasksMessages.DUE_DATE}}</span>
              <span class="m-right-5"></span>
              <span>({{eCommonMessages.OPTIONAL | translate}})</span>
            </label>
            <app-date-picker [formControl]="controlTaskDueDate" [disableUntil]="dueDateDisableUntil"></app-date-picker>
          </div>
          <div class="frankli-form-group-alert"></div>
        </div>
  
        <!-- Alignment -->
        <ng-container *ngIf="canAlign">
          <ng-container *ngIf="canChangeAlignment">
            <div class="frankli-form-group frankli-form-group-filled" [class.frankli-form-group-invalid]="controlTaskAlignmentType.invalid && submitted">
              <label for="alignmentType" translate>{{eTasksMessages.ALIGNMENT}}</label>
              <select id="alignmentType" class="form-control text-capitalize" [formControl]="controlTaskAlignmentType">
                <option [ngValue]="null" *ngIf="controlTaskAlignmentType.value === null" translate>{{eCommonMessages.SELECT_PROMPT}}</option>
                <option [ngValue]="eTaskAlignmentType.TODO" translate>{{eTasksMessages.ALIGNMENT_TODO}}</option>
                <option [ngValue]="eTaskAlignmentType.GOAL" *ngIf="globals.hasFeature(eCompanyFeatures.GOALS)" translate>{{eTasksMessages.ALIGNMENT_GOAL}}</option>
                <option [ngValue]="eTaskAlignmentType.ONE_TO_ONE" *ngIf="globals.hasFeature(eCompanyFeatures.ONE_TO_ONE)" translate>{{eTasksMessages.ALIGNMENT_ONE_TO_ONE}}</option>
                <option [ngValue]="eTaskAlignmentType.MILESTONE" *ngIf="globals.hasFeature(eCompanyFeatures.CAREERS)" translate>{{eTasksMessages.ALIGNMENT_MILESTONE}}</option>
              </select>
            </div>
  
            <div class="frankli-form-group-alert">
              <ng-container *ngIf="submitted && controlTaskAlignmentType.invalid">
                <div *ngIf="controlTaskAlignmentType.hasError('maxlength')" translate [translateParams]="{maxValue: 255}">{{eCommonMessages.FORM_MAX_CHARACTER_VALUE}}</div>
              </ng-container>
            </div>
          </ng-container>

          <ng-container [ngSwitch]="controlTaskAlignmentType.value">
            <ng-container *ngSwitchCase="eTaskAlignmentType.GOAL">
              <div class="frankli-form-group frankli-form-group-filled" [class.frankli-form-group-invalid]="controlTaskAlignmentId.invalid && submitted">
                <label for="alignmentId" translate>{{eTasksMessages.ALIGN_TO_A_GOAL}}</label>
                <app-goal-search [formControl]="controlTaskAlignedGoal" [scope]="'all'" id="alignmentId"></app-goal-search>
              </div>
              <div class="frankli-form-group-alert">
                <ng-container *ngIf="submitted && controlTaskAlignmentId.invalid">
                  <div *ngIf="controlTaskAlignmentId.hasError('required')" translate>{{eTasksMessages.SCHEDULE_VALIDATION_REQUIRED}}</div>
                </ng-container>
              </div>
            </ng-container>
        
            <ng-container *ngSwitchCase="eTaskAlignmentType.ONE_TO_ONE">
              <ng-container *ngIf="!loadingSchedules; else templateLoadingSchedules;">
                <ng-container *ngIf="oneToOneSchedules.length > 0; else templateNoSchedules">
                  <div class="frankli-form-group frankli-form-group-filled" [class.frankli-form-group-invalid]="controlTaskAlignmentId.invalid && submitted">
                    <label for="alignmentId" translate>{{eTasksMessages.ALIGN_TO_A_ONE_TO_ONE}}</label>
                    <!-- TODO: Create 1:1 schedule picker -->
                    <select id="alignmentId" class="form-control text-capitalize" [formControl]="controlTaskAlignmentId">
                      <option [ngValue]="null" *ngIf="controlTaskAlignmentId.value === null" translate>{{eCommonMessages.SELECT_PROMPT}}</option>
                      <option *ngFor="let schedule of oneToOneSchedules" [ngValue]="schedule.id">
                        <!-- <ng-container *ngIf="(schedule.manager.id === globals.user.id); else userIsParticipant;">
                          <span>[{{schedule.user.firstName}} {{schedule.user.lastName}}]</span>
                        </ng-container>
                        <ng-template #userIsParticipant>
                          <span>[{{schedule.manager.firstName}} {{schedule.manager.lastName}}]</span>
                        </ng-template> -->
                        <span>&nbsp;{{schedule.purposeTitle}}</span>
                      </option>
                    </select>
                  </div>
                  <div class="frankli-form-group-alert">
                    <ng-container *ngIf="submitted && controlTaskAlignmentId.invalid">
                      <div *ngIf="controlTaskAlignmentId.hasError('required')" translate>{{eTasksMessages.SCHEDULE_VALIDATION_REQUIRED}}</div>
                    </ng-container>
                  </div>
                </ng-container>
          
                <ng-template #templateNoSchedules>
                  <div class="text-center">
                    <span class="text-danger" [innerHtml]="eTasksMessages.SCHEDULE_VALIDATION_EMPTY | translate"></span>
                  </div>
                </ng-template>
              </ng-container>

              <ng-template #templateLoadingSchedules>
                <div class="frankli-form-group frankli-form-group-filled">
                  <label translate>{{eTasksMessages.ALIGN_TO_A_ONE_TO_ONE}}</label>
                  <div class="fake-input text-center">
                    <span class="fal fa-fw fa-spinner fa-spin"></span>
                  </div>
                </div>
              </ng-template>
            </ng-container>

            <ng-container *ngSwitchCase="eTaskAlignmentType.MILESTONE">
              <!-- Linked roles -->
              <div class="p-bottom-15" *ngIf="globals.hasFeature(eCompanyFeatures.CAREERS)">
                <div class="frankli-form-group frankli-form-group-filled" [class.frankli-form-group-invalid]="controlTaskAlignedRoles.invalid && submitted">
                  <label translate>{{eTasksMessages.ALIGN_TO_A_ROLE}}</label>
                  <app-role-picker [formControl]="controlTaskAlignedRoles" [canSelectMultiple]="true"></app-role-picker>
                </div>
                <div class="frankli-form-group-alert">
                  <ng-container *ngIf="submitted && controlTaskAlignedRoles.invalid"></ng-container>
                </div>
              </div>
            </ng-container>
          </ng-container>
        </ng-container>
      </div>
    </div>
  </div>

  <div class="primary-actions-row">
    <app-button (onClick)="onCancelCreate()" [type]="eButtonType.GREEN_INVERTED">
      <span translate>{{eCommonMessages.CANCEL}}</span>
    </app-button>
    <span class="m-right-5"></span>
    <app-button (onClick)="onConfirmCreate()">
      <span>{{(formCreate.controls.id.value ? eCommonMessages.UPDATE : eCommonMessages.CREATE) | translate}}</span>
    </app-button>
  </div>
</div>
