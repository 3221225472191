import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Page } from '@app/models/api/page.model';
import { PaginationNewMessages } from '../pagination-new/pagination-new.messages';

interface PageGroups {
  start: number[];
  middle: number[];
  end: number[];
}

@Component({
  selector: 'app-pagination-server',
  templateUrl: './pagination-server.component.html',
  styleUrls: ['./pagination-server.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PaginationServerComponent implements OnInit {
  public readonly startBound = 9;
  public readonly endBound = 9;
  public readonly startSliceSize = 3;
  public readonly endSliceSize = 3;
  public readonly middleBufferSize = 3;
  public readonly truncateThreshold = 14;
  public readonly ePaginationNewMessages = PaginationNewMessages;

  @Input() page!: Page<any>
  @Input() pageSize: number = 15;
  @Input() pageSizes: number[] = [5, 10, 15, 25, 50, 100];
  @Output() pageSizeChanged: EventEmitter<number> = new EventEmitter<number>();
  @Output() pageNumberChanged: EventEmitter<number> = new EventEmitter<number>();
  pages: number[] = [];
  pageGroups: PageGroups = {
    start: [],
    middle: [],
    end: []
  };
  isWithinStartBound: boolean = false;
  isWithinEndBound: boolean = false;

  constructor() {}

  ngOnInit(): void {
    this.pages = this.generatePagesFromTotal();
    if (this.page.totalPages > this.truncateThreshold) {
      this.pageGroups = this.createPageGroups();
    }
  }

  private generatePagesFromTotal(): number[] {
    return Array.from(Array(this.page.totalPages).keys());
  }

  private createPageGroups(): PageGroups {
    if (this.page.number < this.startBound) {
      this.isWithinStartBound = true;
    } else if (this.page.number >= this.page.totalPages - this.endBound) {
      this.isWithinEndBound = true;
    }

    const start = this.createStartGroup();
    const middle = this.createMiddleGroup();
    const end = this.createEndGroup();
    return { start, middle, end };
  }

  private createStartGroup(): number[] {
    return this.isWithinStartBound ?
      this.pages.slice(0, this.startBound + 1) :
      this.pages.slice(0, this.startSliceSize);
  }

  private createMiddleGroup(): number[] {
    return this.isWithinStartBound || this.isWithinEndBound ?
      [] :
      this.pages.slice(this.page.number - this.middleBufferSize, this.page.number + this.middleBufferSize + 1);
  }

  private createEndGroup(): number[] {
    return this.isWithinEndBound ?
      this.pages.slice(this.pages.length - this.endBound - 1, this.pages.length) :
      this.pages.slice(this.pages.length - this.endSliceSize, this.pages.length);
  }
}
