import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CompanySecondaryManagerPermission } from '@app/models/company/company-secondary-manager/company-secondary-manager-permission.model';
import { Observable } from 'rxjs';
import { CompanySecondaryManagerPermissionService } from '../interfaces/company/company-secondary-manager-permission.service';

@Injectable({
  providedIn: 'root'
})
export class CompanySecondaryManagerPermissionAPIService implements CompanySecondaryManagerPermissionService {

  private readonly BASE_URL = '/api/company/company-secondary-manager-permission';
  httpHeaders: HttpHeaders;
  constructor(private http: HttpClient) {
    this.httpHeaders = new HttpHeaders();
    this.httpHeaders.append('Content-Type', 'application/json');
   }
   getPermissions(): Observable<CompanySecondaryManagerPermission> {
     const url = this.BASE_URL;
    return this.http.get<CompanySecondaryManagerPermission>(url);
   }

   updatePermissions(permissions: CompanySecondaryManagerPermission): Observable<CompanySecondaryManagerPermission>{
    const url = this.BASE_URL;
    return this.http.put<CompanySecondaryManagerPermission>(url, permissions, { headers: this.httpHeaders })
   }

}
