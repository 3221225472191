import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Globals } from '@app/shared/globals/globals';
import { TalkingPoint } from '@app/domain/one_to_one/model/talking-point.model';
import { CommonMessages } from '@app/constants/common.messages';
import { OneToOneMessages } from '@app/domain/one_to_one/locale/one-to-one.messages';
import { TalkingPointItemAction, TalkingPointItemActionType } from '../talking-point-item/talking-point-item.component';
import { OneToOneStatus } from '../../model/one-to-one-status.model';
import { OneToOneMeetingStatus } from '../../model/one-to-one-meeting-status.model';
import { IState } from '@app/models/state/state.model';
import { OneToOneScheduleDetailsView } from '../../model/one-to-one-schedule.model';
import { OneToOneMeeting } from '../../model/one-to-one-meeting.model';

interface PageState extends IState {
  submitted: boolean;
}

export enum TalkingPointActionType {
  BUMP = 'BUMP',
  COMMENT = 'COMMENT',
  ACTION = 'ACTION'
}

export interface TalkingPointActionPayloadComment {
  talkingPointId: number;
  comment: string;
}

export interface TalkingPointActionPayloadAction {
  talkingPointId: number;
  checked: boolean;
}

export type TalkingPointAction = TalkingPointActionBump | TalkingPointActionComment | TalkingPointActionAction;

export type TalkingPointActionBump = {
  type: TalkingPointActionType.BUMP;
  payload: TalkingPoint;
}

export type TalkingPointActionComment = {
  type: TalkingPointActionType.COMMENT;
  payload: TalkingPointActionPayloadComment;
}

export type TalkingPointActionAction = {
  type: TalkingPointActionType.ACTION;
  payload: TalkingPointActionPayloadAction;
}

@Component({
  selector: 'app-talking-points',
  templateUrl: './talking-points.component.html',
  styleUrls: ['./talking-points.component.scss'],
})
export class TalkingPointsComponent implements OnChanges {
  public readonly TP_MIN_LENGTH = 10;
  public readonly TP_MAX_LENGTH = 255;

  public readonly eOneToOneMessages = OneToOneMessages;
  public readonly eCommonMessages = CommonMessages;

  @Input() schedule?: OneToOneScheduleDetailsView;
  @Input() meeting?: OneToOneMeeting;
  @Input() meetingPrevious?: OneToOneMeeting;
  @Input() talkingPoints: TalkingPoint[];
  @Input() canReorder: boolean;
  @Input() displayComments: boolean;

  @Input() creatingSchedule: boolean;

  @Output() changeEvent: EventEmitter<TalkingPoint[]>;
  @Output() onAction: EventEmitter<TalkingPointAction>;

  createForm: FormGroup;

  editPoint?: TalkingPoint;

  state: PageState;

  talkingPointsPreviousCanBump: TalkingPoint[];

  get controlCreateTitle(): FormControl {
    return this.createForm.controls.title as FormControl;
  }

  get canAction(): boolean { // Can mark talking points as actioned
    if (!this.schedule) { return false; }
    if (!this.schedule.status) { return false; }
    if (!this.meeting) { return false; }
    if (!this.meeting.status) { return false; }
    if (this.schedule.status === OneToOneStatus.ARCHIVED) { return false; }
    if (this.schedule.status === OneToOneStatus.PAUSED) { return false; }
    if (this.meeting.status === OneToOneMeetingStatus.MISSED) { return false; }
    if (this.meeting.status === OneToOneMeetingStatus.CANCELLED) { return false; }
    return true;
  }

  get canAddOrRemove(): boolean { // Can add or remove new talking points
    if (!this.creatingSchedule) { return false; }

    return true;
  }

  get canBump(): boolean { // Can add talking points leftover from the previous meeting to the current one
    if (!this.schedule) { return false; }
    if (!this.schedule.status) { return false; }
    if (!this.meeting) { return false; }
    if (!this.meeting.status) { return false; }

    switch(this.schedule.status) {
      case OneToOneStatus.ACTIVE:
        break;
      default:
        return false;
    }

    return true;
  }

  get canComment(): boolean { // Can comment on the talking point
    if (!this.schedule) { return false; }
    if (!this.schedule.status) { return false; }
    if (!this.meeting) { return false; }
    if (!this.meeting.status) { return false; }
    if (this.schedule.status === OneToOneStatus.ARCHIVED) { return false; }
    if (this.schedule.status === OneToOneStatus.PAUSED) { return false; }
    if (this.meeting.status === OneToOneMeetingStatus.MISSED) { return false; }
    if (this.meeting.status === OneToOneMeetingStatus.CANCELLED) { return false; }
    return true;
  }

  get showPreviousTalkingPoints(): boolean {
    if (!this.meeting) { return false; }
    if (!this.meeting.status) { return false; }
    if (this.meeting.status !== OneToOneMeetingStatus.SCHEDULED) { return false; }
    return true;
  }

  constructor(
    public globals: Globals

  ) {
    this.talkingPoints = [];
    this.talkingPointsPreviousCanBump = [];

    this.canReorder = false;
    this.creatingSchedule = false;
    this.displayComments = true;

    this.changeEvent = new EventEmitter<TalkingPoint[]>();
    this.onAction = new EventEmitter<TalkingPointAction>();

    this.editPoint = undefined;

    this.createForm = new FormGroup({
      title: new FormControl('', [Validators.required, Validators.minLength(this.TP_MIN_LENGTH), Validators.maxLength(this.TP_MAX_LENGTH)])
    });

    this.state = {
      loading: true,
      error: false,
      errorMessage: '',
      submitted: false
    };
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.meeting) {
      this.onMeetingChange(changes.meeting.currentValue);
    }
  }

  onMeetingChange(meeting: OneToOneMeetingStatus): void {
    if (!meeting) { return; }
    if (!this.meetingPrevious) { return; }
    const talkingPointsCurrentNames = this.talkingPoints.map(tp => tp.title);
    this.talkingPointsPreviousCanBump = this.meetingPrevious.talkingPoints.filter(tp => !tp.actioned && !talkingPointsCurrentNames.includes(tp.title));
  }

  checkEnter(event: KeyboardEvent): void {
    if (event.key === 'Enter') {
      this.state.submitted = true;
      this.newTalkingPoint();
    }
  }

  clearCreateTitle(): void {
    this.createForm.controls.title.reset();
  }

  newTalkingPoint(): void {
    if (!this.createForm.valid) { return console.warn('Form invalid'); }

    // let managerId = null;
    // if (this.schedule) {
    //   managerId = this.schedule.manager.id;
    // } else {
    //   managerId = this.globals.user.id;
    // }

    const title = this.createForm.controls.title.value;
    const talkingPointNew: TalkingPoint = {
      actioned: false,
      title: title,
      fromPrevious: false,
      sourceUserId: this.globals.user.id,
      recurring: false,
      orderIndex: this.talkingPoints.length,
      comments: []
    };
    this.talkingPoints = [...this.talkingPoints, talkingPointNew];

    // If no meeting, just push to array
    if (!this.meeting) {
      this.createForm.reset();
      this.state.submitted = false;
      this.changeEvent.emit(this.talkingPoints);
      return;
    }
  }

  onTalkingPointActionEmit(event: TalkingPointItemAction, index: number, talkingPoint: TalkingPoint): void {
    switch(event.type) {
      case TalkingPointItemActionType.EDIT:
        this.editPoint = event.payload;
        break;
      case TalkingPointItemActionType.EDIT_CANCEL:
        this.editPoint = undefined;
        break;
      case TalkingPointItemActionType.EDIT_SUBMIT:
        this.editPoint = undefined;
        this.editTalkingPoint(event.payload.old, event.payload.new);
        break;
      case TalkingPointItemActionType.REMOVE:
        this.tryRemoveTalkingPoint(event.payload);
        break;
      case TalkingPointItemActionType.HIDE:
        this.hideTalkingPoint(event.payload);
        break;
      case TalkingPointItemActionType.BUMP:
        this.bumpTalkingPoint(event.payload);
        break;
      case TalkingPointItemActionType.TOGGLE:
        this.talkingPointChecked(talkingPoint.id, event.payload);
        break;
      case TalkingPointItemActionType.COMMENT:
        this.onComment(event.payload, talkingPoint);
        break;
      default:
        throw new Error('[TalkingPoints] Invalid event emitted');
    }
  }

  tryRemoveTalkingPoint(talkingPoint: TalkingPoint): void {
    if (!this.canAddOrRemove) { return; }
    if (!this.creatingSchedule) { return; }

    // If creating, update the local data without an API call
    const newList = this.talkingPoints.filter(t => t.title !== talkingPoint.title);
    this.changeEvent.emit(newList);
  }

  bumpTalkingPoint(talkingPoint: TalkingPoint): void {
    if (!this.canBump) { return; }

    const action: TalkingPointAction = {
      type: TalkingPointActionType.BUMP,
      payload: talkingPoint
    };
    this.onAction.emit(action);
  }

  hideTalkingPoint(point: TalkingPoint): void {
    if (!this.meetingPrevious) { return; }
    if (!this.meetingPrevious.talkingPoints) { return; }
    this.talkingPointsPreviousCanBump = this.talkingPointsPreviousCanBump.filter(tp => tp !== point);
  }

  editTalkingPoint(point: TalkingPoint, newData: Partial<TalkingPoint>): void {
    const editedTalkingPoint: TalkingPoint = {
      ...point,
      ...newData
    };

    if (editedTalkingPoint.id) {
      editedTalkingPoint.id = undefined;
    }

    this.talkingPoints = this.talkingPoints.map(tp => {
      return ((tp === point) ? editedTalkingPoint : tp);
    });

    this.editPoint = undefined;

    this.changeEvent.emit(this.talkingPoints);
  }

  talkingPointChecked(talkingPointId: number, checked: boolean): void {
    const payload: TalkingPointActionPayloadAction = {
      talkingPointId: talkingPointId,
      checked: checked
    };
    const action: TalkingPointAction = {
      type: TalkingPointActionType.ACTION,
      payload: payload
    };
    this.onAction.emit(action);
  }

  onComment(newComment: string, talkingPoint: TalkingPoint): void {
    this.newComment(talkingPoint.id, newComment);
  }

  newComment(talkingPointId: number, newComment: string): void {
    const payload: TalkingPointActionPayloadComment = {
      talkingPointId: talkingPointId,
      comment: newComment
    };
    const action: TalkingPointAction = {
      type: TalkingPointActionType.COMMENT,
      payload: payload
    };

    this.onAction.emit(action);
  }

  dropDraggableTalkingPoint(event: CdkDragDrop<TalkingPoint[]>): void {
    if (event.previousContainer === event.container) { // If same container
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
      setTimeout(() => {
        this.saveNewTalkingPointOrder(event.container.data as TalkingPoint[]);
      }, 1);
    } else { // If not the same container
      const bumpingItem = event.previousContainer.data[event.previousIndex];
      this.bumpTalkingPoint(bumpingItem);
    }
  }

  getPointIDString(point: TalkingPoint) {
    return `check_${point.id}`;
  }

  saveNewTalkingPointOrder(talkingPoints: TalkingPoint[]): void {
    if (this.creatingSchedule) {
      this.changeEvent.emit(this.talkingPoints);
      return;
    }
  }

  refreshOrderIndices(talkingPoints: TalkingPoint[]): TalkingPoint[] {
    return talkingPoints.map((tp, i) => {
      tp.orderIndex = i;
      return tp;
    });
  }

  trackByTalkingPointId(index: number, item: TalkingPoint): number {
    return item.id;
  }
}
