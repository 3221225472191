<div class="container-sentiment-scale-reporting">
  <ng-container *ngIf="!state.loading && !state.loading">
    <div class="text-right m-top-export hidden-print">
      <!-- <app-table-action-icon [icon]="'fa-file-csv'" (click)="createCSV()" [tooltipText]="'Export all meetings to CSV'" [loading]="state.creatingCSV"></app-table-action-icon> -->
      <app-table-action-icon [icon]="'fa-cloud-download'" (click)="createPDF()" [tooltipText]="'Download as PDF'"></app-table-action-icon>
    </div>
  </ng-container>

  <div class="card">
    <div class="content">
      <app-header [title]="eSentimentScaleMessages.SENTIMENT_SCALES_REPORTING" [description]="eSentimentScaleMessages.SENTIMENT_SCALES_REPORTING_DESCRIPTION"></app-header>

      <div class="p-bottom-15 hidden-print">
        <app-dynamic-filter [filterCategories]="filterData.filterCategories" [searchProperties]="filterData.searchProperties" (filtersChanged)="updateFilter($event)"></app-dynamic-filter>
      </div>

      <app-state [state]="state">
        <ng-container *ngIf="!state.loading && !state.error">
          <ng-container *ngIf="targetUsers?.length > 0; else templateNoResponses;">
            <div class="area-chart-outer">
              <ng-container *ngFor="let targetUser of targetUsers">
                <div class="area-chart-row">
                  <div class="area-chart-header">
                    <div class="d-inline-block clickable" (click)="openUserProfile(targetUser.user.id)">
                      <app-profile-photo [width]="'30px'" [src]="targetUser.user.imageUrl" [firstName]="targetUser.user.firstName" [lastName]="targetUser.user.lastName" ></app-profile-photo>
                    </div>
                    <span>{{targetUser.user.firstName}} {{targetUser.user.lastName}}</span>
                  </div>
                  <div class="area-chart-body card card-offwhite content no-margin">
                    <ng-container *ngFor="let schedule of targetUser.schedules">
                      <div class="area-chart-schedule card content no-margin">
                        <div class="area-chart-schedule-header">
                          <div>{{schedule.purposeTitle}}</div>
                        </div>
                        <div class="area-chart-schedule-body">
                          <ng-container *ngFor="let meeting of schedule.meetings">
                            <div class="area-chart-meeting">
                              <div class="area-chart-meeting-header">
                                <div>{{meeting.meetingTimestamp | momentPipe: 'MMM Do YY'}}</div>
                                <div>{{meeting.meetingTimestamp | momentPipe: 'HH:mm'}}</div>
                              </div>

                              <div class="area-chart-meeting-body">
                                <ng-container *ngFor="let response of meeting.responses">
                                  <div class="area-chart-response">
                                    <div class="area-chart-score">
                                      <app-sentiment-scale-option-display [option]="response.optionSelected" [showNumbers]="meeting.sentimentScale.showNumbers" [showName]="false" [showDescription]="false" [showIndent]="false" [metadataInTooltip]="true"></app-sentiment-scale-option-display>
                                    </div>
                                    
                                    <div class="area-chart-column-data-point-user-source">
                                      <app-profile-photo [width]="'20px'" [src]="response.sourceUser.imageUrl" [firstName]="response.sourceUser.firstName" [lastName]="response.sourceUser.lastName" ></app-profile-photo>
                                      <span>{{response.sourceUser.firstName}} {{response.sourceUser.lastName}}</span>
                                    </div>
                                  </div>
                                </ng-container>
                              </div>
                            </div>
                          </ng-container>
                        </div>
                      </div>
                    </ng-container>
                  </div>
                </div>
              </ng-container>
            </div>
          </ng-container>

          <ng-template #templateNoResponses>
            <app-empty-state [height]="'300px'"></app-empty-state>
          </ng-template>

        </ng-container>
      </app-state>
    </div>
  </div>
</div>