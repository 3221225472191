import { NgModule } from '@angular/core';
import { CommonModule, TitleCasePipe } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { PipesModule } from 'app/shared/pipes/pipes.module';
import { Angulartics2Module } from 'angulartics2';
import { PaginationModule } from 'app/shared/pagination/pagination.module';
import { SharedModule } from 'app/shared/shared.module';
import { GoalSearchTableComponent } from './goal-search-table/goal-search-table.component';
import { GoalsIndividualComponent } from './goals-individual/goals-individual.component';
import { GoalsIndividualActivityComponent  } from './goals-individual/goals-individual-activity/goals-individual-activity.component';
import { GoalsIndividualUpdateComponent  } from './goals-individual/goals-individual-update/goals-individual-update.component';
import { GoalsModalComponent } from './goals-modal/goals-modal.component';
import { GoalsModalSidebarComponent } from './goals-modal/goals-modal-sidebar/goals-modal-sidebar.component';
import { GoalsModalHeaderComponent } from './goals-modal/goals-modal-header/goals-modal-header.component';
import { GoalsComponent } from './goals.component';
import { GoalsOverviewComponent } from './goals-overview/goals-overview.component';
import { GoalsOverviewDraftsComponent } from './goals-overview/goals-overview-drafts/goals-overview-drafts.component';
import { GoalManagerTagsComponent } from './goal-manager-tags/goal-manager-tags.component';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { GoalCreateComponent } from './goal-create/goal-create.component';
import { GoalsCreateKeyResultComponent  } from './goals-create-old/goals-create-key-result/goals-create-key-result.component';
import { GoalsCreateOldComponent } from './goals-create-old/goals-create-old.component';
import { GoalCreateService } from './goals-create-old/goals-create.service';
import { GoalCreateFormPartsModule } from './goal-create/goal-create-form-parts/goal-create-form-parts.module';
import { GoalCreateMainComponent } from './goal-create/goal-create-views/goal-create-main/goal-create-main.component';
import { GoalCreateTemplatesComponent } from './goal-create/goal-create-views/goal-create-templates/goal-create-templates.component';
import { GoalCreateCopyingComponent } from './goal-create/goal-create-views/goal-create-copying/goal-create-copying.component';
import { GoalsIndividualTasksComponent } from './goals-individual/goals-individual-tasks/goals-individual-tasks.component';
import { TranslateModule } from '@ngx-translate/core';
import { GoalTagDropdownListComponent } from '@app/goals/goals-components/goal-manager-tags/goal-tag-dropdown-list/goal-tag-dropdown-list.component';
import { UserGoalsModalComponent } from '@app/goals/goals-components/user-goals-modal/user-goals-modal.component';
import { GoalsIndividualActivityItemComponent } from './goals-individual/goals-individual-activity/goals-individual-activity-item/goals-individual-activity-item.component';
import { ContextMenuModule } from '@app/context-menu/context-menu.module';
import { TaskComponentsModule } from '@app/domain/task/module/task-components.module';

const components = [
  GoalSearchTableComponent,
  GoalsIndividualComponent,
  GoalsIndividualActivityComponent,
  GoalsIndividualUpdateComponent,
  GoalsCreateOldComponent,
  GoalsCreateKeyResultComponent,
  GoalsModalComponent,
  GoalsModalSidebarComponent,
  GoalsModalHeaderComponent,
  GoalsComponent,
  GoalsOverviewComponent,
  GoalsOverviewDraftsComponent,
  GoalManagerTagsComponent,
  GoalTagDropdownListComponent,
  GoalCreateComponent,
  GoalCreateMainComponent,
  GoalCreateTemplatesComponent,
  GoalCreateCopyingComponent,
  GoalsIndividualTasksComponent,
  UserGoalsModalComponent,
  GoalsIndividualActivityItemComponent
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    SweetAlert2Module,
    PipesModule,
    Angulartics2Module,
    PaginationModule,
    SharedModule,
    TooltipModule.forRoot(),
    DragDropModule,
    GoalCreateFormPartsModule,
    TaskComponentsModule,
    SharedModule,
    TranslateModule,
    ContextMenuModule
  ],
  declarations: components,
  exports: components,
  providers: [
    GoalCreateService,
    TitleCasePipe
  ]
})
export class GoalsComponentsModule { }
