import { Component, Input } from '@angular/core';
import { TasksMessages } from '../../locale/tasks.messages';

export enum TaskStatsScope {
  OWNED = 'OWNED',
  ALL = 'ALL',
  REPORTING_LINE = 'REPORTING_LINE'
}

export interface TaskStats {
  numberOfActiveTasks: number;
  averageCompletionPercentage: number;
  numberOfTasksDueInNext30Days: number;
  numberOfTasksCompletedInLast3Months: number;
}

@Component({
  selector: 'app-task-stats',
  templateUrl: './task-stats.component.html',
  styleUrls: ['./task-stats.component.scss']
})
export class TaskStatsComponent {
  public readonly eTaskMessages = TasksMessages;

  @Input() stats: TaskStats;

  constructor() {
    this.stats = {
      numberOfActiveTasks: 0,
      numberOfTasksCompletedInLast3Months: 0,
      averageCompletionPercentage: 0,
      numberOfTasksDueInNext30Days: 0
    };
  }
}
