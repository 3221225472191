import { Component, Input, OnInit } from '@angular/core';
import { OneToOneMeeting } from '../../model/one-to-one-meeting.model';
import { OneToOneScheduleDetailsView } from '../../model/one-to-one-schedule.model';
import { Globals } from '@app/shared/globals/globals';
import { SentimentScaleBusinessService } from '@app/domain/sentiment_scale/service/sentiment-scale-business.service';
import { SentimentScale } from '@app/domain/sentiment_scale/model/sentiment-scale.model';
import { FormControl, FormGroup } from '@angular/forms';
import { CreateMeetingSentimentScaleScore, MeetingSentimentScaleScore } from '../../model/meeting-sentiment-scale-score.model';
import { OneToOneBusinessService } from '../../service/one-to-one-business.service';
import { CommonMessages } from '@app/constants/common.messages';
import { CompanyFeatures } from '@app/models/company-features.model';
import { OneToOneMeetingStatus } from '../../model/one-to-one-meeting-status.model';

@Component({
  selector: 'app-meeting-sentiment-scale',
  templateUrl: './meeting-sentiment-scale.component.html',
  styleUrls: ['./meeting-sentiment-scale.component.scss']
})
export class MeetingSentimentScaleComponent implements OnInit {

  public readonly eCommonMessages = CommonMessages;
  
  @Input() schedule: OneToOneScheduleDetailsView;
  @Input() meeting: OneToOneMeeting;

  currentMeetingSentimentScale: SentimentScale;
  responseSentimentScale: SentimentScale;
  form: FormGroup;
  lastUpdated: Date;

  get controlSentimentScaleOption(): FormControl {
    return this.form.get('option') as FormControl;
  } 

  get sentimentScaleHasChanged(): boolean {
    if (!this.currentMeetingSentimentScale) { return false; }
    if (!this.responseSentimentScale) { return false; }

    return this.currentMeetingSentimentScale.id !== this.responseSentimentScale.id;
  }

  get shouldDisableForm(): boolean {
    if (!this.schedule) { return true; }
    if (!this.meeting) { return true; }
    // if (this.meeting.status === OneToOneMeetingStatus.CANCELLED || this.meeting.status === OneToOneMeetingStatus.MISSED) { return true; } // if meeting is cancelled or missed
    if (!this.globals.hasFeature(CompanyFeatures.ONE_TO_ONE_SENTIMENT_SCALE) && this.meeting.status !== OneToOneMeetingStatus.SCHEDULED) { return true; } // If feature is disabled and the meeting is not scheduled
    return false;
  }


  constructor(
    public globals: Globals,
    private oneToOneBusinessService: OneToOneBusinessService,
    private sentimentScaleBusinessService: SentimentScaleBusinessService
  ) {
    this.schedule = undefined;
    this.meeting = undefined;
    this.lastUpdated = undefined;

    this.currentMeetingSentimentScale = undefined;
    this.responseSentimentScale = undefined;

    this.form = this.initForm();
  }

  ngOnInit(): void {
    this.getData();
  }

  getData(): void {
    if (!this.schedule) { return; }
    if (!this.meeting) { return; }
    if (!this.meeting.sentimentScaleId) { return; }

    this.getCurrentSentimentScale()
      .then(() => this.getCurrentUserResponses());
  }

  getCurrentSentimentScale(): Promise<SentimentScale> {
    return this.sentimentScaleBusinessService.getOne(this.meeting.sentimentScaleId)
      .toPromise()
      .then(sentimentScale => {
        this.currentMeetingSentimentScale = sentimentScale;
        return sentimentScale;
      });
  }

  getCurrentUserResponses(): Promise<MeetingSentimentScaleScore[]> {
    return this.oneToOneBusinessService.getMeetingSentimentScaleScores(this.schedule.id, this.meeting.id)
      .toPromise()
      .then(response => {
        if (response.length === 0) {
          this.form = this.initForm();
          this.responseSentimentScale = this.currentMeetingSentimentScale;
          return [];
        }

        const firstItem = response[0];
        this.responseSentimentScale = firstItem.sentimentScale;
        this.form = this.initForm(firstItem);
        this.lastUpdated = firstItem.updatedAt;

        if (this.shouldDisableForm || this.sentimentScaleHasChanged) {
          this.controlSentimentScaleOption.disable();
        } else {
          this.controlSentimentScaleOption.enable();
        }

        return response;
      });
  }

  initForm(score?: MeetingSentimentScaleScore): FormGroup {
    const defaultTargetUser = this.getDefaultTargetUserId();
    const formGroup = new FormGroup({
      targetUser: new FormControl(defaultTargetUser, []),
      option: new FormControl(null, [])
    });

    if (score) {
      formGroup.controls.option.setValue(score.optionSelected.id);
    }

    return formGroup;
  }

  getDefaultTargetUserId(): number {
    if (!this.schedule) { return null; }
    if (this.schedule.participants.length === 0) { return null; }
    if (this.schedule.participants.length > 1) { return null; }
    return this.schedule.participants[0].id;
  }

  parseFormToCreateDto(form: FormGroup): CreateMeetingSentimentScaleScore {
    return {
      sentimentScaleId: this.currentMeetingSentimentScale.id,
      targetUserId: form.controls.targetUser.value,
      optionSelectedId: form.controls.option.value
    };
  }

  onClickSave() {
    if (!this.form.valid) { return; }

    const createDto = this.parseFormToCreateDto(this.form);

    this.oneToOneBusinessService.updateMeetingSentimentScaleScore(this.schedule.id, this.meeting.id, createDto)
      .then(() => {
        this.getData();
      });
  }
}
