import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {Router} from '@angular/router';
import {Globals} from '@app/shared/globals/globals';
import {SidebarService} from '../sidebar.service';
import {CompanySetUpStep} from '@app/models/company/company-set-up-steps/company-set-up-step.model';

interface Progress {
  left: string;
  right: string
}

@Component({
  selector: 'app-sidebar-setup',
  templateUrl: './sidebar-setup.component.html',
  styleUrls: ['./sidebar-setup.component.scss']
})
export class SidebarSetupComponent implements OnChanges {

  @Input() setUpSteps: CompanySetUpStep[];

  progress!: Progress;
  complete!: number;
  total!: number;

  constructor(
      public router: Router,
      public globals: Globals,
      public sidebarService: SidebarService
  ) {
    // TODO: Use component
    this.progress = {
      left: `rotate(${0}deg)`,
      right: `rotate(${0}deg)`
    };
    this.complete = 0;
    this.total = 0;
    this.setUpSteps = [];
    this.updateProgress();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const changesSteps = changes['setUpSteps'];

    if (changesSteps) {
      this.updateProgress();
    }
  }

  openSetupGuide() {
    this.router.navigate(['/company-setup/steps']);
  }

  private getProgress(total: number, complete: number): Progress {
    const percentage = Number(((complete / total)).toFixed(2));
    const degrees = 360 * percentage;

    let left = 0;
    let right = 0;
    if (degrees > 180) {
      left = degrees - 180;
      right = 180;
    } else {
      right = degrees;
    }

    return {
      left: `rotate(${left}deg)`,
      right: `rotate(${right}deg)`
    };
  }

  private updateProgress(): void {
    let total = 0;
    let complete = 0;

    this.setUpSteps.forEach(step => {
      total++;
      if (step.complete === true || step.skipped === true) {
        complete++;
      }
    });

    this.total = total;
    this.complete = complete;
    this.progress = this.getProgress(this.total, this.complete);
  }
}
