import { Injectable } from '@angular/core';
import { MockService } from '@app/mock/api/mock-service';
import { HttpRequest } from '@angular/common/http';
import { CoachingRequestService } from '@app/shared/api/interfaces/connect/coaching-request.service';
import { ResubmitCoachingRequest } from '@app/models/connect/coaching-request/resubmit-coaching-request.model';
import { CreateCoachingRequest } from '@app/models/connect/coaching-request/create-coaching-request.model';
import { CoachingRequest } from '@app/models/connect/coaching-request/coaching-request.model';
import { CoachDeclineCoachingRequest } from '@app/models/connect/coaching-request/coach-decline-coaching-request.model';
import { ManagerDeclineCoachingRequest } from '@app/models/connect/coaching-request/manager-decline-coaching-request.model';
import { Observable } from 'rxjs';
import { sanitizeUrl } from '@app/shared/utils/helpers';
import { mockCoachingRequests } from '@app/mock/api/data/mockCoachingRequests';
import { Globals } from '@app/shared/globals/globals';

@Injectable()
export class CoachingRequestMockService implements MockService, CoachingRequestService {
    constructor(private readonly globals: Globals) { }

    handleRoute(req: HttpRequest<any>): any {
        const url = sanitizeUrl(req.urlWithParams);

        switch (true) {
            case url.endsWith('api/connect/coaching-requests/manager'):
                return this.getCoachingRequestsByManagerMe();
            case url.match(/api\/connect\/coaching-requests\/\d+$/) !== null:
                const coachingRequestId = +url.split('/')[3];
                return this.getCoachingRequestById(coachingRequestId);
            case url.match(/api\/connect\/coaching-requests\/coach\/\d+$/) !== null:
                const coachingRequestCoachId = +url.split('/')[4];
                return this.getCoachingRequestsByCoachId(coachingRequestCoachId);
            case url.match(/api\/connect\/coaching-requests\/coachee\/\d+$/) !== null:
                const coachingRequestCoacheeId = +url.split('/')[4];
                return this.getCoachingRequestsByCoacheeId(coachingRequestCoacheeId);
        }
    }

    getCoachingRequestById(id: number): Observable<CoachingRequest> | CoachingRequest {
        return mockCoachingRequests.find(c => c.id === id);
    }

    getCoachingRequestsByCoachId(coachId: number): Observable<Array<CoachingRequest>> | CoachingRequest[] {
        return mockCoachingRequests.filter(c => c.coachId === coachId);
    }

    getCoachingRequestsByCoacheeId(coacheeId: number): Observable<Array<CoachingRequest>> | CoachingRequest[] {
        return mockCoachingRequests.filter(c => c.coacheeId === coacheeId);
    }

    getCoachingRequestsByManagerMe(): Observable<Array<CoachingRequest>> | CoachingRequest[] {
        return mockCoachingRequests.filter(c => c.coacheeManagerId === this.globals.user.id);
    }

    // No Ops listed below
    closeCoachingRequest(id: number): Observable<CoachingRequest> {
        return undefined;
    }

    coachApproveCoachingRequest(id: number): Observable<CoachingRequest> {
        return undefined;
    }

    coachDeclineCoachingRequest(id: number, coachDeclineCoachingRequest: CoachDeclineCoachingRequest): Observable<CoachingRequest> {
        return undefined;
    }

    createCoachingRequest(createCoachingRequestDto: CreateCoachingRequest): Observable<CoachingRequest> {
        return undefined;
    }

    managerApproveCoachingRequest(id: number): Observable<CoachingRequest> {
        return undefined;
    }

    managerDeclineCoachingRequest(id: number, managerDeclineCoachingRequest: ManagerDeclineCoachingRequest): Observable<CoachingRequest> {
        return undefined;
    }

    resubmitCoachingRequest(id: number, resubmitCoachingRequest: ResubmitCoachingRequest): Observable<CoachingRequest> {
        return undefined;
    }

}