import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ITerminologyAPI } from './terminology-api.model';
import { TerminologyItem, CreateTerminologyItem } from '../model/terminology-item.model';
import { TerminologyCollection } from '../model/terminology-collection.model';

@Injectable()
export class TerminologyAPIService implements ITerminologyAPI {

  public readonly BASE_URL = 'api/terminology';

  constructor(
    private http: HttpClient
  ) { }

  getCurrent(): Observable<TerminologyCollection> {
    const url = `${this.BASE_URL}/current`;
    return this.http.get<TerminologyCollection>(url);
  }

  getAll(): Observable<TerminologyItem[]> {
    const url = `${this.BASE_URL}/`;
    return this.http.get<TerminologyItem[]>(url);
  }

  getByIdIn(ids: number[]): Observable<TerminologyItem[]> {
    const url = `${this.BASE_URL}/in`;

    let httpParams = new HttpParams();
    if (ids) {
      httpParams = httpParams.set('ids', ids.join(','));
    }

    return this.http.get<TerminologyItem[]>(url, { params: httpParams });
  }

  getOne(id: number): Observable<TerminologyItem> {
    const url = `${this.BASE_URL}/${id}`;
    return this.http.get<TerminologyItem>(url);
  }

  updateMultiple(createDtos: CreateTerminologyItem[]): Observable<TerminologyItem[]> {
    const url = `${this.BASE_URL}/`;
    return this.http.post<TerminologyItem[]>(url, createDtos);
  }
  
}
