import { Component, Input, OnInit } from '@angular/core';
import { Praise } from '@app/models/feedback/praise.model';

@Component({
  selector: 'app-feedback-praise-display',
  templateUrl: './feedback-praise-display.component.html',
  styleUrls: ['./feedback-praise-display.component.scss']
})
export class FeedbackPraiseDisplayComponent implements OnInit {
  @Input() praise: Praise;

  @Input() showSourceUser: boolean;
  @Input() showDestinationUser: boolean;

  constructor() {
    this.praise = undefined!;
    this.showSourceUser = true;
    this.showDestinationUser = true;
  }

  ngOnInit() {
  }

}
