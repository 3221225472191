import { Component, Input } from '@angular/core';
import { CommonMessages } from '@app/constants/common.messages';
import { OneToOneNote } from '@app/domain/one_to_one/model/one-to-one-note.model';

@Component({
  selector: 'app-one-to-one-note-display',
  templateUrl: './one-to-one-note-display.component.html',
  styleUrls: ['./one-to-one-note-display.component.scss']
})
export class OneToOneNoteDisplayComponent {
  public readonly eCommonMessages = CommonMessages;

  @Input() note: OneToOneNote;

  constructor() {
    this.note = undefined;
  }

}
