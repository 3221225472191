<!-- Display if all steps aren't complete -->
<ng-container *ngIf="complete < total">
  <a class="frankli-sidebar-setup" (click)="openSetupGuide()" [class.bg-selected]="router.url.includes('/company-setup/steps')">
    <hr class="line">
    <div class="round-progress-container" [class.round-container-collapsed]="sidebarService.sidebarMini">
      <div class="progress progress-round">
        <span class="progress-left">
          <span class="progress-bar border-primary" [style.transform]="progress.left"></span>
        </span>
        <span class="progress-right">
          <span class="progress-bar border-primary" [style.transform]="progress.right"></span>
        </span>
      </div>
    </div>
    <div class="step-count step-count-collapsed text-center" [class.hidden]="!sidebarService.sidebarMini">{{complete}} / {{total}}</div>
    <div class="setup-text-container" [class.hidden]="sidebarService.sidebarMini">
      <div class="setup-text-inner">
        <div class="setup-header">Set up Frankli</div>
        <div class="step-count">{{complete}} / {{total}} steps</div>
      </div>
    </div>
  </a>
</ng-container>