import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { GoalsMessages } from '@app/goals/goals.messages';
import { Goal } from '@app/models/goals/goal.model';
import { IState } from '@app/models/state/state.model';
import { RoleName } from '@app/models/user-role.model';
import { Globals } from '@app/shared/globals/globals';
import { ModalComponent } from '@app/shared/modal/modal.component';
import { SwalUtils } from '@app/shared/utils/swal.utils';
import { TasksMessages } from '@app/domain/task/locale/tasks.messages';
import { TaskAlignmentType } from '@app/domain/task/model/task-alignment-type.enum';
import { Task } from '@app/domain/task/model/task.model';
import { TaskBusinessService } from '@app/domain/task/service/task-business.service';
import { DefaultTaskAlignment, initTaskForm, parseTaskFormValueToCreateDto } from '@app/domain/task/component/create-task/task-create.component';
import { CommonMessages } from '@app/constants/common.messages';
import { CreateTaskDto } from '@app/domain/task/model/create-task.dto';
import { TaskCreatedFrom } from '@app/domain/task/model/task-created-from.model';

enum ModalType {
  CREATE = 'CREATE',
  DETAILS = 'DETAILS'
}

@Component({
  selector: 'app-goals-individual-tasks',
  templateUrl: './goals-individual-tasks.component.html',
  styleUrls: ['./goals-individual-tasks.component.scss']
})
export class GoalsIndividualTasksComponent implements OnChanges {

  public readonly eTaskAlignmentType = TaskAlignmentType;
  public readonly eGoalsMessages = GoalsMessages;
  public readonly eTasksMessages = TasksMessages;
  public readonly eModalType = ModalType;

  @Input() goal: Goal;

  @Output() tasksChanged: EventEmitter<Task[]>;

  @ViewChild('modal') modal?: ModalComponent;

  tasks: Task[];
  state: IState;

  formCreate: FormGroup;
  taskViewing?: Task;
  modalType: ModalType;

  get createTaskTitle(): FormControl {
    return this.formCreate.controls.title as FormControl;
  }

  get isGoalOwnerOrGoalAdmin(): boolean {
    if (!this.globals.user) { return false; }

    if (this.globals.hasRole(RoleName.GOAL_COMPANY)) {
      return true;
    }

    if (this.goal && this.goal.owners) {
      return this.goal.owners.map(o => o.id).includes(this.globals.user.id);
    }

    return false;
  }

  get defaultAlignment(): DefaultTaskAlignment {
    if (!this.goal) { return undefined; }
    return {
      type: TaskAlignmentType.GOAL,
      id: this.goal.id
    };
  }

  constructor(
    public globals: Globals,
    private taskBusinessService: TaskBusinessService,
    private swalUtils: SwalUtils
  ) {
    this.goal = undefined!;
    this.taskViewing = undefined;

    this.formCreate = initTaskForm(TaskCreatedFrom.GOAL);

    this.tasksChanged = new EventEmitter<Task[]>();

    this.tasks = [];
    this.state = {
      loading: true,
      error: false,
      errorMessage: ''
    };
  }

  ngOnChanges(changes: SimpleChanges): void {
    const goal = changes['goal'];
    if(goal && (goal.previousValue !== goal.currentValue)) {
      this.getData();
    }
  }
  
  getData(): void {
    if (!this.goal) { return; }

    this.taskBusinessService.getTasks(null, [], [], [], [TaskAlignmentType.GOAL], [this.goal.id], false)
      .toPromise()
      .then(tasks => {
        this.tasks = tasks;
        this.state.loading = false;
      })
      .catch((err: HttpErrorResponse) => {
        this.doError(err.message);
      });
  }

  doError(message: string): void {
    this.state.loading = false;
    this.state.error = true;
    this.state.errorMessage = message;
  }

  updateTaskInData(task: Task): void {
    this.tasks = this.tasks.map(t => {
      if (t.id === task.id) {
        return task;
      } else {
        return t;
      }
    });
  }

  showModal(modalType: ModalType): void {
    this.modalType = modalType;
    if (this.modal) {
      this.modal.show();
    }
  }

  hideModal(): void {
    if (this.modal) {
      this.modal.hide();
    }
  }

  onTaskAction(event: string, task: Task): void {
    switch (event) {
      case 'edit':
        this.startEditTask(task);
        break;
      case 'unlink':
        this.startUnlinkTask(task);
        break;
      case 'archive':
        this.startArchiveTask(task);
        break;
      case 'view':
        this.startViewTask(task);
        break;
    }
  }

  startCreateTask(): void {
    this.formCreate = initTaskForm(TaskCreatedFrom.GOAL, undefined, this.defaultAlignment, [this.globals.user]);
    this.showModal(ModalType.CREATE);
  }

  startEditTask(task: Task): void {
    const taskCopy = JSON.parse(JSON.stringify(task));
    this.formCreate = initTaskForm(TaskCreatedFrom.GOAL, taskCopy, this.defaultAlignment, undefined);
    this.showModal(ModalType.CREATE);
  }

  startViewTask(task: Task): void {
    this.taskViewing = task;
    this.showModal(ModalType.DETAILS);
  }

  startArchiveTask(task: Task): void {
    if (this.taskBusinessService.updating.includes(task.id)) { return; }

    this.swalUtils.displayWarningConfirmationSwal({
      text: CommonMessages.ACTION_CANNOT_BE_UNDONE,
      confirmButtonText: TasksMessages.ARCHIVE_TASK
    }).then(result => {
      if (result.isConfirmed) {
        this.taskBusinessService.archiveTask(task.id)
          .then(res => {
            this.taskUpdated(res);
            this.hideModal();
          });
      }
    });
  }

  startUnlinkTask(task: Task): void {
    this.swalUtils.displayWarningConfirmationSwal({
      text: TasksMessages.WARNING_UNLINK_TASK_GOAL,
      confirmButtonText: TasksMessages.UNLINK_TASK
    }).then((result) => {
      if (result.isConfirmed) {
        this.taskBusinessService.removeTaskAlignment(task)
          .then(() => {
            this.getData();
          });
      }
    });
  }

  taskUpdated(task: Task): void {
    const index = this.tasks.findIndex(t => t.id === task.id);
    if (index !== -1) {
      // If alignment was removed, remove it from the list
      if ((task.alignmentType !== TaskAlignmentType.GOAL) || (task.alignmentId !== this.goal.id)) {
        this.removeTask(task);
      } else {
        this.tasks[index] = task;
      }

      // this.filterEmit(this.universalFilterData.lastFilterResult, true);
    }
  }

  removeTask(task: Task): void {
    this.tasks = this.tasks.filter(t => t.id !== task.id);
  }

  onCancelCreate(): void {
    this.formCreate = initTaskForm(TaskCreatedFrom.GOAL, undefined, this.defaultAlignment, [this.globals.user]);
    this.hideModal();
  }

  onSubmitCreate(): void {
    if (this.formCreate.invalid) { return; }

    const createDto: CreateTaskDto = parseTaskFormValueToCreateDto(this.formCreate.value);
    const taskId = this.formCreate.value.id;

    if (taskId) {
      this.doEdit(taskId, createDto);
    } else {
      this.doCreate(createDto);
    }
  }

  doCreate(createDto: CreateTaskDto): void {
    this.taskBusinessService.createNewTask(createDto)
      .then(() => {
        this.getData();
        this.hideModal();
      });
  }

  doEdit(id: number, updateDto: CreateTaskDto): void {
    this.taskBusinessService.updateExistingTask(id, updateDto)
      .then(() => {
        this.getData();
        this.hideModal();
      });
  }
}
