import { Component, ElementRef, forwardRef, Input, OnInit, ViewChild } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { CommonMessages } from '@app/constants/common.messages';
import { IState } from '@app/models/state/state.model';
import { Globals } from '@app/shared/globals/globals';

interface PageState extends IState {
  searchRunning: boolean;
  dropdownOpen: boolean;
  disabled: boolean;
  searchIsEmpty: boolean;
}

type PickerValue = (string | string[]);

@Component({
  selector: 'app-text-item-picker',
  templateUrl: './text-item-picker.component.html',
  styleUrls: ['./text-item-picker.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => TextItemPickerComponent),
    multi: true,
  }],
})
export class TextItemPickerComponent implements OnInit, ControlValueAccessor {
  public readonly eCommonMessages = CommonMessages;

  @ViewChild('searchInput') searchInput?: ElementRef<HTMLInputElement>;

  @Input() searchPlaceholder: string;  
  @Input() customOptions: string[];
  @Input() customOptionsOnly: boolean;
  @Input() canSelectMultiple: boolean;
  @Input() blacklistedIds: number[];
  @Input() loseFocusAfterSelection: boolean;
  @Input() clearSearchAfterSelection: boolean;

  state: PageState;
  searchControl: FormControl;
  // results: string[];
  _value: string[];
  searchIsFocused: boolean;

  onChange = (_: any) => {};
  onTouched = () => {};

  get value(): PickerValue {
    return (this.canSelectMultiple ? this._value : this._value[0]);
  }

  set value(v: PickerValue) {
    if (this.state.disabled) { return; }
    this.writeValue(v);
    this.onChange(v);
  }

  constructor(
    public globals: Globals
  ) {
    this.searchControl = new FormControl('', [Validators.required]);
    this._value = [];
    // this.results = [];
    this.blacklistedIds = [];

    this.searchPlaceholder = CommonMessages.SEARCH_BY_NAME;

    this.customOptions = [];
    this.customOptionsOnly = false;
    this.canSelectMultiple = false;

    this.state = {
      loading: true,
      error: false,
      errorMessage: '',
      searchRunning: false,
      dropdownOpen: false,
      disabled: false,
      searchIsEmpty: true
    };
    this.searchIsFocused = false;
    this.loseFocusAfterSelection = true;
    this.clearSearchAfterSelection = true;
  }

  ngOnInit(): void {
    this.state.loading = false;
  }

  // trySearch(sarg: string): void {
  //   this.state.searchRunning = true;

  //   this.state.searchIsEmpty = !(sarg && sarg.length > 0);

  //   if (this.customOptionsOnly) {
  //     this.doSearchCustomOnly(this.customOptions, sarg);
  //     return;
  //   } else {
  //     this.doSearchRegular(this.customOptions, sarg);
  //   }

  // }
  
  // doSearchCustomOnly(customOptions: string[], sarg: string): void {
  //   let results = customOptions;
  //   if (sarg) {
  //     results = results.filter(s => s.name.toLowerCase().includes(sarg)); // Only matches to search
  //   }
  //   results = results.slice(0, 5); // Only first 5
  //   const selectedstringIds = this._value.map(v => v.id);
  //   results = results.filter(r => !selectedstringIds.includes(r.id)); // Not already selected
  //   results = results.filter(r => !this.blacklistedIds.includes(r.id)); // Not blacklisted

  //   this.results = results;
  //   this.state.searchRunning = false;
  //   this.state.loading = false;
  // }

  // doSearchRegular(customOptions: string[], sarg: string): void {
  //   let matchingCustomResults = [];
  //   if (sarg) {
  //     matchingCustomResults = customOptions.filter(s => s.name.toLowerCase().includes(sarg));
  //   }

  //   this.textBusinessService.get(null, sarg, null, null).toPromise()
  //     .then(results => {
  //       results = [...matchingCustomResults, ...results ];
  //       const selectedstringIds = this._value.map(v => v.id);
  //       results = results.filter(r => !selectedstringIds.includes(r.id));
  //       results = results.filter(r => !this.blacklistedIds.includes(r.id));
  //       this.results = results.slice(0, 5);
  //     })
  //     .finally(() => {
  //       this.state.searchRunning = false;
  //       this.state.loading = false;
  //     });
  // }

  selectItem(text: string): void {
    if (this.state.disabled) { return; }

    if (this.canSelectMultiple) {
      this.addItemToMultiplePickerValue(text);
    } else {
      this.value = text;
      this.state.dropdownOpen = false;
    }

    this.blurSearchIfToggled();
    this.clearSearchIfToggled();

    this.onTouched();
    this.searchControl.updateValueAndValidity();
  }

  blurSearchIfToggled(): void {
    if (!this.loseFocusAfterSelection) {
      this.focusSearchInput();
    } else {
      this.blurSearchInput();
    }
  }

  clearSearchIfToggled(): void {
    if (this.clearSearchAfterSelection) {
      this.searchControl.patchValue('');
    }
  }

  addItemToMultiplePickerValue(text: string): void {
    const selectedIds = this._value
    if (selectedIds.includes(text)) { return; }
    this.value = [...this._value, text];
  }

  removeSelectedItem(text: string, event?: MouseEvent): void {
    if (event) {
      event.stopPropagation();
    }

    if (this.canSelectMultiple) {
      this.value = this._value.filter(r => r !== text);
    } else {
      this.value = null;
    }
  }

  writeValue(v: PickerValue): void {
    if (this.canSelectMultiple) {
      v = v || [];
      this._value = v as string[];
    } else {
      if (v) {
        this._value = [v as string];
      } else {
        this._value = [];
      }
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.state.disabled = isDisabled;
    if (isDisabled) {
      this.searchControl.disable();
    } else {
      this.searchControl.enable();
    }
  }

  onFocusSearch(): void {
    this.searchIsFocused = true;
    if (this.state.disabled) { return; }

    this.state.dropdownOpen = true;
  }

  onBlurSearch(): void {
    this.searchIsFocused = false;

    setTimeout(() => {
      if (this.searchIsFocused) { return; }
      this.state.dropdownOpen = false;
    }, 200);
  }

  trySelectFirst() {
    if (this.searchControl.invalid) { return; }

    this.selectItem(this.searchControl.value);

    if (!this.canSelectMultiple) {
      this.blurSearchInput();
    }
  }

  focusSearchInput(): void {
    if (!this.searchInput) { return; }
    this.searchInput.nativeElement.focus();
    this.state.dropdownOpen = true;
  }

  blurSearchInput(): void {
    if (!this.searchInput) { return; }
    this.searchInput.nativeElement.blur();
    this.state.dropdownOpen = false;
  }

  onKeyupBackspace(): void {
    if (this.searchControl.value) { return; }
    if (!this.state.searchIsEmpty) { return; }
    if (this.state.searchRunning) { return; }

    if (this.canSelectMultiple) {
      // Get value
      const value = this.value as string[];
      if (!value) { return; }
      if (value.length === 0) { return; }
  
      value.pop();
  
      this.value = value;
    } else {
      // Get value
      const value = this.value as string;
      if (!value) { return; }

      this.value = null;
    }

    this.searchControl.updateValueAndValidity();
  }

  onKeyupEscape(): void {
    this.blurSearchInput();
  }
}
