import { Injectable } from '@angular/core';
import { CommonMessages } from '@app/constants/common.messages';
import { OneToOneSchedule, OneToOneScheduleBase } from '../model/one-to-one-schedule.model';
import { OneToOneMessages } from '../locale/one-to-one.messages';
import { OneToOneStatus } from '../model/one-to-one-status.model';
import { OneToOneScheduleType } from '../model/one-to-one-schedule-type.model';
import { OneToOneMeeting } from '../model/one-to-one-meeting.model';
import { OneToOneMeetingStatus } from '../model/one-to-one-meeting-status.model';
import { OneToOneMeetingLength } from '../model/one-to-one-meeting-length.model';
import { ScheduleLocationType } from '../model/schedule-location-type.model';
import { OneToOneFrequency } from '../model/one-to-one-frequency.model';

@Injectable()
export class OneToOneUtilsService {

  public static getOneToOneStatusFilter(oneToOneSchedule?: OneToOneSchedule): string {
    if (oneToOneSchedule) {
      switch(oneToOneSchedule.status) {
        case OneToOneStatus.ARCHIVED:
          return CommonMessages.ARCHIVED;
        case OneToOneStatus.COMPLETED:
          return OneToOneMessages.COMPLETED;
        case OneToOneStatus.PAUSED:
          return OneToOneMessages.PAUSED;
        case OneToOneStatus.ACTIVE:
        default:
          return OneToOneMessages.ACTIVE;
      }
    }

    return CommonMessages.UNKNOWN;
  }

  public static getIconClassForOneToOneScheduleType(oneToOneScheduleType: OneToOneScheduleType): string {
    switch(oneToOneScheduleType) {
      case OneToOneScheduleType.ONE_TO_ONE:
        return 'fa-user-group';
      case OneToOneScheduleType.ONE_TO_MANY:
      default:
        return 'fa-users';
    }
  }

  public static getIconClassForOneToOneScheduleStatus(oneToOneScheduleStatus: OneToOneStatus): string {
    switch(oneToOneScheduleStatus) {
      case OneToOneStatus.ARCHIVED:
        return 'fa-archive';
      case OneToOneStatus.COMPLETED:
        return 'fa-check';
      case OneToOneStatus.PAUSED:
        return 'fa-pause';
      case OneToOneStatus.ACTIVE:
      default:
        return '';
    }
  }

  public static getIconClassForOneToOneMeetingStatus(meeting: OneToOneMeeting): string {
    switch(meeting.status) {
      case OneToOneMeetingStatus.SCHEDULED:
        return 'fa-clock';
      case OneToOneMeetingStatus.IN_PROGRESS:
      case OneToOneMeetingStatus.REOPENED:
        return 'fa-play';
      case OneToOneMeetingStatus.MISSED:
        return 'fa-circle-exclamation';
      case OneToOneMeetingStatus.CANCELLED:
        return 'fa-times';
      case OneToOneMeetingStatus.COMPLETED:
      case OneToOneMeetingStatus.AUTO_COMPLETED:
        return 'fa-check';
      default:
        return '';
    }
  }

  public static sortByManager(schedules: OneToOneSchedule[]): OneToOneSchedule[] {
    return schedules.sort((schedule1, schedule2) => {
      const s1ManagerName = `${schedule1.manager.firstName} ${schedule1.manager.lastName}`;
      const s2ManagerName = `${schedule2.manager.firstName} ${schedule2.manager.lastName}`;
      return s1ManagerName.localeCompare(s2ManagerName);
    });
  }

  public static sortByParticipant(schedules: OneToOneSchedule[]): OneToOneSchedule[] {
    return schedules.sort((schedule1, schedule2) => {
      const s1ParticipantCount = schedule1.participants.length;
      const s2ParticipantCount = schedule2.participants.length;
      return s1ParticipantCount - s2ParticipantCount;
    });
  }

  public static sortByPurpose(schedules: OneToOneScheduleBase[]): OneToOneScheduleBase[] {
    return schedules.sort((schedule1, schedule2) => {
      const s1Purpose = schedule1.purposeTitle;
      const s2Purpose = schedule2.purposeTitle;
      return s1Purpose.localeCompare(s2Purpose);
    });
  }

  public static sortByTalkingPoints(schedules: OneToOneSchedule[]): OneToOneSchedule[] {
    return schedules.sort((schedule1, schedule2) => {
      const s1TalkingPointsCount = schedule1.talkingPointsRecurring.length;
      const s2TalkingPointsCount = schedule2.talkingPointsRecurring.length;
      return s1TalkingPointsCount - s2TalkingPointsCount;
    });
  }

  public static sortByNextMeeting(schedules: OneToOneScheduleBase[]): OneToOneScheduleBase[] {
    return schedules.sort((schedule1, schedule2) => {
      const s1NextMeeting = schedule1.startDateTime;
      const s2NextMeeting = schedule2.startDateTime;

      if (!s1NextMeeting) {
        return 1;
      }
      if (!s2NextMeeting) {
        return -1;
      }

      return new Date(s1NextMeeting).getTime() - new Date(s2NextMeeting).getTime();
    });
  }

  public static sortByFrequency(schedules: OneToOneScheduleBase[]): OneToOneScheduleBase[] {
    return schedules.sort((schedule1, schedule2) => {
      const s1Frequency = schedule1.frequency;
      const s2Frequency = schedule2.frequency;
      return s1Frequency.localeCompare(s2Frequency);
    });
  }

  public static getLatestMeeting(schedule: OneToOneSchedule): OneToOneMeeting {
    if (!schedule) { return null; }
    if (!schedule.meetingList) { return null; }
    if (schedule.meetingList.length === 0) { return null; }
    
    const meetingsInOrder = schedule.meetingList.sort((a, b) => {
      return new Date(a.meetingTimestamp).getTime() - new Date(b.meetingTimestamp).getTime();
    });

    return meetingsInOrder[0];
  }

  public static getMessageCodeForOneToOneMeetingLength(meetingLength: OneToOneMeetingLength): string {
    switch(meetingLength) {
      case OneToOneMeetingLength.FIFTEEN_MINUTES:
        return OneToOneMessages.MEETING_LENGTH_15_MINUTES;
      case OneToOneMeetingLength.THIRTY_MINUTES:
        return OneToOneMessages.MEETING_LENGTH_30_MINUTES;
      case OneToOneMeetingLength.FORTY_FIVE_MINUTES:
        return OneToOneMessages.MEETING_LENGTH_45_MINUTES;
      case OneToOneMeetingLength.SIXTY_MINUTES:
        return OneToOneMessages.MEETING_LENGTH_60_MINUTES;
      default:
        return '';
    }
  }

  public static getMessageCodeForOneToOneLocationType(locationType: ScheduleLocationType): string {
    switch(locationType) {
      case ScheduleLocationType.IN_PERSON:
        return OneToOneMessages.IN_PERSON;
      case ScheduleLocationType.ZOOM:
        return 'Zoom';
      case ScheduleLocationType.SLACK:
        return 'Slack';
      case ScheduleLocationType.MICROSOFT_TEAMS:
        return 'Microsoft Teams';
      case ScheduleLocationType.SKYPE:
        return 'Skype';
      case ScheduleLocationType.GOOGLE_MEET:
        return 'Google Meet';
      case ScheduleLocationType.WEBEX:
        return 'Webex';
      case ScheduleLocationType.OTHER:
        return OneToOneMessages.OTHER;
      default:
        return '';
    }
  }

  public static getMessageCodeForOneToOneFrequency(frequency: OneToOneFrequency): string {
    switch(frequency) {
      case OneToOneFrequency.Annually:
      case OneToOneFrequency.Custom:
      case OneToOneFrequency.Daily:
      case OneToOneFrequency.Flexible:
      case OneToOneFrequency.Fortnightly:
      case OneToOneFrequency.Individual:
      case OneToOneFrequency.Monthly:
      case OneToOneFrequency.Quarterly:
      case OneToOneFrequency.Weekly:
        return frequency;
      default:
        return '';
    }
  }

  public static getMessageCodeForOneToOneStatus(status: OneToOneStatus): string {
    switch(status) {
      case OneToOneStatus.ACTIVE:
        return OneToOneMessages.ACTIVE;
      case OneToOneStatus.ARCHIVED:
        return CommonMessages.ARCHIVED;
      case OneToOneStatus.COMPLETED:
        return OneToOneMessages.COMPLETED;
      case OneToOneStatus.PAUSED:
        return OneToOneMessages.PAUSED;
      default:
        return '';
    }
  }
}