<router-outlet></router-outlet>
<ng-container *ngIf="route.children.length === 0">
  <div class="container-one-to-one-overview">
    <app-state [state]="state">
      <ng-container *ngIf="!state.loading && !state.error">
        <div class="text-right p-bottom-15">
          <app-button  [tooltipText]="eOneToOneMessages.CREATE_TOOLTIP | translate" (onClick)="openOneToOneCreate()" angulartics2On="click" angularticsAction="Show1to1Create" angularticsCategory="OneToOne" [attr.data-intercom-target]="'Create A 1:1'">
            <span translate>{{eOneToOneMessages.CREATE_SCHEDULE}}</span>
          </app-button>
        </div>

        <div class="card">
          <div class="content">
            <app-header [title]="eOneToOneMessages.MEETINGS" [description]="eOneToOneMessages.ONE_TO_ONES_DESCRIPTION" [helpLink]="'https://help.frankli.io/en/articles/2893368'"></app-header>

            <div class="p-bottom-15">
              <app-dynamic-filter [filterCategories]="filterCategories" [searchProperties]="searchProperties" [selectedFilters]="selectedFilters" (filtersChanged)="updateFilter($event)"></app-dynamic-filter>
            </div>

            <div class="text-right p-bottom-15" *ngIf="data.filtered.length > 1">
              <div class="d-inline-block text-left">
                <app-sorting-dropdown [control]="controlSort" [sortingOptions]="sortingOptions"></app-sorting-dropdown>
              </div>
            </div>

            <app-state [state]="stateList">
              <ng-container *ngIf="!stateList.loading && !stateList.error">
                <div class="area-schedule-list">
                  <ng-container *ngFor="let schedule of data.display">
                    <app-one-to-one-schedule-display [schedule]="schedule" [showIcon]="true" [isChanging]="isChangingSchedule(schedule.id)" [actionsShown]="['view', 'edit', 'recreateCalendar', 'pause', 'resume', 'transfer', 'archive', 'unarchive', 'delete']" (onClickAction)="onClickAction($event, schedule)"></app-one-to-one-schedule-display>
                  </ng-container>
                </div>

                <div [class.hidden]="pagination?.pages?.length === 1">
                  <app-pagination-new-component [arrayData]="data.filtered" [arrayDisplay]="data.display" #pagination></app-pagination-new-component>
                </div>

                <ng-container *ngIf="state.scheduleTransferring">
                  <app-modal #modalTransferSchedule (modalHidden)="onModalHiddenTransfer()">
                    <app-transfer-schedule [oneToOneSchedule]="state.scheduleTransferring" (onSuccess)="onTransferSuccess($event)"></app-transfer-schedule>
                  </app-modal>
                </ng-container>

                <ng-container *ngIf="data.display.length === 0">
                  <app-empty-state
                    [height]="'300px'" [imageSrc]="'assets/img/One_To_One.svg'" [message]="eOneToOneMessages.NO_SCHEDULES_FOR_USER"
                    [primaryButtonShown]="true" [primaryButtonText]="eOneToOneMessages.CREATE_SCHEDULE" (primaryButtonPressed)="openOneToOneCreate()"
                    [tertiaryButtonShown]="true" [tertiaryButtonText]="eOneToOneMessages.LEARN_PROMPT" (tertiaryButtonPressed)="openHelpArticle()"></app-empty-state>
                </ng-container>
              </ng-container>
            </app-state>
          </div>
        </div>
      </ng-container>
    </app-state>
  </div>
</ng-container>