import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { questionDataConstants } from '@app/models/evaluation/assessment-questions.model';

@Component({
  selector: 'app-scale',
  templateUrl: './scale.component.html',
  styleUrls: ['./scale.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => ScaleComponent),
    multi: true,
  }],
})
export class ScaleComponent implements OnInit {
  @Input() disabled: boolean;
  @Input() display: boolean;
  @Input() showLabels: boolean;
  @Input() minLabel: string = 'Not likely'
  @Input() maxLabel: string = 'Very likely'
  @Output() onValueChange: EventEmitter<number> = new EventEmitter<number>();

  values: {
    name: string;
    value: string;
    colour: string;
  }[];

  _value: string;

  onChange = (_: any) => {};
  onTouched = () => {};

  constructor() {
    this.disabled = false;
    this.display = false;
    this._value = undefined!;
    this.values = questionDataConstants.eNPS;
    this.showLabels = true;
  }

  ngOnInit() {
    if (!this._value) {
      this._value = (this.disabled ? '7' : '0');
    }
  }

  get value(): string {
    return this._value;
  }

  set value(v: (string)) {
    if (v !== this._value) {
      this._value = v;

      this.onChange(v);
      this.onValueChange.emit(Number(v));
    }
  }

  // this will populate the value with the one set in the FormControl / ngModel
  writeValue(value: any) {
    this.value = value;
  }

  // Register callbacks
  registerOnChange(fn: (_: any) => {}): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  select(val: string) {
    if (!this.disabled && !this.display) {
      this.value = val;
    }
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }
}
