import {NgModule} from '@angular/core';
import {CommonModule, DatePipe} from '@angular/common';
import {DateJoinedPipe} from 'app/shared/pipes/date-joined.pipe';
import {AgePipe} from 'app/shared/pipes/age.pipe';
import {EnumToArrayPipe} from 'app/shared/pipes/enum-to-array.pipe';
import {DateAgoPipe} from './date-ago.pipe';
import {UnderscoreToSpacePipe} from 'app/shared/pipes/underscore_to_space.pipe';
import {WordMinsToNumericalPipe} from './word-mins-to-numerical.pipe';
import {HtmlPipe} from 'app/shared/pipes/html.pipe';
import {KeyValueAlphabeticalPipe} from './alphabetical-key-value.pipe';
import {SecurePipe} from './secure.pipe';
import {MomentPipe} from 'app/shared/pipes/moment.pipe';
import {EllipsisPipe} from './ellipsis.pipe';
import {DateFormatPipe} from './date-format.pipe';
import {LikertCommentPipe} from './likert-comment.pipe';
import {InitialsPipe} from '@app/shared/pipes/initials.pipe';
import {NumberToWordPipe} from '@app/shared/pipes/number-to-word.pipe';
import {LinkifyPipe} from './linkify.pipe';
import {LinksBlankPipe} from './links_blank.pipe';
import {RichLinkDisplayPipe} from './rich-link-display.pipe';
import {DomSafePipe} from './dom-safe.pipe';
import {LocaleDateDifferencePipe} from '@app/shared/pipes/locale-date-difference/locale-date-difference.pipe';
import {MomentTimezonePipe} from './moment-timezone.pipe';
import {ShortNumberPipe} from './short-number.pipe';
import {SimpleNumberPipe} from './simple-number.pipe';
import { SpaceToUnderscorePipe } from './space_to_underscore.pipe';
import { ReplacePipe } from './replace.pipe';

const pipes = [
  DateJoinedPipe,
  AgePipe,
  EnumToArrayPipe,
  DateAgoPipe,
  UnderscoreToSpacePipe,
  SpaceToUnderscorePipe,
  WordMinsToNumericalPipe,
  HtmlPipe,
  KeyValueAlphabeticalPipe,
  SecurePipe,
  MomentPipe,
  MomentTimezonePipe,
  EllipsisPipe,
  DateFormatPipe,
  LikertCommentPipe,
  InitialsPipe,
  NumberToWordPipe,
  LinkifyPipe,
  LinksBlankPipe,
  RichLinkDisplayPipe,
  DomSafePipe,
  LocaleDateDifferencePipe,
  ShortNumberPipe,
  SimpleNumberPipe,
  ReplacePipe
];

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: pipes,
  exports: pipes,
  providers: [
    DatePipe,
    DateAgoPipe,
    LocaleDateDifferencePipe,
    UnderscoreToSpacePipe,
    WordMinsToNumericalPipe,
    SpaceToUnderscorePipe
  ]
})
export class PipesModule { }

