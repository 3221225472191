<div class="goal-drafts-container">
  <ng-container *ngIf="!state.loading; else stateLoading;">
    <ng-container *ngIf="!state.error; else stateError;">
      <div class="p-top-20 p-bottom-20 p-left-20 p-right-20">
        <div class="row m-left-0 m-right-0">
          <div class="uni-filter-container"></div>
          <div class="col-toggle-container">
            <app-column-toggle-new [columnInfos]="columnInfo" #columnToggle></app-column-toggle-new>
          </div>
        </div>
      </div>

      <ng-container *ngIf="goalDrafts.length > 0; else stateEmpty">
        <div class="scroll-x">
          <table width="100%">
            <!-- Headings -->
            <tr class="frankli-table frankli-table-header frankli-table-header-large noselect">
              <th></th>
              <th width="1em" *ngIf="columnToggle.getColumnEnabled(eTableColumn.OWNERS)" translate>
                {{eGoalsMessages.OWNERS}}
              </th>
              <th width="1em" *ngIf="columnToggle.getColumnEnabled(eTableColumn.PRIORITY) && globals.hasFeature(eFeature.GOAL_PRIORITY)" (click)="doSort(eTableColumn.PRIORITY)"
              [ngClass]="(sort.column === eTableColumn.PRIORITY) ? (sort.ascending ? 'sorting-ascending' : 'sorting-descending') : 'sorting'"
              translate>{{eGoalsMessages.PRIORITY}}</th>
              <th width="1em" *ngIf="columnToggle.getColumnEnabled(eTableColumn.TYPE)" (click)="doSort(eTableColumn.TYPE)"
              [ngClass]="(sort.column === eTableColumn.TYPE) ? (sort.ascending ? 'sorting-ascending' : 'sorting-descending') : 'sorting'"
              translate>{{eGoalsMessages.TYPE}}</th>
              <th *ngIf="columnToggle.getColumnEnabled(eTableColumn.TITLE)" (click)="doSort(eTableColumn.TITLE)"
              [ngClass]="(sort.column === eTableColumn.TITLE) ? (sort.ascending ? 'sorting-ascending' : 'sorting-descending') : 'sorting'"
              translate>{{eGoalsMessages.TITLE}}</th>
              <th width="1em" *ngIf="columnToggle.getColumnEnabled(eTableColumn.VISIBILITY)" (click)="doSort(eTableColumn.VISIBILITY)"
              [ngClass]="(sort.column === eTableColumn.VISIBILITY) ? (sort.ascending ? 'sorting-ascending' : 'sorting-descending') : 'sorting'"
              translate>{{eGoalsMessages.VISIBILITY}}</th>
              <th width="1em" *ngIf="columnToggle.getColumnEnabled(eTableColumn.DUE_DATE)" (click)="doSort(eTableColumn.DUE_DATE)"
              [ngClass]="(sort.column === eTableColumn.DUE_DATE) ? (sort.ascending ? 'sorting-ascending' : 'sorting-descending') : 'sorting'"
              translate>{{eGoalsMessages.DUE_DATE}}</th>
              <th width="1em" *ngIf="columnToggle.getColumnEnabled(eTableColumn.ACTIONS)"
              translate>{{eCommonMessages.ACTIONS}}</th>
            </tr>

            <!-- Body -->
            <ng-container *ngFor="let draft of goalDrafts">
              <tr class="frankli-table frankli-table-single-line">
                <td width="1em" (click)="draft.dropdownOpen = !draft.dropdownOpen">
                  <app-table-dropdown-toggle [opened]="draft.dropdownOpen"></app-table-dropdown-toggle>
                </td>
                <td *ngIf="columnToggle.getColumnEnabled(eTableColumn.OWNERS)">
                  <app-person-picture-list [users]="draft.owners"></app-person-picture-list>
                </td>

                <td class="text-center" *ngIf="columnToggle.getColumnEnabled(eTableColumn.PRIORITY) && globals.hasFeature(eFeature.GOAL_PRIORITY)">
                  <ng-container *ngIf="draft.priority">
                    <app-goal-priority-display [priority]="draft.priority"></app-goal-priority-display>
                  </ng-container>
                </td>

                <td *ngIf="columnToggle.getColumnEnabled(eTableColumn.TYPE)">
                  <app-goal-type-display [type]="draft.type"></app-goal-type-display>
                </td>

                <td *ngIf="columnToggle.getColumnEnabled(eTableColumn.TITLE)">
                  <span class="wrap-title">{{draft.title ? draft.title : (eCommonMessages.NOT_SET | translate)}}</span>
                </td>
  
                <td class="text-center" *ngIf="columnToggle.getColumnEnabled(eTableColumn.VISIBILITY)">
                  <app-goal-visibility-display [visibility]="draft.visibility"></app-goal-visibility-display>
                </td>

                <td *ngIf="columnToggle.getColumnEnabled(eTableColumn.DUE_DATE)">
                  <ng-container *ngIf="draft.endDate; else dueDateNotSet">
                    <app-pill>
                      <span>{{draft.endDate | momentPipe: 'Do MMMM YYYY'}}</span>
                    </app-pill>
                  </ng-container>
                  <ng-template #dueDateNotSet>
                    <app-pill [type]="ePillType.GRAY">
                      <span translate>{{eCommonMessages.NOT_SET}}</span>
                    </app-pill>
                  </ng-template>
                </td>
  
                <td class="text-center" *ngIf="columnToggle.getColumnEnabled(eTableColumn.ACTIONS)">
                  <div class="d-inline-block" (click)="editDraftGoal(draft)" [tooltip]="eGoalsMessages.EDIT_DRAFT_GOAL | translate" [triggers]="globals.tooltipTriggers" placement="bottom" container="body">
                    <app-table-action-icon [icon]="'fa-edit'" [hoverColor]="eIconHoverColor.YELLOW"></app-table-action-icon>
                  </div>
                  <div class="d-inline-block" (click)="deleteDraftGoal(draft)" [tooltip]="eGoalsMessages.DELETE_DRAFT_GOAL | translate" [triggers]="globals.tooltipTriggers" placement="bottom" container="body">
                    <app-table-action-icon [icon]="'fa-trash'" [hoverColor]="eIconHoverColor.RED"></app-table-action-icon>
                  </div>
                </td>
              </tr>
  
              <!-- Dropdown -->
              <tr *ngIf="draft.dropdownOpen">
                <td colspan="50">
                  <app-goal-dropdown [goal]="draft"></app-goal-dropdown>
                </td>
              </tr>
            </ng-container>
          </table>
        </div>
        <app-pagination-server *ngIf="goalDraftPage" [page]="goalDraftPage" [pageSize]="pagingParams.pageSize" (pageSizeChanged)="changePageSize($event)" (pageNumberChanged)="changePageNumber($event)"></app-pagination-server>
      </ng-container>

      <ng-template #stateEmpty>
        <app-empty-state [height]="'calc(100vh - 500px)'" [message]="eGoalsMessages.EMPTY_STATE_SEARCH"></app-empty-state>
      </ng-template>
    </ng-container>
  </ng-container>

  <ng-template #stateError>
    <app-error-state [height]="'calc(100vh - 500px)'" [message]="state.errorMessage"></app-error-state>
  </ng-template>

  <ng-template #stateLoading>
    <app-loading-state [showMessage]="false" [height]="'calc(100vh - 500px)'"></app-loading-state>
  </ng-template>
</div>