import { TerminologyEntity } from './terminology-entity.enum';
import { TerminologyLanguage } from './terminology-language.enum';

export interface TerminologyItem {
  id: number;
  language: TerminologyLanguage;
  entity: TerminologyEntity;
  wording: string;
  createdAt: Date;
  updatedAt: Date;
}

export interface CreateTerminologyItem {
  language: TerminologyLanguage;
  entity: TerminologyEntity;
  wording: string;
}

export const TERMINOLOGY_DEFAULTS = {
  [TerminologyLanguage.EN]: {
    [TerminologyEntity.DEPARTMENT]: 'Department',
    [TerminologyEntity.DEPARTMENT_PLURAL]: 'Departments',
    [TerminologyEntity.TEAM]: 'Team',
    [TerminologyEntity.TEAM_PLURAL]: 'Teams',
    [TerminologyEntity.SECONDARY]: 'Dotted-line',
    [TerminologyEntity.SECONDARY_MANAGER]: 'Dotted-line Manager',
    [TerminologyEntity.SECONDARY_MANAGER_PLURAL]: 'Dotted-line Managers',
    [TerminologyEntity.SITE]: 'Site',
    [TerminologyEntity.SITE_PLURAL]: 'Sites',
    [TerminologyEntity.SKILL]: 'Skill',
    [TerminologyEntity.SKILL_PLURAL]: 'Skills',
    [TerminologyEntity.COMPETENCY]: 'Competency',
    [TerminologyEntity.COMPETENCY_PLURAL]: 'Competencies'
  },
  [TerminologyLanguage.DE]: {
    [TerminologyEntity.DEPARTMENT]: 'Abteilung',
    [TerminologyEntity.DEPARTMENT_PLURAL]: 'Abteilungen',
    [TerminologyEntity.TEAM]: 'Team',
    [TerminologyEntity.TEAM_PLURAL]: 'Teams',
    [TerminologyEntity.SECONDARY]: 'Gestrichelte Linie',
    [TerminologyEntity.SECONDARY_MANAGER]: 'Gestrichelte Linie Manager',
    [TerminologyEntity.SECONDARY_MANAGER_PLURAL]: 'Gestrichelte Linie Manager',
    [TerminologyEntity.SITE]: 'Standort',
    [TerminologyEntity.SITE_PLURAL]: 'Standorte',
    [TerminologyEntity.SKILL]: 'Fähigkeit',
    [TerminologyEntity.SKILL_PLURAL]: 'Fähigkeiten',
    [TerminologyEntity.COMPETENCY]: 'Kompetenz',
    [TerminologyEntity.COMPETENCY_PLURAL]: 'Kompetenzen'
  },
  [TerminologyLanguage.ES_LATAM]: {
    [TerminologyEntity.DEPARTMENT]: 'Departamento',
    [TerminologyEntity.DEPARTMENT_PLURAL]: 'Departamentos',
    [TerminologyEntity.TEAM]: 'Equipo',
    [TerminologyEntity.TEAM_PLURAL]: 'Equipos',
    [TerminologyEntity.SECONDARY]: 'Línea punteada',
    [TerminologyEntity.SECONDARY_MANAGER]: 'Gerente de línea punteada',
    [TerminologyEntity.SECONDARY_MANAGER_PLURAL]: 'Gerentes de línea punteada',
    [TerminologyEntity.SITE]: 'Sitio',
    [TerminologyEntity.SITE_PLURAL]: 'Sitios',
    [TerminologyEntity.SKILL]: 'Habilidad',
    [TerminologyEntity.SKILL_PLURAL]: 'Habilidades',
    [TerminologyEntity.COMPETENCY]: 'Competencia',
    [TerminologyEntity.COMPETENCY_PLURAL]: 'Competencias'
  }
};