import {ErrorHandler, Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import * as Sentry from '@sentry/browser';

const CHUNK_LOAD_ERROR = new RegExp(/Loading chunk [\w-]+ failed/);
const WEBSOCKET_CONNECT = new RegExp(/Failed to execute 'send' on 'WebSocket': Still in CONNECTING state./);
const RESIZE_OBSERVER = new RegExp(/ResizeObserver loop limit exceeded/); // Can safely ignore: https://stackoverflow.com/questions/49384120/resizeobserver-loop-limit-exceeded
const RTE_STYLESHEETS_UNDEFINED = new RegExp(/styleSheets is undefined/); // RTEs on localhost
const RTE_DOC_UNDEFINED = new RegExp(/doc is undefined/); // RTEs on localhost

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {

  handleError(error: Error) {
    // Catch Chunk Load Errors
    if (CHUNK_LOAD_ERROR.test(error.message)) {
      if (environment.sentry.enabled) {
        Sentry.captureException(error);
      }
      window.location.reload();
      return;
    }

    // Ignore websocket send Errors
    if (WEBSOCKET_CONNECT.test(error.message)) { return; }
    if (RESIZE_OBSERVER.test(error.message)) { return; }
    if (RTE_STYLESHEETS_UNDEFINED.test(error.message)) { return; }
    if (RTE_DOC_UNDEFINED.test(error.message)) { return; }

    // Send error to sentry if not handled above
    if (environment.sentry.enabled) {
      Sentry.captureException(error);
    }

    console.error('Handled Error', error);
  }

}