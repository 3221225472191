import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CreateIdea, Idea } from 'app/models/feedback/idea.model';
import { IdeaService } from '@app/shared/api/interfaces/idea.service';

@Injectable()
export class IdeaAPIService implements IdeaService {
  private readonly BASE_URL = 'api/idea';

  headers = new HttpHeaders({
    'Content-Type': 'application/json'
  });

  constructor(private http: HttpClient) { }

  submitIdea(createIdea: CreateIdea) {
    const url = `${this.BASE_URL}/submit/`;
    return this.http.post<Idea>(url, createIdea, {headers: this.headers});
  }

  getIdeasSubmitted(): Observable<Idea[]> {
    const url = `${this.BASE_URL}/submitted`;
    return this.http.get<Idea[]>(url);
  }

  getIdeaSubmittedById(id: number): Observable<Idea> {
    const url = `${this.BASE_URL}/submitted/${id}`;
    return this.http.get<Idea>(url);
  }

  getIdeaSubmittedByUserId(id: number): Observable<Idea[]> {
    const url = `${this.BASE_URL}/submitted/user/${id}`;
    return this.http.get<Idea[]>(url);
  }

  getIdeasByManagerMe(): Observable<Idea[]> {
    const url = `${this.BASE_URL}/submitted/manager/me`;
    return this.http.get<Idea[]>(url);
  }
  getIdeasBySecondaryManagerMe(): Observable<Idea[]> {
    const url = `${this.BASE_URL}/submitted/secondary-manager/me`;
    return this.http.get<Idea[]>(url);
  }

  getIdeaBySecondaryManagerAndId(id: number): Observable<Idea> {
    const url = `${this.BASE_URL}/submitted/secondary-manager/id/${id}`;
    return this.http.get<Idea>(url);
  }

  getIdeaByManagerAndId(id: number): Observable<Idea> {
    const url = `${this.BASE_URL}/submitted/manager/id/${id}`;
    return this.http.get<Idea>(url);
  }

  getSidebarIdeas(userIds: number[]): Observable<Idea[]> {
    const url = `${this.BASE_URL}/sidebar`;

    let params = new HttpParams();

    if (userIds) {
      params = params.set('userIds', userIds.join(','));
    }

    return this.http.get<Idea[]>(url, { params: params });
  }

}
