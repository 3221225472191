<div class="container-profile-basic-info">
  <div class="card">
    <div class="content">
      <div class="area-primary-info">
        <div class="area-profile-picture">
          <app-profile-photo [src]="user.imageUrl" [firstName]="user.firstName" [lastName]="user.lastName" [width]="'140px'"></app-profile-photo>
        </div>
  
        <div class="area-profile-name">
          <h1 class="standard-heading">{{user.firstName}} {{user.lastName}}</h1>
          <ng-container *ngIf="secondaryManager">
            <app-pill [type]="ePillType.GREEN">
              <span>{{globals.getTerminology(eTerminologyEntity.SECONDARY)}}</span>
            </app-pill>
          </ng-container>
        </div>
  
        <div class="standard-description">
          <app-rich-display [text]="user.description"></app-rich-display>
        </div>
      </div>
    
      <div class="info-item-list">
        <h4 class="standard-heading">Basic information</h4>

        <div class="info-item" *ngIf="user.dateOfBirth">
          <span class="info-item-icon fal fa-fw fa-cake"></span>
          <div class="info-item-content">
            <div class="info-item-label">Birthday</div>
            <div class="info-item-value">{{user.dateOfBirth | momentPipe: 'Do MMMM'}}</div>
          </div>
        </div>

        <div class="info-item" *ngIf="user.preferredContactMethod">
          <span class="info-item-icon fal fa-fw fa-star"></span>
          <div class="info-item-content">
            <div class="info-item-label">Preferred contact method</div>
            <div class="info-item-value">{{preferredContactMethodFriendlyName}}</div>
          </div>
        </div>

        <div class="info-item" *ngIf="user.email">
          <span class="info-item-icon fal fa-fw fa-envelope"></span>
          <div class="info-item-content">
            <div class="info-item-label">Email address</div>
            <div class="info-item-value"><a [href]="'mailto:'+user.email">{{user.email}}</a></div>
          </div>
        </div>

        <div class="info-item" *ngIf="user.phoneNumber">
          <span class="info-item-icon fal fa-fw fa-phone"></span>
          <div class="info-item-content">
            <div class="info-item-label">Phone number</div>
            <div class="info-item-value">{{user.phoneNumber}}</div>
          </div>
        </div>

        <div class="info-item" *ngIf="user.twitterHandle">
          <span class="info-item-icon fab fa-fw fa-twitter"></span>
          <div class="info-item-content">
            <div class="info-item-label">Twitter</div>
            <div class="info-item-value"><a [href]="'https://twitter.com/'+user.twitterHandle">{{user.twitterHandle}}</a></div>
          </div>
        </div>

        <div class="info-item" *ngIf="user.linkedIn">
          <span class="info-item-icon fab fa-fw fa-linkedin"></span>
          <div class="info-item-content">
            <div class="info-item-label">LinkedIn</div>
            <div class="info-item-value"><a [href]="user.linkedIn">{{user.linkedIn}}</a></div>
          </div>
        </div>
      </div>

      <div class="info-item-list">
        <h4 class="standard-heading">Location & work</h4>

        <div class="info-item" *ngIf="userManager">
          <span class="info-item-icon fal fa-fw fa-user-group"></span>
          <div class="info-item-content">
            <div class="info-item-label">Manager</div>
            <div class="info-item-value"><a [routerLink]="'/profile/' + user.managerId">{{userManager.firstName}} {{userManager.lastName}}</a></div>
          </div>
        </div>

        <div class="info-item" *ngIf="user.position">
          <span class="info-item-icon fal fa-fw fa-briefcase"></span>
          <div class="info-item-content">
            <div class="info-item-label">Role</div>
            <div class="info-item-value">{{user.position.name}}</div>
          </div>
        </div>

        <div class="info-item" *ngIf="user.organisationalUnit">
          <span class="info-item-icon fal fa-fw fa-building"></span>
          <div class="info-item-content">
            <div class="info-item-label">{{globals.getTerminology(eTerminologyEntity.DEPARTMENT)}}</div>
            <div class="info-item-value">{{user.organisationalUnit.name}}</div>
          </div>
        </div>

        <div class="info-item" *ngIf="user.officeLocation">
          <span class="info-item-icon fal fa-fw fa-map-marker-alt"></span>
          <div class="info-item-content">
            <!-- <div class="info-item-label">Working from</div> -->
            <div class="info-item-label">{{globals.getTerminology(eTerminologyEntity.SITE)}}</div>
            <div class="info-item-value">{{user.officeLocation.name}}</div>
          </div>
        </div>

        <div class="info-item" *ngIf="userCurrentTime">
          <span class="info-item-icon fal fa-fw fa-clock"></span>
          <div class="info-item-content">
            <div class="info-item-label">Current time</div>
            <div class="info-item-value">{{userCurrentTime}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>