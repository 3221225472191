import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'ellipsis'
})
export class EllipsisPipe implements PipeTransform {
  previousValue!: string;
  previousArgs!: any;
  resultCached!: string;

  transform(value: string, args: any): string {
    // Skip calculation if value hasn't changed
    if ((value === this.previousValue) && (args === this.previousArgs) && this.resultCached) {
      return this.resultCached;
    }
    this.previousValue = value;
    this.previousArgs = args;

    const maxLength = (args ? Number(args) : 50);

    const result = ((value.length > maxLength) ? value.slice(0, maxLength) + '...' : value)
    this.resultCached = result;
    return result;
  }

}

