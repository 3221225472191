import { User } from '../user/user.model';

export interface FeedbackRequest {
  id: number;
  responses: Array<FeedbackRequestResponse>;
  sender: User;
  regarding: User;
  questions: FeedbackRequestQuestion[];
  timestamp: Date;
}

export interface FeedbackRequestQuestion {
  id: number;
  orderIndex: number;
  questionText: string;
  requestId: number;
}

export interface FeedbackRequestResponse {
  id: number;
  user: User;
  externalUser: User;
  status: FeedbackRequestResponseStatus;
  answers: FeedbackRequestResponseAnswer[];
  timestamp: Date | null;
}

export enum FeedbackRequestResponseStatus {
  PENDING = 'PENDING',
  DECLINED = 'DECLINED',
  APPROVED = 'APPROVED'
}

export class CreateFeedbackRequestDto {
  regarding: User;
  recipients: Array<User>;
  recipientsExternal: Array<FeedbackRequestExternalUser>;
  questions: Array<CreateFeedbackRequestQuestion>;

  constructor(regarding: User, recipients: Array<User>, recipientsExternal: Array<FeedbackRequestExternalUser>, questions: Array<CreateFeedbackRequestQuestion>) {
    this.regarding = regarding;
    this.recipients = recipients;
    this.recipientsExternal = recipientsExternal;
    this.questions = questions;
  }
}
export class CreateFeedbackRequestQuestion {
  orderIndex: number;
  questionText: string;

  constructor(orderIndex: number, questionText: string) {
    this.orderIndex = orderIndex;
    this.questionText = questionText;
  }
}

export interface FeedbackRequestExternalUser {
  id: number;
  email: string;
  token: string;
}

export interface FeedbackRequestResponseAnswer {
  id?: number;
  questionId: number;
  answer: string;
}