<h4 class="m-top-15" *ngIf="showHeader === true">Daily Overview</h4>

<div class="table-container">
  <table class="session-log-user-stickiness" width="100%">
    <thead>
      <tr>
        <th>Date</th>
        <th>Total Users</th>
        <th>Unique Users</th>
        <th>DAU/Total %</th>
        <th>Unique Users last 7 Days</th>
        <th>DAU/WAU %</th>
        <th>WAU/Total %</th>
        <th>Unique Users last 28 Days</th>
        <th>DAU/MAU %</th>
        <th>MAU/Total %</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let day of data">
        <th>{{day.date | date:'dd/MM/yyyy (E)'}}</th>
        <td class="cell-divider">{{day.totalUsers}}</td>
        <td>{{day.dailyActiveUsers}}</td>
        <td class="cell-divider">{{calculateDailyActiveVsTotal(day)}}</td>
        <td>{{day.weeklyActiveUsers}}</td>
        <td>{{calculateDailyActiveVsWeeklyActive(day)}}</td>
        <td class="cell-divider">{{calculateWeeklyActiveVsTotal(day)}}</td>
        <td>{{day.monthlyActiveUsers}}</td>
        <td>{{calculateDailyActiveVsMonthlyActive(day)}}</td>
        <td class="cell-divider">{{calculateMonthlyActiveVsTotal(day)}}</td>
      </tr>
    </tbody>
  </table>
</div>