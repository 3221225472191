export class LocaleDateDifferenceMessages {
    public static readonly SECOND = 'date-difference.second';
    public static readonly SECONDS = 'date-difference.seconds';
    public static readonly MINUTE = 'date-difference.minute';
    public static readonly MINUTES = 'date-difference.minutes';
    public static readonly HOUR = 'date-difference.hour';
    public static readonly HOURS = 'date-difference.hours';
    public static readonly DAY = 'date-difference.day';
    public static readonly DAYS = 'date-difference.days';
    public static readonly MONTH = 'date-difference.month';
    public static readonly MONTHS = 'date-difference.months';
    public static readonly YEAR = 'date-difference.year';
    public static readonly YEARS = 'date-difference.years';
    public static readonly WEEK = 'date-difference.week';
    public static readonly WEEKS = 'date-difference.weeks';
}