import moment from 'moment';

export class EvaluagentEvaluationObject {
  id: number;
  userId: number;
  publishedAt: Date;
  updatedAt: Date;
  notes: string;
  mode: string;
  status: string;
  scorecard: string;
  outcome: string;
  outcomeName: string;
  numOfAutofails: number;
  qualityScore: string;
  secondsElapsed: number;
  evaluationId: string;
  evaluatorId: string;

  constructor(evaluagentEvaluationObjectServerSide: EvaluagentEvaluationObjectServerSide) {
    if (!evaluagentEvaluationObjectServerSide) { return undefined; }

    this.id = evaluagentEvaluationObjectServerSide.id;
    this.userId = evaluagentEvaluationObjectServerSide.userId;
    this.publishedAt = moment.utc(evaluagentEvaluationObjectServerSide.publishedAt).toDate();
    this.updatedAt = moment.utc(evaluagentEvaluationObjectServerSide.updatedAt).toDate();
    this.notes = evaluagentEvaluationObjectServerSide.notes;
    this.mode = evaluagentEvaluationObjectServerSide.mode;
    this.status = evaluagentEvaluationObjectServerSide.status;
    this.scorecard = evaluagentEvaluationObjectServerSide.scorecard;
    this.outcome = evaluagentEvaluationObjectServerSide.outcome;
    this.outcomeName = evaluagentEvaluationObjectServerSide.outcomeName;
    this.numOfAutofails = evaluagentEvaluationObjectServerSide.numOfAutofails;
    this.qualityScore = evaluagentEvaluationObjectServerSide.qualityScore;
    this.secondsElapsed = evaluagentEvaluationObjectServerSide.secondsElapsed;
    this.evaluationId = evaluagentEvaluationObjectServerSide.evaluationId;
    this.evaluatorId = evaluagentEvaluationObjectServerSide.evaluatorId;
  }
}

export interface EvaluagentEvaluationObjectServerSide {
  id: number;
  userId: number;
  publishedAt: string;
  updatedAt: string;
  notes: string;
  mode: string;
  status: string;
  scorecard: string;
  outcome: string;
  outcomeName: string;
  numOfAutofails: number;
  qualityScore: string;
  secondsElapsed: number;
  evaluationId: string;
  evaluatorId: string;
}
