<ng-container *ngFor='let ag of assessmentGroups'>
  <ng-container>
    <div class="row m-left-0 m-right-0 border-top row-ag">
      <div class="circle-col">
        <div class="circle circle-text" [style.background]="ag.colour">
          <span>{{ag.value}}</span>
        </div>
      </div>
      <div class="ag-col frankli-form-group frankli-form-group-placeholder-shown input-group">
        <div class="ag-name"><input type="text" class="form-control" maxlength="250" [(ngModel)]="ag.groupTitle"></div>
        <div class="ag-desc"><textarea type="text" class="form-control noresize" maxlength="2000" [(ngModel)]="ag.groupDescription" ></textarea></div>
      </div>
    </div>
  </ng-container>
</ng-container>
<!-- <div class="row text-center p-bottom-5"><button (click)="saveFields()" class="btn-frankli-round btn-frankli-green-inverted">Save</button></div> -->
