import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'localeDateDifference'
})
export class LocaleDateDifferencePipe implements PipeTransform {
  constructor(private translateService: TranslateService) { }

  transform(value: Date): string {
    const date = moment(value);
    const now = moment();
    const delta =  now.diff(date, 'seconds');

    return this.findDifferencePast(delta);
  }

  private findDifferencePast(delta: number): string {
    let unitValue: number;
    let code: string;
    // format string
    if (delta < 60) { // sent in last minute
      unitValue = delta;
      code = 'date-difference.second';
    } else if (delta < 3600) { // sent in last hour
      unitValue = Math.floor(delta / 60);
      code = 'date-difference.minute';
    } else if (delta < 86400) { // sent on last day
      unitValue = Math.floor(delta / 3600);
      code = 'date-difference.hour';
    } else if (delta < 604800) { // sent more than one day ago
      unitValue = Math.floor(delta / 86400);
      code = 'date-difference.day';
    } else if (delta < 2592000) { // sent more than one week ago
      unitValue = Math.floor(delta / 604800);
      code = 'date-difference.week';
    } else if (delta < 31536000) { // sent more than one month ago
      unitValue = Math.floor(delta / 2592000);
      code = 'date-difference.month';
    } else { // sent more than one year ago
      unitValue = Math.floor(delta / 31536000);
      code = 'date-difference.year';
    }

    // add s for in case of multiples
    if (unitValue > 1) {
      code += 's'
    }

    return this.translateService.instant(code, { unitValue });
  }
}
