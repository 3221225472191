import { Component, OnDestroy, OnInit } from '@angular/core';
import { CompanyFeatures } from '@app/models/company-features.model';
import { GoalsAPIService } from 'app/shared/api/goals.api.service';
import { Globals } from 'app/shared/globals/globals';
import { GoalType } from '@app/models/goals/goal-type.enum';
import { Page } from '@app/models/api/page.model';
import { GoalTagOverview } from '@app/models/goals/goal-tag-overview';
import { PagingParams } from '@app/models/api/paging-params.model';
import { SortingParams } from '@app/models/api/sorting-params.model';
import { SortDirection } from '@app/models/api/sort-direction.enum';
import { FormControl } from '@angular/forms';
import { debounceTime, map, takeUntil } from 'rxjs/operators';
import { SearchUtils } from '@app/shared/utils/search.utils';
import { Subject } from 'rxjs';
import { GoalUtils } from '@app/shared/utils/goal.utils';
import { GoalStatus } from '@app/models/goals/goal-status.model';
import { ColumnInfo } from '@app/shared/column-toggle-new/column.info.model';
import { GoalsMessages } from '@app/goals/goals.messages';
import { IState } from '@app/models/state/state.model';
import { TagAPIService } from '@app/domain/tag/service/tag-api.service';

enum TableColumn {
  NAME,
  DESCRIPTION,
  NUMBER_OF_GOALS
}

@Component({
  selector: 'app-goal-manager-tags',
  templateUrl: './goal-manager-tags.component.html',
  styleUrls: ['./goal-manager-tags.component.scss']
})
export class GoalManagerTagsComponent implements OnInit, OnDestroy {
  public readonly eGoalUtils = GoalUtils;
  public readonly eTableColumn = TableColumn;
  public readonly eFeature = CompanyFeatures;
  public readonly eGoalType = GoalType;
  public readonly eGoalStatus = GoalStatus;
  public readonly eGoalsMessages = GoalsMessages;
  public readonly ngUnsubscribe$ = new Subject<void>();

  state: IState;
  tagOverviewPage?: Page<GoalTagOverview>;
  
  pagingParams: PagingParams;
  sortingParams: SortingParams;
  
  opened: boolean[];
  searchControl: FormControl;
  
  columnInfo: ColumnInfo[];
  alwaysEnabledColumns: TableColumn[];

  maxGoalsCount: number;

  constructor(
    private goalsAPIService: GoalsAPIService,
    private tagAPIService: TagAPIService,
    public globals: Globals
  ) {
    this.opened = [];
    this.columnInfo = this.getColumnInfo();
    this.alwaysEnabledColumns = [TableColumn.NAME];

    this.tagOverviewPage = undefined;
    this.state = {
      loading: true,
      error: false,
      errorMessage: ''
    };

    this.pagingParams = {
      pageSize: 15,
      pageNumber: 0
    };

    this.sortingParams = {
      sortAttributes: ['text'],
      sortDirection: SortDirection.ASC
    };

    this.searchControl = new FormControl(null, []);

    this.maxGoalsCount = 0;
  }

  ngOnInit() {
    this.searchControl.valueChanges
      .pipe(
        takeUntil(this.ngUnsubscribe$),
        map(searchValue => SearchUtils.parseSearchArgument(searchValue)),
        debounceTime(500)
      )
      .subscribe((searchArgument) => this.searchGoalTagOverviews(searchArgument));

    this.searchControl.setValue('');
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.unsubscribe();
  }

  searchGoalTagOverviews(title?: string) {
    this.state.loading = true;
    this.tagAPIService.getTagOverviews(this.pagingParams, this.sortingParams, title).subscribe(tagOverviewPage => {
      this.maxGoalsCount = this.calculateMaxGoalsCount(tagOverviewPage.content);
      this.tagOverviewPage = tagOverviewPage;
      this.state.loading = false;
    });
  }

  changePageSize($event: number) {
    this.pagingParams.pageSize = $event;
    this.searchGoalTagOverviews();
  }

  changePageNumber($event: number) {
    this.pagingParams.pageNumber = $event;
    this.searchGoalTagOverviews();
  }

  trackTagsDisplayed(index: number, item: GoalTagOverview) {
    return item.goalTag.id;
  }

  toggleDropdown(i: number) {
    this.opened[i] = !this.opened[i];
  }

  closeAllDropdowns() {
    this.opened = [];
  }

  getColumnInfo(): ColumnInfo[] {
    return [
      {
        index: TableColumn.NAME,
        titleMessageCode: GoalsMessages.TAG_NAME
      },
      {
        index: TableColumn.DESCRIPTION,
        titleMessageCode: GoalsMessages.TAG_DESCRIPTION
      },
      {
        index: TableColumn.NUMBER_OF_GOALS,
        titleMessageCode: GoalsMessages.TAG_NUMBER_OF_GOALS
      }
    ];
  }

  calculateMaxGoalsCount(page: GoalTagOverview[]): number {
    let max = 0;

    page.forEach(p => {
      while (p.goalCount > max) {
        max += 10;
      }
    });

    return max;
  }
}
