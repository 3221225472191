import { ISODateString } from '@app/shared/utils/date-string.util';
import moment from 'moment';
import { User, UserServerSide } from '../../user/user.model';
import { PollFrequency } from '../poll-frequency.enum';
import { PollDraftQuestion } from './poll-draft-question.model';

export interface PollDraftServerSide {
  id: number;
  creatorId: number;
  creatorUserShown: boolean;
  title: string;
  description: string;
  timezone: string;
  expiryDate: ISODateString | null;
  publishDate: ISODateString | null;
  recipients: Array<UserServerSide>;
  pollQuestions: Array<PollDraftQuestion>;
  creationDate: ISODateString;
  updateDate: ISODateString | null;
  frequency: PollFrequency;
}

export class PollDraft {
  id: number;
  creatorId: number;
  creatorUserShown: boolean;
  title: string;
  description: string;
  timezone: string;
  expiryDate: Date | null;
  publishDate: Date | null;
  recipients: Array<User>;
  pollQuestions: Array<PollDraftQuestion>;
  creationDate: Date;
  updateDate: Date | null;
  frequency: PollFrequency;

  constructor(pollDraftServerSide: PollDraftServerSide) {
    this.id = pollDraftServerSide.id;
    this.creatorId = pollDraftServerSide.creatorId;
    this.creatorUserShown = pollDraftServerSide.creatorUserShown;
    this.title = pollDraftServerSide.title;
    this.description = pollDraftServerSide.description;
    this.timezone = pollDraftServerSide.timezone;
    this.expiryDate = (pollDraftServerSide.expiryDate === null) ? null : moment.utc(pollDraftServerSide.expiryDate).toDate();
    this.publishDate = (pollDraftServerSide.publishDate === null) ? null : moment.utc(pollDraftServerSide.publishDate).toDate();
    this.recipients = pollDraftServerSide.recipients.map(u => new User(u));
    this.pollQuestions = pollDraftServerSide.pollQuestions;
    this.creationDate = moment.utc(pollDraftServerSide.creationDate).toDate();
    this.updateDate = (pollDraftServerSide.updateDate === null) ? null : moment.utc(pollDraftServerSide.updateDate).toDate();
    this.frequency = pollDraftServerSide.frequency;
  }
  
}

