<div class="competency-item-container" [class.dropdown-open]="dropdownExpanded" *ngIf="competency">
  <div class="card-header" (click)="toggleDropdown()">
    <div class="col-arrow"[title]="(dropdownExpanded ? eCommonMessages.COLLAPSE : eCommonMessages.EXPAND) | translate">
      <div class="fal fa-fw fa-caret-right"></div>
    </div>
    <div class="col-details">
      <div class="display-competency-name">
        <h5 class="standard-heading">{{competency.name}}</h5>
        <ng-container *ngIf="competency.category">
          <div class="standard-description">{{competency.category}}</div>
        </ng-container>
      </div>
      <ng-container *ngIf="globals.hasFeature(eCompanyFeatures.SKILL_RATING) && skillRatingConfiguration; else templateNoSkillRatings;">
        <div class="item-rating" [tooltip]="skillRating ? tooltipScoreComment : undefined" placement="bottom" container="body">
          <ng-container *ngIf="showScore">
            <app-star-scale [ngModel]="skillRating ? skillRating.scoreValue : -1" [disabled]="true" [starCount]="skillRatingConfiguration?.scoreCount" [iconUsed]="skillRatingConfiguration?.scoreIcon" [optionMetadata]="skillRatingConfiguration?.options"></app-star-scale>
          </ng-container>
        </div>
        <ng-template #tooltipScoreComment>
          <div class="standard-description text-left" *ngIf="skillRating">
            <ng-container *ngIf="metadata">
              <strong>{{metadata.name}}</strong>
              <app-rich-display [text]="metadata.description"></app-rich-display>
            </ng-container>
            <ng-container *ngIf="skillRating.scoreComment">
              <hr class="row m-top-0 m-bottom-10"/>
              <app-rich-display [text]="skillRating.scoreComment"></app-rich-display>
            </ng-container>
          </div>
        </ng-template>
      </ng-container>
      <ng-template #templateNoSkillRatings>
        <div></div>
      </ng-template>
    </div>
  </div>
  <div class="dropdown-contents">
    <div class="display-competency-description">
      <div class="standard-description">
        <app-rich-display [text]="competency.description"></app-rich-display>
      </div>
    </div>
  </div>
</div>
