import { TerminologyCollection } from '@app/domain/terminology/model/terminology-collection.model';
import { TerminologyEntity } from '@app/domain/terminology/model/terminology-entity.enum';

export const mockTerminology: TerminologyCollection = {
  [TerminologyEntity.DEPARTMENT]: 'Department',
  [TerminologyEntity.DEPARTMENT_PLURAL]: 'Departments',
  [TerminologyEntity.TEAM]: 'Team',
  [TerminologyEntity.TEAM_PLURAL]: 'Teams', 
  [TerminologyEntity.SECONDARY]: 'Dotted-line', 
  [TerminologyEntity.SECONDARY_MANAGER]: 'Dotted-line Manager',
  [TerminologyEntity.SECONDARY_MANAGER_PLURAL]: 'Dotted-line Managers', 
  [TerminologyEntity.SITE]: 'Site',
  [TerminologyEntity.SITE_PLURAL]: 'Sites',
  [TerminologyEntity.SKILL]: 'Skill',
  [TerminologyEntity.SKILL_PLURAL]: 'Skills',
  [TerminologyEntity.COMPETENCY]: 'Competency',
  [TerminologyEntity.COMPETENCY_PLURAL]: 'Competencies',
};