import { SurveyQuestionType } from '@app/models/survey-question-type.enum';
import { QuestionBankQuestionCategory } from '@app/models/evaluation/question-bank-question-category.enum';

export const assessmentQuestions = [
  // Manager review
  ... [
    {
      colour: '#008168',
      groupTitle: 'Significantly Exceeds Expectations',
      groupDescription: 'Delivered exceptional added value to business beyond scope',
      value: '5',
      questionType: SurveyQuestionType.ONE_TO_FIVE,
      answerIndex: 0,
      category: QuestionBankQuestionCategory.MANAGER_REVIEW
    },
    {
      colour: '#30747F',
      groupTitle: 'Exceeds Expectations',
      groupDescription: 'Delivered differentiated value within business/work area/team',
      value: '4',
      questionType: SurveyQuestionType.ONE_TO_FIVE,
      answerIndex: 1,
      category: QuestionBankQuestionCategory.MANAGER_REVIEW
    },
    {
      colour: '#FFC200',
      groupTitle: 'Meets Expectations',
      groupDescription: 'Delivered positive value to business/work area/team',
      value: '3',
      questionType: SurveyQuestionType.ONE_TO_FIVE,
      answerIndex: 2,
      category: QuestionBankQuestionCategory.MANAGER_REVIEW
    },
    {
      colour: '#FB946E',
      groupTitle: 'Mostly Met Expectations',
      groupDescription: 'Delivered some added value to business/work area/team',
      value: '2',
      questionType: SurveyQuestionType.ONE_TO_FIVE,
      answerIndex: 3,
      category: QuestionBankQuestionCategory.MANAGER_REVIEW
    },
    {
      colour: '#d0352c',
      groupTitle: 'Did Not Meet Expectations',
      groupDescription: 'Did not add value and/or negative impact',
      value: '1',
      questionType: SurveyQuestionType.ONE_TO_FIVE,
      answerIndex: 4,
      category: QuestionBankQuestionCategory.MANAGER_REVIEW
    },
    {
      colour: '#008168',
      groupTitle: 'Top Performer',
      groupDescription: 'Role model. Hard to see how their performance could be improved upon',
      value: '4',
      questionType: SurveyQuestionType.ONE_TO_FOUR,
      answerIndex: 0,
      category: QuestionBankQuestionCategory.MANAGER_REVIEW
    },
    {
      colour: '#30747F',
      groupTitle: 'Above Average Performer',
      groupDescription: 'Consistently delivers and has demonstrated strong potential in their role',
      value: '3',
      questionType: SurveyQuestionType.ONE_TO_FOUR,
      answerIndex: 1,
      category: QuestionBankQuestionCategory.MANAGER_REVIEW
    },
    {
      colour: '#FFC200',
      groupTitle: 'Average Performer',
      groupDescription: 'Could apply themselves more to their current role',
      value: '2',
      questionType: SurveyQuestionType.ONE_TO_FOUR,
      answerIndex: 2,
      category: QuestionBankQuestionCategory.MANAGER_REVIEW
    },
    {
      colour: '#FB946E',
      groupTitle: 'Below Average Performer',
      groupDescription: 'Performs below what is expected of them in their current role. Needs further support',
      value: '1',
      questionType: SurveyQuestionType.ONE_TO_FOUR,
      answerIndex: 3,
      category: QuestionBankQuestionCategory.MANAGER_REVIEW
    },
    {
      colour: '#008168',
      groupTitle: 'Exceeds Expectations',
      groupDescription: 'Role model. Hard to see how their performance could be improved upon',
      value: '3',
      questionType: SurveyQuestionType.ONE_TO_THREE,
      answerIndex: 0,
      category: QuestionBankQuestionCategory.MANAGER_REVIEW
    },
    {
      colour: '#FFC200',
      groupTitle: 'Meets Expectations',
      groupDescription: 'Could apply themselves more to their current role',
      value: '2',
      questionType: SurveyQuestionType.ONE_TO_THREE,
      answerIndex: 1,
      category: QuestionBankQuestionCategory.MANAGER_REVIEW
    },
    {
      colour: '#FF6F68',
      groupTitle: 'Below Expectations',
      groupDescription: 'Performs below what is expected of them in their current role. Needs further support',
      value: '1',
      questionType: SurveyQuestionType.ONE_TO_THREE,
      answerIndex: 2,
      category: QuestionBankQuestionCategory.MANAGER_REVIEW
    }
  ],

  // Self reflection
  ...[
    {
      colour: '#008168',
      groupTitle: 'Significantly Exceeds Expectations',
      groupDescription: 'Delivered exceptional added value to business beyond scope',
      value: '5',
      questionType: SurveyQuestionType.ONE_TO_FIVE,
      answerIndex: 0,
      category: QuestionBankQuestionCategory.SELF_REFLECTION
    },
    {
      colour: '#30747F',
      groupTitle: 'Exceeds Expectations',
      groupDescription: 'Delivered differentiated value within business/work area/team',
      value: '4',
      questionType: SurveyQuestionType.ONE_TO_FIVE,
      answerIndex: 1,
      category: QuestionBankQuestionCategory.SELF_REFLECTION
    },
    {
      colour: '#FFC200',
      groupTitle: 'Meets Expectations',
      groupDescription: 'Delivered positive value to business/work area/team',
      value: '3',
      questionType: SurveyQuestionType.ONE_TO_FIVE,
      answerIndex: 2,
      category: QuestionBankQuestionCategory.SELF_REFLECTION
    },
    {
      colour: '#FB946E',
      groupTitle: 'Mostly Met Expectations',
      groupDescription: 'Delivered some added value to business/work area/team',
      value: '2',
      questionType: SurveyQuestionType.ONE_TO_FIVE,
      answerIndex: 3,
      category: QuestionBankQuestionCategory.SELF_REFLECTION
    },
    {
      colour: '#d0352c',
      groupTitle: 'Did Not Meet Expectations',
      groupDescription: 'Did not add value and/or negative impact',
      value: '1',
      questionType: SurveyQuestionType.ONE_TO_FIVE,
      answerIndex: 4,
      category: QuestionBankQuestionCategory.SELF_REFLECTION
    },
    {
      colour: '#008168',
      groupTitle: 'Top Performer',
      groupDescription: 'Role model. Hard to see how their performance could be improved upon',
      value: '4',
      questionType: SurveyQuestionType.ONE_TO_FOUR,
      answerIndex: 0,
      category: QuestionBankQuestionCategory.SELF_REFLECTION
    },
    {
      colour: '#30747F',
      groupTitle: 'Above Average Performer',
      groupDescription: 'Consistently delivers and has demonstrated strong potential in their role',
      value: '3',
      questionType: SurveyQuestionType.ONE_TO_FOUR,
      answerIndex: 1,
      category: QuestionBankQuestionCategory.SELF_REFLECTION
    },
    {
      colour: '#FFC200',
      groupTitle: 'Average Performer',
      groupDescription: 'Could apply themselves more to their current role',
      value: '2',
      questionType: SurveyQuestionType.ONE_TO_FOUR,
      answerIndex: 2,
      category: QuestionBankQuestionCategory.SELF_REFLECTION
    },
    {
      colour: '#FB946E',
      groupTitle: 'Below Average Performer',
      groupDescription: 'Performs below what is expected of them in their current role. Needs further support',
      value: '1',
      questionType: SurveyQuestionType.ONE_TO_FOUR,
      answerIndex: 3,
      category: QuestionBankQuestionCategory.SELF_REFLECTION
    },
    {
      colour: '#008168',
      groupTitle: 'Exceeds Expectations',
      groupDescription: 'Role model. Hard to see how their performance could be improved upon',
      value: '3',
      questionType: SurveyQuestionType.ONE_TO_THREE,
      answerIndex: 0,
      category: QuestionBankQuestionCategory.SELF_REFLECTION
    },
    {
      colour: '#FFC200',
      groupTitle: 'Meets Expectations',
      groupDescription: 'Could apply themselves more to their current role',
      value: '2',
      questionType: SurveyQuestionType.ONE_TO_THREE,
      answerIndex: 1,
      category: QuestionBankQuestionCategory.SELF_REFLECTION
    },
    {
      colour: '#FF6F68',
      groupTitle: 'Below Expectations',
      groupDescription: 'Performs below what is expected of them in their current role. Needs further support',
      value: '1',
      questionType: SurveyQuestionType.ONE_TO_THREE,
      answerIndex: 2,
      category: QuestionBankQuestionCategory.SELF_REFLECTION
    }
  ],

  // Upward review
  ... [
    {
      colour: '#008168',
      groupTitle: 'Significantly Exceeds Expectations',
      groupDescription: 'Delivered exceptional added value to business beyond scope',
      value: '5',
      questionType: SurveyQuestionType.ONE_TO_FIVE,
      answerIndex: 0,
      category: QuestionBankQuestionCategory.UPWARD_REVIEW
    },
    {
      colour: '#30747F',
      groupTitle: 'Exceeds Expectations',
      groupDescription: 'Delivered differentiated value within business/work area/team',
      value: '4',
      questionType: SurveyQuestionType.ONE_TO_FIVE,
      answerIndex: 1,
      category: QuestionBankQuestionCategory.UPWARD_REVIEW
    },
    {
      colour: '#FFC200',
      groupTitle: 'Meets Expectations',
      groupDescription: 'Delivered positive value to business/work area/team',
      value: '3',
      questionType: SurveyQuestionType.ONE_TO_FIVE,
      answerIndex: 2,
      category: QuestionBankQuestionCategory.UPWARD_REVIEW
    },
    {
      colour: '#FB946E',
      groupTitle: 'Mostly Met Expectations',
      groupDescription: 'Delivered some added value to business/work area/team',
      value: '2',
      questionType: SurveyQuestionType.ONE_TO_FIVE,
      answerIndex: 3,
      category: QuestionBankQuestionCategory.UPWARD_REVIEW
    },
    {
      colour: '#d0352c',
      groupTitle: 'Did Not Meet Expectations',
      groupDescription: 'Did not add value and/or negative impact',
      value: '1',
      questionType: SurveyQuestionType.ONE_TO_FIVE,
      answerIndex: 4,
      category: QuestionBankQuestionCategory.UPWARD_REVIEW
    },
    {
      colour: '#008168',
      groupTitle: 'Top Performer',
      groupDescription: 'Role model. Hard to see how their performance could be improved upon',
      value: '4',
      questionType: SurveyQuestionType.ONE_TO_FOUR,
      answerIndex: 0,
      category: QuestionBankQuestionCategory.UPWARD_REVIEW
    },
    {
      colour: '#30747F',
      groupTitle: 'Above Average Performer',
      groupDescription: 'Consistently delivers and has demonstrated strong potential in their role',
      value: '3',
      questionType: SurveyQuestionType.ONE_TO_FOUR,
      answerIndex: 1,
      category: QuestionBankQuestionCategory.UPWARD_REVIEW
    },
    {
      colour: '#FFC200',
      groupTitle: 'Average Performer',
      groupDescription: 'Could apply themselves more to their current role',
      value: '2',
      questionType: SurveyQuestionType.ONE_TO_FOUR,
      answerIndex: 2,
      category: QuestionBankQuestionCategory.UPWARD_REVIEW
    },
    {
      colour: '#FB946E',
      groupTitle: 'Below Average Performer',
      groupDescription: 'Performs below what is expected of them in their current role. Needs further support',
      value: '1',
      questionType: SurveyQuestionType.ONE_TO_FOUR,
      answerIndex: 3,
      category: QuestionBankQuestionCategory.UPWARD_REVIEW
    },
    {
      colour: '#008168',
      groupTitle: 'Exceeds Expectations',
      groupDescription: 'Role model. Hard to see how their performance could be improved upon',
      value: '3',
      questionType: SurveyQuestionType.ONE_TO_THREE,
      answerIndex: 0,
      category: QuestionBankQuestionCategory.UPWARD_REVIEW
    },
    {
      colour: '#FFC200',
      groupTitle: 'Meets Expectations',
      groupDescription: 'Could apply themselves more to their current role',
      value: '2',
      questionType: SurveyQuestionType.ONE_TO_THREE,
      answerIndex: 1,
      category: QuestionBankQuestionCategory.UPWARD_REVIEW
    },
    {
      colour: '#FF6F68',
      groupTitle: 'Below Expectations',
      groupDescription: 'Performs below what is expected of them in their current role. Needs further support',
      value: '1',
      questionType: SurveyQuestionType.ONE_TO_THREE,
      answerIndex: 2,
      category: QuestionBankQuestionCategory.UPWARD_REVIEW
    }
  ]
];

export const questionDataConstants = {
  eNPS: [
    {
      colour: '#FB946E',
      name: 'Detractor',
      value: '1',
    },
    {
      colour: '#FB946E',
      name: 'Detractor',
      value: '2',
    },
    {
      colour: '#FB946E',
      name: 'Detractor',
      value: '3',
    },
    {
      colour: '#FB946E',
      name: 'Detractor',
      value: '4',
    },
    {
      colour: '#FB946E',
      name: 'Detractor',
      value: '5',
    },
    {
      colour: '#FB946E',
      name: 'Detractor',
      value: '6',
    },
    {
      colour: '#FFC200',
      name: 'Neutral',
      value: '7',
    },
    {
      colour: '#FFC200',
      name: 'Neutral',
      value: '8',
    },
    {
      colour: '#30747F',
      name: 'Promoter',
      value: '9',
    },
    {
      colour: '#30747F',
      name: 'Promoter',
      value: '10',
    }
  ]
}
