import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ZendeskAPIService } from 'app/shared/api/zendesk.api.service';

@Component({
  selector: 'app-zendesk-callback',
  templateUrl: './zendesk-callback.component.html',
  styleUrls: ['./zendesk-callback.component.scss']
})
export class ZendeskCallbackComponent implements OnInit {

  constructor(
    public route: ActivatedRoute,
    private router: Router,
    private zendeskAPIService: ZendeskAPIService
  ) { }

  ngOnInit(): void {
    const code = this.route.snapshot.queryParamMap.get('code');

    if (code === null) {
      this.handleError('Something went wrong when trying to sign you in.');
      return;
    }

    this.zendeskAPIService.clientToken(code!).subscribe(() => {
      this.router.navigateByUrl('admin/dashboard/integrations/zendesk');
    });
  }

  private handleError(message: string): void {
    localStorage.setItem('zendesk_error', `${Date.now()}: ${message}`);
    this.router.navigateByUrl('admin/dashboard/integrations/zendesk');
  }

}
