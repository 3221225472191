import { Component } from '@angular/core';
import { Competency } from '@app/domain/competency/model/competency.model';
import { ModalComponent } from '@app/shared/modal/modal.component';
import { Globals } from '../../../../shared/globals/globals';
import { SwalUtils } from '../../../../shared/utils/swal.utils';

@Component({
  selector: 'app-competency-modal',
  templateUrl: './competency-modal.component.html',
  styleUrls: ['./competency-modal.component.scss']
})
export class CompetencyModalComponent extends ModalComponent {
  competency!: Competency;

  constructor(
    globals: Globals,
    swalUtils: SwalUtils
) {
    super(globals, swalUtils);
  }

  showFor(competency: Competency) {
    this.competency = competency;
    this.show();
  }
}
