import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';

const imageFormats = ['png', '']

@Component({
  selector: 'app-uploaded-file-link-preview',
  templateUrl: './uploaded-file-link-preview.component.html',
  styleUrls: ['./uploaded-file-link-preview.component.css']
})
export class UploadedFileLinkPreviewComponent implements OnInit {
  @Input() fileURL!: string;
  @ViewChild('previewImageContainer') imageContainer?: ElementRef<HTMLDivElement>;

  fileName: string = 'File';
  isImage: boolean = false;
  imageError: boolean = false;

  constructor() { }

  ngOnInit() {
    this.parseFile(this.fileURL)
  }

  parseFile(fileURL: string) {
    const friendlyFileName = this.getFileNameDecoded(this.fileURL);
    if (friendlyFileName) {
      this.fileName = friendlyFileName;
      const extension = this.getFileExtension(friendlyFileName);
    }
  }

  private getFileNameDecoded(fileURL: string): (string | undefined) {
    fileURL = fileURL.replace(/[\#\?].*$/, '');

    const urlParts = fileURL.split('/');

    if (urlParts.length > 0) {
      const lastItem = urlParts.pop();

      if (lastItem) {
        return decodeURIComponent(lastItem)
      }
    }

    return undefined;
  }

  private getFileExtension(fileName: string): (string | undefined) {
    const nameSplit = fileName.split('.');

    if (nameSplit.length > 0) {
      let extension = nameSplit.pop();

      if (extension) {
        extension = extension.toLowerCase();
        this.isImage = imageFormats.includes(extension);
        return extension;
      }
    }

    return undefined;
  }

  onImageError() {
    this.imageError = true;
  }
}
