import {
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR} from '@angular/forms';
import {
  AngularMyDatePickerDirective,
  DefaultView,
  IAngularMyDpOptions,
  IMyCalendarAnimation,
  IMyDate,
  IMyDateModel,
  IMyDateRange,
  IMyDayLabels,
  IMyDisabledDates,
  IMyDivHostElement,
  IMyMarkedDate,
  IMyMarkedDates,
  IMyMonthLabels,
  IMyStyles,
} from 'angular-mydatepicker';
import moment from 'moment';
import { DateUtils } from '../date.utils';

// See: https://github.com/kekeh/angular-mydatepicker
// for more configuration options, guides and examples

// NOTES:
// - Not bothering with the daterange stuff for now since we already have a component for that and just need to get this working. Can update this to work later for consistency.
// - If you want a Date AND Time picker, use the DateTimePickerComponent instead
@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.css'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => DatePickerComponent),
    multi: true,
  }],
})
export class DatePickerComponent implements ControlValueAccessor, OnInit, OnDestroy, OnChanges {
  @ViewChild('datePicker') datePickerInput?: (ElementRef | AngularMyDatePickerDirective);

  // COMMON
  @Input() name: string;
  @Input() placeholder: string;
  @Input() disabled: boolean;
  @Input() displayFormat: string;
  @Input() showResetButton: boolean; // Whether or not to show the reset button
  @Input() showClearButton: boolean; // Whether or not to show the clear button

  // DATE PICKER NATIVE
  @Input() dateRange: boolean;
  @Input() inline: boolean;
  @Input() dayLabels: IMyDayLabels;
  @Input() monthLabels: IMyMonthLabels;
  @Input() dateFormat: string;
  @Input() defaultView: DefaultView; // 1 = day, 2 = month, 3 = year
  @Input() firstDayOfWeek: string;
  @Input() satHighlight: boolean;
  @Input() sunHighlight: boolean;
  @Input() highlightDates: IMyDate[];
  @Input() markCurrentDay: boolean;
  @Input() markCurrentMonth: boolean;
  @Input() markCurrentYear: boolean;
  @Input() monthSelector: boolean;
  @Input() yearSelector: boolean;
  @Input() disableHeaderButtons: boolean;
  @Input() showWeekNumbers: boolean;
  @Input() selectorHeight: string;
  @Input() selectorWidth: string;
  @Input() disableUntil: IMyDate | null; // Disable all dates up to this date
  @Input() disableSince: IMyDate | null; // Disable all dates after this date
  @Input() disableDates: (IMyDate[] | IMyDisabledDates[]);
  @Input() disableDateRanges: IMyDateRange[];
  @Input() disableWeekends: boolean;
  @Input() disableWeekdays: string[];
  @Input() enableDates: IMyDate[];
  @Input() markDates: IMyMarkedDates[];
  @Input() markWeekends: IMyMarkedDate;
  @Input() alignSelectorRight: boolean; // Stopped implementing here
  @Input() openSelectorTopOfInput: boolean;
  @Input() closeSelectorOnDateSelect: boolean;
  @Input() closeSelectorOnDocumentClick: boolean;
  @Input() minYear: number;
  @Input() maxYear: number;
  @Input() showSelectorArrow: boolean;
  @Input() appendSelectorToBody: boolean;
  @Input() focusInputOnDateSelect: boolean;
  @Input() moveFocusByArrowKeys: boolean;
  @Input() dateRangeDatesDelimiter: string;
  @Input() inputFieldValidation: boolean;
  @Input() showMonthNumber: boolean;
  @Input() todayTxt: string;
  @Input() showFooterToday: boolean;
  @Input() calendarAnimation: IMyCalendarAnimation;
  @Input() viewChangeAnimation: boolean;
  @Input() rtl: boolean;
  @Input() stylesData: IMyStyles;
  @Input() divHostElement: IMyDivHostElement;
  @Input() ariaLabelPrevMonth: string;
  @Input() ariaLabelNextMonth: string;
  @Input() locale: string;
  @Input() outputUTC: boolean;

  @Output() calendarToggle: EventEmitter<boolean>;

  config: IAngularMyDpOptions;
  innerFormControl!: FormControl;

  dateDisplay: string | null;
  _value!: Date | null;

  get value() {
    return this._value; // When date range implemented, store the whole object on set and get whichever part is relevant
  }

  set value(val: Date | null) {
    if (this.disabled) {
      console.error('Tried setting disabled picker');
      return;
    }

    this.writeValue(val);
    this.onChange(val);
  }

  constructor() {
    this.config = {};

    this.outputUTC = false;
    this.name = '';
    this.placeholder = '';
    this.todayTxt = '';
    this.dateFormat = 'yyyy-mm-dd'; // Datepicker formatting syntax
    this.displayFormat = 'YYYY-MM-DD'; // Moment formattign syntax
    this.firstDayOfWeek = 'mo';
    this.selectorHeight = '266px';
    this.selectorWidth = '266px';
    this.dateRangeDatesDelimiter = '-';
    this.ariaLabelPrevMonth = 'Previous Month';
    this.ariaLabelNextMonth = 'Next Month';

    this.showResetButton = false;
    this.showClearButton = false;
    this.dateRange = false;
    this.disabled = false;
    this.inline = false;
    this.satHighlight = false;
    this.sunHighlight = false;
    this.markCurrentDay = true;
    this.markCurrentMonth = true;
    this.markCurrentYear = true;
    this.monthSelector = true;
    this.yearSelector = true;
    this.disableHeaderButtons = true;
    this.showWeekNumbers = false;
    this.disableWeekends = false;
    this.alignSelectorRight = false;
    this.openSelectorTopOfInput = false;
    this.closeSelectorOnDateSelect = false;
    this.closeSelectorOnDocumentClick = true;
    this.showSelectorArrow = true;
    this.appendSelectorToBody = false;
    this.focusInputOnDateSelect = true;
    this.moveFocusByArrowKeys = true;
    this.inputFieldValidation = true;
    this.showMonthNumber = true;
    this.showFooterToday = false;
    this.viewChangeAnimation = true;
    this.rtl = false;

    this.minYear = 1000;
    this.maxYear = 9999;

    this.divHostElement = null!;
    this.stylesData = null!;
    this.calendarAnimation = null!;
    this.disableUntil = null!;
    this.disableSince = null!;
    this.markWeekends = null!;
    this.dateDisplay = null!;

    this.dayLabels = null!;
    this.monthLabels = null!;

    this.defaultView = DefaultView.Date;

    this.highlightDates = [];
    this.disableDates = [];
    this.disableDateRanges = [];
    this.disableWeekdays = [];
    this.enableDates = [];
    this.markDates = [];

    this.locale = moment().locale();
    this.innerFormControl = new FormControl(null, []);
    this.innerFormControl.valueChanges.subscribe(val => this.dateControlChanged(val));

    this.calendarToggle = new EventEmitter<boolean>();
  }

  onChange = (_: any) => { };
  onTouched = () => { };

  // #region - LIFECYCLE HOOKS
  ngOnInit(): void {
    this.config = this.buildConfig();
  }

  ngOnDestroy(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    const disableUntil = changes['disableUntil'];
    const disableSince = changes['disableSince'];

    if (disableUntil || disableSince) {
      this.config = this.buildConfig();
    }
  }
  // #endregion

  // #region - CALLBACK REGISTRATION
  registerOnChange(fn: (_: any) => {}): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }
  // #endregion

  resetDate() {
    this.innerFormControl.reset();
  }

  clearDate() {
    if (this.datePickerInput) {
      (this.datePickerInput as AngularMyDatePickerDirective).clearDate();
    }
  }

  openPicker() {
    if (!this.disabled && this.datePickerInput) {
      (this.datePickerInput as AngularMyDatePickerDirective).openCalendar();
    }
  }

  dateControlChanged(val: IMyDateModel | null): void {
    this.blurPicker();
    if (val === null) {
      this.value = null;
    } else {
      this.value = this.parsePickerDateToNormalDate(val);
    }
  }

  blurPicker(): void {
    if (this.datePickerInput) {
      const picker = (this.datePickerInput as any).elem as ElementRef<HTMLInputElement>;
      if (picker) {
        picker.nativeElement.blur();
        setTimeout(() => {
          (this.datePickerInput as AngularMyDatePickerDirective).closeCalendar();
        }, 1);
      }
    }
  }

  // NOTE: This needs work if we add date range
  parsePickerDateToNormalDate(pickerDate: IMyDateModel): Date {
    const jsDate = DateUtils.convertPickerFormatToDate(pickerDate.singleDate.date);

    if (this.outputUTC) {
      return moment(jsDate).utcOffset(0, true).toDate();
    }

    return jsDate;
  }

  parseNormalDateToPickerDate(date: Date): IMyDateModel {
    const dateMoment = moment(date);

    return {
      isRange: false,
      singleDate: {
        date: {
          year: dateMoment.year(),
          month: (dateMoment.month() + 1),
          day: dateMoment.date()
        }
      }
    };
  }

  buildConfig(): IAngularMyDpOptions {
    const config: IAngularMyDpOptions = {
      dateRange: this.dateRange,
      inline: this.inline,
      dateFormat: this.dateFormat,
      defaultView: this.defaultView,
      satHighlight: this.satHighlight,
      sunHighlight: this.sunHighlight,
      highlightDates: this.highlightDates,
      markCurrentDay: this.markCurrentDay,
      markCurrentMonth: this.markCurrentMonth,
      markCurrentYear: this.markCurrentYear,
      monthSelector: this.monthSelector,
      yearSelector: this.yearSelector,
      disableHeaderButtons: this.disableHeaderButtons,
      showWeekNumbers: this.showWeekNumbers,
      selectorHeight: this.selectorHeight,
      selectorWidth: this.selectorWidth,
      disableDates: this.disableDates,
      disableDateRanges: this.disableDateRanges,
      disableWeekends: this.disableWeekends,
      disableWeekdays: this.disableWeekdays,
      enableDates: this.enableDates,
      markDates: this.markDates,
      alignSelectorRight: this.alignSelectorRight,
      openSelectorTopOfInput: this.openSelectorTopOfInput,
      closeSelectorOnDateSelect: false, // NOTE: Handled by the blurPicker function.
      closeSelectorOnDocumentClick: this.closeSelectorOnDocumentClick,
      minYear: this.minYear,
      maxYear: this.maxYear,
      showSelectorArrow: this.showSelectorArrow,
      appendSelectorToBody: this.appendSelectorToBody,
      focusInputOnDateSelect: this.focusInputOnDateSelect,
      moveFocusByArrowKeys: this.moveFocusByArrowKeys,
      dateRangeDatesDelimiter: this.dateRangeDatesDelimiter,
      inputFieldValidation: this.inputFieldValidation,
      showMonthNumber: this.showMonthNumber,
      todayTxt: this.todayTxt,
      showFooterToday: this.showFooterToday,
      viewChangeAnimation: this.viewChangeAnimation,
      rtl: this.rtl,
      ariaLabelPrevMonth: this.ariaLabelPrevMonth,
      ariaLabelNextMonth: this.ariaLabelNextMonth
    };

    if (this.dayLabels) {
      config.dayLabels = this.dayLabels;
    }

    if (this.monthLabels) {
      config.monthLabels = this.monthLabels;
    }

    if (this.disableSince) {
      config.disableSince = this.disableSince;
    }

    if (this.disableUntil) {
      config.disableUntil = this.disableUntil;
    }

    if (this.calendarAnimation) {
      config.calendarAnimation = this.calendarAnimation;
    }

    if (this.stylesData) {
      config.stylesData = this.stylesData;
    }

    if (this.markWeekends) {
      config.markWeekends = this.markWeekends;
    }

    if (this.divHostElement) {
      config.divHostElement = this.divHostElement;
    }

    return config;
  }

  writeValue(date: Date): void {
    this._value = date;
    this.setDateDisplay(date);
    this.setPickerToDate(date);
  }

  setPickerToDate(date: Date) {
    this.innerFormControl.setValue(this.parseNormalDateToPickerDate(date), { emitEvent: false });
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    if (isDisabled) {
      this.innerFormControl.disable();
    } else {
      this.innerFormControl.enable();
    }
  }

  setDateDisplay(date: Date | null) {
    if (date) {
      const dateMoment = moment(date);
      if (dateMoment.isValid()) {
        this.dateDisplay = dateMoment.format(this.displayFormat);
        return;
      }
    }

    this.dateDisplay = null;
  }
  
  onCalendarToggle(event: number): void {
    let isOpen = false;
    
    // 1 = calendar opened
    // 2 = calendar closed by date select
    // 3 = calendar closed by calendar button
    // 4 = calendar closed by outside click (document click)
    // 5 = calendar closed by ESC key

    switch(event) {
      case 1:
        isOpen = true;
        break;
      case 2:
      case 3:
      case 4:
      case 5:
        isOpen = false;
        break;
    }

    this.calendarToggle.emit(isOpen);
  }

  onFocusInput(): void {
    if (!this.inline) {
      this.openPicker();
    }
  }
}
