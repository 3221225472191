import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { IState } from '@app/models/state/state.model';
import { User } from '@app/models/user/user.model';
import { IconHoverColor } from '@app/shared/components/inputs/table-action-icon/table-action-icon.component';
import { OneToOneMessages } from '../../locale/one-to-one.messages';
import { OneToOneMeetingLength } from '../../model/one-to-one-meeting-length.model';
import { OneToOneSchedule, OneToOneScheduleDetailsView } from '../../model/one-to-one-schedule.model';
import { Globals } from '@app/shared/globals/globals';
import { OneToOneBusinessService } from '../../service/one-to-one-business.service';
import { ModalComponent } from '@app/shared/modal/modal.component';

interface PageState extends IState {
  scheduleTransferring: OneToOneSchedule;
}

@Component({
  selector: 'app-profile-one-to-ones',
  templateUrl: './profile-one-to-ones.component.html',
  styleUrls: ['./profile-one-to-ones.component.scss']
})
export class ProfileOneToOnesComponent implements OnInit {
  public readonly eOneToOneMeetingLength = OneToOneMeetingLength;
  public readonly eOneToOneMessages = OneToOneMessages;
  public readonly eIconHoverColor = IconHoverColor;

  @ViewChild('modalTransferSchedule') modalTransferSchedule?: ModalComponent;
  @Input() user: User;

  state: PageState;
  schedules: OneToOneScheduleDetailsView[];

  constructor(
    public globals: Globals,
    private oneToOneBusinessService: OneToOneBusinessService
  ) {
    this.user = undefined;
    this.state = {
      loading: true,
      error: false,
      errorMessage: '',
      scheduleTransferring: undefined
    };
    this.schedules = [];
  }

  ngOnInit(): void {
    this.getData();
  }

  getData(): void {
    if (!this.user) { return; }

    this.oneToOneBusinessService.getSchedulesManagedByUserId(this.user.id)
      .toPromise()
      .then(schedulesManaging => {
        this.schedules = schedulesManaging;
        this.state.loading = false;
      });
  }

  startTransferSchedule(schedule: OneToOneSchedule): void {
    if (!schedule) { return; }
    if (!schedule.id) { return; }

    this.state.scheduleTransferring = schedule;

    setTimeout(() => {
      this.modalTransferSchedule.show();
    }, 1);
  }

  onModalHiddenTransfer(): void {
    this.state.scheduleTransferring = undefined;
  }

  onTransferSuccess(): void {
    this.state.scheduleTransferring = undefined;
    this.getData();
  }
}
