<ng-container *ngIf="page">
    <div class="row m-left-0 m-right-0 pagination-padding">
        <div class="col-sm-6">
            <div class="dataTables_info" id="datatables_info" role="status" aria-live="polite" translate [translateParams]="{ pageAmountStart: (page.pageable.offset + 1), pageAmountEnd: (page.pageable.offset + page.numberOfElements), pageAmountTotal: (page.totalElements) }">{{ePaginationNewMessages.PAGE_INFO}}</div>
        </div>
        <div class="col-sm-6 amount-select">
            <ng-container *ngIf="page.content">
                <span>{{ePaginationNewMessages.AMOUNT_TO_DISPLAY | translate}}: </span>
                <span class="frankli-form-group frankli-form-group-filled">
                    <select class="form-control" [(ngModel)]="pageSize" (ngModelChange)="pageSizeChanged.emit(pageSize)">
                        <option *ngFor="let size of pageSizes" [ngValue]="size">{{size}}</option>
                    </select>
                </span>
            </ng-container>
        </div>
    </div>

    <div class="dataTables_paginate pagination-controller text-center" *ngIf="page.totalPages > 1">
        <ul class="pagination">

            <li (click)="pageNumberChanged.emit(page.number - 1)" [class.disabled]="page.number === 0" class="clickable noselect">
                <a><span class="fal fa-angle-left"></span></a>
            </li>

            <ng-container *ngIf="page.totalPages <= truncateThreshold; else truncatedPages">
                <li *ngFor="let p of [].constructor(page.totalPages); index as index" (click)="pageNumberChanged.emit(index)" [class.active]="page.number === index" class="clickable noselect">
                    <a>{{index + 1}}</a>
                </li>
            </ng-container>
            <ng-template #truncatedPages>
                <!-- Show start page group -->
                <li *ngFor="let pageNumber of pageGroups.start" (click)="pageNumberChanged.emit(pageNumber)" [class.active]="page.number === pageNumber" class="clickable noselect">
                    <a>{{pageNumber + 1}}</a>
                </li>

                <!-- Show middle page group if it exists -->
                <ng-container *ngIf="pageGroups.middle.length > 0">
                    <span class="fal fa-ellipsis-h"></span>
                    <li *ngFor="let pageNumber of pageGroups.middle" (click)="pageNumberChanged.emit(pageNumber)" [class.active]="page.number === pageNumber" class="clickable noselect">
                        <a>{{pageNumber + 1}}</a>
                    </li>
                </ng-container>


                <!-- Show end page group -->
                <span class="fal fa-ellipsis-h"></span>
                <li *ngFor="let pageNumber of pageGroups.end" (click)="pageNumberChanged.emit(pageNumber)" [class.active]="page.number === pageNumber" class="clickable noselect">
                    <a>{{pageNumber + 1}}</a>
                </li>
            </ng-template>

            <li (click)="pageNumberChanged.emit(page.number + 1)" [class.disabled]="page.number === page.totalPages - 1" class="clickable noselect">
                <a><span class="fal fa-angle-right"></span></a>
            </li>
        </ul>
    </div>
</ng-container>
