<!-- This compoennt doesn't show unless there's data - except for when it's displaying peer reviews -->
<div class="peer-status-arrows-container" *ngIf="(requestType === eEvaluationFeedbackRequestType.PEER_REVIEW) || (sentOfType.length > 0 || receivedOfType.length > 0)">
  <div class="arrow-item arrow-sent" [tooltip]="tooltipSent" [triggers]="globals.tooltipTriggers" [adaptivePosition]="false" container="body" *ngIf="sent">
    <span class="fal fa-fw fa-arrow-up" *ngIf="received"></span>
    <div class="arrow-text" [class.arrows-green]="(sentRequestsNotDeclined.length > 0) && (sentRequestsCompleted.length === sentRequestsNotDeclined.length)">
      <div class="arrow-text-background" [style.height.%]="(sentRequestsCompleted.length / sentRequestsNotDeclined.length) * 100"></div>
      <span class="arrow-text-count">{{sentRequestsCompleted.length}}</span>
      <span class="arrow-text-total">{{sentRequestsNotDeclined.length}}</span>
    </div>
  </div>
  <div class="arrow-item arrow-received" [tooltip]="tooltipReceived" [triggers]="globals.tooltipTriggers" [adaptivePosition]="false" container="body" *ngIf="received">
    <span class="fal fa-fw fa-arrow-down" *ngIf="sent"></span>
    <div class="arrow-text" [class.arrows-green]="(receivedRequestsNotDeclined.length > 0) && (receivedRequestsCompleted.length === receivedRequestsNotDeclined.length)">
      <div class="arrow-text-background" [style.height.%]="(receivedRequestsCompleted.length / receivedRequestsNotDeclined.length) * 100"></div>
      <span class="arrow-text-count">{{receivedRequestsCompleted.length}}</span>
      <span class="arrow-text-total">{{receivedRequestsNotDeclined.length}}</span>
    </div>
  </div>
</div>

<ng-template #tooltipSent>
  <div class="text-left">
    <h4 class="standard-heading" *ngIf="received">Sent</h4>
    <div>
      <strong>{{sentRequestsCompleted.length}}</strong>
      <span class="m-right-5"></span>
      <span>completed</span>
    </div>
    <div>
      <strong>{{sentRequestsNotDeclined.length}}</strong>
      <span class="m-right-5"></span>
      <span>requests sent</span>
    </div>
    <!-- <div>
      <strong>{{sentRequestsDeclined.length}}</strong>
      <span class="m-right-5"></span>
      <span>declined</span>
    </div> -->
  </div>
</ng-template>

<ng-template #tooltipReceived>
  <div class="text-left">
    <h4 class="standard-heading" *ngIf="sent">Received</h4>
    <div>
      <strong>{{receivedRequestsCompleted.length}}</strong>
      <span class="m-right-5"></span>
      <span>completed</span>
    </div>
    <div>
      <strong>{{receivedRequestsNotDeclined.length}}</strong>
      <span class="m-right-5"></span>
      <span>requests received</span>
    </div>
    <!-- <div>
      <strong>{{receivedRequestsDeclined.length}}</strong>
      <span class="m-right-5"></span>
      <span>declined</span>
    </div> -->
  </div>
</ng-template>