<ng-container *ngIf="file">
  <div class="row m-left-0 m-right-0">
    <div class="col-file-icon">
      <div class="icon-circle">
        <span class="fal fa-fw fa-file"></span>
      </div>
    </div>
    <div class="col-file-data">
      <div>{{file.file.name }}</div>
    </div>
  </div>
</ng-container>