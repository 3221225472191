import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import { Interest } from '@app/domain/interest/model/interest.model';
import {CreateInterestDto} from '@app/domain/interest/model/create-interest.model';
import {Observable} from 'rxjs';
import {IInterestAPI} from '@app/domain/interest/api/interest-api.model';
import {UserMinimal} from '@app/models/user/user-minimal.model';
import { InterestType } from '@app/domain/interest/model/interest-type.model';

@Injectable()
export class InterestAPIService implements IInterestAPI {

  private readonly BASE_URL = 'api/interests';

  constructor(private http: HttpClient) {
  }

  get(ids: number[], name: string, categories: string[], types: InterestType[], approved: boolean): Observable<Interest[]> {
    const url = `${this.BASE_URL}`;

    let params = new HttpParams();

    if (ids) {
      ids.forEach(id => {
        params = params.append('id', id);
      });
    }

    if (categories) {
      categories.forEach(category => {
        params = params.append('category', category);
      });
    }

    if (types) {
      types.forEach(type => {
        params = params.append('type', type);
      });
    }

    if (name && name.length > 0) {
      params = params.append('name', name);
    }

    if (approved !== null) {
      params = params.append('approved', approved);
    }

    return this.http.get<Interest[]>(url, { params: params });
  }

  create(createInterestDto: CreateInterestDto): Observable<Interest> {
    const url = `${this.BASE_URL}`;
    return this.http.post<Interest>(url, createInterestDto);
  }

  update(id: number, createInterestDto: CreateInterestDto): Observable<Interest> {
    const url = `${this.BASE_URL}/${id}`;
    return this.http.put<Interest>(url, createInterestDto);
  }

  delete(id: number): Observable<Interest> {
    const url = `${this.BASE_URL}/${id}`;
    return this.http.delete<Interest>(url);
  }

  approvePendingInterest(id: number): Observable<Interest> {
    const url = `${this.BASE_URL}/pending/approve/${id}`;
    return this.http.post<Interest>(url, null);
  }

  importInterests(file: File): Observable<Interest[]> {
    const url = `${this.BASE_URL}/file`;
    const formData: FormData = new FormData();
    formData.append('file', file);
    return this.http.post<Array<Interest>>(url, formData);
  }

  getAllInterestCategories(): Observable<string[]> {
    const url = `${this.BASE_URL}/category`;
    return this.http.get<string[]>(url);
  }

  deleteMultiple(interestIds: number[]): Observable<Interest[]> {
    const url = `${this.BASE_URL}/delete`;
    return this.http.post<Interest[]>(url, interestIds);
  }

  getAllUsersUsingInterest(id: number): Observable<UserMinimal[]> {
    const url = `${this.BASE_URL}/${id}/users`;
    return this.http.get<UserMinimal[]>(url);
  }

  getSkillsForUserId(userId: number): Observable<Interest[]> {
    const url = `${this.BASE_URL}/selected/${userId}`;
    return this.http.get<Interest[]>(url);
  }

  downloadCSV(): Observable<Blob> {
    const url = `${this.BASE_URL}/download-csv`;
    return this.http.get(url, { responseType: 'blob' });
  }

}
