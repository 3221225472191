import { Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TalkingPoint } from '@app/domain/one_to_one/model/talking-point.model';
import { IState } from '@app/models/state/state.model';
import { UserMinimal } from '@app/models/user/user-minimal.model';
import { User } from '@app/models/user/user.model';
import { ButtonType } from '@app/shared/components/inputs/button/button.component';
import { Globals } from '@app/shared/globals/globals';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { OneToOneNote } from '../../model/one-to-one-note.model';
import { IconHoverColor } from '@app/shared/components/inputs/table-action-icon/table-action-icon.component';
import { CommonMessages } from '@app/constants/common.messages';

export interface TalkingPointItemAction {
  type: TalkingPointItemActionType;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload?: any;
}

export enum TalkingPointItemActionType {
  EDIT = 'EDIT',
  EDIT_CANCEL = 'EDIT_CANCEL',
  EDIT_SUBMIT = 'EDIT_SUBMIT',
  REMOVE = 'REMOVE',
  HIDE = 'HIDE',
  BUMP = 'BUMP',
  TOGGLE = 'TOGGLE',
  COMMENT = 'COMMENT'
}

interface PageState extends IState {
  viewingComments: boolean;
  addingComment: boolean;
}

@Component({
  selector: 'app-talking-point-item',
  templateUrl: './talking-point-item.component.html',
  styleUrls: ['./talking-point-item.component.scss']
})
export class TalkingPointItemComponent implements OnChanges {
  public readonly eIconHoverColor = IconHoverColor;
  public readonly eCommonMessages = CommonMessages;
  public readonly eButtonType = ButtonType;

  public readonly MIN_TP_TITLE_LENGTH = 10;
  public readonly MAX_TP_TITLE_LENGTH = 255;
  
  @ViewChild('editTitleInput') editTitleInput?: ElementRef<HTMLInputElement>;

  @Input() talkingPoint: TalkingPoint;
  @Input() editing: boolean;
  @Input() scheduleManager: User;
  @Input() scheduleParticipants: UserMinimal[];
  @Input() canReorder: boolean;
  @Input() canAction: boolean;
  @Input() canAddOrRemove: boolean;
  @Input() canBump: boolean;
  @Input() canComment: boolean;
  @Input() canEdit: boolean;
  @Input() creatingSchedule: boolean;
  @Input() displayComments: boolean;

  @Output() onTalkingPointAction: EventEmitter<TalkingPointItemAction>;

  state: PageState;

  checkboxForm: FormControl;
  commentForm: FormControl;

  editForm: FormGroup;
  submitted: boolean;
  usersWithComments: UserMinimal[];

  get hasOtherUserComments(): boolean {
    if (!this.talkingPoint) { return false; }
    if (!this.talkingPoint.comments) { return false; }
    return this.talkingPoint.comments.some(c => c.userId !== this.globals.user.id);
  }

  get controlEditTitle(): FormControl {
    return this.editForm.controls.title as FormControl;
  }

  get hasComments(): boolean {
    if (!this.talkingPoint.comments) { return false; }
    if (this.talkingPoint.comments.length === 0) { return false; }
    return true;
  }

  get commentsOrdered(): OneToOneNote[] {
    if (!this.talkingPoint.comments) { return []; }
    // // Return in order of userId, except for the schedule managers comment which is always last.
    // return this.talkingPoint.comments.sort((a, b) => {
    //   if (a.userId === this.scheduleManager.id) { return 1; }
    //   if (b.userId === this.scheduleManager.id) { return -1; }
    //   return a.userId - b.userId;
    // });
    // Return in order of userId, except for the current users comment which is always last.
    return this.talkingPoint.comments.sort((a, b) => {
      if (a.userId === this.globals.user.id) { return 1; }
      if (b.userId === this.globals.user.id) { return -1; }
      return a.userId - b.userId;
    });
  }

  get currentUserHasComment(): boolean {
    if (!this.talkingPoint.comments) { return false; }
    return this.talkingPoint.comments.some(c => c.userId === this.globals.user.id);
  }

  constructor(
    public globals: Globals
  ) {
    this.talkingPoint = undefined!;
    
    this.scheduleManager = undefined;
    this.scheduleParticipants = [];

    this.editing = false;
    this.canReorder = false;
    this.canAction = false;
    this.canAddOrRemove = false;
    this.canBump = false;
    this.canComment = false;
    this.canEdit = false;
    this.creatingSchedule = false;
    this.submitted = false;
    this.displayComments = true;

    this.usersWithComments = [];

    this.state = {
      loading: true,
      error: false,
      errorMessage: '',
      viewingComments: true,
      addingComment: false
    };

    this.onTalkingPointAction = new EventEmitter<TalkingPointItemAction>();

    this.checkboxForm = this.initCheckboxForm();
    this.commentForm = this.initCommentForm();
    this.editForm = this.initEditForm();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const talkingPoint = changes['talkingPoint'];
    const editing = changes['editing'];

    if (talkingPoint) {
      this.onTalkingPointChanged(talkingPoint.previousValue, talkingPoint.currentValue);
    }

    if (editing) {
      this.onEditingChanged();
    }
  }
  
  onTalkingPointChanged(oldTP: TalkingPoint, newTP: TalkingPoint): void {
    if (!newTP) { return; }

    const currentUserComment = newTP.comments.find(c => c.userId === this.globals.user.id);
    if (currentUserComment) {
      this.commentForm = this.initCommentForm(currentUserComment);
    } else {
      this.commentForm = this.initCommentForm();
    }

    this.checkboxForm = this.initCheckboxForm(newTP.actioned);

    this.usersWithComments = newTP.comments.map(c => c.user);

    this.state.loading = false;
  }

  onEditingChanged(): void {
    this.editForm.setValue({
      title: this.talkingPoint.title
    });

    setTimeout(() => {
      if (this.editTitleInput) {
        this.editTitleInput.nativeElement.focus();
      }
    }, 1);
  }

  onToggleChecked(value: boolean): void {
    if (!this.canAction) { return; }

    this.onTalkingPointAction.emit({
      type: TalkingPointItemActionType.TOGGLE,
      payload: value
    });
  }

  tryBump(): void {
    if (!this.canBump)  { return; }

    this.onTalkingPointAction.emit({
      type: TalkingPointItemActionType.BUMP,
      payload: this.talkingPoint
    });
  }

  tryHide(): void {
    this.onTalkingPointAction.emit({
      type: TalkingPointItemActionType.HIDE,
      payload: this.talkingPoint
    });
  }

  tryRemove(): void {
    if (!this.canAddOrRemove) { return; }

    this.onTalkingPointAction.emit({
      type: TalkingPointItemActionType.REMOVE,
      payload: this.talkingPoint
    });
  }

  editStart(): void {
    if (!this.canEdit) { return; }

    this.onTalkingPointAction.emit({
      type: TalkingPointItemActionType.EDIT,
      payload: this.talkingPoint
    });
  }

  editSubmit(): void {
    if (!this.canEdit) { return; }
    this.submitted = true;

    if (this.editForm.invalid) { return; }

    const editFormValue = this.editForm.value;
    
    this.onTalkingPointAction.emit({
      type: TalkingPointItemActionType.EDIT_SUBMIT,
      payload: {
        old: this.talkingPoint,
        new: editFormValue
      }
    });

    this.editing = false;
    this.submitted = false;
  }

  editCancel(): void {
    this.onTalkingPointAction.emit({
      type: TalkingPointItemActionType.EDIT_CANCEL,
      payload: this.talkingPoint
    });
  }

  onEditKeyup(event: KeyboardEvent): void {
    if (event.key === 'Enter') {
      this.editSubmit();
    }
  }

  toggleCommentList(): void {
    this.state.viewingComments = !this.state.viewingComments;
  }

  startAddingComment(): void {
    this.state.addingComment = true;
  }

  cancelAddingComment(): void {
    this.state.addingComment = false;
  }

  submitAddingComment(autosaving = false): void {
    if (!this.canComment) { return; }
    if (this.commentForm.pristine) {
      this.state.addingComment = false;
      return;
    }

    const newComment = this.commentForm.value;
    if (newComment === '') { return; }

    this.onTalkingPointAction.emit({
      type: TalkingPointItemActionType.COMMENT,
      payload: newComment
    });

    if (!autosaving) {
      this.state.addingComment = false;
    }

    setTimeout(() => {
      this.commentForm.markAsPristine();
    }, 100);
  }

  initCheckboxForm(checked?: boolean): FormControl {
    const formControl = new FormControl(false, []);

    if (checked) {
      formControl.setValue(checked);
    }

    formControl.valueChanges
      .pipe(debounceTime(100))
      .pipe(distinctUntilChanged())
      .subscribe(checked => this.onToggleChecked(checked));

    return formControl;
  }

  initCommentForm(comment?: OneToOneNote): FormControl {
    const formControl = new FormControl('', []);

    if (comment) {
      formControl.setValue(comment.contents);
    }

    formControl.valueChanges
      .pipe(debounceTime(2000))
      .pipe(distinctUntilChanged())
      .subscribe(() => {
        this.submitAddingComment(true);
      });

    return formControl;
  }

  initEditForm(talkingPoint?: TalkingPoint): FormGroup {
    const formGroup = new FormGroup({
      title: new FormControl('', [Validators.required, Validators.minLength(this.MIN_TP_TITLE_LENGTH), Validators.maxLength(this.MAX_TP_TITLE_LENGTH)])
    });

    if (talkingPoint) {
      formGroup.controls.title.setValue(talkingPoint.title);
    }

    return formGroup;
  }
}
