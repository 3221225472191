<div class="btn-dropdown-container">
  <div class="button-color area-primary" (click)="onClickPrimaryButton($event)">
    <span translate>{{text}}</span>
  </div>
  <button class="button-color area-dropdown-icon" type="button">
    <span class="fas fa-fw fa-caret-down"></span>

    <div class="dropdown-menu-popup" (click)="popupClicked($event)">
      <ng-content></ng-content>
    </div>
  </button>
</div>
