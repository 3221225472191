<div class="container-goals-tasks">
  <app-state [state]="state">
    <ng-container *ngIf="!state.loading && !state.error">
      <ng-container *ngIf="(tasks.length > 0); else templateNoTasks">
        <div class="area-task-list">
          <ng-container *ngFor="let task of tasks">
            <app-task-display [task]="task" [actionsShown]="['view', 'status', 'edit', 'unlink', 'archive']" (onClickAction)="onTaskAction($event, task)"></app-task-display>
          </ng-container>
        </div>

        <div class="text-center p-top-15" *ngIf="isGoalOwnerOrGoalAdmin">
          <app-button (onClick)="startCreateTask()">
            <span translate>{{eTasksMessages.CREATE_TASK}}</span>
          </app-button>
        </div>
      </ng-container>

      <ng-template #templateNoTasks>
        <ng-container *ngIf="isGoalOwnerOrGoalAdmin; else templateEmptyStateNonOwner;">
          <app-empty-state [height]="'300px'" [message]="eGoalsMessages.NO_TASKS_FOR_GOAL_OWNER" [primaryButtonShown]="true" [primaryButtonText]="eTasksMessages.CREATE_TASK" (primaryButtonPressed)="startCreateTask()"></app-empty-state>
        </ng-container>
        <ng-template #templateEmptyStateNonOwner>
          <app-empty-state [height]="'300px'" [message]="eGoalsMessages.NO_TASKS_FOR_GOAL"></app-empty-state>
        </ng-template>
      </ng-template>

      <app-modal #modal [sidePanel]="true" [showCloseButton]="false">
        <ng-container [ngSwitch]="modalType">
          <app-task-create *ngSwitchCase="eModalType.CREATE" [formCreate]="formCreate" [submitted]="state.submitted" (onFormCancel)="onCancelCreate()" (onFormSubmit)="onSubmitCreate($event)" (requestCloseModal)="onCancelCreate($event)" [canAlign]="false" #componentCreate></app-task-create>
          <app-task-details-component *ngSwitchCase="eModalType.DETAILS" [task]="taskViewing" (requestCloseModal)="onCancelCreate()" (onTaskUpdated)="taskUpdated($event, taskViewing)"></app-task-details-component>
        </ng-container>
      </app-modal>
    </ng-container>
  </app-state>
</div>
