<div class="container-sidebar-skills">
  <app-state [state]="state">
    <ng-container *ngIf="!state.loading && !state.error;">
      <ng-container *ngIf="globals.hasFeature(eCompanyFeatures.SKILL_RATING) && isManagingSingleUser">
        <div class="p-bottom-15 text-right">
          <a [routerLink]="'/profile/' + userIds[0]">Add or update ratings</a>
        </div>
      </ng-container>

      <ng-container *ngIf="data.master.length > 0; else templateNoData;">
        <div class="data-list">
          <ng-container *ngFor="let skill of data.display; index as index;">
            <app-skill-rating-display [entity]="skill" [skillRating]="skill.skillRating" [skillRatingConfiguration]="skillRatingConfiguration"></app-skill-rating-display>
          </ng-container>
        </div>

        <app-pagination-new-component [arrayData]="data.filtered" [arrayDisplay]="data.display" #pagination></app-pagination-new-component>
      </ng-container>

      <ng-template #templateNoData>
        <app-empty-state [height]="'200px'" [imageSrc]="'assets/img/empty-state/empty-state-skills.svg'" [message]="'No ' + globals.getTerminology(eTerminologyEntity.SKILL_PLURAL)"></app-empty-state>
      </ng-template>
    </ng-container>
  </app-state>
</div>