<ng-container *ngIf="(answersResults && resultsKeys && resultsKeys.length > 0); else noData">
  <div class="option-headers row m-left-0 m-right-0">
    <div class="col-xs-5 col-heading p-left-10">Result</div>
    <div class="col-xs-5 col-heading p-left-0">Ratios</div>
    <div class="col-xs-2 col-heading text-center">Votes</div>
  </div>
  <div class="choice-report">
    <ng-container *ngFor="let key of resultsKeys">
      <ng-container *ngIf="answersResults[key]; let item">
        <div class="option-row row">
          <div class="col-xs-5 option-name">{{item.name}}</div>
          <div class="col-xs-5 option-progress option-progress-border">
            <div class="option-progress-container">
              <div class="option-progress-bar" [class.has-value]="(item.percentageOfTotal > 14)" [style.width.%]="item.percentageOfTotal">
                <span>{{item.percentageOfTotal | number: '0.0-0'}}%</span>
              </div>
            </div>
          </div>
          <div class="col-xs-2 text-center option-count">
            <span>{{item.voteCount}}</span>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </div>
</ng-container>

<ng-template #noData>
  <div class="choice-report">
    <div class="p-15 text-center">No data</div>
  </div>
</ng-template>