import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PermissionService } from '@app/shared/api/interfaces/permission.service';
import { UserMinimal } from '@app/models/user/user-minimal.model';
import { RoleName } from '@app/models/user-role.model';

@Injectable()
export class PermissionAPIService implements PermissionService {

  private readonly BASE_URL = 'api/permission';

  constructor(private http: HttpClient) { }

  getUsersWithPermission(roleName: RoleName): Observable<UserMinimal[]> {
    const url = `${this.BASE_URL}/${roleName}`;
    return this.http.get<UserMinimal[]>(url);
  }

  updatePermission(roleName: RoleName, userIds: number[]): Observable<UserMinimal[]> {
    const url = `${this.BASE_URL}/${roleName}`;
    return this.http.post<UserMinimal[]>(url, userIds);
  }
}