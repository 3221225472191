import moment from 'moment';
import { User } from '../user/user.model';
import { GoalActivityServerside } from './goal-activity-serverside.model';
import { GoalActivityType } from './goal-activity-type.model';
import { GoalActivityEdit } from './goal-activity-edit.model';
import { GoalProgressUpdate } from './goal-progress-update.model';

export class GoalActivity {
  id: number;
  user: User;
  date: Date;
  message: string;
  type: GoalActivityType;
  progressUpdate: GoalProgressUpdate | null;
  editUpdate: GoalActivityEdit | null;

  constructor(goalActivityServerside: GoalActivityServerside) {
    this.id = goalActivityServerside.id;
    this.user = goalActivityServerside.user;
    this.date = moment.utc(goalActivityServerside.date).toDate();
    this.message = goalActivityServerside.message;
    this.type = goalActivityServerside.type;
    this.progressUpdate = goalActivityServerside.progressUpdate;
    this.editUpdate = goalActivityServerside.editUpdate;
  }
}


