export class SkillRatingOption implements ISkillRatingOption {

  id: number;
  scoreValue: number;
  name: string;
  description: string;

  constructor(serverside: ISkillRatingOptionServerside) {
    this.id = serverside.id;
    this.scoreValue = serverside.scoreValue;
    this.name = serverside.name;
    this.description = serverside.description;
  }

  // Add business logic here if needed

}

export interface ISkillRatingOption {
  id: number;
  scoreValue: number;
  name: string;
  description: string;
}

export interface ISkillRatingOptionServerside {
  id: number;
  scoreValue: number;
  name: string;
  description: string;
}
