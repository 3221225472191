import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { CommonMessages } from '@app/constants/common.messages';
import { IState } from '@app/models/state/state.model';
import { RoleName } from '@app/models/user-role.model';
import { ColumnToggleComponent } from '@app/shared/column-toggle/column-toggle.component';
import { PaginationNewComponent } from '@app/shared/pagination/pagination-new/pagination-new.component';
import { TasksMessages } from '../../locale/tasks.messages';
import { FilterCategory } from '@app/shared/dynamic-filter/interfaces/filter-category';
import { PagingParams } from '@app/models/api/paging-params.model';
import { FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Globals } from '@app/shared/globals/globals';
import { BreadcrumbService } from '@app/shared/breadcrumbs/breadcrumbs.service';
import { TaskAPIService } from '../../api/task-api.service';
import { ParentFilter } from '@app/models/api/parent-filter.model';
import { Breadcrumb } from '@app/models/breadcrumb.model';
import { Page } from '@app/models/api/page.model';
import { TeamTaskReportOverview } from '../../model/team-task-report-overview.model';
import { Observable, forkJoin, of } from 'rxjs';
import { ColumnInfo } from '@app/shared/column-toggle-new/column.info.model';
import { UserMinimal } from '@app/models/user/user-minimal.model';
import { CompanyFeatures } from '@app/models/company-features.model';
import { SearchMode } from '@app/shared/dynamic-filter/types/search-mode';
import { FilterType } from '@app/shared/dynamic-filter/types/filter-type';
import { ModalComponent } from '@app/shared/modal/modal.component';
import { FilteredTaskListAction } from '../../component/filtered-task-list/filtered-task-list.component';
import { Task } from '../../model/task.model';
import { CustomWindow } from '@app/models/custom-window.model';
import moment from 'moment';
import { TaskStats, TaskStatsScope } from '../../component/task-stats/task-stats.component';
import { TerminologyEntity } from '@app/domain/terminology/model/terminology-entity.enum';

interface PageState extends IState {
  creatingCSV: boolean;
}

interface TeamTasksRequest {
  teamTasksOverview: Observable<TeamTaskReportOverview[]>;
  secondaryTasksOverview: Observable<TeamTaskReportOverview[]>;
}

interface TeamTasksResponse {
  teamTasksOverview: TeamTaskReportOverview[];
  secondaryTasksOverview: TeamTaskReportOverview[];
}

enum TeamTasksModalType {
  TASKS_LIST = 'TASKS_LIST',
  TASK_DETAILS = 'TASK_DETAILS'
}

export interface TeamTaskPageUser extends TeamTaskReportOverview {
  dropdownOpen: boolean;
  childrenLoading: boolean;
  secondary: boolean;
  directReports: TeamTaskPageUser[];
}

export enum TeamTaskTableColumn {
  NAME,
  TASK_COUNT,
  TASK_COUNT_COMPLETE,
  TASK_COUNT_IN_PROGRESS,
  TASK_COUNT_NOT_STARTED,
  TASK_COUNT_OVERDUE,
  AVERAGE_COMPLETION,
  ACTIONS
}

declare let window: CustomWindow;

const RANGE_OPTIONS = [
  {
    displayName: 'No tasks',
    range: {
      max: '0'
    }
  },
  {
    displayName: '1 - 5 tasks',
    range: {
      min: '1',
      max: '5'
    }
  },
  {
    displayName: '6 - 10 tasks',
    range: {
      min: '6',
      max: '10'
    }
  },
  {
    displayName: '11 - 20 tasks',
    range: {
      min: '11',
      max: '20'
    }
  },
  {
    displayName: '21 - 30 tasks',
    range: {
      min: '21',
      max: '30'
    }
  },
  {
    displayName: '31+ tasks',
    range: {
      min: '31',
    }
  }
];

@Component({
  selector: 'app-team-tasks',
  templateUrl: './team-tasks.component.html',
  styleUrls: ['./team-tasks.component.scss']
})
export class TeamTasksComponent implements OnInit, OnDestroy {

  public readonly eTeamTasksModalType = TeamTasksModalType;
  public readonly eTerminologyEntity = TerminologyEntity;
  public readonly eTableColumn = TeamTaskTableColumn;
  public readonly eCommonMessages = CommonMessages;
  public readonly eTaskMessages = TasksMessages;
  public readonly eRoleName = RoleName;

  @ViewChild('columnToggle') columnToggle?: ColumnToggleComponent;
  @ViewChild('pagination') pagination?: PaginationNewComponent;
  @ViewChild('userTasksModal') userTasksModal?: ModalComponent;

  state: PageState;
  stateSearch: IState;
  stateStats: IState;
  breadcrumb: Breadcrumb;

  parentFilter: ParentFilter;
  filterCategories: FilterCategory[];
  searchProperties: string[];
  columnTitles: string[];
  viewAsAdmin: FormControl;
  pagingParams: PagingParams;

  modalType?: TeamTasksModalType;
  viewingUser?: UserMinimal;
  viewingTask?: Task;

  columnInfos: ColumnInfo[];

  tasksOverviewPage?: Page<TeamTaskReportOverview>;
  directReports: TeamTaskPageUser[];
  taskStats?: TaskStats;

  constructor(
    public route: ActivatedRoute,
    public globals: Globals,
    private breadcrumbService: BreadcrumbService,
    private taskAPIService: TaskAPIService
  ) {
    this.directReports = [];
    this.searchProperties =  [ 'name' ];

    this.state = {
      loading: true,
      error: false,
      errorMessage: '',
      creatingCSV: false
    };
    this.stateSearch = {
      loading: true,
      error: false,
      errorMessage: ''
    };
    this.stateStats = {
      loading: true,
      error: false,
      errorMessage: ''
    };

    this.modalType = undefined;
    this.viewingUser = undefined;
    this.viewingTask = undefined;
    
    this.columnTitles = Object.keys(TeamTaskTableColumn).map(c => TeamTaskTableColumn[c]);

    this.pagingParams = {
      pageSize: 15,
      pageNumber: 0
    };

    this.columnInfos = [
      {
        index: TeamTaskTableColumn.NAME,
        titleMessageCode: CommonMessages.NAME
      },
      {
        index: TeamTaskTableColumn.TASK_COUNT,
        titleMessageCode: TasksMessages.TASK_COUNT
      },
      {
        index: TeamTaskTableColumn.TASK_COUNT_COMPLETE,
        titleMessageCode: TasksMessages.TASK_COUNT_COMPLETE
      },
      {
        index: TeamTaskTableColumn.TASK_COUNT_IN_PROGRESS,
        titleMessageCode: TasksMessages.TASK_COUNT_IN_PROGRESS
      },
      {
        index: TeamTaskTableColumn.TASK_COUNT_NOT_STARTED,
        titleMessageCode: TasksMessages.TASK_COUNT_NOT_STARTED
      },
      {
        index: TeamTaskTableColumn.TASK_COUNT_OVERDUE,
        titleMessageCode: TasksMessages.TASK_COUNT_OVERDUE
      },
      {
        index: TeamTaskTableColumn.AVERAGE_COMPLETION,
        titleMessageCode: TasksMessages.AVERAGE_COMPLETION
      },
      {
        index: TeamTaskTableColumn.ACTIONS,
        titleMessageCode: CommonMessages.ACTIONS
      }
    ];

    this.breadcrumb = this.breadcrumbService.init(this.route);
    this.viewAsAdmin = this.initFormViewAsAdmin();
  }

  ngOnInit(): void {
    this.filterCategories = this.populateUniversalFilter();
    this.state.loading = false;
    this.initialiseStats();
    this.initialiseTree();
  }

  ngOnDestroy(): void {
    this.breadcrumbService.remove(this.breadcrumb);
  }

  initialiseStats() {
    this.stateStats.loading = true;
    const statsScope = this.viewAsAdmin.value ? TaskStatsScope.ALL : TaskStatsScope.REPORTING_LINE;
    this.taskAPIService.getStatsForScope(statsScope)
      .toPromise()
      .then(stats => {
        this.taskStats = stats;
      })
      .catch(err => {
        console.error('Error loading stats: ', err);
        this.stateStats.error = true;
        this.stateStats.errorMessage = err.message;
      })
      .finally(() => {
        this.stateStats.loading = false;
      });
  }

  initialiseTree(): void {
    this.stateSearch.loading = true;

    const request: TeamTasksRequest = {
      teamTasksOverview: this.taskAPIService.getTeamTaskReportOverview(this.globals.user.id, 0, this.viewAsAdmin.value),
      secondaryTasksOverview: this.userIsSecondaryManager() ?
        this.taskAPIService.getSecondarySubordinatesTasksOverview() :
        of(null)
    };

    forkJoin(request).subscribe((response: TeamTasksResponse) => {
      let subordinates = new Array<TeamTaskPageUser>();
      let secondarySubordinates = new Array<TeamTaskPageUser>();
      if (this.globals.hasMinimumAccessLevelOf(RoleName.ADMIN) || this.globals.hasMinimumAccessLevelOf(RoleName.MANAGER)) {
        subordinates = this.parseSubordinates(response.teamTasksOverview);
      }

      if (response.secondaryTasksOverview) {
        secondarySubordinates = this.parseSecondarySubordinates(response.secondaryTasksOverview);
      }

      this.directReports = subordinates.concat(secondarySubordinates);

      this.stateSearch.loading = false;
    });
  }

  initFormViewAsAdmin(): FormControl {
    const formControl = new FormControl(false, []);

    formControl.valueChanges.subscribe(() => this.initialiseTree());

    return formControl;
  }

  parseSubordinates(teamTasksOverview : TeamTaskReportOverview[]){
    const subordinates = teamTasksOverview.map(u => this.parseRootUser(u));
    return this.sortByName(subordinates);
  }

  parseSecondarySubordinates(secondaryTasksOverview: TeamTaskReportOverview[]){
    const secondaryUsers = secondaryTasksOverview.map(x => this.parseSecondarySubordinate(x));
    return this.sortByName(secondaryUsers);
  }

  sortByName(array: TeamTaskPageUser[]){
    return array.sort((a, b) => {
      const nameA = `${a.user.firstName} ${a.user.lastName}`;
      const nameB = `${b.user.firstName} ${b.user.lastName}`;
      const secondaryMatch = (a === b)? 0 : a? -1 : 1;
      return secondaryMatch || ((nameA > nameB) ? 1 : -1);
    });
  }

  parseSecondarySubordinate(taskDirectReportOverview: TeamTaskReportOverview): TeamTaskPageUser {
    const pageUser = taskDirectReportOverview as TeamTaskPageUser;

    pageUser.dropdownOpen = false;
    pageUser.secondary = true;
    pageUser.directReports = [];

    return pageUser;
  }

  parseRootUser(taskDirectReportOverview: TeamTaskReportOverview): TeamTaskPageUser {
    const rootUser = taskDirectReportOverview as TeamTaskPageUser;

    rootUser.dropdownOpen = false;
    rootUser.secondary = false;
    rootUser.directReports = [];

    return rootUser;
  }

  convertReportArrayToPageUser(teamTasksOverview: TeamTaskReportOverview[]): TeamTaskPageUser[] {
    return teamTasksOverview.map(tg => this.convertReportToPageUser(tg));
  }

  convertReportToPageUser(user: TeamTaskReportOverview): TeamTaskPageUser {
    const report = user as TeamTaskPageUser;

    if (report) {
      report.dropdownOpen = false;
      report.directReports = [];
    }

    return report;
  }

  openUserTasks(pageUser: TeamTaskPageUser) {
    this.modalType = TeamTasksModalType.TASKS_LIST;
    this.viewingUser = pageUser.user;

    setTimeout(() => {
      this.userTasksModal.show();
    }, 1);
  }

  userIsManager(){
    return this.globals.hasRole(RoleName.MANAGER);
  }

  userIsSecondaryManager(){
    return this.globals.hasFeature(CompanyFeatures.SECONDARY_MANAGER) && this.globals.hasRole(RoleName.SECONDARY_MANAGER);
  }

  loadChildren(pageUser: TeamTaskPageUser): void {
    if (!pageUser.hasDirectReports) { return; }
    pageUser.dropdownOpen = !pageUser.dropdownOpen;

    if (pageUser.directReports.length > 0) { return; }

    pageUser.childrenLoading = true;
    this.taskAPIService.getTeamTaskReportOverview(pageUser.user.id, 1, false).subscribe(directReportOverviews => {
      pageUser.directReports = this.parseSubordinates(directReportOverviews);
      pageUser.childrenLoading = false;
    });
  }

  searchReports(): void {
    this.stateSearch.loading = true;

    this.taskAPIService.searchTeamTaskReportOverviews(this.pagingParams, this.parentFilter, this.viewAsAdmin.value)
      .toPromise()
      .then(reportsPage => {
        this.tasksOverviewPage = reportsPage;
        this.directReports = this.parseSubordinates(this.tasksOverviewPage.content);
        this.stateSearch.loading = false;
      });
  }

  updateFilter(parentFilter: ParentFilter) {
    this.parentFilter = parentFilter;
    if (this.parentFilter.childFilters.length > 0) {
      this.searchReports();
    } else {
      this.initialiseTree();
    }
  }

  changePageSize($event: number): void {
    this.pagingParams.pageSize = $event;
    this.searchReports();
  }

  changePageNumber($event: number): void {
    this.pagingParams.pageNumber = $event;
    this.searchReports();
  }

  populateUniversalFilter(): FilterCategory[] {
    const departmentCategory: FilterCategory = {
      displayName: this.globals.getTerminology(TerminologyEntity.DEPARTMENT),
      fieldName: 'organisationalUnitId',
      searchMode: SearchMode.DEPARTMENT,
      type: FilterType.COMBINED,
      options: []
    };

    const siteCategory: FilterCategory = {
      displayName: this.globals.getTerminology(TerminologyEntity.SITE),
      fieldName: 'officeLocationId',
      searchMode: SearchMode.OFFICE_LOCATION,
      type: FilterType.COMBINED,
      options: []
    };

    const positionCategory: FilterCategory = {
      displayName: CommonMessages.POSITION,
      fieldName: 'positionId',
      searchMode: SearchMode.POSITION,
      type: FilterType.COMBINED,
      options: []
    };

    const activeTasksCategory: FilterCategory = {
      displayName: TasksMessages.ACTIVE_TASKS,
      fieldName: 'countTasksActive',
      searchMode: SearchMode.NONE,
      type: FilterType.RANGE,
      options: RANGE_OPTIONS
    };

    const statusNotStartedTasksCategory: FilterCategory = {
      displayName: TasksMessages.STATUS_NOT_STARTED,
      fieldName: 'countTasksNotStarted',
      searchMode: SearchMode.NONE,
      type: FilterType.RANGE,
      options: RANGE_OPTIONS
    };

    const statusInProgressTasksCategory: FilterCategory = {
      displayName: TasksMessages.STATUS_IN_PROGRESS,
      fieldName: 'countTasksInProgress',
      searchMode: SearchMode.NONE,
      type: FilterType.RANGE,
      options: RANGE_OPTIONS
    };

    const statusCompletedTasksCategory: FilterCategory = {
      displayName: TasksMessages.STATUS_COMPLETE,
      fieldName: 'countTasksCompleted',
      searchMode: SearchMode.NONE,
      type: FilterType.RANGE,
      options: RANGE_OPTIONS
    };

    return [departmentCategory, siteCategory, positionCategory, activeTasksCategory, statusNotStartedTasksCategory, statusInProgressTasksCategory, statusCompletedTasksCategory];
  }

  onTasksModalHidden(): void {
    this.modalType = undefined;
    this.viewingUser = undefined;
    this.viewingTask = undefined;
  }

  onTaskListActionClicked(emittedData: FilteredTaskListAction): void {
    if (!emittedData) { return; }

    switch(emittedData.action) {
      case 'view':
        this.modalType = TeamTasksModalType.TASK_DETAILS;
        this.viewingTask = emittedData.task;
        break;
    }
  }

  stopViewingTask(): void {
    this.modalType = TeamTasksModalType.TASKS_LIST;
    this.viewingTask = undefined;
  }

  exportTasks(): void {
    if (this.state.creatingCSV) { return; }
    this.state.creatingCSV = true;

    this.taskAPIService.exportTeamTaskReportOverview(this.globals.user.id, 0, this.viewAsAdmin.value)
      .toPromise()
      .then(res => {
        console.info(res);
        const blob = new Blob([res], { type: 'text/csv' });
        this.downloadStringAsCSV(blob, `frankli_team-tasks-${moment().format('LL').toString()}.csv`);
      })
      .finally(() => {
        this.state.creatingCSV = false;
      });
  }

  downloadStringAsCSV(csvData: Blob | string, fileName: string): void {
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      const blob = new Blob([csvData], {type: 'text/csv'});
      window.navigator.msSaveOrOpenBlob(blob);
      return;
    }

    const downloadURL = window.URL.createObjectURL(csvData);
    const link = document.createElement('a');
    link.href = downloadURL;
    link.download = fileName;
    link.click();
  }
}
