import { UserStickinessServerside } from './user-stickiness-serverside.model';
import moment from 'moment';

export class UserStickiness {

  public date: Date;
  public totalUsers: number;
  public dailyActiveUsers: number;
  public weeklyActiveUsers: number;
  public monthlyActiveUsers: number;

  constructor(userStickinessDayServerside: UserStickinessServerside) {
    this.date = moment.utc(userStickinessDayServerside.date).toDate();
    this.totalUsers = userStickinessDayServerside.totalUsers;
    this.dailyActiveUsers = userStickinessDayServerside.dailyActiveUsers;
    this.weeklyActiveUsers = userStickinessDayServerside.weeklyActiveUsers;
    this.monthlyActiveUsers = userStickinessDayServerside.monthlyActiveUsers;
  }

}
