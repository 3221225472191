import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {MergeDevLinkTokenResponse} from '@models/integrations/mergedev/merge-dev-link-token.model';
import {MergeDevAccountToken} from '@models/integrations/mergedev/merge-dev-account-token.model';

@Injectable()
export class MergeDevAPIService {
  http: HttpClient;
  constructor(private _http: HttpClient) {
    this.http = _http;
  }

  getLinkToken() {
    const url = 'api/merge-dev/link-key';
    return this.http.get<MergeDevLinkTokenResponse>(url);
  }

  createAccountTokenFromPublicToken(publicToken: string) {
    const url = 'api/merge-dev/account-token';
    return this.http.post<MergeDevAccountToken>(url, publicToken);
  }
  getMergeDevAccountToken() {
    const url = 'api/merge-dev/account-token';
    return this.http.get<MergeDevAccountToken>(url);
  }

  removeMergeAccountTokenIfAdminUserAndInvalid() {
    const url = 'api/merge-dev/merge-remove-link';
    return this.http.put<MergeDevAccountToken>(url, {});
  }


}
