import { User } from 'app/models/user/user.model';
import moment from 'moment';
import { Site } from '../site.model';
import { Department } from '../department.model';
import { GoalActivity } from './goal-activity.model';
import { GoalKeyResult } from './goal-key-result.model';
import { GoalPriority } from './goal-priority.model';
import { GoalServerside } from './goal-serverside.model';
import { GoalStatus } from './goal-status.model';
import { Tag } from '../../domain/tag/model/tag.model';
import { GoalType } from './goal-type.enum';
import { GoalVisibility } from './goal-visibility.model';
import { GoalUtils } from '@app/shared/utils/goal.utils';
import { GoalKeyResultType } from './goal-key-result-type.model';
import { GoalSort } from './goal-sort.model';

export class Goal {
  static sort: GoalSort = new GoalSort();

  // TODO: Initialize values for each of these
  id: number;
  title: string;
  type: GoalType;
  department: Department | null;
  officeLocation: Site | null;
  creationDate: Date;
  endDate: Date;
  completionDate: Date | null;
  visibility: GoalVisibility;
  priority: GoalPriority;
  tags: Array<Tag>;
  keyResults: Array<GoalKeyResult>;
  owners: Array<User>;
  complete: boolean;
  archived: boolean;
  activity: Array<GoalActivity>;
  completionPercentage: number;
  status: GoalStatus;
  developmentNeeds: string | null;
  alignment: number | null;

  static sortKeyResults(keyResults: GoalKeyResult[]): GoalKeyResult[] {
    return keyResults.sort((a, b) => a.orderIndex - b.orderIndex);
  }

  static getGoalArrayCompletionPercentage(goals: Array<Goal>) {
    goals = goals.map((g) => this.getGoalCompletionPercentage(g));
    return goals;
  }

  static getGoalArrayStateSnapShotFromActivity(goals: Goal[], dateLimit: Date): Goal[] {
    return goals.map(goal => this.getGoalStateSnapShotFromActivity(goal, dateLimit));
  }

  // Rollback goal to target state by applying edits in reverse
  static getGoalStateSnapShotFromActivity(goal: Goal, dateLimit: Date | string): Goal {
    goal.completionPercentage = 0;

    if (!dateLimit || dateLimit.valueOf() > new Date().valueOf()) {
      return this.getGoalCompletionPercentage(goal);
    }

    goal.activity
      .filter(activity => (activity.progressUpdate || activity.editUpdate))
      .sort((a, b) => a.date.valueOf() - b.date.valueOf())
      .filter(activity => activity.date.valueOf() > dateLimit.valueOf()) // Remove events that occured before the given date
      .reverse()
      .forEach(activity => {
        if (activity.editUpdate) {
          if (activity.editUpdate.keyResultsUpdated) {
            activity.editUpdate.keyResultsUpdated.forEach(keyResultUpdate => {
              const krIndex = goal.keyResults.findIndex(kr => kr.id === keyResultUpdate.id);
              if (krIndex !== -1) {
                if (keyResultUpdate.measureStartValuePrevious !== null) {
                  goal.keyResults[krIndex].measureStartValue = keyResultUpdate.measureStartValuePrevious;
                }

                if (keyResultUpdate.measureGoalValuePrevious !== null) {
                  goal.keyResults[krIndex].measureGoalValue = keyResultUpdate.measureGoalValuePrevious;
                }

                if (keyResultUpdate.measureCurrentValuePrevious !== null) {
                  goal.keyResults[krIndex].measureCurrentValue = keyResultUpdate.measureCurrentValuePrevious;
                }

                if (keyResultUpdate.resultPrevious) {
                  goal.keyResults[krIndex].result = keyResultUpdate.resultPrevious;
                }

                if (keyResultUpdate.endDatePrevious) {
                  goal.keyResults[krIndex].endDate = keyResultUpdate.endDatePrevious;
                }

                if (keyResultUpdate.measureTypePrevious) {
                  goal.keyResults[krIndex].type = keyResultUpdate.measureTypePrevious as GoalKeyResultType;
                }

                if (keyResultUpdate.measureUnitPlacementPrevious) {
                  goal.keyResults[krIndex].measureUnitPlacement = keyResultUpdate.measureUnitPlacementPrevious;
                }
              }
            });
          }
        }

        if (activity.progressUpdate) {
          if (activity.progressUpdate.keyResults) {
            activity.progressUpdate.keyResults.forEach(keyResultUpdate => {
              const krIndex = goal.keyResults.findIndex(kr => kr.id.toString() === keyResultUpdate.keyResultId.toString());
              if (krIndex !== -1) {
                if (keyResultUpdate.currentValuePrevious !== null) {
                  goal.keyResults[krIndex].measureCurrentValue = keyResultUpdate.currentValuePrevious;
                }

                if (keyResultUpdate.targetValuePrevious !== null) {
                  goal.keyResults[krIndex].measureGoalValue = keyResultUpdate.targetValuePrevious;
                }
              }
            });

            if (activity.progressUpdate.status) {
              goal.status = activity.progressUpdate.status.previous;
            }
          }
        }
      });

    return this.getGoalCompletionPercentage(goal);
  }

  static getGoalCompletionPercentage(goal: Goal): Goal {
    const values: number[] = goal.keyResults.map((k) => GoalUtils.getKeyResultProgress(k));

    goal.completionPercentage = this.getAverage(values);

    return goal;
  }

  private static getAverage(values: number[]): number {
    let total = 0;
    values.forEach((v) => {
      if (!isNaN(v)) {
        total += v;
      }
    });
    return Number((total / values.length).toFixed(2));
  }

  static getAverageAndCount(goals: Goal[]): number[] {
    let count = 0;
    let total = 0;
    let activeCount = 0;
    let completeCount = 0;
    for (const goal of goals) {
      if (!goal.complete) {
        activeCount++;
        for (const keyResult of goal.keyResults) {
          const current = keyResult.measureCurrentValue;
          const max = keyResult.measureGoalValue;

          const percentage = current > max ? 100.0 : (current / max) * 100.0;

          total += Math.floor(percentage);
          count++;
        }
      } else {
        completeCount++;
      }
    }

    return [
      count > 0 ? total / count : 0,
      count,
      activeCount,
      completeCount
    ];
  }

  // TODO: remove undefined later once goals-create.component.ts is updated to use CreateGoalDto and UpdateGoalDto instead
  constructor(goalServerside?: GoalServerside) {
    if (goalServerside) {
      this.id = goalServerside.id;
      this.title = goalServerside.title;
      this.type = goalServerside.type;
      this.department = goalServerside.department;
      this.officeLocation = goalServerside.officeLocation;
      this.creationDate = moment.utc(goalServerside.creationDate).toDate();
      this.endDate = moment.utc(goalServerside.endDate).toDate();
      this.completionDate = (goalServerside.completionDate === null) ? null : moment.utc(goalServerside.completionDate).toDate();
      this.visibility = goalServerside.visibility;
      this.priority = goalServerside.priority;
      this.tags = goalServerside.tags;
      this.keyResults = Goal.sortKeyResults(goalServerside.keyResults.map(r => new GoalKeyResult(r)));
      this.owners = goalServerside.owners;
      this.complete = goalServerside.complete;
      this.archived = goalServerside.archived;
      this.activity = goalServerside.activity.map(a => new GoalActivity(a));
      this.completionPercentage = goalServerside.completionPercentage;
      this.status = goalServerside.status;
      this.developmentNeeds = goalServerside.developmentNeeds;
      this.alignment = goalServerside.alignment;
    }
  }
}
