import { Component, EventEmitter, Input, Output } from '@angular/core';
import { OneToOneSchedule } from '../../model/one-to-one-schedule.model';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TransferOneToOneSchedule } from '../../model/transfer-one-to-one-schedule.model';
import { OneToOneMessages } from '../../locale/one-to-one.messages';
import { ButtonType } from '@app/shared/components/inputs/button/button.component';
import { OneToOneBusinessService } from '../../service/one-to-one-business.service';
import { Globals } from '@app/shared/globals/globals';
import { CommonMessages } from '@app/constants/common.messages';

@Component({
  selector: 'app-transfer-schedule',
  templateUrl: './transfer-schedule.component.html',
  styleUrls: ['./transfer-schedule.component.scss']
})
export class TransferScheduleComponent {
  public readonly eOneToOneMessages = OneToOneMessages;
  public readonly eCommonMessages = CommonMessages;
  public readonly eButtonType = ButtonType;

  @Input() oneToOneSchedule?: OneToOneSchedule;
  @Input() canTransferToSelf: boolean;

  @Output() onSuccess: EventEmitter<number>;

  form: FormGroup;
  submitted: boolean;
  submitting: boolean;

  get blacklistedManagerIds(): number[] {
    if (!this.oneToOneSchedule) { return []; }
    if (!this.oneToOneSchedule.participants) { return []; }
    if (this.oneToOneSchedule.participants.length === 0) { return []; }
    if (!this.oneToOneSchedule.manager) { return []; }
    return [ this.oneToOneSchedule.manager.id, this.oneToOneSchedule.participants[0].id ];
  }

  get controlNewManager(): FormControl {
    return this.form.controls.newManagerId as FormControl;
  }

  get controlMessage(): FormControl {
    return this.form.controls.message as FormControl;
  }

  constructor(
    public globals: Globals,
    private oneToOneBusinessService: OneToOneBusinessService
  ) {
    this.oneToOneSchedule = undefined;
    this.canTransferToSelf = false;
    this.submitted = false;
    this.submitting = false;

    this.onSuccess = new EventEmitter<number>();
    this.form = this.initForm();
  }

  initForm(transferOneToOneSchedule?: TransferOneToOneSchedule): FormGroup {
    const formGroup = new FormGroup({
      newManagerId: new FormControl(null, [Validators.required]),
      message: new FormControl('', [Validators.required, Validators.maxLength(255)])
    });

    if (transferOneToOneSchedule) {
      formGroup.controls.newManagerId.setValue(transferOneToOneSchedule.newManagerId);
      formGroup.controls.message.setValue(transferOneToOneSchedule.message);
    }

    return formGroup;
  }

  onSubmit(): void {
    this.submitted = true;

    if (!this.oneToOneSchedule) { return console.error('No schedule provided'); }
    if (this.form.invalid) { return console.error('Form is invalid'); }

    this.submitting = true;
    const transferRequest: TransferOneToOneSchedule = {
      newManagerId: this.controlNewManager.value.id,
      message: this.controlMessage.value
    };

    this.oneToOneBusinessService.transferScheduleToNewManager(this.oneToOneSchedule.id, transferRequest)
      .then(() => {
        this.onSuccess.emit(this.oneToOneSchedule.id);
        this.submitted = false;
      })
      .finally(() => {
        this.submitting = false;
      });
  }
}
