import { Component, Input, OnInit } from '@angular/core';
import { Globals } from '@app/shared/globals/globals';

interface ChartData {
  value: string; // Raw value
  text: string; // Formatted value
  label: string; // Strongly Disagree, Neutral, Agree, etc.
  height: string; // Relative height for progress bar
  tooltip: string; // Tooltip text
  color: string; // Bar color
  colorAlt: string; // Background color
}

interface WidthData {
  value: string;
  text: string;
  label: string;
  percentOfTotal: string;
  customLabel?: string;
}

@Component({
  selector: 'app-one-to-ten-reporting-chart',
  templateUrl: './one-to-ten-reporting-chart.component.html',
  styleUrls: ['./one-to-ten-reporting-chart.component.scss']
})
export class OneToTenReportingChartComponent implements OnInit {
  @Input() data?: WidthData[];
  @Input() isENPS?: boolean;

  dataParsed: ChartData[];
  labels = [];
  customLabels = [];

  constructor(
    public globals: Globals
  ) {
    this.dataParsed = [];
    this.isENPS = true;
    this.labels = ['N/A', 'Not likely', 'Not likely', 'Quite unlikely', 'Neutral', 'Neutral', 'Neutral', 'Likely', 'Quite likely', 'Strongly likely', 'Very likely'];
  }

  ngOnInit() {
    this.dataParsed = this.parseData(this.data);
  }

  parseData(input?: WidthData[]): ChartData[] {
    if (!input) {
      return [];
    }
    const dataUsing = this.parseWidthsToChartData(input);
    return dataUsing;
  }

  parseWidthsToChartData(widths: WidthData[]): ChartData[] {

    if (!this.isENPS)
      this.reparseDataForNonENPS(widths);

    this.getCustomLabels(widths);
    
    const maxHeight = this.getChartMaxHeight(widths);

    return widths.map(d => {
      const output: ChartData = {
        value: d.value,
        text: d.text,
        label: this.isENPS ? d.label : this.changeLabelsForNonENPS(d.text),
        height: this.getChartHeight(+d.value, maxHeight),
        tooltip: this.getChartTooltip(+d.value),
        color: this.getChartColor(d.label),
        colorAlt: this.getChartColorAlt(d.label)
      };

      return output;
    });

  }

  getChartHeight(value: number, maxHeight: number): string {
    const ratio = (value / maxHeight);
    const ratioPercent = (ratio * 100);

    if (ratioPercent < 5) {
      return '5%';
    }

    return `${ratioPercent}%`;
  }

  getChartMaxHeight(data: any[]): number {
    let maxValue = 0;

    data.forEach(d => {
      if (+d.value > maxValue) {
        maxValue = +d.value;
      }
    });

    return maxValue;
  }

  getChartTooltip(value: number): string {
    if (value > 1) {
      return `${value} people`;
    }

    if (value > 0) {
      return `${value} person`;
    }

    return 'None';
  }

  reparseDataForNonENPS(groups: WidthData[]){
    const array1 = groups.filter(obj => parseInt(obj.text) >= 1 && parseInt(obj.text) <= 3);
    const array2 = groups.filter(obj => parseInt(obj.text) >= 4 && parseInt(obj.text) <= 7);
    const array3 = groups.filter(obj => parseInt(obj.text) >= 8 && parseInt(obj.text) <= 10);

    // Modify the label of the second array to "Neutral"
    array2.forEach(obj => {
      obj.label = 'Neutral';
    });

    // Modify the label of the third array to "Promoter"
    array3.forEach(obj => {
      obj.label = 'Promoter';
    });
      
    return [array1, array2, array3];
  }
  
  // Get all the unique labels for the chart
  getCustomLabels(widths: WidthData[]) {
    widths.forEach(x => {
      if (!this.customLabels.includes(x.customLabel)) {
        this.customLabels.push(x.customLabel);
      }
    });
  }

  changeLabelsForNonENPS(text: string): string{
    
    const i = parseInt(text);
    const matchingFirstLabel = this.customLabels[0] !== this.labels[0];
    const matchingLastLabel = this.customLabels[10] !== this.labels[10];
    
    // If the user has changed either the first or last label, assume the default labels no longer fit the scale
    if(matchingFirstLabel || matchingLastLabel) {
      if(i > 0 && i < 4)
        return this.customLabels[1];
      else if (i > 7)
        return this.customLabels[3];
      else
        //return null;
        return 'Neutral';
    }
    
    return this.labels[i];
  }
  
  getChartColor(label: string): string {
    switch (label) {
      // case 'Strongly Agree':
      //   return '#008168';
      case 'Promoter':
        return '#30747F';
      case 'Neutral':
        return '#FFC200';
      case 'Detractor':
        return '#FB946E';
      // case 'Strongly Disagree':
      //   return '#d0352c';
      case 'No Answer':
        return 'lightgrey';
      default:
        return '#AAAAAA';
    }
  }

  getChartColorAlt(label: string): string {
    // return '#D8ECE9';
    // return '#FFEEF2';
    // return '#FEF5E2';
    switch (label) {
      // case 'Strongly Agree':
      //   return '#D8ECE9';
      case 'Promoter':
        return '#D8ECE9';
      case 'Neutral':
        return '#FEF5E2';
      case 'Detractor':
        return '#FFEEF2';
      // case 'Strongly Disagree':
      //   return '#FFEEF2';
      case 'No Answer':
        return '#FFEEF2';
      default:
        return '#EEEEEE';
    }
  }
}
