import { ConnectQuestion } from '@app/models/connect/question/connect-question.model';
import { SurveyQuestionType } from '@app/models/survey-question-type.enum';
import { ConnectQuestionCategory } from '@app/models/connect/type/connect-question-category.enum';

export const mockConnectQuestions: ConnectQuestion[] = [
    // COACH APPLICATION
    {
        id: 1,
        companyId: 1,
        questionText: 'Do you have any other supporting information to add to this request?',
        orderIndex: 1,
        surveyQuestionType: SurveyQuestionType.TEXT_AREA,
        category: ConnectQuestionCategory.COACH_APPLICATION,
        required: false,
        displayQuestionNumber: false
    },
    // COACHING REQUEST
    {
        id: 2,
        companyId: 1,
        questionText: 'What is important to you when it comes to these skills?',
        orderIndex: 1,
        surveyQuestionType: SurveyQuestionType.TEXT_AREA,
        category: ConnectQuestionCategory.COACHING_REQUEST,
        required: true,
        displayQuestionNumber: true
    },
    {
        id: 3,
        companyId: 1,
        questionText: 'What will reaching your goal give you?',
        orderIndex: 2,
        surveyQuestionType: SurveyQuestionType.TEXT_AREA,
        category: ConnectQuestionCategory.COACHING_REQUEST,
        required: true,
        displayQuestionNumber: true
    },
    {
        id: 4,
        companyId: 1,
        questionText: 'What do you hope to achieve in learning these skills?',
        orderIndex: 3,
        surveyQuestionType: SurveyQuestionType.TEXT_AREA,
        category: ConnectQuestionCategory.COACHING_REQUEST,
        required: true,
        displayQuestionNumber: true
    },
    {
        id: 5,
        companyId: 1,
        questionText: 'How will you know when you\'ve reached your goal?',
        orderIndex: 4,
        surveyQuestionType: SurveyQuestionType.TEXT_AREA,
        category: ConnectQuestionCategory.COACHING_REQUEST,
        required: true,
        displayQuestionNumber: true
    },
    // MENTORING REQUEST
    {
        id: 6,
        companyId: 1,
        questionText: 'Why is mentoring important to you?',
        orderIndex: 1,
        surveyQuestionType: SurveyQuestionType.TEXT_AREA,
        category: ConnectQuestionCategory.MENTORING_REQUEST,
        required: true,
        displayQuestionNumber: true
    },
    {
        id: 7,
        companyId: 1,
        questionText: 'What are you hoping to gain professionally or personally from mentoring?',
        orderIndex: 2,
        surveyQuestionType: SurveyQuestionType.TEXT_AREA,
        category: ConnectQuestionCategory.MENTORING_REQUEST,
        required: true,
        displayQuestionNumber: true
    },
    {
        id: 8,
        companyId: 1,
        questionText: 'What do you hope to achieve following mentoring?',
        orderIndex: 3,
        surveyQuestionType: SurveyQuestionType.TEXT_AREA,
        category: ConnectQuestionCategory.MENTORING_REQUEST,
        required: true,
        displayQuestionNumber: true
    },
    {
        id: 9,
        companyId: 1,
        questionText: 'How will you know when you\'ve reached your goal?',
        orderIndex: 4,
        surveyQuestionType: SurveyQuestionType.TEXT_AREA,
        category: ConnectQuestionCategory.MENTORING_REQUEST,
        required: true,
        displayQuestionNumber: true
    },
];