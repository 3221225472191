import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { AssessmentGroupInfoFiltered } from '@app/models/evaluation/assessment-group-info.model';
import { EvaluationCycle } from '@app/models/evaluation/evaluation-cycle.model';
import { EvaluationModuleType } from '@app/models/evaluation/evaluation-module-type.model';
import { IState } from '@app/models/state/state.model';
import { EvaluationCycleAPIService } from '@app/shared/api/evaluation-cycle.api.service';
import { ButtonGroupOption } from '@app/shared/components/inputs/button-group/button-group.component';
import { Globals } from '@app/shared/globals/globals';
import { EvaluationUtils } from '@app/shared/utils/evaluation.utils';
import { forkJoin } from 'rxjs';

type TabEntity = EvaluationCycle;

@Component({
  selector: 'app-information-sidebar-reviews',
  templateUrl: './information-sidebar-reviews.component.html',
  styleUrls: ['./information-sidebar-reviews.component.scss']
})
export class InformationSidebarReviewsComponent implements OnInit {

  public readonly eEvaluationModuleType = EvaluationModuleType;

  @Input() cycleId: number;
  @Input() userIds: number[];
  @Input() userIdsManaging: number[];

  state: IState;

  controlTab: FormControl;
  buttonGroupOptions: ButtonGroupOption[];

  evaluationCycle: EvaluationCycle;
  assessmentGroupsFiltered: AssessmentGroupInfoFiltered;

  constructor(
    public globals: Globals,
    private evaluationCycleAPIService: EvaluationCycleAPIService
  ) {
    this.evaluationCycle = undefined;
    this.userIds = [];
    this.userIdsManaging = [];

    this.state = {
      loading: true,
      error: false,
      errorMessage: ''
    };

    this.assessmentGroupsFiltered = new AssessmentGroupInfoFiltered([], false);

    this.controlTab = this.initControlTab();
    this.buttonGroupOptions = this.getButtonGroupOptions([EvaluationModuleType.SELF_REFLECTION, EvaluationModuleType.MANAGER_REVIEW]);
  }

  ngOnInit(): void {
    this.getData();
  }

  getButtonGroupOptions(stepsActive: EvaluationModuleType[]): ButtonGroupOption[] {
    const options: ButtonGroupOption[] = [];

    if (stepsActive.includes(EvaluationModuleType.SELF_REFLECTION)) {
      options.push({
        value: EvaluationModuleType.SELF_REFLECTION,
        friendlyText: 'Self reflection',
        iconClasses: undefined,
        color: undefined
      });
    }

    if (stepsActive.includes(EvaluationModuleType.MANAGER_REVIEW)) {
      options.push({
        value: EvaluationModuleType.MANAGER_REVIEW,
        friendlyText: 'Manager review',
        iconClasses: undefined,
        color: undefined
      });
    }

    if (stepsActive.includes(EvaluationModuleType.PEER_REVIEW)) {
      options.push({
        value: EvaluationModuleType.PEER_REVIEW,
        friendlyText: 'Peer reviews',
        iconClasses: undefined,
        color: undefined
      });
    }

    if (stepsActive.includes(EvaluationModuleType.UPWARD_REVIEW)) {
      options.push({
        value: EvaluationModuleType.UPWARD_REVIEW,
        friendlyText: 'Upward reviews',
        iconClasses: undefined,
        color: undefined
      });
    }

    return options;
  }

  getData(): void {
    if (!this.cycleId) return this.doError('Unknown cycle');

    forkJoin([
      this.evaluationCycleAPIService.getEvaluationCycleById(this.cycleId),
      this.evaluationCycleAPIService.getAssessmentGroupInfoForCycle(this.cycleId)
    ])
      .toPromise()
      .then(([cycle, assessmentGroupInfo]) => {
        this.evaluationCycle = cycle;
        this.assessmentGroupsFiltered = new AssessmentGroupInfoFiltered(assessmentGroupInfo, false);

        const stepsActive = cycle.modules.map(m => m.evaluationModuleType);
        this.buttonGroupOptions = this.getButtonGroupOptions(stepsActive);
        const defaultTab = this.getDefaultTabForCycle(cycle);
        this.controlTab.setValue(defaultTab);

        this.state.loading = false;
      });
  }

  doError(message: string): void {
    this.state.loading = false;
    this.state.error = true;
    this.state.errorMessage = message;
  }

  sortData(data: TabEntity[]): TabEntity[] {
    // Order by name
    return data.sort((a, b) => {
      const sortStringA = `${a.name}`;
      const sortStringB = `${b.name}`;
      return sortStringA.localeCompare(sortStringB);
    });
  }

  getDefaultTabForCycle(evaluationCycle: EvaluationCycle): EvaluationModuleType {
    if (EvaluationUtils.cycleHasModule(evaluationCycle, EvaluationModuleType.SELF_REFLECTION)) {
      return EvaluationModuleType.SELF_REFLECTION;
    }
    
    if (EvaluationUtils.cycleHasModule(evaluationCycle, EvaluationModuleType.MANAGER_REVIEW)) {
      return EvaluationModuleType.MANAGER_REVIEW;
    }

    if (EvaluationUtils.cycleHasModule(evaluationCycle, EvaluationModuleType.PEER_REVIEW)) {
      return EvaluationModuleType.PEER_REVIEW;
    }

    if (EvaluationUtils.cycleHasModule(evaluationCycle, EvaluationModuleType.UPWARD_REVIEW)) {
      return EvaluationModuleType.UPWARD_REVIEW;
    }

    return null; 
  }

  initControlTab(tab?: EvaluationModuleType): FormControl {
    const formControl = new FormControl(EvaluationModuleType.SELF_REFLECTION);

    if (tab) {
      formControl.setValue(tab);
    }

    return formControl;
  }

  // getAnswers(): void {
  //   const isManagingUser = this.userIdsManaging.includes(this.userIds[0]);
  //   if (isManagingUser) {
  //     this.evaluationCycleAPIService.getEvaluationUserByCycleIdAndUserId(this.cycleId!, this.userIds[0])
  //       .toPromise()
  //       .then(evaluationUser => {
  //         if (evaluationUser.selfReflection) {
  //           evaluationUser.selfReflection = this.parseSelfReflection(evaluationUser.selfReflection);
  //         }
  //         this.selfReflection = evaluationUser.selfReflection;
  
  //         let managerReview = EvaluationUtils.getManagerReviewResponseFromEvaluationUser(evaluationUser);
  //         if (managerReview) {
  //           managerReview = this.parseManagerReview(managerReview);
  //         }
  
  //         this.managerReview = managerReview;
  //         this.state.loading = false;
  //       });
  //     return;
  //   } else {
  //     forkJoin([
  //       this.evaluationCycleAPIService.getSelfReflectionForCycleAndUser(this.cycleId!, this.userIds[0]),
  //       this.evaluationCycleAPIService.getManagerReviewForUserMe(this.cycleId!)
  //     ])
  //       .toPromise()
  //       .then(([selfReflection, managerReview]) => {
  //         if (selfReflection) {
  //           selfReflection = this.parseSelfReflection(selfReflection);
  //         }
  //         this.selfReflection = selfReflection;
  
  //         if (managerReview) {
  //           managerReview = this.parseManagerReview(managerReview);
  //         }
  //         this.managerReview = managerReview;
  //         this.state.loading = false;
  //       });
  //   }

  // }

  // // TODO: Do this with pre-processing instead of calling it from the UI
  // getAnswerForScale(step: EvaluationModuleType, question: EvaluationCycleQuestion, answer: string): string {
  //   if (question.assessmentQuestion) {
  //     const stepAnswers = this.assessmentGroupsFiltered.assessmentGroupInfo[step];

  //     if (stepAnswers) {
  //       const typeAnswers = stepAnswers[question.questionBankQuestion.surveyQuestionType];

  //       if (typeAnswers) {
  //         const value = typeAnswers.find(ag => ag.value === answer);

  //         if (value) {
  //           return `${value.groupTitle} (${answer})`;
  //         }
  //       }
  //     }
  //   }

  //   return 'Unknown';
  // }
}
