import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Praise } from '@app/models/feedback/praise.model';
import { IState } from '@app/models/state/state.model';
import { PraiseAPIService } from '@app/shared/api/praise.api.service';
import { PaginationNewComponent } from '@app/shared/pagination/pagination-new/pagination-new.component';

type TabEntity = Praise;

@Component({
  selector: 'app-information-sidebar-feedback-praise',
  templateUrl: './information-sidebar-feedback-praise.component.html',
  styleUrls: ['./information-sidebar-feedback-praise.component.scss']
})
export class InformationSidebarFeedbackPraiseComponent implements OnInit {
  
  @ViewChild('pagination') pagination?: PaginationNewComponent;

  @Input() userIds: number[];

  state: IState;
  data: {
    master: TabEntity[];
    filtered: TabEntity[];
    display: TabEntity[];
  };

  constructor(
    private praiseApiService: PraiseAPIService
  ) {
    this.userIds = [];

    this.state = {
      loading: true,
      error: false,
      errorMessage: ''
    };

    this.data = {
      master: [],
      filtered: [],
      display: []
    };
  }

  ngOnInit(): void {
    this.getData();
  }
  
  getData(): void {
    // Otherwise nothing
    this.praiseApiService.getSidebarPraise(this.userIds)
      .subscribe(data => {
        data = this.sortData(data);
        this.populateData(data);
        this.state.loading = false;
      });
  }

  sortData(data: TabEntity[]): TabEntity[] {
    // Order by timestamp
    return data.sort((a, b) => {
      const dateA = new Date(a.timestamp);
      const dateB = new Date(b.timestamp);
      return dateB.getTime() - dateA.getTime();
    });
  }

  populateData(data?: TabEntity[]): void {
    if (!data) { data = this.data.master; }
    this.data.master = data;
    this.data.filtered = data;

    // this.universalFilterData.searchProps = ['name'];
    // this.universalFilterData.filterOptions = data.map(i => this.getFilterOptionsForBrowseRole(i));

    this.refreshPagination();
  }

  refreshPagination(): void {
    if (this.pagination) {
      this.pagination.update();
    }
  }
}
