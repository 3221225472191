<div class="create-form-container">
  <!-- Action icons from new workflow -->
  <div class="extra-actions-row">
    <div class="row m-left-0 m-right-0">
      <div class="actions-row-left">
        <div class="actions-row-pill">
          <ng-container [ngSwitch]="!!formCreate.controls.id.value">
            <span *ngSwitchCase="false" translate>{{eQualificationMessages.HEADER_CREATING}}</span>
            <span *ngSwitchCase="true" translate>{{eQualificationMessages.HEADER_EDITING}}</span>
          </ng-container>
        </div>
      </div>
      <div class="actions-row-right">
        <div class="actions-row-item" (click)="openHelpArticle()">
          <span class="fal fa-fw fa-lightbulb-on"></span>
          <span class="m-right-5"></span>
          <span translate>{{eCommonMessages.LEARN_MORE}}</span>
        </div>
        <div class="actions-row-item frankli-close" (click)="closeCreateModal()">
          <span class="fal fa-fw fa-times"></span>
        </div>
      </div>
    </div>
  </div>

  <div class="main-form-contents">
    <ng-container *ngIf="formCreate" [ngSwitch]="selectedView">
      <ng-container *ngSwitchCase="eQualificationCreatePageViews.MAIN">
        <div class="main-form">
          <div class="p-left-15 p-right-15 p-top-15">
            <!-- Name -->
            <div class="frankli-form-group frankli-form-group-filled frankli-form-group-placeholder-shown" [class.frankli-form-group-invalid]="submitted && controlQualificationName.invalid">
              <label for="name">
                <span translate>{{eQualificationMessages.NAME}}</span>
              </label>
              <input id="name" type="text" class="form-control" [formControl]="controlQualificationName" [placeholder]="eQualificationMessages.NAME_PLACEHOLDER | translate">
              <div class="frankli-form-group-alert">
                <ng-container *ngIf="submitted">
                  <div *ngIf="controlQualificationName.hasError('required')" translate>{{eCommonMessages.FORM_REQUIRED}}</div>
                </ng-container>
                <div *ngIf="controlQualificationName.hasError('maxlength')" translate [translateParams]="{ maxValue: 255 }">{{eCommonMessages.FORM_MAX_CHARACTER_VALUE}}</div>
              </div>
            </div>

            <!-- Provider -->
            <div class="frankli-form-group frankli-form-group-filled frankli-form-group-placeholder-shown" [class.frankli-form-group-invalid]="submitted && controlQualificationProvider.invalid">
              <label for="name">
                <span translate>{{eQualificationMessages.PROVIDER}}</span>
              </label>
              <input id="name" type="text" class="form-control" [formControl]="controlQualificationProvider" [placeholder]="eQualificationMessages.PROVIDER_PLACEHOLDER | translate">
              <div class="frankli-form-group-alert">
                <ng-container *ngIf="submitted">
                  <div *ngIf="controlQualificationProvider.hasError('required')" translate>{{eCommonMessages.FORM_REQUIRED}}</div>
                </ng-container>
                <div *ngIf="controlQualificationProvider.hasError('maxlength')" translate [translateParams]="{ maxValue: 50 }">{{eCommonMessages.FORM_MAX_CHARACTER_VALUE}}</div>
              </div>
            </div>

            <!-- Received date -->
            <div class="frankli-form-group frankli-form-group-filled" [class.frankli-form-group-invalid]="submitted && controlQualificationReceivedDate.invalid">
              <label for="description">
                <span translate>{{eQualificationMessages.RECEIVED_DATE}}</span>
              </label>
              <!-- <app-date-picker [name]="'qualificationReceivedDate'" [formControl]="controlQualificationReceivedDate" [displayFormat]="'YYYY'" [defaultView]="3" [monthSelector]="false" [disableSince]="maxReceivedDate"></app-date-picker> -->
              <app-year-picker [formControl]="controlQualificationReceivedDate"></app-year-picker>
              <div class="frankli-form-group-alert">
                <ng-container *ngIf="submitted">
                  <div *ngIf="controlQualificationReceivedDate.hasError('required')" translate>{{eCommonMessages.FORM_REQUIRED}}</div>
                </ng-container>
              </div>
            </div>

            <!-- Expiration date -->
            <div class="frankli-form-group frankli-form-group-filled" [class.frankli-form-group-invalid]="submitted && controlQualificationExpirationDate.invalid">
              <label for="description">
                <span translate>{{eQualificationMessages.EXPIRATION_DATE}}</span>
              </label>
              <app-date-picker [name]="'qualificationExpiration'" [formControl]="controlQualificationExpirationDate" [showClearButton]="true"></app-date-picker>
              <div class="frankli-form-group-alert">
                <ng-container *ngIf="submitted">
                  <div *ngIf="controlQualificationExpirationDate.hasError('required')" translate>{{eCommonMessages.FORM_REQUIRED}}</div>
                </ng-container>
              </div>
            </div>

            <!-- Description -->
            <div class="frankli-form-group frankli-form-group-filled" [class.frankli-form-group-invalid]="submitted && controlQualificationDescription.invalid">
              <label for="description">
                <span translate>{{eQualificationMessages.DESCRIPTION}}</span>
                <span class="m-left-5">({{eCommonMessages.OPTIONAL | translate}})</span>
              </label>
              <app-editor-response id="description" [formControl]="controlQualificationDescription" [maxCharacters]="2000" [hardMax]="3000" [columnIndent]="false" [placeholder]="eCommonMessages.TEXTAREA_PLACEHOLDER | translate"></app-editor-response>
              <div class="frankli-form-group-alert">
                <ng-container *ngIf="submitted">
                </ng-container>
                <div *ngIf="controlQualificationDescription.hasError('softmax')" translate [translateParams]="{ maxValue: 2000 }">{{eCommonMessages.FORM_MAX_CHARACTER_VALUE}}</div>
                <div *ngIf="controlQualificationDescription.hasError('maxlength')" translate>{{eCommonMessages.TOO_MUCH_FORMATTING}}</div>
              </div>
            </div>
          </div>
        </div>

        <div class="primary-actions-row">
          <button class="btn-frankli-round btn-frankli-cancel m-right-5" (click)="onCancelCreate()" translate>
            <span translate>{{eCommonMessages.CANCEL}}</span>
          </button>
          <button class="btn-frankli-round btn-frankli-green" (click)="onConfirmCreate()">
            <span>{{(formCreate.controls.id.value ? eCommonMessages.UPDATE : eCommonMessages.CREATE) | translate}}</span>
          </button>
        </div>
      </ng-container>
    </ng-container>
  </div>
</div>
