<app-modal #innerModal>
  <div class="modal-body-content">
    <div class="p-bottom-15">
      <h1 class="standard-heading">Recent Activity</h1>
    </div>

    <ng-container *ngIf="(activityList && activityList.length > 0); else noActivity">
      <ng-container *ngFor="let activity of activityList; index as i;">
        <ng-container *ngIf="activity.activityType">
          <ng-container [ngSwitch]="activity.activityType">
            <!-- UNSUBMISSION -->
            <ng-container *ngSwitchCase="eEvaluationAdminActivityType.MANAGER_REVIEW_UNSUBMITTED">
              <div class="row m-left-0 m-right-0 p-bottom-10">
                <div class="col-icon">
                  <span *ngIf="activity.admin" (click)="navigateToProfile(activity.admin.id)" class="clickable noselect">
                    <span class="owner-img">
                      <!-- TODO: Add firstName and lastName -->
                      <app-profile-photo [src]="activity.admin.imageUrl"></app-profile-photo>
                    </span>
                  </span>
                </div>
                <div class="col-message">
                  <div>
                    <strong>{{activity.admin.firstName}} {{activity.admin.lastName}}</strong>
                    <span class="m-right-5"></span>
                    <span>Unsubmitted this manager review on</span>
                    <span class="m-right-5"></span>
                    <strong>{{activity.timestamp | date: 'MMM d, y @ h:mm a'}}.</strong>
                  </div>
                  <div>
                    <strong>Reason:</strong>
                    <span class="m-right-5"></span>
                    <span>{{activity.extraInfo ? activity.extraInfo : 'None given'}}</span>
                  </div>
                </div>
              </div>
            </ng-container>
            <ng-container *ngSwitchCase="eEvaluationAdminActivityType.SELF_REFLECTION_UNSUBMITTED">
              <div class="row m-left-0 m-right-0 p-bottom-10">
                <div class="col-icon">
                  <span *ngIf="activity.admin" (click)="navigateToProfile(activity.admin.id)" class="clickable noselect">
                    <span class="owner-img">
                      <!-- TODO: Add firstName and lastName -->
                      <app-profile-photo [src]="activity.admin.imageUrl"></app-profile-photo>
                    </span>
                  </span>
                </div>
                <div class="col-message">
                  <div>
                    <strong *ngIf="activity.admin">{{activity.admin.firstName}} {{activity.admin.lastName}}</strong>
                    <span class="m-right-5"></span>
                    <span>Unsubmitted this self reflection on</span>
                    <span class="m-right-5"></span>
                    <strong>{{activity.timestamp | date: 'MMM d, y @ h:mm a'}}.</strong>
                  </div>
                  <div>
                    <strong>Reason:</strong>
                    <span class="m-right-5"></span>
                    <span>{{activity.extraInfo ? activity.extraInfo : 'None given'}}</span>
                  </div>
                </div>
              </div>
            </ng-container>
            <ng-container *ngSwitchCase="eEvaluationAdminActivityType.PEER_REVIEW_UNSUBMITTED">
              <div class="row m-left-0 m-right-0 p-bottom-10">
                <div class="col-icon">
                  <span *ngIf="activity.admin" (click)="navigateToProfile(activity.admin.id)" class="clickable noselect">
                    <span class="owner-img">
                      <!-- TODO: Add firstName and lastName -->
                      <app-profile-photo [src]="activity.admin.imageUrl"></app-profile-photo>
                    </span>
                  </span>
                </div>
                <div class="col-message">
                  <div>
                    <strong>{{activity.admin.firstName}} {{activity.admin.lastName}}</strong>
                    <span class="m-right-5"></span>
                    <span>Unsubmitted this peer review on</span>
                    <span class="m-right-5"></span>
                    <strong>{{activity.timestamp | date: 'MMM d, y @ h:mm a'}}.</strong>
                  </div>
                  <div>
                    <strong>Reason:</strong>
                    <span class="m-right-5"></span>
                    <span>{{activity.extraInfo ? activity.extraInfo : 'None given'}}</span>
                  </div>
                </div>
              </div>
            </ng-container>
  
            <!-- TODO: Other actions -->
            <ng-container *ngSwitchCase="eEvaluationAdminActivityType.RENAMED">
            </ng-container>
            <ng-container *ngSwitchCase="eEvaluationAdminActivityType.ADDED_PARTICIPANT">
            </ng-container>
            <ng-container *ngSwitchCase="eEvaluationAdminActivityType.REMOVED_PARTICIPANT">
            </ng-container>
            <ng-container *ngSwitchCase="eEvaluationAdminActivityType.STEP_DATE_CHANGED">
            </ng-container>
            <ng-container *ngSwitchCase="eEvaluationAdminActivityType.QUESTION_ADDED">
            </ng-container>
            <ng-container *ngSwitchCase="eEvaluationAdminActivityType.QUESTION_REMOVED">
            </ng-container>
            <ng-container *ngSwitchCase="eEvaluationAdminActivityType.CALIBRATION_SAVED">
            </ng-container>
            <ng-container *ngSwitchCase="eEvaluationAdminActivityType.EXTEND_END_DATE">
            </ng-container>
          </ng-container>
        </ng-container>
      </ng-container>
    </ng-container>

    <ng-template #noActivity>
      <span>No activity found</span>
    </ng-template>

    <div class="p-top-15 text-center">
      <app-button (onClick)="hide()">
        <span>OK</span>
      </app-button>
    </div>
  </div>
</app-modal>