import { Component, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { GoalsMessages } from '@app/goals/goals.messages';
import { Tag } from '@app/domain/tag/model/tag.model';
import { TagPickerComponent } from '@app/shared/components/inputs/tag-picker/tag-picker.component';
import { Globals } from '@app/shared/globals/globals';
import { CommonGoalCreateFormPart } from '../../goal-create-views/goal-create-main/goal-create-main.component';

@Component({
  selector: 'app-goal-create-part-tags',
  templateUrl: './goal-create-part-tags.component.html',
  styleUrls: ['./goal-create-part-tags.component.scss']
})
export class GoalCreatePartTagsComponent implements CommonGoalCreateFormPart {
  public readonly eGoalsMessages = GoalsMessages;

  @Input() controlTags: FormControl;
  @Input() submitted: boolean;
  @Input() submittedDraft: boolean;
  @Input() parentDropdownOpen: boolean;

  @Output() onSetParentDropdown: EventEmitter<boolean>;

  @ViewChild('tagPicker') tagPicker?: TagPickerComponent;

  customOptions: Tag[];

  constructor(
    public globals: Globals
  ) {
    this.controlTags = new FormControl([], []);
    this.customOptions = [];
    this.submitted = false;
    this.submittedDraft = false;
    this.parentDropdownOpen = false;
    this.onSetParentDropdown = new EventEmitter<boolean>();
  }

  setParentDropdown(expanded: boolean): void {
    this.onSetParentDropdown.emit(expanded);
  }

  focus(): void {
    this.focusTagPicker();
  }

  focusTagPicker(): void {
    if (!this.tagPicker) { return; }
    this.tagPicker.focusSearchInput();
  }
}
