import { Component, Input, OnInit } from '@angular/core';
import { CommonMessages } from '@app/constants/common.messages';
import { AssessmentGroupInfoFiltered } from '@app/models/evaluation/assessment-group-info.model';
import { EvaluationCycleQuestion } from '@app/models/evaluation/evaluation-cycle-question.model';
import { EvaluationCycle } from '@app/models/evaluation/evaluation-cycle.model';
import { EvaluationModuleType } from '@app/models/evaluation/evaluation-module-type.model';
import { SelfReflection } from '@app/models/evaluation/self-reflection.model';
import { IState } from '@app/models/state/state.model';
import { SurveyQuestionType } from '@app/models/survey-question-type.enum';
import { EvaluationCycleAPIService } from '@app/shared/api/evaluation-cycle.api.service';
import { Globals } from '@app/shared/globals/globals';

@Component({
  selector: 'app-information-sidebar-reviews-self-reflection',
  templateUrl: './information-sidebar-reviews-self-reflection.component.html',
  styleUrls: ['./information-sidebar-reviews-self-reflection.component.scss']
})
export class InformationSidebarReviewsSelfReflectionComponent implements OnInit {

  public readonly eEvaluationModuleType = EvaluationModuleType;
  public readonly eSurveyQuestionType = SurveyQuestionType;
  public readonly eCommonMessages = CommonMessages;

  @Input() cycle: EvaluationCycle;
  @Input() userIds: number[];
  @Input() userIdsManaging: number[];
  @Input() assessmentGroupsFiltered: AssessmentGroupInfoFiltered;

  state: IState;
  selfReflection: SelfReflection;

  constructor(
    public globals: Globals,
    private evaluationCycleAPIService: EvaluationCycleAPIService
  ) {
    this.cycle = undefined;
    this.userIds = [];
    this.userIdsManaging = [];

    this.state = {
      loading: true,
      error: false,
      errorMessage: ''
    };

    this.selfReflection = undefined;
  }

  ngOnInit(): void {
    this.getData();
  }

  doError(message: string): void {
    this.state.loading = false;
    this.state.error = true;
    this.state.errorMessage = message;
  }

  getData(): void {
    if (!this.cycle || !this.userIds || this.userIds.length === 0) return this.doError('No data');
    const isManagingUser = this.userIdsManaging.includes(this.userIds[0]);

    if (isManagingUser) {
      this.evaluationCycleAPIService.getEvaluationUserByCycleIdAndUserId(this.cycle.id, this.userIds[0])
        .toPromise()
        .then(evaluationUser => {
          this.selfReflection = this.parseSelfReflection(evaluationUser.selfReflection);
          this.state.loading = false;
        });
    } else {
      this.evaluationCycleAPIService.getSelfReflectionForCycleAndUser(this.cycle.id, this.userIds[0]).toPromise()
        .then(selfReflection => {
          this.selfReflection = this.parseSelfReflection(selfReflection);
          this.state.loading = false;
        });
    }
  }

  parseSelfReflection(selfReflection: SelfReflection): SelfReflection {
    if (!selfReflection) { return selfReflection; }

    selfReflection.selfQuestionsAnswered.map(answer => {
      const question = this.cycle!.selfReflectionQuestions.find(y => y.id === answer.questionId)!;
      if (question) {
        answer.question = question;
      }

      return answer;
    });

    return selfReflection;
  }

  // TODO: Do this with pre-processing instead of calling it from the UI
  getAnswerForScale(step: EvaluationModuleType, question: EvaluationCycleQuestion, answer: string): string {
    if (question.assessmentQuestion) {
      const stepAnswers = this.assessmentGroupsFiltered.assessmentGroupInfo[step];

      if (stepAnswers) {
        const typeAnswers = stepAnswers[question.questionBankQuestion.surveyQuestionType];

        if (typeAnswers) {
          const value = typeAnswers.find(ag => ag.value === answer);

          if (value) {
            return `${value.groupTitle} (${answer})`;
          }
        }
      }
    }

    return 'Unknown';
  }
}
