<div class="container-peer-review-item" *ngIf="request">
  <span class="col-subject">
    <span class="owner-img" [routerLink]="['/profile', request.subjectUser.id]">
      <app-profile-photo [src]="request.subjectUser.imageUrl" [firstName]="request.subjectUser.firstName" [lastName]="request.subjectUser.lastName"></app-profile-photo>
    </span>
  </span>
  <div class="col-subject-name">
    <strong>{{request.subjectUser.firstName}} {{request.subjectUser.lastName}}</strong>
    <div class="direct-report">Sender</div>
  </div>
  <span class="col-author">
    <span class="owner-img" [routerLink]="['/profile', request.authorUser.id]">
      <app-profile-photo [src]="request.authorUser.imageUrl" [firstName]="request.authorUser.firstName" [lastName]="request.authorUser.lastName"></app-profile-photo>
    </span>
  </span>
  <div class="col-author-name">
    <strong>{{request.authorUser.firstName}} {{request.authorUser.lastName}}</strong>
    <div class="direct-report">Nominee</div>
  </div>
  <div class="col-cycle">
    <ng-container *ngIf="cycle">
      <strong [title]="cycle.name">{{cycle.name}}</strong>
      <div>
        <span>{{cycle.peerReviewQuestions.length}}</span>
        <span class="m-right-5"></span>
        <span>questions</span>
      </div>
    </ng-container>
  </div>
  <div class="col-state">
    <div class="progress-bar-item">
      <app-peer-review-progress-bar [request]="request"></app-peer-review-progress-bar>
    </div>
    <ng-container *ngIf="wasUnsubmitted">
      <div class="unsubmitted-message" (click)="showActivityModal()" tooltip="Click here for more information" placement="bottom" container="body" [triggers]="globals.tooltipTriggers">Unsubmitted</div>
    </ng-container>
  </div>
  <div class="col-actions">
    <ng-container [ngSwitch]="request.state">
      <ng-container *ngSwitchCase="eEvaluationFeedbackRequestState.PENDING_MANAGER">
        <ng-container *ngIf="canApproveRequestManager">
          <app-button [type]="eButtonType.GRAY_INVERTED" (onClick)="declineRequest()" [tooltipText]="'Decline this request'">
            <span>Decline</span>
          </app-button>
          <span class="m-right-5"></span>
          <app-button [type]="eButtonType.GREEN_INVERTED" (onClick)="approveRequest()" [tooltipText]="'Approve this request'">
            <span>Approve</span>
          </app-button>
        </ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="eEvaluationFeedbackRequestState.PENDING_PEER">
        <ng-container *ngIf="canApproveRequest">
          <app-button [type]="eButtonType.GRAY_INVERTED" (onClick)="declineRequest()" [tooltipText]="'Decline to respond to this review'">
            <span>Decline</span>
          </app-button>
          <span class="m-right-5"></span>
          <app-button [type]="eButtonType.GREEN_INVERTED" (onClick)="approveRequest()" [tooltipText]="'Agree to respond to this review'">
            <span>Approve</span>
          </app-button>
        </ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="eEvaluationFeedbackRequestState.APPROVED">
        <ng-container *ngIf="canViewRequest">
          <app-button [type]="eButtonType.GREEN_INVERTED" (onClick)="openRequest()" [tooltipText]="'Respond to this review'">
            <span>Respond</span>
          </app-button>
          <!-- <app-table-action-icon (click)="openRequest()" [icon]="'fa-edit'" [hoverColor]="eIconHoverColor.YELLOW" [tooltipText]="'Respond to this review'"></app-table-action-icon> -->
        </ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="eEvaluationFeedbackRequestState.COMPLETED">
        <ng-container *ngIf="canViewRequest">
          <app-button [type]="eButtonType.GREEN_INVERTED" (onClick)="openRequest()" [tooltipText]="'Respond to this review'">
            <span>Respond</span>
          </app-button>
          <!-- <app-table-action-icon (click)="openRequest()" [icon]="'fa-eye'" [hoverColor]="eIconHoverColor.GREEN" [tooltipText]="'Open this review'"></app-table-action-icon> -->
        </ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="eEvaluationFeedbackRequestState.DECLINED_PEER"></ng-container>
    </ng-container>
  </div>
</div>

<app-modal #modalDecline>
  <div class="p-bottom-15">
    <h1 class="standard-heading">Decline Reason</h1>
    <div class="standard-description">Please state why you wish to decline this request</div>
  </div>

  <div class="frankli-form-group frankli-form-group-filled" [class.frankli-form-group-invalid]="state.submitted && controlDeclineReason.invalid">
    <label for="reason">Reason:</label>
    <textarea id="reason" class="form-control noresize" rows="3" [formControl]="controlDeclineReason"></textarea>
    <div class="p-relative">
      <div class="pull-right" [class.text-danger]="state.submitted && controlDeclineReason.invalid">{{controlDeclineReason.value?.length}}/255</div>
      <div class="frankli-form-group-alert">
        <ng-container *ngIf="state.submitted">
          <div *ngIf="controlDeclineReason.hasError('required')">Required</div>
          <div *ngIf="controlDeclineReason.hasError('minlength')">Must be at least 20 characters</div>
        </ng-container>
        <div *ngIf="controlDeclineReason.hasError('maxlength')">Must be less than 255 characters</div>
      </div>
    </div>
  </div>

  <div class="text-center p-top-15">
    <app-button [type]="eButtonType.GRAY_INVERTED" (onClick)="cancelDeclineRequest()">
      <span>Cancel</span>
    </app-button>
    <span class="m-right-5"></span>
    <app-button [type]="eButtonType.GREEN" (onClick)="confirmDeclineRequest()">
      <span>Decline</span>
    </app-button>
  </div>
</app-modal>
