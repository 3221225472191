<div class="container-meeting-sentiment-scale">
  <ng-container *ngIf="!sentimentScaleHasChanged; else templateSentimentScaleHasChanged;">
    <app-sentiment-scale-response [sentimentScale]="currentMeetingSentimentScale" [formControl]="controlSentimentScaleOption"></app-sentiment-scale-response>

    <div class="area-actions">
      <div class="text-left text-info">
        <ng-container *ngIf="lastUpdated !== undefined">
          <small>Last updated: {{ lastUpdated | momentPipe: 'lll' }}</small>
        </ng-container>
      </div>
  
      <div class="area-button-save" *ngIf="!controlSentimentScaleOption.disabled">
        <app-button (onClick)="onClickSave()">
          <span translate>{{eCommonMessages.SAVE}}</span>
        </app-button>
      </div>
    </div>
  </ng-container>
  <ng-template #templateSentimentScaleHasChanged>
    <app-sentiment-scale-response [sentimentScale]="responseSentimentScale" [formControl]="controlSentimentScaleOption"></app-sentiment-scale-response>
  </ng-template>
</div>
