import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { GoalsMessages } from '@app/goals/goals.messages';
import { CompanyFeatures } from '@app/models/company-features.model';
import { GoalKeyResult } from '@app/models/goals/goal-key-result.model';
import { GoalPriority } from '@app/models/goals/goal-priority.model';
import { Tag } from '@app/domain/tag/model/tag.model';
import { GoalType } from '@app/models/goals/goal-type.enum';
import { GoalVisibility } from '@app/models/goals/goal-visibility.model';
import { RoleName } from '@app/models/user-role.model';
import { IState } from '@app/models/state/state.model';
import { UserMinimal } from '@app/models/user/user-minimal.model';
import { DropdownDisplayComponent } from '@app/shared/components/displays/dropdown-display/dropdown-display.component';
import { Globals } from '@app/shared/globals/globals';
import { DateUtils } from '@app/shared/utils/date.utils';
import { FrankliValidators } from '@app/shared/validators/validators';
import { TranslateService } from '@ngx-translate/core';
import { IMyDate } from 'angular-mydatepicker';
import { GoalCreatePartKeyResultsComponent } from '../../goal-create-form-parts/goal-create-part-key-results/goal-create-part-key-results.component';
import { GoalCreatePartTypeComponent } from '../../goal-create-form-parts/goal-create-part-type/goal-create-part-type.component';
import { GoalCreatePartDetailsComponent } from '../../goal-create-form-parts/goal-create-part-details/goal-create-part-details.component';
import { GoalCreatePartDevelopmentNeedsComponent } from '../../goal-create-form-parts/goal-create-part-development-needs/goal-create-part-development-needs.component';
import { GoalCreatePartSiteComponent } from '../../goal-create-form-parts/goal-create-part-site/goal-create-part-site.component';
import { GoalCreatePartDepartmentComponent } from '../../goal-create-form-parts/goal-create-part-department/goal-create-part-department.component';
import { GoalCreatePartOwnersComponent } from '../../goal-create-form-parts/goal-create-part-owners/goal-create-part-owners.component';
import { GoalCreatePartAlignmentComponent } from '../../goal-create-form-parts/goal-create-part-alignment/goal-create-part-alignment.component';
import { GoalCreatePartPriorityComponent } from '../../goal-create-form-parts/goal-create-part-priority/goal-create-part-priority.component';
import { GoalCreatePartTagsComponent } from '../../goal-create-form-parts/goal-create-part-tags/goal-create-part-tags.component';
import { GoalCreatePartTasksComponent } from '../../goal-create-form-parts/goal-create-part-tasks/goal-create-part-tasks.component';
import { CreateTaskDto } from '@app/domain/task/model/create-task.dto';
import { GoalCreateValidationLimits } from '../../goal-create.component';
import { NotifyUtils } from '@app/shared/utils/notify.utils';
import { GoalUtils } from '@app/shared/utils/goal.utils';
import { ModalComponent } from '@app/shared/modal/modal.component';
import { GoalDistribution } from '@app/models/goals/goal-distribution.model';
import { SwalUtils } from '@app/shared/utils/swal.utils';
import { TerminologyEntity } from '@app/domain/terminology/model/terminology-entity.enum';

export enum FormPart {
  TYPE = 'TYPE',
  DETAILS = 'DETAILS',
  DEVELOPMENT_NEEDS = 'DEVELOPMENT_NEEDS',
  SITE = 'SITE',
  DEPARTMENT = 'DEPARTMENT',
  KEY_RESULTS = 'KEY_RESULTS',
  OWNERS = 'OWNERS',
  ALIGNMENT = 'ALIGNMENT',
  PRIORITY = 'PRIORITY',
  TAGS = 'TAGS',
  TASKS = 'TASKS'
}
interface IDropdownMetadata {
  [key: string]: {
    title: string;
    description: string;
  }
}

type PageState = IState

export interface CommonGoalCreateFormPart {
  focus: () => void;
}

@Component({
  selector: 'app-goal-create-main',
  templateUrl: './goal-create-main.component.html',
  styleUrls: ['./goal-create-main.component.scss']
})
export class GoalCreateMainComponent implements OnInit {
  public readonly eFormPart = FormPart;
  public readonly eGoalType = GoalType;
  public readonly eCompanyFeatures = CompanyFeatures;

  @Input() goalForm!: FormGroup;
  @Input() submitted: boolean;
  @Input() submittedDraft: boolean;
  @Input() furthestPart?: FormPart;
  @Input() modal?: ModalComponent;
  @Output() furthestPartChange: EventEmitter<FormPart>;

  @ViewChild('keyResultsFormPart') keyResultsFormPart?: GoalCreatePartKeyResultsComponent;

  // #region - DROPDOWN VIEWCHILDS
  @ViewChild('dropdownTypeContainer') dropdownTypeContainer?: ElementRef<HTMLDivElement>;
  @ViewChild('dropdownType') dropdownType?: DropdownDisplayComponent;
  @ViewChild('controlType') controlType?: GoalCreatePartTypeComponent;
  @ViewChild('dropdownDetailsContainer') dropdownDetailsContainer?: ElementRef<HTMLDivElement>;
  @ViewChild('dropdownDetails') dropdownDetails?: DropdownDisplayComponent;
  @ViewChild('controlDetails') controlDetails?: GoalCreatePartDetailsComponent;
  @ViewChild('dropdownDevelopmentNeedsContainer') dropdownDevelopmentNeedsContainer?: ElementRef<HTMLDivElement>;
  @ViewChild('dropdownDevelopmentNeeds') dropdownDevelopmentNeeds?: DropdownDisplayComponent;
  @ViewChild('controlDevelopmentNeeds') controlDevelopmentNeeds?: GoalCreatePartDevelopmentNeedsComponent;
  @ViewChild('dropdownSiteContainer') dropdownSiteContainer?: ElementRef<HTMLDivElement>;
  @ViewChild('dropdownSite') dropdownSite?: DropdownDisplayComponent;
  @ViewChild('controlSite') controlSite?: GoalCreatePartSiteComponent;
  @ViewChild('dropdownDepartmentContainer') dropdownDepartmentContainer?: ElementRef<HTMLDivElement>;
  @ViewChild('dropdownDepartment') dropdownDepartment?: DropdownDisplayComponent;
  @ViewChild('controlDepartment') controlDepartment?: GoalCreatePartDepartmentComponent;
  @ViewChild('dropdownKeyResultsContainer') dropdownKeyResultsContainer?: ElementRef<HTMLDivElement>;
  @ViewChild('dropdownKeyResults') dropdownKeyResults?: DropdownDisplayComponent;
  @ViewChild('controlKeyResults') controlKeyResults?: GoalCreatePartKeyResultsComponent;
  @ViewChild('dropdownOwnersContainer') dropdownOwnersContainer?: ElementRef<HTMLDivElement>;
  @ViewChild('dropdownOwners') dropdownOwners?: DropdownDisplayComponent;
  @ViewChild('controlOwners') controlOwners?: GoalCreatePartOwnersComponent;
  @ViewChild('dropdownAlignmentContainer') dropdownAlignmentContainer?: ElementRef<HTMLDivElement>;
  @ViewChild('dropdownAlignment') dropdownAlignment?: DropdownDisplayComponent;
  @ViewChild('controlAlignment') controlAlignment?: GoalCreatePartAlignmentComponent;
  @ViewChild('dropdownPriorityContainer') dropdownPriorityContainer?: ElementRef<HTMLDivElement>;
  @ViewChild('dropdownPriority') dropdownPriority?: DropdownDisplayComponent;
  @ViewChild('controlPriority') controlPriority?: GoalCreatePartPriorityComponent;
  @ViewChild('dropdownTagsContainer') dropdownTagsContainer?: ElementRef<HTMLDivElement>;
  @ViewChild('dropdownTags') dropdownTags?: DropdownDisplayComponent;
  @ViewChild('controlTags') controlTags?: GoalCreatePartTagsComponent;
  @ViewChild('dropdownTasksContainer') dropdownTasksContainer?: ElementRef<HTMLDivElement>;
  @ViewChild('dropdownTasks') dropdownTasks?: DropdownDisplayComponent;
  @ViewChild('controlTasks') controlTasks?: GoalCreatePartTasksComponent;
  // #endregion

  state: PageState;

  dropdownMetadata: IDropdownMetadata;
  dataGoalType: GoalType[];
  dataGoalPriorities: GoalPriority[];

  dropdownFirstToggled: FormPart[];

  minDataDate: IMyDate;
  maxLinkedItemDate: IMyDate | null;

  // #region - FORMCONTROL GETTERS
  get controlGoalType(): FormControl {
    return this.goalForm.controls.type as FormControl;
  }

  get controlGoalTitle(): FormControl {
    return this.goalForm.controls.title as FormControl;
  }

  get controlGoalEndDate(): FormControl {
    return this.goalForm.controls.endDate as FormControl;
  }

  get controlGoalVisibility(): FormControl {
    return this.goalForm.controls.visibility as FormControl;
  }

  get controlGoalKeyResults(): FormArray {
    return this.goalForm.controls.keyResults as FormArray;
  }

  get controlGoalOwners(): FormControl {
    return this.goalForm.controls.owners as FormControl;
  }

  get controlGoalAlignment(): FormControl {
    return this.goalForm.controls.alignmentId as FormControl;
  }

  get controlGoalPriority(): FormControl {
    return this.goalForm.controls.priority as FormControl;
  }

  get controlGoalTags(): FormControl {
    return this.goalForm.controls.tags as FormControl;
  }

  get controlGoalTasks(): FormArray {
    return this.goalForm.controls.tasks as FormArray;
  }

  get controlGoalDevelopmentNeeds(): FormControl {
    return this.goalForm.controls.developmentNeeds as FormControl;
  }

  get controlGoalSite(): FormControl {
    return this.goalForm.controls.officeLocationId as FormControl;
  }

  get controlGoalDepartment(): FormControl {
    return this.goalForm.controls.departmentId as FormControl;
  }

  get controlGoalDistribution(): FormControl {
    return this.goalForm.controls.distribute as FormControl;
  }
  // #endregion

  constructor(
    public globals: Globals,
    public translateService: TranslateService,
    private notifyUtils: NotifyUtils,
    private swalUtils: SwalUtils
  ) {
    this.state = {
      loading: true,
      error: false,
      errorMessage: ''
    };
    this.submitted = false;
    this.submittedDraft = false;

    this.minDataDate = DateUtils.convertDateToPickerFormat(new Date());
    this.maxLinkedItemDate = null;

    this.dataGoalType = [
      GoalType.PERSONAL_OPERATIONAL,
      GoalType.PERSONAL_DEVELOPMENTAL,
      GoalType.TEAM,
      GoalType.DEPARTMENT,
      GoalType.OFFICE_LOCATION,
      GoalType.COMPANY
    ];
    this.dataGoalPriorities = [
      GoalPriority.NOT_SET,
      GoalPriority.P1,
      GoalPriority.P2,
      GoalPriority.P3,
      GoalPriority.P4,
      GoalPriority.P5
    ];
    this.dropdownFirstToggled = [];
    this.furthestPart = FormPart.TYPE;
    this.furthestPartChange = new EventEmitter<FormPart>();

    this.dropdownMetadata = this.getDropdownMetadata();
  }

  ngOnInit(): void {
    this.initPossibleGoalTypes();
    this.initEventListeners(this.goalForm);
    this.getDateMinimumAndMaximums();
    this.state.loading = false;
  }

  getDateMinimumAndMaximums(): void {
    this.minDataDate = DateUtils.convertDateToPickerFormat(new Date());
    const formDueDate = this.goalForm.controls.endDate.value as Date | null;
    if (formDueDate) {
      const maxDate = new Date(formDueDate);
      maxDate.setHours(0, 0, 0, 0);
      maxDate.setDate(maxDate.getDate() + 1);
      this.maxLinkedItemDate = DateUtils.convertDateToPickerFormat(maxDate);
    } else {
      this.maxLinkedItemDate = null;
    }
  }

  initPossibleGoalTypes(): void {
    if (!this.canSetGoalType(GoalType.OFFICE_LOCATION)) {
      this.dataGoalType = this.dataGoalType.filter(t => t !== GoalType.OFFICE_LOCATION);
    }
    if (!this.canSetGoalType(GoalType.COMPANY)) {
      this.dataGoalType = this.dataGoalType.filter(t => t !== GoalType.COMPANY);
    }
  }

  canSetGoalType(type: GoalType): boolean {
    switch (type) {
      case GoalType.COMPANY:
        return this.globals.hasRole(RoleName.GOAL_COMPANY);
      case GoalType.DEPARTMENT:
        return (!!this.globals.user.organisationalUnit);
      case GoalType.OFFICE_LOCATION:
        return (this.globals.user.officeLocation! && this.globals.hasFeature(CompanyFeatures.GOAL_OFFICE_LOCATION));
      default:
        return true;
    }
  }

  // #region - FORM CHANGE LISTENERS
  initEventListeners(form: FormGroup) {
    form.controls.type.valueChanges.subscribe(type => this.onTypeChanged(type));
    form.controls.title.valueChanges.subscribe(title => this.onTitleChanged(title));
    form.controls.endDate.valueChanges.subscribe(date => this.onDueDateChanged(date));
    form.controls.visibility.valueChanges.subscribe(visibility => this.onVisibilityChanged(visibility));
    form.controls.developmentNeeds.valueChanges.subscribe(developmentNeeds => this.onDevelopmentNeedsChanged(developmentNeeds));
    form.controls.officeLocationId.valueChanges.subscribe(site => this.onSiteChanged(site));
    form.controls.departmentId.valueChanges.subscribe(dept => this.onDepartmentChanged(dept));
    form.controls.keyResults.valueChanges.subscribe(keyResults => this.onKeyResultsChanged(keyResults));
    form.controls.owners.valueChanges.subscribe(owners => this.onOwnersChanged(owners));
    form.controls.distribute.valueChanges.subscribe(distribution => this.onDistributeChanged(distribution));
    form.controls.alignmentId.valueChanges.subscribe(alignedId => this.onAlignmentChanged(alignedId));
    form.controls.priority.valueChanges.subscribe(priority => this.onPriorityChanged(priority));
    form.controls.tags.valueChanges.subscribe(tags => this.onTagsChanged(tags));
    form.controls.tasks.valueChanges.subscribe(tasks => this.onTasksChanged(tasks));
  }

  onTypeChanged(type: GoalType): void {
    this.goalForm.controls.departmentId.setErrors(null);
    this.goalForm.controls.departmentId.setValidators([]);
    this.goalForm.controls.developmentNeeds.setErrors(null);
    this.goalForm.controls.developmentNeeds.setValidators([]);
    this.goalForm.controls.officeLocationId.setErrors(null);
    this.goalForm.controls.officeLocationId.setValidators([]);

    switch (type) {
      case GoalType.DEPARTMENT:
        this.goalForm.controls.departmentId.setValidators([Validators.required]);
        break;
      case GoalType.PERSONAL_DEVELOPMENTAL:
        this.goalForm.controls.developmentNeeds.setValidators([Validators.minLength(GoalCreateValidationLimits.developmentNeeds.min), Validators.maxLength(GoalCreateValidationLimits.developmentNeeds.max)]);
        break;
      case GoalType.OFFICE_LOCATION:
        this.goalForm.controls.officeLocationId.setValidators([Validators.required]);
        break;
    }

    // Set default visibility for selected type
    const visibilityForType = GoalUtils.getDefaultVisibilityForGoalType(type);
    if(this.controlGoalVisibility.value !== visibilityForType) {
      this.controlGoalVisibility.setValue(visibilityForType);
      if (this.modal && this.modal.visible && this.dropdownType.expanded) {
        switch(visibilityForType) {
          case GoalVisibility.PRIVATE:
            this.notifyUtils.notify(GoalsMessages.VISIBILITY_SET_TO_PRIVATE);
            break;
          case GoalVisibility.PUBLIC:
            this.notifyUtils.notify(GoalsMessages.VISIBILITY_SET_TO_PUBLIC);
            break;
        }
      }
    }

    this.goalForm.controls.departmentId.setValue(null);
    this.goalForm.controls.officeLocationId.setValue(null);
    this.goalForm.controls.developmentNeeds.setValue(null);

    this.onFormPartVisited(FormPart.TYPE);

    this.setDropdown(this.dropdownType, false);
    setTimeout(() => {
      this.setDropdown(this.dropdownDetails, true);
    }, 1);
  }

  onTitleChanged(title: string): void {
    this.onFormPartVisited(FormPart.DETAILS);
  }

  onDueDateChanged(date: Date): void {
    const minDate = new Date();
    const maxDate = new Date(date.toString());

    minDate.setHours(0, 0, 0, 0);
    this.minDataDate = DateUtils.convertDateToPickerFormat(minDate);

    if (maxDate) {
      maxDate.setHours(0, 0, 0, 0);
      maxDate.setDate(maxDate.getDate() + 1);
      this.maxLinkedItemDate = DateUtils.convertDateToPickerFormat(maxDate);
    }

    // Update result minimum date
    const resultForms = this.controlGoalKeyResults.controls as FormGroup[];
    resultForms.forEach(form => {
      form.controls.endDate.clearValidators();
      form.controls.endDate.setErrors(null);
      form.controls.endDate.setValidators([FrankliValidators.minDate(minDate), FrankliValidators.maxDate(maxDate)]);
      form.controls.endDate.updateValueAndValidity();
    });

    // Update task minimum date
    const taskForms = this.controlGoalTasks.controls as FormGroup[];
    taskForms.forEach(form => {
      form.controls.dueDate.clearValidators();
      form.controls.dueDate.setErrors(null);
      form.controls.dueDate.setValidators([FrankliValidators.minDate(minDate), FrankliValidators.maxDate(maxDate)]);
      form.controls.dueDate.updateValueAndValidity();
    });

    this.onFormPartVisited(FormPart.DETAILS);
  }

  onVisibilityChanged(visibility: GoalVisibility): void {
    this.onFormPartVisited(FormPart.DETAILS);
  }

  onDevelopmentNeedsChanged(developmentNeeds: string): void {
    if (developmentNeeds) {
      this.onFormPartVisited(FormPart.DEVELOPMENT_NEEDS);
    }
  }

  onSiteChanged(siteId: number): void {
    if (siteId) {
      this.onFormPartVisited(FormPart.SITE);
      if (siteId !== null) {
        this.setDropdown(this.dropdownSite, false);
      }
    }
  }

  onDepartmentChanged(deptId: number): void {
    if (deptId) {
      this.onFormPartVisited(FormPart.DEPARTMENT);
      if (deptId !== null) {
        this.setDropdown(this.dropdownDepartment, false);
      }
    }
  }

  onKeyResultsChanged(keyResults: GoalKeyResult[]): void {
    this.onFormPartVisited(FormPart.KEY_RESULTS);
  }

  onOwnersChanged(owners: UserMinimal[]): void {
    this.onFormPartVisited(FormPart.OWNERS);
  }

  onDistributeChanged(distribution: GoalDistribution): void {
    this.onFormPartVisited(FormPart.OWNERS);

    const warningSubtext = 'Are you sure you want to continue?';

    switch(distribution) {
      case GoalDistribution.COMPANY:
        this.swalUtils.displayWarningConfirmationSwal({
          title: 'This will create a unique goal for each person in the company',
          text: warningSubtext
        }).then(res => {
          if (!res.isConfirmed) {
            this.controlGoalDistribution.setValue(GoalDistribution.NONE);
          }
        });
        break;
      case GoalDistribution.OWNERS:
        this.swalUtils.displayWarningConfirmationSwal({
          title: 'This will create a unique goal for each owner',
          text: warningSubtext
        }).then(res => {
          if (!res.isConfirmed) {
            this.controlGoalDistribution.setValue(GoalDistribution.NONE);
          }
        });
        break;
      case GoalDistribution.NONE:
      default:
        break;
    }
  }

  onAlignmentChanged(alignment: number): void {
    this.onFormPartVisited(FormPart.ALIGNMENT);

    if (alignment !== null) {
      this.setDropdown(this.dropdownAlignment, false);
      setTimeout(() => {
        this.setDropdown(this.dropdownPriority, true);
      }, 1);
    }
  }

  onPriorityChanged(priority: GoalPriority): void {
    this.onFormPartVisited(FormPart.PRIORITY);

    this.setDropdown(this.dropdownPriority, false);
    setTimeout(() => {
      this.setDropdown(this.dropdownTags, true);
    }, 1);
  }

  onTagsChanged(tags: Tag[]): void {
    this.onFormPartVisited(FormPart.TAGS);
  }

  onTasksChanged(tasks: CreateTaskDto[]): void {
    this.onFormPartVisited(FormPart.TASKS);
  }
  // #endregion

  setDropdown(dropdown: DropdownDisplayComponent | undefined, expanded: boolean): void {
    if (dropdown) {
      if (dropdown.expanded !== expanded) {
        dropdown.setExpanded(expanded);
      }
    }
  }

  onToggleDropdown(part: FormPart, expanded: boolean): void {
    const ignore = [FormPart.TYPE, FormPart.DETAILS, FormPart.DEPARTMENT, FormPart.SITE, FormPart.DEVELOPMENT_NEEDS, FormPart.KEY_RESULTS];

    if (!ignore.includes(part)) {
      if (!this.dropdownFirstToggled.includes(part)) {
        this.dropdownFirstToggled.push(part);
        this.onFormPartVisited(part);
      }
    }

    if (expanded) {
      setTimeout(() => {
        this.closeAllOtherDropdowns(part);
        setTimeout(() => this.scrollPartIntoview(part), 100);
      }, 1);
    }

    if (expanded === true && part === FormPart.TAGS && this.controlTags) {
      setTimeout(() => {
        this.controlTags.focusTagPicker();
      }, 1);
    }
  }

  closeAllOtherDropdowns(part: FormPart): void {

    if (part !== FormPart.TYPE) {
      this.setDropdown(this.dropdownType, false);
    }
    if (part !== FormPart.DETAILS) {
      this.setDropdown(this.dropdownDetails, false);
    }
    if (part !== FormPart.DEVELOPMENT_NEEDS) {
      this.setDropdown(this.dropdownDevelopmentNeeds, false);
    }
    if (part !== FormPart.SITE) {
      this.setDropdown(this.dropdownSite, false);
    }
    if (part !== FormPart.DEPARTMENT) {
      this.setDropdown(this.dropdownDepartment, false);
    }
    if (part !== FormPart.KEY_RESULTS) {
      this.setDropdown(this.dropdownKeyResults, false);
    }
    if (part !== FormPart.OWNERS) {
      this.setDropdown(this.dropdownOwners, false);
    }
    if (part !== FormPart.ALIGNMENT) {
      this.setDropdown(this.dropdownAlignment, false);
    }
    if (part !== FormPart.PRIORITY) {
      this.setDropdown(this.dropdownPriority, false);
    }
    if (part !== FormPart.TAGS) {
      this.setDropdown(this.dropdownTags, false);
    }
    if (part !== FormPart.TASKS) {
      this.setDropdown(this.dropdownTasks, false);
    }
  }

  scrollPartIntoview(part: FormPart): void {
    switch (part) {
      case FormPart.TYPE:
        if (this.dropdownTypeContainer) {
          this.dropdownTypeContainer.nativeElement.scrollIntoView();
        }
        break;
      case FormPart.DETAILS:
        if (this.dropdownDetailsContainer) {
          this.dropdownDetailsContainer.nativeElement.scrollIntoView();
        }
        break;
      case FormPart.KEY_RESULTS:
        if (this.dropdownKeyResultsContainer) {
          this.dropdownKeyResultsContainer.nativeElement.scrollIntoView();
        }
        break;
      case FormPart.OWNERS:
        if (this.dropdownOwnersContainer) {
          this.dropdownOwnersContainer.nativeElement.scrollIntoView();
        }
        break;
      case FormPart.ALIGNMENT:
        if (this.dropdownAlignmentContainer) {
          this.dropdownAlignmentContainer.nativeElement.scrollIntoView();
        }
        break;
      case FormPart.PRIORITY:
        if (this.dropdownPriorityContainer) {
          this.dropdownPriorityContainer.nativeElement.scrollIntoView();
        }
        break;
      case FormPart.TAGS:
        if (this.dropdownTagsContainer) {
          this.dropdownTagsContainer.nativeElement.scrollIntoView();
        }
        break;
      case FormPart.TASKS:
        if (this.dropdownTasksContainer) {
          this.dropdownTasksContainer.nativeElement.scrollIntoView();
        }
        break;
    }
  }
  
  getIndexForFormPart(part: FormPart | undefined) {
    if (!part) {
      return -1;
    }

    return Object.keys(FormPart).findIndex(fp => (fp === part.toString()));
  }

  checkIfFormPartIsBeforeOther(partChecking: FormPart | undefined, partLimit: FormPart): boolean {
    if (partChecking === undefined) {
      return false;
    }

    return (this.getIndexForFormPart(partChecking) >= this.getIndexForFormPart(partLimit));
  }

  onFormPartVisited(part: FormPart): void {
    const currentFurthestIndex = this.getIndexForFormPart(this.furthestPart);
    const newFurthestIndex = this.getIndexForFormPart(part);

    if (newFurthestIndex > currentFurthestIndex) {
      this.furthestPart = part;
      this.furthestPartChange.emit(part);
    }
  }

  getDropdownMetadata(): IDropdownMetadata {
    // MOTE: The minHeights here are set in refreshDropdownPreviews() above
    return {
      [FormPart.TYPE]: {
        title: GoalsMessages.GOAL_CREATE_TYPE_TITLE,
        description: GoalsMessages.GOAL_CREATE_TYPE_DESCRIPTION
      },
      [FormPart.DETAILS]: {
        title: GoalsMessages.GOAL_CREATE_DETAILS_TITLE,
        description: GoalsMessages.GOAL_CREATE_DETAILS_DESCRIPTION
      },
      [FormPart.DEVELOPMENT_NEEDS]: {
        title: GoalsMessages.GOAL_CREATE_DEVELOPMENT_NEEDS_TITLE,
        description: GoalsMessages.GOAL_CREATE_DEVELOPMENT_NEEDS_DESCRIPTION
      },
      [FormPart.SITE]: {
        title: this.translateService.instant(GoalsMessages.GOAL_CREATE_SITE_TITLE,  { site: this.globals.getTerminology(TerminologyEntity.SITE) }),
        description: this.translateService.instant(GoalsMessages.GOAL_CREATE_SITE_DESCRIPTION,  { site: this.globals.getTerminology(TerminologyEntity.SITE) })
      },
      [FormPart.DEPARTMENT]: {
        title: this.translateService.instant(GoalsMessages.GOAL_CREATE_DEPARTMENT_TITLE,  { departmentWording: this.globals.getTerminology(TerminologyEntity.DEPARTMENT) }),
        description: this.translateService.instant(GoalsMessages.GOAL_CREATE_DEPARTMENT_DESCRIPTION,  { departmentWording: this.globals.getTerminology(TerminologyEntity.DEPARTMENT) })
      },
      [FormPart.KEY_RESULTS]: {
        title: GoalsMessages.GOAL_CREATE_KEY_RESULTS_TITLE,
        description: GoalsMessages.GOAL_CREATE_KEY_RESULTS_DESCRIPTION
      },
      [FormPart.OWNERS]: {
        title: GoalsMessages.GOAL_CREATE_OWNERS_AND_DISTRIBUTION_TITLE,
        description: GoalsMessages.GOAL_CREATE_OWNERS_DESCRIPTION
      },
      [FormPart.ALIGNMENT]: {
        title: GoalsMessages.GOAL_CREATE_ALIGNMENT_TITLE,
        description: GoalsMessages.GOAL_CREATE_ALIGNMENT_DESCRIPTION
      },
      [FormPart.PRIORITY]: {
        title: GoalsMessages.GOAL_CREATE_PRIORITY_TITLE,
        description: GoalsMessages.GOAL_CREATE_PRIORITY_DESCRIPTION
      },
      [FormPart.TAGS]: {
        title: GoalsMessages.GOAL_CREATE_TAGS_TITLE,
        description: GoalsMessages.GOAL_CREATE_TAGS_DESCRIPTION
      },
      [FormPart.TASKS]: {
        title: GoalsMessages.GOAL_CREATE_TASKS_TITLE,
        description: GoalsMessages.GOAL_CREATE_TASKS_DESCRIPTION
      }
    };
  }

  scrollToFirstInvalidDropdown(): void {
    // Type
    if (this.controlGoalType.invalid) {
      this.expandAndFocusPart(FormPart.TYPE);
      return;
    }

    // Details
    if (this.controlGoalTitle.invalid || this.controlGoalEndDate.invalid || this.controlGoalVisibility.invalid) {
      this.expandAndFocusPart(FormPart.DETAILS);
      return;
    }

    // Type specific form parts
    switch (this.controlGoalType.value) {
      case GoalType.PERSONAL_DEVELOPMENTAL:
        if (this.controlGoalDevelopmentNeeds.invalid) {
          this.expandAndFocusPart(FormPart.DEVELOPMENT_NEEDS);
          return;
        }
        break;
      case GoalType.DEPARTMENT:
        if (this.controlGoalDepartment.invalid) {
          this.expandAndFocusPart(FormPart.DEPARTMENT);
          return;
        } 
        break;
      case GoalType.OFFICE_LOCATION:
        if (this.controlGoalSite.invalid) {
          this.expandAndFocusPart(FormPart.SITE);
          return;
        } 
        break;
    }

    // Key Results
    if (this.controlGoalKeyResults.invalid) {
      this.expandAndFocusPart(FormPart.KEY_RESULTS);
      return;
    }

    // Owners
    if (this.controlGoalOwners.invalid) {
      this.expandAndFocusPart(FormPart.OWNERS);
      return;
    }

    // Alignment
    if (this.controlGoalAlignment.invalid) {
      this.expandAndFocusPart(FormPart.ALIGNMENT);
      return;
    }

    // Priority
    if (this.controlGoalPriority.invalid) {
      this.expandAndFocusPart(FormPart.PRIORITY);
      return;
    }

    // Tags
    if (this.controlGoalTags.invalid) {
      this.expandAndFocusPart(FormPart.TAGS);
      return;
    }

    // Tasks
    if (this.controlGoalTasks.invalid) {
      this.expandAndFocusPart(FormPart.TASKS);
      return;
    }
  }

  scrollToFirstInvalidDraftDropdown(): void {
    // Key Results
    if (this.controlGoalKeyResults.invalid) {
      this.expandAndFocusPart(FormPart.KEY_RESULTS);
      return;
    }
  }

  setDropdownForPart(part: FormPart, expanded: boolean): void {
    const dropdownElement = this.getDropdownRefForPart(part);
    this.setDropdown(dropdownElement, expanded);
  }

  expandAndFocusPart(part: FormPart): void {
    this.setDropdownForPart(part, true);
    setTimeout(() => this.scrollPartIntoview(part), 250);
  }

  expandAndHighlightPart(part: FormPart): void {
    this.expandAndFocusPart(part);
    setTimeout(() => {
      this.highlightControlForPart(part);
    }, 500);
  }

  highlightControlForPart(part: FormPart): void {
    const dropdownElement = this.getDropdownRefForPart(part);
    if (!dropdownElement) { return; }

    const controlElement = this.getControlRefForPart(part);
    if (!controlElement) { return; }

    controlElement.focus();
  }

  getDropdownRefForPart(part: FormPart): DropdownDisplayComponent {
    switch (part) {
      case FormPart.TYPE:
        return this.dropdownType;
      case FormPart.SITE:
        return this.dropdownSite;
      case FormPart.DEPARTMENT:
        return this.dropdownDepartment;
      case FormPart.DEVELOPMENT_NEEDS:
        return this.dropdownDevelopmentNeeds;
      case FormPart.DETAILS:
        return this.dropdownDetails;
      case FormPart.KEY_RESULTS:
        return this.dropdownKeyResults;
      case FormPart.OWNERS:
        return this.dropdownOwners;
      case FormPart.ALIGNMENT:
        return this.dropdownAlignment;
      case FormPart.PRIORITY:
        return this.dropdownPriority;
      case FormPart.TAGS:
        return this.dropdownTags;
      case FormPart.TASKS:
        return this.dropdownTasks;
    }
  }

  getControlRefForPart(part: FormPart) {
    switch (part) {
      case FormPart.TYPE:
        return this.controlType;
      case FormPart.SITE:
        return this.controlSite;
      case FormPart.DEPARTMENT:
        return this.controlDepartment;
      case FormPart.DEVELOPMENT_NEEDS:
        return this.controlDevelopmentNeeds;
      case FormPart.DETAILS:
        return this.controlDetails;
      case FormPart.KEY_RESULTS:
        return this.controlKeyResults;
      case FormPart.OWNERS:
        return this.controlOwners;
      case FormPart.ALIGNMENT:
        return this.controlAlignment;
      case FormPart.PRIORITY:
        return this.controlPriority;
      case FormPart.TAGS:
        return this.controlTags;
      case FormPart.TASKS:
        return this.controlTasks;
    }
  }
}
