<ng-container *ngIf="meeting">
  <div class="meeting-item" [class.is-selected]="isSelected" [class.is-highlighted]="isHighlighted" [class.is-dimmed]="isDimmed" [ngClass]="'meeting-status-' + meeting.status" (click)="meetingClicked()">
    <!-- <div>{{meeting.id}}</div> -->
    <ng-container *ngIf="schedule.frequency !== eOneToOneFrequency.Flexible; else templateMeetingFlexible;">
      <span>{{meeting.meetingTimestamp | momentPipe: 'D MMM YYYY'}}</span>
      <span>,&nbsp;</span>
      <span>{{meeting.meetingTimestamp | momentPipe: 'h:mma'}}</span>
    </ng-container>
    <ng-template #templateMeetingFlexible>
      <ng-container *ngIf="meeting.completedTimestamp; else templateMeetingFlexibleIncomplete;">
        <span>{{meeting.completedTimestamp | momentPipe: 'D MMM YYYY'}}</span>
        <span>,&nbsp;</span>
        <span>{{meeting.completedTimestamp | momentPipe: 'h:mma'}}</span>
      </ng-container>
      <ng-template #templateMeetingFlexibleIncomplete>
        <span>{{eOneToOneMessages.FLEXIBLE_MEETING | translate}}</span>
      </ng-template>
    </ng-template>

    <span class="m-right-10"></span>
    <span class="far fa-fw" [ngClass]="meetingStatusData.iconClass" [class.schedule-not-active]="schedule.status !== eOneToOneStatus.ACTIVE" [tooltip]="meetingStatusData.tooltipText | translate" placement="top" [triggers]="globals.tooltipTriggers"></span>
  </div>
</ng-container>
