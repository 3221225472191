import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { EvaluationCycle } from 'app/models/evaluation/evaluation-cycle.model';
import { Observable } from 'rxjs';
import { EvaluationFeedbackRequest } from 'app/models/evaluation/evaluation-feedback-request.model';
import { EvaluationFeedback } from 'app/models/evaluation/evaluation-feedback.model';
import { SelfReflection } from 'app/models/evaluation/self-reflection.model';
import { EvaluationUser, EvaluationUserMin } from 'app/models/evaluation/evaluation-user.model';
import { EvaluationCycleUserOverview } from 'app/models/evaluation/evaluation-cycle-user-overview.model';
import {  map } from 'rxjs/operators';
import { Goal } from 'app/models/goals/goal.model';
import { EvaluationFeedbackCalibration } from 'app/models/evaluation/evaluation-feedback-calibration.model';
import { ManagerReviewAccess } from 'app/models/evaluation/manager-review-access.model';
import { EvaluationCycleDirectReport } from '@app/models/evaluation/evaluation-cycle-direct-report.model';
import { AssessmentGroupInfo } from 'app/models/evaluation/assessment-group-info.model';
import { EvaluationCycleService } from '@app/shared/api/interfaces/evaluation-cycle.service';
import { ReviewUnsubmitListInfo } from 'app/models/evaluation/review-unsubmit-list-info.model';
import { EvaluationAdminActivity } from 'app/models/evaluation/evaluation-admin-action.model';
import { PagingParams } from '@app/models/api/paging-params.model';
import { Page } from '@app/models/api/page.model';
import { ApiUtils } from '@app/shared/utils/api.utils';
import { ParentFilter } from '@models/api/parent-filter.model';
import { staticUserSortingParams } from 'app/shared/utils/api-filter/static-user-params';
import { PeerReviewAccess } from '@app/models/evaluation/peer-review-access.model';
import { UserMinimal } from '@app/models/user/user-minimal.model';
import { ReviewStepSummary } from '@models/evaluation/peer-review-summary/review-step-summary.model';
import { CreateReviewStepSummary } from '@models/evaluation/peer-review-summary/create-review-step-summary.model';
import { UpwardReviewAccess } from '@app/models/evaluation/upward-review-access.model';
import moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export class EvaluationCycleAPIService implements EvaluationCycleService {
  baseURL = '/api/evaluation';
  httpHeaders: HttpHeaders;

  constructor(
      private http: HttpClient,
  ) {
    this.httpHeaders = new HttpHeaders();
    this.httpHeaders.append('Content-Type', 'application/json');
  }

  parseCycleMultiple(cycles: EvaluationCycle[]): EvaluationCycle[] {
    return cycles.map(c => this.parseCycle(c));
  }

  parseCycle(cycle: EvaluationCycle): EvaluationCycle {
    const timezoneOffsetMinutes = moment().utcOffset();
    const output = JSON.parse(JSON.stringify(cycle));

    if (output) {
      output.modules = output.modules.map(cm => {
        cm.startDateTime = moment(cm.startDateTime).subtract(timezoneOffsetMinutes, 'minutes').toDate();
        cm.endDateTime = moment(cm.endDateTime).subtract(timezoneOffsetMinutes, 'minutes').toDate();
        return cm;
      });
    }

    return output;
  }

  /**
   * Admin saves review cycle
   * - POST to /evaluation with EvaluationCycleDto
   * @param cycle NewEvaluationCycle
   * @param draft boolean
   */
  saveReviewCycle(cycle: EvaluationCycle, draft: boolean): Observable<EvaluationCycle> {
    const url = `${this.baseURL}?draft=${draft}`;
    return this.http.post<EvaluationCycle>(url, cycle).pipe(map(c => this.parseCycle(c)));
  }

  /**
   * Admin edits review cycle
   * - PUT to /evaluation/:id with EvaluationCycleDto
   * @param cycle EvaluationCycle
   */
  editReviewCycle(cycle: EvaluationCycle): Observable<EvaluationCycle> {
    const url = `${this.baseURL}/${cycle.id}`;
    return this.http.put<EvaluationCycle>(url, cycle).pipe(map(c => this.parseCycle(c)));
  }

  // - Admin closes review cycle
  // # POST to /evaluation/:id/close with EvaluationCycleDto
  closeReviewCycle(cycle: EvaluationCycle): Observable<EvaluationCycle> {
    const url = `${this.baseURL}/${cycle.id}/close`;
    return this.http.post<EvaluationCycle>(url, cycle).pipe(map(c => this.parseCycle(c)));
  }

  /**
   * - gets cycle by Id
   * /evaluation/:id GET
   * @param id Cycle Id
   */
  getEvaluationCycleById(id: number): Observable<EvaluationCycle> {
    const url = `${this.baseURL}/${id}`;
    return this.http.get<EvaluationCycle>(url).pipe(map(c => this.parseCycle(c)));
  }

  // - gets cycles for company
  // # /evaluation/company GET
  getEvaluationCyclesForCompany(): Observable<Array<EvaluationCycle>> {
    const url = `${this.baseURL}/company`;
    return this.http.get<Array<EvaluationCycle>>(url).pipe(map(c => this.parseCycleMultiple(c)));
  }

  // - gets cycles for company
  // # /evaluation/company GET
  getCompletedEvaluationCyclesForCompany(): Observable<Array<EvaluationCycle>> {
    const url = `${this.baseURL}/company/completed`;
    return this.http.get<EvaluationCycle[]>(url).pipe(map(c => this.parseCycleMultiple(c)));
  }

  // - gets cycles for user
  // # /evaluation/cycle/user/{id} GET
  getEvaluationCycleForUserId(userId: number): Observable<EvaluationCycle> {
    const url = `${this.baseURL}/cycle/user/${userId}`;
    return this.http.get<EvaluationCycle>(url).pipe(map(c => this.parseCycle(c)));
  }

  // - gets all cycles for user
  // # /evaluation/cycle/user/{id}/all GET
  getAllEvaluationCyclesForUserId(userId: number): Observable<Array<EvaluationCycle>> {
    const url = `${this.baseURL}/cycle/user/${userId}/all`;
    return this.http.get<Array<EvaluationCycle>>(url).pipe(map(c => this.parseCycleMultiple(c)));
  }

  // - gets users for cycle
  // # /evaluation/{cycleId}/users GET
  getEvaluationUsersForCycle(cycleId: number): Observable<Array<EvaluationUser>> {
    const url = `${this.baseURL}/${cycleId}/users`;
    return this.http.get<Array<EvaluationUser>>(url);
  }
  // // - gets users for cycle -- keeping old version
  // // # /evaluation/{cycleId}/users GET
  // getEvaluationUsersMinForCycle(cycleId: number): Observable<Array<EvaluationUserMin>> {
  //   const url = `${this.baseURL}/${cycleId}/users/min`;
  //   return this.http.get<Array<EvaluationUserMin>>(url);
  // }
  // - gets users for cycle
  // # /evaluation/{cycleId}/users/min GET
  getEvaluationUsersMinForCycle(cycleId: number, pagingParams: PagingParams): Observable<Page<EvaluationUserMin>> {
    const url = `${this.baseURL}/${cycleId}/users/min`;
    const params = ApiUtils.createPageParams(pagingParams);
    return this.http.get<Page<EvaluationUserMin>>(url, {params});
  }
  getEvaluationUsersMinCompleteForCycle(cycleId: number, pagingParams: PagingParams): Observable<Page<EvaluationUserMin>> {
    const url = `${this.baseURL}/${cycleId}/users/min/complete`;
    const params = ApiUtils.createPageParams(pagingParams);
    return this.http.get<Page<EvaluationUserMin>>(url, {params});
  }
  getEvaluationUsersMinInProgressForCycle(cycleId: number, pagingParams: PagingParams): Observable<Page<EvaluationUserMin>> {
    const url = `${this.baseURL}/${cycleId}/users/min/in-progress`;
    const params = ApiUtils.createPageParams(pagingParams);
    return this.http.get<Page<EvaluationUserMin>>(url, {params});
  }
  getEvaluationUsersMinNotStartedForCycle(cycleId: number, pagingParams: PagingParams): Observable<Page<EvaluationUserMin>> {
    const url = `${this.baseURL}/${cycleId}/users/min/not-started`;
    const params = ApiUtils.createPageParams(pagingParams);
    return this.http.get<Page<EvaluationUserMin>>(url, {params});
  }

  searchCycleForEvaluationUsersByName(cycleId: number, searchArgs: string, pagingParams: PagingParams): Observable<Page<EvaluationUserMin>> {
    const url = `${this.baseURL}/${cycleId}/users/min/search`;
    const params = new HttpParams()
      .append('pageNumber', String(pagingParams.pageNumber))
      .append('pageSize', String(pagingParams.pageSize))
      .append('q', searchArgs);
    return this.http.get<Page<EvaluationUserMin>>(url, {params});
  }
  // - gets users for cycle that i manage
  // # /evaluation/{cycleId}/users GET
  getEvaluationCycleDirectReportOverview(cycleId: number, userId: number): Observable<EvaluationCycleDirectReport> {
    const url = `${this.baseURL}/${cycleId}/users/managed/${userId}`;
    return this.http.get<EvaluationCycleDirectReport>(url);
  }

  // - gets evaluation user for cycle
  // # /evaluation/{cycleId}/user/{userId} GET
  getEvaluationUserByCycleIdAndUserId(cycleId: number, userId: number): Observable<EvaluationUser> {
    const url = `${this.baseURL}/${cycleId}/user/${userId}`;
    return this.http.get<EvaluationUser>(url);
  }

  // - gets past answers for user
  // # /evaluation/user/:id GET
  getEvaluationFeedbackFromMeForUserIdInActiveCycle(id: number): Observable<EvaluationFeedback> {
    const url = `${this.baseURL}/user/${id}`;
    return this.http.get<EvaluationFeedback>(url);
  }

  // - delete evaluation by id
  // # /self/{id} DELETE
  archiveSelfReflectionQuestion(id: number): Observable<null> {
    const url = `${this.baseURL}/self-question/${id}`;
    return this.http.delete<null>(url);
  }

  // - submit a new answer for user
  // # /evaluation/user/:id POST
  createEvaluationFeedbackForUser(id: number, feedback: EvaluationFeedback): Observable<EvaluationFeedback> {
    const url = `${this.baseURL}/user/${id}`;
    return this.http.post<EvaluationFeedback>(url, feedback);
  }

  // - updating past answer for user
  // # /evaluation/user/:id PUT
  editEvaluationFeedbackForUser(id: number, feedback: EvaluationFeedback, autosave: boolean): Observable<EvaluationFeedback> {
    const url = `${this.baseURL}/user/${id}?autosave=${autosave}`;
    return this.http.put<EvaluationFeedback>(url, feedback);
  }

  // - complete answer for user
  // # /evaluation/user/:id/complete POST
  completeEvaluationFeedbackByRequestId(requestId: number, feedback: EvaluationFeedback): Observable<EvaluationFeedback> {
    const url = `${this.baseURL}/request/${requestId}/complete`;
    return this.http.post<EvaluationFeedback>(url, feedback);
  }

  // - Request peer feedback for a direct report
  // # /evaluation/user/:id/request POST
  nominateUserForCycle(cycleId: number, subjectUserId: number, authorUserId: number) {
    const url = `${this.baseURL}/${cycleId}/user/${subjectUserId}/nominate`;
    return this.http.post<EvaluationFeedbackRequest>(url, authorUserId);
  }

  // #region - self

  // - Gets past self reflections
  // # /evaluation/self/past GET
  getSelfReflectionForCycleAndUser(cycleId: number, userId: number) {
    const url = `${this.baseURL}/${cycleId}/self/${userId}`;
    return this.http.get<SelfReflection>(url);
  }

  getSelfReflectionForSelf() {
    const url = `${this.baseURL}/self`;
    return this.http.get<SelfReflection>(url);
  }

  // - Gets past self reflections
  // # /evaluation/self/past GET
  getPastSelfReflections() {
    const url = `${this.baseURL}/self/past`;
    return this.http.get<Array<SelfReflection>>(url);
  }

  // - Gets past answers for self reflection
  // # /evaluation/self GET
  getAnswersForSelfReflection(): Observable<SelfReflection | null> {
    const url = `${this.baseURL}/self`;
    return this.http.get<SelfReflection>(url);
  }

  // - Update past answers for self reflection
  // # /evaluation/self PUT
  editSelfReflection(answers: SelfReflection, autosave: boolean) {
    const url = `${this.baseURL}/self?autosave=${autosave}`;
    return this.http.put<SelfReflection>(url, answers);
  }

  // - Post answers for self reflection
  // # /evaluation/self POST
  createSelfReflection(answers: SelfReflection) {
    const url = `${this.baseURL}/self`;
    return this.http.post<SelfReflection>(url, answers);
  }

  // - Post answers for self reflection
  // # /evaluation/self POST
  completeSelfReflection(answers: SelfReflection): Observable<SelfReflection> {
    const url = `${this.baseURL}/self/complete`;
    return this.http.post<SelfReflection>(url, answers);
  }

  // #endregion

  // #region - requests

  // - Get user feedback requests for user
  // # /evaluation/user/:id/requests GET
  getRequestsForUserEvaluation(id: number): Observable<EvaluationFeedbackRequest[]> {
    const url = `${this.baseURL}/user/${id}/requests`;
    return this.http.get<EvaluationFeedbackRequest[]>(url);
  }

  getEvaluationFeedbackRequestsForUsersByManagerMeByCycleId(cycleId: number): Observable<EvaluationFeedbackRequest[]> {
    const url = `${this.baseURL}/${cycleId}/requests/managed`;
    return this.http.get<EvaluationFeedbackRequest[]>(url);
  }

  getEvaluationFeedbackRequestsForUsersByManagerMe(): Observable<EvaluationFeedbackRequest[]> {
    const url = `${this.baseURL}/requests/managed`;
    return this.http.get<EvaluationFeedbackRequest[]>(url);
  }

  // - Get user feedback requests sent 2 user
  // # /evaluation/user/:id/requests/received GET
  getEvaluationFeedbackRequestsSentToUser(id: number): Observable<EvaluationFeedbackRequest[]> {
    const url = `${this.baseURL}/user/${id}/requests/received`;
    return this.http.get<EvaluationFeedbackRequest[]>(url);
  }

  // - Get user feedback requests sent 2 user
  // # /evaluation/user/:id/requests/received GET
  getEvaluationFeedbackRequestsSentToUserWhereSRCompleted(id: number): Observable<EvaluationFeedbackRequest[]> {
    const url = `${this.baseURL}/user/${id}/requests/received/sr-completed`;
    return this.http.get<EvaluationFeedbackRequest[]>(url);
  }

  // - Approve a request for feedback
  // # /evaluation/request/:reqId/approve POST
  approveEvaluationRequest(id: number): Observable<EvaluationFeedbackRequest> {
    const url = `${this.baseURL}/request/${id}/approve`;
    return this.http.get<EvaluationFeedbackRequest>(url);
  }

  // - Decline a request for feedback
  // # /evaluation/request/:reqId/decline POST
  declineEvaluationRequest(id: number, reason: string): Observable<EvaluationFeedbackRequest> {
    const url = `${this.baseURL}/request/${id}/decline`;
    return this.http.post<EvaluationFeedbackRequest>(url, reason);
  }

  // #endregion
  // - Check Can Complete USERS
  // # /{cycleId}/complete/users GET
  getCycleUsersCompleted(id: number): Observable<number[]> {
    const url = `${this.baseURL}/${id}/complete/users`;
    return this.http.get<number[]>(url);
  }

  getCycleUsersStatesCount(id: number): Observable<number[]> {
    const url = `${this.baseURL}/${id}/state/users/count`;
    return this.http.get<number[]>(url);
  }

  // - gets cycles for company that have users i manage in them
  // # /evaluation/company/managed GET
  getEvaluationCyclesForCompanyManaged(): Observable<Array<EvaluationCycle>> {
    const url = `${this.baseURL}/company/managed`;
    return this.http.get<Array<EvaluationCycle>>(url).pipe(map(c => this.parseCycleMultiple(c)));
  }

  // - delete cycle by id
  // # /{id} DELETE
  deleteEvaluationCycle(id: number): Observable<null> {
    const url = `${this.baseURL}/${id}`;
    return this.http.delete<null>(url);
  }

  createEvaluationCalibration(cycleID: number, calibration: EvaluationFeedbackCalibration) {
    if (calibration.evaluationFeedbackId) {
      return this.createEvaluationFeedbackCalibration(cycleID, calibration);
    }

    if (calibration.selfReflectionId) {
      return this.createSelfReflectionCalibration(cycleID, calibration);
    }

    console.error('No valid object ID provided for calibration');
    return undefined;
  }

  createEvaluationFeedbackCalibration(evaluationCycleId: number, evaluationFeedbackCalibration: EvaluationFeedbackCalibration): Observable<EvaluationFeedbackCalibration> {
    const url = `${this.baseURL}/${evaluationCycleId}/review/${evaluationFeedbackCalibration.evaluationFeedbackId}/calibration`;
    return this.http.post<EvaluationFeedbackCalibration>(url, evaluationFeedbackCalibration);
  }

  createSelfReflectionCalibration(evaluationCycleId: number, evaluationFeedbackCalibration: EvaluationFeedbackCalibration): Observable<EvaluationFeedbackCalibration> {
    const url = `${this.baseURL}/${evaluationCycleId}/reflection/${evaluationFeedbackCalibration.selfReflectionId}/calibration`;
    return this.http.post<EvaluationFeedbackCalibration>(url, evaluationFeedbackCalibration);
  }

  getEvaluationCycleUserOverview(userId: number, cycleId: number): Observable<EvaluationCycleUserOverview> {
    const url = `${this.baseURL}/${cycleId}/user-overview/${userId}`;
    return this.http.get<EvaluationCycleUserOverview>(url).pipe(map(e => {
      if (e.goals !== null) {
        e.goals = Goal.getGoalArrayCompletionPercentage(e.goals);
      }
      return e;
    }));
  }

  getEvaluationCyclesReport(ids: Array<number>) {
    const url = `${this.baseURL}/export`;
    return this.http.post(url, {ids}, {responseType: 'blob'});
  }

  downloadUserPDF(cycleId: number, userId: number, fileName: string) {
    const url = `${this.baseURL}/${cycleId}/user/${userId}/${fileName}.pdf`;
    return this.http.get(url, {responseType: 'blob'});
  }

  // Unlock several reviews at once
  unlockManagerReviewAccessBulk(evaluationCycleId: number, subjectIds: number[]): Observable<ManagerReviewAccess[]> {
    const url = `${this.baseURL}/${evaluationCycleId}/reviews/unlock`;
    return this.http.put<ManagerReviewAccess[]>(url, subjectIds);
  }

  // after the unlock, manager can use this to share with their direct
  shareManagerReviewAccess(evaluationCycleId: number, evaluationFeedbackId: number): Observable<ManagerReviewAccess> {
    const url = `${this.baseURL}/${evaluationCycleId}/review/${evaluationFeedbackId}/share`;
    return this.http.put<ManagerReviewAccess>(url, {});
  }

  // use this for new manager review view for the user themselves so they see calibrated score only
  getManagerReviewForUserMe(evaluationCycleId: number): Observable<EvaluationFeedback> {
    const url = `${this.baseURL}/${evaluationCycleId}/review/me`;
    return this.http.get<EvaluationFeedback>(url);
  }

  // use this for new manager review view for the user themselves so they see calibrated score only
  getManagerReviewForUserMeById(evaluationCycleId: number, managerReviewId: number): Observable<EvaluationFeedback> {
    const url = `${this.baseURL}/${evaluationCycleId}/review/${managerReviewId}`;
    return this.http.get<EvaluationFeedback>(url);
  }

  // use this for new manager review view for the user themselves so they see calibrated score only
  getUsersWithCompletedLockedManagerReviewsInCycle(evaluationCycleId: number): Observable<number[]> {
    const url = `${this.baseURL}/${evaluationCycleId}/locked-manager-reviews/users`;
    return this.http.get<number[]>(url);
  }

  getAssessmentGroupInfoForCycle(cycleId: number): Observable<Array<AssessmentGroupInfo>> {
    const url = '/api/assessment-group-info';
    let params = new HttpParams();
    if (cycleId) {
      params = params.append('cycleId', String(cycleId));
    }
    return this.http.get<Array<AssessmentGroupInfo>>(url, {params: params});
  }

  addAssessmentGroupInfoByCycle(assessmentGI: AssessmentGroupInfo[], cycleId: number): Observable<AssessmentGroupInfo[]> {
    const url = `/api/assessment-group-info/${cycleId}/add-all`;
    return this.http.post<AssessmentGroupInfo[]>(url, assessmentGI);
  }

  extendCycle(evaluationCycleId: number, newEndDate: Date): Observable<EvaluationCycle> {
    const url = `${this.baseURL}/${evaluationCycleId}/extend`;
    return this.http.put<EvaluationCycle>(url, newEndDate).pipe(map(c => this.parseCycle(c)));
  }

  unsubmitManagerReviews(reviewUnsubmitListInfo: ReviewUnsubmitListInfo): Observable<Array<EvaluationFeedback>> {
    const url = '/api/evaluation-admin/unsubmit/manager';
    return this.http.put<Array<EvaluationFeedback>>(url, reviewUnsubmitListInfo);
  }

  getActivityForCycle(evaluationCycleId: number): Observable<Array<EvaluationAdminActivity>> {
    const url = `${this.baseURL}/${evaluationCycleId}/activity`;
    return this.http.get<Array<EvaluationAdminActivity>>(url);
  }

  getMostRecentActivityForEvaluationRequest(evaluationCycleId: number, evaluationFeedbackId: number): Observable<EvaluationAdminActivity> {
    const url = `${this.baseURL}/${evaluationCycleId}/activity/evaluation-request/${evaluationFeedbackId}`;
    return this.http.get<EvaluationAdminActivity>(url);
  }

  unsubmitSelfReflections(reviewUnsubmitListInfo: ReviewUnsubmitListInfo): Observable<Array<SelfReflection>> {
    const url = '/api/evaluation-admin/unsubmit/self-reflection';
    return this.http.put<Array<SelfReflection>>(url, reviewUnsubmitListInfo);
  }

  getMostRecentActivityForSelfReflection(evaluationCycleId: number, selfReflectionId: number): Observable<EvaluationAdminActivity> {
    const url = `${this.baseURL}/${evaluationCycleId}/activity/self-reflection/${selfReflectionId}`;
    return this.http.get<EvaluationAdminActivity>(url);
  }

  unsubmitPeerReviews(reviewUnsubmitListInfo: ReviewUnsubmitListInfo): Observable<Array<EvaluationFeedback>> {
    const url = '/api/evaluation-admin/unsubmit/peer-review';
    return this.http.put<Array<EvaluationFeedback>>(url, reviewUnsubmitListInfo);
  }

  getSelfReflectionAssessmentGroupAnswersForCycle(cycleId: number): Observable<number[]> {
    const url = `${this.baseURL}/${cycleId}/assessment-group-scores/self-reflection`;
    return this.http.get<number[]>(url);
  }

  getManagerReviewAssessmentGroupAnswersForCycle(cycleId: number): Observable<number[]> {
    const url = `${this.baseURL}/${cycleId}/assessment-group-scores/manager-review`;
    return this.http.get<number[]>(url);
  }

  getUpwardReviewAssessmentGroupAnswersForCycle(cycleId: number): Observable<number[]> {
    const url = `${this.baseURL}/${cycleId}/assessment-group-scores/upward-review`;
    return this.http.get<number[]>(url);
  }

  getEvaluationUsersMinByFilters(cycleId: number, pagingParams: PagingParams, parentFilter: ParentFilter): Observable<Page<EvaluationUserMin>> {
    const url = `${this.baseURL}/${cycleId}/users/min/filtered`;
    const params = ApiUtils.createPageAndSortParams(pagingParams, staticUserSortingParams);
    return this.http.post<Page<EvaluationUserMin>>(url, parentFilter, {params});
  }

  getEvaluationUsersMinManagedByFilters(cycleId: number, pagingParams: PagingParams, parentFilter: ParentFilter): Observable<Page<EvaluationUserMin>> {
    const url = `${this.baseURL}/${cycleId}/managed/min/filtered`;
    const params = ApiUtils.createPageAndSortParams(pagingParams, staticUserSortingParams);
    return this.http.post<Page<EvaluationUserMin>>(url, parentFilter, {params});
  }

  // PEER SUMMARY
  createPeerReviewSummaryForUserAndCycle(cycleId: number, userId: number, peerReviewSummary: CreateReviewStepSummary): Observable<ReviewStepSummary> {
    const url = `/api/peer-review-summary/${cycleId}/${userId}`;
    return this.http.post<ReviewStepSummary>(url, peerReviewSummary);
  }

  updatePeerReviewSummaryForUserAndCycle(cycleId: number, userId: number, peerReviewSummary: CreateReviewStepSummary): Observable<ReviewStepSummary> {
    const url = `/api/peer-review-summary/${cycleId}/${userId}`;
    return this.http.put<ReviewStepSummary>(url, peerReviewSummary);
  }

  sharePeerReviewSummaryForUserAndCycle(cycleId: number, userId: number, peerReviewSummary: CreateReviewStepSummary): Observable<ReviewStepSummary> {
    const url = `/api/peer-review-summary/${cycleId}/${userId}/share`;
    return this.http.post<ReviewStepSummary>(url, peerReviewSummary);
  }

  getPeerReviewSummaryForMeInCycle(cycleId: number): Observable<ReviewStepSummary> {
    const url = `/api/peer-review-summary/${cycleId}/me`;
    return this.http.get<ReviewStepSummary>(url);
  }

  getPeerReviewSummaryForUserInCycle(cycleId: number, userId: number): Observable<ReviewStepSummary> {
    const url = `/api/peer-review-summary/${cycleId}/${userId}`;
    return this.http.get<ReviewStepSummary>(url);
  }

  getPeerReviewSummaryForUserInCycleAsAdmin(cycleId: number, userId: number): Observable<ReviewStepSummary> {
    const url = `/api/peer-review-summary/${cycleId}/${userId}/admin`;
    return this.http.get<ReviewStepSummary>(url);
  }

  // UPWARD SUMMARY
  createUpwardReviewSummaryForUserAndCycle(cycleId: number, userId: number, peerReviewSummary: CreateReviewStepSummary): Observable<ReviewStepSummary> {
    const url = `/api/upward-review-summary/${cycleId}/${userId}`;
    return this.http.post<ReviewStepSummary>(url, peerReviewSummary);
  }

  updateUpwardReviewSummaryForUserAndCycle(cycleId: number, userId: number, peerReviewSummary: CreateReviewStepSummary): Observable<ReviewStepSummary> {
    const url = `/api/upward-review-summary/${cycleId}/${userId}`;
    return this.http.put<ReviewStepSummary>(url, peerReviewSummary);
  }

  shareUpwardReviewSummaryForUserAndCycle(cycleId: number, userId: number, peerReviewSummary: CreateReviewStepSummary): Observable<ReviewStepSummary> {
    const url = `/api/upward-review-summary/${cycleId}/${userId}/share`;
    return this.http.post<ReviewStepSummary>(url, peerReviewSummary);
  }

  getUpwardReviewSummaryForMeInCycle(cycleId: number): Observable<ReviewStepSummary> {
    const url = `/api/upward-review-summary/${cycleId}/me`;
    return this.http.get<ReviewStepSummary>(url);
  }

  getUpwardReviewSummaryForUserInCycle(cycleId: number, userId: number): Observable<ReviewStepSummary> {
    const url = `/api/upward-review-summary/${cycleId}/${userId}`;
    return this.http.get<ReviewStepSummary>(url);
  }

  getUpwardReviewSummaryForUserInCycleAsAdmin(cycleId: number, userId: number): Observable<ReviewStepSummary> {
    const url = `/api/upward-review-summary/${cycleId}/${userId}/admin`;
    return this.http.get<ReviewStepSummary>(url);
  }

  getUsersCompletedPeerReviewAboutMeForCycleId(cycleId: number): Observable<UserMinimal[]> {
    const url = `${this.baseURL}/${cycleId}/nominated-users-peer/`;
    return this.http.get<UserMinimal[]>(url);
  }

  getUsersCompletedUpwardReviewAboutMeForCycleId(cycleId: number): Observable<UserMinimal[]> {
    const url = `${this.baseURL}/${cycleId}/nominated-users-upward/`;
    return this.http.get<UserMinimal[]>(url);
  }

  getMyManagerReviewsForActiveCycles(): Observable<EvaluationFeedbackRequest[]> {
    const url = `${this.baseURL}/manager-reviews/me`;
    return this.http.get<EvaluationFeedbackRequest[]>(url);
  }

  getMyPeerReviewsForActiveCycles(): Observable<EvaluationFeedbackRequest[]> {
    const url = `${this.baseURL}/peer-reviews/me`;
    return this.http.get<EvaluationFeedbackRequest[]>(url);
  }

  getNominationRequestsForMyTeam(): Observable<EvaluationFeedbackRequest[]> {
    const url = `${this.baseURL}/nominations/team`;
    return this.http.get<EvaluationFeedbackRequest[]>(url);
  }

  getNominationRequestsForMyTeamForCycle(cycleId: number): Observable<EvaluationFeedbackRequest[]> {
    const url = `${this.baseURL}/nominations/team/${cycleId}`;
    return this.http.get<EvaluationFeedbackRequest[]>(url);
  }

  getPeersNominatedCompletedShared(cycleId: number): Observable<EvaluationFeedbackRequest[]> {
    const url = `${this.baseURL}/${cycleId}/nominations/complete`;
    return this.http.get<EvaluationFeedbackRequest[]>(url);
  }

  getPeersNominatedCompletedSharedForUserId(cycleId: number, userId: number): Observable<EvaluationFeedbackRequest[]> {
    const url = `${this.baseURL}/${cycleId}/nominations/complete/${userId}`;
    return this.http.get<EvaluationFeedbackRequest[]>(url);
  }

  getNominationById(requestId: number): Observable<EvaluationFeedback> {
    const url = `${this.baseURL}/nominations/complete/${requestId}`;
    return this.http.get<EvaluationFeedback>(url);
  }

  getNominationByIdIfExists(requestId: number): Observable<EvaluationFeedback | null> {
    const url = `${this.baseURL}/nominations/complete/${requestId}/optional`;
    return this.http.get<EvaluationFeedback | null>(url);
  }

  checkIsPeerReviewShared(requestId: number): Observable<boolean> {
    const url = `${this.baseURL}/check-complete/peer/${requestId}`;
    return this.http.get<boolean>(url);
  }

  checkIsUpwardReviewShared(requestId: number): Observable<boolean> {
    const url = `${this.baseURL}/check-complete/upward/${requestId}`;
    return this.http.get<boolean>(url);
  }

  checkIsManagerReviewShared(requestId: number): Observable<boolean> {
    const url = `${this.baseURL}/check-complete/manager/${requestId}`;
    return this.http.get<boolean>(url);
  }

  sharePeerReviewAccess(requestId: number): Observable<PeerReviewAccess> {
    const url = `${this.baseURL}/nominations/share/peer/${requestId}`;
    return this.http.get<PeerReviewAccess>(url);
  }

  shareUpwardReviewAccess(requestId: number): Observable<UpwardReviewAccess> {
    const url = `${this.baseURL}/nominations/share/upward/${requestId}`;
    return this.http.get<UpwardReviewAccess>(url);
  }

  sharePeerReviewAccessBySubjectUserId(cycleId: number, subjectUserId: number): Observable<PeerReviewAccess[]> {
    const url = `${this.baseURL}/${cycleId}/share/peer/all`;
    return this.http.post<PeerReviewAccess[]>(url, subjectUserId);
  }

  shareUpwardReviewAccessBySubjectUserId(cycleId: number, subjectUserId: number): Observable<UpwardReviewAccess[]> {
    const url = `${this.baseURL}/${cycleId}/share/upward/all`;
    return this.http.post<UpwardReviewAccess[]>(url, subjectUserId);
  }

  getUpwardReviewRequestsForMe(): Observable<EvaluationFeedbackRequest[]> {
    const url = `${this.baseURL}/upward/me`;
    return this.http.get<EvaluationFeedbackRequest[]>(url);
  }

  getUpwardReviewRequestsForMeForCycle(cycleId: number): Observable<EvaluationFeedbackRequest[]> {
    const url = `${this.baseURL}/${cycleId}/upward/me/`;
    return this.http.get<EvaluationFeedbackRequest[]>(url);
  }

  getUpwardReviewsCompletedSharedForUserId(cycleId: number, userId: number): Observable<EvaluationFeedbackRequest[]> {
    const url = `${this.baseURL}/${cycleId}/upward/complete/${userId}`;
    return this.http.get<EvaluationFeedbackRequest[]>(url);
  }

  getSidebarCycles(userIds: number[]): Observable<EvaluationCycle[]> {
    const url = `${this.baseURL}/sidebar`;

    let params = new HttpParams();
    if (userIds) {
      params = params.append('userIds', userIds.join(','));
    }

    return this.http.get<EvaluationCycle[]>(url, { params: params }).pipe(map(c => this.parseCycleMultiple(c)));
  }

}
