<router-outlet></router-outlet>
<ng-container *ngIf="route.children.length === 0">
  <ng-container *ngIf="componentState.isSuccessful()">
    <div class="row m-left-0 m-right-0" [class.sidebar-expanded]="sidebarOpen">
        <!-- Sidebar -->
        <div class="col-filter-sidebar p-top-sidebar">
            <div class="lh-40 p-bottom-10">
                <a [routerLink]="['/settings']" [queryParams]="{ part: 'personal' }">
                    <span class="fal fa-fw fa-edit"></span>
                    <span class="m-right-5"></span>
                    <span translate>{{eProfileMessages.EDIT_PROFILE}}</span>
                </a>
            </div>
            <div class="card">

                <!-- Search -->
                <div class="search-collapse-container">
                    <div class="main-search-container">
                        <input type="search" class="round-search form-control" placeholder="Search by name..."
                               [formControl]="filters.search">
                        <i class="fal fa-search form-control-feedback"></i>
                    </div>
                    <div class="sidebar-collapse-icon-outer">
                        <span class="fal fa-fw fa-chevron-left frankli-view pull-left sidebar-collapse-icon"
                              [class.sidebar-icon-collapsed]="!sidebarOpen"
                              [title]="sidebarOpen ? 'Collapse sidebar' : 'Expand sidebar'"
                              (click)="toggleSidebarCollapsed()"></span>
                    </div>
          </div>

          <!-- Filters -->
          <div class="category-area">
            <!-- Everyone -->
            <div class="p-bottom-10">
              <div class="category-body noselect">
                <div class="category-option" *ngIf="overviewCounts.allCount > 0" (click)="toggleAllFilterSelected()" [class.cat-option-selected]="filters.all">
                    <span class="icon-number">{{overviewCounts.allCount | shortNumber }}</span>
                  <span class="category-text">Everyone</span>
                </div>
                <div class="category-option" *ngIf="overviewCounts.adminCount > 0" (click)="toggleAdminFilterSelected()" [class.cat-option-selected]="filters.admins">
                    <span class="icon-number">{{overviewCounts.adminCount | shortNumber}}</span>
                  <span class="category-text">Admins</span>
                </div>
                <div class="category-option" *ngIf="overviewCounts.frankliAdminCount > 0" (click)="toggleFrankliAdminFilterSelected()" [class.cat-option-selected]="filters.frankliAdmins">
                    <span class="icon-number">{{overviewCounts.frankliAdminCount | shortNumber}}</span>
                  <span class="category-text">Frankli Admins</span>
                </div>
              </div>
            </div>

            <!-- Users filters -->
            <div class="p-bottom-10" *ngIf="globals.user">
              <div class="category-title">
                <span class="title-text">My {{globals.getTerminology(eTerminologyEntity.TEAM_PLURAL) | lowercase}}:</span>
              </div>
              <div class="category-body noselect">
                <div class="category-option" *ngIf="myTeams.department" (click)="filterSelected($event, 'departments', myTeams.department.id)" [class.cat-option-selected]="filters.departments[myTeams.department.id]">
                    <span class="icon-number"
                          *ngIf="myTeams.department.count > 0">{{myTeams.department.count | shortNumber}}</span>
                  <span class="category-text">{{myTeams.department.name}}</span>
                </div>
                <div class="category-option" *ngIf="myTeams.site" (click)="filterSelected($event, 'sites', myTeams.site.id)" [class.cat-option-selected]="filters.sites[myTeams.site.id]">
                    <span *ngIf="myTeams.site.count > 0" class="icon-number">{{myTeams.site.count | shortNumber}}</span>
                  <span class="category-text">{{myTeams.site.name}}</span>
                </div>
                <div class="category-option" *ngIf="myTeams.manager" (click)="filterSelected($event, 'managers', myTeams.manager.id)" [class.cat-option-selected]="filters.managers[myTeams.manager.id]">
                    <span *ngIf="myTeams.manager.count > 0"
                          class="icon-number">{{myTeams.manager.count | shortNumber}}</span>
                  <span class="category-text">{{myTeams.manager.name}}</span>
                </div>
                <div class="category-option" *ngIf="myTeams.managerMe" (click)="filterSelected($event, 'managers', myTeams.managerMe.id)" [class.cat-option-selected]="filters.managers[myTeams.managerMe.id]">
                    <span *ngIf="myTeams.managerMe.count > 0"
                          class="icon-number">{{myTeams.managerMe.count | shortNumber}}</span>
                  <span class="category-text">My direct reports</span>
                </div>
              </div>
            </div>

              <!-- Departments -->
              <ng-container [ngTemplateOutlet]="filterCategory" [ngTemplateOutletContext]="{title: globals.getTerminology(eTerminologyEntity.DEPARTMENT_PLURAL), source: overviewCounts.departmentCounts, filterProp: 'departments', searchControl: 'departmentsSearch', searchPlaceholder: 'Search by ' + globals.getTerminology(eTerminologyEntity.DEPARTMENT).toLowerCase()}"></ng-container>

              <!-- Sites -->
              <ng-container [ngTemplateOutlet]="filterCategory" [ngTemplateOutletContext]="{title: globals.getTerminology(eTerminologyEntity.SITE_PLURAL), source: overviewCounts.siteCounts, filterProp: 'sites', searchControl: 'sitesSearch', searchPlaceholder: 'Search by ' + globals.getTerminology(eTerminologyEntity.SITE).toLowerCase()}"></ng-container>

            <!-- Managers -->
            <ng-container [ngTemplateOutlet]="filterCategory" [ngTemplateOutletContext]="{title: 'Managers', source: overviewCounts.managerCounts, filterProp: 'managers', searchControl: 'managersSearch', searchPlaceholder: 'Search by manager'}"></ng-container>

            <ng-template #filterCategory let-title="title" let-source="source" let-filterProp="filterProp" let-searchControl="searchControl" let-searchPlaceholder="searchPlaceholder">
              <div class="p-bottom-10">
                <div class="category-title" (click)="toggleCategoryExpanded(title)">
                  <span class="fal fa-fw fa-chevron-right frankli-view expand-on-hover dropdown-icon" [class.dropdown-icon-expanded]="categoryExpanded[title]"></span>
                  <span class="title-text">{{title}}:</span>
                </div>
                <div class="category-body noselect" *ngIf="categoryExpanded[title]">
                  <div class="sidebar-category-filter" *ngIf="searchPlaceholder && source.length > 6">
                    <input type="search" class="round-search form-control" [placeholder]="searchPlaceholder" [formControl]="filters[searchControl]">
                    <i class="fal fa-search form-control-feedback"></i>
                  </div>
                  <div class="category-item-list">
                    <ng-container *ngFor="let item of source">
                      <div class="category-option" *ngIf="(item.count > 0) && (filters[filterProp+'Search'].invalid || item.name.toLowerCase().includes(filters[searchControl].value.toLowerCase()))"  (click)="filterSelected($event, filterProp, item.id)" [class.cat-option-selected]="filters[filterProp][item.id]">
                          <span class="icon-number">{{item.count | shortNumber}}</span>
                        <span class="category-text">{{item.name}}</span>
                      </div>
                    </ng-container>
                  </div>
                </div>
              </div>
            </ng-template>
          </div>
        </div>
      </div>

      <!-- People grid -->
      <div class="col-people-grid">
        <div>
          <!-- Title -->
          <div class="main-header">
            <div class="page-title d-inline-block">
              <span>People</span>
            </div>
            <span title="Export to Excel" class="fal fa-fw fa-file-excel frankli-view clickable pull-right export-icon"
                  (click)="exportPeopleToXLSX()" *ngIf="canExport"></span>
          </div>

          <!-- Grid -->
          <ng-container *ngIf="searchState.isSuccessful()">
            <ng-container *ngIf="pageUsers.length > 0; else noUsers">
              <div class="padding-col-outer">
                <div class="row m-left-0 m-right-0">
                  <div class="col-xs-12 col-sm-6 col-md-6 col-lg-4 padding-col" *ngFor="let user of pageUsers; index as i;">
                    <div class="user-item" [routerLink]="['profile/', user.id]">
                      <app-person-display [personDetails]="user"></app-person-display>
                    </div>
                  </div>
                </div>
              </div>

              <app-pagination-server [page]="pagedUsers" [pageSize]="pageParams.pageSize" [pageSizes]="[30, 60, 90, 120]" (pageNumberChanged)="changePageNumber($event)" (pageSizeChanged)="changePageSize($event)"></app-pagination-server>

            </ng-container>
            <ng-template #noUsers>
              <div class="p-top-15 p-bottom-15 text-center">
                <img class="icon-padding new-icon-height" src="/assets/img/NoSearchFound.svg" />
                <br>
                <p>No people found. Try changing your filters.</p>
              </div>
            </ng-template>
          </ng-container>

          <ng-container *ngIf="searchState.isLoading()">
            <div class="card p-top-15 p-bottom-15 text-center">
              <span class="fas fa-spin fa-spinner fa-2x fa-fw"></span>
            </div>
          </ng-container>

          <ng-container *ngIf="searchState.isFailure()">
            <div class="card p-top-15 p-bottom-15 text-center">
              <p>
                <img class="icon-padding new-icon-height" src="/assets/img/Error.svg" />
                <br/>
                <span>An error occurred while getting your people.</span>
                <br/>
                <span>{{componentState.error.message}}</span>
              </p>
              <span class="btn-frankli-round btn-frankli-green" [routerLink]="'/dashboard'">
        Back to your dashboard?
      </span>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </ng-container>

  <!-- #region - ALTERNATE PAGE STATES -->
  <ng-container *ngIf="componentState.isLoading()">
    <div class="card p-top-15 p-bottom-15 text-center">
      <span class="fas fa-spin fa-spinner fa-2x fa-fw"></span>
    </div>
  </ng-container>

  <ng-container *ngIf="componentState.isFailure()">
    <div class="card p-top-15 p-bottom-15 text-center">
      <p>
        <img class="icon-padding new-icon-height" src="/assets/img/Error.svg" />
        <br/>
        <span>An error occurred while getting your people.</span>
        <br/>
        <span>{{componentState.error.message}}</span>
      </p>
      <span class="btn-frankli-round btn-frankli-green" [routerLink]="'/dashboard'">
        Back to your dashboard?
      </span>
    </div>
  </ng-container>
  <!-- #endregion -->
</ng-container>
