export class SettingsMessages {
  // General
  public static readonly SETTINGS = 'settings.settings';
  public static readonly TAB_LABEL = 'settings.tab-label';

  // Tabs and headings
  public static readonly PROFILE_DETAILS = 'settings.profile-details';
  public static readonly PROFILE_DETAILS_SHORT = 'settings.profile-details-short';
  public static readonly PROFILE_DETAILS_DESCRIPTION = 'settings.profile-details-description';
  public static readonly LOCATION_DETAILS = 'settings.location-details';
  public static readonly LOCATION_DETAILS_SHORT = 'settings.location-details-short';
  public static readonly LOCATION_DETAILS_DESCRIPTION = 'settings.location-details-description';
  public static readonly CONTACT_INFORMATION = 'settings.contact-information';
  public static readonly CONTACT_INFORMATION_SHORT = 'settings.contact-information-short';
  public static readonly CONTACT_INFORMATION_DESCRIPTION = 'settings.contact-information-description';
  public static readonly CONNECTION_SETTINGS = 'settings.connection-settings';
  public static readonly CONNECTION_SETTINGS_SHORT = 'settings.connection-settings-short';
  public static readonly CONNECTION_SETTINGS_DESCRIPTION = 'settings.connection-settings-description';
  public static readonly SECURITY_SETTINGS = 'settings.security-settings';
  public static readonly SECURITY_SETTINGS_SHORT = 'settings.security-settings-short';
  public static readonly SECURITY_SETTINGS_DESCRIPTION = 'settings.security-settings-description';
  public static readonly INTERESTS = 'settings.interests';
  public static readonly INTERESTS_SHORT = 'settings.interests-short';
  public static readonly INTERESTS_DESCRIPTION = 'settings.interests-description';
  public static readonly SKILLS_DESCRIPTION = 'settings.skills-description';
  public static readonly QUALIFICATIONS = 'settings.qualifications';
  public static readonly QUALIFICATIONS_SHORT = 'settings.qualifications-short';
  public static readonly QUALIFICATIONS_DESCRIPTION = 'settings.qualifications-description';

  // Personal details
  public static readonly BIRTHDAY_REMINDER_DESCRIPTION = 'settings.birthday-reminder-description';
  public static readonly NO_ROLE = 'settings.no-role';
  public static readonly NO_DEPARTMENT = 'settings.no-department';

  // Location settings
  public static readonly ADDRESS = 'settings.address';

  // Connection settings
  public static readonly NOTIFICATIONS_VIA_EMAIL = 'settings.notifications-via-email';
  public static readonly NOTIFICATIONS_VIA_EMAIL_DESCRIPTION = 'settings.notifications-via-email-description';
  public static readonly NOTIFICATIONS_VIA_SLACK = 'settings.notifications-via-slack';
  public static readonly NOTIFICATIONS_VIA_SLACK_DESCRIPTION = 'settings.notifications-via-slack-description';
  public static readonly NOTIFICATIONS_VIA_SLACK_NOT_CONNECTED = 'settings.notifications-via-slack-not-connected';
  public static readonly NOTIFICATIONS_VIA_TEAMS = 'settings.notifications-via-teams';
  public static readonly NOTIFICATIONS_VIA_TEAMS_DESCRIPTION = 'settings.notifications-via-teams-description';
  public static readonly NOTIFICATIONS_VIA_TEAMS_NOT_CONNECTED = 'settings.notifications-via-teams-not-connected';
  public static readonly GOOGLE_ACCOUNT_ACCESS = 'settings.google-account-access';
  public static readonly GOOGLE_ACCOUNT_ACCESS_DESCRIPTION = 'settings.google-account-access-description';
  public static readonly GOOGLE_ACCOUNT_ACCESS_REVOKING = 'settings.google-account-access-revoking';

  // Interests
  public static readonly CREATE_AN_INTEREST = 'settings.create-an-interest';
  public static readonly SUGGEST_AN_INTEREST = 'settings.suggest-an-interest';

  //Skills
  public static readonly CREATE_A_SKILL = 'settings.create-a-skill';
  public static readonly SUGGEST_A_SKILL = 'settings.suggest-a-skill';

  // TODO: Move these from profile.messages to settings.messages where applicable
  public static readonly DATE_OF_BIRTH = 'profile.date-of-birth';
  public static readonly BIRTHDAY_REMINDER = 'profile.birthday-reminder';
  public static readonly PREFERRED_CONTACT_METHOD = 'profile.preferred-contact-method';
  public static readonly CONTACT_METHODS = {
    NONE: 'profile.contact-methods.none',
    IN_PERSON: 'profile.contact-methods.in-person',
    EMAIL: 'profile.contact-methods.email',
    PHONE: 'profile.contact-methods.phone',
    TEXT: 'profile.contact-methods.text',
    MICROSOFT_TEAMS: 'profile.contact-methods.microsoft-teams',
    SLACK: 'profile.contact-methods.slack',
  };
  public static readonly PHONE_NUMBER = 'profile.phone-number';
  public static readonly LINKEDIN_URL = 'profile.linkedin-url';
  public static readonly TWITTER_HANDLE = 'profile.twitter-handle';
  public static readonly PROFILE_PICTURE = 'profile.profile-picture';
  public static readonly GENDER = 'profile.gender';
  public static readonly GENDER_OPTIONS = {
    MALE: 'profile.gender-options.male',
    FEMALE: 'profile.gender-options.female',
    OTHER: 'profile.gender-options.other',
    PREFER_NOT_TO_SAY: 'profile.gender-options.prefer-not-to-say',
  };
  public static readonly ABOUT_ME = 'profile.about-me';
  public static readonly STREET = 'profile.street';
  public static readonly CITY = 'profile.city';
  public static readonly COUNTRY = 'profile.country';
  public static readonly POSTAL_CODE = 'profile.postal-code';
  public static readonly SITE = 'profile.site';
  public static readonly TIMEZONE = 'profile.timezone';
  public static readonly SUCCESS_PROFILE_UPDATED = 'profile.success-profile-updated';
  public static readonly HELP_DATEPICKER = 'profile.help-datepicker';
  public static readonly MUST_LINK_TO_LINKEDIN = 'profile.must-link-to-linkedin';
  public static readonly SITE_MANAGED_BY_ACTIVE_DIRECTORY = 'profile.site-managed-by-active-directory';
  public static readonly SITE_MANAGED_BY_PEOPLE_HR = 'profile.site-managed-by-people-hr';
  public static readonly CATEGORIES = 'profile.categories';
  public static readonly AVAILABLE = 'profile.available';
  public static readonly SUGGEST_NEW_INTEREST = 'profile.suggest-new-interest';
  public static readonly SUGGEST_NEW_INTEREST_DESCRIPTION = 'profile.suggest-new-interest-description';
  public static readonly WARNING_MAX_INTERESTS = 'profile.warning-max-interests';
  public static readonly WARNING_MAX_INTERESTS_DESCRIPTION = 'profile.warning-max-interests-description';
}
