<div class="avoid-breaks-print" [ngClass]="columnIndent ? 'row' : ''">
  <div [ngClass]="columnIndent ? 'col-sm-10 col-sm-offset-1' : ''">
    <div class="likert-container" [class.small-likert]="container.offsetWidth <= wrapWidth" [class.likert-disabled]="display || disabled" #container>
      <!-- Input -->
      <div class="scale-container">
        <div class="background-line" [style.height.px]="(circleSize / 4)" [style.top.px]="(circleSize / 2)"></div>
        <div class="background-line-small" [style.width.px]="(circleSize / 4)" [style.top.px]="(circleSize / 2)"></div>
        <div class="likert-options-container">
          <div (click)="select(1)" [class.likert-selected]="selection === 1" class="likert-option">
            <div class="circle-outer fill-1" [style.width.px]="circleSize" [style.height.px]="circleSize">
              <div class="circle-inner" [style.borderWidth.px]="circleBorderWidth">
                <span class="fal fa-fw fa-check likert-icon" [style.fontSize.px]="circleIconSize" *ngIf="showIcons"></span>
              </div>
            </div>
            <div class="likert-label" [style.fontSize.px]="labelFontSize">Strongly Disagree</div>
          </div>
          <div (click)="select(2)" [class.likert-selected]="selection === 2" class="likert-option">
            <div class="circle-outer fill-2" [style.width.px]="circleSize" [style.height.px]="circleSize">
              <div class="circle-inner" [style.borderWidth.px]="circleBorderWidth">
                <span class="fal fa-fw fa-check likert-icon" [style.fontSize.px]="circleIconSize" *ngIf="showIcons"></span>
              </div>
            </div>
            <div class="likert-label" [style.fontSize.px]="labelFontSize">Disagree</div>
          </div>
          <div (click)="select(3)" [class.likert-selected]="selection === 3" class="likert-option">
            <div class="circle-outer fill-3" [style.width.px]="circleSize" [style.height.px]="circleSize">
              <div class="circle-inner" [style.borderWidth.px]="circleBorderWidth">
                <span class="fal fa-fw fa-check likert-icon" [style.fontSize.px]="circleIconSize" *ngIf="showIcons"></span>
              </div>
            </div>
            <div class="likert-label" [style.fontSize.px]="labelFontSize">Neutral</div>
          </div>
          <div (click)="select(4)" [class.likert-selected]="selection === 4" class="likert-option">
            <div class="circle-outer fill-4" [style.width.px]="circleSize" [style.height.px]="circleSize">
              <div class="circle-inner" [style.borderWidth.px]="circleBorderWidth">
                <span class="fal fa-fw fa-check likert-icon" [style.fontSize.px]="circleIconSize" *ngIf="showIcons"></span>
              </div>
            </div>
            <div class="likert-label" [style.fontSize.px]="labelFontSize">Agree</div>
          </div>
          <div (click)="select(5)" [class.likert-selected]="selection === 5" class="likert-option">
            <div class="circle-outer fill-5" [style.width.px]="circleSize" [style.height.px]="circleSize">
              <div class="circle-inner" [style.borderWidth.px]="circleBorderWidth">
                <span class="fal fa-fw fa-check likert-icon" [style.fontSize.px]="circleIconSize" *ngIf="showIcons"></span>
              </div>
            </div>
            <div class="likert-label" [style.fontSize.px]="labelFontSize">Strongly Agree</div>
          </div>
        </div>
      </div>
  
      <!-- Comment -->
      <div class="comment-container" *ngIf="commentEnabled">
        <ng-container *ngIf="!display; else templateDisplayComment;">
          <div class="frankli-form-group frankli-form-group-filled" [class.frankli-form-group-invalid]="((commentMinLength && (comment?.length < commentMinLength!)) || (commentMaxLength && (comment?.length > commentMaxLength!)))">
            <textarea class="form-control noresize" rows="3" [(ngModel)]="comment" (ngModelChange)="commentChanged($event)" [disabled]="disabled"></textarea>
            <div class="text-right"  *ngIf="commentMaxLength !== null">
              <p [class.text-danger]="(commentMaxLength && (comment?.length > commentMaxLength))">{{comment?.length}} / {{commentMaxLength}}</p>
            </div>
          </div>
        </ng-container>
        <ng-template #templateDisplayComment>
          <span>{{comment}}</span>
        </ng-template>
      </div>
    </div>
    </div>
</div>
