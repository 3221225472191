import {InterestType} from '@app/domain/interest/model/interest-type.model';
import { Interest } from '../model/interest.model';

// Id values 1 - 30 are Personal, 31 - 60 are Professional
export const mockInterests: Interest[] = [
  {
    id: 1,
    interestType: InterestType.PERSONAL,
    category: 'Sport',
    name: 'Baseball',
    approved: true
  },
  {
    id: 2,
    interestType: InterestType.PERSONAL,
    category: 'Sport',
    name: 'Basketball',
    approved: true
  },
  {
    id: 3,
    interestType: InterestType.PERSONAL,
    category: 'Sport',
    name: 'Boxing',
    approved: true
  },
  {
    id: 4,
    interestType: InterestType.PERSONAL,
    category: 'Sport',
    name: 'Martial Arts',
    approved: true
  },
  {
    id: 5,
    interestType: InterestType.PERSONAL,
    category: 'Sport',
    name: 'Wrestling',
    approved: true
  },
  {
    id: 6,
    interestType: InterestType.PERSONAL,
    category: 'Sport',
    name: 'Cricket',
    approved: true
  },
  {
    id: 7,
    interestType: InterestType.PERSONAL,
    category: 'Sport',
    name: 'Extreme Sports',
    approved: true
  },
  {
    id: 8,
    interestType: InterestType.PERSONAL,
    category: 'Sport',
    name: 'Football',
    approved: true
  },
  {
    id: 9,
    interestType: InterestType.PERSONAL,
    category: 'Sport',
    name: 'Golf',
    approved: true
  },
  {
    id: 10,
    interestType: InterestType.PERSONAL,
    category: 'Sport',
    name: 'Cycling',
    approved: true
  },
  {
    id: 11,
    interestType: InterestType.PERSONAL,
    category: 'Sport',
    name: 'Gymnastics',
    approved: true
  },
  {
    id: 12,
    interestType: InterestType.PERSONAL,
    category: 'Sport',
    name: 'Hockey',
    approved: true
  },
  {
    id: 13,
    interestType: InterestType.PERSONAL,
    category: 'Sport',
    name: 'Horse Riding',
    approved: true
  },
  {
    id: 14,
    interestType: InterestType.PERSONAL,
    category: 'Sport',
    name: 'Motor Sports',
    approved: true
  },
  {
    id: 15,
    interestType: InterestType.PERSONAL,
    category: 'Sport',
    name: 'Tennis',
    approved: true
  },
  {
    id: 16,
    interestType: InterestType.PERSONAL,
    category: 'Sport',
    name: 'Squash',
    approved: true
  },
  {
    id: 17,
    interestType: InterestType.PERSONAL,
    category: 'Sport',
    name: 'Badminton',
    approved: true
  },
  {
    id: 18,
    interestType: InterestType.PERSONAL,
    category: 'Sport',
    name: 'Rugby',
    approved: true
  },
  {
    id: 19,
    interestType: InterestType.PERSONAL,
    category: 'Sport',
    name: 'Soccer',
    approved: true
  },
  {
    id: 20,
    interestType: InterestType.PERSONAL,
    category: 'Sport',
    name: 'Snooker / Pool',
    approved: true
  },
  {
    id: 21,
    interestType: InterestType.PERSONAL,
    category: 'Sport',
    name: 'Running (Short Distance)',
    approved: true
  },
  {
    id: 22,
    interestType: InterestType.PERSONAL,
    category: 'Sport',
    name: 'Running (Long Distance)',
    approved: true
  },
  {
    id: 23,
    interestType: InterestType.PERSONAL,
    category: 'Sport',
    name: 'Triathlon',
    approved: true
  },
  {
    id: 24,
    interestType: InterestType.PERSONAL,
    category: 'Sport',
    name: 'Track & Field',
    approved: true
  },
  {
    id: 25,
    interestType: InterestType.PERSONAL,
    category: 'Sport',
    name: 'Volleyball',
    approved: true
  },
  {
    id: 26,
    interestType: InterestType.PERSONAL,
    category: 'Sport',
    name: 'Swimming',
    approved: true
  },
  {
    id: 27,
    interestType: InterestType.PERSONAL,
    category: 'Sport',
    name: 'Scuba Diving',
    approved: true
  },
  {
    id: 28,
    interestType: InterestType.PERSONAL,
    category: 'Sport',
    name: 'Snorkelling',
    approved: true
  },
  {
    id: 29,
    interestType: InterestType.PERSONAL,
    category: 'Sport',
    name: 'Water Polo',
    approved: true
  },
  {
    id: 30,
    interestType: InterestType.PERSONAL,
    category: 'Sport',
    name: 'Aqua Jogging',
    approved: true
  },
  {
    id: 31,
    interestType: InterestType.PROFESSIONAL,
    category: 'Computers & Electronics',
    name: 'Software System Integration',
    approved: true
  },
  {
    id: 32,
    interestType: InterestType.PROFESSIONAL,
    category: 'Computers & Electronics',
    name: 'Artificial intelligence',
    approved: true
  },
  {
    id: 33,
    interestType: InterestType.PROFESSIONAL,
    category: 'Computers & Electronics',
    name: 'Block Chain Technology',
    approved: true
  },
  {
    id: 34,
    interestType: InterestType.PROFESSIONAL,
    category: 'Finance & Insurance',
    name: 'Accounting & Tax',
    approved: true
  },
  {
    id: 35,
    interestType: InterestType.PROFESSIONAL,
    category: 'Finance & Insurance',
    name: 'Currencies & Foreign Exchange',
    approved: true
  },
  {
    id: 36,
    interestType: InterestType.PROFESSIONAL,
    category: 'Finance & Insurance',
    name: 'Debt Management',
    approved: true
  },
  {
    id: 37,
    interestType: InterestType.PROFESSIONAL,
    category: 'Finance & Insurance',
    name: 'Investing',
    approved: true
  },
  {
    id: 38,
    interestType: InterestType.PROFESSIONAL,
    category: 'Finance & Insurance',
    name: 'Retirement & Pensions',
    approved: true
  },
  {
    id: 39,
    interestType: InterestType.PROFESSIONAL,
    category: 'Recruitment',
    name: 'Intake consultation',
    approved: true
  },
  {
    id: 40,
    interestType: InterestType.PROFESSIONAL,
    category: 'Recruitment',
    name: 'Recruitment marketing',
    approved: true
  },
  {
    id: 41,
    interestType: InterestType.PROFESSIONAL,
    category: 'Recruitment',
    name: 'Interviewing techniques',
    approved: true
  },
  {
    id: 42,
    interestType: InterestType.PROFESSIONAL,
    category: 'Recruitment',
    name: 'People Skills',
    approved: true
  },
  {
    id: 43,
    interestType: InterestType.PROFESSIONAL,
    category: 'Recruitment',
    name: 'Pipeline management',
    approved: true
  },
  {
    id: 44,
    interestType: InterestType.PROFESSIONAL,
    category: 'Recruitment',
    name: 'Candidate Sourcing',
    approved: true
  },
  {
    id: 45,
    interestType: InterestType.PROFESSIONAL,
    category: 'Recruitment',
    name: 'Candidate Screening',
    approved: true
  },
  {
    id: 46,
    interestType: InterestType.PROFESSIONAL,
    category: 'Recruitment',
    name: 'Candidate Qualifying',
    approved: true
  },
  {
    id: 47,
    interestType: InterestType.PROFESSIONAL,
    category: 'Recruitment',
    name: 'Contract management',
    approved: true
  },
  {
    id: 48,
    interestType: InterestType.PROFESSIONAL,
    category: 'Recruitment',
    name: 'Onboarding',
    approved: true
  },
  {
    id: 49,
    interestType: InterestType.PROFESSIONAL,
    category: 'Recruitment',
    name: 'Workforce Planning',
    approved: true
  },
  {
    id: 50,
    interestType: InterestType.PROFESSIONAL,
    category: 'Recruitment',
    name: 'Talent Retention',
    approved: true
  },
  {
    id: 51,
    interestType: InterestType.PROFESSIONAL,
    category: 'Design',
    name: 'Architecture',
    approved: true
  },
  {
    id: 52,
    interestType: InterestType.PROFESSIONAL,
    category: 'Design',
    name: 'Graphic Design & Publishing',
    approved: true
  },
  {
    id: 53,
    interestType: InterestType.PROFESSIONAL,
    category: 'Design',
    name: 'Industrial & Product Design',
    approved: true
  },
  {
    id: 54,
    interestType: InterestType.PROFESSIONAL,
    category: 'Design',
    name: 'Interior Design',
    approved: true
  },
  {
    id: 55,
    interestType: InterestType.PROFESSIONAL,
    category: 'Design',
    name: 'Product Design',
    approved: true
  },
  {
    id: 56,
    interestType: InterestType.PROFESSIONAL,
    category: 'Design',
    name: 'Service Design',
    approved: true
  },
  {
    id: 57,
    interestType: InterestType.PROFESSIONAL,
    category: 'Human Resource',
    name: 'Compensation & Benefits',
    approved: true
  },
  {
    id: 58,
    interestType: InterestType.PROFESSIONAL,
    category: 'Human Resource',
    name: 'Corporate Training',
    approved: true
  },
  {
    id: 59,
    interestType: InterestType.PROFESSIONAL,
    category: 'Human Resource',
    name: 'Payroll Services',
    approved: true
  },
  {
    id: 60,
    interestType: InterestType.PROFESSIONAL,
    category: 'Human Resource',
    name: 'Recruitment & Staffing',
    approved: true
  }
];