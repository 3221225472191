export enum NewsActivityType {
    PRAISE_SENT = "PRAISE_SENT",
    GOAL_CREATED = "GOAL_CREATED",
    GOAL_PROGRESS_UPDATED = "GOAL_PROGRESS_UPDATED"
}

export interface NewsActivity {
    id: number;
    companyId: number;
    userId: number;
    organisationalUnitId: number;
    officeLocationId: number;
    foreignId: number;
    timestamp: Date;
    type: NewsActivityType;
    message: string;
    link: string;
    icon: string;
}
