import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnChanges, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { OneToOneMeetingLength } from '@app/domain/one_to_one/model/one-to-one-meeting-length.model';
import { CalendarAPIService } from '@app/shared/api/calendar.api.service';
import { Breadcrumb } from 'app/models/breadcrumb.model';
import { OneToOneScheduleDetailsView } from '@app/domain/one_to_one/model/one-to-one-schedule.model';
import { ScheduleLocationType } from '@app/domain/one_to_one/model/schedule-location-type.model';
import { OneToOneStatus } from '@app/domain/one_to_one/model/one-to-one-status.model';
import { TalkingPoint } from '@app/domain/one_to_one/model/talking-point.model';
import { BreadcrumbService } from 'app/shared/breadcrumbs/breadcrumbs.service';
import { Globals } from 'app/shared/globals/globals';
import { RouteGuardUnsavedChanges } from 'app/shared/utils/unsaved-changes.model';
import { EvaluationCycle } from '@app/models/evaluation/evaluation-cycle.model';
import { EvaluationCycleState } from '@app/models/evaluation/evaluation-cycle-state';
import { CompanyFeatures } from '@app/models/company-features.model';
import { EvaluationCycleAPIService } from '@app/shared/api/evaluation-cycle.api.service';
import { UserAPIService } from '@app/shared/api/user.api.service';
import { SwalUtils } from '@app/shared/utils/swal.utils';
import { OneToOneMessages } from '@app/domain/one_to_one/locale/one-to-one.messages';
import { CommonMessages } from '@app/constants/common.messages';
import { TranslateService } from '@ngx-translate/core';
import { SSOProvider } from '@app/models/auth/sso-provider.enum';
import { RoleName } from '@app/models/user-role.model';
import { SecondaryManagerAPIService } from '@app/shared/api/company/secondary-manager.api.service';
import { CalendarConnectionStatus } from '@app/domain/one_to_one/model/calendar-connected.model';
import { IState } from '@app/models/state/state.model';
import { IconHoverColor } from '@app/shared/components/inputs/table-action-icon/table-action-icon.component';
import { ButtonType } from '@app/shared/components/inputs/button/button.component';
import { validTimezones } from '@app/constants/timezone.constants';
import { AdminMessages } from '@app/admin/admin.messages';
import { FrankliValidators } from '@app/shared/validators/validators';
import { OneToOneFrequency } from '@app/domain/one_to_one/model/one-to-one-frequency.model';
import { TalkingPointTemplate } from '@app/domain/one_to_one/model/talking-point-template.model';
import { forkJoin, of } from 'rxjs';
import { SecondaryManager } from '@app/models/company/company-secondary-manager/secondary-manager.model';
import { CreateOneToOneSchedule } from '@app/domain/one_to_one/model/create-one-to-one-schedule.model';
import { TemplateModalComponent } from '../../component/template-modal/template-modal.component';
import { DateUtils } from '@app/shared/utils/date.utils';
import { UnderscoreToSpacePipe } from '@app/shared/pipes/underscore_to_space.pipe';
import { SpaceToUnderscorePipe } from '@app/shared/pipes/space_to_underscore.pipe';
import { OneToOneBusinessService } from '../../service/one-to-one-business.service';
import { UserMinimal } from '@app/models/user/user-minimal.model';
import { OneToOneMeetingStatus } from '../../model/one-to-one-meeting-status.model';
import { AvailabilityModalComponent } from '../../component/availability-modal/availability-modal.component';
import moment from 'moment';
import 'moment-timezone';
import { SentimentScaleBusinessService } from '@app/domain/sentiment_scale/service/sentiment-scale-business.service';
import { SentimentScale } from '@app/domain/sentiment_scale/model/sentiment-scale.model';
import { SentimentScaleMessages } from '@app/domain/sentiment_scale/locale/sentiment-scale.messages';
import { ButtonGroupOption } from '@app/shared/components/inputs/button-group/button-group.component';
import { OneToOneMeeting, OneToOneMeetingMinimal } from '../../model/one-to-one-meeting.model';
import { map } from 'rxjs/operators';
import { deepClone } from '@app/shared/utils/helpers';

interface PageState extends IState {
  submitted: boolean;
  submitting: boolean;
  datepickerLoading: boolean;
  editing: boolean;
}

@Component({
  selector: 'create-one-to-one',
  styleUrls: ['./create-one-to-one.component.scss'],
  templateUrl: './create-one-to-one.component.html',
  providers: [
    UnderscoreToSpacePipe,
    SpaceToUnderscorePipe
  ]
})
export class CreateOneToOneComponent implements OnInit, OnDestroy, RouteGuardUnsavedChanges, OnChanges {
  public readonly eSentimentScaleMessages = SentimentScaleMessages;
  public readonly eOneToOneMeetingLength = OneToOneMeetingLength;
  public readonly eScheduleLocationType = ScheduleLocationType;
  public readonly eOneToOneFrequency = OneToOneFrequency;
  public readonly eOneToOneMessages = OneToOneMessages;
  public readonly eCompanyFeatures = CompanyFeatures;
  public readonly eIconHoverColor = IconHoverColor;
  public readonly eCommonMessages = CommonMessages;
  public readonly eAdminMessages = AdminMessages;
  public readonly eButtonType = ButtonType;

  @ViewChild('availabilityModal') availabilityModal?: AvailabilityModalComponent;
  @ViewChild('componentTalkingPointTemplates') componentTalkingPointTemplates?: TemplateModalComponent;

  public formChanged: boolean;

  breadcrumb: Breadcrumb;
  state: PageState;

  dataLocationTypes: ScheduleLocationType[];
  dataFrequencies: OneToOneFrequency[];
  dataEvaluationCycles: EvaluationCycle[];
  dataSentimentScales: SentimentScale[];

  minDate: moment.Moment;
  maxDate: moment.Moment;

  timezoneMessage: string;
  forceIndividualFrequency: boolean;
  dataTimezones: string[];
  
  existingSchedulesLinkedToReviews: OneToOneScheduleDetailsView[];

  showJustNextMeetingCheckbox: boolean;
  optionsJustNextMeeting: ButtonGroupOption[];

  saved: boolean;

  locationPlaceholder: string;
  calendarConnectionStatus: CalendarConnectionStatus;
  secondaryManager: SecondaryManager;
  
  customFrequencyValid: boolean; // TODO: Replace this with proper form validators
  performanceReviewTemplate?: TalkingPointTemplate;
  
  form: FormGroup;
  scheduleEditing?: OneToOneScheduleDetailsView;
  latestMeeting: OneToOneMeeting;
  justNextMeetingValueCached: boolean;

  get controlScheduleId(): FormControl {
    return this.form.controls.id as FormControl;
  }

  get controlLocationType(): FormControl {
    return this.form.controls.locationType as FormControl;
  }

  get controlLocation(): FormControl {
    return this.form.controls.location as FormControl;
  }

  get controlLocationAutoCreateGoogle(): FormControl {
    return this.form.controls.locationAutoCreateGoogle as FormControl;
  }

  get controlTimezone(): FormControl {
    return this.form.controls.timezone as FormControl;
  }

  get controlFrequency(): FormControl {
    return this.form.controls.frequency as FormControl;
  }

  get controlMeetingTime(): FormControl {
    return this.form.controls.meetingTime as FormControl;
  }

  get controlMeetingLength(): FormControl {
    return this.form.controls.meetingLength as FormControl;
  }

  get controlLinkedEvaluationCycle(): FormControl {
    return this.form.controls.linkedEvaluationCycle as FormControl;
  }

  get controlPurpose(): FormControl {
    return this.form.controls.purpose as FormControl;
  }

  get controlRecurringRule(): FormControl {
    return this.form.controls.recurringRule as FormControl;
  }

  get controlScheduleParticipants(): FormControl {
    return this.form.controls.participants as FormControl;
  }

  get controlTalkingPointTemplateId(): FormControl {
    return this.form.controls.talkingPointTemplateId as FormControl;
  }

  get controlTalkingPointsRecurring(): FormControl {
    return this.form.controls.talkingPointsRecurring as FormControl;
  }

  get controlHasSentimentScale(): FormControl {
    return this.form.controls.hasSentimentScale as FormControl;
  }

  get controlSentimentScaleId(): FormControl {
    return this.form.controls.sentimentScaleId as FormControl;
  }

  get controlJustNextMeeting(): FormControl {
    return this.form.controls.justNextMeeting as FormControl;
  }

  get canSelectEvaluationCycle(): boolean {
    if (!this.globals.hasFeature(CompanyFeatures.EVALUATION_CYCLES)) { return false; } // Must have eval cycle feature enabled
    if (!this.controlScheduleParticipants.value) { return false; } // Participant must be selected
    if (this.controlScheduleParticipants.value.length !== 1) { return false; } // Must only be one participant selected
    if (this.controlScheduleParticipants.value[0].managerId === this.globals.user.id) { return true; } // You must be the manager of the selected participant
    if (this.controlFrequency.value !== OneToOneFrequency.Individual) { return false; } // Schedule frequency must be individual
    if (this.canSeeSecondaryManagerReviews) { return true; }

    return false;
  }

  get canSeeSecondaryManagerTalkingPoints(): boolean {
    if (!this.secondaryManager) { return false; }
    return this.secondaryManager.talkingPointTemplates;
  }

  get canSeeSecondaryManagerReviews(): boolean {
    if (!this.secondaryManager) { return false; }
    return this.secondaryManager.talkingPointTemplates;
  }

  get autoCreatingLocationDetails(): boolean {
    if (!this.controlLocationType.value) { return false; }
    if (this.controlLocationType.value !== ScheduleLocationType.GOOGLE_MEET) { return false; }
    if (this.controlScheduleId.value) { return false; }
    if (!this.controlLocationAutoCreateGoogle.value) { return false; }
    return true;
  }

  get shouldExplainFrequency(): boolean {
    if (this.controlFrequency.value === OneToOneFrequency.Monthly) { return true; }
    if (this.controlFrequency.value === OneToOneFrequency.Quarterly) { return true; }
    return false;
  }

  get shouldUseAvailabilityPicker(): boolean {
    if (!this.calendarConnectionStatus) { return; }
    if (!this.calendarConnectionStatus.connected) { return; }
    if (!this.availabilityModal) { return; }
    return true;
  }

  get selectedSentimentScaleObject(): SentimentScale {
    if (!this.controlSentimentScaleId.value) { return; }
    return this.dataSentimentScales.find(s => s.id === this.controlSentimentScaleId.value);
  }

  get showSentimentScaleOptions(): boolean {
    if (this.controlScheduleParticipants.value.length > 1) { return false; }
    if (!this.globals.hasFeature(CompanyFeatures.ONE_TO_ONE_SENTIMENT_SCALE)) { return false; }

    return true;
  }

  get isScheduleManager(): boolean {
    if (!this.scheduleEditing) { return true; } // If creating a new schedule, you are the manager
    return this.scheduleEditing.manager.id === this.globals.user.id;
  }

  constructor(
    public globals: Globals,
    public route: ActivatedRoute,
    private router: Router,
    private oneToOneBusinessService: OneToOneBusinessService,
    private breadcrumbService: BreadcrumbService,
    private calendarAPIService: CalendarAPIService,
    private translateService: TranslateService,
    private evaluationCyclesService: EvaluationCycleAPIService,
    private secondaryManagerService: SecondaryManagerAPIService,
    private userAPIService: UserAPIService,
    private swalUtils: SwalUtils,
    private underscoreToSpacePipe: UnderscoreToSpacePipe,
    private spaceToUnderscorePipe: SpaceToUnderscorePipe,
    private sentimentScaleBusinessService: SentimentScaleBusinessService
  ) {
    this.showJustNextMeetingCheckbox = false;
    this.forceIndividualFrequency = false;
    this.customFrequencyValid = true;
    this.optionsJustNextMeeting = this.getOptionsJustNextMeeting();
    this.formChanged = false;
    this.saved = false;
    this.scheduleEditing = undefined;
    this.latestMeeting = undefined;
    this.justNextMeetingValueCached = null;

    this.secondaryManager = undefined;
    this.scheduleEditing = undefined;

    this.dataLocationTypes = Object.values(ScheduleLocationType);
    this.dataFrequencies = Object.values(OneToOneFrequency);
    this.dataEvaluationCycles = [];
    this.dataSentimentScales = [];

    this.timezoneMessage = '';

    this.existingSchedulesLinkedToReviews = [];

    this.state = {
      loading: true,
      error: false,
      errorMessage: '',
      submitted: false,
      submitting: false,
      datepickerLoading: false,
      editing: false
    };

    this.calendarConnectionStatus = undefined;

    this.locationPlaceholder = '';

    this.dataTimezones = validTimezones.map(tz => this.underscoreToSpacePipe.transform(tz));
    this.form = this.initForm();

    // Init breadcrumb
    this.breadcrumb = this.breadcrumbService.initWithLabel(this.route, false, OneToOneMessages.CREATE_SCHEDULE_BREADCRUMB);
  }

  ngOnInit(): void {
    this.getData();
  }

  ngOnDestroy(): void {
    this.breadcrumbService.remove(this.breadcrumb);
  }

  ngOnChanges(): void {
    this.globals.hasUnsavedChanges = this.unsavedChanges();
  }

  getData(): void {
    const paramId = +this.route.snapshot.params.id;
    const paramUserIdsString = this.route.snapshot.queryParams.userId;
    let paramUserIds: number[] = [];
    if (paramUserIdsString) {
      paramUserIds = paramUserIdsString.split(',').map(id => +id);
    }
    const paramCycleId = +this.route.snapshot.queryParams.evaluationCycleId;
    const paramTemplateName = this.route.snapshot.queryParams.template;
    const paramAction = this.route.snapshot.url[0].path; // Create or edit

    forkJoin([
      this.oneToOneBusinessService.getOneToOneSchedulesLinkedToReviews(),
      this.oneToOneBusinessService.getTalkingPointTemplateByName('Performance Review Outcome')
    ])
      .toPromise()
      .then(([existingSchedulesLinkedToReviews, performanceReviewTemplate]) => {
        this.existingSchedulesLinkedToReviews = existingSchedulesLinkedToReviews;
        this.performanceReviewTemplate = performanceReviewTemplate;

        this.getCalendarConnected();

        switch (paramAction) {
          case 'create':
            this.state.editing = false;
            this.initFormCreate(paramTemplateName, paramUserIds, paramCycleId);
            break;
          case 'edit':
            this.state.editing = true;
            this.initFormEdit(paramId);
            break;
        }
      })
      .catch((err: HttpErrorResponse) => {
        if (err.status === 403) {
          this.router.navigate(['/one-to-one']);
          return;
        }

        this.state.loading = false;
      });
  }

  initFormCreate(templateName: string, userIds: number[], cycleId: number): void {
    forkJoin([
      (templateName ? this.oneToOneBusinessService.getTalkingPointTemplateByName(templateName) : this.oneToOneBusinessService.getDefaultTalkingPointTemplate()),
      (userIds && userIds.length === 1 ? this.evaluationCyclesService.getAllEvaluationCyclesForUserId(userIds[0]) : of([]))
    ])
      .toPromise()
      .then(async ([template, evaluationCycles]) => {
        await this.setParticipantFromQueryParam(userIds);
        await this.populateFormFromTalkingPointTemplate(template);
        await this.populateFormFromEvaluationCycleData(evaluationCycles, cycleId);
      })
      .finally(() => {
        this.state.loading = false;
      });
  }

  initFormEdit(scheduleEditingId: number): void {
    this.oneToOneBusinessService.getOneToOneScheduleDetailsById(scheduleEditingId)
      .toPromise()
      .then(schedule => {
        const canEdit: boolean = this.validateCanEditSchedule(schedule);
        if (!canEdit) {
          return;
        }
        this.scheduleEditing = schedule;
        this.form = this.initForm(schedule);
        this.getLinkedCycleOptions();
      });
  }

  validateCanEditSchedule(oneToOneSchedule: OneToOneScheduleDetailsView): boolean {
    if (!oneToOneSchedule) {
      this.swalUtils
        .displayErrorSwal({
          title: CommonMessages.EMPTY_STATE,
          confirmButtonText: CommonMessages.OK
        })
        .then(() => this.cancelAndReturnToOverview());
      return false;
    }

    // Cannot edit archived schedules
    if (oneToOneSchedule.status === OneToOneStatus.ARCHIVED) {
      this.swalUtils
        .displayErrorSwal({
          title: OneToOneMessages.CANNOT_EDIT_ARCHIVED,
          confirmButtonText: CommonMessages.OK
        })
        .then(() => this.cancelAndReturnToOverview());
      return false;
    }

    // Cannot edit schedules managed by other people
    // if (oneToOneSchedule.manager.id !== this.globals.user.id) {
    //   this.swalUtils
    //     .displayErrorSwal({
    //       title: OneToOneMessages.NO_PERMISSION_TO_EDIT,
    //       confirmButtonText: CommonMessages.OK
    //     })
    //     .then(() => this.cancelAndReturnToOverview());
    //   return;
    // }

    // Cannot edit schedule if the latest meeting is IN_PROGRESS
    const latestMeeting = oneToOneSchedule.meetingList[oneToOneSchedule.meetingList.length - 1];
    if (latestMeeting.status === OneToOneMeetingStatus.IN_PROGRESS) {
      this.swalUtils
        .displayErrorSwal({
          title: OneToOneMessages.CANNOT_EDIT_IN_PROGRESS,
          confirmButtonText: CommonMessages.OK
        })
        .then(() => this.cancelAndReturnToOverview());
      return false;
    }

    return true;
  }

  getLinkedCycleOptions(): void {
    if (this.controlScheduleParticipants.value.length !== 1) {
      console.warn('Cannot get linked cycle options without exactly one participant selected');
      this.dataEvaluationCycles = [];
      this.state.loading = false;
      return;
    }

    const participant: UserMinimal = this.controlScheduleParticipants.value[0];
    if (participant.managerId !== this.globals.user.id) {
      console.warn('Cannot get linked cycle options for a participant you do not manage');
      this.dataEvaluationCycles = [];
      this.state.loading = false;
      return;
    }

    this.evaluationCyclesService.getAllEvaluationCyclesForUserId(participant.id)
      .toPromise()
      .then(evaluationCycles => {
        this.populateFormFromEvaluationCycleData(evaluationCycles);
      })
      .finally(() => {
        this.state.loading = false;
      });
  }

  getSentimentScaleOptions(): void {
    this.sentimentScaleBusinessService.getAll(false)
      .toPromise()
      .then(sentimentScales => {
        this.dataSentimentScales = sentimentScales;
        if (this.controlHasSentimentScale.enabled) {
          this.controlSentimentScaleId.enable();
        }
        if (sentimentScales.length > 0 && this.controlSentimentScaleId.value === null) {
          this.controlSentimentScaleId.setValue(sentimentScales[0].id);
        }
      });
  }

  cancelAndReturnToOverview(): void {
    this.router.navigate(['/one-to-one']);
    this.formChanged = false;
  }

  populateFormFromTalkingPointTemplate(talkingPointTemplate: TalkingPointTemplate): TalkingPointTemplate {
    // If a certain template was used, set the purpose accordingly
    switch(talkingPointTemplate.name) {
      case 'Coaching':
        this.controlPurpose.setValue(this.translateService.instant(OneToOneMessages.COACHING_SESSION_PURPOSE));
        break;
      case 'Mentoring':
        this.controlPurpose.setValue(this.translateService.instant(OneToOneMessages.MENTORING_SESSION_PURPOSE));
        break;
      case 'Performance Review Outcome':
        this.controlPurpose.setValue(this.translateService.instant(OneToOneMessages.REVIEW_CYCLE_PURPOSE));
        break;
    }

    // If the template exists, populate the recommended talking points list
    const talkingPointsParsed = this.parseTalkingPointTemplateToTalkingPointsList(talkingPointTemplate);
    this.controlTalkingPointTemplateId.setValue(talkingPointTemplate.id);
    this.controlTalkingPointsRecurring.setValue(talkingPointsParsed);

    return talkingPointTemplate;
  }

  populateFormFromEvaluationCycleData(evaluationCycles: EvaluationCycle[], cycleId?: number): number {
    // Show only activated and closed cycles
    // TODO: this filter should be done on the backend
    this.dataEvaluationCycles = evaluationCycles.filter(c => [EvaluationCycleState.ACTIVATED].includes(c.state));

    // If no cycle ID as given as a query param, return
    if (!cycleId) {
      return null;
    }

    const cycleToLink = this.dataEvaluationCycles.find(c => c.id === cycleId);

    // If no cycle was found, return
    if (!cycleToLink) {
      console.warn('No cycle found with ID', cycleId);
      return null;
    }

    // If a cycle was found, populate the form accordingly
    this.controlLinkedEvaluationCycle.setValue(cycleId, { emitEvent: true });
    this.controlPurpose.setValue(this.translateService.instant(OneToOneMessages.LINKED_CYCLE_PURPOSE, {cycleName: cycleToLink.name}));
    this.forceIndividualFrequency = true;

    return cycleId;
  }

  getDataLocationTypes(): ScheduleLocationType[] {
    const sortedLocationTypes = [
      ScheduleLocationType.MICROSOFT_TEAMS,
      ScheduleLocationType.SLACK,
      ScheduleLocationType.IN_PERSON,
      ScheduleLocationType.ZOOM,
      ScheduleLocationType.GOOGLE_MEET,
      ScheduleLocationType.SKYPE,
      ScheduleLocationType.WEBEX,
      ScheduleLocationType.OTHER
    ];

    return sortedLocationTypes;
  }

  getCalendarConnected(): void {
    this.calendarAPIService.isConnected()
      .toPromise()
      .then(isConnected => {
        this.calendarConnectionStatus = isConnected;
      });
  }

  initForm(oneToOneSchedule?: OneToOneScheduleDetailsView): FormGroup {
    const defaultDate = moment().add(7, 'day').minute(0).second(0).millisecond(0).toDate();
    const defaultMeetingLength = OneToOneMeetingLength.THIRTY_MINUTES;
    const defaultTimezone = this.getDefaultTimezone();
    const validTimezonesWithSpaces = validTimezones.map(tz => this.underscoreToSpacePipe.transform(tz));
    const defaultFrequency = this.dataFrequencies[1];

    const formGroup = new FormGroup(
      {
        id: new FormControl(null, []),
        timezone: new FormControl(defaultTimezone, [Validators.required, FrankliValidators.isOneOf(validTimezonesWithSpaces)]), // TODO: This shouldn't be case sensitive
        locationType: new FormControl(null, [Validators.required]),
        location: new FormControl('', [Validators.maxLength(2000)]),
        locationAutoCreateGoogle: new FormControl(false, []),
        purpose: new FormControl('', [Validators.required, Validators.maxLength(255)]),
        meetingTime: new FormControl(defaultDate, Validators.required),
        meetingLength: new FormControl(defaultMeetingLength, Validators.required),
        linkedEvaluationCycle: new FormControl(null, this.selectedCycleUniqueForThisUser()),
        frequency: new FormControl(defaultFrequency, [Validators.required]),
        recurringRule: new FormControl('', []),
        participants: new FormControl([], [Validators.required, FrankliValidators.maxLengthArray(10)]),
        talkingPointsRecurring: new FormControl([], []),
        talkingPointTemplateId: new FormControl(null, []),
        hasSentimentScale: new FormControl(false, []),
        sentimentScaleId: new FormControl(null, []),
        justNextMeeting: new FormControl(false, [])
      }
    );
    
    formGroup.controls.hasSentimentScale.valueChanges.subscribe(hasSentimentScale => this.onSentimentScalesToggled(hasSentimentScale));
    formGroup.controls.linkedEvaluationCycle.valueChanges.subscribe(cycleId => this.onLinkedEvaluationCycleChange(cycleId, formGroup));
    formGroup.controls.timezone.valueChanges.subscribe(timezone => this.onTimezoneChange(timezone, formGroup));
    formGroup.controls.locationAutoCreateGoogle.valueChanges.subscribe(usingMeet => this.onLocationAutoCreateGoogleChange(usingMeet, formGroup));
    formGroup.controls.locationType.valueChanges.subscribe(locationType => this.onLocationTypeChange(locationType, formGroup));
    formGroup.controls.frequency.valueChanges.subscribe(frequency => this.onFrequencyChange(frequency, formGroup));
    formGroup.controls.participants.valueChanges.subscribe(participant => this.onParticipantsChange(participant));
    formGroup.controls.justNextMeeting.valueChanges.subscribe(val => this.onJustNextMeetingChanged(val, formGroup));
    formGroup.controls.talkingPointsRecurring.valueChanges.subscribe(() => {
      formGroup.controls.talkingPointsRecurring.markAsDirty();
    });

    if (oneToOneSchedule) {
      this.showJustNextMeetingCheckbox = (oneToOneSchedule.frequency !== OneToOneFrequency.Individual);

      const timezone = this.underscoreToSpacePipe.transform(oneToOneSchedule.timezone);

      formGroup.controls.id.setValue(oneToOneSchedule.id, { emitEvent: false });
      formGroup.controls.timezone.setValue(timezone, { emitEvent: false });
      formGroup.controls.locationType.setValue(oneToOneSchedule.locationType, { emitEvent: false });
      formGroup.controls.location.setValue(oneToOneSchedule.location, { emitEvent: false });
      formGroup.controls.purpose.setValue(oneToOneSchedule.purposeTitle, { emitEvent: false });
      formGroup.controls.meetingTime.setValue(oneToOneSchedule.startDateTime, { emitEvent: false });
      formGroup.controls.meetingLength.setValue(this.eOneToOneMeetingLength[oneToOneSchedule.meetingLength], { emitEvent: false });
      formGroup.controls.frequency.setValue(oneToOneSchedule.frequency);
      formGroup.controls.recurringRule.setValue(oneToOneSchedule.recurringRule, { emitEvent: false });
      formGroup.controls.participants.setValue(oneToOneSchedule.participants, { emitEvent: false });
      formGroup.controls.talkingPointsRecurring.setValue(deepClone(oneToOneSchedule.talkingPointsRecurring), { emitEvent: false });
      formGroup.controls.talkingPointTemplateId.setValue(oneToOneSchedule.talkingPointTemplateId, { emitEvent: false });

      if (oneToOneSchedule.sentimentScaleId !== null) {
        formGroup.controls.hasSentimentScale.setValue(true);
        formGroup.controls.sentimentScaleId.setValue(oneToOneSchedule.sentimentScaleId);
      }

      // Cannot change any scheduling details if the schedule is paused
      if (oneToOneSchedule.status === OneToOneStatus.PAUSED) {
        formGroup.controls.meetingLength.disable();
        formGroup.controls.meetingTime.disable();
        formGroup.controls.timezone.disable();
        formGroup.controls.frequency.disable();
        this.showJustNextMeetingCheckbox = false;
      }

      // Cannot edit schedule from FLEXIBLE to anything else or vice versa
      if (oneToOneSchedule.frequency === OneToOneFrequency.Flexible) {
        formGroup.controls.meetingTime.disable();
        formGroup.controls.frequency.disable();
      } else {
        this.dataFrequencies = Object.keys(OneToOneFrequency)
          .filter(k => k !== OneToOneFrequency.Flexible) as OneToOneFrequency[];
      }

      // If not managing, we need to disable some forms
      if (oneToOneSchedule.manager.id !== this.globals.user.id) {
        formGroup.controls.timezone.disable();
        formGroup.controls.locationType.disable();
        formGroup.controls.location.disable();
        formGroup.controls.purpose.disable();
        formGroup.controls.meetingTime.disable();
        formGroup.controls.meetingLength.disable();
        formGroup.controls.frequency.disable();
        formGroup.controls.recurringRule.disable();
        formGroup.controls.participants.disable();
        // formGroup.controls.talkingPointsRecurring.disable();
        // formGroup.controls.talkingPointTemplateId.disable();
        formGroup.controls.hasSentimentScale.disable();
        formGroup.controls.sentimentScaleId.disable();
      }

      this.justNextMeetingValueCached = null;
      formGroup.controls.justNextMeeting.setValue(true);
    }
    // formGroup.controls.hasSentimentScale.valueChanges.subscribe(hasSentimentScale => this.onSentimentScalesToggled(hasSentimentScale));

    formGroup.valueChanges.subscribe(() => {
      this.formChanged = true;
    });

    formGroup.controls.timezone.updateValueAndValidity();

    return formGroup;
  }

  onSentimentScalesToggled(hasSentimentScale: boolean): void {
    if (!hasSentimentScale) {
      this.controlSentimentScaleId.setValue(null);
      this.controlSentimentScaleId.disable();
    } else {
      this.getSentimentScaleOptions();
    }
  }

  getDefaultTimezone(): string {
    // if (this.globals && this.globals.user && this.globals.user.homeAddress && this.globals.user.homeAddress.timezone) {
    //   return this.globals.user.homeAddress.timezone;
    // }

    const guessedTimezone = moment.tz.guess();

    // FIXME: This is a quick fix for the issues Lisa was having around timezones
    // Her timezone was defaulting to "America/New_York" which is not a valid timezone apparently
    if (!validTimezones.includes(guessedTimezone)) {
      if (guessedTimezone.startsWith('America')) {
        return 'US/Eastern';
      }

      return 'Europe/Dublin';
    }

    return guessedTimezone;
  }

  onLinkedEvaluationCycleChange(cycleId: number, formGroup: FormGroup): void {
    if(cycleId == null) {
      formGroup.controls.frequency.enable();
      return;
    } else {
      formGroup.controls.frequency.setValue(OneToOneFrequency.Individual);
      formGroup.controls.frequency.disable();
    }

    const isUsingPerformanceReviewTemplate = this.checkIfUsingPerformanceOutcomeTemplate();
    if (isUsingPerformanceReviewTemplate) { return; }

    this.swalUtils.displaySuccessConfirmationSwal({
      title: 'Update talking points?',
      text: 'We noticed you have linked this 1:1 to a Review Cycle, would you like to update the Talking Point Template to match?',
      cancelButtonText: CommonMessages.NO,
      confirmButtonText: CommonMessages.YES
    }).then((result) => {
      if (result.isConfirmed) {
        this.trySetPerformanceReviewTemplate();
      }
    });
  }

  onTimezoneChange(timezone: string, formGroup: FormGroup): void {
    if (formGroup.controls.timezone.invalid) { return; }

    this.updateMeetingPickerDateLimits(timezone);

    this.timezoneMessage = this.translateService.instant(OneToOneMessages.TIMEZONE_MESSAGE, {timezoneName: timezone});
  }

  updateMeetingPickerDateLimits(timezone: string): void {
    this.state.datepickerLoading = true;

    const timezoneWithUnderscores = this.spaceToUnderscorePipe.transform(timezone);

    // Update min dates of meeting time picker
    const minDate = DateUtils.parseDateTimeToLocalTimezone(moment(), timezoneWithUnderscores);
    const maxDate = DateUtils.parseDateTimeToLocalTimezone(moment(), timezoneWithUnderscores).add(1, 'year');

    this.minDate = minDate;
    this.maxDate = maxDate;

    setTimeout(() => {
      this.state.datepickerLoading = false;
    }, 1);
  }

  onLocationTypeChange(locationType: ScheduleLocationType, formGroup: FormGroup): void {
    switch (locationType) {
      case ScheduleLocationType.IN_PERSON:
        formGroup.controls.location.setValidators(null);
        formGroup.controls.location.setValue('');
        this.locationPlaceholder = this.translateService.instant(OneToOneMessages.IN_PERSON_LOCATION_PLACEHOLDER);
        break;
      case ScheduleLocationType.MICROSOFT_TEAMS:
        formGroup.controls.location.setValidators(null);
        // formGroup.controls.location.setValue(this.translateService.instant(OneToOneMessages.TEAMS_LOCATION));
        formGroup.controls.location.setValue('');
        this.locationPlaceholder = this.translateService.instant(OneToOneMessages.TEAMS_LOCATION_PLACEHOLDER);
        break;
      case ScheduleLocationType.OTHER:
        formGroup.controls.location.setValidators([Validators.required]);
        formGroup.controls.location.setValue('');
        this.locationPlaceholder = this.translateService.instant(OneToOneMessages.OTHER_LOCATION_PLACEHOLDER);
        break;
      case ScheduleLocationType.SKYPE:
        formGroup.controls.location.setValidators(null);
        formGroup.controls.location.setValue('');
        this.locationPlaceholder = this.translateService.instant(OneToOneMessages.SKYPE_LOCATION_PLACEHOLDER);
        break;
      case ScheduleLocationType.SLACK:
        formGroup.controls.location.setValidators(null);
        formGroup.controls.location.setValue(this.translateService.instant(OneToOneMessages.SLACK_LOCATION));
        this.locationPlaceholder = this.translateService.instant(OneToOneMessages.SLACK_LOCATION_PLACEHOLDER);
        break;
      case ScheduleLocationType.ZOOM:
        formGroup.controls.location.setValidators(null);
        formGroup.controls.location.setValue('');
        this.locationPlaceholder = this.translateService.instant(OneToOneMessages.ZOOM_LOCATION_PLACEHOLDER);
        break;
      case ScheduleLocationType.GOOGLE_MEET:
        formGroup.controls.location.setValidators(null);
        formGroup.controls.location.setValue('');
        this.locationPlaceholder = this.translateService.instant(OneToOneMessages.MEET_LOCATION_PLACEHOLDER);
        break;
      case ScheduleLocationType.WEBEX:
        formGroup.controls.location.setValidators(null);
        formGroup.controls.location.setValue('');
        this.locationPlaceholder = this.translateService.instant(OneToOneMessages.WEBEX_LOCATION_PLACEHOLDER);
        break;
    }

    // this.controlLocation.updateValueAndValidity();

    const generateGoogleMeetLink = ((locationType === ScheduleLocationType.GOOGLE_MEET) && (this.calendarConnectionStatus && this.calendarConnectionStatus.connected && this.calendarConnectionStatus.provider === SSOProvider.GOOGLE));
    formGroup.controls.locationAutoCreateGoogle.setValue(generateGoogleMeetLink);
  }

  onLocationAutoCreateGoogleChange(usingMeet: boolean, formGroup: FormGroup): void {
    if (!usingMeet) {
      formGroup.controls.location.enable();
      return;
    }

    formGroup.controls.location.setValue('Automatically creating google meet link');
    formGroup.controls.location.disable();
  }

  onFrequencyChange(frequency: string, formGroup: FormGroup): void {
    if (frequency === OneToOneFrequency.Custom) {
      if (!formGroup.controls.recurringRule.value || formGroup.controls.recurringRule.value === '') {
        formGroup.controls.recurringRule.setValue('FREQ=DAILY;INTERVAL=1;');
      }
    }

    if (frequency === OneToOneFrequency.Flexible) {
      formGroup.controls.meetingTime.setValidators([]);
    } else {
      formGroup.controls.meetingTime.setValidators([Validators.required]);
    }

    formGroup.controls.meetingTime.updateValueAndValidity();
  }

  onParticipantsChange(participants: UserMinimal[]): void {
    this.controlLinkedEvaluationCycle.setValue(null);
    this.getLinkedCycleOptions();
    this.getSecondaryManagerData(participants);

    if (participants.length > 1) {
      this.controlHasSentimentScale.setValue(false);
    }
  }

  setParticipantFromQueryParam(userIds: number[]): Promise<UserMinimal[]> {
    if (!userIds) { return null; }
    if (userIds.length === 0) { return; }
    
    return this.userAPIService.getUsersByIds(userIds)
      .toPromise()
      .then(users => {
        if (users === null) { return null; }
        if (users.length === 0) { return null; }
        this.controlScheduleParticipants.setValue(users);
        this.onParticipantsChange(users);
        return users;
      });
  }

  selectedCycleUniqueForThisUser(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (control.value) {
        const otherSchedulesWithThisUserAndCycleCombo = this.existingSchedulesLinkedToReviews.filter(s => (s.participants[0].id === this.controlScheduleParticipants.value.id) && (s.evaluationCycleId === control.value.id));

        if (otherSchedulesWithThisUserAndCycleCombo.length > 0) {
          return { notUnique: {value: control.value} };
        }
      }

      return null;
    };
  }

  onClickSave(): string {
    this.state.submitted = true;

    if (this.state.submitting) { return; }
    if (this.form.invalid) { return; }

    const customFormValid = (this.controlFrequency.value !== OneToOneFrequency.Custom || (this.controlFrequency.value === OneToOneFrequency.Custom && this.customFrequencyValid));
    if (!customFormValid) { return; }

    this.state.submitting = true;

    const participantIds = this.controlScheduleParticipants.value.map(p => p.id);

    const talkingPointsOrdered = this.refreshTalkingPointOrderIndices(this.controlTalkingPointsRecurring.value);
    this.controlTalkingPointsRecurring.setValue(talkingPointsOrdered);

    const timezoneWithUnderscores = this.spaceToUnderscorePipe.transform(this.controlTimezone.value);
    let startDateTime = null;
    if (this.controlMeetingTime.value) {
      startDateTime = DateUtils.parseDateTimeToUTC(this.controlMeetingTime.value, timezoneWithUnderscores).format(DateUtils.DEFAULT_DATE_FORMAT);
    }

    const createOneToOneSchedule: CreateOneToOneSchedule = {
      participantIds: participantIds,
      purpose: this.controlPurpose.value,
      locationType: this.controlLocationType.value,
      locationDetails: this.controlLocation.value,
      startDateTime: startDateTime,
      meetingLength: this.controlMeetingLength.value,
      timezone: timezoneWithUnderscores,
      frequency: this.controlFrequency.value,
      recurringRule: this.controlRecurringRule.value,
      talkingPointsRecurring: this.controlTalkingPointsRecurring.value,
      talkingPointTemplateId: this.controlTalkingPointTemplateId.value,
      evaluationCycleId: this.controlLinkedEvaluationCycle.value ? this.controlLinkedEvaluationCycle.value : null,
      justNextMeeting: this.controlJustNextMeeting.value,
      sentimentScaleId: this.controlHasSentimentScale.value ? this.controlSentimentScaleId.value : null
    };

    const id = this.controlScheduleId.value;

    if (id) {
      this.doEditSchedule(id, createOneToOneSchedule);
      return 'edit';
    } else {
      if (this.controlLocationAutoCreateGoogle.value) {
        createOneToOneSchedule.locationDetails = 'AUTO_CREATE';
      }

      this.doCreateSchedule(createOneToOneSchedule);
      return 'create';
    }
  }

  doCreateSchedule(createOneToOneSchedule: CreateOneToOneSchedule): void {
    this.oneToOneBusinessService.createSchedule(createOneToOneSchedule)
      .then(() => {
        this.formChanged = false;
        this.router.navigate(['/one-to-one']);
      })
      .finally(() => {
        this.state.submitting = false;
      });
  }

  doEditSchedule(id: number, createOneToOneSchedule: CreateOneToOneSchedule): void {
    this.oneToOneBusinessService.updateSchedule(id, createOneToOneSchedule)
      .then(() => {
        this.formChanged = false;
        this.router.navigate(['/one-to-one/', id]);
      })
      .finally(() => {
        this.state.submitting = false;
      });
  }

  getSecondaryManagerData(participants: UserMinimal[]): void {
    if (!this.globals.hasFeature(CompanyFeatures.SECONDARY_MANAGER)) { return; } // Must have secondary manager feature enabled
    if (!this.globals.hasRole(RoleName.SECONDARY_MANAGER)) { return; } // Must be a secondary manager
    if (!participants) { return; } // Must have participants
    if (participants.length === 0) { return; } // Must have participants
    if (participants.length > 1) { return; } // Must only have one participant

    const participantId = participants[0].id;

    this.secondaryManagerService.getSecondaryManagerForUser(participantId, this.globals.user.id)
      .toPromise()
      .then(secondaryManager => {
        this.secondaryManager = secondaryManager;
      });
  }

  tryShowAvailabilityPopup(event?: MouseEvent): void {
    if (!this.shouldUseAvailabilityPicker) { return; }
    event.preventDefault();
    this.availabilityModal.show();
  }

  clearTalkingPoints(): void {
    this.swalUtils.displayWarningConfirmationSwal({
      title: CommonMessages.WARNING_PROMPT,
      text: OneToOneMessages.CLEAR_ALL_WARNING,
      cancelButtonText: CommonMessages.CANCEL,
      confirmButtonText: CommonMessages.OK
    }).then((result) => {
      if (result.isConfirmed) {
        this.controlTalkingPointsRecurring.setValue([]);
      }
    });
  }

  unsavedChanges(): boolean {
    return this.formChanged;
  }

  getMonthlyText() {
    const date = moment(this.controlMeetingTime.value).toDate();
    return this.getOrdinal(Math.ceil(date.getDate() / 7)) + ' ' + moment(date).format('dddd');
  }

  getOrdinal(n: number): string {
    switch (n) {
      case 1:
        return this.translateService.instant(OneToOneMessages.FIRST);
      case 2:
        return this.translateService.instant(OneToOneMessages.SECOND);
      case 3:
        return this.translateService.instant(OneToOneMessages.THIRD);
      case 4:
        return this.translateService.instant(OneToOneMessages.FOURTH);
      case 5:
        return this.translateService.instant(OneToOneMessages.LAST);
      default:
        return '';
    }
  }

  cancelCreate() {
    this.router.navigate(['/one-to-one']);
  }

  formatOption(o: ScheduleLocationType) {
    switch(o) {
      case ScheduleLocationType.IN_PERSON:
        return OneToOneMessages.IN_PERSON;
      case ScheduleLocationType.OTHER:
        return OneToOneMessages.OTHER;
      default:
        return this.underscoreToSpacePipe.transform(o.charAt(0) + o.slice(1).toLowerCase());
    }
  }

  availabilityTimeChanged(val: Date) {
    this.controlMeetingTime.setValue(val);
  }

  availabilityLengthChanged(val: number) {
    this.controlMeetingLength.setValue(val);
  }

  onCustomFrequencyChange($event: boolean) {
    this.customFrequencyValid = $event;
  }

  refreshTalkingPointOrderIndices(talkingPoints: TalkingPoint[]): TalkingPoint[] {
    return talkingPoints.map(
      (tp, index) => {
        tp.orderIndex = index;
        return tp;
      }
    );
  }

  setGoogleLinkManually(): void {
    this.controlLocationAutoCreateGoogle.setValue(false);
    this.controlLocation.setValue('');
  }

  showTalkingPointTemplates(): void {
    if (!this.componentTalkingPointTemplates) { return; }
    this.componentTalkingPointTemplates.showModal();
  }

  onSelectTemplateFromRecommended(template: TalkingPointTemplate): void {
    if (!template) { return; }

    // Populate talking points from template and store the ID of the template used
    this.controlTalkingPointTemplateId.setValue(template.id);
    const talkingPoints = this.parseTalkingPointTemplateToTalkingPointsList(template);

    this.controlTalkingPointsRecurring.setValue(talkingPoints);
  }

  onTalkingPointsChanged(event: TalkingPoint[]): void {
    this.controlTalkingPointsRecurring.setValue(event);
  }

  parseTalkingPointTemplateToTalkingPointsList(template: TalkingPointTemplate): TalkingPoint[] {
    return template.talkingPoints
      .sort((a, b) => a.orderIndex - b.orderIndex)
      .map(talkingPoint => {
        const newItem: TalkingPoint = {
          actioned: false,
          title: talkingPoint.text,
          fromPrevious: false,
          sourceUserId: this.globals.user.id,
          recurring: true,
          comments: []
        };
        return newItem;
      });
  }
  
  checkIfUsingPerformanceOutcomeTemplate(): boolean {
    if (this.performanceReviewTemplate === null) { return false; }
    if (this.controlTalkingPointTemplateId.value === this.performanceReviewTemplate.id) { return true; }
    return false;
  }

  trySetPerformanceReviewTemplate(): void {
    if (!this.performanceReviewTemplate) { return; }
    if (this.controlTalkingPointTemplateId.value === this.performanceReviewTemplate.id) { return; }

    this.controlTalkingPointsRecurring.setValue([]);
    this.controlTalkingPointTemplateId.setValue(this.performanceReviewTemplate.id);
    this.controlTalkingPointsRecurring.setValue(this.parseTalkingPointTemplateToTalkingPointsList(this.performanceReviewTemplate));
  }

  onClickTimezoneInput(): void {
    this.controlTimezone.setValue('');
  }

  getOptionsJustNextMeeting(): ButtonGroupOption[] {
    return [
      {
        value: true,
        friendlyText: this.translateService.instant(OneToOneMessages.JUST_NEXT_MEETING_TITLE_NEXT_MEETING),
        iconClasses: 'fa-calendar-day',
        tooltip: undefined,
        color: undefined
      },
      {
        value: false,
        friendlyText: this.translateService.instant(OneToOneMessages.JUST_NEXT_MEETING_TITLE_WHOLE_SCHEDULE),
        iconClasses: 'fa-calendar-alt',
        tooltip: undefined,
        color: undefined
      }
    ];
  }

  onJustNextMeetingChanged(_justNextMeeting: boolean, _formGroup: FormGroup): void {
    if (!this.scheduleEditing) { return; }
    if (_justNextMeeting === null) { return; }
    if (this.justNextMeetingValueCached === _justNextMeeting) { return; }

    if (this.justNextMeetingValueCached === null) {
      this.updateJustNextMeetingData(_justNextMeeting, _formGroup);
      return;
    }

    if (this.controlTalkingPointsRecurring.pristine && this.controlMeetingTime.pristine) {
      this.updateJustNextMeetingData(_justNextMeeting, _formGroup);
      return;
    }

    this.swalUtils.displayWarningConfirmationSwal({
      text: OneToOneMessages.JUST_NEXT_MEETING_CONFIRMATION
    })
      .then(res => {
        if(res.isConfirmed) {
          this.updateJustNextMeetingData(_justNextMeeting, _formGroup);
        } else {
          let valueReset = this.justNextMeetingValueCached;
          if (valueReset === null) {
            valueReset = this.state.editing;
          }
          _formGroup.controls.justNextMeeting.setValue(valueReset);
        }
      });
  }

  updateJustNextMeetingData(_justNextMeeting: boolean, _formGroup: FormGroup): void {
    this.latestMeeting = undefined;

    this.justNextMeetingValueCached = _justNextMeeting;
    if (_justNextMeeting === true) {
      if (this.scheduleEditing.meetingList.length === 0) { return; }

      const latestMeetingId = this.scheduleEditing.meetingList[this.scheduleEditing.meetingList.length - 1].id;

      this.oneToOneBusinessService.getMeetingById(this.scheduleEditing.id, latestMeetingId)
        .toPromise()
        .then(meeting => {
          this.latestMeeting = meeting;
          _formGroup.controls.meetingTime.setValue(this.latestMeeting.meetingTimestamp);
          _formGroup.controls.talkingPointsRecurring.setValue(this.latestMeeting.talkingPoints);
          this.markJustNextMeetingFormsAsPristine(_formGroup);
        });
  
    } else {
      _formGroup.controls.meetingTime.setValue(this.scheduleEditing.startDateTime);
      _formGroup.controls.talkingPointsRecurring.setValue(this.scheduleEditing.talkingPointsRecurring);
      this.markJustNextMeetingFormsAsPristine(_formGroup);
    }
  }

  markJustNextMeetingFormsAsPristine(_formGroup: FormGroup): void {
    _formGroup.controls.meetingTime.markAsPristine();
    _formGroup.controls.talkingPointsRecurring.markAsPristine();
  }
}
