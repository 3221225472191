import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '@app/shared/shared.module';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { PaginationModule } from '@app/shared/pagination/pagination.module';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { TeamTasksComponent } from '../pages/team-tasks/team-tasks.component';
import { TeamTasksRowComponent } from '../component/team-tasks-row/team-tasks-row.component';
import { FilteredTaskListComponent } from '../component/filtered-task-list/filtered-task-list.component';
import { TaskStatsComponent } from '../component/task-stats/task-stats.component';

const components = [
  TeamTasksComponent,
  TeamTasksRowComponent,
  FilteredTaskListComponent,
  FilteredTaskListComponent,
  TaskStatsComponent
];

@NgModule({
  declarations: components,
  exports: components,
  imports: [
    CommonModule,
    RouterModule,
    TranslateModule,
    TooltipModule,
    PaginationModule,
    DragDropModule,
    SharedModule
  ]
})
export class TaskComponentsModule { }
