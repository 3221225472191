import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Qualification } from '../../model/qualification.model';

interface ClickActionEvent {
  event: string,
  qualification: Qualification
}

@Component({
  selector: 'app-qualifications-list-display',
  templateUrl: './qualifications-list-display.component.html',
  styleUrls: ['./qualifications-list-display.component.scss']
})
export class QualificationsListDisplayComponent{

  @Input() qualifications: Qualification[];
  @Output() onClickItem: EventEmitter<ClickActionEvent>;

  constructor() {
    this.qualifications = [];
    this.onClickItem = new EventEmitter<ClickActionEvent>();
  }

  onClickItemAction(event: string, qualification: Qualification): void {
    this.onClickItem.emit({event, qualification});
  }
}
