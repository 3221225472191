import { Injectable } from '@angular/core';
import { SentimentScaleAPIService } from '../api/sentiment-scale-api.service';
import { NotifyUtils } from '@app/shared/utils/notify.utils';
import { Observable } from 'rxjs';
import { CreateSentimentScale } from '../model/create-sentiment-scale.model';
import { SentimentScale } from '../model/sentiment-scale.model';
import { SentimentScaleMessages } from '../locale/sentiment-scale.messages';
import { map } from 'rxjs/operators';
import { FrankliHttpErrorResponse } from '@app/models/exception/frankli-http-error-response.model';
import { SwalUtils } from '@app/shared/utils/swal.utils';
import { ErrorCode } from '@app/models/exception/error-codes.model';


@Injectable({
  providedIn: 'root'
})
export class SentimentScaleBusinessService {

  private static _updatingScales: number[];

  constructor(
    private sentimentScaleAPIService: SentimentScaleAPIService,
    private notifyUtils: NotifyUtils,
    private swalUtils: SwalUtils
  ) {
    SentimentScaleBusinessService._updatingScales = [];
  }

  public isModifyingScale(scheduleId: number): boolean {
    return SentimentScaleBusinessService._updatingScales.includes(scheduleId);
  }

  private startUpdatingScale(scheduleId: number): void {
    SentimentScaleBusinessService._updatingScales.push(scheduleId);
  }

  private finishUpdatingScale(scheduleId: number): void {
    SentimentScaleBusinessService._updatingScales = SentimentScaleBusinessService._updatingScales.filter(tid => tid !== scheduleId);
  }

  create(createDto: CreateSentimentScale): Promise<SentimentScale> {
    return this.sentimentScaleAPIService.create(createDto)
      .toPromise()
      .then(res => SentimentScaleBusinessService.parseSentimentScale(res))
      .then(res => {
        this.notifyUtils.notify(SentimentScaleMessages.CREATE_SUCCESS_TOAST);
        return res;
      });
  }

  update(id: number, updateDto: CreateSentimentScale): Promise<SentimentScale> {
    if (this.isModifyingScale(id)) { return new Promise((resolve, reject) => reject()); }
    this.startUpdatingScale(id);

    return this.sentimentScaleAPIService.update(id, updateDto, false)
      .toPromise()
      .then(res => SentimentScaleBusinessService.parseSentimentScale(res))
      .then(res => {
        this.notifyUtils.notify(SentimentScaleMessages.EDIT_SUCCESS_TOAST);
        return res;
      })
      .catch((err: FrankliHttpErrorResponse) => {
        if (err?.error?.errorCode === ErrorCode.SENTIMENT_SCALE_CANNOT_MODIFY_BECAUSE_USED) {
          // Show confirmation modal asking if they want to update the sentiment scale anyways and explaining what will happen if they continue
          return this.swalUtils.displayWarningConfirmationSwal({
            title: 'This sentiment scale is already in use. Would you like to replace it?',
            text: 'This will archive the current version and create a copy with all the changes made. Any schedules using this scale will be updated to use te new scale.'
          }).then(res => {
            // Finish running quietly and swallow the previous error
            if (!res.isConfirmed) {
              return null;
            }

            return this.sentimentScaleAPIService.update(id, updateDto, true)
              .toPromise()
              .then(res => SentimentScaleBusinessService.parseSentimentScale(res))
              .then(res => {
                this.notifyUtils.notify(SentimentScaleMessages.EDIT_SUCCESS_TOAST);
                return res;
              })
              .catch(err => {
                console.error('Failed to update sentiment scale');
                throw err;
              });
          });
        } else {
          throw err;
        }
      })
      .finally(() => {
        this.finishUpdatingScale(id);
      });
  }

  archive(id: number): Promise<SentimentScale> {
    if (this.isModifyingScale(id)) { return new Promise((resolve, reject) => reject()); }
    this.startUpdatingScale(id);

    return this.sentimentScaleAPIService.archive(id)
      .toPromise()
      .then(res => SentimentScaleBusinessService.parseSentimentScale(res))
      .then(res => {
        this.notifyUtils.notify(SentimentScaleMessages.ARCHIVE_SUCCESS_TOAST);
        return res;
      })
      .finally(() => {
        this.finishUpdatingScale(id);
      });
  }

  unarchive(id: number): Promise<SentimentScale> {
    if (this.isModifyingScale(id)) { return new Promise((resolve, reject) => reject()); }
    this.startUpdatingScale(id);

    return this.sentimentScaleAPIService.unarchive(id)
      .toPromise()
      .then(res => SentimentScaleBusinessService.parseSentimentScale(res))
      .then(res => {
        this.notifyUtils.notify(SentimentScaleMessages.UNARCHIVE_SUCCESS_TOAST);
        return res;
      })
      .finally(() => {
        this.finishUpdatingScale(id);
      });
  }

  getAll(_includeArchived?: boolean): Observable<SentimentScale[]> {
    return this.sentimentScaleAPIService.getAll(_includeArchived)
      .pipe(map(scales => scales.map(SentimentScaleBusinessService.parseSentimentScale)));
  }

  getOne(id: number): Observable<SentimentScale> {
    return this.sentimentScaleAPIService.getById(id)
      .pipe(map(SentimentScaleBusinessService.parseSentimentScale));
  }

  getByIdIn(ids: number[]): Observable<SentimentScale[]> {
    return this.sentimentScaleAPIService.getByIdIn(ids)
      .pipe(map(scales => scales.map(SentimentScaleBusinessService.parseSentimentScale)));
  }

  public static parseSentimentScale(sentimentScale: SentimentScale): SentimentScale {
    // Sort options by orderIndex
    sentimentScale.options = sentimentScale.options.sort((a, b) => a.orderIndex - b.orderIndex);

    return sentimentScale;
  }
}
