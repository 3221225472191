import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

interface LikertValue {
  selection: number;
  comment: string;
}

@Component({
  selector: 'app-likert-scale',
  templateUrl: './likert-scale.component.html',
  styleUrls: ['./likert-scale.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => LikertScaleComponent),
    multi: true,
  }]
})
export class LikertScaleComponent implements ControlValueAccessor, OnInit {
  @Input() disabled: boolean;
  @Input() display: boolean;
  @Input() commentEnabled: boolean;
  @Input() commentMaxLength: number | null = null;
  @Input() commentMinLength: number | null = null;
  @Input() showIcons: boolean;
  @Input() columnIndent: boolean;
  @Input() circleSize: number;
  @Input() circleBorderWidth: number;
  @Input() circleIconSize: number;
  @Input() labelFontSize: number;
  @Input() wrapWidth: number;
  sentences = ['No rating', 'Strongly disagree', 'Disagree', 'Neutral', 'Agree', 'Strongly agree'];

  comment: string;
  selection?: number;
  _value: LikertValue | number;

  onChange = (_: any) => {};
  onTouched = () => {};

  constructor() {
    this.disabled = false;
    this.display = false;
    this.comment = '';
    this.commentEnabled = false;
    this.showIcons = true;
    this._value = 0;
    this.columnIndent = true;
    this.circleSize = 80;
    this.circleBorderWidth = 6;
    this.circleIconSize = 50;
    this.labelFontSize = 18;
    this.wrapWidth = 400;
  }

  ngOnInit() {
    if (!this.selection) {
      this.selection = this.disabled ? 3 : undefined;
    }
  }

  get value(): (number | LikertValue) {
    return this._value;
  }

  set value(v: (number | LikertValue)) {
    if (v !== this._value) {
      this._value = v;
      if (this.commentEnabled) {
        const value = v as LikertValue;
        if (value) {
          this.comment = value.comment;
          this.selection = +value.selection;
        }
      } else {
        this.selection = +v;
      }

      this.onChange(v);
    }
  }

  // this will populate the value with the one set in the FormControl / ngModel
  writeValue(value: any) {
    this.value = value;
  }

  // Register callbacks
  registerOnChange(fn: (_: any) => {}): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  select(index: number) {
    if (this.disabled || this.display) { return; }
    if (this.commentEnabled) {
      this.value = {
        selection: index,
        comment: this.comment
      };
    } else {
      this.value = index;
    }
  }

  commentChanged(c: any) {
    if (this.disabled || this.display) { return; }
    if (this.commentEnabled) {
      this.value = {
        selection: this.selection!,
        comment: this.comment
      };
    }
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }
}
