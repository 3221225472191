import { RoleApplicationConfigurationRuleType } from '@app/domain/role_application_configuration/model/role-application-configuration-rule-type.model';

export const RoleApplicationMessages = {
  ROLE_APPLICATIONS: 'role-application.role-applications',
  ROLE_APPLICATIONS_DESCRIPTION: 'role-application.role-applications-description',
  APPLICATION_DESIGNER: 'role-application.application-designer',
  APPLICATION_DESIGNER_DESCRIPTION: 'role-application.application-designer-description',
  SUCCESS_CREATE_CONFIGURATION: 'role-application.success-create-configuration',
  SUCCESS_CREATE_APPLICATION: 'role-application.success-create-application',
  SUCCESS_UPDATE: 'role-application.success-update',
  SUCCESS_DELETE: 'role-application.success-delete',
  SUCCESS_ANSWERS_SAVED: 'role-application.success-answers-saved',
  SUCCESS_SUBMIT: 'role-application.success-submit',
  SUCCESS_DISCARD: 'role-application.success-discard',
  SUCCESS_REJECT: 'role-application.success-reject',
  SUCCESS_FINALIZE: 'role-application.success-finalize',
  SUCCESS_FINALIZE_APPROVED: 'role-application.success-finalize-approved',
  SUCCESS_ADD_PRIMARY_REVIEWER: 'role-application.success-add-primary-reviewer',
  SUCCESS_ADD_REVIEWERS: 'role-application.success-update-reviewers',
  SUCCESS_COMMENT_ADDED: 'role-application.success-comment-added',
  SUCCESS_SETTINGS_UPDATED: 'role-application.success-settings-updated',
  CONFIRMATION_DELETE_CONFIGURATION: 'role-application.confirmation-delete-configuration',
  CONFIRMATION_DELETE_APPLICATION: 'role-application.confirmation-delete-application',
  CONFIRMATION_DISCARD_APPLICATION: 'role-application.confirmation-discard-application',
  DEFAULT_CONFIGURATION: 'role-application.default-configuration',
  DEFAULT_CONFIGURATION_DESCRIPTION: 'role-application.default-configuration-description',
  EXISTING_CONFIGURATIONS: 'role-application.existing-configurations',
  CTA_CREATE: 'role-application.cta-create',
  EMPTY_STATE_ALL_APPLICATIONS: 'role-application.empty-state-all-applications',
  EMPTY_STATE_FILTERS_APPLICATIONS: 'role-application.empty-state-filters-applications',
  EMPTY_STATE_ALL_CONFIGURATIONS: 'role-application.empty-state-all-configurations',
  EMPTY_STATE_FILTERS_CONFIGURATIONS: 'role-application.empty-state-filters-configurations',
  HEADER_CREATING: 'role-application.header-creating',
  HEADER_EDITING: 'role-application.header-editing',
  APPLICANT_QUESTION_TEMPLATE: 'role-application.applicant-question-template',
  PRIMARY_REVIEWER_QUESTION_TEMPLATE: 'role-application.primary-reviewer-question-template',
  PRIMARY_REVIEWER: 'role-application.primary-reviewer',
  SECONDARY_REVIEWERS: 'role-application.secondary-reviewers',
  CONFIGURATION_RULES: 'role-application.configuration-rules',
  CONFIGURATION_RULES_DESCRIPTION: 'role-application.configuration-rules-description',
  CONFIGURATION_QUESTION_TEMPLATES: 'role-application.configuration-question-templates',
  CONFIGURATION_QUESTION_TEMPLATES_DESCRIPTION: 'role-application.configuration-question-templates-description',
  CONFIGURATION_REVIEWERS: 'role-application.configuration-reviewers',
  CONFIGURATION_REVIEWERS_DESCRIPTION: 'role-application.configuration-reviewers-description',
  ADD_A_RULE: 'role-application.add-a-rule',
  RULE_TYPE: 'role-application.rule-type',
  RULE_TYPE_PLACEHOLDER: 'role-application.rule-type-placeholder',
  RULE_TYPE_OPTION: {
    [RoleApplicationConfigurationRuleType.USER_ACCESS_LEVEL]: 'role-application.rule-type-option.user-access-level',
    [RoleApplicationConfigurationRuleType.USER_DEPARTMENT_ID]: 'role-application.rule-type-option.user-department-id',
    [RoleApplicationConfigurationRuleType.USER_SITE_ID]: 'role-application.rule-type-option.user-site-id',
    [RoleApplicationConfigurationRuleType.APPLIED_ROLE_ID]: 'role-application.rule-type-option.applied-role-id',
    [RoleApplicationConfigurationRuleType.APPLIED_ROLE_NAME]: 'role-application.rule-type-option.applied-role-name',
  },
  RULE_VALUE: 'role-application.rule-value',
  RULES_MAX_LENGTH: 'role-application.rules-max-length',
  PLEASE_SELECT_RULE_TYPE: 'role-application.please-select-rule-type',
  PLACEHOLDER_ROLE_CONTAINS: 'role-application.placeholder-role-contains',
  TOOLTIP_VIEW_APPLICATION: 'role-application.tooltip-view-application',
  TOOLTIP_EDIT_APPLICATION: 'role-application.tooltip-edit-application',
  TOOLTIP_DELETE_APPLICATION: 'role-application.tooltip-delete-application',
  TOOLTIP_DISCARD_APPLICATION: 'role-application.tooltip-discard-application',
  TOOLTIP_EDIT_CONFIGURATION: 'role-application.tooltip-edit-configuration',
  TOOLTIP_COPY_CONFIGURATION: 'role-application.tooltip-copy-configuration',
  TOOLTIP_DELETE_CONFIGURATION: 'role-application.tooltip-delete-configuration',
  START_APPLICATION: 'role-application.start-application',
  APPLICATIONS_CREATED: 'role-application.applications-created',
  APPLICATIONS_REVIEWING: 'role-application.applications-reviewing',
  APPLICATIONS_MANAGING: 'role-application.applications-managing',
  APPLICATIONS_ALL: 'role-application.applications-all',
  PLACEHOLDER_SEARCH_APPLICATION: 'role-application.placeholder-search-application',
  PLACEHOLDER_SEARCH_ALL_APPLICATIONS: 'role-application.placeholder-search-all-applications',
  PLACEHOLDER_SEARCH_CONFIGURATION: 'role-application.placeholder-search-configuration',
  ROLE_APPLICATION_DETAILS: 'role-application.role-application-details',
  ROLE_APPLICATION_DETAILS_DESCRIPTION: 'role-application.role-application-details-description',
  COULD_NOT_START_APPLICATION: 'role-application.could-not-start-application',
  NO_VALID_CONFIGURATION_DESCRIPTION: 'role-application.no-valid-configurations-description',
  ALREADY_APPLIED_FOR_ROLE_DESCRIPTION: 'role-application.already-applied-for-role-description',
  CONFIRMATION_AWARD_APPLICATION: 'role-application.confirmation-award-application',
  AWARD_ROLE: 'role-application.award-role',
  ROLE_APPLICATION_SETTINGS: 'role-application.role-application-settings',
  SUCCESS_TASKS_LINKED: 'role-application.success-tasks-linked',
  SUCCESS_TASKS_UNLINKED: 'role-application.success-tasks-unlinked',
};
