<div class="container-filtered-task-list">
  <app-state [state]="state">
    <ng-container *ngIf="!state.loading && !state.error">
      <ng-container *ngIf="(tasks.master.length > 0); else templateNoTasks">
        <div class="p-bottom-15">
          <app-universal-filter [newUI]="true" [filterOptions]="universalFilterData.filterOptions" [searchProps]="universalFilterData.searchProps" (resultEmit)="filterEmit($event)" [defaultFilters]="universalFilterData.defaultFilters" [newUI]="true" [placeholderText]="universalFilterData.placeholder | translate"></app-universal-filter>
        </div>
    
        <ng-container *ngIf="tasks.filtered.length > 0; else templateNoSearchResults;">
          <!-- Sorting -->
          <div class="text-right p-bottom-15" *ngIf="tasks.filtered.length > 1">
            <div class="d-inline-block text-left">
              <app-sorting-dropdown [control]="controlSort" [sortingOptions]="sortingOptions"></app-sorting-dropdown>
            </div>
          </div>
    
          <!-- Data list -->
          <div class="area-task-list">
            <ng-container *ngFor="let task of tasks.display">
              <app-task-display [task]="task" [actionsShown]="['view']" (onClickAction)="onTaskAction($event, task)"></app-task-display>
            </ng-container>
          </div>
        </ng-container>
    
        <div [class.hidden]="tasks.filtered.length <= 1">
          <app-pagination-new-component [arrayData]="tasks.filtered" [arrayDisplay]="tasks.display" [amountToDisplay]="5" #pagination></app-pagination-new-component>
        </div>
    
        <ng-template #templateNoSearchResults>
          <app-empty-state [height]="'300px'" [message]="eTasksMessages.EMPTY_STATE_SEARCH"></app-empty-state>
        </ng-template>
      </ng-container>
      
      <ng-template #templateNoTasks>
        <app-empty-state [height]="'300px'" [message]="eTasksMessages.EMPTY_STATE"></app-empty-state>
      </ng-template>
    </ng-container>
  </app-state>
</div>