
import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OneToOneAPIService } from '@app/domain/one_to_one/api/one-to-one-api.service';
import { ParentFilter } from '@app/models/api/parent-filter.model';
import { Breadcrumb } from '@app/models/breadcrumb.model';
import { IState } from '@app/models/state/state.model';
import { BreadcrumbService } from '@app/shared/breadcrumbs/breadcrumbs.service';
import { Globals } from '@app/shared/globals/globals';
import { SentimentScaleMessages } from '../../locale/sentiment-scale.messages';
import { OneToOneMessages } from '@app/domain/one_to_one/locale/one-to-one.messages';
import { FilterOperator } from '@app/models/api/filter-operator.enum';
import { PagingParams } from '@app/models/api/paging-params.model';
import { SortingParams } from '@app/models/api/sorting-params.model';
import { FilterCategory } from '@app/shared/dynamic-filter/interfaces/filter-category';
import { SearchMode } from '@app/shared/dynamic-filter/types/search-mode';
import { FilterType } from '@app/shared/dynamic-filter/types/filter-type';
import { MeetingSentimentScaleScoreMinimal } from '@app/domain/one_to_one/model/meeting-sentiment-scale-score.model';
import { SentimentScale } from '../../model/sentiment-scale.model';
import { SentimentScaleAPIService } from '../../api/sentiment-scale-api.service';
import { UserAPIService } from '@app/shared/api/user.api.service';
import { OneToOneMeeting } from '@app/domain/one_to_one/model/one-to-one-meeting.model';
import { SentimentScaleOption } from '../../model/sentiment-scale-option.model';
import { UserMinimal } from '@app/models/user/user-minimal.model';
import moment from 'moment';
import { PdfExportService } from '@app/domain/pdf/service/pdf-export.service';

export interface ChartTargetUser {
  user: UserMinimal;
  schedules: ChartSchedule[];
}

export interface ChartSchedule {
  purposeTitle: string;
  manager: UserMinimal;
  participants: UserMinimal[];
  meetings: ChartMeeting[];
}

export interface ChartMeeting {
  meetingTimestamp: Date;
  responses: ChartScore[];
}

export interface ChartScore {
  optionSelected: SentimentScaleOption;
  sourceUser: UserMinimal;
}


// TODO: Show all this if we can
// enum ChartColumnType {
//   DATA_POINTS = 'DATA_POINTS',
//   CHART_CHANGED = 'CHART_CHANGED',
//   FISCAL_YEAR_START = 'FISCAL_YEAR_START'
// }

interface IFilterData {
  searchProperties: [];
  filterCategories: FilterCategory[];
}

@Component({
  selector: 'app-sentiment-scale-reporting',
  templateUrl: './sentiment-scale-reporting.component.html',
  styleUrls: ['./sentiment-scale-reporting.component.scss']
})
export class SentimentScaleReportingComponent implements OnDestroy {

  public readonly eSentimentScaleMessages = SentimentScaleMessages;
  public readonly eOneToOneMessages = OneToOneMessages;

  private readonly CHART_HEIGHT_PX = 400;

  breadcrumb: Breadcrumb;
  state: IState;

  parentFilter: ParentFilter;
  pagingParams: PagingParams;
  sortingParams: SortingParams;
  filterData: IFilterData;

  targetUsers: ChartTargetUser[];

  mapScales: Map<number, SentimentScale>;
  mapUsers: Map<number, UserMinimal>;
  mapMeetings: Map<number, OneToOneMeeting>;

  constructor(
    public globals: Globals,
    public router: Router,
    public activatedRoute: ActivatedRoute,
    private userAPIService: UserAPIService,
    private pdfExportService: PdfExportService,
    private breadcrumbService: BreadcrumbService,
    private oneToOneAPIService: OneToOneAPIService,
    private sentimentScaleAPIService: SentimentScaleAPIService
  ) {
    this.state = {
      loading: false,
      error: false,
      errorMessage: ''
    };

    this.parentFilter = {
      operator: FilterOperator.AND,
      childFilters: [],
    };
    this.pagingParams = {
      pageSize: 25,
      pageNumber: 0,
    };
    this.sortingParams = undefined;

    this.filterData = {
      searchProperties: [],
      filterCategories: [
        {
          displayName: SentimentScaleMessages.SOURCE_USER,
          fieldName: 'source_user_id',
          searchMode: SearchMode.USER,
          type: FilterType.COMBINED,
          options: []
        },
        {
          displayName: SentimentScaleMessages.TARGET_USER,
          fieldName: 'target_user_id',
          searchMode: SearchMode.USER,
          type: FilterType.COMBINED,
          options: []
        },
        {
          displayName: SentimentScaleMessages.SENTIMENT_SCALE,
          fieldName: 'sentiment_scale_id',
          searchMode: SearchMode.SENTIMENT_SCALE,
          type: FilterType.COMBINED,
          options: []
        }
      ]
    };

    this.targetUsers = [];

    this.mapScales = new Map<number, SentimentScale>();
    this.mapUsers = new Map<number, UserMinimal>();
    this.mapMeetings = new Map<number, OneToOneMeeting>();

    this.breadcrumb = this.breadcrumbService.init(this.activatedRoute);
  }

  ngOnDestroy(): void {
    this.breadcrumbService.remove(this.breadcrumb);
  }

  refreshData(): void {
    this.state.loading = true;

    this
      .getResponses(this.parentFilter, this.pagingParams, this.sortingParams)
      .then(() => {
        this.state.loading = false;
      })
      .catch(err => this.doError(err.message));
  }
    
  doError(errorMessage: string): void {
    this.state.error = true;
    this.state.errorMessage = errorMessage;
    this.state.loading = false;
  }
    
  getResponses(_parentFilter: ParentFilter, _pagingParams: PagingParams, _sortingParams: SortingParams): Promise<ChartTargetUser[]> {
    return this.oneToOneAPIService.getChartData(_parentFilter, _pagingParams, _sortingParams)
      .toPromise()
      .then(data => {
        this.targetUsers = data;
        return data;
      });
  }

  getInvolvedSentimentScales(scores: MeetingSentimentScaleScoreMinimal[]): Promise<SentimentScale[]> {
    const uniqueSentimentScaleIds = scores
      .map(score => score.sentimentScaleId) // Get just scale IDs
      .filter((value, index, self) => self.indexOf(value) === index); // Filter out duplicates

    if (uniqueSentimentScaleIds.length === 0) {
      return Promise.resolve([]);
    }

    return this.sentimentScaleAPIService.getByIdIn(uniqueSentimentScaleIds)
      .toPromise()
      .then(sentimentScales => {
        // Sort sentiment scale options by score value
        sentimentScales = sentimentScales.map(sentimentScale => {
          sentimentScale.options = sentimentScale.options.sort((a, b) => a.score - b.score);
          return sentimentScale;
        });

        this.mapScales = new Map<number, SentimentScale>(sentimentScales.map(sentimentScale => [sentimentScale.id, sentimentScale]));
        return sentimentScales;
      });
  }

  getInvolvedUsers(scores: MeetingSentimentScaleScoreMinimal[]): Promise<UserMinimal[]> {
    const uniqueUserIds = scores
      .map(score => [score.sourceUserId, score.targetUserId]) // Get just user IDs
      .reduce((acc, val) => acc.concat(val), []) // Flatten array
      .filter((value, index, self) => self.indexOf(value) === index); // Filter out duplicates

    if (uniqueUserIds.length === 0) {
      return Promise.resolve([]);
    }

    return this.userAPIService.getUsersByIds(uniqueUserIds)
      .toPromise()
      .then(users => {
        this.mapUsers = new Map<number, UserMinimal>(users.map(user => [user.id, user]));
        return users;
      });
  }

  getInvolvedMeetings(scores: MeetingSentimentScaleScoreMinimal[]): Promise<OneToOneMeeting[]> {
    const uniqueMeetingIds = scores
      .map(score => score.meetingId) // Get just meeting IDs
      .filter((value, index, self) => self.indexOf(value) === index); // Filter out duplicates

    if (uniqueMeetingIds.length === 0) {
      return Promise.resolve([]);
    }
    
    return this.oneToOneAPIService.getMeetingsByIdIn(uniqueMeetingIds)
      .toPromise()
      .then(meetings => {
        this.mapMeetings = new Map<number, OneToOneMeeting>(meetings.map(meeting => [meeting.id, meeting]));
        return meetings;
      });
  }

  updateFilter(parentFilter: ParentFilter) {
    this.parentFilter = parentFilter;
    this.pagingParams.pageNumber = 0;
    this.refreshData();
  }

  openUserProfile(userId: number): void {
    this.router.navigate(['/profile', userId]);
  }

  createPDF(): void {
    const fileName = `frankli_sentiment_scale_reporting-${moment().format('LL').toString()}.pdf`;
    this.pdfExportService.startExport(fileName);
  }
}
