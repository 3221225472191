<div class="sentiment-scale-display-container" *ngIf="scale">
  <div class="top-row">
      <div class="col-details">
          <h5 class="standard-heading">
              <span>{{scale.name}}</span>
              <ng-container *ngIf="scale.archived">
                <span class="m-right-10"></span>
                <app-pill [type]="ePillType.GRAY">
                  <span>{{eCommonMessages.ARCHIVED | translate}}</span>
                </app-pill>
              </ng-container>
          </h5>
          <div class="standard-description">{{scale.description}}</div>
      </div>
      <div class="col-actions">
        <app-table-action-icon *ngIf="canSeeActionEdit" [icon]="'fa-edit'" [tooltipText]="eSentimentScaleMessages.EDIT_TOOLTIP" (click)="clickAction('edit')"></app-table-action-icon>
        <app-table-action-icon *ngIf="canSeeActionArchive" [icon]="'fa-archive'" [tooltipText]="eSentimentScaleMessages.ARCHIVE_TOOLTIP" (click)="clickAction('archive')"></app-table-action-icon>
      </div>
  </div>
  <ng-container *ngIf="showOptions">
    <div class="bottom-row">
      <app-sentiment-scale-response [sentimentScale]="scale" [disabled]="true"></app-sentiment-scale-response>
    </div>
  </ng-container>
</div>