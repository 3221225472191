import moment from 'moment';
import { ISODateString } from '@app/shared/utils/date-string.util';

export class ZendeskToken {

  id: number;
  tenantId: string;
  accessToken: string;
  connected: boolean;
  created: Date;
  error: string | null;
  syncing: boolean;


  constructor(zendeskTokenServerSide: ZendeskTokenServerSide) {
    this.id = zendeskTokenServerSide.id;
    this.tenantId = zendeskTokenServerSide.tenantId;
    this.accessToken = zendeskTokenServerSide.accessToken;
    this.connected = zendeskTokenServerSide.connected;
    this.created = moment.utc(zendeskTokenServerSide.created).toDate();
    this.error = zendeskTokenServerSide.error;
    this.syncing = zendeskTokenServerSide.syncing;
  }

}

export interface ZendeskTokenServerSide {
  id: number;
  tenantId: string;
  accessToken: string;
  connected: boolean;
  created: ISODateString;
  error: string | null;
  syncing: boolean;
}
