<div class="goal-create-task-item-container">
  <div class="row-title-due-date">
    <div class="row">
      <div class="col-sm-6 col-xs-12">
        <div class="frankli-form-group" [class.frankli-form-group-filled]="controlTaskTitle.value">
          <label translate>{{eTasksMessages.TITLE}}</label>
          <input type="text" [formControl]="controlTaskTitle" class="form-control"/>
          <div class="frankli-form-group-alert">
            <div *ngIf="controlTaskTitle.hasError('maxlength')" translate [translateParams]="{ maxValue: eGoalCreateTaskValidationLimits.title.max}">{{eCommonMessages.FORM_MAX_CHARACTER_VALUE}}</div>
            <ng-container *ngIf="submitted && controlTaskTitle.invalid">  
              <div *ngIf="controlTaskTitle.hasError('required')" translate>{{eTasksMessages.TITLE_VALIDATION_REQUIRED}}</div>
            </ng-container>
          </div>
        </div>
      </div>
      <div class="col-sm-6 col-xs-12">
        <div class="frankli-form-group" [class.frankli-form-group-filled]="controlTaskDueDate.value">
          <label>
            <span translate>{{eTasksMessages.DUE_DATE}}</span>
            <span class="m-left-5">({{eCommonMessages.OPTIONAL | translate}})</span>
          </label>
          <app-date-picker [name]="'goalEndDate'" [formControl]="controlTaskDueDate" [disableSince]="maxDueDate" [disableUntil]="minDueDate" [displayFormat]="dateDisplayFormat" (calendarToggle)="onCalendarToggle($event)" [showResetButton]="true"></app-date-picker>
          <div class="frankli-form-group-alert">
            <div *ngIf="controlTaskDueDate.hasError('mindate')" translate>{{eCommonMessages.CANNOT_BE_IN_THE_PAST}}</div>
            <div *ngIf="controlTaskDueDate.hasError('maxdate')" translate>{{eGoalsMessages.GOAL_CREATE_TASKS_VALIDATION_DUE_DATE_MAX}}</div>
            <ng-container *ngIf="submitted && controlTaskDueDate.invalid">
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row-description">
    <div class="frankli-form-group frankli-form-group-filled">
      <label>
        <span translate>{{eTasksMessages.DESCRIPTION}}</span>
        <span>({{eCommonMessages.OPTIONAL | translate}})</span>
      </label>
      <textarea class="form-control noresize" rows="2" [formControl]="controlTaskDescription"></textarea>
      <div class="frankli-form-group-alert"></div>
    </div>
  </div>
  <div class="row-owners">
    <div class="frankli-form-group frankli-form-group-filled">
      <label translate>{{eTasksMessages.TASK_OWNERS}}</label>
      <app-user-picker-multiple [formControl]="controlTaskOwners" [customOptions]="customOwnerOptions" (onResultsChanged)="onOwnersResultsChanged($event)"></app-user-picker-multiple>
      <div class="frankli-form-group-alert">
        <ng-container *ngIf="submitted && controlTaskOwners.invalid">
            <div *ngIf="controlTaskOwners.hasError('required')"
                 translate>{{eTasksMessages.TASK_OWNERS_VALIDATION_REQUIRED}}</div>
        </ng-container>
      </div>
    </div>
  </div>
</div>