export enum CompanySetUpStepType {
  CREATE_USERS = 'CREATE_USERS',
  CREATE_DEPARTMENTS = 'CREATE_DEPARTMENTS',
  CREATE_OFFICE_LOCATIONS = 'CREATE_OFFICE_LOCATIONS',
  CREATE_TAGS = 'CREATE_TAGS',
  CREATE_POSITIONS = 'CREATE_POSITIONS',
  CREATE_COMPETENCIES = 'CREATE_COMPETENCIES',
  SET_FISCAL_YEAR = 'SET_FISCAL_YEAR',
  SET_GOAL_PERMISSIONS = 'SET_GOAL_PERMISSIONS',
  SET_FEATURES = 'SET_FEATURES',
  CREATE_DEFAULT_TALKING_POINTS = 'CREATE_DEFAULT_TALKING_POINTS',
  CREATE_COMPANY_GOALS = 'CREATE_COMPANY_GOALS',
  INVITE_ALL_USERS = 'INVITE_ALL_USERS',
  VIEW_INTEGRATIONS = 'VIEW_INTEGRATIONS',
  CONFIGURE_UNPROMPTED_FEEDBACK = 'CONFIGURE_UNPROMPTED_FEEDBACK',
}