import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { CommonMessages } from '@app/constants/common.messages';
import { Competency } from '@app/domain/competency/model/competency.model';
import { Interest } from '@app/domain/interest/model/interest.model';
import { CompanyFeatures } from '@app/models/company-features.model';
import { IState } from '@app/models/state/state.model';
import { UserMinimal } from '@app/models/user/user-minimal.model';
import { User } from '@app/models/user/user.model';
import { InterestDisplayType } from '@app/shared/components/displays/interest-display/interest-display.component';
import { PillType } from '@app/shared/components/pill/pill.component';
import { Globals } from '@app/shared/globals/globals';
import { RoleMessages } from '../../locale/role.messages';
import { Role } from '../../model/role.model';
import { RoleBusinessService } from '../../service/role-business.service';
import { TerminologyEntity } from '@app/domain/terminology/model/terminology-entity.enum';

enum ComparisonType {
  IS_SELECTED = 'IS_SELECTED',
  NOT_SELECTED = 'NOT_SELECTED'
}

interface PageCompetency extends Competency {
  comparisonType?: ComparisonType;
}

interface PageSkill extends Interest {
  comparisonType?: ComparisonType;
}

@Component({
  selector: 'app-role-display',
  templateUrl: './role-display.component.html',
  styleUrls: ['./role-display.component.scss']
})
export class RoleDisplayComponent implements OnInit, OnChanges {

  public readonly eInterestDisplayType = InterestDisplayType;
  public readonly eTerminologyEntity = TerminologyEntity;
  public readonly eCompanyFeatures = CompanyFeatures;
  public readonly eComparisonType = ComparisonType;
  public readonly eCommonMessages = CommonMessages;
  public readonly eRoleMessages = RoleMessages;
  public readonly ePillType = PillType;

  @Input() role: Role;
  @Input() enableComparison: boolean;
  @Input() roleComparison?: Role;
  @Input() comparisonUser?: User;

  @Input() showUsersInRole: boolean;

  state: IState;
  roleUsers: UserMinimal[];

  competenciesCompared: PageCompetency[];
  skillsCompared: PageSkill[];

  constructor(
    public globals: Globals,
    private roleBusinessService: RoleBusinessService
  ) {
    this.competenciesCompared = [];
    this.skillsCompared = [];
    this.roleUsers = [];

    this.role = undefined;

    this.enableComparison = false;
    this.showUsersInRole = true;

    this.state = {
      loading: true,
      error: false,
      errorMessage: ''
    };
  }

  ngOnInit(): void {
    this.getUsersInRole();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const changesRole = changes['role'];
    const changesEnableComparison = changes['enableComparison'];
    const changesRoleComparison = changes['roleComparison'];

    if (changesRole || changesEnableComparison || changesRoleComparison) {
      this.updateComparison();
    }
  }

  getUsersInRole(): void {
    if (!this.role) { return; }
    if (!this.showUsersInRole) { return ;}

    this.roleBusinessService.getAllUsersAssignedToRole(this.role.id).toPromise()
      .then(users => {
        this.roleUsers = users;
      })
      .finally(() => {
        this.state.loading = false;
      });
  }

  updateComparison(): void {
    const source = this.role;
    const target = this.roleComparison;

    this.competenciesCompared = this.getCompetenciesComparison(source, target);
    this.skillsCompared = this.getSkillsComparison(source, target);
  }

  getCompetenciesComparison(source: Role, target: Role): PageCompetency[] {
    // If not comparing, return all competencies as usual
    if (!this.enableComparison || !target) {
      return source.competencies as PageCompetency[];
    }

    // If comparing, check if each competency is selected in the comparison role
    return source.competencies
      .map(competency => {
        let comparisonType = ComparisonType.NOT_SELECTED;
        if (target.competencies) {
          if (target.competencies.some(c => c.id === competency.id)) {
            comparisonType = ComparisonType.IS_SELECTED;
          }
        }

        return {
          ...competency,
          comparisonType
        };
      })
      // Sort IS_SELECTED to the top
      .sort((compA, compB) => {
        if (compA.comparisonType === ComparisonType.IS_SELECTED) {
          return -1;
        } else if (compB.comparisonType === ComparisonType.IS_SELECTED) {
          return 1;
        } else {
          return 0;
        }
      });
  }

  getSkillsComparison(source: Role, target: Role): PageSkill[] {
    // If not comparing, return all skills as usual
    if (!this.enableComparison || !target) {
      return source.skills as PageSkill[];
    }

    // If comparing, check if each skill is selected in the comparison role
    return source.skills
      .map(skill => {
        let comparisonType = ComparisonType.NOT_SELECTED;
        if (target.skills) {
          if (target.skills.some(s => s.id === skill.id)) {
            comparisonType = ComparisonType.IS_SELECTED;
          }
        }

        if ((comparisonType === ComparisonType.NOT_SELECTED) && (this.comparisonUser && this.comparisonUser.skills)) {
          if (this.comparisonUser.skills.some(s => s.id === skill.id)) {
            comparisonType = ComparisonType.IS_SELECTED;
          }
        }

        return {
          ...skill,
          comparisonType
        };
      })
      // Sort IS_SELECTED to the top
      .sort((skillA, skillB) => {
        if (skillA.comparisonType === ComparisonType.IS_SELECTED) {
          return -1;
        } else if (skillB.comparisonType === ComparisonType.IS_SELECTED) {
          return 1;
        } else {
          return 0;
        }
      });
  }

  isCompetencySelected(competency: PageCompetency): boolean {
    if (!competency) { return true; }
    if (!this.enableComparison) { return true; }
    if (!competency.comparisonType) { return true; }
    if (competency.comparisonType === ComparisonType.IS_SELECTED) { return true; }
    return false;
  }

  isSkillSelected(skill: PageSkill): boolean {
    if (!skill) { return true; }
    if (!this.enableComparison) { return true; }
    if (!skill.comparisonType) { return true; }
    if (skill.comparisonType === ComparisonType.IS_SELECTED) { return true; }
    return false;
  }
}
