<div class="goal-create-copying-container">
  <div class="heading-area">
    <div class="modal-title" translate>{{eGoalsMessages.GOAL_CREATE_COPYING_TITLE}}</div>
    <div class="modal-description" translate>{{eGoalsMessages.GOAL_CREATE_COPYING_DESCRIPTION}}</div>
  </div>
  <div class="goals-list">
    <div class="dynamic-filter-container">
      <app-dynamic-filter [filterCategories]="filterCategories" [searchProperties]="searchProperties" [selectedFilters]="selectedFilters" (filtersChanged)="updateFilter($event)"></app-dynamic-filter>
    </div>
    <ng-container *ngIf="!state.loading; else stateLoading;">
      <ng-container *ngIf="goalsFiltered.length > 0; else stateSearchEmpty;">
        <div class="goals-list-inner">
          <ng-container *ngFor="let goal of goalsFiltered">
            <div class="goal-item">
              <app-goal-display [goal]="goal" (onSelect)="onSelectGoal(goal)" [canSelect]="true"></app-goal-display>
            </div>
          </ng-container>
        </div>
      </ng-container>
      <div class="pagination-wrapper">
        <app-pagination-server [page]="goalsPage" [pageSize]="pagingParams.pageSize" (pageSizeChanged)="changePageSize($event)" (pageNumberChanged)="changePageNumber($event)"></app-pagination-server>
      </div>
    </ng-container>
  </div>
</div>

<ng-template #stateLoading>
  <app-loading-state [showMessage]="false" [height]="'calc(100vh - 300px)'"></app-loading-state>
</ng-template>

<ng-template #stateSearchEmpty>
  <app-empty-state [height]="'calc(100vh - 300px)'" [message]="eGoalsMessages.GOAL_CREATE_COPYING_EMPTY_STATE_SEARCH"></app-empty-state>
</ng-template>

<ng-template #stateEmpty>
  <app-empty-state [height]="'calc(100vh - 300px)'" [message]="eGoalsMessages.GOAL_CREATE_COPYING_EMPTY_STATE" [primaryButtonShown]="true" [primaryButtonText]="eGoalsMessages.CREATE_A_GOAL" (primaryButtonPressed)="onClickCreateGoal()"></app-empty-state>
</ng-template>