<div class="container-sidebar-action-points">
  <app-state [state]="state">
    <ng-container *ngIf="!state.loading && !state.error;">
      <ng-container *ngIf="dataAll.length > 0; else templateNoData;">
        <ng-container *ngIf="dataNewlyAdded.length > 0">
          <div class="p-bottom-5">
            <h5 class="standard-heading" translate>{{eOneToOneMessages.NEWLY_ADDED_ACTION_POINTS}}</h5>
          </div>
  
          <div class="data-list">
            <ng-container *ngFor="let task of dataNewlyAdded; index as index;">
              <app-task-display [task]="task" [actionsShown]="['view', 'edit', 'unlink', 'archive']" (onClickAction)="onTaskAction($event, task)"></app-task-display>
            </ng-container>
          </div>
        </ng-container>

        <ng-container *ngIf="dataForReview.length > 0">
          <div class="p-top-15 p-bottom-5">
            <h5 class="standard-heading" translate>{{eOneToOneMessages.ACTION_POINTS_TO_REVIEW}}</h5>
          </div>
  
          <div class="data-list">
            <ng-container *ngFor="let task of dataForReview; index as index;">
              <app-task-display [task]="task" [actionsShown]="['view', 'edit', 'unlink', 'archive']" (onClickAction)="onTaskAction($event, task)"></app-task-display>
            </ng-container>
          </div>
        </ng-container>

        <div class="text-center p-top-15">
          <app-button [type]="eButtonType.GREEN_INVERTED" (onClick)="startCreateTask()">New action point</app-button>
        </div>
      </ng-container>

      <ng-template #templateNoData>
        <app-empty-state [height]="'200px'" [imageSrc]="'assets/img/empty-state/empty-state-tasks.svg'" [message]="'No action points'"></app-empty-state>
      </ng-template>

      <app-modal #modal [showCloseButton]="false" [sidePanel]="true" [confirmClosing]="confirmModalClose" [confirmCloseModalSettings]="confirmCloseSettings" (onConfirmCloseModalResponse)="onCancelCreate($event)">
        <ng-container [ngSwitch]="modalType">
          <app-task-create *ngSwitchCase="eModalType.CREATE" [formCreate]="formCreate" [submitted]="state.submitted" (onFormCancel)="onCancelCreate()" (onFormSubmit)="onSubmitCreate($event)" (requestCloseModal)="onCancelCreate($event)" #componentCreate></app-task-create>
          <app-task-details-component *ngSwitchCase="eModalType.DETAILS" [task]="taskViewing" (requestCloseModal)="onCancelCreate()" (onTaskUpdated)="taskUpdated($event)"></app-task-details-component>
        </ng-container>
      </app-modal>
    </ng-container>
  </app-state>
</div>