import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthMessages } from '@app/auth/auth.messages';
import { CommonMessages } from '@app/constants/common.messages';
import { Globals } from '@app/shared/globals/globals';
import { SidebarService } from '@app/shared/sidebar/sidebar.service';

@Component({
  selector: 'app-auth-layout',
  templateUrl: './auth-layout.component.html',
  styleUrls: ['./auth-layout.component.scss']
})

export class AuthLayoutComponent implements OnInit {
  public readonly eCommonMessages = CommonMessages;
  public readonly eAuthMessages = AuthMessages;

  constructor(
    public router: Router,
    public globals: Globals,
    public sidebarService: SidebarService
  ) {
  }

  ngOnInit() {
    this.sidebarService.closeSidebar();
  }

  sidebarToggle() {
    this.sidebarService.toggleSidebar();
  }

  logout() {
    this.router.navigate(['/logout']);
  }

  openHelp(): void {
    window.open('https://www.frankli.io', '_blank');
  }
}

