<div class="goal-activity-container">
  <ng-container *ngIf="!state.loading; else stateLoading">
    <div class="card">
      <!-- Comment Form -->
      <div class="p-top-20 p-bottom-20 p-left-20 p-right-20">
        <div class="primary-heading">
          <span translate>{{eGoalsMessages.ACTIVITY_HISTORY}}</span>
        </div>
    
        <div class="comment-form-area" *ngIf="!goal.complete && !goal.archived">
          <div class="frankli-form-group" [class.frankli-form-group-filled]="controlComment.value" [class.frankli-form-group-invalid]="state.submitted && controlComment.invalid">
            <label for="comment">
              <span class="fal fa-comment"></span>
              <span translate>{{eGoalsMessages.ACTIVITY_COMMENT_LABEL}}</span>
            </label>
  
            <ng-container *ngIf="!state.submitting; else stateCommentLoading">
              <div class="input-group">
                <input class="form-control" [formControl]="controlComment" id="comment" (keydown.enter)="addComment()">
                <div class="input-group-addon" (click)="resetInput()" *ngIf="controlComment.value.length > 0">
                  <app-table-action-icon [icon]="'fa-times'" [hoverColor]="eIconHoverColor.RED"></app-table-action-icon>
                </div>
              </div>
            </ng-container>
            <ng-template #stateCommentLoading>
              <div class="text-center p-top-15">
                <span translate>{{eGoalsMessages.ACTIVITY_COMMENT_SUBMITTING}}</span>
                <span class="fas fa-spin fa-spinner fa-fw"></span>
              </div>
            </ng-template>
          </div>
          <div class="comment-box-bottom" [class.comment-written]="controlComment.value" [class.comment-invalid]="">
            <small translate>{{eGoalsMessages.ACTIVITY_COMMENT_SUBMIT_PROMPT}}</small>
            <small class="character-count" [class.text-danger]="controlComment.value && (controlComment.value.length > commentMaxLength)">{{controlComment.value.length}} / {{commentMaxLength}}</small>
          </div>
          <div class="frankli-form-group-alert">
            <div *ngIf="controlComment.hasError('maxlength')" translate [translateParams]="{ maxValue: commentMaxLength }">{{eCommonMessages.FORM_MAX_CHARACTER_VALUE}}</div>

            <ng-container *ngIf="state.submitted && controlComment.invalid">
              <div *ngIf="controlComment.hasError('required')" translate>{{eCommonMessages.FORM_REQUIRED}}</div>
            </ng-container>
          </div>
        </div>
      </div>

      <hr class="divider-h"/>

      <!-- Activity List -->
      <div class="p-top-20 p-bottom-20 p-left-20 p-right-20">
        <div class="activity-scrollable">
          <ng-container *ngFor="let activity of goal.activity; index as index; first as isFirst; last as isLast;">
            <ng-container *ngIf="commentBeingDeletedId !== activity.id; else stateCommentDeleting">
              <app-goals-individual-activity-item [activity]="activity" (onClickDelete)="deleteComment(activity.id)"></app-goals-individual-activity-item>
            </ng-container>

            <ng-template #stateCommentDeleting>
              <div class="text-center">
                <span translate>{{eGoalsMessages.ACTIVITY_COMMENT_DELETING}}</span>
                <span class="fas fa-spin fa-spinner fa-fw"></span>
              </div>
            </ng-template>
          </ng-container>
        </div>
    
      </div>
    </div>
  </ng-container>

  <ng-template #stateLoading>
    <app-loading-state [showMessage]="false" [height]="'300px'"></app-loading-state>
  </ng-template>
</div>