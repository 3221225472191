import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-button-with-dropdown',
  templateUrl: './button-with-dropdown.component.html',
  styleUrls: ['./button-with-dropdown.component.scss']
})
export class ButtonWithDropdownComponent implements OnInit {
  @Input() text: string;
  @Output() public primaryAction: EventEmitter<MouseEvent>;

  constructor() {
    this.text = 'Click me';
    this.primaryAction = new EventEmitter<MouseEvent>();
  }

  ngOnInit(): void {
  }

  onClickPrimaryButton(event: MouseEvent): void {
    this.primaryAction.emit(event);
  }

  popupClicked(event: MouseEvent): void {
    // This hides the menu once an option is clicked
    if (document.activeElement) {
      (document.activeElement as HTMLElement).blur();
    }
  }
}
