import { Injectable } from '@angular/core';
import { MockService } from '@app/mock/api/mock-service';
import { HttpRequest } from '@angular/common/http';
import { PollService } from '@app/shared/api/interfaces/poll.service';
import { Observable } from 'rxjs';
import { PollResponse } from '@app/models/poll/poll-response.model';
import { sanitizeUrl } from '@app/shared/utils/helpers';
import { Globals } from '@app/shared/globals/globals';
import { CreatePollSeriesDto } from '@app/models/poll/poll-series/create-poll-series.dto';
import { CreatePollResponseDto } from '@app/models/poll/create-poll-response.dto';
import { CreatePollDraftDto } from '@app/models/poll/poll-draft/create-poll-draft.dto';
import { PollDraft } from '@app/models/poll/poll-draft/poll-draft.model';
import { UpdatePollDraftDto } from '@app/models/poll/poll-draft/update-poll-draft.dto';
import { PollReceivedDto } from '@app/models/poll/poll-received.dto';
import { PollSeries } from '@app/models/poll/poll-series/poll-series.model';
import { UpdatePollSeriesDto } from '@app/models/poll/poll-series/update-poll-series.dto';

@Injectable()
export class PollMockService implements MockService, PollService {
    constructor(private readonly globals: Globals) { }

    handleRoute(req: HttpRequest<any>): any {
        const url = sanitizeUrl(req.urlWithParams);

        // TODO(shane): update to work with poll series
        // switch (true) {
        //     case url.endsWith('api/poll') && req.method === 'GET':
        //         return this.getAllPolls();
        //     case url.match(/api\/poll\/\d+$/) !== null && req.method === 'GET':
        //         const pollId = +url.split('/')[2];
        //         return this.getPollById(pollId);
        //     case url.match(/api\/poll\/responses\/\d+$/) !== null:
        //         const respondedPollId = +url.split('/')[3];
        //         return this.getPollResponses(respondedPollId);
        //     case url.endsWith('api/poll/assigned'):
        //         return this.getAssignedPolls();
        //     case url.endsWith('api/poll/published'):
        //         return this.getAllPollsPublishedToUser();
        // }
    }

    // poll
    getPollsReceived(): Observable<Array<PollReceivedDto>> {
        return undefined;
    }
    getPollReceivedByPollId(pollId: number): Observable<PollReceivedDto> {
        return undefined;
    }
    createPollResponseByPollId(pollId: number, createPollResponseDto: CreatePollResponseDto): Observable<PollResponse> {
        return undefined;
    }

    // poll series
    createPollSeries(createPollSeriesDto: CreatePollSeriesDto, draftId?: number): Observable<PollSeries> {
        return undefined;
    }
    updatePollSeries(id: number, updatePollSeriesDto: UpdatePollSeriesDto): Observable<PollSeries> {
        return undefined;
    }
    archivePollSeries(id: number): Observable<PollSeries> {
        return undefined;
    }
    getPollSeries(): Observable<Array<PollSeries>>{
        return undefined;
    }
    getPollSeriesById(id: number): Observable<PollSeries>{
        return undefined;
    }

    // poll draft
    createPollDraft(createPollDraftDto: CreatePollDraftDto): Observable<PollDraft> {
        return undefined;
    }
    updatePollDraftById(id: number, updatePollDraftDTO: UpdatePollDraftDto): Observable<PollDraft> {
        return undefined;
    }
    deletePollDraftById(id: number): Observable<PollDraft> {
        return undefined;
    }
    getPollDraftById(id: number): Observable<PollDraft> {
        return undefined;
    }
    getPollDrafts(): Observable<Array<PollDraft>> {
        return undefined;
    }
}