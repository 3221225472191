<div class="category-container" [class.property-selected]="isValueSelected" [class.mobile-touch-events]="globals.onMobile" *ngIf="category">
    <div class="category-text-container">
        <!-- Filter name -->
        <span>{{category.displayName | translate | underscoreToSpace}}{{(selectedFiltersForCategory.length > 0) ? ':' : ''}}</span>
        <span class="m-right-5"></span>

        <!-- Selected value preview -->
        <ng-container [ngSwitch]="category.searchMode">
            <ng-container *ngSwitchCase="eSearchMode.DATE_RANGE">
                <ng-container *ngIf="dateRangePickerHasValueSelected">
                    <span>{{dateRangePickerControl.value.start | date}}&nbsp;-&nbsp;{{dateRangePickerControl.value.end | date}}</span>
                    <span class="m-right-5"></span>
                </ng-container>
            </ng-container>
            <ng-container *ngSwitchDefault>
                <ng-container *ngIf="selectedFiltersForCategory.length > 0;">
                    <span>{{selectedFiltersForCategory[0].option.displayName | translate}}</span>
                    <ng-container *ngIf="selectedFiltersForCategory.length > 1;">
                        <span class="count-extra-properties">
                            <span>+{{selectedFiltersForCategory.length - 1}}</span>
                        </span>
                    </ng-container>
                    <span class="m-right-5"></span>
                </ng-container>
            </ng-container>
        </ng-container>

        <!-- Filter dropdown -->
        <span class="fal fa-fw fa-chevron-down"></span>
    </div>

    <ng-container *ngIf="hasCustomControl && (category.searchMode === eSearchMode.DATE_RANGE) && (dateRangePickerControl !== undefined)">
        <div class="container-date-range-picker">
            <app-date-range-picker [formControl]="dateRangePickerControl" [name]="'dateperiod'" [maxSpan]="{years: 1}" [linkedCalendars]="false" [alwaysShowCalendars]="true"></app-date-range-picker>
        </div>
    </ng-container>

    <div class="category-dropdown" *ngIf="!hasCustomControl">
        <ng-container *ngIf="hasSearchBox">
            <div class="search-box-container">
                <input type="search" class="form-control search-box-control" [placeholder]="searchPlaceholder" [formControl]="searchControl" maxlength="100"/>
                <div class="search-box-icon">
                    <span class="fal fa-fw fa-search"></span>
                </div>
            </div>
        </ng-container>

        <ng-container [ngSwitch]="category.searchMode">
            <ng-container *ngSwitchCase="eSearchMode.DATE">
            </ng-container>
            <ng-container *ngSwitchCase="eSearchMode.DATE_RANGE">
            </ng-container>
            <ng-container *ngSwitchDefault>
                <ng-container *ngIf="(displayedOptions | async); let options;">
                    <ng-container *ngIf="!searchRunning; else templateStateLoading">
                        <ng-container *ngIf="options.length > 0; else templateStateEmpty;">
                            <div class="dropdown-results-list">
                                <ng-container *ngFor="let option of options">
                                    <div class="property-item" (click)="toggleOption(option)">
                                        <input class="property-checkbox" type="checkbox" [checked]="filterIsSelected(category, option)"/>
                                        <span class="property-name m-left-5" [title]="option.displayName">{{option.displayName | translate}}</span>
                                    </div>
                                </ng-container>
                            </div>
                            <ng-container *ngIf="hasSearchBox">
                                <div class="dropdown-results-paging">
                                    <span translate [translateParams]="{ pageSize: (totalElements > pageSizeDisplay ? pageSizeDisplay : totalElements), totalElements }">{{eUniversalFilterMessages.PAGING}}</span>
                                </div>
                            </ng-container>
                        </ng-container>
    
                        <ng-template #templateStateEmpty>
                            <app-empty-state [message]="'No search results'" [imageSrc]="null" [height]="'50px'"></app-empty-state>
                        </ng-template>
                    </ng-container>

                    <ng-template #templateStateLoading>
                        <app-loading-state [height]="'200px'"></app-loading-state>
                    </ng-template>
                </ng-container>
            </ng-container>
        </ng-container>
    </div>
</div>
