import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { CommonMessages } from '@app/constants/common.messages';
import { GoalsMessages } from '@app/goals/goals.messages';
import { UserMinimal } from '@app/models/user/user-minimal.model';
import { Globals } from '@app/shared/globals/globals';
import { DateUtils } from '@app/shared/utils/date.utils';
import { TasksMessages } from '@app/domain/task/locale/tasks.messages';
import { IMyDate } from 'angular-mydatepicker';
import { Subject } from 'rxjs';
import { GoalCreateTaskValidationLimits, initTaskFormGroup } from '../goal-create-part-tasks.component';

@Component({
  selector: 'app-goal-create-task-item',
  templateUrl: './goal-create-task-item.component.html',
  styleUrls: ['./goal-create-task-item.component.scss']
})
export class GoalCreateTaskItemComponent implements OnInit, OnDestroy {
  public readonly eGoalsMessages = GoalsMessages;
  public readonly eTasksMessages = TasksMessages;
  public readonly eCommonMessages = CommonMessages;
  public readonly eGoalCreateTaskValidationLimits = GoalCreateTaskValidationLimits;
  public readonly dateDisplayFormat = 'D MMM YYYY';

  private readonly ngUnsubscribe$: Subject<void> = new Subject<void>();

  @Input() taskForm: FormGroup;
  @Input() submitted: boolean;
  @Input() submittedDraft: boolean;
  @Input() minDueDate: IMyDate;
  @Input() maxDueDate: IMyDate | null;

  @Output() calendarOrSearchToggle: EventEmitter<boolean>;

  customOwnerOptions: UserMinimal[];

  get controlTaskTitle(): FormControl {
    return this.taskForm.controls.title as FormControl;
  }

  get controlTaskDueDate(): FormControl {
    return this.taskForm.controls.dueDate as FormControl;
  }

  get controlTaskDescription(): FormControl {
    return this.taskForm.controls.description as FormControl;
  }

  get controlTaskAlignmentType(): FormControl {
    return this.taskForm.controls.alignmentType as FormControl;
  }

  get controlTaskAlignmentId(): FormControl {
    return this.taskForm.controls.alignmentId as FormControl;
  }

  get controlTaskOwners(): FormControl {
    return this.taskForm.controls.owners as FormControl;
  }

  constructor(
    public globals: Globals
  ) {
    this.taskForm = initTaskFormGroup();
    this.calendarOrSearchToggle = new EventEmitter<boolean>();
    this.submitted = false;
    this.submittedDraft = false;
    this.minDueDate = DateUtils.convertDateToPickerFormat(new Date());
    this.maxDueDate = null;
    this.customOwnerOptions = [ globals.user ];
  }

  ngOnInit(): void {
    this.initEventListeners();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.unsubscribe();
  }

  onCalendarToggle(isOpen: boolean): void {
    this.calendarOrSearchToggle.emit(isOpen);
  }

  initEventListeners(): void {
  }

  onOwnersResultsChanged(results: UserMinimal[]): void {
    const isOpen = (results.length > 0);
    this.calendarOrSearchToggle.emit(isOpen);
  }
}
