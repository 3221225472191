<div class="goal-create-site-container" [class.parent-dropdown-closed]="!parentDropdownOpen">
  <ng-container *ngIf="possibleSites.length > 0; else noSitesAvailableTemplate;">
    <div class="search-box-outer" *ngIf="parentDropdownOpen">
      <div class="search-box-container">
        <input type="search" class="form-control search-box-control" [placeholder]="eCommonMessages.SEARCH_BY_NAME | translate" [formControl]="searchControl" maxlength="100"/>
        <div class="search-box-icon">
            <span class="fal fa-fw fa-search"></span>
        </div>
      </div>
    </div>

    <ng-container *ngIf="possibleSitesDisplay.length > 0; else noSearchResults">
      <div class="sites-list">
        <ng-container *ngFor="let site of possibleSitesDisplay; index as i; first as isFirst; last as isLast;">
          <div class="sites-list-item" (click)="onSelectSite(site)">
            <app-site-display [site]="site" [canSelect]="true" [selected]="controlSite.value === site.id"></app-site-display>
          </div>
        </ng-container>
      </div>
    </ng-container>

    <ng-template #noSearchResults>
      <ng-container *ngIf="parentDropdownOpen">
        <app-empty-state [height]="'normal'" [message]="eCommonMessages.NO_SEARCH_SITES | translate:{ sitePlural: globals.getTerminology(eTerminologyEntity.SITE_PLURAL) }"></app-empty-state>
      </ng-container>
    </ng-template>
  </ng-container>

  <ng-template #noSitesAvailableTemplate>
    <div class="state-empty">
      <img class="icon-padding new-icon-height" src="/assets/img/NoSurveys.svg" />
      <div class="p-bottom-10" [innerHTML]="eGoalsMessages.GOAL_CREATE_SITE_EMPTY_STATE | translate:{ sitePlural: globals.getTerminology(eTerminologyEntity.SITE_PLURAL) }"></div>
    </div>
  </ng-template>

  <ng-container *ngIf="submitted && controlSite.invalid">
    <div class="frankli-form-group-alert p-top-15 p-bottom-15">
      <div *ngIf="controlSite.hasError('required')" [innerHTML]="eGoalsMessages.GOAL_CREATE_SITE_VALIDATION_REQUIRED | translate:{ site: globals.getTerminology(eTerminologyEntity.SITE) }"></div>
    </div>
  </ng-container>
</div>