export enum ConnectActivityType {
    COACHING_SKILL_SEARCH = 'COACHING_SKILL_SEARCH',
    SKILL_SHOWN_INTEREST = 'SKILL_SHOWN_INTEREST',
    COACHING_CREATED_REQUEST = 'COACHING_CREATED_REQUEST',
    COACHING_UPDATED_REQUEST = 'COACHING_UPDATED_REQUEST',
    COACHING_MANAGER_DECLINED_REQUEST = 'COACHING_MANAGER_DECLINED_REQUEST',
    COACHING_MANAGER_APPROVED_REQUEST = 'COACHING_MANAGER_APPROVED_REQUEST',
    COACHING_COACH_DECLINED_REQUEST = 'COACHING_COACH_DECLINED_REQUEST',
    COACHING_COACH_APPROVED_REQUEST = 'COACHING_COACH_APPROVED_REQUEST',
    COACHING_SESSION_CLOSED = 'COACHING_SESSION_CLOSED',
    MENTORING_SKILL_SEARCH = 'MENTORING_SKILL_SEARCH',
    MENTORING_MENTEE_CREATED_REQUEST = 'MENTORING_MENTEE_CREATED_REQUEST',
    MENTORING_MENTEE_UPDATED_REQUEST = 'MENTORING_MENTEE_UPDATED_REQUEST',
    MENTORING_MANAGER_DECLINED_REQUEST = 'MENTORING_MANAGER_DECLINED_REQUEST',
    MENTORING_MANAGER_APPROVED_REQUEST = 'MENTORING_MANAGER_APPROVED_REQUEST',
    MENTORING_MENTOR_DECLINED_REQUEST = 'MENTORING_MENTOR_DECLINED_REQUEST',
    MENTORING_MENTOR_APPROVED_REQUEST = 'MENTORING_MENTOR_APPROVED_REQUEST',
    MENTORING_SESSION_CLOSED = 'MENTORING_SESSION_CLOSED'
}