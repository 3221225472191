import {ActivatedRouteSnapshot, Router, RouterStateSnapshot} from '@angular/router';
import {Injectable} from '@angular/core';
import {Globals} from '../globals/globals';
import {UserState} from '@app/models/user/user.model';

/**
 * Allows user to dashboard reset if user.passwordReset === true
 */
@Injectable()
export class PasswordResetAllowGuard {

  constructor(
      public globals: Globals,
      private router: Router,
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.globals.user) {
      if (this.globals.user.passwordReset) {
        return true;
      }

      const STATES = [UserState.INVITED, UserState.PENDING];
      if (STATES.includes(this.globals.user.userState)) {
        return true;
      }
    }

    this.router.navigate(['/dashboard']);
    return false;
  }
}
