import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SimilarUsers } from 'app/models/user/similar-users.model';

@Injectable()
export class SimilarUsersAPIService {

  http: HttpClient;
  constructor(private _http: HttpClient) {
    this.http = _http;
  }

  getSimilarCount(): Observable<SimilarUsers> {
    const url = '/api/similar';
    return this.http.get<SimilarUsers>(url);
  }
  setSimilarCount(newCount: number): Observable<SimilarUsers> {
    const url = '/api/similar';
    return this.http.post<SimilarUsers>(url, {newCount});
  }

}
