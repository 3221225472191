import { Component, Input, OnInit, Output, EventEmitter, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { CommonMessages } from '@app/constants/common.messages';
import { GoalsMessages } from '@app/goals/goals.messages';
import { GoalDistribution } from '@app/models/goals/goal-distribution.model';
import { RoleName } from '@app/models/user-role.model';
import { UserMinimal } from '@app/models/user/user-minimal.model';
import { ButtonGroupOption } from '@app/shared/components/inputs/button-group/button-group.component';
import { UserPickerMultipleComponent } from '@app/shared/components/inputs/user-picker-multiple/user-picker-multiple.component';
import { Globals } from '@app/shared/globals/globals';
import { GoalUtils } from '@app/shared/utils/goal.utils';
import { CommonGoalCreateFormPart } from '../../goal-create-views/goal-create-main/goal-create-main.component';

@Component({
  selector: 'app-goal-create-part-owners',
  templateUrl: './goal-create-part-owners.component.html',
  styleUrls: ['./goal-create-part-owners.component.scss']
})
export class GoalCreatePartOwnersComponent implements OnInit, CommonGoalCreateFormPart {
  public readonly eGoalsMessages = GoalsMessages;
  public readonly eCommonMessages = CommonMessages;
  public readonly eGoalDistribution = GoalDistribution;
  
  @Input() controlOwners: FormControl;
  @Input() controlDistribution: FormControl;
  @Input() submitted: boolean;
  @Input() submittedDraft: boolean;
  @Input() parentDropdownOpen: boolean;

  @Output() onSetParentDropdown: EventEmitter<boolean>;

  @ViewChild('userPicker') userPicker: UserPickerMultipleComponent;

  customOptions: UserMinimal[];
  distributionOptions: ButtonGroupOption[];

  get canSelectOwners(): boolean {
    if (!this.controlDistribution) { return true; }
    return [GoalDistribution.NONE, GoalDistribution.OWNERS].includes(this.controlDistribution.value);
  }

  constructor(
    public globals: Globals
  ) {
    this.controlOwners = new FormControl([], []);
    this.controlDistribution = new FormControl(GoalDistribution.NONE, []);
    this.customOptions = [ globals.user ];
    this.submitted = false;
    this.submittedDraft = false;
    this.parentDropdownOpen = true;
    this.onSetParentDropdown = new EventEmitter<boolean>();
    this.distributionOptions = [];
  }

  ngOnInit(): void {
    this.distributionOptions = this.getDistributionOptions();
  }

  setParentDropdown(expanded: boolean): void {
    this.onSetParentDropdown.emit(expanded);
  }

  focus(): void {
    if (!this.userPicker) { return; }

    this.userPicker.focusSearchInput();
  }

  getDistributionOptions(): ButtonGroupOption[] {
    const optionNone = {
      value: GoalDistribution.NONE,
      friendlyText: GoalsMessages.GOAL_CREATE_DISTRIBUTION_NONE,
      iconClasses: `fal ${GoalUtils.getIconClassForGoalDistribution(GoalDistribution.NONE)}`
    };
    const optionOwners = {
      value: GoalDistribution.OWNERS,
      friendlyText: GoalsMessages.GOAL_CREATE_DISTRIBUTION_OWNERS,
      iconClasses: `fal ${GoalUtils.getIconClassForGoalDistribution(GoalDistribution.OWNERS)}`
    };
    const optionCompany = {
      value: GoalDistribution.COMPANY,
      friendlyText: GoalsMessages.GOAL_CREATE_DISTRIBUTION_COMPANY,
      iconClasses: `fal ${GoalUtils.getIconClassForGoalDistribution(GoalDistribution.COMPANY)}`
    };

    if (this.globals.hasRole(RoleName.GOAL_COMPANY)) {
      return [optionNone, optionOwners, optionCompany];
    } else if (this.globals.hasMinimumAccessLevelOf(RoleName.MANAGER)) {
      return [optionNone, optionOwners];
    } else {
      return [optionNone];
    }
  }
}
