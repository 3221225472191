import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { Goal } from 'app/models/goals/goal.model';
import { ActivatedRoute, Router } from '@angular/router';
import { Globals } from 'app/shared/globals/globals';
import { CompanyFeatures } from '@app/models/company-features.model';
import { Site } from '@app/models/site.model';
import { RoleName } from '@app/models/user-role.model';
import { GoalType } from '@app/models/goals/goal-type.enum';
import { Page } from '@app/models/api/page.model';
import { ParentFilter } from '@app/models/api/parent-filter.model';
import { FilterOperator } from '@app/models/api/filter-operator.enum';
import { PagingParams } from '@app/models/api/paging-params.model';
import { FilterCategory } from '@app/shared/dynamic-filter/interfaces/filter-category';
import { SelectedFilter } from '@app/shared/dynamic-filter/interfaces/selected-filter';
import { GoalsAPIService } from '@app/shared/api/goals.api.service';
import { State } from '@app/shared/utils/state.model';
import { GoalCategory } from '@app/shared/dynamic-filter/categories/goal-category';
import { Department } from '@app/models/department.model';
import { GoalsMessages } from '@app/goals/goals.messages';
import { CommonMessages } from '@app/constants/common.messages';
import { ColumnInfo } from '@app/shared/column-toggle-new/column.info.model';
import { GoalPriority } from '@app/models/goals/goal-priority.model';
import { GoalUtils } from '@app/shared/utils/goal.utils';
import { Subject } from 'rxjs';
import { CompanyAPIService } from '@app/shared/api/company/company.api.service';
import { GoalTableColumn } from '@app/goals/goals-components/goal-search-table/goal-table-column';
import { FilterCategoryBase } from '@app/shared/dynamic-filter/interfaces/filter-category-base';
import { TableSort } from '@app/models/table-sort';
import { SortingParams } from '@app/models/api/sorting-params.model';
import { SortDirection } from '@app/models/api/sort-direction.enum';
import { TranslateService } from '@ngx-translate/core';
import { DateUtils } from '@app/shared/utils/date.utils';
import { GoalCreateService } from '../goals-create-old/goals-create.service';
import { takeUntil } from 'rxjs/operators';
import { ColumnToggleNewComponent } from '@app/shared/column-toggle-new/column-toggle-new.component';
import { ContextMenuOption } from '@app/models/context-menu-option.model';
import { TerminologyEntity } from '@app/domain/terminology/model/terminology-entity.enum';

interface PageGoal extends Goal {
  dueDuration: string;
}

@Component({
  selector: 'app-goal-search-table',
  templateUrl: './goal-search-table.component.html',
  styleUrls: ['./goal-search-table.component.scss'],
})
export class GoalSearchTableComponent implements OnInit, OnChanges, OnDestroy {

  public readonly eTerminologyEntity = TerminologyEntity;
  public readonly eCommonMessages = CommonMessages;
  public readonly eTableColumn = GoalTableColumn;
  public readonly eGoalsMessages = GoalsMessages;
  public readonly eGoalPriority = GoalPriority;
  public readonly eFeature = CompanyFeatures;
  public readonly eGoalUtils = GoalUtils;
  public readonly eDateUtils = DateUtils;
  public readonly eRoleName = RoleName;
  public readonly eGoalType = GoalType;

  private readonly ngUnsubscribe$ = new Subject<void>();

  @Input() hiddenFilterCategories: FilterCategoryBase[] = [];
  @Input() startDisabledColumns: GoalTableColumn[] = [];
  @Input() selectedFilters: SelectedFilter[] = [];
  @Input() canCreateGoal: boolean;

  @Output() navigatedToGoal: EventEmitter<number> = new EventEmitter<number>();
  @Output() openCreateModal: EventEmitter<boolean> = new EventEmitter<boolean>();

  @ViewChild('columnToggle') columnToggle?: ColumnToggleNewComponent;

  departments: Department[] = [];
  officeLocations: Site[] = [];

  goalsFiltered: Array<PageGoal> = new Array<PageGoal>();
  goalsPage?: Page<PageGoal>;

  parentFilter: ParentFilter = {
    operator: FilterOperator.AND,
    childFilters: []
  };
  pagingParams: PagingParams = {
    pageSize: 15,
    pageNumber: 0
  };

  sortingParams: SortingParams = {
    sortAttributes: ['endDate'],
    sortDirection: SortDirection.ASC
  };
  sort: TableSort = {
    column: GoalTableColumn.DUE_DATE,
    ascending: true,
  };

  filterCategories: FilterCategory[] = [];
  searchProperties: string[] = ['title'];
  componentState: State = new State(false);
  searchState: State = new State(false);
  alwaysEnabledColumns: GoalTableColumn[] = [GoalTableColumn.TITLE];
  opened: boolean[] = [];

  columnInfo: ColumnInfo[];

  hiddenFiltersClientside: string[];

  constructor(
    public globals: Globals,
    private router: Router,
    private route: ActivatedRoute,
    private goalsAPIService: GoalsAPIService,
    private companyAPIService: CompanyAPIService,
    private translateService: TranslateService,
    private goalCreateService: GoalCreateService
  ) {
    this.canCreateGoal = false;
    this.hiddenFiltersClientside = [];
    this.columnInfo = this.getColumnInfo();
  }

  ngOnInit() {
    this.initializeFilterData();

    this.initGoalCreateServiceListeners();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.hiddenFilterCategories || changes.selectedFilters) {
      this.closeAllDropdowns();
      this.populateDynamicFilter();
    }
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.unsubscribe();
  }

  initGoalCreateServiceListeners(): void {
    this.goalCreateService.getGoalSaved()
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(() => this.refreshData());

    this.goalCreateService.getGoalUpdated()
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(() => this.refreshData());

    this.goalCreateService.getRefreshGoals()
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(() => this.refreshData());
  }

  refreshData(): void {
    this.initializeFilterData();
  }

  initializeFilterData() {
    this.componentState.setLoading();

    // This ensures there will be no double-search on init if inputs are provided
    if (this.hiddenFilterCategories.length === 0 && this.selectedFilters.length === 0) {
      this.populateDynamicFilter();
      this.searchGoals();
    }

    this.componentState.setSuccess();
  }

  populateDynamicFilter() {
    const disabledFilterCategoryFields: string[] = this.hiddenFilterCategories.map(c => c.fieldName);

    this.filterCategories = [
      GoalCategory.statusCategory(),
      GoalCategory.completeCategory(),
      GoalCategory.archivedCategory(),
      GoalCategory.priorityCategory(this.translateService),
      GoalCategory.ownerCategory(),
      GoalCategory.typeCategory(this.globals.terminology),
      GoalCategory.departmentCategory(this.globals.getTerminology(TerminologyEntity.DEPARTMENT)),
      GoalCategory.officeLocationCategory(this.globals.terminology),
      GoalCategory.visibilityCategory()
    ].map(fc => {
      if (disabledFilterCategoryFields.includes(fc.fieldName)) {
        fc.hidden = true;
      }
      return fc;
    });
  }

  navigateToGoal(id: number) {
    this.router.navigate(['individual/' + id], {relativeTo: this.route})
      .then(() => {
        this.navigatedToGoal.emit(id);
      });
  }

  doSort(column: GoalTableColumn) {
    if (this.sort.column === column) {
      this.sort.ascending = !this.sort.ascending;
      this.sortingParams.sortDirection = this.sortingParams.sortDirection === SortDirection.ASC ? SortDirection.DESC : SortDirection.ASC;
    } else {
      this.sort.column = column;
      this.sort.ascending = true;
      this.sortingParams.sortAttributes = this.getSortAttributesForCurrentColumn(column);
      this.sortingParams.sortDirection = SortDirection.ASC;
    }
    this.searchGoals();
  }

  getSortAttributesForCurrentColumn(column: GoalTableColumn): string[] {
    switch (column) {
      case GoalTableColumn.PRIORITY:
        return ['priority'];
      case GoalTableColumn.TYPE:
        return ['type'];
      case GoalTableColumn.TITLE:
        return ['title'];
      case GoalTableColumn.DUE_DATE:
        return ['endDate'];
      case GoalTableColumn.DEPARTMENT:
        return ['department.name'];
      case GoalTableColumn.SITE:
        return ['officeLocation.name'];
      default:
        return [];
    }
  }

  closeAllDropdowns() {
    this.opened = [];
  }

  toggleDropdown(i: number) {
    this.opened[i] = !this.opened[i];
  }

  trackGoalsDisplayed(index: number, item: Goal) {
    return item.id;
  }

  showGoalCreate() {
    this.openCreateModal.emit(true);
  }

  updateFilter($event: ParentFilter) {
    this.parentFilter = $event;
    this.pagingParams.pageNumber = 0;
    this.searchGoals();
  }

  searchGoals() {
    this.searchState.setLoading();
    this.goalsAPIService.searchGoals(this.pagingParams, this.sortingParams, this.parentFilter).subscribe(goalsPage => {
      const pageParsed = this.parsePageToPageGoal(goalsPage);

      this.goalsPage = pageParsed;
      this.goalsFiltered = Goal.getGoalArrayCompletionPercentage(pageParsed.content) as PageGoal[];

      this.searchState.setSuccess();
    });
  }

  parsePageToPageGoal(page: Page<Goal>): Page<PageGoal> {
    const pageParsed = page as Page<PageGoal>;

    if (pageParsed) {
      pageParsed.content = this.parseGoalToPageGoalMultiple(pageParsed.content);
    }

    return pageParsed;
  }

  parseGoalToPageGoalMultiple(goals: Goal[]): PageGoal[] {
    return goals.map(g => this.parseGoalToPageGoalSingle(g));
  }

  parseGoalToPageGoalSingle(goal: Goal): PageGoal {
    const goalParsed = goal as PageGoal;

    if (goalParsed) {
      goalParsed.dueDuration = DateUtils.getDaysRemaining(this.translateService, goal.endDate);
    }

    return goalParsed;
  }

  changePageSize($event: number) {
    this.pagingParams.pageSize = $event;
    this.searchGoals();
  }

  changePageNumber($event: number) {
    this.pagingParams.pageNumber = $event;
    this.searchGoals();
  }

  getColumnInfo(): ColumnInfo[] {
    return [
      {
        index: GoalTableColumn.STATUS,
        titleMessageCode: this.getMessageCodeForColumn(GoalTableColumn.STATUS)
      },
      {
        index: GoalTableColumn.OWNERS,
        titleMessageCode: this.getMessageCodeForColumn(GoalTableColumn.OWNERS)
      },
      {
        index: GoalTableColumn.PRIORITY,
        titleMessageCode: this.getMessageCodeForColumn(GoalTableColumn.PRIORITY)
      },
      {
        index: GoalTableColumn.TYPE,
        titleMessageCode: this.getMessageCodeForColumn(GoalTableColumn.TYPE)
      },
      {
        index: GoalTableColumn.TITLE,
        titleMessageCode: this.getMessageCodeForColumn(GoalTableColumn.TITLE)
      },
      {
        index: GoalTableColumn.PROGRESS,
        titleMessageCode: this.getMessageCodeForColumn(GoalTableColumn.PROGRESS)
      },
      {
        index: GoalTableColumn.DUE_DATE,
        titleMessageCode: this.getMessageCodeForColumn(GoalTableColumn.DUE_DATE)
      },
      {
        index: GoalTableColumn.DEPARTMENT,
        titleMessageCode: this.getMessageCodeForColumn(GoalTableColumn.DEPARTMENT)
      },
      {
        index: GoalTableColumn.SITE,
        titleMessageCode: this.getMessageCodeForColumn(GoalTableColumn.SITE)
      }
    ];
  }

  getContextMenuOptions(goal: Goal): ContextMenuOption[] {
    return [
      {
        title: 'View',
        icon: 'fa-eye',
        action: () => {
          this.navigateToGoal(goal.id);
        }
      },
      {
        title: 'Delete',
        icon: 'fa-trash',
        action: () => {
          console.info('Delete would fire if it was hooked up');
        }
      },
      {
        title: 'Copy',
        icon: 'fa-copy',
        action: () => {
          console.info('Copy would fire if it was hooked up');
        }
      }
    ];
  }

  updateHiddenFilters(): void {
    let hiddenFiltersClientside: string[] = [];

    if (this.columnToggle) {
      this.columnInfo.forEach(column => {
        const enabled = this.columnToggle!.getColumnEnabled(column.index);
        if (!enabled) {
          const columnName = this.getMessageCodeForColumn(column.index as GoalTableColumn);

          if (columnName) {
            hiddenFiltersClientside = [...hiddenFiltersClientside, columnName];
          }
        }
      });
    }

    this.hiddenFiltersClientside = hiddenFiltersClientside;
  }

  getMessageCodeForColumn(column: GoalTableColumn): string {
    switch (column) {
      case GoalTableColumn.STATUS:
        return GoalsMessages.STATUS;
      case GoalTableColumn.OWNERS:
        return GoalsMessages.OWNERS;
      case GoalTableColumn.PRIORITY:
        return GoalsMessages.PRIORITY;
      case GoalTableColumn.TYPE:
        return GoalsMessages.TYPE;
      case GoalTableColumn.TITLE:
        return GoalsMessages.TITLE;
      case GoalTableColumn.PROGRESS:
        return GoalsMessages.PROGRESS;
      case GoalTableColumn.DUE_DATE:
        return GoalsMessages.DUE_DATE;
      case GoalTableColumn.DEPARTMENT:
        return this.globals.getTerminology(TerminologyEntity.DEPARTMENT);
      case GoalTableColumn.SITE:
        return this.globals.getTerminology(TerminologyEntity.SITE);
      default:
        return '';
    }
  }
}
