
<app-modal #modal [background]="'light'" [width]="'80%'" [minWidth]="'700px'">
  <div class="container-availability-modal">
    <div class="p-bottom-15">
      <h4 class="standard-heading">
        <span translate>{{eOneToOneMessages.AVAILABILITY}}</span>
      </h4>
    </div>

    <app-state [state]="state">
      <ng-container *ngIf="!state.loading && !state.error">
        <div class="area-main-columns">
          <div class="area-filters">
            <div class="p-bottom-15">
              <div class="frankli-form-group">
                <app-date-picker [formControl]="controlMeetingTime" [locale]="DATEPICKER_LOCALE" [disableUntil]="DATEPICKER_LIMITS.MIN" [disableSince]="DATEPICKER_LIMITS.MAX" [placeholder]="eOneToOneMessages.CHOOSE_DATE_TOOLTIP | translate" [inline]="true"></app-date-picker>
                <div class="frankli-form-group-alert"></div>
              </div>
            </div>
      
            <div class="p-bottom-15">
              <div class="frankli-form-group frankli-form-group-filled" *ngIf="showMeetingLength">
                <label class="less-margin" for="meetingLength" translate>{{eOneToOneMessages.MEETING_LENGTH}}</label>
                <select class="form-control text-capitalize" id="meetingLength" [(ngModel)]="meetingLength" (ngModelChange)="lengthChanged($event)">
                    <option [value]="eOneToOneMeetingLength.FIFTEEN_MINUTES" translate>{{eOneToOneMessages.MEETING_LENGTH_15_MINUTES}}</option>
                    <option [value]="eOneToOneMeetingLength.THIRTY_MINUTES" translate>{{eOneToOneMessages.MEETING_LENGTH_30_MINUTES}}</option>
                    <option [value]="eOneToOneMeetingLength.FORTY_FIVE_MINUTES" translate>{{eOneToOneMessages.MEETING_LENGTH_45_MINUTES}}</option>
                    <option [value]="eOneToOneMeetingLength.SIXTY_MINUTES" translate>{{eOneToOneMessages.MEETING_LENGTH_60_MINUTES}}</option>
                </select>
                <div class="frankli-form-group-alert"></div>
              </div>
            </div>
          </div>
      
          <div class="area-calendar-schedule-outer">
            <div class="area-calendar-schedule">
              <div class="area-calendar-background-timeslots">
                <ng-container *ngFor="let time of [].constructor(24); index as hoursIndex;">
                  <div class="calendar-slot-hour">
                    <div class="calendar-slot-hour-label">
                      <span>{{hoursIndex}}:00</span>
                    </div>
  
                    <ng-container *ngFor="let quarter of [].constructor(4); index as minutesIndex;">
                      <div class="calendar-slot-fifteen-minutes" (click)="trySelectTimeslot(hoursIndex, minutesIndex)" [class.can-select-timeslot]="canSelectTimeslot(hoursIndex, minutesIndex)">
                        <div class="preview-timeslot-selection" [style.height.px]="selectionPreviewHeight"></div>
                      </div>
                    </ng-container>
                  </div>
                </ng-container>
              </div>

              <div class="area-calendar-user-schedules-list">
                <ng-container *ngIf="userCalendarData && userCalendarData.length > 0">
                  <ng-container *ngFor="let userCalendarDataItem of userCalendarData; index as indexUser">
                    <div class="area-calendar-schedule-user-calendar">
                      <div class="area-calendar-schedule-user-details">
                        <div class="display-user-picture-and-name">
                          <app-profile-photo [src]="userCalendarDataItem.user.imageUrl" [firstName]="userCalendarDataItem.user.firstName" [lastName]="userCalendarDataItem.user.lastName" [width]="'40px'"></app-profile-photo>
                          <span class="display-user-name">{{userCalendarDataItem.user.firstName}} {{userCalendarDataItem.user.lastName}}</span>
                        </div>
                        <div class="display-event-count">{{eCommonMessages.EVENT_COUNT(userCalendarDataItem.schedules.length) | translate: { count: userCalendarDataItem.schedules.length } }}</div>
                      </div>
      
                      <div class="area-calendar-schedule-user-events">
                        <ng-container *ngFor="let meeting of userCalendarDataItem.schedules; index as indexSchedules;">
                          <div class="display-event-item" [ngClass]="'event-status-'+meeting.status" [style.top.px]="meeting.top" [style.height.px]="meeting.height" [style.background]="meeting.background">
                            <span>{{meeting.start | date : 'h:mm a'}} - {{meeting.end | date : 'h:mm a'}}</span>
                            <span class="m-right-5"></span>
                            
                            <span class="meeting-status">
                              <ng-container [ngSwitch]="meeting.status">
                                <ng-container *ngSwitchCase="eEventStatus.AWAY">[{{eOneToOneMessages.AWAY | translate}}]</ng-container>
                                <ng-container *ngSwitchCase="eEventStatus.BUSY">[{{eOneToOneMessages.BUSY | translate}}]</ng-container>
                                <ng-container *ngSwitchCase="eEventStatus.FREE">[{{eOneToOneMessages.FREE | translate}}]</ng-container>
                                <ng-container *ngSwitchCase="eEventStatus.OUT_OF_OFFICE">[{{eOneToOneMessages.OUT_OF_OFFICE | translate}}]</ng-container>
                                <ng-container *ngSwitchCase="eEventStatus.TENTATIVE">[{{eOneToOneMessages.TENTATIVE | translate}}]</ng-container>
                                <ng-container *ngSwitchCase="eEventStatus.WORKING_ELSEWEHRE">[{{eOneToOneMessages.WORKING_ELSEWHERE | translate}}]</ng-container>
                                <ng-container *ngSwitchDefault>[{{meeting.status}}]</ng-container>
                              </ng-container>
                            </span>
                          </div>
                        </ng-container>
                      </div>
                    </div>
                  </ng-container>
                </ng-container>
              </div>

              <ng-container *ngIf="currentSelection">
                <div class="area-calendar-preview-current-selection" [style.top.px]="currentSelection.top" [style.height.px]="currentSelection.height">
                  <span translate>{{eCommonMessages.CURRENT_SELECTION}}</span>
                  <span>:&nbsp;</span>
                  <span>{{currentSelection.start | date : 'h:mm a'}} - {{currentSelection.end | date : 'h:mm a'}}</span>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </ng-container>
    </app-state>
  </div>
</app-modal>
