import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Page } from '@app/models/api/page.model';
import { PagingParams } from '@app/models/api/paging-params.model';
import { SortingParams } from '@app/models/api/sorting-params.model';
import { ApiUtils } from '@app/shared/utils/api.utils';
import { IBrowseRoleAPI } from './browse-role-api.model';
import { BrowseRole } from '../model/browse-role.model';

@Injectable()
export class BrowseRoleAPIService implements IBrowseRoleAPI {

  private readonly BASE_URL = 'api/browse-roles';

  constructor(private http: HttpClient) {
  }

  get(ids: number[], name: string): Observable<BrowseRole[]> {
    const url = `${this.BASE_URL}`;

    let params = new HttpParams();

    if (ids) {
      ids.forEach(id => {
        params = params.append('id', id);
      });
    }

    if (name && name.length > 0) {
      params = params.append('name', name);
    }

    return this.http.get<BrowseRole[]>(url, { params: params });
  }

  getPaginated(pagingParams: PagingParams, sortingParams: SortingParams, ids: number[], name: string): Observable<Page<BrowseRole>> {
    const url = `${this.BASE_URL}/paged`;

    let params = ApiUtils.createPageAndSortParams(pagingParams, sortingParams);

    if (ids) {
      ids.forEach(id => {
        params = params.append('id', id);
      });
    }

    if (name && name.length > 0) {
      params = params.append('name', name);
    }

    return this.http.get<Page<BrowseRole>>(url, { params: params });
  }

  getPinnedRoles(): Observable<BrowseRole[]> {
    const url = `${this.BASE_URL}/pinned`;

    return this.http.get<BrowseRole[]>(url);
  }

  markRoleAsPinned(roleId: number): Observable<BrowseRole[]> {
    const url = `${this.BASE_URL}/pin`;

    return this.http.put<BrowseRole[]>(url, roleId);
  }

  unmarkRoleAsPinned(roleId: number): Observable<BrowseRole[]> {
    const url = `${this.BASE_URL}/unpin`;

    return this.http.put<BrowseRole[]>(url, roleId);
  }
}
