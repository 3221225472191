import { Component, ElementRef, forwardRef, Input, OnInit, ViewChild } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { CommonMessages } from '@app/constants/common.messages';
import { IState } from '@app/models/state/state.model';
import { RoleName } from '@app/models/user-role.model';
import { Globals } from '@app/shared/globals/globals';
import { debounceTime, map } from 'rxjs/operators';

interface PageState extends IState {
  searchRunning: boolean;
  dropdownOpen: boolean;
  disabled: boolean;
  searchIsEmpty: boolean;
}

type PickerValue = (RoleName | RoleName[]);

@Component({
  selector: 'app-access-level-picker',
  templateUrl: './access-level-picker.component.html',
  styleUrls: ['./access-level-picker.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => AccessLevelPickerComponent),
    multi: true,
  }],
})
export class AccessLevelPickerComponent implements OnInit, ControlValueAccessor {
  public readonly eCommonMessages = CommonMessages;

  @ViewChild('searchInput') searchInput?: ElementRef<HTMLInputElement>;

  @Input() searchPlaceholder: string;
  @Input() canSelectMultiple: boolean;
  @Input() blacklistedRoleNames: RoleName[];
  @Input() loseFocusAfterSelection: boolean;
  @Input() clearSearchAfterSelection: boolean;

  state: PageState;
  searchControl: FormControl;
  results: RoleName[];
  _value: RoleName[];
  searchIsFocused: boolean;

  onChange = (_: any) => {};
  onTouched = () => {};

  get value(): PickerValue {
    return (this.canSelectMultiple ? this._value : this._value[0]);
  }

  set value(v: PickerValue) {
    if (this.state.disabled) { return; }
    this.writeValue(v);
    this.onChange(v);
  }

  constructor(
    public globals: Globals
  ) {
    this.searchControl = new FormControl();
    this._value = [];
    this.results = [];
    this.blacklistedRoleNames = [];

    this.searchPlaceholder = CommonMessages.SEARCH_BY_NAME;

    this.canSelectMultiple = false;

    this.state = {
      loading: true,
      error: false,
      errorMessage: '',
      searchRunning: false,
      dropdownOpen: false,
      disabled: false,
      searchIsEmpty: true
    };
    this.searchIsFocused = false;
    this.loseFocusAfterSelection = true;
    this.clearSearchAfterSelection = true;
  }

  ngOnInit(): void {
    this.state.loading = false;
    this.doSearch('');
    this.searchControl.valueChanges
      .pipe(
        map(sarg => {
          this.state.searchRunning = true;
          if (sarg) {
            sarg = sarg.trim().toLowerCase();
          }
          return sarg;
        }),
        debounceTime(500)
      )
      .subscribe(sarg => this.trySearch(sarg));
  }

  trySearch(sarg: string): void {
    this.state.searchRunning = true;

    this.state.searchIsEmpty = !(sarg && sarg.length > 0);

    this.doSearch(sarg);
  }
  
  doSearch(sarg: string): void {
    let results = [ RoleName.ADMIN, RoleName.MANAGER, RoleName.USER ];

    results = results.filter(r => !this.blacklistedRoleNames.includes(r)); // Filter out blacklisted

    if (sarg) {
      results = results.filter(s => s.toLowerCase().includes(sarg)); // Only matches to search
    }

    results = results.slice(0, 5); // Only first 5
    // const selectedRoles = this._value;
    // results = results.filter(r => !selectedRoles.includes(r)); // Not already selected
    results = results.filter(r => !this.blacklistedRoleNames.includes(r)); // Not blacklisted

    this.results = results;
    this.state.searchRunning = false;
    this.state.loading = false;
  }

  selectItem(role: RoleName): void {
    if (this.state.disabled) { return; }

    if (this.canSelectMultiple) {
      this.addItemToMultiplePickerValue(role);
    } else {
      this.value = role;
      this.state.dropdownOpen = false;
    }

    this.blurSearchIfToggled();
    this.clearSearchIfToggled();

    this.onTouched();
    this.searchControl.updateValueAndValidity();
  }

  blurSearchIfToggled(): void {
    if (!this.loseFocusAfterSelection) {
      this.focusSearchInput();
    } else {
      this.blurSearchInput();
    }
  }

  clearSearchIfToggled(): void {
    if (this.clearSearchAfterSelection) {
      this.searchControl.patchValue('');
    }
  }

  addItemToMultiplePickerValue(role: RoleName): void {
    const selectedIds = this._value;
    if (selectedIds.includes(role)) { return; }
    this.value = [...this._value, role];
  }

  removeSelectedItem(role: RoleName, event?: MouseEvent): void {
    if (!this.canSelectMultiple) { return; }
    if (event) {
      event.stopPropagation();
    }
    this.value = this._value.filter(r => r !== role);
  }

  writeValue(v: PickerValue): void {
    if (this.canSelectMultiple) {
      v = v || [];
      this._value = v as RoleName[];
    } else {
      if (v) {
        this._value = [v as RoleName];
      } else {
        this._value = [];
      }
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.state.disabled = isDisabled;
    if (isDisabled) {
      this.searchControl.disable();
    } else {
      this.searchControl.enable();
    }
  }

  onFocusSearch(): void {
    this.searchIsFocused = true;
    if (this.state.disabled) { return; }

    this.state.dropdownOpen = true;
  }

  onBlurSearch(): void {
    this.searchIsFocused = false;

    setTimeout(() => {
      if (this.searchIsFocused) { return; }
      this.state.dropdownOpen = false;
    }, 200);
  }

  trySelectFirst() {
    if (this.results.length === 0) {
      if (!this.searchControl.value) { return this.searchControl.updateValueAndValidity(); }
      return;
    } // Can't select first if there are zero results

    this.selectItem(this.results[0]);

    if (!this.canSelectMultiple) {
      this.blurSearchInput();
    }
  }

  focusSearchInput(): void {
    if (!this.searchInput) { return; }
    this.searchInput.nativeElement.focus();
    this.state.dropdownOpen = true;
  }

  blurSearchInput(): void {
    if (!this.searchInput) { return; }
    this.searchInput.nativeElement.blur();
    this.state.dropdownOpen = false;
  }

  onKeyupBackspace(): void {
    if (this.searchControl.value) { return; }
    if (!this.canSelectMultiple) { return; }
    if (!this.state.searchIsEmpty) { return; }
    if (this.state.searchRunning) { return; }

    // Get value
    const value = this.value as RoleName[];

    if (!value) { return; }
    if (value.length === 0) { return; }

    value.pop();

    this.value = value;
    this.searchControl.updateValueAndValidity();
  }

  onKeyupEscape(): void {
    this.blurSearchInput();
  }
}
