/* eslint-disable @typescript-eslint/no-unused-vars */
import {MockService} from '@app/mock/api/mock-service';
import {Injectable} from '@angular/core';
import {HttpRequest} from '@angular/common/http';
import {CompanyService} from '@app/shared/api/interfaces/company/company.service';
import {Company, CompanyFeature, UpdateCompanyFiscalYearDto} from '@app/models/company.model';
import {CreateSiteDto, Site} from '@app/models/site.model';
import {CompanySetUpStep} from '@app/models/company/company-set-up-steps/company-set-up-step.model';
import {OrgChartUserDto} from '@app/models/user/org-chart-user.model';
import {CreateDepartmentDto, Department} from '@app/models/department.model';
import {UpdateCompanyFeaturesDto} from '@app/models/company/update-company-features-dto';
import {Observable, of} from 'rxjs';
import {User} from '@app/models/user/user.model';
import {Globals} from '@app/shared/globals/globals';
import {mockOrganisationalUnits} from '@app/mock/api/data/mockOrganisationalUnits';
import {mockOfficeLocations} from '@app/mock/api/data/mockOfficeLocations';
import {mockCompanies} from '@app/mock/api/data/mockCompanies';
import {mockUsers} from '@app/mock/api/data/mockUsers';
import {sanitizeUrl} from '@app/shared/utils/helpers';
import {CompanySetUpStepType} from '@app/models/company/company-set-up-steps/company-set-up-step-type.enum';
import {UserMinimal} from '@app/models/user/user-minimal.model';
import {mockCompanySetUpSteps} from '../../data/mockCompanySetUpSteps';
import {ProvisionedOfficeLocation} from '@app/models/ProvisionedOfficeLocation';
import {ProvisionedOrganisationalUnit} from '@app/models/ProvisionedOrganisationalUnit';

@Injectable()
export class CompanyMockService implements MockService, CompanyService {
  constructor(private readonly globals: Globals) {
  }

  handleRoute(req: HttpRequest<any>): any {
    const url = sanitizeUrl(req.urlWithParams);

    switch (true) {
      case url.endsWith('api/company/me'):
        return this.getMyCompany();
      case url.endsWith('api/company/department'):
        return this.getDepartmentByType('department');
      case url.endsWith('api/company/department?type=Department'):
        return this.getDepartmentByType('department');
      case url.endsWith('api/company/site'):
        return this.getAllSites();
      case url.endsWith('api/company/org-chart'):
        return this.getCompanyOrgChart();
      case url.endsWith('api/set-up-steps'):
        return this.getSetUpSteps();
    }
  }

  getAllProvisionedOrganisationalUnits(): ProvisionedOrganisationalUnit[] | Observable<ProvisionedOrganisationalUnit[]> {
    return [];
  }

  getProvisionedOrganisationalUnitByOrganisationalUnitId(organisationalUnitId: number): ProvisionedOrganisationalUnit | Observable<ProvisionedOrganisationalUnit> {
    return null;
  }

  getAllProvisionedOfficeLocations(): ProvisionedOfficeLocation[] | Observable<ProvisionedOfficeLocation[]> {
    return [];
  }

  getProvisionedOfficeLocationByOfficeLocationId(officeLocationId: number): ProvisionedOfficeLocation | Observable<ProvisionedOfficeLocation> {
    return null;
  }

  getAllSites(): Observable<Site[]> | Site[] {
    return mockOfficeLocations;
  }

  getById(id: number): Observable<Company> | Company {
    return mockCompanies.find(c => c.id === id);
  }

  delete(companyId: number): Observable<void> {
    return of(null);
  }

  getCompanyOrgChart(): Observable<Array<OrgChartUserDto>> | OrgChartUserDto[] {
    const ceo = mockUsers.find(u => u.id === 1);
    return [
      {
        userId: ceo.id,
        managerId: ceo.id,
        userInfo: {
          name: ceo.firstName + ' ' + ceo.lastName,
          imageURL: ceo.imageUrl,
          position: ceo.position.name,
          location: ceo.officeLocation.name
        },
        goalInfo: null,
        user: null,
        desc: null,
        children: this.getOrgChartDirectReports(ceo),
        collapsed: true,
        showPercentages: false
      }
    ];
  }

  private getOrgChartDirectReports(manager: User): OrgChartUserDto[] {
    return mockUsers.filter(u => u.id !== manager.id && u.managerId === manager.id).map(u => ({
      userId: u.id,
      managerId: u.id,
      userInfo: {
        name: u.firstName + ' ' + u.lastName,
        imageURL: u.imageUrl,
        position: u.position.name,
        location: u.officeLocation.name
      },
      goalInfo: null, // TODO Add Goal Info
      user: null,
      desc: null,
      children: this.getOrgChartDirectReports(u),
      collapsed: true,
      showPercentages: false
    }));
  }

  getMyCompany(): Observable<Company> | Company {
    return this.globals.company;
  }

  getDepartmentByType(type: string): Observable<Department[]> | Department[] {
    return mockOrganisationalUnits;
  }

  getSetUpSteps(): Observable<Array<CompanySetUpStep>> | CompanySetUpStep[] {
    return mockCompanySetUpSteps;
  }

  // No Ops are listed below
  createSite(officeLocation: Site): Observable<Site> {
    return undefined;
  }

  createDepartment(organisationalUnit: Department): Observable<Department> {
    return undefined;
  }

  deleteSite(id: number): Observable<Site> {
    return undefined;
  }

  deleteDepartment(departmentId: number): Observable<Department> {
    return undefined;
  }

  skipCompanySetUpStepType(type: CompanySetUpStepType): Observable<CompanySetUpStep> {
    return undefined;
  }

  updateFeatures(features: Array<CompanyFeature>): Observable<Array<CompanyFeature>> {
    return undefined;
  }

  updateFeaturesForId(companyId: number, dto: UpdateCompanyFeaturesDto): Observable<Array<CompanyFeature>> {
    return undefined;
  }

  updateFiscalYear(updateCompanyFiscalYearDto: UpdateCompanyFiscalYearDto): Observable<Company> {
    return undefined;
  }

  updateSite(siteId: number, createSiteDto: CreateSiteDto): Observable<Site> {
    return undefined;
  }

  updateDepartment(departmentId: number, createDepartmentDto: CreateDepartmentDto): Observable<Department> {
    return undefined;
  }

  deleteSitesMultiple(siteIds: number[]): Observable<Site[]> {
    return undefined;
  }

  deleteDepartmentsMultiple(departmentIds: number[]): Observable<Department[]> {
    return undefined;
  }

  getAllUsersAssignedToSite(siteId: number): Observable<UserMinimal[]> {
    return undefined;
  }

  getAllUsersAssignedToDepartment(departmentId: number): Observable<UserMinimal[]> {
    return undefined;
  }

}