import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { OneToOneMessages } from '@app/domain/one_to_one/locale/one-to-one.messages';
import { OneToOneFrequency } from '@app/domain/one_to_one/model/one-to-one-frequency.model';
import { OneToOneMeetingStatus } from '@app/domain/one_to_one/model/one-to-one-meeting-status.model';
import { OneToOneMeetingMinimal } from '@app/domain/one_to_one/model/one-to-one-meeting.model';
import { OneToOneScheduleDetailsView } from '@app/domain/one_to_one/model/one-to-one-schedule.model';
import { IState } from '@app/models/state/state.model';
import { ButtonGroupOption } from '@app/shared/components/inputs/button-group/button-group.component';
import { IMyDate, IMyMarkedDates } from 'angular-mydatepicker';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

enum ScheduleTabType {
  CALENDAR = 'CALENDAR',
  LIST = 'LIST'
}

@Component({
  selector: 'app-information-sidebar-schedule-calendar',
  templateUrl: './information-sidebar-schedule-calendar.component.html',
  styleUrls: ['./information-sidebar-schedule-calendar.component.scss']
})
export class InformationSidebarScheduleCalendarComponent implements OnInit, OnChanges, OnDestroy {
  private readonly ngUnsubscribe$ = new Subject<void>();
  public readonly eOneToOneFrequency = OneToOneFrequency;
  public readonly eOneToOneMessages = OneToOneMessages;
  public readonly eScheduleTabType = ScheduleTabType;
  public readonly DISABLE_SINCE = { year: 1900, month: 1, day: 1 };

  @Input() schedule: OneToOneScheduleDetailsView;
  @Input() meetingNavigationControl: FormControl;

  state: IState;

  controlTab: FormControl;
  buttonGroupOptions: ButtonGroupOption[];

  controlDatePicker: FormControl;

  markedDates: IMyMarkedDates[];
  meetingDates: IMyDate[];

  constructor() {
    this.schedule = undefined;
    this.meetingNavigationControl = new FormControl(null, []);

    this.state = {
      loading: true,
      error: false,
      errorMessage: ''
    };
    this.markedDates = [];

    this.controlTab = this.initControlTab();
    this.buttonGroupOptions = this.getButtonGroupOptions();
    this.controlDatePicker = this.initControlDatePicker();
  }

  ngOnInit(): void {
    this.state.loading = false;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.meetingNavigationControl && changes.meetingNavigationControl.currentValue) {
      this.meetingNavigationControl.valueChanges
        .pipe(takeUntil(this.ngUnsubscribe$))
        .subscribe((meetingId: number) => this.onMeetingChanged(meetingId));
      this.onMeetingChanged(this.meetingNavigationControl.value);
    }

    if (changes.schedule && changes.schedule.currentValue) {
      this.onScheduleChanged(changes.schedule.currentValue);
    }
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.unsubscribe();
  }

  initControlTab(): FormControl {
    const formControl = new FormControl(ScheduleTabType.CALENDAR, []);

    return formControl;
  }

  getButtonGroupOptions(): ButtonGroupOption[] {
    return [
      {
        value: ScheduleTabType.CALENDAR,
        friendlyText: OneToOneMessages.VIEW_CALENDAR,
        iconClasses: undefined,
        color: undefined
      },
      {
        value: ScheduleTabType.LIST,
        friendlyText: OneToOneMessages.VIEW_LIST,
        iconClasses: undefined,
        color: undefined
      }
    ];
  }

  onClickMeetingItem(meetingItem: OneToOneMeetingMinimal): void {
    if (!meetingItem.id) { return; }
    if (meetingItem.id === this.meetingNavigationControl.value) { return; }
    this.meetingNavigationControl.setValue(meetingItem.id);
  }

  initControlDatePicker(): FormControl {
    const formControl = new FormControl(null, []);

    formControl.valueChanges.subscribe((value: Date) => this.onDatePickerChanged(value));

    return formControl;
  }

  onMeetingChanged(meetingId: number): void {
    if (!meetingId) { return; }

    // If meeting is updated, set the date picker to the date of that meeting
    const meeting = this.schedule.meetingList.find(m => m.id === meetingId);
    if (!meeting) { return; }
    if (this.controlDatePicker.value === meeting.meetingTimestamp) { return; }

    this.controlDatePicker.setValue(meeting.meetingTimestamp);
  }

  onDatePickerChanged(date: Date): void {
    if (!date) { return; }

    // If date is updated, set the meeting to the first meeting on that date
    const meeting = this.schedule.meetingList.find(m => new Date(m.meetingTimestamp).toDateString() === new Date(date).toDateString());

    if (!meeting) { return; }
    if (this.meetingNavigationControl.value === meeting.id) { return; }

    this.meetingNavigationControl.setValue(meeting.id);
  }

  onScheduleChanged(schedule: OneToOneScheduleDetailsView): void {
    this.markedDates = this.getMarkedDatesForSchedule(schedule);
    this.meetingDates = this.getMeetingDatesForSchedule(schedule);
  }

  /**
   * Groups dates and colour codes then by status
   * The model for IMarkedDates is:
   * dates: Array<IMyDate>;
   * color?: string;
   * styleClass?: string;
   * @param schedule A list of marked dates
   */
  getMarkedDatesForSchedule(schedule: OneToOneScheduleDetailsView): IMyMarkedDates[] {
    const datesByStatus = {};

    schedule.meetingList.forEach(meeting => {
      const date = new Date(meeting.meetingTimestamp);
      const meetingStatus = meeting.status;

      if (!Object.hasOwnProperty.call(datesByStatus, meetingStatus)) {
        datesByStatus[meetingStatus] = [];
      }

      datesByStatus[meetingStatus].push(date);
    });

    const markedDates: IMyMarkedDates[] = [];

    const keys = Object.keys(datesByStatus);
    for (const key of keys) {
      markedDates.push({
        dates: datesByStatus[key].map(date => {
          return {
            year: date.getFullYear(),
            month: date.getMonth() + 1,
            day: date.getDate()
          };
        }),
        // color: this.getColorForStatus(key as OneToOneMeetingStatus)
        styleClass: this.getClassForStatus(key as OneToOneMeetingStatus)
      });
    }

    return markedDates;
  }

  getColorForStatus(status: OneToOneMeetingStatus): string {
    switch (status) {
      case OneToOneMeetingStatus.SCHEDULED:
        return '#FFAB00';
      case OneToOneMeetingStatus.IN_PROGRESS:
      case OneToOneMeetingStatus.REOPENED:
        return '#36B37E';
      case OneToOneMeetingStatus.MISSED:
      case OneToOneMeetingStatus.CANCELLED:
        return '#FF5630';
      case OneToOneMeetingStatus.COMPLETED:
      case OneToOneMeetingStatus.AUTO_COMPLETED:
        return '#254DC6';
      default:
        return undefined;
    }
  }

  getClassForStatus(status: OneToOneMeetingStatus): string {
    switch (status) {
      case OneToOneMeetingStatus.SCHEDULED:
        return 'meeting-date-scheduled';
      case OneToOneMeetingStatus.IN_PROGRESS:
      case OneToOneMeetingStatus.REOPENED:
        return 'meeting-date-in-progress';
      case OneToOneMeetingStatus.MISSED:
      case OneToOneMeetingStatus.CANCELLED:
        return 'meeting-date-didnt-happen';
      case OneToOneMeetingStatus.COMPLETED:
      case OneToOneMeetingStatus.AUTO_COMPLETED:
        return 'meeting-date-completed';
      default:
        return undefined;
    }
  }

  getMeetingDatesForSchedule(schedule: OneToOneScheduleDetailsView): IMyDate[] {
    return schedule.meetingList.map(meeting => {
      const date = new Date(meeting.meetingTimestamp);
      return {
        year: date.getFullYear(),
        month: date.getMonth() + 1,
        day: date.getDate()
      };
    });
  }
}
