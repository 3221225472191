<div class="chart-container">
  <!-- Each row will be 50px high, 1px border at the top -->
  <!-- 215 high at minimum (4 groups of 50 and 15 for the labels) -->
  <div class="chart-line-h-container">
    <div class="line-h-row">
      <div class="line-h-label">10</div>
    </div>
    <div class="line-h-row">
        <div class="line-h-label">&nbsp;</div>
    </div>
    <div class="line-h-row">
        <div class="line-h-label">5</div>
    </div>
    <div class="line-h-row">
        <div class="line-h-label">&nbsp;</div>
    </div>
    <div class="line-h-row row-bottom">
        <div class="line-h-label">0</div>
    </div>
  </div>

  <div class="chart-data-container">
    <div class="chart-data-container-inner">
      <ng-container *ngFor="let col of chartData">
        <div class="chart-bar-column" [style.width.px]="columnWidth" [class.col-selected]="col.id === selectedId">
          <div class="bar-container">
            <div class="bar-fill" [style.height.%]="col.height"></div>
          </div>
          <div class="bar-label-container">{{col.label}}</div>
        </div>
      </ng-container>
      <svg class="trendline" #trendline></svg>
    </div>
  </div>
</div>