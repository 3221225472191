import { Injectable } from '@angular/core';
import { TerminologyAPIService } from './terminology-api-service.model';
import { CreateTerminologyItem, TerminologyItem } from '../model/terminology-item.model';
import { NotifyUtils } from '@app/shared/utils/notify.utils';
import { AdminMessages } from '@app/admin/admin.messages';
import { Observable } from 'rxjs';
import { TerminologyCollection } from '../model/terminology-collection.model';

@Injectable({
  providedIn: 'root'
})
export class TerminologyBusinessService {

  constructor(
    private terminologyApiService: TerminologyAPIService,
    private notifyUtils: NotifyUtils
  ) { }

  public getCurrent(): Observable<TerminologyCollection> {
    return this.terminologyApiService.getCurrent();
  }

  public getAll(): Observable<TerminologyItem[]> {
    return this.terminologyApiService.getAll();
  }
  
  public updateMultiple(createDtos: CreateTerminologyItem[]): Promise<TerminologyItem[]> {
    return this.terminologyApiService.updateMultiple(createDtos)
      .toPromise()
      .then(res => {
        this.notifyUtils.notify(AdminMessages.TERMINOLOGY.SUCCESS_UPDATE_TERMINOLOGY);
        return res;
      });
  }

}
