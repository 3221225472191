<div class="form-controls-list-container">
  <div class="form-controls-group" #dropdownTypeContainer>
    <app-dropdown-display [title]="dropdownMetadata[eFormPart.TYPE].title" [description]="dropdownMetadata[eFormPart.TYPE].description" [expanded]="true" (expandedChange)="onToggleDropdown(eFormPart.TYPE, $event)" #dropdownType>
      <app-goal-create-part-type [typesAvailable]="dataGoalType" [controlType]="controlGoalType" [parentDropdownOpen]="dropdownType.expanded" [submitted]="submitted" [submittedDraft]="submittedDraft" (onSetParentDropdown)="setDropdown(dropdownType, $event)"></app-goal-create-part-type>
    </app-dropdown-display>
  </div>
  
  <ng-container *ngIf="checkIfFormPartIsBeforeOther(furthestPart, eFormPart.TYPE)">
    <div class="form-controls-group" #dropdownDetailsContainer>
      <app-dropdown-display [iconClass]="'fa-bullseye'" [title]="dropdownMetadata[eFormPart.DETAILS].title" [description]="dropdownMetadata[eFormPart.DETAILS].description" (expandedChange)="onToggleDropdown(eFormPart.DETAILS, $event)" #dropdownDetails>
        <app-goal-create-part-details [controlTitle]="controlGoalTitle" [controlEndDate]="controlGoalEndDate" [controlVisibility]="controlGoalVisibility" [minDueDate]="minDataDate" [parentDropdownOpen]="dropdownDetails.expanded" [submitted]="submitted" [submittedDraft]="submittedDraft" (onSetParentDropdown)="setDropdown(dropdownDetails, $event)" #controlDetails></app-goal-create-part-details>
      </app-dropdown-display>
    </div>
  
    <ng-container *ngIf="checkIfFormPartIsBeforeOther(furthestPart, eFormPart.DETAILS)">
      <ng-container [ngSwitch]="controlGoalType.value">
        <ng-container *ngSwitchCase="eGoalType.PERSONAL_DEVELOPMENTAL">
          <div class="form-controls-group" #dropdownDevelopmentNeedsContainer>
            <app-dropdown-display [title]="dropdownMetadata[eFormPart.DEVELOPMENT_NEEDS].title" [description]="dropdownMetadata[eFormPart.DEVELOPMENT_NEEDS].description" (expandedChange)="onToggleDropdown(eFormPart.DEVELOPMENT_NEEDS, $event)" #dropdownDevelopmentNeeds>
              <app-goal-create-part-development-needs [controlDevelopmentNeeds]="controlGoalDevelopmentNeeds" [parentDropdownOpen]="dropdownDevelopmentNeeds.expanded" [submitted]="submitted" [submittedDraft]="submittedDraft" (onSetParentDropdown)="setDropdown(dropdownDevelopmentNeeds, $event)" #controlDevelopmentNeeds></app-goal-create-part-development-needs>
            </app-dropdown-display>
          </div>
        </ng-container>

        <ng-container *ngSwitchCase="eGoalType.OFFICE_LOCATION">
          <div class="form-controls-group" #dropdownSiteContainer>
            <app-dropdown-display [title]="dropdownMetadata[eFormPart.SITE].title" [description]="dropdownMetadata[eFormPart.SITE].description" (expandedChange)="onToggleDropdown(eFormPart.SITE, $event)" #dropdownSite>
              <app-goal-create-part-site [controlSite]="controlGoalSite" [parentDropdownOpen]="dropdownSite.expanded" [submitted]="submitted" [submittedDraft]="submittedDraft" (onSetParentDropdown)="setDropdown(dropdownSite, $event)" #controlSite></app-goal-create-part-site>
            </app-dropdown-display>
          </div>
        </ng-container>
    
        <ng-container *ngSwitchCase="eGoalType.DEPARTMENT">
          <div class="form-controls-group" #dropdownDepartmentContainer>
            <app-dropdown-display [title]="dropdownMetadata[eFormPart.DEPARTMENT].title" [description]="dropdownMetadata[eFormPart.DEPARTMENT].description" (expandedChange)="onToggleDropdown(eFormPart.DEPARTMENT, $event)" #dropdownDepartment>
              <app-goal-create-part-department [controlDepartment]="controlGoalDepartment" [parentDropdownOpen]="dropdownDepartment.expanded" [submitted]="submitted" [submittedDraft]="submittedDraft" (onSetParentDropdown)="setDropdown(dropdownDepartment, $event)" #controlDepartment></app-goal-create-part-department>
            </app-dropdown-display>
          </div>
        </ng-container>
      </ng-container>
  
      <!-- (controlGoalType.value === eGoalType.PERSONAL_DEVELOPMENTAL && checkIfFormPartIsBeforeOther(furthestPart, eFormPart.DEVELOPMENT_NEEDS)) || -->
      <!-- , eGoalType.PERSONAL_DEVELOPMENTAL -->
      <ng-container *ngIf="
        (controlGoalType.value === eGoalType.DEPARTMENT && checkIfFormPartIsBeforeOther(furthestPart, eFormPart.DEPARTMENT)) ||
        (controlGoalType.value === eGoalType.OFFICE_LOCATION && checkIfFormPartIsBeforeOther(furthestPart, eFormPart.SITE)) ||
        (![eGoalType.DEPARTMENT, eGoalType.OFFICE_LOCATION].includes(controlGoalType.value) && checkIfFormPartIsBeforeOther(furthestPart, eFormPart.DETAILS))
      ">
        <div class="form-controls-group" #dropdownKeyResultsContainer>
          <app-dropdown-display [title]="dropdownMetadata[eFormPart.KEY_RESULTS].title" [description]="dropdownMetadata[eFormPart.KEY_RESULTS].description" (expandedChange)="onToggleDropdown(eFormPart.KEY_RESULTS, $event)" #dropdownKeyResults>
            <app-goal-create-part-key-results [controlKeyResults]="controlGoalKeyResults" [controlDueDate]="controlGoalEndDate" [minDueDate]="minDataDate" [maxDueDate]="maxLinkedItemDate" [parentDropdownOpen]="dropdownKeyResults.expanded" [submitted]="submitted" [submittedDraft]="submittedDraft" (onSetParentDropdown)="setDropdown(dropdownKeyResults, $event)" #controlKeyResults></app-goal-create-part-key-results>
          </app-dropdown-display>
        </div>
  
        <ng-container *ngIf="checkIfFormPartIsBeforeOther(furthestPart, eFormPart.KEY_RESULTS)">
          <div class="form-controls-group" #dropdownOwnersContainer>
            <app-dropdown-display [title]="dropdownMetadata[eFormPart.OWNERS].title" [description]="dropdownMetadata[eFormPart.OWNERS].description" (expandedChange)="onToggleDropdown(eFormPart.OWNERS, $event)" #dropdownOwners>
              <app-goal-create-part-owners [controlOwners]="controlGoalOwners" [controlDistribution]="controlGoalDistribution" [parentDropdownOpen]="dropdownOwners.expanded" [submitted]="submitted" [submittedDraft]="submittedDraft" (onSetParentDropdown)="setDropdown(dropdownOwners, $event)" #controlOwners></app-goal-create-part-owners>
            </app-dropdown-display>
          </div>
        
          <div class="form-controls-group" *ngIf="controlGoalType.value && (controlGoalType.value !== eGoalType.COMPANY)" #dropdownAlignmentContainer>
            <app-dropdown-display [title]="dropdownMetadata[eFormPart.ALIGNMENT].title" [description]="dropdownMetadata[eFormPart.ALIGNMENT].description" (expandedChange)="onToggleDropdown(eFormPart.ALIGNMENT, $event)" #dropdownAlignment>
              <app-goal-create-part-alignment [controlAlignment]="controlGoalAlignment" [controlType]="controlGoalType" [parentDropdownOpen]="dropdownAlignment.expanded" [submitted]="submitted" [submittedDraft]="submittedDraft" (onSetParentDropdown)="setDropdown(dropdownAlignment, $event)" #controlAlignment></app-goal-create-part-alignment>
            </app-dropdown-display>
          </div>

          <div class="form-controls-group" *ngIf="globals.hasFeature(eCompanyFeatures.GOAL_PRIORITY)" #dropdownPriorityContainer>
            <app-dropdown-display [title]="dropdownMetadata[eFormPart.PRIORITY].title" [description]="dropdownMetadata[eFormPart.PRIORITY].description" (expandedChange)="onToggleDropdown(eFormPart.PRIORITY, $event)" #dropdownPriority>
              <app-goal-create-part-priority [prioritiesAvailable]="dataGoalPriorities" [controlPriority]="controlGoalPriority" [parentDropdownOpen]="dropdownPriority.expanded" [submitted]="submitted" [submittedDraft]="submittedDraft" (onSetParentDropdown)="setDropdown(dropdownPriority, $event)" #controlPriority></app-goal-create-part-priority>
            </app-dropdown-display>
          </div>
        
          <div class="form-controls-group" #dropdownTagsContainer>
            <app-dropdown-display [title]="dropdownMetadata[eFormPart.TAGS].title" [description]="dropdownMetadata[eFormPart.TAGS].description" (expandedChange)="onToggleDropdown(eFormPart.TAGS, $event)" #dropdownTags>
              <app-goal-create-part-tags [controlTags]="controlGoalTags" [parentDropdownOpen]="dropdownTags.expanded" [submitted]="submitted" [submittedDraft]="submittedDraft" (onSetParentDropdown)="setDropdown(dropdownTags, $event)" #controlTags></app-goal-create-part-tags>
            </app-dropdown-display>
          </div>
      
          <div class="form-controls-group" #dropdownTasksContainer *ngIf="globals.hasFeature(eCompanyFeatures.TODOS)">
            <app-dropdown-display [title]="dropdownMetadata[eFormPart.TASKS].title" [description]="dropdownMetadata[eFormPart.TASKS].description" (expandedChange)="onToggleDropdown(eFormPart.TASKS, $event)" #dropdownTasks>
              <app-goal-create-part-tasks [controlTasks]="controlGoalTasks" [controlDueDate]="controlGoalEndDate" [minDueDate]="minDataDate" [maxDueDate]="maxLinkedItemDate" [parentDropdownOpen]="dropdownTasks.expanded" [submitted]="submitted" [submittedDraft]="submittedDraft" (onSetParentDropdown)="setDropdown(dropdownTasks, $event)" #controlTasks></app-goal-create-part-tasks>
            </app-dropdown-display>
          </div>
        </ng-container>
      </ng-container>
    </ng-container>
  </ng-container>
</div>
