import { Injectable } from '@angular/core';
import { OneToOneSchedule, OneToOneScheduleDetailsView, OneToOneScheduleMinimal } from '../model/one-to-one-schedule.model';
import { OneToOneMessages } from '../locale/one-to-one.messages';
import { OneToOneAPIService } from '../api/one-to-one-api.service';
import { NotifyUtils } from '@app/shared/utils/notify.utils';
import { Observable } from 'rxjs';
import { TalkingPointTemplate } from '../model/talking-point-template.model';
import { CreateOneToOneSchedule } from '../model/create-one-to-one-schedule.model';
import { TransferOneToOneSchedule } from '../model/transfer-one-to-one-schedule.model';
import { ParentFilter } from '@app/models/api/parent-filter.model';
import { Page } from '@app/models/api/page.model';
import { PagingParams } from '@app/models/api/paging-params.model';
import { SortingParams } from '@app/models/api/sorting-params.model';
import { OneToOneMeeting } from '../model/one-to-one-meeting.model';
import { TalkingPoint } from '../model/talking-point.model';
import { OneToOneNoteMinimal } from '../model/one-to-one-note.model';
import { OneToOneMeetingFile } from '../model/one-to-one-meeting-file.model';
import { RescheduleOneToOneMeeting } from '../model/reschedule-one-to-one-meeting.model';
import { OneToOneMeetingStatus } from '../model/one-to-one-meeting-status.model';
import { CreateMeetingSentimentScaleScore, MeetingSentimentScaleScore } from '../model/meeting-sentiment-scale-score.model';
import { ScheduleSentimentScaleTimeline } from '@app/shared/components/information-sidebar/tabs/information-sidebar-sentiment-scale-timeline/information-sidebar-sentiment-scale-timeline.component';

@Injectable({
  providedIn: 'root'
})
export class OneToOneBusinessService {

  private static _updatingSchedules: number[];
  private static _updatingMeetings: number[];

  constructor(
    private oneToOneAPIService: OneToOneAPIService,
    private notifyUtils: NotifyUtils
  ) {
    OneToOneBusinessService._updatingSchedules = [];
    OneToOneBusinessService._updatingMeetings = [];
  }

  public isModifyingSchedule(scheduleId: number): boolean {
    return OneToOneBusinessService._updatingSchedules.includes(scheduleId);
  }

  public isModifyingMeeting(meetingId: number): boolean {
    return OneToOneBusinessService._updatingMeetings.includes(meetingId);
  }

  private startUpdatingSchedule(scheduleId: number): void {
    OneToOneBusinessService._updatingSchedules.push(scheduleId);
  }

  private finishUpdatingSchedule(scheduleId: number): void {
    OneToOneBusinessService._updatingSchedules = OneToOneBusinessService._updatingSchedules.filter(tid => tid !== scheduleId);
  }

  private startUpdatingMeeting(meetingId: number): void {
    OneToOneBusinessService._updatingMeetings.push(meetingId);
  }

  private finishUpdatingMeeting(meetingId: number): void {
    OneToOneBusinessService._updatingMeetings = OneToOneBusinessService._updatingMeetings.filter(tid => tid !== meetingId);
  }

  createSchedule(createDto: CreateOneToOneSchedule): Promise<OneToOneSchedule> {
    return this.oneToOneAPIService.createOneToOneSchedule(createDto).toPromise()
      .then(res => {
        this.notifyUtils.notify(OneToOneMessages.CREATE_SUCCESS_TOAST);
        return res;
      });
  }

  updateSchedule(id: number, updateDto: CreateOneToOneSchedule): Promise<OneToOneSchedule> {
    if (this.isModifyingSchedule(id)) { return new Promise((resolve, reject) => reject()); }
    this.startUpdatingSchedule(id);

    return this.oneToOneAPIService.updateOneToOneSchedule(id, updateDto).toPromise()
      .then(res => {
        this.notifyUtils.notify(OneToOneMessages.EDIT_SUCCESS_TOAST);
        return res;
      })
      .finally(() => {
        this.finishUpdatingSchedule(id);
      });
  }

  pause(id: number): Promise<OneToOneSchedule> {
    if (this.isModifyingSchedule(id)) { return new Promise((resolve, reject) => reject()); }
    this.startUpdatingSchedule(id);

    return this.oneToOneAPIService.pauseSchedule(id).toPromise()
      .then(res => {
        this.notifyUtils.notify(OneToOneMessages.MEETING_PAUSED_TOAST);
        return res;
      })
      .finally(() => {
        this.finishUpdatingSchedule(id);
      });
  }

  resume(id: number): Promise<OneToOneSchedule> {
    if (this.isModifyingSchedule(id)) { return new Promise((resolve, reject) => reject()); }
    this.startUpdatingSchedule(id);

    return this.oneToOneAPIService.resumeSchedule(id).toPromise()
      .then(res => {
        this.notifyUtils.notify(OneToOneMessages.MEETING_RESUMED_TOAST);
        return res;
      })
      .finally(() => {
        this.finishUpdatingSchedule(id);
      });
  }

  archive(id: number): Promise<OneToOneSchedule> {
    if (this.isModifyingSchedule(id)) { return new Promise((resolve, reject) => reject()); }
    this.startUpdatingSchedule(id);

    return this.oneToOneAPIService.archiveSchedule(id).toPromise()
      .then(res => {
        this.notifyUtils.notify(OneToOneMessages.MEETING_ARCHIVED_TOAST);
        return res;
      })
      .finally(() => {
        this.finishUpdatingSchedule(id);
      });
  }

  unarchive(id: number): Promise<OneToOneSchedule> {
    if (this.isModifyingSchedule(id)) { return new Promise((resolve, reject) => reject()); }
    this.startUpdatingSchedule(id);

    return this.oneToOneAPIService.resumeSchedule(id).toPromise()
      .then(res => {
        this.notifyUtils.notify(OneToOneMessages.MEETING_UNARCHIVED_TOAST);
        return res;
      })
      .finally(() => {
        this.finishUpdatingSchedule(id);
      });
  }

  delete(id: number): Promise<OneToOneSchedule> {
    if (this.isModifyingSchedule(id)) { return new Promise((resolve, reject) => reject()); }
    this.startUpdatingSchedule(id);

    return this.oneToOneAPIService.deleteSchedule(id).toPromise()
      .then(res => {
        this.notifyUtils.notify(OneToOneMessages.MEETING_DELETED_TOAST);
        return res;
      })
      .finally(() => {
        this.finishUpdatingSchedule(id);
      });
  }

  getOverviewPageData(filter: ParentFilter, paging: PagingParams, sorting: SortingParams): Observable<Page<OneToOneScheduleMinimal>> {
    return this.oneToOneAPIService.getOneToOneSchedulesForOverview(filter, paging, sorting);
  }

  getOneToOneSchedulesForManagerMe(): Observable<OneToOneScheduleDetailsView[]> {
    return this.oneToOneAPIService.getOneToOneSchedulesForManagerMe();
  }

  getTalkingPointTemplateByName(name: string): Observable<TalkingPointTemplate> {
    return this.oneToOneAPIService.getTalkingPointTemplateByName(name);
  }

  getDefaultTalkingPointTemplate(): Observable<TalkingPointTemplate> {
    return this.oneToOneAPIService.getDefaultTalkingPointTemplate();
  }

  getScheduleById(scheduleId: number): Observable<OneToOneScheduleMinimal> {
    return this.oneToOneAPIService.getOneToOneScheduleById(scheduleId);
  }

  getMeetingById(scheduleId: number, meetingId: number): Observable<OneToOneMeeting> {
    return this.oneToOneAPIService.getOneToOneMeetingById(scheduleId, meetingId);
  }
  
  getOneToOneScheduleDetailsById(scheduleId: number): Observable<OneToOneScheduleDetailsView> {
    return this.oneToOneAPIService.getOneToOneScheduleDetailsById(scheduleId);
  }

  getSchedulesManagedByUserId(userId: number): Observable<OneToOneScheduleDetailsView[]> {
    return this.oneToOneAPIService.getSchedulesManagedByUserId(userId);
  }

  rebuildCalendarEventsForScheduleId(id: number): Promise<OneToOneSchedule> {
    if (this.isModifyingSchedule(id)) { return new Promise((resolve, reject) => reject()); }
    this.startUpdatingSchedule(id);

    return this.oneToOneAPIService.rebuildCalendarEventsForScheduleId(id)
      .toPromise()
      .then(res => {
        this.notifyUtils.notify(OneToOneMessages.CALENDAR_REBUILD_SUCCESS);
        return res;
      })
      .finally(() => {
        this.finishUpdatingSchedule(id);
      });
  }

  rebuildCalendarEventsForUserId(userId): Promise<boolean> {
    return this.oneToOneAPIService.rebuildCalendarEventsForUserId(userId)
      .toPromise()
      .then(res => {
        this.notifyUtils.notify(OneToOneMessages.CALENDAR_REBUILD_SUCCESS);
        return res;
      });
  }

  transferScheduleToNewManager(scheduleId: number, transferOneToOneSchedule: TransferOneToOneSchedule): Promise<OneToOneSchedule> {
    if (this.isModifyingSchedule(scheduleId)) { return new Promise((resolve, reject) => reject()); }
    this.startUpdatingSchedule(scheduleId);

    return this.oneToOneAPIService.transferScheduleToNewManager(scheduleId, transferOneToOneSchedule)
      .toPromise()
      .then(res => {
        this.notifyUtils.notify(OneToOneMessages.SCHEDULE_TRANSFER_SUCCESS);
        return res;
      })
      .finally(() => {
        this.finishUpdatingSchedule(scheduleId);
      });
  }

  // TALKING POINT COMMENTS

  addTalkingPointComment(scheduleId: number, meetingId: number, talkingPointId: number, newComment: string): Promise<TalkingPoint> {
    if (this.isModifyingMeeting(meetingId)) { return new Promise((resolve, reject) => reject()); }
    this.startUpdatingMeeting(meetingId);

    const commentParsed = newComment.trim();

    return this.oneToOneAPIService.addTalkingPointComment(scheduleId, meetingId, talkingPointId, commentParsed)
      .toPromise()
      .then(res => {
        this.notifyUtils.notify(OneToOneMessages.ADDED_TALKING_POINT_COMMENT_TOAST);
        return res;
      })
      .finally(() => {
        this.finishUpdatingMeeting(meetingId);
      });

  }

  // SHARED NOTES

  addMeetingSharedNote(scheduleId: number, meetingId: number, note: string): Promise<OneToOneMeeting> {
    if (this.isModifyingMeeting(meetingId)) { return new Promise((resolve, reject) => reject()); }
    this.startUpdatingMeeting(meetingId);

    return this.oneToOneAPIService.addMeetingSharedNotes(scheduleId, meetingId, note)
      .toPromise()
      .then(res => {
        this.notifyUtils.notify(OneToOneMessages.SHARED_NOTES_UPDATE_SUCCESS);
        return res;
      })
      .finally(() => {
        this.finishUpdatingMeeting(meetingId);
      });
  }

  // PRIVATE NOTES

  getMeetingPrivateNotes(scheduleId: number, meetingId: number): Promise<OneToOneNoteMinimal> {
    return this.oneToOneAPIService.getMeetingPrivateNotes(scheduleId, meetingId)
      .toPromise();
  }

  addMeetingPrivateNotes(scheduleId: number, meetingId: number, note: string): Promise<OneToOneNoteMinimal> {
    if (this.isModifyingMeeting(meetingId)) { return new Promise((resolve, reject) => reject()); }
    this.startUpdatingMeeting(meetingId);

    return this.oneToOneAPIService.addMeetingPrivateNotes(scheduleId, meetingId, note)
      .toPromise()
      .then(res => {
        this.notifyUtils.notify(OneToOneMessages.PRIVATE_NOTES_UPDATE_SUCCESS);
        return res;
      })
      .finally(() => {
        this.finishUpdatingMeeting(meetingId);
      });
  }

  // FILES

  getMeetingFiles(scheduleId: number, meetingId: number): Observable<OneToOneMeetingFile[]> {
    return this.oneToOneAPIService.getMeetingFiles(scheduleId, meetingId);
  }

  addMeetingFile(scheduleId: number, meetingId: number, file: File): Promise<OneToOneMeetingFile> {
    return this.oneToOneAPIService.addMeetingFile(scheduleId, meetingId, file)
      .toPromise()
      .then(res => {
        this.notifyUtils.notify(OneToOneMessages.FILE_UPLOAD_SUCCESS);
        return res;
      });
  }

  deleteMeetingFile(scheduleId: number, meetingId: number, fileName: string): Promise<void> {
    return this.oneToOneAPIService.deleteMeetingFile(scheduleId, meetingId, fileName)
      .toPromise()
      .then(res => {
        this.notifyUtils.notify(OneToOneMessages.FILE_DELETE_SUCCESS);
        return res;
      });
  }

  getSidebarOneToOneSchedules(userIds: number[]): Observable<OneToOneScheduleDetailsView[]> {
    return this.oneToOneAPIService.getSidebarOneToOneSchedules(userIds);
  }

  getOneToOneSchedulesLinkedToReviews(): Observable<OneToOneScheduleDetailsView[]> {
    return this.oneToOneAPIService.getOneToOneSchedulesLinkedToReviews();
  }

  rescheduleMeeting(scheduleId: number, meetingId: number, rescheduleOneToOneMeeting: RescheduleOneToOneMeeting): Promise<OneToOneMeeting> {
    if (this.isModifyingMeeting(meetingId)) { return new Promise((resolve, reject) => reject()); }
    this.startUpdatingMeeting(meetingId);

    return this.oneToOneAPIService.rescheduleMeeting(scheduleId, meetingId, rescheduleOneToOneMeeting)
      .toPromise()
      .then(res => {
        this.notifyUtils.notify(OneToOneMessages.MEETING_RESCHEDULE_SUCCESS);
        return res;
      })
      .finally(() => {
        this.finishUpdatingMeeting(meetingId);
      });
  }

  updateMeetingStatus(scheduleId: number, meetingId: number, newStatus: OneToOneMeetingStatus): Promise<OneToOneMeeting> {
    if (this.isModifyingMeeting(meetingId)) { return new Promise((resolve, reject) => reject()); }
    this.startUpdatingMeeting(meetingId);

    return this.oneToOneAPIService.updateMeetingStatus(scheduleId, meetingId, newStatus)
      .toPromise()
      .then(res => {
        const message = this.getMeetingStatusUpdateMessage(newStatus);
        this.notifyUtils.notify(message);

        return res;
      })
      .finally(() => {
        this.finishUpdatingMeeting(meetingId);
      });
  }

  private getMeetingStatusUpdateMessage(newStatus: OneToOneMeetingStatus): string {
    if (newStatus === OneToOneMeetingStatus.COMPLETED) {
      return OneToOneMessages.MEETING_STATUS_COMPLETED_SUCCESS;
    }

    if (newStatus === OneToOneMeetingStatus.CANCELLED) {
      return OneToOneMessages.MEETING_STATUS_CANCELLED_SUCCESS;
    }

    return OneToOneMessages.MEETING_STATUS_UPDATE_SUCCESS;
  }

  bumpOneToOneMeetingTalkingPoint(scheduleId: number, meetingId: number, talkingPointId: number): Promise<OneToOneMeeting> {
    if (this.isModifyingMeeting(meetingId)) { return new Promise((resolve, reject) => reject()); }
    this.startUpdatingMeeting(meetingId);

    return this.oneToOneAPIService.bumpTalkingPoint(scheduleId, meetingId, talkingPointId)
      .toPromise()
      .then(res => {
        this.notifyUtils.notify(OneToOneMessages.BUMPED_TALKING_POINT_TOAST);
        return res;
      })
      .finally(() => {
        this.finishUpdatingMeeting(meetingId);
      });
  }

  actionOneToOneMeetingTalkingPoint(scheduleId: number, meetingId: number, talkingPointId: number, checked: boolean): Promise<TalkingPoint> {
    if (this.isModifyingMeeting(meetingId)) { return new Promise((resolve, reject) => reject()); }
    this.startUpdatingMeeting(meetingId);

    return this.oneToOneAPIService.actionTalkingPoint(scheduleId, meetingId, talkingPointId, checked)
      .toPromise()
      .then(res => {
        if (res.actioned) {
          this.notifyUtils.notify(OneToOneMessages.COMPLETED_TALKING_POINT_TOAST);
        } else {
          this.notifyUtils.notify(OneToOneMessages.UNCOMPLETED_TALKING_POINT_TOAST);
        }
        return res;
      })
      .finally(() => {
        this.finishUpdatingMeeting(meetingId);
      });
  }

  getMeetingSentimentScaleScores(scheduleId: number, meetingId: number): Observable<MeetingSentimentScaleScore[]> {
    return this.oneToOneAPIService.getMeetingSentimentScaleScores(scheduleId, meetingId);
  }

  updateMeetingSentimentScaleScore(scheduleId: number, meetingId: number, createDto: CreateMeetingSentimentScaleScore): Promise<MeetingSentimentScaleScore> {
    if (this.isModifyingMeeting(meetingId)) { return new Promise((resolve, reject) => reject()); }
    this.startUpdatingMeeting(meetingId);

    return this.oneToOneAPIService.updateMeetingSentimentScaleScore(scheduleId, meetingId, createDto)
      .toPromise()
      .then(res => {
        this.notifyUtils.notify(OneToOneMessages.MEETING_SENTIMENT_SCALE_SCORE_UPDATE_SUCCESS);
        return res;
      })
      .finally(() => {
        this.finishUpdatingMeeting(meetingId);
      });
  }

  searchForMeetingByContents(scheduleId: number, searchArgument: string): Promise<number[]> {
    return this.oneToOneAPIService.searchForMeetingByContents(scheduleId, searchArgument)
      .toPromise();
  }

  getSentimentTimelineForSchedule(scheduleId: number): Observable<ScheduleSentimentScaleTimeline> {
    return this.oneToOneAPIService.getSentimentTimelineForSchedule(scheduleId);
  }

}
