import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { QuestionBase } from '@app/shared/survey-components/dynamic-form/question-base';
import { FormGroup } from '@angular/forms';
import { SurveyQuestionType } from '@app/models/survey-question-type.enum';

@Component({
  selector: 'app-dynamic-form-question',
  templateUrl: './dynamic-form-question.component.html',
  styleUrls: ['./dynamic-form-question.component.css']
})
export class DynamicFormQuestionComponent {
  @ViewChild('formQuestion') formQuestion?: ElementRef<HTMLDivElement>;

  @Input() question!: QuestionBase<string>;
  @Input() isSubmitted: boolean;
  @Input() form!: FormGroup;
  eSurveyQuestionType = SurveyQuestionType;

  nonFilledQuestions = [SurveyQuestionType.TEXT] // Question types where label is collapsed

  get errorMessage(): string {
    const errors = this.form.get(this.question.key)!.errors;
    let message = '';
    if (errors['required']) {
      message += `${this.question.key} is required. `;
    } else if (errors['minlength']) {
      message += `Your answer must be at least ${this.question.minCharacters} characters long.`
    } else if (errors['maxlength']) {
      message += `Your answer cannot be more than ${this.question.maxCharacters} characters long.`
    }
    return message;
  }

  constructor(
    public elRef: ElementRef
  ) {
    this.isSubmitted = false;
  }
}
