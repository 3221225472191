export const QuestionTemplateMessages = {
  QUESTION_TEMPLATES: 'question-template.question-templates',
  QUESTION_TEMPLATES_DESCRIPTION: 'question-template.question-templates-description',
  SUCCESS_CREATE: 'question-template.success-create',
  SUCCESS_UPDATE: 'question-template.success-update',
  SUCCESS_DELETE: 'question-template.success-delete',
  TEMPLATE_NAME: 'question-template.template-name',
  TEMPLATE_NAME_PLACEHOLDER: 'question-template.template-name-placeholder',
  TEMPLATE_QUESTIONS: 'question-template.template-questions',
  TEMPLATE_QUESTIONS_COUNT: 'question-template.template-questions-count',
  TEMPLATE_QUESTIONS_MIN: 'question-template.template-questions-min',
  TEMPLATE_QUESTIONS_MAX: 'question-template.template-questions-max',
  TEMPLATE_QUESTIONS_DESCRIPTION: 'question-template.template-questions-description',
  TEMPLATE_QUESTIONS_EMPTY: 'question-template.template-questions-empty',
  QUESTION_TEXT: 'question-template.question-text',
  QUESTION_TEXT_PLACEHOLDER: 'question-template.question-text-placeholder',
  QUESTION_TYPE: 'question-template.question-type',
  QUESTION_TYPE_OPINION_SCALE: 'question-template.question-type-opinion-scale',
  QUESTION_TYPE_TEXT: 'question-template.question-type-text',
  QUESTION_TYPE_LIKERT_SCALE: 'question-template.question-type-likert-scale',
  QUESTION_TYPE_MULTIPLE_CHOICE: 'question-template.question-type-multiple-choice',
  QUESTION_TYPE_REQUIRED: 'question-template.question-type-required',
  SCALE_START: 'question-template.scale-start',
  SCALE_START_REQUIRED: 'question-template.scale-start-required',
  SCALE_START_PLACEHOLDER: 'question-template.scale-start-placeholder',
  SCALE_END: 'question-template.scale-end',
  SCALE_END_REQUIRED: 'question-template.scale-end-required',
  SCALE_END_PLACEHOLDER: 'question-template.scale-end-placeholder',
  POSSIBLE_ANSWER_REMOVE: 'question-template.possible-answer-remove',
  POSSIBLE_ANSWER_ADD: 'question-template.possible-answer-add',
  POSSIBLE_ANSWER_PLACEHOLDER: 'question-template.possible-answer-placeholder',
  POSSIBLE_ANSWER_MIN_COUNT: 'question-template.possible-answer-min-count',
  POSSIBLE_ANSWER_MAX_COUNT: 'question-template.possible-answer-max-count',
  CAN_SELECT_MULTIPLE_QUESTION: 'question-template.can-select-multiple-question',
  CAN_SELECT_MULTIPLE: 'question-template.can-select-multiple',
  CAN_COMMENT_QUESTION: 'question-template.can-comment-question',
  CAN_COMMENT: 'question-template.can-comment',
  CAN_COMMENT_WARNING_CHARACTER: 'question-template.can-comment-warning-character',
  COMMENT_LABEL: 'question-template.comment-label',
  SET_AS_DEFAULT: 'question-template.set-as-default',
  SET_AS_DEFAULT_DESCRIPTION: 'question-template.set-as-default-description',
  EMPTY_STATE_ALL: 'question-template.empty-state-all',
  EMPTY_STATE_FILTERS: 'question-template.empty-state-filters',
  CTA_CREATE: 'question-template.cta-create',
  CTA_EDIT: 'question-template.cta-edit',
  EXISTING_TEMPLATES: 'question-template.existing-templates',
  PLACEHOLDER_SEARCH: 'question-template.placeholder-search',
  CONFIRMATION_DELETE: 'question-template.confirmation-delete',
  CREATE_DESCRIPTION: 'question-template.create-description',
  ADD_A_QUESTION: 'question-template.add-a-question',
  DEFAULT_APPLICANT_TEMPLATE: 'question-template.default-applicant-template',
  DEFAULT_APPLICANT_TEMPLATE_CONTEXT: 'question-template.default-applicant-template-context',
  DEFAULT_APPROVER_TEMPLATE: 'question-template.default-approver-template',
  DEFAULT_APPROVER_TEMPLATE_CONTEXT: 'question-template.default-approver-template-context',
  TOOLTIP_COPY: 'question-template.tooltip-copy',
  TOOLTIP_EDIT: 'question-template.tooltip-edit',
  TOOLTIP_DELETE: 'question-template.tooltip-delete',
  SUCCESS_SET_DEFAULT_TEMPLATE: 'question-template.success-set-default-template',
  DELETE_IN_USE_BY_CONFIGURATION: 'question-template.delete-in-use-by-configuration',
  NAME_IN_USE: 'question-template.name-in-use'
};
