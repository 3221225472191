import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { OneToOneMeetingStatus } from '@app/domain/one_to_one/model/one-to-one-meeting-status.model';
import { OneToOneMeeting } from '@app/domain/one_to_one/model/one-to-one-meeting.model';
import { OneToOneMessages } from '@app/domain/one_to_one/locale/one-to-one.messages';
import { OneToOneSchedule } from '@app/domain/one_to_one/model/one-to-one-schedule.model';
import { OneToOneFrequency } from '../../model/one-to-one-frequency.model';
import { OneToOneUtilsService } from '../../service/one-to-one-utils.service';
import { Globals } from '@app/shared/globals/globals';
import { OneToOneStatus } from '../../model/one-to-one-status.model';

interface MeetingStatusData {
  iconClass: string;
  tooltipText: string;
}

@Component({
  selector: 'app-meeting-navigation-carousel-item',
  templateUrl: './meeting-navigation-carousel-item.component.html',
  styleUrls: ['./meeting-navigation-carousel-item.component.scss']
})
export class MeetingNavigationCarouselItemComponent implements OnChanges {
  public readonly eOneToOneMeetingStatus = OneToOneMeetingStatus;
  public readonly eOneToOneFrequency = OneToOneFrequency;
  public readonly eOneToOneMessages = OneToOneMessages;
  public readonly eOneToOneStatus = OneToOneStatus;

  @Input() schedule: OneToOneSchedule;
  @Input() meeting?: OneToOneMeeting;
  @Input() index: number;
  @Input() selectedId: number;
  @Input() highlightedIds: number[];

  @Output() onSelect: EventEmitter<boolean>;

  meetingStatusData: MeetingStatusData;

  get isSelected(): boolean {
    if (!this.meeting) { return false; }
    return this.meeting.id === this.selectedId;
  }

  get isHighlighted(): boolean {
    if (!this.meeting) { return false; }
    return this.highlightedIds.includes(this.meeting.id);
  }

  get isDimmed(): boolean {
    if (!this.meeting) { return false; }
    if (this.highlightedIds.length === 0) { return false; }
    if (this.isHighlighted) { return false; }
    return true;
  }

  constructor(
    public globals: Globals
  ) {
    this.highlightedIds = [];
    this.schedule = undefined;
    this.meeting = undefined;
    this.index = 0;
    this.selectedId = 0;
    this.onSelect = new EventEmitter<boolean>();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.meeting) {
      this.meetingStatusData = this.getMeetingStatusData(this.meeting);
    }
  }

  getMeetingStatusData(meeting: OneToOneMeeting): MeetingStatusData {
    const icon = OneToOneUtilsService.getIconClassForOneToOneMeetingStatus(meeting);

    let tooltip = undefined;
    switch(meeting.status) {
      case OneToOneMeetingStatus.SCHEDULED:
        tooltip = OneToOneMessages.SCHEDULED;
        break;
      case OneToOneMeetingStatus.IN_PROGRESS:
      case OneToOneMeetingStatus.REOPENED:
        tooltip = OneToOneMessages.IN_PROGRESS;
        break;
      case OneToOneMeetingStatus.CANCELLED:
        tooltip = OneToOneMessages.CANCELLED;
        break;
      case OneToOneMeetingStatus.MISSED:
        tooltip = OneToOneMessages.MISSED;
        break;
      case OneToOneMeetingStatus.AUTO_COMPLETED:
      case OneToOneMeetingStatus.COMPLETED:
        tooltip = OneToOneMessages.COMPLETED;
        break;
      default:
        break;
    }

    return {
      iconClass: icon,
      tooltipText: tooltip
    };
  }

  meetingClicked() {
    this.onSelect.emit(true);
  }
}
