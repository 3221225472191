import { HttpParams } from '@angular/common/http';
import { PagingParams } from '@app/models/api/paging-params.model';
import { SortingParams } from '@app/models/api/sorting-params.model';

export class ApiUtils {
  static createPageParams(pagingParams: PagingParams): HttpParams {
    return new HttpParams()
      .append('pageNumber', String(pagingParams.pageNumber))
      .append('pageSize', String(pagingParams.pageSize));
  }

  static createPageAndSortParams(pagingParams: PagingParams, sortingParams: SortingParams): HttpParams {
    let params = new HttpParams()
      .append('pageNumber', String(pagingParams.pageNumber))
      .append('pageSize', String(pagingParams.pageSize))
      .append('sortDirection', String(sortingParams.sortDirection));
    sortingParams.sortAttributes.forEach(sortAttribute => params = params.append('sortAttributes', sortAttribute));
    return params;
  }

  static toQueryString(queryParams) {
    let encodedQueryParams = [];
    for (let key in queryParams) {
      encodedQueryParams.push(key + "=" + encodeURIComponent(queryParams[key]));
    }
    return encodedQueryParams.join("&");
  }
}