<div class="template-display-container" *ngIf="template">
  <!-- Objective (And tags) -->
  <div class="template-objective">
    <span class="template-objective-icon" [tooltip]="eGoalsMessages.OBJECTIVE | translate" [triggers]="globals.tooltipTriggers" placement="bottom" container="body">
      <span class="fal fa-fw fa-bullseye"></span>
    </span>

    <span class="template-title">{{template.title}}</span>
  </div>

  <hr class="divider-h"/>

  <!-- Key Results -->
  <div class="template-results">
    <ng-container *ngFor="let result of template.keyResults; first as isFirst; last as isLast;">
      <div class="result-item">
        <ng-container [ngSwitch]="result.type">
          <ng-container *ngSwitchCase="eGoalKeyResultType.BINARY">
            <span class="result-type" [tooltip]="measureBinaryTooltip" placement="bottom" container="body" [triggers]="globals.tooltipTriggers" [ngSwitch]="result.type">
              <span class="fal fa-fw fa-check-double"></span>
            </span>
          </ng-container>
          <ng-container *ngSwitchCase="eGoalKeyResultType.NUMERIC">
            <span class="result-type" [tooltip]="measureNumericTooltip" placement="bottom" container="body" [triggers]="globals.tooltipTriggers" [ngSwitch]="result.type">
              <span  class="fal fa-fw fa-tally"></span>
            </span>
          </ng-container>
        </ng-container>
        
        <ng-template #measureBinaryTooltip>
          <div class="text-left" [innerHTML]="eGoalsMessages.TEMPLATE_EXPLANATION_RESULT_TYPE_COMPLETE_INCOMPLETE | translate"></div>
        </ng-template>

        <ng-template #measureNumericTooltip>
          <div class="text-left p-bottom-5" [innerHTML]="eGoalsMessages.TEMPLATE_EXPLANATION_RESULT_TYPE_NUMERIC | translate"></div>
          <ng-container *ngIf="result.measureUnit" [ngSwitch]="result.measureUnitPlacement">
            <div class="text-left" *ngSwitchCase="eGoalKeyResultMeasureUnitPlacement.BEFORE" [innerHTML]="eGoalsMessages.TEMPLATE_EXPLANATION_RESULT_UNIT_BEFORE | translate: { unit: result.measureUnit }"></div>
            <div class="text-left" *ngSwitchCase="eGoalKeyResultMeasureUnitPlacement.AFTER" [innerHTML]="eGoalsMessages.TEMPLATE_EXPLANATION_RESULT_UNIT_AFTER | translate: { unit: result.measureUnit }"></div>
          </ng-container>
        </ng-template>

        <span class="result-title">{{result.result}}</span>

      </div>

      <hr class="divider-h" *ngIf="!isLast"/>
    </ng-container>
  </div>

  <hr class="divider-h"/>

  <ng-container *ngIf="template.tags.length > 0">
    <div class="template-tags">
      <span class="tags-icon" [tooltip]="eGoalsMessages.TAGS | translate" [triggers]="globals.tooltipTriggers" placement="bottom" container="body">
        <span class="fal fa-fw fa-tag"></span>
      </span>

      <ng-container *ngFor="let tag of template.tags">
        <span class="tag-item" [class.has-description] [tooltip]="tag.description" [triggers]="globals.tooltipTriggers" placement="bottom" container="body">
          <app-pill [minWidth]="0" [type]="ePillType.OFF_WHITE">
            <span>{{tag.text}}</span>
          </app-pill>
        </span>
      </ng-container>
    </div>

    <hr class="divider-h"/>
  </ng-container>

  <!-- Actions -->
  <div class="container-actions">
    <button class="btn-frankli-round btn-frankli-green btn-frankli-small btn-use-template" (click)="onUseClicked()" translate>{{eGoalsMessages.USE_TEMPLATE}}</button>
  </div>
</div>