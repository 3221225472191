import { Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { GoalsModalComponent } from '@app/goals/goals-components/goals-modal/goals-modal.component';
import { OneToOneMeetingLength } from '@app/domain/one_to_one/model/one-to-one-meeting-length.model';
import { CalendarAPIService } from '@app/shared/api/calendar.api.service';
import { OneToOneMessages } from '@app/domain/one_to_one/locale/one-to-one.messages';
import { TranslateService } from '@ngx-translate/core';
import { UserMinimal } from '@app/models/user/user-minimal.model';
import { IState } from '@app/models/state/state.model';
import { DateUtils } from '@app/shared/utils/date.utils';
import { Calendar } from '../../model/calendar.model';
import { Globals } from '@app/shared/globals/globals';
import { ScheduleItem } from '../../model/schedule-item.model';
import moment from 'moment';
import 'moment-timezone';
import { CommonMessages } from '@app/constants/common.messages';

interface PageState extends IState {
  loadingCalendars: boolean;
}

interface PageScheduleItem extends ScheduleItem {
  duration: number;
  top: number;
  height: number;
}

interface UserCalendarData {
  user: UserMinimal;
  schedules: PageScheduleItem[];
}

interface ValidDateLimits {
  hour: number;
  quarter: number;
}

enum EventStatus {
  WORKING_ELSEWEHRE = 'workingElsewhere',
  FREE = 'free',
  BUSY = 'busy',
  OUT_OF_OFFICE = 'oof',
  TENTATIVE = 'tentative',
  AWAY = 'away'
}

@Component({
  selector: 'app-availability-modal',
  templateUrl: './availability-modal.component.html',
  styleUrls: ['./availability-modal.component.scss']
})
export class AvailabilityModalComponent implements OnChanges, OnDestroy {
  public readonly eOneToOneMeetingLength = OneToOneMeetingLength;
  public readonly eOneToOneMessages = OneToOneMessages;
  public readonly eCommonMessages = CommonMessages;
  public readonly eEventStatus = EventStatus;
  public readonly DATEPICKER_LOCALE: string = moment().locale();
  public readonly DATEPICKER_LIMITS = {
    MIN: DateUtils.convertDateToPickerFormat(moment().startOf('day').subtract(1, 'day').toDate()),
    MAX: DateUtils.convertDateToPickerFormat(moment().add(1, 'year').toDate())
  };

  @Input() otherUsers: UserMinimal[];
  @Input() timezone: string;
  @Input() meetingTime: Date;
  @Input() meetingLength: OneToOneMeetingLength;
  @Input() showMeetingLength: boolean;

  @Output() resultTime: string;
  @Output() meetingTimeChanges: EventEmitter<Date>;
  @Output() meetingLengthChanges: EventEmitter<OneToOneMeetingLength>;

  @ViewChild('modal') modal?: GoalsModalComponent;
  @ViewChild('datePicker') datePickerComp?: ElementRef;

  controlMeetingTime: FormControl;

  state: PageState;

  limits: ValidDateLimits;

  form!: FormGroup;
  timerRefresh: NodeJS.Timer;

  userCalendarData: UserCalendarData[];
  currentSelection: PageScheduleItem;

  get selectionPreviewHeight(): number {
    // Meeting length is an enum that maps to a minutes value. Each 15 minute slot is 20px high.
    return (+Object.keys(OneToOneMeetingLength).indexOf(this.meetingLength + '') + 1) * 20;
  }

  constructor(
    public globals: Globals,
    private calendarAPIService: CalendarAPIService,
    private translateService: TranslateService
  ) {
    this.otherUsers = [];

    this.userCalendarData = [];

    this.resultTime = '';
    this.timezone = '';

    this.meetingTime = new Date();
    this.meetingLength = undefined;

    this.meetingTimeChanges = new EventEmitter<Date>();
    this.meetingLengthChanges = new EventEmitter<OneToOneMeetingLength>();

    this.meetingTimeChanges.subscribe(() => this.updateCurrentSelection());
    this.meetingLengthChanges.subscribe(() => this.updateCurrentSelection());

    this.showMeetingLength = true;
    this.controlMeetingTime = this.initMeetingTimeControl();

    this.limits = {
      hour: 0,
      quarter: 0
    };

    this.state = {
      loading: true,
      error: false,
      errorMessage: '',
      loadingCalendars: false
    };

    this.timerRefresh = setInterval(() => this.refreshValidDateLimits(), 1000); // Refresh date limits of date picker every 30000ms 30 seconds
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.meetingTime && changes.meetingTime.currentValue) {
      this.refreshValidDateLimits();
    }
  }

  ngOnDestroy(): void {
    this.timerRefresh = null;
  }

  show(): void {
    if (!this.modal) { return; }
    this.controlMeetingTime = this.initMeetingTimeControl(this.meetingTime);
    this.controlMeetingTime.updateValueAndValidity();
    this.modal.show();
    this.state.loading = false;
  }

  hide(): void {
    if (!this.modal) { return; }
    this.modal.hide();
  }

  refreshValidDateLimits(): void {
    this.limits = this.getValidDateLimits();
  }

  getValidDateLimits(): ValidDateLimits {
    const dateNow = moment();
    const minMinuteIndex = this.getQuarterFromMinutes(dateNow.minutes());
    const dateSelected = moment(this.controlMeetingTime.value);

    if (dateSelected.isSameOrBefore(dateNow)) {
      const newDate = dateNow.startOf('minute').minute(minMinuteIndex * 15).add(15, 'minutes');
      this.controlMeetingTime = this.initMeetingTimeControl(newDate.toDate());
    }

    return {
      hour: dateNow.hour(),
      quarter: minMinuteIndex
    };
  }

  initMeetingTimeControl(meetingTime?: Date): FormControl {
    const formControl = new FormControl(null, []);

    if (meetingTime) {
      formControl.setValue(meetingTime);
    }

    formControl.valueChanges.subscribe(val => this.onMeetingTimeChange(val));

    return formControl;
  }

  onMeetingTimeChange(date: Date): void {
    const resDateMoment = moment(date);

    // Get old time so we can keep the time the same
    const oldHour = moment(this.meetingTime).hour();
    const oldMinute = moment(this.meetingTime).minute();
    resDateMoment.hour(oldHour);
    resDateMoment.minute(oldMinute);
    
    // If new time is in the past, increment by 15 minutes until it's in the future
    if (resDateMoment.isBefore(moment())) {
      while (resDateMoment.isBefore(moment())) {
        resDateMoment.add(15, 'minutes');
      }
    }

    this.meetingTime = resDateMoment.toDate();
    this.meetingTimeChanges.emit(resDateMoment.toDate());
    setTimeout(() => {
      if(this.modal && this.modal.visible){
        this.getAvailability();
      }
    }, 10);

    setTimeout(() => this.scrollToCurrentMeetingTime(), 250);

  }
  
  scrollToCurrentMeetingTime(): void {
    const elements = document.getElementsByClassName('current-selected-date');
    if (!elements) { return; }
    if (elements.length === 0) { return; }

    const firstElement = elements[0];
    if (!firstElement) { return; }

    firstElement.scrollIntoView({block: 'center'});
  }

  getAvailability(): void {
    if (this.state.loadingCalendars) { return; }
    this.state.loadingCalendars = true;

    const currentUsereEmail = this.globals.user.email;
    const otherUserEmails = (this.otherUsers || []).map(u => u.email);
    const allUserEmails = [currentUsereEmail, ...otherUserEmails];

    const startDateTime = moment.tz(this.meetingTime, this.timezone).startOf('day');
    const endDateTime = moment.tz(this.meetingTime, this.timezone).endOf('day');

    this.calendarAPIService
      .getAvailability(allUserEmails, startDateTime.format('YYYY-MM-DD[T]HH:mm[:00.000Z]'), endDateTime.format('YYYY-MM-DD[T]HH:mm[:00.000Z]'), this.timezone)
      .toPromise()
      .then(calendars => {
        this.userCalendarData = this.parseCalendars(calendars);
      })
      .catch(err => {
        console.error('Error getting availability:', err);
      })
      .finally(() => {
        this.state.loading = false;
        this.state.loadingCalendars = false;
      });
  }

  parseCalendars(calendars: Calendar[]): UserCalendarData[] {
    return calendars
      .filter(calendar => calendar && calendar.items)
      .map(calendar => {
        const associatedUser = this.getAssociatedUserForCalendar(calendar);
        if (!associatedUser) { return undefined; }

        const itemsParsed =  calendar.items.map(item => {
          const startMoment = moment(item.start);
          const endMoment = moment(item.end);

          
          // Calculating height
          // NOTE: 80px = 1hr, 40px = 30 mins, etc.
          const diff = moment.duration(endMoment.diff(startMoment));
          let diffHeight = (diff.asHours() * 80);

          // Calculating offset from top
          const dayStart = moment(this.meetingTime).startOf('day');
          const topDiff = startMoment.diff(dayStart, 'hours', true);
          const top = (topDiff * 80);

          // If the duration would cause the event to roll onto the next day, we need to adjust the height
          // Each 15 minutes is 20px high
          if (diffHeight + top > 1920) {
            diffHeight = 1920 - top;
          }

          const parsedSchedule: PageScheduleItem = {
            status: item.status,
            start: item.start,
            end: item.end,
            duration: diff.asMinutes(),
            top: top,
            height: diffHeight
          };

          return parsedSchedule;
        });

        return {
          user: associatedUser,
          schedules: itemsParsed
        };
      })
      .filter(x => x !== undefined);
  }

  getAssociatedUserForCalendar(calendar: Calendar): UserMinimal {
    if (!calendar) { return undefined; }
    if (!calendar.email) { return undefined; }
    if (calendar.email === this.globals.user.email) { return this.globals.user; }
    if (!this.otherUsers) { return undefined; }
    return this.otherUsers.find(u => u.email === calendar.email);
  }

  lengthChanged(val: OneToOneMeetingLength): void {
    this.meetingLengthChanges.emit(val);
  }

  getCurrentSelectionData(): {top: number, height: number, text: string} {
    const FIFTEEN_MIN_HEIGHT = 20;
    const HOUR_CELL_HEIGHT = (FIFTEEN_MIN_HEIGHT * 4); // Should prob be a const at the top of the file
    const output = {
      top: 0,
      height: 0,
      text: ''
    };

    // Convert meetingTime to top
    const startMoment = moment(this.meetingTime);
    const dayStart = moment(this.meetingTime).startOf('day');
    const topDiff = startMoment.diff(dayStart, 'hours', true);
    output.top = (topDiff * HOUR_CELL_HEIGHT);

    // Convert meetingLength to height
    const meetingLengthMultiplier = (+Object.keys(OneToOneMeetingLength).indexOf(this.meetingLength + '') + 1);
    output.height = (FIFTEEN_MIN_HEIGHT * meetingLengthMultiplier);

    // Set text
    const endMoment = moment(startMoment).add((meetingLengthMultiplier * 15), 'minutes');
    output.text = this.translateService.instant(OneToOneMessages.SELECTION) + `: ${startMoment.format('h:mm a')}-${endMoment.format('h:mm a')}`;

    return output;
  }

  isCurrentDate(): boolean {
    const dateSelected = moment(this.controlMeetingTime.value).startOf('day');
    const dateNow = moment().startOf('day');

    return dateSelected.isSameOrBefore(dateNow);
  }

  getQuarterFromMinutes(minutes: number): number {
    if (minutes <= 15) {
      return 0;
    }

    if (minutes <= 30) {
      return 1;
    }

    if (minutes <= 45) {
      return 2;
    }

    if (minutes <= 60) {
      return 3;
    }

    return 0;
  }

  trySelectTimeslot(hoursIndex: number, minutesIndex: number): void {
    const canSelect = this.canSelectTimeslot(hoursIndex, minutesIndex);
    if (!canSelect) { return; }

    const minutesOptions = [0, 15, 30, 45];
    const minutes = minutesOptions[minutesIndex];
    const day = moment(this.meetingTime).hour(hoursIndex).minutes(minutes).toDate();

    this.meetingTime = day;
    this.meetingTimeChanges.emit(day);
    this.hide();
  }

  updateCurrentSelection(): void {
    this.currentSelection = this.getCurrentSelection();
  }

  getCurrentSelection(): PageScheduleItem {
    const duration = (+Object.keys(OneToOneMeetingLength).indexOf(this.meetingLength + '') + 1) * 15;
    const start = this.meetingTime;
    const end = moment(this.meetingTime).add(duration, 'minutes').toDate();
    const startMoment = moment(start);
    const endMoment = moment(end);
    const diff = moment.duration(endMoment.diff(startMoment));
    const diffHeight = (diff.asHours() * 80);
    
    const dayStart = moment(this.meetingTime).startOf('day');
    const topDiff = startMoment.diff(dayStart, 'hours', true);
    const top = (topDiff * 80);

    return {
      status: null,
      start: start,
      end: end,
      duration: duration,
      top: top,
      height: diffHeight
    };
  }

  /**
   * Returns true if the timeslot can be selected and false if not.
   * Cannot select timeslots in the past.
   * All timeslots can be selected on dates in the future.
   * This checks from the start of the timeslot, not the end. eg if it is 8:01 then the 8:00 timeslot is invalid.
   * @param hoursIndex 
   * @param minutesIndex 
   */
  canSelectTimeslot(hoursIndex: number, minutesIndex: number): boolean {
    const dateSelected = moment(this.controlMeetingTime.value).startOf('day');
    const dateNow = moment().startOf('day');

    if (dateSelected.isSameOrBefore(dateNow)) {
      const selectedHour = moment(this.controlMeetingTime.value).hour();
      const selectedMinute = moment(this.controlMeetingTime.value).minute();
      if (hoursIndex < selectedHour) {
        return false;
      }
      if (hoursIndex === selectedHour && minutesIndex < this.getQuarterFromMinutes(selectedMinute)) {
        return false;
      }
    }

    return true;
  }
}
