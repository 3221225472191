import {Component, ElementRef, forwardRef, Input, OnInit, ViewChild} from '@angular/core';
import {ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR} from '@angular/forms';
import {CommonMessages} from '@app/constants/common.messages';
import { CriterionOperator } from '@app/models/api/criterion-operator.enum';
import { FilterOperator } from '@app/models/api/filter-operator.enum';
import { PagingParams } from '@app/models/api/paging-params.model';
import { ParentFilter } from '@app/models/api/parent-filter.model';
import { SortDirection } from '@app/models/api/sort-direction.enum';
import { SortingParams } from '@app/models/api/sorting-params.model';
import { IState } from '@app/models/state/state.model';
import { UserMinimal } from '@app/models/user/user-minimal.model';
import { User } from '@app/models/user/user.model';
import { UserAPIService } from '@app/shared/api/user.api.service';
import {Globals} from '@app/shared/globals/globals';
import { debounceTime, map } from 'rxjs/operators';

interface PageState extends IState {
  searchRunning: boolean;
  dropdownOpen: boolean;
  disabled: boolean;
  searchIsEmpty: boolean;
}

type PickerValue = (UserMinimal | UserMinimal[]);

@Component({
  selector: 'app-user-picker',
  templateUrl: './user-picker.component.html',
  styleUrls: ['./user-picker.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => UserPickerComponent),
    multi: true,
  }],
})
export class UserPickerComponent implements OnInit, ControlValueAccessor {
  public readonly eCommonMessages = CommonMessages;

  @ViewChild('searchInput') searchInput?: ElementRef<HTMLInputElement>;

  @Input() searchPlaceholder: string;  
  @Input() customOptions: User[];
  @Input() customOptionsOnly: boolean;
  @Input() canSelectMultiple: boolean;
  @Input() blacklistedIds: number[];
  @Input() clearSearchAfterSelection: boolean;
  @Input() loseFocusAfterSelection: boolean;
  @Input() hideAvatarForIds: number[];
  @Input() signedUpUsersOnly: boolean;

  state: PageState;
  searchControl: FormControl;
  results: UserMinimal[];
  _value: UserMinimal[];
  searchIsFocused: boolean;

  onChange = (_: any) => {};
  onTouched = () => {};

  get value(): PickerValue {
    return (this.canSelectMultiple ? this._value : this._value[0]);
  }

  set value(v: PickerValue) {
    if (this.state.disabled) { return; }
    this.writeValue(v);
    this.onChange(v);
  }

  constructor(
    public globals: Globals,
    private userAPIService: UserAPIService
  ) {
    this.searchControl = new FormControl();
    this._value = [];
    this.results = [];
    this.blacklistedIds = [];

    this.searchPlaceholder = CommonMessages.SEARCH_BY_NAME;

    this.customOptions = [];
    this.hideAvatarForIds = [null, -1];
    
    this.state = {
      loading: true,
      error: false,
      errorMessage: '',
      searchRunning: false,
      dropdownOpen: false,
      disabled: false,
      searchIsEmpty: true
    };

    this.clearSearchAfterSelection = true;
    this.loseFocusAfterSelection = true;
    this.customOptionsOnly = false;
    this.signedUpUsersOnly = true;
    this.canSelectMultiple = false;
    this.searchIsFocused = false;
  }

  ngOnInit(): void {
    this.state.loading = false;
    this.searchControl.valueChanges
      .pipe(
        map(sarg => {
          this.state.searchRunning = true;
          if (sarg) {
            sarg = sarg.trim().toLowerCase();
          }
          return sarg;
        }),
        debounceTime(500)
      )
      .subscribe(sarg => this.trySearch(sarg));
  }

  trySearch(sarg: string): void {
    this.state.searchRunning = true;

    this.state.searchIsEmpty = !(sarg && sarg.length > 0);

    if (this.customOptionsOnly) {
      this.doSearchCustomOnly(this.customOptions, sarg);
      return;
    } else {
      this.doSearchRegular(this.customOptions, sarg);
    }

  }
  
  doSearchCustomOnly(customOptions: User[], sarg: string): void {
    let results = customOptions;
    if (sarg) {
      results = results.filter(s => `${s.firstName}${s.lastName}`.toLowerCase().includes(sarg)); // Only matches to search
    }

    results = results.slice(0, 5); // Only first 5
    const selectedUserIds = this._value.map(v => v.id);
    results = results.filter(r => !selectedUserIds.includes(r.id)); // Not already selected
    results = results.filter(r => !this.blacklistedIds.includes(r.id)); // Not blacklisted

    this.results = results;
    this.state.searchRunning = false;
    this.state.loading = false;
  }

  doSearchRegular(customOptions: User[], sarg: string): void {
    let matchingCustomResults = customOptions;
    if (sarg) {
      matchingCustomResults = matchingCustomResults.filter(s => s && `${s.firstName}${s.lastName}`.toLowerCase().includes(sarg));
    }

    const blacklistedIdsNumeric: string[] = this.blacklistedIds.filter(bl => bl).map(bl => bl.toString());

    const parentFilter: ParentFilter = {
      operator: FilterOperator.AND,
      childFilters: [
        {
          operator: FilterOperator.AND,
          filterCriteria: [
            {
              field: 'name',
              operator: CriterionOperator.CONTAINS,
              value: sarg
            },
            {
              field: 'isArchived',
              operator: CriterionOperator.EQUALS,
              value: 'false'
            },
            {
              field: 'excludedIds',
              operator: CriterionOperator.EQUALS,
              values: [...blacklistedIdsNumeric, this.globals.user.id.toString()]
            }
          ]
        }
      ]
    };

    if (this.signedUpUsersOnly) {
      parentFilter.childFilters[0].filterCriteria.push({
        field: 'isSignedUp',
        operator: CriterionOperator.EQUALS,
        value: 'true'
      });
    }

    const pagingParams: PagingParams = {
      pageNumber: 0,
      pageSize: 5
    };

    const sortingParams: SortingParams = {
      sortAttributes: [
        'firstName'
      ],
      sortDirection: SortDirection.ASC
    };

    this.userAPIService.searchUsersPaginated(pagingParams, sortingParams, parentFilter).toPromise()
      .then(page => {
        let pageContents = page.content;
        pageContents = [...matchingCustomResults, ...pageContents ];
        const selectedUserIds = this._value.map(v => v.id);
        pageContents = pageContents.filter(r => !selectedUserIds.includes(r.id));
        pageContents = pageContents.filter(r => !this.blacklistedIds.includes(r.id));
        this.results = pageContents.slice(0, 5);
      })
      .finally(() => {
        this.state.searchRunning = false;
        this.state.loading = false;
      });
  }

  selectItem(user: UserMinimal): void {
    if (this.state.disabled) { return; }

    if (this.canSelectMultiple) {
      this.addItemToMultiplePickerValue(user);
    } else {
      this.value = user;
      this.state.dropdownOpen = false;
    }

    this.blurSearchIfToggled();
    this.clearSearchIfToggled();

    this.onTouched();
    this.searchControl.updateValueAndValidity();
  }

  blurSearchIfToggled(): void {
    if (!this.loseFocusAfterSelection) {
      this.focusSearchInput();
    } else {
      this.blurSearchInput();
    }
  }

  clearSearchIfToggled(): void {
    if (this.clearSearchAfterSelection) {
      this.searchControl.patchValue('');
    }
  }

  addItemToMultiplePickerValue(user: UserMinimal): void {
    const selectedIds = this._value.map(r => r.id);
    if (selectedIds.includes(user.id)) { return; }
    this.value = [...this._value, user];
  }

  removeSelectedItem(user: User, event?: MouseEvent): void {
    if (!this.canSelectMultiple) { return; }
    if (event) {
      event.stopPropagation();
    }
    this.value = this._value.filter(r => r.id !== user.id);
  }

  writeValue(v: PickerValue): void {
    if (this.canSelectMultiple) {
      v = v || [];
      this._value = v as User[];
    } else {
      if (v) {
        this._value = [v as User];
      } else {
        this._value = [];
      }
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.state.disabled = isDisabled;
    if (isDisabled) {
      this.searchControl.disable();
    } else {
      this.searchControl.enable();
    }
  }

  onFocusSearch(): void {
    this.searchIsFocused = true;
    if (this.state.disabled) { return; }

    this.state.dropdownOpen = true;
  }

  onBlurSearch(): void {
    this.searchIsFocused = false;

    setTimeout(() => {
      if (this.searchIsFocused) { return; }
      this.state.dropdownOpen = false;
    }, 200);
  }

  trySelectFirst() {
    if (this.results.length === 0) {
      if (!this.searchControl.value) { return this.searchControl.updateValueAndValidity(); }
      return;
    } // Can't select first if there are zero results

    this.selectItem(this.results[0]);

    if (!this.canSelectMultiple) {
      this.blurSearchInput();
    }
  }

  focusSearchInput(): void {
    if (!this.searchInput) { return; }
    this.searchInput.nativeElement.focus();
    this.state.dropdownOpen = true;
  }

  blurSearchInput(): void {
    if (!this.searchInput) { return; }
    this.searchInput.nativeElement.blur();
    this.state.dropdownOpen = false;
  }

  onKeyupBackspace(): void {
    if (this.searchControl.value) { return; }
    if (!this.canSelectMultiple) { return; }
    if (!this.state.searchIsEmpty) { return; }
    if (this.state.searchRunning) { return; }

    // Get value
    const value = this.value as User[];

    if (!value) { return; }
    if (value.length === 0) { return; }

    value.pop();

    this.value = value;
    this.searchControl.updateValueAndValidity();
  }

  onKeyupEscape(): void {
    this.blurSearchInput();
  }
}
