import {Component, EventEmitter, Input, Output} from '@angular/core';
import {CommonMessages} from '@app/constants/common.messages';
import { Globals } from '@app/shared/globals/globals';

export enum ButtonType {
  GREEN = 'GREEN',
  GREEN_INVERTED = 'GREEN_INVERTED',
  GRAY = 'GRAY',
  GRAY_INVERTED = 'GRAY_INVERTED',
  BLUE = 'BLUE',
  BLUE_INVERTED = 'BLUE_INVERTED',
  NAVY = 'NAVY',
  NAVY_INVERTED = 'NAVY_INVERTED',
  RED = 'RED',
  RED_INVERTED = 'RED_INVERTED',
  YELLOW = 'YELLOW',
  YELLOW_INVERTED = 'YELLOW_INVERTED',
  MICROSOFT = 'MICROSOFT',
  MICROSOFT_TEAMS = 'MICROSOFT_TEAMS',
  GOOGLE = 'GOOGLE',
}

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent {
  public readonly eCommonMessages = CommonMessages;
  public readonly eButtonType = ButtonType;

  @Input() type: ButtonType;

  @Input() loading: boolean;
  @Input() disabled: boolean;

  @Input() small: boolean;

  @Input() tooltipText: string;
  @Input() tooltipPlacement: string;
  @Input() tooltipContainer: string;
  @Input() tooltipTriggers: string;

  @Input() buttonWidth: string;

  @Output() onClick: EventEmitter<MouseEvent>;

  constructor(
    public globals: Globals
  ) {
    this.type = ButtonType.GREEN;

    this.loading = false;
    this.disabled = false;
    this.small = false;

    this.tooltipText = '';
    this.tooltipContainer = 'body';
    this.tooltipPlacement = 'bottom';
    this.tooltipTriggers = this.globals.tooltipTriggers;

    this.buttonWidth = 'unset';

    this.onClick = new EventEmitter<MouseEvent>();
  }

  clickButton(event: MouseEvent): void {
    if (this.loading) { return; }
    if (this.disabled) { return; }
    this.onClick.emit(event);
  }
}
