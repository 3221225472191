import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SettingsService } from '@app/shared/api/interfaces/settings.service';

const maxFileUploadKey = 'spring.servlet.multipart.max-file-size';

@Injectable()
export class SettingsAPIService implements SettingsService {

  http: HttpClient
  constructor(private _http: HttpClient) {
    this.http = _http;
  }

  getAllSettings() {
    const url = '/api/settings/';
    return this.http.get(url);
  }

  getMaxFileUploadSize(): Observable<string> {
    const url = '/api/settings/' + maxFileUploadKey + '/';
    return this.http.get(url, {responseType: 'text'});
  }

}
