<div>
  <div class="text-center modal-title">
    <span>New features in Frankli</span>
  </div>
  <div class="new-features-list" *ngIf="features.length > 0; else noNewFeatures">
    <div class="new-feature-item" *ngFor="let f of features; index as i; last as l">
      <!-- Title and date -->
      <div class="m-bottom-10">
        <span class="new-feature-title">{{f.title}}</span>
        <span class="pull-right" *ngIf="f.publishDate">{{f.publishDate | date}}</span>
      </div>
  
      <!-- Image -->
      <div *ngIf="f.imageUrl" class="text-center m-bottom-10">
        <img [src]="f.imageUrl" class="new-feature-image"/>
      </div>
  
      <!-- Message -->
      <div>
        <div [innerHTML]="f.message"></div>
      </div>
  
      <!-- Link - TODO: Maybe move this -->
      <div *ngIf="f.url">
        <a [href]="f.url" target="_blank">Read More...</a>
      </div>
    </div>
  </div>
  <ng-template #noNewFeatures>
    <div class="p-top-15 p-bottom-15 text-center">
      <span>No new features to view</span>
    </div>
  </ng-template>
</div>