<div class="container-star-scale" [class.star-scale-active]="!disabled">
  <div class="list-stars" [class.has-hover-within]="hoveredIndex >= 0" (mouseleave)="onMouseLeave()">
    <ng-container *ngFor="let star of [].constructor(starCount); index as index;">
      <div class="star-item" [class.star-item-hovered]="hoveredIndex >= index" [class.star-item-selected]="(value !== null && value !== undefined) && (value >= index)" (mouseover)="onMouseEnter(index)" (click)="onClickStar(index)" [tooltip]="shouldShowTooltipForIndex(index) ? tooltipOption : undefined">
        <span class="far fa-fw" [ngClass]="iconUsed" [style.fontSize]="starFontSize"></span>
        <ng-container *ngIf="showLegend">
          <div class="area-legend">
            <ng-container [ngTemplateOutlet]="tooltipOption"></ng-container>
          </div>
        </ng-container>
      </div>
      <ng-template #tooltipOption>
        <ng-container *ngIf="metadata && metadata[index]">
          <div class="text-left">
            <strong>{{metadata[index].name}}</strong>
            <div class="standard-description">
              <app-rich-display [text]="metadata[index].description"></app-rich-display>
            </div>
          </div>
        </ng-container>
      </ng-template>
    </ng-container>
  </div>
</div>
