import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {RxStompService} from '@stomp/ng2-stompjs';
import {Message} from '@stomp/stompjs';
import {Globals} from '../globals/globals';
import {environment} from 'environments/environment';
import {RxStompConfig} from './websocket.config';
import {FrankliNotification} from '@app/models/notifications/frankli-notification.model';

// TODO: this should be removed in favor of the rx-stomp-state.d.ts
// Can't get it to pick up on it
export enum RxStompState {
  CONNECTING = 0,
  OPEN = 1,
  CLOSING = 2,
  CLOSED = 3
}

/**
* This service is used to handle the connection and subscription for the websocket. It is used to display the
* notifications in the navbar and the notifications component on the dashboard.
*/
@Injectable()
export class WebsocketService {
  private readonly STOMP_ENDPOINT = '/user/queue/events';
  private readonly stompSubscription$: Observable<Message>;

  public readonly websocketConnection$: Observable<FrankliNotification>;
  public readonly websocketConnectionState$: Observable<RxStompState>;
  /**
	* The constructor will create a user which is the current user. This is needed so that we can filter out the
	* notifications that are not intended for this user.
	* It also initialises the stomp service. You can eagerly create the service by providing the stomp config in the
	* app.module, but the user is not logged in at this point so the handshake will fail. This is why we are using
	* the StompRService as opposed to the StompService.
	* @param {Globals} globals
	* @param {StompRService} stompService
	*/
  constructor(
    public globals: Globals,
    private stompService: RxStompService,
  ) {
    this.stompService.configure(RxStompConfig);
    this.stompSubscription$ = this.stompService.watch(this.STOMP_ENDPOINT);
    this.websocketConnection$ = new Observable<FrankliNotification>(observer => {
      this.stompSubscription$.subscribe(
        (message: Message) => {
          //TODO: Review this
          const notification: FrankliNotification = JSON.parse(message.body);
          // ensure notification belongs to current user
          if (notification.userId === this.globals.user.id) {
            // publish
            observer.next(notification);
          }
        },
        (error: any) => {
          console.error('Error while subscribing to websocket', error);
        });
    });
    this.websocketConnectionState$ = this.stompService.connectionState$.asObservable();
  }

  public activate(): void {
    if (environment.mock.enabled) {
      return;
    }
    if (this.stompService.active) {
      return;
    }
    if (this.stompService.connected()) {
      return;
    }
    if (!this.globals.user) {
      return;
    }

    this.stompService.configure({
      brokerURL: environment.websocketUrl + `?token=${localStorage.getItem('access_token')}`
    });

    this.stompService.activate();
  }

  public deactivate(): void {
    if (!this.stompService.active) {
      return;
    }

    this.stompService.deactivate();
  }

  public getConnection$(): Observable<FrankliNotification> {
    return this.websocketConnection$;
  }

  public getConnectionState$(): Observable<RxStompState> {
    return this.websocketConnectionState$;
  }

  public testSendingWhileDeactivate(): void {
    for (let index = 0; index < 3; index++) {
      this.deactivate();
      this.activate();
    }
  }
}
