import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-frame-with-heading',
  templateUrl: './frame-with-heading.component.html',
  styleUrls: ['./frame-with-heading.component.scss']
})
export class FrameWithHeadingComponent {

  @Input() title: string;

  constructor() {
    this.title = 'Placeholder';
  }
}
