import {Injectable} from '@angular/core';
import {BehaviorSubject, Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NavbarService {

  public openTasks$: Subject<boolean> = new BehaviorSubject<boolean>(false);

  constructor() {}

  openTasks(){
    this.openTasks$.next(true);
  }

  resetTasks(){
    this.openTasks$.next(false);
  }

  
}
