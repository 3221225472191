import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { PossibleAnswer } from '@app/models/survey/possible-answer.model';
import { PillType } from 'app/shared/components/pill/pill.component';

@Component({
  selector: 'app-multi-choice',
  templateUrl: './multi-choice.component.html',
  styleUrls: ['./multi-choice.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => MultiChoiceComponent),
    multi: true
  }]
})
export class MultiChoiceComponent implements ControlValueAccessor, OnInit {
  public readonly ePillType = PillType;

  @Input() answers: PossibleAnswer[];
  @Input() disabled: boolean;
  @Input() multiple: boolean;

  _valueSingle: number;
  _valuesMultiple: number[];

  onChange = (_: any) => {};
  onTouched = () => {};

  get value(): string {
    if (this.multiple) {
      return this.valuesMultiple.join(',');
    } else {
      if (this.valueSingle) {
        return this.valueSingle.toString();
      } else {
        return '';
      }
    }
  }

  set value(v: string) {
    if (this.multiple) {
      if (v) {
        const values = v.split(',');
        this.valuesMultiple = values.map((x: string) => Number(x));
      } else {
        this.valuesMultiple = [];
      }
    } else {
      if (v) {
        this.valueSingle = +(v);
      } else {
        this.valueSingle = undefined!;
      }
    }
  }

  get valueSingle(): number {
    return this._valueSingle;
  }

  set valueSingle(val: number) {
    this._valueSingle = val;
    this.onChange(val.toString())
  }

  get valuesMultiple(): number[] {
    return this._valuesMultiple;
  }

  set valuesMultiple(val: number[]) {
    this._valuesMultiple = val;
    this.onChange(val.join(','))
  }

  constructor() {
    this.disabled = false;
    this.answers = [];
    this.multiple = false;
    this._valuesMultiple = [];
    this._valueSingle = undefined!;
  }

  isSelected(val: number) {
    if (this.multiple) {
      return this._valuesMultiple.includes(val);
    } else {
      return this._valueSingle === val;
    }
  }

  ngOnInit() {
  }

  writeValue(v: any) {
    if (this.multiple) {
      if (v) {
        const values = v.split(',');
        this._valuesMultiple = values.map((x: string) => Number(x));
      } else {
        this._valuesMultiple = [];
      }
    } else {
      if (v) {
        this._valueSingle = +(v);
      } else {
        this._valueSingle = undefined!;
      }
    }
  }

  // Register callbacks
  registerOnChange(fn: (_: any) => {}): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  select(value: number) {
    if (!this.disabled) {
      if (this.multiple) {
        if (this.isSelected(value)) {
          this.valuesMultiple = this.valuesMultiple.filter(v => v !== value);
        } else {
          this.valuesMultiple = [...this.valuesMultiple, value];
        }
      } else {
        this.valueSingle = value;
      }
    }
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }
}
