export const CareerPathwayMessages = {
  CAREER_PATHWAYS: 'career-pathway.career-pathways',
  CAREER_PATHWAYS_DESCRIPTION: 'career-pathway.career-pathways-description',
  EXISTING_PATHWAYS: 'career-pathway.existing-pathways',
  HEADER_CREATING: 'career-pathway.header-creating',
  HEADER_EDITING: 'career-pathway.header-editing',
  HEADER_BRANCHING: 'career-pathway.header-branching',
  NAME: 'career-pathway.name',
  NAME_PLACEHOLDER: 'career-pathway.name-placeholder',
  ROLES: 'career-pathway.roles',
  CONFIRMATION_DELETE: 'career-pathway.confirmation-delete',
  CONFIRMATION_MAKE_PUBLIC: 'career-pathway.confirmation-make-public',
  TOOLTIP_COPY: 'career-pathway.tooltip-copy',
  TOOLTIP_EDIT: 'career-pathway.tooltip-edit',
  TOOLTIP_DELETE: 'career-pathway.tooltip-delete',
  TOOLTIP_SELECT: 'career-pathway.tooltip-select',
  TOOLTIP_BRANCH: 'career-pathway.tooltip-branch',
  TOOLTIP_MAKE_PUBLIC: 'career-pathway.tooltip-make-public',
  SUCCESS_CREATE: 'career-pathway.success-create',
  SUCCESS_UPDATE: 'career-pathway.success-update',
  SUCCESS_DELETE: 'career-pathway.success-delete',
  SUCCESS_UPDATE_MY_PATHWAY: 'career-pathway.success-update-my-pathway',
  SUCCESS_MAKE_PUBLIC: 'career-pathway.success-make-public',
  EMPTY_STATE_ALL: 'career-pathway.empty-state-all',
  EMPTY_STATE_FILTERS: 'career-pathway.empty-state-filters',
  CTA_CREATE: 'career-pathway.cta-create',
  ADD_A_ROLE: 'career-pathway.add-a-role',
  ADD_A_ROLE_SEARCH_NOT_ADDED: 'career-pathway.add-a-role-search-not-added',
  EMPTY_STATE_PATHWAY_ROLES: 'career-pathway.empty-state-pathway-roles',
  NO_ROLES: 'career-pathway.no-roles',
  MY_CAREER_PATH: 'career-pathway.my-career-path',
  MY_CAREER_PATH_DESCRIPTION: 'career-pathway.my-career-path-description',
  CHOOSE_A_NEW_PATH: 'career-pathway.choose-a-new-path',
  EMPTY_STATE_SELECTED_PATHWAY: 'career-pathway.empty-state-selected-pathway',
  CURRENT_ROLE: 'career-pathway.current-role',
  CURRENT_PATHWAY: 'career-pathway.current-pathway',
  PATHWAY_TREE: 'career-pathway.pathway-tree',
  PATHWAY_TREE_DESCRIPTION: 'career-pathway.pathway-tree-description',
  PATHWAYS_CONTAINING_ROLE: 'career-pathway.pathways-containing-role',
  PATHWAYS_CONTAINING_ROLE_DESCRIPTION: 'career-pathway.pathways-containing-role-description',
  PATHWAYS_ALL: 'career-pathway.pathways-all',
  PATHWAYS_ALL_DESCRIPTION: 'career-pathway.pathways-all-description',
  VIEW_ONLY_RELATED_PATHWAYS: 'career-pathway.view-only-related-pathways',
  VIEW_ALL_PATHWAYS: 'career-pathway.view-all-pathways',
  EMPTY_STATE: 'career-pathway.empty-state',
  EMPTY_STATE_RELATED_PATHWAYS: 'career-pathway.empty-state-related-pathways',
  PLACEHOLDER_SEARCH: 'career-pathway.placeholder-search',
  STOP_ADDING_ROLES: 'career-pathway.stop-adding-roles',
  STOP_ADDING_ROLES_WARNING: 'career-pathway.stop-adding-roles-warning',
  MOVE_THIS_ROLE: 'career-pathway.move-this-role',
  REMOVE_THIS_ROLE: 'career-pathway.remove-this-role',
  SUGGESTED_PATHWAY_DESCRIPTION: 'career-pathway.suggested-pathway-description',
  WANT_TO_EXPLORE_YOUR_OPTIONS: 'career-pathway.want-to-explore-your-options',
  BROWSE_PATHWAYS: 'career-pathway.browse-pathways',
  CUSTOM_CAREER_PATHWAYS: 'career-pathway.custom-career-pathways',
  CUSTOM_CAREER_PATHWAYS_DESCRIPTION: 'career-pathway.custom-career-pathways-description',
  MODIFY_SELECTED: 'career-pathway.modify-selected',
  CREATE_YOUR_OWN: 'career-pathway.create-your-own',
};
