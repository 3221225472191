<div class="goal-create-key-result-item-container">
    <div class="row-title-due-date">
      <div class="row">
        <div class="col-sm-6 col-xs-12">
          <div class="frankli-form-group" [class.frankli-form-group-filled]="controlKeyResultTitle.value">
            <label translate>{{eGoalsMessages.KEY_RESULT}}</label>
            <input type="text" [formControl]="controlKeyResultTitle" class="form-control"/>
            <div class="frankli-form-group-alert">
              <div *ngIf="controlKeyResultTitle.hasError('maxlength')" translate [translateParams]="{ maxValue: eGoalCreateResultValidationLimits.result.max }">{{eCommonMessages.FORM_MAX_CHARACTER_VALUE}}</div>
              <ng-container *ngIf="submitted && controlKeyResultTitle.invalid">
                <div *ngIf="controlKeyResultTitle.hasError('required')" translate>{{eGoalsMessages.GOAL_CREATE_KEY_RESULTS_VALIDATION_RESULT_REQUIRED}}</div>
              </ng-container>
            </div>
          </div>
        </div>
        <div class="col-sm-6 col-xs-12">
          <div class="frankli-form-group" [class.frankli-form-group-filled]="controlKeyResultEndDate.value">
            <label translate>{{eGoalsMessages.DUE_DATE}}</label>
            <app-date-picker [name]="'goalEndDate'" [formControl]="controlKeyResultEndDate" [disableUntil]="minDueDate" [disableSince]="maxDueDate" [displayFormat]="dateDisplayFormat" (calendarToggle)="onCalendarToggle($event)" [showResetButton]="true"></app-date-picker>
            <div class="frankli-form-group-alert">
              <div *ngIf="controlKeyResultEndDate.hasError('mindate')" translate>{{eCommonMessages.CANNOT_BE_IN_THE_PAST}}</div>
              <div *ngIf="controlKeyResultEndDate.hasError('maxdate')" translate>{{eGoalsMessages.GOAL_CREATE_KEY_RESULTS_VALIDATION_DUE_DATE_MAX}}</div>
              <ng-container *ngIf="submitted && controlKeyResultEndDate.invalid">
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row-measure-type">
      <div class="frankli-form-group frankli-form-group-filled">
        <label>
          <label translate>{{eGoalsMessages.MEASURE_TYPE}}</label>
          <span class="fal fa-fw fa-question-circle m-left-5" [tooltip]="tooltipMeasureType" [triggers]="globals.tooltipTriggers" placement="bottom" container="body"></span>
        </label>
        <app-button-group [options]="measureTypeOptions" [formControl]="controlKeyResultType" [consistentWidths]="true"></app-button-group>
        <div class="frankli-form-group-alert">
          <ng-container *ngIf="submitted && controlKeyResultType.invalid">
            <div *ngIf="controlKeyResultType.hasError('required')" translate>{{eGoalsMessages.GOAL_CREATE_KEY_RESULTS_VALIDATION_MEASURE_TYPE_REQUIRED}}</div>
          </ng-container>
        </div>
      </div>
    </div>
    <div class="row-measure-details" *ngIf="controlKeyResultType.value === eGoalKeyResultType.NUMERIC">
      <div class="row-measure-start-end">
        <div class="row">
          <div class="col-sm-6 col-xs-12">
            <div class="frankli-form-group" [class.frankli-form-group-filled]="controlKeyResultStartValue.value.toString()">
              <label translate>{{eGoalsMessages.START_VALUE}}</label>
              <input type="text" class="form-control" [formControl]="controlKeyResultStartValue"/>
              <div class="frankli-form-group-alert">
                <ng-container *ngIf="controlKeyResultStartValue.invalid">
                  <ng-container *ngIf="submitted">
                    <div *ngIf="controlKeyResultStartValue.hasError('required')" translate>{{eGoalsMessages.GOAL_CREATE_KEY_RESULTS_VALIDATION_START_VALUE_REQUIRED}}</div>
                  </ng-container>
                  <ng-container *ngIf="submitted || submittedDraft">
                    <div *ngIf="controlKeyResultStartValue.hasError('forbiddenCharacter')" translate>{{eGoalsMessages.GOAL_CREATE_KEY_RESULTS_VALIDATION_START_VALUE_FORBIDDEN_CHARACTER}}</div>
                  </ng-container>
                </ng-container>
              </div>
            </div>
          </div>
          <div class="col-sm-6 col-xs-12">
            <div class="frankli-form-group" [class.frankli-form-group-filled]="controlKeyResultGoalValue.value.toString()">
              <label translate>{{eGoalsMessages.GOAL_VALUE}}</label>
              <input type="text" class="form-control" [formControl]="controlKeyResultGoalValue"/>
              <div class="frankli-form-group-alert">
                <ng-container *ngIf="controlKeyResultGoalValue.invalid">
                  <ng-container *ngIf="submitted">
                    <div *ngIf="controlKeyResultGoalValue.hasError('required')" translate>{{eGoalsMessages.GOAL_CREATE_KEY_RESULTS_VALIDATION_GOAL_VALUE_REQUIRED}}</div>
                  </ng-container>
                  <ng-container *ngIf="submitted || submittedDraft">
                    <div *ngIf="controlKeyResultGoalValue.hasError('forbiddenCharacter')" translate>{{eGoalsMessages.GOAL_CREATE_KEY_RESULTS_VALIDATION_GOAL_VALUE_FORBIDDEN_CHARACTER}}</div>
                  </ng-container>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row-measure-unit-details">
        <div class="row">
          <div class="col-sm-6 col-xs-12">
            <div class="frankli-form-group" [class.frankli-form-group-filled]="controlKeyResultUnit.value">
              <label>
                <span translate>{{eGoalsMessages.UNIT}}</span>
                <span class="m-left-5">({{eCommonMessages.OPTIONAL | translate}})</span>
              </label>
              <input type="text" class="form-control" [formControl]="controlKeyResultUnit"/>
              <div class="frankli-form-group-alert">
                <div *ngIf="controlKeyResultUnit.hasError('maxlength')" translate [translateParams]="{ maxValue: eGoalCreateResultValidationLimits.measureUnit.max}">{{eCommonMessages.FORM_MAX_CHARACTER_VALUE}}</div>
                <ng-container *ngIf="submitted && controlKeyResultUnit.invalid">
                </ng-container>
              </div>
            </div>
          </div>
          <div class="col-sm-6 col-xs-12">
            <ng-container *ngIf="controlKeyResultUnit.value">
              <div class="frankli-form-group frankli-form-group-filled">
                <label translate>{{eGoalsMessages.UNIT_PLACEMENT}}</label>
                <app-button-group [options]="measureUnitPlacementOptions" [formControl]="controlKeyResultUnitPlacement" [consistentWidths]="true"></app-button-group>
                <div class="frankli-form-group-alert">
                  <ng-container *ngIf="submitted && controlKeyResultUnitPlacement.invalid">
                    <div *ngIf="controlKeyResultUnitPlacement.hasError('required')" translate>{{eGoalsMessages.GOAL_CREATE_KEY_RESULTS_VALIDATION_UNIT_PLACEMENT_REQUIRED}}</div>
                  </ng-container>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
</div>

<ng-template #tooltipMeasureType>
  <div class="text-left">
    <div [innerHTML]="eGoalsMessages.GOAL_CREATE_KEY_RESULTS_EXPLANATION_COMPLETE_INCOMPLETE | translate"></div>

    <br class="m-bottom-5"/>

    <div [innerHTML]="eGoalsMessages.GOAL_CREATE_KEY_RESULTS_EXPLANATION_NUMERIC | translate"></div>

    <br class="m-bottom-15"/>

    <div translate>{{eGoalsMessages.GOAL_CREATE_KEY_RESULTS_EXPLANATION_GENERAL}}</div>
  </div>
</ng-template>