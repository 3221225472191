export enum GoalTableColumn {
    STATUS,
    OWNERS,
    PRIORITY,
    TYPE,
    TITLE,
    PROGRESS,
    DUE_DATE,
    DEPARTMENT,
    SITE
}