import { SelfReflection } from '@app/models/evaluation/self-reflection.model';
import { mockEvaluationCycles } from '@app/mock/api/data/mockEvaluationCycles';
import { mockUsers } from '@app/mock/api/data/mockUsers';
import { mapSelfReflectionAnswers } from '@app/mock/api/mock-helpers';

export const mockSelfReflections: SelfReflection[] = [
    {
        id: 1,
        cycleId: mockEvaluationCycles[0].id,
        authorUser: mockUsers[1],
        lastEditTimestamp: new Date(),
        completed: true,
        selfQuestionsAnswered: mapSelfReflectionAnswers(mockEvaluationCycles[0].selfReflectionQuestions)
    },
    {
        id: 2,
        cycleId: mockEvaluationCycles[0].id,
        authorUser: mockUsers[2],
        lastEditTimestamp: new Date(),
        completed: true,
        selfQuestionsAnswered: mapSelfReflectionAnswers(mockEvaluationCycles[0].selfReflectionQuestions)
    },
    {
        id: 3,
        cycleId: mockEvaluationCycles[0].id,
        authorUser: mockUsers[3],
        lastEditTimestamp: new Date(),
        completed: true,
        selfQuestionsAnswered: mapSelfReflectionAnswers(mockEvaluationCycles[0].selfReflectionQuestions)
    },
    {
        id: 4,
        cycleId: mockEvaluationCycles[1].id,
        authorUser: mockUsers[0],
        lastEditTimestamp: new Date(),
        completed: true,
        selfQuestionsAnswered: mapSelfReflectionAnswers(mockEvaluationCycles[0].selfReflectionQuestions)
    },
];