<div>
  <div class="preview-container">
    <div class="fake-input">
      <div class="fake-input-inner" (click)="changeFile()">
        <ng-container *ngIf="value; else noFile;">
          <div class="preview-item">
            <app-uploaded-file-display class="preview-item" [file]="value"></app-uploaded-file-display>
          </div>
        </ng-container>
        <ng-template #noFile>
          <div class="no-file-selected">
            <button class="select-file-button">Select file</button>
          </div>
        </ng-template>
      </div>
    </div>
    <div class="remove-icon" *ngIf="value">
      <span class="fal fa-times fa-fw frankli-close expand-on-hover" (click)="removeFile()"></span>
    </div>
  </div>

  <!-- Only show if no file -->
  <input class="file-upload-input hidden" type="file" (change)="fileChanged($event)" #uploadInput>
</div>