import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ConnectionSettingsDto } from '../../model/connection-settings.model';
import { IConnectionSettingsAPI } from './connection-settings-api.model';

@Injectable()
export class ConnectionSettingsAPIService implements IConnectionSettingsAPI {
  private readonly BASE_URL: string = 'api/connection-settings';

  constructor(
      private http: HttpClient
  ) { }

  get(): Observable<ConnectionSettingsDto> {
    const url = `${this.BASE_URL}`;
    return this.http.get<ConnectionSettingsDto>(url);
  }

  update(updatedSettings: ConnectionSettingsDto): Observable<ConnectionSettingsDto> {
    const url = `${this.BASE_URL}`;
    return this.http.put<ConnectionSettingsDto>(url, updatedSettings);
  }
}
