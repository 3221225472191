import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import { CareerPathway } from '@app/domain/career_pathway/model/career-pathway.model';
import {CreateCareerPathwayDto} from '@app/domain/career_pathway/model/create-career-pathway.model';
import {ICareerPathwayAPI} from '@app/domain/career_pathway/api/career-pathway-api.model';
import { Page } from '@app/models/api/page.model';
import { PagingParams } from '@app/models/api/paging-params.model';
import { SortingParams } from '@app/models/api/sorting-params.model';
import { ApiUtils } from '@app/shared/utils/api.utils';
import { CareerPathwayNode } from '../model/career-pathway-node.model';

@Injectable()
export class CareerPathwayAPIService implements ICareerPathwayAPI {

  private readonly BASE_URL = 'api/career-pathways';

  constructor(private http: HttpClient) {
  }

  get(ids: number[], name: string, roleId: number[], showPrivate: boolean): Observable<CareerPathway[]> {
    const url = `${this.BASE_URL}`;

    let params = new HttpParams();

    if (ids) {
      ids.forEach(id => {
        params = params.append('id', id);
      });
    }

    if (name && name.length > 0) {
      params = params.append('name', name);
    }

    if (roleId) {
      roleId.forEach(id => {
        params = params.append('roleId', id);
      });
    }

    if (showPrivate !== null) {
      params = params.append('showPrivate', showPrivate);
    }

    return this.http.get<CareerPathway[]>(url, { params: params });
  }

  getPaginated(pagingParams: PagingParams, sortingParams: SortingParams, ids: number[], name: string, roleId: number[], showPrivate: boolean): Observable<Page<CareerPathway>> {
    const url = `${this.BASE_URL}/paged`;

    let params = ApiUtils.createPageAndSortParams(pagingParams, sortingParams);

    if (ids) {
      ids.forEach(id => {
        params = params.append('id', id);
      });
    }

    if (name && name.length > 0) {
      params = params.append('name', name);
    }

    if (roleId) {
      roleId.forEach(id => {
        params = params.append('roleId', id);
      });
    }

    if (showPrivate !== null) {
      params = params.append('showPrivate', showPrivate);
    }

    return this.http.get<Page<CareerPathway>>(url, { params: params });
  }

  create(createCareerPathwayDto: CreateCareerPathwayDto): Observable<CareerPathway> {
    const url = `${this.BASE_URL}`;
    return this.http.post<CareerPathway>(url, createCareerPathwayDto);
  }

  update(id: number, createCareerPathwayDto: CreateCareerPathwayDto): Observable<CareerPathway> {
    const url = `${this.BASE_URL}/${id}`;
    return this.http.put<CareerPathway>(url, createCareerPathwayDto);
  }

  makePublic(id: number): Observable<CareerPathway> {
    const url = `${this.BASE_URL}/${id}/make-public`;
    return this.http.post<CareerPathway>(url, null);
  }

  delete(id: number): Observable<CareerPathway> {
    const url = `${this.BASE_URL}/${id}`;
    return this.http.delete<CareerPathway>(url);
  }

  getSelectedCareerPathwayForUserId(userId: number): Observable<CareerPathway> {
    const url = `${this.BASE_URL}/user/${userId}`;
    return this.http.get<CareerPathway>(url);
  }

  setSelectedCareerPathwayForUserId(userId: number, careerPathwayId: number): Observable<CareerPathway> {
    const url = `${this.BASE_URL}/user/${userId}`;
    return this.http.put<CareerPathway>(url, careerPathwayId);
  }

  getCareerPathwayNodeByCurrentRoleId(roleId: number): Observable<CareerPathwayNode> {
    const url = `${this.BASE_URL}/node/${roleId}`;
    return this.http.get<CareerPathwayNode>(url);
  }
  
  getMostRelevantPathwayForMe(): Observable<CareerPathway> {
    const url = `${this.BASE_URL}/most-relevant`;
    return this.http.get<CareerPathway>(url);
  }
}
