<div class="goal-tags-container">
  <app-state [state]="state">
    <ng-container *ngIf="!state.loading && !state.error">
      <div class="card">
        <!-- Filter and Column Toggle -->
        <div class="filter-area">
          <div class="row m-left-0 m-right-0">
            <div class="col-search-box">
              <div class="search-box-container">
                <input type="search" class="form-control search-box-control" [placeholder]="eGoalsMessages.TAG_SEARCH_PROMPT | translate" [formControl]="searchControl" maxlength="100"/>
                <div class="search-box-icon">
                  <span class="fal fa-fw fa-search"></span>
                </div>
              </div>
            </div>
            <div class="col-column-toggle">
              <app-column-toggle-new [columnInfos]="columnInfo" [alwaysEnabled]="alwaysEnabledColumns" #columnToggle></app-column-toggle-new>
            </div>
          </div>
        </div>
        
        <!-- Tag Table -->
        <ng-container *ngIf="tagOverviewPage">
          <div class="table-area" *ngIf="tagOverviewPage.content.length > 0; else stateEmpty">
            <div class="scroll-x">
              <table width="100%">
                <!-- Table headings -->
                <tr class="frankli-table frankli-table-header frankli-table-header-large noselect">

                  <th class="table-col-dropdown"></th>

                  <th class="table-col-tag-name" *ngIf="columnToggle.getColumnEnabled(eTableColumn.NAME)">
                    <span translate>{{eGoalsMessages.TAG_NAME}}</span>
                  </th>

                  <th class="table-col-tag-description" *ngIf="columnToggle.getColumnEnabled(eTableColumn.DESCRIPTION)">
                    <span translate>{{eGoalsMessages.TAG_DESCRIPTION}}</span>
                  </th>

                  <th class="table-col-tag-number-of-goals" *ngIf="columnToggle.getColumnEnabled(eTableColumn.NUMBER_OF_GOALS)">
                    <span translate>{{eGoalsMessages.TAG_NUMBER_OF_GOALS}}</span>
                  </th>

                </tr>
        
                <!-- Table contents -->
                <ng-container *ngFor="let tagOverview of tagOverviewPage.content; index as i; trackBy: trackTagsDisplayed">
                  <!-- Primary goal info -->
                  <tr class="frankli-table frankli-table-hover frankli-table-single-line" (click)="toggleDropdown(i)">
                    <td class="table-col-dropdown">
                      <app-table-dropdown-toggle [opened]="opened[i]"></app-table-dropdown-toggle>
                    </td>
        
                    <!-- Name -->
                    <td class="table-col-tag-name" *ngIf="columnToggle.getColumnEnabled(eTableColumn.NAME)">
                      <span class="display-tag-title">{{tagOverview.goalTag.text}}</span>
                    </td>
        
                    <!-- Description -->
                    <td class="table-col-tag-description" *ngIf="columnToggle.getColumnEnabled(eTableColumn.DESCRIPTION)">
                      <span class="display-tag-description">{{tagOverview.goalTag.description}}</span>
                    </td>
        
                    <!-- Number of goals -->
                    <td class="table-col-tag-number-of-goals" *ngIf="columnToggle.getColumnEnabled(eTableColumn.NUMBER_OF_GOALS)">
                      <app-progress-bar [replacePercentWithValue]="true" [startValue]="0" [targetValue]="maxGoalsCount" [currentValue]="tagOverview.goalCount" [unitBar]="eGoalsMessages.GOALS | translate"></app-progress-bar>
                    </td>
                  </tr>
        
                  <!-- Dropdown contents -->
                  <tr *ngIf="opened[i]">
                    <td colspan="50">
                      <app-goal-tag-dropdown-list [goalTag]="tagOverview.goalTag"></app-goal-tag-dropdown-list>
                    </td>
                  </tr>
                </ng-container>
              </table>
            </div>

            <app-pagination-server *ngIf="tagOverviewPage" [page]="tagOverviewPage" [pageSize]="pagingParams.pageSize" (pageSizeChanged)="changePageSize($event)" (pageNumberChanged)="changePageNumber($event)"></app-pagination-server>
          </div>
    
          <ng-template #stateEmpty>
            <app-empty-state [message]="eGoalsMessages.TAG_EMPTY_STATE_MESSAGE | translate" [height]="'calc(100vh - 350px)'"></app-empty-state>
          </ng-template>
        </ng-container>
      </div>
    </ng-container>
  </app-state>
</div>