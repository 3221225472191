import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Page } from '@app/models/api/page.model';
import { PagingParams } from '@app/models/api/paging-params.model';
import { SortingParams } from '@app/models/api/sorting-params.model';
import { ApiUtils } from '@app/shared/utils/api.utils';
import { IQuestionTemplateAPI } from './question-template-api.model';
import { QuestionTemplate } from '../model/question-template.model';
import { CreateQuestionTemplate } from '../model/create-question-template.model';
import { QuestionTemplateUpdateResponse } from '../model/question-template-update-response.model';

@Injectable()
export class QuestionTemplateAPIService implements IQuestionTemplateAPI {

  private readonly BASE_URL = 'api/question-template';

  constructor(private http: HttpClient) {
  }

  get(ids: number[], name: string, creatorId: number[]): Observable<QuestionTemplate[]> {
    const url = `${this.BASE_URL}`;

    let params = new HttpParams();

    if (ids) {
      ids.forEach(id => {
        params = params.append('id', id);
      });
    }

    if (name && name.length > 0) {
      params = params.append('name', name);
    }

    if (creatorId) {
      creatorId.forEach(id => {
        params = params.append('creatorId', id);
      });
    }

    return this.http.get<QuestionTemplate[]>(url, { params: params });
  }

  getPaginated(pagingParams: PagingParams, sortingParams: SortingParams, ids: number[], name: string, creatorId: number[]): Observable<Page<QuestionTemplate>> {
    const url = `${this.BASE_URL}/paged`;

    let params = ApiUtils.createPageAndSortParams(pagingParams, sortingParams);

    if (ids) {
      ids.forEach(id => {
        params = params.append('id', id);
      });
    }

    if (name && name.length > 0) {
      params = params.append('name', name);
    }

    if (creatorId) {
      creatorId.forEach(id => {
        params = params.append('creatorId', id);
      });
    }

    return this.http.get<Page<QuestionTemplate>>(url, { params: params });
  }

  create(createQuestionTemplate: CreateQuestionTemplate): Observable<QuestionTemplate> {
    const url = `${this.BASE_URL}`;
    return this.http.post<QuestionTemplate>(url, createQuestionTemplate);
  }

  update(id: number, createQuestionTemplate: CreateQuestionTemplate): Observable<QuestionTemplateUpdateResponse> {
    const url = `${this.BASE_URL}/${id}`;
    return this.http.put<QuestionTemplateUpdateResponse>(url, createQuestionTemplate);
  }

  updateApplicationsUsingTemplate(id: number): Observable<number> {
    const url = `${this.BASE_URL}/${id}/update-applications-using-template`;
    return this.http.get<number>(url);
  }

  delete(id: number): Observable<QuestionTemplate> {
    const url = `${this.BASE_URL}/${id}`;
    return this.http.delete<QuestionTemplate>(url);
  }
}
