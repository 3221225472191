<div class="person-list-container" [class.list-loading]="!initialised || processing">
  <div class="list-main-container" #listContainer>
    <ng-container *ngFor="let u of users; first as isFirst; index as index;">
      <ng-container *ngIf="u">
        <div class="owner-img owners-item" [style.width.px]="imageSizePx" [style.height.px]="imageSizePx" [style.marginLeft]="getMarginForImage(index)" [class.clickable]="openProfileOnClick" (click)="onClickUser(u)" [title]="showTitles ? (u.firstName + ' ' + u.lastName) : ''">
          <app-profile-photo [src]="u.imageUrl" [firstName]="u.firstName" [lastName]="u.lastName" [width]="imageSizePx + 'px'" [letterAvatarFontSize]="letterAvatarFontSize"></app-profile-photo>
        </div>
      </ng-container>
    </ng-container>
  </div>

  <div class="list-loading-overlay">
    <span class="far fa-fw fa-spin fa-spinner"></span>
  </div>
</div>
