import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CreateVisionStatement } from '../model/create-vision-statement.model';
import { VisionStatement } from '../model/vision-statement.model';
import { VisionStatementService } from './vision-statement-api.model';

@Injectable()
export class VisionStatementAPIService implements VisionStatementService {
  private readonly BASE_URL = 'api/vision-statement';

  constructor(
    private http: HttpClient
  ) { }

  getVision(): Observable<VisionStatement> {
    const url = `${this.BASE_URL}`;
    return this.http.get<VisionStatement>(url);
  }

  setVision(visionStatement: CreateVisionStatement): Observable<VisionStatement> {
    const url = `${this.BASE_URL}`;
    return this.http.post<VisionStatement>(url, visionStatement);
  }

  deleteVision(): Observable<VisionStatement> {
    const url = `${this.BASE_URL}`;
    return this.http.delete<VisionStatement>(url);
  }
}
