import { User } from 'app/models/user/user.model';

export class PeerFeedback {
  id: number;
  sender: User;
  recipient: User;
  observation: string;
  reason: string;
  suggestion: string;
  type: PeerFeedbackType;
  timestamp: Date;
  viewable: boolean;
}

export enum PeerFeedbackType {
  HELPFUL = 'HELPFUL',
  ENCOURAGING = 'ENCOURAGING',
  USEFUL = 'USEFUL'
}

export class CreatePeerFeedbackDto {
  recipient: User;
  observation: string;
  reason: string;
  suggestion: string;
  type: PeerFeedbackType;
  viewable: boolean;

  constructor(recipient: User, observation: string, reason: string, suggestion: string, type: PeerFeedbackType, viewable: boolean) {
    this.recipient = recipient;
    this.observation = observation;
    this.reason = reason;
    this.suggestion = suggestion;
    this.type = type;
    this.viewable = viewable;
  }
}
