import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TaskStatus } from '@app/domain/task/model/task-status.enum';
import { Task } from '@app/domain/task/model/task.model';
import { TasksMessages } from '@app/domain/task/locale/tasks.messages';
import { TaskBusinessService } from '../../service/task-business.service';
import { TaskAlignmentType } from '../../model/task-alignment-type.enum';
import { DateUtils } from '@app/shared/utils/date.utils';
import { FormControl } from '@angular/forms';
import { CommonMessages } from '@app/constants/common.messages';
import { PillType } from '@app/shared/components/pill/pill.component';
import { Globals } from '@app/shared/globals/globals';
import { UserMinimal } from '@app/models/user/user-minimal.model';

@Component({
  selector: 'app-task-display',
  templateUrl: './task-display.component.html',
  styleUrls: ['./task-display.component.scss']
})
export class TaskDisplayComponent implements OnInit {
  public readonly eTaskAlignmentType = TaskAlignmentType;
  public readonly eCommonMessages = CommonMessages;
  public readonly eTasksMessages = TasksMessages;
  public readonly eTaskStatus = TaskStatus;
  public readonly ePillType = PillType;

  @Input() task: Task;
  @Input() actionsShown: string[]; // valid options are 'view', 'edit', 'unlink', 'archive'

  @Output() onClickAction: EventEmitter<string>;

  controlTaskStatus: FormControl;

  get canSeeDueDate(): boolean {
    if (!this.task) { return false; }
    if (this.task.archived) { return false; }
    if (!this.task.dueDate) { return false; }
    if (this.task.status === TaskStatus.COMPLETE) { return false; }
    return true;
  }

  get canSeeCreateDate(): boolean {
    if (!this.task) { return false; }
    if (this.task.archived) { return false; }
    if (!this.task.creationDate) { return false; }
    return true;
  }

  get canSeeCompleteDate(): boolean {
    if (!this.task) { return false; }
    if (this.task.archived) { return false; }
    if (!this.task.completionDate) { return false; }
    return true;
  }

  get dueDateHighlight(): string {
    if (!this.task) { return ''; }
    if (!this.task.dueDate) { return ''; }

    const daysUntil = DateUtils.getNumberOfDaysUntil(this.task.dueDate);

    // overdue
    if (daysUntil < 0) { return 'due-date-overdue'; }

    // due within 10 days
    if (daysUntil <= 10) { return 'due-date-due-soon'; }

    return '';
  }

  get canSeeActionEdit(): boolean {
    if (!this.task) { return false; }
    if (this.task.archived) { return false; }
    if (!this.actionsShown) { return false; }
    if (!this.actionsShown.includes('edit')) { return false; }
    return true;
  }

  get canSeeActionUnlink(): boolean {
    if (!this.task) { return false; }
    if (this.task.archived) { return false; }
    if (!this.actionsShown) { return false; }
    if (!this.actionsShown.includes('unlink')) { return false; }
    return true;
  }

  get canSeeActionArchive(): boolean {
    if (!this.task) { return false; }
    if (this.task.archived) { return false; }
    if (!this.actionsShown) { return false; }
    if (!this.actionsShown.includes('archive')) { return false; }
    return true;
  }
  
  get canUpdateTask(): boolean {
    if (!this.task) { return false; }
    if (this.task.archived) { return false; }
    // Must be owner
    if (!this.task.owners) { return false; }
    if (!this.task.owners.map(o => o.id).includes(this.globals.user.id)) { return false; }
    return true;
  }

  get taskOwnersOrdered(): UserMinimal[] {
    if (!this.task) { return []; }
    if (!this.task.owners) { return []; }
    return this.task.owners.sort((a, b) => a.id - b.id);
  }

  constructor(
    public globals: Globals,
    private taskBusinessService: TaskBusinessService
  ) {
    this.task = undefined;

    this.actionsShown = [];

    this.controlTaskStatus = this.initControlTaskStatus();

    this.onClickAction = new EventEmitter<string>();
  }

  ngOnInit(): void {
    this.controlTaskStatus = this.initControlTaskStatus(this.task);
  }

  clickAction(action: string): void {
    this.onClickAction.emit(action);
  }

  initControlTaskStatus(task?: Task): FormControl {
    const control = new FormControl(null, []);

    if (task) {
      control.setValue(task.status, { emitEvent: false });
    }

    control.valueChanges.subscribe(status => this.onStatusChanged(status));

    return control;
  }

  onStatusChanged(status: TaskStatus): void {
    if (!this.task) { return; }

    const previousStatus = this.task.status;

    this.taskBusinessService.updateStatusOfTask(this.task.id, status)
      .then((task) => {
        this.task = task;
      })
      .catch(() => {
        this.controlTaskStatus.setValue(previousStatus, { emitEvent: false });
      });
  }

  getTooltipView(): string {
    switch(this.task.alignmentType) {
      case TaskAlignmentType.TODO :
        return TasksMessages.VIEW_TODO;
      case TaskAlignmentType.ONE_TO_ONE :
        return TasksMessages.VIEW_ACTION_POINT;
      case TaskAlignmentType.MILESTONE :
        return TasksMessages.VIEW_MILESTONE;
      case TaskAlignmentType.GOAL :
      default :
        return TasksMessages.VIEW_TASK;
    }
  }

  getTooltipEdit(): string {
    switch(this.task.alignmentType) {
      case TaskAlignmentType.TODO :
        return TasksMessages.EDIT_TODO;
      case TaskAlignmentType.ONE_TO_ONE :
        return TasksMessages.EDIT_ACTION_POINT;
      case TaskAlignmentType.MILESTONE :
        return TasksMessages.EDIT_MILESTONE;
      case TaskAlignmentType.GOAL :
      default :
        return TasksMessages.EDIT_TASK;
    }
  }

  getTooltipArchive(): string {
    switch(this.task.alignmentType) {
      case TaskAlignmentType.TODO :
        return TasksMessages.ARCHIVE_TODO;
      case TaskAlignmentType.ONE_TO_ONE :
        return TasksMessages.ARCHIVE_ACTION_POINT;
      case TaskAlignmentType.MILESTONE :
        return TasksMessages.ARCHIVE_MILESTONE;
      case TaskAlignmentType.GOAL :
      default :
        return TasksMessages.ARCHIVE_TASK;
    }
  }
}
