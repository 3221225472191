import {Component, Input} from '@angular/core';
import {CompanySetUpStepType} from '@app/models/company/company-set-up-steps/company-set-up-step-type.enum';
import {CompanySetUpStep} from '@app/models/company/company-set-up-steps/company-set-up-step.model';
import {Globals} from '@app/shared/globals/globals';
import {SetUpStepUtils} from '@app/shared/utils/setupstep.utils';

@Component({
  selector: 'app-setup-steps-remaining-display',
  templateUrl: './setup-steps-remaining-display.component.html',
  styleUrls: ['./setup-steps-remaining-display.component.scss']
})
export class SetupStepsRemainingDisplayComponent {

  @Input() stepTypes: CompanySetUpStepType[];

  get stepsRelevant(): CompanySetUpStep[] {
    if (!this.globals) {
      return [];
    }
    if (!this.globals.company) {
      return [];
    }
    if (this.globals.company.setUpComplete) {
      return [];
    }
    if (!this.globals.company.setUpSteps) {
      return [];
    }

    return this.globals.company.setUpSteps.filter(s => this.stepTypes.includes(s.type));
  }

  get stepsIncomplete(): CompanySetUpStep[] {
    return this.stepsRelevant.filter(s => !this.setUpStepUtils.checkIsStepComplete(s));
  }

  constructor(
      public globals: Globals,
      private setUpStepUtils: SetUpStepUtils
  ) {
    this.stepTypes = [];
  }
}
