import { ISODateString } from '@app/shared/utils/date-string.util';
import { Site } from '@app/models/site.model';
import { PollQuestionAnswered } from 'app/models/poll/poll-question-answered.model';
import moment from 'moment';
import { Department } from '@models/department.model';

export interface PollResponseServerSide {
  id: number;
  pollQuestionAnswers: Array<PollQuestionAnswered>;
  startedDateTime: ISODateString;
  completedDateTime: ISODateString;
  officeLocation: Site | null;
  organisationalUnit: Department | null;
}

export class PollResponse {
  id: number;
  pollQuestionAnswers: Array<PollQuestionAnswered>;
  startedDateTime: Date;
  completedDateTime: Date;
  officeLocation: Site | null;
  organisationalUnit: Department | null;

  constructor(pollResponseServerSide: PollResponseServerSide) {
    this.id = pollResponseServerSide.id;
    this.pollQuestionAnswers = pollResponseServerSide.pollQuestionAnswers;
    this.startedDateTime = moment.utc(pollResponseServerSide.startedDateTime).toDate();
    this.completedDateTime = moment.utc(pollResponseServerSide.completedDateTime).toDate();
    this.officeLocation = pollResponseServerSide.officeLocation;
    this.organisationalUnit = pollResponseServerSide.organisationalUnit;
  }
}
