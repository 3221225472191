import { Component, Input, OnChanges } from '@angular/core';
import { Goal } from 'app/models/goals/goal.model';
import { User } from 'app/models/user/user.model';
import { Globals } from 'app/shared/globals/globals';
import { CompanyFeatures } from '@app/models/company-features.model';
import { GoalsAPIService } from 'app/shared/api/goals.api.service';
import { IState } from '@app/models/state/state.model';
import { Router } from '@angular/router';

@Component({
  selector: 'app-profile-goals',
  templateUrl: './profile-goals.component.html',
  styleUrls: ['./profile-goals.component.scss']
})
export class ProfileGoalsComponent implements OnChanges {
  @Input() userProfile: User;

  public readonly eFeature = CompanyFeatures;

  goals: Goal[];
  state: IState;
  
  constructor(
    public globals: Globals,
    private goalAPIService: GoalsAPIService,
    private router: Router
  ) {
    this.state = {
      loading: true,
      error: false,
      errorMessage: ''
    };
    this.goals = [];
  }

  ngOnChanges(): void {
    this.goalAPIService.getPublicPersonalGoals(this.userProfile.id).subscribe(response => {
      this.goals = Goal.getGoalArrayCompletionPercentage(response);
      this.state.loading = false;
    });
  }

  startCreateGoal(): void {
    this.router.navigate(
      ['/goals'],
      {
        queryParams: {
          action: 'create'
        }
      }
    ).then();
  }
}
