import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-table-dropdown-toggle',
  templateUrl: './table-dropdown-toggle.component.html',
  styleUrls: ['./table-dropdown-toggle.component.scss']
})
export class TableDropdownToggleComponent implements OnInit {

  @Input() opened: boolean;
  @Input() size: string;

  constructor() {
    this.opened = false;
    this.size = '50px';
  }

  ngOnInit(): void {
  }

}
