import { Globals } from '@app/shared/globals/globals';
import { ISODateString } from '@app/shared/utils/date-string.util';
import { DateService } from '@app/shared/utils/date.service';
import { Address } from 'app/models/address.model';
import { CompanyConfiguration } from './company/company-configuration/company-configuration.model';
import { CompanySetUpStep, CompanySetUpStepServerSide } from './company/company-set-up-steps/company-set-up-step.model';
import { CompanyTrial, CompanyTrialServerSide } from './company/company-trial.model';
import { CompanyFeatures } from './company-features.model';
import { Site } from './site.model';

const globals = new Globals();
const dateService = new DateService(globals);

class CompanyFilter {
  /**
   * Filter a company by type
   * @param customers
   * @param type
   */
  byType(companies: Array<Company>, type: 'full-or-trial' | 'full' | 'trial' | 'sandbox' | null): Array<Company> {
    if (type === null) {
      return companies;
    }

    switch (type) {
      case 'full-or-trial': {
        return companies
          .filter(company => company.state === CompanyState.FULL);
      }
      case 'full': {
        return companies
          .filter(company => company.state === CompanyState.FULL)
          .filter(company => company.trial === null);
      }

      case 'trial': {
        return companies
          .filter(company => company.state === CompanyState.FULL)
          .filter(company => company.trial !== null);
      }

      case 'sandbox': {
        return companies
          .filter(company => company.state === CompanyState.DEMO);
      }
    }
  }
}

export interface CompanyServerSide {
  id: number;
  version: number;
  address: Address;
  name: string;
  officeLocations: Array<Site>;
  features: Array<CompanyFeature>;
  state: CompanyState;
  trial: CompanyTrialServerSide | null;
  fiscalYear: ISODateString;
  setUpSteps: CompanySetUpStepServerSide[];
  setUpComplete: boolean;
  featureLocked: boolean;
  loginEnabled: boolean;
  configuration: CompanyConfiguration;
}

export class Company {
  static Filter: CompanyFilter = new CompanyFilter();

  id: number;
  version: number;
  address: Address;
  name: string;
  officeLocations: Array<Site>;
  features: Array<CompanyFeature>;
  state: CompanyState;
  trial: CompanyTrial | null;
  fiscalYear: Date;
  setUpSteps: CompanySetUpStep[];
  setUpComplete: boolean;
  featureLocked: boolean;
  loginEnabled: boolean;
  configuration: CompanyConfiguration;

  constructor(companyServerSide: CompanyServerSide) {
    this.id = companyServerSide.id;
    this.version = companyServerSide.version;
    this.address = companyServerSide.address;
    this.name = companyServerSide.name;
    this.officeLocations = companyServerSide.officeLocations;
    this.features = companyServerSide.features;
    this.state = companyServerSide.state;
    this.trial = (companyServerSide.trial === null) ? null : new CompanyTrial(companyServerSide.trial);
    this.fiscalYear = dateService.parseFiscalYear(new Date(companyServerSide.fiscalYear));
    this.setUpSteps = companyServerSide.setUpSteps
      .map(setUpStepServerSide => new CompanySetUpStep(setUpStepServerSide))
      .sort((a, b) => a.stepOrder - b.stepOrder);
    this.setUpComplete = companyServerSide.setUpComplete;
    this.featureLocked = companyServerSide.featureLocked;
    this.loginEnabled = companyServerSide.loginEnabled;
    this.configuration = companyServerSide.configuration;
  }
}

export class AllCompaniesResponse {
  content: Array<Company>;
  last: boolean;
  totalPages: number;
  totalElements: number;
  size: number;
  number: number;
  sort: null;
  numberOfElements: number;
  first: true;
}

export interface CompanyFeature {
  id: number;
  feature: CompanyFeatures;
  enabled: boolean;
}

export enum CompanyState {
  FULL = 'FULL',
  NEW = 'NEW',
  ARCHIVED = 'ARCHIVED',
  DEMO = 'DEMO',
  ARCHIVED_DEMO = 'ARCHIVED_DEMO'
}

export class UpdateCompanyFiscalYearDto {
  constructor(
    private fiscalYear: Date
  ) { }
}
