export interface LikertDistOverview {
    type: LikertDistType;
    average: number;
    entities: LikertDistItem[]
}

export interface LikertDistItem {
    entityID: number;
    name: string;
    scores: LikertDistScores;
    totalResponses: number;
}

export interface LikertDistScores {
    STRONGLY_AGREE: number;
    AGREE: number;
    NEUTRAL: number;
    DISAGREE: number;
    STRONGLY_DISAGREE: number;
}

export enum LikertDistType {
    DEPARTMENT = 'DEPARTMENT',
    SITE = 'SITE',
    TENURE = 'TENURE',
    TEMPLATE = 'TEMPLATE'
}
