import { DOCUMENT } from '@angular/common';
import { Component, ElementRef, EventEmitter, Inject, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { OneToOneMeetingFile } from '@app/domain/one_to_one/model/one-to-one-meeting-file.model';
import { OneToOneMeeting } from '@app/domain/one_to_one/model/one-to-one-meeting.model';
import { SwalUtils } from '@app/shared/utils/swal.utils';
import { OneToOneMessages } from '@app/domain/one_to_one/locale/one-to-one.messages';
import { CommonMessages } from '@app/constants/common.messages';
import { OneToOneScheduleDetailsView } from '../../model/one-to-one-schedule.model';
import { IState } from '@app/models/state/state.model';
import { OneToOneBusinessService } from '../../service/one-to-one-business.service';
import { ButtonType } from '@app/shared/components/inputs/button/button.component';
import { OneToOneMeetingStatus } from '../../model/one-to-one-meeting-status.model';
import { TranslateService } from '@ngx-translate/core';

interface PageState extends IState {
  uploadingFile: boolean;
  filesDeleting: string[]
}

@Component({
  selector: 'app-meeting-files',
  templateUrl: './meeting-files.component.html',
  styleUrls: ['./meeting-files.component.scss'],
})
export class MeetingFilesComponent implements OnChanges {
  public readonly eOneToOneMessages = OneToOneMessages;
  public readonly eCommonMessages = CommonMessages;
  public readonly eButtonType = ButtonType;
  public readonly MAX_FILE_SIZE = 1024 * 1024 * 50; // Max file size in bytes (50MB)

  @Input() schedule: OneToOneScheduleDetailsView;
  @Input() meeting: OneToOneMeeting;

  @Output() onMeetingUpdated: EventEmitter<OneToOneMeeting>;

  @ViewChild('filePicker') filePicker?: ElementRef;
  
  state: PageState;
  meetingFiles: OneToOneMeetingFile[];

  get canAddFiles(): boolean {
    if (!this.meeting) { return false; }
    if (this.meeting.status === OneToOneMeetingStatus.MISSED) { return false; }
    if (this.meeting.status === OneToOneMeetingStatus.CANCELLED) { return false; }
    return true;
  }

  constructor(
    @Inject(DOCUMENT) public document: Document,
    private oneToOneBusinessService: OneToOneBusinessService,
    private translateService: TranslateService,
    private swalUtils: SwalUtils
  ) {
    this.schedule = undefined;
    this.meeting = undefined;

    this.state = {
      loading: true,
      error: false,
      errorMessage: '',
      uploadingFile: false,
      filesDeleting: []
    };

    this.meetingFiles = [];
    this.onMeetingUpdated = new EventEmitter<OneToOneMeeting>();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.meeting) {
      this.onMeetingChanged(changes.meeting.currentValue);
    }
  }

  onMeetingChanged(meeting: OneToOneMeeting): void {
    if (meeting) {
      this.getData();
    }
  }

  getData(): void {
    this.state.loading = true;
    this.oneToOneBusinessService
      .getMeetingFiles(this.schedule.id, this.meeting.id)
      .toPromise()
      .then(files => {
        this.meetingFiles = files;
        this.state.loading = false;
      });
  }

  onAddFile($event: Event): void {
    const target = $event.target as HTMLInputElement;
    const file = target.files[0] as File;

    if (file.size > this.MAX_FILE_SIZE) {
      this.swalUtils
        .displayErrorSwal({
          title: this.translateService.instant(CommonMessages.FILE_SIZE_LESS_THAN_MB, { size: 50 }),
        })
        .then();
      return;
    }

    this.state.uploadingFile = true;
    this.oneToOneBusinessService
      .addMeetingFile(this.schedule.id, this.meeting.id, file)
      .then(() => {
        this.getData();
      })
      .finally(() => {
        this.state.uploadingFile = false;
      });
  }

  openFilePicker(): void {
    if (this.state.uploadingFile) { return; }
    if (!this.filePicker) { return; }
    this.filePicker.nativeElement.click();
  }

  startDownloadingFile(file: OneToOneMeetingFile): void {
    if (!file) { return; }
    if (!file.location) { return; }
    window.open(file.location);
  }

  startDeletingFile(file: OneToOneMeetingFile): void {
    this.swalUtils.displayWarningConfirmationSwal({
      text: OneToOneMessages.CONFIRMATION_FILE_DELETE
    }).then(result => {
      if (result.isConfirmed) {
        this.deleteFile(file);
      }
    });
  }

  deleteFile(file: OneToOneMeetingFile): void {
    this.state.filesDeleting.push(file.name);

    this.oneToOneBusinessService
      .deleteMeetingFile(this.schedule.id, this.meeting.id, file.name)
      .then(() => {
        this.getData();
      })
      .finally(() => {
        this.state.filesDeleting = this.state.filesDeleting.filter(f => f !== file.name);
      });
  }
}
