import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Interest } from '@app/domain/interest/model/interest.model';
import { SkillsBusinessService } from '@app/domain/settings/service/skills-business.service';
import { SkillRatingAPIService } from '@app/domain/skill_rating/api/skill-rating-api.service';
import { SkillRatingConfiguration } from '@app/domain/skill_rating/model/skill-rating-configuration.model';
import { SkillRatingType } from '@app/domain/skill_rating/model/skill-rating-type.model';
import { SkillRating } from '@app/domain/skill_rating/model/skill-rating.model';
import { TerminologyEntity } from '@app/domain/terminology/model/terminology-entity.enum';
import { CompanyFeatures } from '@app/models/company-features.model';
import { IState } from '@app/models/state/state.model';
import { Globals } from '@app/shared/globals/globals';
import { PaginationNewComponent } from '@app/shared/pagination/pagination-new/pagination-new.component';
import { forkJoin } from 'rxjs';

interface PageSkill extends Interest {
  skillRating: SkillRating;
}

type TabEntity = PageSkill;

@Component({
  selector: 'app-information-sidebar-skills',
  templateUrl: './information-sidebar-skills.component.html',
  styleUrls: ['./information-sidebar-skills.component.scss']
})
export class InformationSidebarSkillsComponent implements OnInit {

  public readonly eTerminologyEntity = TerminologyEntity;
  public readonly eCompanyFeatures = CompanyFeatures;
  
  @ViewChild('pagination') pagination?: PaginationNewComponent;

  @Input() userIds: number[];
  @Input() userIdsManaging: number[];

  state: IState;
  data: {
    master: TabEntity[];
    filtered: TabEntity[];
    display: TabEntity[];
  };

  skillRatings: SkillRating[];
  skillRatingConfiguration: SkillRatingConfiguration;
  isManagingSingleUser: boolean;

  constructor(
    public globals: Globals,
    private skillsBusinessService: SkillsBusinessService,
    private skillRatingAPIService: SkillRatingAPIService
  ) {
    this.userIds = [];
    this.skillRatings = [];
    this.userIdsManaging = [];

    this.isManagingSingleUser = false;

    this.state = {
      loading: true,
      error: false,
      errorMessage: ''
    };

    this.data = {
      master: [],
      filtered: [],
      display: []
    };
  }

  ngOnInit(): void {
    this.getData();
  }

  getData(): void {
    if (!this.userIds) { return; }
    if (this.userIds.length === 0) { return; }

    if (this.userIds.length > 1) {
      this.doError('Cannot display skills for multiple users');
      return;
    }

    this.isManagingSingleUser = this.userIdsManaging.includes(this.userIds[0]);
    if (this.globals.hasFeature(CompanyFeatures.SKILL_RATING)) {
      this.getSkillRatingData(this.userIds[0])
        .then(() => this.getSkillsDataForUser(this.userIds[0]));
    } else {
      this.getSkillsDataForUser(this.userIds[0]);
    }
  }

  doError(message: string): void {
    this.state.loading = false;
    this.state.error = true;
    this.state.errorMessage = message;
  }

  getSkillRatingData(userId: number): Promise<void> {
    return forkJoin([
      this.skillRatingAPIService.getForUser(userId),
      this.skillRatingAPIService.getConfiguration()
    ]).toPromise()
      .then(([skillRatings, skillRatingConfiguration]) => {
        this.skillRatings = skillRatings;
        this.skillRatingConfiguration = skillRatingConfiguration;
      });
  }

  getSkillsDataForUser(userId: number): Promise<void> {
    return this.skillsBusinessService.getSkillsForUserId(userId)
      .toPromise()
      .then(skills => {
        let dataParsed = this.parseSkillToPageSkillMultiple(skills, this.skillRatings);
        dataParsed = this.limitRatingsToScale(dataParsed, this.skillRatingConfiguration);
        dataParsed = this.sortData(dataParsed);
        this.populateData(dataParsed);

        this.state.loading = false;
      });
  }

  // getAllSkillsData(): Promise<void> {
  //   return this.skillsBusinessService.getCompetencies(null, null, null)
  //     .toPromise()
  //     .then(skills => {
  //       let dataParsed = this.parseSkillToPageSkillMultiple(skills, this.skillRatings);
  //       dataParsed = this.limitRatingsToScale(dataParsed, this.skillRatingConfiguration);
  //       dataParsed = this.sortData(dataParsed);
  //       this.populateData(dataParsed);

  //       this.state.loading = false;
  //     });
  // }

  sortData(data: TabEntity[]): TabEntity[] {
    return data
      // Order by name
      .sort((a, b) => a.name.localeCompare(b.name))
      // Order by score
      .sort(this.sortBySkillRatingScore);
  }

  limitRatingsToScale(items: PageSkill[], skillRatingConfiguration: SkillRatingConfiguration): PageSkill[] {
    return items.map(item => {
      if (!item.skillRating) return item;

      if (item.skillRating.scoreValue > (skillRatingConfiguration.scoreCount - 1)) {
        item.skillRating.scoreValue = (skillRatingConfiguration.scoreCount - 1);
      }

      return item;
    });
  }

  sortBySkillRatingScore(a: PageSkill, b: PageSkill): number {
    if (!a.skillRating && !b.skillRating) return 0;
    if (!a.skillRating) return 1;
    if (!b.skillRating) return -1;

    return b.skillRating.scoreValue - a.skillRating.scoreValue;
  }

  populateData(data?: TabEntity[]): void {
    if (!data) { data = this.data.master; }
    this.data.master = data;
    this.data.filtered = data;

    // this.universalFilterData.searchProps = ['name'];
    // this.universalFilterData.filterOptions = data.map(i => this.getFilterOptionsForBrowseRole(i));

    this.refreshPagination();
  }

  refreshPagination(): void {
    if (this.pagination) {
      this.pagination.update();
    }
  }

  parseSkillToPageSkillMultiple(data: Interest[], ratings: SkillRating[]): PageSkill[] {
    return data.map(skill => this.parseSkillToPageSkill(skill, ratings));
  }

  parseSkillToPageSkill(skill: Interest, ratings: SkillRating[]): PageSkill {
    const output: PageSkill = skill as PageSkill;
  
    if (output) {
      output.skillRating = ratings.find(rating => rating.skillRatingType === SkillRatingType.SKILL && rating.targetEntityId === skill.id);
    }
  
    return output;
  }

}
