import { Component, Input, OnInit } from '@angular/core';
import { Globals } from '@app/shared/globals/globals';

interface ChartData {
  value: string; // Raw value
  text: string; // Formatted value
  label: string; // Strongly Disagree, Neutral, Agree, etc.
  width?: number; // Relative width as percentage
  percentOfTotal?: string;
  widthRounded?: string; // Width but rounded
  color?: string; // Bar color
  colorAlt?: string; // Background color
  styleClass?: string; // Class used
}

interface WidthData {
  value: string;
  text: string;
  label: string;
  percentOfTotal: string;
}

const MIN_BAR_WIDTH = 0.9;

@Component({
  selector: 'app-likert-reporting-distribuation-chart',
  templateUrl: './likert-reporting-distribuation-chart.component.html',
  styleUrls: ['./likert-reporting-distribuation-chart.component.scss']
})
export class LikertReportingDistribuationChartComponent implements OnInit {
  @Input() data?: WidthData[];
  @Input() numberOfResponses!: number;

  dataParsed: ChartData[];

  constructor(
    public globals: Globals
  ) {
    this.dataParsed = [];
    this.numberOfResponses = 0;
  }

  ngOnInit() {
    if (!this.numberOfResponses) {
      this.numberOfResponses = 0;
    }

    this.dataParsed = this.parseData(this.data);
  }

  parseData(widths?: WidthData[]): ChartData[] {
    if (!widths) {
      return [];
    }

    const dataUsing = this.parseWidthsToChartData(widths);

    return dataUsing;
  }

  parseWidthsToChartData(widths: WidthData[]): ChartData[] {
    // const maxValue = this.getChartMaxValue(dataUsing);
    const emptyCells = this.getChartNoResponses(widths);

    return widths.map(d => {
      const width = this.getChartWidth(+d.value, this.numberOfResponses, emptyCells)
      const output: ChartData = {
        value: d.value, // Raw value
        text: d.text, // Formatted value
        label: d.label, // Strongly Disagree, Neutral, Agree, etc.
        percentOfTotal: ((d.percentOfTotal ? +d.percentOfTotal : 0).toFixed(2)),
        width: width,
        widthRounded: this.getChartWidthRounded(width),
        color: this.getChartColor(d.label),
        colorAlt: this.getChartColorAlt(d.label),
        styleClass: this.getStyleClass(d.label)
      }
      return output;
    })



  }

  getChartMaxValue(data: ChartData[]): number {
    let maxValue = 0;

    data.forEach(d => {
      if (+d.value > maxValue) {
        maxValue = +d.value;
      }
    });

    return maxValue;
  }

  getChartNoResponses(chartData: any[]): number {
    let noResponses = 0;

    chartData.forEach(d => {
      if (d.value === '0') {
        noResponses++;
      }
    });

    return noResponses;
  }

  getChartColor(label: string): string {
    switch (label) {
      case 'Strongly Agree':
        return '#008168';
      case 'Agree':
        return '#30747F';
      case 'Neutral':
        return '#FFC200';
      case 'Disagree':
        return '#FB946E';
      case 'Strongly Disagree':
        return '#d0352c';
      case 'No Answer':
        return 'lightgrey';
      default:
        return '#AAAAAA';
    }
  }

  getChartColorAlt(label: string): string {
    switch (label) {
      case 'Strongly Agree':
        return '#D8ECE9';
      case 'Agree':
        return '#D8ECE9';
      case 'Neutral':
        return '#FEF5E2';
      case 'Disagree':
        return '#FFEEF2';
      case 'Strongly Disagree':
        return '#FFEEF2';
      case 'No Answer':
        return '#FFEEF2';
      default:
        return '#EEEEEE';
    }
  }

  getStyleClass(label: string): string {
    switch (label) {
      case 'Strongly Agree':
        return 'bar-strongly-agree';
      case 'Agree':
        return 'bar-agree';
      case 'Neutral':
        return 'bar-neutral';
      case 'Disagree':
        return 'bar-disagree';
      case 'Strongly Disagree':
        return 'bar-strongly-disagree';
      case 'No Answer':
        return 'bar-no-answer';
      default:
        return '';
    }
  }

  getChartWidth(value: number, totalAnswers: number, emptyCells: number): number {
    if (totalAnswers === 0) {
      return 20;
    }

    if (value === 0) {
      return MIN_BAR_WIDTH;
    }

    // Get empty cell offset
    const emptyCellOffset = (MIN_BAR_WIDTH * emptyCells); // 0.9 * 3

    // Get chart width minus offset
    const chartWidthRemaining = (100 - emptyCellOffset);

    // Get value ratio to totalAnswers as deciaml
    const valueDecimal = (value / totalAnswers);

    // Get width
    const finalWidth = (valueDecimal * chartWidthRemaining);
    return finalWidth;
  }

  getChartWidthRounded(width: number): string {
    return width.toFixed(0);
  }
}
