import { EvaluationCycleModule } from '@app/models/evaluation/evaluation-cycle-module.model';
import { EvaluationModuleType } from '@app/models/evaluation/evaluation-module-type.model';
import { EvaluationCycle } from '@app/models/evaluation/evaluation-cycle.model';
import { EvaluationCycleState } from '@app/models/evaluation/evaluation-cycle-state';
import { SurveyQuestionType } from '@app/models/survey-question-type.enum';
import { EvaluationCycleQuestion } from '@app/models/evaluation/evaluation-cycle-question.model';
import { QuestionBankQuestion } from '@app/models/evaluation/question-bank-question.model';
import { EvaluationUser, EvaluationUserMin } from '@app/models/evaluation/evaluation-user.model';
import { EvaluationFeedback, EvaluationFeedbackMin } from '@app/models/evaluation/evaluation-feedback.model';
import { EvaluationFeedbackRequestType } from '@app/models/evaluation/evaluation-feedback-request-type.model';

export class EvaluationUtils {
  static readonly AssessmentQuestionTypes = [SurveyQuestionType.ONE_TO_FIVE, SurveyQuestionType.ONE_TO_THREE, SurveyQuestionType.ONE_TO_FOUR];

  static getSortedModules = (evaluationCycleModules: EvaluationCycleModule[]): EvaluationCycleModule[] => {
    if (evaluationCycleModules && evaluationCycleModules.length > 0) {
      return evaluationCycleModules.sort((a, b) => a.stepNumber - b.stepNumber);
    } else {
      return [];
    }
  };

  static getTitleForModuleType = (evaluationModuleType: EvaluationModuleType): string => {
    switch (evaluationModuleType) {
      case EvaluationModuleType.SELF_REFLECTION:
        return 'Self Reflections';
      case EvaluationModuleType.PEER_REVIEW:
        return 'Peer Reviews';
      case EvaluationModuleType.MANAGER_REVIEW:
        return 'Manager Reviews';
      case EvaluationModuleType.UPWARD_REVIEW:
        return 'Upward Reviews';
    }
  };

  static cycleHasModule = (evaluationCycle: EvaluationCycle, evaluationModuleType: EvaluationModuleType): boolean => {
    return evaluationCycle.modules.map(m => m.evaluationModuleType).includes(evaluationModuleType);
  };

  static getIconForModuleType = (evaluationModuleType: EvaluationModuleType): string => {
    switch (evaluationModuleType) {
      case EvaluationModuleType.SELF_REFLECTION:
        return 'fa-id-badge';
      case EvaluationModuleType.PEER_REVIEW:
        return 'fa-circle-notch';
      case EvaluationModuleType.MANAGER_REVIEW:
        return 'fa-user-group';
      case EvaluationModuleType.UPWARD_REVIEW:
        return 'fa-up-to-line';
    }
  };

  static getStatus = (evaluationCycle: EvaluationCycle): string => {
    return EvaluationUtils.getStatusForState(evaluationCycle.state);
  };

  static getStatusForState = (evaluationCycleState: EvaluationCycleState): string => {
    switch (evaluationCycleState) {
      case EvaluationCycleState.ACTIVATED:
        return 'Active';
      case EvaluationCycleState.CLOSED:
        return 'Completed';
      case EvaluationCycleState.DRAFT:
        return 'Draft';
      case EvaluationCycleState.SCHEDULED:
        return 'Scheduled';
      default:
        return 'Unknown';
    }
  };

  static getAssessmentGroupValue = (questions: EvaluationCycleQuestion[]): (SurveyQuestionType | null) => {
    // FIXME: This throws a weird error - Think it's something to do with the difference between Object[] and Array<Object>
    // const assessmentQuestion = questions.find(q => (EvaluationUtils.AssessmentQuestions.includes(q.surveyQuestionType)));
    // if (assessmentQuestion) {
    //     return assessmentQuestion.surveyQuestionType;
    // }

    // Alt method
    for (let index = 0; index < questions.length; index++) {
      const q = questions[index];
      if (q.assessmentQuestion) {
        return q.questionBankQuestion.surveyQuestionType;
      }
    }

    return null;
  };

  static getAssessmentGroupFormValueForQuestions = (questions: EvaluationCycleQuestion[]): SurveyQuestionType => {
    const agQuestion = questions.find(x => x.assessmentQuestion);
    if (agQuestion) {
      return agQuestion.questionBankQuestion.surveyQuestionType;
    }
    return undefined!;
  };

  static getAssessmentGroupFormValueForQuestionsMultiple = (...questionLists: EvaluationCycleQuestion[][]): SurveyQuestionType => {
    // Returns the first assessment question type found
    for (let li = 0; li < questionLists.length; li++) {
      const questions = questionLists[li];

      const agQuestion = questions.find(x => x.assessmentQuestion);
      if (agQuestion) {
        return agQuestion.questionBankQuestion.surveyQuestionType;
      }
    }

    return undefined!;
  };

  static createEvaluationCycleQuestionFromQuestionBankQuestion = (questionBankQuestion: QuestionBankQuestion, orderIndex: number): EvaluationCycleQuestion => {
    return {
      id: null!,
      questionBankQuestion,
      questionText: questionBankQuestion.questionText,
      orderIndex,
      assessmentQuestion: questionBankQuestion.assessmentQuestion
    };
  };

  static createEvaluationCycleQuestionsFromQuestionBankQuestions = (questionBankQuestions: QuestionBankQuestion[]): EvaluationCycleQuestion[] => {
    const evaluationCycleQuestions: EvaluationCycleQuestion[] = [];
    for (let i = 0; i < questionBankQuestions.length; i++) {
      evaluationCycleQuestions.push({
        id: null!,
        questionBankQuestion: questionBankQuestions[i],
        orderIndex: i,
        questionText: questionBankQuestions[i].questionText,
        assessmentQuestion: questionBankQuestions[i].assessmentQuestion
      });
    }
    return evaluationCycleQuestions.sort((a, b) => a.orderIndex - b.orderIndex);
  };

  static getManagerReviewResponseFromEvaluationUser(evaluationUser: EvaluationUser): EvaluationFeedback {
    return evaluationUser.responsesAboutUser?.find(r => r.request.type === EvaluationFeedbackRequestType.MANAGER_REVIEW);
  }

  static getManagerReviewResponseFromEvaluationUserMin(evaluationUserMin: EvaluationUserMin): EvaluationFeedbackMin {
    return evaluationUserMin.responsesAboutUser?.find(r => r.request.type === EvaluationFeedbackRequestType.MANAGER_REVIEW);
  }
}
