import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ProfileDetailsDto } from '../../model/profile-details.model';
import { IProfileDetailsAPI } from './profile-details-api.model';

@Injectable()
export class ProfileDetailsAPIService implements IProfileDetailsAPI {
  private readonly BASE_URL: string = 'api/profile-details';

  constructor(
      private http: HttpClient
  ) { }

  get(): Observable<ProfileDetailsDto> {
    const url = `${this.BASE_URL}`;
    return this.http.get<ProfileDetailsDto>(url);
  }

  update(updatedDetails: ProfileDetailsDto): Observable<ProfileDetailsDto> {
    const url = `${this.BASE_URL}`;
    return this.http.put<ProfileDetailsDto>(url, updatedDetails);
  }
}
