import { EvaluationFeedback } from '@app/models/evaluation/evaluation-feedback.model';
import { mockEvaluationCycles } from '@app/mock/api/data/mockEvaluationCycles';
import { mockUsers } from '@app/mock/api/data/mockUsers';
import { mapEvaluationFeedbackAnswers } from '@app/mock/api/mock-helpers';
import { mockEvaluationFeedbackRequests } from 'app/mock/api/data/mockEvaluationFeedbackRequests';

export const mockEvaluationFeedback: EvaluationFeedback[] = [
    {
        id: 1,
        cycleId: mockEvaluationCycles[0].id,
        authorUser: mockUsers[0],
        authorUserId: mockUsers[0].id,
        subjectUser: mockUsers[2],
        subjectUserId: mockUsers[2].id,
        lastEditTimestamp: new Date(),
        request: mockEvaluationFeedbackRequests[0],
        questionsAnswered: mapEvaluationFeedbackAnswers(mockEvaluationCycles[0].managerReviewQuestions)
    },
    {
        id: 2,
        cycleId: mockEvaluationCycles[1].id,
        authorUser: mockUsers[0],
        authorUserId: mockUsers[0].id,
        subjectUser: mockUsers[0],
        subjectUserId: mockUsers[0].id,
        lastEditTimestamp: new Date(),
        request: mockEvaluationFeedbackRequests[1],
        questionsAnswered: mapEvaluationFeedbackAnswers(mockEvaluationCycles[0].managerReviewQuestions)
    },
];