<div class="container-create-sentiment-scale">
  <router-outlet></router-outlet>
  <ng-container *ngIf="activatedRoute.children.length === 0">
    <div class="card">
      <app-state [state]="state">
        <ng-container *ngIf="!state.loading && !state.error">
          <div class="content">
            <ng-container *ngIf="!isEditing; else templateHeaderIsEditing;">
              <app-header [title]="eSentimentScaleMessages.CREATE_SENTIMENT_SCALE" [description]="eSentimentScaleMessages.CREATE_SENTIMENT_SCALE_DESCRIPTION"></app-header>
            </ng-container>
            <ng-template #templateHeaderIsEditing>
              <app-header [title]="eSentimentScaleMessages.EDIT_SENTIMENT_SCALE" [description]="eSentimentScaleMessages.EDIT_SENTIMENT_SCALE_DESCRIPTION"></app-header>
            </ng-template>

            <hr class="row m-top-0"/>

            <ng-container *ngIf="!isEditing">
              <div class="card card-yellow">
                <div class="content">
                  <h4 class="standard-heading">{{ eSentimentScaleMessages.USE_TEMPLATE_PROMPT | translate }}</h4>
                  <div class="standard-description">{{ eSentimentScaleMessages.USE_TEMPLATE_DESCRIPTION | translate }}</div>
                  <div class="text-right p-top-5">
                    <app-button (onClick)="showModal()">{{ eSentimentScaleMessages.SHOW_TEMPLATES | translate }}</app-button>
                  </div>
                </div>
              </div>

              <hr class="row m-top-0"/>
            </ng-container>
            
            <div class="area-form-controls">
              <div class="frankli-form-group" [class.frankli-form-group-filled]="controlName.value" [class.frankli-form-group-invalid]="state.submitted && controlName.invalid">
                <label for="name">{{ eSentimentScaleMessages.NAME | translate }}</label>
                <input type="text" id="name" class="form-control" [formControl]="controlName" />
                <div class="frankli-form-group-alert">
                  <ng-container *ngIf="state.submitted">
                    <span *ngIf="controlName.hasError('required')">{{ eCommonMessages.FORM_REQUIRED }}</span>
                    <span *ngIf="controlName.hasError('maxlength')" translate [translateParams]="{ maxValue: 255 }">{{eCommonMessages.FORM_MAX_CHARACTER_VALUE}}</span>
                  </ng-container>
                </div>
              </div>

              <div class="frankli-form-group frankli-form-group-filled" [class.frankli-form-group-invalid]="state.submitted && controlDescription.invalid">
                <label for="description">{{ eSentimentScaleMessages.DESCRIPTION | translate }}</label>
                <textarea id="description" class="form-control noresize" [formControl]="controlDescription"></textarea>
                <div class="frankli-form-group-alert">
                  <ng-container *ngIf="state.submitted">
                    <span *ngIf="controlDescription.hasError('required')">{{ eCommonMessages.FORM_REQUIRED }}</span>
                    <span *ngIf="controlDescription.hasError('maxlength')" translate [translateParams]="{ maxValue: 1000 }">{{eCommonMessages.FORM_MAX_CHARACTER_VALUE}}</span>
                  </ng-container>
                </div>
              </div>

              <div class="frankli-form-group frankli-form-group-filled">
                <label for="show-numbers">{{ eSentimentScaleMessages.SHOW_NUMBERS | translate }}</label>
                <div class="standard-description">{{ eSentimentScaleMessages.SHOW_NUMBERS_PROMPT | translate }}</div>
                <div class="p-top-5">
                  <app-slider-input [formControl]="controlShowNumbers"></app-slider-input>
                </div>
                <div class="frankli-form-group-alert"></div>
              </div>

              <div class="area-sentiment-scale-options">
                <ng-container *ngIf="controlOptionsArray.length > 0">
                  <div class="area-sentiment-scale-guide">
                    <span class="fal fa-fw fa-thumbs-up" [tooltip]="'Positive options should be placed at the top and have a lower score value'" placement="right"></span>
                    <span class="fal fa-fw fa-thumbs-down" [tooltip]="'Negative options should be placed at the bottom and have a higher score value'" placement="right"></span>
                  </div>
                </ng-container>

                <div class="area-sentiment-scale-options-outer">
                  <ng-container *ngIf="controlOptionsArray.length > 0; else templateNoOptions;">
                    <div class="sentiment-scale-option-list" cdkDropList #questionsList="cdkDropList" [cdkDropListData]="controlOptionsArray" (cdkDropListDropped)="dropDraggableOption($event)">
                      <ng-container *ngFor="let optionForm of controlOptionsArray; index as index;">
                        <div class="wrapper-sentiment-scale-option" cdkDrag [cdkDragData]="optionForm">
                          <app-create-sentiment-scale-option [form]="optionForm" (onClickAction)="clickOptionAction($event, index)"></app-create-sentiment-scale-option>
                        </div>
                      </ng-container>
                    </div>
                    <div class="p-top-15 text-center">
                      <app-button [type]="eButtonType.GREEN_INVERTED" (onClick)="addScaleOption()">
                        <span>{{eSentimentScaleMessages.ADD_OPTION | translate}}</span>
                      </app-button>
                    </div>

                    <div class="p-top-15" *ngIf="controlOptions.hasError('maxlength')">
                      <div class="frankli-form-group-alert">You cannot have more than 10 options</div>
                    </div>
                  </ng-container>
                  <ng-template #templateNoOptions>
                    <app-empty-state [height]="'300px'" [message]="eSentimentScaleMessages.SENTIMENT_SCALE_OPTIONS_EMPTY_STATE" [primaryButtonShown]="true" [primaryButtonText]="eCommonMessages.GET_STARTED" (primaryButtonPressed)="addScaleOption()"></app-empty-state>
                  </ng-template>
                </div>
              </div>
            </div>
          </div>

          <div class="area-action-buttons">
            <app-button [type]="eButtonType.GREEN_INVERTED" (onClick)="cancelCreate()">{{eCommonMessages.CANCEL | translate}}</app-button>
            <app-button [type]="eButtonType.GREEN" [loading]="state.submitting" (onClick)="onClickSave()">{{eCommonMessages.CONFIRM | translate}}</app-button>
          </div>

          <app-modal [width]="'80%'" #modal>
            <app-header [title]="eSentimentScaleMessages.SENTIMENT_SCALE_TEMPLATES" [description]="eSentimentScaleMessages.SENTIMENT_SCALE_TEMPLATES_DESCRIPTION"></app-header>

            <hr class="row m-top-0"/>

            <div class="area-sentiment-scale-templates">
              <ng-container *ngFor="let exampleScale of eEXAMPLE_SCALE_OPTIONS;">
                <div class="wrapper-sentiment-scale-template-option" (click)="onClickTemplate(exampleScale)">
                  <app-sentiment-scale-display [scale]="exampleScale"></app-sentiment-scale-display>
                </div>
              </ng-container>
            </div>
          </app-modal>
        </ng-container>
      </app-state>
    </div>
  </ng-container>
</div>
