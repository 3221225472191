import { Injectable } from '@angular/core';
import { AdminMessages } from '@app/admin/admin.messages';
import { Page } from '@app/models/api/page.model';
import { PagingParams } from '@app/models/api/paging-params.model';
import { SortingParams } from '@app/models/api/sorting-params.model';
import { UserMinimal } from '@app/models/user/user-minimal.model';
import { Globals } from '@app/shared/globals/globals';
import { NotifyUtils } from '@app/shared/utils/notify.utils';
import { Observable } from 'rxjs';
import { RoleAPIService } from '../api/role-api.service';
import { CreateRoleDto } from '../model/create-role.model';
import { ProvisionedPosition } from '../model/provisioned-position.model';
import { Role } from '../model/role.model';

@Injectable({
  providedIn: 'root'
})
export class RoleBusinessService {

  updating: number[];
  uploadingFile: boolean;

  constructor(
    public globals: Globals,
    private roleAPI: RoleAPIService,
    private notifyUtils: NotifyUtils
  ) {
    this.updating = [];
    this.uploadingFile = false;
  }

  private startUpdating(ids: number[]) {
    this.updating = [...this.updating, ...ids];
  }

  private stopUpdating(ids: number[]) {
    this.updating = this.updating.filter(x => !ids.includes(x));
  }

  get(
    ids: number[],
    name: string,
    grade: string,
    competencyIds: number[]
  ): Observable<Role[]> {
    return this.roleAPI.get(ids, name, grade, competencyIds);
  }

  getPaginated(
    pagingParams: PagingParams,
    sortingParams: SortingParams,
    ids: number[],
    name: string,
    grade: string,
    competencyIds: number[]
  ): Observable<Page<Role>> {
    return this.roleAPI.getPaginated(pagingParams, sortingParams, ids, name, grade, competencyIds);
  }

  create(createDto: CreateRoleDto): Promise<Role> {
    return this.roleAPI.create(createDto).toPromise()
      .then(res => {
        this.notifyUtils.notify(AdminMessages.ROLES.SUCCESS_ROLE_CREATE);
        return res;
      });
  }

  update(id: number, updateDto: CreateRoleDto): Promise<Role> {
    if (this.updating.includes(id)) { return new Promise((resolve, reject) => reject()); }
    this.startUpdating([id]);

    return this.roleAPI.update(id, updateDto).toPromise()
      .then(res => {
        this.notifyUtils.notify(AdminMessages.ROLES.SUCCESS_ROLE_UPDATE);

        if (this.globals.user.position && this.globals.user.position.id === id) {
          this.globals.user.position = res;
        }

        return res;
      })
      .finally(() => {
        this.stopUpdating([id]);
      });
  }

  delete(id: number): Promise<Role> {
    if (this.updating.includes(id)) { return new Promise((resolve, reject) => reject()); }
    this.startUpdating([id]);

    return this.roleAPI.delete(id).toPromise()
      .then(res => {
        this.notifyUtils.notify(AdminMessages.ROLES.SUCCESS_DELETE_ROLE_SINGLE);

        if (this.globals.user.position && this.globals.user.position.id === id) {
          this.globals.user.position = null;
        }

        return res;
      })
      .finally(() => {
        this.stopUpdating([id]);
      });
  }

  deleteMultiple(ids: number[]): Promise<Role[]> {
    if (this.updating.some(id => ids.includes(id))) { return new Promise((resolve, reject) => reject()); }
    this.startUpdating(ids);

    return this.roleAPI.deleteMultiple(ids).toPromise()
      .then(res => {
        this.notifyUtils.notify(AdminMessages.ROLES.SUCCESS_DELETE_ROLE_MULTIPLE);

        if (this.globals.user.position && ids.includes(this.globals.user.position.id)) {
          this.globals.user.position = null;
        }

        return res;
      })
      .finally(() => {
        this.stopUpdating(ids);
      });
  }

  importFile(file: File): Promise<Role[]> {
    if (this.uploadingFile) { return new Promise((resolve, reject) => reject()); }
    this.uploadingFile = true;

    return this.roleAPI.importRoles(file).toPromise()
      .then(res => {
        this.notifyUtils.notify(AdminMessages.ROLES.SUCCESS_IMPORT_ROLES);
        return res;
      })
      .finally(() => {
        this.uploadingFile = false;
      });
  }

  getAllUsersAssignedToRole(id: number): Observable<UserMinimal[]> {
    return this.roleAPI.getAllUsersAssignedToRole(id);
  }

  getAllProvisionedPositions(): Observable<ProvisionedPosition[]> {
    return this.roleAPI.getAllProvisionedPositions();
  }

  getProvisionedPositionByPositionId(id: number): Observable<ProvisionedPosition> {
    return this.roleAPI.getProvisionedPositionByPositionId(id);
  }

  searchRoles(searchTerm: string): Promise<Role[]> {
    return this.roleAPI.searchRoles(searchTerm).toPromise();
  }
}
