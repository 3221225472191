import { FeedbackRequest, FeedbackRequestResponseStatus } from '@app/models/feedback/feedback-request.model';
import moment from 'moment';
import { mockUsers } from '@app/mock/api/data/mockUsers';

export const mockFeedbackRequests: FeedbackRequest[] = [
  {
    id: 1,
    questions: [
      {
        id: 1,
        orderIndex: 1,
        questionText: 'How well do you think Maxine has been integrating with our workplace?',
        requestId: 1
      }
    ],
    responses: [
      {
        id: 1,
        user: mockUsers[2],
        externalUser: null,
        status: FeedbackRequestResponseStatus.APPROVED,
        answers: [
          {
            id: 1,
            questionId: 1,
            answer: 'I think she\'s really starting to get used to how we work here which is great.'
          }
        ],
        timestamp: moment().subtract(4, 'days').toDate()
      },
      {
        id: 2,
        user: mockUsers[3],
        externalUser: null,
        status: FeedbackRequestResponseStatus.PENDING,
        answers: [],
        timestamp: null
      }
    ],
    sender: mockUsers[0],
    regarding: mockUsers[1],
    timestamp: moment().subtract(1, 'week').toDate()
  },
  {
    id: 2,
    questions: [
      {
        id: 2,
        orderIndex: 1,
        questionText: 'Do you have any feedback regarding Tina based on your work together on Project X?',
        requestId: 2
      }
    ],
    responses: [
      {
        id: 3,
        user: mockUsers[1],
        externalUser: null,
        status: FeedbackRequestResponseStatus.APPROVED,
        answers: [
          {
            id: 2,
            questionId: 2,
            answer: 'Tina worked very hard on Project X with us, no complaints here.'
          }
        ],
        timestamp: moment().subtract(4, 'days').toDate()
      },
      {
        id: 4,
        user: mockUsers[4],
        externalUser: null,
        status: FeedbackRequestResponseStatus.PENDING,
        answers: [],
        timestamp: null
      }
    ],
    sender: mockUsers[0],
    regarding: mockUsers[2],
    timestamp: moment().subtract(1, 'week').toDate()
  },
];