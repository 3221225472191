import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import {
  FilterMethod,
  FilterOption,
} from '@app/models/universal-filter-option.model';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { Globals } from '../globals/globals';
import { TranslateService } from '@ngx-translate/core';
import { UniversalFilterMessages } from '@app/shared/universal-filter/universal-filter.messages';
import { ButtonType } from '../components/inputs/button/button.component';
import {
  UniversalFilterCategoryItemOutput,
  UniversalFilterDate,
  UniversalFilterDateDirection,
} from './universal-filter-category-item/universal-filter-category-item.component';
import { TerminologyEntity } from '@app/domain/terminology/model/terminology-entity.enum';

export interface Category {
  name: string;
  group: string;
  matchingIDs: number[]; // TODO: Possbily allow any type here and allow property to be defined on @Input() to allow for basic objects to be passed in
  enabled: boolean;
  filterMethod: FilterMethod;
  isDatePicker: boolean;
}

export interface DisplayCategory {
  key: string;
  name: string;
}

export interface DefaultFilter {
  [key: string]: string[];
}

export interface NestedOutputResult {
  nonMatchesIncluded: number[];
  tree: NestedOutput;
}

export interface NestedOutput {
  // Recursive object of IDs
  [id: string]: NestedOutput;
}

export interface Categories {
  [column: string]: Category[];
}

export interface SearchFilterMatch {
  [prop: string]: string[];
}

export interface PropertyMatch {
  [id: number]: {
    searchMatches: SearchFilterMatch;
    filterMatches: SearchFilterMatch; // TODO: Start tracking what filters are matched on
  };
}

export interface StaticFilterOptions {
  [name: string]: {
    properties: string[];
  };
}

@Component({
  selector: 'app-universal-filter',
  templateUrl: './universal-filter.component.html',
  styleUrls: ['./universal-filter.component.scss'],
})
export class UniversalFilterComponent implements OnInit, OnChanges, OnDestroy {
  public readonly eUniversalFilterMessages = UniversalFilterMessages;
  public readonly eButtonType = ButtonType;

  @Input() placeholderText: string; // Placeholder text for the searchbox
  @Input() searchControl: FormControl; // Not required but can pass in a formcontrol if access is needed to it from parent
  @Input() filterOptions: FilterOption[]; // All data
  @Input() searchProps: string[]; // Used to exclude data from the dropdown that can still be searched (User names, goal titles, etc.)
  @Input() dateProps: string[];
  @Input() defaultFilters: DefaultFilter;
  @Input() newUI: boolean;
  @Input() staticFilterOptions: StaticFilterOptions;
  @Input() showClearSearch: boolean;

  @Output() resultEmit: EventEmitter<number[]>;
  @Output() resultEmitNested: EventEmitter<NestedOutputResult>;
  @Output() categoriesChanged: EventEmitter<Categories>;
  @Output() propertiesMatched: EventEmitter<PropertyMatch>;

  usingNestedChecks: boolean;

  result: number[];
  resultNested: NestedOutputResult;

  cachedSearchFilter: {
    search: string;
    filter: {
      [column: string]: Category[];
    };
  };

  cachedDateFilter: {
    [column: string]: UniversalFilterDate;
  };

  categories: Categories;
  categoryKeys: DisplayCategory[];
  categoriesVisible: number;
  allIDs: number[];

  propertiesSelected: number;
  propertiesSelectedVisible: number;

  firstTime: boolean;

  searchSubscription!: Subscription;

  notInNestedResultsIDs: number[];

  propertiesMatchedInternal: PropertyMatch;

  constructor(
    public globals: Globals,
    private translateService: TranslateService
  ) {
    this.placeholderText = this.translateService.instant(
      UniversalFilterMessages.DEFAULT_PLACEHOLDER
    );

    this.searchControl = new FormControl('', []);

    this.notInNestedResultsIDs = [];
    this.filterOptions = [];
    this.categoryKeys = [];
    this.searchProps = [];
    this.dateProps = [];
    this.allIDs = [];
    this.result = [];

    this.resultEmit = new EventEmitter<number[]>();
    this.resultEmitNested = new EventEmitter<NestedOutputResult>();
    this.categoriesChanged = new EventEmitter<Categories>();
    this.propertiesMatched = new EventEmitter<PropertyMatch>();

    this.propertiesSelectedVisible = 0;
    this.propertiesSelected = 0;
    this.categoriesVisible = 0;

    this.firstTime = true;
    this.newUI = false;

    this.usingNestedChecks = false;
    this.showClearSearch = true;

    this.propertiesMatchedInternal = {};
    this.defaultFilters = {};
    this.staticFilterOptions = {};
    this.categories = {};
    this.cachedSearchFilter = {
      search: '',
      filter: {},
    };
    this.cachedDateFilter = {};
    this.resultNested = {
      nonMatchesIncluded: [],
      tree: {},
    };
  }

  ngOnInit() {
    // Listen for search value changing
    this.searchSubscription = this.searchControl.valueChanges
      .pipe(debounceTime(500))
      .subscribe(() => {
        this.doSearchAndFilter();
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    const optionsChanges = changes['filterOptions'];
    if (optionsChanges) {
      if (optionsChanges.previousValue !== optionsChanges.currentValue) {
        this.init();
      }
    }
  }

  ngOnDestroy() {
    if (this.searchSubscription) {
      this.searchSubscription.unsubscribe();
    }
  }

  init() {
    this.allIDs = this.getAllIds(this.filterOptions);
    this.mapData();
  }

  getAllIds(filterOptions: FilterOption[]): number[] {
    let output: number[] = [];

    filterOptions.forEach((fo) => {
      output.push(fo.id);

      if (fo.nestedItems) {
        output = [...output, ...this.getAllIds(fo.nestedItems)];
      }
    });

    return output;
  }

  resetData() {
    for (const key in this.categories) {
      if (Object.hasOwnProperty.call(this.categories, key)) {
        // get values for filter option
        const values = this.filterOptions
          .map((v) => v.properties[key])
          .map((v) => (v === undefined ? null : v.value))
          .filter((v) => v !== null);

        // if value has been removed, clear that value from list in this.categories[key]
        this.categories[key] = this.categories[key].filter((category) =>
          values.some((value) => value === category.name)
        );

        this.categories[key].forEach((v) => {
          v.matchingIDs = [];
        });
      }
    }
  }

  mapData() {
    this.resetData();

    if (this.filterOptions.length > 0) {
      this.categoryKeys = this.getFilterProps(
        this.filterOptions[0],
        this.staticFilterOptions
      );
      this.usingNestedChecks = this.checkIfUsingNested(this.filterOptions);

      // Loop over each piece of data
      this.getCategoriesForOption(this.filterOptions);

      this.sortCategories();
      this.cachedSearchFilter.filter = this.categories;
      this.firstTime = false;

      this.doSearchAndFilter();
    }
  }

  getFilterProps(
    item: FilterOption,
    staticFilterOptions: StaticFilterOptions
  ): DisplayCategory[] {
    const categories: DisplayCategory[] = Object.keys(item.properties)
      .filter((k) => k !== 'id' && !this.searchProps.includes(k))
      .map((k) => {
        return {
          key: k,
          name:
            k.toLowerCase() === 'department'
              ? this.globals.getTerminology(TerminologyEntity.DEPARTMENT)
              : k, // TODO: This is a big hack - fix it and use the name prop instead
        } as DisplayCategory;
      });

    const staticCategories: DisplayCategory[] = Object.keys(staticFilterOptions)
      .filter((o) => !categories.some((c) => c.key === o))
      .map((o) => {
        return {
          key: o,
          name: o.toLowerCase(),
        };
      });

    return [...categories, ...staticCategories];
  }

  // TODO: Use this to mark things as active
  keyHasValue(key: string): boolean {
    if (this.categories[key].some((c) => c.enabled)) {
      return true;
    }

    if (this.cachedDateFilter[key] && this.cachedDateFilter[key].date) {
      return true;
    }

    return false;
  }

  checkIfUsingNested(items: FilterOption[]) {
    return this.filterOptions
      .map((fo) => fo.nestedItems)
      .some((ni) => ni !== undefined && ni !== null);
  }

  getCategoriesForOption(filterOptions: FilterOption[]) {
    // Loop over all the search properties for current object
    // Get categories for nested items)

    if (filterOptions) {
      filterOptions.forEach((fo) => {
        this.categoryKeys.forEach((key) =>
          this.addValuesFromFilterOption(fo, key.key)
        );

        if (
          this.usingNestedChecks &&
          fo.nestedItems &&
          fo.nestedItems.length > 0
        ) {
          this.getCategoriesForOption(fo.nestedItems);
        }
      });
    }

    this.categoryKeys.forEach((k) => {
      const currentProperties = this.categories[k.key];
      const staticProperies = this.staticFilterOptions[k.key];
      if (staticProperies !== undefined) {
        const newProperties = staticProperies.properties
          .filter((p) => !currentProperties.some((c) => c.name === p))
          .map((p) => {
            const isDefault = this.isDefaultFilter(k.key, p);
            const categoryNew: Category = {
              name: p,
              group: k.key,
              matchingIDs: [],
              enabled: this.firstTime && isDefault,
              filterMethod: FilterMethod.OR, // TODO: Allow configuration of this
              isDatePicker: this.dateProps.includes(k.key),
            };
            return categoryNew;
          });
        this.categories[k.key] = [...currentProperties, ...newProperties];
      }
    });
  }

  addValuesFromFilterOption(fo: FilterOption, key: string): void {
    // Value of the prop - Set to 'None' if not defined
    const data = fo.properties[key];
    // TODO: If this is an array
    const value = this.parsePropertyValue(data);
    const filterMethod =
      data !== undefined && data !== null && data.filterMethod
        ? data!.filterMethod
        : FilterMethod.AND; // Value of the prop

    // If column doesnt exist in categories yet add it
    this.createCategory(key);

    if (Array.isArray(value)) {
      (value as string[]).forEach((v) => {
        this.appendPropertyToCategory(key, fo, v, filterMethod);
      });
    } else {
      const v = value as string;
      this.appendPropertyToCategory(key, fo, v, filterMethod);
    }
  }

  appendPropertyToCategory(
    key: string,
    fo: FilterOption,
    value: string,
    filterMethod: FilterMethod
  ): void {
    // Check if value has appeared previously
    const index = this.categories[key].findIndex((ck) => ck.name === value);
    if (index > -1) {
      if (!this.categories[key][index].matchingIDs.includes(fo.id)) {
        this.categories[key][index].matchingIDs.push(fo.id);
      }
      return;
    } else {
      const isDefault = this.isDefaultFilter(key, value);
      this.categories[key].push({
        name: value,
        group: key,
        matchingIDs: [fo.id],
        enabled: this.firstTime && isDefault,
        filterMethod: filterMethod,
        isDatePicker: this.dateProps.includes(key),
      });
    }
  }

  createCategory(key: string): void {
    if (!Object.hasOwnProperty.call(this.categories, key)) {
      this.categories[key] = [];
    }
  }

  parsePropertyValue(property: any): string | string[] {
    const emptyText = 'None';

    if (!property) {
      return emptyText;
    }

    if (!property.value) {
      return emptyText;
    }

    if (Array.isArray(property.value)) {
      if (property.value.length === 0) {
        return emptyText;
      }
    }

    return property.value;
  }

  sortCategories() {
    this.categoryKeys.forEach((ck) => {
      if (this.categories[ck.key]) {
        this.categories[ck.key] = this.categories[ck.key].sort((a, b) => {
          const valA = a.name.toString().toLocaleLowerCase();
          const valB = b.name.toString().toLocaleLowerCase();

          if (valA === 'none') {
            return -2;
          }
          if (valB === 'none') {
            return 2;
          }

          if (valA < valB) {
            return -1;
          }
          if (valA > valB) {
            return 1;
          }

          return 0;
        });
      }
    });
  }

  // Check if prop is included in default filters
  isDefaultFilter(key: string, value: string) {
    if (Object.prototype.hasOwnProperty.call(this.defaultFilters, key)) {
      let defaultValues = this.defaultFilters[key];
      defaultValues = defaultValues.map((d) => d.toLocaleLowerCase());
      return defaultValues.includes(value.toLocaleLowerCase());
    }

    return false;
  }

  // Deselet all props in filter dropdown
  resetFilterAndSearch(event?: any): void {
    if (event) {
      event.stopPropagation(); // Stop dropdown from closing
    }

    // Set all enabled props to false (deselect all)
    this.categoryKeys.forEach((key) => {
      this.categories[key.key].forEach((val) => {
        val.enabled = false;
      });
    });

    // Set cache value
    this.cachedSearchFilter.filter = this.categories;
    this.cachedDateFilter = {};

    // Update search control
    this.resetSearch();

    // Do search+filter
    this.doSearchAndFilter();
  }

  resetSearch(): void {
    this.searchControl.setValue('');
  }

  resetFilter(event?: any): void {
    if (event) {
      event.stopPropagation(); // Stop dropdown from closing
    }

    // Set all enabled props to false (deselect all)
    this.categoryKeys.forEach((key) => {
      this.categories[key.key].forEach((val) => {
        val.enabled = false;
      });
    });

    // Set cache value
    this.cachedSearchFilter.filter = this.categories;

    // Do search+filter
    this.doSearchAndFilter();
  }

  doSearchAndFilter() {
    this.propertiesMatchedInternal = {};

    // Get sarg
    const sarg = this.searchControl.value.toLocaleLowerCase();

    // Set cache
    this.cachedSearchFilter = {
      search: sarg,
      filter: this.categories,
    };

    let result = this.doFilter();

    if (!this.usingNestedChecks) {
      result = this.doSearch(result, sarg);

      this.setResult(result);
    } else {
      // const filterIdsNested = this.getMatchingIdsForActiveFiltersNested();
      this.notInNestedResultsIDs = [];
      const output = this.doSearchFilterForNestedData(
        this.filterOptions,
        result,
        sarg
      );
      this.setResultNested(output);
    }
  }

  // #region - FILTERING
  doFilter() {
    this.propertiesSelected = 0;
    this.propertiesSelectedVisible = 0;

    // Build a list of enabled props
    let result: number[] = this.doFilterProcessing();

    // Get unique values
    result = Array.from(new Set(result).values());

    // Return Result
    return this.propertiesSelected === 0 ? this.allIDs : result;
  }

  doFilterProcessing(): number[] {
    const resultsRaw: number[][] = [];
    let categoriesVisible = 0;
    this.categoryKeys.forEach((key) => {
      if (this.dateProps.includes(key.key)) {
        const results: number[] = [];
        this.filterOptions.forEach((fo) => {
          const value = fo.properties[key.key];
          const dt = new Date(value.value.toString());
          if (
            !this.cachedDateFilter[key.key] ||
            this.cachedDateFilter[key.key] === undefined ||
            !this.cachedDateFilter[key.key].date
          )
            return;

          if (
            this.cachedDateFilter[key.key].startdatefilter ===
            UniversalFilterDateDirection.Before
          ) {
            if (dt <= this.cachedDateFilter[key.key].date) {
              results.push(fo.id);
            }
          } else if (
            this.cachedDateFilter[key.key].startdatefilter ===
            UniversalFilterDateDirection.After
          ) {
            if (dt >= this.cachedDateFilter[key.key].date) {
              results.push(fo.id);
            }
          }

          this.propertiesSelected += 1;
          resultsRaw.push(results);
        });
      } else {
        // Get values for category
        const category = this.categories[key.key];
        if (category) {
          if (category.length > 1) {
            categoriesVisible += 1;
          }
          const method =
            category.length > 0 ? category[0].filterMethod : FilterMethod.OR;
          let categoryResults: number[] = [];
          // let categoryReduced: number[] = []
          const selectedValues = category.filter((value) => value.enabled);
          this.propertiesSelected += selectedValues.length;
          if (category.length > 1) {
            this.propertiesSelectedVisible += selectedValues.length;
          }

          // Run the desired filter
          // EXAMPLE:
          // Data = [a1, a2, a3, b1, b2, c1, ab1]
          // (A and B) = [ab1] <-- Has both A and B (excludes values with just A or B and c1)
          // (A or B) = [a1, a2, a3, b1, b2, ab1] <-- Has either A or B (excludes c1)
          if (selectedValues.length > 0) {
            switch (method) {
              case FilterMethod.AND:
                // Get matches with all checked filters
                categoryResults = this.getResultsAND(selectedValues);
                break;
              case FilterMethod.OR:
                // Get matches with any checked filters (A or B)
                categoryResults = this.getResultsOR(selectedValues);
                break;
            }

            resultsRaw.push(categoryResults);
          }
        }
      }
    });

    this.categoriesVisible = categoriesVisible;

    // Aggregate all results
    return resultsRaw.length > 0 ? this.reduceResults(resultsRaw) : [];
  }

  reduceResults(resultGroups: number[][]): number[] {
    return resultGroups.reduce((p, c) => p.filter((e) => c.includes(e)));
  }

  getResultsAND(categoriesAND: Category[]): number[] {
    let resultAND: number[] = this.allIDs;
    categoriesAND.forEach((category) => {
      resultAND = resultAND.filter((id) => category.matchingIDs.includes(id));
    });
    return resultAND;
  }

  getResultsOR(categoriesOR: Category[]): number[] {
    let resultOR: number[] = [];
    categoriesOR.forEach((category) => {
      resultOR = [...resultOR, ...category.matchingIDs];
    });

    return resultOR;
  }

  // #endregion

  // Perform search with current search argument
  // TODO: Clean this up, split into functions
  doSearch(matches: number[], sarg: string) {
    // If no search argument defined, skip search
    if (sarg.length === 0) {
      return matches;
    }

    const matchListSearched: number[] = [];
    const filterOptionsMatched = this.filterOptions.filter((fo) =>
      matches.includes(fo.id)
    );

    // Loop over each piece of data and check it's search properties for the search arg
    filterOptionsMatched.forEach((item) => {
      item.matchedPropertiesNames = [];

      this.searchProps.forEach((prop) => {
        let value = item.properties[prop] ? item.properties[prop]!.value : '';
        value = value || '';

        let isMatch = false;

        switch (typeof value) {
          case 'string': // String
            isMatch = value.toLocaleLowerCase().includes(sarg);

            if (isMatch) {
              if (!this.propertiesMatchedInternal[item.id]) {
                this.propertiesMatchedInternal[item.id] = {
                  searchMatches: {},
                  filterMatches: {},
                };
              }

              if (
                !this.propertiesMatchedInternal[item.id].searchMatches[prop]
              ) {
                this.propertiesMatchedInternal[item.id].searchMatches[prop] =
                  [];
              }

              this.propertiesMatchedInternal[item.id].searchMatches[prop].push(
                value
              );
            }
            break;
          case 'object': // Array
            value.forEach((v: any) => {
              if (v.toLocaleLowerCase().includes(sarg)) {
                if (!this.propertiesMatchedInternal[item.id]) {
                  this.propertiesMatchedInternal[item.id] = {
                    searchMatches: {},
                    filterMatches: {},
                  };
                }

                if (
                  !this.propertiesMatchedInternal[item.id].searchMatches[prop]
                ) {
                  this.propertiesMatchedInternal[item.id].searchMatches[prop] =
                    [];
                }

                this.propertiesMatchedInternal[item.id].searchMatches[
                  prop
                ].push(v);

                isMatch = true;
              }
            });
            break;
        }

        if (isMatch) {
          const find = matches.filter((m) => m === item.id);
          if (find && find.length === 1) {
            const result = find[0];

            item.matchedPropertiesNames!.push(prop);
            item.matchedPropertiesSearchText = sarg;

            if (!matchListSearched.includes(result)) {
              matchListSearched.push(result);
            }
          }
        }
      });
    });

    return matchListSearched;
  }

  doSearchFilterForNestedData(
    nestedItems: FilterOption[],
    filterIds: number[],
    sarg: string
  ): NestedOutput {
    const output: NestedOutput = {};

    // Loop over all nestedItems
    nestedItems.forEach((fo) => {
      const inFilters = this.checkFilterOptionForFilter(fo, filterIds); // Check if in filter options
      const inSearch = this.checkFilterOptionForSarg(fo, sarg); // Check if in search arguments

      // If this option has nested options
      const nestedData: NestedOutput = fo.nestedItems
        ? this.doSearchFilterForNestedData(fo.nestedItems, filterIds, sarg)
        : null!;
      let nestedDataLength = 0;
      for (const key in nestedData) {
        if (Object.hasOwnProperty.call(nestedData, key)) {
          nestedDataLength += 1;
        }
      }

      const itemMatchesFilters = inFilters && inSearch;

      // Add to output if in search/filter OR child is in search/filter
      if (itemMatchesFilters || nestedDataLength > 0) {
        // Add to array if in tree but not in search/filter results
        if (!itemMatchesFilters && nestedDataLength > 0) {
          this.notInNestedResultsIDs.push(fo.id);
        }

        output[fo.id] = nestedData ? nestedData : {}; // Set empty object if nestedOutput isnt defined
      }
    });

    return output;
  }

  checkFilterOptionForFilter(fo: FilterOption, filterIds: number[]) {
    return filterIds.includes(fo.id);
  }

  checkFilterOptionForSarg(fo: FilterOption, sarg: string): boolean {
    if (sarg.length === 0) {
      // If no sarg, return true;
      return true;
    }

    // Build a big string of all the search properties combined
    let searchString = '';
    this.searchProps.forEach((sp) => {
      const value = fo.properties[sp];
      if (value) {
        searchString += value.value;
      }
    });

    // If that string includes the sarg
    return searchString.toLocaleLowerCase().includes(sarg);
  }

  // Set the result and emit to parent
  setResult(val: number[]) {
    this.result = val;

    // TODO: Might need to emit these as one object eventaully if we end up adding enough metadata
    this.resultEmit.emit(val);
    this.propertiesMatched.emit(this.propertiesMatchedInternal);
  }

  setResultNested(val: NestedOutput) {
    const output = {
      nonMatchesIncluded: this.notInNestedResultsIDs,
      tree: val,
    };

    // TODO: Add propertiesMatched emit to this
    this.resultNested = output;
    this.resultEmitNested.emit(output);
  }

  // Get the number of enabled values in a category
  getCategorySelectedCount(key: string) {
    const count = this.categories[key].filter((v) => v.enabled).length;

    if (count > 0) {
      return count;
    } else {
      return undefined;
    }
  }

  preventClick($event: Event) {
    $event.stopPropagation();
  }

  onCategoryPropertyToggled(
    key: DisplayCategory,
    output: UniversalFilterCategoryItemOutput
  ): void {
    const categoryKey = key.key;
    if (typeof output === 'number') {
      this.categories[categoryKey][output].enabled =
        !this.categories[categoryKey][output].enabled;
    } else {
      this.cachedDateFilter[categoryKey] = output;
      if (output && output.date == null) {
        delete this.cachedDateFilter[categoryKey];
      }
    }

    // Emit change
    this.categoriesChanged.emit(this.categories);

    // Do search+filter
    this.doSearchAndFilter();
  }

  toggleFilterByKeyAndName(key: string, propName: string): void {
    propName = propName.trim().toLowerCase();

    const category = this.categories[key];
    if (!category) {
      throw new Error('Key not found');
    }

    const property = category.find((p) =>
      p.name.trim().toLowerCase().includes(propName)
    );
    if (!property) {
      throw new Error('Property not found');
    }

    property.enabled = !property.enabled;

    // Emit change
    this.categoriesChanged.emit(this.categories);

    // Do search+filter
    this.doSearchAndFilter();
  }

  public setCategoryProperty(
    key: string,
    propName: string,
    enabled: boolean
  ): void {
    propName = propName.trim().toLowerCase();

    const category = this.categories[key];
    if (!category) {
      throw new Error('Key not found');
    }

    const property = category.find((p) =>
      p.name.trim().toLowerCase().includes(propName)
    );
    if (!property) {
      throw new Error('Property not found');
    }

    property.enabled = enabled;

    // Emit change
    this.categoriesChanged.emit(this.categories);

    // Do search+filter
    this.doSearchAndFilter();
  }

  toggleProp(event: any, key: DisplayCategory, index: number) {
    event.stopPropagation(); // Stop dropdown from closing

    this.onCategoryPropertyToggled(key, index);
  }
}
