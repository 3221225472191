import { Injectable } from '@angular/core';
import { ISecuritySettingsAPI } from './security-settings-api.model';
import { ChangePasswordDto } from '@app/models/user/change-password.dto';
import { User } from '@app/models/user/user.model';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class SecuritySettingsAPIService implements ISecuritySettingsAPI {
  private readonly BASE_URL: string = 'api/security-settings';

  constructor(
    private http: HttpClient
  ) {
  }

  updatePassword(changePasswordDto: ChangePasswordDto): Observable<User> {
    const url = `${this.BASE_URL}/change-password`;
    return this.http.post<User>(url, changePasswordDto);
  }
}
