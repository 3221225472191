import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SecondaryManagerAny } from '@app/models/company/company-secondary-manager/secondary-manager-any.model';
import { SecondaryManager } from '@app/models/company/company-secondary-manager/secondary-manager.model';
import { Observable } from 'rxjs';
import { SecondaryManagerService } from '../interfaces/company/secondary-manager.service';

@Injectable({
  providedIn: 'root'
})
export class SecondaryManagerAPIService implements SecondaryManagerService {

  private readonly BASE_URL = '/api';
  httpHeaders: HttpHeaders;

  constructor(private http: HttpClient) {
    this.httpHeaders = new HttpHeaders();
    this.httpHeaders.append('Content-Type', 'application/json');
  }

  getSecondaryManagersForSecondaryManagerMe(): Observable<SecondaryManager[]>{
    const url = `${this.BASE_URL}/user/secondary-manager/secondary-manager/me`;
    return this.http.get<SecondaryManager[]>(url);
  }
  
  getAnySecondaryManagerMe(): Observable<SecondaryManagerAny>{
    const url = `${this.BASE_URL}/secondary-manager/any`;
    return this.http.get<SecondaryManagerAny>(url);
  }

  getSecondaryManagersForUser(userId: number): Observable<Array<SecondaryManager>> {
    const url = `${this.BASE_URL}/user/${userId}/secondary-manager`;
    return this.http.get<Array<SecondaryManager>>(url);
  }

  getSecondaryManagerForUser(userId: number, secondaryManagerId: number): Observable<SecondaryManager>{
    const url = `${this.BASE_URL}/user/${userId}/secondary-manager/${secondaryManagerId}`;
    return this.http.get<SecondaryManager>(url);
  }

  updateSecondaryManagerForUser(userId: number, secondaryManager: SecondaryManager): Observable<SecondaryManager> {
    const url = `${this.BASE_URL}/user/${userId}/secondary-manager`;
    return this.http.post<SecondaryManager>(url, secondaryManager);
  }

  removeSecondaryManagerForUser(userId: number, secondaryManagerId: number): Observable<void> {
    const url = `${this.BASE_URL}/user/${userId}/secondary-manager/remove/${secondaryManagerId}`;
    return this.http.delete<void>(url);
  }
  
  removeAllSecondaryManagersForUser(userId: number): Observable<void> {
    const url = `${this.BASE_URL}/user/${userId}/secondary-manager/remove-all`;
    return this.http.delete<void>(url);
  }

}
