import { GoalPriority } from './goal-priority.model';
import { Goal } from './goal.model';

export class GoalSort {
  /**
   * Sorts an array of goals by title alphabetically
   * @param goals
   * @param direction
   */
  byTitle(
    goals: Array<Goal>,
    direction: 'ascending' | 'descending'
  ): Array<Goal> {
    const response = goals.sort((goalA, goalB) => {
      const a = goalA.title.toLocaleLowerCase();
      const b = goalB.title.toLocaleLowerCase();

      if (a < b) {
        return -1;
      } else if (a > b) {
        return 1;
      } else {
        return 0;
      }
    });

    if (direction === 'descending') {
      response.reverse();
    }

    return response;
  }

  /**
   * Sorts an array of goals by type alphabetically
   * @param goals
   * @param direction
   */
  byType(
    goals: Array<Goal>,
    direction: 'ascending' | 'descending'
  ): Array<Goal> {
    const response = goals.sort((goalA, goalB) => {
      const a = goalA.type.toString().toLocaleLowerCase();
      const b = goalB.type.toString().toLocaleLowerCase();

      if (a < b) {
        return -1;
      } else if (a > b) {
        return 1;
      } else {
        return 0;
      }
    });

    if (direction === 'descending') {
      response.reverse();
    }

    return response;
  }

  /**
   * Sorts an array of goals by priority alphabetically
   * @param goals
   * @param direction
   */
  byPriority(
    goals: Array<Goal>,
    direction: 'ascending' | 'descending'
  ): Array<Goal> {
    let priorityList = goals.filter((x) => x.priority !== GoalPriority.NOT_SET);
    let notSetList = goals.filter((x) => x.priority === GoalPriority.NOT_SET);
    let response;
    priorityList = priorityList.sort((goalA, goalB) => {
      const a = goalA.priority.toString().toLocaleLowerCase();
      const b = goalB.priority.toString().toLocaleLowerCase();

      if (a < b) {
        return -1;
      } else if (a > b) {
        return 1;
      } else {
        return 0;
      }
    });

    if (direction === 'descending') {
      priorityList.reverse();
    }
    notSetList = notSetList.sort((goalA, goalB) => {
      const a = new Date(goalA.endDate).valueOf();
      const b = new Date(goalB.endDate).valueOf();
      if (a < b) {
        return -1;
      } else if (a > b) {
        return 1;
      } else {
        return 0;
      }
    });
    if (direction === 'descending') {
      notSetList.reverse();
    }
    response =
      direction === 'descending'
        ? notSetList.concat(priorityList)
        : priorityList.concat(notSetList);
    return response;
  }

  /**
   * Sorts an array of goals by total keyresults
   * @param goals
   * @param direction
   */
  byKeyResults(
    goals: Array<Goal>,
    direction: 'ascending' | 'descending'
  ): Array<Goal> {
    const response = goals.sort((goalA, goalB) => {
      const a = goalA.keyResults.length;
      const b = goalB.keyResults.length;

      if (a < b) {
        return -1;
      } else if (a > b) {
        return 1;
      } else {
        return 0;
      }
    });

    if (direction === 'descending') {
      response.reverse();
    }

    return response;
  }

  /**
   * Sorts an array of goals by end date
   * @param goals
   * @param direction
   */
  byEndDate(
    goals: Array<Goal>,
    direction: 'ascending' | 'descending'
  ): Array<Goal> {
    const response = goals.sort((goalA, goalB) => {
      const a = new Date(goalA.endDate).valueOf();
      const b = new Date(goalB.endDate).valueOf();
      if (a < b) {
        return -1;
      } else if (a > b) {
        return 1;
      } else {
        return 0;
      }
    });

    if (direction === 'descending') {
      response.reverse();
    }

    return response;
  }

  /**
   * Sorts an array of goals by end date
   * @param goals
   * @param direction
   */
  byProgress(
    goals: Array<Goal>,
    direction: 'ascending' | 'descending'
  ): Array<Goal> {
    goals = Goal.getGoalArrayCompletionPercentage(goals);

    const response = goals.sort((goalA, goalB) => {
      const a = goalA.completionPercentage;
      const b = goalB.completionPercentage;
      if (a < b) {
        return -1;
      } else if (a > b) {
        return 1;
      } else {
        return 0;
      }
    });

    if (direction === 'descending') {
      response.reverse();
    }

    return response;
  }
}
