/* eslint-disable no-case-declarations */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { MockService } from '@app/mock/api/mock-service';
import { EvaluationCycleService } from '@app/shared/api/interfaces/evaluation-cycle.service';
import { HttpRequest } from '@angular/common/http';
import { EvaluationFeedback } from '@app/models/evaluation/evaluation-feedback.model';
import { EvaluationFeedbackCalibration } from '@app/models/evaluation/evaluation-feedback-calibration.model';
import { EvaluationFeedbackRequest } from '@app/models/evaluation/evaluation-feedback-request.model';
import { EvaluationCycle } from '@app/models/evaluation/evaluation-cycle.model';
import { AssessmentGroupInfo } from '@app/models/evaluation/assessment-group-info.model';
import { ManagerReviewAccess } from '@app/models/evaluation/manager-review-access.model';
import { EvaluationCycleDirectReport } from '@app/models/evaluation/evaluation-cycle-direct-report.model';
import { EvaluationUser, EvaluationUserMin } from '@app/models/evaluation/evaluation-user.model';
import { EvaluationCycleUserOverview } from '@app/models/evaluation/evaluation-cycle-user-overview.model';
import { SelfReflection } from '@app/models/evaluation/self-reflection.model';
import { Observable, of } from 'rxjs';
import { clone, sanitizeUrl } from '@app/shared/utils/helpers';
import { Globals } from '@app/shared/globals/globals';
import { mockEvaluationCycles } from '@app/mock/api/data/mockEvaluationCycles';
import { mockSelfReflections } from '@app/mock/api/data/mockSelfReflections';
import { EvaluationCycleState } from '@app/models/evaluation/evaluation-cycle-state';
import { mockAssessmentGroupInfos } from '@app/mock/api/data/mockAssessmentGroupInfos';
import { mockEvaluationFeedbackCalibrations } from '@app/mock/api/data/mockEvaluationFeedbackCalibrations';
import { mockUsers } from '@app/mock/api/data/mockUsers';
import { mockEvaluationFeedback } from '@app/mock/api/data/mockEvaluationFeedback';
import { EvaluationUserState } from '@app/models/evaluation/evaluation-user-state.model';
import { mockEvaluationFeedbackRequests } from '@app/mock/api/data/mockEvaluationFeedbackRequests';
import { mockManagerReviewAccesses } from '@app/mock/api/data/mockManagerReviewAccesses';
import { User } from '@app/models/user/user.model';
import { Injectable } from '@angular/core';
import { mockPraise } from '@app/mock/api/data/mockPraise';
import { mockGoals } from '@app/mock/api/data/mockGoals';
import { mockPeerFeedback } from '@app/mock/api/data/mockPeerFeedback';
import { mockIdeas } from '@app/mock/api/data/mockIdeas';
import { ReviewUnsubmitListInfo } from 'app/models/evaluation/review-unsubmit-list-info.model';
import { EvaluationAdminActivity } from 'app/models/evaluation/evaluation-admin-action.model';
import { mockUsersMin } from '@app/mock/api/data/mockUsersMin';
import { Page } from '@app/models/api/page.model';
import { PagingParams } from '@app/models/api/paging-params.model';
import { CreateReviewStepSummary } from '@models/evaluation/peer-review-summary/create-review-step-summary.model';
import { UserMinimal } from '@app/models/user/user-minimal.model';
import { ReviewStepSummary } from '@models/evaluation/peer-review-summary/review-step-summary.model';
import { UpwardReviewAccess } from '@app/models/evaluation/upward-review-access.model';
import { PeerReviewAccess } from '@app/models/evaluation/peer-review-access.model';
import { mockOneToOneSchedules } from '@app/domain/one_to_one/mock/one-to-one-schedules.mock';

@Injectable()
export class EvaluationCycleMockService implements MockService, EvaluationCycleService {

  constructor(private readonly globals: Globals) {
  }

  handleRoute(req: HttpRequest<any>): any {
    const url = sanitizeUrl(req.urlWithParams);
    const urlSegments = url.split('/');

    switch (true) {
      case url.match(/api\/evaluation\/\d+$/) !== null && req.method === 'GET':
        const evaluationCycleID = +urlSegments[2];
        return this.getEvaluationCycleById(evaluationCycleID);
      case url.endsWith('api/evaluation/company'):
        return this.getEvaluationCyclesForCompany();
      case url.match(/api\/evaluation\/cycle\/user\/\d+$/) !== null:
        const cycleUserId = +urlSegments[4];
        return this.getEvaluationCycleForUserId(cycleUserId);
      case url.match(/api\/evaluation\/cycle\/user\/\d+\/all/) !== null:
        const allCycleUserId = +urlSegments[4];
        return this.getAllEvaluationCyclesForUserId(allCycleUserId);
      case url.match(/api\/evaluation\/\d+\/users\/managed\/\d+$/) !== null:
        const cycleIdManaged = +urlSegments[2];
        const userId = +urlSegments[5];
        return this.getEvaluationCycleDirectReportOverview(cycleIdManaged, userId);
      case url.match(/api\/evaluation\/\d+\/users/) !== null:
        const cycleWithUsersId = +urlSegments[2];
        return this.getEvaluationUsersForCycle(cycleWithUsersId);
      case url.match(/api\/evaluation\/\d+\/users\/min/) !== null:
        const cycleWithUsersMinId = +urlSegments[2];
        return this.getEvaluationUsersMinForCycle(cycleWithUsersMinId, {pageNumber: 0, pageSize: 30} as PagingParams);
      case url.match(/api\/evaluation\/\d+\/user\/\d+$/) !== null:
        const userCycleId = +urlSegments[2];
        const evalCycleUserId = +urlSegments[4];
        return this.getEvaluationUserByCycleIdAndUserId(userCycleId, evalCycleUserId);
      case url.match(/api\/evaluation\/user\/\d+$/) !== null:
        const evalSubjectUserId = +urlSegments[3];
        return this.getEvaluationFeedbackFromMeForUserIdInActiveCycle(evalSubjectUserId);
      case url.match(/api\/evaluation\/\d+\/self\/\d+$/) !== null:
        const selfUserCycleId = +urlSegments[2];
        const selfCycleId = +urlSegments[4];
        return this.getSelfReflectionForCycleAndUser(selfUserCycleId, selfCycleId);
      case url.endsWith('api/evaluation/self'):
        return this.getAnswersForSelfReflection();
      case url.match(/api\/evaluation\/user\/\d+\/requests\/received\/sr-completed/) !== null:
        const srCompletedUserId = +urlSegments[3];
        return this.getEvaluationFeedbackRequestsSentToUserWhereSRCompleted(srCompletedUserId);
      case url.match(/api\/evaluation\/user\/\d+\/requests\/received/) !== null:
        const sentRequestsUserId = +urlSegments[3];
        return this.getEvaluationFeedbackRequestsSentToUser(sentRequestsUserId);
      case url.match(/api\/evaluation\/user\/\d+\/requests/) !== null:
        const reqUserId = +urlSegments[3];
        return this.getRequestsForUserEvaluation(reqUserId);
      case url.match(/api\/evaluation\/\d+\/requests\/managed/) !== null:
        const reqManagedCycleId = +urlSegments[2];
        return this.getEvaluationFeedbackRequestsForUsersByManagerMeByCycleId(reqManagedCycleId);
      case url.match(/api\/evaluation\/\d+\/complete\/users/) !== null:
        const completeUsersManagedCycleId = +urlSegments[2];
        return this.getCycleUsersCompleted(completeUsersManagedCycleId);
      case url.endsWith('api/evaluation/company/managed'):
        return this.getEvaluationCyclesForCompanyManaged();
      case url.match(/api\/evaluation\/\d+\/user-overview\/\d+$/) !== null:
        const overviewCycleId = +urlSegments[2];
        const overviewUserId = +urlSegments[4];
        return this.getEvaluationCycleUserOverview(overviewUserId, overviewCycleId);
      case url.match(/api\/evaluation\/\d+\/review\/me/) !== null:
        const myReviewCycleId = +urlSegments[2];
        return this.getManagerReviewForUserMe(myReviewCycleId);
      case url.match(/api\/evaluation\/\d+\/review\/\d+$/) !== null:
        const myCycleId = +urlSegments[2];
        const myReviewNoCalId = +urlSegments[4];
        return this.getManagerReviewForUserMeById(myCycleId, myReviewNoCalId);
      case url.match(/api\/assessment-group-info\?cycleId=\d+$/) !== null:
        const agCycleId = req.params.get('cycleId');
        return this.getAssessmentGroupInfoForCycle(+agCycleId);
      case url.endsWith('api/assessment-group-info'):
        return this.getAssessmentGroupInfoForCycle(null);
    }
  }

  getAllEvaluationCyclesForUserId(userId: number): Observable<EvaluationCycle[]> {
    return of(mockEvaluationCycles.filter(c => c.users.includes(userId)));
  }

  getAnswersForSelfReflection(): Observable<SelfReflection | null> | SelfReflection {
    const activeCycle = mockEvaluationCycles.find(c => c.state === EvaluationCycleState.ACTIVATED && c.users.includes(this.globals.user.id));
    return activeCycle ? mockSelfReflections.find(s => s.cycleId === activeCycle.id && s.authorUser.id === this.globals.user.id) || null : null;
  }

  getAssessmentGroupInfoForCycle(cycleId: number): Observable<Array<AssessmentGroupInfo>> | AssessmentGroupInfo[] {
    return mockAssessmentGroupInfos.filter(c => c.cycleId === cycleId);
  }

  getEvaluationCyclesForCompany(): Observable<Array<EvaluationCycle>> | EvaluationCycle[] {
    return mockEvaluationCycles;
  }

  getCompletedEvaluationCyclesForCompany(): Observable<EvaluationCycle[]> {
    return of(mockEvaluationCycles);
  }

  getEvaluationCyclesForCompanyManaged(): Observable<Array<EvaluationCycle>> | EvaluationCycle[] {
    const directReports = mockUsers.filter(u => u.id !== this.globals.user.id && u.managerId === this.globals.user.id);
    return mockEvaluationCycles.filter(c => c.users.some(u => directReports.map(d => d.id).includes(u)));
  }

  getCycleUsersCompleted(id: number): Observable<number[]> | number[] {
    return [1, 3];
  }

  getEvaluationCycleById(id: number): Observable<EvaluationCycle> | EvaluationCycle {
    const cycle = mockEvaluationCycles.find(c => c.id === id);
    return cycle ? clone(cycle) : null;
  }

  getEvaluationUserByCycleIdAndUserId(cycleId: number, userId: number): EvaluationUser {
    const user = mockUsers.find(u => u.id === userId);
    return {
      user: user,
      selfReflection: mockSelfReflections.find(s => s.cycleId === cycleId && s.authorUser.id === userId),
      evaluationUserState: EvaluationUserState.IN_PROGRESS,
      requestsAboutUser: mockEvaluationFeedbackRequests.filter(r => r.cycleId === cycleId && r.subjectUser.id === userId && r.authorUser.id !== user.managerId),
      requestsAboutOthers: mockEvaluationFeedbackRequests.filter(r => r.cycleId === cycleId && r.authorUser.id === userId && r.subjectUser.managerId !== userId),
      responsesAboutUser: mockEvaluationFeedback.filter(f => f.cycleId === cycleId && f.subjectUser.id === userId && f.authorUser.id !== user.managerId),
      responsesAboutOthers: mockEvaluationFeedback.filter(f => f.cycleId === cycleId && f.authorUser.id === userId),
      evaluationFeedbackCalibration: mockEvaluationFeedbackCalibrations.find(c => c.evaluationFeedbackId === mockEvaluationFeedback.find(f => f.cycleId === cycleId && f.subjectUser.id === userId && f.authorUser.id === user.managerId).id),
      managerReviewAccess: mockManagerReviewAccesses.find(a => a.cycleId === cycleId && a.managerReview.subjectUser.id === userId),
      lastEditTimestamp: new Date(),
    };
  }
  getEvaluationUserMinByCycleIdAndUserId(cycleId: number, userId: number): EvaluationUserMin {
    const user = mockUsersMin[0];
    return {
      user: user,
      selfReflection: mockSelfReflections.find(s => s.cycleId === cycleId && s.authorUser.id === userId),
      evaluationUserState: EvaluationUserState.IN_PROGRESS,
      requestsAboutUser: mockEvaluationFeedbackRequests.filter(r => r.cycleId === cycleId && r.subjectUser.id === userId && r.authorUser.id !== user.managerId),
      requestsAboutOthers: mockEvaluationFeedbackRequests.filter(r => r.cycleId === cycleId && r.authorUser.id === userId && r.subjectUser.managerId !== userId),
      responsesAboutUser: mockEvaluationFeedback.filter(f => f.cycleId === cycleId && f.subjectUser.id === userId && f.authorUser.id !== user.managerId),
      responsesAboutOthers: mockEvaluationFeedback.filter(f => f.cycleId === cycleId && f.authorUser.id === userId),
      evaluationFeedbackCalibration: mockEvaluationFeedbackCalibrations.find(c => c.evaluationFeedbackId === mockEvaluationFeedback.find(f => f.cycleId === cycleId && f.subjectUser.id === userId && f.authorUser.id === user.managerId).id),
      managerReviewAccess: mockManagerReviewAccesses.find(a => a.cycleId === cycleId && a.managerReview.subjectUser.id === userId),
      lastEditTimestamp: new Date(),
    };
  }

  getEvaluationCycleUserOverview(userId: number, cycleId: number): EvaluationCycleUserOverview {
    return {
      praise: mockPraise.filter(p => p.praisedUser.id === userId),
      goals: mockGoals.filter(g => g.owners.map(o => o.id).includes(userId)),
      feedbackPeerReceived: mockPeerFeedback.filter(f => f.recipient.id === userId),
      feedbackPeerSent: mockPeerFeedback.filter(f => f.sender.id === userId),
      competencies: mockUsers.find(u => u.id === userId).position.competencies,
      ideas: mockIdeas.filter(i => i.senderUser.id === userId),
      // oneToOneSchedulesWithMeetingsWithinReviewPeriod: mockOneToOneSchedules.filter(s => s.participants.length === 1 && s.participants[0].id === userId && s.manager.id === this.globals.user.id),
      oneToOneSchedulesWithMeetingsWithinReviewPeriod: [],
    };
  }

  getEvaluationUsersForCycle(cycleId: number): Observable<Array<EvaluationUser>> | EvaluationUser[] {
    return mockEvaluationCycles.find(c => c.id === cycleId).users.map(u => this.getEvaluationUserByCycleIdAndUserId(cycleId, u)) || null;
  }
  getEvaluationUsersMinForCycle(cycleId: number, pagingParams: PagingParams): Observable<Page<EvaluationUserMin>> | EvaluationUserMin[] {
    return mockEvaluationCycles.find(c => c.id === cycleId).users.map(u => this.getEvaluationUserMinByCycleIdAndUserId(cycleId, u)) || null;
  }

  getEvaluationCycleDirectReportOverview(cycleId: number, userId: number): EvaluationCycleDirectReport {
    const evaluationCycle = mockEvaluationCycles.find(c => c.id === cycleId);
    const currentUser = this.globals.user;
    return this.createEvaluationCycleDirectReportTree(evaluationCycle, currentUser);
  }

  private createEvaluationCycleDirectReportTree(evaluationCycle: EvaluationCycle, user: User): EvaluationCycleDirectReport {
    const directReportList: EvaluationCycleDirectReport[] = [];
    const directReports = mockUsers.filter(u => u.managerId === user.id && u.id !== u.managerId);
    for (const directReport of directReports) {
      if (evaluationCycle.users.includes(directReport.id)) {
        directReportList.push(this.createEvaluationCycleDirectReportTree(evaluationCycle, directReport));
      }
    }
    const evaluationUser = this.getEvaluationUserByCycleIdAndUserId(evaluationCycle.id, user.id);
    return {
      evaluationUser: evaluationUser,
      directReports: directReportList,
      hasDirectReports: directReportList.length > 0,
      secondary: false,
    };
  }

  getEvaluationFeedbackFromMeForUserIdInActiveCycle(id: number): EvaluationFeedback {
    const activeEvaluationCycle = mockEvaluationCycles.find(c => c.state === EvaluationCycleState.ACTIVATED && c.users.includes(id));
    return mockEvaluationFeedback.find(f => f.cycleId === activeEvaluationCycle.id && f.authorUser.id === this.globals.user.id && f.subjectUser.id === id) || null;
  }

  getEvaluationFeedbackRequestsSentToUser(id: number): Observable<EvaluationFeedbackRequest[]> | EvaluationFeedbackRequest[] {
    return mockEvaluationFeedbackRequests.filter(r => r.authorUser.id === id);
  }

  getEvaluationFeedbackRequestsSentToUserWhereSRCompleted(id: number): Observable<EvaluationFeedbackRequest[]> | EvaluationFeedbackRequest[] {
    return mockEvaluationFeedbackRequests.filter(r => r.authorUser.id === id && r.srCompleted);
  }

  getRequestsForUserEvaluation(id: number): Observable<EvaluationFeedbackRequest[]> | EvaluationFeedbackRequest[] {
    const user = mockUsers.find(u => u.id === id);
    return mockEvaluationFeedbackRequests.filter(r => r.subjectUser.id === user.id && r.authorUser.id !== user.managerId);
  }

  getFeedbackRequestsManagedByMe(): Observable<EvaluationFeedbackRequest[]> | EvaluationFeedbackRequest[] {
    return mockEvaluationFeedbackRequests.filter(r => r.subjectUser.managerId === this.globals.user.id);
  }

  getEvaluationFeedbackRequestsForUsersByManagerMeByCycleId(cycleId: number): Observable<EvaluationFeedbackRequest[]> | EvaluationFeedbackRequest[] {
    return mockEvaluationFeedbackRequests.filter(r => r.subjectUser.managerId === this.globals.user.id && r.cycleId === cycleId);
  }

  getManagerReviewForUserMe(cycleId: number): Observable<EvaluationFeedback> | EvaluationFeedback {
    return mockEvaluationFeedback.find(f => f.cycleId === cycleId && f.subjectUser.id === this.globals.user.id && f.authorUser.id === this.globals.user.managerId) || null;
  }

  getManagerReviewForUserMeById(cycleId: number, managerReviewId: number): Observable<EvaluationFeedback> | EvaluationFeedback {
    return mockEvaluationFeedback.find(f => f.cycleId === cycleId && f.id === managerReviewId) || null;
  }

  getSelfReflectionForCycleAndUser(cycleId: number, userId: number): Observable<SelfReflection> | SelfReflection {
    return mockSelfReflections.find(s => s.cycleId === cycleId && s.authorUser.id === userId) || null;
  }

  getEvaluationCycleForUserId(userId: number): Observable<EvaluationCycle> | EvaluationCycle {
    return mockEvaluationCycles.find(c => c.state === EvaluationCycleState.ACTIVATED && c.users.includes(userId)) || null;
  }

  // No Ops listed below
  getSelfReflectionForSelf(): Observable<SelfReflection> | SelfReflection {
    return undefined;
  }

  getPastSelfReflections(): Observable<SelfReflection[]> | SelfReflection[] {
    return undefined;
  }

  getEvaluationCyclesReport(ids: Array<number>): any {
    return undefined;
  }

  addAssessmentGroupInfoByCycle(assessmentGI: AssessmentGroupInfo[], cycleId: number): Observable<AssessmentGroupInfo[]> {
    return undefined;
  }

  approveEvaluationRequest(id: number): Observable<EvaluationFeedbackRequest> {
    return undefined;
  }

  closeReviewCycle(cycle: EvaluationCycle): Observable<EvaluationCycle> {
    return undefined;
  }

  completeEvaluationFeedbackByRequestId(requestId: number, feedback: EvaluationFeedback): Observable<EvaluationFeedback> {
    return undefined;
  }

  completeSelfReflection(answers: SelfReflection): Observable<SelfReflection> {
    return undefined;
  }

  createEvaluationCalibration(cycleID: number, calibration: EvaluationFeedbackCalibration): Observable<EvaluationFeedbackCalibration> | undefined {
    return undefined;
  }

  createEvaluationFeedbackCalibration(cycleId: number, evaluationFeedbackCalibration: EvaluationFeedbackCalibration): Observable<EvaluationFeedbackCalibration> {
    return undefined;
  }

  createSelfReflectionCalibration(cycleId: number, evaluationFeedbackCalibration: EvaluationFeedbackCalibration): Observable<EvaluationFeedbackCalibration> {
    return undefined;
  }

  declineEvaluationRequest(id: number, reason: string): Observable<EvaluationFeedbackRequest> {
    return undefined;
  }

  deleteEvaluationCycle(id: number): Observable<null> {
    return undefined;
  }

  downloadUserPDF(cycleId: number, userId: number, fileName: string): any {
    return undefined;
  }

  editReviewCycle(cycle: EvaluationCycle): Observable<EvaluationCycle> {
    return undefined;
  }

  extendCycle(cycleId: number, newEndDate: Date): Observable<EvaluationCycle> {
    return undefined;
  }

  createSelfReflection(answers: SelfReflection): any {
    return undefined;
  }

  nominateUserForCycle(cycleId: number, subjectUserId: number, authorUserId: number): Observable<EvaluationFeedbackRequest> {
    return undefined;
  }

  saveReviewCycle(cycle: EvaluationCycle, draft: boolean): Observable<EvaluationCycle> {
    return undefined;
  }

  shareManagerReviewAccess(cycleId: number, evaluationFeedbackId: number): Observable<ManagerReviewAccess> {
    return undefined;
  }

  createEvaluationFeedbackForUser(id: number, feedback: EvaluationFeedback): Observable<EvaluationFeedback> {
    return undefined;
  }


  unlockManagerReviewAccessBulk(cycleId: number, subjectIds: number[]): Observable<ManagerReviewAccess[]> {
    return undefined;
  }

  editSelfReflection(answers: SelfReflection, autosave: boolean): any {
    return undefined;
  }

  editEvaluationFeedbackForUser(id: number, feedback: EvaluationFeedback, autosave: boolean): Observable<EvaluationFeedback> {
    return undefined;
  }

  unsubmitManagerReviews(reviewUnsubmitListInfo: ReviewUnsubmitListInfo): Observable<Array<EvaluationFeedback>> {
    return undefined;
  }

  getActivityForCycle(evaluationCycleId: number): Observable<Array<EvaluationAdminActivity>> {
    return undefined;
  }

  getMostRecentActivityForEvaluationRequest(evaluationCycleId: number, evaluationFeedbackId: number): Observable<EvaluationAdminActivity> {
    return undefined;
  }

  unsubmitSelfReflections(reviewUnsubmitListInfo: ReviewUnsubmitListInfo): Observable<Array<SelfReflection>> {
    return undefined;
  }

  getMostRecentActivityForSelfReflection(evaluationCycleId: number, selfReflectionId: number): Observable<EvaluationAdminActivity> {
    return undefined;
  }

  unsubmitPeerReviews(reviewUnsubmitListInfo: ReviewUnsubmitListInfo): Observable<Array<EvaluationFeedback>> {
    return undefined;
  }

  createPeerReviewSummaryForUserAndCycle(cycleId: number, userId: number, peerReviewSummary: CreateReviewStepSummary): Observable<ReviewStepSummary> {
    return undefined;
  }

  updatePeerReviewSummaryForUserAndCycle(cycleId: number, userId: number, peerReviewSummary: CreateReviewStepSummary): Observable<ReviewStepSummary> {
    return undefined;
  }

  sharePeerReviewSummaryForUserAndCycle(cycleId: number, userId: number, peerReviewSummary: CreateReviewStepSummary): Observable<ReviewStepSummary> {
    return undefined;
  }

  getPeerReviewSummaryForMeInCycle(cycleId: number): Observable<ReviewStepSummary> {
    return undefined;
  }

  getPeerReviewSummaryForUserInCycle(cycleId: number, userId: number): Observable<ReviewStepSummary> {
    return undefined;
  }

  getPeerReviewSummaryForUserInCycleAsAdmin(cycleId: number, userId: number): Observable<ReviewStepSummary> {
    return undefined;
  }

  getUsersCompletedPeerReviewAboutMeForCycleId(cycleId: number): Observable<UserMinimal[]> {
    return undefined;
  }

  getUsersCompletedUpwardReviewAboutMeForCycleId(cycleId: number): Observable<UserMinimal[]> {
    return undefined;
  }

  getMyManagerReviewsForActiveCycles(): Observable<EvaluationFeedbackRequest[]> {
    return undefined;
  }

  getMyPeerReviewsForActiveCycles(): Observable<EvaluationFeedbackRequest[]> {
    return undefined;
  }

  getNominationRequestsForMyTeam(): Observable<EvaluationFeedbackRequest[]> {
    return undefined;
  }

  getNominationRequestsForMyTeamForCycle(cycleId: number): Observable<EvaluationFeedbackRequest[]> {
    return undefined;
  }

  getPeersNominatedCompletedShared(cycleId: number): Observable<EvaluationFeedbackRequest[]> {
    return undefined;
  }

  getPeersNominatedCompletedSharedForUserId(cycleId: number, userId: number): Observable<EvaluationFeedbackRequest[]> {
    return undefined;
  }

  getNominationById(requestId: number): Observable<EvaluationFeedback> {
    return undefined;
  }

  getNominationByIdIfExists(requestId: number): Observable<EvaluationFeedback | null> {
    return undefined;
  }

  checkIsPeerReviewShared(requestId: number): Observable<boolean> {
    return undefined;
  }

  checkIsUpwardReviewShared(requestId: number): Observable<boolean> {
    return undefined;
  }

  checkIsManagerReviewShared(requestId: number): Observable<boolean> {
    return undefined;
  }

  sharePeerReviewAccess(requestId: number) {
    return undefined;
  }

  shareUpwardReviewAccess(requestId: number) {
    return undefined;
  }

  sharePeerReviewAccessBySubjectUserId(cycleId: number, subjectUserId: number): Observable<PeerReviewAccess[]> {
    return of(undefined);
  }

  shareUpwardReviewAccessBySubjectUserId(cycleId: number, subjectUserId: number): Observable<UpwardReviewAccess[]> {
    return of(undefined);
  }

  getUpwardReviewRequestsForMe(): Observable<EvaluationFeedbackRequest[]> {
    return undefined;
  }

  getUpwardReviewRequestsForMeForCycle(cycleId: number): Observable<EvaluationFeedbackRequest[]> {
    return undefined;
  }

  getUpwardReviewsCompletedSharedForUserId(cycleId: number, userId: number): Observable<EvaluationFeedbackRequest[]> {
    return undefined;
  }

  getSelfReflectionAssessmentGroupAnswersForCycle(cycleId: number): Observable<number[]> {
    return undefined;
  }

  getManagerReviewAssessmentGroupAnswersForCycle(cycleId: number): Observable<number[]> {
    return undefined;
  }

  getUpwardReviewAssessmentGroupAnswersForCycle(cycleId: number): Observable<number[]> {
    return undefined;
  }

  getSidebarCycles(userIds: number[]): Observable<EvaluationCycle[]> {
    return undefined;
  }

}
