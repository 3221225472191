import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { EmployeeComponentsModule } from '@app/employee/employee-components/employee-components.module';
import { ActionPointsComponent } from '@app/domain/one_to_one/component/action-points/action-points.component';
import { PipesModule } from '@app/shared/pipes/pipes.module';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { Angulartics2Module } from 'angulartics2';
import { GoalsComponentsModule } from 'app/goals/goals-components/goals-components.module';
import { PaginationModule } from 'app/shared/pagination/pagination.module';
import { SharedModule } from 'app/shared/shared.module';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { AngularMyDatePickerModule } from 'angular-mydatepicker';
import { TranslateModule } from '@ngx-translate/core';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { SidebarModule } from '@app/shared/components/sidebar/sidebar.module';
import { AvailabilityModalComponent } from '../component/availability-modal/availability-modal.component';
import { CreateOneToOneComponent } from '../page/create-one-to-one/create-one-to-one.component';
import { TemplateModalComponent } from '../component/template-modal/template-modal.component';
import { CustomFrequencyComponent } from '../component/custom-frequency/custom-frequency.component';
import { MeetingFilesComponent } from '../component/meeting-files/meeting-files.component';
import { OneToOneOverviewComponent } from '../page/one-to-one-overview/one-to-one-overview.component';
import { PrivateNotesComponent } from '../component/private-notes/private-notes.component';
import { SharedNotesComponent } from '../component/shared-notes/shared-notes.component';
import { TalkingPointItemComponent } from '../component/talking-point-item/talking-point-item.component';
import { TalkingPointsComponent } from '../component/talking-points/talking-points.component';
import { MeetingNavigationCarouselItemComponent } from '../component/meeting-navigation-carousel-item/meeting-navigation-carousel-item.component';
import { MeetingNavigationCarouselComponent } from '../component/meeting-navigation-carousel/meeting-navigation-carousel.component';
import { MeetingNavigationListComponent } from '../component/meeting-navigation-list/meeting-navigation-list.component';
import { OneToOneScheduleView } from '../page/one-to-one-schedule-view/one-to-one-schedule-view.component';
import { TransferScheduleComponent } from '../component/transfer-schedule/transfer-schedule.component';
import { OneToOneMeetingViewComponent } from '../page/one-to-one-meeting-view/one-to-one-meeting-view.component';
import { SentimentScaleComponentsModule } from '@app/domain/sentiment_scale/module/sentiment-scale-components.module';
import { MeetingSentimentScaleComponent } from '../component/meeting-sentiment-scale/meeting-sentiment-scale.component';

const components = [
  OneToOneOverviewComponent,
  CreateOneToOneComponent,
  OneToOneScheduleView,
  OneToOneMeetingViewComponent,
  TalkingPointsComponent,
  ActionPointsComponent,
  TemplateModalComponent,
  AvailabilityModalComponent,
  PrivateNotesComponent,
  SharedNotesComponent,
  MeetingFilesComponent,
  CustomFrequencyComponent,
  MeetingNavigationCarouselComponent,
  MeetingNavigationCarouselItemComponent,
  MeetingNavigationListComponent,
  TalkingPointItemComponent,
  TransferScheduleComponent,
  MeetingSentimentScaleComponent
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    Angulartics2Module,
    FormsModule,
    ReactiveFormsModule,
    SweetAlert2Module,
    PaginationModule,
    SharedModule,
    GoalsComponentsModule,
    EmployeeComponentsModule,
    PipesModule,
    AngularMyDatePickerModule,
    TooltipModule.forRoot(),
    TranslateModule,
    DragDropModule,
    TypeaheadModule.forRoot(),
    SidebarModule,
    SentimentScaleComponentsModule
  ],
  declarations: components,
  exports: components
})
export class OneToOneComponentsModule { }
