<ng-container *ngIf="certificate">
  <div class="container-absorblms-certificate-display">
    <div class="card no-margin">
      <div class="content p-relative">
        <ng-container *ngIf="canSeeActions">
          <div class="area-actions">
            <ng-container *ngIf="certificate.hidden; else templateNotHidden;">
              <app-table-action-icon [icon]="'fa-eye'" [tooltipText]="'Show this certificate'" (click)="showCertificate()"></app-table-action-icon>
            </ng-container>
            <ng-template #templateNotHidden>
              <app-table-action-icon [icon]="'fa-eye-slash'" [tooltipText]="'Hide this certificate'" (click)="hideCertificate()"></app-table-action-icon>
            </ng-template>
          </div>
        </ng-container>

        <div class="certificate-heading">
          <ng-container *ngIf="certificate.hidden">
            <span class="fal fa-fw fa-eye-slash" title="This enrollment is hidden and will not be visible to others"></span>
            <span class="m-right-5"></span>
          </ng-container>

          <span>{{certificate.courseName}}</span>
        </div>

        <div>Acquired on: {{certificate.acquiredDate | date}}</div>

        <ng-container *ngIf="certificate.expiryDate">
          <div>Expires on: {{certificate.expiryDate | date}}</div>
        </ng-container>
      </div>
    </div>
  </div>
</ng-container>