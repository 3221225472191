<div class="main-filter-container">
    <ng-container *ngIf="searchProperties && searchProperties.length > 0">
        <div class="search-box-container">
            <input type="search" class="form-control search-box-control" [placeholder]="placeholderText" [formControl]="searchControl" maxlength="100"/>
            <div class="search-box-icon">
                <span class="fal fa-fw fa-search"></span>
            </div>
        </div>
    </ng-container>
    <div class="category-list" *ngIf="parentFilter">
        <ng-container *ngFor="let category of filterCategories">
            <ng-container *ngIf="!category.hidden && !hiddenCategoriesClientside.includes(category.displayName)">
                <app-dynamic-filter-category-item [category]="category" [selectedFiltersForCategory]="getSelectedFiltersForCategory(category)" (onOptionToggled)="toggleFilterOption(category, $event)"></app-dynamic-filter-category-item>
            </ng-container>
        </ng-container>
    </div>
    <ng-container *ngIf="selectedFiltersInner.length > 0 || (searchControl.value && searchControl.value.length > 0)">
        <app-button [small]="true" [type]="eButtonType.NAVY" (onClick)="resetFilters()">
            <span translate>{{eUniversalFilterMessages.RESET}}</span>
        </app-button>
    </ng-container>
</div>