import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { RoleName } from '@app/models/user-role.model';
import { Globals } from '../globals/globals';

@Injectable()
export class UserAccessRevokedGuard implements CanActivate {
  

  constructor(
    public globals: Globals,
    private router: Router
  ) { }

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.globals.user.roles.some(role => role.name === RoleName.ACCESS_REVOKED)) {
      this.router.navigateByUrl('/revoked');
      return false;
    }
    return true;
  }
}
