import { EvaluationFeedbackRequest } from '@app/models/evaluation/evaluation-feedback-request.model';
import { mockEvaluationCycles } from '@app/mock/api/data/mockEvaluationCycles';
import { mockUsers } from '@app/mock/api/data/mockUsers';
import { EvaluationFeedbackRequestState } from 'app/models/evaluation/evaluation-feedback-request-state.model';
import { EvaluationFeedbackRequestType } from '@app/models/evaluation/evaluation-feedback-request-type.model';

export const mockEvaluationFeedbackRequests: EvaluationFeedbackRequest[] = [
  ...mockEvaluationCycles[0].users.map(us => mockUsers.find(f => f.id == us)).filter(u => u.id !== u.managerId).map((user, idx) => ({
    id: idx + 1,
    cycleId: mockEvaluationCycles[0].id,
    authorUser: mockUsers.find(u => u.id === user.managerId),
    authorUserId: user.managerId,
    subjectUser: user,
    subjectUserId: user.id,
    state: EvaluationFeedbackRequestState.COMPLETED,
    requestDeclinedReason: '',
    type: EvaluationFeedbackRequestType.PEER_REVIEW,
    shared: false
  }))
];
