<div class="container-task-activity">
  <!-- Action icons from new workflow -->
  <ng-container *ngIf="showHeader">
    <div class="extra-actions-row">
      <div class="row m-left-0 m-right-0">
        <div class="actions-row-left">
          <div class="actions-row-pill">
            <ng-container [ngSwitch]="task.alignmentType">
              <span *ngSwitchCase="eTaskAlignmentType.TODO">Todo details</span>
              <span *ngSwitchCase="eTaskAlignmentType.ONE_TO_ONE">Action point details</span>
              <span *ngSwitchCase="eTaskAlignmentType.MILESTONE">Milestone details</span>
              <span *ngSwitchDefault>Task details</span>
            </ng-container>
          </div>
        </div>
        <div class="actions-row-right">
          <!-- <div class="actions-row-item" (click)="openHelpArticle()">
            <span class="fal fa-fw fa-lightbulb-on"></span>
            <span class="m-right-5"></span>
            <span translate>{{eCommonMessages.LEARN_MORE}}</span>
          </div> -->
          <div class="actions-row-item frankli-close" (click)="closeDetailsModal()">
            <span class="fal fa-fw fa-times"></span>
          </div>
        </div>
      </div>
    </div>
  </ng-container>

  <div class="main-form-contents" cdk-scrollable>
    <div class="main-form">
      <div class="p-left-15 p-right-15 p-top-15">
        <ng-container *ngIf="task">
          <!-- Title -->
          <div class="p-bottom-15">
            <h1 class="standard-heading">{{task.title}}</h1>
            <div class="standard-description">{{task.description ? task.description : (eCommonMessages.NOT_SET | translate)}}</div>
          </div>
  
          <!-- Status -->
          <div class="p-bottom-15">
            <label translate>{{eTasksMessages.STATUS}}</label>
            <ng-container *ngIf="!task.archived; else statusArchived">
              <ng-container *ngIf="canUpdate; else templateStatusDisplay;">
                <div class="frankli-form-group frankli-form-group-filled">
                  <select class="task-status" [formControl]="controlTaskStatus" [ngClass]="{
                      'task-status-todo': (controlTaskStatus.value === eTaskStatus.NOT_STARTED),
                      'task-status-in-progress': (controlTaskStatus.value === eTaskStatus.IN_PROGRESS),
                      'task-status-complete': (controlTaskStatus.value === eTaskStatus.COMPLETE)
                    }">
                    <option [ngValue]="eTaskStatus.NOT_STARTED" translate>{{eTasksMessages.STATUS_NOT_STARTED}}</option>
                    <option [ngValue]="eTaskStatus.IN_PROGRESS" translate>{{eTasksMessages.STATUS_IN_PROGRESS}}</option>
                    <option [ngValue]="eTaskStatus.COMPLETE" translate>{{eTasksMessages.STATUS_COMPLETE}}</option>
                  </select>
                </div>
              </ng-container>
      
              <ng-template #templateStatusDisplay>
                <div class="task-status" [ngSwitch]="task.status" [ngClass]="{
                      'task-status-todo': (task.status === eTaskStatus.NOT_STARTED),
                      'task-status-in-progress': (task.status === eTaskStatus.IN_PROGRESS),
                      'task-status-complete': (task.status === eTaskStatus.COMPLETE)
                    }">
                  <span *ngSwitchCase="eTaskStatus.NOT_STARTED" translate>{{eTasksMessages.STATUS_NOT_STARTED}}</span>
                  <span *ngSwitchCase="eTaskStatus.IN_PROGRESS" translate>{{eTasksMessages.STATUS_IN_PROGRESS}}</span>
                  <span *ngSwitchCase="eTaskStatus.COMPLETE" translate>{{eTasksMessages.STATUS_COMPLETE}}</span>
                </div>
              </ng-template>
            </ng-container>
            <ng-template #statusArchived>
              <div class="archived-status" translate>{{eCommonMessages.ARCHIVED}}</div>
            </ng-template>
          </div>
        
          <!-- Due Date -->
          <div class="p-bottom-15">
            <label translate>{{eTasksMessages.DUE_DATE}}</label>
            <div>{{task.dueDate ? (task.dueDate | momentPipe: 'DD/MM/YYYY h:mm a') : 'No due date'}}</div>
          </div>
        
          <!-- Creator -->
          <div class="p-bottom-15">
            <label translate>{{eTasksMessages.CREATED_BY}}</label>
            <div>
              <div class="d-inline-block">
                <app-user-display [user]="task.creator" [position]="task.creator.position"></app-user-display>
              </div>
            </div>
          </div>
        
          <!-- Owners -->
          <div>
            <label translate>{{eTasksMessages.TASK_OWNERS}}</label>
            <div>
              <div class="d-inline-block" *ngFor="let owner of task.owners">
                <app-user-display [user]="owner" [position]="owner.position"></app-user-display>
              </div>
            </div>
          </div>

          <div class="row">
            <hr/>
          </div>

          <ng-container *ngIf="task.alignmentType !== eTaskAlignmentType.TODO">
            <ng-container [ngSwitch]="task.alignmentType">
              <ng-container *ngSwitchCase="eTaskAlignmentType.TODO"></ng-container>

              <!-- TODO: Show something for these -->
              <ng-container *ngSwitchCase="eTaskAlignmentType.GOAL">
                <label translate>{{eTasksMessages.ALIGNMENT}}</label>

                <ng-container *ngIf="linkedGoal">
                  <div class="p-bottom-15">
                    <a class="clickable" (click)="openAlignedItem()">Open aligned Goal</a>
                  </div>
                  <app-goal-display [goal]="linkedGoal"></app-goal-display>
                </ng-container>
              </ng-container>

              <ng-container *ngSwitchCase="eTaskAlignmentType.ONE_TO_ONE">
                <label translate>{{eTasksMessages.ALIGNMENT}}</label>

                <ng-container *ngIf="linkedOneToOne">
                  <div class="p-bottom-15">
                    <a class="clickable" (click)="openAlignedItem()">Open aligned 1:1</a>
                  </div>
                  <app-one-to-one-schedule-detailed-display [schedule]="linkedOneToOne"></app-one-to-one-schedule-detailed-display>
                </ng-container>
              </ng-container>
  
              <ng-container *ngSwitchCase="eTaskAlignmentType.MILESTONE">
                <label>Aligned to roles</label>

                <ng-container *ngIf="linkedRoles?.length > 0">    
                  <div class="area-role-list">
                    <ng-container *ngFor="let role of linkedRoles">
                      <div class="role-list-item" [tooltip]="tooltipRoleDetails" [triggers]="globals.tooltipTriggers" placement="left" [adaptivePosition]="false" container="body">
                        <span>{{role.name}}</span>
                        <ng-container *ngIf="role.grade && globals.hasFeature(eCompanyFeature.GRADES)">
                          <span class="m-right-5"></span>
                          <span>{{role.grade}}</span>
                        </ng-container>
                      </div>
  
                      <ng-template #tooltipRoleDetails>
                        <div class="text-left min-width-400 p-15">
                          <app-role-display [role]="role" [showUsersInRole]="true"></app-role-display>
                        </div>
                      </ng-template>
                    </ng-container>
                  </div>
                </ng-container>
              </ng-container>
            </ng-container>
  
            <div class="row">
              <hr/>
            </div>
          </ng-container>

          <!-- Activity -->
          <div class="p-bottom-15">
            <div class="p-bottom-15">
              <h4 class="standard-heading" translate>{{eTasksMessages.ACTIVITY}}</h4>
            </div>

            <app-task-activity [taskId]="task?.id" [showCommentForm]="!task.archived"></app-task-activity>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>
