import { Component, Input, OnChanges, SimpleChanges, ViewEncapsulation } from '@angular/core';;

@Component({
  selector: 'app-profile-photo',
  templateUrl: './profile-photo.component.html',
  styleUrls: ['./profile-photo.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ProfilePhotoComponent implements OnChanges {

  public readonly DEFAULT_USER_IMAGE = 'assets/img/default.svg';

  @Input() src: string;
  @Input() width: string;
  @Input() firstName?: string;
  @Input() lastName?: string;
  @Input() letterAvatarFontSize?: string;

  hasErrors: boolean;
  
  get canUseLetterAvatar(): boolean {
    if (!this.firstName) { return false; } // First name must be set
    return true;
  }

  get srcRequiresAuthentication(): boolean {
    if (!this.src) { return false; } // Src must be set
    if (!this.src.includes('/api/files/get/')) { return false; } // Src must point to the API
    return true;
  }

  // Edge case: If src is pointing at the default image (test users), use the letters where possible
  get hasDefaultAvatar(): boolean {
    if (!this.src) { return false; }
    if (!this.src.includes(this.DEFAULT_USER_IMAGE)) { return false; }
    return true;
  }

  constructor() {
    this.src = '';
    this.width = '';

    this.firstName = undefined;
    this.lastName = undefined;

    this.hasErrors = false;

    this.letterAvatarFontSize = '20px';
  }

  ngOnChanges(changes: SimpleChanges): void {
    const changesSrc = changes['src'];
    if (changesSrc && (changesSrc.previousValue !== changesSrc.currentValue)) {
      this.hasErrors = false;
    }
  }

  onErrorLoadingImage($event): void {
    this.hasErrors = true;
  }
}
