import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { MapsAddress } from 'app/models/maps/maps-address.model';
import { Observable } from 'rxjs';
import { TimeZoneInfo } from 'app/models/maps/time-zone-info.model';
import { map } from 'rxjs/operators';

@Injectable()
export class MapsAPIService {
  http: HttpClient;

  constructor(private _http: HttpClient) {
    this.http = _http;
  }

  getLocationByName(sarg: string): Observable<MapsAddress> {
    const url = 'https://nominatim.openstreetmap.org/?format=json&addressdetails=1&limit=1&q=' + sarg;
    return this.http.get<MapsAddress>(url).pipe(map(res => {
      return res[0];
    }));
  }

  getTimeZoneInfoByLatLng(latitude: number, longitude: number): Observable<TimeZoneInfo> {
    const url = `https://secure.geonames.org/timezoneJSON?lat=${latitude}&lng=${longitude}&username=frankliuser`;
    return this.http.get<TimeZoneInfo>(url);
  }
}
