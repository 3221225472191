import { Observable, of, throwError } from 'rxjs';
import { HttpResponse, HttpStatusCode } from '@angular/common/http';
import { MockService } from '@app/mock/api/mock-service';
import { CompanyMockService } from '@app/mock/api/services/company/company.mock.service';
import { AuthMockService } from '@app/mock/api/services/auth.mock.service';
import { TaskAPIServiceMock } from '@app/domain/task/api/task-api.service.mock';
import { WelcomeMockService } from '@app/mock/api/services/welcome.mock.service';
import { GoalMockService } from '@app/mock/api/services/goal.mock.service';
import { NewFeatureMockService } from '@app/mock/api/services/new-feature.mock.service';
import { NewsActivityMockService } from '@app/mock/api/services/news-activity.mock.service';
import { Injectable } from '@angular/core';
import { InterestAPIServiceMock } from '@app/domain/interest/api/interest-api.service.mock';
import { CompetencyAPIServiceMock } from '@app/domain/competency/api/competency-api.service.mock';
import { RoleAPIServiceMock } from '@app/domain/role/api/role-api.service.mock';
import { PraiseMockService } from '@app/mock/api/services/praise.mock.service';
import { IdeaMockService } from '@app/mock/api/services/idea.mock.service';
import { FeedbackMockService } from '@app/mock/api/services/feedback.mock.service';
import { AnalyticsMockService } from '@app/mock/api/services/analytics.mock.service';
import { NotificationMockService } from '@app/mock/api/services/notification.mock.service';
import { EvaluationCycleMockService } from '@app/mock/api/services/evaluation-cycle.mock.service';
import { OneToOneAPIServiceMock } from '@app/domain/one_to_one/api/one-to-one-api.service.mock';
import { PollMockService } from '@app/mock/api/services/poll.mock.service';
import { QuestionBankMockService } from '@app/mock/api/services/question-bank.mock.service';
import { SurveyMockService } from '@app/mock/api/services/survey.mock.service';
import { ConnectMockHandler } from '@app/mock/api/services/connect/connect.mock.handler';
import { SettingsMockService } from '@app/mock/api/services/settings.mock.service';
import { SpellcheckMockService } from '@app/mock/api/services/spellcheck.mock.service';
import { CalendarMockService } from '@app/mock/api/services/calendar.mock.service';
import { UserMockHandler } from '@app/mock/api/services/user/user.mock.handler';
import { ScimUserMockService } from '@app/mock/api/services/scim-user.mock.service';

@Injectable()
export class MockApiService {
  // Note that the key must match the base path of each route.
  // For complex routes such as those for Connect, create a handler service to hand off the route to a mock service.
  // E.g: The key for 'api/interests' would be 'interests'.
  public readonly mockServices: Record<string, MockService> = {
    'authuser': this.authMockService,
    'company': this.companyMockService,
    'user': this.userMockHandler,
    'todo': this.todoMockService,
    'welcome': this.welcomeMockService,
    'goal': this.goalMockService,
    'new-features': this.newFeatureMockService,
    'news': this.newsActivityMockService,
    'interests': this.interestMockService,
    'competency': this.competencyMockService,
    'position': this.positionMockService,
    'praise': this.praiseMockService,
    'idea': this.ideaMockService,
    'feedback': this.feedbackMockService,
    'statistics': this.analyticsMockService,
    'notifications': this.notificationMockService,
    'connect': this.connectMockHandler,
    'question-bank': this.questionBankMockService,
    'evaluation': this.evaluationCycleMockService,
    'assessment-group-info': this.evaluationCycleMockService,
    'one-to-one': this.oneToOneMockService,
    'poll': this.pollMockService,
    'survey': this.surveyMockService,
    'surveys': this.surveyMockService, // This is included separately because the api/survey root uses a query param and should really be changed
    'settings': this.settingsMockService,
    'spellcheck': this.spellcheckMockService,
    'calendar': this.calendarMockService,
    'scim-users': this.scimUserMockService
  };

  /**
     * Routes that are allowed through the filter
     */
  public readonly allowedRoutes: Array<string> = [
    'api/customer-accounts/demo'
  ];

  /**
     * Routes that ignore the error handler
     */
  public readonly excludeErrorHandler: Array<string> = [
    'api/customer-accounts/demo'
  ];


  constructor(
        private readonly authMockService: AuthMockService,
        private readonly companyMockService: CompanyMockService,
        private readonly userMockHandler: UserMockHandler,
        private readonly todoMockService: TaskAPIServiceMock,
        private readonly welcomeMockService: WelcomeMockService,
        private readonly goalMockService: GoalMockService,
        private readonly newFeatureMockService: NewFeatureMockService,
        private readonly newsActivityMockService: NewsActivityMockService,
        private readonly interestMockService: InterestAPIServiceMock,
        private readonly competencyMockService: CompetencyAPIServiceMock,
        private readonly positionMockService: RoleAPIServiceMock,
        private readonly praiseMockService: PraiseMockService,
        private readonly ideaMockService: IdeaMockService,
        private readonly feedbackMockService: FeedbackMockService,
        private readonly analyticsMockService: AnalyticsMockService,
        private readonly notificationMockService: NotificationMockService,
        private readonly connectMockHandler: ConnectMockHandler,
        private readonly evaluationCycleMockService: EvaluationCycleMockService,
        private readonly oneToOneMockService: OneToOneAPIServiceMock,
        private readonly pollMockService: PollMockService,
        private readonly questionBankMockService: QuestionBankMockService,
        private readonly surveyMockService: SurveyMockService,
        private readonly settingsMockService: SettingsMockService,
        private readonly spellcheckMockService: SpellcheckMockService,
        private readonly calendarMockService: CalendarMockService,
        private readonly scimUserMockService: ScimUserMockService
  ) { }

  static ok(body?: any): Observable<HttpResponse<any>> {
    return of(
      new HttpResponse({
        status: HttpStatusCode.Ok,
        body
      })
    );
  }

  static error(message: string, error: string): Observable<never> {
    return throwError({
      message: message,
      error: error,
      status: HttpStatusCode.NotModified
    });
  }
}