import {Component, Input, OnInit} from '@angular/core';

export enum PillType {
  YELLOW = 'YELLOW',
  RED = 'RED',
  GREEN = 'GREEN',
  BLUE = 'BLUE',
  NAVY = 'NAVY',
  GRAY = 'GRAY',
  OFF_WHITE = 'OFF_WHITE'
}

@Component({
  selector: 'app-pill',
  templateUrl: './pill.component.html',
  styleUrls: ['./pill.component.scss']
})
export class PillComponent implements OnInit {
  /**
   * @param {string} text - Sets the text content of the pill
   * @deprecated [text] prop is being deprecated in favour of ng-content.
   **/
  @Input() text: string;

  /**
   * @param {Object} type - Determines what style of pill to use
   **/
  @Input() type: PillType;
  @Input() minWidth: number;
  @Input() displayFullWidth: boolean;

  constructor() {
    this.type = PillType.YELLOW;
    this.text = '';
    this.minWidth = 50;
    this.displayFullWidth = false;
  }

  ngOnInit(): void {
    if (this.text !== '') {
      console.warn('PillComponent [text] prop is being deprecated in favour of ng-content.');
    }
  }
}
