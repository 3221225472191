import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Globals } from '@app/shared/globals/globals';
import { Category, DisplayCategory } from '../universal-filter.component';
import { ButtonGroupOption } from '@app/shared/components/inputs/button-group/button-group.component';
import { FormControl, FormGroup } from '@angular/forms';
import { ButtonType } from '@app/shared/components/inputs/button/button.component';

// instead of allowing any type, restrict to number or Date
export type UniversalFilterCategoryItemOutput = number | UniversalFilterDate;

export enum UniversalFilterDateDirection {
  Before = 'Before',
  After = 'After',
}

export type UniversalFilterDate = {
  date: Date; // the selected date
  startdatefilter: 'Before' | 'After'; // display results for users with start date before or after the selected date
};

@Component({
  selector: 'app-universal-filter-category-item',
  templateUrl: './universal-filter-category-item.component.html',
  styleUrls: ['./universal-filter-category-item.component.scss'],
})
export class UniversalFilterCategoryItemComponent {

  public readonly eButtonType = ButtonType;

  @Input() key: DisplayCategory;
  @Input() properties: Category[];
  @Input() categoriesVisible: number;
  @Input() cachedDateFilter: {
    [column: string]: UniversalFilterDate;
  };

  buttonGroupOptions: ButtonGroupOption[];
  pickerVisible: boolean;
  form: FormGroup;

  @Output() onPropertyToggled: EventEmitter<UniversalFilterCategoryItemOutput>;

  get controlDatePicker(): FormControl {
    return this.form.controls.date as FormControl;
  }

  get controlTab(): FormControl {
    return this.form.controls.startdatefilter as FormControl;
  }

  get hasSomethingSelected(): boolean {
    if(this.properties.some((p) => p.enabled)) {
      return true;
    }

    if (this.cachedDateFilter[this.key.key]) {
      return true;
    }

    return false;
  }

  get propertiesEnabled(): Category[] {
    return this.properties.filter((p) => p.enabled);
  }
  
  get hasDatePicker(): boolean {
    return this.properties.some((p) => p.isDatePicker === true);
  }

  constructor(public globals: Globals) {
    this.key = undefined!;
    this.properties = [];
    this.categoriesVisible = 0;
    this.pickerVisible = true;
    this.cachedDateFilter = {};

    this.buttonGroupOptions = this.getButtonGroupOptions();
    this.form = this.initForm();

    this.onPropertyToggled = new EventEmitter<UniversalFilterCategoryItemOutput>();
  }

  toggleProperty($event: MouseEvent, property: Category, index: number): void {
    this.onPropertyToggled.emit(index);
  }

  initForm(): FormGroup {
    const formGroup = new FormGroup({
      date: new FormControl(null),
      startdatefilter: new FormControl(UniversalFilterDateDirection.Before),
    });

    formGroup.valueChanges.subscribe((value) => {
      this.onFormChanged(value);
    });

    return formGroup;
  }

  onFormChanged(value: UniversalFilterDate): void {
    this.onPropertyToggled.emit(value);
  }

  resetDate() {
    this.form = this.initForm();
    this.form.updateValueAndValidity();
    this.refreshDatePicker();
  }

  refreshDatePicker(): void {
    this.pickerVisible = false;
    setTimeout(() => {
      this.pickerVisible = true;
    }, 1);
  }

  getButtonGroupOptions(): ButtonGroupOption[] {
    return [
      {
        value: UniversalFilterDateDirection.Before,
        friendlyText: 'Before', // change this to translated string
        iconClasses: undefined,
        color: undefined,
      },
      {
        value: UniversalFilterDateDirection.After,
        friendlyText: 'After', // change this to translated string
        iconClasses: undefined,
        color: undefined,
      },
    ];
  }
}
