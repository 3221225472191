import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CreateFrankliEmailClickDto } from '@app/models/create-frankli-email-click.dto';
import { Observable } from 'rxjs';

@Injectable()
export class EmailAPIService {
  private readonly BASE_URL: string = '/api/email';

  constructor(
    private http: HttpClient
  ) { }

  createClick(token: string): Observable<string> {
    const url = `${this.BASE_URL}/click`;

    const createFrankliEmailClickDto = new CreateFrankliEmailClickDto(token);

    return this.http.post<string>(url, createFrankliEmailClickDto);
  }

  createAnonymousClick(token: string): Observable<string> {
    const url = `${this.BASE_URL}/click/anonymous`;

    const createFrankliEmailClickDto = new CreateFrankliEmailClickDto(token);

    return this.http.post<string>(url, createFrankliEmailClickDto);
  }

}
