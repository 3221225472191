import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { CommonMessages } from '@app/constants/common.messages';

export interface SortingOption {
  value: string;
  label: string;
}

@Component({
  selector: 'app-sorting-dropdown',
  templateUrl: './sorting-dropdown.component.html',
  styleUrls: ['./sorting-dropdown.component.scss']
})
export class SortingDropdownComponent {

  @ViewChild('selectInput') selectInput?: ElementRef<HTMLSelectElement>;

  @Input() control: FormControl;
  @Input() sortingOptions: SortingOption[];

  get selectedOptionLabel(): string {
    if(!this.control) { return CommonMessages.NONE; }
    if(!this.control.value) { return CommonMessages.NONE; }
    if(!this.sortingOptions) { return CommonMessages.NONE; }
    const matchingOption = this.sortingOptions.find(option => option.value === this.control.value);
    if(!matchingOption) { return CommonMessages.NONE; }
    return matchingOption.label;
  }

  constructor() {
    this.control = new FormControl(null, []);
    this.sortingOptions = [];
  }

  focusSelectInput(): void {
    if (!this.selectInput) { return; }
    this.selectInput.nativeElement.focus();
  }
}
