import { Component, Input, OnInit } from '@angular/core';
import { GoalsMessages } from '@app/goals/goals.messages';
import { GoalStats } from '@app/models/goals/goal-stats';

interface RoundProgress {
  left: string;
  right: string;
}

@Component({
  selector: 'app-goal-stats',
  templateUrl: './goals-stats.component.html',
  styleUrls: ['./goals-stats.component.scss']
})
export class GoalStatsComponent implements OnInit {
  public readonly eGoalMessages = GoalsMessages;

  @Input() stats: GoalStats = {
    numberOfActiveGoals: 0,
    numberOfGoalsCompletedInLast3Months: 0,
    averageCompletionPercentage: 0,
    numberOfGoalsDueInNext30Days: 0
  };

  constructor() {
  }

  ngOnInit(): void {
  }
}
