import {ISODateString} from '@app/shared/utils/date-string.util';
import moment from 'moment';
import {User} from '../../user/user.model';
import {CompanySetUpStepType} from './company-set-up-step-type.enum';

export interface CompanySetUpStepServerSide {
  id: number;
  type: CompanySetUpStepType;
  complete: boolean;
  completionDate: ISODateString | null;
  completionUser: User | null;
  skipped: boolean;
  skippedDate: ISODateString | null;
  skippedUser: User | null;
  stepOrder: number; // The order the step comes (ie. 1, 2, 3, 4)
  estimate: number | null; // (optional) estimate of how long the step will take to complete
  category: string;
}

export class CompanySetUpStep {
  id: number;
  type: CompanySetUpStepType;
  complete: boolean;
  completionDate: Date | null;
  completionUser: User | null;
  skipped: boolean;
  skippedDate: Date | null;
  skippedUser: User | null;
  stepOrder: number; // The order the step comes (ie. 1, 2, 3, 4)
  estimate: number | null; // (optional) estimate of how long the step will take to complete
  category: string;

  constructor(companySetUpStepServerSide: CompanySetUpStepServerSide) {
    this.id = companySetUpStepServerSide.id;
    this.type = companySetUpStepServerSide.type;
    this.complete = companySetUpStepServerSide.complete;
    this.completionDate = (companySetUpStepServerSide.completionDate === null) ? null : moment.utc(companySetUpStepServerSide.completionDate).toDate();
    this.completionUser = companySetUpStepServerSide.completionUser;
    this.skipped = companySetUpStepServerSide.skipped;
    this.skippedDate = (companySetUpStepServerSide.skippedDate === null) ? null : moment.utc(companySetUpStepServerSide.skippedDate).toDate();
    this.skippedUser = companySetUpStepServerSide.skippedUser;
    this.stepOrder = companySetUpStepServerSide.stepOrder;
    this.estimate = companySetUpStepServerSide.estimate;
    this.category = companySetUpStepServerSide.category;
  }

}