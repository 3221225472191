<div class="create-form-container">
  <div class="container-actions-row">
    <div class="actions-row-item" (click)="openViewTemplates()" *ngIf="selectedView !== ePageViews.TEMPLATES">
      <span class="fal fa-fw fa-file-import m-right-5"></span>
      <span translate>{{eGoalsMessages.TEMPLATES}}</span>
    </div>
    <div class="actions-row-item" (click)="openViewCreate()" *ngIf="selectedView !== ePageViews.MAIN">
      <span class="fal fa-fw fa-edit m-right-5"></span>
      <span translate>{{eCommonMessages.CREATE}}</span>
    </div>
    <div class="actions-row-item" (click)="openViewCopying()" *ngIf="selectedView !== ePageViews.COPYING">
      <span class="fal fa-fw fa-copy m-right-5"></span>
      <span translate>{{eGoalsMessages.COPY_A_GOAL}}</span>
    </div>
    <div class="actions-row-divider"></div>
    <div class="actions-row-item" [tooltip]="competenciesTooltip" placement="bottom" container="body" *ngIf="globals.hasFeature(eCompanyFeatures.COMPETENCIES)">
      <span class="fal fa-fw fa-sticky-note m-right-5"></span>
      <span translate>{{globals.getTerminology(eTerminologyEntity.COMPETENCY_PLURAL)}}</span>
      <ng-template #competenciesTooltip>
        <ng-container *ngIf="competencies.length > 0; else templateNoCompetencies">
          <div class="text-left p-bottom-5" *ngFor="let competency of competencies">
            <div><strong>{{competency.name}}</strong></div>
            <app-rich-display [text]="competency.description"></app-rich-display>
          </div>
        </ng-container>
        <ng-template #templateNoCompetencies>
          <div translate [translateParams]="globals.terminology">{{eCommonMessages.NO_COMPETENCIES}}</div>
        </ng-template>
      </ng-template>
    </div>
    <div class="actions-row-item" (click)="openHelpArticle()">
      <span class="fal fa-fw fa-lightbulb-on"></span>
      <span class="m-right-5"></span>
      <span translate>{{eGoalsMessages.GOAL_TIPS}}</span>
    </div>
    <div class="actions-row-item frankli-close" (click)="closeCreateModal()">
      <span class="fal fa-fw fa-times"></span>
    </div>
  </div>

  <div class="main-form-contents" cdk-scrollable>
    <ng-container *ngIf="goalForm" [ngSwitch]="selectedView">
      <ng-container *ngSwitchCase="ePageViews.MAIN">
        <app-goal-create-main [goalForm]="goalForm" [submitted]="state.submitted" [submittedDraft]="state.submittedDraft" [(furthestPart)]="furthestFormPart" [modal]="modal" #viewCreate></app-goal-create-main>
        <div class="primary-actions-row">
          <app-button [type]="eButtonType.GRAY_INVERTED" (onClick)="onCancelCreate()">
            <span translate>{{eCommonMessages.CANCEL}}</span>
          </app-button>
          <app-button [type]="eButtonType.GREEN_INVERTED" [loading]="state.processing" (onClick)="onSaveDraft()">
            <span>{{(isEditing ? eCommonMessages.UPDATE_DRAFT : eCommonMessages.SAVE_DRAFT) | translate}}</span>
          </app-button>
          <app-button [type]="eButtonType.GREEN" [loading]="state.processing" (onClick)="onConfirmCreate()">
            <span translate>{{eCommonMessages.CREATE}}</span>
          </app-button>
        </div>
      </ng-container>
      <ng-container *ngSwitchCase="ePageViews.TEMPLATES">
        <app-goal-create-templates (changeView)="onViewChangeRequested($event)" #viewTemplates></app-goal-create-templates>
      </ng-container>
      <ng-container *ngSwitchCase="ePageViews.COPYING">
        <app-goal-create-copying (changeView)="onViewChangeRequested($event)" #viewCopying></app-goal-create-copying>
      </ng-container>
    </ng-container>
  </div>
</div>