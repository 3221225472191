import { Component, Input, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';
import { GoalPriority } from '@app/models/goals/goal-priority.model';
import { GoalUtils } from '@app/shared/utils/goal.utils';
import { CommonGoalCreateFormPart } from '../../goal-create-views/goal-create-main/goal-create-main.component';

interface PriorityOption {
  priority: GoalPriority,
  friendlyText: string;
  icon: string;
  iconText: string;
}

@Component({
  selector: 'app-goal-create-part-priority',
  templateUrl: './goal-create-part-priority.component.html',
  styleUrls: ['./goal-create-part-priority.component.scss']
})
export class GoalCreatePartPriorityComponent implements CommonGoalCreateFormPart {
  public readonly eGoalPriority = GoalPriority;

  @Input() prioritiesAvailable: GoalPriority[];
  @Input() controlPriority: FormControl;
  @Input() submitted: boolean;
  @Input() submittedDraft: boolean;
  @Input() parentDropdownOpen: boolean;

  @Output() onSetParentDropdown: EventEmitter<boolean>;

  dataParsed: PriorityOption[];

  constructor() {
    this.prioritiesAvailable = [];
    this.dataParsed = [];
    this.submitted = false;
    this.submittedDraft = false;
    this.parentDropdownOpen = true;
    this.controlPriority = new FormControl(GoalPriority.NOT_SET, []);
    this.onSetParentDropdown = new EventEmitter<boolean>();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const prioritiesAvailable = changes['prioritiesAvailable'];
    if (prioritiesAvailable) {
      this.dataParsed = this.parseTypesAvailableMultiple(prioritiesAvailable.currentValue); // If types are changed after component load, refresh them
    }
  }

  parseTypesAvailableMultiple(priorities: GoalPriority[]): PriorityOption[] {
    return priorities.map(t => this.parseTypesAvailableSingle(t));
  }

  parseTypesAvailableSingle(priority: GoalPriority): PriorityOption {
    switch (priority) {
      case GoalPriority.NOT_SET:
        return {
          priority: priority,
          friendlyText: GoalUtils.getLongMessageCodeForGoalPriority(priority),
          icon: GoalUtils.getIconClassForGoalPriority(priority),
          iconText: GoalUtils.getMessageCodeForGoalPriority(priority)
        };
      case GoalPriority.P1:
        return {
          priority: priority,
          friendlyText: GoalUtils.getLongMessageCodeForGoalPriority(priority),
          icon: GoalUtils.getIconClassForGoalPriority(priority),
          iconText: GoalUtils.getMessageCodeForGoalPriority(priority)
        };
      case GoalPriority.P2:
        return {
          priority: priority,
          friendlyText: GoalUtils.getLongMessageCodeForGoalPriority(priority),
          icon: GoalUtils.getIconClassForGoalPriority(priority),
          iconText: GoalUtils.getMessageCodeForGoalPriority(priority)
        };
      case GoalPriority.P3:
        return {
          priority: priority,
          friendlyText: GoalUtils.getLongMessageCodeForGoalPriority(priority),
          icon: GoalUtils.getIconClassForGoalPriority(priority),
          iconText: GoalUtils.getMessageCodeForGoalPriority(priority)
        };
      case GoalPriority.P4:
        return {
          priority: priority,
          friendlyText: GoalUtils.getLongMessageCodeForGoalPriority(priority),
          icon: GoalUtils.getIconClassForGoalPriority(priority),
          iconText: GoalUtils.getMessageCodeForGoalPriority(priority)
        };
      case GoalPriority.P5:
        return {
          priority: priority,
          friendlyText: GoalUtils.getLongMessageCodeForGoalPriority(priority),
          icon: GoalUtils.getIconClassForGoalPriority(priority),
          iconText: GoalUtils.getMessageCodeForGoalPriority(priority)
        };
      default:
        return {
          priority: priority,
          friendlyText: GoalUtils.getLongMessageCodeForGoalPriority(null),
          icon: GoalUtils.getIconClassForGoalPriority(null),
          iconText: GoalUtils.getMessageCodeForGoalPriority(null)
        };
    }

  }

  changeSelection(priority: GoalPriority): void {
    if (this.controlPriority.value !== priority) {
      this.controlPriority.setValue(priority);
    }

    if (!this.parentDropdownOpen) {
      this.controlPriority.setValue(null);
      this.setParentDropdown(true);
    }
  }

  setParentDropdown(expanded: boolean): void {
    this.onSetParentDropdown.emit(expanded);
  }

  focus(): void {
    // TODO:
  }
}
