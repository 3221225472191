<div class="container-sorting-dropdown">
  <div class="area-front">
    <span>Sort by:</span>
    <span class="m-right-5"></span>
    <span>{{selectedOptionLabel | translate}}</span>
    <span class="m-right-5"></span>
    <!-- <span class="fal fa-fw fa-arrow-down-wide-short"></span> -->
    <span class="fal fa-fw fa-chevron-down"></span>
  </div>
  <div class="area-back">
    <select [formControl]="control" #selectInput>
      <ng-container *ngFor="let option of sortingOptions">
        <ng-container *ngIf="option.label">
          <option [value]="option.value">{{option.label | translate}}</option>
        </ng-container>
      </ng-container>
    </select>
  </div>
</div>
