import {Injectable} from '@angular/core';
import {CompanySetUpStepType} from '@app/models/company/company-set-up-steps/company-set-up-step-type.enum';
import {CompanySetUpStep} from '@app/models/company/company-set-up-steps/company-set-up-step.model';
import {SetUpStepsAPIService} from '../api/company/set-up-steps.api.service';
import {Globals} from '../globals/globals';


@Injectable()
export class SetUpStepUtils {

  constructor(
      public globals: Globals,
      private setUpStepsAPIService: SetUpStepsAPIService
  ) {
  }

  getIconForStepType(type: CompanySetUpStepType): string {
    switch (type) {
      case CompanySetUpStepType.CREATE_COMPANY_GOALS:
        return 'fa-bullseye';
      case CompanySetUpStepType.CREATE_COMPETENCIES:
        return 'fa-sticky-note';
      case CompanySetUpStepType.CREATE_DEFAULT_TALKING_POINTS:
        return 'fa-comment-check';
      case CompanySetUpStepType.CREATE_DEPARTMENTS:
        return 'fa-users';
      case CompanySetUpStepType.CREATE_OFFICE_LOCATIONS:
        return 'fa-building';
      case CompanySetUpStepType.CREATE_POSITIONS:
        return 'fa-briefcase';
      case CompanySetUpStepType.CREATE_TAGS:
        return 'fa-tag';
      case CompanySetUpStepType.CREATE_USERS:
        return 'fa-user-plus';
      case CompanySetUpStepType.INVITE_ALL_USERS:
        return 'fa-envelope';
      case CompanySetUpStepType.SET_FEATURES:
        return 'fa-list-check';
      case CompanySetUpStepType.SET_FISCAL_YEAR:
        return 'fa-calendar-day';
      case CompanySetUpStepType.SET_GOAL_PERMISSIONS:
        return 'fa-bullseye-pointer';
      case CompanySetUpStepType.VIEW_INTEGRATIONS:
        return 'fa-object-intersect';
      case CompanySetUpStepType.CONFIGURE_UNPROMPTED_FEEDBACK:
        return 'fa-comment-arrow-up-right';
      default:
        return 'Unknown step';
    }
  };

  // TODO: Translation strings
  getTitleForStepType(type: CompanySetUpStepType): string {
    switch (type) {
      case CompanySetUpStepType.CREATE_COMPANY_GOALS:
        return 'Create 3 company goals';
      case CompanySetUpStepType.CREATE_COMPETENCIES:
        return 'Competencies';
      case CompanySetUpStepType.CREATE_DEFAULT_TALKING_POINTS:
        return 'Talking point templates';
      case CompanySetUpStepType.CREATE_DEPARTMENTS:
        return 'Departments';
      case CompanySetUpStepType.CREATE_OFFICE_LOCATIONS:
        return 'Sites';
      case CompanySetUpStepType.CREATE_POSITIONS:
        return 'Roles';
      case CompanySetUpStepType.CREATE_TAGS:
        return 'Tags';
      case CompanySetUpStepType.CREATE_USERS:
        return 'Add your people';
      case CompanySetUpStepType.INVITE_ALL_USERS:
        return 'Invite your people';
      case CompanySetUpStepType.SET_FEATURES:
        return 'Features';
      case CompanySetUpStepType.SET_FISCAL_YEAR:
        return 'Fiscal year';
      case CompanySetUpStepType.SET_GOAL_PERMISSIONS:
        return 'Goal admins';
      case CompanySetUpStepType.VIEW_INTEGRATIONS:
        return 'Integrations';
      case CompanySetUpStepType.CONFIGURE_UNPROMPTED_FEEDBACK:
        return 'Unprompted feedback';
      default:
        return 'Unknown step';
    }
  }

  // TODO: Translation strings
  getDescriptionForStepType(type: CompanySetUpStepType): string {
    switch (type) {
      case CompanySetUpStepType.CREATE_COMPANY_GOALS:
        return 'Create 3 company goals to get started with OKRs in Frankli. You must be a Goal Admin to create company level goals.';
      case CompanySetUpStepType.CREATE_COMPETENCIES:
        return 'Add your company\'s competencies';
      case CompanySetUpStepType.CREATE_DEFAULT_TALKING_POINTS:
        return 'Create your default 1:1s talking points';
      case CompanySetUpStepType.CREATE_DEPARTMENTS:
        return 'Add your departments to group goals, people and other priorities by departments';
      case CompanySetUpStepType.CREATE_OFFICE_LOCATIONS:
        return 'Add your sites and benefit from rich search, filters and groupings by site priorities';
      case CompanySetUpStepType.CREATE_POSITIONS:
        return 'Add in your peoples roles and grades';
      case CompanySetUpStepType.CREATE_TAGS:
        return 'Tags provide you with the ability to share your company values and group important activities by categories. We\'ve added some already to get you started.';
      case CompanySetUpStepType.CREATE_USERS:
        return 'Add any people you want to start using Frankli. You don\'t have to invite them yet.';
      case CompanySetUpStepType.INVITE_ALL_USERS:
        return 'Send an invitation to all the people you\'ve added to Frankli';
      case CompanySetUpStepType.SET_FEATURES:
        return 'Choose what features your people will have access to';
      case CompanySetUpStepType.SET_FISCAL_YEAR:
        return 'Set the start of your company\'s fiscal year';
      case CompanySetUpStepType.SET_GOAL_PERMISSIONS:
        return 'Set your company\'s goal admin';
      case CompanySetUpStepType.VIEW_INTEGRATIONS:
        return 'Check out all the integrations we have to offer.';
      case CompanySetUpStepType.CONFIGURE_UNPROMPTED_FEEDBACK:
        return 'Review who receives the different types of feedback. You don\'t have to change anything here and can keep the default configuration we provide.';
      default:
        return 'Unknown step';
    }
  }

  getHelpArticleForStepType(type: CompanySetUpStepType): string {
    switch (type) {
      case CompanySetUpStepType.CREATE_COMPANY_GOALS:
        return 'https://help.frankli.io/en/articles/2554635-creating-a-company-goal';
      case CompanySetUpStepType.CREATE_COMPETENCIES:
        return 'https://help.frankli.io/en/articles/3665541-competency-library';
      case CompanySetUpStepType.CREATE_DEFAULT_TALKING_POINTS:
        return 'https://help.frankli.io/en/articles/3854138-edit-preset-talking-points';
      case CompanySetUpStepType.CREATE_DEPARTMENTS:
        return 'https://help.frankli.io/en/articles/3621816-how-to-add-edit-or-delete-departments';
      case CompanySetUpStepType.CREATE_OFFICE_LOCATIONS:
        return 'https://help.frankli.io/en/articles/3278069-how-to-add-change-or-delete-office-locations';
      case CompanySetUpStepType.CREATE_POSITIONS:
        return 'https://help.frankli.io/en/articles/3664300-administrating-roles';
      case CompanySetUpStepType.CREATE_TAGS:
        return 'https://help.frankli.io/en/articles/3268912-creating-and-administrating-tags-as-an-admin';
      case CompanySetUpStepType.CREATE_USERS:
        return 'https://help.frankli.io/en/articles/2557192-setting-up-new-people';
      case CompanySetUpStepType.INVITE_ALL_USERS:
        return 'https://help.frankli.io/en/articles/2898790-inviting-pending-people';
      case CompanySetUpStepType.SET_FEATURES:
        return 'https://help.frankli.io/en/articles/3278381-how-to-toggle-features-on-and-off';
      case CompanySetUpStepType.SET_FISCAL_YEAR:
        return 'https://help.frankli.io/en/articles/4421581-how-to-set-the-start-of-your-fiscal-year';
      case CompanySetUpStepType.SET_GOAL_PERMISSIONS:
        return 'https://help.frankli.io/en/articles/3455746-how-to-set-up-and-delete-a-goal-admin';
      case CompanySetUpStepType.VIEW_INTEGRATIONS:
        return null;
      case CompanySetUpStepType.CONFIGURE_UNPROMPTED_FEEDBACK:
        return 'https://help.frankli.io/en/articles/3268955-configuring-unprompted-feedback-as-an-admin';
      default:
        return null;
    }
  }

  checkIsStepComplete(step: CompanySetUpStep): boolean {
    if (!step) {
      return false;
    }
    if (step.complete) {
      return true;
    }
    if (step.skipped) {
      return true;
    }
    return false;
  }

  markStepAsComplete(stepType: CompanySetUpStepType): void {
    if (!stepType) {
      return;
    }
    if (!this.globals) {
      return;
    }
    if (!this.globals.company) {
      return;
    }
    if (!this.globals.company.setUpSteps) {
      return;
    }

    const step = this.globals.company.setUpSteps.find(s => s.type === stepType);

    if (!step) {
      return;
    }
    if (step.complete) {
      return;
    }

    this.setUpStepsAPIService.completeCompanySetUpStepType(stepType).toPromise().then();
  }

  refreshSetUpSteps(): Promise<void> {
    return this.setUpStepsAPIService.getSetUpSteps().toPromise().then(steps => {
      if (!this.globals) {
        return;
      }
      if (!this.globals.company) {
        return;
      }

      if (this.globals.company.setUpSteps !== steps) {
        this.globals.company.setUpSteps = steps;
      }
    });
  }
}
