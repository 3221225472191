<ng-container *ngIf="schedule">
  <div class="schedule-display-container" (click)="onClickSchedule()" [class.is-selected]="selected" [class.can-select]="canSelect" [class.can-deselect]="canDeselect" [class.clickable]="canSelect || canDeselect || canOpen">
    <div class="display-schedule-status" [ngClass]="displayStatusData.statusDisplay" [title]="displayStatusData.statusDisplayFriendlyName"></div>

    <div class="area-schedule-data" [class.clickable]="canSeeActionView" (click)="onClickScheduleData()">
      <ng-container *ngIf="showIcon">
        <span class="fal fa-fw display-schedule-type" [ngClass]="displayTypeData.iconClass" [tooltip]="displayTypeData.tooltip.code | translate: displayTypeData.tooltip.params" [triggers]="globals.tooltipTriggers" placement="bottom"></span>
      </ng-container>

      <div class="display-schedule-users">
        <ng-container *ngIf="showUsers">
          <div class="wrapper-schedule-manager">
            <div class="owner-img">
              <app-profile-photo [src]="schedule.manager.imageUrl" [firstName]="schedule.manager.firstName" [lastName]="schedule.manager.lastName" [title]="schedule.manager.firstName + ' ' + schedule.manager.lastName"></app-profile-photo>
            </div>
            <span class="fas fa-fw fa-crown text-warning"></span>
          </div>
          <app-person-picture-list [users]="schedule.participants"></app-person-picture-list>
        </ng-container>
      </div>

      <div class="display-schedule-title-and-subtext">
        <div class="display-schedule-title">
          <span>{{schedule.purposeTitle}}</span>

          <ng-container *ngIf="schedule.evaluationCycleId">
            <span class="m-right-5"></span>
            <app-pill [type]="ePillType.GREEN">
              <small>{{eOneToOneMessages.LINKED_TO_REVIEW_CYCLE | translate}}</small>
            </app-pill>
          </ng-container>

          <ng-container *ngIf="schedule.status !== eOneToOneStatus.ACTIVE">
            <span class="m-right-10"></span>
            <app-pill [type]="displayStatusData.pillType" [minWidth]="0" [tooltip]="displayStatusData.tooltip.code | translate: displayStatusData.tooltip.params" [triggers]="globals.tooltipTriggers" placement="bottom">
              <small>
                <span class="fal fa-fw" [ngClass]="displayStatusData.iconClass"></span>
                <ng-container [ngSwitch]="schedule.status">
                  <ng-container *ngSwitchCase="eOneToOneStatus.PAUSED">
                    <span class="m-right-5"></span>
                    <span translate>{{eOneToOneMessages.PAUSED}}</span>
                  </ng-container>
                  <ng-container *ngSwitchCase="eOneToOneStatus.ARCHIVED">
                    <span class="m-right-5"></span>
                    <span translate>{{eCommonMessages.ARCHIVED}}</span>
                  </ng-container>
                  <ng-container *ngSwitchCase="eOneToOneStatus.COMPLETED">
                    <span class="m-right-5"></span>
                    <span translate>{{eOneToOneMessages.COMPLETED}}</span>
                  </ng-container>
                </ng-container>
              </small>
            </app-pill>
          </ng-container>
        </div>

        <div class="display-schedule-subtext">
          <ng-container *ngIf="schedule.meetingCount">
            <span>{{eOneToOneMessages.MEETING_COUNT(schedule.meetingCount) | translate: {count: schedule.meetingCount} }}</span>
            <span class="m-right-5"></span>
            <span>-</span>
          </ng-container>
          <ng-container *ngIf="schedule.meetingList">
            <span>{{eOneToOneMessages.MEETING_COUNT(schedule.meetingList.length) | translate: {count: schedule.meetingList.length} }}</span>
            <span class="m-right-5"></span>
            <span>-</span>
          </ng-container>
          <span class="m-right-5"></span>
          <span>{{schedule.frequency | underscoreToSpace | titlecase}}</span>
          <ng-container *ngIf="displayLatestMeetingData && displayLatestMeetingData.meeting && displayLatestMeetingData.meeting.meetingTimestamp; else templateDisplayLastMeetingData;">
            <span class="m-right-5"></span>
            <span>-</span>
            <span class="m-right-5"></span>
            <span>{{eOneToOneMessages.LATEST_MEETING_ON | translate: {timestamp: displayLatestMeetingData.meeting.meetingTimestamp | momentPipe: DATE_FORMAT} }}</span>
            <ng-container *ngIf="displayLatestMeetingData.isUsingDifferentTimezone">
              <span class="m-right-5"></span>
              <span class="fal fa-fw fa-globe" [tooltip]="tooltipOtherTimezoneDetails" [placement]="'bottom'" [triggers]="globals.tooltipTriggers"></span>
              <ng-template #tooltipOtherTimezoneDetails>
                <div class="lh-normal">
                  <span>{{eOneToOneMessages.LATEST_MEETING_ON_OTHER_TIMEZONE | translate: { date: displayLatestMeetingData.meetingTimeInTimezone | underscoreToSpace, timezone: schedule.timezone | underscoreToSpace } }}</span>
                </div>
              </ng-template>
            </ng-container>
          </ng-container>
        </div>
      </div>
    </div>

    <ng-container *ngIf="canSelect">
      <div class="area-select-checkbox">
        <div class="check-container">
          <span class="fal fa-fw fa-check"></span>
        </div>
      </div>
    </ng-container>
  
    <ng-container *ngIf="canDeselect && selected">
      <div class="area-deselect-checkbox">
        <div class="times-container">
          <span class="fal fa-fw fa-times"></span>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="actionsShown && actionsShown.length > 0">
      <div class="col-actions">
        <ng-container *ngIf="!isChanging; else actionsLoading">
          <app-table-action-icon *ngIf="canSeeActionView" [icon]="'fa-eye'" (click)="clickAction('view')" [tooltipText]="eOneToOneMessages.VIEW_SCHEDULE_BREADCRUMB"></app-table-action-icon>
          <app-table-action-icon *ngIf="canSeeActionEdit" [icon]="'fa-edit'" (click)="clickAction('edit')" [hoverColor]="eIconHoverColor.YELLOW" [tooltipText]="eOneToOneMessages.EDIT_SCHEDULE_TOOLTIP"></app-table-action-icon>
          <app-table-action-icon *ngIf="canSeeActionRecreateCalendar" [icon]="'fa-calendar-day'" (click)="clickAction('recreateCalendar')" [hoverColor]="eIconHoverColor.RED" [tooltipText]="'Re-create events in calendar'"></app-table-action-icon>
          <app-table-action-icon *ngIf="canSeeActionPause" [icon]="'fa-pause-circle'" (click)="clickAction('pause')" [hoverColor]="eIconHoverColor.RED" [tooltipText]="eOneToOneMessages.PAUSE_SCHEDULE_TOOLTIP"></app-table-action-icon>
          <app-table-action-icon *ngIf="canSeeActionResume" [icon]="'fa-play-circle'" (click)="clickAction('resume')" [hoverColor]="eIconHoverColor.GREEN" [tooltipText]="eOneToOneMessages.RESUME_SCHEDULE_TOOLTIP"></app-table-action-icon>
          <app-table-action-icon *ngIf="canSeeActionTransfer" [icon]="'fa-calendar-circle-user'" (click)="clickAction('transfer')" [hoverColor]="eIconHoverColor.RED" [tooltipText]="eOneToOneMessages.TRANSFER_SCHEDULE"></app-table-action-icon>
          <app-table-action-icon *ngIf="canSeeActionArchive" [icon]="'fa-archive'" (click)="clickAction('archive')" [hoverColor]="eIconHoverColor.NAVY" [tooltipText]="eOneToOneMessages.ARCHIVE_SCHEDULE_TOOLTIP"></app-table-action-icon>
          <app-table-action-icon *ngIf="canSeeActionUnarchive" [icon]="'fa-folder-open'" (click)="clickAction('unarchive')" [hoverColor]="eIconHoverColor.GREEN" [tooltipText]="eOneToOneMessages.UNARCHIVE_SCHEDULE_TOOLTIP"></app-table-action-icon>
          <app-table-action-icon *ngIf="canSeeActionDelete" [icon]="'fa-trash-alt'" (click)="clickAction('delete')" [hoverColor]="eIconHoverColor.NAVY" [tooltipText]="eOneToOneMessages.DELETE_SCHEDULE_TOOLTIP"></app-table-action-icon>
        </ng-container>
        <ng-template #actionsLoading>
          <span class="fas fa-spinner fa-spin" [title]="eOneToOneMessages.LOADING_SPINNER_TOOLTIP | translate"></span>
        </ng-template>
      </div>
    </ng-container>
  </div>
</ng-container>