import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { initFormGroupOptionItem } from '@app/admin/admin-modules/admin-evaluations/admin-evaluations-components/administrate-schedule-cycle/administrate-schedule-cycle-question-banks/schedule-cycle-add-question/schedule-cycle-add-question.component';
import { DynamicScalePossibleAnswer } from '@app/models/evaluation/dynamic-scale-possible-answer.model';
import { IconHoverColor } from '@app/shared/components/inputs/table-action-icon/table-action-icon.component';

export enum DynamicScaleOptionComponentAction {
  SELECT = 'SELECT',
  REMOVE = 'REMOVE'
}

@Component({
  selector: 'app-dynamic-scale-option',
  templateUrl: './dynamic-scale-option.component.html',
  styleUrls: ['./dynamic-scale-option.component.scss']
})
export class DynamicScaleOptionComponent {
  public readonly eIconHoverColor = IconHoverColor;
  public readonly eDynamicScaleOptionComponentAction = DynamicScaleOptionComponentAction;

  @Input() option: DynamicScalePossibleAnswer;
  @Input() optionEdit: FormGroup;
  @Input() index: number;

  @Input() editing: boolean;
  @Input() selected: boolean;
  @Input() disabled: boolean;
  @Input() submitted: boolean;

  @Output() onClickAction: EventEmitter<DynamicScaleOptionComponentAction>;
  
  get controlEditIndex(): FormControl {
    return this.optionEdit.controls.answerIndex as FormControl;
  }

  get controlEditColour(): FormControl {
    return this.optionEdit.controls.colour as FormControl;
  }

  get controlEditValue(): FormControl {
    return this.optionEdit.controls.answerValue as FormControl;
  }

  get controlEditTitle(): FormControl {
    return this.optionEdit.controls.labelTitle as FormControl;
  }

  get controlEditDescription(): FormControl {
    return this.optionEdit.controls.labelDescription as FormControl;
  }

  constructor() {
    this.option = undefined;
    this.optionEdit = initFormGroupOptionItem();
    this.index = 0;

    this.editing = false;
    this.selected = false;
    this.disabled = false;
    this.submitted = false;

    this.onClickAction = new EventEmitter<DynamicScaleOptionComponentAction>();
  }

  doAction(action: DynamicScaleOptionComponentAction, event?: Event): void {
    if (event) {
      event.preventDefault();
    }

    this.onClickAction.emit(action);
  }

  getTextColorByBackgroundColor(bgColor: string): string {
    if (!bgColor) { return ''; }
    return (parseInt(bgColor.replace('#', ''), 16) > 0xffffff / 2) ? '#000' : '#fff';
  }
}
