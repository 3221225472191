<div class="side-panel noselect" [class.side-panel-hidden]="notificationsShown">
  <ng-container *ngIf="notificationsShown">
    <ng-container *ngIf="connected || isMock; else websocketDisconnected">

      <!-- Top buttons -->
      <div class="side-panel-header">
        <div class="">
          <div class="col-xs-6 text-center notification-section"
            [class.notification-section-active]="sidebarTab === eSidebarTabs.NOTIFICATIONS"
            (click)="sidebarTab = eSidebarTabs.NOTIFICATIONS">
            <a class="nav-link">{{eNavbarMessages.NOTIFICATIONS | translate }} ({{notifications.length}})</a>
          </div>
          <div class="col-xs-6 text-center notification-section"
            [class.notification-section-active]="sidebarTab === eSidebarTabs.ACTIONS"
            (click)="sidebarTab = eSidebarTabs.ACTIONS">
            <a class="nav-link">{{eNavbarMessages.ACTIONS | translate }} ({{actions.length}})</a>
          </div>
        </div>
      </div>

      <!-- Notifications Tab -->
      <div *ngIf="sidebarTab === eSidebarTabs.NOTIFICATIONS">

        <!-- Mark all as read -->
        <ng-container *ngIf="notifications.length > 0">
          <div class="notification-row text-center m-top-10">
            <a (click)="sendMarkAllAsRead()" class="clickable" translate>{{eNavbarMessages.MARK_ALL_AS_READ}}</a>
          </div>
        </ng-container>
        <div class="side-panel-content">

          <!-- Empty State -->
          <ng-container *ngIf="notifications.length === 0">
            <div class="p-top-20 p-bottom-20 text-center">
              <img src="/assets/img/swal-icons/frankli-success-icon.svg" />
              <br/>
              <span translate>{{eNavbarMessages.NO_NOTIFICATIONS_MESSAGE}}</span>
            </div>
          </ng-container>

          <!-- Notifications List -->
          <cdk-virtual-scroll-viewport [style]="getNotificationViewportHeight()" itemSize="71">
              <div *cdkVirtualFor="let n of notifications; index as i; trackBy: trackByNotifications" class="row notification-row clickable notif-highlight"
                [ngClass]="(n.eventStatus==='READ')?'bg-read':'bg-unread'">

                <div class="col-xs-2 text-center p-left-0 p-right-0 p-top-15" (click)="navigateNotification(n)">
                  <span class="notification-image">
                    <ng-container *ngIf="n.imageUrl">
                      <app-profile-photo [src]="n.imageUrl"></app-profile-photo>
                    </ng-container>
                  </span>
                </div>
                <div class="col-xs-9 p-left-0 p-top-10" (click)="navigateNotification(n)">
                  <div class="notification-text clickable" [title]="n.text">{{n.text}}</div>
                  <div>
                    <a class="notification-user clickable"
                      (click)="navigateNotificationUser(n.userId)">{{(n.user !== undefined)?(n.user.firstName + ' ' + n.user.lastName):''}}</a>
                    <span class="notification-timestamp">- {{n.timestamp | dateAgo}}</span>
                  </div>
                </div>
                <div class="col-xs-1 p-left-0 p-right-0 p-top-15">
                  <span *ngIf="isMarkingAsRead(n) === false"
                    class="fal fa-fw fa-times notification-mark-read expand-on-hover frankli-close"
                    (click)="sendMarkAsRead(n)"></span>
                  <span *ngIf="isMarkingAsRead(n) === true"
                    class="fas fa-fw fa-spin fa-spinner notification-mark-read expand-on-hover frankli-close"></span>
                </div>
              </div>
          </cdk-virtual-scroll-viewport>

        </div>
      </div>

      <!-- Actions Tab -->
      <div class="side-panel-content" *ngIf="sidebarTab === eSidebarTabs.ACTIONS">
        <!-- Empty State -->
        <ng-container *ngIf="actions.length === 0">
          <div class="row m-top-20">
            <div class="col-xs-12 text-center">
              <img src="/assets/img/swal-icons/frankli-success-icon.svg" />
            </div>
          </div>
          <div class="row m-bottom-20">
            <div class="col-xs-12 text-center" translate>{{eNavbarMessages.NO_ACTIONS_MESSAGE}}</div>
          </div>
        </ng-container>

        <!-- Actions List -->
        <cdk-virtual-scroll-viewport [style]="getTaskViewportHeight()" itemSize="73">
          <div *cdkVirtualFor="let n of actions;" class="row notification-row clickable notif-highlight"
            [ngClass]="(n.viewed === true)?'bg-read':'bg-unread'" (click)="navigateNotification(n)">
            <div class="col-xs-2 text-center p-left-0 p-right-0 p-top-15">
              <span class="notification-image">
                 <ng-container *ngIf="n.imageUrl">
                   <app-profile-photo [src]="n.imageUrl"></app-profile-photo>
                 </ng-container>
              </span>
            </div>
            <div class="col-xs-10 p-left-0 p-top-10">
              <div class="notification-text" [title]="n.text">{{n.text}}</div>
              <div>
                <a class="clickable notification-user"
                  (click)="navigateNotificationUser(n.userId)">{{(n.user !== undefined)?(n.user.firstName + ' ' + n.user.lastName):''}}</a>
                <span class="notification-timestamp">- {{n.timestamp | dateAgo}}</span>
              </div>
            </div>
            <!-- <div class="col-md-1">
          <span class="fal fa-times clickable" (click)="hideNotification(n)"></span>
        </div> -->
          </div>
        </cdk-virtual-scroll-viewport>

      </div>
    </ng-container>

    <!-- Websocket connection lost state -->
    <ng-template #websocketDisconnected>
      <div class="row m-top-50 m-bottom-50">
        <div class="col-xs-12 text-center">
          <span class="fas fa-spin fa-spinner fa-fw fa-2x"></span>
        </div>
      </div>
      <div class="row m-bottom-20 m-top-10">
        <div class="col-xs-12 text-center">
          <div>
            Connection lost, reconnecting...
          </div>
        </div>
      </div>
    </ng-template>
  </ng-container>
</div>