import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { CommonMessages } from '@app/constants/common.messages';
import { TaskCreateComponent, initTaskForm } from '@app/domain/task/component/create-task/task-create.component';
import { TaskCreatedFrom } from '@app/domain/task/model/task-created-from.model';
import { TaskStatus } from '@app/domain/task/model/task-status.enum';
import { Task } from '@app/domain/task/model/task.model';
import { TaskBusinessService } from '@app/domain/task/service/task-business.service';
import { TaskUtilsService } from '@app/domain/task/service/task-utils.service';
import { IState } from '@app/models/state/state.model';
import { ButtonType } from '@app/shared/components/inputs/button/button.component';
import { Globals } from '@app/shared/globals/globals';
import { ModalComponent } from '@app/shared/modal/modal.component';
import { PaginationNewComponent } from '@app/shared/pagination/pagination-new/pagination-new.component';
import { SwalUtils } from '@app/shared/utils/swal.utils';
import { Subject } from 'rxjs';
import { SweetAlertOptions } from 'sweetalert2';

interface PageState extends IState {
  submitted: boolean;
  submitting: boolean;
}

enum ModalType {
  CREATE = 'CREATE',
  DETAILS = 'DETAILS'
}

type TabEntity = Task;

@Component({
  selector: 'app-information-sidebar-tasks',
  templateUrl: './information-sidebar-tasks.component.html',
  styleUrls: ['./information-sidebar-tasks.component.scss']
})
export class InformationSidebarTasksComponent implements OnInit, OnDestroy {
  public readonly eButtonType = ButtonType;
  public readonly eModalType = ModalType;
  public readonly confirmCloseSettings: SweetAlertOptions = {
    title: CommonMessages.UNSAVED_CHANGES,
    text: CommonMessages.UNSAVED_CHANGES_WARNING,
    confirmButtonText: CommonMessages.STAY,
    cancelButtonText: CommonMessages.LEAVE
  };

  private ngUnsubscribe$: Subject<void> = new Subject<void>();
  
  @ViewChild('modal') modal?: ModalComponent;
  @ViewChild('pagination') pagination?: PaginationNewComponent;
  @ViewChild('componentCreate') componentCreate?: TaskCreateComponent;

  @Input() userIds: number[];

  state: PageState;
  data: {
    master: TabEntity[];
    filtered: TabEntity[];
    display: TabEntity[];
  };
  taskViewing: Task;
  modalType: ModalType;

  formCreate: FormGroup;

  constructor(
    public globals: Globals,
    private swalUtils: SwalUtils,
    private taskBusinessService: TaskBusinessService
  ) {
    this.userIds = [];

    this.taskViewing = undefined;

    this.state = {
      loading: true,
      error: false,
      errorMessage: '',
      submitted: false,
      submitting: false
    };

    this.data = {
      master: [],
      filtered: [],
      display: []
    };

    this.formCreate = initTaskForm(TaskCreatedFrom.OVERVIEW);
  }

  ngOnInit(): void {
    this.getData();
  }
  
  ngOnDestroy(): void {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.unsubscribe();
  }

  getData(): void {
    if (!this.userIds) { return; }
    if (this.userIds.length === 0) { return; }

    this.taskBusinessService.getTasks(null, [], this.userIds, [], [], [], false)
      .subscribe(data => {
        data = this.sortData(data);
        this.populateData(data);
        this.state.loading = false;
      });
  }

  sortData(data: TabEntity[]): TabEntity[] {
    // Order by date, might be null
    data = data.sort((a, b) => {
      if (a.dueDate && b.dueDate) {
        return a.dueDate.getTime() - b.dueDate.getTime();
      } else if (a.dueDate) {
        return -1;
      } else if (b.dueDate) {
        return 1;
      } else {
        return 0;
      }
    });

    // Order by status -> COMPLETE, NOT_STARTED, IN_PROGRESS
    data = data.sort((a, b) => {
      const aStatus = TaskStatus[a.status];
      const bStatus = TaskStatus[b.status];

      if (aStatus === TaskStatus.IN_PROGRESS && bStatus !== TaskStatus.IN_PROGRESS) {
        return -1;
      } else if (aStatus !== TaskStatus.IN_PROGRESS && bStatus === TaskStatus.IN_PROGRESS) {
        return 1;
      } else if (aStatus === TaskStatus.NOT_STARTED && bStatus === TaskStatus.COMPLETE) {
        return -1;
      } else if (aStatus === TaskStatus.COMPLETE && bStatus === TaskStatus.NOT_STARTED) {
        return 1;
      } else {
        return 0;
      }
    });

    return data;
  }

  populateData(data?: TabEntity[]): void {
    if (!data) { data = this.data.master; }
    this.data.master = data;
    this.data.filtered = data;

    // this.universalFilterData.searchProps = ['name'];
    // this.universalFilterData.filterOptions = data.map(i => this.getFilterOptionsForBrowseRole(i));

    this.refreshPagination();
  }

  refreshPagination(): void {
    if (this.pagination) {
      this.pagination.update();
    }
  }

  onTaskAction(event: string, task: Task): void {
    switch (event) {
      case 'view':
        this.startViewTask(task);
        break;
      case 'edit':
        this.startEditTask(task);
        break;
      case 'archive':
        this.startArchiveTask(task);
        break;
    }
  }

  startViewTask(task: Task): void {
    this.taskViewing = task;
    this.showModal(ModalType.DETAILS);
  }

  showModal(modalType: ModalType): void {
    this.modalType = modalType;
    if (this.modal) {
      this.modal.show();
    }
  }

  hideModal(): void {
    if (this.modal) {
      this.modal.hide();
    }
  }

  startEditTask(task: Task): void {
    const taskCopy = JSON.parse(JSON.stringify(task));
    this.formCreate = initTaskForm(TaskCreatedFrom.OVERVIEW, taskCopy);
    this.showModal(ModalType.CREATE);
  }

  startArchiveTask(task: Task): void {
    if (this.taskBusinessService.updating.includes(task.id)) { return; }

    const buttonText = TaskUtilsService.getTooltipArchive(task);

    this.swalUtils.displayWarningConfirmationSwal({
      text: CommonMessages.ACTION_CANNOT_BE_UNDONE,
      confirmButtonText: buttonText
    }).then(result => {
      if (result.isConfirmed) {
        this.taskBusinessService.archiveTask(task.id)
          .then(() => {
            this.taskUpdated();
            this.hideModal();
          });
      }
    });
  }

  taskUpdated(): void {
    this.getData();
  }
}
