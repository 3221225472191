<div class="breadcrumbs-container" *ngIf="breadcrumbs.length > 1">
  <a class="clickable hidden-print" (click)="goBack()">
    <span class="fal fa-fw fa-arrow-left"></span>
    <span class="m-right-5"></span>
    <span translate>{{eCommonMessages.GO_BACK}}</span>
  </a>

  <ol class="breadcrumb" [attr.data-intercom-target]="'Breadcrumbs'">
    <ng-container *ngFor="let breadcrumb of breadcrumbs; index as i">
      <li *ngIf="i+1 < breadcrumbs.length" class="clickable">
        <a [routerLink]="breadcrumb.route" [queryParams]="breadcrumb.queryParams" translate>
          {{breadcrumb.label}}
        </a>
      </li>
      <li *ngIf="i+1 === breadcrumbs.length" class="active" translate>
        {{breadcrumb.label}}
      </li>
    </ng-container>
  </ol>
</div>