<div class="container-profile-goals">
  <div class="content">
    <ng-container *ngIf="userProfile.id === globals.user.id; else templateOtherPersonsProfile;">
      <div class="heading-link">
        <h4 class="standard-heading">{{userProfile?.firstName}}'s Personal Goals</h4>
        <div class="standard-description">
          <a class="view-all-link clickable" [routerLink]="['/goals']">View all Goals</a>
        </div>
      </div>
    </ng-container>
    <ng-template #templateOtherPersonsProfile>
      <h4 class="standard-heading">Goals</h4>
    </ng-template>
  </div>

  <!-- <div class="row">
    <hr class="m-top-0 m-bottom-0"/>
  </div> -->

  <app-state [state]="state">
    <ng-container *ngIf="goals.length > 0; else templateNoGoals;">
      <div class="goals-list">
        <ng-container *ngFor="let goal of goals">
          <app-goal-display [goal]="goal" [canOpen]="true" [openRelative]="true"></app-goal-display>
        </ng-container>
      </div>
    </ng-container>
    <ng-template #templateNoGoals>
      <ng-container *ngIf="userProfile.id === globals.user.id; else templateEmptyStateOtherPersonsProfile;">
        <app-empty-state [height]="'200px'" [imageSrc]="'/assets/img/NoGoals.svg'" [message]="'Did you know that goal-setting is linked to higher achievement?'" [primaryButtonShown]="true" [primaryButtonText]="'Set a goal'" (primaryButtonPressed)="startCreateGoal()"></app-empty-state>
      </ng-container>
      <ng-template #templateEmptyStateOtherPersonsProfile>
        <app-empty-state [height]="'200px'" [imageSrc]="'/assets/img/NoGoals.svg'" [message]="userProfile.firstName + ' has yet to set any goals to public'"></app-empty-state>
      </ng-template>
    </ng-template>
  </app-state>
</div>