<div class="error-state-container" [style.lineHeight]="height">
  <div class="message-inner">

    <div class="error-state-image">
      <img [src]="imageSrc" />
    </div>

    <div class="error-state-message" [innerHTML]="message | translate: messageTranslateParams"></div>

    <div class="text-center" [class.p-bottom-10]="tertiaryButtonShown" *ngIf="primaryButtonShown || secondaryButtonShown">
      <ng-container *ngIf="primaryButtonShown">
        <app-button (onClick)="clickPrimaryButton($event)" [type]="eButtonType.GREEN">
          <span translate>{{primaryButtonText}}</span>
        </app-button>
      </ng-container>

      <ng-container *ngIf="primaryButtonShown && secondaryButtonShown">
        <span class="m-right-5"></span>
      </ng-container>

      <ng-container *ngIf="secondaryButtonShown">
        <app-button (onClick)="clickSecondaryButton($event)" [type]="eButtonType.GREEN_INVERTED">
          <span translate>{{secondaryButtonText}}</span>
        </app-button>
      </ng-container>
    </div>

    <div class="text-center" *ngIf="tertiaryButtonShown">
      <a class="clickable" (click)="clickTertiaryButton($event)">
        <span translate>{{tertiaryButtonText}}</span>
      </a>
    </div>

  </div>
</div>
