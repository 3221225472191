import {MockService} from '@app/mock/api/mock-service';
import {RoleService} from '@app/domain/role/api/role-api.model';
import {Injectable} from '@angular/core';
import {HttpRequest} from '@angular/common/http';
import { Role } from '@app/domain/role/model/role.model';
import {CreateRoleDto} from '@app/domain/role/model/create-role.model';
import {Observable, of} from 'rxjs';
import {mockRoles} from '@app/domain/role/mock/roles.mock';
import {sanitizeUrl} from '@app/shared/utils/helpers';
import {UserMinimal} from '@app/models/user/user-minimal.model';
import { ProvisionedPosition } from '@app/domain/role/model/provisioned-position.model';
import { mockUsers } from '@app/mock/api/data/mockUsers';
import { Page } from '@app/models/api/page.model';
import { PagingParams } from '@app/models/api/paging-params.model';
import { SortingParams } from '@app/models/api/sorting-params.model';

@Injectable()
export class RoleAPIServiceMock implements MockService, RoleService {
  handleRoute(req: HttpRequest<any>): any {
    const url = sanitizeUrl(req.urlWithParams);

    // switch (true) {
    // }
  }

  get(ids: number[], name: string, grade: string, competencyIds: number[]): Observable<Role[]> {
    return of(mockRoles);
  }

  getPaginated(pagingParams: PagingParams, sortingParams: SortingParams, ids: number[], name: string): Observable<Page<Role>> {
    return of({
      first: true,
      last: false,
      empty: false,
      number: 1,
      numberOfElements: mockRoles.length,
      content: mockRoles,
      pageable: null,
      size: mockRoles.length,
      totalElements: mockRoles.length,
      totalPages: 1
    });
  }

  create(createDto: CreateRoleDto): Observable<Role> {
    return of(mockRoles[0]);
  }

  update(id: number, updateDto: CreateRoleDto): Observable<Role> {
    return of(mockRoles[0]);
  }

  delete(id: number): Observable<Role> {
    return of(mockRoles[0]);
  }

  importRoles(file: File): Observable<Array<Role>> {
    return of(mockRoles);
  }

  deleteMultiple(ids: number[]): Observable<Role[]> {
    return of(mockRoles);
  }

  getAllUsersAssignedToRole(id: number): Observable<UserMinimal[]> {
    return of(mockUsers.splice(0, 5));
  }

  getAllProvisionedPositions(): Observable<ProvisionedPosition[]> {
    return of([]);
  }

  getProvisionedPositionByPositionId(positionId: number): Observable<ProvisionedPosition> {
    return of(null);
  }

  getTasksLinkedToRoleApplication(roleApplicationId: number): Observable<Task[]> {
    return of([]);
  }

  linkTasksToRoleApplication(roleApplicationId: number, taskIds: number[]): Observable<Task[]> {
    return of([]);
  }

  unlinkTasksFromRoleApplication(roleApplicationId: number, taskIds: number[]): Observable<Task[]> {
    return of([]);
  }

  searchRoles(searchTerm: string): Observable<Role[]> {
    return of(mockRoles.slice(0, 10));
  }
}