<div class="container-sidebar-one-to-ones">
  <app-state [state]="state">
    <ng-container *ngIf="!state.loading && !state.error;">
      <ng-container *ngIf="data.master.length > 0; else templateNoData;">
        <div class="data-list">
          <ng-container *ngFor="let schedule of data.display; index as index;">
            <app-one-to-one-schedule-detailed-display [schedule]="schedule"></app-one-to-one-schedule-detailed-display>
            <!-- <app-one-to-one-schedule-display [schedule]="schedule"></app-one-to-one-schedule-display> -->
          </ng-container>
        </div>

        <app-pagination-new-component [arrayData]="data.filtered" [arrayDisplay]="data.display" #pagination></app-pagination-new-component>
      </ng-container>

      <ng-template #templateNoData>
        <app-empty-state [height]="'200px'" [imageSrc]="'assets/img/empty-state/empty-state-one-to-ones.svg'" [message]="'No 1:1s'"></app-empty-state>
      </ng-template>
    </ng-container>
  </app-state>
</div>
