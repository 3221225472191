<div class="p-bottom-15">
  <ng-container *ngIf="title">
    <h1 class="standard-heading" translate [translateParams]="titleParams">{{title}}</h1>
  </ng-container>
  <div class="standard-description" *ngIf="description || helpLink">
    <ng-container *ngIf="description">
      <span translate [translateParams]="descriptionParams">{{description}}</span>
      <!-- <span [innerHTML]="description | translate : descriptionParams"></span> -->
    </ng-container>
    <ng-container *ngIf="helpLink">
      <span class="m-right-5"></span>
      <a class="standard-documentation-link" [href]="helpLink" target="_blank" translate>{{eCommonMessages.LEARN_MORE}}</a>
    </ng-container>
  </div>
</div>