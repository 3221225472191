<div class="container-sidebar-sentiment-scale-timeline-answer-item">
   <div class="area-score-option">
     <ng-container *ngIf="scoreOption; else templateNoScore;">
       <app-sentiment-scale-option-display [option]="scoreOption" [showNumbers]="sentimentScale.showNumbers" [showIndent]="false" [showName]="false" [showDescription]="false" [metadataInTooltip]="true" [lastUpdated]="answerData.lastUpdated"></app-sentiment-scale-option-display>
     </ng-container>
     <ng-template #templateNoScore>
      <div class="area-no-score">
        <span class="fal fa-fw fa-2x fa-empty-set" tooltip="No answer" placement="bottom" container="body"></span>
      </div>
     </ng-template>
   </div>
</div>
