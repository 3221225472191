<div class="container-sidebar-qualifications">
  <app-state [state]="state">
    <ng-container *ngIf="!state.loading && !state.error;">
      <ng-container *ngIf="hasData; else templateNoData;">
        <div class="data-list">
          <ng-container *ngFor="let qualificationData of data; index as index;">
            <ng-container *ngIf="qualificationData.user && qualificationData.qualifications.length > 0">
              <app-qualification-sidebar-display [user]="qualificationData.user" [qualifications]="qualificationData.qualifications"></app-qualification-sidebar-display>
            </ng-container>
          </ng-container>
        </div>
      </ng-container>

      <ng-template #templateNoData>
        <app-empty-state [height]="'200px'" [imageSrc]="'assets/img/empty-state/empty-state-qualifications.svg'" [message]="'No qualifications'"></app-empty-state>
      </ng-template>
    </ng-container>
  </app-state>
</div>
