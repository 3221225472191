import { FeedbackUnprompted } from '@app/models/feedback/feedback.model';
import { mockFeedbackUnpromptedSettings } from '@app/mock/api/data/mockFeedbackUnpromptedSettings';
import moment from 'moment';
import { mockUsers } from '@app/mock/api/data/mockUsers';

export const mockUnpromptedFeedback: FeedbackUnprompted[] = [
  {
    id: 1,
    anonymous: false,
    feeling: mockFeedbackUnpromptedSettings.feeling[2],
    feelingCustom: null,
    context: mockFeedbackUnpromptedSettings.context[1],
    contextCustom: null,
    impact: 'It\'s well doable.',
    proceed: mockFeedbackUnpromptedSettings.proceed[1],
    proceedCustom: null,
    message: 'Very pleased with my workload recently.',
    timestamp: moment().subtract(1, 'week').toDate(),
    sourceUser: mockUsers[1],
    destinationUser: mockUsers[0]
  },
  {
    id: 2,
    anonymous: false,
    feeling: mockFeedbackUnpromptedSettings.feeling[0],
    feelingCustom: null,
    context: mockFeedbackUnpromptedSettings.context[0],
    contextCustom: null,
    impact: 'Feeling more productive at work.',
    proceed: mockFeedbackUnpromptedSettings.proceed[3],
    proceedCustom: null,
    message: 'Getting along well with everyone at the office.',
    timestamp: moment().subtract(1, 'week').toDate(),
    sourceUser: mockUsers[3],
    destinationUser: mockUsers[0]
  },
  {
    id: 3,
    anonymous: false,
    feeling: mockFeedbackUnpromptedSettings.feeling[2],
    feelingCustom: null,
    context: mockFeedbackUnpromptedSettings.context[1],
    contextCustom: null,
    impact: 'It\'s well doable.',
    proceed: mockFeedbackUnpromptedSettings.proceed[3],
    proceedCustom: null,
    message: 'Very pleased with my workload recently.',
    timestamp: moment().subtract(1, 'week').toDate(),
    sourceUser: mockUsers[0],
    destinationUser: mockUsers[1]
  },
  {
    id: 4,
    anonymous: false,
    feeling: mockFeedbackUnpromptedSettings.feeling[0],
    feelingCustom: null,
    context: mockFeedbackUnpromptedSettings.context[0],
    contextCustom: null,
    impact: 'Feeling more productive at work.',
    proceed: mockFeedbackUnpromptedSettings.proceed[3],
    proceedCustom: null,
    message: 'Getting along well with everyone at the office.',
    timestamp: moment().subtract(1, 'week').toDate(),
    sourceUser: mockUsers[0],
    destinationUser: mockUsers[1]
  }
];