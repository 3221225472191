import { CoachApplication } from '@app/models/connect/coach-application/coach-application.model';
import { CoachApplicationStatus } from '@app/models/connect/type/coach-application-status.enum';
import { mockConnectQuestionsAnswered } from '@app/mock/api/data/mockConnectQuestionsAnswered';
import { mockConnectSkills } from '@app/mock/api/data/mockConnectSkills';
import moment from 'moment';
import { mockUsers } from '@app/mock/api/data/mockUsers';

export const mockCoachApplications: CoachApplication[] = [
  {
    id: 1,
    createdTimestamp: moment().subtract(1, 'weeks').toDate(),
    lastEditTimestamp: moment().subtract(1, 'weeks').toDate(),
    companyId: 1,
    coachManagerId: mockUsers[0].managerId,
    status: CoachApplicationStatus.APPROVED,
    coachId: mockUsers[0].id,
    coach: mockUsers[0],
    approvedSkills: [
      mockConnectSkills[0],
      mockConnectSkills[1],
      mockConnectSkills[2],
      mockConnectSkills[3]
    ],
    pendingSkills: [],
    answers: [mockConnectQuestionsAnswered[0]],
    managerComment: '',
    coachComment: ''
  },
  {
    id: 2,
    createdTimestamp: moment().subtract(1, 'weeks').toDate(),
    lastEditTimestamp: moment().subtract(1, 'weeks').toDate(),
    companyId: 1,
    coachManagerId: mockUsers[1].managerId,
    status: CoachApplicationStatus.PENDING,
    coachId: mockUsers[1].id,
    coach: mockUsers[1],
    approvedSkills: [

    ],
    pendingSkills: [
      mockConnectSkills[0],
      mockConnectSkills[1],
      mockConnectSkills[2],
      mockConnectSkills[3]
    ],
    answers: [mockConnectQuestionsAnswered[0]],
    managerComment: '',
    coachComment: ''
  },
  {
    id: 3,
    createdTimestamp: moment().subtract(1, 'weeks').toDate(),
    lastEditTimestamp: moment().subtract(1, 'weeks').toDate(),
    companyId: 1,
    coachManagerId: mockUsers[2].managerId,
    status: CoachApplicationStatus.APPROVED,
    coachId: mockUsers[2].id,
    coach: mockUsers[2],
    approvedSkills: [
      mockConnectSkills[4],
      mockConnectSkills[5],
      mockConnectSkills[6],
      mockConnectSkills[7]
    ],
    pendingSkills: [],
    answers: [mockConnectQuestionsAnswered[0]],
    managerComment: '',
    coachComment: ''
  },
  {
    id: 4,
    createdTimestamp: moment().subtract(1, 'weeks').toDate(),
    lastEditTimestamp: moment().subtract(1, 'weeks').toDate(),
    companyId: 1,
    coachManagerId: mockUsers[3].managerId,
    status: CoachApplicationStatus.APPROVED,
    coachId: mockUsers[3].id,
    coach: mockUsers[3],
    approvedSkills: [
      mockConnectSkills[8],
      mockConnectSkills[9],
      mockConnectSkills[10],
    ],
    pendingSkills: [],
    answers: [mockConnectQuestionsAnswered[0]],
    managerComment: '',
    coachComment: ''
  },
  {
    id: 5,
    createdTimestamp: moment().subtract(1, 'weeks').toDate(),
    lastEditTimestamp: moment().subtract(1, 'weeks').toDate(),
    companyId: 1,
    coachManagerId: mockUsers[4].managerId,
    status: CoachApplicationStatus.APPROVED,
    coachId: mockUsers[4].id,
    coach: mockUsers[4],
    approvedSkills: [
      mockConnectSkills[8],
      mockConnectSkills[9],
      mockConnectSkills[10],
    ],
    pendingSkills: [],
    answers: [mockConnectQuestionsAnswered[0]],
    managerComment: '',
    coachComment: ''
  },
  {
    id: 6,
    createdTimestamp: moment().subtract(1, 'weeks').toDate(),
    lastEditTimestamp: moment().subtract(1, 'weeks').toDate(),
    companyId: 1,
    coachManagerId: mockUsers[5].managerId,
    status: CoachApplicationStatus.APPROVED,
    coachId: mockUsers[5].id,
    coach: mockUsers[5],
    approvedSkills: [
      mockConnectSkills[4],
      mockConnectSkills[5],
      mockConnectSkills[6],
      mockConnectSkills[7]
    ],
    pendingSkills: [],
    answers: [mockConnectQuestionsAnswered[0]],
    managerComment: '',
    coachComment: ''
  },
  {
    id: 7,
    createdTimestamp: moment().subtract(1, 'weeks').toDate(),
    lastEditTimestamp: moment().subtract(1, 'weeks').toDate(),
    companyId: 1,
    coachManagerId: mockUsers[6].managerId,
    status: CoachApplicationStatus.APPROVED,
    coachId: mockUsers[6].id,
    coach: mockUsers[6],
    approvedSkills: [
      mockConnectSkills[4],
      mockConnectSkills[5],
      mockConnectSkills[6],
      mockConnectSkills[7]
    ],
    pendingSkills: [],
    answers: [mockConnectQuestionsAnswered[0]],
    managerComment: '',
    coachComment: ''
  },
  {
    id: 8,
    createdTimestamp: moment().subtract(1, 'weeks').toDate(),
    lastEditTimestamp: moment().subtract(1, 'weeks').toDate(),
    companyId: 1,
    coachManagerId: mockUsers[7].managerId,
    status: CoachApplicationStatus.APPROVED,
    coachId: mockUsers[7].id,
    coach: mockUsers[7],
    approvedSkills: [
      mockConnectSkills[8],
      mockConnectSkills[9],
      mockConnectSkills[10],
    ],
    pendingSkills: [],
    answers: [mockConnectQuestionsAnswered[0]],
    managerComment: '',
    coachComment: ''
  },
];