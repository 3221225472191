import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'simpleNumber'
})
export class SimpleNumberPipe implements PipeTransform {

    constructor() {
    }

    transform(number: string, args?: any): any {
        if (number === null) return null;

        number = number.toString();
        number = number.replace(' ', ''); // Get rid of spaces

        // TODO: Let the user configure this later
        // number = number.replace(',','.'); // Get rid of commas
        number = number.replace(',', '.'); // Turn commas to decimals

        return parseFloat(number);
    }
}