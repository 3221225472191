import { Component, ElementRef, forwardRef, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { OneToOneMeeting } from '@app/domain/one_to_one/model/one-to-one-meeting.model';
import { Globals } from '@app/shared/globals/globals';
import { OneToOneMessages } from '@app/domain/one_to_one/locale/one-to-one.messages';
import { OneToOneSchedule } from '@app/domain/one_to_one/model/one-to-one-schedule.model';

@Component({
  selector: 'app-meeting-navigation-carousel',
  templateUrl: './meeting-navigation-carousel.component.html',
  styleUrls: ['./meeting-navigation-carousel.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => MeetingNavigationCarouselComponent),
    multi: true,
  }]
})
export class MeetingNavigationCarouselComponent implements ControlValueAccessor, OnChanges {
  public readonly eOneToOneMessages = OneToOneMessages;

  @Input() schedule: OneToOneSchedule;
  @Input() meetings: OneToOneMeeting[];
  @Input() disabled: boolean;
  @Input() highlightedIds: number[];

  @ViewChild('scrollList') scrollList?: ElementRef<HTMLDivElement>;

  _value: number;

  onChange = (_: any) => {};
  onTouched = () => {};

  get value(): number {
    return this._value;
  }

  @Input() // NOTE: This is needed as a workaround because you cannot sync the values of formControls
  set value(v: number) {
    const previousValue: number = JSON.parse(JSON.stringify(this._value));
    if (this.disabled) { return; }
    this._value = v;
    this.onChange(v);

    if (previousValue === v) { return; }
    this.scrollToMeetingId(v);
  }

  constructor(
    public globals: Globals
  ) {
    this.highlightedIds = [];
    this.meetings = [];

    this._value = undefined!;

    this.disabled = false;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.highlightedIds) {
      const currentValue: number[] = changes.highlightedIds.currentValue;
      if (currentValue == null) { return; }
      if (currentValue.length === 0) { return; }
      const firstHighlightedId = currentValue[0];
      // this.scrollToMeetingId(firstHighlightedId);
      if (this.value !== firstHighlightedId) {
        this.value = firstHighlightedId;
      }
    }
  }

  writeValue(val: number): void {
    this._value = val;
    setTimeout(() => {
      this.scrollToMeetingId(val);
    }, 1);
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }

  // #region - REGISTER CALLBACKS
  registerOnChange(fn: any): void {
    // Register the inner function then call the outer function
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  // #endregion

  clickArrow(direction: 'right' | 'left'): void {
    let newValue = 0;

    switch (direction) {
      case 'right':
        newValue = this.value - 1;
        break;
      case 'left':
        newValue = this.value + 1;
        break;
    }

    this.selectionChanged(newValue);
  }

  selectionChanged(meetingId: number): void {
    if (this.disabled) { return; }
    this.value = meetingId;
  }

  scrollToMeetingId(meetingId: number) {
    if (!this.scrollList) { return; }

    const meetingIndex = this.meetings.findIndex(meeting => meeting.id === meetingId);

    // Focus the item at the given meeting in the list
    const item: HTMLDivElement = this.scrollList.nativeElement.children[meetingIndex] as HTMLDivElement;
    if (!item) { return; }
    item.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
  }
}
