import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Deadline } from '@app/models/deadlines/deadline.model';
import { WelcomeService } from '@app/shared/api/interfaces/welcome.service';

@Injectable()
export class WelcomeAPIService implements WelcomeService {

  private readonly BASE_URL = 'api/welcome';

  constructor(private http: HttpClient) { }

  getDeadlines(): Observable<Array<Deadline>> {
    const url = `${this.BASE_URL}/deadlines`;
    return this.http.get<Array<Deadline>>(url);
  }

}
