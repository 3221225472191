<div class="container-one-to-one-meeting-display" *ngIf="meeting">
  <div class="display-meeting-timestamp p-bottom-15">
    <strong>Scheduled for</strong>
    <div>{{meeting.meetingTimestamp | date}}</div>
  </div>

  <!-- <div class="display-completed-timestamp p-bottom-15">
    <strong>Completed on</strong>
    <div>{{meeting.completedTimestamp | date}}</div>
  </div> -->

  <div class="display-status p-bottom-15">
    <strong>Status</strong>
    <div>{{meeting.status | underscoreToSpace | titlecase}}</div>
  </div>

  <ng-container *ngIf="showTalkingPoints">
    <div class="display-talking-points p-bottom-15">
      <div class="p-bottom-10">
        <strong>Talking points</strong>
      </div>
  
      <div class="talking-points-list">
        <ng-container *ngIf="meeting.talkingPoints && meeting.talkingPoints.length > 0; else templateEmptyTalkingPoints;">
          <ng-container *ngFor="let talkingPoint of meeting.talkingPoints; index as index;">
            <app-one-to-one-talking-point-display [talkingPoint]="talkingPoint" [index]="index"></app-one-to-one-talking-point-display>
          </ng-container>
        </ng-container>
        <ng-template #templateEmptyTalkingPoints>
          <app-empty-state [height]="'100px'" [message]="'No talking points'"></app-empty-state>
        </ng-template>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="showSharedNotes">
    <div class="display-shared-user-notes p-bottom-15">
      <div class="p-bottom-10">
        <strong>Shared notes</strong>
      </div>
  
      <div class="shared-notes-list">
        <ng-container *ngIf="meeting.sharedNotes && meeting.sharedNotes.length > 0; else templateEmptySharedNotes;">
          <ng-container *ngFor="let sharedNoteItem of meeting.sharedNotes; index as index;">
            <app-one-to-one-note-display [note]="sharedNoteItem"></app-one-to-one-note-display>
          </ng-container>
        </ng-container>
        <ng-template #templateEmptySharedNotes>
          <app-empty-state [height]="'100px'" [message]="'No shared notes'"></app-empty-state>
        </ng-template>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="showPrivateNotes">
    <ng-container *ngIf="privateNotes && privateNotes.content;">
      <div class="display-private-notes">
        <div class="p-bottom-10">
          <strong>Your private notes</strong>
        </div>
        <app-one-to-one-note-display [note]="privateNotes"></app-one-to-one-note-display>
      </div>
    </ng-container>
  </ng-container>
</div>