<div class="container-shared-notes">
  <ng-container *ngIf="hasSharedNotes || canAddNotes; else templateEmptyState;">
    <ng-container *ngIf="hasSharedNotes">
      <div class="area-shared-notes-preview" (click)="toggleNotesList()">
        <span>{{eOneToOneMessages.SHARED_NOTES_COUNT(meeting.sharedNotes.length) | translate: { count: meeting.sharedNotes.length } }}</span>
        <span class="fal fa-fw fa-caret-down" [class.flip-v]="state.viewingNotes"></span>
        <app-person-picture-list [users]="usersWithNotes" [imageSizePx]="14"></app-person-picture-list>
      </div>
    
      <ng-container *ngIf="state.viewingNotes && (!state.addingNote || hasOtherUserNotes)">
        <div class="area-shared-notes-list">
          <ng-container *ngFor="let sharedNotesItem of meeting.sharedNotes; index as index;">
            <ng-container *ngIf="(sharedNotesItem.userId !== globals.user.id) || !state.addingNote">
              <div class="wrapper-note-item">
                <div class="note-item-picture" *ngIf="sharedNotesItem.user">
                  <app-profile-photo [src]="sharedNotesItem.user.imageUrl" [firstName]="sharedNotesItem.user.firstName" [lastName]="sharedNotesItem.user.lastName" [width]="'30px'"></app-profile-photo>
                </div>
                <div class="note-item-contents">
                  <div class="note-item-text">
                    <app-rich-display [text]="sharedNotesItem.contents"></app-rich-display>
                  </div>
                  <div class="note-item-actions">
                    <ng-container *ngIf="sharedNotesItem.userId === globals.user.id && canAddNotes">
                        <app-table-action-icon [icon]="'fa-edit'" [hoverColor]="eIconHoverColor.YELLOW" (click)="startAddingNote()"></app-table-action-icon>
                    </ng-container>
                  </div>
                </div>
              </div>
            </ng-container>
          </ng-container>
        </div>
      </ng-container>
    </ng-container>
    
    <ng-container *ngIf="canAddNotes && (!state.noteAlreadyAdded || state.addingNote)">
      <div class="area-add-note">
        <ng-container *ngIf="state.addingNote; else templatePrompToAddNote;">
          <div class="frankli-form-group frankli-form-group-filled">
            <app-editor-response [formControl]="controlNotes" [maxCharacters]="1000" [hardMax]="2000" [height]="globals.onMobile? 200 : 300" [showErrorBorder]="" [toolbar]="editorToolbar" [columnIndent]="false"></app-editor-response>
          </div>
          <div class="text-right p-top-15">
            <app-button [type]="eButtonType.GRAY_INVERTED" (onClick)="cancelAddingNote()">{{eCommonMessages.CANCEL | translate}}</app-button>
            <span class="m-right-5"></span>
            <app-button [type]="eButtonType.GREEN" (onClick)="submitAddingNote()">{{eCommonMessages.SUBMIT | translate}}</app-button>
          </div>
        </ng-container>
        <ng-template #templatePrompToAddNote>
          <div class="area-prompt-to-note" (click)="startAddingNote()">
            <span class="fal fa-fw fa-note"></span>
            <span>{{eOneToOneMessages.CTA_WRITE_NOTE | translate}}</span>
          </div>
        </ng-template>
      </div>
    </ng-container>
  </ng-container>

  <ng-template #templateEmptyState>
    <app-empty-state [message]="'No shared notes'" [height]="'200px'"></app-empty-state>
  </ng-template>
</div>
