import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ISentimentScaleAPI } from '@app/domain/sentiment_scale/api/sentiment-scale-api.model';
import { SentimentScale } from '../model/sentiment-scale.model';
import { CreateSentimentScale } from '../model/create-sentiment-scale.model';
import { Page } from '@app/models/api/page.model';

@Injectable()
export class SentimentScaleAPIService implements ISentimentScaleAPI {

  public readonly BASE_URL = 'api/sentiment-scale';

  constructor(private http: HttpClient) { }

  getAll(includeArchived?: boolean): Observable<SentimentScale[]> {
    const url = `${this.BASE_URL}/`;

    let params: HttpParams = new HttpParams();
    if (includeArchived !== undefined) {
      params = params.append('includeArchived', includeArchived.toString());
    }

    return this.http.get<SentimentScale[]>(url, { params: params });
  }

  getById(id: number): Observable<SentimentScale> {
    const url = `${this.BASE_URL}/${id}`;
    return this.http.get<SentimentScale>(url);
  }

  create(createDto: CreateSentimentScale): Observable<SentimentScale> {
    const url = `${this.BASE_URL}/`;
    return this.http.post<SentimentScale>(url, createDto);
  }

  update(id: number, updateDto: CreateSentimentScale, force?: boolean): Observable<SentimentScale> {
    const url = `${this.BASE_URL}/${id}`;

    let httpParams: HttpParams = new HttpParams();
    if (force) {
      httpParams = httpParams.append('force', 'true');
    }

    return this.http.put<SentimentScale>(url, updateDto, { params: httpParams });
  }

  archive(id: number): Observable<SentimentScale> {
    const url = `${this.BASE_URL}/${id}`;
    return this.http.delete<SentimentScale>(url, {});
  }

  unarchive(id: number): Observable<SentimentScale> {
    const url = `${this.BASE_URL}/${id}/unarchive`;
    return this.http.post<SentimentScale>(url, {});
  }

  search(searchArgument: string, includeArchived: boolean): Observable<Page<SentimentScale>> {
    const url = `${this.BASE_URL}/search`;

    let params: HttpParams = new HttpParams();
    if (searchArgument) {
      params = params.append('query', searchArgument);
    }

    if (includeArchived !== undefined) {
      params = params.append('includeArchived', includeArchived.toString());
    }

    return this.http.get<Page<SentimentScale>>(url, { params: params });
  }

  getByIdIn(ids: number[]): Observable<SentimentScale[]> {
    const url = `${this.BASE_URL}/in`;

    let params: HttpParams = new HttpParams();
    ids.forEach(id => {
      params = params.append('id', id.toString());
    });

    return this.http.get<SentimentScale[]>(url, { params: params });
  }

}
