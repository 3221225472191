import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { Department } from '@app/models/department.model';
import { PillType } from '../../pill/pill.component';

@Component({
  selector: 'app-department-display',
  templateUrl: './department-display.component.html',
  styleUrls: ['./department-display.component.scss']
})
export class DepartmentDisplayComponent implements OnChanges {
  public readonly ePillType = PillType;
  public readonly DATE_FORMAT = 'D MMM YYYY';
  
  @Input() department!: Department;
  @Input() showProgress: boolean;

  @Input() canSelect: boolean;
  @Input() selected: boolean;

  @Output() onSelect: EventEmitter<boolean>;

  constructor() {
    this.selected = false;
    this.canSelect = false;
    this.showProgress = true;
    this.onSelect = new EventEmitter<boolean>();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const department = changes['department'];
    if (department) {
      // TODO: Any required internal calculations here
    }
  }

  selectDepartment(): void {
    if (this.canSelect) {
      this.onSelect.emit(true);
    }
  }
}
