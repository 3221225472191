import { Component, Input, forwardRef } from '@angular/core';
import { FormArray, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { initFormArrayOptionItems, initFormGroupOptionItem } from '@app/admin/admin-modules/admin-evaluations/admin-evaluations-components/administrate-schedule-cycle/administrate-schedule-cycle-question-banks/schedule-cycle-add-question/schedule-cycle-add-question.component';
import { DynamicScalePossibleAnswer } from '@app/models/evaluation/dynamic-scale-possible-answer.model';
import { ButtonType } from '@app/shared/components/inputs/button/button.component';
import { DynamicScaleOptionComponentAction } from '../dynamic-scale-option/dynamic-scale-option.component';
import { CdkDragDrop } from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-dynamic-scale',
  templateUrl: './dynamic-scale.component.html',
  styleUrls: ['./dynamic-scale.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => DynamicScaleComponent),
    multi: true
  }],
})
export class DynamicScaleComponent {
  public readonly eButtonType = ButtonType;

  @Input() disabled: boolean;
  @Input() display: boolean;
  @Input() editing: boolean;
  @Input() submitted: boolean;
  
  @Input() options: DynamicScalePossibleAnswer[];
  @Input() optionsEdit: FormArray;

  _value: string;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function, @typescript-eslint/no-explicit-any
  onChange = (_: any) => {};
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onTouched = () => {};

  get optionsEditArray(): FormGroup[] {
    if (!this.optionsEdit) return [];
    return this.optionsEdit.controls as FormGroup[];
  }

  get optionsOrdered(): DynamicScalePossibleAnswer[] {
    if (!this.options) return [];
    return this.options.sort((a, b) => a.answerIndex - b.answerIndex);
  }

  constructor() {
    this.disabled = false;
    this.display = false;
    this.editing = false;
    this.submitted = false;

    this.options = [];

    this._value = '';

    this.optionsEdit = initFormArrayOptionItems();
  }

  get value(): string {
    return this._value;
  }

  set value(val: string) {
    // Skip if the new value is the same as the old value
    if (val === this._value) { return; }

    this._value = val;
    this.onChange(val);
  }

  onSelectOption(option: DynamicScalePossibleAnswer): void {
    if (this.disabled) { return console.warn('Cannot select option when disabled'); }
    if (this.display) { return console.warn('Cannot select option when in display mode'); }

    this.value = option.answerValue;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onClickAction(action: DynamicScaleOptionComponentAction, option: DynamicScalePossibleAnswer, index: number): void {
    switch (action) {
      case DynamicScaleOptionComponentAction.SELECT:
        this.onSelectOption(option);
        break;
      case DynamicScaleOptionComponentAction.REMOVE:
      default:
        break;
    }
  }

  onClickActionEdit(action: DynamicScaleOptionComponentAction, option: FormGroup, index: number): void {
    switch (action) {
      case DynamicScaleOptionComponentAction.REMOVE:
        this.removeOptionEditAtIndex(index);
        break;
      case DynamicScaleOptionComponentAction.SELECT:
      default:
        break;
    }
  }

  removeOptionEditAtIndex(index: number): void {
    if (this.disabled) { return; }

    this.optionsEdit.removeAt(index);
  }

  writeValue(value: string) {
    const isValid = this.checkIsValueValid(value);
    if (isValid) {
      this.value = value;
    } else {
      this.value = '';
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }

  onClickAddOption(index?: number): void {
    if (this.disabled) { return; }

    const newControl = initFormGroupOptionItem();

    if (index === undefined) {
      this.optionsEdit.push(newControl);
    } else {
      this.optionsEdit.insert(index + 1, newControl);
    }
  }

  checkIsValueValid(value: string): boolean {
    if (!this.options) { return false; }
    return this.options.some(option => option.answerValue === value);
  }

  onDropDraggableOption(event: CdkDragDrop<FormArray>): void {
    // Reject elements from outside the container
    if (event.previousContainer !== event.container) {
      return;
    }

    const from = event.container.data as FormArray;
    this.moveItemInFormArray(from, event.previousIndex, event.currentIndex);
    this.optionsEdit.markAsDirty();
  }

  moveItemInFormArray(formArray: FormArray, fromIndex: number, toIndex: number): void {
    const direction = (toIndex > fromIndex) ? 1 : -1;

    const temp = formArray.at(fromIndex);
    for (let i = fromIndex; (i * direction) < (toIndex * direction); i = (i + direction)) {
      const current = formArray.at(i + direction);
      formArray.setControl(i, current);
    }
    formArray.setControl(toIndex, temp);
  }
}
