import { Injectable } from '@angular/core';
import { AdminMessages } from '@app/admin/admin.messages';
import { NotifyUtils } from '@app/shared/utils/notify.utils';
import { Observable } from 'rxjs';
import { CompetencyAPIService } from '../api/competency-api.service';
import { Competency } from '../model/competency.model';
import { CreateCompetencyDto } from '../model/create-competency.model';
import { TranslateService } from '@ngx-translate/core';
import { Globals } from '@app/shared/globals/globals';

@Injectable({
  providedIn: 'root'
})
export class CompetencyBusinessService {

  updating: number[];
  uploadingFile: boolean;
  downloadingCSV: boolean;

  constructor(
    private competencyAPI: CompetencyAPIService,
    private translateService: TranslateService,
    private notifyUtils: NotifyUtils,
    private globals: Globals
  ) {
    this.updating = [];
    this.uploadingFile = false;
    this.downloadingCSV = false;
  }

  private startUpdating(ids: number[]) {
    this.updating = [...this.updating, ...ids];
  }

  private stopUpdating(ids: number[]) {
    this.updating = this.updating.filter(x => !ids.includes(x));
  }

  create(createDto: CreateCompetencyDto): Promise<Competency> {
    return this.competencyAPI.create(createDto).toPromise()
      .then(res => {
        const message = this.translateService.instant(AdminMessages.COMPETENCIES.SUCCESS_COMPETENCY_CREATE, this.globals.terminology);
        this.notifyUtils.notify(message);
        return res;
      });
  }

  update(id: number, updateDto: CreateCompetencyDto): Promise<Competency> {
    if (this.updating.includes(id)) { return new Promise((resolve, reject) => reject()); }
    this.startUpdating([id]);

    return this.competencyAPI.update(id, updateDto).toPromise()
      .then(res => {
        const message = this.translateService.instant(AdminMessages.COMPETENCIES.SUCCESS_COMPETENCY_UPDATE, this.globals.terminology);
        this.notifyUtils.notify(message);
        return res;
      })
      .finally(() => {
        this.stopUpdating([id]);
      });
  }

  delete(id: number): Promise<Competency> {
    if (this.updating.includes(id)) { return new Promise((resolve, reject) => reject()); }
    this.startUpdating([id]);

    return this.competencyAPI.delete(id).toPromise()
      .then(res => {
        const message = this.translateService.instant(AdminMessages.COMPETENCIES.SUCCESS_DELETE_COMPETENCY_SINGLE, this.globals.terminology);
        this.notifyUtils.notify(message);
        return res;
      })
      .finally(() => {
        this.stopUpdating([id]);
      });
  }

  deleteMultiple(ids: number[]): Promise<Competency[]> {
    if (this.updating.some(id => ids.includes(id))) { return new Promise((resolve, reject) => reject()); }
    this.startUpdating(ids);

    return this.competencyAPI.deleteMultiple(ids).toPromise()
      .then(res => {
        const message = this.translateService.instant(AdminMessages.COMPETENCIES.SUCCESS_DELETE_COMPETENCY_MULTIPLE, this.globals.terminology);
        this.notifyUtils.notify(message);
        return res;
      })
      .finally(() => {
        this.stopUpdating(ids);
      });
  }

  importFile(file: File): Promise<Competency[]> {
    if (this.uploadingFile) { return new Promise((resolve, reject) => reject()); }
    this.uploadingFile = true;

    return this.competencyAPI.importCompetencies(file).toPromise()
      .then(res => {
        const message = this.translateService.instant(AdminMessages.COMPETENCIES.SUCCESS_IMPORT_COMPETENCIES, this.globals.terminology);
        this.notifyUtils.notify(message);
        return res;
      })
      .finally(() => {
        this.uploadingFile = false;
      });
  }

  getCompetencies(
    ids: number[],
    name: string,
    categories: string[]
  ): Observable<Competency[]> {
    return this.competencyAPI.get(ids, name, categories);
  }


  downloadCSV(): Promise<Blob> {
    if (this.downloadingCSV) { return; }
    this.downloadingCSV = true;

    this.competencyAPI.downloadCSV()
      .toPromise()
      .then(res => {
        const url = window.URL.createObjectURL(res);
        const a = document.createElement('a');
        a.href = url;
        a.download = `frankli-competencies-${new Date().toISOString().split('T')[0]}.csv`;
        a.click();
      })
      .finally(() => {
        this.downloadingCSV = false;
      });
  }

  getForUserId(userId: number): Promise<Competency[]> {
    return this.competencyAPI.getForUserId(userId).toPromise();
  }

}
