import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserMinimal } from '@app/models/user/user-minimal.model';
import { Globals } from '@app/shared/globals/globals';
import { QualificationMessages } from '../../locale/qualification.messages';
import { Qualification } from '../../model/qualification.model';
import { QualificationBusinessService } from '../../service/qualification-business.service';

@Component({
  selector: 'app-profile-qualifications',
  templateUrl: './profile-qualifications.component.html',
  styleUrls: ['./profile-qualifications.component.scss']
})
export class ProfileQualificationsComponent implements OnInit {

  public readonly eQualificationMessages = QualificationMessages;

  @Input() user: UserMinimal;

  qualifications: Qualification[];

  constructor(
    public globals: Globals,
    private router: Router,
    private qualificationBusinessService: QualificationBusinessService
  ) {
    this.user = undefined;
    this.qualifications = [];
  }

  ngOnInit(): void {
    this.getData();
  }

  getData(): void {
    if (!this.user) { return; }
    this.qualificationBusinessService.get(null, null, [this.user.id])
      .subscribe(qualifications => {
        this.qualifications = qualifications;
      });
  }

  onClickEmptyState(): void {
    this.router.navigate(['settings', 'qualifications'], { queryParams: { action: 'create' } });
  }
}
