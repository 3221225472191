import { Component, Input } from '@angular/core';
import { IconHoverColor } from '@app/shared/components/inputs/table-action-icon/table-action-icon.component';
import { LongRunningTaskService } from '../../service/long-running-task.service';
import { LongRunningTask } from '../../model/long-running-task.model';
import { LongRunningTaskStatus } from '../../model/long-running-task-status.model';
import { LongRunningTaskType } from '../../model/long-running-task-type.model';

@Component({
  selector: 'app-long-running-task-display',
  templateUrl: './long-running-task-display.component.html',
  styleUrls: ['./long-running-task-display.component.scss']
})
export class LongRunningTaskDisplayComponent {

  public readonly eIconHoverColor = IconHoverColor;
  public readonly eLongRunningTaskType = LongRunningTaskType;

  @Input() task: LongRunningTask;

  dropdownOpen: boolean;

  get canRemoveTask(): boolean {
    if (!this.task) { return false; }
    if (this.task.status === LongRunningTaskStatus.COMPLETED) { return true; }
    if (this.task.status === LongRunningTaskStatus.COMPLETED_WITH_ERRORS) { return true; }
    if (this.task.status === LongRunningTaskStatus.FAILED) { return true; }
    return false;
  }

  constructor(
    private longRunningTaskService: LongRunningTaskService
  ) {
    this.dropdownOpen = false;
  }

  toggleDropdownOpen(): void {
    if (this.dropdownOpen) {
      this.closeDropdown();
    } else {
      this.openDropdown();
    }
  }

  openDropdown(): void {
    this.dropdownOpen = true;
  }

  closeDropdown(): void {
    this.dropdownOpen = false;
  }

  onClickRemoveEvent(): void {
    if (!this.task) { return; }
    this.longRunningTaskService.stopTrackingTask(this.task.id);
  }

}
