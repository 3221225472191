<div class="goal-create-department-container" [class.parent-dropdown-closed]="!parentDropdownOpen">
  <ng-container *ngIf="possibleDepartments.length > 0; else noDepartmentsAvailableTemplate;">
    <div class="search-box-outer" *ngIf="parentDropdownOpen">
      <div class="search-box-container">
        <input type="search" class="form-control search-box-control" [placeholder]="eCommonMessages.SEARCH_BY_NAME | translate" [formControl]="searchControl" maxlength="100"/>
        <div class="search-box-icon">
            <span class="fal fa-fw fa-search"></span>
        </div>
      </div>
    </div>

    <ng-container *ngIf="possibleDepartmentsDisplay.length > 0; else noSearchResults">
      <div class="departments-list">
        <ng-container *ngFor="let department of possibleDepartmentsDisplay; index as i; first as isFirst; last as isLast;">
          <div class="departments-list-item" (click)="onSelectDepartment(department)">
            <app-department-display [department]="department" [canSelect]="true" [selected]="controlDepartment.value === department.id"></app-department-display>
          </div>
        </ng-container>
      </div>
    </ng-container>

    <ng-template #noSearchResults>
      <ng-container *ngIf="parentDropdownOpen">
        <app-empty-state [height]="'normal'" [message]="eCommonMessages.NO_SEARCH_DEPARTMENTS | translate: { departmentWording: globals.getTerminology(eTerminologyEntity.DEPARTMENT_PLURAL) }"></app-empty-state>
      </ng-container>
    </ng-template>
  </ng-container>

  <ng-template #noDepartmentsAvailableTemplate>
    <div class="state-empty">
      <img class="icon-padding new-icon-height" src="/assets/img/NoSurveys.svg" />
      <div class="p-bottom-10" translate [translateParams]="{ departmentWording: globals.getTerminology(eTerminologyEntity.DEPARTMENT_PLURAL) }">{{eGoalsMessages.GOAL_CREATE_DEPARTMENT_EMPTY_STATE}}</div>
    </div>
  </ng-template>

  <ng-container *ngIf="submitted && controlDepartment.invalid">
    <div class="frankli-form-group-alert p-top-15 p-bottom-15">
      <div *ngIf="controlDepartment.hasError('required')" translate [translateParams]="{ departmentWording: globals.getTerminology(eTerminologyEntity.DEPARTMENT) }">{{eGoalsMessages.GOAL_CREATE_DEPARTMENT_VALIDATION_REQUIRED}}</div>
    </div>
  </ng-container>
</div>