<div class="row modal-header-divider">
  <div class="col-xs-10 modal-header-wrapper">
    <div><span class="modal-header-text">{{headerText}}</span></div>
    <div class="intercom-links clickable">
      <a class="m-right-10" href="https://help.frankli.io/en/articles/2554355-creating-a-goal-as-an-employee" target="_blank">How to get started</a>
      <a (click)="showTour()" >Take a tour</a>
    </div>
  </div>
  <div class="col-xs-2 text-right">
    <span title="Close create goal" class="fal fa-times modal-header-close frankli-close" (click)="modal.hide()"></span>
  </div>
</div>