import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
  CreateQuestionBankQuestion,
  QuestionBankQuestion,
  UpdateQuestionBankQuestion,
} from '@app/models/evaluation/question-bank-question.model';
import { QuestionBankService } from '@app/shared/api/interfaces/question-bank.service';

@Injectable({ providedIn: 'root' })
export class QuestionBankApiService implements QuestionBankService {
  baseURL = '/api/question-bank';

  constructor(private http: HttpClient) { }

  getQuestionBankQuestions(): Observable<QuestionBankQuestion[]> {
    const url = `${this.baseURL}/questions`;
    return this.http.get<QuestionBankQuestion[]>(url);
  }

  createQuestionBankQuestion(createQuestionBankQuestion: CreateQuestionBankQuestion): Observable<QuestionBankQuestion> {
    const url = `${this.baseURL}/questions`;
    return this.http.post<QuestionBankQuestion>(url, createQuestionBankQuestion);
  }

  updateQuestionBankQuestion(questionBankQuestionId: number, updateQuestionBankQuestion: UpdateQuestionBankQuestion): Observable<QuestionBankQuestion> {
    const url = `${this.baseURL}/questions/${questionBankQuestionId}`;
    return this.http.patch<QuestionBankQuestion>(url, updateQuestionBankQuestion);
  }
}