import { User, UserServerSide } from '@app/models/user/user.model';
import { ISODateString } from '@app/shared/utils/date-string.util';
import moment from 'moment';

export interface TaskCommentServerSide {
  id: number;
  creator: UserServerSide;
  text: string;
  timestamp: ISODateString;
}

export class TaskComment {
  id: number;
  creator: User;
  text: string;
  timestamp: Date;

  constructor(taskCommentServerSide: TaskCommentServerSide){
    this.id = taskCommentServerSide.id;
    this.creator = new User(taskCommentServerSide.creator);
    this.text = taskCommentServerSide.text;
    this.timestamp = moment.utc(taskCommentServerSide.timestamp).toDate();
  }
}