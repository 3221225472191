<ng-container *ngIf="note">
  <div class="container-one-to-one-note-display">
    <ng-container *ngIf="note.user">
      <div class="talking-point-comment-item-user">{{note.user.firstName}} {{note.user.lastName}}:</div>
    </ng-container>
    <ng-container *ngIf="note.contents; else templateNoNoteContents;">
      <app-rich-display [text]="note.contents"></app-rich-display>
    </ng-container>
    <ng-template #templateNoNoteContents>
      <div translate>{{eCommonMessages.NONE}}</div>
    </ng-template>
  </div>
</ng-container>