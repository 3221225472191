import { Location } from '@angular/common';
import {Component} from '@angular/core';
import { CommonMessages } from '@app/constants/common.messages';
import {Breadcrumb} from 'app/models/breadcrumb.model';
import {BreadcrumbService} from '../breadcrumbs.service';

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss']
})
export class BreadcrumbsComponent {

  public readonly eCommonMessages = CommonMessages;

  breadcrumbs: Breadcrumb[];

  constructor(
    private breadcrumbService: BreadcrumbService,
    private location: Location,
  ) {
    this.breadcrumbs = [];
    this.breadcrumbService.getBreadcrumbs().subscribe(breadcrumbs => {
      this.breadcrumbs = JSON.parse(JSON.stringify(breadcrumbs));
    });
  }

  goBack(): void {
    if (this.breadcrumbs.length <= 1) { return; } // Must have at least 2 breadcrumbs
    this.location.back();
  }

}
