import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Globals} from 'app/shared/globals/globals';
import {map} from 'rxjs/operators';
import {Router} from '@angular/router';
import {NotificationService} from '../notifications/notification.service';
import {SessionLogService} from '../session-log/session-log.service';
import {SessionEventType} from '@app/models/session-log/session-event-type.model';
import {AuthService} from '@app/shared/api/interfaces/auth.service';
import {AuthResponse, AuthResponseServerSide} from '@app/models/auth/auth-response.model';
import {UserInit, UserInitServerSide} from '@app/models/auth/user-init.model';
import {SSOProvider} from '@app/models/auth/sso-provider.enum';

export interface Credentials {
  email: string,
  password: string
}

export interface SSOCredentials {
  authorizationCode: string,
  provider: SSOProvider
}

@Injectable()
export class AuthAPIService implements AuthService {
  constructor(
    private http: HttpClient,
    public globals: Globals,
    private router: Router,
    private notificationService: NotificationService,
    private sessionLogService: SessionLogService
  ) { }

  authenticate(credentials: Credentials): Observable<AuthResponse> {
    credentials.email = credentials.email.trim().toLocaleLowerCase();

    return this.http.post<AuthResponseServerSide>('/api/authuser', credentials).pipe(
      map(authResponseServerSide => new AuthResponse(authResponseServerSide))
    );
  }

  authenticateSSO(credentials: SSOCredentials): Observable<UserInit> {
    return this.http.post<UserInitServerSide>('/sso', credentials).pipe(
      map(userInitServerSide => new UserInit(userInitServerSide))
    );
  }

  authenticateSSOTeams(userAccessToken: string): Observable<UserInit> {
    return this.http.post<UserInitServerSide>('/sso/teams', userAccessToken).pipe(
      map(userInitServerSide => new UserInit(userInitServerSide))
    );
  }

  authenticateMagicLink(magicLinkToken: string): Observable<UserInit>{
    return this.http.post<UserInitServerSide>('/api/magic-link', magicLinkToken).pipe(
      map(userInitServerSide => new UserInit(userInitServerSide))
    );
  }
  
  revokeMyGoogleAccess(): Observable<number> {
    return this.http.post<number>('/sso/google/revoke', null);
  }

  logout() {
    if (localStorage.getItem('access_token')) {
      this.sessionLogService.log(SessionEventType.SESSION_END_LOGOUT);
    }
    localStorage.removeItem('access_token');
    this.router.navigateByUrl('/auth/login')
      .then(() => {
        this.globals.clear();
        this.notificationService.clear();
        localStorage.removeItem('access_token');
        localStorage.removeItem('sso_error');
      });
  }

}
