<ng-container *ngIf="entity">
  <div class="container-skill-rating-display" [class.can-add-ratings]="canAddRating">
    <div class="item-name">{{entity.name}}</div>
    <ng-container *ngIf="globals.hasFeature(eCompanyFeatures.SKILL_RATING); else templateNoSkillRatings;">
      <div class="item-rating" [tooltip]="skillRating ? tooltipScoreComment : undefined" placement="bottom" container="body" (click)="startRatingSkill()">
        <ng-container *ngIf="showScore">
          <app-star-scale [ngModel]="skillRating ? skillRating.scoreValue : -1" [disabled]="true" [starCount]="skillRatingConfiguration?.scoreCount" [iconUsed]="skillRatingConfiguration?.scoreIcon" [optionMetadata]="skillRatingConfiguration?.options"></app-star-scale>
        </ng-container>
      </div>
      <ng-template #tooltipScoreComment>
        <div class="standard-description text-left" *ngIf="skillRating">
          <ng-container *ngIf="metadata">
            <strong>{{metadata.name}}</strong>
            <app-rich-display [text]="metadata.description"></app-rich-display>
          </ng-container>
          <ng-container *ngIf="skillRating.scoreComment">
            <hr class="row m-top-10 m-bottom-10"/>
            <app-rich-display [text]="skillRating.scoreComment"></app-rich-display>
          </ng-container>
        </div>
      </ng-template>
    </ng-container>
    <ng-template #templateNoSkillRatings>
      <div></div>
    </ng-template>
  </div>
</ng-container>
