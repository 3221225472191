<div class="chart-container">
  <div class="row" *ngIf="data; else noData">
    <ng-container *ngFor="let score of dataParsed;">
      <div class="col-likert-score" [style.width.%]="score.width">
        <div class="col-likert-score-fill" [ngClass]="score.styleClass" [tooltip]="colTooltip" [triggers]="globals.tooltipTriggers" placement="bottom" container="body">
          <span class="col-likert-score-text" *ngIf="score.width > 10">{{score.percentOfTotal}}%</span>
        </div>
        <ng-template #colTooltip>
          <span class="text-left">
            <strong>{{score.label}}</strong>
            <br/>
            <span>
              <span>{{score.percentOfTotal}}%</span>
              <span>({{score.value}} {{(+score.value === 1) ? 'person' : 'people'}})</span>
            </span>
          </span>
        </ng-template>
      </div>
    </ng-container>
  </div>

  <ng-template #noData>
    <div>No Data</div>
  </ng-template>
</div>