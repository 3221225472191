<ng-container *ngFor="let ag of assessmentGroups">
  <ng-container *ngIf="display === false || (display === true && value === ag.value)">
    <div class="row m-left-0 m-right-0 border-top row-ag" [class.option-disabled]="disabled" [class.selected]="this.value === ag.value" (click)="select(ag.value)" [class.display]="display === true">
      <div class="circle-col">
        <div class="circle circle-text" [style.background]="ag.colour">
          <span>{{ag.value}}</span>
        </div>
      </div>
      <div class="ag-col">
        <div class="ag-name">{{ag.groupTitle}}</div>
        <span class="ag-desc">{{ag.groupDescription}}</span>
      </div>
    </div>
  </ng-container>
</ng-container>
