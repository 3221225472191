import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LongRunningTaskService } from '../service/long-running-task.service';
import { LongRunningTaskProgressBarComponent } from '../component/long-running-task-progress-bar/long-running-task-progress-bar.component';
import { RouterModule } from '@angular/router';
import { SharedModule } from '@app/shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { LongRunningTaskOverviewComponent } from '../component/long-running-task-overview/long-running-task-overview.component';
import { LongRunningTaskEventDisplayComponent } from '../component/long-running-task-event-display/long-running-task-event-display.component';
import { LongRunningTaskDisplayComponent } from '../component/long-running-task-display/long-running-task-display.component';

const components = [
  LongRunningTaskProgressBarComponent,
  LongRunningTaskOverviewComponent,
  LongRunningTaskDisplayComponent,
  LongRunningTaskEventDisplayComponent
];

@NgModule({
  declarations: components,
  exports: components,
  imports: [
    CommonModule,
    RouterModule,
    TranslateModule.forChild(),
    TooltipModule,
    SharedModule
  ],
  providers: [
    LongRunningTaskService
  ]
})
export class LongRunningTaskModule { }
