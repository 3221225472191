import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { AssessmentGroupInfo } from 'app/models/evaluation/assessment-group-info.model';

@Component({
  selector: 'app-one-to-three-scale',
  templateUrl: './one-to-three-scale.component.html',
  styleUrls: ['./one-to-three-scale.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => OneToThreeScaleComponent),
    multi: true
  }]
})
export class OneToThreeScaleComponent implements ControlValueAccessor {
  @Input() disabled = false;
  @Input() display = false;

  @Input() assessmentGroups: AssessmentGroupInfo[];
  _value: string;

  onChange = (_: any) => {};
  onTouched = () => {};

  constructor() {
    this._value = '0';
    this.assessmentGroups = [];
  }

  get value(): string {
    return this._value;
  }

  set value(val: string) {
    if (val !== this._value) {
      this._value = val;
      this.onChange(val);
    }
  }

  select(index: any) {
    if (this.disabled || this.display) { return; }
    this.value = index;
  }

  writeValue(value: any) {
    if (value === '1' || value === '2' || value === '3') {
      this.value = value;
    } else {
      this.value = '0';
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }
}
