import { InjectableRxStompConfig } from '@stomp/ng2-stompjs';
import { environment } from 'environments/environment';

export const RxStompConfig: InjectableRxStompConfig = {

    // This needs to be overwritten later in the case localStorage.getItem('access_token') is null
    brokerURL: `${environment.websocketUrl}?token=${localStorage.getItem('access_token')}`,

    // Headers
    // Typical keys: login, passcode, host
    connectHeaders: {
    },

    // NOTE: These are not used by the backend, the backend sets these in the WebSocketConfiguration configureMessageBroker
    // Interval in milliseconds, set to 0 to disable
    heartbeatIncoming: 0, // Typical value 0 - disabled
    heartbeatOutgoing: 20000, // Typical value 20000 - every 20 seconds

    // Wait in milliseconds before attempting auto reconnect
    // Set to 0 to disable
    // Typical value 5000 (5 seconds) - changed to stop spam
    reconnectDelay: 20000,

    // Will log diagnostics on console
    // It can be quite verbose, not recommended in production
    // Skip this key to stop logging to console
    debug: (str) => {
        console.debug(new Date(), str);
    }

};
