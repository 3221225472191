import { Component, ElementRef, forwardRef, Input, OnInit, ViewChild } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { CommonMessages } from '@app/constants/common.messages';
import { IState } from '@app/models/state/state.model';
import { Globals } from '@app/shared/globals/globals';
import { debounceTime, map } from 'rxjs/operators';
import { InterestType } from '../../model/interest-type.model';
import { Interest } from '../../model/interest.model';
import { InterestsBusinessService } from '../../service/interests-business.service';

interface PageState extends IState {
  searchRunning: boolean;
  dropdownOpen: boolean;
  disabled: boolean;
  searchIsEmpty: boolean;
}

type PickerValue = (Interest | Interest[]);

@Component({
  selector: 'app-interest-picker',
  templateUrl: './interest-picker.component.html',
  styleUrls: ['./interest-picker.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => InterestPickerComponent),
    multi: true,
  }],
})
export class InterestPickerComponent implements OnInit, ControlValueAccessor {
  public readonly eCommonMessages = CommonMessages;

  @ViewChild('searchInput') searchInput?: ElementRef<HTMLInputElement>;

  @Input() searchPlaceholder: string;  
  @Input() customOptions: Interest[];
  @Input() customOptionsOnly: boolean;
  @Input() canSelectMultiple: boolean;
  @Input() blacklistedIds: number[];
  @Input() allowedTypes: InterestType[];
  @Input() loseFocusAfterSelection: boolean;
  @Input() clearSearchAfterSelection: boolean;

  state: PageState;
  searchControl: FormControl;
  results: Interest[];
  _value: Interest[];
  searchIsFocused: boolean;

  onChange = (_: any) => {};
  onTouched = () => {};

  get value(): PickerValue {
    return (this.canSelectMultiple ? this._value : this._value[0]);
  }

  set value(v: PickerValue) {
    if (this.state.disabled) { return; }
    this.writeValue(v);
    this.onChange(v);
  }

  constructor(
    public globals: Globals,
    private roleBusinessService: InterestsBusinessService
  ) {
    this.searchControl = new FormControl();
    this._value = [];
    this.results = [];
    this.blacklistedIds = [];
    this.allowedTypes = null;

    this.searchPlaceholder = CommonMessages.SEARCH_BY_NAME;

    this.customOptions = [];
    this.customOptionsOnly = false;
    this.canSelectMultiple = false;

    this.state = {
      loading: true,
      error: false,
      errorMessage: '',
      searchRunning: false,
      dropdownOpen: false,
      disabled: false,
      searchIsEmpty: true
    };
    this.searchIsFocused = false;
    this.loseFocusAfterSelection = true;
    this.clearSearchAfterSelection = true;
  }

  ngOnInit(): void {
    this.state.loading = false;
    this.searchControl.valueChanges
      .pipe(
        map(sarg => {
          this.state.searchRunning = true;
          if (sarg) {
            sarg = sarg.trim().toLowerCase();
          }
          return sarg;
        }),
        debounceTime(500)
      )
      .subscribe(sarg => this.trySearch(sarg));
  }

  trySearch(sarg: string): void {
    this.state.searchRunning = true;

    this.state.searchIsEmpty = !(sarg && sarg.length > 0);

    if (this.customOptionsOnly) {
      this.doSearchCustomOnly(this.customOptions, sarg);
      return;
    } else {
      this.doSearchRegular(this.customOptions, sarg);
    }

  }
  
  doSearchCustomOnly(customOptions: Interest[], sarg: string): void {
    let results = customOptions;
    if (sarg) {
      results = results.filter(s => s.name.toLowerCase().includes(sarg)); // Only matches to search
    }
    results = results.slice(0, 5); // Only first 5
    const selectedInterestIds = this._value.map(v => v.id);
    results = results.filter(r => !selectedInterestIds.includes(r.id)); // Not already selected
    results = results.filter(r => !this.blacklistedIds.includes(r.id)); // Not blacklisted

    this.results = results;
    this.state.searchRunning = false;
    this.state.loading = false;
  }

  doSearchRegular(customOptions: Interest[], sarg: string): void {
    let matchingCustomResults = [];
    if (sarg) {
      matchingCustomResults = customOptions.filter(s => s.name.toLowerCase().includes(sarg));
    }

    this.roleBusinessService.getInterests(null, sarg, null, this.allowedTypes, true).toPromise()
      .then(results => {
        results = [...matchingCustomResults, ...results ];
        const selectedInterestIds = this._value.map(v => v.id);
        results = results.filter(r => !selectedInterestIds.includes(r.id));
        results = results.filter(r => !this.blacklistedIds.includes(r.id));
        this.results = results.slice(0, 5);
      })
      .finally(() => {
        this.state.searchRunning = false;
        this.state.loading = false;
      });
  }

  selectItem(role: Interest): void {
    if (this.state.disabled) { return; }

    if (this.canSelectMultiple) {
      this.addItemToMultiplePickerValue(role);
    } else {
      this.value = role;
      this.state.dropdownOpen = false;
    }

    this.blurSearchIfToggled();
    this.clearSearchIfToggled();

    this.onTouched();
    this.searchControl.updateValueAndValidity();
  }

  blurSearchIfToggled(): void {
    if (!this.loseFocusAfterSelection) {
      this.focusSearchInput();
    } else {
      this.blurSearchInput();
    }
  }

  clearSearchIfToggled(): void {
    if (this.clearSearchAfterSelection) {
      this.searchControl.patchValue('');
    }
  }

  addItemToMultiplePickerValue(role: Interest): void {
    const selectedIds = this._value.map(r => r.id);
    if (selectedIds.includes(role.id)) { return; }
    this.value = [...this._value, role];
  }

  removeSelectedItem(role: Interest, event?: MouseEvent): void {
    if (!this.canSelectMultiple) { return; }
    if (event) {
      event.stopPropagation();
    }
    this.value = this._value.filter(r => r.id !== role.id);
  }

  writeValue(v: PickerValue): void {
    if (!v) { return; }
    if (this.canSelectMultiple) {
      this._value = v as Interest[];
    } else {
      this._value = [v as Interest];
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.state.disabled = isDisabled;
    if (isDisabled) {
      this.searchControl.disable();
    } else {
      this.searchControl.enable();
    }
  }

  onFocusSearch(): void {
    this.searchIsFocused = true;
    if (this.state.disabled) { return; }

    this.state.dropdownOpen = true;
  }

  onBlurSearch(): void {
    this.searchIsFocused = false;

    setTimeout(() => {
      if (this.searchIsFocused) { return; }
      this.state.dropdownOpen = false;
    }, 200);
  }

  trySelectFirst() {
    if (this.results.length === 0) {
      if (!this.searchControl.value) { return this.searchControl.updateValueAndValidity(); }
      return;
    } // Can't select first if there are zero results

    this.selectItem(this.results[0]);

    if (!this.canSelectMultiple) {
      this.blurSearchInput();
    }
  }

  focusSearchInput(): void {
    if (!this.searchInput) { return; }
    this.searchInput.nativeElement.focus();
    this.state.dropdownOpen = true;
  }

  blurSearchInput(): void {
    if (!this.searchInput) { return; }
    this.searchInput.nativeElement.blur();
    this.state.dropdownOpen = false;
  }

  onKeyupBackspace(): void {
    if (this.searchControl.value) { return; }
    if (!this.canSelectMultiple) { return; }
    if (!this.state.searchIsEmpty) { return; }
    if (this.state.searchRunning) { return; }

    // Get value
    const value = this.value as Interest[];

    if (!value) { return; }
    if (value.length === 0) { return; }

    value.pop();

    this.value = value;
    this.searchControl.updateValueAndValidity();
  }

  onKeyupEscape(): void {
    this.blurSearchInput();
  }
}
