import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AssessmentGroupInfo } from '@app/models/evaluation/assessment-group-info.model';
import { EvaluationCycle } from '@app/models/evaluation/evaluation-cycle.model';
import { CycleStepMap, EvaluationModuleType } from '@app/models/evaluation/evaluation-module-type.model';
import { SelfReflection } from '@app/models/evaluation/self-reflection.model';

@Component({
  selector: 'app-self-reflection-display',
  templateUrl: './self-reflection-display.component.html',
  styleUrls: ['./self-reflection-display.component.scss']
})
export class SelfReflectionDisplayComponent implements OnInit {

  public readonly eEvaluationModuleType = EvaluationModuleType;

  @Input() cycle: EvaluationCycle;
  @Input() selfReflection: SelfReflection;
  @Input() assessmentGroups: CycleStepMap<AssessmentGroupInfo[]>;
  @Input() startExpanded: boolean;

  @Output() onDropdownOpened: EventEmitter<number>;

  dropdownOpen: boolean;

  constructor(
  ) {
    this.cycle = undefined;
    this.selfReflection = undefined;
    this.startExpanded = false;
    this.assessmentGroups = undefined;

    this.dropdownOpen = false;

    this.onDropdownOpened = new EventEmitter<number>();
  }

  ngOnInit(): void {
    if (this.startExpanded) {
      this.expandDropdown();
    }
  }

  toggleDropdown(): void {
    if (this.dropdownOpen) {
      this.collapseDropdown();
    } else {
      this.expandDropdown();
    }
  }

  expandDropdown(): void {
    this.onDropdownOpened.emit(this.selfReflection.id);
    this.dropdownOpen = true;
  }

  collapseDropdown(): void {
    this.dropdownOpen = false;
  }
}
