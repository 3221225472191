import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { PillType } from '@app/shared/components/pill/pill.component';
import { Goal } from '@app/models/goals/goal.model';
import { GoalType } from '@app/models/goals/goal-type.enum';
import { GoalStatus } from '@app/models/goals/goal-status.model';

enum Status {
  OFF_TRACK = 'OFF_TRACK',
  PROGRESSING = 'PROGRESSING',
  ON_TRACK = 'ON_TRACK',
  ARCHIVED = 'ARCHIVED',
  COMPLETE = 'COMPLETE'
}

@Component({
  selector: 'app-goal-search-item',
  templateUrl: './goal-search-item.component.html',
  styleUrls: ['./goal-search-item.component.scss']
})
export class GoalSearchItemComponent implements OnChanges {
  public readonly ePillType = PillType;
  public readonly DATE_FORMAT = 'D MMM YYYY';

  @Input() goal!: Goal;
  @Input() canSelect: boolean = true;
  @Input() selected: boolean = false;
  @Output() onSelect: EventEmitter<boolean> = new EventEmitter<boolean>();

  status: Status = Status.PROGRESSING;

  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {
    const goal = changes['goal'];
    if (goal) {
      this.status = this.getStatus(goal.currentValue);
    }
  }

  getStatus(goal: Goal): Status {
    if (goal.archived) {
      return Status.ARCHIVED;
    }

    if (goal.complete) {
      return Status.COMPLETE;
    }

    switch (goal.status) {
      case GoalStatus.OFF_TRACK:
        return Status.OFF_TRACK;
      case GoalStatus.PROGRESSING:
        return Status.PROGRESSING;
      case GoalStatus.ON_TRACK:
        return Status.ON_TRACK;
    }
  }

  getIconClass(goal: Goal): string {
    switch (goal.type) {
      case GoalType.PERSONAL_DEVELOPMENTAL:
        return 'fal fa-graduation-cap';
      case GoalType.PERSONAL_OPERATIONAL:
        return 'fal fa-user';
      case GoalType.DEPARTMENT:
        return 'fal fa-users';
      case GoalType.COMPANY:
        return 'fal fa-building';
      case GoalType.OFFICE_LOCATION:
        return 'fal fa-business-time';
      case GoalType.TEAM:
        return 'fal fa-user-friends';
    }
  }

  selectGoal(): void {
    if (this.canSelect) {
      this.onSelect.emit(true);
    }
  }

}
