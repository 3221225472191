import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  FormGroup,
  NG_VALUE_ACCESSOR,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import moment from 'moment';
import { OneToOneMessages } from '@app/domain/one_to_one/locale/one-to-one.messages';
import { CommonMessages } from '@app/constants/common.messages';
import { TranslateService } from '@ngx-translate/core';

// TODO: Might need to move these to their own .models.ts files
enum FrequencyEnd {
  NEVER = 'NEVER',
  ON = 'ON',
  AFTER = 'AFTER'
}

enum FrequencyPeriod {
  DAY = 'DAILY',
  WEEK = 'WEEKLY',
  MONTH = 'MONTHLY',
  YEAR = 'YEARLY'
}

enum FrequencyMonthlyOption {
  ON_MONTH_DAY = 'ON_MONTH_DAY',
  ON_WEEK_DAY = 'ON_WEEK_DAY'
}

type CustomFrequencyDaysKey = ('sun' | 'mon' | 'tue' | 'wed' | 'thu' | 'fri' | 'sat');
interface CustomFrequencyDay{
  key: CustomFrequencyDaysKey, 
  display: string, 
  selected: boolean;
}
class CustomFrequencyDays {
  'sun': boolean;
  'mon': boolean;
  'tue': boolean;
  'wed': boolean;
  'thu': boolean;
  'fri': boolean;
  'sat': boolean;
}

export type BooleanFn = () => boolean;

export function conditionalValidator(predicate: BooleanFn, validator: ValidatorFn, errorNamespace?: string): ValidatorFn {
  return (formControl => {
    if (!formControl.parent) {
      return null;
    }
    let error = null;
    if (predicate()) {
      error = validator(formControl);
    }

    if (errorNamespace && error) {
      const customError = {};
      customError[errorNamespace] = error;
      error = customError;
    }
    return error;
  });
}

@Component({
  selector: 'app-custom-frequency',
  templateUrl: './custom-frequency.component.html',
  styleUrls: ['./custom-frequency.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => CustomFrequencyComponent),
    multi: true,
  }],
})
export class CustomFrequencyComponent implements OnInit, ControlValueAccessor {
  public readonly eFrequencyEnd = FrequencyEnd;
  public readonly eFrequencyPeriod = FrequencyPeriod;
  public readonly eFrequencyMonthlyOption = FrequencyMonthlyOption;
  public readonly eFrequencyOptions = FrequencyPeriod;
  public readonly daysOrder: CustomFrequencyDaysKey[] = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'];
  public readonly eOneToOneMessages = OneToOneMessages;
  public readonly eCommonMessages = CommonMessages;

  private _meetingTime: string;

  days: CustomFrequencyDay[] = [];
  // days: CustomFrequencyDays;
  customFrequencyForm!: FormGroup;
  frequencyCount!: FormControl;
  frequencyMonthOption!: FormControl;
  frequencyEnds!: FormControl;
  frequencyPeriod!: FormControl;
  frequencyEndsOn!: FormControl;
  frequencyEndsAfter!: FormControl;
  _value: string;

  @Input() submitted: boolean;

  @Output() customFrequencyChange = new EventEmitter<boolean>();

  frequencyDisplay = '';

  public get value(): string {
    return this._value;
  }

  public set value(val: string) {
    this._value = val;
  }

  @Input()
  set meetingTime(val: string) {
    this._meetingTime = val;
    this.selectMeetingStartDay();
    this.serializeRule();
    this.updateDisplay();
  }

  public get daysValid(): boolean {
    return ((this.frequencyPeriod.value !== FrequencyPeriod.WEEK) || (this.days.some(x => x.selected)));
  }

  constructor(
    private translateService: TranslateService
  ) {
    this._value = ''; // TODO: Assign the default value object to this
    this._meetingTime = '';
    this.submitted = false;

    this.initForm();
  }

  onChange = (_: any) => { };
  onTouched = () => { };

  writeValue(obj: string): void {
    this.value = obj;
    this.deserializeRule();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  ngOnInit(): void {
  }

  validOnDate(): boolean {
    if (this.customFrequencyForm.get('frequencyEnds')?.value !== FrequencyEnd.ON) {
      return true;
    }

    const mt = moment(this._meetingTime);
    const endOn = moment(this.customFrequencyForm.get('frequencyEndsOn')?.value);
    if (endOn.diff(mt, 'days') < 0) {
      return false;
    }
    return true;
  }

  initForm(): void {
    this.customFrequencyForm = new FormGroup({
      frequencyCount: new FormControl(1, [Validators.required, Validators.pattern(/^(\d{1,22})(.\d{1,2})?$/), Validators.min(1)]),
      frequencyMonthOption: new FormControl(FrequencyMonthlyOption.ON_MONTH_DAY, []),
      frequencyEnds: new FormControl(FrequencyEnd.NEVER, []),
      frequencyPeriod: new FormControl(FrequencyPeriod.DAY, []),
      frequencyEndsOn: new FormControl(new Date(), []),
      frequencyEndsAfter: new FormControl(12, [
        conditionalValidator(() => this.customFrequencyForm.get('frequencyEnds')?.value === FrequencyEnd.AFTER, Validators.required),
        conditionalValidator(() => this.customFrequencyForm.get('frequencyEnds')?.value === FrequencyEnd.AFTER, Validators.pattern(/^(\d{1,22})(.\d{1,2})?$/)),
        conditionalValidator(() => this.customFrequencyForm.get('frequencyEnds')?.value === FrequencyEnd.AFTER, Validators.min(1))
      ])
    });

    this.customFrequencyForm.valueChanges.subscribe(() => {
      this.serializeRule();
      this.updateDisplay();
      this.customFrequencyChange.emit(this.customFrequencyForm.valid && this.daysValid && this.validOnDate());

    });

    this.frequencyCount = this.customFrequencyForm.controls.frequencyCount as FormControl;
    this.frequencyMonthOption = this.customFrequencyForm.controls.frequencyMonthOption as FormControl; // TODO: Form options and default value for this - may need to populate when date is changed in parent form
    this.frequencyEnds = this.customFrequencyForm.controls.frequencyEnds as FormControl;
    this.frequencyPeriod = this.customFrequencyForm.controls.frequencyPeriod as FormControl;
    this.frequencyEndsOn = this.customFrequencyForm.controls.frequencyEndsOn as FormControl;
    this.frequencyEndsAfter = this.customFrequencyForm.controls.frequencyEndsAfter as FormControl;

    this.frequencyEnds.valueChanges.subscribe(() => {
      this.frequencyEndsAfter.updateValueAndValidity();
    });

    this.initDays();
  }

  // initFrequencyOptions(): FrequencyPeriod[] {
  //   const keys = Object.keys(FrequencyPeriod);
  //   return keys.map(k => FrequencyPeriod[k] as FrequencyPeriod);
  // }

  initDays(): void {
    this.days.push({key: 'sun', display: this.getDayMessageCode('sun').slice(0,3), selected: false});
    this.days.push({key: 'mon', display: this.getDayMessageCode('mon').slice(0,3), selected: false});
    this.days.push({key: 'tue', display: this.getDayMessageCode('tue').slice(0,3), selected: false});
    this.days.push({key: 'wed', display: this.getDayMessageCode('wed').slice(0,3), selected: false});
    this.days.push({key: 'thu', display: this.getDayMessageCode('thu').slice(0,3), selected: false});
    this.days.push({key: 'fri', display: this.getDayMessageCode('fri').slice(0,3), selected: false});
    this.days.push({key: 'sat', display: this.getDayMessageCode('sat').slice(0,3), selected: false});
  }

  selectMeetingStartDay(): void {
    const index = moment(this._meetingTime).day();

    const selectedCount = this.days.filter(x => x.selected).length;

    if(selectedCount === 1){
      this.days.forEach(x => x.selected = false);
    }
    this.days[index].selected = true;
    
  }

  isDaySelected(index: number): boolean{
    return this.days[index].selected;
  }
  toggleDay(index: number): void {
    //Selected meeting day weekday can't be unselected as it will break the calendar
    const meetingDayIndex = moment(this._meetingTime).day();
    if(index === meetingDayIndex){
      return;
    }
    const day = this.days[index];
    day.selected = !day.selected;
    this.serializeRule();
    this.updateDisplay();
    this.customFrequencyChange.emit(this.customFrequencyForm.valid && this.daysValid && this.validOnDate());
  }

  getMonthlyText() {
    const date = moment(this._meetingTime).toDate();
    return this.getOrdinal(Math.ceil(date.getDate() / 7)) + ' ' + moment(date).format('dddd');
  }

  getDayText() {
    const date = moment(this._meetingTime).toDate();
    return Math.ceil(date.getDate());
  }

  getOrdinal(n: number): string {
    switch (n) {
      case 1:
        return this.translateService.instant(OneToOneMessages.FIRST);
      case 2:
        return this.translateService.instant(OneToOneMessages.SECOND);
      case 3:
        return this.translateService.instant(OneToOneMessages.THIRD);
      case 4:
        return this.translateService.instant(OneToOneMessages.FOURTH);
      case 5:
        return this.translateService.instant(OneToOneMessages.LAST);
      default:
        return '';
    }
  }

  getFrequencyMessageCode(frequency: FrequencyPeriod, usePlural: boolean) {
    switch (frequency) {
      case FrequencyPeriod.DAY:
        return usePlural ? OneToOneMessages.DAYS : OneToOneMessages.DAY;
      case FrequencyPeriod.WEEK:
        return usePlural ? OneToOneMessages.WEEKS : OneToOneMessages.WEEK;
      case FrequencyPeriod.MONTH:
        return usePlural ? OneToOneMessages.MONTHS : OneToOneMessages.MONTH;
      case FrequencyPeriod.YEAR:
        return usePlural ? OneToOneMessages.YEARS : OneToOneMessages.YEAR;
    }
  }

  getDayMessageCode(day: CustomFrequencyDaysKey) {
    switch (day) {
      case 'sun':
        return CommonMessages.SUNDAY;
      case 'mon':
        return CommonMessages.MONDAY;
      case 'tue':
        return CommonMessages.TUESDAY;
      case 'wed':
        return CommonMessages.WEDNESDAY;
      case 'thu':
        return CommonMessages.THURSDAY;
      case 'fri':
        return CommonMessages.FRIDAY;
      case 'sat':
        return CommonMessages.SATURDAY;
    }
  }

  getDisplayRepeats(interval: number, frequency: string){
    if(interval == 1) {
      return this.translateService.instant(OneToOneMessages.CUSTOM_FREQUENCY_REPEATS_SINGULAR, {periodSingular: frequency, interval: interval});
    } else {
      return this.translateService.instant(OneToOneMessages.CUSTOM_FREQUENCY_REPEATS_PLURAL, {periodPlural: frequency, interval: interval});
    }
    
  }


  getDisplayRepeatsWeek(interval: number, frequency: string){
    let days = '';

    this.days.filter(x => x.selected).forEach(x => {
      const day = this.translateService.instant(this.getDayMessageCode(x.key));
      days += `${day}, `;
    });
    days = days.substring(0, days.length - 2);
    if(interval == 1) {
      return this.translateService.instant(OneToOneMessages.CUSTOM_FREQUENCY_REPEATS_ON_SINGULAR, {periodSingular: frequency, interval: interval, repeatsOn: days});
    } else {
      return this.translateService.instant(OneToOneMessages.CUSTOM_FREQUENCY_REPEATS_ON_PLURAL, {periodPlural: frequency, interval: interval, repeatsOn: days});
    }
  }

  getDisplayRepeatsMonth(interval: number, frequency: FrequencyPeriod){

    if(this.frequencyMonthOption.value === FrequencyMonthlyOption.ON_MONTH_DAY){
      const data = {day: this.getDayText(), interval: interval.toString()};
     
      if(interval == 1){
        return this.translateService.instant(OneToOneMessages.CUSTOM_FREQUENCY_REPEATS_MONTHLY_ON_DAY_SINGULAR, data);
      }
      else{
        return this.translateService.instant(OneToOneMessages.CUSTOM_FREQUENCY_REPEATS_MONTHLY_ON_DAY_PLURAL, data);
      }
    }else{
      const data = {day: this.getMonthlyText(), interval: interval.toString()};
      if(interval == 1){
        return this.translateService.instant(OneToOneMessages.CUSTOM_FREQUENCY_REPEATS_MONTHLY_ON_DATE_SINGULAR, data);
      }
      else{
        return this.translateService.instant(OneToOneMessages.CUSTOM_FREQUENCY_REPEATS_MONTHLY_ON_DATE_PLURAL, data);
      }
    }
  }

  updateDisplay(){
    let frequencyDisplay = '';
    const interval = this.frequencyCount.value ? this.frequencyCount.value : 1;
    const frequency = this.translateService.instant(this.getFrequencyMessageCode(this.frequencyPeriod.value, interval > 1)).toLowerCase();
    switch(this.frequencyPeriod.value){
      case FrequencyPeriod.DAY:
      case FrequencyPeriod.YEAR: 
        frequencyDisplay = this.getDisplayRepeats(interval, frequency.toLowerCase());
        break;
      case FrequencyPeriod.WEEK: 
        frequencyDisplay = this.getDisplayRepeatsWeek(interval, frequency.toLowerCase());
        break;
      case FrequencyPeriod.MONTH: 
        frequencyDisplay = this.getDisplayRepeatsMonth(interval, frequency.toLowerCase());
        break;                    
    }
    let frequencyEnd = '';
    if(this.frequencyEnds.value === FrequencyEnd.AFTER){
      
      
      if(this.frequencyEndsAfter.value  == 1){
        frequencyEnd = this.translateService.instant(OneToOneMessages.CUSTOM_FREQUENCY_ENDS_AFTER_SINGULAR);
      } else {
        frequencyEnd = this.translateService.instant(OneToOneMessages.CUSTOM_FREQUENCY_ENDS_AFTER_PLURAR, {number: this.frequencyEndsAfter.value});
      }
    } else if(this.frequencyEnds.value === FrequencyEnd.ON){
      const date = moment(this.frequencyEndsOn.value).format('DD MMMM YYYY');
      frequencyEnd = this.translateService.instant(OneToOneMessages.CUSTOM_FREQUENCY_ENDS_ON, {date});
      
    }

    if(frequencyEnd.length > 0){
      frequencyDisplay = `${frequencyDisplay}. ${frequencyEnd}`;
    }
    this.frequencyDisplay = frequencyDisplay;
  }

  serializeRule() {
    const frequencyRule = this.getFrequencyRule();

    const intervalRule = `INTERVAL=${this.frequencyCount.value};`;
    const bydayRule = this.getByDayRule();
    const endsRule = this.getEndsRule();
    const monthlyRule = this.getMonthlyRule();
    const rule = `${frequencyRule}${bydayRule}${monthlyRule}${intervalRule}${endsRule}`;
    this._value = rule;
    this.onChange(this.value);
  }



  getFrequencyRule() {
    return `FREQ=${this.frequencyPeriod.value};`;
  }

  getByDayRule() {
    if (this.frequencyPeriod.value !== this.eFrequencyPeriod.WEEK) {
      return '';
    }
    let byDay = 'BYDAY=';
    this.days.filter(x => x.selected).forEach(e => {
      byDay += `${e.key.substring(0, 2).toUpperCase()},`;
    });
    if (byDay.length > 0) {
      byDay = byDay.substring(0, byDay.length - 1) + ';';
    }
    return byDay;
  }

  getMonthlyRule() {
    if (this.frequencyPeriod.value !== this.eFrequencyPeriod.MONTH) {
      return '';
    }

    let monthlyRule = '';

    switch (this.frequencyMonthOption.value) {
      case FrequencyMonthlyOption.ON_MONTH_DAY:
        monthlyRule = `BYMONTHDAY=${this.getDayText()};`;
        break;
      case FrequencyMonthlyOption.ON_WEEK_DAY:
        monthlyRule = `${this.getBySetPosRule()}`;
        break;
    }
    return monthlyRule;
  }

  getBySetPosRule() {
    const date = moment(this._meetingTime).toDate();
    let day = Math.ceil(date.getDate() / 7);
    const dayOfTheWeek = moment(date).format('dd').toUpperCase();
    if (day === 5) {
      day = -1;
    }
    return `BYSETPOS=${day};BYDAY=${dayOfTheWeek};`;
  }

  getEndsRule() {
    let endsRule = '';
    switch (this.frequencyEnds.value) {
      case FrequencyEnd.NEVER:
        break;
      case FrequencyEnd.ON:
        const date = moment(this.frequencyEndsOn.value).format('YYYYMMDD');
        endsRule = `UNTIL=${date};`;
        break;
      case FrequencyEnd.AFTER:
        endsRule = `COUNT=${this.frequencyEndsAfter.value};`;
        break;
    }

    return endsRule;
  }

  keepOrder(a: any, b: any) {
    return a;
  }

  buildRuleDisplay(){
    if (!this.value || this.value.length === 0) {
      this.frequencyDisplay = '';
      return;
    }

    const rules = this.value.split(';');
    let frequencyRule = '';
    let interval = 1;
    const byDayRule = '';
    const bySetPosRule = '';
    
    rules.forEach(rule => {
      if (rule.startsWith('FREQ=')) {
        frequencyRule = rule;
      } else if (rule.startsWith('INTERVAL=')) {
        interval = Number(rule.substring(9).replace(';', ''));
      } else if (rule.startsWith('UNTIL=')) {
        // this.setUntilRule(rule);
      } else if (rule.startsWith('COUNT=')) {
        // this.setAfterRule(rule);
      } else if (rule.startsWith('BYDAY=')) {
        // byDayRule = rule;
      } else if (rule.startsWith('BYSETPOS=')) {
        // bySetPosRule = rule;
      }
    });

    this.setFrequencyRule(frequencyRule);
    if (bySetPosRule) {
      this.setMonthlyRule(bySetPosRule, byDayRule);
    } else if (byDayRule) {
      this.setWeeklyRule(byDayRule);
    }

  }

  // #region - deserializeRule
  deserializeRule() {
    if (!this.value || this.value.length === 0) {
      return;
    }

    const rules = this.value.split(';');
    let frequencyRule = '';
    let byDayRule = '';
    let bySetPosRule = '';

    rules.forEach(rule => {
      if (rule.startsWith('FREQ=')) {
        frequencyRule = rule;
      } else if (rule.startsWith('INTERVAL=')) {
        this.setIntervalRule(rule);
      } else if (rule.startsWith('UNTIL=')) {
        this.setUntilRule(rule);
      } else if (rule.startsWith('COUNT=')) {
        this.setAfterRule(rule);
      } else if (rule.startsWith('BYDAY=')) {
        byDayRule = rule;
      } else if (rule.startsWith('BYSETPOS=')) {
        bySetPosRule = rule;
      }

    });

    this.setFrequencyRule(frequencyRule);
    if (bySetPosRule) {
      this.setMonthlyRule(bySetPosRule, byDayRule);
    } else if (byDayRule) {
      this.setWeeklyRule(byDayRule);
    }
  }

  setFrequencyRule(rule: string) {
    const value = rule.substring(5).replace(';', '');
    this.frequencyPeriod.setValue(value);
  }
  setIntervalRule(rule: string) {
    const value = rule.substring(9).replace(';', '');
    this.frequencyCount.setValue(value);
  }
  setUntilRule(rule: string) {
    const value = rule.substring(6).replace(';', '');
    this.frequencyEnds.setValue(FrequencyEnd.ON);
    const date = moment(rule, 'YYYYMMDD').toDate();
    this.frequencyEndsOn.setValue(date);
    // set the date when display is sorted!
  }

  setAfterRule(rule: string) {
    const value = rule.substring(6).replace(';', '');
    this.frequencyEnds.setValue(FrequencyEnd.AFTER);
    this.frequencyEndsAfter.setValue(value);
  }

  setMonthlyRule(bySetPosRule: string, byDayRule: string) {
    this.frequencyMonthOption.setValue(FrequencyMonthlyOption.ON_WEEK_DAY);
  }

  setWeeklyRule(rule: string) {
    const days = rule.substring(6).replace(';', '').split(',');
    this.days.forEach(k => {
      k.selected = days.indexOf(k.key.substring(0, 2).toUpperCase()) >= 0;
    });
  }
  // #endregion

}
