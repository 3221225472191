import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-dropdown-display',
  templateUrl: './dropdown-display.component.html',
  styleUrls: ['./dropdown-display.component.scss']
})
export class DropdownDisplayComponent implements OnInit {
  @Input() title: string;
  @Input() description: string;
  @Input() expanded: boolean;
  @Input() minContentHeightClosed: string;
  @Input() iconClass?: string;
  @Output() expandedChange: EventEmitter<boolean>;

  // NOTE: Was going to apply this as positive padding and
  // negative margin to fix any overflow (from datepickers or dropdowns)
  // but it was being weird so I removed it.
  // Might be a good approach if the current overflow stuff doesn't work
  // well in goal create
  // @Input() overflowBottom: string;

  constructor() {
    this.title = 'Title';
    this.description = 'Description here';
    this.minContentHeightClosed = 'unset';
    this.expanded = false;
    this.iconClass = undefined;
    this.expandedChange = new EventEmitter<boolean>();
  }

  ngOnInit(): void {
  }

  onClickHeader(event: MouseEvent): void {
    this.toggleExpanded();
  }

  toggleExpanded(): void {
    this.setExpanded(!this.expanded);
  }

  setExpanded(value: boolean): void {
    this.expanded = value;
    this.expandedChange.emit(value);
  }
}
