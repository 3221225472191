import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {PaginationService} from './pagination.service';
import {PaginationNewComponent} from './pagination-new/pagination-new.component';
import {FormsModule} from '@angular/forms';
import {PaginationServerComponent} from './pagination-server/pagination-server.component';
import {TranslateModule} from '@ngx-translate/core';
import {PaginationComponent} from './pagination.component';

@NgModule({
  imports: [RouterModule, CommonModule, FormsModule, TranslateModule],
  declarations: [PaginationComponent, PaginationNewComponent, PaginationServerComponent],
  exports: [PaginationComponent, PaginationNewComponent, PaginationServerComponent],
  providers: [PaginationService]
})

export class PaginationModule { }
