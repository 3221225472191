import { NewsActivity, NewsActivityType } from '@app/models/news-activity.model';
import { mockUsers } from '@app/mock/api/data/mockUsers';
import { mockPraise } from '@app/mock/api/data/mockPraise';

export const mockNewsActivity: NewsActivity[] = [
  {
    id: 1,
    companyId: 1,
    userId: mockUsers[0].id,
    organisationalUnitId: mockUsers[0].organisationalUnit.id,
    officeLocationId: mockUsers[0].officeLocation.id,
    foreignId: mockPraise[0].id,
    timestamp: mockPraise[0].timestamp,
    type: NewsActivityType.PRAISE_SENT,
    message: `<span><img class="owner-img" src="${mockUsers[0].imageUrl}"/> ${mockUsers[0].firstName} has praised <img class="owner-img" src="${mockUsers[1].imageUrl}"/> ${mockUsers[1].firstName}!</span>`,
    link: '/profile/' + mockUsers[1].id,
    icon: 'fal fa-bullhorn'
  },
  {
    id: 2,
    companyId: 1,
    userId: mockUsers[0].id,
    organisationalUnitId: mockUsers[0].organisationalUnit.id,
    officeLocationId: mockUsers[0].officeLocation.id,
    foreignId: mockPraise[1].id,
    timestamp: mockPraise[1].timestamp,
    type: NewsActivityType.PRAISE_SENT,
    message: `<span><img class="owner-img" src="${mockUsers[0].imageUrl}"/> ${mockUsers[0].firstName} has praised <img class="owner-img" src="${mockUsers[2].imageUrl}"/> ${mockUsers[2].firstName}!</span>`,
    link: '/profile/' + mockUsers[1].id,
    icon: 'fal fa-bullhorn'
  }
];