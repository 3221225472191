import { Component, Input, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { FormControl } from '@angular/forms';
import { CommonMessages } from '@app/constants/common.messages';
import { GoalsMessages } from '@app/goals/goals.messages';
import { Department } from '@app/models/department.model';
import { CompanyAPIService } from '@app/shared/api/company/company.api.service';
import { Globals } from '@app/shared/globals/globals';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CommonGoalCreateFormPart } from '../../goal-create-views/goal-create-main/goal-create-main.component';
import { TerminologyEntity } from '@app/domain/terminology/model/terminology-entity.enum';

@Component({
  selector: 'app-goal-create-part-department',
  templateUrl: './goal-create-part-department.component.html',
  styleUrls: ['./goal-create-part-department.component.scss']
})
export class GoalCreatePartDepartmentComponent implements OnInit, OnDestroy, CommonGoalCreateFormPart {

  public readonly eTerminologyEntity = TerminologyEntity;
  public readonly eCommonMessages = CommonMessages;
  public readonly eGoalsMessages = GoalsMessages;

  private readonly ngUnsubscribe$ = new Subject<void>();

  @Input() controlDepartment: FormControl;
  @Input() submitted: boolean;
  @Input() submittedDraft: boolean;
  @Input() parentDropdownOpen: boolean;

  @Output() onSetParentDropdown: EventEmitter<boolean>;

  possibleDepartments: Department[];
  possibleDepartmentsFiltered: Department[];

  departmentItemSelected?: Department;

  searchControl: FormControl;

  get possibleDepartmentsDisplay(): Department[] {
    return this.possibleDepartments
      .filter(d => {
        if ((d.id !== this.controlDepartment.value) && (!this.parentDropdownOpen)) {
          return false;
        }

        const searchArgument = this.searchControl.value.trim().toLocaleLowerCase();

        if (!searchArgument) {
          return true;
        }

        if (searchArgument.length === 0) {
          return true;
        }

        if(d.name.trim().toLocaleLowerCase().includes(searchArgument)) {
          return true;
        }

        return false;
      });
  }

  constructor(
    private companyAPIService: CompanyAPIService,
    public globals: Globals
  ) {
    this.controlDepartment = new FormControl(null, []);
    this.possibleDepartments = [];
    this.possibleDepartmentsFiltered = [];
    this.departmentItemSelected = undefined;
    this.submitted = false;
    this.submittedDraft = false;
    this.parentDropdownOpen = true;
    this.searchControl = new FormControl('', []);
    this.onSetParentDropdown = new EventEmitter<boolean>();
  }

  ngOnInit(): void {
    this.getPossibleDepartmentChoices();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.unsubscribe();
  }

  getPossibleDepartmentChoices(): void {
    this.companyAPIService.getAllDepartments()
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(
        (departments) => {
          this.possibleDepartments = departments;
          this.possibleDepartmentsFiltered = departments;
        }
      );
  }

  onSelectDepartment(department: Department): void {
    if (this.controlDepartment.value !== department) {
      this.controlDepartment.setValue(department.id);
    }

    if (!this.parentDropdownOpen) {
      this.controlDepartment.setValue(null);
      this.setParentDropdown(true);
    }
  }

  setParentDropdown(expanded: boolean): void {
    this.onSetParentDropdown.emit(expanded);
  }

  focus(): void {
    // TODO:
  }
}
