import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { VincereAPIService } from '@app/shared/api/vincere.api.service';

@Component({
    selector: 'app-vincere-callback',
    templateUrl: './vincere-callback.component.html',
    styleUrls: ['./vincere-callback.component.scss']
})
export class VincereCallbackComponent implements OnInit {

    constructor(private route: ActivatedRoute,
                private router: Router,
                private vincereAPIService: VincereAPIService) {
    }

    ngOnInit(): void {
        const code = this.route.snapshot.queryParamMap.get('code');
        if (code === null) {
            this.handleError('Something went wrong when trying to sign you in.')
        }

        this.vincereAPIService.clientToken(code!).subscribe(() => {
            this.router.navigateByUrl('admin/dashboard/integrations/vincere');
        })

    }

    private handleError(message: string) {
        localStorage.setItem('vincere_error', `${Date.now()}: ${message}`);
        this.router.navigateByUrl('admin/dashboard/integrations/vincere');
    }

}
