import { Observable, OperatorFunction } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { IAbsorbLmsAPI } from './absorblms-api.model';
import { AbsorbLmsToken, AbsorbLmsTokenServerSide, UpdateAbsorbLmsToken } from '../model/absorblms-token.model';
import { map } from 'rxjs/operators';
import { AbsorbLmsUserData } from '../model/absorblms-user-data.model';
import { AbsorbLmsEnrollment } from '../model/absorblms-enrollment.model';
import { AbsorbLmsCertificate } from '../model/absorblms-certificate.model';

@Injectable()
export class AbsorbLmsAPIService implements IAbsorbLmsAPI {

  private readonly BASE_URL = 'api/absorblms';

  constructor(private http: HttpClient) {
  }

  getCredentials(): Observable<AbsorbLmsToken> {
    const url = `${this.BASE_URL}/apikey/get`;

    return this.http.get<AbsorbLmsTokenServerSide>(url)
      .pipe(this.parseToken());
  }

  setCredentials(token: UpdateAbsorbLmsToken): Observable<AbsorbLmsToken> {
    const url = `${this.BASE_URL}/apikey/set`;

    return this.http.post<AbsorbLmsTokenServerSide>(url, token)
      .pipe(this.parseToken());
  }

  runManualSync(): Observable<boolean> {
    const url = `${this.BASE_URL}/manual-sync`;
    return this.http.get<boolean>(url);
  }

  getDataForUser(userId: number): Observable<AbsorbLmsUserData> {
    const url = `${this.BASE_URL}/user/${userId}`;
    return this.http.get<AbsorbLmsUserData>(url)
      .pipe(this.parseUserData());
  }

  hideEnrollment(enrollmentId: string): Observable<AbsorbLmsEnrollment> {
    const url = `${this.BASE_URL}/enrollment/${enrollmentId}/hide`;
    return this.http.put<AbsorbLmsEnrollment>(url, null);
  }

  showEnrollment(enrollmentId: string): Observable<AbsorbLmsEnrollment> {
    const url = `${this.BASE_URL}/enrollment/${enrollmentId}/show`;
    return this.http.put<AbsorbLmsEnrollment>(url, null);
  }

  hideCertificate(certificateId: string): Observable<AbsorbLmsCertificate> {
    const url = `${this.BASE_URL}/certificate/${certificateId}/hide`;
    return this.http.put<AbsorbLmsCertificate>(url, null);
  }

  showCertificate(certificateId: string): Observable<AbsorbLmsCertificate> {
    const url = `${this.BASE_URL}/certificate/${certificateId}/show`;
    return this.http.put<AbsorbLmsCertificate>(url, null);
  }

  private parseToken(): OperatorFunction<AbsorbLmsTokenServerSide, AbsorbLmsToken> {
    return map((token: AbsorbLmsTokenServerSide) => {
      if (!token) { return undefined; }
      return new AbsorbLmsToken(token);
    });
  }

  private parseUserData(): OperatorFunction<AbsorbLmsUserData, AbsorbLmsUserData> {
    return map((userData: AbsorbLmsUserData) => {
      if (!userData) { return undefined; }
      userData.enrollments = this.sortEnrollments(userData.enrollments);
      userData.certificates = this.sortCertificates(userData.certificates);
      return userData;
    });
  }

  private sortEnrollments(absorbLmsEnrollments: AbsorbLmsEnrollment[]): AbsorbLmsEnrollment[] {
    if (!absorbLmsEnrollments) { return undefined; }
    return absorbLmsEnrollments.sort((a, b) => {
      if (a.dateAdded > b.dateAdded) {
        return -1;
      } else if (a.dateAdded < b.dateAdded) {
        return 1;
      } else {
        return 0;
      }
    });
  }
  
  private sortCertificates(absorbLmsCertificates: AbsorbLmsCertificate[]): AbsorbLmsCertificate[] {
    if (!absorbLmsCertificates) { return undefined; }
    return absorbLmsCertificates.sort((a, b) => {
      if (a.dateAdded > b.dateAdded) {
        return -1;
      } else if (a.dateAdded < b.dateAdded) {
        return 1;
      } else {
        return 0;
      }
    });
  }
}
