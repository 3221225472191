import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {CreateTriNetConfiguration} from '@models/integrations/trinet/create-tri-net-configuration';
import {Observable} from 'rxjs';
import {TrinetService} from 'app/shared/api/interfaces/trinet.service';

@Injectable()
export class TrinetApiService implements TrinetService {
    private readonly baseUrl = 'api/tri-net';

    constructor(private readonly http: HttpClient) {
    }

    testTriNetCredentials(createTriNetCredentials: CreateTriNetConfiguration): Observable<void> {
        const url = `${this.baseUrl}/test-credentials`;
        return this.http.post<void>(url, createTriNetCredentials);
    }

    startManualSync(): Observable<void> {
        const url = `${this.baseUrl}/sync`;
        return this.http.get<void>(url);
    }

    testSyncSuccessEvent(): Observable<void> {
        const url = `${this.baseUrl}/test/success`;
        return this.http.get<void>(url);
    }
}
