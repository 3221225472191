<div class="center-screen text-center">
  <img class="bigger-icon" src="/assets/img/Failure.svg" />
  <h3 class="text-center display-3" *ngIf="!globals.hasRole(eRole.ADMIN) && !globals.hasRole(eRole.FRANKLI_ADMIN)">
    This feature is not enabled, please contact your Frankli admin if you would like it enabled.
  </h3>
  <h3 class="text-center display-3" *ngIf="(globals.hasRole(eRole.ADMIN) || globals.hasRole(eRole.FRANKLI_ADMIN)) && !globals.company.featureLocked">
    This feature is not enabled, you can enable it in your admin dashboard.
  </h3>

  <div class="p-top-15">
    <app-button [routerLink]="'/dashboard'">
      <span>Back to dashboard</span>
    </app-button>
    <ng-container *ngIf="(globals.hasRole(eRole.ADMIN) || globals.hasRole(eRole.FRANKLI_ADMIN)) && !globals.company.featureLocked">
      <span class="m-right-5"></span>
      <app-button [routerLink]="'/admin/dashboard/features'">
        <span>Go to Admin dashboard</span>
      </app-button>
    </ng-container>
  </div>
</div>