export const AuthMessages = {
  LOG_IN: 'auth.log-in',
  LOG_OUT: 'auth.log-out',
  EMAIL_ADDRESS: 'auth.email-address',
  PASSWORD: 'auth.password',
  CURRENT_PASSWORD: 'auth.current-password',
  NEW_PASSWORD: 'auth.new-password',
  CONFIRM_PASSWORD: 'auth.confirm-password',
  FORGOT_PASSWORD: 'auth.forgot-password',
  FORGOT_PASSWORD_DESCRIPTION: 'auth.forgot-password-description',
  CHANGE_PASSWORD: 'auth.change-password',
  SET_PASSWORD: 'auth.set-password',
  CHANGE_PASSWORD_DESCRIPTION: 'auth.change-password-description',
  PASSWORDS_MUST_MATCH: 'auth.passwords-must-match',
  ERROR_RESET_PASSWORD: 'auth.error-reset-password',
  SUCCESS_FORGOT_PASSWORD: 'auth.success-forgot-password',
  SUCCESS_CHANGE_PASSWORD: 'auth.success-change-password',
  SUCCESS_CREATE_PASSWORD: 'auth.success-create-password',
  SUCCESS_FORGOT_PASSWORD_DESCRIPTION: 'auth.success-forgot-password-description',
  RETURN_TO_LOGIN: 'auth.return-to-login',
  ABOUT: 'auth.about',
  SIGN_IN: 'auth.sign-in',
  PROMPT_ALTERNATE_LOGIN: 'auth.prompt-alternate-login',
  SSO_GOOGLE_INVALIDATE_PROMPT: 'auth.sso-google-invalidate-prompt',
  SSO_GOOGLE_INVALIDATE_PROMPT_DESCRIPTION: 'auth.sso-google-invalidate-prompt-description',
  SSO_GOOGLE_INVALIDATE_PROMPT_CONFIRM: 'auth.sso-google-invalidate-prompt-confirm',
  SSO_GOOGLE_INVALIDATED: 'auth.sso-google-invalidated',
  SSO_GOOGLE_INVALIDATED_DESCRIPTION: 'auth.sso-google-invalidated-description',
  FIRST_TIME_LOGIN: 'auth.first-time-login',
  FIRST_TIME_LOGIN_DESCRIPTION: 'auth.first-time-login-description',
  CAROUSELS: {
    PERFORMANCE: 'auth.carousels.performance',
    PRODUCTIVITY: 'auth.carousels.productivity',
    ENGAGEMENT: 'auth.carousels.engagement'
  },
  SIGN_UP: 'auth.sign-up',
  JOIN_FRANKLI: 'auth.join-frankli',
  REGISTER_HEADING: 'auth.register-heading',
  REGISTER_DESCRIPTION: 'auth.register-description',
  COMPANY_NAME: 'auth.company-name',
  EMAIL_CONFIRMATION_HEADING: 'auth.email-confirmation-heading',
  EMAIL_CONFIRMATION_DESCRIPTION: 'auth.email-confirmation-description',
  EMAIL_CONFIRMATION_PROVIDED: 'auth.email-confirmation-provided',
  BANNER_DEMO_CTA: 'auth.banner-demo-cta',
  LEGAL_AGREEMENTS: 'auth.legal-agreements',
  EMAIL_IN_USE: 'auth.email-in-use',
  CHANGE_IT: 'auth.change-it',
  LOGIN_HEADING: 'auth.login-heading',
  LOGIN_WITH_EMAIL: 'auth.login-with-email',
};
