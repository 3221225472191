import { AssessmentGroupInfo } from '@app/models/evaluation/assessment-group-info.model';
import { SurveyQuestionType } from '@app/models/survey-question-type.enum';
import { QuestionBankQuestionCategory } from '@app/models/evaluation/question-bank-question-category.enum';
import { mockEvaluationCycles } from '@app/mock/api/data/mockEvaluationCycles';
import { flatten } from '@angular/compiler';

export const mockAssessmentGroupInfos: AssessmentGroupInfo[] = flatten(mockEvaluationCycles.map(evaluationCycle => {
  const assessmentGroupInfos = new Array<AssessmentGroupInfo>();
  const keys = Object.keys(QuestionBankQuestionCategory);
  for (const questionBankQuestionCategoryKey of keys) {
    assessmentGroupInfos.push(...[
      {
        id: 1,
        cycleId: evaluationCycle.id,
        questionType: SurveyQuestionType.ONE_TO_FIVE,
        answerIndex: 0,
        category: QuestionBankQuestionCategory[questionBankQuestionCategoryKey as keyof QuestionBankQuestionCategory],
        value: '5',
        colour: '#008168',
        groupTitle: 'Significantly Exceeds Expectations',
        groupDescription: 'Delivered exceptional added value to business beyond scope'
      },
      {
        id: 2,
        cycleId: evaluationCycle.id,
        questionType: SurveyQuestionType.ONE_TO_FIVE,
        answerIndex: 1,
        category: QuestionBankQuestionCategory[questionBankQuestionCategoryKey as keyof QuestionBankQuestionCategory],
        value: '4',
        colour: '#30747F',
        groupTitle: 'Exceeds Expectations',
        groupDescription: 'Delivered differentiated value within business/work area/team'
      },
      {
        id: 3,
        cycleId: evaluationCycle.id,
        questionType: SurveyQuestionType.ONE_TO_FIVE,
        answerIndex: 2,
        category: QuestionBankQuestionCategory[questionBankQuestionCategoryKey as keyof QuestionBankQuestionCategory],
        value: '3',
        colour: '#FFC200',
        groupTitle: 'Meets Expectations',
        groupDescription: 'Delivered positive value to business/work area/team'
      },
      {
        id: 4,
        cycleId: evaluationCycle.id,
        questionType: SurveyQuestionType.ONE_TO_FIVE,
        answerIndex: 3,
        category: QuestionBankQuestionCategory[questionBankQuestionCategoryKey as keyof QuestionBankQuestionCategory],
        value: '2',
        colour: '#FB946E',
        groupTitle: 'Mostly Met Expectations',
        groupDescription: 'Delivered some added value to business/work area/team'
      },
      {
        id: 5,
        cycleId: evaluationCycle.id,
        questionType: SurveyQuestionType.ONE_TO_FIVE,
        answerIndex: 4,
        category: QuestionBankQuestionCategory[questionBankQuestionCategoryKey as keyof QuestionBankQuestionCategory],
        value: '1',
        colour: '#d0352c',
        groupTitle: 'Did Not Meet Expectations',
        groupDescription: 'Did not add value and/or negative impact'
      },
      {
        id: 6,
        cycleId: evaluationCycle.id,
        questionType: SurveyQuestionType.ONE_TO_FOUR,
        answerIndex: 0,
        category: QuestionBankQuestionCategory[questionBankQuestionCategoryKey as keyof QuestionBankQuestionCategory],
        value: '4',
        colour: '#008168',
        groupTitle: 'Top Performer',
        groupDescription: 'Role model. Hard to see how their performance could be improved upon'
      },
      {
        id: 7,
        cycleId: evaluationCycle.id,
        questionType: SurveyQuestionType.ONE_TO_FOUR,
        answerIndex: 1,
        category: QuestionBankQuestionCategory[questionBankQuestionCategoryKey as keyof QuestionBankQuestionCategory],
        value: '3',
        colour: '#30747F',
        groupTitle: 'Above Average Performer',
        groupDescription: 'Consistently delivers and has demonstrated strong potential in their role'
      },
      {
        id: 8,
        cycleId: evaluationCycle.id,
        questionType: SurveyQuestionType.ONE_TO_FOUR,
        answerIndex: 2,
        category: QuestionBankQuestionCategory[questionBankQuestionCategoryKey as keyof QuestionBankQuestionCategory],
        value: '2',
        colour: '#FFC200',
        groupTitle: 'Average Performer',
        groupDescription: 'Could apply themselves more to their current role'
      },
      {
        id: 9,
        cycleId: evaluationCycle.id,
        questionType: SurveyQuestionType.ONE_TO_FOUR,
        answerIndex: 3,
        category: QuestionBankQuestionCategory[questionBankQuestionCategoryKey as keyof QuestionBankQuestionCategory],
        value: '1',
        colour: '#FB946E',
        groupTitle: 'Below Average Performer',
        groupDescription: 'Performs below what is expected of them in their current role. Needs further support'
      },
      {
        id: 10,
        cycleId: evaluationCycle.id,
        questionType: SurveyQuestionType.ONE_TO_THREE,
        answerIndex: 0,
        category: QuestionBankQuestionCategory[questionBankQuestionCategoryKey as keyof QuestionBankQuestionCategory],
        value: '3',
        colour: '#008168',
        groupTitle: 'Exceeds Expectations',
        groupDescription: 'Role model. Hard to see how their performance could be improved upon'
      },
      {
        id: 11,
        cycleId: evaluationCycle.id,
        questionType: SurveyQuestionType.ONE_TO_THREE,
        answerIndex: 1,
        category: QuestionBankQuestionCategory[questionBankQuestionCategoryKey as keyof QuestionBankQuestionCategory],
        value: '2',
        colour: '#FFC200',
        groupTitle: 'Meets Expectations',
        groupDescription: 'Could apply themselves more to their current role'
      },
      {
        id: 12,
        cycleId: evaluationCycle.id,
        questionType: SurveyQuestionType.ONE_TO_THREE,
        answerIndex: 2,
        category: QuestionBankQuestionCategory[questionBankQuestionCategoryKey as keyof QuestionBankQuestionCategory],
        value: '1',
        colour: '#FF6F68',
        groupTitle: 'Below Expectations',
        groupDescription: 'Performs below what is expected of them in their current role. Needs further support'
      }
    ]);
  }

  return assessmentGroupInfos;
}));