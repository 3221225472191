import { TagType } from '../model/tag-type.model';

export const TagMessages = {
  TYPE: {
    [TagType.CATEGORY]: 'tag.type.category',
    [TagType.COMPANY_VALUE]: 'tag.type.company-value',
    [TagType.FOUNDATION_STATEMENT]: 'tag.type.foundation-statement'
  },

  TOOLTIP_VIEW: 'tag.tooltip-view',
  TOOLTIP_EDIT: 'tag.tooltip-edit',
  TOOLTIP_COPY: 'tag.tooltip-copy',
  TOOLTIP_DELETE: 'tag.tooltip-delete',

  ADD_A_COMPANY_VALUE: 'tag.add-a-company-value',
  ADD_A_FOUNDATION_STATEMENT: 'tag.add-a-foundation-statement',

  HEADER_CREATING: {
    [TagType.CATEGORY]: 'tag.header-creating.category',
    [TagType.COMPANY_VALUE]: 'tag.header-creating.company-value',
    [TagType.FOUNDATION_STATEMENT]: 'tag.header-creating.foundation-statement'
  },

  HEADER_UPDATING: {
    [TagType.CATEGORY]: 'tag.header-updating.category',
    [TagType.COMPANY_VALUE]: 'tag.header-updating.company-value',
    [TagType.FOUNDATION_STATEMENT]: 'tag.header-updating.foundation-statement'
  }
};
