import { mockRoles } from '@app/domain/role/mock/roles.mock';
import { mockOrganisationalUnits } from '@app/mock/api/data/mockOrganisationalUnits';
import { mockUserRoles } from '@app/mock/api/data/mockUserRoles';
import { mockOfficeLocations } from '@app/mock/api/data/mockOfficeLocations';
import moment from 'moment';
import { UserMinimal } from '@app/models/user/user-minimal.model';

export const mockUsersMin: UserMinimal[] = [
    {
        id: 1,
        managerId: 1,
        organisationalUnitId: mockOrganisationalUnits[0].id,
        officeLocationId: mockOfficeLocations[0].id,
        positionId: mockRoles[0].id,
        email: "trixy@frankli.io",
        firstName: "Trixy",
        lastName: "Paulson",
        imageUrl: "../../assets/img/user-images/female-7.jpeg",
        linkedIn: "",
        twitterHandle: "Trixy",
        phoneNumber: "323123123",
        startDate: moment().subtract(1, 'year').toDate(),
        roles: [mockUserRoles[2]],
    }
];