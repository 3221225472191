import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { GoalsMessages } from '@app/goals/goals.messages';
import { GoalType } from '@app/models/goals/goal-type.enum';
import { Globals } from '@app/shared/globals/globals';
import { GoalUtils } from '@app/shared/utils/goal.utils';
import { CommonGoalCreateFormPart } from '../../goal-create-views/goal-create-main/goal-create-main.component';
import { TerminologyEntity } from '@app/domain/terminology/model/terminology-entity.enum';

interface TypeOption {
  type: GoalType,
  friendlyText: string;
  icon: string;
}

@Component({
  selector: 'app-goal-create-part-type',
  templateUrl: './goal-create-part-type.component.html',
  styleUrls: ['./goal-create-part-type.component.scss']
})
export class GoalCreatePartTypeComponent implements OnChanges, CommonGoalCreateFormPart {

  public readonly eTerminologyEntity = TerminologyEntity;
  public readonly eGoalsMessages = GoalsMessages;

  @Input() typesAvailable: GoalType[];
  @Input() parentDropdownOpen: boolean;
  @Input() controlType: FormControl;
  @Input() submitted: boolean;
  @Input() submittedDraft: boolean;

  @Output() onSetParentDropdown: EventEmitter<boolean>;

  dataParsed: TypeOption[];

  constructor(
    public globals: Globals
  ) {
    this.typesAvailable = [];
    this.dataParsed = [];
    this.parentDropdownOpen = true;
    this.submitted = false;
    this.submittedDraft = false;
    this.controlType = new FormControl(null, []);
    this.onSetParentDropdown = new EventEmitter<boolean>();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const typesAvailable = changes['typesAvailable'];
    if (typesAvailable) {
      this.dataParsed = this.parseTypesAvailableMultiple(typesAvailable.currentValue); // If types are changed after component load, refresh them
    }
  }

  parseTypesAvailableMultiple(types: GoalType[]): TypeOption[] {
    return types.map(t => this.parseTypesAvailableSingle(t));
  }

  parseTypesAvailableSingle(type: GoalType): TypeOption {
    switch (type) {
      case GoalType.COMPANY:
        return {
          type: type,
          friendlyText: GoalUtils.getMessageCodeForGoalType(GoalType.COMPANY, this.globals.terminology),
          icon: GoalUtils.getIconClassForGoalType(GoalType.COMPANY)
        };
      case GoalType.DEPARTMENT:
        return {
          type: type,
          friendlyText: GoalUtils.getMessageCodeForGoalType(GoalType.DEPARTMENT, this.globals.terminology),
          icon: GoalUtils.getIconClassForGoalType(GoalType.DEPARTMENT)
        };
      case GoalType.OFFICE_LOCATION:
        return {
          type: type,
          friendlyText: GoalUtils.getMessageCodeForGoalType(GoalType.OFFICE_LOCATION, this.globals.terminology),
          icon: GoalUtils.getIconClassForGoalType(GoalType.OFFICE_LOCATION)
        };
      case GoalType.PERSONAL_DEVELOPMENTAL:
        return {
          type: type,
          friendlyText: GoalUtils.getMessageCodeForGoalType(GoalType.PERSONAL_DEVELOPMENTAL, this.globals.terminology),
          icon: GoalUtils.getIconClassForGoalType(GoalType.PERSONAL_DEVELOPMENTAL)
        };
      case GoalType.PERSONAL_OPERATIONAL:
        return {
          type: type,
          friendlyText: GoalUtils.getMessageCodeForGoalType(GoalType.PERSONAL_OPERATIONAL, this.globals.terminology),
          icon: GoalUtils.getIconClassForGoalType(GoalType.PERSONAL_OPERATIONAL)
        };
      case GoalType.TEAM:
        return {
          type: type,
          friendlyText: GoalUtils.getMessageCodeForGoalType(GoalType.TEAM, this.globals.terminology),
          icon: GoalUtils.getIconClassForGoalType(GoalType.TEAM)
        };
      default:
        return {
          type: type,
          friendlyText: GoalUtils.getMessageCodeForGoalType(null, this.globals.terminology),
          icon: GoalUtils.getIconClassForGoalType(null)
        };
    }

  }

  changeSelection(type: GoalType): void {
    if (this.controlType.value !== type) {
      this.controlType.setValue(type);
    }

    if (!this.parentDropdownOpen) {
      this.controlType.setValue(null);
      this.setParentDropdown(true);
    }
  }

  setParentDropdown(expanded: boolean): void {
    this.onSetParentDropdown.emit(expanded);
  }

  focus(): void {
    // TODO:
  }
}
