import { OneToOneSchedule } from '@app/domain/one_to_one/model/one-to-one-schedule.model';
import { ScheduleLocationType } from '@app/domain/one_to_one/model/schedule-location-type.model';
import { mockUsers } from '@app/mock/api/data/mockUsers';
import { OneToOneStatus } from '@app/domain/one_to_one/model/one-to-one-status.model';
import { OneToOneMeetingLength } from '@app/domain/one_to_one/model/one-to-one-meeting-length.model';
import { mockOneToOneMeetings } from '@app/domain/one_to_one/mock/one-to-one-meetings.mock';
import { mockEvaluationCycles } from '@app/mock/api/data/mockEvaluationCycles';
import { mockEmployeeLedTemplateTalkingPoints, mockCoachingTemplateTalkingPoints, mockMentoringTemplateTalkingPoints, mockPerformanceReviewTemplateTalkingPoints } from './one-to-one-recommended-talking-points.mock';
import { OneToOneFrequency } from '../model/one-to-one-frequency.model';
import { OneToOneScheduleType } from '../model/one-to-one-schedule-type.model';

export const mockOneToOneSchedules: OneToOneSchedule[] = [
  {
    id: 1,
    purposeTitle: 'Regular 1:1',
    locationType: ScheduleLocationType.SLACK,
    location: 'I will call you on Slack at this time',
    timezone: 'Europe/Dublin',
    frequency: OneToOneFrequency.Weekly,
    talkingPointsRecurring: mockEmployeeLedTemplateTalkingPoints.map(tp => ({
      id: tp.id,
      actioned: true,
      recurring: true,
      title: tp.text,
      hasRelatedGoals: false,
      fromPrevious: false,
      sourceUserId: mockUsers[0].id,
      comment: '',
      managerComment: '',
      comments: []
    })),
    participants: [mockUsers[1]],
    user: mockUsers[2],
    manager: mockUsers[0],
    status: OneToOneStatus.ACTIVE,
    activity: [],
    meetingList: [
      mockOneToOneMeetings[0],
      mockOneToOneMeetings[1]
    ],
    meetingLength: OneToOneMeetingLength.THIRTY_MINUTES,
    startDateTime: mockOneToOneMeetings[1].meetingTimestamp, 
    recurringRule: '',
    type: OneToOneScheduleType.ONE_TO_ONE,
    sentimentScaleId: null
  },
  {
    id: 2,
    purposeTitle: 'Coaching Session',
    locationType: ScheduleLocationType.SLACK,
    location: 'I will call you on Slack at this time',
    timezone: 'Europe/Dublin',
    frequency: OneToOneFrequency.Individual,
    talkingPointsRecurring: mockCoachingTemplateTalkingPoints.map(tp => ({
      id: tp.id,
      actioned: true,
      recurring: true,
      title: tp.text,
      hasRelatedGoals: false,
      fromPrevious: false,
      sourceUserId: mockUsers[0].id,
      comment: '',
      managerComment: '',
      comments: []
    })),
    participants: [mockUsers[1]],
    user: mockUsers[2],
    manager: mockUsers[0],
    status: OneToOneStatus.ACTIVE,
    activity: [],
    meetingList: [
      mockOneToOneMeetings[2]
    ],
    meetingLength: OneToOneMeetingLength.SIXTY_MINUTES,
    startDateTime: mockOneToOneMeetings[2].meetingTimestamp, 
    recurringRule: '',
    type: OneToOneScheduleType.ONE_TO_ONE,
    sentimentScaleId: null
  },
  {
    id: 3,
    purposeTitle: 'Mentoring Session',
    locationType: ScheduleLocationType.SLACK,
    location: 'I will call you on Slack at this time',
    timezone: 'Europe/Dublin',
    frequency: OneToOneFrequency.Individual,
    talkingPointsRecurring: mockMentoringTemplateTalkingPoints.map(tp => ({
      id: tp.id,
      actioned: true,
      recurring: true,
      title: tp.text,
      hasRelatedGoals: false,
      fromPrevious: false,
      sourceUserId: mockUsers[0].id,
      comment: '',
      managerComment: '',
      comments: []
    })),
    participants: [mockUsers[1]],
    manager: mockUsers[0],
    user: mockUsers[1],
    status: OneToOneStatus.ACTIVE,
    activity: [],
    meetingList: [
      mockOneToOneMeetings[3]
    ],
    meetingLength: OneToOneMeetingLength.SIXTY_MINUTES,
    startDateTime: mockOneToOneMeetings[3].meetingTimestamp, 
    recurringRule: '',
    type: OneToOneScheduleType.ONE_TO_ONE,
    sentimentScaleId: null
  },
  {
    id: 4,
    purposeTitle: 'Discuss Outcome of Mid Year Review 2021',
    locationType: ScheduleLocationType.SLACK,
    location: 'I will call you on Slack at this time',
    timezone: 'Europe/Dublin',
    frequency: OneToOneFrequency.Individual,
    talkingPointsRecurring: mockPerformanceReviewTemplateTalkingPoints.map(tp => ({
      id: tp.id,
      actioned: true,
      recurring: true,
      title: tp.text,
      hasRelatedGoals: false,
      fromPrevious: false,
      sourceUserId: mockUsers[0].id,
      comment: '',
      managerComment: '',
      comments: []
    })),
    participants: [mockUsers[2]],
    user: mockUsers[3],
    manager: mockUsers[0],
    status: OneToOneStatus.ACTIVE,
    activity: [],
    meetingList: [
      mockOneToOneMeetings[4]
    ],
    meetingLength: OneToOneMeetingLength.THIRTY_MINUTES,
    startDateTime: mockOneToOneMeetings[4].meetingTimestamp,
    evaluationCycleId: mockEvaluationCycles[0].id, 
    recurringRule: '',
    type: OneToOneScheduleType.ONE_TO_ONE,
    sentimentScaleId: null
  }
];