import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

const NEW_LINE_CHAR = '\n';
const SPACE_CHAR = ' ';

@Pipe({
  name: 'linkify'
})
export class LinkifyPipe implements PipeTransform {

  constructor(private _domSanitizer: DomSanitizer) {}

  transform(value: any, args?: any): any {
    return this.stylize(value);
  }

  private stylize(text: string): string {
    let linksFound = 0;
    let stylizedText = '';

    if (text && text.length > 0) {
      for (const line of text.split(NEW_LINE_CHAR)) {
        for (const word of line.split(SPACE_CHAR)) {
          if (word.toLowerCase().startsWith('http') && word.length > 7) {
            stylizedText += `<a target="_blank" rel=”noopener noreferrer” href="${word}">${word}</a> `;
            linksFound += 1;
          } else {
            stylizedText += `${word} `;
          }
        }

        if (linksFound > 0) {
          stylizedText += '<br>';
        }
      }
    } else {
      return text;
    }

    return stylizedText;
  }
}
