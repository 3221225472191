<router-outlet></router-outlet>
<ng-container *ngIf="route.children.length === 0">
  <div class="container-team-tasks">
    <app-state [state]="state">
      <ng-container *ngIf="!state.loading && !state.error">
        <div class="text-right">
          <app-table-action-icon [icon]="'fa-file-csv'" (click)="exportTasks()" [tooltipText]="'Export all user statistics to CSV'" [loading]="state.creatingCSV"></app-table-action-icon>
        </div>

        <app-state [state]="stateStats">
          <ng-container *ngIf="!stateStats.loading && !stateStats.error">
            <app-task-stats [stats]="taskStats"></app-task-stats>
          </ng-container>
        </app-state>

        <div class="card">
          <div class="content">
            <div class="area-filters">
              <div class="col-universal-filter">
                <app-dynamic-filter [filterCategories]="filterCategories" [searchProperties]="searchProperties" [placeholderText]="'Search for a person by name'" (filtersChanged)="updateFilter($event)"></app-dynamic-filter>
              </div>
              <div class="col-column-toggle">
                <app-column-toggle-new [columnInfos]="columnInfos" [alwaysEnabled]="[eTableColumn.NAME]" #columnToggle></app-column-toggle-new>
              </div>
            </div>

            <ng-container *ngIf="globals.hasMinimumAccessLevelOf(eRoleName.ADMIN)">
              <div class="toggle-view-as-admin">
                <app-slider-input [formControl]="viewAsAdmin"></app-slider-input>
                <div translate>{{eTaskMessages.VIEW_AS_ADMIN}}</div>
              </div>
            </ng-container>

            <app-state [state]="stateSearch">
              <ng-container *ngIf="!stateSearch.loading && !stateSearch.error">
                <ng-container *ngIf="directReports.length > 0; else noSearchResults">
                  <!-- Table -->
                  <div class="row">
                    <div class="scroll-x">
                      <table width="100%">
                        <tr class="frankli-table frankli-table-small frankli-table-header noselect header-sticky">
                          <th></th> <!-- Spacing for dropdown -->
                          <th *ngIf="columnToggle.getColumnEnabled(eTableColumn.NAME)" translate>
                            {{eCommonMessages.NAME}}
                          </th>
                          <th class="text-center" *ngIf="columnToggle.getColumnEnabled(eTableColumn.TASK_COUNT)" translate>
                            {{eTaskMessages.TASK_COUNT}}
                          </th>
                          <th class="text-center" *ngIf="columnToggle.getColumnEnabled(eTableColumn.TASK_COUNT_COMPLETE)" translate>
                            {{eTaskMessages.TASK_COUNT_COMPLETE}}
                          </th>
                          <th class="text-center" *ngIf="columnToggle.getColumnEnabled(eTableColumn.TASK_COUNT_IN_PROGRESS)" translate>
                            {{eTaskMessages.TASK_COUNT_IN_PROGRESS}}
                          </th>
                          <th class="text-center" *ngIf="columnToggle.getColumnEnabled(eTableColumn.TASK_COUNT_NOT_STARTED)" translate>
                            {{eTaskMessages.TASK_COUNT_NOT_STARTED}}
                          </th>
                          <th class="text-center" *ngIf="columnToggle.getColumnEnabled(eTableColumn.TASK_COUNT_OVERDUE)" translate>
                            {{eTaskMessages.TASK_COUNT_OVERDUE}}
                          </th>
                          <th class="text-left" *ngIf="columnToggle.getColumnEnabled(eTableColumn.AVERAGE_COMPLETION)" translate>
                            {{eTaskMessages.AVERAGE_COMPLETION}}
                          </th>
                          <th class="text-center" *ngIf="columnToggle.getColumnEnabled(eTableColumn.ACTIONS)" translate>
                            {{eCommonMessages.ACTIONS}}
                          </th>
                        </tr>
        
                        <ng-container *ngFor="let report of directReports">
                          <app-team-tasks-row
                            [report]="report" [enabledColumnKeys]="columnToggle.getEnabledColumnKeys()"
                            (onOpenUserTasks)="openUserTasks($event)" (onLoadChildren)="loadChildren($event)"
                          ></app-team-tasks-row>
                        </ng-container>
                      </table>
                    </div>
                  </div>

                  <app-pagination-server *ngIf="!columnToggle.checkAllDisabled() && stateSearch.loading === false && tasksOverviewPage" [page]="tasksOverviewPage" [pageSize]="pagingParams.pageSize" (pageSizeChanged)="changePageSize($event)" (pageNumberChanged)="changePageNumber($event)"></app-pagination-server>
                </ng-container>
                <ng-template #noSearchResults>
                  <app-empty-state [height]="'300px'"></app-empty-state>
                </ng-template>
              </ng-container>
            </app-state>
          </div>
        </div>

        <ng-container *ngIf="viewingUser">
          <app-modal [sidePanel]="true" (modalHidden)="onTasksModalHidden($event)" #userTasksModal>
            <ng-container [ngSwitch]="modalType">
              <ng-container *ngSwitchCase="eTeamTasksModalType.TASKS_LIST">
                <div class="p-bottom-15">
                  <h4 class="standard-heading">Tasks owned by {{viewingUser.firstName}} {{viewingUser.lastName}}</h4>
                  <div class="standard-description">Below is a list of all the tasks where {{viewingUser.firstName}} has been set as an owner.</div>
                </div>
                <app-filtered-task-list [owner]="viewingUser" (actionClicked)="onTaskListActionClicked($event)"></app-filtered-task-list>
              </ng-container>
              <ng-container *ngSwitchCase="eTeamTasksModalType.TASK_DETAILS">
                <div class="p-top-5 p-bottom-15">
                  <a class="clickable" (click)="stopViewingTask()">
                    <span class="fal fa-fw fa-arrow-left"></span>
                    <span class="m-right-5"></span>
                    <span translate>{{eCommonMessages.GO_BACK}}</span>
                  </a>
                </div>
                <app-task-details-component [task]="viewingTask" [showHeader]="false" (requestCloseModal)="onTasksModalHidden($event)"></app-task-details-component>
              </ng-container>
            </ng-container>
          </app-modal>
        </ng-container>
      </ng-container>
    </app-state>
  </div>
</ng-container>