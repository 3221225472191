// TODO:
// - This would be way more efficient if we had an API call to get an array of goals (Pass an array of IDs to the server and get an array back)
// - Find a way to provide HTML files for the rich UI displays
// - Make the spellchecker ignore the rich UI contents (goal name, date, etc)
// - Change the auto-parsing function to use tinymce.dom instead of modifying the content as a string so that we can preserve the cursor position

import { GoalKeyResult } from '@app/models/goals/goal-key-result.model';
import { GoalServerside } from '@app/models/goals/goal-serverside.model';
import { GoalStatus } from '@app/models/goals/goal-status.model';
import { Goal } from '@app/models/goals/goal.model';
import { FeatureLinking } from '@app/shared/components/rich-link-modal/rich-link-modal.component';
import moment from 'moment';
import tinymce, { Editor, EditorEvent, Plugin, XHRSettings } from 'tinymce';

// #region - REGEX CONSTS
export const METADATA_REGEX = new RegExp(/{{2}[A-Z]+\|[0-9]+}{2}/g);
export const BRACKET_REGEX = new RegExp(/{{2}|}{2}/g);
export const RICH_LINK_REGEX = new RegExp(/<\w*>{{2}[A-Z]*\|[0-9]*}{2}<\/\w*>/g);
export const RICH_LINK_REGEX_NO_TAGS = new RegExp(/{{2}[A-Z]*\|[0-9]*}{2}/g);
// #endregion

// #region - INTERFACES, TYPES, CONSTANTS
export interface RichLinkMessage {
  feature: FeatureLinking;
  id: number;
  data: any;
}

export interface MceMessage {
  mceAction: string;
  content?: RichLinkMessage;
}

type EditorEventFn<T> = (event: EditorEvent<T>) => void;
type EditorXHRSuccessCallback = ((response: any) => void) | undefined;
type EditorXHRErrorCallback = (message: 'TIMED_OUT' | 'GENERAL', xhr: XMLHttpRequest, settings: XHRSettings) => void

// Request methods - Add as needed
enum EditorXHRMethod {
  GET = 'GET',
  POST = 'POST'
}

const STYLESHEET_PATH = './assets/css/richLink.css';
const DATE_FORMAT = 'D MMM YYYY';
const EVENTS_TO_PARSE = [ 'KeyUp', 'LoadContent', 'SetContent' ];
const DATA_TEMP_STRING = '{{LINK_DATA}}';
// #endregion

// #region - UTILITY FUNCTIONS
const sendApiRequestWithAuth = (url: string, method: EditorXHRMethod, success?: EditorXHRSuccessCallback, error?: EditorXHRErrorCallback): void => {
  const token = `Bearer ${tinymce.util.LocalStorage.getItem('access_token')}`;
  const request: XHRSettings = {
    url: url,
    type: method,
    requestheaders: {
      '0': { key: 'Accept', value: 'application/json, text/plain, */*'},
      '1': { key: 'Authorization', value: token}
    }
  }

  if (success) {
    request['success'] = (res: string) => {
      const json = JSON.parse(res);
      success(json);
    };
  }

  if (error) {
    request['error'] = error;
  }

  tinymce.util.XHR.send(request);
}

const getEndpointForFeature = (feature: FeatureLinking, id: number): string => {
  switch (feature as FeatureLinking) {
    case FeatureLinking.GOALS:
      return `api/goal/${id}`;
  }
}

const parseStringToHtmlElement = (input: string): Element => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(input, 'text/html');
  return doc.body.children[0];
}
// #endregion

// #region - UI GENERATION
// Gets the classes to be set on the status div of the display for a goal
const getGoalStatusClass = (goal: Goal): string => {
  if (goal.archived) {
    return 'status gray';
  }

  if (goal.complete) {
    return 'status blue';
  }

  switch (goal.status) {
    case GoalStatus.OFF_TRACK:
      return 'status red';
    case GoalStatus.PROGRESSING:
      return 'status yellow';
    case GoalStatus.ON_TRACK:
      return 'status green';
  }

  return '';
}

const getRichLinkWrapper = (feature: FeatureLinking, id: number) => {
  const rlMeta = `${feature}|${id}`;
  const output =
    `<span class="rich-link" contenteditable="false" draggable="true">
        <span class="rl-meta">${rlMeta}</span>
        <span class="rl-data">{{LINK_DATA}}</span>
    </span>`;

  return output;
}

// Gets the UI for a goal
export const getGoalRichUI = (goal: Goal, clickable: boolean = false): Element => {
  // TODO: Make this a template somehow
  let rlData = `
    <span class="${getGoalStatusClass(goal)}">&nbsp;</span>
    <span class="title">${goal.title}</span>
    <span class="pill yellow">${moment(goal.endDate).format(DATE_FORMAT)}</span>
    <span class="progress-bar-container">
      <span class="progress-bar-line progress-${goal.completionPercentage}">&nbsp;</span>
      <span class="progress-bar-text progress-text-${goal.completionPercentage}">${goal.completionPercentage}%</span>
    </span>
  `;

  if (clickable) {
    rlData = `<a href="/goals/individual/${goal.id}">${rlData}</a>`
  }

  let output = getRichLinkWrapper(FeatureLinking.GOALS, goal.id);
  output = output.replace(DATA_TEMP_STRING, rlData);
  return parseStringToHtmlElement(output);
}

const getLoadingInsert = (type: FeatureLinking, id: number): Element => {
  const rlData =
    `<span class="rich-link-loading">
      <span class="icon loading">&nbsp;</span>
      <span>Loading data</span>
    </span>`;

  let output = getRichLinkWrapper(type, id);
  output = output.replace(DATA_TEMP_STRING, rlData);

  return parseStringToHtmlElement(output);
}

const getErrorInsert = (type: FeatureLinking, id: number): Element => {
  let errorMessage = `Failed to get item`;

  switch (type) {
    case FeatureLinking.GOALS:
      errorMessage = 'Failed to get goal information';
  }

  // TODO: Maybe we can attach a click event to this and refresh on click?
  const rlData =
    `<span class="rich-link-error">
      <span class="icon error">&nbsp;</span>
      <span>${errorMessage}</span>
    </span>`;

  let output = getRichLinkWrapper(type, id);
  output = output.replace(DATA_TEMP_STRING, rlData);

  return parseStringToHtmlElement(output);
}
// #endregion

// #region - RICH UI ADDING
// This might be the biggest brain thing i've ever done.
const getErrorCallback = (feature: FeatureLinking, id: number, url: string, method: EditorXHRMethod, success: EditorXHRSuccessCallback, loadingElem: Element): EditorXHRErrorCallback => {
  return (message, error) => {
    const errorElem = getErrorInsert(feature, id);
    errorElem.addEventListener('click', () => {
      tinymce.DOM.replace(loadingElem, errorElem, false);
      sendApiRequestWithAuth(url, method, success, getErrorCallback(feature, id, url, method, success, loadingElem));
    });
    tinymce.DOM.replace(errorElem, loadingElem, false);
  }
}

// Convert <span class="rich-link"> ... </span> to {{GOAL|21}}
const convertFormatToSpan = (element: Element) => {
  const [feature, id] = element.innerHTML.replace(BRACKET_REGEX, '').split('|') as [FeatureLinking, number];
  const loadingElem = getLoadingInsert(feature, id);

  // Replace w/ Loading element
  tinymce.DOM.replace(loadingElem, element, false);

  switch (feature) {
    case FeatureLinking.GOALS:
      const url: string = getEndpointForFeature(feature, id);
      const method: EditorXHRMethod =  EditorXHRMethod.GET;
      const success: EditorXHRSuccessCallback = (res: GoalServerside) => {
        let goal = new Goal(res);
        goal = Goal.getGoalCompletionPercentage(goal); // TODO: Move this JSON parser into the API call functon somehow
        const richUI = getGoalRichUI(goal);
        tinymce.DOM.replace(richUI, loadingElem, false);
      };
      const error: EditorXHRErrorCallback = getErrorCallback(feature, id, url, method, success, loadingElem);
      sendApiRequestWithAuth(url, method, success, error);
      break;
  }
}

const recursiveAddLinks = (element: Element): void => {
  if (element.children.length === 0) {
    if (element.textContent) {
      let matches = element.textContent.match(RICH_LINK_REGEX);

      if (!matches) {
        matches = element.textContent.match(RICH_LINK_REGEX_NO_TAGS);
      }

      if (matches && (matches.length > 0)) {
        convertFormatToSpan(element);
      }
    }
    return;
  }

  for (let index = 0; index < element.children.length; index++) {
    recursiveAddLinks(element.children[index]);
  }
}
// #endregion

// #region - RICH UI REMOVING
// Convert <span class="rich-link"> ... </span> to {{GOAL|21}}
const convertSpanToFormat = (element: Element): string => {
  const metaData = element.children[0];
  const [feature, id] = metaData.innerHTML.split('|');
  return `<strong>{{${feature}|${id}}}</strong>`;
}

// Detect and replace any rich links with their shorthand versions
const recursiveRemoveLinks = (element: Element): Element => {
  if (element.children.length === 0) {
    return element;
  }

  for (let index = 0; index < element.children.length; index++) {
    if (element.children[index].classList.contains('rich-link')) {
      element.children[index].outerHTML = convertSpanToFormat(element.children[index]);
    } else {
      element.children[index].outerHTML = recursiveRemoveLinks(element.children[index]).outerHTML;
    }
  }

  return element;
}

export const richLinkRemoveLinks = (content: string): string => {
  if (content.length === 0) {
    return content;
  }

  const parser = new DOMParser();
  const contentHTML = parser.parseFromString(content, 'text/html');
  if (contentHTML.children.length > 0) {
    const htmlTag = contentHTML.children[0];
    if (htmlTag.children.length > 0) {
      let bodyTag = htmlTag.children[1];
      bodyTag = recursiveRemoveLinks(bodyTag);
      return bodyTag.innerHTML;
    }
  }

  return content;
}
// #endregion

// #region - MODAL CALLBACK
// Run when a message is received from a modal (Eg. a goal was selected to link)
const parseMessageData = (message: RichLinkMessage, editor: Editor) => {
  switch (message.feature) {
    case FeatureLinking.GOALS:
      const goals: Goal[] = message!.data as Goal[];
      const insertString = goals.map(g => getGoalRichUI(g).outerHTML).join(' ');
      editor.insertContent(insertString);
      break;
  }
}
// #endregion

// #region - CHANGE DETECTION
const getContentElementRefFromEvent = (eventType: string, event: EditorEvent<any>): (Element | null) => {
  switch (eventType) {
    case 'LoadContent':
    case 'SetContent':
      return event.target.iframeElement.contentDocument.body;
    case 'KeyUp':
      return event.target;
  }

  return null;
}

const onEventFired = (eventType: string, editor: Editor): EditorEventFn<any> => {
  return (event: EditorEvent<any>) => {
    const contentRef = getContentElementRefFromEvent(eventType, event); // TODO: Replace elements using this instead
    if (!contentRef) {
      return;
    }

    recursiveAddLinks(contentRef);
  }
}
// #endregion

// #region - PLUGIN SETUP
const pluginMetadata: Plugin = {
  getMetadata: () => {
    return {
      name: 'Frankli Rich Link',
      url: 'none'
    }
  }
}

const addPluginCSS = (editor: Editor, domain: string) => {
  if (!editor.contentCSS.includes(STYLESHEET_PATH)) {
    editor.contentCSS.push(`${domain}/assets/css/richLink.css`);
  }
}

const getDomain = (url: string): string => {
  const urlSplit = url.split('/');
  return `${urlSplit[0]}//${urlSplit[2]}`;
}

const registerEventListeners = (editor: Editor): void => {
  EVENTS_TO_PARSE.forEach(event => {
    editor.on(event, onEventFired(event, editor));
  })
}
// #endregion

export const RICH_LINK_PLUGIN = function (editor: Editor, url: string): Plugin { // NOTE: This breaks if it's an arrow function
  let richLinking = false;
  const domain = getDomain(url);

  addPluginCSS(editor, domain);
  registerEventListeners(editor);

  // #region - CORE FUNCTIONS
  const endRichLink = () => {
    richLinking = false;
  }

  const onSelectLink = (windowManager: any, data: MceMessage) => {
    if (data && data.mceAction && data.content) {
      switch (data.mceAction) {
        case 'insertRichLink':
          parseMessageData(data.content!, editor);
        case 'closeRichLink':
        default:
          windowManager.close();
          richLinking = false;
      }
    }

    windowManager.close();
    endRichLink();
  }

  const onStartLink = () => {
    if (richLinking) {
      return;
    }

    richLinking = true;

    editor.windowManager.openUrl({
      // title: 'Goal Linking', // <-- Removed for translations
      title: '',
      url: `${domain}/rich-link`,
      onMessage: onSelectLink,
      onCancel: endRichLink
    });
  }
  // #endregion

  // #region - UI COMPONENTS
  editor.ui.registry.addButton('btnFrankliLink', {
    text: undefined,
    icon: 'bullseye',
    tooltip: 'Link to a goal in Frankli',
    disabled: false,
    onAction: onStartLink,
  });
  // #endregion

  return pluginMetadata;
}
