import { ISODateString } from "@app/shared/utils/date-string.util";

export interface ZendeskQATokenInterface {
  id: number;
  companyId: string;
  workspaceId: string;
  accessToken: string;
  connected: boolean;
  created: ISODateString;
  error: string | null;
  syncing: boolean;
}

export class ZendeskQAToken implements ZendeskQATokenInterface {
  id: number;
  companyId: string;
  workspaceId: string;
  accessToken: string;
  connected: boolean;
  created: ISODateString;
  error: string | null;
  syncing: boolean;

  constructor(zendeskQAToken: ZendeskQAToken) {
    this.id = zendeskQAToken.id;
    this.companyId = zendeskQAToken.companyId;
    this.workspaceId = zendeskQAToken.workspaceId;
    this.accessToken = zendeskQAToken.accessToken;
    this.connected = zendeskQAToken.connected;
    this.created = zendeskQAToken.created;
    this.error = zendeskQAToken.error;
    this.syncing = zendeskQAToken.syncing;
  }
}