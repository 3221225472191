<div class="goal-create-tasks-container" [class.parent-dropdown-closed]="!parentDropdownOpen" [class.has-calendar-open]="calendarOpen">
  <ng-container *ngIf="parentDropdownOpen; else templateDisplayValues">
    <ng-container *ngIf="(taskList.length > 0); else noTasksTemplate">
      <div class="tasks-list-container">
        <ng-container *ngFor="let taskGroup of taskList; index as index; first as isFirst; last as isLast">
          <ng-container *ngIf="isFirst" [ngTemplateOutlet]="addRowTemplate" [ngTemplateOutletContext]="{index: index, visibleByDefault: false}"></ng-container>
      
          <div class="task-item">
      
            <app-goal-create-task-item [taskForm]="taskGroup" [minDueDate]="minDueDate" [maxDueDate]="maxDueDate" (calendarOrSearchToggle)="checkOverflowProtection($event, index, isLast)" [submitted]="submitted" [submittedDraft]="submittedDraft"></app-goal-create-task-item>
      
            <div class="remove-task-icon" tooltip="Remove task" placement="left" (click)="onRemoveTask(index)">
              <span class="fal fa-fw fa-times"></span>
            </div>
          </div>
      
          <ng-container [ngTemplateOutlet]="addRowTemplate" [ngTemplateOutletContext]="{index: (index + 1), visibleByDefault: isLast}"></ng-container>
        </ng-container>
      </div>
    </ng-container>
    <ng-template #noTasksTemplate>
      <app-empty-state [height]="'200px'" [message]="eGoalsMessages.GOAL_CREATE_TASKS_EMPTY_STATE" [imageSrc]="'assets/img/NoSurveys.svg'" [secondaryButtonShown]="true" [secondaryButtonText]="eGoalsMessages.GOAL_CREATE_TASKS_ADD_TASK" (secondaryButtonPressed)="onAddRowClick(0)"></app-empty-state>
    </ng-template>    
  </ng-container>

  <ng-template #templateDisplayValues>
    <ng-container *ngIf="taskList.length > 0">
      <div class="p-15">
        <div class="display-values" (click)="setParentDropdown(true)" [class.display-invalid]="((submitted || submittedDraft) && controlKeyResults.invalid)">
          <div class="display-values-text">
            <ng-container *ngIf="(taskList.length === 1); else templateDisplayMultipleKRs">
              <span translate>{{eGoalsMessages.GOAL_CREATE_TASKS_COUNT_SINGLE}}</span>
            </ng-container>
            <ng-template #templateDisplayMultipleKRs>
              <span translate [translateParams]="{count: taskList.length}">{{eGoalsMessages.GOAL_CREATE_TASKS_COUNT_MULTIPLE}}</span>
            </ng-template>
          </div>
          <span class="fal fa-fw fa-check"></span>
        </div>
      </div>
    </ng-container>
  </ng-template>
</div>

<ng-template let-index="index" let-visibleByDefault="visibleByDefault" #addRowTemplate>
  <div class="add-row" [class.start-visible]="visibleByDefault" (click)="onAddRowClick(index)">
    <span class="fal fa-fw fa-plus m-right-5"></span>
    <span translate>{{eGoalsMessages.GOAL_CREATE_TASKS_ADD_TASK}}</span>
  </div>
</ng-template>

