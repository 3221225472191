import { Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';
import { ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot } from '@angular/router';
import { Globals } from '../globals/globals';
import { environment } from '../../../environments/environment';
import { SwalUtils } from '../utils/swal.utils';
import { SidebarService } from '../sidebar/sidebar.service';
import { TranslateService } from '@ngx-translate/core';
import { CommonMessages } from '@app/constants/common.messages';

@Injectable()
export class CanDeactivateGuard implements CanDeactivate<any> {

  constructor(
    public globals: Globals,
    private sidebarService: SidebarService,
    private translateService: TranslateService,
    private swalUtils: SwalUtils
  ) {}

  canDeactivate(
    component: any,
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | boolean {
    this.sidebarService.onRouteGuardStart();

    const unsavedChanges = component.unsavedChanges();

    if (!this.globals.user || environment.mock.enabled) {
      return true;
    }

    if (unsavedChanges === true) {
      return from(this.swalUtils.displayWarningConfirmationSwal({
        title: CommonMessages.UNSAVED_CHANGES,
        text: CommonMessages.UNSAVED_CHANGES_WARNING,
        confirmButtonText: CommonMessages.STAY,
        cancelButtonText: CommonMessages.LEAVE,
        width: 390
      }).then(val => {
        if (val.dismiss) {
          if (val.dismiss.toString() === 'cancel') {
            setTimeout(async () => {
              this.sidebarService.onRouteGuardEnd();
              this.sidebarService.closeSidebar();
            }, 250);
            return true;
          }
        }
        return false;
      }));
    }

    return true;
  }
}
