<ng-container *ngIf="meetingData && meetingData.meeting">
  <div class="container-sidebar-sentiment-scale-timeline-meeting-item" [class.new-scale-started]="meetingData.usesNewScale">
    <div class="area-meeting-metadata">
      <div>{{meetingData.meeting.meetingTimestamp | momentPipe: 'D MMM YYYY'}}</div>
    </div>
    <div class="area-meeting-answers" [class.area-meeting-answers-latest]="isLast">
      <ng-container *ngFor="let userId of uniqueUserIds">
        <app-information-sidebar-sentiment-scale-timeline-answer-item [userId]="userId" [answerData]="meetingData.participantAnswers[userId]" [sentimentScale]="meetingData.sentimentScale" [isLastMeeting]="isLast"></app-information-sidebar-sentiment-scale-timeline-answer-item>
      </ng-container>
    </div>
  </div>
</ng-container>
