<div class="row center-screen">
  <div class="col-md-12 text-center">
    <img class="bigger-icon" src="/assets/img/Failure.svg" />
    <h3 class="text-center display-3">
      This feature is not available to a restricted account, please purchase a Frankli subscription if you would like it enabled.
    </h3>
  </div>

  <div class="col-md-12 text-center m-top-25">
    <a [routerLink]="'/dashboard'">
      <button class="btn-frankli-round btn-frankli-green">Back to Dashboard</button>
    </a>
  </div>
</div>