import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { Globals } from '../globals/globals';
import { SwalUtils } from '../utils/swal.utils';

@Injectable({
  providedIn: 'root'
})
export class MobileFeatureGuard implements CanActivate {

  constructor(
    public globals: Globals,
    private router: Router,
    private swalUtils: SwalUtils
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    // Return straight to dashboard
    // if (this.globals.onMobile) {
    //   this.router.navigate(['dashboard']);
    //   return false;
    // }

    // Display a warning modal
    if (this.globals.onMobile) {
      this.swalUtils.displayWarningConfirmationSwal({
        text: 'This feature has not been optimised for mobile yet. You may see some visual issues and some workflows may not work as expected',
        confirmButtonText: 'Proceed to feature',
        cancelButtonText: 'Return to dashboard'
      })
        .then(
          (result) => {
            this.globals.modalOpen = false;

            if (result.isDismissed) {
              this.router.navigate(['dashboard']);
              return false;
            } else {
              return true;
            }
          }
        );
    }

    return true;
  }
}
