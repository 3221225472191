<div class="container-peer-review-progress-bar">
  <div class="step-background-bar"></div>

  <div (click)="onClickBubble('sent')" class="step-item col-sent" [ngClass]="stepSent.class" [tooltip]="stepSent.tooltipText" placement="bottom" container="body" [triggers]="globals.tooltipTriggers" *ngIf="stepSent">
    <span class="fa-fw" [ngClass]="stepSent.iconClass"></span>
  </div>

  <div (click)="onClickBubble('manager')" class="step-item col-manager" [ngClass]="stepManager.class" [tooltip]="stepManager.tooltipText ? tooltipManager : ''" placement="bottom" container="body" [triggers]="globals.tooltipTriggers" [class.can-click]="canClickManager"  *ngIf="stepManager">
    <span class="fa-fw" [ngClass]="stepManager.iconClass"></span>
  </div>
  <ng-template #tooltipManager>
    <div>{{stepManager.tooltipText}}</div>
    <strong *ngIf="canClickManager">(Click here for more information)</strong>
  </ng-template>

  <div (click)="onClickBubble('peer')" class="step-item col-peer" [ngClass]="stepPeer.class" [tooltip]="stepPeer.tooltipText ? tooltipPeer : ''" placement="bottom" container="body" [triggers]="globals.tooltipTriggers" [class.can-click]="canClickPeer"  *ngIf="stepPeer">
    <span class="fa-fw" [ngClass]="stepPeer.iconClass"></span>
  </div>
  <ng-template #tooltipPeer>
    <div>{{stepPeer.tooltipText}}</div>
    <strong *ngIf="canClickPeer">(Click here for more information)</strong>
  </ng-template>

  <div (click)="onClickBubble('edit')" class="step-item col-edit" [ngClass]="stepEdit.class" [tooltip]="stepEdit.tooltipText" placement="bottom" container="body" [triggers]="globals.tooltipTriggers" *ngIf="stepEdit">
    <span class="fa-fw" [ngClass]="stepEdit.iconClass"></span>
  </div>

  <div (click)="onClickBubble('submit')" class="step-item col-submit" [ngClass]="stepSubmit.class" [tooltip]="stepSubmit.tooltipText" placement="bottom" container="body" [triggers]="globals.tooltipTriggers" *ngIf="stepSubmit">
    <span class="fa-fw" [ngClass]="stepSubmit.iconClass"></span>
  </div>
</div>

<ng-container *ngIf="request">
  <app-modal [minWidth]="'200px'" [width]="'20%'" #declineReasonModal>
    <div class="p-bottom-15">
      <h1 class="standard-heading">Request declined</h1>
      <ng-container [ngSwitch]="request.state">
        <ng-container *ngSwitchCase="eEvaluationFeedbackRequestState.DECLINED_MANAGER">
          <div class="standard-description">This request was declined by the senders manager</div>
        </ng-container>
        <ng-container *ngSwitchCase="eEvaluationFeedbackRequestState.DECLINED_PEER">
          <div class="standard-description">This request was declined by the recipient</div>
        </ng-container>
      </ng-container>
    </div>
  
    <div class="frankli-form-group frankli-form-group-filled">
      <label>Reason given:</label>
      <div class="p-left-15">
        <app-rich-display [text]="request.requestDeclinedReason ? request.requestDeclinedReason : 'No reason given'"></app-rich-display>
      </div>
    </div>
  </app-modal>
</ng-container>
