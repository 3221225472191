import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit } from '@angular/core';
import { UserStickiness } from '@app/models/session-log/user-stickiness.model';
import moment from 'moment';

@Component({
  selector: 'app-session-log-user-stickiness-overview-monthly-component',
  templateUrl: './session-log-user-stickiness-overview-monthly.component.html',
  styleUrls: ['./session-log-user-stickiness-overview-monthly.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush // For performance reasons
})
export class SessionLogUserStickinessOverviewMonthlyComponent implements OnChanges, OnInit {
  @Input() showHeader: boolean;
  @Input() data: Array<UserStickiness> = new Array<UserStickiness>();
  public dataDisplay: Array<UserStickiness> = new Array<UserStickiness>();

  constructor() {
    this.showHeader = true;
  }

  ngOnChanges(): void {
    this.dataDisplay = this.parseData(this.data);
  }

  ngOnInit(): void { }

  parseData(data: UserStickiness[]): UserStickiness[] {
    data = data.filter((d) => d.date.getDate() === 1);
    data = data.map((d) => {
      const dMoment = moment(d.date);
      d.date = dMoment.subtract(1, 'day').toDate();
      return d;
    });

    return data;
  }

  public calculateMonthlyActiveVsTotal(userStickiness: UserStickiness): string {
    if (userStickiness.totalUsers === 0) {
      return 'N/A';
    }
    return ((userStickiness.monthlyActiveUsers / userStickiness.totalUsers) * 100).toFixed(2);
  }

}
