export enum ReactionType {
    LIKE = "LIKE",
    CELEBRATE = "CELEBRATE",
    SUPPORT = "SUPPORT",
    LOVE = "LOVE",
    INSIGHTFUL = "INSIGHTFUL",
    CURIOUS = "CURIOUS"
}

export interface ActivityReaction {
    id: number;
    userId: number;
    type: ReactionType
}
