import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { QualificationBusinessService } from '../service/qualification-business.service';
import { QualificationAPIService } from '../api/qualification-api.service';
import { QualificationsComponent } from '../page/qualifications/qualifications.component';
import { QualificationCreateComponent } from '@app/domain/qualification/component/qualification-create/qualification-create.component';
import { SharedModule } from '@app/shared/shared.module';
import { PaginationModule } from '@app/shared/pagination/pagination.module';
import { TranslateModule } from '@ngx-translate/core';
import { ProfileQualificationsComponent } from '../component/profile-qualifications/profile-qualifications.component';
import { QualificationsListDisplayComponent } from '../component/qualifications-list-display/qualifications-list-display.component';
import { QualificationSelectComponent } from '../component/qualification-select/qualification-select.component';

const components = [
  QualificationsComponent,
  QualificationCreateComponent,
  ProfileQualificationsComponent,
  QualificationsListDisplayComponent,
  QualificationSelectComponent
];

@NgModule({
  declarations: components,
  exports: components,
  imports: [
    CommonModule,
    SharedModule,
    PaginationModule,
    TranslateModule.forChild()
  ],
  providers: [
    QualificationBusinessService,
    QualificationAPIService
  ]
})
export class QualificationComponentsModule { }
