import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {CallbackComponent} from '@app/callback/callback.component';
import {LicensesComponent} from '@app/licenses/licenses.component';
import { CompanyFeatures } from '@app/models/company-features.model';
import {FeatureGuard} from '@app/shared/auth/feature.guard';
import {FeatureLockedComponent} from '@app/shared/feature-locked/feature-locked.component';
import {Angulartics2Module} from 'angulartics2';
import {OrgChartComponent} from 'app/employee/employee-components/org-chart/org-chart.component';
import {EmployeeLayoutComponent} from 'app/shared/layouts/employee/employee-layout.component';
import {PeopleDirectoryComponent} from './employee/employee-components/people-directory/people-directory.component';
import {ArchivedComponent} from './shared/archived/archived.component';
import {AuthenticatedGuard} from './shared/auth/authenticated.guard';
import {PasswordResetGuard} from './shared/auth/password-reset.guard';
import {UnauthenticatedGuard} from './shared/auth/unauthenticated.guard';
import {UserAccessRevokedGuard} from './shared/auth/user-access-revoked.guard';
import {UserArchivedGuard} from './shared/auth/user-archived.guard';
import {FeatureNotEnabledComponent} from './shared/feature-not-enabled/feature-not-enabled.component';
import {MobileFeatureGuard} from './shared/guards/mobile-feature.guard';
import {LogoutComponent} from './shared/logout/logout.component';
import {NotFoundComponent} from './shared/not-found/not-found.component';
import {RevokedComponent} from './shared/revoked/revoked.component';
import {RichLinkModalComponent} from './shared/components/rich-link-modal/rich-link-modal.component';
import {TeamsGatewayComponent} from '@app/teams-gateway/teams-gateway.component';
import {VincereCallbackComponent} from './vincere-callback/vincere-callback.component';
import { ZendeskCallbackComponent } from 'app/zendesk-callback/zendesk-callback.component';

const appRoutes = [
  {path: 'login', redirectTo: 'auth/login', pathMatch: 'full'},
  {path: 'reset', redirectTo: 'auth/forgot-password', pathMatch: 'full'},
  {path: 'reset/password', redirectTo: 'auth/reset-password', pathMatch: 'full'},
  {path: 'company-setup/steps', redirectTo: 'admin/dashboard/quick-start/steps', pathMatch: 'full'},
  {
    path: 'auth', loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule)
  },
  {
    path: 'callback',
    component: CallbackComponent,
  },
  {
    path: 'callback-vincere',
    component: VincereCallbackComponent,
  },
  {
    path: 'callback-zendesk',
    component: ZendeskCallbackComponent,
  },
  {
    path: 'teams-gateway',
    component: TeamsGatewayComponent,
  },
  {
    path: 'revoked',
    canActivate: [AuthenticatedGuard],
    component: RevokedComponent,
  },
  {
    path: 'archived',
    canActivate: [AuthenticatedGuard],
    component: ArchivedComponent,
  },
  {
    path: 'logout',
    canActivate: [AuthenticatedGuard],
    component: LogoutComponent,
  },
  {
    path: 'licenses',
    component: LicensesComponent,
  },
  {
    path: 'external-feedback',
    canActivate: [UnauthenticatedGuard],
    loadChildren: () => import('./external-feedback/external-feedback.module').then(m => m.ExternalFeedbackModule)
  },
  {
    path: 'external-survey',
    canActivate: [UnauthenticatedGuard],
    loadChildren: () => import('./external-survey/external-survey.module').then(m => m.ExternalSurveyModule),
  },
  {
    path: '',
    component: EmployeeLayoutComponent,
    canActivate: [AuthenticatedGuard, UserArchivedGuard, UserAccessRevokedGuard, PasswordResetGuard],
    children: [
      { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
      { path: 'dashboard', loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule) },
      {
        path: 'people-directory', canActivate: [FeatureGuard], component: PeopleDirectoryComponent,
        data: { label: 'People Directory', feature: CompanyFeatures.PEOPLE_DIRECTORY },
        children: [
          {
            path: 'profile',
            loadChildren: () => import('./domain/user/module/profile.module').then(m => m.ProfileModule),
          },
        ],
      },
      {
        path: 'org-chart',
        data: {feature: CompanyFeatures.ORG_CHART},
        canActivate: [FeatureGuard],
        component: OrgChartComponent,
      },
      {
        path: 'frankli-admin',
        canActivate: [MobileFeatureGuard],
        loadChildren: () => import('./frankli-admin/frankli-admin.module').then(m => m.FrankliAdminModule),
      },
      {
        path: 'search-results',
        canActivate: [],
        loadChildren: () => import('./search/search.module').then(m => m.SearchModule),
      },
      {
        path: 'admin',
        canActivate: [MobileFeatureGuard],
        loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule),
      },
      {
        path: 'manage',
        canActivate: [MobileFeatureGuard],
        loadChildren: () => import('./manager/manager.module').then(m => m.ManagerModule),
      },
      {
        path: 'connect',
        canActivate: [],
        loadChildren: () => import('./connect/connect.module').then(m => m.ConnectModule),
      },
      {
        path: 'feedback',
        canActivate: [],
        loadChildren: () => import('app/feedback/feedback.module').then(m => m.FeedbackModule),
      },
      {
        path: 'goals',
        canActivate: [],
        loadChildren: () => import('./goals/goals.module').then(m => m.GoalsModule),
      },
      {
        path: 'surveys',
        canActivate: [],
        loadChildren: () => import('./surveys/surveys.module').then(m => m.SurveysModule),
      },
      {
        path: 'polls',
        canActivate: [],
        loadChildren: () => import('./surveys/surveys.module').then(m => m.SurveysModule),
      },
      {
        path: 'one-to-one',
        canActivate: [],
        loadChildren: () => import('./domain/one_to_one/module/one-to-one.module').then(m => m.OneToOneModule),
      },
      {
        path: 'profile',
        canActivate: [],
        loadChildren: () => import('./domain/user/module/profile.module').then(m => m.ProfileModule),
      },
      {
        path: 'settings',
        loadChildren: () => import('./domain/settings/module/settings.module').then(m => m.SettingsModule),
      },
      { // TODO: Do we need this module anymore?
        path: 'notifications',
        canActivate: [MobileFeatureGuard],
        loadChildren: () => import('./notifications/notifications.module').then(m => m.NotificationsModule),
      },
      {
        path: 'evaluations',
        canActivate: [],
        loadChildren: () => import('./domain/review_cycle/module/evaluations.module').then(m => m.EvaluationsModule),
      },
      {
        path: 'integration',
        canActivate: [MobileFeatureGuard],
        loadChildren: () => import('./integration/integration.module').then(m => m.IntegrationModule)
      },
      {
        path: 'tasks',
        canActivate: [FeatureGuard],
        loadChildren: () => import('./domain/task/module/task.module').then(m => m.TaskModule)
      },
      { path: 'not-enabled', component: FeatureNotEnabledComponent },
      { path: 'feature-locked', component: FeatureLockedComponent },
      {
        path: 'help',
        loadChildren: () => import('./help/help.module').then(m => m.HelpModule)
      },
      {
        path: 'career',
        loadChildren: () => import('./domain/career/module/career.module').then(m => m.CareerModule)
      }
    ],
  },
  {
    path: 'rich-link',
    canActivate: [ AuthenticatedGuard ],
    component: RichLinkModalComponent
  },
  { path: '**', component: NotFoundComponent },
];

@NgModule({
  imports: [
    RouterModule.forRoot(
      appRoutes,
      // { enableTracing: true } // <-- TODO: debugging purposes only
    ),
    Angulartics2Module.forRoot(),
  ],
  exports: [
    RouterModule,
  ],
})
export class AppRoutingModule {
}

