import { Injectable } from '@angular/core';

function getWindow (): any {
    return window;
}
// Instead of referencing the global javascript window variable, wrap it in a class so it can be mocked for unit tests.
@Injectable()
export class WindowReferenceService {
    nativeWindow (): any {
        const windowReference: any = getWindow();
        return windowReference;
    }
}
