import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { GoalActivityType } from '@app/models/goals/goal-activity-type.model';
import { GoalKeyResultMeasureUnitPlacement } from '@app/models/goals/goal-key-result-measure-unit-placement.model';
import { GoalKeyResultType } from '@app/models/goals/goal-key-result-type.model';
import { GoalType } from '@app/models/goals/goal-type.enum';
import { GoalUtils } from '@app/shared/utils/goal.utils';
import { NotifyUtils } from '@app/shared/utils/notify.utils';
import { SwalUtils } from '@app/shared/utils/swal.utils';
import { FrankliValidators } from '@app/shared/validators/validators';
import { Breadcrumb } from 'app/models/breadcrumb.model';
import { CompanyFeatures } from '@app/models/company-features.model';
import { Site } from '@app/models/site.model';
import { Tag } from '@app/domain/tag/model/tag.model';
import { GoalStatusDto, GoalUpdateDto } from 'app/models/goals/goal.dto';
import { Goal } from 'app/models/goals/goal.model';
import { Department } from '@app/models/department.model';
import { RoleName } from '@app/models/user-role.model';
import { User } from 'app/models/user/user.model';
import { CompanyAPIService } from '@app/shared/api/company/company.api.service';
import { GoalsAPIService } from 'app/shared/api/goals.api.service';
import { BreadcrumbService } from 'app/shared/breadcrumbs/breadcrumbs.service';
import { Globals } from 'app/shared/globals/globals';
import moment from 'moment';
import { forkJoin, Subject } from 'rxjs';
import { GoalCreateService } from '../goals-create-old/goals-create.service';
import { GoalsIndividualActivityComponent } from './goals-individual-activity/goals-individual-activity.component';
import { GoalsIndividualUpdateComponent } from './goals-individual-update/goals-individual-update.component';
import { ChildFilter } from '@app/models/api/child-filter.model';
import { FilterOperator } from '@app/models/api/filter-operator.enum';
import { ParentFilter } from '@app/models/api/parent-filter.model';
import { onboardedNotArchivedChildFilter } from '@app/shared/utils/api-filter/common-user-filters';
import { takeUntil } from 'rxjs/operators';
import { GoalPriority } from '@app/models/goals/goal-priority.model';
import { GoalStatus } from '@app/models/goals/goal-status.model';
import { GoalVisibility } from '@app/models/goals/goal-visibility.model';
import { CommonMessages } from '@app/constants/common.messages';
import { GoalsMessages } from '@app/goals/goals.messages';
import { IMyDate } from 'angular-mydatepicker';
import { ButtonGroupOption } from '@app/shared/components/inputs/button-group/button-group.component';
import { GoalCreatePageViews, GoalCreateValidationLimits } from '../goal-create/goal-create.component';
import { initKeyResultFormGroup } from '../goal-create/goal-create-form-parts/goal-create-part-key-results/goal-create-part-key-results.component';
import { TranslateService } from '@ngx-translate/core';
import { IconHoverColor } from '@app/shared/components/inputs/table-action-icon/table-action-icon.component';
import { ButtonType } from '@app/shared/components/inputs/button/button.component';
import { DateUtils } from '@app/shared/utils/date.utils';
import { TagType } from '@app/domain/tag/model/tag-type.model';
import { TagBusinessService } from '@app/domain/tag/service/tag-business.service';
import { ModalComponent } from '@app/shared/modal/modal.component';
import { SweetAlertResult } from 'sweetalert2';
import { TerminologyEntity } from '@app/domain/terminology/model/terminology-entity.enum';

interface State {
  loading: boolean;
  error: boolean;
  errorMessage: string;
  submitting: boolean;
  submitted: boolean;
  editing: boolean;
  creating: boolean;
}

@Component({
  selector: 'app-goals-individual',
  templateUrl: './goals-individual.component.html',
  styleUrls: ['./goals-individual.component.scss']
})
export class GoalsIndividualComponent implements OnInit, OnDestroy {

  public readonly eGoalKeyResultMeasureUnitPlacement = GoalKeyResultMeasureUnitPlacement;
  public readonly eGoalCreateValidationLimits = GoalCreateValidationLimits;
  public readonly eGoalCreatePageViews = GoalCreatePageViews;
  public readonly eGoalKeyResultType = GoalKeyResultType;
  public readonly eTerminologyEntity = TerminologyEntity;
  public readonly eGoalActivityType = GoalActivityType;
  public readonly eCompanyFeatures = CompanyFeatures;
  public readonly eGoalVisibility = GoalVisibility;
  public readonly eCommonMessages = CommonMessages;
  public readonly eIconHoverColor = IconHoverColor;
  public readonly eGoalsMessages = GoalsMessages;
  public readonly eGoalPriority = GoalPriority;
  public readonly eButtonType = ButtonType;
  public readonly eGoalStatus = GoalStatus;
  public readonly eGoalUtils = GoalUtils;
  public readonly eGoalType = GoalType;
  public readonly eRoleName = RoleName;

  private readonly ngUnsubscribe$ = new Subject<void>();

  @ViewChild(GoalsIndividualUpdateComponent) updateComponent?: GoalsIndividualUpdateComponent;
  @ViewChild(GoalsIndividualActivityComponent) activityComponent?: GoalsIndividualActivityComponent;
  @ViewChild('userGoalCreateModal') userGoalCreateModal?: ModalComponent;

  state: State;

  goalId!: number;

  goal!: Goal;
  user!: User;

  alignedChildren: Goal[];
  alignedParent: Goal | null;

  formEdit!: FormGroup;

  breadcrumb: Breadcrumb;

  userIsOwner: boolean;

  editingResultsWarningVisible: boolean;


  canEditGoal: boolean;

  searchChildFilter: ChildFilter = {
    operator: FilterOperator.AND,
    filterCriteria: []
  };
  parentFilter: ParentFilter = {
    operator: FilterOperator.AND,
    childFilters: [onboardedNotArchivedChildFilter, this.searchChildFilter]
  };

  statusHighlightClass: string;

  minGoalDueDate: IMyDate;
  maxLinkedItemDate: IMyDate | null;

  formOptionsVisibility: ButtonGroupOption[];
  formOptionsDepartment: Department[];
  formOptionsSite: Site[];
  formOptionsTags: Tag[];

  dueDuration: string;

  get goalTitle(): FormControl {
    return this.formEdit.controls.title as FormControl;
  }

  get goalType(): FormControl {
    return this.formEdit.controls.type as FormControl;
  }

  get goalDevelopmentNeeds(): FormControl {
    return this.formEdit.controls.developmentNeeds as FormControl;
  }

  get goalDepartment(): FormControl {
    return this.formEdit.controls.departmentId as FormControl;
  }

  get goalSite(): FormControl {
    return this.formEdit.controls.officeLocationId as FormControl;
  }

  get goalEndDate(): FormControl {
    return this.formEdit.controls.endDate as FormControl;
  }

  get goalVisibility(): FormControl {
    return this.formEdit.controls.visibility as FormControl;
  }

  get goalKeyResults(): FormArray {
    return this.formEdit.controls.keyResults as FormArray;
  }

  get goalKeyResultsList(): FormGroup[] {
    return this.goalKeyResults.controls as FormGroup[];
  }

  get goalOwners(): FormControl {
    return this.formEdit.controls.owners as FormControl;
  }

  get goalAlignment(): FormControl {
    return this.formEdit.controls.alignmentGoal as FormControl;
  }

  get goalPriority(): FormControl {
    return this.formEdit.controls.priority as FormControl;
  }

  get goalTags(): FormControl {
    return this.formEdit.controls.tags as FormControl;
  }

  get goalTasks(): FormArray {
    return this.formEdit.controls.tasks as FormArray;
  }

  get validGoalTypes(): GoalType[] {
    return GoalUtils.getAllowedAlignmentTypesForGoalType(this.goalType.value);
  }

  constructor(
    public route: ActivatedRoute,
    public globals: Globals,
    private goalCreateService: GoalCreateService,
    private companyAPIService: CompanyAPIService,
    private breadcrumbService: BreadcrumbService,
    private translateService: TranslateService,
    private goalsAPIService: GoalsAPIService,
    private formBuilder: FormBuilder,
    private cdRef: ChangeDetectorRef,
    private router: Router,
    private swalUtils: SwalUtils,
    private notifyUtils: NotifyUtils,
    private tagBusinessService: TagBusinessService
  ) {
    this.statusHighlightClass = '';
    this.dueDuration = '';

    this.state = {
      loading: true,
      error: false,
      errorMessage: '',
      submitting: false,
      submitted: false,
      editing: false,
      creating: false
    };

    this.alignedParent = null;
    this.alignedChildren = [];

    this.editingResultsWarningVisible = true;
    this.userIsOwner = false;
    this.canEditGoal = false;

    this.minGoalDueDate = this.convertDateToPickerFormat(new Date);
    this.maxLinkedItemDate = null;

    this.formOptionsVisibility = [
      {
        value: GoalVisibility.PUBLIC,
        friendlyText: GoalsMessages.PUBLIC
      },
      {
        value: GoalVisibility.PRIVATE,
        friendlyText: GoalsMessages.PRIVATE
      }
    ];
    this.formOptionsDepartment = [];
    this.formOptionsSite = [];
    this.formOptionsTags = [];

    // TODO: attach this to valueChanges on owner control if needed
    // this.boxUserFilterValueChanged.pipe(debounceTime(500), distinctUntilChanged()).subscribe((sarg) => {
    //   if (sarg.trim() === '') {
    //     this.ownerFilter.filtered = [];
    //     this.searching = false;
    //   } else {
    //     this.searchChildFilter.filterCriteria = [
    //       {
    //         field: 'name',
    //         operator: CriterionOperator.LIKE,
    //         value: sarg
    //       },
    //       {
    //         field: 'id',
    //         operator: CriterionOperator.NOT_IN,
    //         values: this.ownerFilter.selected.map(su => String(su.id))
    //       }
    //     ]
    //     this.userAPIService.searchUsersPaginated(staticUserPagingParams, staticUserSortingParams, this.parentFilter).subscribe(users => {
    //       this.ownerFilter.filtered = users.content as User[];
    //       this.searching = false;
    //     });
    //   }
    // });

    this.breadcrumb = this.breadcrumbService.init(this.route);

    this.formEdit = this.initFormEdit();
  }

  unsavedChanges() {
    const changes = this.checkUnsaved();
    this.globals.hasUnsavedChanges = changes;
    return changes;
  }

  checkUnsaved(): boolean {
    this.cdRef.detectChanges();

    if (this.state.error) {
      return false;
    }

    if (this.state.editing) {
      return true;
    }

    if (this.updateComponent && this.updateComponent.unsavedChanges()) {
      return true;
    }

    if (this.activityComponent && this.activityComponent.unsavedChanges()) {
      return true;
    }

    return false;
  }

  // #region - LIFECYCLE HOOKS
  ngOnInit() {
    this.getData();
  }

  ngOnDestroy() {
    this.breadcrumbService.remove(this.breadcrumb);
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.unsubscribe();
  }
  // #endregion

  getData() {
    this.goalId = this.getGoalId()!;

    this.load();

    this.router.events
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(event => {
        if (event instanceof NavigationEnd) {
          if (this.route.children.length === 0) {
            this.getData();
          // TODO: This recursion seems wrong?
          }
        }
      });

    this.goalCreateService.getRefreshGoals()
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(() => this.load());
    this.goalCreateService.getGoalUpdated()
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(() => this.load());
  }

  doError(message: string) {
    // TODO: Add custom message from function param
    this.state.error = true;
    this.state.loading = false;
    this.state.errorMessage = message;
  }

  getGoalId(): (number | undefined) {
    const goalId = this.route.snapshot.paramMap.get('id');

    if (goalId === null || isNaN(+goalId) === true) {
      this.doError('TODO:');
      return undefined;
    }

    return +goalId;
  }

  refresh($event: boolean) {
    if ($event === true) {
      this.state.loading = true;
      this.state.error = false;
      this.load();
    }
  }

  initFormEdit(goal?: Goal): FormGroup {
    let formGroup = this.formBuilder.group({
      id: new FormControl(
        null,
        []
      ),
      title: new FormControl(
        '',
        [Validators.required, Validators.minLength(GoalCreateValidationLimits.title.min), Validators.maxLength(GoalCreateValidationLimits.title.max)]
      ),
      type: new FormControl(
        null,
        [Validators.required]
      ),
      developmentNeeds: new FormControl(
        '',
        [Validators.minLength(GoalCreateValidationLimits.developmentNeeds.min), Validators.maxLength(GoalCreateValidationLimits.developmentNeeds.max)]
      ),
      departmentId: new FormControl(
        null,
        []
      ),
      officeLocationId: new FormControl(
        null,
        []
      ),
      endDate: new FormControl(
        null,
        // FrankliValidators.minDate(GoalCreateValidationLimits.endDate.min)
        [Validators.required]
      ),
      visibility: new FormControl(
        GoalVisibility.PUBLIC,
        [Validators.required]
      ),
      keyResults: new FormArray(
        [],
        [FrankliValidators.minLengthArray(GoalCreateValidationLimits.keyResults.min)]
      ),
      owners: new FormControl(
        [],
        // TODO: Add validation so that either yourself or a direct report must be an owner
        [FrankliValidators.minLengthArray(GoalCreateValidationLimits.owners.min)]
      ),
      alignmentGoal: new FormControl(
        null,
        []
      ),
      priority: new FormControl(
        GoalPriority.NOT_SET,
        []
      ),
      tags: new FormControl(
        [],
        []
      ),
      tasks: new FormArray(
        // [initTaskFormGroup()]
        [],
        []
      ),
    });

    if (goal) {
      formGroup = this.populateFormWithGoalData(formGroup, goal);
    }

    this.initEditFormEventListeners(formGroup);

    this.refreshDateLimits(formGroup);

    if (this.alignedChildren.length > 0) {
      formGroup.controls.type.disable();
      formGroup.controls.visibility.disable();
    }

    return formGroup;
  }

  populateFormWithGoalData(formGroup: FormGroup, goal: Goal): FormGroup {
    formGroup.patchValue(goal);

    // Objects on the goal that are stored as IDs in the form
    const departmentId = this.getFormDefaultDepartment(this.formOptionsDepartment, goal.department);
    const siteId = this.getFormDefaultSite(this.formOptionsSite, goal.officeLocation);

    if (goal.alignment) {
      this.goalsAPIService.getGoalById(goal.alignment).subscribe(ga => {
        formGroup.controls.alignmentGoal.patchValue(ga);
      });
    }

    formGroup.controls.departmentId.setValue(departmentId);
    formGroup.controls.officeLocationId.setValue(siteId);

    // Key Results
    goal.keyResults.forEach(keyResult => {
      const newGroup = initKeyResultFormGroup(goal.endDate);

      newGroup.controls.result.setErrors(null);
      newGroup.controls.result.setErrors(null);
      newGroup.controls.type.setErrors(null);
      newGroup.controls.measureStartValue.setErrors(null);
      newGroup.controls.measureGoalValue.setErrors(null);
      newGroup.controls.endDate.setErrors(null);

      newGroup.patchValue(keyResult);

      (formGroup.controls.keyResults as FormArray).push(newGroup);
    });

    return formGroup;
  }

  getFormDefaultDepartment(formOptions: Department[], goalDepartment: Department | null): number | null {
    if (!goalDepartment) {
      return null;
    }

    const departmentIsValidChoice = formOptions.some(d => d.id === goalDepartment!.id);
    if (!departmentIsValidChoice) {
      return null;
    }

    return goalDepartment.id;
  }

  getFormDefaultSite(formOptions: Site[], goalSite: Site | null): number | null {
    if (!goalSite) {
      return null;
    }

    const departmentIsValidChoice = formOptions.some(d => d.id === goalSite!.id);
    if (!departmentIsValidChoice) {
      return null;
    }

    return goalSite.id;
  }

  initEditFormEventListeners(formGroup: FormGroup): void {
    formGroup.controls.type.valueChanges.subscribe(type => this.onGoalTypeChanged(type));
    formGroup.controls.endDate.valueChanges.subscribe(endDate => this.onGoalEndDateChanged(endDate));
  }

  onGoalTypeChanged(type: GoalType): void {
    this.goalAlignment.setValue(null);

    switch (type) {
      case GoalType.PERSONAL_DEVELOPMENTAL:
        this.goalDevelopmentNeeds.setValue('');
        break;
      case GoalType.DEPARTMENT:
      case GoalType.OFFICE_LOCATION:
        this.goalVisibility.setValue(GoalVisibility.PUBLIC);
        break;
    }
  }

  onGoalEndDateChanged(endDate: Date): void {
    this.refreshDateLimits(this.formEdit);
  }

  refreshDateLimits(form: FormGroup): void {
    if (!form) {
      return;
    }
    const minDate = new Date(); // Applies to goal and it's results
    const maxDate = new Date(form.controls.endDate.value); // Applies to key results (And tasks but not from here)

    minDate.setHours(0, 0, 0, 0);
    this.minGoalDueDate = DateUtils.convertDateToPickerFormat(minDate);

    if (maxDate) {
      maxDate.setHours(0, 0, 0, 0);
      maxDate.setDate(maxDate.getDate() + 1);
      this.maxLinkedItemDate = DateUtils.convertDateToPickerFormat(maxDate);
    }

    // Update result minimum date
    const keyResultsFormArray = form.controls.keyResults as FormArray;
    const keyResults = keyResultsFormArray.controls as FormGroup[];
    keyResults.forEach(form => {
      form.controls.endDate.clearValidators();
      form.controls.endDate.setErrors(null);

      // FrankliValidators.minDate(minDate), 
      form.controls.endDate.setValidators([FrankliValidators.maxDate(maxDate)]);
      form.controls.endDate.updateValueAndValidity();
    });
  }

  insertKeyResultAtIndex(index: number) {
    const goalDueDate = this.goalEndDate.value;
    const newFormGroup = goalDueDate ? initKeyResultFormGroup(goalDueDate) : initKeyResultFormGroup();
    this.goalKeyResults.insert(index, newFormGroup);
  }

  removeKeyResultAtIndex(index: number) {
    this.goalKeyResults.removeAt(index);
  }

  onDropKeyResult(event: CdkDragDrop<FormGroup[]>) {
    const resultGroup = this.goalKeyResults.at(event.previousIndex) as FormGroup;
    this.goalKeyResults.removeAt(event.previousIndex);
    this.goalKeyResults.insert(event.currentIndex, resultGroup);
  }

  init(goal: Goal) {
    // Sort tags by type - Company values then Categories then Competencies
    goal.tags = goal.tags
      .sort((a, b) => a.text.localeCompare(b.text))
      .sort((a, b) => {
        if (a.type === b.type) {
          return 0;
        }
        if (a.type === TagType.COMPANY_VALUE) {
          return -1;
        }
        if (b.type === TagType.COMPANY_VALUE) {
          return 1;
        }
        if (a.type === TagType.CATEGORY) {
          return -1;
        }
        if (b.type === TagType.CATEGORY) {
          return 1;
        }
        return 0;
      });

    this.goal = Goal.getGoalCompletionPercentage(goal);
    this.dueDuration = DateUtils.getDaysRemaining(this.translateService, goal.endDate);
    this.canEditGoal = this.checkCanEdit();
  }

  load() {
    this.userIsOwner = false;
    this.state.loading = true;
    this.alignedParent = null;
    this.alignedChildren = [];

    forkJoin([
      this.tagBusinessService.get(null, null, null),
      this.companyAPIService.getDepartmentByType('Department'),
      this.companyAPIService.getAllSites(),
      this.goalsAPIService.getGoalById(this.goalId),
      this.goalsAPIService.getAlignedGoals(this.goalId)
    ])
      .toPromise()
      .then(
        ([tags, departments, officeLocations, goal, alignedChildren]) => {
          this.formOptionsDepartment = departments;
          this.formOptionsSite = officeLocations;
          this.formOptionsTags = tags;

          this.alignedChildren = Goal.getGoalArrayCompletionPercentage(alignedChildren);

          if (goal.title) {
            this.breadcrumb = this.breadcrumbService.updateCurrentBreadcrumb(goal.title, null);
          }

          this.userIsOwner = this.checkIfUserIsOwnerOnGoal(goal, this.globals.user.id);

          this.init(goal);

          this.statusHighlightClass = this.getStatusHighlight(goal);

          // TODO: Error state for alignment goal will conflict with main page - Use a child component instead?
          if (goal.alignment !== null) {
            this.goalsAPIService.getGoalById(goal.alignment).subscribe(alignedParent => {
              this.alignedParent = Goal.getGoalCompletionPercentage(alignedParent);
              this.goalAlignment.setValue(this.alignedParent);
              this.state.loading = false;
            }, () => {
              this.state.error = true;
              this.state.loading = false;
            });
          } else {
            this.state.loading = false;
          }
        },
        (error: HttpErrorResponse) => {
          this.doError(error.message);
        }
      );
  }
  // #endregion

  // #region - goal actions
  complete() {
    this.swalUtils.displayWarningConfirmationSwal({
      text: GoalsMessages.WARNING_GOAL_MARKED_COMPLETE,
      imageUrl: 'assets/img/swal-icons/frankli-warning-icon.svg',
      confirmButtonText: GoalsMessages.COMPLETE_GOAL
    }).then((result) => {
      if (result.isConfirmed) {
        // TODO: Loading
        // TODO: Error handling
        const goalStatusDto = new GoalStatusDto(GoalActivityType.COMPLETE);
        this.goalsAPIService.updateStatus(this.goalId, goalStatusDto).subscribe((response: Goal) => {
          this.init(response);
          this.notifyUtils.notify(GoalsMessages.SUCCESS_GOAL_MARKED_COMPLETE);

          this.router.navigateByUrl('/goals?type=personal');
        });
      } else {
        this.swalUtils.displayErrorSwal({
          title: CommonMessages.CANCELLED,
          text: GoalsMessages.WARNING_GOAL_NOT_MARKED_COMPLETE,
        });
      }
    });
  }

  checkIfUserIsOwnerOnGoal(goal: Goal, userId: number): boolean {
    if (!goal) {
      return false;
    }

    if (goal.owners.length === 0) {
      return false;
    }
    
    const goalOwnerIds = goal.owners.map((o: User) => o.id);

    return goalOwnerIds.includes(userId);
  }

  archive() {
    const message = ((this.goal.owners.length > 1) ? GoalsMessages.WARNING_GOAL_ARCHIVE_MULTIPLE_OWNERS : GoalsMessages.WARNING_GOAL_ARCHIVE);

    this.swalUtils.displayWarningConfirmationSwal({
      text: message,
      confirmButtonText: GoalsMessages.ARCHIVE_GOAL
    }).then((result) => {
      if (result.isConfirmed) {
        const goalStatusDto = new GoalStatusDto(GoalActivityType.ARCHIVE);
        this.goalsAPIService.updateStatus(this.goalId, goalStatusDto).subscribe((response: Goal) => {
          this.init(response);
          this.notifyUtils.notify(GoalsMessages.SUCCESS_GOAL_ARCHIVE);
          this.router.navigateByUrl('/goals?type=personal');
        });
      } else {
        this.swalUtils.displayErrorSwal({
          title: CommonMessages.CANCELLED,
          text: GoalsMessages.WARNING_GOAL_NOT_ARCHIVE
        });
      }
    });
  }

  unarchive() {
    this.swalUtils.displayWarningConfirmationSwal({
      text: GoalsMessages.WARNING_GOAL_UNARCHIVE,
      confirmButtonText: GoalsMessages.UNARCHIVE_GOAL,
    }).then((result) => {
      // TODO: Loading
      // TODO: Error handling
      if (result.isConfirmed) {
        const goalStatusDto = new GoalStatusDto(GoalActivityType.UNARCHIVE);
        this.goalsAPIService.updateStatus(this.goalId, goalStatusDto).subscribe((response: Goal) => {
          this.init(response);
          this.notifyUtils.notify(GoalsMessages.SUCCESS_GOAL_UNARCHIVE);
        });
      } else {
        this.swalUtils.displayErrorSwal({
          title: CommonMessages.CANCELLED,
          text: GoalsMessages.WARNING_GOAL_NOT_UNARCHIVE,
        });
      }
    });
  }

  active() {
    this.swalUtils.displayWarningConfirmationSwal({
      text: GoalsMessages.WARNING_GOAL_ACTIVATE,
      confirmButtonText: GoalsMessages.ACTIVATE_GOAL
    }).then((result) => {
      if (result.isConfirmed) {
        // TODO: Loading
        // TODO: Error handling
        const goalStatusDto = new GoalStatusDto(GoalActivityType.INCOMPLETE);
        this.goalsAPIService.updateStatus(this.goalId, goalStatusDto).subscribe((response: Goal) => {
          this.init(response);
          this.notifyUtils.notify(GoalsMessages.SUCCESS_GOAL_ACTIVATE);
        });
      } else {
        this.swalUtils.displayErrorSwal({
          title: CommonMessages.CANCELLED,
          text: GoalsMessages.WARNING_GOAL_NOT_ACTIVATE
        });
      }
    });
  }

  deleteGoal() {
    const message = this.getDeleteMessage();

    this.swalUtils.displayWarningConfirmationSwal({
      text: message,
      confirmButtonText: GoalsMessages.DELETE_GOAL
    }).then((result) => {
      if (result.isConfirmed) {
        // TODO: Loading state
        // TODO: Error handling
        this.goalsAPIService.deleteGoal(this.goal.id).subscribe(() => {
          this.notifyUtils.notify(GoalsMessages.SUCCESS_GOAL_DELETE);
          this.router.navigateByUrl('/goals?type=personal');
        });
      } else {
        this.swalUtils.displayErrorSwal({
          title: CommonMessages.CANCELLED,
          text: GoalsMessages.WARNING_GOAL_NOT_DELETE
        });
      }
    });
  }

  getDeleteMessage(): string {
    let messages: string[] = [];

    if (this.goal.owners.length > 1) {
      messages = [...messages, this.translateService.instant(GoalsMessages.WARNING_GOAL_DELETE_MULTIPLE_OWNERS)];
    }

    if (this.alignedChildren.length > 0) {
      messages = [...messages, this.translateService.instant(GoalsMessages.WARNING_GOAL_DELETE_ALIGNED_CHILDREN)];
    }

    messages = [...messages, this.translateService.instant(CommonMessages.ACTION_CANNOT_BE_UNDONE)];

    return `${messages.join('. \n')}.`;
  }
  // #endregion

  navigateToGoal(goal: Goal) {
    this.router.navigate(['/goals/individual/', goal.id ]);
  }

  focusElement(e: ElementRef) {
    this.cdRef.detectChanges();
    e.nativeElement.focus();
  }

  // Enable editing goal
  startEditing(): void {
    this.globals.hasUnsavedChanges = true;
    this.state.editing = true;
    this.editingResultsWarningVisible = true;
    this.formEdit = this.initFormEdit(this.goal);
  }

  submitEdits(): void {
    this.state.submitted = true;

    if (this.formEdit.invalid) {
      // this.swalUtils.displayErrorSwal();
      return;
    }

    // Create goal object
    const goal = this.convertFormValuesToGoal(this.formEdit);

    // Push to service
    this.postEditedGoal(goal);
  }

  convertFormValuesToGoal(form: FormGroup): Goal {
    const goal = form.value as Goal;

    goal.type = this.formEdit.controls.type.value;
    goal.visibility = this.formEdit.controls.visibility.value;

    let department = null;
    let site = null;

    switch (goal.type) {
      case GoalType.DEPARTMENT:
        department = this.formOptionsDepartment.find(d => d.id === this.goalDepartment.value) || null;
        break;
      case GoalType.OFFICE_LOCATION:
        site = this.formOptionsSite.find(s => s.id === this.goalSite.value) || null;
        break;
      case GoalType.COMPANY:
        break;
      case GoalType.PERSONAL_DEVELOPMENTAL:
        break;
      case GoalType.PERSONAL_OPERATIONAL:
        break;
      case GoalType.TEAM:
        break;
    }

    goal.department = department;
    goal.officeLocation = site;

    // Properties that cannot be edited
    goal.creationDate = this.goal.creationDate;
    goal.completionDate = this.goal.completionDate;
    goal.complete = this.goal.complete;
    goal.archived = this.goal.archived;
    goal.activity = this.goal.activity;
    goal.completionPercentage = this.goal.completionPercentage;
    goal.status = this.goal.status;
    goal.alignment = null;

    if (form.controls.alignmentGoal.value) {
      goal.alignment = form.controls.alignmentGoal.value.id;
    }

    // Reset ordering on key results in case they were moved around/removed/added
    for (let i = 0; i < goal.keyResults.length; i++) {
      goal.keyResults[i].orderIndex = i;
    }

    return goal;
  }

  postEditedGoal(g: Goal) {
    const goalUpdateDto = new GoalUpdateDto(g);

    if (this.formEdit.invalid) {
      return;
    }

    this.state.loading = true;
    this.goalsAPIService.updateGoal(g.id, goalUpdateDto).subscribe(
      (res) => {
        this.notifyUtils.notify(GoalsMessages.SUCCESS_GOAL_UPDATE);
        this.goal = res;
        this.refresh(true);
        this.state.editing = false;
      },
      () => {
        this.state.loading = false;
      }
    );
  }

  // Cancel editing goal
  cancelEdit() {
    this.formEdit = this.initFormEdit(this.goal);
    this.state.editing = false;
    this.state.submitted = false;
  }

  private formDateToDateObject(control: AbstractControl): Date | null {
    let date = control.value;
    if (date !== null) {
      // ensure date is in utc format to avoid timezone issues
      date = moment.utc(date).toDate();
    }

    return date;
  }

  openOwner(o: User) {
    this.router.navigate(['/profile/', o.id]);
  }

  openTag(t: Tag) {
    this.router.navigate(['/goals'], { queryParams: { type: 'tags', tagId: t.id } });
  }

  private validateEndDate(goalEndDate: Date, resultEndDate: Date | null): boolean {
    if (resultEndDate === null) {
      return true;
    }
    if (goalEndDate.getTime() >= resultEndDate.getTime()) {
      return true;
    }
    return false;
  }

  getAbstractAsFormGroup(abstractControl: AbstractControl): FormGroup {
    return <FormGroup>abstractControl;
  }

  getStatusHighlight(goal: Goal): string {
    if (goal.archived) {
      return 'goal-status-highlight-archived';
    }

    if (goal.complete) {
      return 'goal-status-highlight-complete';
    }

    switch (goal.status) {
      case GoalStatus.OFF_TRACK:
        return 'goal-status-highlight-off-track';
      case GoalStatus.PROGRESSING:
        return 'goal-status-highlight-progressing';
      case GoalStatus.ON_TRACK:
        return 'goal-status-highlight-on-track';
    }
  }

  checkCanEdit(): boolean {
    // If the current user is an owner on the goal
    const userIsGoalOwner = this.checkIfUserIsOwnerOnGoal(this.goal, this.globals.user.id);
    if (userIsGoalOwner) {
      return true;
    }

    // If the current user is a goal admin
    if (this.globals.hasRole(RoleName.GOAL_COMPANY)) {
      return true;
    }

    // If the current user is the manager of one of the owners
    if (this.goal.owners.map(o => o.managerId).includes(this.globals.user.id)) {
      return true;
    }

    return false;
  }

  // TODO: Use the DateUtils instead of this
  convertDateToPickerFormat(date: Date): IMyDate {
    return {
      day: date.getDate(),
      month: (date.getMonth() + 1),
      year: date.getFullYear(),
    };
  }
  
  createAlignedGoal(){
    this.state.creating = true;
    setTimeout(() => {
      this.userGoalCreateModal.show();
    }, 1);
  }
  
  
  // called when the close button at the top right of the goal create modal is clicked
  hideCreateModal(): void {
    if (this.userGoalCreateModal) {
      this.userGoalCreateModal.hide();
    }

    setTimeout(() => {
      this.state.creating = false;

      setTimeout(() => {
        this.state.creating = true;
      }, 1);
    }, 1);
  }


  onCloseCreateResponse(result: SweetAlertResult): void {
    if(result.isConfirmed){
      this.hideCreateModal();
    }
  }

}
