import { Component, Input, OnChanges, OnDestroy, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { CommonMessages } from '@app/constants/common.messages';
import { OneToOneMessages } from '@app/domain/one_to_one/locale/one-to-one.messages';
import { OneToOneMeetingStatus } from '@app/domain/one_to_one/model/one-to-one-meeting-status.model';
import { OneToOneScheduleDetailsView } from '@app/domain/one_to_one/model/one-to-one-schedule.model';
import { DefaultTaskAlignment, TaskCreateComponent, initTaskForm, parseTaskFormValueToCreateDto } from '@app/domain/task/component/create-task/task-create.component';
import { TasksMessages } from '@app/domain/task/locale/tasks.messages';
import { CreateTaskDto } from '@app/domain/task/model/create-task.dto';
import { TaskAlignmentType } from '@app/domain/task/model/task-alignment-type.enum';
import { TaskCreatedFrom } from '@app/domain/task/model/task-created-from.model';
import { Task } from '@app/domain/task/model/task.model';
import { TaskBusinessService } from '@app/domain/task/service/task-business.service';
import { TaskUtilsService } from '@app/domain/task/service/task-utils.service';
import { IState } from '@app/models/state/state.model';
import { ButtonType } from '@app/shared/components/inputs/button/button.component';
import { Globals } from '@app/shared/globals/globals';
import { ModalComponent } from '@app/shared/modal/modal.component';
import { PaginationNewComponent } from '@app/shared/pagination/pagination-new/pagination-new.component';
import { deepClone } from '@app/shared/utils/helpers';
import { SwalUtils } from '@app/shared/utils/swal.utils';
import { SweetAlertOptions } from 'sweetalert2';
import moment from 'moment';
import { Subject } from 'rxjs';
import { OneToOneMeeting } from '../../model/one-to-one-meeting.model';
import { SortingOption } from '@app/shared/components/inputs/sorting-dropdown/sorting-dropdown.component';

interface PageState extends IState {
  submitted: boolean;
  submitting: boolean;
}

enum ModalType {
  CREATE = 'CREATE',
  DETAILS = 'DETAILS'
}

enum TableColumn {
  CREATOR = 'CREATOR',
  TITLE = 'TITLE',
  OWNERS = 'OWNERS',
  DUE_DATE = 'DUE_DATE',
  STATUS = 'STATUS',
  ACTIONS = 'ACTIONS'
}

type TabEntity = Task;

@Component({
  selector: 'app-action-points',
  templateUrl: './action-points.component.html',
  styleUrls: ['./action-points.component.scss']
})
export class ActionPointsComponent implements OnDestroy, OnChanges {
  public readonly eOneToOneMessages = OneToOneMessages;
  public readonly eButtonType = ButtonType;
  public readonly eModalType = ModalType;
  public readonly confirmCloseSettings: SweetAlertOptions = {
    title: CommonMessages.UNSAVED_CHANGES,
    text: CommonMessages.UNSAVED_CHANGES_WARNING,
    confirmButtonText: CommonMessages.STAY,
    cancelButtonText: CommonMessages.LEAVE
  };

  private ngUnsubscribe$: Subject<void> = new Subject<void>();
  
  @ViewChild('modal') modal?: ModalComponent;
  @ViewChild('pagination') pagination?: PaginationNewComponent;
  @ViewChild('componentCreate') componentCreate?: TaskCreateComponent;

  @Input() schedule: OneToOneScheduleDetailsView;
  @Input() meeting: OneToOneMeeting;
  @Input() meetingPrevious: OneToOneMeeting;

  state: PageState;
  dataNewlyAdded: TabEntity[];
  dataForReview: TabEntity[];
  taskViewing: Task;
  modalType: ModalType;

  formCreate: FormGroup;
  defaultAlignment: DefaultTaskAlignment;

  controlSortNew: FormControl;
  controlSortExisting: FormControl;
  sortingOptions: SortingOption[];

  get dataAll(): TabEntity[] {
    return [...this.dataNewlyAdded, ...this.dataForReview];
  }

  constructor(
    public globals: Globals,
    private swalUtils: SwalUtils,
    private taskBusinessService: TaskBusinessService
  ) {
    this.taskViewing = undefined;
    this.defaultAlignment = undefined;

    this.state = {
      loading: true,
      error: false,
      errorMessage: '',
      submitted: false,
      submitting: false
    };

    this.dataNewlyAdded = [];
    this.dataForReview = [];

    this.formCreate = initTaskForm(TaskCreatedFrom.ONE_TO_ONE);
    this.sortingOptions = this.getSortingOptions();
    this.controlSortNew = this.initControlSortNew();
    this.controlSortExisting = this.initControlSortExisting();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.meeting) {
      this.onMeetingChanged();
    }
  }
  
  ngOnDestroy(): void {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.unsubscribe();
  }

  onMeetingChanged(): void {
    if (this.schedule) {
      this.defaultAlignment = {
        type: TaskAlignmentType.ONE_TO_ONE,
        id: this.schedule.id
      };

      const allParticipants = [this.schedule.manager, ...this.schedule.participants];
      this.formCreate = initTaskForm(TaskCreatedFrom.ONE_TO_ONE, undefined, this.defaultAlignment, allParticipants);
    }

    this.getData();
  }
  
  getData(): void {
    if (!this.schedule) { return; }
    if (!this.schedule.id) { return; }
    if (!this.schedule.participants) { return; }
    if (this.schedule.participants.length === 0) { return; }

    const scheduleParticipantIds = [this.schedule.manager.id, ...this.schedule.participants.map(p => p.id)];

    this.taskBusinessService.getTasks(null, [], scheduleParticipantIds, [], [TaskAlignmentType.ONE_TO_ONE], [this.schedule.id], false)
      .subscribe(data => {
        data = this.sortData(data);
        this.populateData(data);
        this.state.loading = false;    
      });
  }

  sortData(data: TabEntity[]): TabEntity[] {
    // Order by name
    data = data.sort((a, b) => {
      const sortStringA = `${a.title}`;
      const sortStringB = `${b.title}`;
      return sortStringA.localeCompare(sortStringB);
    });

    // Order by due date, due soon to due later. If no due date then put after
    data = data.sort((a, b) => {
      if (a.dueDate && b.dueDate) {
        return moment(a.dueDate).diff(moment(b.dueDate));
      } else if (a.dueDate) {
        return -1;
      } else if (b.dueDate) {
        return 1;
      } else {
        return 0;
      }
    });

    // Sort the completed ones to the bottom
    data = data.sort((a, b) => {
      if (a.completionDate && b.completionDate) {
        return moment(a.completionDate).diff(moment(b.completionDate));
      } else if (a.completionDate) {
        return 1;
      } else if (b.completionDate) {
        return -1;
      } else {
        return 0;
      }
    });

    return data;
  }

  populateData(data?: TabEntity[]): void {
    const dataForPeriod = this.getActionPointDataForPeriod(data);

    this.dataNewlyAdded = dataForPeriod[0];
    this.dataForReview = dataForPeriod[1];

    this.refreshPagination();
  }

  refreshPagination(): void {
    if (this.pagination) {
      this.pagination.update();
    }
  }

  onTaskAction(event: string, task: Task): void {
    switch (event) {
      case 'view':
        this.startViewTask(task);
        break;
      case 'edit':
        this.startEditTask(task);
        break;
      case 'unlink':
        this.startUnlinkTask(task);
        break;
      case 'archive':
        this.startArchiveTask(task);
        break;
    }
  }

  startViewTask(task: Task): void {
    this.taskViewing = task;
    this.showModal(ModalType.DETAILS);
  }
  
  showModal(modalType: ModalType): void {
    this.modalType = modalType;
    if (this.modal) {
      this.modal.show();
    }
  }

  hideModal(): void {
    if (this.modal) {
      this.modal.hide();
    }
  }

  startCreateTask(): void {
    const scheduleParticipants = [this.schedule.manager, ...this.schedule.participants];
    this.formCreate = initTaskForm(TaskCreatedFrom.ONE_TO_ONE, undefined, this.defaultAlignment, scheduleParticipants);
    this.showModal(ModalType.CREATE);
  }

  onCancelCreate(): void {
    const scheduleParticipants = [this.schedule.manager, ...this.schedule.participants];
    this.formCreate = initTaskForm(TaskCreatedFrom.ONE_TO_ONE, undefined, this.defaultAlignment, scheduleParticipants);
    this.hideModal();
  }

  startEditTask(task: Task): void {
    const taskCopy = JSON.parse(JSON.stringify(task));
    this.formCreate = initTaskForm(TaskCreatedFrom.ONE_TO_ONE, taskCopy);
    this.showModal(ModalType.CREATE);
  }

  onSubmitCreate(): void {
    this.state.submitted = true;
    if (this.formCreate.invalid) { return; }

    const createDto: CreateTaskDto = parseTaskFormValueToCreateDto(this.formCreate.value);
    const taskId = this.formCreate.value.id;

    if (taskId) {
      this.doEdit(taskId, createDto);
    } else {
      this.doCreate(createDto);
    }
  }

  doCreate(createDto: CreateTaskDto): void {
    this.taskBusinessService.createNewTask(createDto)
      .then(() => {
        this.getData();
        this.hideModal();
      });
  }

  doEdit(id: number, updateDto: CreateTaskDto): void {
    this.taskBusinessService.updateExistingTask(id, updateDto)
      .then(() => {
        this.getData();
        this.hideModal();
      });
  }


  startArchiveTask(task: Task): void {
    if (this.taskBusinessService.updating.includes(task.id)) { return; }

    const buttonText = TaskUtilsService.getTooltipArchive(task);

    this.swalUtils.displayWarningConfirmationSwal({
      text: CommonMessages.ACTION_CANNOT_BE_UNDONE,
      confirmButtonText: buttonText
    }).then(result => {
      if (result.isConfirmed) {
        this.taskBusinessService.archiveTask(task.id)
          .then(res => {
            this.taskUpdated(res);
            this.hideModal();
          });
      }
    });
  }

  startUnlinkTask(task: Task): void {
    this.swalUtils.displayWarningConfirmationSwal({
      text: TasksMessages.WARNING_UNLINK_TASK_ONE_TO_ONE,
      confirmButtonText: TasksMessages.UNLINK_TASK
    }).then((result) => {
      if (result.isConfirmed) {
        this.taskBusinessService.removeTaskAlignment(task)
          .then(() => {
            this.getData();
          });
      }
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  taskUpdated(task: Task): void {
    this.getData();
  }

  getActionPointDataForPeriod(actionPointsInput: Task[]): [Task[], Task[]] {
    if (!this.meeting) {
      return [actionPointsInput, []];
    }

    const currentMeetingIndex = this.schedule.meetingList.findIndex(m => m.id === this.meeting.id);
    if (currentMeetingIndex === -1) {
      return [actionPointsInput, []];
    }
    const currentMeeting = this.schedule.meetingList[currentMeetingIndex];
    const currentMeetingCompletion = currentMeeting.completedTimestamp? currentMeeting.completedTimestamp : moment().toDate();
    const currentMeetingCompleted = [OneToOneMeetingStatus.COMPLETED, OneToOneMeetingStatus.AUTO_COMPLETED].includes(currentMeeting.status);

    let actionPoints = deepClone(actionPointsInput);

    actionPoints = actionPoints.filter(ap => {
      if (currentMeetingCompleted) { return true; } // Show all action points if the current meeting is not completed
      if(moment(ap.creationDate).isSameOrBefore(moment(currentMeetingCompletion))) { return true; } // Only show action points created before the current meeting was completed

      return true;
    });

    const actionPointsNew = [];
    const actionPointsExisting = [];

    actionPoints.forEach(ap => {
      // If there's no previous meeting, all action points are new ones
      if (!this.meetingPrevious) {
        actionPointsNew.push(ap);
        return;
      }

      if (moment(ap.creationDate).isAfter(moment(this.meetingPrevious.meetingTimestamp))) {
        actionPointsNew.push(ap);
        return;
      }

      if (ap.completionDate && moment(ap.completionDate).isBefore(moment(this.meetingPrevious.meetingTimestamp))) {
        return;
      }

      actionPointsExisting.push(ap);
    });

    return [actionPointsNew, actionPointsExisting];
  }

  getSortingOptions(): SortingOption[] {
    return [
      {
        value: TableColumn.CREATOR,
        label: TasksMessages.CREATED_BY
      },
      {
        value: TableColumn.TITLE,
        label: TasksMessages.TITLE
      },
      {
        value: TableColumn.OWNERS,
        label: TasksMessages.TASK_OWNERS
      },
      {
        value: TableColumn.DUE_DATE,
        label: TasksMessages.DUE_DATE
      },
      {
        value: TableColumn.STATUS,
        label: TasksMessages.STATUS
      }
    ];
  }

  initControlSortNew(column?: TableColumn): FormControl {
    const formControl = new FormControl(null, []);

    if (column) {
      formControl.setValue(column, { emitEvent: false });
    }

    formControl.valueChanges.subscribe(column => this.onSortChangedNew(column));

    return formControl;
  }

  initControlSortExisting(column?: TableColumn): FormControl {
    const formControl = new FormControl(null, []);

    if (column) {
      formControl.setValue(column, { emitEvent: false });
    }

    formControl.valueChanges.subscribe(column => this.onSortChangedExisting(column));

    return formControl;
  }

  onSortChangedNew(column: TableColumn): void {
    switch (column) {
      case TableColumn.CREATOR:
        this.dataNewlyAdded = TaskUtilsService.sortByCreator(this.dataNewlyAdded);
        break;
      case TableColumn.TITLE:
        this.dataNewlyAdded = TaskUtilsService.sortByTitle(this.dataNewlyAdded);
        break;
      case TableColumn.OWNERS:
        this.dataNewlyAdded = TaskUtilsService.sortByOwners(this.dataNewlyAdded);
        break;
      case TableColumn.DUE_DATE:
        this.dataNewlyAdded = TaskUtilsService.sortByDueDate(this.dataNewlyAdded);
        break;
      case TableColumn.STATUS:
        this.dataNewlyAdded = TaskUtilsService.sortByStatus(this.dataNewlyAdded);
        break;
      case TableColumn.ACTIONS:
      default:
        return;
    }

    this.refreshPagination();
  }

  onSortChangedExisting(column: TableColumn): void {
    switch (column) {
      case TableColumn.CREATOR:
        this.dataForReview = TaskUtilsService.sortByCreator(this.dataForReview);
        break;
      case TableColumn.TITLE:
        this.dataForReview = TaskUtilsService.sortByTitle(this.dataForReview);
        break;
      case TableColumn.OWNERS:
        this.dataForReview = TaskUtilsService.sortByOwners(this.dataForReview);
        break;
      case TableColumn.DUE_DATE:
        this.dataForReview = TaskUtilsService.sortByDueDate(this.dataForReview);
        break;
      case TableColumn.STATUS:
        this.dataForReview = TaskUtilsService.sortByStatus(this.dataForReview);
        break;
      case TableColumn.ACTIONS:
      default:
        return;
    }

    this.refreshPagination();
  }
}
