import { EvaluationCycleQuestion } from '@app/models/evaluation/evaluation-cycle-question.model';
import {
  mockManagerReviewAssessmentGroupQuestionBankQuestions,
  mockManagerReviewQuestionBankQuestions,
  mockPeerReviewQuestionBankQuestions,
  mockSelfReflectionAssessmentGroupQuestionBankQuestions,
  mockSelfReflectionQuestionBankQuestions,
} from '@app/mock/api/data/mockQuestionBankQuestions';

export const mockMidYearReviewSelfReflectionQuestions: EvaluationCycleQuestion[] = [
  ...mockSelfReflectionQuestionBankQuestions.map((qbq, idx) => ({
    id: idx + 1,
    questionBankQuestion: qbq,
    questionText: qbq.questionText,
    orderIndex: idx,
    assessmentQuestion: qbq.assessmentQuestion
  })),
  {
    id: mockSelfReflectionQuestionBankQuestions.length + 1,
    questionBankQuestion: mockSelfReflectionAssessmentGroupQuestionBankQuestions[0],
    questionText: mockSelfReflectionAssessmentGroupQuestionBankQuestions[0].questionText,
    orderIndex: mockSelfReflectionQuestionBankQuestions.length,
    assessmentQuestion: mockSelfReflectionAssessmentGroupQuestionBankQuestions[0].assessmentQuestion
  }
];

export const mockMidYearReviewPeerReviewQuestions: EvaluationCycleQuestion[] = mockPeerReviewQuestionBankQuestions.map((qbq, idx) => ({
  id: idx + 1,
  questionBankQuestion: qbq,
  questionText: qbq.questionText,
  orderIndex: idx,
  assessmentQuestion: qbq.assessmentQuestion
}));

export const mockMidYearReviewManagerReviewQuestions: EvaluationCycleQuestion[] = [
  ...mockManagerReviewQuestionBankQuestions.map((qbq, idx) => ({
    id: idx + 1,
    questionBankQuestion: qbq,
    questionText: qbq.questionText,
    orderIndex: idx,
    assessmentQuestion: qbq.assessmentQuestion
  })),
  {
    id: mockManagerReviewQuestionBankQuestions.length + 1,
    questionBankQuestion: mockManagerReviewAssessmentGroupQuestionBankQuestions[0],
    questionText: mockManagerReviewAssessmentGroupQuestionBankQuestions[0].questionText,
    orderIndex: mockManagerReviewQuestionBankQuestions.length,
    assessmentQuestion: mockManagerReviewAssessmentGroupQuestionBankQuestions[0].assessmentQuestion
  }
];

export const mockMidYearReviewUpwardReviewQuestions: EvaluationCycleQuestion[] = [
  ...mockManagerReviewQuestionBankQuestions.map((qbq, idx) => ({
    id: idx + 1,
    questionBankQuestion: qbq,
    questionText: qbq.questionText,
    orderIndex: idx,
    assessmentQuestion: qbq.assessmentQuestion
  })),
  {
    id: mockManagerReviewQuestionBankQuestions.length + 1,
    questionBankQuestion: mockManagerReviewAssessmentGroupQuestionBankQuestions[0],
    questionText: mockManagerReviewAssessmentGroupQuestionBankQuestions[0].questionText,
    orderIndex: mockManagerReviewQuestionBankQuestions.length,
    assessmentQuestion: mockManagerReviewAssessmentGroupQuestionBankQuestions[0].assessmentQuestion
  }
];

export const mockEvaluationCycleQuestions: EvaluationCycleQuestion[] = [
  ...mockMidYearReviewSelfReflectionQuestions,
  ...mockMidYearReviewPeerReviewQuestions,
  ...mockMidYearReviewManagerReviewQuestions,
  ...mockMidYearReviewUpwardReviewQuestions
];