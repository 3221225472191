import { AfterViewChecked, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NavigationEnd, RouteConfigLoadEnd, RouteConfigLoadStart, Router } from '@angular/router';
import { fromEvent, Observable, Subject } from 'rxjs';
import { Globals } from 'app/shared/globals/globals';
import { Company, CompanyState } from 'app/models/company.model';
import moment from 'moment';
import { ErrorService } from 'app/shared/interceptors/error.service';
import { User } from 'app/models/user/user.model';
import { takeUntil } from 'rxjs/operators';
import { SessionLogService } from '@app/shared/session-log/session-log.service';
import { SessionEventType } from '@app/models/session-log/session-event-type.model';
import { environment } from '../../../../environments/environment';
import { SwalUtils } from '@app/shared/utils/swal.utils';
import { SidebarService } from '@app/shared/sidebar/sidebar.service';
import { Title } from '@angular/platform-browser';
import { BannerStyle } from '@app/shared/components/banner/banner.component';
import { ButtonType } from '@app/shared/components/inputs/button/button.component';
import { ModalComponent } from '@app/shared/modal/modal.component';

interface SessionLogState {
  timer: any | null,
  clickSinceLastEvent: boolean,
  inactive: boolean
}
@Component({
  selector: 'app-employee-layout',
  templateUrl: './employee-layout.component.html',
  styleUrls: ['./employee-layout.component.scss']
})

export class EmployeeLayoutComponent implements OnInit, AfterViewChecked, OnDestroy {
  private readonly ngUnsubscribe$: Subject<void> = new Subject<void>();

  public readonly eBannerStyle = BannerStyle;
  public readonly eButtonType = ButtonType;
  public readonly eCompanyState = CompanyState;

  @ViewChild('modalRequestSandbox') modalRequestSandbox?: ModalComponent;

  updateStatus$: Observable<boolean>;

  isMock = environment.mock.enabled;
  displayBanner: boolean;
  displayOverlay: boolean;
  status: number | null;

  lazyLoading: boolean;
  company: Company;
  user: User;

  get canRequestSandbox(): boolean {
    if (!this.displayBanner) { return false; }
    if (!this.isMock) { return false; }
    return true;
  }

  // For keeping tracking of user session
  private readonly SESSION_ACTIVE_TIME = 1000 * 60 * 5; // 5 minutes (1000ms * 60 (seconds) * 5 (minutes))

  private sessionLogState: SessionLogState = {
    timer: null,
    clickSinceLastEvent: false,
    inactive: false
  };

  constructor(
    public globals: Globals,
    private router: Router,
    private chahngeDetectorRef: ChangeDetectorRef,
    private errorService: ErrorService,
    private sessionLogService: SessionLogService,
    public sidebarService: SidebarService,
    private swalUtils: SwalUtils,
    private titleService: Title
  ) {
    this.displayBanner = true;
    this.displayOverlay = false;
    this.status = null;
    this.lazyLoading = false;
    this.company = this.globals.company;
    this.user = this.globals.user;
    this.updateStatus$ = this.errorService.getUpdateStatus();
  }

  ngOnInit() {
    // TODO: DO we need this here AND in the app.component?
    this.router.events
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(event => {
        switch (true) {
          case event instanceof NavigationEnd:
            // TODO: This doesn't work properly becuase component init starts before route guards have fired.
            // Sidebar service check is a workaround
            // See https://github.com/angular/angular/issues/32561 for details
            if (!this.sidebarService.routeGuardRunning) {
              setTimeout(() => {
                this.sidebarService.closeSidebar();
              }, 1);
            }
            break;
          case event instanceof RouteConfigLoadStart:
            this.lazyLoading = true;
            break;
          case event instanceof RouteConfigLoadEnd:
            this.lazyLoading = false;
            break;
        }

      });

    this.errorService.getStatus()
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe((status: number) => {
        this.status = status;
        let msg = '';
        switch (status) {
          // no internet connection
          case 0: {
            msg = 'No internet connection';
            break;
          }
          // logged out
          case 200: {
            msg = 'You are not logged in';
            break;
          }
          case 400: {
            msg = 'You are not logged in';
            break;
          }
          case 502:
          case 503:
          case 504:
            this.titleService.setTitle('Trying to reconnect');
            break;
          default:
            if (status !== null) {
              this.swalUtils.displayErrorSwal({
                title: `Error ${status}`,
                text: msg,
                imageUrl: 'assets/img/swal-icons/frankli-error-icon.svg',
                imageWidth: 140,
                imageHeight: 140,
                confirmButtonColor: '#30747F'
              });
            }
            break;
        }

        this.displayOverlay = (status !== null);
      });

    // Session log click listener - An "active" user is one who has made a click within the last 5 minutes
    fromEvent(document, 'click')
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(() => {
        // If session is inactive, restart on click
        if (this.sessionLogState.inactive === true) {
          this.sessionLogService.log(SessionEventType.SESSION_START_APP_ACTION);
          this.startSessionTimer();
        } else {
          // If session was previously active, flag as still active on click
          this.sessionLogState.clickSinceLastEvent = true;
        }
      });

    this.sessionLogService.log(SessionEventType.SESSION_START_APP_INITIALISED);

    this.startSessionTimer();
  }

  ngAfterViewChecked() {
    this.chahngeDetectorRef.detectChanges();
  }

  ngOnDestroy() {
    if (this.sessionLogState.timer !== null) {
      clearTimeout(this.sessionLogState.timer);
    }
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.unsubscribe();
  }

  public getDayDifference(): number | null {
    if (this.globals.company.trial !== null) {
      const today = moment().startOf('day');
      const end = moment(this.globals.company.trial.endDate).startOf('day');
      return (-1 * today.diff(end, 'days'));
    }

    return null;
  }

  public hideBanner() {
    this.displayBanner = false;
  }

  public reloadApp() {
    window.location.reload();
  }

  private startSessionTimer(): void {
    this.sessionLogState.clickSinceLastEvent = false;
    this.sessionLogState.inactive = false;
    this.sessionLogState.timer = setTimeout(() => {
      // If no click was made, mark session as inactive and don't restart the timer
      if (this.sessionLogState.clickSinceLastEvent === false) {
        this.sessionLogState.inactive = true;
        this.sessionLogService.log(SessionEventType.SESSION_END_INACTIVITY);
        return;
      }

      // Click was made, tell backend user is active
      this.sessionLogService.log(SessionEventType.SESSION_ACTIVE);

      // Restart timer
      this.startSessionTimer();

    }, this.SESSION_ACTIVE_TIME);
  }

  routeHasPatternBackground(): boolean {
    const validRoutes: string[] = ['/feedback', '/connect'];

    for (let index = 0; index < validRoutes.length; index++) {
      const route = validRoutes[index];
      if (this.router.url.includes(route)) {
        return true;
      }
    }

    return false;
  }

  startRequestSandbox(): void {
    if (!this.modalRequestSandbox) { return; }
    this.modalRequestSandbox.show();
  }

}
