import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { Angulartics2Module } from 'angulartics2';
import { PaginationModule } from 'app/shared/pagination/pagination.module';
import { SharedModule } from 'app/shared/shared.module';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { TranslateModule } from '@ngx-translate/core';

import { CreateSentimentScaleComponent } from '../page/create-sentiment-scale/create-sentiment-scale.component';
import { SentimentScaleOverviewComponent } from '../page/sentiment-scale-overview/sentiment-scale-overview.component';
import { CreateSentimentScaleOptionComponent } from '../component/create-sentiment-scale-option/create-sentiment-scale-option.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { SentimentScaleReportingComponent } from '../page/sentiment-scale-reporting/sentiment-scale-reporting.component';

const components = [
  CreateSentimentScaleComponent,
  SentimentScaleOverviewComponent,
  CreateSentimentScaleOptionComponent,
  SentimentScaleReportingComponent
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    Angulartics2Module,
    FormsModule,
    ReactiveFormsModule,
    SweetAlert2Module,
    PaginationModule,
    SharedModule,
    TooltipModule.forRoot(),
    TranslateModule,
    DragDropModule
  ],
  declarations: components,
  exports: components
})
export class SentimentScaleComponentsModule { }
