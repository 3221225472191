import {UserGender, UserServerSide, UserState} from '@app/models/user/user.model';
import {PreferredContactMethod} from '@app/models/user/preferred-contact-method.model';
import {mockAddresses} from '@app/mock/api/data/mockAddresses';
import {mockRoles} from '@app/domain/role/mock/roles.mock';
import {mockOrganisationalUnits} from '@app/mock/api/data/mockOrganisationalUnits';
import {mockInterests} from '@app/domain/interest/mock/interests.mock';
import {mockUserRoles} from '@app/mock/api/data/mockUserRoles';
import {mockOfficeLocations} from '@app/mock/api/data/mockOfficeLocations';
import moment from 'moment';
import {mockUserConfiguration} from './mockUserConfiguration';

export const mockUsers: UserServerSide[] = [
  {
    id: 1,
    userState: UserState.FULL,
    passwordReset: false,
    email: 'trixy@frankli.io',
    firstName: 'Trixy',
    lastName: 'Paulson',
    description: 'Hey all, I am Trixy! Originally from the U.S., I now live in Ireland. I am interested in all things employment law and helping people in their careers.',
    gender: UserGender.FEMALE,
    homeAddress: mockAddresses[0],
    phoneNumber: '0871234567',
    preferredContactMethod: PreferredContactMethod.PHONE,
    dateOfBirth: new Date(1990, 1, 1),
    birthdayReminder: true,
    startDate: moment().subtract(1, 'year').toDate(),
    imageUrl: '../../assets/img/user-images/female-7.jpeg',
    position: mockRoles[0],
    organisationalUnit: mockOrganisationalUnits[0],
    personalInterests: mockInterests.slice(0, 9),
    professionalInterests: mockInterests.slice(30, 39),
    interests: [...mockInterests.slice(0, 9), ...mockInterests.slice(30, 39)],
    interestsSocialise: mockInterests.slice(10, 19),
    interestsCoach: mockInterests.slice(40, 49),
    interestsMentor: mockInterests.slice(40, 49),
    officeLocation: mockOfficeLocations[0],
    roles: [mockUserRoles[2]],
    version: 0,
    managerId: 1,
    twitterHandle: 'Trixy',
    managerName: 'None',
    linkedIn: null,
    configuration: mockUserConfiguration,
    skills: mockInterests.slice(30,39)
  },
  {
    id: 2,
    managerId: 1,
    firstName: 'Maxine',
    lastName: 'Stewart',
    gender: UserGender.FEMALE,
    preferredContactMethod: PreferredContactMethod.PHONE,
    roles: [mockUserRoles[1]],
    imageUrl: '../../assets/img/user-images/female-2.jpeg',
    position: mockRoles[7],
    organisationalUnit: mockOrganisationalUnits[3],
    twitterHandle: 'Maxine',
    description: 'My job is to make sure you love yours! I enjoy helping others get satisfaction and fulfillment everyday.',
    email: 'maxine@frankli.io',
    homeAddress: mockAddresses[0],
    userState: UserState.FULL,
    passwordReset: false,
    phoneNumber: '0871234567',
    dateOfBirth: new Date(1990, 1, 1),
    birthdayReminder: true,
    startDate: moment().subtract(2, 'year').toDate(),
    officeLocation: mockOfficeLocations[0],
    version: 0,
    managerName: 'None',
    linkedIn: null,
    configuration: mockUserConfiguration,
    personalInterests: mockInterests.slice(0, 9),
    professionalInterests: mockInterests.slice(30, 39),
    interests: [...mockInterests.slice(0, 9), ...mockInterests.slice(30, 39)],
    interestsSocialise: mockInterests.slice(10, 19),
    interestsCoach: mockInterests.slice(40, 49),
    interestsMentor: mockInterests.slice(40, 49), 
    skills: mockInterests.slice(30,39)
  },
  {
    id: 3,
    managerId: 1,
    firstName: 'Tina',
    lastName: 'Moore',
    gender: UserGender.FEMALE,
    preferredContactMethod: PreferredContactMethod.PHONE,
    roles: [mockUserRoles[1]],
    imageUrl: '../../assets/img/user-images/female-3.jpeg',
    position: mockRoles[8],
    organisationalUnit: mockOrganisationalUnits[0],
    twitterHandle: 'Tina',
    description: 'I enjoy winning new business, playing squash and travelling to new countries with my family.',
    email: 'tina@frankli.io',
    homeAddress: mockAddresses[0],
    userState: UserState.FULL,
    passwordReset: false,
    phoneNumber: '0871234567',
    dateOfBirth: new Date(1990, 1, 1),
    birthdayReminder: true,
    startDate: moment().subtract(3, 'year').toDate(),
    officeLocation: mockOfficeLocations[0],
    version: 0,
    managerName: 'None',
    linkedIn: null,
    configuration: mockUserConfiguration,
    personalInterests: mockInterests.slice(0, 9),
    professionalInterests: mockInterests.slice(30, 39),
    interests: [...mockInterests.slice(0, 9), ...mockInterests.slice(30, 39)],
    interestsSocialise: mockInterests.slice(10, 19),
    interestsCoach: mockInterests.slice(40, 49),
    interestsMentor: mockInterests.slice(40, 49), 
    skills: mockInterests.slice(30,39)
  },
  {
    id: 4,
    managerId: 1,
    firstName: 'Norma',
    lastName: 'White',
    gender: UserGender.FEMALE,
    preferredContactMethod: PreferredContactMethod.IN_PERSON,
    roles: [mockUserRoles[0]],
    imageUrl: '../../assets/img/user-images/female-4.jpeg',
    position: mockRoles[9],
    organisationalUnit: mockOrganisationalUnits[1],
    twitterHandle: 'Norma',
    description: 'I specialise in UI design, CSS & Javascript. I love good food with friends and long walks in the countryside with my two dogs.',
    email: 'norma@frankli.io',
    homeAddress: mockAddresses[0],
    userState: UserState.FULL,
    passwordReset: false,
    phoneNumber: '0871234567',
    dateOfBirth: new Date(1990, 1, 1),
    birthdayReminder: true,
    startDate: moment().subtract(4, 'year').toDate(),
    officeLocation: mockOfficeLocations[0],
    version: 0,
    managerName: 'None',
    linkedIn: null,
    configuration: mockUserConfiguration,
    personalInterests: mockInterests.slice(0, 9),
    professionalInterests: mockInterests.slice(30, 39),
    interests: [...mockInterests.slice(0, 9), ...mockInterests.slice(30, 39)],
    interestsSocialise: mockInterests.slice(10, 19),
    interestsCoach: mockInterests.slice(40, 49),
    interestsMentor: mockInterests.slice(40, 49), 
    skills: mockInterests.slice(30,39)
  },
  {
    id: 5,
    managerId: 2,
    firstName: 'Louisa',
    lastName: 'Johnson',
    gender: UserGender.FEMALE,
    preferredContactMethod: PreferredContactMethod.IN_PERSON,
    roles: [mockUserRoles[0]],
    imageUrl: '../../assets/img/user-images/female-5.jpeg',
    position: mockRoles[9],
    organisationalUnit: mockOrganisationalUnits[1],
    twitterHandle: 'Louisa',
    description: 'I work as part of the frontend development team and enjoy working with Angular JS, CSS and ruby on rails.',
    email: 'louisa@frankli.io',
    homeAddress: mockAddresses[0],
    userState: UserState.FULL,
    passwordReset: false,
    phoneNumber: '0871234567',
    dateOfBirth: new Date(1990, 1, 1),
    birthdayReminder: true,
    startDate: moment().subtract(5, 'year').toDate(),
    officeLocation: mockOfficeLocations[0],
    version: 0,
    managerName: 'None',
    linkedIn: null,
    configuration: mockUserConfiguration,
    personalInterests: mockInterests.slice(0, 9),
    professionalInterests: mockInterests.slice(30, 39),
    interests: [...mockInterests.slice(0, 9), ...mockInterests.slice(30, 39)],
    interestsSocialise: mockInterests.slice(10, 19),
    interestsCoach: mockInterests.slice(40, 49),
    interestsMentor: mockInterests.slice(40, 49), 
    skills: mockInterests.slice(30,39)
  },
  {
    id: 6,
    managerId: 2,
    firstName: 'Davis',
    lastName: 'Thornton',
    gender: UserGender.MALE,
    preferredContactMethod: PreferredContactMethod.IN_PERSON,
    roles: [mockUserRoles[0]],
    imageUrl: '../../assets/img/user-images/male-1.jpeg',
    position: mockRoles[9],
    organisationalUnit: mockOrganisationalUnits[1],
    twitterHandle: 'Davis',
    description: 'I usually use Angular and some other libraries to create websites and app frontends.',
    email: 'davis@frankli.io',
    homeAddress: mockAddresses[0],
    userState: UserState.FULL,
    passwordReset: false,
    phoneNumber: '0871234567',
    dateOfBirth: new Date(1990, 1, 1),
    birthdayReminder: true,
    startDate: moment().subtract(6, 'year').toDate(),
    officeLocation: mockOfficeLocations[0],
    version: 0,
    managerName: 'None',
    linkedIn: null,
    configuration: mockUserConfiguration,
    personalInterests: mockInterests.slice(0, 9),
    professionalInterests: mockInterests.slice(30, 39),
    interests: [...mockInterests.slice(0, 9), ...mockInterests.slice(30, 39)],
    interestsSocialise: mockInterests.slice(10, 19),
    interestsCoach: mockInterests.slice(40, 49),
    interestsMentor: mockInterests.slice(40, 49), 
    skills: mockInterests.slice(30,39)
  },
  {
    id: 7,
    managerId: 2,
    firstName: 'Noah',
    lastName: 'Ryan',
    gender: UserGender.MALE,
    preferredContactMethod: PreferredContactMethod.IN_PERSON,
    roles: [mockUserRoles[0], mockUserRoles[4]],
    imageUrl: '../../assets/img/user-images/male-2.jpeg',
    position: mockRoles[10],
    organisationalUnit: mockOrganisationalUnits[1],
    twitterHandle: 'Noah',
    description: 'I am interest in complex distributed systems and cloud computing infrastructure. In my spare time I play drums in a local band. I also enjoy travel and blogging.',
    email: 'noah@frankli.io',
    homeAddress: mockAddresses[0],
    userState: UserState.FULL,
    passwordReset: false,
    phoneNumber: '0871234567',
    dateOfBirth: new Date(1990, 1, 1),
    birthdayReminder: true,
    startDate: moment().subtract(6, 'year').toDate(),
    officeLocation: mockOfficeLocations[1],
    version: 0,
    managerName: 'None',
    linkedIn: null,
    configuration: mockUserConfiguration,
    personalInterests: mockInterests.slice(0, 9),
    professionalInterests: mockInterests.slice(30, 39),
    interests: [...mockInterests.slice(0, 9), ...mockInterests.slice(30, 39)],
    interestsSocialise: mockInterests.slice(10, 19),
    interestsCoach: mockInterests.slice(40, 49),
    interestsMentor: mockInterests.slice(40, 49), 
    skills: mockInterests.slice(30,39)
  },
  {
    id: 8,
    managerId: 2,
    firstName: 'Alan',
    lastName: 'Harmond',
    gender: UserGender.MALE,
    preferredContactMethod: PreferredContactMethod.EMAIL,
    roles: [mockUserRoles[0], mockUserRoles[4]],
    imageUrl: '../../assets/img/user-images/male-3.jpeg',
    position: mockRoles[10],
    organisationalUnit: mockOrganisationalUnits[0],
    twitterHandle: 'Alan',
    description: 'I write PHP and Java depending on the project. I love vim and run OpenBSD. I tinker with computers in my spare time.',
    email: 'alan@frankli.io',
    homeAddress: mockAddresses[0],
    userState: UserState.FULL,
    passwordReset: false,
    phoneNumber: '0871234567',
    dateOfBirth: new Date(1990, 1, 1),
    birthdayReminder: true,
    startDate: moment().subtract(3, 'year').toDate(),
    officeLocation: mockOfficeLocations[1],
    version: 0,
    managerName: 'None',
    linkedIn: null,
    configuration: mockUserConfiguration,
    personalInterests: mockInterests.slice(0, 9),
    professionalInterests: mockInterests.slice(30, 39),
    interests: [...mockInterests.slice(0, 9), ...mockInterests.slice(30, 39)],
    interestsSocialise: mockInterests.slice(10, 19),
    interestsCoach: mockInterests.slice(40, 49),
    interestsMentor: mockInterests.slice(40, 49), 
    skills: mockInterests.slice(30,39)
  },
  {
    id: 9,
    managerId: 3,
    firstName: 'Torin',
    lastName: 'Campany',
    gender: UserGender.MALE,
    preferredContactMethod: PreferredContactMethod.EMAIL,
    roles: [mockUserRoles[0], mockUserRoles[4]],
    imageUrl: '../../assets/img/user-images/male-4.jpeg',
    position: mockRoles[11],
    organisationalUnit: mockOrganisationalUnits[1],
    twitterHandle: 'Torin',
    description: 'All things product UI/UX. I enjoy endurance training and spending time at the zoo with my family.',
    email: 'torin@frankli.io',
    homeAddress: mockAddresses[0],
    userState: UserState.FULL,
    passwordReset: false,
    phoneNumber: '0871234567',
    dateOfBirth: new Date(1990, 1, 1),
    birthdayReminder: true,
    startDate: moment().subtract(2, 'year').toDate(),
    officeLocation: mockOfficeLocations[1],
    version: 0,
    managerName: 'None',
    linkedIn: null,
    configuration: mockUserConfiguration,
    personalInterests: mockInterests.slice(0, 9),
    professionalInterests: mockInterests.slice(30, 39),
    interests: [...mockInterests.slice(0, 9), ...mockInterests.slice(30, 39)],
    interestsSocialise: mockInterests.slice(10, 19),
    interestsCoach: mockInterests.slice(40, 49),
    interestsMentor: mockInterests.slice(40, 49), 
    skills: mockInterests.slice(30,39)
  },
  {
    id: 10,
    managerId: 3,
    firstName: 'Tom',
    lastName: 'Van Hesteren',
    gender: UserGender.MALE,
    preferredContactMethod: PreferredContactMethod.EMAIL,
    roles: [mockUserRoles[0]],
    imageUrl: '../../assets/img/user-images/male-5.jpeg',
    position: mockRoles[12],
    organisationalUnit: mockOrganisationalUnits[1],
    twitterHandle: 'Tom',
    description: 'Problem solver. Alcohol evangelist. Pop culture guru. Internetaholic. Food practitioner. Web expert.',
    email: 'tom@frankli.io',
    homeAddress: mockAddresses[0],
    userState: UserState.FULL,
    passwordReset: false,
    phoneNumber: '0871234567',
    dateOfBirth: new Date(1990, 1, 1),
    birthdayReminder: true,
    startDate: moment().subtract(4, 'year').toDate(),
    officeLocation: mockOfficeLocations[1],
    version: 0,
    managerName: 'None',
    linkedIn: null,
    configuration: mockUserConfiguration,
    personalInterests: mockInterests.slice(0, 9),
    professionalInterests: mockInterests.slice(30, 39),
    interests: [...mockInterests.slice(0, 9), ...mockInterests.slice(30, 39)],
    interestsSocialise: mockInterests.slice(10, 19),
    interestsCoach: mockInterests.slice(40, 49),
    interestsMentor: mockInterests.slice(40, 49), 
    skills: mockInterests.slice(30,39)
  },
  {
    id: 11,
    managerId: 3,
    firstName: 'Faye',
    lastName: 'Gallagher',
    gender: UserGender.FEMALE,
    preferredContactMethod: PreferredContactMethod.EMAIL,
    roles: [mockUserRoles[2]],
    imageUrl: '../../assets/img/user-images/female-6.jpeg',
    position: mockRoles[13],
    organisationalUnit: mockOrganisationalUnits[0],
    twitterHandle: 'Faye',
    description: 'I keep things afloat down in Cork. You\'d think it was easy to make Cork float wouldn\'t you?',
    email: 'faye@frankli.io',
    homeAddress: mockAddresses[0],
    userState: UserState.FULL,
    passwordReset: false,
    phoneNumber: '0871234567',
    dateOfBirth: new Date(1990, 1, 1),
    birthdayReminder: true,
    startDate: moment().subtract(4, 'year').toDate(),
    officeLocation: mockOfficeLocations[1],
    version: 0,
    managerName: 'None',
    linkedIn: null,
    configuration: mockUserConfiguration,
    personalInterests: mockInterests.slice(0, 9),
    professionalInterests: mockInterests.slice(30, 39),
    interests: [...mockInterests.slice(0, 9), ...mockInterests.slice(30, 39)],
    interestsSocialise: mockInterests.slice(10, 19),
    interestsCoach: mockInterests.slice(40, 49),
    interestsMentor: mockInterests.slice(40, 49), 
    skills: mockInterests.slice(30,39)
  },
  {
    id: 12,
    managerId: 3,
    firstName: 'Michelle',
    lastName: 'Flanagan',
    gender: UserGender.FEMALE,
    preferredContactMethod: PreferredContactMethod.EMAIL,
    roles: [mockUserRoles[1]],
    imageUrl: '../../assets/img/user-images/female-1.jpeg',
    position: mockRoles[7],
    organisationalUnit: mockOrganisationalUnits[3],
    twitterHandle: 'Trixy',
    description: 'I manage some of our offices and try to ensure smooth operation and interoperation.',
    email: 'michelle@frankli.io',
    homeAddress: mockAddresses[0],
    userState: UserState.FULL,
    passwordReset: false,
    phoneNumber: '0871234567',
    dateOfBirth: new Date(1990, 1, 1),
    birthdayReminder: true,
    startDate: moment().subtract(5, 'year').toDate(),
    officeLocation: mockOfficeLocations[2],
    version: 0,
    managerName: 'None',
    linkedIn: null,
    configuration: mockUserConfiguration,
    personalInterests: mockInterests.slice(0, 9),
    professionalInterests: mockInterests.slice(30, 39),
    interests: [...mockInterests.slice(0, 9), ...mockInterests.slice(30, 39)],
    interestsSocialise: mockInterests.slice(10, 19),
    interestsCoach: mockInterests.slice(40, 49),
    interestsMentor: mockInterests.slice(40, 49), 
    skills: mockInterests.slice(30,39)
  },
  {
    id: 13,
    managerId: 7,
    firstName: 'Gillian',
    lastName: 'Monkman',
    gender: UserGender.FEMALE,
    preferredContactMethod: PreferredContactMethod.EMAIL,
    roles: [mockUserRoles[1], mockUserRoles[3]],
    imageUrl: '../../assets/img/user-images/female-8.jpeg',
    position: mockRoles[8],
    organisationalUnit: mockOrganisationalUnits[2],
    twitterHandle: 'Gillian',
    description: 'I have an eye for detail and enjoy helping product teams release great product. In my spare time, I enjoy yoga and swimming.',
    email: 'gillian@frankli.io',
    homeAddress: mockAddresses[0],
    userState: UserState.FULL,
    passwordReset: false,
    phoneNumber: '0871234567',
    dateOfBirth: new Date(1990, 1, 1),
    birthdayReminder: true,
    startDate: moment().subtract(5, 'year').toDate(),
    officeLocation: mockOfficeLocations[2],
    version: 0,
    managerName: 'None',
    linkedIn: null,
    configuration: mockUserConfiguration,
    personalInterests: mockInterests.slice(0, 9),
    professionalInterests: mockInterests.slice(30, 39),
    interests: [...mockInterests.slice(0, 9), ...mockInterests.slice(30, 39)],
    interestsSocialise: mockInterests.slice(10, 19),
    interestsCoach: mockInterests.slice(40, 49),
    interestsMentor: mockInterests.slice(40, 49), 
    skills: mockInterests.slice(30,39)
  },
  {
    id: 14,
    managerId: 7,
    firstName: 'Jamie',
    lastName: 'Sweeney',
    gender: UserGender.FEMALE,
    preferredContactMethod: PreferredContactMethod.IN_PERSON,
    roles: [mockUserRoles[0], mockUserRoles[3]],
    imageUrl: '../../assets/img/user-images/female-9.jpeg',
    position: mockRoles[14],
    organisationalUnit: mockOrganisationalUnits[1],
    twitterHandle: 'Jamie',
    description: 'I help product teams ship amazing looking software. Outside of work I blog about product design and all things UX.',
    email: 'jamie@frankli.io',
    homeAddress: mockAddresses[0],
    userState: UserState.FULL,
    passwordReset: false,
    phoneNumber: '0871234567',
    dateOfBirth: new Date(1990, 1, 1),
    birthdayReminder: true,
    startDate: moment().subtract(1, 'year').toDate(),
    officeLocation: mockOfficeLocations[2],
    version: 0,
    managerName: 'None',
    linkedIn: null,
    configuration: mockUserConfiguration,
    personalInterests: mockInterests.slice(0, 9),
    professionalInterests: mockInterests.slice(30, 39),
    interests: [...mockInterests.slice(0, 9), ...mockInterests.slice(30, 39)],
    interestsSocialise: mockInterests.slice(10, 19),
    interestsCoach: mockInterests.slice(40, 49),
    interestsMentor: mockInterests.slice(40, 49), 
    skills: mockInterests.slice(30,39)
  },
  {
    id: 15,
    managerId: 7,
    firstName: 'Joye',
    lastName: 'Murphy',
    gender: UserGender.FEMALE,
    preferredContactMethod: PreferredContactMethod.IN_PERSON,
    roles: [mockUserRoles[0], mockUserRoles[3]],
    imageUrl: '../../assets/img/user-images/female-10.jpeg',
    position: mockRoles[14],
    organisationalUnit: mockOrganisationalUnits[1],
    twitterHandle: 'Joye',
    description: 'I am part of the mobile development team & specialise in Android development. Outside of work, I enjoy landscape painting, walking my dog and the cinema.',
    email: 'joye@frankli.io',
    homeAddress: mockAddresses[0],
    userState: UserState.FULL,
    passwordReset: false,
    phoneNumber: '0871234567',
    dateOfBirth: new Date(1990, 1, 1),
    birthdayReminder: true,
    startDate: moment().subtract(1, 'year').toDate(),
    officeLocation: mockOfficeLocations[2],
    version: 0,
    managerName: 'None',
    linkedIn: null,
    configuration: mockUserConfiguration,
    personalInterests: mockInterests.slice(0, 9),
    professionalInterests: mockInterests.slice(30, 39),
    interests: [...mockInterests.slice(0, 9), ...mockInterests.slice(30, 39)],
    interestsSocialise: mockInterests.slice(10, 19),
    interestsCoach: mockInterests.slice(40, 49),
    interestsMentor: mockInterests.slice(40, 49), 
    skills: mockInterests.slice(30,39)
  },
  {
    id: 16,
    managerId: 9,
    firstName: 'Barry',
    lastName: 'Castree',
    gender: UserGender.MALE,
    preferredContactMethod: PreferredContactMethod.IN_PERSON,
    roles: [mockUserRoles[0]],
    imageUrl: '../../assets/img/user-images/male-6.jpeg',
    position: mockRoles[14],
    organisationalUnit: mockOrganisationalUnits[1],
    twitterHandle: 'Barry',
    description: 'Interested in the world of social media marketing and SEO.',
    email: 'barry@frankli.io',
    homeAddress: mockAddresses[0],
    userState: UserState.FULL,
    passwordReset: false,
    phoneNumber: '0871234567',
    dateOfBirth: new Date(1990, 1, 1),
    birthdayReminder: true,
    startDate: moment().subtract(1, 'year').toDate(),
    officeLocation: mockOfficeLocations[3],
    version: 0,
    managerName: 'None',
    linkedIn: null,
    configuration: mockUserConfiguration,
    personalInterests: mockInterests.slice(0, 9),
    professionalInterests: mockInterests.slice(30, 39),
    interests: [...mockInterests.slice(0, 9), ...mockInterests.slice(30, 39)],
    interestsSocialise: mockInterests.slice(10, 19),
    interestsCoach: mockInterests.slice(40, 49),
    interestsMentor: mockInterests.slice(40, 49), 
    skills: mockInterests.slice(30,39)
  },
  {
    id: 17,
    managerId: 9,
    firstName: 'Benny',
    lastName: 'Brennan',
    gender: UserGender.MALE,
    preferredContactMethod: PreferredContactMethod.IN_PERSON,
    roles: [mockUserRoles[0]],
    imageUrl: '../../assets/img/user-images/male-7.jpeg',
    position: mockRoles[15],
    organisationalUnit: mockOrganisationalUnits[1],
    twitterHandle: 'Becky',
    description: 'iOS mobile developer and creative designer. I collect old cars and pocket watches in my spare time.',
    email: 'benny@frankli.io',
    homeAddress: mockAddresses[0],
    userState: UserState.FULL,
    passwordReset: false,
    phoneNumber: '0871234567',
    dateOfBirth: new Date(1990, 1, 1),
    birthdayReminder: true,
    startDate: moment().subtract(1, 'year').toDate(),
    officeLocation: mockOfficeLocations[3],
    version: 0,
    managerName: 'None',
    linkedIn: null,
    configuration: mockUserConfiguration,
    personalInterests: mockInterests.slice(0, 9),
    professionalInterests: mockInterests.slice(30, 39),
    interests: [...mockInterests.slice(0, 9), ...mockInterests.slice(30, 39)],
    interestsSocialise: mockInterests.slice(10, 19),
    interestsCoach: mockInterests.slice(40, 49),
    interestsMentor: mockInterests.slice(40, 49), 
    skills: mockInterests.slice(30,39)
  },
  {
    id: 18,
    managerId: 9,
    firstName: 'Rob',
    lastName: 'Pryor',
    gender: UserGender.MALE,
    preferredContactMethod: PreferredContactMethod.PHONE,
    roles: [mockUserRoles[0]],
    imageUrl: '../../assets/img/user-images/male-8.jpeg',
    position: mockRoles[15],
    organisationalUnit: mockOrganisationalUnits[1],
    twitterHandle: 'Rob',
    description: 'I enjoy using Apple products, so I try to make something that others will enjoy in the same way.',
    email: 'rob@frankli.io',
    homeAddress: mockAddresses[0],
    userState: UserState.FULL,
    passwordReset: false,
    phoneNumber: '0871234567',
    dateOfBirth: new Date(1990, 1, 1),
    birthdayReminder: true,
    startDate: moment().subtract(1, 'year').toDate(),
    officeLocation: mockOfficeLocations[3],
    version: 0,
    managerName: 'None',
    linkedIn: null,
    configuration: mockUserConfiguration,
    personalInterests: mockInterests.slice(0, 9),
    professionalInterests: mockInterests.slice(30, 39),
    interests: [...mockInterests.slice(0, 9), ...mockInterests.slice(30, 39)],
    interestsSocialise: mockInterests.slice(10, 19),
    interestsCoach: mockInterests.slice(40, 49),
    interestsMentor: mockInterests.slice(40, 49), 
    skills: mockInterests.slice(30,39)
  },
  {
    id: 19,
    managerId: 9,
    firstName: 'Care',
    lastName: 'Hasel',
    gender: UserGender.MALE,
    preferredContactMethod: PreferredContactMethod.PHONE,
    roles: [mockUserRoles[0]],
    imageUrl: '../../assets/img/user-images/male-9.jpeg',
    position: mockRoles[15],
    organisationalUnit: mockOrganisationalUnits[1],
    twitterHandle: 'Care',
    description: 'I write the test suites for the iOS applications, and do other stuff as well.',
    email: 'care@frankli.io',
    homeAddress: mockAddresses[0],
    userState: UserState.FULL,
    passwordReset: false,
    phoneNumber: '0871234567',
    dateOfBirth: new Date(1990, 1, 1),
    birthdayReminder: true,
    startDate: moment().subtract(1, 'year').toDate(),
    officeLocation: mockOfficeLocations[3],
    version: 0,
    managerName: 'None',
    linkedIn: null,
    configuration: mockUserConfiguration,
    personalInterests: mockInterests.slice(0, 9),
    professionalInterests: mockInterests.slice(30, 39),
    interests: [...mockInterests.slice(0, 9), ...mockInterests.slice(30, 39)],
    interestsSocialise: mockInterests.slice(10, 19),
    interestsCoach: mockInterests.slice(40, 49),
    interestsMentor: mockInterests.slice(40, 49), 
    skills: mockInterests.slice(30,39)
  },
  {
    id: 20,
    managerId: 17,
    firstName: 'Brenden',
    lastName: 'McKoy',
    gender: UserGender.MALE,
    preferredContactMethod: PreferredContactMethod.PHONE,
    roles: [mockUserRoles[0]],
    imageUrl: '../../assets/img/user-images/male-10.jpeg',
    position: mockRoles[15],
    organisationalUnit: mockOrganisationalUnits[1],
    twitterHandle: 'Brenden',
    description: 'Certified Microsoft fan, huge nerd and lover of podcasts.',
    email: 'brenden@frankli.io',
    homeAddress: mockAddresses[0],
    userState: UserState.FULL,
    passwordReset: false,
    phoneNumber: '0871234567',
    dateOfBirth: new Date(1990, 1, 1),
    birthdayReminder: true,
    startDate: moment().subtract(1, 'year').toDate(),
    officeLocation: mockOfficeLocations[4],
    version: 0,
    managerName: 'None',
    linkedIn: null,
    configuration: mockUserConfiguration,
    personalInterests: mockInterests.slice(0, 9),
    professionalInterests: mockInterests.slice(30, 39),
    interests: [...mockInterests.slice(0, 9), ...mockInterests.slice(30, 39)],
    interestsSocialise: mockInterests.slice(10, 19),
    interestsCoach: mockInterests.slice(40, 49),
    interestsMentor: mockInterests.slice(40, 49), 
    skills: mockInterests.slice(30,39)
  },
  {
    id: 21,
    managerId: 17,
    firstName: 'Rozanna',
    lastName: 'Daly',
    gender: UserGender.FEMALE,
    preferredContactMethod: PreferredContactMethod.EMAIL,
    roles: [mockUserRoles[2]],
    imageUrl: '../../assets/img/user-images/female-11.jpeg',
    position: mockRoles[13],
    organisationalUnit: mockOrganisationalUnits[0],
    twitterHandle: 'Rozanna',
    description: 'I specialise in project management and financial planning. Outside of work, I enjoy volunteering and playing acoustic guitar with friends.',
    email: 'rozanna@frankli.io',
    homeAddress: mockAddresses[0],
    userState: UserState.FULL,
    passwordReset: false,
    phoneNumber: '0871234567',
    dateOfBirth: new Date(1990, 1, 1),
    birthdayReminder: true,
    startDate: moment().subtract(1, 'year').toDate(),
    officeLocation: mockOfficeLocations[4],
    version: 0,
    managerName: 'None',
    linkedIn: null,
    configuration: mockUserConfiguration,
    personalInterests: mockInterests.slice(0, 9),
    professionalInterests: mockInterests.slice(30, 39),
    interests: [...mockInterests.slice(0, 9), ...mockInterests.slice(30, 39)],
    interestsSocialise: mockInterests.slice(10, 19),
    interestsCoach: mockInterests.slice(40, 49),
    interestsMentor: mockInterests.slice(40, 49), 
    skills: mockInterests.slice(30,39)
  },
  {
    id: 22,
    managerId: 16,
    firstName: 'Lyndsie',
    lastName: 'Britney',
    gender: UserGender.FEMALE,
    preferredContactMethod: PreferredContactMethod.EMAIL,
    roles: [mockUserRoles[1]],
    imageUrl: '../../assets/img/user-images/female-12.jpeg',
    position: mockRoles[7],
    organisationalUnit: mockOrganisationalUnits[3],
    twitterHandle: 'Lyndsie',
    description: 'I work in HR and specialise in contract management and recruitment.',
    email: 'lyndsie@frankli.io',
    homeAddress: mockAddresses[0],
    userState: UserState.FULL,
    passwordReset: false,
    phoneNumber: '0871234567',
    dateOfBirth: new Date(1990, 1, 1),
    birthdayReminder: true,
    startDate: moment().subtract(1, 'year').toDate(),
    officeLocation: mockOfficeLocations[4],
    version: 0,
    managerName: 'None',
    linkedIn: null,
    configuration: mockUserConfiguration,
    personalInterests: mockInterests.slice(0, 9),
    professionalInterests: mockInterests.slice(30, 39),
    interests: [...mockInterests.slice(0, 9), ...mockInterests.slice(30, 39)],
    interestsSocialise: mockInterests.slice(10, 19),
    interestsCoach: mockInterests.slice(40, 49),
    interestsMentor: mockInterests.slice(40, 49), 
    skills: mockInterests.slice(30,39)
  },
  {
    id: 23,
    managerId: 16,
    firstName: 'Cami',
    lastName: 'Gorton',
    gender: UserGender.FEMALE,
    preferredContactMethod: PreferredContactMethod.EMAIL,
    roles: [mockUserRoles[1]],
    imageUrl: '../../assets/img/user-images/female-13.jpeg',
    position: mockRoles[8],
    organisationalUnit: mockOrganisationalUnits[2],
    twitterHandle: 'Cami',
    description: 'I enjoy leading and mentoring people to deliver great things for our customers. Outside of work I enjoy flying airplanes & travel.',
    email: 'cami@frankli.io',
    homeAddress: mockAddresses[0],
    userState: UserState.FULL,
    passwordReset: false,
    phoneNumber: '0871234567',
    dateOfBirth: new Date(1990, 1, 1),
    birthdayReminder: true,
    startDate: moment().subtract(1, 'year').toDate(),
    officeLocation: mockOfficeLocations[4],
    version: 0,
    managerName: 'None',
    linkedIn: null,
    configuration: mockUserConfiguration,
    personalInterests: mockInterests.slice(0, 9),
    professionalInterests: mockInterests.slice(30, 39),
    interests: [...mockInterests.slice(0, 9), ...mockInterests.slice(30, 39)],
    interestsSocialise: mockInterests.slice(10, 19),
    interestsCoach: mockInterests.slice(40, 49),
    interestsMentor: mockInterests.slice(40, 49), 
    skills: mockInterests.slice(30,39)
  },
  {
    id: 24,
    managerId: 16,
    firstName: 'Siofra',
    lastName: 'Murray',
    gender: UserGender.FEMALE,
    preferredContactMethod: PreferredContactMethod.PHONE,
    roles: [mockUserRoles[0]],
    imageUrl: '../../assets/img/user-images/female-14.jpeg',
    position: mockRoles[16],
    organisationalUnit: mockOrganisationalUnits[2],
    twitterHandle: 'Shefra',
    description: 'I am an organiser by nature. Outside of work I like to draw and play tennis.',
    email: 'siofra@frankli.io',
    homeAddress: mockAddresses[0],
    userState: UserState.FULL,
    passwordReset: false,
    phoneNumber: '0871234567',
    dateOfBirth: new Date(1990, 1, 1),
    birthdayReminder: true,
    startDate: moment().subtract(1, 'year').toDate(),
    officeLocation: mockOfficeLocations[4],
    version: 0,
    managerName: 'None',
    linkedIn: null,
    configuration: mockUserConfiguration,
    personalInterests: mockInterests.slice(0, 9),
    professionalInterests: mockInterests.slice(30, 39),
    interests: [...mockInterests.slice(0, 9), ...mockInterests.slice(30, 39)],
    interestsSocialise: mockInterests.slice(10, 19),
    interestsCoach: mockInterests.slice(40, 49),
    interestsMentor: mockInterests.slice(40, 49), 
    skills: mockInterests.slice(30,39)
  },
  {
    id: 25,
    managerId: 16,
    firstName: 'Sabrina',
    lastName: 'Marzellano',
    gender: UserGender.FEMALE,
    preferredContactMethod: PreferredContactMethod.PHONE,
    roles: [mockUserRoles[0]],
    imageUrl: '../../assets/img/user-images/female-15.jpeg',
    position: mockRoles[16],
    organisationalUnit: mockOrganisationalUnits[4],
    twitterHandle: 'Celinda',
    description: 'My passion is helping others. I enjoy problem solving and nice long chats with a cup of tea.',
    email: 'sabrina@frankli.io',
    homeAddress: mockAddresses[0],
    userState: UserState.FULL,
    passwordReset: false,
    phoneNumber: '0871234567',
    dateOfBirth: new Date(1990, 1, 1),
    birthdayReminder: true,
    startDate: moment().subtract(1, 'year').toDate(),
    officeLocation: mockOfficeLocations[5],
    version: 0,
    managerName: 'None',
    linkedIn: null,
    configuration: mockUserConfiguration,
    personalInterests: mockInterests.slice(0, 9),
    professionalInterests: mockInterests.slice(30, 39),
    interests: [...mockInterests.slice(0, 9), ...mockInterests.slice(30, 39)],
    interestsSocialise: mockInterests.slice(10, 19),
    interestsCoach: mockInterests.slice(40, 49),
    interestsMentor: mockInterests.slice(40, 49), 
    skills: mockInterests.slice(30,39)
  },
  {
    id: 26,
    managerId: 16,
    firstName: 'Garth',
    lastName: 'Olsen',
    gender: UserGender.MALE,
    preferredContactMethod: PreferredContactMethod.PHONE,
    roles: [mockUserRoles[0], mockUserRoles[3]],
    imageUrl: '../../assets/img/user-images/male-11.jpeg',
    position: mockRoles[16],
    organisationalUnit: mockOrganisationalUnits[4],
    twitterHandle: 'Garth',
    description: 'I enjoy travelling and seeing the world around me!',
    email: 'garth@frankli.io',
    homeAddress: mockAddresses[0],
    userState: UserState.FULL,
    passwordReset: false,
    phoneNumber: '0871234567',
    dateOfBirth: new Date(1990, 1, 1),
    birthdayReminder: true,
    startDate: moment().subtract(1, 'year').toDate(),
    officeLocation: mockOfficeLocations[5],
    version: 0,
    managerName: 'None',
    linkedIn: null,
    configuration: mockUserConfiguration,
    personalInterests: mockInterests.slice(0, 9),
    professionalInterests: mockInterests.slice(30, 39),
    interests: [...mockInterests.slice(0, 9), ...mockInterests.slice(30, 39)],
    interestsSocialise: mockInterests.slice(10, 19),
    interestsCoach: mockInterests.slice(40, 49),
    interestsMentor: mockInterests.slice(40, 49), 
    skills: mockInterests.slice(30,39)
  },
  {
    id: 27,
    managerId: 24,
    firstName: 'Phoebe',
    lastName: 'Higounet',
    gender: UserGender.MALE,
    preferredContactMethod: PreferredContactMethod.PHONE,
    roles: [mockUserRoles[0], mockUserRoles[3]],
    imageUrl: '../../assets/img/user-images/male-12.jpeg',
    position: mockRoles[16],
    organisationalUnit: mockOrganisationalUnits[5],
    twitterHandle: 'Phoebe',
    description: 'Pop culture advocate. Lifelong web fan. Passionate thinker. Student. Twitter evangelist.',
    email: 'phoebe@frankli.io',
    homeAddress: mockAddresses[0],
    userState: UserState.FULL,
    passwordReset: false,
    phoneNumber: '0871234567',
    dateOfBirth: new Date(1990, 1, 1),
    birthdayReminder: true,
    startDate: moment().subtract(1, 'year').toDate(),
    officeLocation: mockOfficeLocations[5],
    version: 0,
    managerName: 'None',
    linkedIn: null,
    configuration: mockUserConfiguration,
    personalInterests: mockInterests.slice(0, 9),
    professionalInterests: mockInterests.slice(30, 39),
    interests: [...mockInterests.slice(0, 9), ...mockInterests.slice(30, 39)],
    interestsSocialise: mockInterests.slice(10, 19),
    interestsCoach: mockInterests.slice(40, 49),
    interestsMentor: mockInterests.slice(40, 49),
    skills: mockInterests.slice(30,39)
  },
  {
    id: 28,
    managerId: 22,
    firstName: 'Quinn',
    lastName: 'Lynch',
    gender: UserGender.MALE,
    preferredContactMethod: PreferredContactMethod.IN_PERSON,
    roles: [mockUserRoles[0]],
    imageUrl: '../../assets/img/user-images/male-13.jpeg',
    position: mockRoles[16],
    organisationalUnit: mockOrganisationalUnits[5],
    twitterHandle: 'Quinn',
    description: 'I am the numbers guy! I enjoy problem solving and working on large complex projects. In my spare time, I am an active traveller and photographer. I have a goal to travel to every country by the time I am 40! Watch this space.',
    email: 'quinn@frankli.io',
    homeAddress: mockAddresses[0],
    userState: UserState.FULL,
    passwordReset: false,
    phoneNumber: '0871234567',
    dateOfBirth: new Date(1990, 1, 1),
    birthdayReminder: true,
    startDate: moment().subtract(1, 'year').toDate(),
    officeLocation: mockOfficeLocations[5],
    version: 0,
    managerName: 'None',
    linkedIn: null,
    configuration: mockUserConfiguration,
    personalInterests: mockInterests.slice(0, 9),
    professionalInterests: mockInterests.slice(30, 39),
    interests: [...mockInterests.slice(0, 9), ...mockInterests.slice(30, 39)],
    interestsSocialise: mockInterests.slice(10, 19),
    interestsCoach: mockInterests.slice(40, 49),
    interestsMentor: mockInterests.slice(40, 49), 
    skills: mockInterests.slice(30,39)
  },
  {
    id: 29,
    managerId: 22,
    firstName: 'Woodie',
    lastName: 'Sullivan',
    gender: UserGender.MALE,
    preferredContactMethod: PreferredContactMethod.IN_PERSON,
    roles: [mockUserRoles[0]],
    imageUrl: '../../assets/img/user-images/male-14.jpeg',
    position: mockRoles[16],
    organisationalUnit: mockOrganisationalUnits[5],
    twitterHandle: 'Woodie',
    description: 'I enjoy spending time with my family, watching sport and listening to music.',
    email: 'woodie@frankli.io',
    homeAddress: mockAddresses[0],
    userState: UserState.FULL,
    passwordReset: false,
    phoneNumber: '0871234567',
    dateOfBirth: new Date(1990, 1, 1),
    birthdayReminder: true,
    startDate: moment().subtract(1, 'year').toDate(),
    officeLocation: mockOfficeLocations[5],
    version: 0,
    managerName: 'None',
    linkedIn: null,
    configuration: mockUserConfiguration,
    personalInterests: mockInterests.slice(0, 9),
    professionalInterests: mockInterests.slice(30, 39),
    interests: [...mockInterests.slice(0, 9), ...mockInterests.slice(30, 39)],
    interestsSocialise: mockInterests.slice(10, 19),
    interestsCoach: mockInterests.slice(40, 49),
    interestsMentor: mockInterests.slice(40, 49), 
    skills: mockInterests.slice(30,39)
  },
  {
    id: 30,
    managerId: 28,
    firstName: 'Tyler',
    lastName: 'Skullet',
    gender: UserGender.MALE,
    preferredContactMethod: PreferredContactMethod.IN_PERSON,
    roles: [mockUserRoles[0]],
    imageUrl: '../../assets/img/user-images/male-15.jpeg',
    position: mockRoles[16],
    organisationalUnit: mockOrganisationalUnits[5],
    twitterHandle: 'Tyler',
    description: 'Hey I am Tyler originally from Montreal in Canada, now living in New York! I specialise in SEO and targeted digital marketing. Hit me up for a coffee and a chat.',
    email: 'tyler@frankli.io',
    homeAddress: mockAddresses[0],
    userState: UserState.FULL,
    passwordReset: false,
    phoneNumber: '0871234567',
    dateOfBirth: new Date(1990, 1, 1),
    birthdayReminder: true,
    startDate: moment().subtract(1, 'year').toDate(),
    officeLocation: mockOfficeLocations[5],
    version: 0,
    managerName: 'None',
    linkedIn: null,
    configuration: mockUserConfiguration,
    personalInterests: mockInterests.slice(0, 9),
    professionalInterests: mockInterests.slice(30, 39),
    interests: [...mockInterests.slice(0, 9), ...mockInterests.slice(30, 39)],
    interestsSocialise: mockInterests.slice(10, 19),
    interestsCoach: mockInterests.slice(40, 49),
    interestsMentor: mockInterests.slice(40, 49), 
    skills: mockInterests.slice(30,39)
  }
];