import { Injectable } from '@angular/core';
import { Globals } from '@app/shared/globals/globals';
import { DateUtils } from '@app/shared/utils/date.utils';
import { NotifyUtils } from '@app/shared/utils/notify.utils';
import { Observable } from 'rxjs';
import { ProfileDetailsAPIService } from '../../api/profile-details/profile-details-api.service';
import { SettingsMessages } from '../../locale/settings.messages';
import { ProfileDetailsDto } from '../../model/profile-details.model';

@Injectable()
export class ProfileDetailsBusinessService {

  private updatingProfileDetails: boolean;

  constructor(
    private profileDetailsAPIService: ProfileDetailsAPIService,
    private notifyUtils: NotifyUtils,
    public globals: Globals
  ) {
    this.updatingProfileDetails = false;
  }

  get(): Observable<ProfileDetailsDto> {
    return this.profileDetailsAPIService.get();
  }

  update(userProfileDetails: ProfileDetailsDto): Promise<ProfileDetailsDto> {
    if (this.updatingProfileDetails) { return new Promise((resolve, reject) => reject() ); }
    this.updatingProfileDetails = true;

    userProfileDetails.dateOfBirth = DateUtils.setTimezoneToUTCButKeepDate(userProfileDetails.dateOfBirth);

    return this.profileDetailsAPIService.update(userProfileDetails).toPromise()
      .then(res => {
        this.notifyUtils.notify(SettingsMessages.SUCCESS_PROFILE_UPDATED);
        this.globals.user.description = res.description;
        this.globals.user.gender = res.gender;
        this.globals.user.dateOfBirth = res.dateOfBirth;
        this.globals.user.birthdayReminder = res.birthdayReminder;
        return res;
      })
      .finally(() => {
        this.updatingProfileDetails = false;
      });
  }

}
