<div class="container-tag-display" *ngIf="tag" [ngClass]="'tag-type-'+tag.type" [style.padding]="padding">
  <div class="col-data">
    <div class="p-bottom-5">
      <h5 class="standard-heading">
        <span>{{tag.text}}</span>
      </h5>
      <div class="display-tag-type" *ngIf="showType">
        <span translate>{{eTagMessages.TYPE[tag.type]}}</span>
      </div>
    </div>
    <div class="standard-description" *ngIf="showDescription">
      <!-- <small> -->
        <app-rich-display [text]="tag.description"></app-rich-display>
      <!-- </small> -->
    </div>
  </div>

  <div class="col-actions">
    <app-table-action-icon *ngIf="actionsShown.includes('view')" [icon]="'fa-eye'" [hoverColor]="eIconHoverColor.GREEN" [tooltipText]="eCommonMessages.VIEW" (click)="clickAction('view')"></app-table-action-icon>
    <app-table-action-icon *ngIf="actionsShown.includes('edit')" [icon]="'fa-edit'" [hoverColor]="eIconHoverColor.YELLOW" [tooltipText]="eCommonMessages.EDIT" (click)="clickAction('edit')"></app-table-action-icon>
    <app-table-action-icon *ngIf="actionsShown.includes('copy')" [icon]="'fa-copy'" [hoverColor]="eIconHoverColor.YELLOW" [tooltipText]="eCommonMessages.COPY" (click)="clickAction('copy')"></app-table-action-icon>
    <app-table-action-icon *ngIf="actionsShown.includes('delete')" [icon]="'fa-trash'" [hoverColor]="eIconHoverColor.RED" [tooltipText]="eCommonMessages.DELETE" (click)="clickAction('delete')"></app-table-action-icon>
  </div>
</div>
