import { Injectable } from '@angular/core';
import { MockService } from '@app/mock/api/mock-service';
import { HttpRequest } from '@angular/common/http';
import { CoachApplicationService } from '@app/shared/api/interfaces/connect/coach-application.service';
import { ResubmitCoachApplication } from '@app/models/connect/coach-application/resubmit-coach-application.model';
import { CreateCoachApplication } from '@app/models/connect/coach-application/create-coach-application.model';
import { ManagerDeclineCoachApplication } from '@app/models/connect/coach-application/manager-decline-coach-application.model';
import { CoachApplication } from '@app/models/connect/coach-application/coach-application.model';
import { Observable } from 'rxjs';
import { sanitizeUrl } from '@app/shared/utils/helpers';
import { Globals } from '@app/shared/globals/globals';
import { mockCoachApplications } from '@app/mock/api/data/mockCoachApplications';
import { CoachApplicationStatus } from '@app/models/connect/type/coach-application-status.enum';

@Injectable()
export class CoachApplicationMockService implements MockService, CoachApplicationService {
    constructor(private readonly globals: Globals) { }

    handleRoute(req: HttpRequest<any>): any {
        const url = sanitizeUrl(req.urlWithParams);

        switch (true) {
            case url.endsWith('api/connect/coach-applications/approved'):
                return this.getApprovedCoachApplications();
            case url.endsWith('api/connect/coach-applications/manager'):
                return this.getCoachApplicationsByManagerMe();
            case url.match(/api\/connect\/coach-applications\/\d+$/) !== null:
                const coachApplicationId = +url.split('/')[3];
                return this.getCoachApplicationById(coachApplicationId);
            case url.match(/api\/connect\/coach-applications\/coach\/\d+$/) !== null:
                const coachApplicationCoachId = +url.split('/')[4];
                return this.getCoachApplicationByCoachId(coachApplicationCoachId);
        }
    }

    getApprovedCoachApplications(): Observable<Array<CoachApplication>> | CoachApplication[] {
        return mockCoachApplications.filter(c => c.status === CoachApplicationStatus.APPROVED);
    }

    getCoachApplicationByCoachId(coachId: number): Observable<CoachApplication> | CoachApplication {
        return mockCoachApplications.find(c => c.coachId === coachId) || null;
    }

    getCoachApplicationById(id: number): Observable<CoachApplication> | CoachApplication {
        return mockCoachApplications.find(c => c.id === id);
    }

    getCoachApplicationsByManagerMe(): Observable<Array<CoachApplication>> | CoachApplication[] {
        return mockCoachApplications.filter(c => c.coachManagerId === this.globals.user.id);
    }

    // No Ops listed below
    createCoachApplication(createCoachApplication: CreateCoachApplication): Observable<CoachApplication> {
        return undefined;
    }

    managerApproveCoachApplication(id: number): Observable<CoachApplication> {
        return undefined;
    }

    managerDeclineCoachApplication(id: number, managerDeclineCoachApplication: ManagerDeclineCoachApplication): Observable<CoachApplication> {
        return undefined;
    }

    resubmitCoachApplication(id: number, resubmitCoachApplication: ResubmitCoachApplication): Observable<CoachApplication> {
        return undefined;
    }

}