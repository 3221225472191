import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import { Globals } from '@app/shared/globals/globals';
import {SimpleNumberPipe} from '@app/shared/pipes/simple-number.pipe';
import {GoalUtils} from '@app/shared/utils/goal.utils';

export enum ProgressBarStyle {
  GREEN = 'GREEN',
  YELLOW = 'YELLOW',
  RED = 'RED'
}

@Component({
  selector: 'app-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss'],
  providers: [
    SimpleNumberPipe
  ]
})
export class ProgressBarComponent implements OnInit, OnChanges {

  @Input() startValue: number;
  @Input() currentValue: number;
  @Input() targetValue: number;
  @Input() unitBar: string;
  @Input() showOverflowText: boolean;
  @Input() style: ProgressBarStyle;
  
  @Input() showUnit: boolean;
  @Input() replacePercentWithValue: boolean;
  @Input() showTextOnHover: boolean;
  @Input() unitDetail?: string;
  @Input() showDetailUnitBeforeValue: boolean;
  @Input() unitFormat: string;
  @Input() showBackground: boolean;
  @Input() consistentWidthValue: boolean;

  @Input() previousValue: number; // For showing progress increases/decreases

  get valueHasIncreased(): boolean | null {
    if (this.previousValue === undefined) { return null; }
    return (this.previousValue < this.currentValue);
  }

  get valueDifference(): number {
    if (this.previousValue === undefined) { return 0; }
    const lowestValue = (this.valueHasIncreased ? this.previousValue : this.currentValue);
    const highestValue = (this.valueHasIncreased ? this.currentValue : this.previousValue);
    return (highestValue - lowestValue);
  }

  widthPercentage: number;
  widthPercentageDifference: number;
  overflowPercentage: number;
  overflowTooltip: string;
  valueDifferencePercent: number;

  constructor(
    public globals: Globals,
    private simpleNumberPipe: SimpleNumberPipe
  ) {
    this.startValue = 0;
    this.currentValue = 0;
    this.targetValue = 100;
    this.showTextOnHover = false;
    this.unitBar = '%';
    this.unitDetail = '';
    this.widthPercentage = 0;
    this.widthPercentageDifference = 0;
    this.showDetailUnitBeforeValue = false;
    this.replacePercentWithValue = false;
    this.showUnit = true;
    this.showOverflowText = false;
    this.overflowPercentage = 0;
    this.overflowTooltip = '';
    this.valueDifferencePercent = 0;
    this.unitFormat = '0.0-2';
    this.showBackground = false;
    this.consistentWidthValue = false;
    this.style = ProgressBarStyle.GREEN;
  }

  ngOnInit(): void {
    this.widthPercentage = this.calculateProgressWidthPercent(this.startValue, this.currentValue, this.targetValue);
  }

  ngOnChanges(changes: SimpleChanges): void {
    const startValueChange = changes['startValue'];
    const currentValueChange = changes['currentValue'];
    const targetValueChange = changes['targetValue'];

    if (startValueChange || currentValueChange || targetValueChange) {
      this.startValue = this.simpleNumberPipe.transform(this.startValue.toString());
      this.currentValue = this.simpleNumberPipe.transform(this.currentValue.toString());
      this.targetValue = this.simpleNumberPipe.transform(this.targetValue.toString());
      this.updatePercentages();
    }
  }

  updatePercentages(): void {
    const reversed = (this.targetValue < this.startValue);
    this.widthPercentage = this.calculateProgressWidthPercent(this.startValue, this.currentValue, this.targetValue);
    if (this.previousValue !== undefined) {
      if (this.valueHasIncreased) {
        this.widthPercentageDifference = this.calculateProgressWidthPercent(this.startValue, this.valueDifference, this.currentValue);
      } else {
        this.widthPercentageDifference = this.calculateProgressWidthPercent(this.startValue, this.previousValue, this.valueDifference);
      }
      this.valueDifferencePercent = this.calculateProgressWidthPercent(this.startValue, this.valueDifference, this.targetValue);
    }
    this.overflowPercentage = GoalUtils.getProgressOverflowValue(reversed, this.targetValue, this.currentValue, this.startValue);
    this.overflowTooltip = this.overflowPercentage < 0 ?
      `Current progress is ${(this.overflowPercentage * -1).toFixed(2)}% below the starting value` :
      `Current progress is ${this.overflowPercentage.toFixed(2)}% above the goal value`;
  }

  calculateProgressWidthPercent(start: number, current: number, target: number): number {
    current = current || 0;

    const currentOffset = (start - current);
    const targetOffset = (start - target);

    if (targetOffset === 0) {
      return 0;
    }

    const widthPercent = ((currentOffset / targetOffset) * 100);

    if (widthPercent > 100) {
      return 100;
    }

    if (widthPercent < 0) {
      return 0;
    }

    return widthPercent;
  }
}
