<router-outlet></router-outlet>

<ng-container *ngIf="route.children.length === 0">
  <div class="container-user-profile">
    <app-state [state]="state">
      <ng-container *ngIf="!state.loading && !state.error">
        <ng-container *ngIf="!archived; else stateArchived">
          <ng-container [ngSwitch]="userProfile.userState">
            <ng-container *ngSwitchDefault>
              <div class="action-button-container">
                <ng-container *ngIf="isCurrentUserProfile; else templateTopActionsOtherUserProfile;">
                  <a [routerLink]="['/settings']">
                    <span class="fal fa-fw fa-edit"></span>
                    <span class="m-right-5"></span>
                    <span translate>{{eProfileMessages.EDIT_PROFILE}}</span>
                  </a>
                </ng-container>
                <ng-template #templateTopActionsOtherUserProfile>
                  <ng-container *ngIf="globals.hasFeature(eCompanyFeatures.FEEDBACK_PRAISE)">
                    <app-button [type]="eButtonType.GREEN_INVERTED" (onClick)="startPraise()">
                      <span class="fal fa-bullhorn"></span>
                      <span class="m-right-5"></span>
                      <span>Give Praise</span>
                    </app-button>
                  </ng-container>
                  <ng-container *ngIf="globals.hasFeature(eCompanyFeatures.ONE_TO_ONE) && !hasOneToOne">
                    <app-button [type]="eButtonType.GREEN_INVERTED" (onClick)="createOneToOne()">
                      <span class="fal fa-calendar-plus"></span>
                      <span class="m-right-5"></span>
                      <span>Create 1:1</span>
                    </app-button>
                  </ng-container>
                  <ng-container *ngIf="(globals.hasRole(eRoleName.GOAL_COMPANY) && (isManagingUserProfile  || (secondaryManager && secondaryManager.goals))) && globals.hasFeature(eCompanyFeatures.GOALS)">
                    <app-button [type]="eButtonType.GREEN_INVERTED" (onClick)="userGoalsModal.show()">
                      <span class="fal fa-bullseye"></span>
                      <span class="m-right-5"></span>
                      <span>View Goals</span>
                    </app-button>
                  </ng-container>
                </ng-template>
              </div>

              <div class="area-main-columns">
                <div class="column-left">
                  <app-profile-basic-info [user]="userProfile" [secondaryManager]="secondaryManager" (userChanged)="onUserUpdated($event)"></app-profile-basic-info>
                </div>
                <div class="column-right">
                  <div class="card">
                    <div class="content">
                      <app-profile-skills-competencies [user]="userProfile"></app-profile-skills-competencies>
                    </div>
                  </div>

                  <div class="card">
                    <div class="content">
                      <app-profile-interests [user]="userProfile"></app-profile-interests>
                    </div>
                  </div>

                  <!-- <div class="card">
                    <div class="content">
                      <app-role-display [role]="userProfile.position"></app-role-display>
                    </div>
                  </div> -->

                  <div class="card" *ngIf="globals.hasFeature(eCompanyFeatures.FEEDBACK_PRAISE)">
                    <div class="content">
                      <app-profile-praise #praiseComp [(userProfile)]="userProfile" (clickGivePraise)="startPraise()"></app-profile-praise>
                    </div>
                  </div>
    
                  <div class="card" *ngIf="globals.hasFeature(eCompanyFeatures.GOALS)">
                    <app-profile-goals [(userProfile)]="userProfile"></app-profile-goals>
                  </div>

                  <div class="card" *ngIf="globals.hasFeature(eCompanyFeatures.ONE_TO_ONE) && globals.hasMinimumAccessLevelOf(eRoleName.ADMIN)">
                    <app-profile-one-to-ones [user]="userProfile"></app-profile-one-to-ones>
                  </div>

                  <div class="card" *ngIf="globals.hasFeature(eCompanyFeatures.QUALIFICATIONS)">
                    <app-profile-qualifications [user]="userProfile"></app-profile-qualifications>
                  </div>
  
                  <!-- <div class="card" *ngIf="globals.hasFeature(eCompanyFeatures.CONNECT)">
                    <app-profile-development [(userProfile)]="userProfile"></app-profile-development>
                  </div> -->

                  <div class="card" *ngIf="globals.hasFeature(eCompanyFeatures.ABSORBLMS)">
                    <app-profile-absorblms-certificates [user]="userProfile"></app-profile-absorblms-certificates>
                  </div>
                </div>
              </div>

              <!-- Modals -->
               <!-- TODO: These should move to the praise and goal cards -->
              <app-modal (modalHidden)="cancelPraise(false)" [width]="'90%'" [minWidth]="'100px'" [maxWidth]="'800px'" #praiseModal>
                <div class="praise-modal-inner">
                  <div class="p-bottom-15 text-center">
                    <h1 class="standard-heading">{{titleText}}</h1>
                  </div>
            
                  <ng-container *ngIf="praiseModal.visible">
                    <div class="p-bottom-15">
                      <div class="frankli-form-group frankli-form-group-filled" [class.frankli-form-group-invalid]="praiseSubmitted && praiseForm.controls.praise.invalid">
                        <app-editor-response [formControl]="praiseForm.controls.praise" [enforceCharacterLimit]="false" [columnIndent]="false" [showErrorBorder]="false" [toolbar]="editorToolbar" [height]="200" [maxCharacters]="largeEditorMaxLengthSoft" [minlength]="largeEditorMinLength" [placeholder]="'Please write here (Max ' + largeEditorMaxLengthSoft + ' characters)'"></app-editor-response>
                      </div>
              
                      <div class="frankli-form-group-alert">
                        <ng-container *ngIf="praiseSubmitted">
                          <div *ngIf="praiseForm.controls.praise.hasError('required')">Please provide a little detail here</div>
                          <div *ngIf="praiseForm.controls.praise.hasError('softmin')">Your submission must be at least {{largeEditorMinLength}} characters</div>
                        </ng-container>
                        <div *ngIf="praiseForm.controls.praise.hasError('softmax')">Your submission must be {{largeEditorMaxLengthSoft}} characters or fewer</div>
                        <div *ngIf="praiseForm.controls.praise.hasError('maxlength')">Too much formatting</div>
                      </div>
                    </div>
            
                    <div class="p-bottom-15">
                      <div class="p-bottom-10 text-center">
                        <h5 class="standard-heading">Which company values did they demonstrate?</h5>
                      </div>
                      <div class="area-praise-values">
                        <ng-container *ngIf="availableValues.length > 0; else templateNoPraiseValues;">
                          <div class="area-praise-values-list">
                            <ng-container *ngFor="let value of availableValues">
                              <span class="praise-value-item" [class.praise-value-item-selected]="value.selected"  (click)="toggleValue(value)">{{value.text}}</span>
                            </ng-container>
                          </div>
                        </ng-container>
                        <ng-template #templateNoPraiseValues>
                          <p>No values found</p>
                        </ng-template>
                      </div>
                    </div>
            
                    <div class="area-praise-buttons p-top-15">
                      <app-button [type]="eButtonType.GRAY_INVERTED" (onClick)="cancelPraise()">Cancel</app-button>
                      <app-button [type]="eButtonType.GREEN" (onClick)="confirmPraise()">Submit</app-button>
                    </div>
                  </ng-container>
                </div>
              </app-modal>
            
              <app-user-goals-modal #userGoalsModal [owner]="userProfile"></app-user-goals-modal>
            </ng-container>

            <ng-container *ngSwitchCase="eUserState.PENDING">
              <app-empty-state [height]="'300px'" [imageSrc]="'assets/img/UserNotSignedUp.svg'" [message]="(userProfile ? userProfile.firstName + ' ' + userProfile.lastName : 'Person') + ' has not been invited to Frankli yet'"></app-empty-state>
            </ng-container>

            <ng-container *ngSwitchCase="eUserState.INVITED">
              <app-empty-state [height]="'300px'" [imageSrc]="'assets/img/UserNotSignedUp.svg'" [message]="(userProfile ? userProfile.firstName + ' ' + userProfile.lastName : 'Person') + ' has not completed sign up yet'"></app-empty-state>
            </ng-container>
          </ng-container>
        </ng-container>
    
        <ng-template #stateArchived>
          <app-empty-state [height]="'300px'" [imageSrc]="'assets/img/UserNotSignedUp.svg'" [message]="(userProfile ? userProfile.firstName + ' ' + userProfile.lastName : 'Person') + ' has been archived'"></app-empty-state>
        </ng-template>
      </ng-container>
    </app-state>
  </div>
</ng-container>
