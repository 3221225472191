<div class="container-circles">
  <div class="circle c-green"></div>
  <div class="circle c-red"></div>
  <div class="circle c-orange"></div>
  <div class="c-blue"></div>
</div>
<div class="container-info">
  <div class="m-top-20">Your session has expired</div>
  <br />
  <button (click)="navigate()" class="btn-frankli-round btn-frankli-green">Go to Login</button>
</div>