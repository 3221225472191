import { Component, OnInit, ViewChild } from '@angular/core';
import { IState } from '@app/models/state/state.model';
import { RouteGuardUnsavedChanges } from '@app/shared/utils/unsaved-changes.model';
import { QualificationSelectComponent } from '../../component/qualification-select/qualification-select.component';
import { QualificationMessages } from '../../locale/qualification.messages';

@Component({
  selector: 'app-qualifications',
  templateUrl: './qualifications.component.html',
  styleUrls: ['./qualifications.component.scss']
})
export class QualificationsComponent implements OnInit, RouteGuardUnsavedChanges {
  
  public readonly eQualificationMessages = QualificationMessages;

  @ViewChild('qualificationSelectComponent') qualificationSelectComponent: QualificationSelectComponent;
  
  state: IState;

  constructor() {
    this.state = {
      loading: true,
      error: false,
      errorMessage: ''
    };
  }

  ngOnInit(): void {
    this.state.loading = false;
  }

  unsavedChanges(): boolean {
    if (!this.qualificationSelectComponent) { return false; }
    // return this.qualificationSelectComponent.unsavedChanges();
    return false;
  }
}
