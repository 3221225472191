import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonMessages } from '@app/constants/common.messages';
import { IState } from '@app/models/state/state.model';
import { FilterMethod, FilterOption } from '@app/models/universal-filter-option.model';
import { ButtonType } from '@app/shared/components/inputs/button/button.component';
import { Globals } from '@app/shared/globals/globals';
import { ModalComponent } from '@app/shared/modal/modal.component';
import { PaginationNewComponent } from '@app/shared/pagination/pagination-new/pagination-new.component';
import { DefaultFilter, Categories } from '@app/shared/universal-filter/universal-filter.component';
import { SwalUtils } from '@app/shared/utils/swal.utils';
import { Subject } from 'rxjs';
import Swal, { SweetAlertOptions, SweetAlertResult } from 'sweetalert2';
import { QualificationMessages } from '../../locale/qualification.messages';
import { CreateQualificationDto } from '../../model/create-qualification.model';
import { Qualification } from '../../model/qualification.model';
import { QualificationBusinessService } from '../../service/qualification-business.service';
import { initQualificationForm, QualificationCreateComponent } from '../qualification-create/qualification-create.component';

interface PageState extends IState {
  submitted: boolean;
  submitting: boolean;
}

interface UniversalFilterData {
  searchControl: FormControl;
  filterOptions: FilterOption[];
  searchProps: string[];
  defaultFilters: DefaultFilter;
  lastFilterResult: number[];
  currentCategories: Categories;
}

@Component({
  selector: 'app-qualification-select',
  templateUrl: './qualification-select.component.html',
  styleUrls: ['./qualification-select.component.scss']
})
export class QualificationSelectComponent implements OnInit {
  private readonly ngUnsubscribe$: Subject<void> = new Subject<void>();

  public readonly eQualificationMessages = QualificationMessages;
  public readonly eCommonMessages = CommonMessages;
  public readonly eButtonType = ButtonType;

  @ViewChild('pagination') pagination!: PaginationNewComponent;
  @ViewChild('modalCreate') modalCreate?: ModalComponent;
  @ViewChild('templateCreateComponent') templateCreateComponent?: QualificationCreateComponent;

  qualifications: Qualification[];
  qualificationsFiltered: Qualification[];
  qualificationsDisplay: Qualification[];

  formCreate: FormGroup;

  state: PageState;

  universalFilterData: UniversalFilterData;
  confirmCloseSettings: SweetAlertOptions;

  constructor(
    public globals: Globals,
    private swalUtils: SwalUtils,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private qualificationBusinessService: QualificationBusinessService
  ) {
    this.qualifications = [];
    this.qualificationsFiltered = [];
    this.qualificationsDisplay = [];

    this.state = {
      loading: true,
      error: false,
      errorMessage: '',
      submitted: false,
      submitting: false
    };

    this.universalFilterData = {
      searchControl: new FormControl('', []),
      filterOptions: [],
      searchProps: [],
      lastFilterResult: [],
      defaultFilters: {},
      currentCategories: {}
    };

    this.confirmCloseSettings = {
      title: CommonMessages.UNSAVED_CHANGES,
      text: CommonMessages.UNSAVED_CHANGES_WARNING,
      confirmButtonText: CommonMessages.STAY,
      cancelButtonText: CommonMessages.LEAVE
    };

    this.formCreate = initQualificationForm();
  }

  ngOnInit(): void {
    this.getData();
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.unsubscribe();
  }

  getData() {
    if (!this.globals) { return false; }
    if (!this.globals.user) { return false; }
    if (!this.globals.user.id) { return false; }
    this.qualificationBusinessService.get(null, null, [this.globals.user.id]).subscribe(qualifications => {
      this.populateData(qualifications);
      this.state.loading = false;
      this.initDefaultAction();
    });
  }

  initDefaultAction(): void {
    const action = this.activatedRoute.snapshot.queryParams['action'];
    switch (action) {
      case 'create':
        this.router.navigate(['.'], {relativeTo: this.activatedRoute}).then(() => {
          setTimeout(() => this.onClickAddQualification(), 1);
        });
        break;
    }
  }

  populateData(data?: Qualification[]): void {
    if (!data) { data = this.qualifications; }
    this.qualifications = data;
    this.qualificationsFiltered = data;

    this.universalFilterData.searchProps = [QualificationMessages.NAME];
    this.universalFilterData.filterOptions = data.map(i => this.getFilterOptionsForQualification(i));
  }

  getFilterOptionsForQualification(qualification: Qualification): FilterOption {
    return {
      id: qualification.id,
      properties: {
        [QualificationMessages.NAME]: {
          value: qualification.name,
          filterMethod: FilterMethod.OR
        }
      }
    };
  }

  filterCategoriesChanged(categories: Categories) {
    this.universalFilterData.currentCategories = categories;
  }

  filterEmit(ids: number[], doRefresh = true): void {
    this.universalFilterData.lastFilterResult = ids;
    this.qualificationsFiltered = this.qualifications
      .filter(p => ids.includes(p.id));

    if (doRefresh) {
      this.refreshPagination();
    }
  }

  refreshPagination(): void {
    if (this.pagination) {
      this.pagination.update();
    }
  }

  onClickAddQualification(): void {
    this.formCreate = initQualificationForm();
    this.showCreateModal();
  }


  showCreateModal(): void {
    if (this.modalCreate) {
      this.modalCreate.show();
    }
  }

  hideCreateModal(): void {
    if (this.modalCreate) {
      this.modalCreate.hide();
    }
  }

  onCloseCreateResponse(result: SweetAlertResult): void {
    if (result.isDismissed && result.dismiss && (result.dismiss === Swal.DismissReason.cancel)) {
      this.hideCreateModal();
    }
  }

  onModalHidden(): void {
    this.state.submitted = false;
    this.formCreate = initQualificationForm();
  }

  cancelCreate(): void {
    this.hideCreateModal();
  }

  onSubmit(): void {
    this.state.submitted = true;

    if (this.state.submitting) { return; } // Cancel submission if already processing
    if (this.formCreate.invalid) { return; } // Cancel if form is invalid

    this.state.submitting = true;

    const createDto = this.formCreate.value as CreateQualificationDto;

    const id = this.formCreate.controls.id.value;
    if (id) {
      this.submitEdit(id, createDto);
    } else {
      this.submitCreate(createDto);
    }
  }

  submitEdit(id: number, createDto: CreateQualificationDto): void {
    this.qualificationBusinessService.update(id, createDto)
      .then(() => {
        this.resetFormAndCloseModal();
        this.getData();
      })
      .finally(() => {
        this.state.submitting = false;
      });
  }

  submitCreate(createDto: CreateQualificationDto): void {
    this.qualificationBusinessService.create(createDto)
      .then(() => {
        this.resetFormAndCloseModal();
        this.getData();
      })
      .finally(() => {
        this.state.submitting = false;
      });
  }

  resetFormAndCloseModal() {
    this.formCreate = initQualificationForm();
    this.state.submitting = false;
    this.state.submitted = false;
    this.hideCreateModal();
  }

  onClickItemAction(event: string, qualification: Qualification): void {
    switch(event) {
      case 'edit':
        this.startEdit(qualification);
        break;
      case 'delete':
        this.startDelete(qualification);
        break;
    }
  }

  startEdit(qualification: Qualification): void {
    const qualificationCopy = JSON.parse(JSON.stringify(qualification));
    this.formCreate = initQualificationForm(qualificationCopy);
    this.showCreateModal();
  }

  startDelete(qualification: Qualification): void {
    this.swalUtils.displayWarningConfirmationSwal({
      text: QualificationMessages.CONFIRMATION_DELETE,
      confirmButtonText: CommonMessages.DELETE
    }).then((result) => {
      if (result.value) {
        this.qualificationBusinessService.delete(qualification.id).then(() => this.getData());
      } else {
        this.state.submitting = false;
      }
    }).catch(() => {
      this.state.submitting = false;
    });
  }
}
