import { Params } from '@angular/router';

export class Breadcrumb {
  route: string;
  label: string;
  queryParams: Params;

  constructor(route: string, label: string, queryParams: Params) {
    this.route = route;
    this.label = label;
    this.queryParams = queryParams;
  }
}
