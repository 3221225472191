import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { IState } from '@app/models/state/state.model';
import { PaginationNewComponent } from '@app/shared/pagination/pagination-new/pagination-new.component';
import { OneToOneScheduleDetailsView } from '@app/domain/one_to_one/model/one-to-one-schedule.model';
import { OneToOneBusinessService } from '@app/domain/one_to_one/service/one-to-one-business.service';

type TabEntity = OneToOneScheduleDetailsView;

@Component({
  selector: 'app-information-sidebar-one-to-ones',
  templateUrl: './information-sidebar-one-to-ones.component.html',
  styleUrls: ['./information-sidebar-one-to-ones.component.scss']
})
export class InformationSidebarOneToOnesComponent implements OnInit {
  
  @ViewChild('pagination') pagination?: PaginationNewComponent;

  @Input() userIds: number[];

  state: IState;
  data: {
    master: TabEntity[];
    filtered: TabEntity[];
    display: TabEntity[];
  };

  constructor(
    private oneToOneBusinessService: OneToOneBusinessService
  ) {
    this.userIds = [];

    this.state = {
      loading: true,
      error: false,
      errorMessage: ''
    };

    this.data = {
      master: [],
      filtered: [],
      display: []
    };
  }

  ngOnInit(): void {
    this.getData();
  }
  
  getData(): void {
    if (!this.userIds) { return; }
    if (this.userIds.length === 0) { return; }

    this.oneToOneBusinessService.getSidebarOneToOneSchedules(this.userIds)
      .subscribe(data => {
        data = this.sortData(data);
        this.populateData(data);
        this.state.loading = false;
      });
  }

  sortData(data: TabEntity[]): TabEntity[] {
    // Order by name
    return data.sort((a, b) => {
      const sortStringA = `${a.purposeTitle}`;
      const sortStringB = `${b.purposeTitle}`;
      return sortStringA.localeCompare(sortStringB);
    });
  }

  populateData(data?: TabEntity[]): void {
    if (!data) { data = this.data.master; }
    this.data.master = data;
    this.data.filtered = data;

    // this.universalFilterData.searchProps = ['name'];
    // this.universalFilterData.filterOptions = data.map(i => this.getFilterOptionsForBrowseRole(i));

    this.refreshPagination();
  }

  refreshPagination(): void {
    if (this.pagination) {
      this.pagination.update();
    }
  }
}
