import { Component, OnDestroy, OnInit } from '@angular/core';
import { Globals } from '../globals/globals';
import { Router } from '@angular/router';
import { RoleName } from '@app/models/user-role.model';
import { CompanyFeatures } from '@app/models/company-features.model';
import { SidebarService } from 'app/shared/sidebar/sidebar.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { clone } from '@app/shared/utils/helpers';
import { TitleCasePipe } from '@angular/common';
import { RouteInfo } from '@app/models/routes/route-info.model';
import { SidebarMessages } from './sidebar.messages';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '@env/environment';
import { SettingsMessages } from '@app/domain/settings/locale/settings.messages';
import { NavbarMessages } from '../navbar/navbar.messages';
import { CareerMessages } from '@app/domain/career/locale/career.messages';
import { TerminologyEntity } from '@app/domain/terminology/model/terminology-entity.enum';

@Component({
  selector: 'app-sidebar-component',
  templateUrl: 'sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class EmployeeSidebarComponent implements OnInit, OnDestroy {

  public readonly eTerminologyEntity = TerminologyEntity;
  public readonly eSettingsMessages = SettingsMessages;
  public readonly eNavbarMessages = NavbarMessages;
  public readonly eFeatures = CompanyFeatures;

  GENERAL_ROUTES: RouteInfo[] = [
    {
      path: 'help',
      title: this.translateService.instant(SidebarMessages.HELP),
      iconType: 'fal fa-fw fa-question-circle',
      feature: CompanyFeatures.HELP,
      children: [
        {
          path: 'help/videos',
          title: this.translateService.instant(SidebarMessages.TRAINING_VIDEOS),
          iconType: 'fal fa-fw fa-video',
          feature: null,
          children: null,
          locked: false
        },
        {
          path: 'help/articles',
          title: this.translateService.instant(SidebarMessages.HELP_CENTER),
          iconType: 'fal fa-fw fa-book-open',
          feature: null,
          children: null,
          locked: false
        }
      ],
      locked: false
    }
  ];

  EMPLOYEE_ROUTES: RouteInfo[] = [
    {
      path: '/dashboard',
      title: this.translateService.instant(SidebarMessages.DASHBOARD),
      iconType: 'fal fa-fw fa-chart-pie',
      feature: null,
      children: null,
      locked: false,
      hideOnMobile: false
    },
    {
      path: '',
      title: this.translateService.instant(SidebarMessages.PEOPLE),
      iconType: 'fal fa-fw fa-user-friends',
      feature: null,
      locked: false,
      hideOnMobile: false,
      children: [
        {
          path: 'people-directory',
          title: this.translateService.instant(SidebarMessages.PEOPLE_DIRECTORY),
          iconType: 'fal fa-fw fa-id-card',
          feature: CompanyFeatures.PEOPLE_DIRECTORY,
          children: null,
          locked: false,
          hideOnMobile: false
        }, {
          path: 'org-chart',
          title: this.translateService.instant(SidebarMessages.ORG_CHART),
          iconType: 'fal fa-fw fa-network-wired',
          feature: CompanyFeatures.ORG_CHART,
          children: null,
          locked: false,
          hideOnMobile: true
        },
      ],
    },
    {
      path: '/feedback/',
      title: this.translateService.instant(SidebarMessages.FEEDBACK),
      iconType: 'fal fa-fw fa-bullhorn',
      feature: CompanyFeatures.FEEDBACK,
      children: null,
      locked: false,
      hideOnMobile: false
    },
    {
      path: '/goals/',
      title: this.translateService.instant(SidebarMessages.GOALS),
      iconType: 'fal fa-fw fa-bullseye',
      feature: CompanyFeatures.GOALS,
      children: null,
      locked: false,
      hideOnMobile: false
    },
    {
      path: '/tasks/',
      title: this.translateService.instant(SidebarMessages.TASKS),
      iconType: 'fal fa-fw fa-tasks',
      feature: CompanyFeatures.TODOS,
      children: null,
      locked: false,
      hideOnMobile: false
    },
    {
      path: '/surveys',
      title: this.translateService.instant(SidebarMessages.SURVEYS_AND_POLLS),
      iconType: 'fal fa-fw fa-poll',
      feature: CompanyFeatures.SURVEYS,
      children: null,
      locked: false,
      hideOnMobile: false
    },
    {
      path: '/one-to-one',
      title: this.translateService.instant(SidebarMessages.ONE_TO_ONE_MEETINGS),
      iconType: 'fal fa-fw fa-calendar-day',
      feature: CompanyFeatures.ONE_TO_ONE,
      children: null,
      locked: false,
      hideOnMobile: false,
      isBeta: false
    },
    {
      path: '/connect/',
      title: this.translateService.instant(SidebarMessages.CONNECT),
      iconType: 'fal fa-fw fa-infinity',
      feature: CompanyFeatures.CONNECT,
      children: null,
      locked: false,
      hideOnMobile: false
    },
    {
      path: '/evaluations/',
      title: this.translateService.instant(SidebarMessages.MY_REVIEWS),
      iconType: 'fal fa-fw fa-clipboard-user',
      feature: CompanyFeatures.EVALUATION_CYCLES,
      children: null,
      locked: false,
      hideOnMobile: false
    },
    {
      path: '/career',
      title: this.translateService.instant(CareerMessages.CAREERS),
      iconType: 'fal fa-fw fa-list-timeline',
      feature: CompanyFeatures.CAREERS,
      children: null,
      locked: false,
      hideOnMobile: false
    },
  ];

  ADMIN_ROUTES: RouteInfo[] = [
    {
      path: '/admin/dashboard',
      title: this.translateService.instant(SidebarMessages.ADMIN),
      iconType: 'fal fa-fw fa-sliders-v',
      feature: null,
      children: null,
      locked: false,
      hideOnMobile: true
    }
  ];

  FRANKLI_ADMIN_ROUTES: RouteInfo[] = [
    {
      path: '/frankli-admin',
      title: this.translateService.instant(SidebarMessages.FRANKLI_ADMIN),
      iconType: 'fal fa-fw fa-cog',
      feature: null,
      children: null,
      locked: false,
      hideOnMobile: true
    }
  ];

  employeeRoutes: Array<RouteInfo> = this.EMPLOYEE_ROUTES;
  managerRoutes: Array<RouteInfo> = [];
  adminRoutes: Array<RouteInfo> = this.ADMIN_ROUTES;
  frankliAdminRoutes: Array<RouteInfo> = this.FRANKLI_ADMIN_ROUTES;
  generalRoutes: Array<RouteInfo> = this.GENERAL_ROUTES;
  employeeMenuItems: Array<RouteInfo> = [];
  managerMenuItems: Array<RouteInfo> = [];
  adminMenuItems: Array<RouteInfo> = [];
  generalMenuItems: Array<RouteInfo> = [];
  frankliAdminMenuItems: Array<RouteInfo> = [];
  eRoleName = RoleName;
  eCompanyFeatures = CompanyFeatures;
  destroy$: Subject<boolean> = new Subject<boolean>();
  searchValue: string;
  hasAccess: {
    manager: boolean;
    secondaryManager: boolean;
    admin: boolean;
    frankliAdmin: boolean;
  };

  isMock = environment.mock.enabled;

  get setupComplete(): boolean {
    if (!this.globals.company) {
      return false;
    }

    return this.globals.company.setUpComplete;
  }

  constructor(
    public globals: Globals,
    private router: Router,
    public sidebarService: SidebarService,
    private titleCasePipe: TitleCasePipe,
    private translateService: TranslateService
  ) {
    this.searchValue = '';
    this.hasAccess = {
      manager: false,
      secondaryManager: false,
      admin: false,
      frankliAdmin: false
    };

    this.sidebarService.getUpdate().pipe(takeUntil(this.destroy$)).subscribe(() => this.filter());
  }
  setupTeamRoutes() {
    if (this.checkIfManager()) {
      this.setupManagerRoutes();
    } else if (this.checkIfSecondaryManager()) {
      this.setupSecondaryManagerRoutes();

    }
  }

  setupSecondaryManagerRoutes() {

    const children = [];

    children.push(
      {
        path: '/manage/people',
        title: this.translateService.instant(SidebarMessages.TEAM_VIEW, { value: this.titleCasePipe.transform(this.globals.getTerminology(TerminologyEntity.TEAM)) }),
        iconType: 'fal fa-fw fa-eye',
        feature: null,
        locked: false,
        children: null
      }
    );

    if (this.globals.secondaryManagerAny.feedback) {
      children.push(
        {
          path: '/manage/inbound-feedback',
          title: this.translateService.instant(SidebarMessages.TEAM_FEEDBACK, {value: this.titleCasePipe.transform(this.globals.getTerminology(TerminologyEntity.TEAM))}),
          iconType: 'fal fa-fw fa-comments-alt',
          feature: CompanyFeatures.FEEDBACK,
          children: null,
          locked: false
        }
      );
    }

    if (this.globals.secondaryManagerAny.goals) {
      children.push(
        {
          path: '/manage/goals',
          title: this.translateService.instant(SidebarMessages.TEAM_GOALS, {value: this.titleCasePipe.transform(this.globals.getTerminology(TerminologyEntity.TEAM))}),
          iconType: 'fal fa-fw fa-bullseye',
          feature: CompanyFeatures.GOALS,
          locked: false,
          children: null
        }
      );
    }

    if (this.globals.secondaryManagerAny.reviews) {
      children.push(
        {
          path: '/manage/evaluations',
          title: this.translateService.instant(SidebarMessages.TEAM_REVIEWS, {value: this.titleCasePipe.transform(this.globals.getTerminology(TerminologyEntity.TEAM))}),
          iconType: 'fal fa-fw fa-circle-notch',
          feature: CompanyFeatures.EVALUATION_CYCLES,
          children: null,
          locked: false,
        }
      );
    }


    this.managerRoutes = [
      {
        path: '',
        title: this.titleCasePipe.transform(this.globals.getTerminology(TerminologyEntity.TEAM)),
        iconType: 'fal fa-fw fa-screen-users',
        feature: null,
        locked: false,
        hideOnMobile: true,
        children: children
      }
    ];
  }

  // TODO: Don't replace entire const declared above
  setupManagerRoutes() {
    this.managerRoutes = [
      {
        path: '',
        title: this.titleCasePipe.transform(this.globals.getTerminology(TerminologyEntity.TEAM)),
        iconType: 'fal fa-fw fa-screen-users',
        feature: null,
        locked: false,
        hideOnMobile: true,
        children: [
          {
            path: '/manage/inbound-feedback',
            title: this.translateService.instant(SidebarMessages.TEAM_FEEDBACK, { value: this.titleCasePipe.transform(this.globals.getTerminology(TerminologyEntity.TEAM)) }),
            iconType: 'fal fa-fw fa-comments-alt',
            feature: CompanyFeatures.FEEDBACK,
            children: null,
            locked: false
          },
          {
            path: '/manage/people',
            title: this.translateService.instant(SidebarMessages.TEAM_VIEW, { value: this.titleCasePipe.transform(this.globals.getTerminology(TerminologyEntity.TEAM)) }),
            iconType: 'fal fa-fw fa-eye',
            feature: null,
            locked: false,
            children: null
          },
          {
            path: '/manage/goals',
            title: this.translateService.instant(SidebarMessages.TEAM_GOALS, { value: this.titleCasePipe.transform(this.globals.getTerminology(TerminologyEntity.TEAM)) }),
            iconType: 'fal fa-fw fa-bullseye',
            feature: CompanyFeatures.GOALS,
            locked: false,
            children: null
          },
          {
            path: '/manage/tasks',
            title: this.translateService.instant(SidebarMessages.TEAM_TASKS, { value: this.titleCasePipe.transform(this.globals.getTerminology(TerminologyEntity.TEAM)) }),
            iconType: 'fal fa-fw fa-tasks',
            feature: CompanyFeatures.TODOS,
            locked: false,
            children: null
          },
          {
            path: '/manage/meetings',
            title: this.translateService.instant(SidebarMessages.TEAM_ONE_TO_ONES, { value: this.titleCasePipe.transform(this.globals.getTerminology(TerminologyEntity.TEAM)) }),
            iconType: 'fal fa-fw fa-calendar',
            feature: CompanyFeatures.ONE_TO_ONE,
            locked: false,
            children: null
          },
          // { path: '/manage/analytics', title: 'Analytics', iconType: 'fal fa-fw fa-chart-bar', feature: CompanyFeatures.ANALYTICS, locked: false, children: null },
          {
            path: '/manage/evaluations',
            title: this.translateService.instant(SidebarMessages.TEAM_REVIEWS, { value: this.titleCasePipe.transform(this.globals.getTerminology(TerminologyEntity.TEAM)) }),
            iconType: 'fal fa-fw fa-circle-notch',
            feature: CompanyFeatures.EVALUATION_CYCLES,
            children: null,
            locked: false,
          },
        ],
      },
    ];
  }

  ngOnInit() {
    this.hasAccess = {
      manager: this.checkIfManager(),
      secondaryManager: this.checkIfSecondaryManager(),
      admin: this.checkIfAdmin(),
      frankliAdmin: this.checkIfFrankliAdmin()
    };

    this.filter();
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  navigateToProfile() {
    this.router.navigate(['/profile']).then();
  }

  logout(): void {
    this.router.navigateByUrl('/logout').then();
  }

  checkIfManager(): boolean {
    if (this.globals.user && this.globals.user.roles) {
      return this.globals.user.roles.some(r =>
        r.name === RoleName.MANAGER ||
        r.name === RoleName.ADMIN ||
        r.name === RoleName.FRANKLI_ADMIN
      );
    } else {
      return false;
    }
  }

  checkIfSecondaryManager(): boolean{
    if (this.globals.user && this.globals.user.roles) {
      return this.globals.hasFeature(CompanyFeatures.SECONDARY_MANAGER) &&
        this.globals.user.roles.some(r =>
          r.name === RoleName.SECONDARY_MANAGER
        );
    } else {
      return false;
    }
  }

  checkIfAdmin(): boolean {
    if (this.globals.user && this.globals.user.roles) {
      return this.globals.user.roles.some(r => r.name === RoleName.ADMIN || r.name === RoleName.FRANKLI_ADMIN);
    } else {
      return false;
    }
  }

  checkIfFrankliAdmin(): boolean {
    if (this.globals.user && this.globals.user.roles) {
      return this.globals.user.roles.some(r => r.name === RoleName.FRANKLI_ADMIN);
    } else {
      return false;
    }
  }

  filter() {
    this.setupTeamRoutes();

    const routeFilter = (route: RouteInfo): (boolean | undefined) => {

      if (!route.children) {
        if (!route.feature) {
          return true;
        }

        if (this.globals.hasFeature(route.feature)) {
          return true;
        }

        if (!this.globals.hasFeature(route.feature) && this.globals.company && this.globals.company.featureLocked) {
          route.locked = true;
          return true;
        }
      } else {
        // Assign new children as a result of a recursive call to the filter
        return (route.children = route.children.filter(routeFilter)).length > 0;
      }
    };

    // We clone the routes so that they remain immutable
    this.employeeMenuItems = this.employeeRoutes.map(clone).filter(routeFilter);
    this.managerMenuItems = this.managerRoutes.map(clone).filter(routeFilter);

    this.adminMenuItems = this.adminRoutes.map(clone).filter(routeFilter);
    this.frankliAdminMenuItems = this.frankliAdminRoutes.map(clone).filter(routeFilter);
    this.generalMenuItems = this.generalRoutes.map(clone).filter(routeFilter);
  }

  navigateToRoute(route: string) {
    this.router.navigateByUrl(route).then();
  }

  checkEnter(event: KeyboardEvent) {
    if (event.key === 'Enter' && this.searchValue !== '' && this.searchValue) {
      const sarg = this.searchValue;
      this.searchValue = '';
      this.router.navigate(['/search-results'], { queryParams: { q: sarg } }).then();
    }
  }


  sidebarToggle() {
    this.sidebarService.toggleSidebar();
  }
}
