<div class="activity-item-container" *ngIf="activity">
  <div class="row m-left-0 m-right-0">
    <div class="col-data">
      <div class="col-activity">
        <div class="row m-left-0 m-right-0">
          <div class="col-activity-owner">
            <span class="owner-img" [routerLink]="['/profile/', activity.user.id]">
              <app-profile-photo [src]="activity.user.imageUrl" [firstName]="activity.user.firstName" [lastName]="activity.user.lastName"></app-profile-photo>
            </span>
          </div>
  
          <div class="col-activity-details">
            <div class="activity-intro">
              <ng-container [ngSwitch]="activity.type">
                <span *ngSwitchCase="eGoalActivityType.ARCHIVE" [innerHTML]="eGoalsMessages.ACTIVITY_MESSAGE_ARCHIVED_GOAL | translate: {user: (activity.user.firstName + ' ' + activity.user.lastName)}"></span>
                <span *ngSwitchCase="eGoalActivityType.UNARCHIVE" [innerHTML]="eGoalsMessages.ACTIVITY_MESSAGE_UNARCHIVED_GOAL | translate: {user: (activity.user.firstName + ' ' + activity.user.lastName)}"></span>
                <span *ngSwitchCase="eGoalActivityType.COMPLETE" [innerHTML]="eGoalsMessages.ACTIVITY_MESSAGE_COMPLETED_GOAL | translate: {user: (activity.user.firstName + ' ' + activity.user.lastName)}"></span>
                <span *ngSwitchCase="eGoalActivityType.INCOMPLETE" [innerHTML]="eGoalsMessages.ACTIVITY_MESSAGE_UNCOMPLETED_GOAL | translate: {user: (activity.user.firstName + ' ' + activity.user.lastName)}"></span>
                <span *ngSwitchCase="eGoalActivityType.CREATE" [innerHTML]="eGoalsMessages.ACTIVITY_MESSAGE_CREATED_GOAL | translate: {user: (activity.user.firstName + ' ' + activity.user.lastName)}"></span>
                <span *ngSwitchCase="eGoalActivityType.EDIT" [innerHTML]="eGoalsMessages.ACTIVITY_MESSAGE_EDITED_GOAL | translate: {user: (activity.user.firstName + ' ' + activity.user.lastName)}"></span>
                <span *ngSwitchCase="eGoalActivityType.COMMENT" [innerHTML]="eGoalsMessages.ACTIVITY_MESSAGE_COMMENTED_ON_GOAL | translate: {user: (activity.user.firstName + ' ' + activity.user.lastName)}"></span>
                <span *ngSwitchCase="eGoalActivityType.KEY_RESULT" [innerHTML]="eGoalsMessages.ACTIVITY_MESSAGE_PROGRESSED_KEY_RESULTS | translate: {user: (activity.user.firstName + ' ' + activity.user.lastName)}"></span>
                <span *ngSwitchCase="eGoalActivityType.PROGRESS_UPDATE" [innerHTML]="eGoalsMessages.ACTIVITY_MESSAGE_PROGRESS_UPDATE | translate: {user: (activity.user.firstName + ' ' + activity.user.lastName)}"></span>
                <span *ngSwitchCase="eGoalActivityType.STATUS_UPDATE" [innerHTML]="eGoalsMessages.ACTIVITY_MESSAGE_STATUS_UPDATE | translate: {user: (activity.user.firstName + ' ' + activity.user.lastName)}"></span>
              </ng-container>
            </div>

            <div class="activity-detail-container">
              <ng-container [ngSwitch]="activity.type">
                <div *ngSwitchCase="eGoalActivityType.EDIT">
                  <ng-container [ngTemplateOutlet]="templateEditUpdate"></ng-container>
                </div>
                <div *ngSwitchCase="eGoalActivityType.COMMENT">
                  <ng-container [ngTemplateOutlet]="templateMessageUpdate"></ng-container>
                </div>
                <div *ngSwitchCase="eGoalActivityType.KEY_RESULT">
                  <ng-container [ngTemplateOutlet]="templateMessageUpdate"></ng-container>
                </div>
                <div *ngSwitchCase="eGoalActivityType.PROGRESS_UPDATE">
                  <ng-container [ngTemplateOutlet]="templateStatusUpdate"></ng-container>
                </div>
                <div *ngSwitchCase="eGoalActivityType.STATUS_UPDATE">
                  <ng-container [ngTemplateOutlet]="templateStatusUpdate"></ng-container>
                </div>
              </ng-container>

              <ng-template #templateMessageUpdate>
                <div class="p-top-10">
                  <div class="activity-comment">
                    <app-rich-display [text]="activity.message"></app-rich-display>
                  </div>
                </div>
              </ng-template>

              <ng-template #templateStatusUpdate>
                <div class="p-top-10">
                  <!-- Progress updates -->
                  <ng-container *ngIf="activity.progressUpdate">
                    <!-- Status -->
                    <div class="status-update-status" *ngIf="activity.progressUpdate.status !== null">
                      <div class="update-label" translate>
                        <span class="fal fa-fw fa-flag"></span>
                        <span translate>{{eGoalsMessages.STATUS}}</span>
                      </div>
                      <div>
                        <ng-container [ngTemplateOutlet]="templateStatusDisplay" [ngTemplateOutletContext]="{ status: activity.progressUpdate.status.previous }"></ng-container>
                        <span class="fal fa-arrow-right"></span>
                        <ng-container [ngTemplateOutlet]="templateStatusDisplay" [ngTemplateOutletContext]="{ status: activity.progressUpdate.status.current }"></ng-container>
                      </div>
                    </div>
  
                    <!-- Key result updated -->
                    <div class="status-update-key-results" *ngIf="activity.progressUpdate.keyResults && (activity.progressUpdate.keyResults.length > 0)">
                      <ng-container *ngFor="let keyResult of activity.progressUpdate.keyResults">
                        <div class="update-label" translate>
                          <span class="fal fa-fw fa-list"></span>
                          <span translate>{{eGoalsMessages.KEY_RESULTS}}</span>
                        </div>
  
                        <!-- Current Value -->
                        <div class="status-result-values" *ngIf="keyResult.currentValueCurrent !== keyResult.currentValuePrevious">
                          <div>
                            <span>{{keyResult.name}}</span>
                            <span>:</span>
                          </div>
                          <div class="activity-comment">
                            <ng-container [ngSwitch]="keyResult.type">
                              <ng-container *ngSwitchCase="eGoalKeyResultType.BINARY">
                                <strong translate>{{ (keyResult.currentValuePrevious === 0) ? eGoalsMessages.INCOMPLETE : eGoalsMessages.COMPLETE }}</strong>
                                <span class="fal fa-arrow-right"></span>
                                  <strong translate>{{ (keyResult.currentValueCurrent === 0) ? eGoalsMessages.INCOMPLETE : eGoalsMessages.COMPLETE }}</strong>
                              </ng-container>
                              <ng-container *ngSwitchCase="eGoalKeyResultType.NUMERIC">
                                <strong>
                                  <ng-container [ngTemplateOutlet]="templateResultValueDisplay" [ngTemplateOutletContext]="{ value: keyResult.currentValuePrevious, measureUnit: keyResult.measureUnit, unitPlacement: keyResult.measureUnitPlacement }"></ng-container>
                                </strong>
                                <span class="fal fa-arrow-right"></span>
                                <strong>
                                  <ng-container [ngTemplateOutlet]="templateResultValueDisplay" [ngTemplateOutletContext]="{ value: keyResult.currentValueCurrent, measureUnit: keyResult.measureUnit, unitPlacement: keyResult.measureUnitPlacement }"></ng-container>
                                </strong>
                              </ng-container>
                            </ng-container>
                          </div>
                        </div>
                      </ng-container>
                    </div>
                  </ng-container>

                  <!-- Comment -->
                  <div class="status-update-comment" *ngIf="activity.message.length > 0">
                    <div class="update-label">
                      <span class="fal fa-fw fa-badge-check"></span>
                      <span translate>{{eGoalsMessages.PROGRESS}}</span>
                    </div>
                    <div class="activity-comment">
                      <app-rich-display [text]="activity.message"></app-rich-display>
                    </div>
                  </div>
                </div>
              </ng-template>

              <ng-template #templateEditUpdate>
                <div class="p-top-10 p-bottom-10" *ngIf="activity.editUpdate !== null">
                  <!-- Title -->
                  <ng-container *ngIf="activity.editUpdate.titleCurrent !== null">
                    <div class="edit-item">
                      <div translate>{{eGoalsMessages.ACTIVITY_EDITED_OBJECTIVE}}</div>
                      <div class="activity-comment">
                        <strong>{{ activity.editUpdate.titlePrevious }}</strong>
                        <span class="fal fa-arrow-right"></span>
                        <strong>{{ activity.editUpdate.titleCurrent }}</strong>
                      </div>
                    </div>
                  </ng-container>
      
                  <!-- Type -->
                  <ng-container *ngIf="activity.editUpdate.typeCurrent !== null">
                    <div class="edit-item">
                      <div translate>{{eGoalsMessages.ACTIVITY_EDITED_TYPE}}</div>
                      <div class="activity-comment">
                        <strong>{{activity.editUpdate.typePrevious!.toString() | underscoreToSpace}}</strong>
                        <span class="fal fa-arrow-right"></span>
                        <strong>{{activity.editUpdate.typeCurrent!.toString() | underscoreToSpace}}</strong>
                      </div>
                    </div>
                  </ng-container>

                  <!-- Office location -->
                  <ng-container *ngIf="activity.editUpdate.officeLocationCurrent !== null">
                    <div class="edit-item">
                      <!--

                        <div *ngIf="result.keyResultTitle" translate [translateParams]="{keyResultTitle:result.keyResultTitle}">{{eGoalsMessages.ACTIVITY_EDITED_RESULT_TYPE_INCLUDE_RESULT}}</div>
                      -->
                      <div translate [translateParams]="{site:globals.getTerminology(eTerminologyEntity.SITE).toLowerCase()}">{{eGoalsMessages.ACTIVITY_EDITED_OFFICE_LOCATION}}</div>
                      <div class="activity-comment">
                        <strong>{{activity.editUpdate.officeLocationPrevious!.name }}</strong>
                        <span class="fal fa-arrow-right"></span>
                        <strong>{{activity.editUpdate.officeLocationCurrent!.name }}</strong>
                      </div>
                    </div>
                  </ng-container>

                  <!-- Development needs -->
                  <ng-container *ngIf="activity.editUpdate.developmentNeedsCurrent !== null">
                    <div class="edit-item">
                      <div translate>{{eGoalsMessages.ACTIVITY_EDITED_DEVELOPMENT_NEEDS}}</div>
                      <div class="activity-comment">
                        <strong>{{activity.editUpdate.developmentNeedsPrevious}}</strong>
                        <span class="fal fa-arrow-right"></span>
                        <strong *ngIf="activity.editUpdate.developmentNeedsCurrent">{{activity.editUpdate.developmentNeedsCurrent}}</strong>
                        <strong *ngIf="!activity.editUpdate.developmentNeedsCurrent" translate>{{eCommonMessages.NONE}}</strong>
                      </div>
                    </div>
                  </ng-container>
      
                  <!-- End Date -->
                  <ng-container *ngIf="activity.editUpdate.endDateCurrent !== null">
                    <div class="edit-item">
                      <div translate>{{eGoalsMessages.ACTIVITY_EDITED_DUE_DATE}}</div>
                      <div class="activity-comment">
                        <strong>{{activity.editUpdate.endDatePrevious | date: "d MMMM y"}}</strong>
                        <span class="fal fa-arrow-right"></span>
                        <strong>{{activity.editUpdate.endDateCurrent | date: "d MMMM y"}}</strong>
                      </div>
                    </div>
                  </ng-container>
      
                  <!-- Visibility -->
                  <ng-container *ngIf="activity.editUpdate.visibilityCurrent !== null">
                    <div class="edit-item">
                      <div translate>{{eGoalsMessages.ACTIVITY_EDITED_VISIBILITY}}</div>
                      <div class="activity-comment">
                        <strong>{{activity.editUpdate.visibilityPrevious}}</strong>
                        <span class="fal fa-arrow-right"></span>
                        <strong>{{activity.editUpdate.visibilityCurrent}}</strong>
                      </div>
                    </div>
                  </ng-container>
      
                  <!-- Priority -->
                  <ng-container *ngIf="activity.editUpdate.priorityCurrent !== null">
                    <div class="edit-item">
                      <div translate>{{eGoalsMessages.ACTIVITY_EDITED_PRIORITY}}</div>
                      <div class="activity-comment">
                        <app-goal-priority-display [priority]="activity.editUpdate.priorityPrevious || eGoalPriority.NOT_SET"></app-goal-priority-display>
                        <span class="fal fa-arrow-right"></span>
                        <app-goal-priority-display [priority]="activity.editUpdate.priorityCurrent"></app-goal-priority-display>
                      </div>
                    </div>
                  </ng-container>
      
                  <!-- Alignment -->
                  <ng-container *ngIf="activity.editUpdate.alignmentTitlePrevious || activity.editUpdate.alignmentTitleCurrent">
                    <div class="edit-item">
                      <div translate>{{eGoalsMessages.ACTIVITY_EDITED_ALIGNMENT}}</div>
                      <div class="activity-comment">
                        <strong translate>{{activity.editUpdate.alignmentTitlePrevious ? activity.editUpdate.alignmentTitlePrevious : eGoalsMessages.NO_ALIGNMENT}}</strong>
                        <span class="fal fa-arrow-right"></span>
                        <strong translate>{{activity.editUpdate.alignmentTitleCurrent ? activity.editUpdate.alignmentTitleCurrent : eGoalsMessages.NO_ALIGNMENT}}</strong>
                      </div>
                    </div>
                  </ng-container>
      
                  <!-- Added Owners -->
                  <ng-container *ngIf="activity.editUpdate.addedOwners.length > 0">
                    <div class="edit-item">
                      <div translate>{{eGoalsMessages.ACTIVITY_EDITED_OWNERS_ADDED}}</div>
                      <div class="activity-comment">
                        <div class="d-inline-block m-right-10 m-bottom-5"
                             *ngFor="let owner of activity.editUpdate.addedOwners">
                          <span class="owner-img clickable m-right-5" [routerLink]="['/profile/', owner.id]">
                            <app-profile-photo [src]="owner.imageUrl" [firstName]="owner.firstName" [lastName]="owner.lastName"></app-profile-photo>
                          </span>
                            <strong>{{owner.firstName}} {{owner.lastName}}</strong>
                        </div>
                      </div>
                    </div>
                  </ng-container>
      
                  <!-- Removed Owners -->
                  <ng-container *ngIf="activity.editUpdate.removedOwners.length > 0">
                    <div class="edit-item">
                      <div translate>{{eGoalsMessages.ACTIVITY_EDITED_OWNERS_REMOVED}}</div>
                      <div class="activity-comment">
                          <div class="d-inline-block m-right-10 m-bottom-5"
                               *ngFor="let owner of activity.editUpdate.removedOwners">
                          <span class="owner-img clickable m-right-5" [routerLink]="['/profile/', owner.id]">
                            <app-profile-photo [src]="owner.imageUrl" [firstName]="owner.firstName" [lastName]="owner.lastName"></app-profile-photo>
                          </span>
                              <strong>{{owner.firstName}} {{owner.lastName}}</strong>
                          </div>
                      </div>
                    </div>
                  </ng-container>

                  <!-- Added Tags -->
                  <ng-container *ngIf="activity.editUpdate.addedTags.length > 0">
                    <div class="edit-item">
                      <div translate>{{eGoalsMessages.ACTIVITY_EDITED_TAGS_ADDED}}</div>
                      <div class="activity-comment">
                        <ng-container *ngFor="let tag of activity.editUpdate.addedTags; index as i">
                          <strong>{{tag.text}}</strong>
                          <span *ngIf="i !== activity.editUpdate.addedTags.length - 1">, </span>
                        </ng-container>
                      </div>
                    </div>
                  </ng-container>
      
                  <!-- Removed Tags -->
                  <ng-container *ngIf="activity.editUpdate.removedTags.length > 0">
                    <div class="edit-item">
                      <div translate>{{eGoalsMessages.ACTIVITY_EDITED_TAGS_REMOVED}}</div>
                      <div class="activity-comment">
                        <ng-container *ngFor="let tag of activity.editUpdate.removedTags; index as i">
                          <strong>{{tag.text}}</strong>
                          <span *ngIf="i !== activity.editUpdate.removedTags.length - 1">, </span>
                        </ng-container>
                      </div>
                    </div>
                  </ng-container>
      
                  <!-- Results Added -->
                  <ng-container *ngIf="activity.editUpdate.keyResultsAdded.length > 0">
                    <div class="edit-item">
                      <div translate>{{eGoalsMessages.ACTIVITY_EDITED_RESULTS_ADDED}}</div>
                      <div class="activity-comment">
                        <ng-container *ngFor="let result of activity.editUpdate.keyResultsAdded">
                          <strong>{{result}}</strong>
                        </ng-container>
                      </div>
                    </div>
                  </ng-container>
      
                  <!-- Results Removed -->
                  <ng-container *ngIf="activity.editUpdate.keyResultsRemoved.length > 0">
                    <div class="edit-item">
                      <div translate>{{eGoalsMessages.ACTIVITY_EDITED_RESULTS_REMOVED}}</div>
                      <div class="activity-comment">
                        <ng-container *ngFor="let result of activity.editUpdate.keyResultsRemoved">
                          <strong>{{result}}</strong>
                        </ng-container>
                      </div>
                    </div>
                  </ng-container>
      
                  <!-- Results Edited -->
                  <ng-container *ngIf="activity.editUpdate.keyResultsUpdated.length > 0">
                    <div class="edit-item" *ngFor="let result of activity.editUpdate.keyResultsUpdated">
                      <div *ngIf="result.resultPrevious !== null">
                        <div translate>{{eGoalsMessages.ACTIVITY_EDITED_RESULT_RESULT}}</div>
                        <div class="activity-comment">
                          <strong>{{result.resultPrevious}}</strong>
                          <span class="fal fa-arrow-right"></span>
                          <strong>{{result.resultCurrent}}</strong>
                        </div>
                      </div>
      
                      <div *ngIf="result.measureTypePrevious !== null">
                        <div *ngIf="result.keyResultTitle" translate [translateParams]="{keyResultTitle:result.keyResultTitle}">{{eGoalsMessages.ACTIVITY_EDITED_RESULT_TYPE_INCLUDE_RESULT}}</div>
                        <div *ngIf="!result.keyResultTitle" translate>{{eGoalsMessages.ACTIVITY_EDITED_RESULT_TYPE}}</div>
                        <div class="activity-comment">
                          <strong>{{result.measureTypePrevious}}</strong>
                          <span class="fal fa-arrow-right"></span>
                          <strong>{{result.measureTypeCurrent}}</strong>
                        </div>
                      </div>

                      <div *ngIf="result.measureStartValuePrevious !== null">
                        <div *ngIf="result.keyResultTitle" translate [translateParams]="{keyResultTitle:result.keyResultTitle}">{{eGoalsMessages.ACTIVITY_EDITED_RESULT_START_VALUE_INCLUDE_RESULT}}</div>
                        <div *ngIf="!result.keyResultTitle" translate>{{eGoalsMessages.ACTIVITY_EDITED_RESULT_START_VALUE}}</div>
                        <div class="activity-comment">
                          <strong>{{result.measureStartValuePrevious}}</strong>
                          <span class="fal fa-arrow-right"></span>
                          <strong>{{result.measureStartValueCurrent}}</strong>
                        </div>
                      </div>

                      <div *ngIf="result.measureCurrentValuePrevious !== null">
                        <div *ngIf="result.keyResultTitle" translate [translateParams]="{keyResultTitle:result.keyResultTitle}">{{eGoalsMessages.ACTIVITY_EDITED_RESULT_CURRENT_VALUE_INCLUDE_RESULT}}</div>
                        <div *ngIf="!result.keyResultTitle" translate>{{eGoalsMessages.ACTIVITY_EDITED_RESULT_CURRENT_VALUE}}</div>
                        <div class="activity-comment">
                          <strong>{{result.measureCurrentValuePrevious}}</strong>
                          <span class="fal fa-arrow-right"></span>
                          <strong>{{result.measureCurrentValueCurrent}}</strong>
                        </div>
                      </div>
      
                      <div *ngIf="result.measureGoalValuePrevious !== null">
                        <div *ngIf="result.keyResultTitle" translate [translateParams]="{keyResultTitle:result.keyResultTitle}">{{eGoalsMessages.ACTIVITY_EDITED_RESULT_GOAL_VALUE_INCLUDE_RESULT}}</div>
                        <div *ngIf="!result.keyResultTitle" translate>{{eGoalsMessages.ACTIVITY_EDITED_RESULT_GOAL_VALUE}}</div>
                        <div class="activity-comment">
                          <strong>{{result.measureGoalValuePrevious}}</strong>
                          <span class="fal fa-arrow-right"></span>
                          <strong>{{result.measureGoalValueCurrent}}</strong>
                        </div>
                      </div>

                      <div *ngIf="result.measureUnitPlacementPrevious !== null || result.measureUnitPlacementCurrent !== null">
                        <div *ngIf="result.keyResultTitle" translate [translateParams]="{keyResultTitle:result.keyResultTitle}">{{eGoalsMessages.ACTIVITY_EDITED_RESULT_UNIT_PLACEMENT_INCLUDE_RESULT}}</div>
                        <div *ngIf="!result.keyResultTitle" translate>{{eGoalsMessages.ACTIVITY_EDITED_RESULT_UNIT_PLACEMENT}}</div>
                        <div class="activity-comment">
                          <strong translate>{{parseUnitPlacementToMessageCode(result.measureUnitPlacementPrevious)}}</strong>
                          <span class="fal fa-arrow-right"></span>
                          <strong translate>{{parseUnitPlacementToMessageCode(result.measureUnitPlacementCurrent)}}</strong>
                        </div>
                      </div>
      
                      <div *ngIf="result.endDatePrevious !== null && result.endDateCurrent !== null">
                        <div *ngIf="result.keyResultTitle" translate [translateParams]="{keyResultTitle:result.keyResultTitle}">{{eGoalsMessages.ACTIVITY_EDITED_RESULT_DUE_DATE_INCLUDE_RESULT}}</div>
                        <div *ngIf="!result.keyResultTitle" translate>{{eGoalsMessages.ACTIVITY_EDITED_RESULT_DUE_DATE}}</div>
                        <div class="activity-comment">
                          <strong>{{(result.endDatePrevious === null) ? "N/A" : (result.endDatePrevious | date: "d MMMM y")}}</strong>
                          <span class="fal fa-arrow-right"></span>
                          <strong>{{(result.endDateCurrent === null) ? "N/A" : (result.endDateCurrent | date: "d MMMM y")}}</strong>
                        </div>
                      </div>
                    </div>
                  </ng-container>
                </div>
              </ng-template>
            </div>
          </div>
        </div>
      </div>

      <div class="col-timestamp">
        <app-pill>
          <div>{{ activity.date | momentPipe: 'D MMM YYYY HH:mm' }}</div>
        </app-pill>
      </div>
    </div>

    <div class="col-delete">
      <div
        *ngIf="((activity.type === eGoalActivityType.COMMENT) && (activity.user.id === globals.user.id))"
        class="action-icon-container" (click)="deleteComment($event)"
        [tooltip]="eGoalsMessages.ACTIVITY_COMMENT_DELETE_PROMPT | translate" [triggers]="globals.tooltipTriggers" placement="bottom" container="body">
        <app-table-action-icon [icon]="'fa-times'" [hoverColor]="eIconHoverColor.RED"></app-table-action-icon>
      </div>
    </div>
  </div>
</div>

<ng-template #templateStatusDisplay let-status="status">
  <ng-container [ngSwitch]="status">
    <strong *ngSwitchCase="eGoalStatus.OFF_TRACK" translate>{{eGoalsMessages.OFF_TRACK}}</strong>
    <strong *ngSwitchCase="eGoalStatus.PROGRESSING" translate>{{eGoalsMessages.PROGRESSING}}</strong>
    <strong *ngSwitchCase="eGoalStatus.ON_TRACK" translate>{{eGoalsMessages.ON_TRACK}}</strong>
  </ng-container>
</ng-template>

<ng-template #templateResultValueDisplay let-value="value" let-unitPlacement="unitPlacement" let-measureUnit="measureUnit">
  <ng-container [ngSwitch]="unitPlacement">
    <span *ngSwitchCase="eGoalKeyResultMeasureUnitPlacement.BEFORE">{{measureUnit}}</span>
    <span>{{value}}</span>
    <span *ngSwitchCase="eGoalKeyResultMeasureUnitPlacement.AFTER">{{measureUnit}}</span>
  </ng-container>
</ng-template>
