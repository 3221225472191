import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SentimentScale } from '../../model/sentiment-scale.model';
import { SentimentScaleMessages } from '../../locale/sentiment-scale.messages';
import { PillType } from '@app/shared/components/pill/pill.component';
import { CommonMessages } from '@app/constants/common.messages';

@Component({
  selector: 'app-sentiment-scale-display',
  templateUrl: './sentiment-scale-display.component.html',
  styleUrls: ['./sentiment-scale-display.component.scss']
})
export class SentimentScaleDisplayComponent {

  public readonly eSentimentScaleMessages = SentimentScaleMessages;
  public readonly eCommonMessages = CommonMessages;
  public readonly ePillType = PillType;

  @Input() scale: SentimentScale;
  @Input() showOptions: boolean;
  @Input() actionsShown: string[]; // valid options are 'edit', 'archive'

  @Output() onClickAction: EventEmitter<string>;

  get canSeeActionEdit(): boolean {
    if (!this.scale) { return false; }
    if (this.scale.archived) { return false; }
    return this.actionsShown.includes('edit');
  }

  get canSeeActionArchive(): boolean {
    if (!this.scale) { return false; }
    if (this.scale.archived) { return false; }
    return this.actionsShown.includes('archive');
  }

  constructor() {
    this.scale = undefined;

    this.showOptions = true;

    this.actionsShown = [];

    this.onClickAction = new EventEmitter<string>();
  }

  clickAction(action: string): void {
    this.onClickAction.emit(action);
  }

}
