<div class="enps-container" [class.enps-disabled]="disabled">
  <div class="row m-left-0 m-right-0">
    <div class="enps-item" *ngFor="let val of values; index as i">
      <div [class]="'enps-bubble ' + 'enps-fill-' + val.value" [class.selected]="(_value === val.value) || enpsChart" (click)="select(val.value)">
        <span class="noselect">{{val.value}}</span>
      </div>
    </div>
  </div>

  <div class="row m-top-5" *ngIf="showLabels">
    <div class="col-xs-6 text-left">
      <span class="not-likely-label">1 - {{minLabel}}</span>
    </div>
    <div class="col-xs-6 text-right">
      <span class="very-likely-label">10 - {{maxLabel}}</span>
    </div>
  </div>

  <!-- TODO: Might need to add something that taps into [display] -->
</div>
