import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-licenses',
  templateUrl: './licenses.component.html',
  styleUrls: ['./licenses.component.scss']
})
export class LicensesComponent implements OnInit {

  sample = {
    name: '',
    license: '',
    link: ''
  };

  packages: unknown[] = [];

  constructor(private http: HttpClient) { }

  ngOnInit(): void {
    this.packages.push(this.sample);
    this.readLicenses();
  }

  readLicenses(): void {
    this.http.get('assets/LICENSES', { responseType: 'text' })
      .subscribe((data: string) => {
        const packages = data.split('\n\n');
        this.packages = packages.map(entry => {
          const lines = entry.split('\n');
          return {
            name: lines[0],
            license: lines[1],
            link: lines[2]
          };
        });
      });
  }

}
