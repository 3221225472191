import { Injectable } from '@angular/core';
import { MockService } from '@app/mock/api/mock-service';
import { Globals } from '@app/shared/globals/globals';
import { HttpRequest } from '@angular/common/http';
import { FeedbackService } from '@app/shared/api/interfaces/feedback.service';
import { Observable } from 'rxjs';
import {
  CreateFeedbackUnpromptedDraftDto,
  CreateFeedbackUnpromptedDto,
  FeedbackUnprompted,
  FeedbackUnpromptedDraft,
  FeedbackUnpromptedProceed,
  FeedbackUnpromptedSettings,
} from '@app/models/feedback/feedback.model';
import { CreatePeerFeedbackDto, PeerFeedback } from '@app/models/feedback/peer-feedback.model';
import {
  CreateFeedbackRequestDto,
  FeedbackRequest,
  FeedbackRequestResponseAnswer,
} from '@app/models/feedback/feedback-request.model';
import { mockUnpromptedFeedback } from '@app/mock/api/data/mockUnpromptedFeedback';
import { mockFeedbackRequests } from '@app/mock/api/data/mockFeedbackRequests';
import { mockFeedbackUnpromptedSettings } from '@app/mock/api/data/mockFeedbackUnpromptedSettings';
import { mockFeedbackUnpromptedDrafts } from '@app/mock/api/data/mockFeedbackUnpromptedDrafts';
import { mockPeerFeedback } from '@app/mock/api/data/mockPeerFeedback';
import { sanitizeUrl } from '@app/shared/utils/helpers';
import { CreateFeedbackUploadDto, FeedbackUpload } from '@app/models/feedback/feedback-upload.model';
import { FeedbackUnpromptedActivity } from '@app/models/feedback/feedback-activity.model';
import { FeedbackUploadActivity } from '@app/models/feedback/feedback-upload-activity.model';
import { mockFrankliActivity } from '@app/mock/api/data/mockFrankliActivity';

@Injectable()
export class FeedbackMockService implements MockService, FeedbackService {
    constructor(private readonly globals: Globals) { }

    handleRoute(req: HttpRequest<any>): any {
        const url = sanitizeUrl(req.urlWithParams);

        switch (true) {
            case url.endsWith('api/feedback/unprompted/activity/sent'):
                return this.getUnpromptedFeedbackActivityForFeedbackByMe();
            case url.endsWith('api/feedback/unprompted/activity/received'):
                return this.getUnpromptedFeedbackActivityForFeedbackToMe();
            case url.endsWith('api/feedback/unprompted') && req.method === 'GET':
                return this.getSentFeedback();
            case url.match(/api\/feedback\/unprompted\/\d+$/) !== null:
                const unpromptedFeedbackId = +url.split('/')[3];
                return this.getSentFeedbackById(unpromptedFeedbackId);
            case url.endsWith('api/feedback/submitted/manager/me'):
                return this.getFeedbackByManagerMe();
            case url.match(/api\/feedback\/submitted\/manager\/id\/\d+$/) !== null:
                const unpromptedFeedbackManagerId = +url.split('/')[5];
                return this.getFeedbackByManagerAndId(unpromptedFeedbackManagerId);
            case url.endsWith('api/feedback/settings/options') && req.method === 'GET':
                return this.getFeedbackSettings();
            case url.endsWith('api/feedback/unprompted/draft') && req.method === 'GET':
                return this.getFeedbackUnpromptedDrafts();
            case url.match(/api\/feedback\/unprompted\/draft\/\d+$/) !== null:
                const unpromptedFeedbackDraftId = +url.split('/')[4];
                return this.getFeedbackUnpromptedDraftById(unpromptedFeedbackDraftId);
            case url.match(/api\/feedback\/peer\/\d+$/) !== null:
                const peerFeedbackId = +url.split('/')[3];
                return this.getPeerFeedbackById(peerFeedbackId);
            case url.match(/api\/feedback\/peer\/sender\/\d+$/) !== null:
                const peerFeedbackSenderId = +url.split('/')[4];
                return this.getPeerFeedbackBySenderId(peerFeedbackSenderId);
            case url.match(/api\/feedback\/peer\/recipient\/\d+$/) !== null:
                const peerFeedbackRecipientId = +url.split('/')[4];
                return this.getPeerFeedbackByRecipientId(peerFeedbackRecipientId);
            case url.endsWith('api/feedback/peer/recipient/directs'):
                return this.getPeerFeedbackByRecipientsMyDirectReports();
            case url.endsWith('api/feedback/request/sent'):
                return this.getFeedbackRequestsSent();
            case url.match(/api\/feedback\/request\/\d+$/) !== null:
                const feedbackRequestId = +url.split('/')[3];
                return this.getFeedbackRequestById(feedbackRequestId);
            case url.endsWith('api/feedback/request/sent/manager/me'):
                return this.getFeedbackRequestsByManagerMe();
            case url.match(/api\/feedback\/request\/sent\/\d+$/) !== null:
                const feedbackRequestSentId = +url.split('/')[4];
                return this.getFeedbackRequestSentById(feedbackRequestSentId);
            case url.endsWith('api/feedback/request/received'):
                return this.getFeedbackRequestsReceived();
            case url.match(/api\/feedback\/request\/received\/\d+$/) !== null:
                const feedbackRequestReceivedId = +url.split('/')[4];
                return this.getFeedbackRequestReceivedById(feedbackRequestReceivedId);
            case url.endsWith('api/feedback/upload/sent'):
                return this.getFeedbackUploadSent();
            case url.match(/api\/feedback\/upload\/sent\/\d+$/) !== null:
                const feedbackUploadId = +url.split('/')[4];
                return this.getFeedbackUploadSentById(feedbackUploadId);
            case url.endsWith('api/feedback/upload/activity'):
                return this.getUploadFeedbackActivityForMe();
        }
    }

    getFeedbackByManagerAndId(id: number): Observable<FeedbackUnprompted> | FeedbackUnprompted {
        return mockUnpromptedFeedback.find(f => f.id === id);
    }

    getFeedbackByManagerMe(): Observable<Array<FeedbackUnprompted>> | FeedbackUnprompted[] {
        return mockUnpromptedFeedback.filter(f => f.destinationUser.id === this.globals.user.id);
    }

    getFeedbackRequestReceivedById(id: number): Observable<FeedbackRequest> | FeedbackRequest {
        return mockFeedbackRequests.find(fr => fr.id === id);
    }

    getFeedbackRequestSentById(id: number): Observable<FeedbackRequest> | FeedbackRequest {
        return mockFeedbackRequests.find(fr => fr.id === id);
    }

    getFeedbackRequestsReceived(): Observable<Array<FeedbackRequest>> | FeedbackRequest[] {
        return mockFeedbackRequests.filter(fr => fr.responses.map(r => r.user.id).includes(this.globals.user.id));
    }

    getFeedbackRequestsSent(): Observable<Array<FeedbackRequest>> | FeedbackRequest[] {
        return mockFeedbackRequests.filter(fr => fr.sender.id === this.globals.user.id);
    }

    getFeedbackRequestById(id: number): Observable<FeedbackRequest> | FeedbackRequest {
        return mockFeedbackRequests.find(fr => fr.id === id) || null;
    }

    getFeedbackRequestsByManagerMe(): Observable<FeedbackRequest[]> | FeedbackRequest[] {
        return mockFeedbackRequests.filter(fr => fr.sender.managerId === this.globals.user.id);
    }

    getFeedbackSettings(): Observable<FeedbackUnpromptedSettings> | FeedbackUnpromptedSettings {
        return mockFeedbackUnpromptedSettings;
    }

    getFeedbackUnpromptedDraftById(id: number): Observable<FeedbackUnpromptedDraft> | FeedbackUnpromptedDraft {
        return mockFeedbackUnpromptedDrafts.find(d => d.id === id);
    }

    getFeedbackUnpromptedDrafts(): Observable<Array<FeedbackUnpromptedDraft>> | FeedbackUnpromptedDraft[] {
        return mockFeedbackUnpromptedDrafts;
    }

    getPeerFeedbackById(id: number): Observable<PeerFeedback> | PeerFeedback {
        return mockPeerFeedback.find(pf => pf.id === id);
    }

    getPeerFeedbackByRecipientId(recipientId: number): Observable<Array<PeerFeedback>> | PeerFeedback[] {
        return mockPeerFeedback.filter(pf => pf.recipient.id === recipientId);
    }

    getPeerFeedbackBySenderId(senderId: number): Observable<Array<PeerFeedback>> | PeerFeedback[] {
        return mockPeerFeedback.filter(pf => pf.sender.id === senderId);
    }

    getSentFeedback(): Observable<Array<FeedbackUnprompted>> | FeedbackUnprompted[] {
        return mockUnpromptedFeedback.filter(f => f.sourceUser.id === this.globals.user.id);
    }

    getSentFeedbackById(id: number): Observable<FeedbackUnprompted> | FeedbackUnprompted {
        return mockUnpromptedFeedback.find(f => f.id === id);
    }

    getUnpromptedFeedbackActivityForFeedbackByMe(): Observable<FeedbackUnpromptedActivity[]> | FeedbackUnpromptedActivity[] {
        return mockUnpromptedFeedback.filter(f => f.sourceUser.id === this.globals.user.id).map(f => ({
            raw: f,
            event: mockFrankliActivity.find(a => a.activityForeignId === f.id && a.frankliActivityType === 'FEEDBACK_UNPROMPTED_SENT')
        }));
    }

    getUnpromptedFeedbackActivityForFeedbackToMe(): Observable<FeedbackUnpromptedActivity[]> | FeedbackUnpromptedActivity[] {
        return mockUnpromptedFeedback.filter(f => f.destinationUser.id === this.globals.user.id).map(f => ({
            raw: f,
            event: mockFrankliActivity.find(a => a.activityForeignId === f.id && a.frankliActivityType === 'FEEDBACK_UNPROMPTED_SENT')
        }));
    }

    getPeerFeedbackByRecipientsMyDirectReports(): Observable<Array<PeerFeedback>> | PeerFeedback[] {
        return mockPeerFeedback.filter(pf => pf.recipient.managerId === this.globals.user.id);
    }

    getFeedbackUploadSent(): Observable<FeedbackUpload[]> | FeedbackUpload[] {
        return [];
    }

    getFeedbackUploadSentById(id: number): Observable<FeedbackUpload> | FeedbackUpload {
        return undefined;
    }

    getUploadFeedbackActivityForMe(): Observable<FeedbackUploadActivity[]> | FeedbackUploadActivity[] {
        return [];
    }


    // No Ops listed below
    submitFeedbackUpload(createFeedbackUploadDto: CreateFeedbackUploadDto, file?: File): Observable<FeedbackUpload> {
        return undefined;
    }

    approveFeedbackRequestById(id: number, responseText: FeedbackRequestResponseAnswer[]): Observable<FeedbackRequest> {
        return undefined;
    }

    createFeedbackRequest(createFeedbackRequestDto: CreateFeedbackRequestDto): Observable<FeedbackRequest> | FeedbackRequest {
        return undefined;
    }

    createFeedbackUnpromptedDraft(createFeedbackUnpromptedDraftDto: CreateFeedbackUnpromptedDraftDto): Observable<FeedbackUnpromptedDraft> {
        return undefined;
    }

    createFeedbackUnpromptedFromDraft(draftId: number, createFeedbackUnpromptedDto: CreateFeedbackUnpromptedDto): Observable<FeedbackUnprompted> {
        return undefined;
    }

    declineFeedbackRequestById(id: number): Observable<FeedbackRequest> {
        return undefined;
    }

    deleteFeedbackUnpromptedDraftById(id: number): Observable<Array<FeedbackUnpromptedDraft>> {
        return undefined;
    }

    submitFeedbackUnprompted(createFeedbackUnpromptedDto: CreateFeedbackUnpromptedDto): Observable<FeedbackUnprompted> {
        return undefined;
    }

    submitPeerFeedback(createPeerFeedbackDto: CreatePeerFeedbackDto): Observable<PeerFeedback> {
        return undefined;
    }

    updateFeedbackSettings(proceed: Array<FeedbackUnpromptedProceed>): Observable<FeedbackUnpromptedSettings> {
        return undefined;
    }

    updateFeedbackUnpromptedDraft(id: number, createFeedbackUnpromptedDraftDto: CreateFeedbackUnpromptedDraftDto): Observable<FeedbackUnpromptedDraft> {
        return undefined;
    }
}