import { Component, Input } from '@angular/core';
import { AbsorbLmsAPIService } from '@app/domain/absorblms/api/absorblms-api.service';
import { AbsorbLmsCertificate } from '@app/domain/absorblms/model/absorblms-certificate.model';
import { Globals } from '@app/shared/globals/globals';

@Component({
  selector: 'app-absorblms-certificate-display',
  templateUrl: './absorblms-certificate-display.component.html',
  styleUrls: ['./absorblms-certificate-display.component.scss']
})
export class AbsorblmsCertificateDisplayComponent {

  @Input() certificate: AbsorbLmsCertificate;

  get canSeeActions(): boolean {
    return this.globals.user.id === this.certificate.userId;
  }

  constructor(
    public globals: Globals,
    private absorbLmsAPIService: AbsorbLmsAPIService
  ) { }

  showCertificate(): void {
    this.absorbLmsAPIService.showCertificate(this.certificate.id)
      .toPromise()
      .then(certificate => {
        this.certificate.hidden = certificate.hidden;
      });
  }

  hideCertificate(): void {
    this.absorbLmsAPIService.hideCertificate(this.certificate.id)
      .toPromise()
      .then(certificate => {
        this.certificate.hidden = certificate.hidden;
      });
  }
}
