<div class="user-item" *ngIf="user">
  <div class="row m-left-0 m-right-0">
    <div class="user-image-container">
      <app-profile-photo [src]="user.imageUrl" [firstName]="user.firstName" [lastName]="user.lastName" [width]="'30px'"></app-profile-photo>
    </div>
    <div class="user-details-container">
      <div>{{user.firstName}} {{user.lastName}}</div>
      <div class="user-role" *ngIf="showRole">
        <ng-container *ngIf="position; else noRoleTemplate;">
        <span>{{position.name}}</span>
        <span *ngIf="(globals.hasFeature(eCompanyFeatures.GRADES) && position.grade)">({{position.grade}})</span>
        </ng-container>
        <ng-template #noRoleTemplate>
          <span>Role pending</span>
        </ng-template>
      </div>
    </div>
    <div class="user-icon-container" *ngIf="showIcon">
      <span class="fal fa-fw" [ngClass]="iconClass" [style.color]="iconColor"></span>
    </div>
  </div>
</div>
