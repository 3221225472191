import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

@Component({
  selector: 'app-checkbox-input',
  templateUrl: './checkbox-input.component.html',
  styleUrls: ['./checkbox-input.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => CheckboxInputComponent),
    multi: true
  }],
})
export class CheckboxInputComponent implements OnInit, ControlValueAccessor {

  @Input() disabled: boolean;

  _value: boolean;

  onChange = (_: any) => {};
  onTouched = () => {};

  get value(): boolean {
    return this._value;
  }

  set value(v: boolean) {
    if (this.disabled) {
      return;
    }

    if (v !== this._value) {
      this._value = v;
      this.onChange(v);
    }
  }

  constructor() {
    this.disabled = false;
    this._value = false;
  }

  ngOnInit(): void {
  }

  writeValue(value: any): void {
    this.value = value;
  }

  registerOnChange(fn: (_: any) => {}): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  onClickCheckbox() {
    this.value = !this.value;
  }
}
