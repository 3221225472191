import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ModalComponent } from '@app/shared/modal/modal.component';
import { State } from '@app/shared/utils/state.model';
import { GoalStats } from '@app/models/goals/goal-stats';
import { FilterCategoryBase } from '@app/shared/dynamic-filter/interfaces/filter-category-base';
import { SelectedFilter } from '@app/shared/dynamic-filter/interfaces/selected-filter';
import { GoalTableColumn } from '@app/goals/goals-components/goal-search-table/goal-table-column';
import { GoalCategory } from '@app/shared/dynamic-filter/categories/goal-category';
import { CompanyFeatures } from '@app/models/company-features.model';
import { CommonMessages } from '@app/constants/common.messages';
import { GoalsAPIService } from '@app/shared/api/goals.api.service';
import { Globals } from '@app/shared/globals/globals';
import { UserMinimal } from '@app/models/user/user-minimal.model';
import { GoalsMessages } from '@app/goals/goals.messages';

@Component({
  selector: 'app-user-goals-modal',
  templateUrl: './user-goals-modal.component.html',
  styleUrls: ['./user-goals-modal.component.scss']
})
export class UserGoalsModalComponent implements OnChanges {
  public readonly eGoalsMessages = GoalsMessages;

  @Input() owner: UserMinimal;

  @Output() modalShown: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() modalHidden: EventEmitter<boolean> = new EventEmitter<boolean>();

  @ViewChild('modal') modal: ModalComponent;

  goalStats: GoalStats = {
    averageCompletionPercentage: 0,
    numberOfActiveGoals: 0,
    numberOfGoalsCompletedInLast3Months: 0,
    numberOfGoalsDueInNext30Days: 0
  };
  statsState: State = new State(false);
  hiddenFilterCategories: FilterCategoryBase[] = [];
  startDisabledColumns: GoalTableColumn[] = [GoalTableColumn.DEPARTMENT, GoalTableColumn.SITE];
  selectedFilters: SelectedFilter[] = [];

  constructor(private goalsAPIService: GoalsAPIService, public globals: Globals) {}

  ngOnChanges() {
    if (this.owner) {
      this.setupGoalSearch();
    }
  }

  setupGoalSearch() {
    this.hiddenFilterCategories = this.initializeHiddenFilterCategories();
    this.selectedFilters = this.initializeSelectedFilters();
    this.refreshGoalStats();
  }

  refreshGoalStats() {
    this.statsState.setLoading();
    this.goalsAPIService.getGoalStatsForUser(this.owner.id).subscribe(goalStats => {
      this.goalStats = goalStats;
      this.statsState.setSuccess();
    });
  }

  private initializeHiddenFilterCategories() {
    const hiddenCategories: FilterCategoryBase[] = [
      GoalCategory.ownerCategoryBase(),
      GoalCategory.departmentCategoryBase(),
      GoalCategory.officeLocationCategoryBase(),
      GoalCategory.visibilityCategoryBase()
    ];

    if (!this.globals.hasFeature(CompanyFeatures.GOAL_PRIORITY)) {
      hiddenCategories.push(GoalCategory.priorityCategoryBase());
    }
    return hiddenCategories;
  }

  private initializeSelectedFilters() {
    const selectedFilters: SelectedFilter[] = [
      {
        category: GoalCategory.completeCategory(),
        option: { displayName: CommonMessages.NO, value: 'false' }
      },
      {
        category: GoalCategory.archivedCategory(),
        option: { displayName: CommonMessages.NO, value: 'false' }
      },
      {
        category: GoalCategory.ownerCategoryBase(),
        permanent: true,
        option: {
          displayName: `${this.owner.firstName} ${this.owner.lastName}`,
          value: this.owner.id.toString()
        }
      }
    ];
    return selectedFilters;
  }

  show(): void {
    this.modal.show();
  }

  hide(): void {
    this.modal.hide();
  }

  onModalShown(event: boolean): void {
    this.modalShown.emit(event);
  }

  onModalHidden(event: boolean): void {
    this.modalHidden.emit(event);
  }

}
