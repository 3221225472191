import { FilterCategory } from '@app/shared/dynamic-filter/interfaces/filter-category';
import { FilterType } from '@app/shared/dynamic-filter/types/filter-type';
import { SearchMode } from '@app/shared/dynamic-filter/types/search-mode';
import { FilterCategoryBase } from '@app/shared/dynamic-filter/interfaces/filter-category-base';
import { OneToOneMessages } from '@app/domain/one_to_one/locale/one-to-one.messages';
import { OneToOneMeetingLength } from '@app/domain/one_to_one/model/one-to-one-meeting-length.model';
import { OneToOneUtilsService } from '@app/domain/one_to_one/service/one-to-one-utils.service';
import { ScheduleLocationType } from '@app/domain/one_to_one/model/schedule-location-type.model';
import { OneToOneFrequency } from '@app/domain/one_to_one/model/one-to-one-frequency.model';
import { OneToOneStatus } from '@app/domain/one_to_one/model/one-to-one-status.model';
import { SentimentScaleMessages } from '@app/domain/sentiment_scale/locale/sentiment-scale.messages';

export class OneToOneCategory {

  public static talkingPointTemplateCategoryBase = (): FilterCategoryBase => ({
    fieldName: 'talking_point_template_id',
    searchMode: SearchMode.TALKING_POINT_TEMPLATE,
    type: FilterType.COMBINED
  });

  public static talkingPointTemplateCategory = (): FilterCategory => ({
    ...OneToOneCategory.talkingPointTemplateCategoryBase(),
    displayName: OneToOneMessages.TALKING_POINT_TEMPLATE,
    options: []
  });

  public static timePeriodCategoryBase = (): FilterCategoryBase => ({
    fieldName: 'time_period',
    searchMode: SearchMode.DATE_RANGE,
    type: FilterType.RANGE
  });

  public static timePeriodCategory = (): FilterCategory => ({
    ...OneToOneCategory.timePeriodCategoryBase(),
    displayName: 'Time period',
    options: []
  });

  public static startDateTimeCategoryBase = (): FilterCategoryBase => ({
    fieldName: 'start_date_time',
    searchMode: SearchMode.DATE,
    type: FilterType.INDIVIDUAL
  });

  public static startDateTimeCategory = (): FilterCategory => ({
    ...OneToOneCategory.startDateTimeCategoryBase(),
    displayName: 'Next meeting time',
    options: []
  });

  public static meetingLengthCategoryBase = (): FilterCategoryBase => ({
    fieldName: 'meeting_length',
    searchMode: SearchMode.NONE,
    type: FilterType.COMBINED
  });

  public static meetingLengthCategory = (): FilterCategory => ({
    ...OneToOneCategory.meetingLengthCategoryBase(),
    displayName: OneToOneMessages.MEETING_LENGTH,
    options: Object.keys(OneToOneMeetingLength).map((meetingLength) => {
      return {
        displayName: OneToOneUtilsService.getMessageCodeForOneToOneMeetingLength(OneToOneMeetingLength[meetingLength]),
        value: meetingLength
      };
    })
  });

  public static locationTypeCategoryBase = (): FilterCategoryBase => ({
    fieldName: 'location_type',
    searchMode: SearchMode.NONE,
    type: FilterType.COMBINED
  });

  public static locationTypeCategory = (): FilterCategory => ({
    ...OneToOneCategory.locationTypeCategoryBase(),
    displayName: OneToOneMessages.LOCATION_TYPE,
    options: Object.keys(ScheduleLocationType).map((locationType) => {
      return {
        displayName: OneToOneUtilsService.getMessageCodeForOneToOneLocationType(ScheduleLocationType[locationType]),
        value: locationType
      };
    })
  });

  public static frequencyCategoryBase = (): FilterCategoryBase => ({
    fieldName: 'frequency',
    searchMode: SearchMode.NONE,
    type: FilterType.COMBINED
  });

  public static frequencyCategory = (): FilterCategory => ({
    ...OneToOneCategory.frequencyCategoryBase(),
    displayName: OneToOneMessages.FREQUENCY,
    options: Object.keys(OneToOneFrequency).map((frequency) => {
      return {
        displayName: OneToOneUtilsService.getMessageCodeForOneToOneFrequency(OneToOneFrequency[frequency]),
        value: frequency
      };
    })
  });

  public static statusCategoryBase = (): FilterCategoryBase => ({
    fieldName: 'status',
    searchMode: SearchMode.NONE,
    type: FilterType.COMBINED
  });

  public static statusCategory = (): FilterCategory => ({
    ...OneToOneCategory.statusCategoryBase(),
    displayName: OneToOneMessages.STATUS,
    options: Object.keys(OneToOneStatus).map((status) => {
      return {
        displayName: OneToOneUtilsService.getMessageCodeForOneToOneStatus(OneToOneStatus[status]),
        value: status
      };
    })
  });

  public static sentimentScaleIdCategoryBase = (): FilterCategoryBase => ({
    fieldName: 'sentiment_scale_id',
    searchMode: SearchMode.SENTIMENT_SCALE,
    type: FilterType.COMBINED
  });

  public static sentimentScaleIdCategory = (): FilterCategory => ({
    ...OneToOneCategory.sentimentScaleIdCategoryBase(),
    displayName: SentimentScaleMessages.SENTIMENT_SCALE,
    options: []
  });

}
