/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { FrankliHttpErrorResponse } from '@app/models/exception/frankli-http-error-response.model';
import { EMPTY, Observable, OperatorFunction } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { SentryService } from '../sentry.service';
import { SwalUtils } from '../swal.utils';

@Injectable()
export class CatchErrorUtils {

  constructor(
    private swalUtils: SwalUtils,
    private router: Router,
    private sentryService: SentryService
  ) { }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  catchAndRefresh(): OperatorFunction<void, unknown> {
    return catchError((err, req) => {
      window.location.reload();
      throw err;
    });
  }

  catchAndRetry(startCount = 1, maxCount = 5): OperatorFunction<void, unknown> {
    let currentCount = startCount;

    return catchError((err: FrankliHttpErrorResponse, caught: Observable<unknown>) => {
      if (currentCount === maxCount) {
        this.swalUtils.displayErrorSwal({ title: `Failed after ${maxCount} retries`, text: err.error.message }).then();
        return EMPTY;
      }

      currentCount += 1;

      this.swalUtils.displayErrorSwal({ text: err.error.message, showCancelButton: true, reverseButtons: true, confirmButtonText: 'Retry' }).then(
        (res) => {
          if (res.isConfirmed) {
            caught.toPromise().then();
          }
        }
      );

      return EMPTY;
    });
  }

  catchAndNavigateToRoute(route: string[], extras?: NavigationExtras, callbackFn?: () => void): OperatorFunction<void, unknown> {
    return catchError((err, req) => {
      this.router.navigate(route, extras).then(() => callbackFn);
      throw err;
    });
  }

  catchAndCaptureSentryFeedback(): OperatorFunction<void, unknown> {
    return catchError((err, req) => {
      this.sentryService.captureFeedback(err);
      throw err;
    });
  }
}
