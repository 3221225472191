import { OneToOneMessages } from '@app/domain/one_to_one/locale/one-to-one.messages';

export class SidebarMessages {
  static readonly HELP = 'sidebar.help';
  static readonly DASHBOARD = 'sidebar.dashboard';
  static readonly PEOPLE = 'sidebar.people';
  static readonly PEOPLE_DIRECTORY = 'sidebar.people-directory';
  static readonly ORG_CHART = 'sidebar.org-chart';
  static readonly FEEDBACK = 'sidebar.feedback';
  static readonly GOALS = 'sidebar.goals';
  static readonly TASKS = 'sidebar.tasks';
  static readonly SURVEYS_AND_POLLS = 'sidebar.surveys-and-polls';
  static readonly ONE_TO_ONE_MEETINGS = OneToOneMessages.MEETINGS;
  static readonly CONNECT = 'sidebar.connect';
  static readonly MY_REVIEWS = 'sidebar.my-reviews';
  static readonly ADMIN = 'sidebar.admin';
  static readonly FRANKLI_ADMIN = 'sidebar.frankli-admin';
  static readonly TEAM_FEEDBACK = 'sidebar.team.feedback';
  static readonly TEAM_VIEW = 'sidebar.team.view';
  static readonly TEAM_GOALS = 'sidebar.team.goals';
  static readonly TEAM_TASKS = 'sidebar.team.tasks';
  static readonly TEAM_ONE_TO_ONES = 'sidebar.team.one-to-ones';
  static readonly TEAM_REVIEWS = 'sidebar.team.reviews';
  static readonly HELP_CENTER = 'sidebar.help-center'; // Help center
  static readonly TRAINING_VIDEOS = 'sidebar.training-videos';
}