import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CommonMessages } from '@app/constants/common.messages';
import { Globals } from '@app/shared/globals/globals';
import { TasksMessages} from '../../locale/tasks.messages';
import { TaskActivityType } from '@app/domain/task/model/task-activity-type.enum';
import { TaskAlignmentType } from '@app/domain/task/model/task-alignment-type.enum';
import { TaskStatus } from '@app/domain/task/model/task-status.enum';
import { Task } from '@app/domain/task/model/task.model';
import { TaskBusinessService } from '../../service/task-business.service';
import { RoleBusinessService } from '@app/domain/role/service/role-business.service';
import { Role } from '@app/domain/role/model/role.model';
import { OneToOneScheduleDetailsView } from '@app/domain/one_to_one/model/one-to-one-schedule.model';
import { Goal } from '@app/models/goals/goal.model';
import { GoalsAPIService } from '@app/shared/api/goals.api.service';
import { OneToOneBusinessService } from '@app/domain/one_to_one/service/one-to-one-business.service';

interface PageState {
  commentSubmitted: boolean;
  processing: boolean;
}

@Component({
  selector: 'app-task-details-component',
  templateUrl: './task-details.component.html',
  styleUrls: ['./task-details.component.scss']
})
export class TaskDetailsComponent implements OnChanges {

  public readonly eTaskAlignmentType = TaskAlignmentType;
  public readonly eTaskActivityType = TaskActivityType;
  public readonly eCommonMessages = CommonMessages;
  public readonly eTasksMessages = TasksMessages;
  public readonly eTaskStatus = TaskStatus;

  @Input() task !: Task;
  @Input() showHeader: boolean;

  @Output() onTaskUpdated: EventEmitter<Task>;
  @Output() requestCloseModal: EventEmitter<boolean>;

  state: PageState;
  controlTaskStatus: FormControl;

  linkedGoal: Goal;
  linkedOneToOne: OneToOneScheduleDetailsView;
  linkedRoles: Role[];

  get canUpdate(): boolean {
    if (!this.globals) {
      return false;
    }
    if (!this.globals.user) {
      return false;
    }

    if (this.task.creator.id === this.globals.user.id) {
      return true;
    }
    if (this.task.owners.map(o => o.id).includes(this.globals.user.id)) {
      return true;
    }

    return false;
  }

  constructor(
    public globals: Globals,
    private router: Router,
    private taskBusinessService: TaskBusinessService,
    private roleBusinessService: RoleBusinessService,
    private goalAPIService: GoalsAPIService,
    private oneToOneBusinessService: OneToOneBusinessService
  ) {
    this.task = undefined!;
    this.showHeader = true;

    this.linkedGoal = undefined;
    this.linkedOneToOne = undefined;
    this.linkedRoles = [];

    this.onTaskUpdated = new EventEmitter<Task>();
    this.requestCloseModal = new EventEmitter<boolean>();

    this.controlTaskStatus = this.initControlStatus();

    this.state = {
      commentSubmitted: false,
      processing: false
    };
  }

  ngOnChanges(changes: SimpleChanges): void {
    const changesTask = changes['task'];
    if (changesTask) {
      this.controlTaskStatus = this.initControlStatus(this.task);
      this.getlinkedPositions(this.task);
      this.getAlignmentData(this.task);
    }
  }

  openAlignedItem(): void {
    if (!this.task) { return; }

    switch (this.task.alignmentType) {
      case TaskAlignmentType.GOAL :
        this.router.navigate(['/goals/individual/', this.task.alignmentId]);
        return;
      case TaskAlignmentType.ONE_TO_ONE :
        this.router.navigate(['/one-to-one/update/', this.task.alignmentId]);
        return;
      case TaskAlignmentType.MILESTONE :
      case TaskAlignmentType.TODO :
        return;
    }
  }

  closeDetailsModal(): void {
    this.requestCloseModal.emit(true);
  }

  initControlStatus(task?: Task): FormControl {
    const control = new FormControl(null, [Validators.required]);

    if (task) {
      control.setValue(task.status, { emitEvent: false });
    }

    control.valueChanges.subscribe(status => this.onStatusChanged(status));

    return control;
  }

  onStatusChanged(status: TaskStatus): void {
    if (!this.task) { return; }

    const previousStatus = this.task.status;

    this.taskBusinessService.updateStatusOfTask(this.task.id, status)
      .then((task) => {
        this.task = task;
        this.onTaskUpdated.emit(this.task);
      })
      .catch(() => {
        this.controlTaskStatus.setValue(previousStatus, { emitEvent: false });
      });
  }

  getlinkedPositions(task: Task): void {
    if (!task) { return; }
    if (!task.linkedPositionIds) { return; }
    if (task.linkedPositionIds.length === 0) { return; }

    this.roleBusinessService.get(task.linkedPositionIds, null, null, null)
      .toPromise()
      .then(roles => {
        this.linkedRoles = roles;
      });
  }

  getAlignmentData(task: Task): void {
    this.linkedGoal = undefined;
    this.linkedOneToOne = undefined;

    if (!task) { return; }
    if (task.alignmentType === TaskAlignmentType.TODO) { return; }
    if (!task.alignmentId) { return; }

    switch (task.alignmentType) {
      case TaskAlignmentType.GOAL :
        this.goalAPIService.getGoalById(task.alignmentId)
          .toPromise()
          .then(goal => {
            goal = Goal.getGoalCompletionPercentage(goal);
            this.linkedGoal = goal;
          });
        break;
      case TaskAlignmentType.ONE_TO_ONE :
        this.oneToOneBusinessService.getOneToOneScheduleDetailsById(task.alignmentId)
          .toPromise()
          .then(oneToOne => {
            this.linkedOneToOne = oneToOne;
          });
        break;
    }
  }
}
