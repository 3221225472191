import { Component, Input, OnInit } from '@angular/core';
import { EvaluationFeedbackRequestState } from '@app/models/evaluation/evaluation-feedback-request-state.model';
import { EvaluationFeedbackRequestType } from '@app/models/evaluation/evaluation-feedback-request-type.model';
import { EvaluationFeedbackRequest } from '@app/models/evaluation/evaluation-feedback-request.model';
import { EvaluationFeedback } from '@app/models/evaluation/evaluation-feedback.model';
import { Globals } from '@app/shared/globals/globals';

@Component({
  selector: 'app-peer-reviews-status-arrows',
  templateUrl: './peer-reviews-status-arrows.component.html',
  styleUrls: ['./peer-reviews-status-arrows.component.scss']
})
export class PeerReviewsStatusArrowsComponent implements OnInit {
  public readonly eEvaluationFeedbackRequestType = EvaluationFeedbackRequestType;

  @Input() sent: EvaluationFeedbackRequest[];
  @Input() received: EvaluationFeedback[];
  @Input() requestType: EvaluationFeedbackRequestType;
  @Input() showIcons: boolean;

  get sentOfType(): EvaluationFeedbackRequest[] {
    return (this.sent || []).filter(r => r.type === this.requestType);
  }

  get sentRequestsCompleted(): EvaluationFeedbackRequest[] {
    return this.sentOfType.filter(r => r.state === EvaluationFeedbackRequestState.COMPLETED);
  }

  get sentRequestsNotDeclined(): EvaluationFeedbackRequest[] {
    return this.sentOfType.filter(r => ![EvaluationFeedbackRequestState.DECLINED_MANAGER, EvaluationFeedbackRequestState.DECLINED_PEER].includes(r.state));
  }

  get sentRequestsDeclined(): EvaluationFeedbackRequest[] {
    return this.sentOfType.filter(r => [EvaluationFeedbackRequestState.DECLINED_MANAGER, EvaluationFeedbackRequestState.DECLINED_PEER].includes(r.state));
  }

  get receivedOfType(): EvaluationFeedback[] {
    return (this.received || []).filter(r => r.request.type === this.requestType);
  }

  get receivedRequestsCompleted(): EvaluationFeedback[] {
    return this.receivedOfType.filter(r => r.request.state === EvaluationFeedbackRequestState.COMPLETED);
  }

  get receivedRequestsNotDeclined(): EvaluationFeedback[] {
    return this.receivedOfType.filter(r => ![EvaluationFeedbackRequestState.DECLINED_MANAGER, EvaluationFeedbackRequestState.DECLINED_PEER].includes(r.request.state));
  }

  get receivedRequestsDeclined(): EvaluationFeedback[] {
    return this.receivedOfType.filter(r => [EvaluationFeedbackRequestState.DECLINED_MANAGER, EvaluationFeedbackRequestState.DECLINED_PEER].includes(r.request.state));
  }

  constructor(
    public globals: Globals
  ) {
    this.sent = undefined;
    this.received = undefined;
    this.requestType = EvaluationFeedbackRequestType.PEER_REVIEW;
  }

  ngOnInit(): void {
  }
}
