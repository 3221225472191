<ng-container *ngIf="report">
  <tr class="frankli-table frankli-table-hover frankli-table-single-line">
    <td *ngFor="let indent of [].constructor(depth)" class="text-center" width="60px"></td>
  
    <!-- Dropdown -->
    <td class="text-center" *ngIf="enabledColumnKeys.length > 0" width="40px" (click)="loadChildren(report)">
      <ng-container *ngIf="report.hasDirectReports">
          <app-table-dropdown-toggle [opened]="report.dropdownOpen" [size]="'24px'"></app-table-dropdown-toggle>
      </ng-container>
    </td>
  
    <!-- Name -->
    <td *ngIf="enabledColumnKeys.includes(eTableColumn.NAME)" (click)="loadChildren(report)">
      <div class="display-user-details">
        <app-profile-photo [width]="'40px'" [src]="report.user.imageUrl" [firstName]="report.user.firstName" [lastName]="report.user.lastName"></app-profile-photo>
        <div class="d-inline-block lh-normal v-align-middle">
          <div>
            <span>{{ report.user.firstName }} {{report.user.lastName }}</span>
            <ng-container *ngIf="report.secondary">
              <span class="m-right-10"></span>
              <app-pill [type]="ePillType.GREEN" [text]="globals.getTerminology(eTerminologyEntity.SECONDARY)"></app-pill>
            </ng-container>
          </div>
          <ng-container *ngIf="report.tasksCountOwned === 0">
            <div class="text-danger">This person has no tasks</div>
          </ng-container>
        </div>
      </div>
    </td>
  
    <!-- <ng-container *ngIf="report.tasksCount > 0; else templateNoTasks"> -->
      <!-- Task Count -->
      <td class="text-center col-width-regular" [ngClass]="(report.tasksCount > 0 ? 'col-data-highlighted' : 'col-data-suppressed')" *ngIf="enabledColumnKeys.includes(eTableColumn.TASK_COUNT)">
          <span [title]="report.tasksCount + ' tasks'">{{(report.tasksCount > 0) ? report.tasksCount : '0'}}</span>
      </td>
    
      <!-- Task Count - Completed -->
      <td class="text-center col-width-regular" [ngClass]="(report.tasksCountCompleted > 0 ? 'col-data-highlighted' : 'col-data-suppressed')" *ngIf="enabledColumnKeys.includes(eTableColumn.TASK_COUNT_COMPLETE)">
          <span [title]="report.tasksCountCompleted + ' tasks completed'">{{(report.tasksCountCompleted > 0) ? report.tasksCountCompleted : '0'}}</span>
      </td>
    
      <!-- Task Count - In progress -->
      <td class="text-center col-width-regular" [ngClass]="(report.tasksCountInProgress > 0 ? 'col-data-highlighted' : 'col-data-suppressed')" *ngIf="enabledColumnKeys.includes(eTableColumn.TASK_COUNT_IN_PROGRESS)">
          <span [title]="report.tasksCountInProgress + ' tasks in progress'">{{(report.tasksCountInProgress > 0) ? report.tasksCountInProgress : '0'}}</span>
      </td>
    
      <!-- Task Count - Not started -->
      <td class="text-center col-width-regular" [ngClass]="(report.tasksCountNotStarted > 0 ? 'col-data-highlighted' : 'col-data-suppressed')" *ngIf="enabledColumnKeys.includes(eTableColumn.TASK_COUNT_NOT_STARTED)">
          <span [title]="report.tasksCountNotStarted + ' tasks not started'">{{(report.tasksCountNotStarted > 0) ? report.tasksCountNotStarted : '0'}}</span>
      </td>
    
      <!-- Task Count - Overdue -->
      <td class="text-center col-width-regular" [ngClass]="(report.tasksCountOverdue > 0 ? 'col-data-highlighted' : 'col-data-suppressed')" *ngIf="enabledColumnKeys.includes(eTableColumn.TASK_COUNT_OVERDUE)">
          <span [title]="report.tasksCountOverdue + ' tasks overdue'">{{(report.tasksCountOverdue > 0) ? report.tasksCountOverdue : '0'}}</span>
      </td>

      <!-- Average Completion -->
      <td class="text-nowrap col-width-large" *ngIf="enabledColumnKeys.includes(eTableColumn.AVERAGE_COMPLETION)">
        <app-progress-bar [startValue]="0" [targetValue]="100" [currentValue]="(report.tasksCountCompleted / report.tasksCount) * 100"></app-progress-bar>
      </td>

    <!-- </ng-container>
    <ng-template #templateNoTasks>
      <td class="text-center" [attr.colspan]="getColspanForEmptyState()">
        <span>This person has no tasks</span>
      </td>
    </ng-template> -->

    <!-- Actions -->
    <td class="text-center action-icons col-width-regular" *ngIf="enabledColumnKeys.includes(eTableColumn.ACTIONS)">
      <ng-container *ngIf="report.tasksCountOwned > 0">
        <app-table-action-icon [icon]="'fa-eye'" [tooltipText]="eTasksMessages.VIEW_USERS_TASKS | translate: { user: report.user.firstName }" (click)="openUserTasks(report)"></app-table-action-icon>
      </ng-container>
    </td>
  </tr>
  
  <!-- Dropdown contents -->
  <ng-container *ngIf="report.dropdownOpen && enabledColumnKeys.length > 0">
      <tr class="frankli-table">
        <td colspan="50">
            <ng-container *ngIf="!report.childrenLoading">
                <div class="scroll-x">
                    <table width="100%" class="tasks-subtable">
                      <ng-container *ngFor="let directReport of report.directReports">
                          <app-team-tasks-row
                            [report]="directReport" [enabledColumnKeys]="enabledColumnKeys" [depth]="depth + 1"
                            (onLoadChildren)="loadChildren($event)" (onOpenUserTasks)="openUserTasks($event)"
                          ></app-team-tasks-row>
                      </ng-container>
                    </table>
                </div>
            </ng-container>
            <ng-container *ngIf="report.childrenLoading">
                <div class="text-center">
                    <span class="fas fa-fw fa-spin fa-2x fa-spinner m-top-10 m-bottom-10"></span>
                </div>
            </ng-container>
        </td>
      </tr>
  </ng-container>
</ng-container>