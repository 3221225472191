<div class="display-container" *ngIf="praise">
  <!-- Sent by X to Y on Date -->
  <div class="area-metadata" *ngIf="(praise.senderUser && showSourceUser) || (praise.praisedUser && showDestinationUser) || (praise.timestamp)">
    <span>Sent&nbsp;</span>

    <!-- Destination -->
    <span class="d-inline-block text-nowrap" *ngIf="praise.praisedUser && showDestinationUser">
      <span>to</span>
      <span class="area-destination-user">
        <span class="user-picture">
          <app-profile-photo [src]="praise.praisedUser.imageUrl" [firstName]="praise.praisedUser.firstName" [lastName]="praise.praisedUser.lastName"></app-profile-photo>
        </span>
        <span>&nbsp;{{praise.praisedUser.firstName}} {{praise.praisedUser.lastName}}</span>
      </span>
    </span>

    <!-- Source user -->
    <span class="d-inline-block text-nowrap" *ngIf="praise.senderUser && showSourceUser">
      <span>by</span>
      <span class="area-source-user">
        <span class="user-picture">
          <app-profile-photo [src]="praise.senderUser.imageUrl" [firstName]="praise.senderUser.firstName" [lastName]="praise.senderUser.lastName"></app-profile-photo>
        </span>
        <span>&nbsp;{{praise.senderUser.firstName}} {{praise.senderUser.lastName}}</span>
      </span>
    </span>

    <!-- Timestamp -->
    <span class="d-inline-block text-nowrap" *ngIf="praise.timestamp">
      <span>on&nbsp;</span>
      <span class="area-timestamp">{{praise.timestamp | date}}</span>
    </span>
  </div>

  <!-- Message -->
  <div class="area-message">
    <div class="feedback-question">What have they done that deserves praise?</div>
    <div class="feedback-answer">
      <ng-container *ngIf="praise.message; else noAnswer;">
        <app-rich-display [text]="praise.message"></app-rich-display>
      </ng-container>
    </div>
  </div>

  <!-- Values -->
  <div class="area-values">
    <div class="feedback-question">Which company values did they demonstrate?</div>
    <div class="feedback-answer">
      <ng-container *ngIf="praise.values; else noAnswer;">
        <div class="p-top-5" *ngIf="praise.values.length > 0; else noAnswer">
          <ng-container *ngFor="let v of praise.values">
            <span class="interest-item">{{v.text}}</span>
          </ng-container>
        </div>
      </ng-container>
    </div>
  </div>
</div>

<ng-template #noAnswer>
  <div>N/A</div>
</ng-template>