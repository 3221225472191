<div class="display-container" *ngIf="idea">
  <!-- Sent by X to Y on Date -->
  <div class="area-metadata" *ngIf="(sourceUser && showSourceUser) || (destinationUser && showDestinationUser) || (idea.timestamp)">
    <span>Sent&nbsp;</span>

    <!-- Destination -->
    <span class="text-nowrap" *ngIf="destinationUser && showDestinationUser">
      <span>to</span>
      <span class="area-destination-user">
        <span class="user-picture">
          <!-- TODO: Add firstName and lastName -->
          <app-profile-photo [src]="destinationUser.imageUrl"></app-profile-photo>
        </span>
        <span>&nbsp;{{destinationUser.firstName}} {{destinationUser.lastName}}</span>
      </span>
    </span>

    <!-- Source user -->
    <span class="text-nowrap" *ngIf="sourceUser && showSourceUser">
      <span>by</span>
      <span class="area-source-user">
        <span class="user-picture">
          <!-- TODO: Add firstName and lastName -->
          <app-profile-photo [src]="sourceUser.imageUrl"></app-profile-photo>
        </span>
        <span>&nbsp;{{sourceUser.firstName}} {{sourceUser.lastName}}</span>
      </span>
    </span>

    <!-- Timestamp -->
    <span class="text-nowrap" *ngIf="idea.timestamp">
      <span>on&nbsp;</span>
      <span class="area-timestamp">{{idea.timestamp | date}}</span>
    </span>
  </div>

  <!-- Message -->
  <div class="area-message">
    <div class="feedback-question">Lets hear your idea</div>
    <div class="feedback-answer">
      <ng-container *ngIf="idea.message; else noAnswer;">
        <app-rich-display [text]="idea.message"></app-rich-display>
      </ng-container>
    </div>
  </div>
  
  <!-- Effect -->
  <div class="area-effect">
    <div class="feedback-question">What do you think this idea would improve?</div>
    <div class="feedback-answer">
      <ng-container *ngIf="idea.ideaEffect; else noAnswer;">
        <ng-container *ngIf="(eIdeaEffect[idea.ideaEffect] !== eIdeaEffect.OTHER); else displayEffectCustom">
          <div>{{eIdeaEffect[idea.ideaEffect]}}</div>
        </ng-container>
        <ng-template #displayEffectCustom>
          <div>{{idea.ideaEffectCustom}}</div>
        </ng-template>
      </ng-container>
    </div>
  </div>
  
  <!-- Values -->
  <div class="area-values">
    <div class="feedback-question">Does this idea demonstrate certain company values?</div>
    <div class="feedback-answer">
      <ng-container *ngIf="idea.values; else noAnswer;">
        <div class="p-top-5" *ngIf="idea.values.length > 0; else noAnswer">
          <ng-container *ngFor="let v of idea.values">
            <span class="interest-item">{{v.text}}</span>
          </ng-container>
        </div>
      </ng-container>
    </div>
  </div>
</div>

<ng-template #noAnswer>
  <div>N/A</div>
</ng-template>