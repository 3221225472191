import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SentimentScaleOption } from '../../model/sentiment-scale-option.model';
import { IState } from '@app/models/state/state.model';
import { SentimentScaleMessages } from '../../locale/sentiment-scale.messages';
import { CommonMessages } from '@app/constants/common.messages';
import { CreateSentimentScaleOption } from '../../model/create-sentiment-scale.model';

export enum CreateSentimentScaleOptionComponentAction {
  REMOVE = 'REMOVE'
}

@Component({
  selector: 'app-create-sentiment-scale-option',
  templateUrl: './create-sentiment-scale-option.component.html',
  styleUrls: ['./create-sentiment-scale-option.component.scss']
})
export class CreateSentimentScaleOptionComponent implements OnInit {
  
  public readonly eCreateSentimentScaleOptionComponentAction = CreateSentimentScaleOptionComponentAction;

  public static getDefaultColourForIndex(index: number): string {
    const colours = [
      '#54C6BB', // Green
      '#FEBC56', // Yellow
      '#F75757', // Red
      '#254DC6', // Blue
      '#012139', // Navy
    ];

    return colours[index % colours.length];
  }

  public static initForm(index: number, sentimentScaleOption?: SentimentScaleOption): FormGroup {
    const defaultColour = CreateSentimentScaleOptionComponent.getDefaultColourForIndex(index);
    const formGroup = new FormGroup({
      id: new FormControl(null),
      name: new FormControl('', [ Validators.required, Validators.maxLength(255) ]),
      description: new FormControl('', [ Validators.maxLength(1000) ]),
      score: new FormControl(index + 1, [ Validators.required ]),
      colour: new FormControl(defaultColour, [ Validators.required ])
    });

    if (sentimentScaleOption) {
      formGroup.controls.id.setValue(sentimentScaleOption.id);
      formGroup.controls.name.setValue(sentimentScaleOption.name);
      formGroup.controls.description.setValue(sentimentScaleOption.description);
      formGroup.controls.score.setValue(sentimentScaleOption.score);
      // formGroup.controls.orderIndex.setValue(sentimentScaleOption.orderIndex);
      formGroup.controls.colour.setValue(sentimentScaleOption.colour);
    }

    return formGroup;
  }

  public static parseFormToCreateDto(form: FormGroup, orderIndex: number): CreateSentimentScaleOption {
    return {
      name: form.controls.name.value,
      description: form.controls.description.value,
      score: form.controls.score.value,
      orderIndex: orderIndex,
      colour: form.controls.colour.value
    };
  }

  public readonly eSentimentScaleMessages = SentimentScaleMessages;
  public readonly eCommonMessages = CommonMessages;

  @Input() form: FormGroup;
  @Input() submitted: boolean;

  @Output() onClickAction: EventEmitter<CreateSentimentScaleOptionComponentAction>;

  state: IState;

  get controlId(): FormControl {
    return this.form.get('id') as FormControl;
  }

  get controlName(): FormControl {
    return this.form.get('name') as FormControl;
  }

  get controlDescription(): FormControl {
    return this.form.get('description') as FormControl;
  }

  get controlScore(): FormControl {
    return this.form.get('score') as FormControl;
  }

  get controlOrderIndex(): FormControl {
    return this.form.get('orderIndex') as FormControl;
  }

  get controlColour(): FormControl {
    return this.form.get('colour') as FormControl;
  }

  constructor() {
    this.submitted = false;
    this.state = {
      loading: true,
      error: false,
      errorMessage: ''
    };
    this.onClickAction = new EventEmitter<CreateSentimentScaleOptionComponentAction>();
    this.form = CreateSentimentScaleOptionComponent.initForm(0);
  }

  ngOnInit(): void {
    this.state.loading = false;
  }

  clickAction(action: CreateSentimentScaleOptionComponentAction): void {
    this.onClickAction.emit(action);
  }

}
