import { Injectable } from '@angular/core';
import { AuthMessages } from '@app/auth/auth.messages';
import { ChangePasswordDto } from '@app/models/user/change-password.dto';
import { User } from '@app/models/user/user.model';
import { UserAPIService } from '@app/shared/api/user.api.service';
import { NotifyUtils } from '@app/shared/utils/notify.utils';
import { SecuritySettingsAPIService } from '../../api/security-settings/security-settings-api.service';

@Injectable({
  providedIn: 'root'
})
export class SecuritySettingsBusinessService {

  private changingPassword: boolean;

  constructor(
    private securitySettingsAPIService: SecuritySettingsAPIService,
    private userAPIService: UserAPIService,
    private notifyUtils: NotifyUtils
  ) { }

  updatePassword(changePasswordDto: ChangePasswordDto): Promise<User> {
    if (this.changingPassword) { return new Promise((resolve, reject) => reject()); }
    this.changingPassword = true;

    return this.securitySettingsAPIService.updatePassword(changePasswordDto).toPromise()
      .then(res => {
        this.notifyUtils.notify(AuthMessages.SUCCESS_CHANGE_PASSWORD);
        this.userAPIService.getUserInit().toPromise().then();
        return res;
      })
      .finally(() => {
        this.changingPassword = false;
      });
  }
}
