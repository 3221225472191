import { Component, OnDestroy, OnInit } from '@angular/core';
import { GoalsAPIService } from 'app/shared/api/goals.api.service';
import { CompanyFeatures } from '@app/models/company-features.model';
import { Globals } from 'app/shared/globals/globals';
import { GoalDraft } from '@app/models/goals/goal-draft.model';
import { SwalUtils } from '@app/shared/utils/swal.utils';
import { GoalCreateService } from '../../goals-create-old/goals-create.service';
import { GoalType } from '@app/models/goals/goal-type.enum';
import { NotifyUtils } from '@app/shared/utils/notify.utils';
import { PagingParams } from '@app/models/api/paging-params.model';
import { Page } from '@app/models/api/page.model';
import { GoalPriority } from '@app/models/goals/goal-priority.model';
import { GoalVisibility } from '@app/models/goals/goal-visibility.model';
import { IState } from '@app/models/state/state.model';
import { ColumnInfo } from '@app/shared/column-toggle-new/column.info.model';
import { GoalsMessages } from '@app/goals/goals.messages';
import { CommonMessages } from '@app/constants/common.messages';
import { IconHoverColor } from '@app/shared/components/inputs/table-action-icon/table-action-icon.component';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TableSort } from '@app/models/table-sort';
import { SortDirection } from '@app/models/api/sort-direction.enum';
import { SortingParams } from '@app/models/api/sorting-params.model';
import { PillType } from '@app/shared/components/pill/pill.component';

enum TableColumn {
  TITLE,
  PRIORITY,
  TYPE,
  VISIBILITY,
  // KEY_RESULTS,
  OWNERS,
  // TAGS,
  DUE_DATE,
  ACTIONS
}

interface State extends IState {
  sumbitting: boolean;
}

interface PageDraft extends GoalDraft {
  dropdownOpen: boolean;
}

@Component({
  selector: 'app-goals-overview-drafts-component',
  templateUrl: './goals-overview-drafts.component.html',
  styleUrls: ['./goals-overview-drafts.component.scss'],
})
export class GoalsOverviewDraftsComponent implements OnInit, OnDestroy {

  public readonly eGoalVisibility = GoalVisibility;
  public readonly eIconHoverColor = IconHoverColor;
  public readonly eCommonMessages = CommonMessages;
  public readonly eGoalsMessages = GoalsMessages;
  public readonly eGoalPriority = GoalPriority;
  public readonly eTableColumn = TableColumn;
  public readonly eFeature = CompanyFeatures;
  public readonly eGoalType = GoalType;
  public readonly ePillType = PillType;

  private readonly ngUnsubscribe$ = new Subject<void>();

  goalDraftPage?: Page<PageDraft>;
  goalDrafts: PageDraft[];
  pagingParams: PagingParams;

  state: State;

  columnInfo: ColumnInfo[];

  sortingParams: SortingParams;
  sort: TableSort;

  constructor(
    private goalsAPIService: GoalsAPIService,
    private goalCreateService: GoalCreateService,
    public globals: Globals,
    private swalUtils: SwalUtils,
    private notifyUtils: NotifyUtils
  ) {
    this.goalDrafts = [];
    
    this.pagingParams = {
      pageSize: 15,
      pageNumber: 0
    };

    this.sortingParams = {
      sortAttributes: ['priority'],
      sortDirection: SortDirection.ASC
    };
    this.sort = {
      column: TableColumn.DUE_DATE,
      ascending: true,
    };

    this.state = {
      loading: true,
      error: false,
      errorMessage: '',
      sumbitting: false
    };

    this.columnInfo = this.getColunnInfo();
  }

  ngOnInit() {
    this.fetchDraftGoals();

    this.goalCreateService.getGoalSaved()
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(() => this.fetchDraftGoals());

    this.goalCreateService.getRefreshGoals()
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(() => this.fetchDraftGoals());
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.unsubscribe();
  }

  fetchDraftGoals() {
    this.state.loading = true;
    this.goalsAPIService.getDraftGoals(this.pagingParams, this.sortingParams)
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(goalDraftPage => {
        const pageMapped: Page<PageDraft> = goalDraftPage as Page<PageDraft>;
        pageMapped.content = this.parseDraftToPageDraftMultiple(goalDraftPage.content);

        this.goalDraftPage = pageMapped;
        this.goalDrafts = pageMapped.content;
        this.state.loading = false;
      });
  }

  editDraftGoal(goal: GoalDraft) {
    this.goalCreateService.sendGoalDraftEdited(goal);
  }

  deleteDraftGoal(goal: GoalDraft) {
    if (this.state.sumbitting) { return; }

    this.state.sumbitting = true;

    this.swalUtils.displayWarningConfirmationSwal({
      title: 'Are you sure?',
      text: 'Your draft goal will be deleted',
      imageUrl: 'assets/img/swal-icons/frankli-warning-icon.svg',
      imageWidth: 140,
      imageHeight: 140,
      showCancelButton: true,
      confirmButtonColor: '#30747F',
      confirmButtonText: 'Delete Draft',
    }).then((result) => {
      if (result.isConfirmed) {
        this.goalsAPIService.deleteDraftGoalById(goal.id!).subscribe((response: GoalDraft) => {
          this.notifyUtils.notify('Draft successfully deleted');
          this.state.sumbitting = false;
          this.goalCreateService.sendGoalSaved(response);
        }, () => {
          this.state.sumbitting = false;
        });
      } else {
        this.state.sumbitting = false;
      }
    });
  }

  changePageSize($event: number) {
    this.pagingParams.pageSize = $event;
    this.fetchDraftGoals();
  }

  changePageNumber($event: number) {
    this.pagingParams.pageNumber = $event;
    this.fetchDraftGoals();
  }

  getColunnInfo(): ColumnInfo[] {
    return [
      {
        index: TableColumn.OWNERS,
        titleMessageCode: GoalsMessages.OWNERS
      },
      {
        index: TableColumn.PRIORITY,
        titleMessageCode: GoalsMessages.PRIORITY
      },
      {
        index: TableColumn.TYPE,
        titleMessageCode: GoalsMessages.TYPE
      },
      {
        index: TableColumn.TITLE,
        titleMessageCode: GoalsMessages.TITLE
      },
      // {
      //   index: TableColumn.KEY_RESULTS,
      //   titleMessageCode: GoalsMessages.KEY_RESULTS
      // },
      {
        index: TableColumn.VISIBILITY,
        titleMessageCode: GoalsMessages.VISIBILITY
      },
      // {
      //   index: TableColumn.TAGS,
      //   titleMessageCode: GoalsMessages.TAGS
      // },
      {
        index: TableColumn.DUE_DATE,
        titleMessageCode: GoalsMessages.DUE_DATE
      },
      {
        index: TableColumn.ACTIONS,
        titleMessageCode: CommonMessages.ACTIONS
      }
    ];
  }

  doSort(column: TableColumn) {
    if (this.sort.column === column) {
      this.sort.ascending = !this.sort.ascending;
      this.sortingParams.sortDirection = this.sortingParams.sortDirection === SortDirection.ASC ? SortDirection.DESC : SortDirection.ASC;
    } else {
      this.sort.column = column;
      this.sort.ascending = true;
      this.sortingParams.sortAttributes = this.getSortAttributesForCurrentColumn(column);
      this.sortingParams.sortDirection = SortDirection.ASC;
    }
    this.fetchDraftGoals();
  }

  getSortAttributesForCurrentColumn(column: TableColumn): string[] {
    switch (column) {
      case TableColumn.TITLE:
        return ['title'];
      case TableColumn.PRIORITY:
        return ['priority'];
      case TableColumn.TYPE:
        return ['type'];
      case TableColumn.VISIBILITY:
        return ['visibility'];
      case TableColumn.DUE_DATE:
        return ['endDate'];
      default:
        return [];
    }
  }

  parseDraftToPageDraftMultiple(drafts: GoalDraft[]): PageDraft[] {
    return drafts.map(d => this.parseDraftToPageDraftSingle(d));
  }
  parseDraftToPageDraftSingle(draft: GoalDraft): PageDraft {
    const output = draft as PageDraft;

    if (output) {
      output.dropdownOpen = false;
    }

    return output;
  }
}