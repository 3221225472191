<div class="tag-picker-container" [class.tag-picker-disabled]="state.disabled">
  <div class="search-input-container" [class.picker-has-value]="_value && (_value.length > 0)">
    <div class="value-preview" [class.can-select-multiple]="canSelectMultiple" (click)="searchInput.focus()">
      <ng-container *ngIf="_value">
        <ng-container *ngFor="let tag of _value">
          <div class="tag-item-selected" [tooltip]="canSelectMultiple ? (eCommonMessages.REMOVE | translate) : ''" [triggers]="globals.tooltipTriggers" (click)="removeSelectedItem(tag, $event)">
            <app-tag-display [tag]="tag" [showDescription]="false" [padding]="'0px'"></app-tag-display>
          </div>
        </ng-container>
      </ng-container>
      <input class="search-input" [class.has-value]="_value.length > 0" [class.search-invisible]="!state.dropdownOpen" [class.search-floats]="!canSelectMultiple"
        [formControl]="searchControl" (keyup.enter)="trySelectFirst()" (keyup.backspace)="onKeyupBackspace()" (keyUp.escape)="onKeyupEscape()" (focus)="onFocusSearch()"
        (blur)="onBlurSearch()" [placeholder]="(_value.length === 0) ? (searchPlaceholder | translate) : ''" #searchInput/>
    </div>
    <ng-container *ngIf="state.dropdownOpen; else templateClearIcon;">
      <span class="icon-input-search fal fa-fw fa-search"></span>
    </ng-container>
    <ng-template #templateClearIcon>
      <span class="icon-input-clear fal fa-fw fa-times" *ngIf="showClearIcon" (click)="clearInput()"></span>
    </ng-template>
  </div>

  <div class="search-results-container" [class.search-results-float]="showResultsFloating" *ngIf="state.dropdownOpen && !state.disabled">
    <div class="tag-list">
      <ng-container *ngIf="!state.loading; else templateStateLoading;">
        <ng-container *ngIf="!state.searchRunning; else templateSearchRunning;">
          <ng-container *ngIf="(results.length > 0) || (searchControl.value !== '' && searchControl.value !== null) || (searchControl.value === '' && results.length > 0); else templateNoSearchValue;">
            <ng-container *ngIf="(results.length > 0); else templateNoSearchResults;">
              <div class="tag-result-item" *ngFor="let tag of results" (click)="selectItem(tag)">
                <app-tag-display [tag]="tag" [padding]="'0px'"></app-tag-display>
              </div>
            </ng-container>
          </ng-container>
        </ng-container>
      </ng-container>

      <ng-template #templateNoSearchValue>
        <div class="text-center p-15">
          <span translate>{{eCommonMessages.START_TYPING_TO_SEARCH_OR_ENTER}}</span>
        </div>
      </ng-template>

      <ng-template #templateNoSearchResults>
        <div class="text-center p-15">
          <span translate>{{eCommonMessages.NO_SEARCH_ROLES}}</span>
        </div>
      </ng-template>

      <ng-template #templateSearchRunning>
        <div class="text-center p-15">
          <span class="fas fa-spin fa-spinner fa-fw"></span>
        </div>
      </ng-template>

      <ng-template #templateStateLoading>
        <div class="text-center p-15">
          <span class="fas fa-spin fa-spinner fa-fw"></span>
        </div>
      </ng-template>
    </div>
  </div>
</div>
