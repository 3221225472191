import moment from 'moment';

export class EvaluagentToken {
  id: number;
  apiKey: string;
  apiSecret: string;
  connected: boolean;
  lastUpdated: Date;
  error: string | null;
  syncing: boolean;
  lastSynced: Date | null;

  constructor(evaluagentTokenServerSide: EvaluagentTokenServerSide) {
    if (!evaluagentTokenServerSide) { return undefined; }

    this.id = evaluagentTokenServerSide.id;
    this.apiKey = evaluagentTokenServerSide.apiKey;
    this.apiSecret = evaluagentTokenServerSide.apiSecret;
    this.connected = evaluagentTokenServerSide.connected;
    this.lastUpdated = moment.utc(evaluagentTokenServerSide.lastUpdated).toDate();
    this.error = evaluagentTokenServerSide.error;
    this.syncing = evaluagentTokenServerSide.syncing;
    this.lastSynced = evaluagentTokenServerSide.lastSynced ? moment.utc(evaluagentTokenServerSide.lastSynced).toDate() : null;
  }
}

export interface EvaluagentTokenServerSide {
  id: number;
  apiKey: string;
  apiSecret: string;
  connected: boolean;
  lastUpdated: string;
  error: string | null;
  syncing: boolean;
  lastSynced: string | null;
}

export interface UpdateEvaluagentToken {
  apiKey: string;
  apiSecret: string;
}
