<div class="step-details-container" *ngIf="modules && modules.length > 0">
  <ng-container *ngFor="let step of modules; first as isFirst; last as isLast;">
    <span class="step-details-item" [class.step-details-item-active]="!isOpenCycle && (step.state === eEvaluationModuleState.ACTIVATED)">
      <span class="fal fa-fw" [ngClass]="eEvaluationUtils.getIconForModuleType(step.evaluationModuleType)"></span>
      <span class="m-right-5"></span>
      <strong>{{step.friendlyName}}</strong>
      <div>({{step.startDateTime | date}} - {{step.endDateTime | date}})</div>
      <div class="p-top-10" *ngIf="(calibrationEnabled && (step.evaluationModuleType === eEvaluationModuleType.MANAGER_REVIEW))">
        <app-pill [type]="ePillType.GREEN">
          <small><strong>Calibrated</strong></small>
        </app-pill>
      </div>
      <div class="p-top-10" *ngIf="[eEvaluationModuleType.UPWARD_REVIEW, eEvaluationModuleType.PEER_REVIEW].includes(step.evaluationModuleType) && (step.reviewSubjectAccessType !== eReviewSubjectAccessType.LEGACY)">
        <ng-container [ngSwitch]="step.reviewSubjectAccessType">
          <app-pill *ngSwitchCase="eReviewSubjectAccessType.MANAGER_SUMMARY" [type]="ePillType.GREEN">
            <small><strong>Manager writes summary</strong></small>
          </app-pill>
          <app-pill *ngSwitchCase="eReviewSubjectAccessType.MANAGER_APPROVAL" [type]="ePillType.GREEN">
            <small><strong>Manager can share</strong></small>
          </app-pill>
          <app-pill *ngSwitchCase="eReviewSubjectAccessType.SUBJECT_ACCESS" [type]="ePillType.GREEN">
            <small><strong>Automatically shared</strong></small>
          </app-pill>
        </ng-container>
      </div>
    </span>
    <span class="step-progression-arrow" *ngIf="!isLast && !isOpenCycle">
      <span class="fal fa-fw fa-arrow-right"></span>
    </span>
  </ng-container>
</div>