import { Praise } from '@app/models/feedback/praise.model';
import { mockUsers } from '@app/mock/api/data/mockUsers';
import { mockGoalTags } from '@app/mock/api/data/mockGoalTags';

export const mockPraise: Praise[] =[
  {
    id: 1,
    praisedUser: mockUsers[1],
    senderUser: mockUsers[0],
    message: 'Great work on the latest project!',
    timestamp: new Date(),
    hidden: false,
    values: mockGoalTags,
    reactions: []
  },
  {
    id: 2,
    praisedUser: mockUsers[2],
    senderUser: mockUsers[0],
    message: 'Great work on the latest project!',
    timestamp: new Date(),
    hidden: false,
    values: mockGoalTags,
    reactions: []
  },
  {
    id: 3,
    praisedUser: mockUsers[3],
    senderUser: mockUsers[0],
    message: 'Great work on the latest project!',
    timestamp: new Date(),
    hidden: false,
    values: mockGoalTags,
    reactions: []
  },
  {
    id: 4,
    praisedUser: mockUsers[4],
    senderUser: mockUsers[0],
    message: 'Great work on the latest project!',
    timestamp: new Date(),
    hidden: false,
    values: mockGoalTags,
    reactions: []
  },
  {
    id: 5,
    praisedUser: mockUsers[5],
    senderUser: mockUsers[0],
    message: 'Great work on the latest project!',
    timestamp: new Date(),
    hidden: false,
    values: mockGoalTags,
    reactions: []
  },
  {
    id: 6,
    praisedUser: mockUsers[6],
    senderUser: mockUsers[0],
    message: 'Great work on the latest project!',
    timestamp: new Date(),
    hidden: false,
    values: mockGoalTags,
    reactions: []
  },
  {
    id: 7,
    praisedUser: mockUsers[7],
    senderUser: mockUsers[0],
    message: 'Great work on the latest project!',
    timestamp: new Date(),
    hidden: false,
    values: mockGoalTags,
    reactions: []
  },
  {
    id: 8,
    praisedUser: mockUsers[8],
    senderUser: mockUsers[0],
    message: 'Great work on the latest project!',
    timestamp: new Date(),
    hidden: false,
    values: mockGoalTags,
    reactions: []
  },
  {
    id: 9,
    praisedUser: mockUsers[9],
    senderUser: mockUsers[0],
    message: 'Great work on the latest project!',
    timestamp: new Date(),
    hidden: false,
    values: mockGoalTags,
    reactions: []
  },
  {
    id: 10,
    praisedUser: mockUsers[10],
    senderUser: mockUsers[0],
    message: 'Great work on the latest project!',
    timestamp: new Date(),
    hidden: false,
    values: mockGoalTags,
    reactions: []
  },

  {
    id: 10,
    praisedUser: mockUsers[10],
    senderUser: mockUsers[0],
    message: 'Great work on the latest project!',
    timestamp: new Date(),
    hidden: false,
    values: mockGoalTags,
    reactions: []
  },
  {
    id: 11,
    praisedUser: mockUsers[11],
    senderUser: mockUsers[0],
    message: 'Great work on the latest project!',
    timestamp: new Date(),
    hidden: false,
    values: mockGoalTags,
    reactions: []
  },
  {
    id: 12,
    praisedUser: mockUsers[12],
    senderUser: mockUsers[0],
    message: 'Great work on the latest project!',
    timestamp: new Date(),
    hidden: false,
    values: mockGoalTags,
    reactions: []
  },
  {
    id: 13,
    praisedUser: mockUsers[13],
    senderUser: mockUsers[0],
    message: 'Great work on the latest project!',
    timestamp: new Date(),
    hidden: false,
    values: mockGoalTags,
    reactions: []
  },
  {
    id: 14,
    praisedUser: mockUsers[14],
    senderUser: mockUsers[0],
    message: 'Great work on the latest project!',
    timestamp: new Date(),
    hidden: false,
    values: mockGoalTags,
    reactions: []
  },
  {
    id: 15,
    praisedUser: mockUsers[15],
    senderUser: mockUsers[0],
    message: 'Great work on the latest project!',
    timestamp: new Date(),
    hidden: false,
    values: mockGoalTags,
    reactions: []
  },
  {
    id: 16,
    praisedUser: mockUsers[16],
    senderUser: mockUsers[0],
    message: 'Great work on the latest project!',
    timestamp: new Date(),
    hidden: false,
    values: mockGoalTags,
    reactions: []
  },
  {
    id: 17,
    praisedUser: mockUsers[17],
    senderUser: mockUsers[0],
    message: 'Great work on the latest project!',
    timestamp: new Date(),
    hidden: false,
    values: mockGoalTags,
    reactions: []
  },
  {
    id: 18,
    praisedUser: mockUsers[18],
    senderUser: mockUsers[0],
    message: 'Great work on the latest project!',
    timestamp: new Date(),
    hidden: false,
    values: mockGoalTags,
    reactions: []
  },
  {
    id: 19,
    praisedUser: mockUsers[19],
    senderUser: mockUsers[0],
    message: 'Great work on the latest project!',
    timestamp: new Date(),
    hidden: false,
    values: mockGoalTags,
    reactions: []
  },
  {
    id: 20,
    praisedUser: mockUsers[20],
    senderUser: mockUsers[0],
    message: 'Great work on the latest project!',
    timestamp: new Date(),
    hidden: false,
    values: mockGoalTags,
    reactions: []
  },
  {
    id: 21,
    praisedUser: mockUsers[21],
    senderUser: mockUsers[0],
    message: 'Great work on the latest project!',
    timestamp: new Date(),
    hidden: false,
    values: mockGoalTags,
    reactions: []
  },
  {
    id: 22,
    praisedUser: mockUsers[22],
    senderUser: mockUsers[0],
    message: 'Great work on the latest project!',
    timestamp: new Date(),
    hidden: false,
    values: mockGoalTags,
    reactions: []
  },
  {
    id: 23,
    praisedUser: mockUsers[23],
    senderUser: mockUsers[0],
    message: 'Great work on the latest project!',
    timestamp: new Date(),
    hidden: false,
    values: mockGoalTags,
    reactions: []
  },
  {
    id: 24,
    praisedUser: mockUsers[24],
    senderUser: mockUsers[0],
    message: 'Great work on the latest project!',
    timestamp: new Date(),
    hidden: false,
    values: mockGoalTags,
    reactions: []
  },
  {
    id: 25,
    praisedUser: mockUsers[25],
    senderUser: mockUsers[0],
    message: 'Great work on the latest project!',
    timestamp: new Date(),
    hidden: false,
    values: mockGoalTags,
    reactions: []
  },
  {
    id: 26,
    praisedUser: mockUsers[26],
    senderUser: mockUsers[0],
    message: 'Great work on the latest project!',
    timestamp: new Date(),
    hidden: false,
    values: mockGoalTags,
    reactions: []
  },
  {
    id: 27,
    praisedUser: mockUsers[27],
    senderUser: mockUsers[0],
    message: 'Great work on the latest project!',
    timestamp: new Date(),
    hidden: false,
    values: mockGoalTags,
    reactions: []
  },
  {
    id: 28,
    praisedUser: mockUsers[28],
    senderUser: mockUsers[0],
    message: 'Great work on the latest project!',
    timestamp: new Date(),
    hidden: false,
    values: mockGoalTags,
    reactions: []
  },
  {
    id: 29,
    praisedUser: mockUsers[29],
    senderUser: mockUsers[0],
    message: 'Great work on the latest project!',
    timestamp: new Date(),
    hidden: false,
    values: mockGoalTags,
    reactions: []
  },
  {
    id: 30,
    praisedUser: mockUsers[0],
    senderUser: mockUsers[1],
    message: 'Great job on finding Frankli for us!',
    timestamp: new Date(),
    hidden: false,
    values: mockGoalTags,
    reactions: []
  },
  {
    id: 31,
    praisedUser: mockUsers[0],
    senderUser: mockUsers[2],
    message: 'We were recently nominated for a Best Place to Work award and since i\'ve joined the company I can see the direct impact Trixy has on the leadership team, our company culture and the employees as a whole. Thank you Trixy!',
    timestamp: new Date(),
    hidden: false,
    values: mockGoalTags,
    reactions: []
  }
];