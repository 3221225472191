import { OneToOneMeeting } from '@app/domain/one_to_one/model/one-to-one-meeting.model';
import { OneToOneMeetingStatus } from '@app/domain/one_to_one/model/one-to-one-meeting-status.model';
import { mockUsers } from '@app/mock/api/data/mockUsers';
import { mockEmployeeLedTemplateTalkingPoints, mockCoachingTemplateTalkingPoints, mockMentoringTemplateTalkingPoints, mockPerformanceReviewTemplateTalkingPoints } from './one-to-one-recommended-talking-points.mock';
import moment from 'moment';

export const mockOneToOneMeetings: OneToOneMeeting[] = [
  {
    id: 1,
    talkingPoints: mockEmployeeLedTemplateTalkingPoints.map(tp => ({
      id: tp.id,
      actioned: true,
      recurring: true,
      title: tp.text,
      hasRelatedGoals: false,
      fromPrevious: false,
      sourceUserId: mockUsers[0].id,
      comment: '',
      managerComment: '',
      comments: []
    })),
    // sharedUserNotes: 'These are notes shared by the 1:1 participant that are visible to both the 1:1 Creator and Participant.',
    // sharedManagerNotes: 'These are notes shared by the 1:1 creator that are visible to both the 1:1 Creator and Participant.',
    // userNotes: 'These are notes created by the 1:1 participant that are visible only to the Participant.',
    // managerNotes: 'These are notes created by the 1:1 creator that are visible only to the Creator.',
    sharedNotes: [],
    // privateNotes: [],
    completedTimestamp: moment().subtract(6, 'days').toDate(),
    meetingTimestamp: moment().subtract(1, 'week').toDate(),
    status: OneToOneMeetingStatus.COMPLETED
  },
  {
    id: 2,
    talkingPoints: mockEmployeeLedTemplateTalkingPoints.map(tp => ({
      id: tp.id,
      actioned: false,
      recurring: true,
      title: tp.text,
      hasRelatedGoals: false,
      fromPrevious: false,
      sourceUserId: mockUsers[0].id,
      comment: '',
      managerComment: '',
      comments: []
    })),
    // sharedUserNotes: 'These are notes shared by the 1:1 participant that are visible to both the 1:1 Creator and Participant.',
    // sharedManagerNotes: 'These are notes shared by the 1:1 creator that are visible to both the 1:1 Creator and Participant.',
    // userNotes: 'These are notes created by the 1:1 participant that are visible only to the Participant.',
    // managerNotes: 'These are notes created by the 1:1 creator that are visible only to the Creator.',
    sharedNotes: [],
    // privateNotes: [],
    meetingTimestamp: moment().add(10, 'minutes').toDate(),
    status: OneToOneMeetingStatus.IN_PROGRESS
  },
  {
    id: 3,
    talkingPoints: mockCoachingTemplateTalkingPoints.map(tp => ({
      id: tp.id,
      actioned: false,
      recurring: true,
      title: tp.text,
      hasRelatedGoals: false,
      fromPrevious: false,
      sourceUserId: mockUsers[0].id,
      comment: '',
      managerComment: '',
      comments: []
    })),
    // sharedUserNotes: 'These are notes shared by the 1:1 participant that are visible to both the 1:1 Creator and Participant.',
    // sharedManagerNotes: 'These are notes shared by the 1:1 creator that are visible to both the 1:1 Creator and Participant.',
    // userNotes: 'These are notes created by the 1:1 participant that are visible only to the Participant.',
    // managerNotes: 'These are notes created by the 1:1 creator that are visible only to the Creator.',
    sharedNotes: [],
    // privateNotes: [],
    meetingTimestamp: moment().add(1, 'week').toDate(),
    status: OneToOneMeetingStatus.SCHEDULED
  },
  {
    id: 4,
    talkingPoints: mockMentoringTemplateTalkingPoints.map(tp => ({
      id: tp.id,
      actioned: false,
      recurring: true,
      title: tp.text,
      hasRelatedGoals: false,
      fromPrevious: false,
      sourceUserId: mockUsers[0].id,
      comment: '',
      managerComment: '',
      comments: []
    })),
    // sharedUserNotes: 'These are notes shared by the 1:1 participant that are visible to both the 1:1 Creator and Participant.',
    // sharedManagerNotes: 'These are notes shared by the 1:1 creator that are visible to both the 1:1 Creator and Participant.',
    // userNotes: 'These are notes created by the 1:1 participant that are visible only to the Participant.',
    // managerNotes: 'These are notes created by the 1:1 creator that are visible only to the Creator.',
    sharedNotes: [],
    // privateNotes: [],
    meetingTimestamp: moment().add(2, 'weeks').toDate(),
    status: OneToOneMeetingStatus.SCHEDULED
  },
  {
    id: 5,
    talkingPoints: mockPerformanceReviewTemplateTalkingPoints.map(tp => ({
      id: tp.id,
      actioned: false,
      recurring: true,
      title: tp.text,
      hasRelatedGoals: false,
      fromPrevious: false,
      sourceUserId: mockUsers[0].id,
      comment: '',
      managerComment: '',
      comments: []
    })),
    // sharedUserNotes: 'These are notes shared by the 1:1 participant that are visible to both the 1:1 Creator and Participant.',
    // sharedManagerNotes: 'These are notes shared by the 1:1 creator that are visible to both the 1:1 Creator and Participant.',
    // userNotes: 'These are notes created by the 1:1 participant that are visible only to the Participant.',
    // managerNotes: 'These are notes created by the 1:1 creator that are visible only to the Creator.',
    sharedNotes: [],
    // privateNotes: [],
    meetingTimestamp: moment().add(1, 'week').toDate(),
    status: OneToOneMeetingStatus.SCHEDULED
  }
];