<div class="container-evaluation-feedback-request-display" *ngIf="request">
  <div class="top-row" (click)="toggleDropdown()">
    <div class="col-dropdown">
      <app-table-dropdown-toggle [opened]="dropdownOpen" [size]="'30px'"></app-table-dropdown-toggle>
    </div>
    <div class="col-user">
      <div class="owner-img">
        <app-profile-photo [src]="request.authorUser.imageUrl" [firstName]="request.authorUser.firstName" [lastName]="request.authorUser.lastName"></app-profile-photo>
      </div>
      <span class="m-right-10"></span>
      <span>{{request.authorUser.firstName}} {{request.authorUser.lastName}}</span>
    </div>
    <div class="col-submitted-date">
      <!-- This data no exist :( -->
    </div>
  </div>
  <div class="questions-list" *ngIf="dropdownOpen">
    <ng-container *ngIf="request.state === eEvaluationFeedbackRequestState.COMPLETED && response; else templateNoResponse;">
      <ng-container *ngFor="let question of response.questionsAnswered; index as index;">
        <div class="question-item" *ngIf="question.question">
          <app-sidebar-question-item [index]="index" [question]="question.question.questionText" [questionType]="question.question.questionBankQuestion.surveyQuestionType" [answer]="question.textAnswer" [comment]="question.comment" [assessmentGroups]="assessmentGroups" [options]="question.question.questionBankQuestion.possibleAnswers"></app-sidebar-question-item>
        </div>
      </ng-container>
    </ng-container>
    <ng-template #templateNoResponse>
      <app-empty-state [message]="'Response is not visible yet'" [height]="'200px'"></app-empty-state>
    </ng-template>
  </div>
</div>