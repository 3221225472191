import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {CommonModule} from '@angular/common';
import {ProfileRoutes} from '../routing/profile.routing';
import { ProfileComponentsModule } from './profile-components.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(ProfileRoutes),
    ProfileComponentsModule
  ]
})
export class ProfileModule { }

