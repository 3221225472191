import {Location} from '@angular/common';
import {Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {Router} from '@angular/router';
import {NewFeature} from '@app/models/new-feature/new-feature.model';
import {FrankliNotificationType} from '@app/models/notifications/frankli-event-type.enum';
import {FrankliNotification} from '@app/models/notifications/frankli-notification.model';
import {CompanyState} from 'app/models/company.model';
import { CompanyFeatures } from '@app/models/company-features.model';
import {RoleName} from '@app/models/user-role.model';
import {User} from 'app/models/user/user.model';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {NewFeatureAPIService} from '../api/new-feature.api.service';
import {AuthAPIService} from '../auth/auth.api.service';
import {Globals} from '../globals/globals';
import {NotificationService} from '../notifications/notification.service';
import {ModalComponent} from '../modal/modal.component';
import {environment} from '../../../environments/environment';
import {SwalUtils} from '../utils/swal.utils';
import {SidebarService} from '../sidebar/sidebar.service';
import {I18nService} from '@app/i18n/i18n.service';
import {BsDropdownConfig} from 'ngx-bootstrap/dropdown';
import {TranslateService} from '@ngx-translate/core';
import {NavbarService} from '@app/services/navbar.service';
import {NavbarMessages} from './navbar.messages';
import {SetUpStepUtils} from '../utils/setupstep.utils';
import { SettingsMessages } from '@app/domain/settings/locale/settings.messages';
import { ButtonType } from '../components/inputs/button/button.component';

const misc: any = {
  navbar_menu_visible: 0,
  active_collapse: true,
  disabled_collapse_init: 0,
};
declare let $: any;

export enum SidebarTabs {
  NOTIFICATIONS = 'NOTIFICATIONS',
  ACTIONS = 'ACTIONS'
}

interface NewFeatureSub {
  unreadCount: number;
  all: NewFeature[];
}

@Component({
  selector: 'app-navbar-component',
  templateUrl: 'navbar.component.html',
  styleUrls: ['navbar.component.scss'],
  providers: [{ provide: BsDropdownConfig, useValue: { isAnimated: true, autoClose: true } }]
})

export class NavbarComponent implements OnInit, OnDestroy {
  public readonly eFrankliNotificationType = FrankliNotificationType;
  public readonly eSettingsMessages = SettingsMessages;
  public readonly eCompanyFeatures = CompanyFeatures;
  public readonly eNavbarMessages = NavbarMessages;
  public readonly eCompanyState = CompanyState;
  public readonly eButtonType = ButtonType;
  public readonly eRoleName = RoleName;

  @ViewChild('newFeaturesModal') newFeaturesModal!: ModalComponent;

  connected: boolean;

  sidebarTab: SidebarTabs;
  notificationsShown: boolean;
  listTitles: any[];
  location: Location;
  nativeElement: Node;
  sidebarIcon = 'left';
  searchValue: string;

  users: Array<User>;
  notifications: Array<FrankliNotification>;
  actions: Array<FrankliNotification>;

  destroy$: Subject<boolean> = new Subject<boolean>();

  @ViewChild('app-navbar-component') button!: ElementRef;

  // marking as read
  markingAsRead: Array<number>;
  // actions that have been clicked on but not actioned
  viewed: Array<number>;

  newFeaturesUnreadCount = 0;
  newFeatures: NewFeatureSub = {
    unreadCount: 0,
    all: []
  };

  // If click event fires inside the component, set to true
  // If click event fires outside, hide notifications then reset variable
  private inside = false;
  isMock = environment.mock.enabled;
  @HostListener('click')
  clickInside() {
    this.inside = true;
  }

  @HostListener('document:click')
  clickOutside() {
    if (this.inside === false) {
      this.notificationsShown = false;
    }
    this.inside = false;
  }

  get isMobileMenu(): boolean {
    if ($ === undefined) { return false; }
    try {
      const isMobile = $(window).width() <= 991;
      return isMobile;
    } catch {
      return false;
    }
  }

  get canSeeUniversalCreate(): boolean {
    if (this.globals.hasFeature(CompanyFeatures.GOALS)) { return true; }
    if (this.globals.hasFeature(CompanyFeatures.ONE_TO_ONE)) { return true; }
    if (this.globals.hasFeature(CompanyFeatures.TODOS)) { return true; }
    if (this.globals.hasFeature(CompanyFeatures.FEEDBACK)) { return true; }
    if (this.globals.hasFeature(CompanyFeatures.SURVEYS)) { return true; }
    if (this.globals.hasMinimumAccessLevelOf(RoleName.ADMIN)) { return true; }

    return false;
  }

  constructor(
    location: Location,
    private element: ElementRef,
    private authService: AuthAPIService,
    private router: Router,
    private title: Title,
    public globals: Globals,
    private notificationService: NotificationService,
    private newFeatureService: NewFeatureAPIService,
    public sidebarService: SidebarService,
    public i18nService: I18nService,
    private translateService: TranslateService,
    private setUpStepUtils: SetUpStepUtils,
    private navbarService: NavbarService,
    private swalUtils: SwalUtils
  ) {
    this.connected = false;
    this.location = location;
    this.nativeElement = element.nativeElement;
    this.notificationsShown = false;
    this.sidebarTab = SidebarTabs.ACTIONS;

    this.users = new Array<User>();
    this.notifications = new Array<FrankliNotification>();
    this.actions = new Array<FrankliNotification>();

    this.markingAsRead = new Array<number>();
    this.viewed = new Array<number>();
    this.listTitles = [];
    this.searchValue = '';

    /**
     * This subscribes the navbar component to the receive live notifications via the notification service
     */

    this.notificationService.getNotifications$().pipe(takeUntil(this.destroy$)).subscribe((notification) => {
      this.handleNotification(notification);
    });

    this.navbarService.openTasks$.pipe(takeUntil(this.destroy$)).subscribe((openTasks) => {
      if(openTasks){
        this.sidebarTab = SidebarTabs.ACTIONS;
        this.notificationsShown = true;
      }
    });
    

    /**
     * If websocket connection closes, handle that here
     */
    this.connected = this.notificationService.isConnected();
    if (this.connected === true) {
      this.init();
    }
    this.notificationService.isConnected$().pipe(takeUntil(this.destroy$)).subscribe((connected: boolean) => {
      this.connected = connected;
      if (this.connected === true) {
        this.init();
      }
    });
  }

  ngOnInit() {
    this.newFeatureService.getAllNewFeatures().subscribe((features) => this.parseNewFeatures(features));
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
    this.notificationsShown = false;
  }

  private init() {
    // clear notifications that might already exist
    this.notifications.length = 0;
    this.actions.length = 0;

    // Handle
    const getN = this.notificationService.getNotifications();
    for (let i = 0; i < getN.length; i++) {
      const notification = getN[i];
      this.handleNotification(notification);
    }
    this.notifications.sort((a, b) => {
      return b.id - a.id;
    });
  }

  private handleNotification(notification: FrankliNotification): void {
    if (notification.eventStatus === 'READ') {
      this.removeNotification(notification);
    } else {
      this.addNotification(notification);
    }
    this.updateTitle();
    this.notifications.sort((a, b) => new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime());
    this.actions.sort((a, b) => new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime());

    // Refresh sidebar numbers on step completion
    if (notification.eventType === FrankliNotificationType.COMPANY_SET_UP_STEP_COMPLETE) {
      this.setUpStepUtils.refreshSetUpSteps().then();
    }
  }

  private removeNotification(notification: FrankliNotification): void {
    if (notification.action === true) {
      this.actions = this.actions.filter(n => n.id !== notification.id);
    } else {
      this.notifications = this.notifications.filter(n => n.id !== notification.id);
    }

    // remove from marking as read and viewed
    this.markingAsRead = this.markingAsRead.filter(m => m !== notification.id);
    this.viewed = this.viewed.filter(m => m !== notification.id);
  }

  private addNotification(notification: FrankliNotification): void {
    if (notification.action) {
      this.actions.unshift(notification);
    } else {
      this.notifications.unshift(notification);
    }
  }

  sidebarToggle() {
    this.sidebarService.toggleSidebar();
  }

  getTitle() {
    let titlee = this.location.prepareExternalUrl(this.location.path());
    if (titlee.charAt(0) === '#') {
      titlee = titlee.slice(2);
    }
    for (let item = 0; item < this.listTitles.length; item++) {
      const parent = this.listTitles[item];
      if (parent.path === titlee) {
        return parent.title;
      } else if (parent.children) {
        const children_from_url = titlee.split('/')[2];
        for (let current = 0; current < parent.children.length; current++) {
          if (parent.children[current].path === children_from_url) {
            return parent.children[current].title;
          }
        }
      }
    }
    return 'Dashboard';
  }

  getPath() {
    return this.location.prepareExternalUrl(this.location.path());
  }

  sidebarToggleIcon() {
    if (this.sidebarIcon === 'left') {
      this.sidebarIcon = 'right';
    } else {
      this.sidebarIcon = 'left';
    }
  }

  checkEnter(event: KeyboardEvent) {
    if (event.key === 'Enter' && this.searchValue !== '' && this.searchValue) {
      const sarg = this.searchValue;
      this.searchValue = '';
      this.router.navigate(['/search-results'], { queryParams: { q: sarg } });
    }
  }

  logout() {
    if (this.globals.hasUnsavedChanges) {
      this.swalUtils.displayWarningConfirmationSwal({
        title: 'Unsaved Changes',
        text: 'Your changes will not be saved if you leave!',
        imageUrl: 'assets/img/swal-icons/frankli-warning-icon.svg',
        imageWidth: 140,
        imageHeight: 140,
        confirmButtonText: 'Stay on page',
        confirmButtonColor: '#30747F',
        cancelButtonText: 'Leave without saving',
        showCancelButton: true,
        width: 390,
        reverseButtons: true
      }).then(val => {
        if (val.dismiss) {
          if (val.dismiss.toString() === 'cancel') {
            this.navbarService.resetTasks();
            this.router.navigate(['/logout']);
          }
        }
      });
    } else {
      this.navbarService.resetTasks();
      this.router.navigate(['/logout']);
    }
  }

  updateTitle() {
    const count = this.notifications.length + this.actions.length;
    if (count > 0) {
      this.title.setTitle('(' + count + ') Frankli');
    } else {
      this.title.setTitle('Frankli');
    }
  }

  public markAsRead(notification: FrankliNotification): void {
    this.markingAsRead.push(notification.id);
    this.notificationService.markAsRead(notification.id);
  }

  public markAllAsRead() {
    this.notifications.forEach(n => this.markingAsRead.push(n.id));
    this.notificationService.markAllAsRead();
  }

  /**
   * Marks a task as viewed (for frontend use only)
   * @param notification
   */
  public markAsViewed(notification: FrankliNotification): void {
    notification.viewed = true;
  }

  openNotificationsPanel() {
    if (!this.notificationsShown) {
      if ((this.actions.length > 0) && (this.notifications.length === 0)) {
        this.sidebarTab = SidebarTabs.ACTIONS;
      } else if ((this.notifications.length > 0) && (this.actions.length === 0)) {
        this.sidebarTab = SidebarTabs.NOTIFICATIONS;
      }
    }

    this.notificationsShown = !this.notificationsShown;
  }

  public onMarkAllAsRead($event: void): void {
    this.markAllAsRead();
  }

  public onMarkAsRead($event: FrankliNotification): void {
    this.markAsRead($event);
  }

  public onMarkAsViewed($event: FrankliNotification): void {
    this.markAsViewed($event);
  }

  parseNewFeatures(features: NewFeature[]) {
    const noArchived = features
      .filter(f => !f.archived)
      .sort((a, b) => {
        // Sort null dates to start
        if (!(a && a.publishDate)) { return -1; }
        if (!(b && b.publishDate)) { return 1; }

        // If both are dates, compare
        const aDate = new Date(a.publishDate).getTime();
        const bDate = new Date(b.publishDate).getTime();

        return bDate - aDate;
      });

    this.newFeaturesUnreadCount = noArchived.filter(f => !f.viewed).length;
    this.newFeatures = {
      unreadCount: this.newFeaturesUnreadCount,
      all: noArchived
    };
  }

  openNewFeatures() {
    if (!this.newFeaturesModal) { return; }

    this.newFeaturesModal.show();
    this.newFeatureService.markAllAsRead()
      .toPromise()
      .then((features) => this.parseNewFeatures(features));
  }

}
