import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonMessages } from '@app/constants/common.messages';
import { TagMessages } from '@app/domain/tag/locale/tag.messages';
import { Tag } from '@app/domain/tag/model/tag.model';
import { Globals } from '@app/shared/globals/globals';
import { IconHoverColor } from '../../inputs/table-action-icon/table-action-icon.component';
import { PillType } from '../../pill/pill.component';

@Component({
  selector: 'app-tag-display',
  templateUrl: './tag-display.component.html',
  styleUrls: ['./tag-display.component.scss']
})
export class TagDisplayComponent {

  public readonly eIconHoverColor = IconHoverColor;
  public readonly eCommonMessages = CommonMessages;
  public readonly eTagMessages = TagMessages;
  public readonly ePillType = PillType;

  @Input() tag: Tag;
  @Input() showType: boolean;
  @Input() showDescription: boolean;
  @Input() padding: string;
  @Input() actionsShown: string[]; // valid options are 'edit' and 'delete'

  @Output() onClickAction: EventEmitter<string>;

  constructor(
    public globals: Globals
  ) {
    this.showType = true;
    this.showDescription = true;
    this.padding = '15px';

    this.actionsShown = [];
    this.onClickAction = new EventEmitter<string>();
  }

  clickAction(action: string): void {
    this.onClickAction.emit(action);
  }
}
