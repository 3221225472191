import { Component, Input } from '@angular/core';
import { EvaluagentEvaluationObject } from '@app/domain/evaluagent/model/evaluagent-evaluation-object.model';

@Component({
  selector: 'app-evaluagent-evaluation-display',
  templateUrl: './evaluagent-evaluation-display.component.html',
  styleUrls: ['./evaluagent-evaluation-display.component.scss']
})
export class EvaluagentEvaluationDisplayComponent {

  @Input() evaluation: EvaluagentEvaluationObject;

  constructor() {
    this.evaluation = undefined;
  }

  navigateToEvaluation(): void {
    if (!this.evaluation) { return; }
    if (!this.evaluation.evaluationId) { return; }

    const url = `https://app.evaluagent.com/quality/evaluations/${this.evaluation.evaluationId}/`;
    window.open(url, '_blank');
  }
}
