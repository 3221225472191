import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CommonMessages } from '@app/constants/common.messages';
import { PillType } from '@app/shared/components/pill/pill.component';
import { Globals } from '@app/shared/globals/globals';
import { DateUtils } from '@app/shared/utils/date.utils';
import { FrankliValidators } from '@app/shared/validators/validators';
import { IMyDate } from 'angular-mydatepicker';
import moment from 'moment';
import { QualificationMessages } from '../../locale/qualification.messages';
import { Qualification } from '../../model/qualification.model';

export const initQualificationForm = (qualification?: Qualification): FormGroup => {
  const formGroup = new FormGroup({
    id: new FormControl(null, []),
    name: new FormControl('', [Validators.required, Validators.maxLength(255)]),
    provider: new FormControl('', [Validators.required, Validators.maxLength(50)]),
    description: new FormControl('', [ FrankliValidators.softMaxValidation(2000), Validators.maxLength(3000) ]),
    receivedDate: new FormControl(moment().subtract(1, 'day'), [ Validators.required ]),
    expirationDate: new FormControl(null, [])
  });

  if (qualification) {
    formGroup.controls.id.setValue(qualification.id, { emitEvent: false });
    formGroup.controls.name.setValue(qualification.name, { emitEvent: false });
    formGroup.controls.provider.setValue(qualification.provider, { emitEvent: false });
    formGroup.controls.description.setValue(qualification.description, { emitEvent: false });
    formGroup.controls.receivedDate.setValue(qualification.receivedDate, { emitEvent: false });
    formGroup.controls.expirationDate.setValue(qualification.expirationDate, { emitEvent: false });
  }

  return formGroup;
};

export enum QualificationCreatePageViews {
  MAIN = 'MAIN'
}

@Component({
  selector: 'app-qualification-create',
  templateUrl: './qualification-create.component.html',
  styleUrls: ['./qualification-create.component.scss']
})
export class QualificationCreateComponent {
  public readonly eQualificationMessages = QualificationMessages;
  public readonly eCommonMessages = CommonMessages;
  public readonly ePillType = PillType;
  public readonly eQualificationCreatePageViews = QualificationCreatePageViews;

  @Input() submitted: boolean;
  @Input() formCreate: FormGroup;
  @Input() categories: string[];

  @Output() onFormCancel: EventEmitter<boolean>;
  @Output() onFormSubmit: EventEmitter<boolean>;
  @Output() requestCloseModal: EventEmitter<boolean>;

  public formChanged: boolean;

  selectedView: QualificationCreatePageViews;

  maxReceivedDate: IMyDate;

  get controlQualificationId(): FormControl {
    return this.formCreate.controls.id as FormControl;
  }

  get controlQualificationName(): FormControl {
    return this.formCreate.controls.name as FormControl;
  }

  get controlQualificationProvider(): FormControl {
    return this.formCreate.controls.provider as FormControl;
  }

  get controlQualificationDescription(): FormControl {
    return this.formCreate.controls.description as FormControl;
  }

  get controlQualificationReceivedDate(): FormControl {
    return this.formCreate.controls.receivedDate as FormControl;
  }

  get controlQualificationExpirationDate(): FormControl {
    return this.formCreate.controls.expirationDate as FormControl;
  }

  constructor(
    public globals: Globals
  ) {
    this.formChanged = false;
    this.submitted = false;

    this.categories = [];

    this.selectedView = QualificationCreatePageViews.MAIN;

    this.onFormCancel = new EventEmitter<boolean>();
    this.onFormSubmit = new EventEmitter<boolean>();
    this.requestCloseModal = new EventEmitter<boolean>();

    this.maxReceivedDate = DateUtils.convertDateMomentToPickerFormat(moment().add(1, 'day'));

    this.formCreate = initQualificationForm();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const changesFormCreate = changes['formCreate'];
    if (changesFormCreate) {
      this.formChanged = false;
      this.initEventListeners();
    }
  }

  initEventListeners(): void {
    this.formCreate.valueChanges.subscribe(() => {
      this.formChanged = true;
    });
  }

  submitForm(): void {
    this.onFormSubmit.emit(true);
  }

  onViewChangeRequested(view: QualificationCreatePageViews): void {
    this.selectedView = view;
  }

  // #region - PRIMARY ACTIONS CALLBACKS AND EMITS
  onCancelCreate(): void {
    this.onFormCancel.emit(true);
  }

  onConfirmCreate(): void {
    this.onFormSubmit.emit(true);
  }

  closeCreateModal() {
    this.requestCloseModal.emit(true);
  }
  // #endregion

  openHelpArticle() {
    const newWindow = window.open('https://help.frankli.io/en/articles/6522341', '_blank');
    
    if (newWindow) {
      newWindow.focus();
    }
  }
}
