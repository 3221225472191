import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'likertComment'
})
export class LikertCommentPipe implements PipeTransform {
    previousValue!: (number | string)[];
    resultCached!: { selection: number; comment: string };

    transform(value: (number | string)[]): { selection: number; comment: string } {
        if (value === this.previousValue) {
            return this.resultCached;
        }

        this.previousValue = value;

        const result = {
            selection: +value[0],
            comment: value[1] as string
        }
        this.resultCached = result;

        return result;
    }
}

