export enum AbsorbLmsEnrollmentStatus {
  NOT_STARTED = 'NOT_STARTED',
  IN_PROGRESS = 'IN_PROGRESS',
  PENDING_APPROVAL = 'PENDING_APPROVAL',
  COMPLETE = 'COMPLETE',
  NOT_COMPLETE = 'NOT_COMPLETE',
  FAILED = 'FAILED',
  DECLINED = 'DECLINED',
  PENDING_EVALUATION_REQUIRED = 'PENDING_EVALUATION_REQUIRED',
  ON_WAITLIST = 'ON_WAITLIST',
  ABSENT = 'ABSENT',
  NOT_APPLICABLE = 'NOT_APPLICABLE',
  PENDING_PROCTOR = 'PENDING_PROCTOR',
  READY_FOR_REVIEW = 'READY_FOR_REVIEW',
}
