import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { OneToOneSchedule } from '../../model/one-to-one-schedule.model';
import { Globals } from '@app/shared/globals/globals';
import { OneToOneStatus } from '../../model/one-to-one-status.model';
import { PillType } from '@app/shared/components/pill/pill.component';
import { OneToOneMessages } from '../../locale/one-to-one.messages';
import { OneToOneScheduleType } from '../../model/one-to-one-schedule-type.model';
import { OneToOneUtilsService } from '../../service/one-to-one-utils.service';
import { OneToOneMeeting } from '../../model/one-to-one-meeting.model';
import { IconHoverColor } from '@app/shared/components/inputs/table-action-icon/table-action-icon.component';
import { OneToOneFrequency } from '../../model/one-to-one-frequency.model';
import { DateUtils } from '@app/shared/utils/date.utils';
import { CommonMessages } from '@app/constants/common.messages';
import moment from 'moment';
import { TerminologyEntity } from '@app/domain/terminology/model/terminology-entity.enum';

enum Status {
  ACTIVE = 'ACTIVE',
  PAUSED = 'PAUSED',
  ARCHIVED = 'ARCHIVED',
  COMPLETED = 'COMPLETED',
  NONE = 'NONE'
}

type DisplayAction = 'view' | 'edit' | 'recreateCalendar' | 'pause' | 'resume' | 'transfer' | 'archive' | 'unarchive' | 'delete';

interface DisplayTypeData {
  iconClass: string;
  tooltip: { code: string, params: { [key: string ]: string } };
}

interface DisplayStatusData {
  iconClass: string;
  tooltip: { code: string, params: { [key: string ]: string } };
  pillType: PillType;
  statusDisplay: Status;
  statusDisplayFriendlyName: string;
}

interface DisplayMeetingData {
  meeting: OneToOneMeeting;
  meetingTimeInTimezone: string;
  isUsingDifferentTimezone: boolean;
}

@Component({
  selector: 'app-one-to-one-schedule-display',
  templateUrl: './one-to-one-schedule-display.component.html',
  styleUrls: ['./one-to-one-schedule-display.component.scss']
})
export class OneToOneScheduleDisplayComponent implements OnChanges {
  public readonly DATE_FORMAT = 'Do MMM YYYY [at] HH:mm';
  public readonly eOneToOneFrequency = OneToOneFrequency;
  public readonly eOneToOneMessages = OneToOneMessages;
  public readonly eOneToOneStatus = OneToOneStatus;
  public readonly eCommonMessages = CommonMessages;
  public readonly eIconHoverColor = IconHoverColor;
  public readonly ePillType = PillType;

  @Input() schedule: OneToOneSchedule;
  @Input() actionsShown: DisplayAction[];
  @Input() showIcon: boolean;
  @Input() showUsers: boolean;
  @Input() isChanging: boolean;

  @Input() canSelect: boolean;
  @Input() canDeselect: boolean;
  @Input() selected: boolean;

  @Output() onClickAction: EventEmitter<string>;

  displayTypeData: DisplayTypeData;
  displayStatusData: DisplayStatusData;
  displayLatestMeetingData: DisplayMeetingData;
  status: Status;

  get scheduleIconStatus(): string {
    return OneToOneUtilsService.getIconClassForOneToOneScheduleStatus(this.schedule.status);
  }

  get canSeeActionView(): boolean {
    if (!this.actionsShown.includes('view')) { return false; } // Action must be flagged for display
    return true;
  }

  get canSeeActionEdit(): boolean {
    if (!this.actionsShown.includes('edit')) { return false; } // Action must be flagged for display
    // if (this.schedule.manager.id !== this.globals.user.id) { return false; } // Must be managing the schedule
    if (this.schedule.status === OneToOneStatus.ARCHIVED) { return false; } // Schedule must not be archived
    if (this.schedule.status === OneToOneStatus.COMPLETED) { return false; } // Schedule must not be completed
    return true;
  }

  get canSeeActionRecreateCalendar(): boolean {
    if (!this.actionsShown.includes('recreateCalendar')) { return false; } // Action must be flagged for display
    if (this.schedule.manager.id !== this.globals.user.id) { return false; } // Must be managing the schedule
    if (this.schedule.status === OneToOneStatus.ARCHIVED) { return false; } // Schedule must not be archived
    if (this.schedule.status === OneToOneStatus.COMPLETED) { return false; } // Schedule must not be completed
    if (this.schedule.frequency === OneToOneFrequency.Flexible) { return false; } // Schedule must not be flexible
    if (this.schedule.status === OneToOneStatus.PAUSED) { return false; } // Schedule must not be paused
    return true;
  }

  get canSeeActionPause(): boolean {
    if (!this.actionsShown.includes('pause')) { return false; } // Action must be flagged for display
    if (this.schedule.manager.id !== this.globals.user.id) { return false; } // Must be managing the schedule
    if (this.schedule.status === OneToOneStatus.ARCHIVED) { return false; } // Schedule must not be archived
    if (this.schedule.status === OneToOneStatus.COMPLETED) { return false; } // Schedule must not be completed
    if (this.schedule.frequency === OneToOneFrequency.Flexible) { return false; } // Schedule must not be flexible
    if (this.schedule.status === OneToOneStatus.PAUSED) { return false; } // Schedule must not be paused
    return true;
  }

  get canSeeActionResume(): boolean {
    if (!this.actionsShown.includes('resume')) { return false; } // Action must be flagged for display
    if (this.schedule.manager.id !== this.globals.user.id) { return false; } // Must be managing the schedule
    if (this.schedule.status === OneToOneStatus.ARCHIVED) { return false; } // Schedule must not be archived
    if (this.schedule.status === OneToOneStatus.COMPLETED) { return false; } // Schedule must not be completed
    if (this.schedule.frequency === OneToOneFrequency.Flexible) { return false; } // Schedule must not be flexible
    if (this.schedule.status !== OneToOneStatus.PAUSED) { return false; } // Schedule must be paused
    return true;
  }

  get canSeeActionTransfer(): boolean {
    if (!this.actionsShown.includes('transfer')) { return false; } // Action must be flagged for display
    if (this.schedule.manager.id !== this.globals.user.id) { return false; } // Must be managing the schedule
    if (this.schedule.status === OneToOneStatus.ARCHIVED) { return false; } // Schedule must not be archived
    if (this.schedule.status === OneToOneStatus.COMPLETED) { return false; } // Schedule must not be completed
    if (this.schedule.status !== OneToOneStatus.ACTIVE) { return false; } // Schedule must be active
    return true;
  }

  get canSeeActionArchive(): boolean {
    if (!this.actionsShown.includes('archive')) { return false; } // Action must be flagged for display
    if (this.schedule.manager.id !== this.globals.user.id) { return false; } // Must be managing the schedule
    if (this.schedule.status === OneToOneStatus.ARCHIVED) { return false; } // Schedule must not be archived
    if (this.schedule.status === OneToOneStatus.COMPLETED) { return false; } // Schedule must not be completed
    return true;
  }

  get canSeeActionUnarchive(): boolean {
    if (!this.actionsShown.includes('unarchive')) { return false; } // Action must be flagged for display
    if (this.schedule.manager.id !== this.globals.user.id) { return false; } // Must be managing the schedule
    if (this.schedule.status !== OneToOneStatus.ARCHIVED) { return false; } // Schedule must be archived
    if (this.schedule.frequency === OneToOneFrequency.Flexible) { return false; } // Schedule must not be flexible
    if (this.schedule.frequency === OneToOneFrequency.Individual) { return false; } // Schedule must not be individual
    return true;
  }

  get canSeeActionDelete(): boolean {
    if (!this.actionsShown.includes('delete')) { return false; } // Action must be flagged for display
    if (this.schedule.manager.id !== this.globals.user.id) { return false; } // Must be managing the schedule
    if (!(this.schedule.status === OneToOneStatus.ARCHIVED || this.schedule.status === OneToOneStatus.COMPLETED)) { return false; } // Schedule must be archived or completed
    return true;
  }

  constructor(
    public globals: Globals
  ) {
    this.schedule = undefined;

    this.showIcon = true;
    this.showUsers = true;

    this.canSelect = false;
    this.canDeselect = false;
    this.selected = false;
    this.isChanging = false;

    this.actionsShown = [];

    this.onClickAction = new EventEmitter<string>();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.schedule) {
      this.displayTypeData = this.getDisplayTypeData(changes.schedule.currentValue);
      this.displayStatusData = this.getDisplayStatusData(changes.schedule.currentValue);
      this.displayLatestMeetingData = this.getDisplayLatestMeetingData(changes.schedule.currentValue);
    }
  }

  getDisplayTypeData(schedule: OneToOneSchedule): DisplayTypeData {
    const icon = OneToOneUtilsService.getIconClassForOneToOneScheduleType(schedule.type);

    let tooltip = undefined;
    switch (schedule.type) {
      case OneToOneScheduleType.ONE_TO_ONE:
        tooltip = { code: OneToOneMessages.ONE_TO_ONE, params: null };
        break;
      case OneToOneScheduleType.ONE_TO_MANY:
        tooltip = { code: OneToOneMessages.TEAM_MEETING, params: { teamWording: this.globals.getTerminology(TerminologyEntity.TEAM) } };
        break;
      default:
        tooltip = { code: null, params: null };
        break;
    }

    return {
      iconClass: icon,
      tooltip: tooltip
    };
  }

  getDisplayStatusData(schedule: OneToOneSchedule): DisplayStatusData {
    const icon = OneToOneUtilsService.getIconClassForOneToOneScheduleStatus(schedule.status);

    let tooltip = { code: null, params: null };
    let pillType = PillType.GREEN;
    switch (this.schedule.status) {
      case OneToOneStatus.ACTIVE:
        tooltip = { code: OneToOneMessages.ACTIVE, params: null };
        pillType = PillType.GREEN;
        break;
      case OneToOneStatus.ARCHIVED:
        tooltip = { code: CommonMessages.ARCHIVED, params: null };
        pillType = PillType.GRAY;
        break;
      case OneToOneStatus.COMPLETED:
        tooltip = { code: OneToOneMessages.COMPLETED, params: null };
        pillType = PillType.BLUE;
        break;
      case OneToOneStatus.PAUSED:
        tooltip = { code: OneToOneMessages.PAUSED, params: null };
        pillType = PillType.YELLOW;
        break;
    }

    const statusDisplay = this.getStatus(schedule);
    let statusDisplayFriendlyName = statusDisplay.toString();
    statusDisplayFriendlyName = statusDisplayFriendlyName.charAt(0).toUpperCase() + statusDisplayFriendlyName.slice(1).toLowerCase(); // Titlecase
    statusDisplayFriendlyName = statusDisplayFriendlyName.replace(/_/g, ' '); // Underscore to space

    return {
      iconClass: icon,
      tooltip: tooltip,
      pillType: pillType,
      statusDisplay: statusDisplay,
      statusDisplayFriendlyName: statusDisplayFriendlyName
    };
  }

  getDisplayLatestMeetingData(schedule: OneToOneSchedule): DisplayMeetingData {
    let latestMeeting = schedule.nextMeeting;
    if (!latestMeeting) { latestMeeting = schedule.lastMeeting; }
    if (!latestMeeting) { return null; }
    const meetingTimeInTimezone = DateUtils.parseDateTimeToTimezone(latestMeeting.meetingTimestamp, schedule.timezone).format('Do MMM HH:mm');
    const currentUserTimezone = moment.tz.guess();
    const isUsingDifferentTimezone = schedule.timezone !== currentUserTimezone;

    return {
      meeting: latestMeeting,
      meetingTimeInTimezone: meetingTimeInTimezone,
      isUsingDifferentTimezone: isUsingDifferentTimezone
    };
  }

  getStatus(schedule: OneToOneSchedule): Status {
    switch (schedule.status) {
      case OneToOneStatus.ACTIVE:
        return Status.ACTIVE;
      case OneToOneStatus.ARCHIVED:
        return Status.ARCHIVED;
      case OneToOneStatus.COMPLETED:
        return Status.COMPLETED;
      case OneToOneStatus.PAUSED:
        return Status.PAUSED;
      default:
        return Status.NONE;
    }
  }

  onClickSchedule(): void {
    this.selectSchedule();
  }

  onClickScheduleData(): void {
    if (this.canSeeActionView) {
      this.clickAction('view');
    }
  }

  selectSchedule(): void {
    if (this.canSelect) {
      this.onClickAction.emit('select');
    }
  }

  clickAction(action: DisplayAction): void {
    this.onClickAction.emit(action);
  }
}
