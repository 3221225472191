import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { GoalsMessages } from '@app/goals/goals.messages';
import { GoalKeyResultMeasureUnitPlacement } from '@app/models/goals/goal-key-result-measure-unit-placement.model';
import { GoalKeyResultType } from '@app/models/goals/goal-key-result-type.model';
import { GoalTemplate } from '@app/models/goals/templates/goal-template.model';
import { Globals } from '@app/shared/globals/globals';
import { PillType } from '../../pill/pill.component';

@Component({
  selector: 'app-goal-template-display',
  templateUrl: './goal-template-display.component.html',
  styleUrls: ['./goal-template-display.component.scss']
})
export class GoalTemplateDisplayComponent implements OnInit {
  public readonly eGoalKeyResultType = GoalKeyResultType;
  public readonly eGoalKeyResultMeasureUnitPlacement = GoalKeyResultMeasureUnitPlacement;
  public readonly ePillType = PillType;
  public readonly eGoalsMessages = GoalsMessages;

  @Input() template: GoalTemplate;

  @Output() onButtonClicked: EventEmitter<boolean>;

  constructor(
    public globals: Globals
  ) {
    this.template = undefined!;
    this.onButtonClicked = new EventEmitter<boolean>();
  }

  ngOnInit(): void {
  }

  onUseClicked(): void {
    this.onButtonClicked.emit(true);
  }
}
