import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonMessages } from '@app/constants/common.messages';
import { GoalsMessages } from '@app/goals/goals.messages';
import { GoalStatus } from '@app/models/goals/goal-status.model';
import { GoalType } from '@app/models/goals/goal-type.enum';
import { Goal } from '@app/models/goals/goal.model';
import { Globals } from '@app/shared/globals/globals';
import { GoalUtils } from '@app/shared/utils/goal.utils';
import { PillType } from '../../pill/pill.component';
import { TerminologyEntity } from '@app/domain/terminology/model/terminology-entity.enum';

enum Status {
  OFF_TRACK = 'OFF_TRACK',
  PROGRESSING = 'PROGRESSING',
  ON_TRACK = 'ON_TRACK',
  ARCHIVED = 'ARCHIVED',
  COMPLETE = 'COMPLETE',
  NONE = 'NONE'
}

@Component({
  selector: 'app-goal-display',
  templateUrl: './goal-display.component.html',
  styleUrls: ['./goal-display.component.scss']
})
export class GoalDisplayComponent implements OnChanges {

  public readonly eTerminologyEntity = TerminologyEntity;
  public readonly eGoalsMessages = GoalsMessages;
  public readonly eGoalType = GoalType;
  public readonly ePillType = PillType;

  public readonly DATE_FORMAT = 'D MMM YYYY';
  
  @Input() goal!: Goal;
  @Input() showProgress: boolean;
  @Input() showIcon: boolean;
  @Input() showOwners: boolean;

  @Input() canSelect: boolean;
  @Input() canDeselect: boolean;
  @Input() selected: boolean;

  @Input() canOpen: boolean;
  @Input() openRelative: boolean;
  
  @Input() progressLabel: string | undefined;

  @Input() goalPrevious: Goal; // Previous instance of the goal to show how progress has changed
  

  @Output() onSelect: EventEmitter<boolean>;

  status: Status;

  constructor(
    public globals: Globals,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
    this.selected = false;
    this.canSelect = false;
    this.canDeselect = false;
    this.showProgress = true;
    this.showIcon = true;
    this.showOwners = true;
    this.canOpen = false;
    this.openRelative = false;
    this.status = Status.PROGRESSING;
    this.onSelect = new EventEmitter<boolean>();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const goal = changes['goal'];
    if (goal) {
      this.status = this.getStatus(goal.currentValue);
    }
  }

  getStatus(goal: Goal): Status {
    if (goal.archived) {
      return Status.ARCHIVED;
    }

    if (goal.complete) {
      return Status.COMPLETE;
    }

    switch (goal.status) {
      case GoalStatus.OFF_TRACK:
        return Status.OFF_TRACK;
      case GoalStatus.PROGRESSING:
        return Status.PROGRESSING;
      case GoalStatus.ON_TRACK:
        return Status.ON_TRACK;
      case null:
        return Status.NONE;
    }
    
    return Status.PROGRESSING;
  }

  onClickGoal(): void {
    this.selectGoal();
    this.navigateToGoal();
  }

  navigateToGoal(): void {
    if (!this.canOpen) { return; }
    if (!this.goal) { return; }
    if (this.openRelative) {
      this.router.navigate(['goals/individual/', this.goal.id], { relativeTo: this.activatedRoute });
    } else {
      this.router.navigate(['goals/individual/', this.goal.id]);
    }
  }

  selectGoal(): void {
    if (this.canSelect) {
      this.onSelect.emit(true);
    }
  }

  get goalIcon(): string {
    return GoalUtils.getIconClassForGoalType(this.goal.type);
  }

  get goalIconTooltip(): { code: string, params: { [key: string ]: string } } {
    switch (this.goal.type) {
      case GoalType.COMPANY:
        return { code: CommonMessages.COMPANY, params: null };
      case GoalType.PERSONAL_DEVELOPMENTAL:
        return { code: GoalsMessages.PERSONAL_DEVELOPMENTAL, params: null };
      case GoalType.PERSONAL_OPERATIONAL:
        return { code: GoalsMessages.PERSONAL_OPERATIONAL, params: null };
      case GoalType.DEPARTMENT:
        return { code: GoalsMessages.DEPARTMENT_GOAL, params: { departmentWording: this.globals.getTerminology(TerminologyEntity.DEPARTMENT) } };
      case GoalType.TEAM:
        return { code: GoalsMessages.TEAM_GOAL, params: { teamWording: this.globals.getTerminology(TerminologyEntity.TEAM) }};
      case GoalType.OFFICE_LOCATION:
        return { code: GoalsMessages.SITE_GOAL, params: { site: this.globals.getTerminology(TerminologyEntity.SITE) } };
      default:
        return { code: null, params: null };
    }
  }
}
