<div class="container-scorecard-average-display" *ngIf="scorecard">
  <div class="scorecard-item-top">
    <div class="scorecard-average" title="Average score">{{scorecard?.averageQualityScore | number: '0.2-2'}}%</div>
    <div class="scorecard-icon">
        <span class="fal fa-fw fa-clipboard-list-check"></span>
    </div>
  </div>
  <ng-container *ngIf="passRate && passRate > 0">
    <div class="scorecard-pass-rate">{{passRate}}% pass rate</div>
  </ng-container>
  <div class="scorecard-title">{{scorecard.scorecard}}</div>

  <ng-container *ngIf="showEvaluationsList && (evaluations.length > 0)">
    <div class="text-right p-top-15">
      <a class="clickable" (click)="toggleEvaluationsListExpanded()">{{evaluationsListExpanded ? 'Hide evaluations' : 'Show evaluations'}}</a>
    </div>
    <ng-container *ngIf="evaluationsListExpanded">
      <div class="evaluations-list">
        <ng-container *ngFor="let evaluation of evaluations">
          <app-evaluagent-evaluation-display [evaluation]="evaluation"></app-evaluagent-evaluation-display>
        </ng-container>
      </div>
    </ng-container>
  </ng-container>
</div>