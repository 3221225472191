/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */
import { Component, Input } from '@angular/core';
import { SentimentScale } from '../../model/sentiment-scale.model';
import { Globals } from '@app/shared/globals/globals';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { SentimentScaleOption } from '../../model/sentiment-scale-option.model';

@Component({
  selector: 'app-sentiment-scale-response',
  templateUrl: './sentiment-scale-response.component.html',
  styleUrls: ['./sentiment-scale-response.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: SentimentScaleResponseComponent,
    multi: true,
  }]
})
export class SentimentScaleResponseComponent {

  @Input() sentimentScale: SentimentScale;
  @Input() disabled = false;

  _value: number;

  onChange = (_: any) => {};
  onTouched = () => {};

  get scaleOptionsOrdered(): SentimentScaleOption[] {
    return this.sentimentScale.options.sort((a, b) => a.orderIndex - b.orderIndex);
  }

  get value(): number {
    return this._value;
  }

  set value(value: number) {
    if (this.disabled) { return; }
    this._value = value;
    this.onChange(value);
    this.onTouched();
  }

  constructor(
    public globals: Globals
  ) {
    this.sentimentScale;
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }

  onClickOption(option: number): void {
    if (this.value === option) { return; }
    this.value = option;
  }

  writeValue(value: number): void {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

}
