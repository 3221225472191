<div class="qualification-display" *ngIf="qualification">
  <div class="col-data">
    <h5 class="standard-heading">
      <span>{{qualification.name}}</span>
      <ng-container *ngIf="isExpired">
        <span class="m-right-5"></span>
        <app-pill [type]="ePillType.RED">{{eCommonMessages.EXPIRED | translate}}</app-pill>
      </ng-container>
    </h5>
    <div class="standard-description">
      <div class="display-provider">
        <span translate>{{eQualificationMessages.PROVIDER}}</span>
        <span class="m-right-5">:</span>
        <span>{{qualification.provider}}</span>
      </div>
      <div class="display-received-date">
        <span translate>{{eQualificationMessages.RECEIVED_DATE}}</span>
        <span class="m-right-5">:</span>
        <span>{{qualification.receivedDate ? (qualification.receivedDate | momentPipe: 'YYYY' ) : (eCommonMessages.NOT_SET | translate)}}</span>
      </div>
      <div class="display-expiration-date">
        <span translate>{{eQualificationMessages.EXPIRATION_DATE}}</span>
        <span class="m-right-5">:</span>
        <span [class.text-danger]="isExpired">{{qualification.expirationDate ? (qualification.expirationDate | momentPipe: 'Do MMM YYYY' ) : (eCommonMessages.NOT_SET | translate)}}</span>
      </div>
    </div>
    <div class="standard-description p-top-15">
      <app-rich-display [text]="qualification.description"></app-rich-display>
    </div>
  </div>

  <div class="col-actions">
    <app-table-action-icon *ngIf="actionsShown.includes('edit')" [icon]="'fa-edit'" [hoverColor]="eIconHoverColor.YELLOW" [tooltipText]="eQualificationMessages.TOOLTIP_EDIT" (click)="clickAction('edit')"></app-table-action-icon>
    <app-table-action-icon *ngIf="actionsShown.includes('delete')" [icon]="'fa-trash'" [hoverColor]="eIconHoverColor.RED" [tooltipText]="eQualificationMessages.TOOLTIP_DELETE" (click)="clickAction('delete')"></app-table-action-icon>
  </div>
</div>
