<div class="content" [ngSwitch]="formState">
  <ng-container *ngSwitchCase="eFormState.FORM">
    <div class="p-bottom-15">
      <h4 class="standard-heading">Create private sandbox</h4>
    </div>

    <form [formGroup]="form" (ngSubmit)="onFormSubmit()">

      <ng-container *ngIf="formLoading === false">
        <div class="row m-bottom-10">
          <div class="col-xs-12 col-sm-6">
            <div class="frankli-form-group" [class.frankli-form-group-invalid]="firstName.invalid && formSubmitted"
              [class.frankli-form-group-filled]="firstName.value.length > 0">
              <label for="first-name">First Name</label>
              <input id="first-name" type="first" class="form-control text-capitalize" placeholder="First Name"
                [formControl]="firstName">
              <div class="frankli-form-group-alert">
                <div *ngIf="formSubmitted && firstName.hasError('required')">
                  First name is required
                </div>
                <div *ngIf="formSubmitted && firstName.hasError('maxlength')">
                  First name cannot be over {{firstName.errors.maxlength.requiredLength}} characters
                </div>
              </div>
            </div>
          </div>

          <div class="col-xs-12 col-sm-6">
            <div class="frankli-form-group" [class.frankli-form-group-invalid]="lastName.invalid && formSubmitted"
              [class.frankli-form-group-filled]="lastName.value.length > 0">
              <label for="last-name">Last Name</label>
              <input id="last-name" type="last" class="form-control text-capitalize" placeholder="Last Name"
                [formControl]="lastName">
              <div class="frankli-form-group-alert">
                <div *ngIf="formSubmitted && lastName.hasError('required')">
                  Last name is required
                </div>
                <div *ngIf="formSubmitted && lastName.hasError('maxlength')">
                  Last name cannot be over {{lastName.errors.maxlength.requiredLength}} characters
                </div>
              </div>
            </div>
          </div>

        </div>

        <div class="row m-bottom-10">
          <div class="col-xs-12 col-sm-6">
            <div class="frankli-form-group" [class.frankli-form-group-invalid]="email.invalid && formSubmitted"
              [class.frankli-form-group-filled]="email.value.length > 0">
              <label for="email-address">Email address</label>
              <input id="email-address" type="email" class="form-control" placeholder="Email" [formControl]="email">
              <div class="frankli-form-group-alert">
                <div *ngIf="formSubmitted && email.hasError('required')">
                  Email address is required
                </div>
                <div *ngIf="formSubmitted && email.hasError('pattern')">
                  Email must be a valid email
                </div>
                <div *ngIf="formSubmitted && email.hasError('maxlength')">
                  Email must be less than {{email.errors.maxlength.requiredLength}} characters
                </div>
              </div>
            </div>
          </div>

          <div class="col-xs-12 col-sm-6">
            <div class="frankli-form-group" [class.frankli-form-group-invalid]="companyName.invalid && formSubmitted"
              [class.frankli-form-group-filled]="companyName.value.length > 0">
              <label for="company-name">Company Name</label>
              <input id="company-name" type="text" class="form-control text-capitalize" placeholder="First Name"
                [formControl]="companyName">
              <div class="frankli-form-group-alert">
                <div *ngIf="formSubmitted && companyName.hasError('required')">
                  Company name is required
                </div>
                <div *ngIf="formSubmitted && companyName.hasError('maxlength')">
                  Company name cannot be over {{companyName.errors.maxlength.requiredLength}} characters
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-xs-12 text-center">
            <button type="submit" class="btn-frankli-round btn-frankli-green" angulartics2On="click"
              angularticsAction="Create private sandbox" angularticsCategory="Demo">Submit</button>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="formLoading === true">
        <div class="row">
          <div class="col-xs-12 text-center">
            <p>
              Creating your sandbox, this may take a while
            </p>
            <span class="fas fa-fw fa-spin fa-spinner"></span>
          </div>
        </div>
      </ng-container>
    </form>
  </ng-container>

  <ng-container *ngSwitchCase="eFormState.COMPLETE">
    <div class="row m-top-15 m-bottom-30">
      <div class="col-xs-12 text-center">
        <h4 class="request-sandbox-title">Check your inbox!</h4>
      </div>
    </div>

    <div class="row">
      <div class="col-xs-12 text-center">
        <img class="icon-padding new-icon-height" src="/assets/img/check.svg" />
        <p>We have emailed you details on how to access your private sandbox</p>
      </div>
    </div>
  </ng-container>
</div>