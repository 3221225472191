<div (click)="onContainerClicked($event)" class="modal fade" tabindex="-1" [ngClass]="{'in': visibleAnimate}"
  [ngStyle]="{ 'display': visible ? 'block' : 'none', 'opacity': visibleAnimate ? 1 : 0 }"
  [class.sidebar-collapsed]="sidebarCollapsed">
  <div class="modal-dialog" [class.p-mobile]="globals.onMobile">
    <div class="modal-content"> 
      <!-- Main Panel -->
      <div class="row m-left-0 m-right-0">
        <!-- Expand/Collapse Icon -->
        <div tooltip="Expand sidebar" [triggers]="globals.tooltipTriggers" placement="right" container="body" class="modal-sidebar-button" (click)="toggleSidebar()">
          <span class="fal fa-angle-right fa-2x"></span>
        </div>

        <!-- Main Form -->
        <div class="col-main">
    
          <!-- Header -->
          <div class="modal-body-header">
            <ng-content select=".app-goal-modal-header"></ng-content>
            <ng-content select=".app-goal-modal-subtitle"></ng-content>
          </div>
    
          <!-- Modal Content -->
          <div class="modal-body-content">
            <ng-content select=".app-goal-modal-body"></ng-content>
          </div>
        </div>
  
        <!-- Sidebar -->
        <!-- TODO: This is hidden on mobile. Find a way to display it and add that functionality back -->
        <div class="col-sidebar">
          <ng-content select=".app-goal-modal-sidebar"></ng-content>
        </div>
      </div>
    </div>
    <div class="modal-dismiss" (click)="hide()">

    </div>
  </div>
</div>
