/* eslint-disable @typescript-eslint/no-unused-vars */
import { Injectable } from '@angular/core';
import { MockService } from '@app/mock/api/mock-service';
import { PraiseService } from '@app/shared/api/interfaces/praise.service';
import { Observable, of } from 'rxjs';
import { HttpRequest } from '@angular/common/http';
import { ReactionType } from '@app/models/activity-reaction.model';
import { CreatePraiseDto, Praise, UpdatePraiseDto } from '@app/models/feedback/praise.model';
import { Globals } from '@app/shared/globals/globals';
import { mockPraise } from '@app/mock/api/data/mockPraise';
import { sanitizeUrl } from '@app/shared/utils/helpers';

@Injectable()
export class PraiseMockService implements MockService, PraiseService {
  constructor(private readonly globals: Globals) { }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleRoute(req: HttpRequest<any>): any {
    const url = sanitizeUrl(req.urlWithParams);

    switch (true) {
      case url.endsWith('api/praise/sent'):
        return this.getPraiseSent();
      case url.endsWith('api/praise/submitted/manager/me'):
        return this.getPraiseByDestinationUser();
      case url.match(/api\/praise\/submitted\/manager\/id\/\d+$/) !== null:
        return this.getPraiseByDestinationUserAndId(+url.split('/')[5]);
      case url.match(/api\/praise\/sent\/\d+$/) !== null:
        return this.getPraiseSentById(+url.split('/')[3]);
      case url.endsWith('api/praise/received'):
        return this.getPraiseReceived();
      case url.match(/api\/praise\/received\/\d+$/) !== null:
        return this.getPraiseReceivedById(+url.split('/')[3]);
      case url.match(/api\/praise\/received\/user\/\d+$/) !== null:
        return this.getPraiseReceivedByUserId(+url.split('/')[4]);
    }
  }

  getPraiseByDestinationUser(): Observable<Praise[]> {
    return of(mockPraise.filter(p => p.praisedUser.id === this.globals.user.id));
  }

  getPraiseByDestinationUserAndId(id: number): Observable<Praise> {
    return of(mockPraise.find(p => p.praisedUser.id === this.globals.user.id && p.id === id));
  }

  getPraiseReceived(): Observable<Praise[]> {
    return of(mockPraise.filter(p => p.praisedUser.id === this.globals.user.id));
  }

  getPraiseReceivedById(id: number): Observable<Praise> {
    return of(mockPraise.find(p => p.praisedUser.id === this.globals.user.id && p.id === id));
  }

  getPraiseReceivedByUserId(userId: number): Observable<Praise[]> {
    return of(mockPraise.filter(p => p.praisedUser.id === userId));
  }

  getPraiseSent(): Observable<Praise[]> {
    return of(mockPraise.filter(p => p.senderUser.id === this.globals.user.id));
  }

  getPraiseSentById(id: number): Observable<Praise> {
    return of(mockPraise.find(p => p.id === id) || null);
  }

  getSidebarPraise(userIds: number[]): Observable<Praise[]> {
    return of(mockPraise);
  }

  // No Ops listed below
  deletePraiseById(id: number): Observable<Praise> {
    return undefined;
  }

  submitPraise(createPraiseDto: CreatePraiseDto): Observable<Praise> {
    return undefined;
  }

  togglePraiseVisibleById(id: number): Observable<Praise> {
    return undefined;
  }

  toggleReactionForPraiseByIdAndReactionType(id: number, type: ReactionType): Observable<Praise> {
    return undefined;
  }

  updatePraiseById(id: number, updatePraiseDto: UpdatePraiseDto): Observable<Praise> {
    return undefined;
  }
}