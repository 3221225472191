import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { Breadcrumb } from 'app/models/breadcrumb.model';
import { ActivatedRoute, Params, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class BreadcrumbService {
  private subject: Subject<Array<Breadcrumb>>;
  private breadcrumbs: Array<Breadcrumb>;

  constructor(private router: Router) {
    this.subject = new Subject<Array<Breadcrumb>>();
    this.breadcrumbs = new Array<Breadcrumb>();
  }

  /**
   * Returns an observable outputting the current list of breadcrumbs
   */
  getBreadcrumbs(): Observable<Array<Breadcrumb>> {
    return this.subject.asObservable();
  }

  /**
   * Updates the current route's breadcrumb
   * @param label
   * @param url
   * @param queryParams
   */
  updateCurrentBreadcrumb(label: string | null, url: string | null, queryParams?: Params): Breadcrumb {
    if (this.breadcrumbs.length > 0) {
      const breadcrumb = this.breadcrumbs[this.breadcrumbs.length - 1];

      if (label !== null) {
        breadcrumb.label = label;
      }

      if (url !== null) {
        breadcrumb.route = url;
      }

      if (queryParams) {
        breadcrumb.queryParams = queryParams;
      }

    }

    this.subject.next(this.breadcrumbs);

    return this.breadcrumbs[this.breadcrumbs.length - 1]
  }

  /**
   * Updates the current route's breadcrumb
   * @param crumb the breadcrumb to be updated
   * @param label the new string, pass null if not wanted to be updated
   * @param url the new url, pass null if not wanted to be updated
   * @param queryParams
   */
  updateSpecificBreadcrumb(crumb: Breadcrumb, label: string | null, url: string | null, queryParams?: Params): Breadcrumb {
    let breadcrumb: Breadcrumb = crumb;
    if (this.breadcrumbs.length > 0) {
      breadcrumb = this.breadcrumbs.find(b => b === crumb)!;

      if (breadcrumb) {
        if (label !== null) {
          breadcrumb.label = label;
        }

        if (url !== null) {
          breadcrumb.route = url;
        }

        if (queryParams) {
          breadcrumb.queryParams = queryParams;
        }
      }
    }

    this.subject.next(this.breadcrumbs);

    return breadcrumb;
  }

  /**
   * Adds a breadcrumb to the breadcrumb array, building the appropriate url
   * @param route
   * @param isBaseComponent (defaults to false)
   */
  init(route: ActivatedRoute, isBaseComponent: boolean = false): Breadcrumb {
    const label = route.snapshot.data['label'];
    return this.initWithLabel(route, isBaseComponent, label);
  }

  initWithLabel(route: ActivatedRoute, isBaseComponent: boolean = false, label: string): Breadcrumb {
    let url = '';
    route.pathFromRoot.forEach(activatedRoute => {
      activatedRoute.snapshot.url.forEach(path => {
        url += '/';
        url += path;
      });
    });

    if (isBaseComponent === true) {
      while (this.breadcrumbs.length > 0) {
        this.breadcrumbs.pop();
      }
    }

    const breadcrumb = new Breadcrumb(url, label, null);
    this.breadcrumbs.push(breadcrumb);

    this.subject.next(this.breadcrumbs);

    return this.breadcrumbs[this.breadcrumbs.length - 1];
  }

  /**
   * Removes a breadcrumb from the array
   * @param breadcrumb
   */
  remove(breadcrumb: Breadcrumb) {
    const index = this.breadcrumbs.indexOf(breadcrumb);
    this.breadcrumbs.splice(index, 1);

    this.subject.next(this.breadcrumbs);
  }

  getUrl(route: ActivatedRoute): string {
    let url = '';
    route.pathFromRoot.forEach(activatedRoute => {
      activatedRoute.snapshot.url.forEach(path => {
        url += '/';
        url += path;
      });
    });

    return url;
  }

}
