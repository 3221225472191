import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-trend-arrow',
  templateUrl: './trend-arrow.component.html',
  styleUrls: ['./trend-arrow.component.scss']
})
export class TrendArrowComponent {

  @Input() valueCurrent: number;
  @Input() valuePrevious: number;

  constructor() {
    this.valueCurrent = undefined;
    this.valuePrevious = undefined;
  }

  get hasPreviousValue(): boolean {
    return this.valuePrevious !== undefined;
  }
}
