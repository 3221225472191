import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { NewsActivity } from '@app/models/news-activity.model';
import { NewsActivityService } from '@app/shared/api/interfaces/news-activity.service';

@Injectable()
export class NewsActivityAPIService implements NewsActivityService {
    private readonly baseURL = 'api/news/';

    constructor(private _http: HttpClient) { }

    getNewsActivity(): Observable<Array<NewsActivity>> {
        const url = this.baseURL;
        return this._http.get<Array<NewsActivity>>(url);
    }
}