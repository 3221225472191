import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ZendeskToken, ZendeskTokenServerSide } from '@app/models/integrations/zendesk/zendesk-token.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ZendeskUserStatus } from '@models/integrations/zendesk/zendesk-user-status.model';
import { ZendeskService } from './interfaces/zendesk.service';

@Injectable({
  providedIn: 'root'
})
export class ZendeskAPIService implements ZendeskService {

  private readonly BASE_URL: string = 'api/zendesk';

  constructor(private http: HttpClient) { }

  // TOKEN

  getToken(): Observable<ZendeskToken | null> {
    const url = `${this.BASE_URL}/token`;
    return this.http.get<ZendeskTokenServerSide | null>(url).pipe(
      map(zendeskAPIKey => zendeskAPIKey === null ? null : new ZendeskToken(zendeskAPIKey))
    );
  }

  createToken(token: ZendeskTokenServerSide): Observable<ZendeskToken> {
    const url = `${this.BASE_URL}/token`;
    return this.http.post<ZendeskTokenServerSide>(url, token).pipe(map(zendeskAPIKey => new ZendeskToken(zendeskAPIKey)));
  }

  removeToken(): Observable<null> {
    const url = `${this.BASE_URL}/token`;
    return this.http.delete<null>(url);
  }

  // CLIENT TOKEN

  clientToken(clientToken: string): Observable<null> {
    const url = `${this.BASE_URL}/client-token`;
    return this.http.post<null>(url, clientToken);
  }

  // USER STATUS AND METADATA

  /**
   * Get a user's Zendesk metadata.
   * @param userId - Optional. If not provided, the status for the current user is returned.
   * @returns A user's Zendesk metadata.
   */
  getUserStatus(userId?: number): Observable<ZendeskUserStatus> {
    let url = `${this.BASE_URL}/status/`;

    if (userId) {
      url += userId;
    }

    return this.http.get<ZendeskUserStatus>(url);
  }

  getOldestTicketForCompany(): Observable<Date> {
    const url = `${this.BASE_URL}/oldest-ticket/`;
    return this.http.get<Date>(url);
  }

  getOldestTicketForUser(userId: number): Observable<Date> {
    const url = `${this.BASE_URL}/oldest-ticket/${userId}`;
    return this.http.get<Date>(url);
  }

  // METRICS

  getCsatScoreForCompany(periodStart: Date, periodEnd: Date): Observable<number> {
    const url = `${this.BASE_URL}/metrics/csat/`;

    let httpParams: HttpParams = new HttpParams();
    httpParams = httpParams.append('periodStart', periodStart.toISOString());
    httpParams = httpParams.append('periodEnd', periodEnd.toISOString());

    return this.http.get<number>(url, {params: httpParams});
  }

  getCsatScoreForUser(userId: number, periodStart: Date, periodEnd: Date): Observable<number> {
    const url = `${this.BASE_URL}/metrics/csat/${userId}`;

    let httpParams: HttpParams = new HttpParams();
    httpParams = httpParams.append('periodStart', periodStart.toISOString());
    httpParams = httpParams.append('periodEnd', periodEnd.toISOString());

    return this.http.get<number>(url, {params: httpParams});
  }

  getTicketsSolvedForCompany(periodStart: Date, periodEnd: Date): Observable<number> {
    const url = `${this.BASE_URL}/metrics/tickets-solved/`;

    let httpParams: HttpParams = new HttpParams();
    httpParams = httpParams.append('periodStart', periodStart.toISOString());
    httpParams = httpParams.append('periodEnd', periodEnd.toISOString());

    return this.http.get<number>(url, {params: httpParams});
  }

  getTicketsSolvedForUser(userId: number, periodStart: Date, periodEnd: Date): Observable<number> {
    const url = `${this.BASE_URL}/metrics/tickets-solved/${userId}`;

    let httpParams: HttpParams = new HttpParams();
    httpParams = httpParams.append('periodStart', periodStart.toISOString());
    httpParams = httpParams.append('periodEnd', periodEnd.toISOString());

    return this.http.get<number>(url, {params: httpParams});
  }

  // MANUAL SYNC

  runManualSync(): Observable<boolean> {
    const url = `${this.BASE_URL}/manual-sync`;
    return this.http.get<boolean>(url);
  }

}
