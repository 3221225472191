import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ContactInformationDto } from '../../model/contact-information.model';
import { IContactInformationAPI } from './contact-information-api.model';

@Injectable()
export class ContactInformationAPIService implements IContactInformationAPI {
  private readonly BASE_URL: string = 'api/contact-information';

  constructor(
      private http: HttpClient
  ) { }

  get(): Observable<ContactInformationDto> {
    const url = `${this.BASE_URL}`;
    return this.http.get<ContactInformationDto>(url);
  }

  update(updatedInformation: ContactInformationDto): Observable<ContactInformationDto> {
    const url = `${this.BASE_URL}`;
    return this.http.put<ContactInformationDto>(url, updatedInformation);
  }
}
