<div class="container-dynamic-scale-option">
  <ng-container *ngIf="!editing; else templateEditing;">
    <ng-container *ngIf="option">
      <div class="scale-option-item" [class.option-disabled]="disabled" [class.option-selected]="selected" (click)="doAction(eDynamicScaleOptionComponentAction.SELECT)">
        <div class="col-option-circle" [style.backgroundColor]="option.colour" [style.color]="getTextColorByBackgroundColor(option.colour)">
          <ng-container *ngIf="!editing; else templateEditingTitle">
            <span>{{option.answerValue}}</span>
          </ng-container>
        </div>
      
        <div class="divider-h"></div>

        <div class="col-option-details">
          <div class="option-item-title">{{option.labelTitle}}</div>
          <span class="option-item-description">{{option.labelDescription}}</span>
        </div>
      </div>
    </ng-container>
  </ng-container>

  <ng-template #templateEditing>
    <div class="scale-option-edit-form" *ngIf="optionEdit">
      <div class="container-remove-option-icon">
        <app-table-action-icon [icon]="'fa-times'" [hoverColor]="eIconHoverColor.RED" [tooltipText]="'Remove option'" (click)="doAction(eDynamicScaleOptionComponentAction.REMOVE)"></app-table-action-icon>
      </div>

      <div class="scale-edit-columns">
        <div class="scale-edit-left">
          <span class="fal fa-fw fa-bars"></span>
        </div>

        <div class="scale-edit-right">
          <h4 class="standard-heading">Option {{index + 1}}</h4>

          <div class="scale-option-edit-form-inner">
            <div class="scale-option-edit-form-group-values">
              <div class="frankli-form-group frankli-form-group-filled frankli-form-group-placeholder-shown" [class.frankli-form-group-invalid]="submitted && controlEditColour.invalid">
                <label>Colour</label>
                <div class="colour-picker-wrapper">
                  <input type="color" [formControl]="controlEditColour"/>
                </div>
              </div>
        
              <div class="frankli-form-group frankli-form-group-filled frankli-form-group-placeholder-shown" [class.frankli-form-group-invalid]="submitted && controlEditValue.invalid">
                <label>Value</label>
                <input type="number" class="form-control" [formControl]="controlEditValue"/>
              </div>
            </div>
      
            <div class="divider-h"></div>
      
            <div class="scale-option-edit-form-group">
              <div class="frankli-form-group frankli-form-group-filled frankli-form-group-placeholder-shown" [class.frankli-form-group-invalid]="submitted && controlEditTitle.invalid">
                <label>Title</label>
                <input type="text" class="form-control" [formControl]="controlEditTitle"/>
              </div>
          
              <div class="frankli-form-group frankli-form-group-filled frankli-form-group-placeholder-shown" [class.frankli-form-group-invalid]="submitted && controlEditDescription.invalid">
                <label>Description</label>
                <textarea class="form-control noresize" maxlength="2000" [formControl]="controlEditDescription" rows="2"></textarea>
              </div>
            </div>
          </div>    
        </div>
      </div>

      <div class="frankli-form-group-alert" *ngIf="submitted && optionEdit.invalid" >
        <ng-container *ngIf="controlEditColour.invalid">
          <div *ngIf="controlEditColour.hasError('required')">You must select a colour</div>
        </ng-container>
        <ng-container *ngIf="controlEditValue.invalid">
          <div *ngIf="controlEditValue.hasError('required')">You must enter a value</div>
        </ng-container>
        <ng-container *ngIf="controlEditTitle.invalid">
          <div *ngIf="controlEditTitle.hasError('required')">You must enter a title</div>
          <div *ngIf="controlEditTitle.hasError('maxlength')">Title must be less than 255 characters</div>
        </ng-container>
        <ng-container *ngIf="controlEditDescription.invalid">
          <div *ngIf="controlEditDescription.hasError('required')">You must enter a description</div>
          <div *ngIf="controlEditDescription.hasError('maxlength')">Description must be less than 2000 characters</div>
        </ng-container>
      </div>
    </div>
  </ng-template>
</div>