import { SkillRatingType } from './skill-rating-type.model';

export class SkillRating implements ISkillRating {
  id: number;
  sourceUserId: number;
  targetUserId: number;
  skillRatingType: SkillRatingType;
  targetEntityId: number;
  scoreValue: number;
  scoreComment: string;
  archived: boolean;
  createdAt: Date;
  updatedAt: Date;

  constructor(serverside: ISkillRatingServerside) {
    this.id = serverside.id;
    this.sourceUserId = serverside.sourceUserId;
    this.targetUserId = serverside.targetUserId;
    this.skillRatingType = serverside.skillRatingType;
    this.targetEntityId = serverside.targetEntityId;
    this.scoreValue = serverside.scoreValue;
    this.scoreComment = serverside.scoreComment;
    this.archived = serverside.archived;
    this.createdAt = new Date(serverside.createdAt);
    this.updatedAt = new Date(serverside.updatedAt);
  }

  // Add business logic here if needed

}

export interface ISkillRating {
  id: number;
  sourceUserId: number;
  targetUserId: number;
  skillRatingType: SkillRatingType;
  targetEntityId: number;
  scoreValue: number;
  scoreComment: string;
  archived: boolean;
  createdAt: Date;
  updatedAt: Date;
}


export interface ISkillRatingServerside {
  id: number;
  sourceUserId: number;
  targetUserId: number;
  skillRatingType: SkillRatingType;
  targetEntityId: number;
  scoreValue: number;
  scoreComment: string;
  archived: boolean;
  createdAt: string;
  updatedAt: string;
}
