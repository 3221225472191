import { ScimUserService } from '@app/shared/api/interfaces/scim-user.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ScimUser } from '@app/models/user/scim-user.model';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class ScimUserApiService implements ScimUserService {
  private readonly baseUrl = 'api/scim-users';

  constructor(private readonly http: HttpClient) { }

  getScimUserByUserId(userId: number): Observable<ScimUser> {
    const url = `${this.baseUrl}/user/${userId}`;
    return this.http.get<ScimUser>(url);
  }

  getTeamsCallURIForUserByUserId(userId: number): Observable<URL> {
    const url = `${this.baseUrl}/user/${userId}/teams-call-uri`;
    return this.http.get<URL>(url);
  }

  getScimUsersForCompany(): Observable<ScimUser[]> {
    const url = `${this.baseUrl}`;
    return this.http.get<ScimUser[]>(url);
  }
}