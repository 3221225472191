import { Injectable } from '@angular/core';
import { SSOProvider } from '@app/models/auth/sso-provider.enum';
import { UserInit } from '@app/models/auth/user-init.model';
import { SecondaryManagerAny } from '@app/models/company/company-secondary-manager/secondary-manager-any.model';
import { Company } from 'app/models/company.model';
import { CompanyFeatures } from '@app/models/company-features.model';
import { RoleName } from '@app/models/user-role.model';
import { User} from 'app/models/user/user.model';
import { CustomWindow } from '@app/models/custom-window.model';
import { TerminologyCollection } from '@app/domain/terminology/model/terminology-collection.model';
import { TerminologyEntity } from '@app/domain/terminology/model/terminology-entity.enum';
import { TERMINOLOGY_DEFAULTS } from '@app/domain/terminology/model/terminology-item.model';
import { TerminologyLanguage } from '@app/domain/terminology/model/terminology-language.enum';

export declare const window: CustomWindow;

@Injectable()
export class Globals {
  static onMobile = false;

  user!: User;
  company!: Company;
  secondaryManagerAny!: SecondaryManagerAny;
  welcomeViewed: boolean;
  hasUnsavedChanges: boolean;
  ssoProvider?: SSOProvider;
  modalOpen: boolean;
  usingTeams: boolean;
  terminology: TerminologyCollection;

  get onMobile(): boolean {
    if (!window) { return false; }
    if (window.innerWidth < 400) { return true; }
    return window.isMobile;
  }

  // click dblclick hover [focus blur] [mouseenter mouseleave] [mouseover mouseout] [touchstart touchend touchcancel] touchmove
  get tooltipTriggers(): string {
    if (this.onMobile) {
      return 'touchstart touchend touchcancel';
    }

    return 'mouseover mouseout';
  }

  constructor() {
    this.welcomeViewed = true;
    this.hasUnsavedChanges = false;
    this.ssoProvider = undefined;
    this.modalOpen = false;
    this.usingTeams = false;
    this.terminology = TERMINOLOGY_DEFAULTS[TerminologyLanguage.EN];
    Globals.onMobile = this.onMobile;
  }

  public init(init: UserInit) {
    this.user = init.user;
    this.company = init.company;
    this.secondaryManagerAny = init.secondaryManagerAny;
    if (init.terminologyCollection) {
      this.terminology = init.terminologyCollection;
    }
  }

  public updateUser(u: User) {
    this.user.birthdayReminder = u.birthdayReminder;
    this.user.dateOfBirth = u.dateOfBirth;
    this.user.description = u.description;
    this.user.email = u.email;
    this.user.firstName = u.firstName;
    this.user.userState = u.userState;
    this.user.gender = u.gender;
    this.user.homeAddress = u.homeAddress;
    this.user.id = u.id;
    this.user.imageUrl = u.imageUrl;
    this.user.lastName = u.lastName;
    this.user.officeLocation = u.officeLocation;
    this.user.organisationalUnit = u.organisationalUnit;
    this.user.personalInterests = u.personalInterests;
    this.user.phoneNumber = u.phoneNumber;
    this.user.position = u.position;
    this.user.preferredContactMethod = u.preferredContactMethod;
    this.user.professionalInterests = u.professionalInterests;
    this.user.roles = u.roles;
    this.user.startDate = u.startDate;
    this.user.version = u.version;
    this.user.twitterHandle = u.twitterHandle;
    this.user.configuration = u.configuration;
  }

  public updateCompany(company: Company) {
    this.company.name = company.name;
    this.company.state = company.state;
    this.company.features = company.features;
    this.company.address = company.address;
    this.company.officeLocations = company.officeLocations;
    this.company.id = company.id;
  }

  /**
   * returns true if the current company has the provided feature enabled, otherwise returns false
   * @param features
   */
  public hasFeature(...features: CompanyFeatures[]): boolean {
    if (!this.company) {
      return false;
    }
    if (!this.company.features) {
      return false;
    }

    return this.company.features
      .filter(f => features.includes(f.feature))
      .every(f => f.enabled);
  }

  public hasAnyFeature(features: CompanyFeatures[]): boolean {
    if (this.company && this.company.features) {
      return this.company.features.some(f => {
        return (f.enabled === true && features.includes(f.feature));
      });
    }

    return false;
  }

  public hasAllFeature(features: CompanyFeatures[]): boolean {
    if (this.company && this.company.features) {
      return this.company.features.every(f => {
        return (f.enabled === true && features.includes(f.feature));
      });
    }

    return false;
  }

  /**
   * returns true if the current user has the provided role, otherwise returns false
   * @param roles
   */
  public hasRole(...roles: RoleName[]): boolean {
    if (!this.user) {
      return false;
    }
    if (!this.user.roles) {
      return false;
    }

    return (this.user.roles.filter(r => roles.includes(r.name)).length === roles.length);
  }

  public hasRoleMultiple(roles: RoleName[]): boolean {
    if (roles.includes(RoleName.ADMIN)) {
      roles.push(RoleName.FRANKLI_ADMIN);
    }

    if (this.user && this.user.roles) {
      return this.user.roles.some(r => roles.includes(r.name));
    }

    return false;
  }

  public clear(): void {
    this.user = undefined!;
    this.company = undefined!;
    localStorage.clear();
  }

  public clearKeepToken(): void {
    this.user = undefined!;
    this.company = undefined!;
  }

  public checkIsMobile() {
    if ((window && !!window.isMobileFn)) {
      return window.isMobileFn();
    }

    return false;
  }

  public getHighestAccessLevel(): AccessLevelRole {
    if (this.hasRole(RoleName.FRANKLI_ADMIN)) {
      return RoleName.FRANKLI_ADMIN;
    }

    if (this.hasRole(RoleName.ADMIN)) {
      return RoleName.ADMIN;
    }

    if (this.hasRole(RoleName.MANAGER)) {
      return RoleName.MANAGER;
    }

    return RoleName.USER;
  }

  public hasMinimumAccessLevelOf(accessLevel: AccessLevelRole): boolean {
    if (!this.user) { return false; }
    if (!this.user.roles) { return false; }

    const highestAccessLevel = this.getHighestAccessLevel();

    const userLevelRank = this.getAccessLevelRank(highestAccessLevel);
    const requestLevelRank = this.getAccessLevelRank(accessLevel);

    return (userLevelRank >= requestLevelRank);
  }

  private getAccessLevelRank(accessLevel: AccessLevelRole): number {
    switch(accessLevel) {
      case RoleName.USER:
        return 0;
      case RoleName.MANAGER:
        return 1;
      case RoleName.ADMIN:
        return 3;
      case RoleName.FRANKLI_ADMIN:
        return 4;
    }

    return 0;
  }

  public getTerminology(entity: TerminologyEntity): string {
    if (!this.terminology) {
      return 'Unknown';
    }

    if (!this.terminology[entity]) {
      return 'Unknown';
    }

    return this.terminology[entity];
  }

}

type AccessLevelRole = (RoleName.USER | RoleName.MANAGER | RoleName.ADMIN | RoleName.FRANKLI_ADMIN);
