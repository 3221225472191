import { Injectable } from '@angular/core';
import { QuestionBase } from '@app/shared/survey-components/dynamic-form/question-base';
import { AbstractControl, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class QuestionControlService {
  constructor() { }

  toFormGroup(questions: Array<QuestionBase<string>>): FormGroup {
    const group: { [p: string]: AbstractControl } = {};
    questions.forEach(question =>
        group[question.key] = new FormControl(question.value || '', QuestionControlService.getValidatorsForQuestion(question)));
    return new FormGroup(group);
  }

  private static getValidatorsForQuestion(question: QuestionBase<string>): Array<ValidatorFn> {
    const validators = new Array<ValidatorFn>();
    if (question.required) {
      validators.push(Validators.required);
    }
    validators.push(Validators.minLength(question.minCharacters));
    validators.push(Validators.maxLength(question.maxCharacters));
    return validators;
  }
}
