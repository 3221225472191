import { Injectable } from '@angular/core';
import { ZendeskQAService } from './interfaces/zendesk-qa.service';
import { HttpClient } from '@angular/common/http';
import { ZendeskQAToken } from '@app/models/integrations/zendesk-qa/zendesk-qa-token.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ZendeskQAAPIService implements ZendeskQAService {

  private readonly BASE_URL: string = '/api/zendeskqa';

  constructor(private http: HttpClient) { }

  // TOKEN

  getToken(): Observable<ZendeskQAToken | null> {
    const url = `${this.BASE_URL}/token`;
    return this.http.get<ZendeskQAToken | null>(url).pipe(
      map(zendeskQAToken => zendeskQAToken === null ? null : new ZendeskQAToken(zendeskQAToken))
    );
  }

  createToken(token: ZendeskQAToken): Observable<ZendeskQAToken> {
    const url = `${this.BASE_URL}/token`;
    return this.http.post<ZendeskQAToken>(url, token).pipe(map(zendeskQAToken => new ZendeskQAToken(zendeskQAToken)));
  }

  removeToken(): Observable<null> {
    const url = `${this.BASE_URL}/token`;
    return this.http.delete<null>(url);
  }

  // USER DATA

  // gets the IQS for a user over a certain number of months, defaults to 1 month
  getIQSByUserId(userId: number, months: number): Observable<number> {
  const url = `${this.BASE_URL}/metrics/iqs/${userId}/${months}`;
    return this.http.get<number>(url);
  }

  // MANUAL SYNC

  runManualSync(): Observable<boolean> {
    const url = `${this.BASE_URL}/manual-sync`;
    return this.http.get<boolean>(url);
  }
}
