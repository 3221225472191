import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LocationDetailsDto } from '../../model/location-details.model';
import { ILocationDetailsAPI } from './location-details-api.model';

@Injectable()
export class LocationDetailsAPIService implements ILocationDetailsAPI {
  private readonly BASE_URL: string = 'api/location-details';

  constructor(
      private http: HttpClient
  ) { }

  get(): Observable<LocationDetailsDto> {
    const url = `${this.BASE_URL}`;
    return this.http.get<LocationDetailsDto>(url);
  }

  update(updatedDetails: LocationDetailsDto): Observable<LocationDetailsDto> {
    const url = `${this.BASE_URL}`;
    return this.http.put<LocationDetailsDto>(url, updatedDetails);
  }
}
