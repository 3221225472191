import { Component } from '@angular/core';
import { LongRunningTaskService } from '../../service/long-running-task.service';
import { LongRunningTask } from '../../model/long-running-task.model';

@Component({
  selector: 'app-long-running-task-overview',
  templateUrl: './long-running-task-overview.component.html',
  styleUrls: ['./long-running-task-overview.component.scss']
})
export class LongRunningTaskOverviewComponent {

  get allTasks(): LongRunningTask[] {
    return this.longRunningTaskService.getAllTaskData();
  }

  constructor(
    public longRunningTaskService: LongRunningTaskService
  ) { }
}
