export enum GoalActivityType {
  COMMENT = 'COMMENT',
  CREATE = 'CREATE',
  EDIT = 'EDIT',
  COMPLETE = 'COMPLETE',
  INCOMPLETE = 'INCOMPLETE',
  ARCHIVE = 'ARCHIVE',
  KEY_RESULT = 'KEY_RESULT',
  UNARCHIVE = 'UNARCHIVE',
  PROGRESS_UPDATE = 'PROGRESS_UPDATE',
  STATUS_UPDATE = 'STATUS_UPDATE'
}
