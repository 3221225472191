import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { CommonMessages } from '@app/constants/common.messages';
import { GoalsMessages } from '@app/goals/goals.messages';
import { GoalKeyResultMeasureUnitPlacement } from '@app/models/goals/goal-key-result-measure-unit-placement.model';
import { GoalKeyResultType } from '@app/models/goals/goal-key-result-type.model';
import { ButtonGroupOption } from '@app/shared/components/inputs/button-group/button-group.component';
import { Globals } from '@app/shared/globals/globals';
import { DateUtils } from '@app/shared/utils/date.utils';
import { IMyDate } from 'angular-mydatepicker';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { GoalCreateResultValidationLimits, initKeyResultFormGroup } from '../goal-create-part-key-results.component';

const measureTypeOptionsData: ButtonGroupOption[] = [
  {
    value: GoalKeyResultType.BINARY,
    friendlyText: GoalsMessages.COMPLETE_INCOMPLETE
  },
  {
    value: GoalKeyResultType.NUMERIC,
    friendlyText: GoalsMessages.NUMERIC
  }
];

const measureUnitPlacementOptionsData: ButtonGroupOption[] = [
  {
    value: GoalKeyResultMeasureUnitPlacement.BEFORE,
    friendlyText: GoalsMessages.BEFORE
  },
  {
    value: GoalKeyResultMeasureUnitPlacement.AFTER,
    friendlyText: GoalsMessages.AFTER
  }
];

@Component({
  selector: 'app-goal-create-key-result-item',
  templateUrl: './goal-create-key-result-item.component.html',
  styleUrls: ['./goal-create-key-result-item.component.scss']
})
export class GoalCreateKeyResultItemComponent implements OnInit, OnDestroy {
  public readonly eGoalKeyResultType = GoalKeyResultType;
    public readonly eGoalKeyResultMeasureUnitPlacement = GoalKeyResultMeasureUnitPlacement;
    public readonly dateDisplayFormat = 'D MMM YYYY';
  public readonly measureTypeOptions = measureTypeOptionsData;
  public readonly measureUnitPlacementOptions = measureUnitPlacementOptionsData;
  public readonly eGoalsMessages = GoalsMessages;
  public readonly eCommonMessages = CommonMessages;
  public readonly eGoalCreateResultValidationLimits = GoalCreateResultValidationLimits;

  private readonly ngUnsubscribe$: Subject<void> = new Subject<void>();

  @Input() keyResultForm: FormGroup;
  @Input() submitted: boolean;
  @Input() submittedDraft: boolean;
  @Input() minDueDate: IMyDate;
  @Input() maxDueDate: IMyDate | null;

  @Output() calendarToggle: EventEmitter<boolean>;

  get controlKeyResultTitle(): FormControl {
    return this.keyResultForm.controls.result as FormControl;
  }

  get controlKeyResultEndDate(): FormControl {
    return this.keyResultForm.controls.endDate as FormControl;
  }

  get controlKeyResultType(): FormControl {
    return this.keyResultForm.controls.type as FormControl;
  }

  get controlKeyResultStartValue(): FormControl {
    return this.keyResultForm.controls.measureStartValue as FormControl;
  }

  get controlKeyResultGoalValue(): FormControl {
    return this.keyResultForm.controls.measureGoalValue as FormControl;
  }

  get controlKeyResultUnit(): FormControl {
    return this.keyResultForm.controls.measureUnit as FormControl;
  }

  get controlKeyResultUnitPlacement(): FormControl {
    return this.keyResultForm.controls.measureUnitPlacement as FormControl;
  }

  constructor(
    public globals: Globals
  ) {
    this.keyResultForm = initKeyResultFormGroup();
    this.calendarToggle = new EventEmitter<boolean>();
    this.submitted = false;
    this.submittedDraft = false;
    this.minDueDate = DateUtils.convertDateToPickerFormat(new Date());
    this.maxDueDate = null;
  }

  ngOnInit(): void {
    this.initEventListeners();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.unsubscribe();
  }

  onCalendarToggle(isOpen: boolean): void {
    this.calendarToggle.emit(isOpen);
  }

  initEventListeners(): void {
      this.controlKeyResultType.valueChanges
          .pipe(takeUntil(this.ngUnsubscribe$))
          .subscribe(
              (type: GoalKeyResultType) => {
                  switch (type) {
                      case GoalKeyResultType.BINARY:
                          this.keyResultForm.controls.measureStartValue.setValue(0);
                          this.keyResultForm.controls.measureGoalValue.setValue(1);
                          break;
                      case GoalKeyResultType.NUMERIC:
                      default:
                          this.keyResultForm.controls.measureStartValue.setValue(0);
                          this.keyResultForm.controls.measureGoalValue.setValue(100);
                          break;
                  }
              }
          );
  }
}
