import { Injectable } from '@angular/core';
import { MockService } from '@app/mock/api/mock-service';
import { HttpRequest } from '@angular/common/http';
import { CoachApplicationMockService } from '@app/mock/api/services/connect/coach-application.mock.service';
import { CoachingRequestMockService } from '@app/mock/api/services/connect/coaching-request.mock.service';
import { ConnectActivityMockService } from '@app/mock/api/services/connect/connect-activity.mock.service';
import { ConnectQuestionMockService } from '@app/mock/api/services/connect/connect-question.mock.service';
import { MentorMockService } from '@app/mock/api/services/connect/mentor.mock.service';
import { MentoringRequestMockService } from '@app/mock/api/services/connect/mentoring-request.mock.service';
import { sanitizeUrl } from '@app/shared/utils/helpers';

@Injectable()
export class ConnectMockHandler implements MockService {
    constructor(
        private readonly coachApplicationMockService: CoachApplicationMockService,
        private readonly coachingRequestMockService: CoachingRequestMockService,
        private readonly connectActivityMockService: ConnectActivityMockService,
        private readonly connectQuestionMockService: ConnectQuestionMockService,
        private readonly mentorMockService: MentorMockService,
        private readonly mentoringRequestMockService: MentoringRequestMockService
    ) { }

    handleRoute(req: HttpRequest<any>): any {
        const url = sanitizeUrl(req.url);

        const route = url.split('/')[2]; // Skip over 'api' and 'connect' in URL

        switch (route) {
            case 'coach-applications':
                return this.coachApplicationMockService.handleRoute(req);
            case 'coaching-requests':
                return this.coachingRequestMockService.handleRoute(req);
            case 'activity':
                return this.connectActivityMockService.handleRoute(req);
            case 'questions':
                return this.connectQuestionMockService.handleRoute(req);
            case 'mentors':
                return this.mentorMockService.handleRoute(req);
            case 'mentoring-requests':
                return this.mentoringRequestMockService.handleRoute(req);
        }
    }

}