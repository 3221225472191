import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { GoalVisibility } from '@app/models/goals/goal-visibility.model';
import { Globals } from '@app/shared/globals/globals';
import { GoalUtils } from '@app/shared/utils/goal.utils';

@Component({
  selector: 'app-goal-visibility-display',
  templateUrl: './goal-visibility-display.component.html',
  styleUrls: ['./goal-visibility-display.component.scss']
})
export class GoalVisibilityDisplayComponent implements OnInit, OnChanges {
  public readonly eGoalVisibility = GoalVisibility;

  @Input() visibility: GoalVisibility | null;

  visibilityTooltip: string;
  visibilityIconClass: string;

  constructor(
    public globals: Globals
  ) {
    this.visibility = GoalVisibility.PUBLIC;
    this.visibilityTooltip = '';
    this.visibilityIconClass = 'fa-question';
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    const visibility = changes['visibility'];
    if (visibility) {
      this.onTypeChanged(visibility.currentValue);
    }
  }

  onTypeChanged(visibility: GoalVisibility | null): void {
    if (visibility === null) {
      this.visibilityIconClass = 'fa-question';
      this.visibilityTooltip = '';
      return;
    }

    this.visibilityIconClass = GoalUtils.getIconClassForGoalVisibility(visibility);
      this.visibilityTooltip = GoalUtils.getMessageCodeForGoalVisibility(visibility);
  }
}
