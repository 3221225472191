import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { CommonMessages } from '@app/constants/common.messages';
import { GoalCreateService } from '@app/goals/goals-components/goals-create-old/goals-create.service';
import { GoalsMessages } from '@app/goals/goals.messages';
import { Tag } from '@app/domain/tag/model/tag.model';
import { GoalTemplate } from '@app/models/goals/templates/goal-template.model';
import { RoleName } from '@app/models/user-role.model';
import { IState } from '@app/models/state/state.model';
import { FilterMethod, FilterOption } from '@app/models/universal-filter-option.model';
import { UserMinimal } from '@app/models/user/user-minimal.model';
import { GoalsAPIService } from '@app/shared/api/goals.api.service';
import { Globals } from '@app/shared/globals/globals';
import { PaginationNewComponent } from '@app/shared/pagination/pagination-new/pagination-new.component';
import { DefaultFilter, Categories, Category } from '@app/shared/universal-filter/universal-filter.component';
import { GoalCreatePageViews } from '../../goal-create.component';

interface IUniversalFilterData {
  searchControl: FormControl;
  filterOptions: FilterOption[];
  searchProps: string[];
  lastFilterResult: number[];
  defaultFilters: DefaultFilter;
  currentCategories: Categories;
}

type PageState = IState
@Component({
  selector: 'app-goal-create-templates',
  templateUrl: './goal-create-templates.component.html',
  styleUrls: ['./goal-create-templates.component.scss']
})
export class GoalCreateTemplatesComponent implements OnInit {
  public readonly eGoalsMessages = GoalsMessages;
  public readonly eCommonMessages = CommonMessages;

  templatesMaster: GoalTemplate[];
  templatesFiltered: GoalTemplate[];
  templatesDisplay: GoalTemplate[];

  state: PageState;

  canCreateTemplates: boolean;

  universalFilterData: IUniversalFilterData;

  @Output() changeView: EventEmitter<GoalCreatePageViews>;

  @ViewChild('pagination') pagination?: PaginationNewComponent;

  constructor(
    public globals: Globals,
    private router: Router,
    private goalsAPIService: GoalsAPIService,
    private goalCreateService: GoalCreateService
  ) {
    this.templatesMaster = [];
    this.templatesFiltered = [];
    this.templatesDisplay = [];

    this.canCreateTemplates = false;

    this.state = {
      loading: true,
      error: false,
      errorMessage: ''
    };

    this.changeView = new EventEmitter<GoalCreatePageViews>();
    
    this.universalFilterData = this.initUniversalFilter();
  }

  ngOnInit(): void {
    this.canCreateTemplates = this.checkCanCreateTemplates();
    this.getGoalTemplates();
  }

  // #region - POPULATION
  getGoalTemplates(): void {
    this.goalsAPIService.getGoalTemplates()
      .subscribe(
        (templates) => {
          this.populateTemplates(templates);
          this.state.loading = false;
        }
      );
  }

  populateTemplates(templates: GoalTemplate[]): void {
    this.templatesMaster = templates;
    this.templatesFiltered = templates;
    this.populateUniversalFilter(templates);
  }
  // #endregion

  // #region - CREATE ACTION
  checkCanCreateTemplates(): boolean {
    return (this.globals.hasRole(RoleName.ADMIN) || this.globals.hasRole(RoleName.FRANKLI_ADMIN));
  }

  onClickCreateTemplate(): void {
    this.router.navigate(['admin/dashboard/goals/templates']);
  }
  // #endregion

  // #region - UNIVERSAL FILTER
  initUniversalFilter(): IUniversalFilterData {
    return {
      searchControl: new FormControl('', []),
      filterOptions: [],
      searchProps: [],
      lastFilterResult: [],
      defaultFilters: {},
      currentCategories: {}
    };
  }

  populateUniversalFilter(data: GoalTemplate[]) {
    this.universalFilterData.searchProps = [GoalsMessages.TITLE];
    this.universalFilterData.filterOptions = data.map(v => this.getFilterOptionsForTemplate(v));
    const categoriesSelected = (this.universalFilterData.currentCategories && (Object.keys(this.universalFilterData.currentCategories).length > 0));
    this.universalFilterData.defaultFilters = ((categoriesSelected) ? this.getSelectedFilters() : this.getDefaultFilters());
  }

  getFilterOptionsForTemplate(template: GoalTemplate): FilterOption {
    const option: FilterOption = {
      id: template.id,
      properties: {
        [GoalsMessages.TITLE]: { value: template.title, filterMethod: FilterMethod.OR },
        [GoalsMessages.TAGS]: { value: this.getFilterTags(template.tags), filterMethod: FilterMethod.OR },
        [CommonMessages.CREATOR]: { value: this.getFilterCreator(template.creator), filterMethod: FilterMethod.OR }
      }
    };

    return option;
  }

  getFilterTags(tags: Tag[]): string | string[] {
    if (!tags || (tags.length === 0)) {
      return CommonMessages.NOT_SET;
    }

    return tags.map(t => t.text);
  }

  getFilterCreator(creator: UserMinimal): string {
    if (!creator) {
      return CommonMessages.UNKNOWN;
    }

    return `${creator.firstName} ${creator.lastName}`;
  }

  getSelectedFilters(): DefaultFilter {
    const data = this.universalFilterData.currentCategories;
    const output: DefaultFilter = {};

    for (const key in data) {
      if (Object.prototype.hasOwnProperty.call(data, key)) {
        const keyCategories: Category[] = data[key];
        const categoriesEnabled = keyCategories.filter(c => c.enabled).map(c => c.name.toLowerCase());

        if (!output[key]) {
          output[key] = [];
        }

        output[key] = [...output[key], ...categoriesEnabled];
      }
    }

    return output;
  }

  getDefaultFilters(): DefaultFilter {
    const defaultFilters: DefaultFilter = {};

    // Add any default features here. See video-library-browse.ts for examples on how

    return defaultFilters;
  }

  filterEmit(results: number[], doRefresh = true): void {
    this.universalFilterData.lastFilterResult = results;
    this.templatesFiltered = this.templatesMaster.filter(t => results.includes(t.id));

    if (doRefresh) {
      this.refreshPagination();
    }
  }

  filterCategoriesChanged(categories: Categories): void {
    this.universalFilterData.currentCategories = categories;
  }
  // #endregion

  refreshPagination(): void {
    if (this.pagination) {
      this.pagination.update();
    }
  }

  onSelectTemplate(template: GoalTemplate): void {
    this.goalCreateService.sendGoalTemplateCopied(template);
    this.changeView.emit(GoalCreatePageViews.MAIN);
  }
}
