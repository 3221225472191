import { SortingParams } from '@app/models/api/sorting-params.model';
import { SortDirection } from '@app/models/api/sort-direction.enum';
import { PagingParams } from '@app/models/api/paging-params.model';

export const staticPositionSortingParams: SortingParams = {
  sortAttributes: ['name'],
  sortDirection: SortDirection.ASC
};

export const staticPositionPagingParams: PagingParams = {
  pageSize: 15,
  pageNumber: 0
};