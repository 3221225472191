import { Injectable } from '@angular/core';
import { InterestAPIService } from '@app/domain/interest/api/interest-api.service';
import { Interest } from '@app/domain/interest/model/interest.model';
import { UserAPIService } from '@app/shared/api/user.api.service';
import { Globals } from '@app/shared/globals/globals';
import { NotifyUtils } from '@app/shared/utils/notify.utils';
import { Observable } from 'rxjs';
import { SettingsMessages } from '../locale/settings.messages';

@Injectable({
  providedIn: 'root'
})
export class SkillsBusinessService {

  private updatingSkills: boolean;
  
  constructor(
    private interestAPI: InterestAPIService, 
    private userAPI: UserAPIService, 
    private notifyUtils: NotifyUtils, 
    public globals: Globals
  ) {
    this.updatingSkills = false;
  }

  setSkills(skills: Interest[]): Promise<Interest[]> {
    if (this.updatingSkills) { return new Promise((resolve, reject) => reject()); }
    this.updatingSkills = true;

    return this.userAPI.updateSkills(skills).toPromise()
      .then(res => {
        this.notifyUtils.notify(SettingsMessages.SUCCESS_PROFILE_UPDATED);
        this.globals.user.skills = res.skills;
        
        return res.skills;
      })
      .finally(() => {
        this.updatingSkills = false;
      });
  }

  getSkillsForUserId(userId: number): Observable<Interest[]> {
    return this.interestAPI.getSkillsForUserId(userId);
  }
}
