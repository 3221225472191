import {EvaluationUtils} from '@app/shared/utils/evaluation.utils';
import {CycleStepMap, EvaluationModuleType} from './evaluation-module-type.model';
import {SurveyQuestionType, SurveyQuestionTypeMap} from '@app/models/survey-question-type.enum';
import {QuestionBankQuestionCategory} from '@app/models/evaluation/question-bank-question-category.enum';

export interface AssessmentGroupInfo {
    id: number;
    cycleId: number;
    questionType: SurveyQuestionType;
    answerIndex: number;
    category: QuestionBankQuestionCategory;
    value: string;
    colour: string;
    groupTitle: string;
    groupDescription: string;
}

export class AssessmentGroupInfoFiltered {
  // Index of step then question type
  assessmentGroupInfo: CycleStepMap<SurveyQuestionTypeMap<AssessmentGroupInfo[]>>;

  constructor(assessmentGroups: AssessmentGroupInfo[], questionBank: boolean) {
    // NOTE: Manager review and peer review are the same

    // Set defaults
    this.assessmentGroupInfo = {
      [EvaluationModuleType.SELF_REFLECTION]: {},
      [EvaluationModuleType.MANAGER_REVIEW]: {},
      [EvaluationModuleType.PEER_REVIEW]: {},
      [EvaluationModuleType.UPWARD_REVIEW]: {}
    };

    // Get assessment values for each
    EvaluationUtils.AssessmentQuestionTypes.forEach(q => {
      this.assessmentGroupInfo[EvaluationModuleType.SELF_REFLECTION][q] = filterAssessmentGroups(assessmentGroups, q, QuestionBankQuestionCategory.SELF_REFLECTION);
      this.assessmentGroupInfo[EvaluationModuleType.MANAGER_REVIEW][q] = filterAssessmentGroups(assessmentGroups, q, QuestionBankQuestionCategory.MANAGER_REVIEW);
      this.assessmentGroupInfo[EvaluationModuleType.PEER_REVIEW][q] = filterAssessmentGroups(assessmentGroups, q, questionBank ? QuestionBankQuestionCategory.PEER_REVIEW : QuestionBankQuestionCategory.MANAGER_REVIEW);
      this.assessmentGroupInfo[EvaluationModuleType.UPWARD_REVIEW][q] = filterAssessmentGroups(assessmentGroups, q, QuestionBankQuestionCategory.UPWARD_REVIEW);
    });
  }
}

export function filterAssessmentGroups(assessmentGroups: AssessmentGroupInfo[], questionType: SurveyQuestionType, category: QuestionBankQuestionCategory): AssessmentGroupInfo[] {
  return assessmentGroups.filter(x => x.questionType === questionType && x.category === category).sort((x, y) => {
    return x.answerIndex - y.answerIndex;
  });
}

