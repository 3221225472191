import {Injectable} from '@angular/core';
import {HttpClient, HttpEvent} from '@angular/common/http';
import {Observable} from 'rxjs';
import {BulkUpdateResponseDto, BulkUpdateUserDto, BulkUpload} from '@app/models/bulk-update/bulk-update.model';
import {FileAPIService} from './file.api.service';

@Injectable({providedIn: 'root'})
export class BulkUploadAPIService {

  private readonly BASE_URL = '/api/bulk-upload';

  constructor(
      private http: HttpClient,
      private fileAPIService: FileAPIService
  ) {
  }

  getAllBulkUploadCreate(): Observable<Array<BulkUpload>> {
    const URL = `${this.BASE_URL}/create`;

    return this.http.get<Array<BulkUpload>>(URL);
  }

  userBulkCreateFromFile(file: File): Observable<HttpEvent<{}>> {
    const URL = `${this.BASE_URL}/create/file`

    return this.fileAPIService.saveFileWithProgress(file, URL);
  }

  userBulkUpdateFromFile(file: File): Observable<HttpEvent<{}>> {
    const URL = `${this.BASE_URL}/update/file`

    return this.fileAPIService.saveFileWithProgress(file, URL);
  }

  userBulkArchiveFromFile(file: File): Observable<HttpEvent<{}>> {
    const URL = `${this.BASE_URL}/archive/file`

    return this.fileAPIService.saveFileWithProgress(file, URL);
  }


  userBulkCreate(bulk: Array<BulkUpdateUserDto>): Observable<BulkUpdateResponseDto> {
    const URL = `${this.BASE_URL}/create`;
    return this.http.post<BulkUpdateResponseDto>(URL, bulk);
  }

  userBulkUpdate(bulk: Array<BulkUpdateUserDto>): Observable<BulkUpdateResponseDto> {
    const URL = `${this.BASE_URL}/update`;
    return this.http.post<BulkUpdateResponseDto>(URL, bulk);
  }

  exportAllUsersToXLSX(): Observable<any> {
    const URL = `${this.BASE_URL}/export`;
    return this.http.get(URL, {responseType: 'blob'});
  }
}
