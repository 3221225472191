import { Component, Input, OnInit } from '@angular/core';
import { AbsorbLmsAPIService } from '@app/domain/absorblms/api/absorblms-api.service';
import { AbsorbLmsCertificate } from '@app/domain/absorblms/model/absorblms-certificate.model';
import { IState } from '@app/models/state/state.model';
import { User } from '@app/models/user/user.model';

@Component({
  selector: 'app-profile-absorblms-certificates',
  templateUrl: './profile-absorblms-certificates.component.html',
  styleUrls: ['./profile-absorblms-certificates.component.scss']
})
export class ProfileAbsorblmsCertificatesComponent implements OnInit {

  @Input() user: User;

  state: IState;
  certificates: AbsorbLmsCertificate[];

  constructor(
    private absorbLmsAPIService: AbsorbLmsAPIService
  ) {
    this.state = {
      loading: true,
      error: false,
      errorMessage: ''
    };
    this.certificates = [];
  }

  ngOnInit(): void {
    this.getData();
  }

  getData(): void {
    if (!this.user) { return; }
    this.absorbLmsAPIService.getDataForUser(this.user.id)
      .toPromise()
      .then(userData => {
        this.certificates = userData.certificates;
        this.state.loading = false;
      })
      .catch(err => this.doError(err.message));
  }
  
  doError(message: string): void {
    this.state.error = true;
    this.state.errorMessage = message;
    this.state.loading = false;
  }
}
