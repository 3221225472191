import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { LongRunningTaskAPIService } from '../api/long-running-task-api.service';
import { LongRunningTask } from '../model/long-running-task.model';
import { NotifyUtils } from '@app/shared/utils/notify.utils';

// const TEST_DATA: LongRunningTask[] = [
//   new LongRunningTask({
//     'id': 12,
//     'companyId': 1,
//     'sourceUserId': 2,
//     'sourceUser': {
//       'id': 2,
//       'managerId': 2,
//       'organisationalUnitId': 1,
//       'officeLocationId': 1,
//       'positionId': 2,
//       'email': 'noel@frankli.io',
//       'firstName': 'Noel',
//       'lastName': 'Dykes',
//       'imageUrl': '../../assets/img/frankli-images/noel.png',
//       'linkedIn': null,
//       'twitterHandle': '',
//       'phoneNumber': '07191-8877665',
//       'startDate': new Date('2018-07-03'),
//       'roles': [
//         { 'id': 7, 'name': RoleName.GOAL_COMPANY },
//         { 'id': 1, 'name': RoleName.FRANKLI_ADMIN }
//       ]
//     },
//     'taskType': LongRunningTaskType.GOAL_DISTRIBUTION,
//     'requestBody': '{"title":"Long running task 9","type":"PERSONAL_OPERATIONAL","departmentId":null,"officeLocationId":null,"alignmentId":null,"endDate":"2024-09-03T23:00:00.000Z","visibility":"PRIVATE","priority":"NOT_SET","tagIds":[],"ownerIds":[2],"keyResults":[{"result":"Exceed Net Promoter Score (NPS) of over 8.0","type":"BINARY","endDate":null,"measureStartValue":0.0,"measureGoalValue":1.0,"measureUnit":"","measureUnitPlacement":"AFTER","measureCurrentValue":0.0,"orderIndex":0},{"result":"Get 1000 survey responses to annual satisfaction survey","type":"NUMERIC","endDate":null,"measureStartValue":0.0,"measureGoalValue":1.0,"measureUnit":"responses","measureUnitPlacement":"AFTER","measureCurrentValue":0.0,"orderIndex":1},{"result":"Conduct 50 phone interviews with top customers","type":"NUMERIC","endDate":null,"measureStartValue":0.0,"measureGoalValue":1.0,"measureUnit":"interviews","measureUnitPlacement":"AFTER","measureCurrentValue":0.0,"orderIndex":2},{"result":"Conduct 15 phone interviews with recently churned customers","type":"NUMERIC","endDate":null,"measureStartValue":0.0,"measureGoalValue":1.0,"measureUnit":"interviews","measureUnitPlacement":"AFTER","measureCurrentValue":0.0,"orderIndex":3},{"result":"Present an action plan of 10 improvements for next quarter","type":"BINARY","endDate":null,"measureStartValue":0.0,"measureGoalValue":1.0,"measureUnit":"","measureUnitPlacement":"AFTER","measureCurrentValue":0.0,"orderIndex":4}],"developmentNeeds":null,"complete":null,"archived":null,"distribute":"COMPANY","tasks":[],"draftId":null}',
//     'events': [
//       {
//         'id': 438,
//         'parentTaskId': 12,
//         'eventType': LongRunningTaskEventType.EVENT_SUCCESS,
//         'message': 'Goal created for Daire Finn (9 of 37)',
//         'metadata': '{"title":"Long running task 9","type":"PERSONAL_OPERATIONAL","departmentId":null,"officeLocationId":null,"alignmentId":null,"endDate":"2024-09-03T23:00:00.000Z","visibility":"PRIVATE","priority":"NOT_SET","tagIds":[],"ownerIds":[10],"keyResults":[{"result":"Exceed Net Promoter Score (NPS) of over 8.0","type":"BINARY","endDate":null,"measureStartValue":0.0,"measureGoalValue":1.0,"measureUnit":"","measureUnitPlacement":"AFTER","measureCurrentValue":0.0,"orderIndex":0},{"result":"Get 1000 survey responses to annual satisfaction survey","type":"NUMERIC","endDate":null,"measureStartValue":0.0,"measureGoalValue":1.0,"measureUnit":"responses","measureUnitPlacement":"AFTER","measureCurrentValue":0.0,"orderIndex":1},{"result":"Conduct 50 phone interviews with top customers","type":"NUMERIC","endDate":null,"measureStartValue":0.0,"measureGoalValue":1.0,"measureUnit":"interviews","measureUnitPlacement":"AFTER","measureCurrentValue":0.0,"orderIndex":2},{"result":"Conduct 15 phone interviews with recently churned customers","type":"NUMERIC","endDate":null,"measureStartValue":0.0,"measureGoalValue":1.0,"measureUnit":"interviews","measureUnitPlacement":"AFTER","measureCurrentValue":0.0,"orderIndex":3},{"result":"Present an action plan of 10 improvements for next quarter","type":"BINARY","endDate":null,"measureStartValue":0.0,"measureGoalValue":1.0,"measureUnit":"","measureUnitPlacement":"AFTER","measureCurrentValue":0.0,"orderIndex":4}],"developmentNeeds":null,"complete":null,"archived":null,"distribute":"COMPANY","tasks":[],"draftId":null}',
//         'progress': 24,
//         'createdAt': '2024-08-21T10:33:26.000Z',
//         'updatedAt': '2024-08-21T10:33:26.000Z'
//       },
//       {
//         'id': 442,
//         'parentTaskId': 12,
//         'eventType': LongRunningTaskEventType.EVENT_SUCCESS,
//         'message': 'Goal created for Davis Thornton (13 of 37)',
//         'metadata': '{"title":"Long running task 9","type":"PERSONAL_OPERATIONAL","departmentId":null,"officeLocationId":null,"alignmentId":null,"endDate":"2024-09-03T23:00:00.000Z","visibility":"PRIVATE","priority":"NOT_SET","tagIds":[],"ownerIds":[14],"keyResults":[{"result":"Exceed Net Promoter Score (NPS) of over 8.0","type":"BINARY","endDate":null,"measureStartValue":0.0,"measureGoalValue":1.0,"measureUnit":"","measureUnitPlacement":"AFTER","measureCurrentValue":0.0,"orderIndex":0},{"result":"Get 1000 survey responses to annual satisfaction survey","type":"NUMERIC","endDate":null,"measureStartValue":0.0,"measureGoalValue":1.0,"measureUnit":"responses","measureUnitPlacement":"AFTER","measureCurrentValue":0.0,"orderIndex":1},{"result":"Conduct 50 phone interviews with top customers","type":"NUMERIC","endDate":null,"measureStartValue":0.0,"measureGoalValue":1.0,"measureUnit":"interviews","measureUnitPlacement":"AFTER","measureCurrentValue":0.0,"orderIndex":2},{"result":"Conduct 15 phone interviews with recently churned customers","type":"NUMERIC","endDate":null,"measureStartValue":0.0,"measureGoalValue":1.0,"measureUnit":"interviews","measureUnitPlacement":"AFTER","measureCurrentValue":0.0,"orderIndex":3},{"result":"Present an action plan of 10 improvements for next quarter","type":"BINARY","endDate":null,"measureStartValue":0.0,"measureGoalValue":1.0,"measureUnit":"","measureUnitPlacement":"AFTER","measureCurrentValue":0.0,"orderIndex":4}],"developmentNeeds":null,"complete":null,"archived":null,"distribute":"COMPANY","tasks":[],"draftId":null}',
//         'progress': 35,
//         'createdAt': '2024-08-21T10:33:27.000Z',
//         'updatedAt': '2024-08-21T10:33:27.000Z'
//       },
//       {
//         'id': 462,
//         'parentTaskId': 12,
//         'eventType': LongRunningTaskEventType.EVENT_SUCCESS,
//         'message': 'Goal created for Garth Osan (33 of 37)',
//         'metadata': '{"title":"Long running task 9","type":"PERSONAL_OPERATIONAL","departmentId":null,"officeLocationId":null,"alignmentId":null,"endDate":"2024-09-03T23:00:00.000Z","visibility":"PRIVATE","priority":"NOT_SET","tagIds":[],"ownerIds":[34],"keyResults":[{"result":"Exceed Net Promoter Score (NPS) of over 8.0","type":"BINARY","endDate":null,"measureStartValue":0.0,"measureGoalValue":1.0,"measureUnit":"","measureUnitPlacement":"AFTER","measureCurrentValue":0.0,"orderIndex":0},{"result":"Get 1000 survey responses to annual satisfaction survey","type":"NUMERIC","endDate":null,"measureStartValue":0.0,"measureGoalValue":1.0,"measureUnit":"responses","measureUnitPlacement":"AFTER","measureCurrentValue":0.0,"orderIndex":1},{"result":"Conduct 50 phone interviews with top customers","type":"NUMERIC","endDate":null,"measureStartValue":0.0,"measureGoalValue":1.0,"measureUnit":"interviews","measureUnitPlacement":"AFTER","measureCurrentValue":0.0,"orderIndex":2},{"result":"Conduct 15 phone interviews with recently churned customers","type":"NUMERIC","endDate":null,"measureStartValue":0.0,"measureGoalValue":1.0,"measureUnit":"interviews","measureUnitPlacement":"AFTER","measureCurrentValue":0.0,"orderIndex":3},{"result":"Present an action plan of 10 improvements for next quarter","type":"BINARY","endDate":null,"measureStartValue":0.0,"measureGoalValue":1.0,"measureUnit":"","measureUnitPlacement":"AFTER","measureCurrentValue":0.0,"orderIndex":4}],"developmentNeeds":null,"complete":null,"archived":null,"distribute":"COMPANY","tasks":[],"draftId":null}',
//         'progress': 89,
//         'createdAt': '2024-08-21T10:33:31.000Z',
//         'updatedAt': '2024-08-21T10:33:31.000Z'
//       },
//       {
//         'id': 446,
//         'parentTaskId': 12,
//         'eventType': LongRunningTaskEventType.EVENT_SUCCESS,
//         'message': 'Goal created for Tom Van Hesteren (17 of 37)',
//         'metadata': '{"title":"Long running task 9","type":"PERSONAL_OPERATIONAL","departmentId":null,"officeLocationId":null,"alignmentId":null,"endDate":"2024-09-03T23:00:00.000Z","visibility":"PRIVATE","priority":"NOT_SET","tagIds":[],"ownerIds":[18],"keyResults":[{"result":"Exceed Net Promoter Score (NPS) of over 8.0","type":"BINARY","endDate":null,"measureStartValue":0.0,"measureGoalValue":1.0,"measureUnit":"","measureUnitPlacement":"AFTER","measureCurrentValue":0.0,"orderIndex":0},{"result":"Get 1000 survey responses to annual satisfaction survey","type":"NUMERIC","endDate":null,"measureStartValue":0.0,"measureGoalValue":1.0,"measureUnit":"responses","measureUnitPlacement":"AFTER","measureCurrentValue":0.0,"orderIndex":1},{"result":"Conduct 50 phone interviews with top customers","type":"NUMERIC","endDate":null,"measureStartValue":0.0,"measureGoalValue":1.0,"measureUnit":"interviews","measureUnitPlacement":"AFTER","measureCurrentValue":0.0,"orderIndex":2},{"result":"Conduct 15 phone interviews with recently churned customers","type":"NUMERIC","endDate":null,"measureStartValue":0.0,"measureGoalValue":1.0,"measureUnit":"interviews","measureUnitPlacement":"AFTER","measureCurrentValue":0.0,"orderIndex":3},{"result":"Present an action plan of 10 improvements for next quarter","type":"BINARY","endDate":null,"measureStartValue":0.0,"measureGoalValue":1.0,"measureUnit":"","measureUnitPlacement":"AFTER","measureCurrentValue":0.0,"orderIndex":4}],"developmentNeeds":null,"complete":null,"archived":null,"distribute":"COMPANY","tasks":[],"draftId":null}',
//         'progress': 45,
//         'createdAt': '2024-08-21T10:33:28.000Z',
//         'updatedAt': '2024-08-21T10:33:28.000Z'
//       },
//       {
//         'id': 456,
//         'parentTaskId': 12,
//         'eventType': LongRunningTaskEventType.EVENT_SUCCESS,
//         'message': 'Goal created for Brenden McKoy (27 of 37)',
//         'metadata': '{"title":"Long running task 9","type":"PERSONAL_OPERATIONAL","departmentId":null,"officeLocationId":null,"alignmentId":null,"endDate":"2024-09-03T23:00:00.000Z","visibility":"PRIVATE","priority":"NOT_SET","tagIds":[],"ownerIds":[28],"keyResults":[{"result":"Exceed Net Promoter Score (NPS) of over 8.0","type":"BINARY","endDate":null,"measureStartValue":0.0,"measureGoalValue":1.0,"measureUnit":"","measureUnitPlacement":"AFTER","measureCurrentValue":0.0,"orderIndex":0},{"result":"Get 1000 survey responses to annual satisfaction survey","type":"NUMERIC","endDate":null,"measureStartValue":0.0,"measureGoalValue":1.0,"measureUnit":"responses","measureUnitPlacement":"AFTER","measureCurrentValue":0.0,"orderIndex":1},{"result":"Conduct 50 phone interviews with top customers","type":"NUMERIC","endDate":null,"measureStartValue":0.0,"measureGoalValue":1.0,"measureUnit":"interviews","measureUnitPlacement":"AFTER","measureCurrentValue":0.0,"orderIndex":2},{"result":"Conduct 15 phone interviews with recently churned customers","type":"NUMERIC","endDate":null,"measureStartValue":0.0,"measureGoalValue":1.0,"measureUnit":"interviews","measureUnitPlacement":"AFTER","measureCurrentValue":0.0,"orderIndex":3},{"result":"Present an action plan of 10 improvements for next quarter","type":"BINARY","endDate":null,"measureStartValue":0.0,"measureGoalValue":1.0,"measureUnit":"","measureUnitPlacement":"AFTER","measureCurrentValue":0.0,"orderIndex":4}],"developmentNeeds":null,"complete":null,"archived":null,"distribute":"COMPANY","tasks":[],"draftId":null}',
//         'progress': 72,
//         'createdAt': '2024-08-21T10:33:30.000Z',
//         'updatedAt': '2024-08-21T10:33:30.000Z'
//       },
//       {
//         'id': 449,
//         'parentTaskId': 12,
//         'eventType': LongRunningTaskEventType.EVENT_SUCCESS,
//         'message': 'Goal created for Gilberte Monkman (20 of 37)',
//         'metadata': '{"title":"Long running task 9","type":"PERSONAL_OPERATIONAL","departmentId":null,"officeLocationId":null,"alignmentId":null,"endDate":"2024-09-03T23:00:00.000Z","visibility":"PRIVATE","priority":"NOT_SET","tagIds":[],"ownerIds":[21],"keyResults":[{"result":"Exceed Net Promoter Score (NPS) of over 8.0","type":"BINARY","endDate":null,"measureStartValue":0.0,"measureGoalValue":1.0,"measureUnit":"","measureUnitPlacement":"AFTER","measureCurrentValue":0.0,"orderIndex":0},{"result":"Get 1000 survey responses to annual satisfaction survey","type":"NUMERIC","endDate":null,"measureStartValue":0.0,"measureGoalValue":1.0,"measureUnit":"responses","measureUnitPlacement":"AFTER","measureCurrentValue":0.0,"orderIndex":1},{"result":"Conduct 50 phone interviews with top customers","type":"NUMERIC","endDate":null,"measureStartValue":0.0,"measureGoalValue":1.0,"measureUnit":"interviews","measureUnitPlacement":"AFTER","measureCurrentValue":0.0,"orderIndex":2},{"result":"Conduct 15 phone interviews with recently churned customers","type":"NUMERIC","endDate":null,"measureStartValue":0.0,"measureGoalValue":1.0,"measureUnit":"interviews","measureUnitPlacement":"AFTER","measureCurrentValue":0.0,"orderIndex":3},{"result":"Present an action plan of 10 improvements for next quarter","type":"BINARY","endDate":null,"measureStartValue":0.0,"measureGoalValue":1.0,"measureUnit":"","measureUnitPlacement":"AFTER","measureCurrentValue":0.0,"orderIndex":4}],"developmentNeeds":null,"complete":null,"archived":null,"distribute":"COMPANY","tasks":[],"draftId":null}',
//         'progress': 54,
//         'createdAt': '2024-08-21T10:33:28.000Z',
//         'updatedAt': '2024-08-21T10:33:28.000Z'
//       },
//       {
//         'id': 448,
//         'parentTaskId': 12,
//         'eventType': LongRunningTaskEventType.EVENT_SUCCESS,
//         'message': 'Goal created for Trixy Paulon (19 of 37)',
//         'metadata': '{"title":"Long running task 9","type":"PERSONAL_OPERATIONAL","departmentId":null,"officeLocationId":null,"alignmentId":null,"endDate":"2024-09-03T23:00:00.000Z","visibility":"PRIVATE","priority":"NOT_SET","tagIds":[],"ownerIds":[20],"keyResults":[{"result":"Exceed Net Promoter Score (NPS) of over 8.0","type":"BINARY","endDate":null,"measureStartValue":0.0,"measureGoalValue":1.0,"measureUnit":"","measureUnitPlacement":"AFTER","measureCurrentValue":0.0,"orderIndex":0},{"result":"Get 1000 survey responses to annual satisfaction survey","type":"NUMERIC","endDate":null,"measureStartValue":0.0,"measureGoalValue":1.0,"measureUnit":"responses","measureUnitPlacement":"AFTER","measureCurrentValue":0.0,"orderIndex":1},{"result":"Conduct 50 phone interviews with top customers","type":"NUMERIC","endDate":null,"measureStartValue":0.0,"measureGoalValue":1.0,"measureUnit":"interviews","measureUnitPlacement":"AFTER","measureCurrentValue":0.0,"orderIndex":2},{"result":"Conduct 15 phone interviews with recently churned customers","type":"NUMERIC","endDate":null,"measureStartValue":0.0,"measureGoalValue":1.0,"measureUnit":"interviews","measureUnitPlacement":"AFTER","measureCurrentValue":0.0,"orderIndex":3},{"result":"Present an action plan of 10 improvements for next quarter","type":"BINARY","endDate":null,"measureStartValue":0.0,"measureGoalValue":1.0,"measureUnit":"","measureUnitPlacement":"AFTER","measureCurrentValue":0.0,"orderIndex":4}],"developmentNeeds":null,"complete":null,"archived":null,"distribute":"COMPANY","tasks":[],"draftId":null}',
//         'progress': 51,
//         'createdAt': '2024-08-21T10:33:28.000Z',
//         'updatedAt': '2024-08-21T10:33:28.000Z'
//       },
//       {
//         'id': 434,
//         'parentTaskId': 12,
//         'eventType': LongRunningTaskEventType.EVENT_SUCCESS,
//         'message': 'Goal created for Neil Kenny (5 of 37)',
//         'metadata': '{"title":"Long running task 9","type":"PERSONAL_OPERATIONAL","departmentId":null,"officeLocationId":null,"alignmentId":null,"endDate":"2024-09-03T23:00:00.000Z","visibility":"PRIVATE","priority":"NOT_SET","tagIds":[],"ownerIds":[6],"keyResults":[{"result":"Exceed Net Promoter Score (NPS) of over 8.0","type":"BINARY","endDate":null,"measureStartValue":0.0,"measureGoalValue":1.0,"measureUnit":"","measureUnitPlacement":"AFTER","measureCurrentValue":0.0,"orderIndex":0},{"result":"Get 1000 survey responses to annual satisfaction survey","type":"NUMERIC","endDate":null,"measureStartValue":0.0,"measureGoalValue":1.0,"measureUnit":"responses","measureUnitPlacement":"AFTER","measureCurrentValue":0.0,"orderIndex":1},{"result":"Conduct 50 phone interviews with top customers","type":"NUMERIC","endDate":null,"measureStartValue":0.0,"measureGoalValue":1.0,"measureUnit":"interviews","measureUnitPlacement":"AFTER","measureCurrentValue":0.0,"orderIndex":2},{"result":"Conduct 15 phone interviews with recently churned customers","type":"NUMERIC","endDate":null,"measureStartValue":0.0,"measureGoalValue":1.0,"measureUnit":"interviews","measureUnitPlacement":"AFTER","measureCurrentValue":0.0,"orderIndex":3},{"result":"Present an action plan of 10 improvements for next quarter","type":"BINARY","endDate":null,"measureStartValue":0.0,"measureGoalValue":1.0,"measureUnit":"","measureUnitPlacement":"AFTER","measureCurrentValue":0.0,"orderIndex":4}],"developmentNeeds":null,"complete":null,"archived":null,"distribute":"COMPANY","tasks":[],"draftId":null}',
//         'progress': 13,
//         'createdAt': '2024-08-21T10:33:25.000Z',
//         'updatedAt': '2024-08-21T10:33:25.000Z'
//       },
//       {
//         'id': 452,
//         'parentTaskId': 12,
//         'eventType': LongRunningTaskEventType.EVENT_SUCCESS,
//         'message': 'Goal created for Bary Castree (23 of 37)',
//         'metadata': '{"title":"Long running task 9","type":"PERSONAL_OPERATIONAL","departmentId":null,"officeLocationId":null,"alignmentId":null,"endDate":"2024-09-03T23:00:00.000Z","visibility":"PRIVATE","priority":"NOT_SET","tagIds":[],"ownerIds":[24],"keyResults":[{"result":"Exceed Net Promoter Score (NPS) of over 8.0","type":"BINARY","endDate":null,"measureStartValue":0.0,"measureGoalValue":1.0,"measureUnit":"","measureUnitPlacement":"AFTER","measureCurrentValue":0.0,"orderIndex":0},{"result":"Get 1000 survey responses to annual satisfaction survey","type":"NUMERIC","endDate":null,"measureStartValue":0.0,"measureGoalValue":1.0,"measureUnit":"responses","measureUnitPlacement":"AFTER","measureCurrentValue":0.0,"orderIndex":1},{"result":"Conduct 50 phone interviews with top customers","type":"NUMERIC","endDate":null,"measureStartValue":0.0,"measureGoalValue":1.0,"measureUnit":"interviews","measureUnitPlacement":"AFTER","measureCurrentValue":0.0,"orderIndex":2},{"result":"Conduct 15 phone interviews with recently churned customers","type":"NUMERIC","endDate":null,"measureStartValue":0.0,"measureGoalValue":1.0,"measureUnit":"interviews","measureUnitPlacement":"AFTER","measureCurrentValue":0.0,"orderIndex":3},{"result":"Present an action plan of 10 improvements for next quarter","type":"BINARY","endDate":null,"measureStartValue":0.0,"measureGoalValue":1.0,"measureUnit":"","measureUnitPlacement":"AFTER","measureCurrentValue":0.0,"orderIndex":4}],"developmentNeeds":null,"complete":null,"archived":null,"distribute":"COMPANY","tasks":[],"draftId":null}',
//         'progress': 62,
//         'createdAt': '2024-08-21T10:33:29.000Z',
//         'updatedAt': '2024-08-21T10:33:29.000Z'
//       },
//       {
//         'id': 457,
//         'parentTaskId': 12,
//         'eventType': LongRunningTaskEventType.EVENT_SUCCESS,
//         'message': 'Goal created for Rozanna Ghio (28 of 37)',
//         'metadata': '{"title":"Long running task 9","type":"PERSONAL_OPERATIONAL","departmentId":null,"officeLocationId":null,"alignmentId":null,"endDate":"2024-09-03T23:00:00.000Z","visibility":"PRIVATE","priority":"NOT_SET","tagIds":[],"ownerIds":[29],"keyResults":[{"result":"Exceed Net Promoter Score (NPS) of over 8.0","type":"BINARY","endDate":null,"measureStartValue":0.0,"measureGoalValue":1.0,"measureUnit":"","measureUnitPlacement":"AFTER","measureCurrentValue":0.0,"orderIndex":0},{"result":"Get 1000 survey responses to annual satisfaction survey","type":"NUMERIC","endDate":null,"measureStartValue":0.0,"measureGoalValue":1.0,"measureUnit":"responses","measureUnitPlacement":"AFTER","measureCurrentValue":0.0,"orderIndex":1},{"result":"Conduct 50 phone interviews with top customers","type":"NUMERIC","endDate":null,"measureStartValue":0.0,"measureGoalValue":1.0,"measureUnit":"interviews","measureUnitPlacement":"AFTER","measureCurrentValue":0.0,"orderIndex":2},{"result":"Conduct 15 phone interviews with recently churned customers","type":"NUMERIC","endDate":null,"measureStartValue":0.0,"measureGoalValue":1.0,"measureUnit":"interviews","measureUnitPlacement":"AFTER","measureCurrentValue":0.0,"orderIndex":3},{"result":"Present an action plan of 10 improvements for next quarter","type":"BINARY","endDate":null,"measureStartValue":0.0,"measureGoalValue":1.0,"measureUnit":"","measureUnitPlacement":"AFTER","measureCurrentValue":0.0,"orderIndex":4}],"developmentNeeds":null,"complete":null,"archived":null,"distribute":"COMPANY","tasks":[],"draftId":null}',
//         'progress': 75,
//         'createdAt': '2024-08-21T10:33:30.000Z',
//         'updatedAt': '2024-08-21T10:33:30.000Z'
//       },
//       {
//         'id': 435,
//         'parentTaskId': 12,
//         'eventType': LongRunningTaskEventType.EVENT_SUCCESS,
//         'message': 'Goal created for Timothy Savage (6 of 37)',
//         'metadata': '{"title":"Long running task 9","type":"PERSONAL_OPERATIONAL","departmentId":null,"officeLocationId":null,"alignmentId":null,"endDate":"2024-09-03T23:00:00.000Z","visibility":"PRIVATE","priority":"NOT_SET","tagIds":[],"ownerIds":[7],"keyResults":[{"result":"Exceed Net Promoter Score (NPS) of over 8.0","type":"BINARY","endDate":null,"measureStartValue":0.0,"measureGoalValue":1.0,"measureUnit":"","measureUnitPlacement":"AFTER","measureCurrentValue":0.0,"orderIndex":0},{"result":"Get 1000 survey responses to annual satisfaction survey","type":"NUMERIC","endDate":null,"measureStartValue":0.0,"measureGoalValue":1.0,"measureUnit":"responses","measureUnitPlacement":"AFTER","measureCurrentValue":0.0,"orderIndex":1},{"result":"Conduct 50 phone interviews with top customers","type":"NUMERIC","endDate":null,"measureStartValue":0.0,"measureGoalValue":1.0,"measureUnit":"interviews","measureUnitPlacement":"AFTER","measureCurrentValue":0.0,"orderIndex":2},{"result":"Conduct 15 phone interviews with recently churned customers","type":"NUMERIC","endDate":null,"measureStartValue":0.0,"measureGoalValue":1.0,"measureUnit":"interviews","measureUnitPlacement":"AFTER","measureCurrentValue":0.0,"orderIndex":3},{"result":"Present an action plan of 10 improvements for next quarter","type":"BINARY","endDate":null,"measureStartValue":0.0,"measureGoalValue":1.0,"measureUnit":"","measureUnitPlacement":"AFTER","measureCurrentValue":0.0,"orderIndex":4}],"developmentNeeds":null,"complete":null,"archived":null,"distribute":"COMPANY","tasks":[],"draftId":null}',
//         'progress': 16,
//         'createdAt': '2024-08-21T10:33:25.000Z',
//         'updatedAt': '2024-08-21T10:33:25.000Z'
//       },
//       {
//         'id': 443,
//         'parentTaskId': 12,
//         'eventType': LongRunningTaskEventType.EVENT_SUCCESS,
//         'message': 'Goal created for Noak Pohlke (14 of 37)',
//         'metadata': '{"title":"Long running task 9","type":"PERSONAL_OPERATIONAL","departmentId":null,"officeLocationId":null,"alignmentId":null,"endDate":"2024-09-03T23:00:00.000Z","visibility":"PRIVATE","priority":"NOT_SET","tagIds":[],"ownerIds":[15],"keyResults":[{"result":"Exceed Net Promoter Score (NPS) of over 8.0","type":"BINARY","endDate":null,"measureStartValue":0.0,"measureGoalValue":1.0,"measureUnit":"","measureUnitPlacement":"AFTER","measureCurrentValue":0.0,"orderIndex":0},{"result":"Get 1000 survey responses to annual satisfaction survey","type":"NUMERIC","endDate":null,"measureStartValue":0.0,"measureGoalValue":1.0,"measureUnit":"responses","measureUnitPlacement":"AFTER","measureCurrentValue":0.0,"orderIndex":1},{"result":"Conduct 50 phone interviews with top customers","type":"NUMERIC","endDate":null,"measureStartValue":0.0,"measureGoalValue":1.0,"measureUnit":"interviews","measureUnitPlacement":"AFTER","measureCurrentValue":0.0,"orderIndex":2},{"result":"Conduct 15 phone interviews with recently churned customers","type":"NUMERIC","endDate":null,"measureStartValue":0.0,"measureGoalValue":1.0,"measureUnit":"interviews","measureUnitPlacement":"AFTER","measureCurrentValue":0.0,"orderIndex":3},{"result":"Present an action plan of 10 improvements for next quarter","type":"BINARY","endDate":null,"measureStartValue":0.0,"measureGoalValue":1.0,"measureUnit":"","measureUnitPlacement":"AFTER","measureCurrentValue":0.0,"orderIndex":4}],"developmentNeeds":null,"complete":null,"archived":null,"distribute":"COMPANY","tasks":[],"draftId":null}',
//         'progress': 37,
//         'createdAt': '2024-08-21T10:33:27.000Z',
//         'updatedAt': '2024-08-21T10:33:27.000Z'
//       },
//       {
//         'id': 445,
//         'parentTaskId': 12,
//         'eventType': LongRunningTaskEventType.EVENT_SUCCESS,
//         'message': 'Goal created for Torin Campany (16 of 37)',
//         'metadata': '{"title":"Long running task 9","type":"PERSONAL_OPERATIONAL","departmentId":null,"officeLocationId":null,"alignmentId":null,"endDate":"2024-09-03T23:00:00.000Z","visibility":"PRIVATE","priority":"NOT_SET","tagIds":[],"ownerIds":[17],"keyResults":[{"result":"Exceed Net Promoter Score (NPS) of over 8.0","type":"BINARY","endDate":null,"measureStartValue":0.0,"measureGoalValue":1.0,"measureUnit":"","measureUnitPlacement":"AFTER","measureCurrentValue":0.0,"orderIndex":0},{"result":"Get 1000 survey responses to annual satisfaction survey","type":"NUMERIC","endDate":null,"measureStartValue":0.0,"measureGoalValue":1.0,"measureUnit":"responses","measureUnitPlacement":"AFTER","measureCurrentValue":0.0,"orderIndex":1},{"result":"Conduct 50 phone interviews with top customers","type":"NUMERIC","endDate":null,"measureStartValue":0.0,"measureGoalValue":1.0,"measureUnit":"interviews","measureUnitPlacement":"AFTER","measureCurrentValue":0.0,"orderIndex":2},{"result":"Conduct 15 phone interviews with recently churned customers","type":"NUMERIC","endDate":null,"measureStartValue":0.0,"measureGoalValue":1.0,"measureUnit":"interviews","measureUnitPlacement":"AFTER","measureCurrentValue":0.0,"orderIndex":3},{"result":"Present an action plan of 10 improvements for next quarter","type":"BINARY","endDate":null,"measureStartValue":0.0,"measureGoalValue":1.0,"measureUnit":"","measureUnitPlacement":"AFTER","measureCurrentValue":0.0,"orderIndex":4}],"developmentNeeds":null,"complete":null,"archived":null,"distribute":"COMPANY","tasks":[],"draftId":null}',
//         'progress': 43,
//         'createdAt': '2024-08-21T10:33:27.000Z',
//         'updatedAt': '2024-08-21T10:33:27.000Z'
//       },
//       {
//         'id': 451,
//         'parentTaskId': 12,
//         'eventType': LongRunningTaskEventType.EVENT_SUCCESS,
//         'message': 'Goal created for Joye Croisdall (22 of 37)',
//         'metadata': '{"title":"Long running task 9","type":"PERSONAL_OPERATIONAL","departmentId":null,"officeLocationId":null,"alignmentId":null,"endDate":"2024-09-03T23:00:00.000Z","visibility":"PRIVATE","priority":"NOT_SET","tagIds":[],"ownerIds":[23],"keyResults":[{"result":"Exceed Net Promoter Score (NPS) of over 8.0","type":"BINARY","endDate":null,"measureStartValue":0.0,"measureGoalValue":1.0,"measureUnit":"","measureUnitPlacement":"AFTER","measureCurrentValue":0.0,"orderIndex":0},{"result":"Get 1000 survey responses to annual satisfaction survey","type":"NUMERIC","endDate":null,"measureStartValue":0.0,"measureGoalValue":1.0,"measureUnit":"responses","measureUnitPlacement":"AFTER","measureCurrentValue":0.0,"orderIndex":1},{"result":"Conduct 50 phone interviews with top customers","type":"NUMERIC","endDate":null,"measureStartValue":0.0,"measureGoalValue":1.0,"measureUnit":"interviews","measureUnitPlacement":"AFTER","measureCurrentValue":0.0,"orderIndex":2},{"result":"Conduct 15 phone interviews with recently churned customers","type":"NUMERIC","endDate":null,"measureStartValue":0.0,"measureGoalValue":1.0,"measureUnit":"interviews","measureUnitPlacement":"AFTER","measureCurrentValue":0.0,"orderIndex":3},{"result":"Present an action plan of 10 improvements for next quarter","type":"BINARY","endDate":null,"measureStartValue":0.0,"measureGoalValue":1.0,"measureUnit":"","measureUnitPlacement":"AFTER","measureCurrentValue":0.0,"orderIndex":4}],"developmentNeeds":null,"complete":null,"archived":null,"distribute":"COMPANY","tasks":[],"draftId":null}',
//         'progress': 59,
//         'createdAt': '2024-08-21T10:33:29.000Z',
//         'updatedAt': '2024-08-21T10:33:29.000Z'
//       },
//       {
//         'id': 447,
//         'parentTaskId': 12,
//         'eventType': LongRunningTaskEventType.EVENT_SUCCESS,
//         'message': 'Goal created for Faye Grafhom (18 of 37)',
//         'metadata': '{"title":"Long running task 9","type":"PERSONAL_OPERATIONAL","departmentId":null,"officeLocationId":null,"alignmentId":null,"endDate":"2024-09-03T23:00:00.000Z","visibility":"PRIVATE","priority":"NOT_SET","tagIds":[],"ownerIds":[19],"keyResults":[{"result":"Exceed Net Promoter Score (NPS) of over 8.0","type":"BINARY","endDate":null,"measureStartValue":0.0,"measureGoalValue":1.0,"measureUnit":"","measureUnitPlacement":"AFTER","measureCurrentValue":0.0,"orderIndex":0},{"result":"Get 1000 survey responses to annual satisfaction survey","type":"NUMERIC","endDate":null,"measureStartValue":0.0,"measureGoalValue":1.0,"measureUnit":"responses","measureUnitPlacement":"AFTER","measureCurrentValue":0.0,"orderIndex":1},{"result":"Conduct 50 phone interviews with top customers","type":"NUMERIC","endDate":null,"measureStartValue":0.0,"measureGoalValue":1.0,"measureUnit":"interviews","measureUnitPlacement":"AFTER","measureCurrentValue":0.0,"orderIndex":2},{"result":"Conduct 15 phone interviews with recently churned customers","type":"NUMERIC","endDate":null,"measureStartValue":0.0,"measureGoalValue":1.0,"measureUnit":"interviews","measureUnitPlacement":"AFTER","measureCurrentValue":0.0,"orderIndex":3},{"result":"Present an action plan of 10 improvements for next quarter","type":"BINARY","endDate":null,"measureStartValue":0.0,"measureGoalValue":1.0,"measureUnit":"","measureUnitPlacement":"AFTER","measureCurrentValue":0.0,"orderIndex":4}],"developmentNeeds":null,"complete":null,"archived":null,"distribute":"COMPANY","tasks":[],"draftId":null}',
//         'progress': 48,
//         'createdAt': '2024-08-21T10:33:28.000Z',
//         'updatedAt': '2024-08-21T10:33:28.000Z'
//       },
//       {
//         'id': 453,
//         'parentTaskId': 12,
//         'eventType': LongRunningTaskEventType.EVENT_SUCCESS,
//         'message': 'Goal created for Bondy Dmych (24 of 37)',
//         'metadata': '{"title":"Long running task 9","type":"PERSONAL_OPERATIONAL","departmentId":null,"officeLocationId":null,"alignmentId":null,"endDate":"2024-09-03T23:00:00.000Z","visibility":"PRIVATE","priority":"NOT_SET","tagIds":[],"ownerIds":[25],"keyResults":[{"result":"Exceed Net Promoter Score (NPS) of over 8.0","type":"BINARY","endDate":null,"measureStartValue":0.0,"measureGoalValue":1.0,"measureUnit":"","measureUnitPlacement":"AFTER","measureCurrentValue":0.0,"orderIndex":0},{"result":"Get 1000 survey responses to annual satisfaction survey","type":"NUMERIC","endDate":null,"measureStartValue":0.0,"measureGoalValue":1.0,"measureUnit":"responses","measureUnitPlacement":"AFTER","measureCurrentValue":0.0,"orderIndex":1},{"result":"Conduct 50 phone interviews with top customers","type":"NUMERIC","endDate":null,"measureStartValue":0.0,"measureGoalValue":1.0,"measureUnit":"interviews","measureUnitPlacement":"AFTER","measureCurrentValue":0.0,"orderIndex":2},{"result":"Conduct 15 phone interviews with recently churned customers","type":"NUMERIC","endDate":null,"measureStartValue":0.0,"measureGoalValue":1.0,"measureUnit":"interviews","measureUnitPlacement":"AFTER","measureCurrentValue":0.0,"orderIndex":3},{"result":"Present an action plan of 10 improvements for next quarter","type":"BINARY","endDate":null,"measureStartValue":0.0,"measureGoalValue":1.0,"measureUnit":"","measureUnitPlacement":"AFTER","measureCurrentValue":0.0,"orderIndex":4}],"developmentNeeds":null,"complete":null,"archived":null,"distribute":"COMPANY","tasks":[],"draftId":null}',
//         'progress': 64,
//         'createdAt': '2024-08-21T10:33:29.000Z',
//         'updatedAt': '2024-08-21T10:33:29.000Z'
//       },
//       {
//         'id': 450,
//         'parentTaskId': 12,
//         'eventType': LongRunningTaskEventType.EVENT_SUCCESS,
//         'message': 'Goal created for Jammie Stempe (21 of 37)',
//         'metadata': '{"title":"Long running task 9","type":"PERSONAL_OPERATIONAL","departmentId":null,"officeLocationId":null,"alignmentId":null,"endDate":"2024-09-03T23:00:00.000Z","visibility":"PRIVATE","priority":"NOT_SET","tagIds":[],"ownerIds":[22],"keyResults":[{"result":"Exceed Net Promoter Score (NPS) of over 8.0","type":"BINARY","endDate":null,"measureStartValue":0.0,"measureGoalValue":1.0,"measureUnit":"","measureUnitPlacement":"AFTER","measureCurrentValue":0.0,"orderIndex":0},{"result":"Get 1000 survey responses to annual satisfaction survey","type":"NUMERIC","endDate":null,"measureStartValue":0.0,"measureGoalValue":1.0,"measureUnit":"responses","measureUnitPlacement":"AFTER","measureCurrentValue":0.0,"orderIndex":1},{"result":"Conduct 50 phone interviews with top customers","type":"NUMERIC","endDate":null,"measureStartValue":0.0,"measureGoalValue":1.0,"measureUnit":"interviews","measureUnitPlacement":"AFTER","measureCurrentValue":0.0,"orderIndex":2},{"result":"Conduct 15 phone interviews with recently churned customers","type":"NUMERIC","endDate":null,"measureStartValue":0.0,"measureGoalValue":1.0,"measureUnit":"interviews","measureUnitPlacement":"AFTER","measureCurrentValue":0.0,"orderIndex":3},{"result":"Present an action plan of 10 improvements for next quarter","type":"BINARY","endDate":null,"measureStartValue":0.0,"measureGoalValue":1.0,"measureUnit":"","measureUnitPlacement":"AFTER","measureCurrentValue":0.0,"orderIndex":4}],"developmentNeeds":null,"complete":null,"archived":null,"distribute":"COMPANY","tasks":[],"draftId":null}',
//         'progress': 56,
//         'createdAt': '2024-08-21T10:33:28.000Z',
//         'updatedAt': '2024-08-21T10:33:28.000Z'
//       },
//       {
//         'id': 455,
//         'parentTaskId': 12,
//         'eventType': LongRunningTaskEventType.EVENT_SUCCESS,
//         'message': 'Goal created for Care Hasel (26 of 37)',
//         'metadata': '{"title":"Long running task 9","type":"PERSONAL_OPERATIONAL","departmentId":null,"officeLocationId":null,"alignmentId":null,"endDate":"2024-09-03T23:00:00.000Z","visibility":"PRIVATE","priority":"NOT_SET","tagIds":[],"ownerIds":[27],"keyResults":[{"result":"Exceed Net Promoter Score (NPS) of over 8.0","type":"BINARY","endDate":null,"measureStartValue":0.0,"measureGoalValue":1.0,"measureUnit":"","measureUnitPlacement":"AFTER","measureCurrentValue":0.0,"orderIndex":0},{"result":"Get 1000 survey responses to annual satisfaction survey","type":"NUMERIC","endDate":null,"measureStartValue":0.0,"measureGoalValue":1.0,"measureUnit":"responses","measureUnitPlacement":"AFTER","measureCurrentValue":0.0,"orderIndex":1},{"result":"Conduct 50 phone interviews with top customers","type":"NUMERIC","endDate":null,"measureStartValue":0.0,"measureGoalValue":1.0,"measureUnit":"interviews","measureUnitPlacement":"AFTER","measureCurrentValue":0.0,"orderIndex":2},{"result":"Conduct 15 phone interviews with recently churned customers","type":"NUMERIC","endDate":null,"measureStartValue":0.0,"measureGoalValue":1.0,"measureUnit":"interviews","measureUnitPlacement":"AFTER","measureCurrentValue":0.0,"orderIndex":3},{"result":"Present an action plan of 10 improvements for next quarter","type":"BINARY","endDate":null,"measureStartValue":0.0,"measureGoalValue":1.0,"measureUnit":"","measureUnitPlacement":"AFTER","measureCurrentValue":0.0,"orderIndex":4}],"developmentNeeds":null,"complete":null,"archived":null,"distribute":"COMPANY","tasks":[],"draftId":null}',
//         'progress': 70,
//         'createdAt': '2024-08-21T10:33:30.000Z',
//         'updatedAt': '2024-08-21T10:33:30.000Z'
//       },
//       {
//         'id': 430,
//         'parentTaskId': 12,
//         'eventType': LongRunningTaskEventType.EVENT_SUCCESS,
//         'message': 'Goal created for David Kenny (1 of 37)',
//         'metadata': '{"title":"Long running task 9","type":"PERSONAL_OPERATIONAL","departmentId":null,"officeLocationId":null,"alignmentId":null,"endDate":"2024-09-03T23:00:00.000Z","visibility":"PRIVATE","priority":"NOT_SET","tagIds":[],"ownerIds":[1],"keyResults":[{"result":"Exceed Net Promoter Score (NPS) of over 8.0","type":"BINARY","endDate":null,"measureStartValue":0.0,"measureGoalValue":1.0,"measureUnit":"","measureUnitPlacement":"AFTER","measureCurrentValue":0.0,"orderIndex":0},{"result":"Get 1000 survey responses to annual satisfaction survey","type":"NUMERIC","endDate":null,"measureStartValue":0.0,"measureGoalValue":1.0,"measureUnit":"responses","measureUnitPlacement":"AFTER","measureCurrentValue":0.0,"orderIndex":1},{"result":"Conduct 50 phone interviews with top customers","type":"NUMERIC","endDate":null,"measureStartValue":0.0,"measureGoalValue":1.0,"measureUnit":"interviews","measureUnitPlacement":"AFTER","measureCurrentValue":0.0,"orderIndex":2},{"result":"Conduct 15 phone interviews with recently churned customers","type":"NUMERIC","endDate":null,"measureStartValue":0.0,"measureGoalValue":1.0,"measureUnit":"interviews","measureUnitPlacement":"AFTER","measureCurrentValue":0.0,"orderIndex":3},{"result":"Present an action plan of 10 improvements for next quarter","type":"BINARY","endDate":null,"measureStartValue":0.0,"measureGoalValue":1.0,"measureUnit":"","measureUnitPlacement":"AFTER","measureCurrentValue":0.0,"orderIndex":4}],"developmentNeeds":null,"complete":null,"archived":null,"distribute":"COMPANY","tasks":[],"draftId":null}',
//         'progress': 2,
//         'createdAt': '2024-08-21T10:33:24.000Z',
//         'updatedAt': '2024-08-21T10:33:24.000Z'
//       },
//       {
//         'id': 431,
//         'parentTaskId': 12,
//         'eventType': LongRunningTaskEventType.EVENT_SUCCESS,
//         'message': 'Goal created for Noel Dykes (2 of 37)',
//         'metadata': '{"title":"Long running task 9","type":"PERSONAL_OPERATIONAL","departmentId":null,"officeLocationId":null,"alignmentId":null,"endDate":"2024-09-03T23:00:00.000Z","visibility":"PRIVATE","priority":"NOT_SET","tagIds":[],"ownerIds":[2],"keyResults":[{"result":"Exceed Net Promoter Score (NPS) of over 8.0","type":"BINARY","endDate":null,"measureStartValue":0.0,"measureGoalValue":1.0,"measureUnit":"","measureUnitPlacement":"AFTER","measureCurrentValue":0.0,"orderIndex":0},{"result":"Get 1000 survey responses to annual satisfaction survey","type":"NUMERIC","endDate":null,"measureStartValue":0.0,"measureGoalValue":1.0,"measureUnit":"responses","measureUnitPlacement":"AFTER","measureCurrentValue":0.0,"orderIndex":1},{"result":"Conduct 50 phone interviews with top customers","type":"NUMERIC","endDate":null,"measureStartValue":0.0,"measureGoalValue":1.0,"measureUnit":"interviews","measureUnitPlacement":"AFTER","measureCurrentValue":0.0,"orderIndex":2},{"result":"Conduct 15 phone interviews with recently churned customers","type":"NUMERIC","endDate":null,"measureStartValue":0.0,"measureGoalValue":1.0,"measureUnit":"interviews","measureUnitPlacement":"AFTER","measureCurrentValue":0.0,"orderIndex":3},{"result":"Present an action plan of 10 improvements for next quarter","type":"BINARY","endDate":null,"measureStartValue":0.0,"measureGoalValue":1.0,"measureUnit":"","measureUnitPlacement":"AFTER","measureCurrentValue":0.0,"orderIndex":4}],"developmentNeeds":null,"complete":null,"archived":null,"distribute":"COMPANY","tasks":[],"draftId":null}',
//         'progress': 5,
//         'createdAt': '2024-08-21T10:33:24.000Z',
//         'updatedAt': '2024-08-21T10:33:24.000Z'
//       },
//       {
//         'id': 433,
//         'parentTaskId': 12,
//         'eventType': LongRunningTaskEventType.EVENT_SUCCESS,
//         'message': 'Goal created for Barry Kenny (4 of 37)',
//         'metadata': '{"title":"Long running task 9","type":"PERSONAL_OPERATIONAL","departmentId":null,"officeLocationId":null,"alignmentId":null,"endDate":"2024-09-03T23:00:00.000Z","visibility":"PRIVATE","priority":"NOT_SET","tagIds":[],"ownerIds":[5],"keyResults":[{"result":"Exceed Net Promoter Score (NPS) of over 8.0","type":"BINARY","endDate":null,"measureStartValue":0.0,"measureGoalValue":1.0,"measureUnit":"","measureUnitPlacement":"AFTER","measureCurrentValue":0.0,"orderIndex":0},{"result":"Get 1000 survey responses to annual satisfaction survey","type":"NUMERIC","endDate":null,"measureStartValue":0.0,"measureGoalValue":1.0,"measureUnit":"responses","measureUnitPlacement":"AFTER","measureCurrentValue":0.0,"orderIndex":1},{"result":"Conduct 50 phone interviews with top customers","type":"NUMERIC","endDate":null,"measureStartValue":0.0,"measureGoalValue":1.0,"measureUnit":"interviews","measureUnitPlacement":"AFTER","measureCurrentValue":0.0,"orderIndex":2},{"result":"Conduct 15 phone interviews with recently churned customers","type":"NUMERIC","endDate":null,"measureStartValue":0.0,"measureGoalValue":1.0,"measureUnit":"interviews","measureUnitPlacement":"AFTER","measureCurrentValue":0.0,"orderIndex":3},{"result":"Present an action plan of 10 improvements for next quarter","type":"BINARY","endDate":null,"measureStartValue":0.0,"measureGoalValue":1.0,"measureUnit":"","measureUnitPlacement":"AFTER","measureCurrentValue":0.0,"orderIndex":4}],"developmentNeeds":null,"complete":null,"archived":null,"distribute":"COMPANY","tasks":[],"draftId":null}',
//         'progress': 10,
//         'createdAt': '2024-08-21T10:33:25.000Z',
//         'updatedAt': '2024-08-21T10:33:25.000Z'
//       },
//       {
//         'id': 436,
//         'parentTaskId': 12,
//         'eventType': LongRunningTaskEventType.EVENT_SUCCESS,
//         'message': 'Goal created for Markus Karlsson (7 of 37)',
//         'metadata': '{"title":"Long running task 9","type":"PERSONAL_OPERATIONAL","departmentId":null,"officeLocationId":null,"alignmentId":null,"endDate":"2024-09-03T23:00:00.000Z","visibility":"PRIVATE","priority":"NOT_SET","tagIds":[],"ownerIds":[8],"keyResults":[{"result":"Exceed Net Promoter Score (NPS) of over 8.0","type":"BINARY","endDate":null,"measureStartValue":0.0,"measureGoalValue":1.0,"measureUnit":"","measureUnitPlacement":"AFTER","measureCurrentValue":0.0,"orderIndex":0},{"result":"Get 1000 survey responses to annual satisfaction survey","type":"NUMERIC","endDate":null,"measureStartValue":0.0,"measureGoalValue":1.0,"measureUnit":"responses","measureUnitPlacement":"AFTER","measureCurrentValue":0.0,"orderIndex":1},{"result":"Conduct 50 phone interviews with top customers","type":"NUMERIC","endDate":null,"measureStartValue":0.0,"measureGoalValue":1.0,"measureUnit":"interviews","measureUnitPlacement":"AFTER","measureCurrentValue":0.0,"orderIndex":2},{"result":"Conduct 15 phone interviews with recently churned customers","type":"NUMERIC","endDate":null,"measureStartValue":0.0,"measureGoalValue":1.0,"measureUnit":"interviews","measureUnitPlacement":"AFTER","measureCurrentValue":0.0,"orderIndex":3},{"result":"Present an action plan of 10 improvements for next quarter","type":"BINARY","endDate":null,"measureStartValue":0.0,"measureGoalValue":1.0,"measureUnit":"","measureUnitPlacement":"AFTER","measureCurrentValue":0.0,"orderIndex":4}],"developmentNeeds":null,"complete":null,"archived":null,"distribute":"COMPANY","tasks":[],"draftId":null}',
//         'progress': 18,
//         'createdAt': '2024-08-21T10:33:25.000Z',
//         'updatedAt': '2024-08-21T10:33:25.000Z'
//       },
//       {
//         'id': 441,
//         'parentTaskId': 12,
//         'eventType': LongRunningTaskEventType.EVENT_SUCCESS,
//         'message': 'Goal created for Louisa Prettyjohns (12 of 37)',
//         'metadata': '{"title":"Long running task 9","type":"PERSONAL_OPERATIONAL","departmentId":null,"officeLocationId":null,"alignmentId":null,"endDate":"2024-09-03T23:00:00.000Z","visibility":"PRIVATE","priority":"NOT_SET","tagIds":[],"ownerIds":[13],"keyResults":[{"result":"Exceed Net Promoter Score (NPS) of over 8.0","type":"BINARY","endDate":null,"measureStartValue":0.0,"measureGoalValue":1.0,"measureUnit":"","measureUnitPlacement":"AFTER","measureCurrentValue":0.0,"orderIndex":0},{"result":"Get 1000 survey responses to annual satisfaction survey","type":"NUMERIC","endDate":null,"measureStartValue":0.0,"measureGoalValue":1.0,"measureUnit":"responses","measureUnitPlacement":"AFTER","measureCurrentValue":0.0,"orderIndex":1},{"result":"Conduct 50 phone interviews with top customers","type":"NUMERIC","endDate":null,"measureStartValue":0.0,"measureGoalValue":1.0,"measureUnit":"interviews","measureUnitPlacement":"AFTER","measureCurrentValue":0.0,"orderIndex":2},{"result":"Conduct 15 phone interviews with recently churned customers","type":"NUMERIC","endDate":null,"measureStartValue":0.0,"measureGoalValue":1.0,"measureUnit":"interviews","measureUnitPlacement":"AFTER","measureCurrentValue":0.0,"orderIndex":3},{"result":"Present an action plan of 10 improvements for next quarter","type":"BINARY","endDate":null,"measureStartValue":0.0,"measureGoalValue":1.0,"measureUnit":"","measureUnitPlacement":"AFTER","measureCurrentValue":0.0,"orderIndex":4}],"developmentNeeds":null,"complete":null,"archived":null,"distribute":"COMPANY","tasks":[],"draftId":null}',
//         'progress': 32,
//         'createdAt': '2024-08-21T10:33:26.000Z',
//         'updatedAt': '2024-08-21T10:33:26.000Z'
//       },
//       {
//         'id': 459,
//         'parentTaskId': 12,
//         'eventType': LongRunningTaskEventType.EVENT_SUCCESS,
//         'message': 'Goal created for Cami Gorton (30 of 37)',
//         'metadata': '{"title":"Long running task 9","type":"PERSONAL_OPERATIONAL","departmentId":null,"officeLocationId":null,"alignmentId":null,"endDate":"2024-09-03T23:00:00.000Z","visibility":"PRIVATE","priority":"NOT_SET","tagIds":[],"ownerIds":[31],"keyResults":[{"result":"Exceed Net Promoter Score (NPS) of over 8.0","type":"BINARY","endDate":null,"measureStartValue":0.0,"measureGoalValue":1.0,"measureUnit":"","measureUnitPlacement":"AFTER","measureCurrentValue":0.0,"orderIndex":0},{"result":"Get 1000 survey responses to annual satisfaction survey","type":"NUMERIC","endDate":null,"measureStartValue":0.0,"measureGoalValue":1.0,"measureUnit":"responses","measureUnitPlacement":"AFTER","measureCurrentValue":0.0,"orderIndex":1},{"result":"Conduct 50 phone interviews with top customers","type":"NUMERIC","endDate":null,"measureStartValue":0.0,"measureGoalValue":1.0,"measureUnit":"interviews","measureUnitPlacement":"AFTER","measureCurrentValue":0.0,"orderIndex":2},{"result":"Conduct 15 phone interviews with recently churned customers","type":"NUMERIC","endDate":null,"measureStartValue":0.0,"measureGoalValue":1.0,"measureUnit":"interviews","measureUnitPlacement":"AFTER","measureCurrentValue":0.0,"orderIndex":3},{"result":"Present an action plan of 10 improvements for next quarter","type":"BINARY","endDate":null,"measureStartValue":0.0,"measureGoalValue":1.0,"measureUnit":"","measureUnitPlacement":"AFTER","measureCurrentValue":0.0,"orderIndex":4}],"developmentNeeds":null,"complete":null,"archived":null,"distribute":"COMPANY","tasks":[],"draftId":null}',
//         'progress': 81,
//         'createdAt': '2024-08-21T10:33:30.000Z',
//         'updatedAt': '2024-08-21T10:33:30.000Z'
//       },
//       {
//         'id': 461,
//         'parentTaskId': 12,
//         'eventType': LongRunningTaskEventType.EVENT_SUCCESS,
//         'message': 'Goal created for Celinda Marzellano (32 of 37)',
//         'metadata': '{"title":"Long running task 9","type":"PERSONAL_OPERATIONAL","departmentId":null,"officeLocationId":null,"alignmentId":null,"endDate":"2024-09-03T23:00:00.000Z","visibility":"PRIVATE","priority":"NOT_SET","tagIds":[],"ownerIds":[33],"keyResults":[{"result":"Exceed Net Promoter Score (NPS) of over 8.0","type":"BINARY","endDate":null,"measureStartValue":0.0,"measureGoalValue":1.0,"measureUnit":"","measureUnitPlacement":"AFTER","measureCurrentValue":0.0,"orderIndex":0},{"result":"Get 1000 survey responses to annual satisfaction survey","type":"NUMERIC","endDate":null,"measureStartValue":0.0,"measureGoalValue":1.0,"measureUnit":"responses","measureUnitPlacement":"AFTER","measureCurrentValue":0.0,"orderIndex":1},{"result":"Conduct 50 phone interviews with top customers","type":"NUMERIC","endDate":null,"measureStartValue":0.0,"measureGoalValue":1.0,"measureUnit":"interviews","measureUnitPlacement":"AFTER","measureCurrentValue":0.0,"orderIndex":2},{"result":"Conduct 15 phone interviews with recently churned customers","type":"NUMERIC","endDate":null,"measureStartValue":0.0,"measureGoalValue":1.0,"measureUnit":"interviews","measureUnitPlacement":"AFTER","measureCurrentValue":0.0,"orderIndex":3},{"result":"Present an action plan of 10 improvements for next quarter","type":"BINARY","endDate":null,"measureStartValue":0.0,"measureGoalValue":1.0,"measureUnit":"","measureUnitPlacement":"AFTER","measureCurrentValue":0.0,"orderIndex":4}],"developmentNeeds":null,"complete":null,"archived":null,"distribute":"COMPANY","tasks":[],"draftId":null}',
//         'progress': 86,
//         'createdAt': '2024-08-21T10:33:31.000Z',
//         'updatedAt': '2024-08-21T10:33:31.000Z'
//       },
//       {
//         'id': 458,
//         'parentTaskId': 12,
//         'eventType': LongRunningTaskEventType.EVENT_SUCCESS,
//         'message': 'Goal created for Lyndsie Britney (29 of 37)',
//         'metadata': '{"title":"Long running task 9","type":"PERSONAL_OPERATIONAL","departmentId":null,"officeLocationId":null,"alignmentId":null,"endDate":"2024-09-03T23:00:00.000Z","visibility":"PRIVATE","priority":"NOT_SET","tagIds":[],"ownerIds":[30],"keyResults":[{"result":"Exceed Net Promoter Score (NPS) of over 8.0","type":"BINARY","endDate":null,"measureStartValue":0.0,"measureGoalValue":1.0,"measureUnit":"","measureUnitPlacement":"AFTER","measureCurrentValue":0.0,"orderIndex":0},{"result":"Get 1000 survey responses to annual satisfaction survey","type":"NUMERIC","endDate":null,"measureStartValue":0.0,"measureGoalValue":1.0,"measureUnit":"responses","measureUnitPlacement":"AFTER","measureCurrentValue":0.0,"orderIndex":1},{"result":"Conduct 50 phone interviews with top customers","type":"NUMERIC","endDate":null,"measureStartValue":0.0,"measureGoalValue":1.0,"measureUnit":"interviews","measureUnitPlacement":"AFTER","measureCurrentValue":0.0,"orderIndex":2},{"result":"Conduct 15 phone interviews with recently churned customers","type":"NUMERIC","endDate":null,"measureStartValue":0.0,"measureGoalValue":1.0,"measureUnit":"interviews","measureUnitPlacement":"AFTER","measureCurrentValue":0.0,"orderIndex":3},{"result":"Present an action plan of 10 improvements for next quarter","type":"BINARY","endDate":null,"measureStartValue":0.0,"measureGoalValue":1.0,"measureUnit":"","measureUnitPlacement":"AFTER","measureCurrentValue":0.0,"orderIndex":4}],"developmentNeeds":null,"complete":null,"archived":null,"distribute":"COMPANY","tasks":[],"draftId":null}',
//         'progress': 78,
//         'createdAt': '2024-08-21T10:33:30.000Z',
//         'updatedAt': '2024-08-21T10:33:30.000Z'
//       },
//       {
//         'id': 432,
//         'parentTaskId': 12,
//         'eventType': LongRunningTaskEventType.EVENT_SUCCESS,
//         'message': 'Goal created for Martin McLaughlin (3 of 37)',
//         'metadata': '{"title":"Long running task 9","type":"PERSONAL_OPERATIONAL","departmentId":null,"officeLocationId":null,"alignmentId":null,"endDate":"2024-09-03T23:00:00.000Z","visibility":"PRIVATE","priority":"NOT_SET","tagIds":[],"ownerIds":[4],"keyResults":[{"result":"Exceed Net Promoter Score (NPS) of over 8.0","type":"BINARY","endDate":null,"measureStartValue":0.0,"measureGoalValue":1.0,"measureUnit":"","measureUnitPlacement":"AFTER","measureCurrentValue":0.0,"orderIndex":0},{"result":"Get 1000 survey responses to annual satisfaction survey","type":"NUMERIC","endDate":null,"measureStartValue":0.0,"measureGoalValue":1.0,"measureUnit":"responses","measureUnitPlacement":"AFTER","measureCurrentValue":0.0,"orderIndex":1},{"result":"Conduct 50 phone interviews with top customers","type":"NUMERIC","endDate":null,"measureStartValue":0.0,"measureGoalValue":1.0,"measureUnit":"interviews","measureUnitPlacement":"AFTER","measureCurrentValue":0.0,"orderIndex":2},{"result":"Conduct 15 phone interviews with recently churned customers","type":"NUMERIC","endDate":null,"measureStartValue":0.0,"measureGoalValue":1.0,"measureUnit":"interviews","measureUnitPlacement":"AFTER","measureCurrentValue":0.0,"orderIndex":3},{"result":"Present an action plan of 10 improvements for next quarter","type":"BINARY","endDate":null,"measureStartValue":0.0,"measureGoalValue":1.0,"measureUnit":"","measureUnitPlacement":"AFTER","measureCurrentValue":0.0,"orderIndex":4}],"developmentNeeds":null,"complete":null,"archived":null,"distribute":"COMPANY","tasks":[],"draftId":null}',
//         'progress': 8,
//         'createdAt': '2024-08-21T10:33:24.000Z',
//         'updatedAt': '2024-08-21T10:33:24.000Z'
//       },
//       {
//         'id': 437,
//         'parentTaskId': 12,
//         'eventType': LongRunningTaskEventType.EVENT_SUCCESS,
//         'message': 'Goal created for Marcin Blaszyk (8 of 37)',
//         'metadata': '{"title":"Long running task 9","type":"PERSONAL_OPERATIONAL","departmentId":null,"officeLocationId":null,"alignmentId":null,"endDate":"2024-09-03T23:00:00.000Z","visibility":"PRIVATE","priority":"NOT_SET","tagIds":[],"ownerIds":[9],"keyResults":[{"result":"Exceed Net Promoter Score (NPS) of over 8.0","type":"BINARY","endDate":null,"measureStartValue":0.0,"measureGoalValue":1.0,"measureUnit":"","measureUnitPlacement":"AFTER","measureCurrentValue":0.0,"orderIndex":0},{"result":"Get 1000 survey responses to annual satisfaction survey","type":"NUMERIC","endDate":null,"measureStartValue":0.0,"measureGoalValue":1.0,"measureUnit":"responses","measureUnitPlacement":"AFTER","measureCurrentValue":0.0,"orderIndex":1},{"result":"Conduct 50 phone interviews with top customers","type":"NUMERIC","endDate":null,"measureStartValue":0.0,"measureGoalValue":1.0,"measureUnit":"interviews","measureUnitPlacement":"AFTER","measureCurrentValue":0.0,"orderIndex":2},{"result":"Conduct 15 phone interviews with recently churned customers","type":"NUMERIC","endDate":null,"measureStartValue":0.0,"measureGoalValue":1.0,"measureUnit":"interviews","measureUnitPlacement":"AFTER","measureCurrentValue":0.0,"orderIndex":3},{"result":"Present an action plan of 10 improvements for next quarter","type":"BINARY","endDate":null,"measureStartValue":0.0,"measureGoalValue":1.0,"measureUnit":"","measureUnitPlacement":"AFTER","measureCurrentValue":0.0,"orderIndex":4}],"developmentNeeds":null,"complete":null,"archived":null,"distribute":"COMPANY","tasks":[],"draftId":null}',
//         'progress': 21,
//         'createdAt': '2024-08-21T10:33:26.000Z',
//         'updatedAt': '2024-08-21T10:33:26.000Z'
//       },
//       {
//         'id': 454,
//         'parentTaskId': 12,
//         'eventType': LongRunningTaskEventType.EVENT_SUCCESS,
//         'message': 'Goal created for Reinaldos Pryor (25 of 37)',
//         'metadata': '{"title":"Long running task 9","type":"PERSONAL_OPERATIONAL","departmentId":null,"officeLocationId":null,"alignmentId":null,"endDate":"2024-09-03T23:00:00.000Z","visibility":"PRIVATE","priority":"NOT_SET","tagIds":[],"ownerIds":[26],"keyResults":[{"result":"Exceed Net Promoter Score (NPS) of over 8.0","type":"BINARY","endDate":null,"measureStartValue":0.0,"measureGoalValue":1.0,"measureUnit":"","measureUnitPlacement":"AFTER","measureCurrentValue":0.0,"orderIndex":0},{"result":"Get 1000 survey responses to annual satisfaction survey","type":"NUMERIC","endDate":null,"measureStartValue":0.0,"measureGoalValue":1.0,"measureUnit":"responses","measureUnitPlacement":"AFTER","measureCurrentValue":0.0,"orderIndex":1},{"result":"Conduct 50 phone interviews with top customers","type":"NUMERIC","endDate":null,"measureStartValue":0.0,"measureGoalValue":1.0,"measureUnit":"interviews","measureUnitPlacement":"AFTER","measureCurrentValue":0.0,"orderIndex":2},{"result":"Conduct 15 phone interviews with recently churned customers","type":"NUMERIC","endDate":null,"measureStartValue":0.0,"measureGoalValue":1.0,"measureUnit":"interviews","measureUnitPlacement":"AFTER","measureCurrentValue":0.0,"orderIndex":3},{"result":"Present an action plan of 10 improvements for next quarter","type":"BINARY","endDate":null,"measureStartValue":0.0,"measureGoalValue":1.0,"measureUnit":"","measureUnitPlacement":"AFTER","measureCurrentValue":0.0,"orderIndex":4}],"developmentNeeds":null,"complete":null,"archived":null,"distribute":"COMPANY","tasks":[],"draftId":null}',
//         'progress': 67,
//         'createdAt': '2024-08-21T10:33:29.000Z',
//         'updatedAt': '2024-08-21T10:33:29.000Z'
//       },
//       {
//         'id': 460,
//         'parentTaskId': 12,
//         'eventType': LongRunningTaskEventType.EVENT_SUCCESS,
//         'message': 'Goal created for Cilka Bromwich (31 of 37)',
//         'metadata': '{"title":"Long running task 9","type":"PERSONAL_OPERATIONAL","departmentId":null,"officeLocationId":null,"alignmentId":null,"endDate":"2024-09-03T23:00:00.000Z","visibility":"PRIVATE","priority":"NOT_SET","tagIds":[],"ownerIds":[32],"keyResults":[{"result":"Exceed Net Promoter Score (NPS) of over 8.0","type":"BINARY","endDate":null,"measureStartValue":0.0,"measureGoalValue":1.0,"measureUnit":"","measureUnitPlacement":"AFTER","measureCurrentValue":0.0,"orderIndex":0},{"result":"Get 1000 survey responses to annual satisfaction survey","type":"NUMERIC","endDate":null,"measureStartValue":0.0,"measureGoalValue":1.0,"measureUnit":"responses","measureUnitPlacement":"AFTER","measureCurrentValue":0.0,"orderIndex":1},{"result":"Conduct 50 phone interviews with top customers","type":"NUMERIC","endDate":null,"measureStartValue":0.0,"measureGoalValue":1.0,"measureUnit":"interviews","measureUnitPlacement":"AFTER","measureCurrentValue":0.0,"orderIndex":2},{"result":"Conduct 15 phone interviews with recently churned customers","type":"NUMERIC","endDate":null,"measureStartValue":0.0,"measureGoalValue":1.0,"measureUnit":"interviews","measureUnitPlacement":"AFTER","measureCurrentValue":0.0,"orderIndex":3},{"result":"Present an action plan of 10 improvements for next quarter","type":"BINARY","endDate":null,"measureStartValue":0.0,"measureGoalValue":1.0,"measureUnit":"","measureUnitPlacement":"AFTER","measureCurrentValue":0.0,"orderIndex":4}],"developmentNeeds":null,"complete":null,"archived":null,"distribute":"COMPANY","tasks":[],"draftId":null}',
//         'progress': 83,
//         'createdAt': '2024-08-21T10:33:31.000Z',
//         'updatedAt': '2024-08-21T10:33:31.000Z'
//       },
//       {
//         'id': 440,
//         'parentTaskId': 12,
//         'eventType': LongRunningTaskEventType.EVENT_SUCCESS,
//         'message': 'Goal created for Norma Ingerman (11 of 37)',
//         'metadata': '{"title":"Long running task 9","type":"PERSONAL_OPERATIONAL","departmentId":null,"officeLocationId":null,"alignmentId":null,"endDate":"2024-09-03T23:00:00.000Z","visibility":"PRIVATE","priority":"NOT_SET","tagIds":[],"ownerIds":[12],"keyResults":[{"result":"Exceed Net Promoter Score (NPS) of over 8.0","type":"BINARY","endDate":null,"measureStartValue":0.0,"measureGoalValue":1.0,"measureUnit":"","measureUnitPlacement":"AFTER","measureCurrentValue":0.0,"orderIndex":0},{"result":"Get 1000 survey responses to annual satisfaction survey","type":"NUMERIC","endDate":null,"measureStartValue":0.0,"measureGoalValue":1.0,"measureUnit":"responses","measureUnitPlacement":"AFTER","measureCurrentValue":0.0,"orderIndex":1},{"result":"Conduct 50 phone interviews with top customers","type":"NUMERIC","endDate":null,"measureStartValue":0.0,"measureGoalValue":1.0,"measureUnit":"interviews","measureUnitPlacement":"AFTER","measureCurrentValue":0.0,"orderIndex":2},{"result":"Conduct 15 phone interviews with recently churned customers","type":"NUMERIC","endDate":null,"measureStartValue":0.0,"measureGoalValue":1.0,"measureUnit":"interviews","measureUnitPlacement":"AFTER","measureCurrentValue":0.0,"orderIndex":3},{"result":"Present an action plan of 10 improvements for next quarter","type":"BINARY","endDate":null,"measureStartValue":0.0,"measureGoalValue":1.0,"measureUnit":"","measureUnitPlacement":"AFTER","measureCurrentValue":0.0,"orderIndex":4}],"developmentNeeds":null,"complete":null,"archived":null,"distribute":"COMPANY","tasks":[],"draftId":null}',
//         'progress': 29,
//         'createdAt': '2024-08-21T10:33:26.000Z',
//         'updatedAt': '2024-08-21T10:33:26.000Z'
//       },
//       {
//         'id': 463,
//         'parentTaskId': 12,
//         'eventType': LongRunningTaskEventType.EVENT_SUCCESS,
//         'message': 'Goal created for Fee Higounet (34 of 37)',
//         'metadata': '{"title":"Long running task 9","type":"PERSONAL_OPERATIONAL","departmentId":null,"officeLocationId":null,"alignmentId":null,"endDate":"2024-09-03T23:00:00.000Z","visibility":"PRIVATE","priority":"NOT_SET","tagIds":[],"ownerIds":[35],"keyResults":[{"result":"Exceed Net Promoter Score (NPS) of over 8.0","type":"BINARY","endDate":null,"measureStartValue":0.0,"measureGoalValue":1.0,"measureUnit":"","measureUnitPlacement":"AFTER","measureCurrentValue":0.0,"orderIndex":0},{"result":"Get 1000 survey responses to annual satisfaction survey","type":"NUMERIC","endDate":null,"measureStartValue":0.0,"measureGoalValue":1.0,"measureUnit":"responses","measureUnitPlacement":"AFTER","measureCurrentValue":0.0,"orderIndex":1},{"result":"Conduct 50 phone interviews with top customers","type":"NUMERIC","endDate":null,"measureStartValue":0.0,"measureGoalValue":1.0,"measureUnit":"interviews","measureUnitPlacement":"AFTER","measureCurrentValue":0.0,"orderIndex":2},{"result":"Conduct 15 phone interviews with recently churned customers","type":"NUMERIC","endDate":null,"measureStartValue":0.0,"measureGoalValue":1.0,"measureUnit":"interviews","measureUnitPlacement":"AFTER","measureCurrentValue":0.0,"orderIndex":3},{"result":"Present an action plan of 10 improvements for next quarter","type":"BINARY","endDate":null,"measureStartValue":0.0,"measureGoalValue":1.0,"measureUnit":"","measureUnitPlacement":"AFTER","measureCurrentValue":0.0,"orderIndex":4}],"developmentNeeds":null,"complete":null,"archived":null,"distribute":"COMPANY","tasks":[],"draftId":null}',
//         'progress': 91,
//         'createdAt': '2024-08-21T10:33:31.000Z',
//         'updatedAt': '2024-08-21T10:33:31.000Z'
//       },
//       {
//         'id': 439,
//         'parentTaskId': 12,
//         'eventType': LongRunningTaskEventType.EVENT_SUCCESS,
//         'message': 'Goal created for Killian Kelly (10 of 37)',
//         'metadata': '{"title":"Long running task 9","type":"PERSONAL_OPERATIONAL","departmentId":null,"officeLocationId":null,"alignmentId":null,"endDate":"2024-09-03T23:00:00.000Z","visibility":"PRIVATE","priority":"NOT_SET","tagIds":[],"ownerIds":[11],"keyResults":[{"result":"Exceed Net Promoter Score (NPS) of over 8.0","type":"BINARY","endDate":null,"measureStartValue":0.0,"measureGoalValue":1.0,"measureUnit":"","measureUnitPlacement":"AFTER","measureCurrentValue":0.0,"orderIndex":0},{"result":"Get 1000 survey responses to annual satisfaction survey","type":"NUMERIC","endDate":null,"measureStartValue":0.0,"measureGoalValue":1.0,"measureUnit":"responses","measureUnitPlacement":"AFTER","measureCurrentValue":0.0,"orderIndex":1},{"result":"Conduct 50 phone interviews with top customers","type":"NUMERIC","endDate":null,"measureStartValue":0.0,"measureGoalValue":1.0,"measureUnit":"interviews","measureUnitPlacement":"AFTER","measureCurrentValue":0.0,"orderIndex":2},{"result":"Conduct 15 phone interviews with recently churned customers","type":"NUMERIC","endDate":null,"measureStartValue":0.0,"measureGoalValue":1.0,"measureUnit":"interviews","measureUnitPlacement":"AFTER","measureCurrentValue":0.0,"orderIndex":3},{"result":"Present an action plan of 10 improvements for next quarter","type":"BINARY","endDate":null,"measureStartValue":0.0,"measureGoalValue":1.0,"measureUnit":"","measureUnitPlacement":"AFTER","measureCurrentValue":0.0,"orderIndex":4}],"developmentNeeds":null,"complete":null,"archived":null,"distribute":"COMPANY","tasks":[],"draftId":null}',
//         'progress': 27,
//         'createdAt': '2024-08-21T10:33:26.000Z',
//         'updatedAt': '2024-08-21T10:33:26.000Z'
//       },
//       {
//         'id': 444,
//         'parentTaskId': 12,
//         'eventType': LongRunningTaskEventType.EVENT_SUCCESS,
//         'message': 'Goal created for Aluin Harmond (15 of 37)',
//         'metadata': '{"title":"Long running task 9","type":"PERSONAL_OPERATIONAL","departmentId":null,"officeLocationId":null,"alignmentId":null,"endDate":"2024-09-03T23:00:00.000Z","visibility":"PRIVATE","priority":"NOT_SET","tagIds":[],"ownerIds":[16],"keyResults":[{"result":"Exceed Net Promoter Score (NPS) of over 8.0","type":"BINARY","endDate":null,"measureStartValue":0.0,"measureGoalValue":1.0,"measureUnit":"","measureUnitPlacement":"AFTER","measureCurrentValue":0.0,"orderIndex":0},{"result":"Get 1000 survey responses to annual satisfaction survey","type":"NUMERIC","endDate":null,"measureStartValue":0.0,"measureGoalValue":1.0,"measureUnit":"responses","measureUnitPlacement":"AFTER","measureCurrentValue":0.0,"orderIndex":1},{"result":"Conduct 50 phone interviews with top customers","type":"NUMERIC","endDate":null,"measureStartValue":0.0,"measureGoalValue":1.0,"measureUnit":"interviews","measureUnitPlacement":"AFTER","measureCurrentValue":0.0,"orderIndex":2},{"result":"Conduct 15 phone interviews with recently churned customers","type":"NUMERIC","endDate":null,"measureStartValue":0.0,"measureGoalValue":1.0,"measureUnit":"interviews","measureUnitPlacement":"AFTER","measureCurrentValue":0.0,"orderIndex":3},{"result":"Present an action plan of 10 improvements for next quarter","type":"BINARY","endDate":null,"measureStartValue":0.0,"measureGoalValue":1.0,"measureUnit":"","measureUnitPlacement":"AFTER","measureCurrentValue":0.0,"orderIndex":4}],"developmentNeeds":null,"complete":null,"archived":null,"distribute":"COMPANY","tasks":[],"draftId":null}',
//         'progress': 40,
//         'createdAt': '2024-08-21T10:33:27.000Z',
//         'updatedAt': '2024-08-21T10:33:27.000Z'
//       }
//     ],
//     'status': LongRunningTaskStatus.IN_PROGRESS,
//     'createdAt': '2024-08-21T10:33:24.000Z',
//     'updatedAt': '2024-08-21T10:33:24.000Z'
//   } as ILongRunningTaskServerside)
// ];

@Injectable({
  providedIn: 'root'
})
export class LongRunningTaskService {

  private readonly POLLING_RATE = 5000; // 10 seconds

  private tasksBeingTracked: number[] = [];
  private intervalId: NodeJS.Timer;
  private taskData: LongRunningTask[] = [];

  public $tasksListUpdated = new Subject<void>();

  public get isTrackingTasks(): boolean {
    return this.tasksBeingTracked.length > 0;
  }

  constructor(
    private longRunningTaskAPI: LongRunningTaskAPIService,
    private notifyUtils: NotifyUtils
  ) {
  }

  /**
   * Will start a timer that will periodically check for task data
   */
  public startPeriodicTaskCheck(): void {
    this.longRunningTaskAPI.getInProgress()
      .toPromise()
      .then(tasks => {
        if (tasks.length > 0) {
          this.tasksBeingTracked = tasks.map(task => task.id);
          this.taskData = tasks;
          this.$tasksListUpdated.next();
        }
      })
      .finally(() => {
        this.intervalId = setInterval(() => this.trySyncTaskData(), this.POLLING_RATE);
      });
  }

  public stopPeriodicTaskCheck(): void {
    if (!this.intervalId) { return; }
    clearInterval(this.intervalId);
  }

  /**
   * Will iterate over all tasks being tracked and try to sync their data
   */
  private trySyncTaskData(): void {
    if (this.tasksBeingTracked.length === 0) { return; }

    this.tasksBeingTracked.forEach(taskId => {
      // If we already have the task data and it's completed, we don't need to sync it
      const task = this.taskData.find(t => t.id === taskId);
      if (task && task.getIsCompleted()) {
        return;
      }

      this.syncDataForTask(taskId);
    });
  }

  public syncDataForTask(taskId: number): void {
    this.longRunningTaskAPI.get(taskId)
      .toPromise()
      .then(task => {
        const currentIndexOfTask = this.taskData.findIndex(t => t.id === taskId);
        if (currentIndexOfTask >= 0) {
          this.taskData[currentIndexOfTask] = this.taskData[currentIndexOfTask].Apply(task);
        } else {
          this.taskData.push(task);
        }
        this.$tasksListUpdated.next();
      })
      .catch(err => {
        console.error(`Error syncing task data for task ID: ${taskId}`, err);
      });
  }

  /**
   * Add a task to the list of tasks being tracked
   * @param taskId The ID of the task to start tracking
   */
  startTrackingTask(taskId: number): void {
    if (this.tasksBeingTracked.includes(taskId)) {
      return;
    }

    this.tasksBeingTracked.push(taskId);
    this.$tasksListUpdated.next();
    this.syncDataForTask(taskId);
  }

  /**
   * Remove a task from the list of tasks being tracked
   * @param taskId The ID of the task to stop tracking
   */
  stopTrackingTask(taskId: number): void {
    this.longRunningTaskAPI.dismiss(taskId)
      .toPromise()
      .then(() => {
        this.tasksBeingTracked = this.tasksBeingTracked.filter(id => id !== taskId);
        this.taskData = this.taskData.filter(task => task.id !== taskId);
        this.$tasksListUpdated.next();
      });
  }

  /**
   * Gets the data for any tasks we have synced
   * @returns An array of LongRunningTask objects
   */
  getAllTaskData(): LongRunningTask[] {
    return this.taskData;
  }

  /**
   * Get the data for a specific task
   * @param taskId The ID of the task to get data for
   * @returns The LongRunningTask object for the given task ID
   */
  getTaskData(taskId: number): LongRunningTask {
    return this.taskData.find(task => task.id === taskId);
  }

  /**
   * Remove all task data from the service
   */
  removeAllTaskData(): void {
    this.taskData = [];
  }

  /**
   * Remove data for a specific task
   * @param taskId The ID of the task to remove data for
   */
  removeTaskData(taskId: number): void {
    this.taskData = this.taskData.filter(task => task.id !== taskId);
  }
}