import {Injectable, NgZone} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {SwalUtils} from '@app/shared/utils/swal.utils';
import {sanitizeUrl} from '@app/shared/utils/helpers';
import { ErrorCode } from '@app/models/exception/error-codes.model';
import { FrankliHttpErrorResponse } from '@app/models/exception/frankli-http-error-response.model';

const IGNORED_ROUTES = [
  'authuser',
  'sso',
  'sso/teams',
  'api/connect/mentors/current-user',
  'api/connect/coaches/current-user',
  'api/email/click',
  'api/email/click/anonymous',
  'api/session/event',
  'api/customer-accounts/demo',
  'api/status',
  'api/user/init'
];

const IGNORED_HTTP_STATUSES = [
  0,
  501,
  502,
  503,
  504,
  505,
  506,
  507,
  510,
  511
];

const IGNORED_ERROR_CODES: ErrorCode[] = [
  ErrorCode.TEST_ERROR_CODE_BLACKLISTED,
  ErrorCode.GOAL_INVALID_OWNERS,
  ErrorCode.REGISTER_EMAIL_USED_CONFIRMED,
  ErrorCode.REGISTER_EMAIL_USED_UNCONFIRMED,
  ErrorCode.QUESTION_TEMPLATE_CANT_DELETE_IN_USE,
  ErrorCode.QUESTION_TEMPLATE_NAME_EXISTS,
  ErrorCode.SENTIMENT_SCALE_CANNOT_MODIFY_BECAUSE_USED
];

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {

  constructor(
    private zone: NgZone,
    private swalUtils: SwalUtils
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError(
        (error: FrankliHttpErrorResponse, caught: Observable<HttpEvent<any>>) => {
          const url = req.url;
          const sanitizedUrl = sanitizeUrl(url); // Remove trailing slashes from URL

          if (IGNORED_ROUTES.includes(sanitizedUrl)) {
            return throwError(error);
          }

          if (IGNORED_HTTP_STATUSES.includes(error.status)) {
            return throwError(error);
          }

          if (this.hasErrorCode(error)) {
            if (IGNORED_ERROR_CODES.includes(error.error.errorCode)) {
              return throwError(error);
            }
          }

          // Check if route is not allowed to throw a generic error SWAL
          // Will need to add regex matching in future if the whitelisted routes need parameters
          this.zone.run(() => this.displaySwalForFailedRequest(error, req, next)).then();

          // return of(null);
          return throwError(error);
        }
      )
    );
  }

  public displaySwalForFailedRequest(errorResponse: FrankliHttpErrorResponse, req?: HttpRequest<any>, next?: HttpHandler): Promise<HttpEvent<any> | null> {
    let mainText = '';
    let codeDisplay = '';
    let html = '';

    if (errorResponse.error) {
      mainText = typeof errorResponse.error === 'string' ? errorResponse.error : errorResponse.error.message;

      if (this.hasErrorCode(errorResponse)) {
        codeDisplay = `<small class="error-code">ERROR CODE: ${errorResponse.error.errorCode}</small> `;
      } else {
        codeDisplay = '';
      }

      html = `<div>${mainText}</div>${codeDisplay}`;
    }

    return this.swalUtils.displayErrorSwal({
      html: html,
    }).then();
  }

  private hasErrorCode(error: FrankliHttpErrorResponse): boolean {
    if (!error) { return false; }
    if (!error.error) { return false; }
    if (typeof error.error === 'string') { return false; }
    return !!error.error.errorCode;
  }
}