import { FeedbackUnpromptedSettings } from '@app/models/feedback/feedback.model';
import { mockUsers } from '@app/mock/api/data/mockUsers';

export const mockFeedbackUnpromptedSettings: FeedbackUnpromptedSettings = {
    context: [
        {
            id: 1,
            text: 'My work environment'
        },
        {
            id: 2,
            text: 'My workload'
        },
        {
            id: 3,
            text: 'My manager'
        },
        {
            id: 4,
            text: 'My colleagues'
        },
        {
            id: 5,
            text: 'Complaint'
        },
        {
            id: 6,
            text: 'Other'
        }
    ],
    feeling: [
        {
            id: 1,
            text: 'Happy'
        },
        {
            id: 2,
            text: 'Anxious'
        },
        {
            id: 3,
            text: 'Empowered'
        },
        {
            id: 4,
            text: 'Other'
        }
    ],
    proceed: [
        {
            id: 1,
            text: 'Meet with HR to discuss',
            timestamp: new Date(),
            user: mockUsers[1]
        },
        {
            id: 2,
            text: 'Meet with my manager to discuss',
            timestamp: null,
            user: null
        },
        {
            id: 3,
            text: 'Request external support/advice EAP',
            timestamp: null,
            user: mockUsers[1]
        },
        {
            id: 4,
            text: 'No further action',
            timestamp: null,
            user: mockUsers[2]
        },
        {
            id: 5,
            text: 'Other',
            timestamp: null,
            user: null
        }
    ]
}