<div class="container-circles">
  <div class="circle c-green"></div>
  <div class="circle c-red"></div>
  <div class="circle c-orange"></div>
  <div class="c-blue"></div>
</div>
<div class="container-info">
  <img class="logo" src="/assets/img/Frankli_logo_primary_white.png" />
  <br />
  <div class="m-top-20">Under maintenance</div>
  <div>Please check back in a few minutes.</div>
  <br />
  <div class="m-top-20">This page will automatically refresh when we are back online</div>
</div>