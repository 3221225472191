import { User } from 'app/models/user/user.model';
import { UserMinimal } from '@app/models/user/user-minimal.model';

export interface EvaluationAdminActivity {
    id: number;
    foreignId: number;
    cycleId: number;
    extraInfo: string;
    admin: User;
    timestamp: Date;
    activityType: EvaluationAdminActivityType;
    affectedUser?: User | UserMinimal;
    affectedWriter?: User | UserMinimal;
}

export enum EvaluationAdminActivityType {
    RENAMED = 'RENAMED',
    ADDED_PARTICIPANT = 'ADDED_PARTICIPANT',
    REMOVED_PARTICIPANT = 'REMOVED_PARTICIPANT',
    STEP_DATE_CHANGED = 'STEP_DATE_CHANGED',
    QUESTION_ADDED = 'QUESTION_ADDED',
    QUESTION_REMOVED = 'QUESTION_REMOVED',
    CALIBRATION_SAVED = 'CALIBRATION_SAVED',
    MANAGER_REVIEW_UNSUBMITTED = 'MANAGER_REVIEW_UNSUBMITTED',
    SELF_REFLECTION_UNSUBMITTED = 'SELF_REFLECTION_UNSUBMITTED',
    PEER_REVIEW_UNSUBMITTED = 'PEER_REVIEW_UNSUBMITTED',
    EXTEND_END_DATE = 'EXTEND_END_DATE',
    UPWARD_REVIEW_UNSUBMITTED = 'UPWARD_REVIEW_UNSUBMITTED'
}
