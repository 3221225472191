import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { OneToOneMeeting } from '@app/domain/one_to_one/model/one-to-one-meeting.model';
import { Globals } from '@app/shared/globals/globals';
import { OneToOneMessages } from '@app/domain/one_to_one/locale/one-to-one.messages';
import { CommonMessages } from '@app/constants/common.messages';
import { OneToOneNote } from '../../model/one-to-one-note.model';
import { UserMinimal } from '@app/models/user/user-minimal.model';
import { OneToOneScheduleDetailsView } from '../../model/one-to-one-schedule.model';
import { ButtonType } from '@app/shared/components/inputs/button/button.component';
import { IState } from '@app/models/state/state.model';
import { OneToOneBusinessService } from '../../service/one-to-one-business.service';
import { IconHoverColor } from '@app/shared/components/inputs/table-action-icon/table-action-icon.component';
import { OneToOneMeetingStatus } from '../../model/one-to-one-meeting-status.model';
import { OneToOneStatus } from '../../model/one-to-one-status.model';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

interface PageState extends IState {
  viewingNotes: boolean;
  addingNote: boolean;
  noteAlreadyAdded: boolean;
}

const editorConfig = {
  toolbar: 'undo redo | formatselect bold italic underline strikethrough | numlist bullist | outdent indent | alignleft aligncenter alignright | link btnFrankliLink',
  plugins: 'wordcount link autolink frankliRichLink'
};

@Component({
  selector: 'app-shared-notes',
  templateUrl: './shared-notes.component.html',
  styleUrls: ['./shared-notes.component.scss']
})
export class SharedNotesComponent implements OnChanges {
  public readonly eOneToOneMessages = OneToOneMessages;
  public readonly eIconHoverColor = IconHoverColor;
  public readonly eCommonMessages = CommonMessages;
  public readonly eEditorConfig = editorConfig;
  public readonly eButtonType = ButtonType;
  public readonly MAX_CHARACTERS = 5000;
  
  @Input() schedule: OneToOneScheduleDetailsView;
  @Input() meeting: OneToOneMeeting;

  @Output() onNotesUpdated: EventEmitter<OneToOneMeeting>;
  
  state: PageState;
  controlNotes: FormControl;
  usersWithNotes: UserMinimal[];
  
  get hasOtherUserNotes(): boolean {
    if (!this.meeting) { return false; }
    if (!this.meeting.sharedNotes) { return false; }
    return this.meeting.sharedNotes.some(note => note.userId !== this.globals.user.id);
  }

  get hasSharedNotes(): boolean {
    if (!this.meeting.sharedNotes) { return false; }
    if (this.meeting.sharedNotes.length === 0) { return false; }
    return true;
  }

  get canAddNotes(): boolean {
    if (!this.schedule) { return false; }
    if (this.schedule.status === OneToOneStatus.ARCHIVED) { return false; }
    if (!this.meeting) { return false; }
    if (this.meeting.status === OneToOneMeetingStatus.MISSED) { return false; }
    if (this.meeting.status === OneToOneMeetingStatus.CANCELLED) { return false; }
    return true;
  }

  constructor(
    public globals: Globals,
    private oneToOneBusinessService: OneToOneBusinessService
  ) {
    this.schedule = undefined;
    this.meeting = undefined;

    this.usersWithNotes = [];

    this.state = {
      loading: true,
      error: false,
      errorMessage: '',
      viewingNotes: true,
      addingNote: false,
      noteAlreadyAdded: false
    };

    this.onNotesUpdated = new EventEmitter<OneToOneMeeting>();

    this.controlNotes = this.initControlNotes();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.meeting) {
      this.onChangeMeeting(changes.meeting.currentValue);
    }
  }

  onChangeMeeting(meeting: OneToOneMeeting): void {
    if (!meeting) { return; }

    const currentUserSharedNote = meeting.sharedNotes.find(note => note.userId === this.globals.user.id);
    if (currentUserSharedNote) {
      this.controlNotes = this.initControlNotes(currentUserSharedNote);
      this.state.noteAlreadyAdded = true;
    } else {
      this.controlNotes = this.initControlNotes();
      this.state.noteAlreadyAdded = false;
    }

    this.usersWithNotes = meeting.sharedNotes.map(note => note.user);
  }

  initControlNotes(notes?: OneToOneNote): FormControl {
    const formControl = new FormControl('', [Validators.maxLength(10000)]);

    if (notes) {
      formControl.setValue(notes.contents);
    }

    formControl.valueChanges
      .pipe(debounceTime(2000))
      .pipe(distinctUntilChanged())
      .subscribe(() => {
        this.submitAddingNote(true);
      });

    return formControl;
  }

  submitAddingNote(autosaving = false): void {
    if (this.controlNotes.pristine) {
      this.state.addingNote = false;
      return;
    }
    const newNotes = this.controlNotes.value;
    if (newNotes === '') { return; }

    this.oneToOneBusinessService.addMeetingSharedNote(this.schedule.id, this.meeting.id, newNotes)
      .then(meeting => {
        this.state.noteAlreadyAdded = true;

        if (!autosaving) {
          this.state.addingNote = false;
        }

        this.onNotesUpdated.emit(meeting);

        setTimeout(() => {
          this.controlNotes.markAsPristine();
        }, 100);
      });
  }

  toggleNotesList(): void {
    this.state.viewingNotes = !this.state.viewingNotes;
  }

  startAddingNote(): void {
    this.state.addingNote = true;
  }

  cancelAddingNote(): void {
    this.state.addingNote = false;
  }

}
