import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IQualificationAPI } from './qualification-api.model';
import { Qualification } from '../model/qualification.model';
import { CreateQualificationDto } from '../model/create-qualification.model';
import { PagingParams } from '@app/models/api/paging-params.model';
import { SortingParams } from '@app/models/api/sorting-params.model';
import { ApiUtils } from '@app/shared/utils/api.utils';
import { Page } from '@app/models/api/page.model';
import { QualificationSidebarData } from '../model/qualification-sidebar-data.model';

@Injectable()
export class QualificationAPIService implements IQualificationAPI {

  private readonly BASE_URL = 'api/qualification';

  constructor(private http: HttpClient) { }

  get(ids: number[], name: string, creatorIds: number[]): Observable<Qualification[]> {
    const url = `${this.BASE_URL}`;

    let params = new HttpParams();

    if (ids) {
      ids.forEach(id => {
        params = params.append('id', id);
      });
    }

    if (creatorIds) {
      creatorIds.forEach(id => {
        params = params.append('creatorId', id);
      });
    }

    if (name && name.length > 0) {
      params = params.append('name', name);
    }

    return this.http.get<Qualification[]>(url, { params: params });
  }

  getPaginated(pagingParams: PagingParams, sortingParams: SortingParams, ids: number[], name: string, creatorIds: number[]): Observable<Page<Qualification>> {
    const url = `${this.BASE_URL}/paged`;

    let params = ApiUtils.createPageAndSortParams(pagingParams, sortingParams);

    if (ids) {
      ids.forEach(id => {
        params = params.append('id', id);
      });
    }

    if (creatorIds) {
      creatorIds.forEach(id => {
        params = params.append('creatorId', id);
      });
    }

    if (name && name.length > 0) {
      params = params.append('name', name);
    }

    return this.http.get<Page<Qualification>>(url, { params: params });
  }

  create(createQualificationDto: CreateQualificationDto): Observable<Qualification> {
    const url = `${this.BASE_URL}`;
    return this.http.post<Qualification>(url, createQualificationDto);
  }

  update(id: number, createQualificationDto: CreateQualificationDto): Observable<Qualification> {
    const url = `${this.BASE_URL}/${id}`;
    return this.http.put<Qualification>(url, createQualificationDto);
  }

  delete(id: number): Observable<Qualification> {
    const url = `${this.BASE_URL}/${id}`;
    return this.http.delete<Qualification>(url);
  }

  getSidebarData(userIds: number[]): Observable<QualificationSidebarData[]> {
    const url = `${this.BASE_URL}/sidebar`;

    let params = new HttpParams();

    if (userIds) {
      params = params.append('userIds', userIds.join(','));
    }

    return this.http.get<QualificationSidebarData[]>(url, { params: params });
  }
}
