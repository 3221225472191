import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';
import { CommonMessages } from '@app/constants/common.messages';
import { GoalsMessages } from '@app/goals/goals.messages';
import { GoalVisibility } from '@app/models/goals/goal-visibility.model';
import { ButtonGroupOption } from '@app/shared/components/inputs/button-group/button-group.component';
import { DateUtils } from '@app/shared/utils/date.utils';
import { GoalUtils } from '@app/shared/utils/goal.utils';
import { IMyDate } from 'angular-mydatepicker';
import { CommonGoalCreateFormPart } from '../../goal-create-views/goal-create-main/goal-create-main.component';
import { GoalCreateValidationLimits } from '../../goal-create.component';

const visbilityOptionsData: ButtonGroupOption[] = [
  {
    value: GoalVisibility.PUBLIC,
    friendlyText: GoalsMessages.PUBLIC,
    iconClasses: `fal ${GoalUtils.getIconClassForGoalVisibility(GoalVisibility.PUBLIC)}`
  },
  {
    value: GoalVisibility.PRIVATE,
    friendlyText: GoalsMessages.PRIVATE,
    iconClasses: `fal ${GoalUtils.getIconClassForGoalVisibility(GoalVisibility.PRIVATE)}`
  }
];


@Component({
  selector: 'app-goal-create-part-details',
  templateUrl: './goal-create-part-details.component.html',
  styleUrls: ['./goal-create-part-details.component.scss']
})
export class GoalCreatePartDetailsComponent implements OnInit, CommonGoalCreateFormPart {
  public readonly eGoalVisibility = GoalVisibility;
  public readonly dateDisplayFormat = 'D MMM YYYY';
  public readonly visbilityOptions = visbilityOptionsData;
  public readonly eGoalsMessages = GoalsMessages;
  public readonly eCommonMessages = CommonMessages;
  public readonly eGoalCreateValidationLimits = GoalCreateValidationLimits;

  @Input() controlTitle: FormControl;
  @Input() controlEndDate: FormControl;
  @Input() controlVisibility: FormControl;
  @Input() submitted: boolean;
  @Input() submittedDraft: boolean;
  @Input() parentDropdownOpen: boolean;
  @Input() minDueDate: IMyDate;

  @Output() onSetParentDropdown: EventEmitter<boolean>;

  datepickerOpen: boolean;

  constructor() {
    this.controlTitle = new FormControl('');
    this.controlEndDate = new FormControl(new Date());
    this.controlVisibility = new FormControl(GoalVisibility.PUBLIC);
    this.datepickerOpen = false;
    this.submitted = false;
    this.submittedDraft = false;
    this.parentDropdownOpen = true;

    this.minDueDate = DateUtils.convertDateToPickerFormat(new Date());
    this.onSetParentDropdown = new EventEmitter<boolean>();
  }

  ngOnInit(): void {
  }

  onCalendarToggle(isOpened: boolean): void {
    this.datepickerOpen = isOpened;
  }

  setParentDropdown(expanded: boolean): void {
    this.onSetParentDropdown.emit(expanded);
  }

  convertDateToPickerFormat(date: moment.Moment): IMyDate {
    const dateJS = date.toDate();
    return {
      day: dateJS.getDate(),
      month: (dateJS.getMonth() + 1),
      year: dateJS.getFullYear(),
    };
  }

  focus(): void {
    // TODO:
  }
}
