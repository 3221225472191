import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { VincereMetricResult } from '@app/models/integrations/vincere/vincere-metric-result.model';
import { VincereUserStatus } from '@app/models/integrations/vincere/vincere-user-status.model';
import { Observable } from 'rxjs';
import { VincereMetricService } from './interfaces/vincere-metric.service';

@Injectable({
  providedIn: 'root'
})
export class VincereMetricAPIService implements VincereMetricService {

  private readonly BASE_URL: string = 'api/vincere-metric';

  constructor(
    private http: HttpClient
  ) { }

  getUserMetrics(userId: number, year: number, month: number): Observable<Array<VincereMetricResult>> {
    const url = `${this.BASE_URL}/${userId}?year=${year}&month=${month}`;
    return this.http.get<Array<VincereMetricResult>>(url);
  }

  getDatesForUser(userId: number) {
    const url = `${this.BASE_URL}/${userId}/dates`;
    return this.http.get<Array<Date>>(url);
  }

  getUserStatus(userId: number): Observable<VincereUserStatus> {
    const url = `${this.BASE_URL}/${userId}/status`;
    return this.http.get<VincereUserStatus>(url);
  }


  // parseVincereMetricMultiple(metrics: VincereMetricResult[]): VincereMetricResult[] {
  //   return metrics.map(m => this.parseVincereMetricSingle(m));
  // }

  // parseVincereMetricSingle(metric: VincereMetricResult): VincereMetricResult {
  //   if (metric) {
  //     metric.iconClass = this.vincereUtils.getIconForMetricName(metric.name);
  //   }

  //   return metric;
  // }
}
