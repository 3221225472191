import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { EvaluationAdminActivityType } from '@app/models/evaluation/evaluation-admin-action.model';
import { EvaluationCycle } from '@app/models/evaluation/evaluation-cycle.model';
import { EvaluationFeedbackRequestState } from '@app/models/evaluation/evaluation-feedback-request-state.model';
import { EvaluationFeedbackRequest } from '@app/models/evaluation/evaluation-feedback-request.model';
import { IState } from '@app/models/state/state.model';
import { EvaluationCycleAPIService } from '@app/shared/api/evaluation-cycle.api.service';
import { ButtonType } from '@app/shared/components/inputs/button/button.component';
import { IconHoverColor } from '@app/shared/components/inputs/table-action-icon/table-action-icon.component';
import { PillType } from '@app/shared/components/pill/pill.component';
import { Globals } from '@app/shared/globals/globals';
import { ModalComponent } from '@app/shared/modal/modal.component';
import { NotifyUtils } from '@app/shared/utils/notify.utils';
import { forkJoin } from 'rxjs';

interface PageState extends IState {
  submitted: boolean;
  submitting: boolean;
}

@Component({
  selector: 'app-peer-reviews-item-team',
  templateUrl: './peer-reviews-item-team.component.html',
  styleUrls: ['./peer-reviews-item-team.component.scss']
})
export class PeerReviewsItemTeamComponent implements OnInit {
  public readonly eEvaluationFeedbackRequestState = EvaluationFeedbackRequestState;
  public readonly eIconHoverColor = IconHoverColor;
  public readonly eButtonType = ButtonType;
  public readonly ePillType = PillType;

  @Input() request: EvaluationFeedbackRequest;

  @Output() onApprove: EventEmitter<EvaluationFeedbackRequest>;
  @Output() onDecline: EventEmitter<EvaluationFeedbackRequest>;

  @ViewChild('modalDecline') modalDecline: ModalComponent;

  cycle?: EvaluationCycle;
  wasUnsubmitted: boolean;

  state: PageState;

  controlDeclineReason: FormControl;

  get canViewRequest(): boolean {
    if (!this.request) { return false; }
    if (!this.globals.user) { return false; }
    if (this.request.authorUserId === this.globals.user.id) { return true; }
    return false;
  }

  get canApproveRequest(): boolean {
    if (!this.request) { return false; }
    if (!this.globals.user) { return false; }
    if (!this.request) { return false; }
    if (this.request.subjectUserId === this.globals.user.id) { return true; }
    return false;
  }

  get canApproveRequestManager(): boolean {
    if (!this.request) { return false; }
    if (!this.globals.user) { return false; }
    if (!this.request) { return false; }
    if (!this.request.subjectUser) { return false; }
    if (this.request.subjectUser.managerId === this.globals.user.id) { return true; }
    return false;
  }

  constructor(
    private evaluationCycleAPIService: EvaluationCycleAPIService,
    private router: Router,
    public globals: Globals,
    private notifyUtils: NotifyUtils
  ) {
    this.onDecline = new EventEmitter<EvaluationFeedbackRequest>();
    this.onApprove = new EventEmitter<EvaluationFeedbackRequest>();

    this.state = {
      loading: true,
      error: false,
      errorMessage: '',
      submitted: false,
      submitting: false
    };
    this.controlDeclineReason = this.initDeclineForm();
  }

  ngOnInit(): void {
    this.getData();
  }

  getData(): void {
    if (!this.request) { return; }

    forkJoin([
      this.evaluationCycleAPIService.getEvaluationCycleById(this.request.cycleId),
      // TODO: This no work
      this.evaluationCycleAPIService.getMostRecentActivityForEvaluationRequest(this.request.cycleId, this.request.id)
    ]).subscribe(
      ([cycle, lastActivity]) => {
        this.cycle = cycle;

        this.wasUnsubmitted = ((this.request.state !== EvaluationFeedbackRequestState.COMPLETED) && lastActivity && (lastActivity.activityType === EvaluationAdminActivityType.PEER_REVIEW_UNSUBMITTED));

        this.state.loading = false;
      }
    );
  }

  openRequest(): void {
    this.router.navigate(['evaluations/respond', this.request.id]);
  }

  declineRequest(): void {
    this.controlDeclineReason = this.initDeclineForm();
    this.showDeclineModal();
  }

  approveRequest(): void {
    this.state.submitted = true;

    if (this.state.submitting) { return; }
    this.state.submitting = true;

    this.evaluationCycleAPIService.approveEvaluationRequest(this.request.id).subscribe(
      (res) => {
        this.notifyUtils.notify('Request approved successfully');
        this.onApprove.emit(res);
        this.state.submitting = false;
        this.hideDeclineModal();
      }, 
      () => {
        this.state.submitting = false;
      }
    );
  }

  cancelDeclineRequest() {
    this.controlDeclineReason = this.initDeclineForm();
    this.hideDeclineModal();
  }

  confirmDeclineRequest() {
    this.state.submitted = true;

    if (this.state.submitting) { return; }
    if (this.controlDeclineReason.invalid) { return; }

    this.state.submitting = true;

    this.evaluationCycleAPIService.declineEvaluationRequest(this.request.id, this.controlDeclineReason.value).subscribe(
      (res) => {
        this.notifyUtils.notify('Request declined successfully');
        this.onDecline.emit(res);
        this.state.submitting = false;
        this.hideDeclineModal();
      },
      () => {
        this.state.submitting = false;
      }
    );
  }

  initDeclineForm(): FormControl {
    const formControl = new FormControl('', [Validators.required, Validators.minLength(20), Validators.maxLength(255)]);

    return formControl;
  }

  hideDeclineModal(): void {
    if (this.modalDecline) {
      this.modalDecline.hide();
    }
  }

  showDeclineModal(): void {
    if (this.modalDecline) {
      this.modalDecline.show();
    }
  }

  showActivityModal(): void {
    
  }
}
