import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Poll } from '@app/models/poll/poll.model';
import { select } from 'd3-selection';
import { line } from 'd3-shape';
import 'd3-transition';
import moment from 'moment';

const QUARTER_HEIGHT = 50;
const COLUMN_GUTTER = 10;
interface ChartColumnData {
  id: number;
  label: string;
  height: number;
  value: number;
  canvasX: number;
  canvasY: number;
  hasScore: boolean;
}

interface ChartPoll extends Poll {
  status: string; // FIXME: Add this as a new enum to the poll model and store it
  hasScore: boolean;
  score: number;
}

@Component({
  selector: 'app-survey-score-over-time-chart',
  templateUrl: './survey-score-over-time-chart.component.html',
  styleUrls: ['./survey-score-over-time-chart.component.scss']
})
export class SurveyScoreOverTimeChartComponent implements OnInit {
  @Input() columnWidth: number;

  @Input() data: ChartPoll[];
  @Input() selectedId: number;

  @ViewChild('trendline') trendline?: ElementRef<SVGElement>;

  chartHeight: number;
  chartData: ChartColumnData[];

  constructor() {
    this.selectedId = 0;
    this.columnWidth = 80;
    this.chartHeight = 200;
    this.data = [];
    this.chartData = [];
  }

  ngOnInit(): void {
    this.chartData = this.parseScoresMultiple(this.data);
    
    // Draw on the next frame when chart area is rendered
    setTimeout(() => {
      const trendlineData = this.chartData.filter(d => d.hasScore);
      this.drawTrendline(this.trendline, trendlineData);
    }, 1);
  }

  parseScoresMultiple(polls: ChartPoll[]): ChartColumnData[] {
    return polls.map((poll: ChartPoll, index: number) => this.parseScoreSingle(poll, index));
  }

  parseScoreSingle(poll: ChartPoll, index: number): ChartColumnData {
    const height = poll.score;
    const canvasX = this.getCanvasXForColumn(index);
    const canvasY = this.getCanvasYForColumn(height);
    const startDateFormatted = moment(poll.publishedDate).format('D MMM YYYY');
    // const endDateFormatted = moment(poll.expirationDate).format('D MMM YYYY');

    const output: ChartColumnData = {
      id: poll.id,
      label: `${startDateFormatted}`, // TODO: Date here?
      height: height,
      value: poll.score,
      canvasX: canvasX,
      canvasY: canvasY,
      hasScore: poll.hasScore
    };

    return output;
  }

  getCanvasXForColumn(index: number): number {
    const halfColumnWidth = (this.columnWidth / 2);

    const widthColumnsBefore = (index * (this.columnWidth + COLUMN_GUTTER));

    return widthColumnsBefore + halfColumnWidth + COLUMN_GUTTER;
  }

  getCanvasYForColumn(height: number): number {
    const fullRows = (height / 25);
    const fullHeight = (fullRows * QUARTER_HEIGHT);

    const remainder = (height % 25);
    const remainderHeight = ((remainder / 25));
    const y = (fullHeight + remainderHeight);

    return y;
  }

  drawTrendline(host: ElementRef<SVGElement> | undefined, data: ChartColumnData[]): void {
    if (!host) {
      throw new Error('[SurveyScoreOverTimeChart] Trendline element not found');
    }

    const svg = select(host.nativeElement);
    const width = ((this.columnWidth + 10) * this.chartData.length);
    const height = this.chartHeight;
    const strokeWidth = 2;

    const clampLimits = {
      max: this.chartHeight - strokeWidth,
      min: strokeWidth
    };

    const lineData = data.map(d => {
      const x = d.canvasX;
      const y = this.clamp(d.canvasY, clampLimits.min, clampLimits.max); // Clamp the line to inside the chart
      return [x, y] as [number, number];
    });

    const lineGen = line();
    // .curve(curveBumpX)
    // .curve(curveMonotoneX);

    svg
      .attr('viewBox', `0 0 ${width} ${height}`)
      .attr('width', width)
      .attr('height', height);

    // #region - Line
    svg
      .selectAll('path')
      .data([data])
      .enter()
      .append('path')
      .attr('d', lineGen(lineData))
      .attr('stroke', '#FB946E')
      .attr('stroke-width', strokeWidth)
      .attr('fill', 'none');

    svg
      .selectAll('path')
      .data([data])
      .transition().duration(250)
      .attr('d', lineGen(lineData));
    // #endregion

    // #region - Tooltip
    svg
      .selectAll('div')
      .data([data])
      .enter()
      .append('div')
      .attr('class', 'tooltip')
    // .style('opacity', '0')
      .style('background', 'white')
      .style('border-style', 'solid')
      .style('border-width', '1px')
      .style('border-color', '#EEEEEE')
      .style('border-radius', '6px')
      .style('padding', '10px')
      .style('pointer-events', 'none')
      .style('white-space', 'nowrap');

    svg
      .selectAll('div')
      .data([data])
      .style('left', '50px')
      .style('top', '10px');
    // #endregion

    // #region - Point dots
    svg
      .selectAll('circle')
      .data(lineData)
      .enter()
      .append('circle')
      .attr('cx', function (d: any) {
        return d[0];
      })
      .attr('cy', function (d: any) {
        return d[1];
      })
      .attr('fill', '#FB946E')
      .attr('stroke', 'none')
      .attr('r', 3);

    svg
      .selectAll('circle')
      .data(lineData)
      .transition().duration(250)
      .attr('cx', function (d: any) {
        return d[0];
      })
      .attr('cy', function (d: any) {
        return d[1];
      });

    // Hover effects
    // svg
    //   .selectAll('circle')
    //   .data(lineData)
    //   .on('mouseover', function (event: MouseEvent, data: [number, number]) {

    //     svg
    //       .selectAll('div')
    //       .data([data])
    //       .html('Temp')
    //       .style('opacity', '1')
    //       .style('left', `${data[0]}px`)
    //       .style('top', `${data[1]}px`);
    //   })
    //   .on('mousemove', function (event: MouseEvent, data: [number, number]) {
    //     svg
    //       .selectAll('div')
    //       .data([data])
    //       .style('left', `${data[0]}px`)
    //       .style('top', `${data[1]}px`);
    //   })
    //   .on('mouseout', function (event: MouseEvent, data: [number, number]) {
    //     svg
    //       .selectAll('div')
    //       .data([data])
    //       // .style('opacity', '0');
    //   });

    // #endregion
  }

  clamp(value: number, min: number, max: number): number {
    return Math.min(Math.max(value, min), max);
  }
}
