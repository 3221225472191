import { Injectable } from '@angular/core';
import { NotifyUtils } from '@app/shared/utils/notify.utils';
import { Observable } from 'rxjs';
import { MissionStatementMessages } from '../locale/mission-statement.messages';
import { CreateMissionStatement } from '../model/create-mission-statement.model';
import { MissionStatement } from '../model/mission-statement.model';
import { MissionStatementAPIService } from './mission-statement-api.service';

@Injectable({
  providedIn: 'root'
})
export class MissionStatementBusinessService {

  updating: boolean;

  constructor(
    private missionAPI: MissionStatementAPIService,
    private notifyUtils: NotifyUtils
  ) {
    this.updating = false;
  }

  private startUpdating() {
    this.updating = true;
  }

  private stopUpdating() {
    this.updating = false;
  }

  get(): Observable<MissionStatement> {
    return this.missionAPI.getMission();
  }

  set(missionStatement: CreateMissionStatement): Promise<MissionStatement> {
    if (this.updating) { return new Promise((resolve, reject) => reject()); }
    this.startUpdating();

    return this.missionAPI.setMission(missionStatement).toPromise()
      .then(res => {
        this.notifyUtils.notify(MissionStatementMessages.SUCCESS_SET_MISSION);
        return res;
      })
      .finally(() => {
        this.stopUpdating();
      });
  }

  delete(): Promise<MissionStatement> {
    if (this.updating) { return new Promise((resolve, reject) => reject()); }
    this.startUpdating();

    return this.missionAPI.deleteMission().toPromise()
      .then(res => {
        this.notifyUtils.notify(MissionStatementMessages.SUCCESS_DELETE_MISSION);
        return res;
      })
      .finally(() => {
        this.stopUpdating();
      });
  }
}
