import { Goal } from './goal.model';
import { GoalKeyResult } from './goal-key-result.model';
import { Tag } from '../../domain/tag/model/tag.model';
import { Department } from '../department.model';
import { Site } from '../site.model';
import { GoalActivityType } from "./goal-activity-type.model";
import { GoalType } from './goal-type.enum';
import { GoalPriority } from './goal-priority.model';
import { GoalStatus } from './goal-status.model';
import { GoalVisibility } from './goal-visibility.model';

export class GoalCommentDto {
  id: number | null;
  comment: string | null;

  constructor(id: number | null, comment: string | null) {
    this.id = id;
    this.comment = comment;
  }
}

export class GoalProgressDto {
  status: GoalStatus;
  keyResults: Array<GoalKeyResult>;
  progress: string | null;

  constructor(status: GoalStatus, keyResults: Array<GoalKeyResult>, progress: string) {
    this.status = status;
    this.keyResults = keyResults;
    this.progress = progress;
  }
}

export class GoalStatusDto {
  goalActivityType: GoalActivityType;

  constructor(goalActivityType: GoalActivityType) {
    this.goalActivityType = goalActivityType;
  }
}

export class GoalUpdateDto {
  title: string;
  type: GoalType;
  department: Department | null;
  officeLocation: Site | null;
  endDate: Date;
  visibility: GoalVisibility;
  priority: GoalPriority;
  tags: Array<Tag>;
  keyResults: Array<GoalKeyResult>;
  ownerIds: Array<number>;
  status: GoalStatus;
  alignment: number | null;
  developmentNeeds!: string | null;

  constructor(goal: Goal) {
    this.title = goal.title;
    this.type = goal.type;
    this.department = goal.department;
    this.officeLocation = goal.officeLocation;
    this.endDate = goal.endDate;
    this.priority = goal.priority;
    this.tags = goal.tags;
    this.keyResults = goal.keyResults;
    this.ownerIds = goal.owners.map(o => o.id);
    this.status = goal.status;
    this.alignment = goal.alignment;
    this.visibility = goal.visibility;

    // department and office locations
    switch (goal.type) {
      case GoalType.DEPARTMENT:
        this.department = goal.department;
        this.officeLocation = null;
        break;
      case GoalType.OFFICE_LOCATION:
        this.officeLocation = goal.officeLocation;
        this.department = null;
        break;
      case GoalType.PERSONAL_DEVELOPMENTAL:
        this.developmentNeeds = goal.developmentNeeds;
        break;
      default:
        this.department = null;
        this.officeLocation = null;
        this.developmentNeeds = null;
        break;
    }
  }
}

