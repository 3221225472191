<ng-container *ngIf="(meetings && (meetings.length > 0))">
  <div class="carousel-container">
    <div class="carousel-meeting-list" #scrollList>
      <ng-container *ngFor="let meeting of meetings; index as index;">
        <!-- <ng-container *ngIf="highlightedIds.length === 0 || highlightedIds.includes(meeting.id)"> -->
          <app-meeting-navigation-carousel-item [schedule]="schedule" [meeting]="meeting" [index]="index" [selectedId]="_value" (onSelect)="selectionChanged(meeting.id)" [highlightedIds]="highlightedIds"></app-meeting-navigation-carousel-item>
        <!-- </ng-container> -->
      </ng-container>
    </div>
  </div>
</ng-container>
