import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'environments/environment';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AuthAPIService } from '../auth/auth.api.service';
import { Globals } from '../globals/globals';
import { ErrorService } from './error.service';
import * as Sentry from '@sentry/angular';

@Injectable()
export class HttpInterceptors implements HttpInterceptor {
  constructor(
    private errorService: ErrorService,
    public globals: Globals,
    private authService: AuthAPIService,
    private router: Router
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // If the event was posted with a url containing '//' or ';' in it, log an error
    if (request.url && (request.url.includes('//') || request.url.includes(';'))) {
      console.info('Invalid request made to ', request.url);
      Sentry.captureMessage('Invalid characters in request url: ' + request.url);
    }

    if (this.globals.user) {
      request = request.clone({
        setHeaders: {
          'Accept-Language': this.globals.user.configuration.preferredLocale
        }
      });
    }

    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          const serverVersionHeader = event.headers.get('x-server-version');

          if (serverVersionHeader && serverVersionHeader !== environment.version) {
            if (event.url && event.url!.indexOf('/health') > 0 && environment.version === '1.0.2') {
              this.authService.logout();
            }

            this.errorService.setUpdateStatus(true);
          }
        }
        return event;
      }),
      catchError((failure: HttpErrorResponse) => {
        const failureUrl = failure.url;
        if (failureUrl === null) {
          return throwError(failure);
        }

        switch (failure.status) {
          // no internet connection
          case 0:
            this.errorService.sendStatus(0);
            break;
          // logged out
          case 200:
            if ((<string>failure.error.text).toLocaleLowerCase().substring(0, 15) === '<!doctype html>') {
              this.errorService.sendStatus(200);
            }
            break;
          case 401:
            // NOTE: This is for switching to JWT from cookies. TODO: Remove once switched fully
            if (failure.url && failure.url.indexOf('/connect') === 0) {
              this.router.navigate(['/logout']);
            }
            break;
          // nginx up but server down
          case 502:
            if (!(failure.url && failure!.url.includes('version.json'))) {
              this.errorService.sendStatus(502);
            }
            break;
          // server down
          case 503:
            this.errorService.sendStatus(503);
            break;
          // proxy down
          case 504:
            this.errorService.sendStatus(504);
            break;
        }
        return throwError(failure);
      })
    );
  }
}
