import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges } from '@angular/core';
import { SessionLogYear } from '@app/models/session-log/session-event-overview.modal';
import { State } from '@app/shared/utils/state.model';

interface SessionLogWeeklyBreakdownWeek {
  days: Array<SessionLogWeeklyBreakdownDay>;
}

interface SessionLogWeeklyBreakdownDay {
  day: string;
  totalDays: number;
  hours: Array<SessionLogWeeklyBreakdownHour>
}

interface SessionLogWeeklyBreakdownHour {
  hour: number;
  totalUsers: number;
}

@Component({
  selector: 'app-session-log-weekly-breakdown-component',
  templateUrl: './session-log-weekly-breakdown.component.html',
  styleUrls: ['./session-log-weekly-breakdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush // For performance reasons
})
export class SessionLogWeeklyBreakdownComponent implements OnChanges {
  public readonly state: State = new State(true);
  public readonly RANGE_100: number = 500;
  public readonly RANGE_80: number = 400;
  public readonly RANGE_60: number = 300;
  public readonly RANGE_40: number = 200;
  public readonly RANGE_20: number = 1;

  @Input()
  public data: Array<SessionLogYear> = new Array<SessionLogYear>();
  public week: SessionLogWeeklyBreakdownWeek;

  constructor(
    private changeDetectorRef: ChangeDetectorRef
  ) { }

  ngOnChanges(): void {
    if (this.data.length !== 0) {
      this.week = this.mapSessionLogYearsToSessionLogWeeklyBreakdownWeek(this.data);
      this.state.setSuccess();
      this.changeDetectorRef.detectChanges();
    }
    else{
      this.state.setSuccess();
      this.changeDetectorRef.detectChanges();
    }
  }

  private mapSessionLogYearsToSessionLogWeeklyBreakdownWeek(years: Array<SessionLogYear>): SessionLogWeeklyBreakdownWeek {

    const week: SessionLogWeeklyBreakdownWeek = { days: [] };

    const sunday: SessionLogWeeklyBreakdownDay = { day: 'Sunday', totalDays: 0, hours: [] };
    const monday: SessionLogWeeklyBreakdownDay = { day: 'Monday', totalDays: 0, hours: [] };
    const tuesday: SessionLogWeeklyBreakdownDay = { day: 'Tuesday', totalDays: 0, hours: [] };
    const wednesday: SessionLogWeeklyBreakdownDay = { day: 'Wednesday', totalDays: 0, hours: [] };
    const thursday: SessionLogWeeklyBreakdownDay = { day: 'Thursday', totalDays: 0, hours: [] };
    const friday: SessionLogWeeklyBreakdownDay = { day: 'Friday', totalDays: 0, hours: [] };
    const saturday: SessionLogWeeklyBreakdownDay = { day: 'Saturday', totalDays: 0, hours: [] };

    week.days.push(sunday);
    week.days.push(monday);
    week.days.push(tuesday);
    week.days.push(wednesday);
    week.days.push(thursday);
    week.days.push(friday);
    week.days.push(saturday);

    // populate hours
    week.days.forEach(day => {
      for (let i = 0; i < 24; i++) {
        day.hours.push({
          hour: i,
          totalUsers: 0
        });
      }
    })

    years.forEach(year => {
      year.months.forEach(month => {
        month.days.forEach(day => {

          // Day
          let d: SessionLogWeeklyBreakdownDay;
          switch (day.dayOfTheWeek) {
            case 1:
              d = sunday;
              break;
            case 2:
              d = monday;
              break;
            case 3:
              d = tuesday;
              break;
            case 4:
              d = wednesday;
              break;
            case 5:
              d = thursday;
              break;
            case 6:
              d = friday;
              break;
            case 7:
            default:
              d = saturday;
              break;
          }
          d.totalDays++;

          // Hours
          day.hours.forEach(hour => {
            const dayhour = d.hours.find(h => h.hour === hour.hour) || null;
            if (dayhour !== null) {
              dayhour.totalUsers += hour.users;
            }
          });

        });
      })
    });

    return week;
  }

  public getHourStringByIndex(index: number): string {
    if (index % 2 === 1) {
      return '';
    }

    const suffix = (index < 12) ? 'am' : 'pm';
    if (index === 0 || index === 12) {
      return `12 ${suffix}`
    }

    return `${index % 12} ${suffix}`
  }

  public getCellTitle(users: number, days: number, day: string, hour: number): string {
    return `${day} ${hour}:00 - ${hour}:59 | ${this.getAverageUsers(users, days)} average users`;
  }

  public getAverageUsers(users: number, days: number): number {
    return Math.floor(
      users / days
    );
  }

}
