import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { RoleApplication } from '@app/domain/role_application/model/role-application.model';
import { IRoleApplicationAPI } from '@app/domain/role_application/api/role-application-api.model';
import { RoleApplicationActivity } from '../model/role-application-activity.model';
import { RoleApplicationQuestion } from '../model/role-application-question.model';
import { UpdateRoleApplicationQuestion } from '../model/update-role-application-question.model';
import { RoleApplicationState } from '../model/role-application-state.model';
import { RoleApplicationUpdateReviewers } from '../model/role-application-update-reviewers.model';
import { RoleApplicationAddPrimaryReviewer } from '../model/role-application-add-primary-reviewer.model';
import { RoleApplicationSettings } from '../model/role-application-settings.model';
import { Page } from '@app/models/api/page.model';
import { ParentFilter } from '@app/models/api/parent-filter.model';

@Injectable()
export class RoleApplicationAPIService implements IRoleApplicationAPI {

  private readonly BASE_URL = 'api/role-application';

  constructor(private http: HttpClient) {
  }

  get(ids: number[], applicantId: number[], reviewerId: number[], states: RoleApplicationState[], includeArchived: boolean): Observable<RoleApplication[]> {
    // return of(mockRoleApplications);
    const url = `${this.BASE_URL}`;

    let params = new HttpParams();

    if (ids) {
      ids.forEach(id => {
        params = params.append('id', id);
      });
    }

    if (applicantId) {
      applicantId.forEach(id => {
        params = params.append('applicantId', id);
      });
    }

    if (reviewerId) {
      reviewerId.forEach(id => {
        params = params.append('reviewerId', id);
      });
    }

    if (states) {
      states.forEach(id => {
        params = params.append('state', id);
      });
    }

    if (includeArchived) {
      params = params.append('includeArchived', includeArchived.toString());
    }

    return this.http.get<RoleApplication[]>(url, { params: params });
  }

  // getPaginated(pagingParams: PagingParams, sortingParams: SortingParams, ids: number[], applicantId: number[], reviewerId: number[], states: RoleApplicationState[]): Observable<Page<RoleApplication>> {
  //   const url = `${this.BASE_URL}/paged`;

  //   let params = ApiUtils.createPageAndSortParams(pagingParams, sortingParams);

  //   if (ids) {
  //     ids.forEach(id => {
  //       params = params.append('id', id);
  //     });
  //   }

  //   if (applicantId) {
  //     applicantId.forEach(id => {
  //       params = params.append('applicantId', id);
  //     });
  //   }

  //   if (reviewerId) {
  //     reviewerId.forEach(id => {
  //       params = params.append('reviewerId', id);
  //     });
  //   }

  //   if (states) {
  //     states.forEach(id => {
  //       params = params.append('state', id);
  //     });
  //   }

  //   return this.http.get<Page<RoleApplication>>(url, { params: params });
  // }

  create(roleId: number): Observable<RoleApplication> {
    const url = `${this.BASE_URL}`;
    return this.http.post<RoleApplication>(url, roleId);
  }

  delete(id: number): Observable<RoleApplication> {
    const url = `${this.BASE_URL}/${id}`;
    return this.http.delete<RoleApplication>(url);
  }

  discard(id: number): Observable<RoleApplication> {
    const url = `${this.BASE_URL}/${id}/discard`;
    return this.http.get<RoleApplication>(url);
  }

  submit(id: number): Observable<RoleApplication> {
    const url = `${this.BASE_URL}/${id}/submit`;
    return this.http.get<RoleApplication>(url);
  }

  decline(id: number, declineReason: string): Observable<RoleApplication> {
    const url = `${this.BASE_URL}/${id}/decline`;
    return this.http.post<RoleApplication>(url, declineReason);
  }

  approve(id: number): Observable<RoleApplication> {
    const url = `${this.BASE_URL}/${id}/approve`;
    return this.http.post<RoleApplication>(url, null);
  }

  reopen(id: number, reopenReason: string): Observable<RoleApplication> {
    const url = `${this.BASE_URL}/${id}/reopen`;
    return this.http.post<RoleApplication>(url, reopenReason);
  }

  award(id: number, sendBackToManager: boolean): Observable<RoleApplication> {
    const url = `${this.BASE_URL}/${id}/award`;
    return this.http.post<RoleApplication>(url, sendBackToManager);
  }

  awardApprovedByAdmin(id: number): Observable<RoleApplication> {
    const url = `${this.BASE_URL}/${id}/award-approved-by-admin`;
    return this.http.post<RoleApplication>(url, null);
  }

  updateReviewers(id: number, roleApplicationAddReviewers: RoleApplicationUpdateReviewers): Observable<RoleApplication> {
    const url = `${this.BASE_URL}/${id}/update-reviewers`;
    return this.http.post<RoleApplication>(url, roleApplicationAddReviewers);
  }

  updatePrimaryReviewer(id: number, roleApplicationAddPrimaryReviewer: RoleApplicationAddPrimaryReviewer): Observable<RoleApplication> {
    const url = `${this.BASE_URL}/${id}/add-primary-reviewer`;
    return this.http.post<RoleApplication>(url, roleApplicationAddPrimaryReviewer);
  }

  getActivityForApplicationId(applicationId: number): Observable<RoleApplicationActivity[]> {
    const url = `${this.BASE_URL}/${applicationId}/activity`;
    return this.http.get<RoleApplicationActivity[]>(url);
  }

  getApplicantQuestions(applicationId: number): Observable<RoleApplicationQuestion[]> {
    const url = `${this.BASE_URL}/${applicationId}/applicant-questions`;
    return this.http.get<RoleApplicationQuestion[]>(url);
  }

  getPrimaryReviewerQuestions(applicationId: number): Observable<RoleApplicationQuestion[]> {
    const url = `${this.BASE_URL}/${applicationId}/primary-reviewer-questions`;
    return this.http.get<RoleApplicationQuestion[]>(url);
  }

  updateApplicantQuestions(applicationId: number, updatedQuestions: UpdateRoleApplicationQuestion[]): Observable<RoleApplicationQuestion[]> {
    const url = `${this.BASE_URL}/${applicationId}/applicant-questions`;
    return this.http.put<RoleApplicationQuestion[]>(url, updatedQuestions);
  }

  updatePrimaryReviewerQuestions(applicationId: number, updatedQuestions: UpdateRoleApplicationQuestion[]): Observable<RoleApplicationQuestion[]> {
    const url = `${this.BASE_URL}/${applicationId}/primary-reviewer-questions`;
    return this.http.put<RoleApplicationQuestion[]>(url, updatedQuestions);
  }

  addCommentToApplication(applicationId: number, commentText: string): Observable<RoleApplicationActivity[]> {
    const url = `${this.BASE_URL}/${applicationId}/comment`;
    return this.http.post<RoleApplicationActivity[]>(url, commentText);
  }

  getApplicationsCreatedByMyReports(): Observable<RoleApplication[]> {
    const url = `${this.BASE_URL}/managing`;
    return this.http.get<RoleApplication[]>(url);
  }

  getRoleApplicationSettings(): Observable<RoleApplicationSettings> {
    const url = `${this.BASE_URL}/settings`;
    return this.http.get<RoleApplicationSettings>(url);
  }

  updateRoleApplicationSettings(roleApplicationSettings: RoleApplicationSettings): Observable<RoleApplicationSettings> {
    const url = `${this.BASE_URL}/settings`;
    return this.http.post<RoleApplicationSettings>(url, roleApplicationSettings);
  }

  getFiltered(parentFilter: ParentFilter): Observable<Page<RoleApplication>> {
    const url = `${this.BASE_URL}/filtered`;
    return this.http.post<Page<RoleApplication>>(url, parentFilter);
  }

  getFilteredExport(parentFilter: ParentFilter): Observable<Blob> {
    const url = `${this.BASE_URL}/filtered/export`;
    return this.http.post(url, parentFilter, {responseType: 'blob'});
  }
}
