import { Component, Input, OnInit } from '@angular/core';
import { TalkingPoint } from '@app/domain/one_to_one/model/talking-point.model';

@Component({
  selector: 'app-one-to-one-talking-point-display',
  templateUrl: './one-to-one-talking-point-display.component.html',
  styleUrls: ['./one-to-one-talking-point-display.component.scss']
})
export class OneToOneTalkingPointDisplayComponent implements OnInit {

  @Input() talkingPoint: TalkingPoint;
  @Input() index?: number;

  constructor() {
    this.talkingPoint = undefined;
    this.index = undefined;
  }

  ngOnInit(): void {
  }

}
