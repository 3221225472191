import { MockService } from '@app/mock/api/mock-service';
import { HttpRequest } from '@angular/common/http';
import { PermissionService } from '@app/shared/api/interfaces/permission.service';
import { Observable, of } from 'rxjs';
import { User } from '@app/models/user/user.model';
import { sanitizeUrl } from '@app/shared/utils/helpers';
import { mockUsers } from '@app/mock/api/data/mockUsers';
import { mockUserRoles } from '@app/mock/api/data/mockUserRoles';
import { RoleName } from '@app/models/user-role.model';

export class PermissionMockService implements MockService, PermissionService {

  handleRoute(req: HttpRequest<any>): any {
    const url = sanitizeUrl(req.urlWithParams);

    const permissionKey = url.split('/')[3] as RoleName;

    switch (true) {
      case url.match(/api\/user\/permission\/\D+$/) !== null && req.method === 'GET':
        return this.getUsersWithPermission(permissionKey);
    }
  }

  getUsersWithPermission(key: RoleName): Observable<User[]> | User[] {
    switch (key) {
      case RoleName.GOAL_COMPANY:
        return of(mockUsers.filter(u => u.roles.includes(mockUserRoles[3])));
      case RoleName.SURVEY_COMPANY:
        return of(mockUsers.filter(u => u.roles.includes(mockUserRoles[4])));
      case RoleName.CAREER_COMPANY:
        return of(mockUsers.filter(u => u.roles.includes(mockUserRoles[5])));
      default:
        return of([]);
    }
  }

  // No Ops listed below
  updatePermission(key: RoleName, users: number[]): Observable<User[]> {
    return of(mockUsers);
  }
}