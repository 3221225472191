<ng-container *ngIf="!state.loading; else stateLoading;">
    <ng-container *ngIf="!state.error; else stateError;">
        <ng-content></ng-content>
    </ng-container>
</ng-container>

<ng-template #stateLoading>
    <app-loading-state [height]="stateHeight"></app-loading-state>
</ng-template>

<ng-template #stateError>
  <app-error-state [height]="stateHeight" [message]="state.errorMessage" [primaryButtonShown]="errorButtonShown" [primaryButtonText]="errorButtonText" (primaryButtonPressed)="onErrorButtonPressed($event)"></app-error-state>
</ng-template>