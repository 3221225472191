<router-outlet></router-outlet>
<ng-container *ngIf="route.children.length === 0">
  <!-- Title and tab select -->
  <div class="text-right p-bottom-15">
    <app-button-with-dropdown [text]="eGoalsMessages.CREATE_A_GOAL" (primaryAction)="openGoalCreate()">
      <div class="dropdown-button-menu-item" (click)="openGoalCreateTemplate()" *ngIf="globals.hasFeature(eCompanyFeatures.GOAL_TEMPLATE)">
        <span class="fal fa-fw fa-file-import"></span>
        <span class="m-left-5" translate>{{eGoalsMessages.USE_A_TEMPLATE}}</span>
      </div>
      <div class="dropdown-button-menu-item" (click)="openGoalCreateCopying()">
        <span class="fal fa-fw fa-copy"></span>
        <span class="m-left-5" translate>{{eGoalsMessages.COPY_A_GOAL}}</span>
      </div>
      <!-- <div class="dropdown-button-menu-divider"></div>
      <div class="dropdown-button-menu-item" (click)="openGoalCreateOld()">
        <span class="fal fa-fw fa-history"></span>
        <span class="m-left-5" translate>{{eGoalsMessages.CLASSIC_VIEW}}</span>
      </div> -->
    </app-button-with-dropdown>
  </div>

  <ng-container [ngSwitch]="tabSelected">
    <app-state [state]="state">
      <ng-container *ngIf="!state.loading && !state.error">
          <!-- Other pages -->
          <ng-container *ngIf="tabSelected !== eGoalOverviewPages.DRAFTS && tabSelected !== eGoalOverviewPages.TAGS">
            <ng-container *ngIf="statsState.isSuccessful()">
              <app-goal-stats [stats]="goalStats"></app-goal-stats>
            </ng-container>
            <ng-container *ngIf="statsState.isLoading()">
              <div class="card text-center">
                <div class="content">
                  <span class="fas fa-spin fa-spinner fa-3x fa-fw"></span>
                </div>
              </div>
            </ng-container>
          </ng-container>
    
          <div class="card">
            <!-- Navigation tabs -->
            <app-nav-tabs [data]="navTabData" [selected]="tabSelected" (tabChanged)="onTabChanged($event)"></app-nav-tabs>
    
            <!-- Drafts page -->
            <app-goals-overview-drafts-component *ngSwitchCase="eGoalOverviewPages.DRAFTS"></app-goals-overview-drafts-component>
    
            <!-- Tags page -->
            <app-goal-manager-tags *ngSwitchCase="eGoalOverviewPages.TAGS"></app-goal-manager-tags>

            <ng-container *ngSwitchDefault>
              <ng-container *ngIf="!state.tabChanging; else templateTabChanging;">
                <!-- Regular goal pages -->
                <app-goal-search-table #goalSearchTable
                                       [canCreateGoal]="canCreateGoal"
                                       [hiddenFilterCategories]="hiddenFilterCategories"
                                       [startDisabledColumns]="disabledColumns"
                                       [selectedFilters]="selectedFilters"
                                       (openCreateModal)="openGoalCreate()">
                </app-goal-search-table>
              </ng-container>
  
              <ng-template #templateTabChanging>
                <app-loading-state [height]="'200px'"></app-loading-state>
              </ng-template>
            </ng-container>
          </div>
        </ng-container>
    </app-state>
  </ng-container>
</ng-container>

<!-- Create modal -->
<!-- TODO: Get rid of this eventually -->
<app-goal-modal #modalCreateOld>
  <div class="app-goal-modal-header">
    <app-goals-modal-header [modal]="modalCreateOld" *ngIf="modalCreateOldShown" [headerText]="'Create a new goal'"></app-goals-modal-header>
  </div>
  <div class="app-goal-modal-body" *ngIf="modalCreateOldShown">
    <app-goals-create-old [modal]="modalCreateOld" [mode]="eGoalCreateFormMode.CREATE" [startType]="createType"></app-goals-create-old>
  </div>
  <div class="app-goal-modal-sidebar" *ngIf="modalCreateOldShown">
    <app-goals-modal-sidebar [modal]="modalCreateOld"></app-goals-modal-sidebar>
  </div>
</app-goal-modal>

<!-- New goal create modal -->
<ng-container *ngIf="!createHidden">
  <app-modal [showCloseButton]="false" [minWidth]="'200px'" [maxWidth]="'900px'" [width]="'80%'" [sidePanel]="true" [confirmClosing]="goalCreateComponent.formChanged" [confirmCloseModalSettings]="confirmCloseSettings" (onConfirmCloseModalResponse)="onCloseCreateResponse($event)" #modalCreate>
    <app-goal-create [initGoalType]="createType" [initPageView]="createView" [modal]="modalCreate" (requestCloseModal)="hideCreateModal()" (requestOpenModal)="showCreateModal()" #goalCreateComponent></app-goal-create>
  </app-modal>
</ng-container>