import { Injectable } from '@angular/core';
import { MockService } from '@app/mock/api/mock-service';
import { HttpRequest } from '@angular/common/http';
import { QuestionBankService } from '@app/shared/api/interfaces/question-bank.service';
import {
  CreateQuestionBankQuestion,
  QuestionBankQuestion,
  UpdateQuestionBankQuestion,
} from '@app/models/evaluation/question-bank-question.model';
import { Observable } from 'rxjs';
import { sanitizeUrl } from '@app/shared/utils/helpers';
import { mockQuestionBankQuestions } from '@app/mock/api/data/mockQuestionBankQuestions';

@Injectable()
export class QuestionBankMockService implements MockService, QuestionBankService {
    handleRoute(req: HttpRequest<any>): any {
        const url = sanitizeUrl(req.urlWithParams);

        switch (true) {
            case url.endsWith('api/question-bank/questions'):
                return this.getQuestionBankQuestions();
        }
    }

    getQuestionBankQuestions(): Observable<QuestionBankQuestion[]> | QuestionBankQuestion[] {
        return mockQuestionBankQuestions;
    }

    // No Ops listed below
    createQuestionBankQuestion(createQuestionBankQuestion: CreateQuestionBankQuestion): Observable<QuestionBankQuestion> {
        return undefined;
    }

    updateQuestionBankQuestion(questionBankQuestionId: number, updateQuestionBankQuestion: UpdateQuestionBankQuestion): Observable<QuestionBankQuestion> {
        return undefined;
    }

}