export const CommonMessages = {
  ALL: 'common.all',
  ACTIONS: 'common.actions',
  WARNING_PROMPT: 'common.warning-prompt',
  CONFIRM: 'common.confirm',
  SUBMIT: 'common.submit',
  CANCEL: 'common.cancel',
  CANCELLED: 'common.cancelled',
  OK: 'common.ok',
  CLEAR_SELECTION: 'common.clear-selection',
  FORM_REQUIRED: 'common.form-required',
  FORM_CHARACTER_LENGTH_BETWEEN: 'common.form-character-length-between', // minCharacters, maxCharacters
  SELECT_PROMPT: 'common.select-prompt',
  OPTIONAL: 'common.optional',
  SAVE: 'common.save',
  EXPAND_SIDEBAR: 'common.expand-sidebar',
  COLLAPSE_SIDEBAR: 'common.collapse-sidebar',
  MORE_ACTIONS: 'common.more-actions',
  PREVIOUS: 'common.previous',
  NEXT: 'common.next',
  COMPANY: 'common.company',
  SITE: 'common.site',
  ARCHIVED: 'common.archived',
  COMPLETE: 'common.complete',
  OTHER: 'common.other',
  UNKNOWN: 'common.unknown',
  ANSWER: 'common.answer',
  STRONGLY_DISAGREE: 'common.likert-response.strongly-disagree',
  DISAGREE: 'common.likert-response.disagree',
  NEUTRAL: 'common.likert-response.neutral',
  AGREE: 'common.likert-response.agree',
  STRONGLY_AGREE: 'common.likert-response.strongly-agree',
  COMMENT: 'common.comment',
  RETURN_TO_DASHBOARD_QUESTION: 'common.return-to-dashboard-question',
  RETURN_TO_DASHBOARD: 'common.return-to-dashboard',
  FILE_NAME: 'common.file-name',
  DOWNLOAD: 'common.download',
  DELETE: 'common.delete',
  DELETING: 'common.deleting',
  UPLOAD_FILE: 'common.upload-file',
  FORM_INVALID_VALUE: 'common.form-invalid-value',
  FORM_MIN_VALUE: 'common.form-min-value',
  MONDAY: 'common.monday',
  TUESDAY: 'common.tuesday',
  WEDNESDAY: 'common.wednesday',
  THURSDAY: 'common.thursday',
  FRIDAY: 'common.friday',
  SATURDAY: 'common.saturday',
  SUNDAY: 'common.sunday',
  EDIT: 'common.edit',
  UPDATE: 'common.update',
  EVERY: 'common.every',
  UNSAVED_CHANGES: 'common.unsaved-changes',
  UNSAVED_CHANGES_WARNING: 'common.unsaved-changes-warning',
  LEAVE: 'common.leave',
  STAY: 'common.stay',
  NOT_SET: 'common.not-set',
  LEARN_MORE: 'common.learn-more',
  DUE_IN: 'common.due-in',
  DUE_TODAY: 'common.due-today',
  OVERDUE_BY: 'common.overdue-by',
  YES: 'common.yes',
  NO: 'common.no',
  ARCHIVE: 'common.archive',
  UNARCHIVE: 'common.unarchive',
  CANCEL_EDIT: 'common.cancel-edit',
  CANCEL_EDIT_TOOLTIP: 'common.cancel-edit-tooltip',
  SAVE_CHANGES: 'common.save-changes',
  SAVE_CHANGES_TOOLTIP: 'common.save-changes-tooltip',
  FORM_MIN_CHARACTER_VALUE: 'common.form-min-character-value', // Params: minValue
  FORM_MAX_CHARACTER_VALUE: 'common.form-max-character-value', // Params: maxValue
  DATE_CREATED: 'common.date-created',
  AN_ERROR_OCCURRED: 'common.an-error-occurred',
  EMPTY_STATE: 'common.empty-state',
  TO: 'common.to',
  CREATE: 'common.create',
  NO_COMPETENCIES: 'common.no-competencies', // Params: competencyPlural
  UPDATE_DRAFT: 'common.update-draft',
  SAVE_DRAFT: 'common.save-draft',
  CANNOT_BE_IN_THE_PAST: 'common.cannot-be-in-the-past',
  CATEGORY: 'common.category',
  COMPANY_VALUE: 'common.company-value',
  FORM_PROVIDE_DETAIL: 'common.form-provide-detail',
  TOO_MUCH_FORMATTING: 'common.too-much-formatting',
  ACTION_CANNOT_BE_UNDONE: 'common.action-cannot-be-undone',
  NAME: 'common.name',
  DESCRIPTION: 'common.description',
  POSITION: 'common.position',
  SEARCH_PEOPLE: 'common.search-people',
  SEARCH_BY_NAME: 'common.search-by-name',
  ADD_SELECTED: 'common.add-selected',
  NO_SEARCH_USERS: 'common.no-search-users',
  NO_SEARCH_DEPARTMENTS: 'common.no-search-departments',
  NO_SEARCH_SITES: 'common.no-search-sites',
  NO_SEARCH_PEOPLE: 'common.no-search-people',
  NO_SEARCH_ROLES: 'common.no-search-roles',
  NO_SEARCH_COMPETENCIES: 'common.no-search-competencies', // Params: competencyPlural
  NO_SEARCH_INTERESTS: 'common.no-search-interests',
  NO_SEARCH_ACCESS_LEVEL: 'common.no-search-access-level',
  NO_SEARCH_TASKS: 'common.no-search-tasks',
  NO_SEARCH_GOALS: 'common.no-search-goals',
  CREATOR: 'common.creator',
  NONE: 'common.none',
  NONE_SELECTED: 'common.none-selected',
  CREATED: 'common.created',
  UPDATED: 'common.updated',
  BULK_ACTIONS: {
    NAME: 'common.bulk-actions.name',
    HEADING: 'common.bulk-actions.heading',
    DELETE_SELECTED: 'common.bulk-actions.delete-selected',
    EDIT_SELECTED: 'common.bulk-actions.edit-selected',
    ARCHIVE_SELECTED: 'common.bulk-actions.archive-selected',
    INVITE_SELECTED: 'common.bulk-actions.invite-selected',
    WARNING_CANCEL_BULK_ACTIONS: 'common.bulk-actions.warning-cancel-bulk-actions',
  },
  CREATING: 'common.creating',
  EDITING: 'common.editing',
  UNARCHIVING: 'common.unarchiving',
  SELECT: 'common.select',
  DESELECT: 'common.deselect',
  SELECT_ALL: 'common.select-all',
  DESELECT_ALL: 'common.deselect-all',
  SELECT_VISIBLE: 'common.select-visible',
  DESELECT_VISIBLE: 'common.deselect-visible',
  SELECT_FILTERED: 'common.select-filtered',
  DESELECT_FILTERED: 'common.deselect-filtered',
  GET_STARTED: 'common.get-started',
  NEVER: 'common.never',
  LAST_UPDATED: 'common.last-updated',
  CLOSE: 'common.close',
  ACCESS_LEVELS: {
    USER: 'common.access-levels.user',
    MANAGER: 'common.access-levels.manager',
    ADMIN: 'common.access-levels.admin',
    FRANKLI_ADMIN: 'common.access-levels.frankli-admin'
  },
  USER_STATE: {
    ARCHIVED: 'common.user-state.archived',
    ACCESS_REVOKED: 'common.user-state.access-revoked',
    NOT_INVITED: 'common.user-state.not-invited',
    INVITED: 'common.user-state.invited',
    FULL: 'common.user-state.full'
  },
  ACCESS_REVOKED: 'common.access-revoked',
  NO_CHANGE: 'common.no-change',
  NO_MANAGER: 'common.no-manager',
  NO_SECONDARY_MANAGER: 'common.no-secondary-manager',
  START_TYPING_TO_SEARCH: 'common.start-typing-to-search',
  START_TYPING_TO_SEARCH_OR_ENTER: 'common.start-typing-to-search-or-enter',
  TRY_AGAIN: 'common.try-again',
  EXPAND: 'common.expand',
  COLLAPSE: 'common.collapse',
  EXPAND_COLLAPSE: 'common.expand-collapse',
  COPY: 'common.copy',
  MUST_BE_VALID_EMAIL: 'common.must-be-valid-email',
  ADD_FROM_FILE: 'common.add-from-file',
  DOWNLOAD_TEMPLATE: 'common.download-template',
  TEXTAREA_PLACEHOLDER: 'common.textarea-placeholder',
  GRADE: 'common.grade',
  FILE_SIZE_LESS_THAN_MB: 'common.file-size-less-than-MB', // size
  INTEREST_TYPE: {
    PERSONAL: 'common.interest-type.personal',
    PROFESSIONAL: 'common.interest-type.professional',
    ALL: 'common.interest-type.all'
  },
  APPROVE: 'common.approve',
  DECLINE: 'common.decline',
  FORM_PASSWORD_SPECIAL_CHARACTERS: 'common.form-password-special-characters',
  HIDE_BANNER: 'common.hide-banner',
  LIBRARY: 'common.library',
  SELECTED: 'common.selected',
  REMOVE: 'common.remove',
  CLEAR_FILTERS: 'common.clear-filters',
  MORE_DETAILS: 'common.more-details',
  ADD_SKILLS: 'common.add-skills',
  GO_BACK: 'common.go-back',
  PLEASE_REVIEW_OPTIONS: 'common.please-review-options',
  PLEASE_GIVE_UNIQUE_NAME: 'common.please-give-unique-name',
  ROLE_PENDING: 'common.role-pending',
  ADD: 'common.add',
  DISCARD: 'common.discard',
  EXPIRED: 'common.expired',
  EVENT_COUNT_SINGULAR: 'common.event-count-singular',
  EVENT_COUNT_PLURAL: 'common.event-count-plural',
  EVENT_COUNT(count: number): string {
    return count === 1 ? this.EVENT_COUNT_SINGULAR : this.EVENT_COUNT_PLURAL;
  },
  CURRENT_SELECTION: 'common.current-selection',
  COMMENT_COUNT_SINGULAR: 'common.comment-count-singular',
  COMMENT_COUNT_PLURAL: 'common.comment-count-plural',
  COMMENT_COUNT(count: number): string {
    return count === 1 ? this.COMMENT_COUNT_SINGULAR : this.COMMENT_COUNT_PLURAL;
  },
  CTA_WRITE_COMMENT: 'common.cta-write-comment',
  ADD_OPTION: 'common.add-option',
  PREVIEW: 'common.preview',
};
