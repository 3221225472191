import { Injectable } from '@angular/core';
import { NotifyUtils } from '@app/shared/utils/notify.utils';
import { Observable } from 'rxjs';
import { VisionStatementMessages } from '../locale/vison-statement.messages';
import { CreateVisionStatement } from '../model/create-vision-statement.model';
import { VisionStatement } from '../model/vision-statement.model';
import { VisionStatementAPIService } from './vision-statement-api.service';

@Injectable({
  providedIn: 'root'
})
export class VisionStatementBusinessService {

  updating: boolean;

  constructor(
    private visionAPI: VisionStatementAPIService,
    private notifyUtils: NotifyUtils
  ) {
    this.updating = false;
  }

  private startUpdating() {
    this.updating = true;
  }

  private stopUpdating() {
    this.updating = false;
  }

  get(): Observable<VisionStatement> {
    return this.visionAPI.getVision();
  }

  set(visionStatement: CreateVisionStatement): Promise<VisionStatement> {
    if (this.updating) { return new Promise((resolve, reject) => reject()); }
    this.startUpdating();

    return this.visionAPI.setVision(visionStatement).toPromise()
      .then(res => {
        this.notifyUtils.notify(VisionStatementMessages.SUCCESS_SET_VISION);
        return res;
      })
      .finally(() => {
        this.stopUpdating();
      });
  }

  delete(): Promise<VisionStatement> {
    if (this.updating) { return new Promise((resolve, reject) => reject()); }
    this.startUpdating();

    return this.visionAPI.deleteVision().toPromise()
      .then(res => {
        this.notifyUtils.notify(VisionStatementMessages.SUCCESS_DELETE_VISION);
        return res;
      })
      .finally(() => {
        this.stopUpdating();
      });
  }
}
