import { Component, Input } from '@angular/core';
import { Globals } from '@app/shared/globals/globals';

export enum IconHoverColor {
  RED = 'RED',
  YELLOW = 'YELLOW',
  GREEN = 'GREEN',
  NAVY = 'NAVY'
}

@Component({
  selector: 'app-table-action-icon',
  templateUrl: './table-action-icon.component.html',
  styleUrls: ['./table-action-icon.component.scss']
})
export class TableActionIconComponent {

  @Input() icon: string;
  @Input() iconStyle: string;
  @Input() hoverColor: IconHoverColor;
  @Input() boxSize: string;
  @Input() iconSize: string;
  @Input() invertColours: boolean;
  @Input() flipIcon: boolean;

  @Input() tooltipText?: string;
  @Input() tooltipPlacement: string;
  @Input() tooltipContainer: string;
  @Input() tooltipAdaptivePosition: boolean;

  @Input() loading: boolean;

  constructor(
    public globals: Globals
  ) {
    this.hoverColor = IconHoverColor.GREEN;
    this.icon = 'fa-question-circle';
    this.iconStyle = 'fal';
    this.boxSize = '30px';
    this.iconSize = '14px';

    this.tooltipText = undefined;
    this.tooltipPlacement = 'bottom';
    this.tooltipContainer = 'body';
    this.tooltipAdaptivePosition = true;
    this.invertColours = false;
    this.flipIcon = false;

    this.loading = false;
  }

  onClickIcon(event: MouseEvent): void {
    // Might need this later
    // this.onClick.emit(event);
  }
}
