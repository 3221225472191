<div class="container-evaluation-display" *ngIf="evaluation">
  <div class="card no-margin">
    <div class="content">
      <h6 class="standard-heading">
        <span>{{evaluation.publishedAt | date}}</span>
        <span class="m-right-5"></span>
        <span>{{evaluation.scorecard}}</span>
      </h6>
      <div>{{evaluation.mode}}</div>
      <!-- <div>{{evaluation.status}}</div> -->
      
      <div class="row-outcome-score">
        <div [ngClass]="{
          'text-success': evaluation.outcome === 'Pass',
          'text-danger': evaluation.outcome === 'Fail'
        }">
          <strong>{{evaluation.outcomeName}}</strong>
          <ng-container *ngIf="(evaluation.outcome === 'Fail') && (evaluation.numOfAutofails > 0)">
            <span class="m-right-5"></span>
            <span>({{evaluation.numOfAutofails}} auto-fails)</span>
          </ng-container>
        </div>
        <div class="evaluation-quality-score">
          <span>{{evaluation.qualityScore | number: '0.2-2'}}%</span>
        </div>
      </div>

      <hr class="row"/>

      <div>
        <small>
          <span>ID:</span>
          <span>&nbsp;</span>
          <span>{{evaluation.evaluationId}}</span>
          <span>&nbsp;</span>
          <span>(</span>
          <a class="clickable" (click)="navigateToEvaluation()">
            <span>Open in EvaluAgent</span>
          </a>
          <span>)</span>
        </small>
      </div>
    </div>
  </div>
</div>