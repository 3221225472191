import { Component, ElementRef, EventEmitter, forwardRef, Input, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { debounceTime, map } from 'rxjs/operators';
import { Goal } from '@app/models/goals/goal.model';
import { GoalsAPIService } from '@app/shared/api/goals.api.service';
import { staticGoalPagingParams, staticGoalSortingParams } from '@app/shared/utils/api-filter/static-goal-params';
import { GoalType } from '@app/models/goals/goal-type.enum';
import { ChildFilter } from '@app/models/api/child-filter.model';
import { FilterOperator } from '@app/models/api/filter-operator.enum';
import { ParentFilter } from '@app/models/api/parent-filter.model';
import { activeGoalChildFilter } from '@app/shared/utils/api-filter/common-goal-filters';
import { CriterionOperator } from '@app/models/api/criterion-operator.enum';
import { GoalsMessages } from '@app/goals/goals.messages';

@Component({
  selector: 'app-goal-search',
  templateUrl: './goal-search.component.html',
  styleUrls: ['./goal-search.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => GoalSearchComponent),
    multi: true,
  }]
})
export class GoalSearchComponent implements ControlValueAccessor {
  @Input() placeholder: string;
  @Input() disabled;
  @Input() maxResultsShown: number;
  @Input() customOptions: Goal[];
  @Input() scope: 'alignment' | 'all';
  @Input() alignmentGoalType?: GoalType;
  @Input() showResultsFloating?: boolean;

  @Output() onResultsChanged: EventEmitter<Goal[]>;

  @ViewChild('searchInput') searchInput?: ElementRef<HTMLInputElement>;

  searchRunning: boolean;
  searchResults: Goal[] = [];
  searchControl: FormControl;

  searchChildFilter: ChildFilter;
  parentFilter: ParentFilter;
  searchIsFocused: boolean;

  _value: Goal;
  onChange = (_: any) => {};
  onTouched = () => {};

  get value(): Goal {
    return this._value;
  }

  set value(val: Goal) {
    if (!this.disabled) {
      this._value = val;
      this.onChange(val);
    }
  }

  constructor(private goalsAPIService: GoalsAPIService) {
    this.scope = 'all';
    this.placeholder = GoalsMessages.SEARCH_FOR_GOAL_BY_OBJECTIVE;

    this.customOptions = [];

    this.maxResultsShown = 3;
    
    this.disabled = false;
    this.searchRunning = false;
    this.searchIsFocused = false;
    this.showResultsFloating = true;

    this.onResultsChanged = new EventEmitter<Goal[]>();

    this.searchChildFilter = {
      operator: FilterOperator.AND,
      filterCriteria: []
    };

    this.parentFilter = {
      operator: FilterOperator.AND,
      childFilters: [activeGoalChildFilter, this.searchChildFilter]
    };

    this.searchControl = this.initSearchControl();
  }

  writeValue(value: Goal): void {
    this._value = value;
  }

  // Register callbacks
  registerOnChange(fn: (_: any) => {}): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  initSearchControl(): FormControl {
    const searchControl = new FormControl('', []);

    searchControl.valueChanges
      .pipe(
        map(sarg => {
          this.searchRunning = true;
          sarg = sarg.toLocaleLowerCase();
          return sarg;
        }),
        debounceTime(500)
      )
      .subscribe(sarg => this.onSearchChange(sarg));

    return searchControl;
  }

  onSearchChange(sarg: string): void {
    if (sarg === '') {
      this.updateSearchResults([]);
      return;
    }
    this.searchGoals(sarg);
  }

  searchGoals(sarg: string): void {
    switch(this.scope) {
      case 'alignment':
        this.searchGoalsAlignment(sarg);
        break;
      case 'all':
        this.searchGoalsAll(sarg);
        break;
    }
  }

  searchGoalsAlignment(sarg: string): void {
    if (!this.alignmentGoalType) {
      this.updateSearchResults([]);
      this.searchRunning = false;
      return;
    }

    this.goalsAPIService.getAlignmentGoalsByGoalType(staticGoalPagingParams, staticGoalSortingParams, this.alignmentGoalType, sarg)
      .subscribe(goalPage => {
        this.updateSearchResults(goalPage.content);
        this.searchRunning = false;
      });
  }

  searchGoalsAll(sarg: string): void {
    this.searchChildFilter.filterCriteria = [
      {
        field: 'title',
        operator: CriterionOperator.LIKE,
        value: sarg
      }
    ];

    this.goalsAPIService.searchGoals(staticGoalPagingParams, staticGoalSortingParams, this.parentFilter)
      .subscribe(goalPage => {
        this.updateSearchResults(goalPage.content);
        this.searchRunning = false;
      }
      );
  }

  updateSearchResults(results: Goal[]): void {
    results = results.slice(0,this.maxResultsShown);

    this.searchResults = results;
    this.onResultsChanged.emit(results);
  }

  doSelectGoal(goal: Goal): void {
    this.value = goal;
    this.searchControl.setValue('', { emitEvent: false });
    this.onSearchChange('');
    this.focusSearchInput();
  }

  doDeselectGoal(_goal: Goal): void {
    this.value = null;
  }

  focusSearchInput(): void {
    if (this.searchInput) {
      this.searchInput.nativeElement.focus();
    }
  }

  onFocusSearch(): void {
    this.searchIsFocused = true;

    // If focused and nothing is selected, search for empty string so that all goals are shown
    if (!this.value && (!this.searchControl.value || this.searchControl.value === '')) {
      this.searchGoals('');
    }
  }

  onBlurSearch(): void {
    this.searchIsFocused = false;
  }
}
