import moment from 'moment';
import { VincereTokenServerSide } from './vincere-token-server-side.model';

export class VincereToken {

    id: number;
    tenantId: string;
    apiKey: string;
    clientId: string;
    connected: boolean
    created: Date;
    error: string | null;

    constructor(vincereTokenServerSide: VincereTokenServerSide) {
        this.id = vincereTokenServerSide.id;
        this.tenantId = vincereTokenServerSide.tenantId;
        this.apiKey = vincereTokenServerSide.apiKey;
        this.clientId = vincereTokenServerSide.clientId;
        this.connected = vincereTokenServerSide.connected;
        this.created = moment.utc(vincereTokenServerSide.created).toDate();
        this.error = vincereTokenServerSide.error;
    }

}