import { Injectable } from '@angular/core';
import { LocationDetailsDto } from '@app/domain/settings/model/location-details.model';
import { Globals } from '@app/shared/globals/globals';
import { NotifyUtils } from '@app/shared/utils/notify.utils';
import { Observable } from 'rxjs';
import { LocationDetailsAPIService } from '../../api/location-details/location-details-api.service';
import { SettingsMessages } from '../../locale/settings.messages';

@Injectable({
  providedIn: 'root'
})
export class LocationDetailsBusinessService {

  private updatingLocationDetails: boolean;

  constructor(
    private locationDetailsAPIService: LocationDetailsAPIService,
    private notifyUtils: NotifyUtils,
    public globals: Globals
  ) {
    this.updatingLocationDetails = false;
  }

  get(): Observable<LocationDetailsDto> {
    return this.locationDetailsAPIService.get();
  }

  update(userLocationDetails: LocationDetailsDto): Promise<LocationDetailsDto> {
    if (this.updatingLocationDetails) { return new Promise((resolve, reject) => reject() ); }
    this.updatingLocationDetails = true;

    return this.locationDetailsAPIService.update(userLocationDetails).toPromise()
      .then(res => {
        this.notifyUtils.notify(SettingsMessages.SUCCESS_PROFILE_UPDATED);
        this.globals.user.homeAddress = res.homeAddress;
        this.globals.user.officeLocation = res.officeLocation;
        return res;
      })
      .finally(() => {
        this.updatingLocationDetails = false;
      });
  }
}
