import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { IsActiveMatchOptions, Router } from '@angular/router';
import { Globals } from '@app/shared/globals/globals';

export interface NavTabData {
  label: string;
  items: NavTabItemData[];
  iconsEnabled?: boolean;
}

export interface NavTabItemData {
  identifier: any;
  title: string;
  description?: string;
  icon?: string;
  condition: () => boolean;
  counter?: () => number;
  onClick?: () => void;
  amplitude: {
    action?: string;
    category?: string;
  },
  intercomTarget?: string;
  tooltip?: string; // TODO: Add support for tooltips when needed
  routeData?: {
    path: string[],
    // queryParams: any[] // TODO: Add support for queryParams when needed
  }
}

@Component({
  selector: 'app-nav-tabs',
  templateUrl: './nav-tabs.component.html',
  styleUrls: ['./nav-tabs.component.scss']
})
export class NavTabsComponent implements OnInit, OnChanges {

  @Input() data: NavTabData;
  @Input() selected: any;
  @Input() alwaysShowTabs: boolean; // Will diplay the tabs even when there's only one
  @Input() forceMobileView: boolean;
  @Input() vertical: boolean; // Will display the nav tabs in a vertical list
  @Input() showLabel: boolean; // Will show the set label in any desktop views
  @Input() alternateTabCounter: boolean; // Will show the yellow version of the counter

  @Output() tabChanged: EventEmitter<any>;

  selectedMobile: any;

  get numberOfTabsShown(): number {
    if (!this.data) { return 0; }
    if (!this.data.items) { return 0; }

    let count = 0;
    this.data.items.forEach(tab => {
      if (tab.condition()) {
        count += 1;
      }
    });

    return count;
  }

  constructor(
    private router: Router,
    public globals: Globals
  ) {
    this.data = {
      label: '',
      items: []
    };
    this.alwaysShowTabs = false;
    this.tabChanged = new EventEmitter<any>();
    this.forceMobileView = false;
    this.vertical = false;
    this.showLabel = false;
  }

  ngOnInit() {
    this.selectedMobile = this.selected;
  }

  ngOnChanges(changes: SimpleChanges) {
    const selectedChange = changes['selected'];
    const selectedMobileChange = changes['selectedMobile'];

    // If the mobile select was changed
    if (selectedMobileChange) {
      this.selectTab(selectedMobileChange.currentValue);
      return;
    }

    // If the desktop tab was changed
    if (selectedChange) {
      this.selectedMobile = selectedChange.currentValue;
      return;
    }
  }

  selectTabMobile(tabIdentifier: any) {
    const selection = this.data.items.find(d => d.identifier.toString() === tabIdentifier.toString());

    if (selection) {
      this.selectTab(selection);
    }
  }

  selectTab(tab: NavTabItemData) {
    this.tabChanged.emit(tab.identifier);
    if (tab.onClick) {
      tab.onClick();
    }
  }

  isRouteActive(tab: NavTabItemData): boolean {
    if (!tab.routeData) { return false; }
    if (!tab.routeData.path) { return false; }

    const options: IsActiveMatchOptions = {
      paths: 'subset',
      queryParams: 'ignored',
      fragment: 'ignored',
      matrixParams: 'ignored'
    };
    return this.router.isActive(tab.routeData.path.join('/'), options);
  }
}
