import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

type SidebarCollapseValue = 'true' | 'false'; // Has to be string for localstorage

@Injectable()
export class SidebarService {
  private subjectUpdate = new Subject<string>();
  public sidebarOpen: boolean;
  public routeGuardRunning: boolean;

  public $miniSidebarToggled = new Subject<{previous: SidebarCollapseValue, next: SidebarCollapseValue}>();

  get sidebarMini(): boolean {
    return (localStorage.getItem('sidebar_collapsed') === 'true'); // This has to be a string because you can't store booleans in localStorage
  }
  set sidebarMini(collapsed: boolean) {
    const oldValue = localStorage.getItem('sidebar_collapsed') as SidebarCollapseValue;
    const newValue = collapsed.toString() as SidebarCollapseValue;
    localStorage.setItem('sidebar_collapsed', newValue);
    this.$miniSidebarToggled.next({
      previous: oldValue,
      next: newValue
    });
  }

  constructor() {
    this.sidebarOpen = false;
    this.routeGuardRunning = false;
  }

  getUpdate() {
    return this.subjectUpdate.asObservable();
  }

  sendUpdate() {
    this.subjectUpdate.next('INIT');
  }


  public openSidebar() {
    this.sidebarOpen = true;
  }

  public closeSidebar() {
    this.sidebarOpen = false;
  }

  public toggleSidebar() {
    if (this.sidebarOpen) {
      this.closeSidebar();
    } else {
      this.openSidebar();
    }
  }

  public onRouteGuardStart() {
    this.routeGuardRunning = true;
  }

  public onRouteGuardEnd() {
    this.routeGuardRunning = false;
  }
}
