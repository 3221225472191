import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Router, RouterStateSnapshot} from '@angular/router';
import {Globals} from 'app/shared/globals/globals';

/**
 * Redirects to password reset if user.passwordReset === true
 */
@Injectable()
export class PasswordResetGuard {

  constructor(
      public globals: Globals,
      private router: Router,
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (!this.globals.user.passwordReset) {
      return true;
    }

    if (this.globals.ssoProvider) {
      return true;
    }

    this.router.navigate(['/auth/set-password']);
    return false;
  }
}
