import { Injectable } from '@angular/core';
import { MockService } from '@app/mock/api/mock-service';
import { IdeaService } from '@app/shared/api/interfaces/idea.service';
import { CreateIdea, Idea } from '@app/models/feedback/idea.model';
import { HttpRequest } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { mockIdeas } from '@app/mock/api/data/mockIdeas';
import { Globals } from '@app/shared/globals/globals';
import { sanitizeUrl } from '@app/shared/utils/helpers';

@Injectable()
export class IdeaMockService implements MockService, IdeaService {
  constructor(private readonly globals: Globals) { }

  handleRoute(req: HttpRequest<any>): any {
    const url = sanitizeUrl(req.urlWithParams);

    switch (true) {
      case url.endsWith('api/idea/submitted'):
        return this.getIdeasSubmitted();
      case url.match(/api\/idea\/submitted\/\d+$/) !== null:
        return this.getIdeaSubmittedById(+url.split('/')[3]);
      case url.match(/api\/idea\/submitted\/user\/\d+$/) !== null:
        return this.getIdeaSubmittedByUserId(+url.split('/')[4]);
      case url.endsWith('api/idea/submitted/manager/me'):
        return this.getIdeasByManagerMe();
      case url.match(/api\/idea\/submitted\/manager\/id\/\d+$/) !== null:
        return this.getIdeaByManagerAndId(+url.split('/')[5]);
    }
  }

  getIdeaByManagerAndId(id: number): Observable<Idea> {
    return of(mockIdeas.find(i => i.id === id));
  }

  getIdeaSubmittedById(id: number): Observable<Idea> {
    return of(mockIdeas.find(i => i.id === id));
  }

  getIdeaSubmittedByUserId(id: number): Observable<Idea[]> {
    return of(mockIdeas.filter(i => i.senderUser.id === id));
  }

  getIdeasByManagerMe(): Observable<Idea[]> {
    return of(mockIdeas.filter(i => i.destinationUser.id === this.globals.user.id));
  }

  getIdeasSubmitted(): Observable<Idea[]> {
    return of(mockIdeas);
  }

  getSidebarIdeas(userIds: number[]): Observable<Idea[]> {
    return of(mockIdeas);
  }

  // No Ops listed below
  submitIdea(ideaItem: CreateIdea): Observable<Idea> {
    return undefined;
  }

}