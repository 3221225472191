import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import Player, {EventCallback} from "@vimeo/player";

const baseURL = 'https://player.vimeo.com/video/';
const videoUrlRegex = new RegExp(/(?!vimeo.com\/)(?!video\/)?(\d+)/);

type QueryParamBoolean = 1 | 0;
interface ITimeUpdate {
  duration: number;
  percent: number;
  seconds: number;
}

// The minimum percentage required to be watched before the video is considered as watched
const MIN_PERCENT_WATCHED = 0.8;

@Component({
  selector: 'app-vimeo-player',
  templateUrl: './vimeo-player.component.html',
  styleUrls: ['./vimeo-player.component.scss']
})
export class VimeoPlayerComponent implements OnInit {
  @ViewChild('videoFrame') videoFrame?: ElementRef<HTMLIFrameElement>;

  // Configuration
  @Input() controlsColour: string; // &color=54C6BB
  @Input() showCreator: boolean; // &byline=false
  @Input() portraitMode: boolean; // &portrait=false
  @Input() showTitle: boolean; // &title=false

  // Data
  @Input() videoURL: string;

  @Input() isMarkedWatched: boolean;
  @Output() videoMarkedWatched: EventEmitter<boolean>;

  videoID: string | null;
  queryParams: string;

  parsedURL: string;
  loading: boolean;

  player: Player;

  constructor() {
    this.isMarkedWatched = false;
    this.portraitMode = false;
    this.showCreator = false;
    this.showTitle = false;
    this.loading = true;
    
    this.videoFrame = undefined;
    this.videoURL = undefined!;
    this.player = undefined!;
    
    this.videoID = null;
    
    this.controlsColour = '54C6BB';
    this.queryParams = '';
    this.parsedURL = '';

    this.videoMarkedWatched = new EventEmitter<boolean>();
  }

  ngOnInit(): void {
    this.getData();
  }

  getData(): void {
    this.videoID = this.getVideoIDFromURL(this.videoURL);
    this.queryParams = this.getVideoQueryParams();

    if (this.videoID === null) {
      console.error('[Vimeo player] Failed to get video ID');
      return;
    }

    this.parsedURL = baseURL + this.videoID + this.queryParams;

    setTimeout(() => {
      this.loading = false;

      setTimeout(() => this.initPlayer(), 1);
    }, 1);
  }
  
  initPlayer(): void {
    if (this.videoFrame) {
      this.player = new Player(this.videoFrame.nativeElement);
      setTimeout(() => {
        const comp = this;
        this.player.on('timeupdate', this.onTimeUpdate(comp));
      }, 10000);
    }
  }

  getVideoIDFromURL(url: string): string | null {
    const regexMatch = videoUrlRegex.exec(url) || [];

    if (regexMatch && regexMatch.length > 0) {
      return regexMatch[1];
    }

    return null;
  }

  getVideoQueryParams(): string {
    let paramsArray: string[] = [];

    paramsArray = [...paramsArray, `color=${this.controlsColour}`];
    paramsArray = [...paramsArray, `byline=${this.showCreator}`];
    paramsArray = [...paramsArray, `portrait=${this.portraitMode}`];
    paramsArray = [...paramsArray, `title=${this.showTitle}`];

    return `?${paramsArray.join('&')}`;
  }

  onTimeUpdate(comp: VimeoPlayerComponent): EventCallback {
    return (val: ITimeUpdate) => {
      if (this.isMarkedWatched) { return }
  
      if (val.percent >= MIN_PERCENT_WATCHED) {
        comp.markVideoWatched();
      }
    }
  }

  markVideoWatched(): void {
    this.videoMarkedWatched.emit(true);
  }
}
