/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-case-declarations */
import { MockService } from '@app/mock/api/mock-service';
import { Goal } from '@app/models/goals/goal.model';
import { mockGoals } from '@app/mock/api/data/mockGoals';
import { Injectable } from '@angular/core';
import { HttpRequest } from '@angular/common/http';
import { Globals } from '@app/shared/globals/globals';
import { GoalService } from '@app/shared/api/interfaces/goals.service';
import { GoalDepartmentOverview } from '@app/models/goals/goal-department-overview.model';
import { GoalCommentDto, GoalProgressDto, GoalStatusDto, GoalUpdateDto } from '@app/models/goals/goal.dto';
import { DepartmentGoalOverview } from '@app/models/goals/department-goal-overview.model';
import { OfficeLocationGoalOverview } from '@app/models/goals/office-location-goal-overview.model';
import { GoalDirectReportOverview } from '@app/models/goals/goal-direct-report-overview.model';
import { GoalTemplate } from '@app/models/goals/templates/goal-template.model';
import { Observable, of } from 'rxjs';
import { mockGoalDrafts } from '@app/mock/api/data/mockGoalDrafts';
import { mockGoalTemplates } from '@app/mock/api/data/mockGoalTemplates';
import { mockUsers } from '@app/mock/api/data/mockUsers';
import { mockOrganisationalUnits } from '@app/mock/api/data/mockOrganisationalUnits';
import { mockOfficeLocations } from '@app/mock/api/data/mockOfficeLocations';
import { sanitizeUrl } from '@app/shared/utils/helpers';
import { GoalDraft } from '@app/models/goals/goal-draft.model';
import { CreateGoalDto } from '@app/models/goals/create-goal.dto';
import { GoalType } from '@app/models/goals/goal-type.enum';
import { PagingParams } from '@app/models/api/paging-params.model';
import { SortingParams } from '@app/models/api/sorting-params.model';
import { Page } from '@app/models/api/page.model';
import { UpdateGoalDraft } from '@app/models/goals/update-goal-draft';
import { GoalVisibility } from '@app/models/goals/goal-visibility.model';
import { GoalStatus } from '@app/models/goals/goal-status.model';
import { GoalCreateResponse } from '@app/models/goals/goal-create-response.model';
import { DashboardGoalsStats } from '@app/models/goals/dashboard-goal-overview.model';
import { Quarter } from '@app/models/quarter.model';
import { GoalPriority } from '@app/models/goals/goal-priority.model';

@Injectable()
export class GoalMockService implements MockService, GoalService {
  constructor(private readonly globals: Globals) { }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleRoute(req: HttpRequest<any>): any {
    const url = sanitizeUrl(req.urlWithParams);

    switch (true) {
      case url.match(/api\/goal\/\d+$/) !== null:
        const goalId = +url.split('/')[2];
        return this.getGoalById(goalId);
      case url.endsWith('api/goal/individual'):
        return this.getIndividualGoals();
      case url.endsWith('api/goal/all'):
        return this.getGoalsPublicAll();
      case url.endsWith('api/goal/completion'):
        return this.getGoalsOverviewPercentage();
      case url.endsWith('api/goal/admin'):
        return this.getAdminGoals();
      case url.endsWith('api/goal/drafts'):
        return this.getDraftGoals(null!, null!);
      case url.match(/api\/goal\/drafts\/\d+$/) !== null:
        const draftGoalId = +url.split('/')[3];
        return this.getDraftGoalById(draftGoalId);
      case url.endsWith('api/goal/template') && req.method === 'GET':
        return this.getGoalTemplates();
      case url.match(/api\/goal\/aligned\/\d+$/) !== null:
        const alignedGoalId = +url.split('/')[3];
        return this.getAlignedGoals(alignedGoalId);
      case url.match(/api\/goal\/individual\/\d+$/) !== null:
        const individualGoalUserId = +url.split('/')[3];
        return this.getIndividualGoalsByUserId(individualGoalUserId);
      case url.match(/api\/goal\/individual\/\d+\/active/) !== null:
        const activeIndividualGoalUserId = +url.split('/')[3];
        return this.getIndividualActiveGoalsByUserId(activeIndividualGoalUserId);
      case url.match(/api\/goal\/individual\/\d+\/archived/) !== null:
        const archivedIndividualGoalUserId = +url.split('/')[3];
        return this.getIndividualArchivedGoalsByUserId(archivedIndividualGoalUserId);
      case url.match(/api\/goal\/individual\/\d+\/complete/) !== null:
        const completedIndividualGoalUserId = +url.split('/')[3];
        return this.getIndividualArchivedGoalsByUserId(completedIndividualGoalUserId);
      case url.match(/api\/goal\/manager\/\d+\/\d+$/) !== null:
        const managerId = +url.split('/')[3];
        const managerGoalId = +url.split('/')[4];
        return this.managerGetGoalById(managerId, managerGoalId);
      case url.endsWith('api/goal/personal'):
        return this.getPersonalGoals();
      case url.match(/api\/goal\/personal\/\d+$/) !== null:
        const publicGoalUserId = +url.split('/')[3];
        return this.getPublicPersonalGoals(publicGoalUserId);
      case url.endsWith('api/goal/personal/active'):
        return this.getPersonalGoalsActive();
      case url.endsWith('api/goal/personal/archived'):
        return this.getPersonalGoalsArchived();
      case url.endsWith('api/goal/personal/complete'):
        return this.getPersonalGoalsComplete();
      case url.endsWith('api/goal/company'):
        return this.getCompanyGoals();
      case url.endsWith('api/goal/company/active'):
        return this.getCompanyGoalsActive();
      case url.endsWith('api/goal/company/archived'):
        return this.getCompanyGoalsArchived();
      case url.endsWith('api/goal/company/complete'):
        return this.getCompanyGoalsComplete();
      case url.endsWith('api/goal/manage/direct'):
        return this.getGoalDirectReportOverview(this.globals.user.id);
      case url.match(/api\/goal\/manage\/direct\/\d+$/) !== null:
        const teamMemberId = +url.split('/')[4];
        return this.getGoalDirectReportOverview(teamMemberId);
      case url.endsWith('api/goal/manage/direct/ceo'):
        return this.getTeamGoalsOverviewAdmin();
      case url.endsWith('api/goal/team'):
        return this.getTeamGoals();
      case url.endsWith('api/goal/team/active'):
        return this.getTeamGoalsActive();
      case url.endsWith('api/goal/team/archived'):
        return this.getTeamGoalsArchived();
      case url.endsWith('api/goal/team/complete'):
        return this.getTeamGoalsComplete();
      case url.match(/api\/goal\/team\/\d+\/active/) !== null:
        const activeTeamGoalUserId = +url.split('/')[3];
        return this.getTeamGoalsActiveByManagerId(activeTeamGoalUserId);
      case url.match(/api\/goal\/team\/\d+\/archived/) !== null:
        const archivedTeamGoalUserId = +url.split('/')[3];
        return this.getTeamGoalsArchivedByManagerId(archivedTeamGoalUserId);
      case url.match(/api\/goal\/team\/\d+\/complete/) !== null:
        const completeTeamGoalUserId = +url.split('/')[3];
        return this.getTeamGoalsCompleteByManagerId(completeTeamGoalUserId);
      case url.endsWith('api/goal/office-location'):
        return this.getOfficeLocationGoals();
      case url.endsWith('api/goal/office-location/active'):
        return this.getOfficeLocationGoalsActive();
      case url.endsWith('api/goal/office-location/archived'):
        return this.getOfficeLocationGoalsArchived();
      case url.endsWith('api/goal/office-location/complete'):
        return this.getOfficeLocationGoalsComplete();
      case url.match(/api\/goal\/office-location\/\d+$/) !== null:
        const goalOfficeLocationId = +url.split('/')[3];
        return this.getOfficeLocationGoalsByOfficeLocationId(goalOfficeLocationId);
      case url.match(/api\/goal\/office-location\/\d+\/active/) !== null:
        const activeGoalOfficeLocationId = +url.split('/')[3];
        return this.getOfficeLocationGoalsActiveByOfficeLocationId(activeGoalOfficeLocationId);
      case url.match(/api\/goal\/office-location\/\d+\/archived/) !== null:
        const archivedGoalOfficeLocationId = +url.split('/')[3];
        return this.getOfficeLocationGoalsArchivedByOfficeLocationId(archivedGoalOfficeLocationId);
      case url.match(/api\/goal\/office-location\/\d+\/complete/) !== null:
        const completedGoalOfficeLocationId = +url.split('/')[3];
        return this.getOfficeLocationGoalsCompleteByOfficeLocationId(completedGoalOfficeLocationId);
      case url.endsWith('api/goal/overview/departments'):
        return this.getDepartmentGoalsOverview(this.globals.user.organisationalUnit!.id);
      case url.match(/api\/goal\/overview\/departments\/\d+$/) !== null:
        const overviewDepartmentId = +url.split('/')[4];
        return this.getDepartmentGoalsOverview(overviewDepartmentId);
      case url.endsWith('api/goal/departments/overview'):
        return this.getDepartmentGoalOverviewOld();
      case url.endsWith('api/goal/department'):
        return this.getDepartmentGoals();
      case url.endsWith('api/goal/department/active'):
        return this.getDepartmentGoalsActive();
      case url.endsWith('api/goal/department/archived'):
        return this.getDepartmentGoalsArchived();
      case url.endsWith('api/goal/department/complete'):
        return this.getDepartmentGoalsComplete();
      case url.match(/api\/goal\/department\/\d+$/) !== null:
        const goalDepartmentId = +url.split('/')[3];
        return this.getDepartmentGoalsByDepartmentId(goalDepartmentId);
      case url.match(/api\/goal\/department\/\d+\/active/) !== null:
        const activeGoalDepartmentId = +url.split('/')[3];
        return this.getDepartmentGoalsActiveByDepartmentId(activeGoalDepartmentId);
      case url.match(/api\/goal\/department\/\d+\/archived/) !== null:
        const archivedGoalDepartmentId = +url.split('/')[3];
        return this.getDepartmentGoalsArchivedByDepartmentId(archivedGoalDepartmentId);
      case url.match(/api\/goal\/department\/\d+\/complete/) !== null:
        const completedGoalDepartmentId = +url.split('/')[3];
        return this.getDepartmentGoalsCompleteByDepartmentId(completedGoalDepartmentId);
      case url.endsWith('api/goal/oldest'):
        return this.getOldestGoal();
      case url.endsWith('api/goal/office-locations/overview'):
        return this.getOfficeLocationGoalOverview();
      case url.match(/api\/goal\/tags\/\d+\/all/) !== null:
        const goalTagId = +url.split('/')[3];
        return this.getTagGoals(null!, null!, goalTagId);
      case url.match(/api\/goal\/tags\/\d+\/active/) !== null:
        const activeGoalTagId = +url.split('/')[3];
        return this.getTagGoalsActive(activeGoalTagId);
      case url.match(/api\/goal\/tags\/\d+\/archived/) !== null:
        const archivedGoalTagId = +url.split('/')[3];
        return this.getTagGoalsArchived(archivedGoalTagId);
      case url.match(/api\/goal\/tags\/\d+\/complete/) !== null:
        const completedGoalTagId = +url.split('/')[3];
        return this.getTagGoalsComplete(completedGoalTagId);
    }
  }

  getIndividualGoals(): Observable<Goal[]> {
    return of(mockGoals.filter(g => g.owners.map(o => o.id).includes(this.globals.user.id)));
  }

  getAdminGoals(): Observable<Array<Goal>> {
    return of(mockGoals);
  }

  getAlignedGoals(id: number): Observable<Array<Goal>> {
    return of(mockGoals.filter(g => g.alignment === id));
  }

  getCompanyGoals(): Observable<Array<Goal>> {
    return of(mockGoals.filter(g => g.type === GoalType.COMPANY));
  }

  getCompanyGoalsActive(): Observable<Array<Goal>> {
    return of(mockGoals.filter(g => g.type === GoalType.COMPANY && !g.archived && !g.complete));
  }

  getCompanyGoalsArchived(): Observable<Array<Goal>> {
    return of(mockGoals.filter(g => g.type === GoalType.COMPANY && g.archived));
  }

  getCompanyGoalsComplete(): Observable<Array<Goal>> {
    return of(mockGoals.filter(g => g.type === GoalType.COMPANY && g.complete));
  }

  getDepartmentGoalOverviewOld(): Observable<Array<DepartmentGoalOverview>> {
    return of(mockOrganisationalUnits.map(orgUnit => ({
      department: orgUnit,
      goals: mockGoals.filter(g => g.type === GoalType.DEPARTMENT && g.department!.id === orgUnit.id),
      archived: false
    })));
  }

  getDepartmentGoals(): Observable<Array<Goal>> {
    return of(mockGoals.filter(g => g.type === GoalType.DEPARTMENT));
  }

  getDepartmentGoalsActive(): Observable<Array<Goal>> {
    return of(mockGoals.filter(g => g.type === GoalType.DEPARTMENT && !g.archived && !g.complete));
  }

  getDepartmentGoalsActiveByDepartmentId(id: number): Observable<Array<Goal>> {
    return of(mockGoals.filter(g => g.type === GoalType.DEPARTMENT && !g.archived && !g.complete && g.department!.id === id));
  }

  getDepartmentGoalsArchived(): Observable<Array<Goal>> {
    return of(mockGoals.filter(g => g.type === GoalType.DEPARTMENT && g.archived));
  }

  getDepartmentGoalsArchivedByDepartmentId(id: number): Observable<Array<Goal>> {
    return of(mockGoals.filter(g => g.type === GoalType.DEPARTMENT && g.archived && g.department!.id === id));
  }

  getDepartmentGoalsByDepartmentId(id: number): Observable<Array<Goal>> {
    return of(mockGoals.filter(g => g.type === GoalType.DEPARTMENT && g.department!.id === id));
  }

  getDepartmentGoalsComplete(): Observable<Array<Goal>> {
    return of(mockGoals.filter(g => g.type === GoalType.DEPARTMENT && g.complete));
  }

  getDepartmentGoalsCompleteByDepartmentId(id: number): Observable<Array<Goal>> {
    return of(mockGoals.filter(g => g.type === GoalType.DEPARTMENT && g.complete && g.department!.id === id));
  }

  getDepartmentGoalsOverview(id: number): Observable<GoalDepartmentOverview> {
    return of(this.getDepartmentGoalsOverviewObject(id));
  }

  getDepartmentGoalsOverviewObject(id: number): GoalDepartmentOverview {
    const parentDepartment = mockOrganisationalUnits.find(o => o.id === id);
    const cumulativeGoals = mockGoals.filter(g => g.type === GoalType.DEPARTMENT && g.department!.id === parentDepartment!.id);
    const goalCountSelf = cumulativeGoals.length;
    const childDepartments = mockOrganisationalUnits.filter(o => o.parentOrg && o.parentOrg.id === parentDepartment!.id);
    const overviews: GoalDepartmentOverview[] = [];

    for (const childDepartment of childDepartments) {
      cumulativeGoals.push(...mockGoals.filter(g => g.type === GoalType.DEPARTMENT && g.department!.id === childDepartment.id));
      overviews.push(this.getDepartmentGoalsOverviewObject(childDepartment.id));
    }

    const averageAndCount = Goal.getAverageAndCount(cumulativeGoals);
    return {
      department: parentDepartment!,
      activeCount: averageAndCount[2],
      completeCount: averageAndCount[3],
      goalsCount: cumulativeGoals.length,
      goalsCountSelf: goalCountSelf,
      averageCompletion: averageAndCount[0],
      childDepartments: overviews
    };
  }

  getDraftGoalById(id: number): Observable<GoalDraft> {
    return of(mockGoalDrafts.find(gd => gd.id === id)!);
  }

  getDraftGoals(pagingParams: PagingParams, sortingParams: SortingParams): Observable<Page<GoalDraft>> {
    return of({
      first: true,
      last: false,
      empty: false,
      number: 1,
      numberOfElements: mockGoalDrafts.length,
      content: mockGoalDrafts,
      pageable: null,
      size: mockGoalDrafts.length,
      totalElements: mockGoalDrafts.length,
      totalPages: 1
    });
  }

  getGoalById(id: number): Observable<Goal> {
    return of(mockGoals.find(g => g.id === id)!);
  }

  getGoalTemplates(): Observable<Array<GoalTemplate>> {
    return of(mockGoalTemplates);
  }

  getGoalsOverviewPercentage(): Observable<Array<number>> {
    const averageAndCountPersonal = Goal.getAverageAndCount(mockGoals.filter(g =>
      [GoalType.PERSONAL_DEVELOPMENTAL, GoalType.PERSONAL_OPERATIONAL].includes(g.type) &&
            g.owners.map(o => o.id).includes(this.globals.user.id))
    );

    const averageAndCountDepartment = Goal.getAverageAndCount(mockGoals.filter(g =>
      g.type === GoalType.DEPARTMENT &&
            g.department!.id === this.globals.user.organisationalUnit!.id)
    );

    const averageAndCountCompany = Goal.getAverageAndCount(mockGoals.filter(g => g.type === GoalType.COMPANY));

    return of([
      averageAndCountPersonal[0],
      averageAndCountDepartment[0],
      averageAndCountCompany[0]
    ]);
  }

  getGoalsPublicAll(): Observable<Array<Goal>> {
    return of(mockGoals.filter(g => g.visibility === GoalVisibility.PUBLIC));
  }

  getIndividualActiveGoalsByUserId(id: number): Observable<Array<Goal>> {
    return of(mockGoals.filter(g => g.owners.map(o => o.id).includes(id) && !g.archived && !g.complete));
  }

  getIndividualArchivedGoalsByUserId(id: number): Observable<Array<Goal>> {
    return of(mockGoals.filter(g => g.owners.map(o => o.id).includes(id) && g.archived));
  }

  getIndividualCompleteGoalsByUserId(id: number): Observable<Array<Goal>> {
    return of(mockGoals.filter(g => g.owners.map(o => o.id).includes(id) && g.complete));
  }

  getIndividualGoalsByUserId(id: number): Observable<Array<Goal>> {
    return of(mockGoals.filter(g => g.owners.map(o => o.id).includes(id)));
  }

  getOfficeLocationGoalOverview(): Observable<Array<OfficeLocationGoalOverview>> {
    return of(mockOfficeLocations.map(officeLocation => ({
      officeLocation: officeLocation,
      goals: mockGoals.filter(g => g.type === GoalType.OFFICE_LOCATION && g.officeLocation!.id === officeLocation.id)
    })));
  }

  getOfficeLocationGoals(): Observable<Array<Goal>> {
    return of(mockGoals.filter(g => g.type === GoalType.OFFICE_LOCATION));
  }

  getOfficeLocationGoalsActive(): Observable<Array<Goal>> {
    return of(mockGoals.filter(g => g.type === GoalType.OFFICE_LOCATION && !g.archived && !g.complete));
  }

  getOfficeLocationGoalsActiveByOfficeLocationId(id: number): Observable<Array<Goal>> {
    return of(mockGoals.filter(g => g.type === GoalType.OFFICE_LOCATION && !g.archived && !g.complete && g.officeLocation!.id === id));
  }

  getOfficeLocationGoalsArchived(): Observable<Array<Goal>> {
    return of(mockGoals.filter(g => g.type === GoalType.OFFICE_LOCATION && g.archived));
  }

  getOfficeLocationGoalsArchivedByOfficeLocationId(id: number): Observable<Array<Goal>> {
    return of(mockGoals.filter(g => g.type === GoalType.OFFICE_LOCATION && g.archived && g.officeLocation!.id === id));
  }

  getOfficeLocationGoalsByOfficeLocationId(id: number): Observable<Array<Goal>> {
    return of(mockGoals.filter(g => g.type === GoalType.OFFICE_LOCATION && g.officeLocation!.id === id));
  }

  getOfficeLocationGoalsComplete(): Observable<Array<Goal>> {
    return of(mockGoals.filter(g => g.type === GoalType.OFFICE_LOCATION && g.complete));
  }

  getOfficeLocationGoalsCompleteByOfficeLocationId(id: number): Observable<Array<Goal>> {
    return of(mockGoals.filter(g => g.type === GoalType.OFFICE_LOCATION && g.complete && g.officeLocation!.id === id));
  }

  getOldestGoal(): Observable<Goal> {
    const oldestGoal = mockGoals.sort((a, b) => new Date(a.creationDate).getTime() - new Date(b.creationDate).getTime()).find(g => g !== undefined)!;
    return of(oldestGoal);
  }

  getPersonalGoals(): Observable<Array<Goal>> {
    return of(mockGoals.filter(g =>
      g.owners.map(o => o.id).includes(this.globals.user.id) &&
            [GoalType.PERSONAL_DEVELOPMENTAL, GoalType.PERSONAL_OPERATIONAL].includes(g.type)
    ));
  }

  getPersonalGoalsActive(): Observable<Array<Goal>> {
    return of(mockGoals.filter(g =>
      g.owners.map(o => o.id).includes(this.globals.user.id) &&
      [GoalType.PERSONAL_DEVELOPMENTAL, GoalType.PERSONAL_OPERATIONAL].includes(g.type) &&
      !g.archived && !g.complete
    ));
  }

  getPersonalGoalsArchived(): Observable<Array<Goal>> {
    return of(mockGoals.filter(g =>
      g.owners.map(o => o.id).includes(this.globals.user.id) &&
      [GoalType.PERSONAL_DEVELOPMENTAL, GoalType.PERSONAL_OPERATIONAL].includes(g.type) &&
      g.archived
    ));
  }

  getPersonalGoalsComplete(): Observable<Array<Goal>> {
    return of(mockGoals.filter(g =>
      g.owners.map(o => o.id).includes(this.globals.user.id) &&
      [GoalType.PERSONAL_DEVELOPMENTAL, GoalType.PERSONAL_OPERATIONAL].includes(g.type) &&
      g.complete
    ));
  }

  getPublicPersonalGoals(id: number): Observable<Array<Goal>> {
    return of(mockGoals.filter(g =>
      g.owners.map(o => o.id).includes(id) &&
            [GoalType.PERSONAL_DEVELOPMENTAL, GoalType.PERSONAL_OPERATIONAL].includes(g.type) &&
            g.visibility === GoalVisibility.PUBLIC
    ));
  }

  getTagGoals(pagingParams: PagingParams, sortingParams: SortingParams, id: number): Observable<Page<Goal>> {
    const goals = mockGoals.filter(g => g.tags.map(t => t.id).includes(id));
    return of({
      first: true,
      last: false,
      empty: false,
      number: 1,
      numberOfElements: goals.length,
      content: goals,
      pageable: null,
      size: goals.length,
      totalElements: goals.length,
      totalPages: 1
    });
  }

  getTagGoalsActive(id: number): Observable<Array<Goal>> {
    return of(mockGoals.filter(g => g.tags.map(t => t.id).includes(id) && !g.archived && !g.complete));
  }

  getTagGoalsArchived(id: number): Observable<Array<Goal>> {
    return of(mockGoals.filter(g => g.tags.map(t => t.id).includes(id) && g.archived));
  }

  getTagGoalsComplete(id: number): Observable<Array<Goal>> {
    return of(mockGoals.filter(g => g.tags.map(t => t.id).includes(id) && g.complete));
  }

  getTeamGoals(): Observable<Array<Goal>> {
    return of(mockGoals.filter(g => g.type === GoalType.TEAM));
  }

  getTeamGoalsActive(): Observable<Array<Goal>> {
    return of(mockGoals.filter(g => g.type === GoalType.TEAM && !g.archived && !g.complete));
  }

  getTeamGoalsActiveByManagerId(managerId: number): Observable<Array<Goal>> {
    return of(mockGoals.filter(g =>
      g.type === GoalType.TEAM &&
            g.owners.some(o => o.managerId === managerId) &&
            !g.archived && !g.complete
    ));
  }

  getTeamGoalsArchived(): Observable<Array<Goal>> {
    return of(mockGoals.filter(g => g.type === GoalType.TEAM && g.archived));
  }

  getTeamGoalsArchivedByManagerId(managerId: number): Observable<Array<Goal>> {
    return of(mockGoals.filter(g =>
      g.type === GoalType.TEAM &&
            g.owners.some(o => o.managerId === managerId) &&
            g.archived
    ));
  }

  getTeamGoalsComplete(): Observable<Array<Goal>> {
    return of(mockGoals.filter(g => g.type === GoalType.TEAM && g.complete));
  }

  getTeamGoalsCompleteByManagerId(managerId: number): Observable<Array<Goal>> {
    return of(mockGoals.filter(g =>
      g.type === GoalType.TEAM &&
      g.owners.some(o => o.managerId === managerId) &&
      g.complete
    ));
  }

  getGoalDirectReportOverview(id: number): Observable<GoalDirectReportOverview[]> {
    return of(this.getGoalDirectReportOverviewObject(id));
  }

  getGoalDirectReportOverviewObject(id: number): GoalDirectReportOverview[] {
    const manager = mockUsers.find(u => u.id === id);
    const cumulativeGoals = mockGoals.filter(g => g.owners.includes(manager!));
    // const goalCountSelf = cumulativeGoals.length;
    const overviews: GoalDirectReportOverview[] = [];

    const directReports = mockUsers.filter(u => u.managerId === manager!.id && u.id !== u.managerId);
    for (const directReport of directReports) {
      cumulativeGoals.push(...mockGoals.filter(g => g.owners.includes(directReport)));

      for (const overview of this.getGoalDirectReportOverviewObject(directReport.id)) {
        overviews.push(overview);
      }
    }

    const averageAndCount = Goal.getAverageAndCount(cumulativeGoals);

    return [
      {
        user: manager!,
        activeCount: averageAndCount[2],
        completeCount: averageAndCount[3],
        goalsCount: cumulativeGoals.length,
        averageCompletion: Math.round((averageAndCount[0] + Number.EPSILON) * 100) / 100,
        hasDirectReports: overviews.length > 0,
        depth: 0
      }
    ];
  }

  getTeamGoalsOverviewAdmin(): Observable<GoalDirectReportOverview[]> {
    const managerOverviews: GoalDirectReportOverview[] = [];
    for (const topOfOrg of mockUsers.filter(u => u.id === u.managerId)) {
      for (const overview of this.getGoalDirectReportOverviewObject(topOfOrg.id)) {
        managerOverviews.push(overview);
      }
    }
    return of(managerOverviews);
  }

  managerGetGoalById(userId: number, goalId: number): Observable<Goal> {
    return of(mockGoals.find(g => g.id === goalId)!);
  }

  // No Ops listed below
  addComment(id: number, goalCommentDto: GoalCommentDto): Observable<Goal> {
    return undefined;
  }

  createGoalTemplate(goalTemplate: GoalTemplate): Observable<GoalTemplate> {
    return undefined;
  }

  deleteComment(id: number, goalCommentDto: GoalCommentDto): Observable<Goal> {
    return undefined;
  }

  deleteDraftGoalById(id: number): Observable<GoalDraft> {
    return undefined;
  }

  deleteGoal(id: number): Observable<number> {
    return of(id);
  }

  deleteGoalTemplate(templateId: number): Observable<GoalTemplate> {
    return undefined;
  }

  createGoal(createGoalDto: CreateGoalDto): Observable<GoalCreateResponse> {
    return undefined;
  }

  createDraftGoal(createGoalDto: CreateGoalDto): Observable<GoalDraft> {
    return undefined;
  }

  updateDraftGoalById(id: number, draftGoal: UpdateGoalDraft): Observable<GoalDraft> {
    return undefined;
  }

  updateGoal(id: number, goalUpdateDto: GoalUpdateDto): Observable<Goal> {
    return undefined;
  }

  updateGoalTemplate(templateId: number, goalTemplate: GoalTemplate): Observable<GoalTemplate> {
    return undefined;
  }

  updateProgress(id: number, goalProgressDto: GoalProgressDto): Observable<Goal> {
    return undefined;
  }

  updateStatus(id: number, goalStatusDto: GoalStatusDto): Observable<Goal> {
    return undefined;
  }

  getGoals(viewerUserIds: number[], goalId: number[], ownerId: number[], type: GoalType[], status: GoalStatus[], visibility: GoalVisibility[], archived: boolean): Observable<Goal[]> {
    return of(mockGoals);
  }

  getUsersGoalCounts(): Observable<Blob> {
    return undefined;
  }

  getGoalCountDueThisYear(): Observable<number> {
    return of(0);
  }

  getDashboardWidgetDataForQuarter(quarter: Quarter): Observable<DashboardGoalsStats> {
    return of({
      quarter: quarter,
      totalGoals: 0,
      goalsPerPriority: {
        [GoalPriority.NOT_SET]: 0,
        [GoalPriority.P1]: 0,
        [GoalPriority.P2]: 0,
        [GoalPriority.P3]: 0,
        [GoalPriority.P4]: 0,
        [GoalPriority.P5]: 0
      },
      goalsPerStatus: {
        [GoalStatus.OFF_TRACK]: 0,
        [GoalStatus.PROGRESSING]: 0,
        [GoalStatus.ON_TRACK]: 0
      },
      goalsComplete: 0,
      goalsArchived: 0,
      quarterStartDate: new Date(),
      quarterEndDate: new Date()
    });
  }

}