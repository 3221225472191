<ng-container *ngIf="enrollment">
  <div class="container-absorblms-enrollment-display">
    <div class="card no-margin">
      <div class="content p-relative">
        <ng-container *ngIf="canSeeActions">
          <div class="area-actions">
            <ng-container *ngIf="enrollment.hidden; else templateNotHidden;">
              <app-table-action-icon [icon]="'fa-eye'" [tooltipText]="'Show this enrollment'" (click)="showEnrollment()"></app-table-action-icon>
            </ng-container>
            <ng-template #templateNotHidden>
              <app-table-action-icon [icon]="'fa-eye-slash'" [tooltipText]="'Hide this enrollment'" (click)="hideEnrollment()"></app-table-action-icon>
            </ng-template>
          </div>
        </ng-container>

        <div class="enrollment-heading">
          <ng-container *ngIf="enrollment.hidden">
            <span class="fal fa-fw fa-eye-slash" title="This enrollment is hidden and will not be visible to others"></span>
            <span class="m-right-5"></span>
          </ng-container>

          <span>{{enrollment.courseName}}</span>
        </div>
        <div class="enrollment-status" [ngClass]="'enrollment-status-'+enrollment.status">{{enrollment.status | underscoreToSpace}}</div>

        <div class="standard-description">Progress</div>
        <app-progress-bar [currentValue]="enrollment.progress"></app-progress-bar>
  
        <ng-container *ngIf="enrollment.score != null">
          <div>Score: {{enrollment.score}} / 100</div>
        </ng-container>
      </div>
    </div>
  </div>
</ng-container>