import { ISODateString } from '@app/shared/utils/date-string.util';
import moment from 'moment';
import { PollFrequency } from '../poll-frequency.enum';
import { PollQuestion } from '../poll-question.model';
import { Poll, PollServerSide } from '../poll.model';

export interface PollSeriesServerSide {
  id: number;
  creatorId: number;
  name: string;
  description: string | null;
  creatorShown: boolean;
  frequency: PollFrequency;
  polls: Array<PollServerSide>;
  activePollId: number | null;
  questions: Array<PollQuestion>;
  recipients: Array<number>;
  archived: boolean;
  timezone: string;
  expirationDate: ISODateString | null;
  published: boolean;
  publishDate: ISODateString;
}

export class PollSeries {
  id: number;
  creatorId: number;
  name: string;
  description: string | null;
  creatorShown: boolean;
  frequency: PollFrequency;
  polls: Array<Poll>;
  activePollId: number | null;
  questions: Array<PollQuestion>;
  recipients: Array<number>;
  archived: boolean;
  timezone: string;
  expirationDate: Date | null;
  published: boolean;
  publishDate: Date;

  constructor(pollSeriesServerSide: PollSeriesServerSide) {
    this.id = pollSeriesServerSide.id;
    this.creatorId = pollSeriesServerSide.creatorId;
    this.name = pollSeriesServerSide.name;
    this.description = pollSeriesServerSide.description;
    this.creatorShown = pollSeriesServerSide.creatorShown;
    this.frequency = pollSeriesServerSide.frequency;
    this.polls = pollSeriesServerSide.polls.map(p => new Poll(p));
    this.activePollId = (pollSeriesServerSide.activePollId === null) ? null : pollSeriesServerSide.activePollId;
    this.questions = pollSeriesServerSide.questions;
    this.recipients = pollSeriesServerSide.recipients;
    this.archived = pollSeriesServerSide.archived;
    this.timezone = pollSeriesServerSide.timezone;
    this.expirationDate = (pollSeriesServerSide.expirationDate === null) ? null : moment.utc(pollSeriesServerSide.expirationDate).toDate();
    this.published = pollSeriesServerSide.published;
    this.publishDate = moment.utc(pollSeriesServerSide.publishDate).toDate();
  }
}