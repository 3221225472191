import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CreateTempRegistrationDetails } from '@app/models/auth/registration/create-temp-registration.model';
import { TempRegistrationDetails } from '@app/models/auth/registration/temp-registration.model';
import { RegistrationService } from './interfaces/registration.service';
import { map } from 'rxjs/operators';
import { User } from '@app/models/user/user.model';

@Injectable()
export class RegistrationAPIService implements RegistrationService {
  private readonly BASE_URL = 'api/registration';

  public sentRegistration?: TempRegistrationDetails;

  constructor(
    private http: HttpClient
  ) {
    this.sentRegistration = undefined;
  }

  createRegistration(createTempRegistrationDetails: CreateTempRegistrationDetails): Observable<TempRegistrationDetails> {
    const url = `${this.BASE_URL}/create`;
    return this.http.post<TempRegistrationDetails>(url, createTempRegistrationDetails)
      .pipe(
        map(res => {
          if (res && res.id) {
            this.sentRegistration = res;
          }
          return res;
        })
      );
  }

  confirmEmailAddress(email: string): Observable<User> {
    const url = `${this.BASE_URL}/confirm`;
    return this.http.post<User>(url, email);
  }

}
