import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ModalComponent } from '@app/shared/modal/modal.component';
import { LongRunningTaskService } from '../../service/long-running-task.service';

@Component({
  selector: 'app-long-running-task-progress-bar',
  templateUrl: './long-running-task-progress-bar.component.html',
  styleUrls: ['./long-running-task-progress-bar.component.scss']
})
export class LongRunningTaskProgressBarComponent implements OnInit, OnDestroy {

  @ViewChild('modalDetails') modalDetails: ModalComponent;

  progressBarValue = 0;

  constructor(
    public longRunningTaskService: LongRunningTaskService
  ) {
    this.updateProgressBar();
  }

  ngOnInit(): void {
    this.longRunningTaskService.startPeriodicTaskCheck();
    this.longRunningTaskService.$tasksListUpdated.subscribe(() => this.updateProgressBar());
  }

  ngOnDestroy(): void {
    this.longRunningTaskService.stopPeriodicTaskCheck();
  }

  onClickProgressBar(): void {
    this.showModal();
  }

  showModal(): void {
    if (!this.modalDetails) { return; }
    this.modalDetails.show();
  }

  hideModal(): void {
    if (!this.modalDetails) { return; }
    this.modalDetails.hide();
  }

  updateProgressBar(): void {
    this.progressBarValue = this.getAverageProgress();
  }

  getAverageProgress(): number {
    const allTasks = this.longRunningTaskService.getAllTaskData();

    let totalProgress = 0;

    allTasks.forEach(task => {
      totalProgress += task.getHighestProgress();
    });

    const averageProgress = (totalProgress / allTasks.length);
    return averageProgress;
  }
}
