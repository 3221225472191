<div class="language-dropdown-container" dropdown>
    <div id="language-dropdown" aria-controls="dropdown-basic" dropdownToggle>
        <span [class]="'fi ' + flagClass"></span>
        <ng-container *ngIf="showSelectedLanguageName">
            <span>&nbsp;{{ currentLanguage | uppercase }}&nbsp;</span>
            <ng-container *ngIf="previewLanguages.includes(currentLanguage)">
                <app-pill>
                    <strong translate>{{eLanguageSelectorMessages.PREVIEW}}</strong>
                </app-pill>
            </ng-container>
        </ng-container>
  </div>

  <ul *dropdownMenu aria-labelledby="language-dropdown" role="menu" class="dropdown-menu" [class.dropdown-menu-right]="alignDropdownToRight">
    <li role="menuitem" *ngFor="let language of languages">
      <a class="dropdown-item" (click)="setLanguage(language)">
        <span [class]="'fi ' + getFlagClassForLanguage(language)"></span>
        <span>&nbsp;{{ language | uppercase }}&nbsp;</span>
        <ng-container *ngIf="previewLanguages.includes(language)">
          <app-pill>
            <strong translate>{{eLanguageSelectorMessages.PREVIEW}}</strong>
          </app-pill>
        </ng-container>
      </a>
    </li>
  </ul>
</div>