import { Component, ElementRef, forwardRef, Input, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { ControlValueAccessor, FormArray, FormControl, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { CommonMessages } from '@app/constants/common.messages';
import { GoalsMessages } from '@app/goals/goals.messages';
import { CriterionOperator } from '@app/models/api/criterion-operator.enum';
import { FilterOperator } from '@app/models/api/filter-operator.enum';
import { PagingParams } from '@app/models/api/paging-params.model';
import { ParentFilter } from '@app/models/api/parent-filter.model';
import { SortDirection } from '@app/models/api/sort-direction.enum';
import { SortingParams } from '@app/models/api/sorting-params.model';
import { TerminologyEntity } from '@app/domain/terminology/model/terminology-entity.enum';
import { CompanyFeatures } from '@app/models/company-features.model';
import { GoalPriority } from '@app/models/goals/goal-priority.model';
import { GoalStatus } from '@app/models/goals/goal-status.model';
import { GoalType } from '@app/models/goals/goal-type.enum';
import { Goal } from '@app/models/goals/goal.model';
import { IState } from '@app/models/state/state.model';
import { GoalsAPIService } from '@app/shared/api/goals.api.service';
import { Globals } from '@app/shared/globals/globals';
import { GoalUtils } from '@app/shared/utils/goal.utils';
import { debounceTime, map } from 'rxjs/operators';

interface PageState extends IState {
  searchRunning: boolean;
  // dropdownOpen: boolean;
  disabled: boolean;
  searchIsEmpty: boolean;
  showingPicker: boolean;
}

type PickerValue = (Goal | Goal[]);

export enum GoalPickerSlashCommand {
  TYPE = 'type',
  STATUS = 'status',
  PRIORITY = 'priority',
  SITE = 'site',
  DEPARTMENT = 'department'
}

interface SlashCommandMetadata {
  [key: string]: {
    command: string;
    label: string;
    description: string;
    icon: string;
  };
}

@Component({
  selector: 'app-goal-picker',
  templateUrl: './goal-picker.component.html',
  styleUrls: ['./goal-picker.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => GoalPickerComponent),
    multi: true,
  }],
})
export class GoalPickerComponent implements OnInit, ControlValueAccessor {

  public readonly VALID_SLASH_COMMANDS = Object.values(GoalPickerSlashCommand);
  public readonly SITE_SEARCH_REGEX = new RegExp(`(${this.VALID_SLASH_COMMANDS.map(c => c + ':"[^"]*"').join('|')})`, 'g');

  public readonly eGoalPickerSlashCommand = GoalPickerSlashCommand;
  public readonly eTerminologyEntity = TerminologyEntity;
  public readonly eCompanyFeatures = CompanyFeatures;
  public readonly eCommonMessages = CommonMessages;
  public readonly eGoalsMessages = GoalsMessages;
  public readonly eGoalPriority = GoalPriority;
  public readonly eGoalStatus = GoalStatus;
  public readonly eGoalUtils = GoalUtils;
  public readonly eGoalType = GoalType;

  @ViewChild('searchInput') searchInput?: ElementRef<HTMLInputElement>;
  @ViewChildren('searchParameterInput') searchParameterInputs?: QueryList<ElementRef<HTMLInputElement>>;

  @Input() searchPlaceholder: string;  
  @Input() customOptions: Goal[];
  @Input() customOptionsOnly: boolean;
  @Input() canSelectMultiple: boolean;
  @Input() blacklistedIds: number[];
  @Input() clearSearchAfterSelection: boolean;
  @Input() loseFocusAfterSelection: boolean;
  @Input() allowedGoalTypes?: GoalType[];

  state: PageState;
  searchControl: FormControl;
  searchParameters: FormArray;
  results: Goal[];
  _value: Goal[];
  searchIsFocused: boolean;

  slashCommandsSuggesting: GoalPickerSlashCommand[];
  slashCommandMetadata: SlashCommandMetadata;

  optionsGoalTypes: GoalType[] = Object.values(GoalType);
  optionsGoalStatuses: GoalStatus[] = Object.values(GoalStatus);
  optionsGoalPriorities: GoalPriority[] = Object.values(GoalPriority);

  onChange = (_: any) => {};
  onTouched = () => {};

  get value(): PickerValue {
    return (this.canSelectMultiple ? this._value : this._value[0]);
  }

  set value(v: PickerValue) {
    if (this.state.disabled) { return; }
    this.writeValue(v);
    this.onChange(v);
  }

  get searchParametersArray(): FormGroup[] {
    return this.searchParameters.controls as FormGroup[];
  }

  get hasSearchArguments(): boolean {
    if (this.searchControl.value) { return true; }
    if (this.searchParametersArray.map(sp => sp.get('value').value).join('').length > 0) { return true; }
    return false;
  }

  get hasValueSelected(): boolean {
    return this._value && this._value.length > 0;
  }

  get selectedValueIds(): number[] {
    if (!this._value || this._value.length === 0) { return []; }
    return this._value.map(v => v.id);
  }

  constructor(
    public globals: Globals,
    private goalAPIService: GoalsAPIService
  ) {
    this._value = [];
    this.results = [];
    this.blacklistedIds = [];

    this.searchPlaceholder = GoalsMessages.SEARCH_FOR_GOAL_BY_OBJECTIVE_OR_SLASH;

    this.customOptions = [];
    
    this.state = {
      loading: true,
      error: false,
      errorMessage: '',
      searchRunning: false,
      disabled: false,
      searchIsEmpty: true,
      showingPicker: false
    };

    this.clearSearchAfterSelection = true;
    this.loseFocusAfterSelection = true;
    this.customOptionsOnly = false;
    this.canSelectMultiple = false;
    this.searchIsFocused = false;

    this.allowedGoalTypes = [];
    this.slashCommandsSuggesting = [];

    this.searchControl = this.initSearchControl();
    this.searchParameters = this.initSearchParameters();
  }

  ngOnInit(): void {
    this.slashCommandMetadata = this.initSlashCommandMetadata();

    if (this.canSelectMultiple) {
      this.state.showingPicker = true;
    }
    this.state.loading = false;
  }

  initSearchControl(): FormControl {
    const formControl = new FormControl();
    formControl.valueChanges
      .pipe(
        map(sarg => {
          this.state.searchRunning = true;

          if (sarg) {
            sarg = sarg.trim().toLowerCase();
          }

          this.slashCommandsSuggesting = this.getSlashCommandsToSuggest(sarg);
          return sarg;
        }),
        debounceTime(500)
      )
      .subscribe(sarg => this.trySearch(sarg));
    
    return formControl;
  }

  initSearchParameters(): FormArray {
    return new FormArray([]);
  }

  trySearch(sarg: string): void {
    this.state.searchRunning = true;

    this.state.searchIsEmpty = !(sarg && sarg.length > 0);

    if (this.customOptionsOnly) {
      this.doSearchCustomOnly(this.customOptions, sarg);
      return;
    } else {
      this.doSearchRegular(this.customOptions, sarg);
    }

  }
  
  doSearchCustomOnly(customOptions: Goal[], sarg: string): void {
    let results = customOptions;
    if (sarg) {
      results = results.filter(s => s.title.toLowerCase().includes(sarg)); // Only matches to search
    }

    results = results.slice(0, 5); // Only first 5
    const selectedGoalIds = this._value.map(v => v.id);
    results = results.filter(r => !selectedGoalIds.includes(r.id)); // Not already selected
    results = results.filter(r => !this.blacklistedIds.includes(r.id)); // Not blacklisted

    this.results = results;
    this.state.searchRunning = false;
    this.state.loading = false;
  }

  doSearchRegular(customOptions: Goal[], sarg: string): void {
    if (sarg) {
      sarg = sarg.trim();
    }

    let matchingCustomResults = customOptions;
    if (sarg) {
      matchingCustomResults = matchingCustomResults.filter(s => s && s.title.toLowerCase().includes(sarg));
    }

    const blacklistedIdsNumeric: string[] = this.blacklistedIds.filter(bl => bl).map(bl => bl.toString());

    const parentFilter: ParentFilter = {
      operator: FilterOperator.AND,
      childFilters: [
        {
          operator: FilterOperator.AND,
          filterCriteria: [
            {
              field: 'archived',
              operator: CriterionOperator.EQUALS,
              value: 'false'
            },
            {
              field: 'completed',
              operator: CriterionOperator.EQUALS,
              value: 'false'
            }
          ]
        }
      ]
    };

    // Sites and departments have some encoding so we can tell them apart from title.
    // Anything not captured by the site and department filters should be treated as a title search.
    // They can be in any order so we enclose the site and department parameters in quotes.
    // Eg. site:"site name" department:"department name" title search

    const siteSearchParameters = this.searchParametersArray.filter(sp => sp.get('key').value === GoalPickerSlashCommand.SITE);
    if (siteSearchParameters && siteSearchParameters.length > 0) {
      siteSearchParameters.forEach(siteSearchParameter => {
        if (siteSearchParameter.get('value').value) {
          parentFilter.childFilters[0].filterCriteria.push({
            field: 'site_name',
            operator: CriterionOperator.CONTAINS,
            value: siteSearchParameter.get('value').value.trim()
          });
        }
      });
    }

    const departmentSearchParameters = this.searchParametersArray.filter(sp => sp.get('key').value === GoalPickerSlashCommand.DEPARTMENT);
    if (departmentSearchParameters && departmentSearchParameters.length > 0) {
      departmentSearchParameters.forEach(departmentSearchParameter => {
        if (departmentSearchParameter.get('value').value) {
          parentFilter.childFilters[0].filterCriteria.push({
            field: 'department_name',
            operator: CriterionOperator.CONTAINS,
            value: departmentSearchParameter.get('value').value.trim()
          });
        }
      });
    }

    const typeSearchParameters = this.searchParametersArray.filter(sp => sp.get('key').value === GoalPickerSlashCommand.TYPE);
    if (typeSearchParameters && typeSearchParameters.length > 0) {
      typeSearchParameters.forEach(typeSearchParameter => {
        if (typeSearchParameter.get('value').value) {
          parentFilter.childFilters[0].filterCriteria.push({
            field: 'type',
            operator: CriterionOperator.EQUALS,
            value: typeSearchParameter.get('value').value
          });
        }
      });
    }

    const statusSearchParameters = this.searchParametersArray.filter(sp => sp.get('key').value === GoalPickerSlashCommand.STATUS);
    if (statusSearchParameters && statusSearchParameters.length > 0) {
      statusSearchParameters.forEach(statusSearchParameter => {
        if (statusSearchParameter.get('value').value) {
          parentFilter.childFilters[0].filterCriteria.push({
            field: 'status',
            operator: CriterionOperator.EQUALS,
            value: statusSearchParameter.get('value').value
          });
        }
      });
    }

    const prioritySearchParameters = this.searchParametersArray.filter(sp => sp.get('key').value === GoalPickerSlashCommand.PRIORITY);
    if (prioritySearchParameters && prioritySearchParameters.length > 0) {
      prioritySearchParameters.forEach(prioritySearchParameter => {
        if (prioritySearchParameter.get('value').value) {
          parentFilter.childFilters[0].filterCriteria.push({
            field: 'priority',
            operator: CriterionOperator.EQUALS,
            value: prioritySearchParameter.get('value').value
          });
        }
      });
    }

    if (sarg) {
      parentFilter.childFilters[0].filterCriteria.push({
        field: 'title',
        operator: CriterionOperator.CONTAINS,
        value: sarg
      });
    }

    if (this.allowedGoalTypes && this.allowedGoalTypes.length > 0) {
      parentFilter.childFilters[0].filterCriteria.push({
        field: 'type',
        operator: CriterionOperator.IN,
        values: this.allowedGoalTypes
      });
    }

    if (blacklistedIdsNumeric && blacklistedIdsNumeric.length > 0) {
      parentFilter.childFilters[0].filterCriteria.push({
        field: 'id',
        operator: CriterionOperator.NOT_IN,
        values: blacklistedIdsNumeric
      });
    }

    const pagingParams: PagingParams = {
      pageNumber: 0,
      pageSize: 5
    };

    const sortingParams: SortingParams = {
      sortAttributes: [
        'title'
      ],
      sortDirection: SortDirection.ASC
    };

    this.goalAPIService.searchGoals(pagingParams, sortingParams, parentFilter).toPromise()
      .then(page => {
        let pageContents = page.content;
        pageContents = [...matchingCustomResults, ...pageContents ];
        const selectedGoalIds = this._value.map(v => v.id);
        pageContents = pageContents.filter(r => !selectedGoalIds.includes(r.id));
        pageContents = pageContents.filter(r => !this.blacklistedIds.includes(r.id));
        this.results = pageContents.slice(0, 5);
      })
      .finally(() => {
        this.state.searchRunning = false;
        this.state.loading = false;
      });
  }

  selectItem(goal: Goal): void {
    if (this.state.disabled) { return; }

    if (this.canSelectMultiple) {
      this.addItemToMultiplePickerValue(goal);
    } else {
      this.value = goal;
      // this.state.dropdownOpen = false;
    }

    this.blurSearchIfToggled();
    this.clearSearchIfToggled();

    this.onTouched();
    this.searchControl.updateValueAndValidity();
  }

  blurSearchIfToggled(): void {
    if (this.loseFocusAfterSelection) {
      this.blurSearchInput();
    } else {
      this.focusSearchInput();
    }
  }

  clearSearchIfToggled(): void {
    if (this.clearSearchAfterSelection) {
      this.searchControl.patchValue('');
    }
  }

  addItemToMultiplePickerValue(goal: Goal): void {
    const selectedIds = this._value.map(r => r.id);
    if (selectedIds.includes(goal.id)) { return; }
    this.value = [...this._value, goal];
  }

  removeSelectedItem(goal: Goal, event?: MouseEvent): void {
    if (!this.canSelectMultiple) { return; }
    if (event) {
      event.stopPropagation();
    }
    this.value = this._value.filter(r => r.id !== goal.id);
  }

  writeValue(v: PickerValue): void {
    if (this.canSelectMultiple) {
      v = v || [];
      this._value = v as Goal[];
    } else {
      if (v) {
        this._value = [v as Goal];
      } else {
        this._value = [];
      }
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.state.disabled = isDisabled;
    if (isDisabled) {
      this.searchControl.disable();
    } else {
      this.searchControl.enable();
    }
  }

  onFocusSearch(): void {
    this.searchIsFocused = true;
    if (this.state.disabled) { return; }

    // this.state.dropdownOpen = true;
  }

  onBlurSearch(): void {
    this.searchIsFocused = false;

    setTimeout(() => {
      if (this.searchIsFocused) { return; }
      // this.state.dropdownOpen = false;
    }, 200);
  }

  onKeyupEnter(): void {
    if (this.slashCommandsSuggesting.length > 0) {
      this.onClickSlashCommand(this.slashCommandsSuggesting[0]);
      return;
    }

    this.trySelectFirstSearchResult();
  }

  trySelectFirstSearchResult(): void {
    if (this.results.length === 0) {
      if (!this.searchControl.value) { return this.searchControl.updateValueAndValidity(); }
      return;
    } // Can't select first if there are zero results

    this.selectItem(this.results[0]);

    if (!this.canSelectMultiple) {
      this.blurSearchInput();
    }
  }

  focusSearchInput(): void {
    if (!this.searchInput) { return; }
    this.searchInput.nativeElement.focus();
    // this.state.dropdownOpen = true;
  }

  blurSearchInput(): void {
    if (!this.searchInput) { return; }
    this.searchInput.nativeElement.blur();
    // this.state.dropdownOpen = false;
  }

  onKeyupBackspace(): void {
    if (this.searchControl.value) { return; }
    if (!this.canSelectMultiple) { return; }
    if (!this.state.searchIsEmpty) { return; }
    if (this.state.searchRunning) { return; }

    // Get value
    const value = this.value as Goal[];

    if (!value) { return; }
    if (value.length === 0) { return; }

    value.pop();

    this.value = value;
    this.searchControl.updateValueAndValidity();
  }

  onKeyupEscape(): void {
    this.blurSearchInput();
  }

  clearSearchArgument(): void {
    this.searchControl.setValue('');
  }

  getSlashCommandsToSuggest(sarg: string): GoalPickerSlashCommand[] {
    if (!sarg) { return []; }
    // If sarg ends with / and any part of one of the command names, then show the menu. For example: "/s", "/si" "/sit", and "/site" are all valid
    // Get all text after the last / and check if it's a command
    const lastSlashIndex = sarg.lastIndexOf('/');
    if (lastSlashIndex === -1) { return []; }
    const lastSlashText = sarg.substring(lastSlashIndex + 1);

    const searchParameterKeysUsed: GoalPickerSlashCommand[] = this.searchParametersArray.map(sp => sp.get('key').value);

    const matches: GoalPickerSlashCommand[] = [];
    this.VALID_SLASH_COMMANDS.forEach(command => {
      // Site command is not allowed if the company doesn't have the feature
      if (command === GoalPickerSlashCommand.SITE && !this.globals.hasFeature(CompanyFeatures.GOAL_OFFICE_LOCATION)) {
        return;
      }

      // Priority command is not allowed if the company doesn't have the feature
      if (command === GoalPickerSlashCommand.PRIORITY && !this.globals.hasFeature(CompanyFeatures.GOAL_PRIORITY)) {
        return;
      }

      // Skip if the command is already used
      if (searchParameterKeysUsed.includes(command)) {
        return;
      }

      const commandSlashText = this.slashCommandMetadata[command].command;
      if (commandSlashText && commandSlashText.startsWith(lastSlashText)) {
        matches.push(command);
      }
    });
    return matches;
  }

  onClickSlashCommand(slashCommand: GoalPickerSlashCommand): void {
    // Skip if there is already a search parameter with the same key
    const existingSearchParameter = this.searchParametersArray.find(sp => sp.get('key').value === slashCommand);
    if (existingSearchParameter) {
      return;
    }

    // Generate FormGroup for search parameter
    const formGroup = this.initSearchParameterFormGroup(slashCommand);

    // Add item to serach parameters with the key being the command and the value being an empty string
    this.searchParameters.push(formGroup);

    // Remove the slash command from the search control
    const sarg = this.searchControl.value;
    const lastSlashIndex = sarg.lastIndexOf('/');
    if (lastSlashIndex === -1) { return; }

    // Update search argument to remove the slash command
    // If character before the slash is a space, remove the space as well
    if (sarg.charAt(lastSlashIndex - 1) === ' ') {
      const newSarg = sarg.substring(0, lastSlashIndex - 1);
      this.searchControl.patchValue(newSarg);
    } else {
      const newSarg = sarg.substring(0, lastSlashIndex);
      this.searchControl.patchValue(newSarg);
    }

    // Focus the newly cleared form control
    setTimeout(() => {
      const index = this.searchParameters.length - 1;
      if (this.searchParameterInputs && this.searchParameterInputs.length > 0) {
        const matchingInput = this.searchParameterInputs.get(index);
        if (matchingInput) {
          matchingInput.nativeElement.focus();
        }
      }
    }, 10);
  }

  removeSearchParameter(index: number): void {
    this.searchParameters.removeAt(index);
    this.trySearch(this.searchControl.value);
  }

  initSearchParameterFormGroup(slashCommand: GoalPickerSlashCommand): FormGroup {
    const formGroup = new FormGroup({
      key: new FormControl(slashCommand),
      value: new FormControl(null, [])
    });

    formGroup.valueChanges.subscribe(() => {
      this.trySearch(this.searchControl.value);
    });

    return formGroup;
  }

  onClickSelectionPreview(): void {
    if (!this.canSelectMultiple) {
      this.value = null;
    } else {
      this.state.showingPicker = true;
    }
  }

  onKeyupSearchControl(event: KeyboardEvent): void {
    if (event.key === 'Enter') { return this.onKeyupEnter(); }
    if (event.key === 'Backspace') { return this.onKeyupBackspace(); }
    if (event.key === 'Escape') { return this.onKeyupEscape(); }
  }

  onKeydownSearchArgument(event: KeyboardEvent, index: number): void {
    // If it's a backspace and there are no more characters to remove, remove the search argument
    if (event.key === 'Backspace' && !this.searchParametersArray[index].get('value').value) {
      this.removeSearchParameter(index);
      event.preventDefault();

      // Focus the main input
      this.focusSearchInput();
    }
  }

  getSlashCommandEnumFromCommandString(command: string): GoalPickerSlashCommand | undefined {
    return this.VALID_SLASH_COMMANDS.find(c => this.slashCommandMetadata[c].command === command);
  }

  initSlashCommandMetadata(): SlashCommandMetadata {
    const commands: SlashCommandMetadata = {
      [GoalPickerSlashCommand.SITE]: {
        command: 'site',
        label: 'Site',
        description: 'Filter by the site associated with the goal',
        icon: GoalUtils.getIconClassForGoalType(GoalType.OFFICE_LOCATION)
      },
      [GoalPickerSlashCommand.DEPARTMENT]: {
        command: 'department',
        label: 'Department',
        description: 'Filter by the department associated with the goal',
        icon: GoalUtils.getIconClassForGoalType(GoalType.DEPARTMENT)
      },
      [GoalPickerSlashCommand.TYPE]: {
        command: 'type',
        label: 'Type',
        description: 'Filter by the type assigned to the goal',
        icon: 'fas fa-tasks'
      },
      [GoalPickerSlashCommand.STATUS]: {
        command: 'status',
        label: 'Status',
        description: 'Filter by the status of the goal',
        icon: 'fas fa-check-circle'
      },
      [GoalPickerSlashCommand.PRIORITY]: {
        command: 'priority',
        label: 'Priority',
        description: 'Filter by the priority of the goal',
        icon: 'fas fa-exclamation-triangle'
      }
    };

    if (this.globals.getTerminology(TerminologyEntity.SITE)) {
      commands[GoalPickerSlashCommand.SITE].command = this.globals.getTerminology(TerminologyEntity.SITE).toLowerCase()
      commands[GoalPickerSlashCommand.SITE].label = this.globals.getTerminology(TerminologyEntity.SITE)[0].toUpperCase() + this.globals.getTerminology(TerminologyEntity.SITE).slice(1);
      commands[GoalPickerSlashCommand.SITE].description = `Filter by the ${this.globals.getTerminology(TerminologyEntity.SITE)} associated with the goal`;
    }

    if (this.globals.getTerminology(TerminologyEntity.DEPARTMENT)) {
      commands[GoalPickerSlashCommand.DEPARTMENT].command = this.globals.getTerminology(TerminologyEntity.DEPARTMENT).toLowerCase();
      commands[GoalPickerSlashCommand.DEPARTMENT].label = this.globals.getTerminology(TerminologyEntity.DEPARTMENT)[0].toUpperCase() + this.globals.getTerminology(TerminologyEntity.DEPARTMENT).slice(1);
      commands[GoalPickerSlashCommand.DEPARTMENT].description = `Filter by the ${this.globals.getTerminology(TerminologyEntity.DEPARTMENT)} associated with the goal`;
    }

    return commands;
  }
}
