import { Injectable } from '@angular/core';
import { Globals } from '@app/shared/globals/globals';
import { NotifyUtils } from '@app/shared/utils/notify.utils';
import { Observable } from 'rxjs';
import { ContactInformationAPIService } from '../../api/contact-information/contact-information-api.service';
import { SettingsMessages } from '../../locale/settings.messages';
import { ContactInformationDto } from '../../model/contact-information.model';

@Injectable({
  providedIn: 'root'
})
export class ContactInformationBusinessService {

  private updatingContactInformation: boolean;

  constructor(
    private contactInformationAPIService: ContactInformationAPIService,
    private notifyUtils: NotifyUtils,
    public globals: Globals
  ) {
    this.updatingContactInformation = false;
  }

  get(): Observable<ContactInformationDto> {
    return this.contactInformationAPIService.get();
  }

  update(userContactInformation: ContactInformationDto): Promise<ContactInformationDto> {
    if (this.updatingContactInformation) { return new Promise((resolve, reject) => reject() ); }
    this.updatingContactInformation = true;

    userContactInformation.linkedIn = (userContactInformation.linkedIn || '').replace('https://', '').replace('http://', '').replace('www.', '');
    userContactInformation.phoneNumber = (userContactInformation.phoneNumber || '').trim();
    if ((userContactInformation.twitterHandle || '').startsWith('@')) {
      this.globals.user.twitterHandle = userContactInformation.twitterHandle.substring(1);
    }

    return this.contactInformationAPIService.update(userContactInformation).toPromise()
      .then(res => {
        this.notifyUtils.notify(SettingsMessages.SUCCESS_PROFILE_UPDATED);
        this.globals.user.preferredContactMethod = res.preferredContactMethod;
        this.globals.user.phoneNumber = res.phoneNumber;
        this.globals.user.linkedIn = res.linkedIn;
        this.globals.user.twitterHandle = res.twitterHandle;
        return res;
      })
      .finally(() => {
        this.updatingContactInformation = false;
      });
  }
}
