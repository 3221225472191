import { Injectable } from '@angular/core';
import { MockService } from '@app/mock/api/mock-service';
import { HttpRequest } from '@angular/common/http';
import { sanitizeUrl } from '@app/shared/utils/helpers';
import { UserMockService } from '@app/mock/api/services/user/user.mock.service';
import { PermissionMockService } from '@app/mock/api/services/user/permission.mock.service';

@Injectable()
export class UserMockHandler implements MockService {
    constructor(
        private readonly userMockService: UserMockService,
        private readonly permissionMockService: PermissionMockService,
    ) { }

    handleRoute(req: HttpRequest<any>): any {
        const url = sanitizeUrl(req.url);

        const route = url.split('/')[2]; // Skip over 'api' and 'user' in URL

        switch (route) {
            case 'permission':
                return this.permissionMockService.handleRoute(req);
            default: // For the /user route base
                return this.userMockService.handleRoute(req);
        }
    }

}