import { ManagerReviewAccess } from '@app/models/evaluation/manager-review-access.model';
import { mockEvaluationCycles } from '@app/mock/api/data/mockEvaluationCycles';
import { mockUsers } from '@app/mock/api/data/mockUsers';
import { mockEvaluationFeedback } from '@app/mock/api/data/mockEvaluationFeedback';
import { ManagerReviewAccessState } from 'app/models/evaluation/manager-review-access-state.model';

export const mockManagerReviewAccesses: ManagerReviewAccess[] = [
    {
        id: 1,
        companyId: 1,
        adminUserId: mockUsers[0].id,
        cycleId: mockEvaluationCycles[0].id,
        subjectUser: mockUsers[2],
        subjectUserManager: mockUsers[0],
        managerReview: mockEvaluationFeedback[0],
        state: ManagerReviewAccessState.UNLOCKED,
        sharedTimestamp: null,
        viewedTimestamp: null,
        unlockedTimestamp: new Date()
    }
];
