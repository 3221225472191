import { IInterestAPI } from '@app/domain/interest/api/interest-api.model';
import { Injectable } from '@angular/core';
import { MockService } from '@app/mock/api/mock-service';
import { CreateInterestDto } from '@app/domain/interest/model/create-interest.model';
import { HttpRequest } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { mockInterests } from '@app/domain/interest/mock/interests.mock';
import { sanitizeUrl } from '@app/shared/utils/helpers';
import { UserMinimal } from '@app/models/user/user-minimal.model';
import { InterestType } from '@app/domain/interest/model/interest-type.model';
import { Interest } from '../model/interest.model';

@Injectable()
export class InterestAPIServiceMock implements MockService, IInterestAPI {

  handleRoute(req: HttpRequest<any>): any {
    const url = sanitizeUrl(req.urlWithParams);

    switch (true) {
      case url.endsWith('api/interests') && req.method === 'GET':
        return this.get(null, null, null, null, null);
    }
  }

  get(ids: number[], name: string, categories: string[], types: InterestType[], approved: boolean): Observable<Interest[]> {
    return of(mockInterests);
  }

  // No Ops listed below
  // TODO: Return undefined on demo environment
  create(createInterestDto: CreateInterestDto): Observable<Interest> {
    return of(mockInterests[0]);
  }

  update(id: number, createInterestDto: CreateInterestDto): Observable<Interest> {
    return of (mockInterests[0]);
  }

  importInterests(file: File): Observable<Interest[]> {
    return of(mockInterests);
  }

  approvePendingInterest(id: number): Observable<Interest> {
    return of(mockInterests[0]);
  }

  delete(id: number): Observable<Interest> {
    return of(mockInterests[0]);
  }

  getAllInterestCategories(): Observable<string[]> {
    return of([]);
  }

  deleteMultiple(ids: number[]): Observable<Interest[]> {
    return of(mockInterests);
  }

  getAllUsersUsingInterest(id: number): Observable<UserMinimal[]> {
    return of([]);
  }

  getSkillsForUserId(userId: number): Observable<Interest[]> {
    return of([]);
  }

  downloadCSV(): Observable<Blob> {
    return of(undefined);
  }

}