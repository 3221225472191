import { HttpErrorResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CompanyConstants } from '@app/constants/company.constants';
import { UserConstants } from '@app/constants/user.constants';
import { CreateSandboxDto } from '@app/models/company/create-sandbox.dto';
import { finalize } from 'rxjs/operators';
import { CustomerAccountAPIService } from '../api/customer-account.api.service';
import { SwalUtils } from '../utils/swal.utils';

enum FormState {
  FORM, COMPLETE
}

@Component({
  selector: 'app-request-sandbox-component',
  templateUrl: './request-sandbox.component.html',
  styleUrls: ['./request-sandbox.component.scss']
})
export class RequestSandboxComponent {
  public readonly eFormState = FormState;

  public readonly form: FormGroup;
  public formState: FormState = FormState.FORM;
  public formSubmitted: boolean = false;
  public formLoading: boolean = false;
  public get companyName(): FormControl { return this.form.controls.companyName as FormControl }
  public get firstName(): FormControl { return this.form.controls.firstName as FormControl }
  public get lastName(): FormControl { return this.form.controls.lastName as FormControl }
  public get email(): FormControl { return this.form.controls.email as FormControl }

  constructor(
    private formBuilder: FormBuilder,
    private customerAccountAPIService: CustomerAccountAPIService,
    private swalUtils: SwalUtils
  ) {
    this.form = this.formBuilder.group({
      companyName: new FormControl('', [Validators.required, Validators.maxLength(CompanyConstants.NAME_MAX_LENGTH)]),
      firstName: new FormControl('', [Validators.required, Validators.maxLength(UserConstants.FIRSTNAME.MAX)]),
      lastName: new FormControl('', [Validators.required, Validators.maxLength(UserConstants.LASTNAME.MAX)]),
      email: new FormControl('', [Validators.required, Validators.pattern(UserConstants.EMAIL.PATTERN), Validators.maxLength(UserConstants.EMAIL.MAX)])
    });
  }

  public onFormSubmit(): void {
    this.formSubmitted = true;
    if (this.form.valid) {
      this.formLoading = true;
      const createSandboxDto: CreateSandboxDto = {
        companyName: this.companyName.value,
        userFirstName: this.firstName.value,
        userLastName: this.lastName.value,
        userEmail: this.email.value
      };
      this.customerAccountAPIService.anonymousUserCreateSandbox(createSandboxDto)
        .pipe(finalize(() => this.formLoading = false))
        .subscribe(account => {
          this.formState = FormState.COMPLETE;
        }, (failure: HttpErrorResponse) => {
          this.swalUtils.displayWarning({ text: failure.error, title: 'Could not create your sandbox'});
          this.formState = FormState.FORM;
        });
    }
  }

  public resetForm(): void {
    this.form.reset();
    this.form.patchValue({
      companyName: '',
      firstName: '',
      lastName: '',
      email: ''
    });
    this.formSubmitted = false;
    this.formLoading = false;
    this.formState = FormState.FORM;
  }

}