import { Injectable } from '@angular/core';
import { MockService } from '@app/mock/api/mock-service';
import { HttpRequest } from '@angular/common/http';
import { SurveyService } from '@app/shared/api/interfaces/survey.service';
import { Survey } from '@app/models/survey/survey.model';
import { PublishedSurvey, PublishedSurveyFull } from '@app/models/survey/publish-survey.model';
import { CompletedSurveyLocations } from '@app/models/survey/completed-survey-locations.model';
import { CompletedSurvey } from '@app/models/survey/completed-survey.model';
import { ViewablePublishedSurvey } from '@app/models/survey/viewable-published-survey.model';
import { SurveyResultStats } from '@app/models/survey/result-stats.model';
import { SurveyQuestion } from '@app/models/survey/question.model';
import { Observable } from 'rxjs';
import { User } from '@app/models/user/user.model';
import { Globals } from '@app/shared/globals/globals';
import { clone, sanitizeUrl } from '@app/shared/utils/helpers';
import { mockSurveyQuestions } from '@app/mock/api/data/mockSurveyQuestions';
import { mockSurveys } from '@app/mock/api/data/mockSurveys';
import { mockCompletedSurveys } from '@app/mock/api/data/mockCompletedSurveys';
import { mockPublishedSurveys } from '@app/mock/api/data/mockPublishedSurveys';
import { mockUsers } from '@app/mock/api/data/mockUsers';
import { NextSurveyScheduleDate } from '@app/models/survey/next-survey-schedule-date.model';
import moment from 'moment';
import { SurveyRange } from '@app/models/survey/survey-range.model';
import { PublishedEnpsSummary } from '@app/models/survey/published-enps-summary.model';
import { mockPublishedEnpsSummary } from '../data/mockPublishedEnpsSummary';

@Injectable()
export class SurveyMockService implements MockService, SurveyService {
  constructor(private readonly globals: Globals) { }

  handleRoute(req: HttpRequest<any>): any {
    const url = sanitizeUrl(req.urlWithParams);
    const urlSegments = url.split('/');

    switch (true) {
      case url.endsWith('api/surveys'):
        return this.getSurveys();
      case url.match(/api\/survey\?id=\d+$/) !== null:
        const surveyId = +req.params.get('id')!;
        return this.getById(surveyId);
      case url.match(/api\/survey\/published\/\d+$/) !== null && req.method === 'GET':
        const publishedSurveyId = +urlSegments[3];
        return this.getPublishedSurveyById(publishedSurveyId);
      case url.match(/api\/survey\/enps\/\d+$/) !== null:
        const enpsSurveyId = +urlSegments[3];
        return this.getPublishedSurveyByEnpsId(enpsSurveyId);
      case url.endsWith('api/survey/templates'):
        return this.getTemplateSurveys();
      case url.endsWith('api/survey/published'):
        return this.getPublishedSurveys();
      case url.match(/api\/survey\/published\/all\?userId=\d+$/) !== null:
        const userId = +req.params.get('userId')!;
        return this.getPublishedSurveysToUser(userId);
      case url.match(/api\/survey\/\d+\/published\/list/) !== null:
        const surveyPublishedViewableId = +urlSegments[2];
        return this.getViewablePublishedSurveys(surveyPublishedViewableId);
      case url.match(/api\/survey\/\d+\/published/) !== null:
        const surveyPublishedId = +urlSegments[2];
        return this.getPublishedSurveysBySurveyId(surveyPublishedId);
      case url.endsWith('api/survey/questions'):
        return this.getAllQuestions();
      case url.match(/api\/survey\/questions\?questionId=\d+$/) !== null:
        const questionId = +req.params.get('questionId')!;
        return this.getByQuestionId(questionId);
      case url.match(/api\/survey\/published\/\d+\/complete/) !== null:
        const completeSurveyId = +urlSegments[3];
        return this.getSurveyResponses(completeSurveyId);
      case url.match(/api\/survey\/published\/\d+\/locations/) !== null:
        const completedSurveyId = +urlSegments[3];
        return this.getSurveyResponses(completedSurveyId);
      case url.match(/api\/survey\/published\/\d+\/recipients/) !== null:
        const respondedSurveyId = +urlSegments[3];
        return this.getSurveyRecipientsByPublishedSurveyId(respondedSurveyId);
      case url.endsWith('api/survey/published/stats'):
        return this.getSurveyResultStats();
      case url.endsWith('api/survey/published/stats/admin'):
        return this.getSurveyResultStatsAdmin();
      case url.match(/api\/survey\/published\/\?userId=\d+$/) !== null:
        const publishedSurveyUserId = +req.params.get('userId')!;
        return this.getAssignedPublishedSurveysUser(publishedSurveyUserId);
      case url.match(/api\/survey\/published\/\d+\/nextSurveyDate/) !== null:
        const publishedSurveyNextDateId = +urlSegments[3];
        return this.getNextSurveyDate(publishedSurveyNextDateId);
      case url.match(/api\/survey\/published\/\d+\/enps/) !== null:
        return this.getPublishedEnpsSurveySummaryById(0, {dateFrom: new Date(), dateTo: new Date()});
    }
  }

  getNextSurveyDate(publishedSurveyId: number): Observable<NextSurveyScheduleDate> | NextSurveyScheduleDate {
    return {
      publishDate: moment().add(1, 'month').toDate()
    };
  }

  getAllQuestions(): Observable<Array<SurveyQuestion>> | SurveyQuestion[] {
    return mockSurveyQuestions;
  }

  getAssignedPublishedSurveysUser(userId: number): Observable<Array<PublishedSurveyFull>> | PublishedSurveyFull[] {
    return mockPublishedSurveys.filter(s => s.userIds.includes(userId)).map(ps => ({
      ...ps,
      survey: mockSurveys.find(s => s.id === ps.surveyId)!
    }));
  }

  getById(id: number): Observable<Survey> | Survey {
    return clone(
      mockSurveys.map(s => {
        if (s.id === 8) {
          s.enps = true;
        }
        return s;
      })
        .find(s => s.id === id)
    )!; // Have to clone this because I think the survey component is modifying the object and setting the ID to null
  }

  getByQuestionId(id: number): Observable<SurveyQuestion> | SurveyQuestion {
    return mockSurveyQuestions.find(q => q.id === id)!;
  }

  getCompletedSurveyLocation(publishedSurveyId: number): Observable<CompletedSurveyLocations> | CompletedSurveyLocations[] {
    // This is not used anywhere
    return [];
  }

  getPublishedSurveyByEnpsId(id: number): Observable<PublishedSurveyFull> | PublishedSurveyFull {
    const publishedSurvey = mockPublishedSurveys.find(s => s.surveyId === id);
    return {
      ...publishedSurvey!,
      survey: mockSurveys.find(s => s.id === publishedSurvey!.surveyId)!
    };
  }

  getPublishedSurveyById(id: number): Observable<PublishedSurveyFull> | PublishedSurveyFull {
    const publishedSurvey = mockPublishedSurveys.find(s => s.id === id);
    return {
      ...publishedSurvey!,
      survey: mockSurveys.find(s => s.id === publishedSurvey!.surveyId)!
    };
  }

  getPublishedSurveys(): Observable<Array<PublishedSurveyFull>> | PublishedSurveyFull[] {
    return mockPublishedSurveys.filter(ps => ps.userIds.includes(this.globals.user.id)).map(ps => ({
      ...ps,
      survey: mockSurveys.find(s => s.id === ps.surveyId)!
    }));
  }

  getPublishedSurveysBySurveyId(surveyId: number): Observable<Array<PublishedSurvey>> | PublishedSurvey[] {
    return mockPublishedSurveys.filter(ps => ps.surveyId === surveyId);
  }

  getPublishedSurveysToUser(userId: number): Observable<Array<PublishedSurveyFull>> | PublishedSurveyFull[] {
    return mockPublishedSurveys.filter(s => s.userIds.includes(userId)).map(ps => ({
      ...ps,
      survey: mockSurveys.find(s => s.id === ps.surveyId)!
    }));
  }

  getSurveyRecipientsByPublishedSurveyId(publishedSurveyId: number): Observable<Array<User>> | User[] {
    const userIds = mockPublishedSurveys.find(s => s.id === publishedSurveyId)!.userIds;
    return mockUsers.filter(u => userIds.includes(u.id));
  }

  getSurveyResponses(publishedSurveyId: number): Observable<Array<CompletedSurvey>> | CompletedSurvey[] {
    return mockCompletedSurveys.filter(s => s.publishedSurveyId === publishedSurveyId);
  }

  getSurveyResultStats(): Observable<Array<SurveyResultStats>> | SurveyResultStats[] {
    return mockPublishedSurveys.map(ps => {
      const survey = mockSurveys.find(s => s.id === ps.surveyId);
      const completedSurveys = mockCompletedSurveys.filter(c => c.publishedSurveyId === ps.id);
      return {
        surveyId: ps.surveyId,
        publishedSurveyId: ps.id,
        surveyName: survey!.name,
        frequency: survey!.frequency,
        publishedDate: new Date(ps.startDateTime),
        expiryDateTime: new Date(ps.expiryDateTime),
        numberOfResponses: completedSurveys.length,
        totalRecipients: ps.userIds.length,
        totalRecipientsArchived: 0,
        favourite: ps.favourite,
        publishedStatus: ps.status,
        reminderSent: ps.reminderSent,
        creatorUserId: survey!.creatorId,
        creatorUser: mockUsers.find(u => u.id === survey!.creatorId),
        publishedSurveyCount: mockPublishedSurveys.filter(p => p.surveyId === survey!.id).length,
        startDateTime: new Date(survey!.startDateTime!),
        enps: survey!.enps,
        continuous: survey!.continuous,
        timezone: 'UTC',
        createdTimestamp: moment().subtract(1, 'week').toDate(),
        lastEditTimestamp: moment().subtract(1, 'week').toDate(),
        editorUserId: mockUsers[0].id,
        editorUser: mockUsers[0],
        archived: false
      };
    });
  }

  getSurveyResultStatsAdmin(): Observable<Array<SurveyResultStats>> | SurveyResultStats[] {
    return this.getSurveyResultStats();
  }

  getSurveys(): Observable<Array<Survey>> | Survey[] {
    return mockSurveys;
  }

  getTemplateSurveys(): Observable<Array<Survey>> | Survey[] {
    return mockSurveys.filter(s => !s.templateId && s.name !== 'eNPS Survey'); // Disable enps for now
  }

  getViewablePublishedSurveys(surveyId: number): ViewablePublishedSurvey[] {
    return mockPublishedSurveys.filter(ps => ps.surveyId === surveyId).map(ps => ({
      surveyId: ps.surveyId,
      publishedSurveyId: ps.id,
      publishedDate: ps.startDateTime,
      numberOfResponses: mockCompletedSurveys.filter(cs => cs.publishedSurveyId === ps.id).length,
      totalRecipients: ps.userIds.length
    }));
  }

  getEnpsSurveyResponses(publishedSurveyId: number, range: SurveyRange): Observable<Array<CompletedSurvey>> | CompletedSurvey[] {
    return mockCompletedSurveys.filter(cs =>
      cs.publishedSurveyId === publishedSurveyId &&
          cs.completedDateTime &&
          moment(cs.completedDateTime).isBetween(range.dateFrom, range.dateTo)
    );
  }

  getPublishedEnpsSurveySummaryById(id: number, range: SurveyRange): Observable<PublishedEnpsSummary> | PublishedEnpsSummary {
    const enpsPublishedSurvey = mockPublishedSurveys.find(ps => ps.id === id);
    if (!enpsPublishedSurvey) {
      return null!;
    }
    // Just leave hardcoded until we have an enps survey in the mock data
    return mockPublishedEnpsSummary;
  }

  // No Ops listed below
  getSurveyResults(publishedSurveyId: number): any {
    return undefined;
  }

  addCompletedSurvey(completedSurvey: CompletedSurvey): Observable<CompletedSurvey> {
    return undefined!;
  }

  archiveSurvey(surveyId: number): Observable<Survey> {
    return undefined!;
  }

  createSurvey(survey: Survey): Observable<Survey> {
    return undefined!;
  }

  favouritePublishedSurvey(publishedSurveyId: number): Observable<PublishedSurvey> {
    return undefined!;
  }

  publishSurvey(id: number, publishedSurvey: PublishedSurvey, publishNow: boolean): Observable<PublishedSurveyFull> {
    return undefined!;
  }

  sendSurveyReminder(surveyId: number): Observable<number> {
    return undefined!;
  }

  saveSurvey(survey: Survey): Observable<Survey> {
    return undefined!;
  }

  updateSurvey(survey: Survey): Observable<Survey> {
    return undefined!;
  }

  updateSurveyQuestions(id: number, questionIds: Array<number>): Observable<Survey> {
    return undefined!;
  }
}
