import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { CompanyFeatures } from '@app/models/company-features.model';
import { Competency } from '@app/domain/competency/model/competency.model';
import { CreateGoalKeyResultDto } from '@app/models/goals/create-goal-key-result.dto';
import { CreateGoalDto } from '@app/models/goals/create-goal.dto';
import { GoalDraft } from '@app/models/goals/goal-draft.model';
import { GoalKeyResultMeasureUnitPlacement } from '@app/models/goals/goal-key-result-measure-unit-placement.model';
import { GoalKeyResultType } from '@app/models/goals/goal-key-result-type.model';
import { GoalKeyResult } from '@app/models/goals/goal-key-result.model';
import { Tag } from '@app/domain/tag/model/tag.model';
import { GoalTemplate } from '@app/models/goals/templates/goal-template.model';
import { GoalType } from '@app/models/goals/goal-type.enum';
import { Goal } from '@app/models/goals/goal.model';
import { RoleName } from '@app/models/user-role.model';
import { IState } from '@app/models/state/state.model';
import { UserMinimal } from '@app/models/user/user-minimal.model';
import { User } from '@app/models/user/user.model';
import { GoalsAPIService } from '@app/shared/api/goals.api.service';
import { Globals } from '@app/shared/globals/globals';
import { NotifyUtils } from '@app/shared/utils/notify.utils';
import { FrankliValidators } from '@app/shared/validators/validators';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { GoalCreateService } from '../goals-create-old/goals-create.service';
import { initKeyResultFormGroup } from './goal-create-form-parts/goal-create-part-key-results/goal-create-part-key-results.component';
import { initTaskFormGroup } from './goal-create-form-parts/goal-create-part-tasks/goal-create-part-tasks.component';
import { GoalCreateCopyingComponent } from './goal-create-views/goal-create-copying/goal-create-copying.component';
import { FormPart, GoalCreateMainComponent } from './goal-create-views/goal-create-main/goal-create-main.component';
import { GoalCreateTemplatesComponent } from './goal-create-views/goal-create-templates/goal-create-templates.component';
import { UpdateGoalDraft } from '@app/models/goals/update-goal-draft';
import { GoalTemplateKeyResult } from '@app/models/goals/templates/goal-template-key-result.model';
import { GoalPriority } from '@app/models/goals/goal-priority.model';
import { GoalVisibility } from '@app/models/goals/goal-visibility.model';
import { GoalsMessages } from '@app/goals/goals.messages';
import { CommonMessages } from '@app/constants/common.messages';
import { ButtonType } from '@app/shared/components/inputs/button/button.component';
import { TranslateService } from '@ngx-translate/core';
import { FrankliHttpErrorResponse } from '@app/models/exception/frankli-http-error-response.model';
import { ErrorCode } from '@app/models/exception/error-codes.model';
import { SwalUtils } from '@app/shared/utils/swal.utils';
import { CreateTaskDto } from '@app/domain/task/model/create-task.dto';
import { GoalDistribution } from '@app/models/goals/goal-distribution.model';
import { ModalComponent } from '@app/shared/modal/modal.component';
import moment from 'moment';
import { TerminologyEntity } from '@app/domain/terminology/model/terminology-entity.enum';

export const GoalCreateValidationLimits = {
  title: {
    min: 10,
    max: 300
  },
  keyResults: {
    min: 1
  },
  owners: {
    min: 1
  },
  developmentNeeds: {
    min: 10,
    max: 255
  },
  endDate: {
    min: new Date()
  }
};

export enum GoalCreatePageViews {
  MAIN = 'MAIN',
  TEMPLATES = 'TEMPLATES',
  COPYING = 'COPYING'
}


interface PageState extends IState {
  processing: boolean;
  submitted: boolean;
  submittedDraft: boolean;
}

@Component({
  selector: 'app-goal-create',
  templateUrl: './goal-create.component.html',
  styleUrls: ['./goal-create.component.scss']
})
export class GoalCreateComponent implements OnChanges, OnInit, OnDestroy {
  private readonly ngUnsubscribe$ = new Subject<void>();

  public readonly dateDataFormat = 'YYYY-MM-DDT00:00:00.000[Z]';

  public readonly eTerminologyEntity = TerminologyEntity;
  public readonly eCompanyFeatures = CompanyFeatures;
  public readonly eCommonMessages = CommonMessages;
  public readonly ePageViews = GoalCreatePageViews;
  public readonly eGoalsMessages = GoalsMessages;
  public readonly eGoalPriority = GoalPriority;
  public readonly eButtonType = ButtonType;


  @Input() initGoalType?: GoalType;
  @Input() initPageView?: GoalCreatePageViews;
  @Input() modal?: ModalComponent;
  @Input() initGoalAlignmentId?: number;
  @Input() initGoalOwnerIds?: number[];

  @Output() requestCloseModal: EventEmitter<boolean>;
  @Output() requestOpenModal: EventEmitter<boolean>;

  @ViewChild('viewCreate') viewCreate?: GoalCreateMainComponent;
  @ViewChild('viewTemplates') viewTemplates?: GoalCreateTemplatesComponent;
  @ViewChild('viewCopying') viewCopying?: GoalCreateCopyingComponent;

  goalForm: FormGroup;
  public formChanged: boolean;

  selectedView: GoalCreatePageViews;
  state: PageState;

  furthestFormPart?: FormPart;

  competencies: Competency[];

  get isEditing(): boolean {
    return !!this.goalForm.controls.id.value;
  }

  constructor(
    public globals: Globals,
    private goalsAPIService: GoalsAPIService,
    private goalCreateService: GoalCreateService,
    private swalUtils: SwalUtils,
    private notifyUtils: NotifyUtils,
    private translateService: TranslateService
  ) {
    this.state = {
      loading: false,
      error: false,
      errorMessage: '',
      processing: false,
      submitted: false,
      submittedDraft: false
    };
    this.furthestFormPart = undefined;
    this.initGoalType = undefined;
    this.initGoalAlignmentId = undefined;
    this.initGoalOwnerIds = undefined;
    this.requestCloseModal = new EventEmitter<boolean>();
    this.requestOpenModal = new EventEmitter<boolean>();
    this.initPageView = GoalCreatePageViews.MAIN;
    this.selectedView = GoalCreatePageViews.MAIN;
    this.competencies = [];
    this.goalForm = this.initForm(true);
    this.formChanged = false;
  }

  ngOnInit(): void {
    this.initEventListeners();
    this.getCompetencies();
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.unsubscribe();
  }
  
  ngOnChanges(changes: SimpleChanges): void {
    const pageView = changes['initPageView'];
    if (pageView) {
      this.selectedView = pageView.currentValue;
    }

    const initGoalType = changes['initGoalType'];
    if (initGoalType) {
      const setValue = (initGoalType.currentValue ? initGoalType.currentValue : null);
      this.goalForm.controls.type.setValue(setValue);
      this.furthestFormPart = (setValue ? FormPart.TYPE : undefined);
      this.scrollToFirstInvalidDropdown(); // Will open the type dropdown by default
    }

    const initGoalAlignment = changes['initGoalAlignmentId'];
    if (initGoalAlignment) {
      const setValue = (initGoalAlignment.currentValue ? initGoalAlignment.currentValue : null);
      this.goalForm.controls.alignmentId.setValue(setValue);
    }

    const initGoalOwners = changes['initGoalOwnerIds'];
    if (initGoalOwners) {
      const setValue = (initGoalOwners.currentValue ? initGoalOwners.currentValue : null);
      this.goalForm.controls.owners.setValue(setValue);
    }
  }

  initEventListeners(): void {
    this.goalCreateService.getReset().pipe(takeUntil(this.ngUnsubscribe$)).subscribe(() => this.resetState());
    this.goalCreateService.getGoalCopied().pipe(takeUntil(this.ngUnsubscribe$)).subscribe(goal => this.onCopyGoal(goal));
    this.goalCreateService.getGoalTemplateCopied().pipe(takeUntil(this.ngUnsubscribe$)).subscribe(template => this.onCopyTemplate(template));
    this.goalCreateService.getGoalDraftEdited().pipe(takeUntil(this.ngUnsubscribe$)).subscribe(draft => this.onEditDraft(draft));
    this.goalForm.valueChanges.subscribe(() => {
      this.formChanged = true;
    });
  }

  initForm(initKeyResults = false): FormGroup {
    const defaultEndDate = moment().startOf('day').add(2, 'weeks').toDate();
    
    const formGroup = new FormGroup({
      id: new FormControl(
        null,
        []
      ),
      title: new FormControl(
        '',
        [Validators.required, Validators.minLength(GoalCreateValidationLimits.title.min), Validators.maxLength(GoalCreateValidationLimits.title.max)]
      ),
      type: new FormControl(
        null,
        [Validators.required]
      ),
      developmentNeeds: new FormControl(
        '',
        [Validators.minLength(GoalCreateValidationLimits.developmentNeeds.min), Validators.maxLength(GoalCreateValidationLimits.developmentNeeds.max)]
      ),
      departmentId: new FormControl(
        null,
        []
      ),
      officeLocationId: new FormControl(
        null,
        []
      ),
      endDate: new FormControl(
        defaultEndDate,
        [Validators.required, FrankliValidators.minDate(GoalCreateValidationLimits.endDate.min)]
      ),
      visibility: new FormControl(
        GoalVisibility.PUBLIC,
        [Validators.required]
      ),
      keyResults: new FormArray(
        (initKeyResults ? [initKeyResultFormGroup(defaultEndDate)] : []),
        [FrankliValidators.minLengthArray(GoalCreateValidationLimits.keyResults.min)]
      ),
      owners: new FormControl(
        [this.globals.user],
        [FrankliValidators.minLengthArray(GoalCreateValidationLimits.owners.min)]
      ),
      alignmentId: new FormControl(
        null,
        []
      ),
      priority: new FormControl(
        GoalPriority.NOT_SET,
        []
      ),
      tags: new FormControl(
        [],
        []
      ),
      tasks: new FormArray(
        // [initTaskFormGroup()]
        [],
        []
      ),
      distribute: new FormControl(
        GoalDistribution.NONE,
        []
      )
    });

    return formGroup;
  }

  // #region - EXTRA ACTIONS CALLBACKS
  openViewTemplates(): void {
    this.selectedView = this.ePageViews.TEMPLATES;
  }

  openViewCopying(): void {
    this.selectedView = this.ePageViews.COPYING;
  }

  openViewCreate(): void {
    this.selectedView = this.ePageViews.MAIN;
  }

  openHelpArticle() {
    const newWindow = window.open('https://help.frankli.io/en/collections/3357906-okr-goals', '_blank');
    
    if (newWindow) {
      newWindow.focus();
    }
  }

  closeCreateModal() {
    this.requestCloseModal.emit(true);
  }
  // #endregion

  // #region - PRIMARY ACTIONS CALLBACKS
  onCancelCreate(): void {
    this.resetState();
    this.closeCreateModal();
  }

  onSaveDraft(): void {
    this.onSubmitDraft(); // NOTE: Might need different states for draft
    this.doSaveDraft(this.goalForm);
  }

  onConfirmCreate(): void {
    this.onSubmit();
    this.doGoalCreate(this.goalForm);
  }
  // #endregion

  // #region - GOAL CREATION
  onSubmit() {
    this.state.submitted = true;
    this.state.submittedDraft = false;
    this.furthestFormPart = FormPart.TASKS;
  }

  doGoalCreate(goalForm: FormGroup): void {
    if (this.state.processing) {
      return;
    }

    if (goalForm.invalid) {
      this.scrollToFirstInvalidDropdown();
      return;
    }

    this.state.processing = true;

    const createGoalDto: CreateGoalDto = this.parseFormToCreateGoalDto(goalForm);

    if (createGoalDto.distribute !== GoalDistribution.NONE) {
      this.createUsingBulkActions(createGoalDto);
    } else {
      this.createUsingRegularMethod(createGoalDto);
    }
  }

  createUsingRegularMethod(createGoalDto: CreateGoalDto): void {
    this.goalsAPIService.createGoal(createGoalDto)
      .toPromise()
      .then(() => {
        this.resetState();
        this.closeCreateModal();

        this.goalCreateService.sendRefreshGoals();
        this.goalCreateService.sendReset();

        this.notifyUtils.notify(GoalsMessages.SUCCESS_GOAL_CREATED);
      })
      .catch(err => {
        switch(err.error.errorCode) {
          case ErrorCode.GOAL_INVALID_OWNERS:
            return this.swalUtils.displayErrorSwal({
              html: `<div>${err.error.message}</div>${err.error.errorCode ? `<small class="error-code">ERROR CODE: ${err.error.errorCode}</small> ` : ''}`
            }).then((res) => {
              if (res.isConfirmed) {
                this.focusRelevantControlForPart(FormPart.OWNERS);
                throw err;
              }
            });
        }
      })
      .finally(() => {
        this.state.processing = false;
      });
  }

  createUsingBulkActions(createGoalDto: CreateGoalDto): void {
    this.goalsAPIService.createGoalWithProgress(createGoalDto)
      .toPromise()
      .then(() => {
        this.resetState();
        this.closeCreateModal();
      })
      .catch((err: FrankliHttpErrorResponse) => {
        console.error('Error occured with goal create:', err);
        this.state.processing = false;
  
        switch(err.error.errorCode) {
          case ErrorCode.GOAL_INVALID_OWNERS:
            return this.swalUtils.displayErrorSwal({
              html: `<div>${err.error.message}</div>${err.error.errorCode ? `<small class="error-code">ERROR CODE: ${err.error.errorCode}</small> ` : ''}`
            }).then((res) => {
              if (res.isConfirmed) {
                this.focusRelevantControlForPart(FormPart.OWNERS);
                throw err;
              }
            });
        }
      });
  }

  focusRelevantControlForPart(part: FormPart): void {
    if (this.viewCreate) {
      this.viewCreate.expandAndHighlightPart(part);
    }
  }
  // #endregion

  // #region - SAVING AS DRAFT
  onSubmitDraft(): void {
    this.state.submitted = false;
    this.state.submittedDraft = true;
  }

  doSaveDraft(goalForm: FormGroup): void {
    if (this.state.processing) {
      return;
    }

    const valid = this.checkValidityGoalDraft(goalForm);

    if (!valid) {
      this.scrollToFirstInvalidDraftDropdown();
      return;
    }

    const id = goalForm.controls.id.value;
    if (id) {
      this.doSaveDraftExisting(id, goalForm);
    } else {
      this.doSaveDraftNew(goalForm);
    }
  }

  doSaveDraftNew(goalForm: FormGroup): void {
    const newGoalDraft: CreateGoalDto = this.parseFormToCreateGoalDto(goalForm);

    this.goalsAPIService.createDraftGoal(newGoalDraft).subscribe(
      (res: GoalDraft) => {
        this.closeCreateModal();
        this.goalCreateService.sendGoalSaved(res);
        this.goalCreateService.sendReset();
        this.notifyUtils.notify(GoalsMessages.SUCCESS_GOAL_DRAFT_CREATED);
      },
      () => {
        this.state.processing = false;
      }
    );
  }

  doSaveDraftExisting(id: number, goalForm: FormGroup): void {
    const updatingGoalDraft: UpdateGoalDraft = this.parseFormToGoalCreateDtoDraftUpdating(goalForm);
    this.goalsAPIService.updateDraftGoalById(id, updatingGoalDraft).subscribe(
      (res) => {
        this.closeCreateModal();
        this.goalCreateService.sendGoalSaved(res);
        this.goalCreateService.sendReset();
        this.notifyUtils.notify(GoalsMessages.SUCCESS_GOAL_DRAFT_UPDATED);
      }
    );
  }

  checkValidityGoalDraft(form: FormGroup): boolean {
    const draftForm = this.initForm(false);
    draftForm.patchValue(form);

    draftForm.controls.title.setErrors(null);
    draftForm.controls.type.setErrors(null);
    draftForm.controls.developmentNeeds.setErrors(null);
    draftForm.controls.departmentId.setErrors(null);
    draftForm.controls.officeLocationId.setErrors(null);

    const oldKeyResults = ((form.controls.keyResults as FormArray).controls as FormGroup[]);

    oldKeyResults.forEach(oldKr => {
      const newGroup = initKeyResultFormGroup();

      newGroup.controls.result.setErrors(null);
      newGroup.controls.type.setErrors(null);
      newGroup.controls.measureStartValue.setErrors(null);
      newGroup.controls.measureGoalValue.setErrors(null);
      newGroup.controls.endDate.setErrors(null); 

      newGroup.patchValue(oldKr);

      (draftForm.controls.keyResults as FormArray).push(newGroup);
    });

    const foundForbiddenCharacters = oldKeyResults.some(oldKr =>
      oldKr.controls.measureStartValue.hasError('forbiddenCharacter') ||
        oldKr.controls.measureGoalValue.hasError('forbiddenCharacter')
    );
    return foundForbiddenCharacters ? false : draftForm.valid;

  }
  // #endregion

  // #region - PARSING FORM DATA TO OBJECTS
  parseFormToCreateGoalDto(form: FormGroup): CreateGoalDto {
    // NOTE: Owners is set elsewhere because of distribution
    const createGoalDto = form.value as CreateGoalDto;

    createGoalDto.draftId = this.goalForm.value.id || undefined;
    createGoalDto.tasks = this.parseTaskTocreate(form.controls.tasks as FormArray);
    createGoalDto.ownerIds = this.parseFormOwnersToOwnerIds(form.controls.owners.value as User[]);
    createGoalDto.tagIds = this.parseFormTagsToTagIds(form.controls.tags.value as Tag[]);
    createGoalDto.distribute = form.controls.distribute.value;
    createGoalDto.keyResults = createGoalDto.keyResults.map((kr, index) => {
      kr.orderIndex = index;
      return kr;
    });
    
    // Take alignmentId from form control value
    if (form.controls.alignmentId.value) {
      createGoalDto.alignmentId = form.controls.alignmentId.value;
    }

    return createGoalDto;
  }

  parseFormToGoalCreateDtoDraftUpdating(form: FormGroup): UpdateGoalDraft {
    const newGoalDraft = form.value as UpdateGoalDraft;
    // newGoalDraft.tasks = this.parseTaskTocreate(form.controls.tasks as FormArray);
    newGoalDraft.ownerIds = this.parseFormOwnersToOwnerIds(form.controls.owners.value as User[]);
    // newGoalDraft.tagIds = this.parseFormTagsToTagIds(form.controls.tags.value as GoalTag[]);
    newGoalDraft.distribute = this.goalForm.controls.distribute.value as boolean;
    newGoalDraft.keyResults = newGoalDraft.keyResults.map((kr, index) => {
      kr.orderIndex = index;
      return kr;
    });


    //Below is just a patch - need a proper fix
    if (form.controls.alignmentId.value) {
      if (form.controls.alignmentId.value.id) {
        newGoalDraft.alignment = form.controls.alignmentId.value.id;
      } else {
        newGoalDraft.alignment = form.controls.alignmentId.value;
      }

    }

    return newGoalDraft;

  }

  parseFormTagsToTagIds(formValue: Tag[]): number[] {
    return formValue.map(t => t.id);
  }
  
  parseFormOwnersToOwnerIds(formValue: UserMinimal[]): number[] {
    return formValue.map(o => o.id);
  }

  parseTaskTocreate(formGroup: FormArray): CreateTaskDto[] {
    const formsList = formGroup.controls as FormGroup[];

    const output = formsList.map(taskGroup => {
      const createTaskDto: CreateTaskDto = taskGroup.value as CreateTaskDto;
      const ownersFull = taskGroup.controls.owners.value as UserMinimal[];
      createTaskDto.ownerIds = ownersFull.map(o => o.id);
      return createTaskDto;
    });

    return output;
  }

  // #endregion

  // #region - TEMPLATES AND COPYING
  onCopyGoal(goal: Goal): void {
    this.resetState();

    this.goalForm.controls.title.setValue(goal.title);
    this.goalForm.controls.priority.setValue(goal.priority);
    this.goalForm.controls.visibility.setValue(goal.visibility);
    this.goalForm.controls.tags.setValue(goal.tags);

    if (this.canSetGoalType(goal.type)) {
      this.goalForm.controls.type.setValue(goal.type);
    }

    const endDate = this.goalForm.controls.endDate.value;

    const formKeyResults = this.goalForm.controls.keyResults as FormArray;
    formKeyResults.clear();
    goal.keyResults.forEach(kr => {
      const krParsed = this.parseCopiedResultToRegularResult(kr);
      const formGroup = initKeyResultFormGroup(endDate);
      formGroup.patchValue(krParsed);
      formKeyResults.push(formGroup);
    });

    this.furthestFormPart = FormPart.PRIORITY;

    setTimeout(() => {
      if (this.viewCreate) {
        this.viewCreate.setDropdown(this.viewCreate.dropdownType, false);
      }
    }, 1);
  }

  onCopyTemplate(template: GoalTemplate): void {
    const oldOwnersValue = this.goalForm.controls.owners.value;
    const oldAlignmentValue = this.goalForm.controls.alignmentId.value;

    this.resetState();

    this.goalForm.controls.title.setValue(template.title);

    const endDate = this.goalForm.controls.endDate.value;

    const formKeyResults = this.goalForm.controls.keyResults as FormArray;
    formKeyResults.clear();
    template.keyResults.forEach(kr => {
      const krParsed = this.parseTemplateResultToRegularResult(kr);
      const formGroup = initKeyResultFormGroup(endDate);

      formGroup.patchValue(krParsed);
      formKeyResults.push(formGroup);
    });

    // Restore owners and alignment
    this.goalForm.controls.owners.setValue(oldOwnersValue);
    this.goalForm.controls.alignmentId.setValue(oldAlignmentValue);

    this.furthestFormPart = FormPart.PRIORITY;
  }
  // #endregion

  // #region - TEMPLATES AND COPYING PARSING
  parseCopiedResultToRegularResult(result: GoalKeyResult): CreateGoalKeyResultDto {
    const newResult: CreateGoalKeyResultDto = {
      result: result.result,
      endDate: null,
      type: result.type,
      measureGoalValue: result.measureGoalValue,
      measureStartValue: result.measureStartValue,
      measureUnit: result.measureUnit,
      measureUnitPlacement: result.measureUnitPlacement,
      orderIndex: result.orderIndex
    };

    return newResult;
  }

  parseTemplateResultToRegularResult(result: GoalTemplateKeyResult): CreateGoalKeyResultDto {
    const unit = (result.measureUnit ? result.measureUnit : '');

    const newResult: CreateGoalKeyResultDto = {
      result: result.result,
      endDate: null,
      measureStartValue: 0,
      measureGoalValue: 1,
      type: result.type as GoalKeyResultType,
      measureUnit: unit,
      measureUnitPlacement: result.measureUnitPlacement as GoalKeyResultMeasureUnitPlacement,
      orderIndex: result.orderIndex
    };

    return newResult;
  }
  // #endregion

  canSetGoalType(type: GoalType): boolean {
    switch (type) {
      case GoalType.COMPANY:
        return this.globals.hasRole(RoleName.GOAL_COMPANY);
      case GoalType.DEPARTMENT:
        return (!!this.globals.user.organisationalUnit);
      case GoalType.OFFICE_LOCATION:
        return (this.globals.user.officeLocation! && this.globals.hasFeature(CompanyFeatures.GOAL_OFFICE_LOCATION));
      default:
        return true;
    }
  }

  resetState(): void {
    this.state.processing = false;
    this.state.submitted = false;
    this.state.submittedDraft = false;
    this.furthestFormPart = undefined;
    const tempFormValue = this.initForm().value;
    this.goalForm.patchValue(tempFormValue);
    (this.goalForm.controls.keyResults as FormArray).clear();
    (this.goalForm.controls.tasks as FormArray).clear();
    this.initEventListeners();
  }

  onViewChangeRequested(view: GoalCreatePageViews): void {
    this.selectedView = view;
  }

  onEditDraft(draftEdited: GoalDraft): void {
    this.resetState();
    this.goalForm.patchValue(draftEdited);

    const endDate = this.goalForm.controls.endDate.value;

    // Key Results
    const formKeyResults = this.goalForm.controls.keyResults as FormArray;
    formKeyResults.clear();
    draftEdited.keyResults.forEach(kr => {
      const formGroup = initKeyResultFormGroup(endDate);
      formGroup.patchValue(kr);
      formKeyResults.push(formGroup);
    });

    // Tasks
    const formTasks = this.goalForm.controls.tasks as FormArray;
    formTasks.clear();
    draftEdited.tasks.forEach(task => {
      const formGroup = initTaskFormGroup();
      formGroup.patchValue(task);
      formTasks.push(formGroup);
    });

    if (draftEdited.alignment) {
      this.goalsAPIService.getGoalById(draftEdited.alignment).subscribe(alginedGoal => {
        this.goalForm.controls.alignmentId.setValue(alginedGoal);
        this.selectedView = GoalCreatePageViews.MAIN;
        this.furthestFormPart = FormPart.TASKS;
        this.requestOpenModal.emit(true);
      });
    } else {
      this.selectedView = GoalCreatePageViews.MAIN;
      this.furthestFormPart = FormPart.TASKS;
      this.requestOpenModal.emit(true);
    }
  }

  getCompetencies(): void {
    if (this.globals.user.position && this.globals.hasFeature(CompanyFeatures.COMPETENCIES)) {
      this.competencies = this.globals.user.position.competencies;
    }
  }

  scrollToFirstInvalidDropdown(): void {
    if (this.viewCreate) {
      this.viewCreate.scrollToFirstInvalidDropdown();
    }
  }

  scrollToFirstInvalidDraftDropdown(): void {
    if (this.viewCreate) {
      this.viewCreate.scrollToFirstInvalidDraftDropdown();
    }
  }
}
