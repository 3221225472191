import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Calendar } from '@app/domain/one_to_one/model/calendar.model';
import { Observable } from 'rxjs';
import { CalendarService } from '@app/shared/api/interfaces/calendar.service';
import { CalendarConnectionStatus } from '@app/domain/one_to_one/model/calendar-connected.model';

@Injectable({
  providedIn: 'root'
})
export class CalendarAPIService implements CalendarService {
  public readonly BASE_URL = '/api/calendar';

  constructor(private http: HttpClient) { }

  isConnected(): Observable<CalendarConnectionStatus> {
    const url = `${this.BASE_URL}/connected`;
    return this.http.get<CalendarConnectionStatus>(url);
  }

  getAvailability(emails: string[], startDateTime: string, endDateTime: string, timezone: string): Observable<Calendar[]> {
    const request = {
      emails,
      startDateTime,
      endDateTime,
      timezone
    };

    const url = `${this.BASE_URL}/availability`;
    return this.http.post<Calendar[]>(url, request);
  }
}
