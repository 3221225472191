import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {UserState} from 'app/models/user/user.model';
import {Globals} from '../globals/globals';
import {SwalUtils} from '../utils/swal.utils';

@Injectable()
export class NewUserGuard implements CanActivate {
  constructor(
      public globals: Globals,
      private router: Router,
      private swalUtils: SwalUtils
  ) {
  }

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const STATES = [UserState.INVITED, UserState.PENDING];
    if (!STATES.includes(this.globals.user.userState)) {
      return true;
    }

    // if (this.router.url !== '/auth/reset-password') {
    //   this.swalUtils.displayWarning({
    //     title: 'Looks like this is your first time logging in',
    //     text: 'You should reset your password before proceeding.'
    //   }).then(() => {
    //     this.router.navigate(['/auth/reset-password']);
    //   });
    // }
    this.router.navigate(['/auth/reset-password']);

    return false;
  }
}
