import { Component, EventEmitter, Input, Output } from '@angular/core';
import { of } from 'rxjs';
import { ColumnInfo } from '@app/shared/column-toggle-new/column.info.model';
import { TableColumnNumber } from '@app/models/col-toggle-model';
import { ColumnToggleNewMessages } from '@app/shared/column-toggle-new/column-toggle-new.messages';
import { CommonMessages } from '@app/constants/common.messages';

@Component({
  selector: 'app-column-toggle-new',
  templateUrl: './column-toggle-new.component.html',
  styleUrls: ['./column-toggle-new.component.scss']
})
export class ColumnToggleNewComponent {
  public readonly eCommonMessages = CommonMessages;
  public readonly eColumnToggleNewMessages = ColumnToggleNewMessages;

  @Input() columnInfos: ColumnInfo[];
  @Input() alwaysEnabled: number[];
  @Input() startDisabled: number[];

  @Output() onColumnToggled: EventEmitter<number>;

  columns: TableColumnNumber[];

  constructor() {
    this.onColumnToggled = new EventEmitter<number>();

    this.columnInfos = [];
    this.alwaysEnabled = [];
    this.startDisabled = [];
    this.columns = [];
  }

  ngOnInit() {
    this.setColumns(this.columnInfos);
  }

  ngOnChanges() {
    this.setColumns(this.columnInfos);
  }

  setColumns(columnInfos: ColumnInfo[]) {
    this.columns = columnInfos.map(t => {
      return {
        key: t.index,
        titleMessageCode: t.titleMessageCode,
        enabled: this.checkIfStartsDisabled(t.index)
      }
    });
  }

  checkIfStartsDisabled(key: number): boolean {
    if (this.startDisabled.length > 0) {
      if (this.startDisabled.includes(key)) {
        return false;
      }
    }

    return true;
  }

  toggleColumn(event: any, key: number) {
    event.stopPropagation();

    if (!this.alwaysEnabled.includes(key)) {
      this.columns.forEach(c => {
        if (c.key === key) {
          c.enabled = !c.enabled;
          this.onColumnToggled.emit(c.key);
        }
      });
    }
  }

  public getColumnEnabled(key: number) {
    if (this.alwaysEnabled.includes(key)) { return true }

    const col = this.columns.find(c => c.key === key)
    if (col) {
      return col.enabled;
    } else {
      return false;
    }
  }

  subAllColumns() {
    return of(this.columns);
  }

  checkAllEnabled() {
    return !(this.columns.map(c => c.enabled).includes(false));
  }

  checkAllDisabled() {
    return !(this.columns.map(c => c.enabled).includes(true));
  }

  toggleAll(event: any) {
    event.stopPropagation();
    if (this.checkAllEnabled() === false) {
      this.columns.forEach(c => {
        c.enabled = true;
      })
    } else {
      this.columns.forEach(c => {
        if (!this.alwaysEnabled.includes(c.key)) {
          c.enabled = false;
        }
      })
    }
  }

  public getTotalEnabledColumns(): number {
    return this.columns.filter(c => c.enabled === true).length;
  }

  public getEnabledColumns(): ColumnInfo[] {
    return this.columns.filter(c => c.enabled === true).map(c => ({
      index: c.key,
      titleMessageCode: c.titleMessageCode
    }));
  }

  public getEnabledColumnKeys(): number[] {
    return this.columns.filter(c => c.enabled === true).map(c => c.key);
  }

  public getCountEnabledFromArray(columns: ColumnInfo[]): number {
    let counter = 0;

    columns.forEach(c => {
      if (this.getColumnEnabled(c.index)) {
        counter++;
      }
    })

    return counter;
  }
}
