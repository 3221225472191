<ng-container *ngIf="!state.loading; else stateLoading">
  <ng-container *ngIf="!state.error; else stateError">
    <div class="row">
      <div class="col-md-4">
        <div class="card">
          <div class="content">
            <!-- Asked by -->
            <div class="p-bottom-20">
              <div class="form-group m-bottom-5">
                <label>Asked by</label>
              </div>
      
              <div class="lh-40" [routerLink]="'/profile/' + request.sender.id">
                <span class="owner-img">
                  <!-- TODO: Add firstName and lastName -->
                  <app-profile-photo [src]="request.sender.imageUrl"></app-profile-photo>
                </span>
                <span class="v-align-middle p-left-5">{{request.sender.firstName + ' ' + request.sender.lastName}}</span>
              </div>
            </div>
      
            <!-- Regarding -->
            <div class="p-bottom-20">
              <div class="form-group m-bottom-5">
                <label>Regarding</label>
              </div>
      
              <div class="lh-40" [routerLink]="'/profile/' + request.regarding.id">
                <span class="owner-img">
                  <!-- TODO: Add firstName and lastName -->
                  <app-profile-photo [src]="request.regarding.imageUrl"></app-profile-photo>
                </span>
                <span class="v-align-middle p-left-5">{{request.regarding.firstName + ' ' + request.regarding.lastName}}</span>
              </div>
            </div>
      
      
            <!-- Status -->
            <div class="p-bottom-20">
              <div class="form-group m-bottom-5">
                <label>Status</label>
              </div>
      
              <ng-container [ngSwitch]="response.status">
                <div *ngSwitchCase="eFeedbackRequestResponseStatus.PENDING">Pending</div>
                <div *ngSwitchCase="eFeedbackRequestResponseStatus.APPROVED">Approved</div>
                <div *ngSwitchCase="eFeedbackRequestResponseStatus.DECLINED">Declined</div>
              </ng-container>
            </div>
      
            <!-- Date requested -->
            <div class="p-bottom-20">
              <div class="form-group m-bottom-5">
                <label>Date Requested</label>
              </div>
      
              <span>{{request.timestamp | date: 'dd/MM/yyyy h:mm a'}}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-8">
        <div class="card">
          <div class="content">
            <div class="form-group m-bottom-5">
              <label>Response from</label>
            </div>

            <div class="area-user-image">
              <ng-container *ngIf="response.user">
                <span class="owner-img" [routerLink]="'/profile/' + response.user.id">
                  <!-- TODO: Add firstName and lastName -->
                  <app-profile-photo [src]="response.user.imageUrl"></app-profile-photo>
                </span>
                <span class="owner-name" [routerLink]="'/profile/' + response.user.id">{{response.user.firstName + " " + response.user.lastName}}</span>
              </ng-container>
              <ng-container *ngIf="response.externalUser">
                <span class="owner-img">
                  <!-- TODO: Add firstName and lastName -->
                  <app-profile-photo src="assets/img/default.svg"></app-profile-photo>
                </span>
                <span class="owner-name">{{response.externalUser.email}}</span>
              </ng-container>
            </div>

            <hr/>
            
            <div class="form-group m-bottom-5">
              <label>Questions Asked</label>
            </div>

            <div class="questions-list">
              <div class="question-item" *ngFor="let qData of questionListMapped; index as index; first as isFirst; last as isLast">
                <div class="question-text">
                  <strong>Q{{index + 1}}.</strong>
                  <span>{{qData.question.questionText}}</span>
                </div>
                <div class="question-answer">
                  <span>{{qData.answer.answer ? qData.answer.answer : 'No answer'}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>

<!-- #region - ALTERNATE PAGE STATES -->
<ng-template #stateLoading>
  <div class="card">
    <div class="p-top-15 p-bottom-15 text-center">
      <span class="fas fa-spin fa-spinner fa-2x fa-fw"></span>
    </div>
  </div>
</ng-template>

<ng-template #stateError>
  <div class="card">
    <div class="p-top-15 p-bottom-15 text-center">
      <img class="icon-padding new-icon-height" src="/assets/img/Error.svg" />
      <br/>
      <span>An error occurred while getting this response</span>
      <br/>
      <span>{{state.errorMessage}}</span>
      <br/>
      <span class="btn-frankli-round btn-frankli-green m-top-10" [routerLink]="'/dashboard'">
        Back to your dashboard?
      </span>
    </div>
  </div>
</ng-template>
<!-- #endregion -->