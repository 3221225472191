import { Component, Input } from '@angular/core';
import { CommonMessages } from '@app/constants/common.messages';
import { IconHoverColor } from '../inputs/table-action-icon/table-action-icon.component';

export enum BannerStyle {
  YELLOW = 'YELLOW',
  GREEN = 'GREEN',
  RED = 'RED',
  BLUE = 'BLUE',
  NAVY = 'NAVY',
  GRAY = 'GRAY',
  OFFWHITE = 'OFFWHITE',
  WHITE = 'WHITE'
}

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss']
})
export class BannerComponent {

  public readonly eIconHoverColor = IconHoverColor;
  public readonly eCommonMessages = CommonMessages;

  @Input() style: BannerStyle;
  @Input() canDismiss: boolean;
  @Input() canClick: boolean;

  hidden = false;

  constructor() {
    this.style = BannerStyle.GRAY;
    this.hidden = false;
    this.canDismiss = true;
    this.canClick = false;
  }

  onClickHide(): void {
    this.hidden = true;
  }
}
