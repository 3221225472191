import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonMessages } from '@app/constants/common.messages';
import { IState } from '@app/models/state/state.model';

@Component({
  selector: 'app-state',
  templateUrl: './state.component.html',
  styleUrls: ['./state.component.scss']
})
export class StateComponent {

  @Input() state: IState;
  @Input() stateHeight: string;

  @Input() errorButtonText: string;
  @Input() errorButtonShown: boolean;
  @Output() errorButtonPressed: EventEmitter<MouseEvent>;

  constructor() {
    this.state = {
      loading: true,
      error: false,
      errorMessage: ''
    };

    this.errorButtonText = CommonMessages.RETURN_TO_DASHBOARD_QUESTION;
    this.errorButtonShown = false;

    this.stateHeight = '300px';

    this.errorButtonPressed = new EventEmitter<MouseEvent>();
  }

  onErrorButtonPressed(event: MouseEvent): void {
    this.errorButtonPressed.emit(event);
  }
}
