import { Component, Input, Output, EventEmitter } from '@angular/core';
import { CommonMessages } from '@app/constants/common.messages';
import { Globals } from '@app/shared/globals/globals';
import { ButtonType } from '../../inputs/button/button.component';

@Component({
  selector: 'app-error-state',
  templateUrl: './error-state.component.html',
  styleUrls: ['./error-state.component.scss']
})
export class ErrorStateComponent {

  public readonly eButtonType = ButtonType;

  @Input() message: string;
  @Input() messageTranslateParams: { [key: string]: string };
  @Input() height: string;
  @Input() imageSrc: string;

  @Input() primaryButtonShown: boolean;
  @Input() primaryButtonText: string;
  @Output() primaryButtonPressed: EventEmitter<MouseEvent>;

  @Input() secondaryButtonShown: boolean;
  @Input() secondaryButtonText: string;
  @Output() secondaryButtonPressed: EventEmitter<MouseEvent>;

  @Input() tertiaryButtonShown: boolean;
  @Input() tertiaryButtonText: string;
  @Output() tertiaryButtonPressed: EventEmitter<MouseEvent>;

  constructor(
    public globals: Globals
  ) {
    this.message = CommonMessages.EMPTY_STATE;
    this.messageTranslateParams = {};
    this.height = '100vh';
    this.imageSrc = '/assets/img/NoSearchFound.svg';

    this.primaryButtonShown = false;
    this.primaryButtonText = CommonMessages.RETURN_TO_DASHBOARD_QUESTION;
    this.primaryButtonPressed = new EventEmitter<MouseEvent>();

    this.secondaryButtonShown = false;
    this.secondaryButtonText = '';
    this.secondaryButtonPressed = new EventEmitter<MouseEvent>();

    this.tertiaryButtonShown = false;
    this.tertiaryButtonText = '';
    this.tertiaryButtonPressed = new EventEmitter<MouseEvent>();
  }

  clickPrimaryButton(event: MouseEvent): void {
    if (this.primaryButtonShown === false) { return; }
    this.primaryButtonPressed.emit(event);
  }

  clickSecondaryButton(event: MouseEvent): void {
    if (this.secondaryButtonShown === false) { return; }
    this.secondaryButtonPressed.emit(event);
  }

  clickTertiaryButton(event: MouseEvent): void {
    if (this.tertiaryButtonShown === false) { return; }
    this.tertiaryButtonPressed.emit(event);
  }
}
