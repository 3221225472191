import { SortingParams } from '@app/models/api/sorting-params.model';
import { SortDirection } from '@app/models/api/sort-direction.enum';
import { PagingParams } from '@app/models/api/paging-params.model';

export const staticOfficeLocationSortingParams: SortingParams = {
    sortAttributes: ['name'],
    sortDirection: SortDirection.ASC
}

export const staticOfficeLocationPagingParams: PagingParams = {
    pageSize: 15,
    pageNumber: 0
}