import { Role } from '@app/domain/role/model/role.model';
import { mockCompetencies } from '@app/domain/competency/mock/competencies.mock';
import { deepClone } from '@app/shared/utils/helpers';
import { mockInterests } from '@app/domain/interest/mock/interests.mock';

export const mockRoles: Role[] = [
  {
    id: 1,
    name: 'CEO',
    description: '',
    grade: '',
    competencies: deepClone(mockCompetencies),
    skills: deepClone(mockInterests)
  },
  {
    id: 2,
    name: 'COO',
    description: '',
    grade: '',
    competencies: deepClone(mockCompetencies),
    skills: deepClone(mockInterests)
  },
  {
    id: 3,
    name: 'Head of People',
    description: '',
    grade: '',
    competencies: deepClone(mockCompetencies),
    skills: deepClone(mockInterests)
  },
  {
    id: 4,
    name: 'Head of Product',
    description: '',
    grade: '',
    competencies: deepClone(mockCompetencies),
    skills: deepClone(mockInterests)
  },
  {
    id: 5,
    name: 'Head of Finance',
    description: '',
    grade: '',
    competencies: deepClone(mockCompetencies),
    skills: deepClone(mockInterests)
  },
  {
    id: 6,
    name: 'Head of Insights',
    description: '',
    grade: '',
    competencies: deepClone(mockCompetencies),
    skills: deepClone(mockInterests)
  },
  {
    id: 7,
    name: 'Head of Operations',
    description: '',
    grade: '',
    competencies: deepClone(mockCompetencies),
    skills: deepClone(mockInterests)
  },
  {
    id: 8,
    name: 'Human Resource Manager',
    description: '',
    grade: '',
    competencies: deepClone(mockCompetencies),
    skills: deepClone(mockInterests)
  },
  {
    id: 9,
    name: 'Team Manager',
    description: '',
    grade: '',
    competencies: deepClone(mockCompetencies),
    skills: deepClone(mockInterests)
  },
  {
    id: 10,
    name: 'Frontend Developer',
    description: '',
    grade: '',
    competencies: deepClone(mockCompetencies),
    skills: deepClone(mockInterests)
  },
  {
    id: 11,
    name: 'Backend Developer',
    description: '',
    grade: '',
    competencies: deepClone(mockCompetencies),
    skills: deepClone(mockInterests)
  },
  {
    id: 12,
    name: 'UX Designer',
    description: '',
    grade: '',
    competencies: deepClone(mockCompetencies),
    skills: deepClone(mockInterests)
  },
  {
    id: 13,
    name: 'Web Designer',
    description: '',
    grade: '',
    competencies: deepClone(mockCompetencies),
    skills: deepClone(mockInterests)
  },
  {
    id: 14,
    name: 'Area Manager',
    description: '',
    grade: '',
    competencies: deepClone(mockCompetencies),
    skills: deepClone(mockInterests)
  },
  {
    id: 15,
    name: 'Android Developer',
    description: '',
    grade: '',
    competencies: deepClone(mockCompetencies),
    skills: deepClone(mockInterests)
  },
  {
    id: 16,
    name: 'iOS Developer',
    description: '',
    grade: '',
    competencies: deepClone(mockCompetencies),
    skills: deepClone(mockInterests)
  },
  {
    id: 17,
    name: 'Support Staff',
    description: '',
    grade: '',
    competencies: deepClone(mockCompetencies),
    skills: deepClone(mockInterests)
  }
];