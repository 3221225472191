<div class="container-sidebar-reviews-manager-review">
  <app-state [state]="state">
    <ng-container *ngIf="!state.loading && !state.error">
      <div class="request-list">
        <ng-container *ngFor="let managerReview of managerReviews; index as index;">
          <app-evaluation-feedback-request-display [cycle]="cycle" [request]="managerReview" [assessmentGroups]="assessmentGroupsFiltered.assessmentGroupInfo[eEvaluationModuleType.MANAGER_REVIEW]" [startExpanded]="true"></app-evaluation-feedback-request-display>
        </ng-container>
      </div>
    </ng-container>
  </app-state>
</div>
