<div class="container-year-picker" tabindex="0">
  <div class="input-container" (click)="onClickInput()">
    <input class="form-control picker-input" [value]="valueYear" #pickerInput>
  </div>
  <div class="popup-container">
    <div class="years-grid">
      <ng-container *ngFor="let year of years; index as index;">
        <ng-container *ngIf="indexIsOnPage(index, page)">
          <div class="year-item" (click)="onSelectYear(year)" [class.year-item-selected]="valueYear === year">{{year}}</div>
        </ng-container>
      </ng-container>
    </div>
    <div class="pagination-controls">
      <div class="pagination-item" [class.pagination-item-disabled]="page === minPage">
        <span class="fal fa-fw fa-chevron-left" (click)="onClickPreviousPage()"></span>
      </div>
      <div class="pagination-item" [class.pagination-item-disabled]="page === maxPage">
        <span class="fal fa-fw fa-chevron-right" (click)="onClickNextPage()"></span>
      </div>
    </div>
  </div>
</div>
