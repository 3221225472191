import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import {
  EvaluationAdminActivity,
  EvaluationAdminActivityType,
} from '@app/models/evaluation/evaluation-admin-action.model';
import { ModalComponent } from '@app/shared/modal/modal.component';

@Component({
  selector: 'app-evaluation-activity-modal',
  templateUrl: './evaluation-activity-modal.component.html',
  styleUrls: ['./evaluation-activity-modal.component.css']
})
export class EvaluationActivityModalComponent implements OnInit {
  public readonly eEvaluationAdminActivityType = EvaluationAdminActivityType;

  @ViewChild('innerModal') modal?: ModalComponent;

  @Input() activityList: EvaluationAdminActivity[];

  @Output() onModalHide: EventEmitter<boolean>;
  @Output() onModalShow: EventEmitter<boolean>;

  constructor(
    private router: Router
  ) {
    this.activityList = [];

    this.onModalHide = new EventEmitter<boolean>();
    this.onModalShow = new EventEmitter<boolean>();
  }

  ngOnInit(): void {
  }

  show(): void {
    if (this.modal) {
      this.modal.show();
      this.onModalShow.emit(true);
    }
  }

  hide(): void {
    if (this.modal) {
      this.modal.hide();
      this.onModalHide.emit(true);
    }
  }

  navigateToProfile(userID: number) {
    this.router.navigateByUrl('/profile/' + userID);
  }
}
