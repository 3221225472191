import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { GoalCreateService } from '@app/goals/goals-components/goals-create-old/goals-create.service';
import { Goal } from '@app/models/goals/goal.model';
import { IState } from '@app/models/state/state.model';
import { GoalsAPIService } from '@app/shared/api/goals.api.service';
import { Globals } from '@app/shared/globals/globals';
import { GoalCreatePageViews } from '../../goal-create.component';
import { FilterCategory } from '@app/shared/dynamic-filter/interfaces/filter-category';
import { SelectedFilter } from '@app/shared/dynamic-filter/interfaces/selected-filter';
import { ParentFilter } from '@app/models/api/parent-filter.model';
import { PagingParams } from '@app/models/api/paging-params.model';
import { staticGoalSortingParams } from '@app/shared/utils/api-filter/static-goal-params';
import { Page } from '@app/models/api/page.model';
import { FilterOperator } from '@app/models/api/filter-operator.enum';
import { GoalCategory } from '@app/shared/dynamic-filter/categories/goal-category';
import { TranslateService } from '@ngx-translate/core';
import { GoalsMessages } from '@app/goals/goals.messages';

@Component({
  selector: 'app-goal-create-copying',
  templateUrl: './goal-create-copying.component.html',
  styleUrls: ['./goal-create-copying.component.scss']
})
export class GoalCreateCopyingComponent implements OnInit {
  public readonly eGoalsMessages = GoalsMessages;

  @Output() changeView: EventEmitter<GoalCreatePageViews> = new EventEmitter<GoalCreatePageViews>();

  goalsFiltered: Goal[] = [];
  state: IState = {
    loading: true,
    error: false,
    errorMessage: ''
  };
  goalsPage: Page<Goal>;
  parentFilter: ParentFilter = {
    operator: FilterOperator.AND,
    childFilters: []
  };
  pagingParams: PagingParams = {
    pageSize: 15,
    pageNumber: 0
  };
  filterCategories: FilterCategory[] = [];
  selectedFilters: SelectedFilter[] = [];
  searchProperties: string[] = ['title'];

  constructor(
    public globals: Globals,
    private goalsAPIService: GoalsAPIService,
    private goalCreateService: GoalCreateService,
    private translateService: TranslateService
  ) {
  }

  ngOnInit(): void {
    this.populateDynamicFilter();
    this.searchGoals();
  }

  // #region - POPULATION
  populateGoals(goals: Goal[]): void {
    this.goalsFiltered = goals;
  }

  populateDynamicFilter() {
    this.filterCategories = [
      GoalCategory.statusCategory(),
      GoalCategory.completeCategory(),
      GoalCategory.archivedCategory(),
      GoalCategory.priorityCategory(this.translateService),
      GoalCategory.typeCategory(this.globals.terminology)
    ];
  }
  // #endregion

  onSelectGoal(goal: Goal): void {
    this.goalCreateService.sendGoalCopied(goal);
    this.changeView.emit(GoalCreatePageViews.MAIN);
  }

  updateFilter($event: ParentFilter) {
    this.parentFilter = $event;
    this.searchGoals();
  }

  searchGoals() {
    this.state.loading = true;
    this.goalsAPIService.searchGoals(this.pagingParams, staticGoalSortingParams, this.parentFilter).subscribe(goalsPage => {
      this.goalsPage = goalsPage;
      this.goalsFiltered = Goal.getGoalArrayCompletionPercentage(goalsPage.content);
      this.state.loading = false;
    });
  }

  changePageSize($event: number) {
    this.pagingParams.pageSize = $event;
    this.searchGoals();
  }

  changePageNumber($event: number) {
    this.pagingParams.pageNumber = $event;
    this.searchGoals();
  }

  onClickCreateGoal(): void {
    this.changeView.emit(GoalCreatePageViews.MAIN);
  }
}
