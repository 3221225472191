// NOTE: To get fontawesome icons - copy paste the SVG code of the desired icon in here and set width="${icon_size}" and height="${icon_size}" on the svg
const icon_size = 18;
const overwrite_defaults = false;

// OVERWRITING DEFAULTS // TODO: Finish this
const defaults_converted = {
  'accessibility-check': `<svg width="${icon_size}" height="${icon_size}" viewBox="0 0 512 512"><path d="M256 8C119.033 8 8 119.033 8 256s111.033 248 248 248 248-111.033 248-248S392.967 8 256 8zm0 464c-118.663 0-216-96.055-216-216 0-118.663 96.055-216 216-216 118.663 0 216 96.055 216 216 0 118.663-96.055 216-216 216zm139.701-299.677c2.03 8.601-3.296 17.218-11.896 19.249-31.006 7.321-59.956 13.775-88.689 17.114.535 114.852 13.167 139.988 27.255 176.036 4.02 10.289-1.063 21.888-11.352 25.906-10.296 4.022-21.89-1.069-25.906-11.352-9.319-23.841-18.551-44.565-24.271-87.277h-9.685c-5.728 42.773-14.986 63.515-24.27 87.276-4.023 10.299-15.633 15.369-25.906 11.353-10.289-4.019-15.371-15.617-11.353-25.906 14.103-36.083 26.72-61.235 27.255-176.036-28.734-3.339-57.683-9.793-88.689-17.114-8.6-2.031-13.926-10.648-11.895-19.249 2.03-8.6 10.647-13.926 19.249-11.896 105.222 24.845 135.48 24.893 240.904 0 8.599-2.022 17.218 3.297 19.249 11.896zm-177.895-42.517c0-21.094 17.1-38.193 38.194-38.193s38.194 17.1 38.194 38.193S277.094 168 256 168s-38.194-17.1-38.194-38.194z"/></svg>`,
  'action-next': `<svg width="${icon_size}" height="${icon_size}" viewBox="0 0 448 512"><path d="M443.5 162.6l-7.1-7.1c-4.7-4.7-12.3-4.7-17 0L224 351 28.5 155.5c-4.7-4.7-12.3-4.7-17 0l-7.1 7.1c-4.7 4.7-4.7 12.3 0 17l211 211.1c4.7 4.7 12.3 4.7 17 0l211-211.1c4.8-4.7 4.8-12.3.1-17z"/></svg>`,
  'action-prev': `<svg width="${icon_size}" height="${icon_size}" viewBox="0 0 448 512"><path d="M4.465 366.475l7.07 7.071c4.686 4.686 12.284 4.686 16.971 0L224 178.053l195.494 195.493c4.686 4.686 12.284 4.686 16.971 0l7.07-7.071c4.686-4.686 4.686-12.284 0-16.97l-211.05-211.051c-4.686-4.686-12.284-4.686-16.971 0L4.465 349.505c-4.687 4.686-4.687 12.284 0 16.97z"/></svg>`,
  'align-center': `<svg width="${icon_size}" height="${icon_size}" viewBox="0 0 448 512"><path d="M352 52v24a6 6 0 0 1-6 6H102a6 6 0 0 1-6-6V52a6 6 0 0 1 6-6h244a6 6 0 0 1 6 6zM6 210h436a6 6 0 0 0 6-6v-24a6 6 0 0 0-6-6H6a6 6 0 0 0-6 6v24a6 6 0 0 0 6 6zm0 256h436a6 6 0 0 0 6-6v-24a6 6 0 0 0-6-6H6a6 6 0 0 0-6 6v24a6 6 0 0 0 6 6zm340-164H102a6 6 0 0 0-6 6v24a6 6 0 0 0 6 6h244a6 6 0 0 0 6-6v-24a6 6 0 0 0-6-6z"/></svg>`,
  'align-justify': `<svg width="${icon_size}" height="${icon_size}" viewBox="0 0 448 512"><path d="M0 76V52a6 6 0 0 1 6-6h436a6 6 0 0 1 6 6v24a6 6 0 0 1-6 6H6a6 6 0 0 1-6-6zm6 134h436a6 6 0 0 0 6-6v-24a6 6 0 0 0-6-6H6a6 6 0 0 0-6 6v24a6 6 0 0 0 6 6zm0 256h436a6 6 0 0 0 6-6v-24a6 6 0 0 0-6-6H6a6 6 0 0 0-6 6v24a6 6 0 0 0 6 6zm0-128h436a6 6 0 0 0 6-6v-24a6 6 0 0 0-6-6H6a6 6 0 0 0-6 6v24a6 6 0 0 0 6 6z"/></svg>`,
  'align-left': `<svg width="${icon_size}" height="${icon_size}" viewBox="0 0 448 512"><path d="M288 52v24a6 6 0 0 1-6 6H6a6 6 0 0 1-6-6V52a6 6 0 0 1 6-6h276a6 6 0 0 1 6 6zM6 210h436a6 6 0 0 0 6-6v-24a6 6 0 0 0-6-6H6a6 6 0 0 0-6 6v24a6 6 0 0 0 6 6zm0 256h436a6 6 0 0 0 6-6v-24a6 6 0 0 0-6-6H6a6 6 0 0 0-6 6v24a6 6 0 0 0 6 6zm276-164H6a6 6 0 0 0-6 6v24a6 6 0 0 0 6 6h276a6 6 0 0 0 6-6v-24a6 6 0 0 0-6-6z"/></svg>`,
  'align-none': `<svg width="${icon_size}" height="${icon_size}" viewBox="0 0 640 512"><!-- Font Awesome Pro 6.0.0-alpha1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path d="M528 304H484L525 336H528C537 336 544 329 544 320C544 311 537 304 528 304ZM528 80C537 80 544 73 544 64C544 55 537 48 528 48H160L200 80H528ZM528 176H322L363 208H528C537 208 544 201 544 192C544 183 537 176 528 176ZM112 336H318L278 304H112C103 304 96 311 96 320C96 329 103 336 112 336ZM112 432C103 432 96 439 96 448C96 457 103 464 112 464H480L440 432H112ZM634 483L26 3C19 -2 9 -1 3 6C-2 13 -1 23 6 29L614 509C617 511 621 512 624 512C629 512 633 510 637 506C642 499 641 489 634 483ZM112 208H156L115 176H112C103 176 96 183 96 192C96 201 103 208 112 208Z"/></svg>`,
  'align-right': `<svg width="${icon_size}" height="${icon_size}" viewBox="0 0 448 512"><path d="M160 76V52a6 6 0 0 1 6-6h276a6 6 0 0 1 6 6v24a6 6 0 0 1-6 6H166a6 6 0 0 1-6-6zM6 210h436a6 6 0 0 0 6-6v-24a6 6 0 0 0-6-6H6a6 6 0 0 0-6 6v24a6 6 0 0 0 6 6zm0 256h436a6 6 0 0 0 6-6v-24a6 6 0 0 0-6-6H6a6 6 0 0 0-6 6v24a6 6 0 0 0 6 6zm160-128h276a6 6 0 0 0 6-6v-24a6 6 0 0 0-6-6H166a6 6 0 0 0-6 6v24a6 6 0 0 0 6 6z"/></svg>`,
  'arrow-left': ``,
  'arrow-right': ``,
  'bold': `<svg width="${icon_size}" height="${icon_size}" viewBox="0 0 320 512"><path d="M249.139 242.128c33.922-18.988 53.22-53.503 53.22-95.748 0-42.421-19.499-80.713-49.665-97.55C232.561 37.505 207.478 32 176.01 32H12C5.373 32 0 37.373 0 44v8c0 6.627 5.373 12 12 12h19.95v384H12c-6.627 0-12 5.373-12 12v8c0 6.627 5.373 12 12 12h169.68c19.934 0 45.622-1.177 67.493-10.348C292.198 451.781 320 406.247 320 353.65c0-52.496-28.196-96.372-70.861-111.522zM66.041 64.201H176.01c24.929 0 43.694 4.153 57.357 12.692 21.38 13.439 33.642 38.537 33.642 68.858 0 49.531-32.265 82.81-80.289 82.81H66.041V64.201zm167.194 375.685c-12.585 5.325-29.449 7.914-51.555 7.914H66.041V260.76h124.458c56.314 0 94.151 37.837 94.151 94.151 0 40.208-19.2 71.966-51.415 84.975z"/></svg>`,
  'bookmark': ``,
  'border-style': ``,
  'border-width': ``,
  'brightness': ``,
  'browse': ``,
  'cancel': ``,
  'cell-background-color': ``,
  'cell-border-color': ``,
  'change-case': ``,
  'character-count': ``,
  'checklist-rtl': ``,
  'checklist': ``,
  'checkmark': ``,
  'chevron-down': ``,
  'chevron-left': ``,
  'chevron-right': ``,
  'chevron-up': ``,
  'close': ``,
  'code-sample': ``,
  'color-levels': ``,
  'color-picker': ``,
  'color-swatch-remove-color': ``,
  'color-swatch': ``,
  'comment-add': ``,
  'comment': ``,
  'contrast': ``,
  'copy': ``,
  'crop': ``,
  'cut-column': ``,
  'cut-row': ``,
  'cut': ``,
  'document-properties': ``,
  'drag': ``,
  'duplicate-column': ``,
  'duplicate-row': ``,
  'duplicate': ``,
  'edit-block': ``,
  'edit-image': ``,
  'embed-page': ``,
  'embed': ``,
  'emoji': ``,
  'export': ``,
  'fill': ``,
  'flip-horizontally': ``,
  'flip-vertically': ``,
  'format-painter': ``,
  'format': ``,
  'fullscreen': ``,
  'gallery': ``,
  'gamma': ``,
  'help': `<svg width="${icon_size}" height="${icon_size}" viewBox="0 0 512 512"><path d="M256 340c-15.464 0-28 12.536-28 28s12.536 28 28 28 28-12.536 28-28-12.536-28-28-28zm7.67-24h-16c-6.627 0-12-5.373-12-12v-.381c0-70.343 77.44-63.619 77.44-107.408 0-20.016-17.761-40.211-57.44-40.211-29.144 0-44.265 9.649-59.211 28.692-3.908 4.98-11.054 5.995-16.248 2.376l-13.134-9.15c-5.625-3.919-6.86-11.771-2.645-17.177C185.658 133.514 210.842 116 255.67 116c52.32 0 97.44 29.751 97.44 80.211 0 67.414-77.44 63.849-77.44 107.408V304c0 6.627-5.373 12-12 12zM256 40c118.621 0 216 96.075 216 216 0 119.291-96.61 216-216 216-119.244 0-216-96.562-216-216 0-119.203 96.602-216 216-216m0-32C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8z"/></svg>`,
  'highlight-bg-color': ``,
  'home': ``,
  'horizontal-rule': ``,
  'image-options': ``,
  'image': ``,
  'indent': ``,
  'info': ``,
  'insert-character': ``,
  'insert-time': ``,
  'invert': ``,
  'italic': `<svg width="${icon_size}" height="${icon_size}" viewBox="0 0 256 512"><path d="M102.791 64h45.215L73.143 448H23.762a12 12 0 0 0-11.764 9.632l-1.61 8C8.892 473.062 14.573 480 22.151 480h128.817a12 12 0 0 0 11.764-9.632l1.61-8c1.495-7.43-4.186-14.368-11.764-14.368h-45.215l74.864-384h50.011a12 12 0 0 0 11.764-9.632l1.61-8C247.108 38.938 241.427 32 233.849 32H104.401a12 12 0 0 0-11.764 9.632l-1.61 8C89.532 57.062 95.213 64 102.791 64z"/></svg>`,
  'language': ``,
  'line-height': ``,
  'line': ``,
  'link': ``,
  'list-bull-circle': ``,
  'list-bull-default': ``,
  'list-bull-square': ``,
  'list-num-default-rtl': ``,
  'list-num-default': ``,
  'list-num-lower-alpha-rtl': ``,
  'list-num-lower-alpha': ``,
  'list-num-lower-greek-rtl': ``,
  'list-num-lower-greek': ``,
  'list-num-lower-roman-rtl': ``,
  'list-num-lower-roman': ``,
  'list-num-upper-alpha-rtl': ``,
  'list-num-upper-alpha': ``,
  'list-num-upper-roman-rtl': ``,
  'list-num-upper-roman': ``,
  'lock': ``,
  'ltr': ``,
  'more-drawer': ``,
  'new-document': ``,
  'new-tab': ``,
  'non-breaking': ``,
  'notice': ``,
  'ordered-list-rtl': ``,
  'ordered-list': ``,
  'orientation': ``,
  'outdent': ``,
  'page-break': ``,
  'paragraph': ``,
  'paste-column-after': ``,
  'paste-column-before': ``,
  'paste-row-after': ``,
  'paste-row-before': ``,
  'paste': ``,
  'paste-text': ``,
  'permanent-pen': ``,
  'plus': ``,
  'preferences': ``,
  'preview': ``,
  'print': ``,
  'quote': ``,
  'redo': `<svg width="${icon_size}" height="${icon_size}" viewBox="0 0 512 512"><path d="M492 8h-10c-6.627 0-12 5.373-12 12v110.625C426.804 57.047 346.761 7.715 255.207 8.001 118.82 8.428 7.787 120.009 8 256.396 8.214 393.181 119.166 504 256 504c63.926 0 122.202-24.187 166.178-63.908 5.113-4.618 5.354-12.561.482-17.433l-7.069-7.069c-4.503-4.503-11.749-4.714-16.482-.454C361.218 449.238 311.065 470 256 470c-117.744 0-214-95.331-214-214 0-117.744 95.331-214 214-214 82.862 0 154.737 47.077 190.289 116H332c-6.627 0-12 5.373-12 12v10c0 6.627 5.373 12 12 12h160c6.627 0 12-5.373 12-12V20c0-6.627-5.373-12-12-12z"/></svg>`,
  'reload': ``,
  'remove-formatting': ``,
  'remove': ``,
  'resize-handle': ``,
  'resize': ``,
  'restore-draft': ``,
  'rotate-left': ``,
  'rotate-right': ``,
  'rtl': ``,
  'save': ``,
  'search': ``,
  'select-all': ``,
  'selected': ``,
  'settings': ``,
  'sharpen': ``,
  'sourcecode': ``,
  'spell-check': ``,
  'strike-through': `<svg width="${icon_size}" height="${icon_size}" viewBox="0 0 512 512"><path d="M500 272H12c-6.627 0-12-5.373-12-12v-8c0-6.627 5.373-12 12-12h488c6.627 0 12 5.373 12 12v8c0 6.627-5.373 12-12 12zm-199.246 16c34.104 17.688 58.216 40.984 58.216 83.01 0 57.657-45.969 87.221-104.86 87.221-43.044 0-101.711-17.734-101.711-60.762V388c0-6.627-5.373-12-12-12h-10.72c-6.627 0-12 5.373-12 12v15.77c0 60.082 76.565 87.291 136.431 87.291 78.593 0 140.211-46.632 140.211-123.832 0-35.712-11.87-60.522-30.603-79.229h-62.964zm-137.387-64h74.348c-43.357-17.896-75.865-37.601-75.865-84.203 0-52.844 43.64-79.03 96.041-79.03 32.008 0 90.37 12.598 90.37 44.38V116c0 6.627 5.373 12 12 12h10.721c6.627 0 12-5.373 12-12V96.327c0-44.421-64.45-68.391-125.091-68.391-72.526 0-131.392 41.225-131.392 115.011 0 38.214 14.813 63.053 36.868 81.053z"/></svg>`,
  'subscript': ``,
  'superscript': ``,
  'table-caption': ``,
  'table-cell-classes': ``,
  'table-cell-properties': ``,
  'table-cell-select-all': ``,
  'table-cell-select-inner': ``,
  'table-classes': ``,
  'table-delete-column': ``,
  'table-delete-row': ``,
  'table-delete-table': ``,
  'table-insert-column-after': ``,
  'table-insert-column-before': ``,
  'table-insert-row-above': ``,
  'table-insert-row-after': ``,
  'table-left-header': ``,
  'table-merge-cells': ``,
  'table-row-numbering-rtl': ``,
  'table-row-numbering': ``,
  'table-row-properties': ``,
  'table-split-cells': ``,
  'table': ``,
  'table-top-header': ``,
  'template': ``,
  'temporary-placeholder': ``,
  'text-color': ``,
  'toc': ``,
  'translate': ``,
  'underline': `<svg width="${icon_size}" height="${icon_size}" viewBox="0 0 448 512"><path d="M0 500v-8c0-6.627 5.373-12 12-12h424c6.627 0 12 5.373 12 12v8c0 6.627-5.373 12-12 12H12c-6.627 0-12-5.373-12-12zM291.142 12v8c0 6.627 5.373 12 12 12h42.72v226.509c0 85.451-46.451 128.726-122.145 128.726-74.322 0-121.569-40.289-121.569-127.572V32h42.721c6.627 0 12-5.373 12-12v-8c0-6.627-5.373-12-12-12H26.659c-6.627 0-12 5.373-12 12v8c0 6.627 5.373 12 12 12H69.38v228.818c0 106.811 63.591 158.094 154.913 158.094 89.287 0 154.337-52.813 154.337-158.094V32h42.721c6.627 0 12-5.373 12-12v-8c0-6.627-5.373-12-12-12H303.142c-6.628 0-12 5.373-12 12z"/></svg>`,
  'undo': `<svg width="${icon_size}" height="${icon_size}" viewBox="0 0 512 512"><path d="M20 8h10c6.627 0 12 5.373 12 12v110.625C85.196 57.047 165.239 7.715 256.793 8.001 393.18 8.428 504.213 120.009 504 256.396 503.786 393.181 392.834 504 256 504c-63.926 0-122.202-24.187-166.178-63.908-5.113-4.618-5.354-12.561-.482-17.433l7.069-7.069c4.503-4.503 11.749-4.714 16.482-.454C150.782 449.238 200.935 470 256 470c117.744 0 214-95.331 214-214 0-117.744-95.331-214-214-214-82.862 0-154.737 47.077-190.289 116H180c6.627 0 12 5.373 12 12v10c0 6.627-5.373 12-12 12H20c-6.627 0-12-5.373-12-12V20c0-6.627 5.373-12 12-12z"/></svg>  `,
  'unlink': ``,
  'unlock': ``,
  'unordered-list': ``,
  'unselected': ``,
  'upload': ``,
  'user': ``,
  'vertical-align': ``,
  'visualblocks': ``,
  'visualchars': ``,
  'warning': ``,
  'zoom-in': ``,
  'zoom-out': ``,
}
export const FRANKLI_TINYMCE_ICONS = {
  ...(overwrite_defaults ? defaults_converted : {}),
  
  // CUSTOM
  'bullseye': `<svg width="${icon_size}" height="${icon_size}" viewBox="0 0 512 512"><path d="M256 176c-44 0-80 36-80 80s36 80 80 80 80-36 80-80-36-80-80-80zm0 128c-26 0-48-22-48-48s22-48 48-48 48 22 48 48-22 48-48 48zm0-208c-88 0-160 72-160 160s72 160 160 160 160-72 160-160S344 96 256 96zm0 288c-71 0-128-57-128-128s57-128 128-128 128 57 128 128-57 128-128 128zm0-368C124 16 16 124 16 256s108 240 240 240 240-108 240-240S388 16 256 16zm0 448c-115 0-208-93-208-208S141 48 256 48s208 93 208 208-93 208-208 208z"/></svg>`
}