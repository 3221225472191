import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { CommonMessages } from '@app/constants/common.messages';
import { GoalsMessages } from '@app/goals/goals.messages';
import { TaskAlignmentType } from '@app/domain/task/model/task-alignment-type.enum';
import { DateUtils } from '@app/shared/utils/date.utils';
import { FrankliValidators } from '@app/shared/validators/validators';
import { IMyDate } from 'angular-mydatepicker';
import { CommonGoalCreateFormPart } from '../../goal-create-views/goal-create-main/goal-create-main.component';

export const GoalCreateTaskValidationLimits = {
  title: {
    min: 1,
    max: 250
  },
  owners: {
    min: 1
  },
};

export const initTaskFormGroup = (date?: Date): FormGroup => {
  const minDate = new Date();
  minDate.setHours(0, 0, 0, 0);

  let maxDate = undefined;

  if (date) {
    maxDate = new Date(date);
  } else {
    maxDate = new Date();
  }
  maxDate.setHours(0, 0, 0, 0);
  maxDate.setDate(maxDate.getDate() + 1);

  return new FormGroup({
    id: new FormControl(
      null,
      []
    ),
    title: new FormControl(
      '',
      [Validators.required, Validators.maxLength(GoalCreateTaskValidationLimits.title.max)]
    ),
    description: new FormControl(
      '',
      []
    ),
    dueDate: new FormControl(
      null,
      [FrankliValidators.minDate(minDate), FrankliValidators.maxDate(maxDate)]
    ),
    alignmentType: new FormControl(
      TaskAlignmentType.GOAL,
      []
    ),
    alignmentId: new FormControl(
      null,
      []
    ),
    owners: new FormControl(
      [],
      [Validators.required, FrankliValidators.minLengthArray(GoalCreateTaskValidationLimits.owners.min)]
    )
  });
};

@Component({
  selector: 'app-goal-create-part-tasks',
  templateUrl: './goal-create-part-tasks.component.html',
  styleUrls: ['./goal-create-part-tasks.component.scss']
})
export class GoalCreatePartTasksComponent implements OnInit, CommonGoalCreateFormPart {
  public readonly eGoalsMessages = GoalsMessages;
  public readonly eCommonMessages = CommonMessages;

  @Input() controlTasks: FormArray;
  @Input() controlDueDate: FormControl;
  @Input() submitted: boolean;
  @Input() submittedDraft: boolean;
  @Input() parentDropdownOpen: boolean;
  @Input() minDueDate: IMyDate;
  @Input() maxDueDate: IMyDate | null;

  @Output() onSetParentDropdown: EventEmitter<boolean>;

  calendarOpen: boolean;

  get taskList(): FormGroup[] {
    return this.controlTasks.controls as FormGroup[];
  }

  constructor() {
    this.controlTasks = new FormArray([]);
    this.controlDueDate = new FormControl(new Date(), []);
    this.calendarOpen = false;
    this.submitted = false;
    this.submittedDraft = false;
    this.parentDropdownOpen = true;
    this.minDueDate = DateUtils.convertDateToPickerFormat(new Date());
    this.maxDueDate = null;
    this.onSetParentDropdown = new EventEmitter<boolean>();
  }

  ngOnInit(): void {
  }

  checkOverflowProtection(isOpen: boolean, index: number, isLast: boolean): void {
    const taskCount = this.taskList.length;    
    if ((taskCount > 0) && !isLast) {
      this.calendarOpen = false;
      return;
    }

    this.calendarOpen = isOpen;
  }

  onRemoveTask(index: number): void {
    this.controlTasks.removeAt(index);
  }

  onAddRowClick(index: number): void {
    this.addNewTaskAtIndex(index);
  }

  addNewTaskAtIndex(index: number): void {
    const goalDueDate = this.controlDueDate.value;
    const newFormGroup = goalDueDate ? initTaskFormGroup(goalDueDate) : initTaskFormGroup();
    this.controlTasks.insert(index, newFormGroup);
  }

  setParentDropdown(expanded: boolean): void {
    this.onSetParentDropdown.emit(expanded);
  }

  focus(): void {
    // TODO:
  }
}
