<div class="cotnainer-transfer-schedule">
  <app-header [title]="'Transfer 1:1 to a new manager'" [description]="'This will move a 1:1 schedule to a new manager. You can attach a message for them which will be added to the latest meeting'"></app-header>

  <div class="frankli-form-group frankli-form-group-filled">
    <label for="newmanager">New manager</label>
    <app-user-picker id="newmanager" [formControl]="controlNewManager" [blacklistedIds]="blacklistedManagerIds" [customOptions]="canTransferToSelf ? [ globals.user ] : []"></app-user-picker>
    <div class="frankli-form-group-alert">
      <ng-container *ngIf="submitted && controlNewManager.invalid">
        <div *ngIf="controlNewManager.hasError('required')">You must choose a new manager</div>
      </ng-container>
    </div>
  </div>

  <div class="frankli-form-group frankli-form-group-filled">
    <label for="message">Message</label>
    <textarea id="message" class="form-control noresize" rows="5" [formControl]="controlMessage"></textarea>
    <div class="text-right">
      <span>{{(controlMessage.value) ? controlMessage.value.length : '0'}}</span>
      <span>&nbsp;/&nbsp;</span>
      <span>255</span>
    </div>
    <div class="frankli-form-group-alert">
      <ng-container *ngIf="submitted && controlMessage.invalid">
        <div *ngIf="controlMessage.hasError('required')">{{eCommonMessages.FORM_REQUIRED | translate}}</div>
        <div *ngIf="controlMessage.hasError('maxlength')">{{eCommonMessages.FORM_MAX_CHARACTER_VALUE | translate: {maxValue: 255 } }}</div>
      </ng-container>
    </div>
  </div>

  <div class="text-center p-top-15">
    <app-button (onClick)="onSubmit()">Submit</app-button>
  </div>
</div>
