<div class="display-container" *ngIf="feedback">
  <!-- Sent by X to Y on Date -->
  <div class="area-metadata" *ngIf="(feedback.creator && showSourceUser) || (feedback.timestamp)">
    <span>Sent&nbsp;</span>

    <!-- Source user -->
    <span class="text-nowrap" *ngIf="!feedback.anonymous && feedback.creator && showSourceUser">
      <span>by</span>
      <span class="area-source-user">
        <span class="user-picture">
          <!-- TODO: Add firstName and lastName -->
          <app-profile-photo [src]="feedback.creator.imageUrl"></app-profile-photo>
        </span>
        <span>&nbsp;{{feedback.creator.firstName}} {{feedback.creator.lastName}}</span>
      </span>
    </span>

    <!-- Timestamp -->
    <span class="text-nowrap" *ngIf="feedback.timestamp">
      <span>on&nbsp;</span>
      <span class="area-timestamp">{{feedback.timestamp | date}}</span>
    </span>
  </div>

  <!-- Title -->
  <div class="area-title">
    <div class="feedback-question">Enter a title</div>
    <ng-container *ngIf="feedback.title; else noAnswer;">
      <div>{{feedback.title}}</div>
    </ng-container>
  </div>

  <!-- Description -->
  <div class="area-description">
    <div class="feedback-question">Describe the feedback you received</div>
    <ng-container *ngIf="feedback.description; else noAnswer;">
      <app-rich-display [text]="feedback.description"></app-rich-display>
    </ng-container>
  </div>
  
  <!-- File -->
  <div class="area-impact">
    <div class="feedback-question">Would you like to attach a file?</div>
    <ng-container *ngIf="feedback.file; else noAnswer;">
      <app-uploaded-file-link-preview [fileURL]="feedback.file"></app-uploaded-file-link-preview>
    </ng-container>
  </div>
</div>

<ng-template #noAnswer>
  <div>N/A</div>
</ng-template>