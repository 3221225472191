import { SentimentScaleMessages } from '@app/domain/sentiment_scale/locale/sentiment-scale.messages';

export const OneToOneMessages = {
  ONE_TO_ONE: 'one-to-one.one-to-one',
  ONE_TO_ONES: 'one-to-one.one-to-ones',
  ONE_TO_ONES_DESCRIPTION: 'one-to-one.one-to-ones-description',
  TEAM_MEETING: 'one-to-one.team-meeting',
  TEAM_MEETINGS: 'one-to-one.team-meetings',
  TEAM_MEETINGS_DESCRIPTION: 'one-to-one.team-meetings-description',
  MANAGE_ONE_TO_ONES: 'one-to-one.manage-one-to-ones',
  CREATE_SCHEDULE: 'one-to-one.create-schedule',
  EDIT_SCHEDULE: 'one-to-one.edit-schedule',
  CREATE_TOOLTIP: 'one-to-one.create-tooltip',
  MANAGED_BY: 'one-to-one.managed-by',
  PURPOSE: 'one-to-one.purpose',
  PARTICIPANT: 'one-to-one.participant',
  NEXT_MEETING: 'one-to-one.next-meeting',
  PREVIOUS_MEETING: 'one-to-one.previous-meeting',
  FREQUENCY: 'one-to-one.frequency',
  STATUS: 'one-to-one.status',
  ACTIVE: 'one-to-one.active',
  TALKING_POINTS: 'one-to-one.talking-points',
  ACTION_POINTS: 'one-to-one.action-points',
  PAUSED: 'one-to-one.paused',
  COMPLETED: 'one-to-one.completed',
  CANCELLED: 'one-to-one.cancelled',
  MISSED: 'one-to-one.missed',
  SCHEDULED: 'one-to-one.scheduled',
  IN_PROGRESS: 'one-to-one.in-progress',
  FLEXIBLE: 'one-to-one.flexible',
  FLEXIBLE_MEETING: 'one-to-one.flexible-meeting',
  REOPENED: 'one-to-one.reopened',
  LINKED_TO_REVIEW_CYCLE: 'one-to-one.linked-to-review-cycle',
  EDIT_SCHEDULE_TOOLTIP: 'one-to-one.edit-schedule-tooltip',
  RESUME_SCHEDULE_TOOLTIP: 'one-to-one.resume-schedule-tooltip',
  UNARCHIVE_SCHEDULE_TOOLTIP: 'one-to-one.unarchive-schedule-tooltip',
  PAUSE_SCHEDULE_TOOLTIP: 'one-to-one.pause-schedule-tooltip',
  ARCHIVE_SCHEDULE_TOOLTIP: 'one-to-one.archive-schedule-tooltip',
  DELETE_SCHEDULE_TOOLTIP: 'one-to-one.delete-schedule-tooltip',
  LOADING_SPINNER_TOOLTIP: 'one-to-one.loading-spinner-tooltip',
  NO_SCHEDULES_FOR_SEARCH: 'one-to-one.no-schedules-for-search',
  NO_SCHEDULES_FOR_USER: 'one-to-one.no-schedules-for-user',
  LEARN_PROMPT: 'one-to-one.learn-prompt',
  CREATE_PROMPT: 'one-to-one.create-prompt',
  MEETING_PAUSED_TOAST: 'one-to-one.meeting-paused-toast',
  MEETING_RESUMED_TOAST: 'one-to-one.meeting-resumed-toast',
  MEETING_UNARCHIVED_TOAST: 'one-to-one.meeting-unarchived-toast',
  MEETING_ARCHIVED_TOAST: 'one-to-one.meeting-archived-toast',
  MEETING_DELETED_TOAST: 'one-to-one.meeting-deleted-toast',
  ARCHIVE_WARNING: 'one-to-one.archive-warning',
  DELETE_WARNING: 'one-to-one.delete-warning',
  NO_RESULTS_FOR_USER_SEARCH: 'one-to-one.no-results-for-user-search',
  CREATE_SEARCH_PLACEHOLDER: 'one-to-one.create-search-placeholder',
  CREATE_PURPOSE_PLACEHOLDER: 'one-to-one.create-purpose-placeholder',
  LOCATION_TYPE: 'one-to-one.location-type',
  LOCATION_DETAILS: 'one-to-one.location-details',
  MEETING_TIME: 'one-to-one.meeting-time',
  MEETING_TIME_IN_PAST: 'one-to-one.meeting-time-in-past',
  MEETING_TIME_EMPTY: 'one-to-one.meeting-time-empty',
  MEETING_LENGTH: 'one-to-one.meeting-length',
  MEETING_LENGTH_15_MINUTES: 'one-to-one.meeting-length-15-minutes',
  MEETING_LENGTH_30_MINUTES: 'one-to-one.meeting-length-30-minutes',
  MEETING_LENGTH_45_MINUTES: 'one-to-one.meeting-length-45-minutes',
  MEETING_LENGTH_60_MINUTES: 'one-to-one.meeting-length-60-minutes',
  REVIEW_CYCLE: 'one-to-one.review-cycle',
  NOT_LINKED: 'one-to-one.not-linked',
  ALREADY_EXISTS_FOR_CYCLE: 'one-to-one.already-exists-for-cycle',
  MONTHLY_WARNING: 'one-to-one.monthly-warning',
  QUARTERLY_WARNING: 'one-to-one.quarterly-warning',
  RECURRING_TALKING_POINTS: 'one-to-one.recurring-talking-points',
  CHOOSE_TEMPLATE: 'one-to-one.choose-template',
  CLEAR_ALL: 'one-to-one.clear-all',
  CHOOSE_TEMPLATE_TOOLTIP: 'one-to-one.choose-template-tooltip',
  CLEAR_ALL_TOOLTIP: 'one-to-one.clear-all-tooltip',
  COACHING_SESSION_PURPOSE: 'one-to-one.coaching-session-purpose',
  MENTORING_SESSION_PURPOSE: 'one-to-one.mentoring-session-purpose',
  REVIEW_CYCLE_PURPOSE: 'one-to-one.review-cycle-purpose',
  LINKED_CYCLE_PURPOSE: 'one-to-one.linked-cycle-purpose',
  NO_PERMISSION_TO_EDIT: 'one-to-one.no-permission-to-edit',
  CANNOT_EDIT_ARCHIVED: 'one-to-one.cannot-edit-archived',
  CANNOT_EDIT_IN_PROGRESS: 'one-to-one.cannot-edit-in-progress',
  TEAMS_LOCATION: 'one-to-one.teams-location',
  SLACK_LOCATION: 'one-to-one.slack-location',
  IN_PERSON_LOCATION_PLACEHOLDER: 'one-to-one.in-person-location-placeholder',
  TEAMS_LOCATION_PLACEHOLDER: 'one-to-one.teams-location-placeholder',
  OTHER_LOCATION_PLACEHOLDER: 'one-to-one.other-location-placeholder',
  SKYPE_LOCATION_PLACEHOLDER: 'one-to-one.skype-location-placeholder',
  SLACK_LOCATION_PLACEHOLDER: 'one-to-one.slack-location-placeholder',
  ZOOM_LOCATION_PLACEHOLDER: 'one-to-one.zoom-location-placeholder',
  MEET_LOCATION_PLACEHOLDER: 'one-to-one.meet-location-placeholder',
  WEBEX_LOCATION_PLACEHOLDER: 'one-to-one.webex-location-placeholder',
  CREATE_SUCCESS_TOAST: 'one-to-one.create-success-toast',
  EDIT_SUCCESS_TOAST: 'one-to-one.edit-success-toast',
  CLEAR_ALL_WARNING: 'one-to-one.clear-all-warning',
  FIRST: 'one-to-one.first',
  SECOND: 'one-to-one.second',
  THIRD: 'one-to-one.third',
  FOURTH: 'one-to-one.fourth',
  LAST: 'one-to-one.last',
  ADDED_BY_OTHER: 'one-to-one.added-by-other',
  ADDED_BY_YOU: 'one-to-one.added-by-you',
  EDIT_TALKING_POINT_TOOLTIP: 'one-to-one.edit-talking-point-tooltip',
  ADD_TALKING_POINT_TOOLTIP: 'one-to-one.add-talking-point-tooltip',
  HIDE_TALKING_POINT_TOOLTIP: 'one-to-one.hide-talking-point-tooltip',
  REMOVE_TALKING_POINT_TOOLTIP: 'one-to-one.remove-talking-point-tooltip',
  CONFIRM_CHANGES: 'one-to-one.confirm-changes',
  DISCARD_CHANGES: 'one-to-one.discard-changes',
  ADD_TALKING_POINT_COMMENT_TOOLTIP: 'one-to-one.add-talking-point-comment-tooltip',
  NO_TALKING_POINTS: 'one-to-one.no-talking-points',
  RESET_TEXT: 'one-to-one.reset-text',
  ADD_TALKING_POINT: 'one-to-one.add-talking-point',
  REMOVE_TALKING_POINT: 'one-to-one.remove-talking-point',
  TYPE_TALKING_POINT_PLACEHOLDER: 'one-to-one.type-talking-point-placeholder',
  ADD_TALKING_POINT_PROMPT: 'one-to-one.add-talking-point-prompt',
  ADDED_TALKING_POINT_TOAST: 'one-to-one.added-talking-point-toast',
  REMOVED_TALKING_POINT_TOAST: 'one-to-one.removed-talking-point-toast',
  BUMPED_TALKING_POINT_TOAST: 'one-to-one.bumped-talking-point-toast',
  COMPLETED_TALKING_POINT_TOAST: 'one-to-one.completed-talking-point-toast',
  UNCOMPLETED_TALKING_POINT_TOAST: 'one-to-one.uncompleted-talking-point-toast',
  ADDED_TALKING_POINT_COMMENT_TOAST: 'one-to-one.added-talking-point-comment-toast',
  SEARCH_TEMPLATES: 'one-to-one.search-templates',
  TEMPLATE: 'one-to-one.template',
  DESCRIPTION: 'one-to-one.description',
  NO_TALKING_POINTS_FOR_TEMPLATE: 'one-to-one.no-talking-points-for-template',
  NO_RESULTS_FOR_TEMPLATE_SEARCH: 'one-to-one.no-results-for-template-search',
  NO_TEMPLATES_FOR_COMPANY: 'one-to-one.no-templates-for-company',
  AVAILABILITY: 'one-to-one.availability',
  CHOOSE_DATE_TOOLTIP: 'one-to-one.choose-date-tooltip',
  YOUR_SCHEDULE: 'one-to-one.your-schedule',
  THEIR_SCHEDULE: 'one-to-one.their-schedule',
  OTHER_SCHEDULE: 'one-to-one.other-schedule',
  AWAY: 'one-to-one.away',
  BUSY: 'one-to-one.busy',
  FREE: 'one-to-one.free',
  OUT_OF_OFFICE: 'one-to-one.out-of-office',
  TENTATIVE: 'one-to-one.tentative',
  WORKING_ELSEWHERE: 'one-to-one.working-elsewhere',
  START_MEETING_AT: 'one-to-one.start-meeting-at',
  SELECTION: 'one-to-one.selection',
  OTHER: 'one-to-one.other',
  IN_PERSON: 'one-to-one.in-person',
  COMPLETE_MEETING_TOOLTIP: 'one-to-one.complete-meeting-tooltip',
  TAKE_TOUR: 'one-to-one.take-tour',
  FINISH_VIEWING_TALKING_POINTS: 'one-to-one.finish-viewing-talking-points',
  FINISH_VIEWING_TALKING_POINTS_TOOLTIP: 'one-to-one.finish-viewing-talking-points-tooltip',
  REOPEN_TOOLTIP: 'one-to-one.reopen-tooltip',
  REOPEN: 'one-to-one.reopen',
  MARK_AS_COMPLETED_TOOLTIP: 'one-to-one.mark-as-completed-tooltip',
  MARK_AS_COMPLETED: 'one-to-one.mark-as-completed',
  LINKED_TO_REVIEW_CYCLE_NAME: 'one-to-one.linked-to-review-cycle-name',
  LINKED_TO_REVIEW_CYCLE_NAME_CREATOR_TOOLTIP: 'one-to-one.linked-to-review-cycle-name-creator-tooltip',
  LINKED_TO_REVIEW_CYCLE_NAME_PARTICIPANT_TOOLTIP: 'one-to-one.linked-to-review-cycle-name-participant-tooltip',
  START_TEAMS_CALL_TOOLTIP: 'one-to-one.start-teams-call-tooltip',
  OPEN_TEAMS: 'one-to-one.open-teams',
  INCOMPLETE_FROM_PREVIOUS: 'one-to-one.incomplete-from-previous',
  CURRENTLY_SCHEDULED: 'one-to-one.currently-scheduled',
  START_ACTION_PROMPT: 'one-to-one.start-action-prompt',
  START_ACTION_PROMPT_FLEXIBLE: 'one-to-one.start-action-prompt-flexible',
  START_MEETING: 'one-to-one.start-meeting',
  START_MEETING_TOOLTIP: 'one-to-one.start-meeting-tooltip',
  SCHEDULED_FOR_DATE: 'one-to-one.scheduled-for-date',
  CANCEL_MEETING_TOOLTIP: 'one-to-one.cancel-meeting-tooltip',
  RESCHEDULE_MEETING_TOOLTIP: 'one-to-one.reschedule-meeting-tooltip',
  VIEW_TALKING_POINTS_TOOLTIP: 'one-to-one.view-talking-points-tooltip',
  SCHEDULE: 'one-to-one.schedule',
  MEETINGS: 'one-to-one.meetings',
  VIEW_CALENDAR: 'one-to-one.view-calendar',
  VIEW_LIST: 'one-to-one.view-list',
  VIEW_CALENDAR_TOOLTIP: 'one-to-one.view-calendar-tooltip',
  VIEW_LIST_TOOLTIP: 'one-to-one.view-list-tooltip',
  SINCE_LAST: 'one-to-one.since-last',
  CURRENT: 'one-to-one.current',
  NO_GOALS_CREATOR: 'one-to-one.no-goals-creator',
  NO_GOALS_PARTICIPANT: 'one-to-one.no-goals-participant',
  NO_PRAISE_CREATOR: 'one-to-one.no-praise-creator',
  NO_PRAISE_PARTICIPANT: 'one-to-one.no-praise-participant',
  NO_IDEAS_CREATOR: 'one-to-one.no-ideas-creator',
  NO_IDEAS_PARTICIPANT: 'one-to-one.no-ideas-participant',
  NO_SELF_REFLECTION_CREATOR: 'one-to-one.no-self-reflection-creator',
  NO_SELF_REFLECTION_PARTICIPANT: 'one-to-one.no-self-reflection-participant',
  NO_MANAGER_REVIEW_CREATOR: 'one-to-one.no-manager-review-creator',
  NO_MANAGER_REVIEW_PARTICIPANT: 'one-to-one.no-manager-review-participant',
  PIN_VIEW: 'one-to-one.pin-view',
  UNPIN_VIEW: 'one-to-one.unpin-view',
  SHARED_NOTES_OTHER: 'one-to-one.shared-notes-other',
  SHARED_NOTES: 'one-to-one.shared-notes',
  PRIVATE_NOTES: 'one-to-one.private-notes',
  FILES: 'one-to-one.files',
  NO_SHARED_NOTES: 'one-to-one.no-shared-notes',
  COLLAPSE_AND_RETURN: 'one-to-one.collapse-and-return',
  EXPAND_TO_FULLSCREEN: 'one-to-one.expand-to-fullscreen',
  NO_FILES: 'one-to-one.no-files',
  REPEAT_EVERY: 'one-to-one.repeat-every',
  DAY: 'one-to-one.day',
  DAYS: 'one-to-one.days',
  MONTH: 'one-to-one.month',
  MONTHS: 'one-to-one.months',
  YEAR: 'one-to-one.year',
  YEARS: 'one-to-one.years',
  WEEK: 'one-to-one.week',
  WEEKS: 'one-to-one.weeks',
  MUST_SELECT_DAY: 'one-to-one.must-select-day',
  MONTH_ON_DAY: 'one-to-one.month-on-day',
  MONTH_ON_DATE: 'one-to-one.month-on-date',
  SCHEDULE_ENDS: 'one-to-one.schedule-ends',
  NEVER: 'one-to-one.never',
  ON: 'one-to-one.on',
  AFTER: 'one-to-one.after',
  MUST_BE_AFTER_MEETING: 'one-to-one.must-be-after-meeting',
  OCCURRENCES: 'one-to-one.occurrences',
  GENERATED_LINK: 'one-to-one.generated-link',
  MANUAL_LINK: 'one-to-one.manual-link',
  NO_ACTION_POINTS: 'one-to-one.no-action-points',
  ADD_ACTION_POINT: 'one-to-one.add-action-point',
  EDIT_ACTION_POINT: 'one-to-one.edit-action-point',
  NEWLY_ADDED_ACTION_POINTS: 'one-to-one.newly-added-action-points',
  ACTION_POINTS_TO_REVIEW: 'one-to-one.action-points-to-review',
  ADD_ACTION_POINT_TOOLTIP: 'one-to-one.add-action-point-tooltip',
  COMPLETE_ACTION_POINT_TOOLTIP: 'one-to-one.complete-action-point-tooltip',
  ACTION_POINT_PLACEHOLDER_1: 'one-to-one.action-point-placeholder-1',
  ACTION_POINT_PLACEHOLDER_2: 'one-to-one.action-point-placeholder-2',
  ACTION_POINT_PLACEHOLDER_3: 'one-to-one.action-point-placeholder-3',
  COMPLETED_JUST_NOW: 'one-to-one.completed-just-now',
  COMPLETED_AGO: 'one-to-one.completed-ago',
  DUE_TODAY: 'one-to-one.due-today',
  DUE_IN: 'one-to-one.due-in',
  OVERDUE_BY: 'one-to-one.overdue-by',
  OVERVIEW_BREADCRUMB: 'one-to-one.overview-breadcrumb',
  VIEW_SCHEDULE_BREADCRUMB: 'one-to-one.view-schedule-breadcrumb',
  VIEW_SCHEDULE_WITH_BREADCRUMB: 'one-to-one.view-schedule-with-breadcrumb',
  CREATE_SCHEDULE_BREADCRUMB: 'one-to-one.create-schedule-breadcrumb',
  CUSTOM_FREQUENCY_REPEATS_SINGULAR: 'one-to-one.custom-frequency-repeats-singular',
  CUSTOM_FREQUENCY_REPEATS_PLURAL: 'one-to-one.custom-frequency-repeats-plural',
  CUSTOM_FREQUENCY_REPEATS_ON_SINGULAR: 'one-to-one.custom-frequency-repeats-on-singular',
  CUSTOM_FREQUENCY_REPEATS_ON_PLURAL: 'one-to-one.custom-frequency-repeats-on-plural',
  CUSTOM_FREQUENCY_REPEATS_MONTHLY_ON_DAY_SINGULAR: 'one-to-one.custom-frequency-repeats-monthly-on-day-singular',
  CUSTOM_FREQUENCY_REPEATS_MONTHLY_ON_DAY_PLURAL: 'one-to-one.custom-frequency-repeats-monthly-on-day-plural',
  CUSTOM_FREQUENCY_REPEATS_MONTHLY_ON_DATE_SINGULAR: 'one-to-one.custom-frequency-repeats-monthly-on-date-singular',
  CUSTOM_FREQUENCY_REPEATS_MONTHLY_ON_DATE_PLURAL: 'one-to-one.custom-frequency-repeats-monthly-on-date-plural',
  CUSTOM_FREQUENCY_ENDS_AFTER_SINGULAR: 'one-to-one.custom-frequency-ends-after-singular',
  CUSTOM_FREQUENCY_ENDS_AFTER_PLURAR: 'one-to-one.custom-frequency-ends-after-plural',
  CUSTOM_FREQUENCY_ENDS_ON: 'one-to-one.custom-frequency-ends-on',
  CANCEL_MEETING: 'one-to-one.cancel-meeting',
  CANCEL_MEETING_INDIVIDUAL: 'one-to-one.cancel-meeting-individual',
  CANCEL_MEETING_SERIES: 'one-to-one.cancel-meeting-series',
  KEEP_MEETING: 'one-to-one.keep-meeting',

  CREATE_TEMPLATE: 'one-to-one.create-template',
  WARNING_TEMPLATE_DELETED: 'one-to-one.warning-template-deleted',
  SUCCESS_TEMPLATE_DELETED: 'one-to-one.success-template-deleted',
  SUCCESS_TEMPLATE_CREATED: 'one-to-one.success-template-created',
  SUCCESS_TEMPLATE_UPDATED: 'one-to-one.success-template-updated',
  TOOLTIP_TEMPLATE_COPY: 'one-to-one.tooltip-template-copy',
  TOOLTIP_TEMPLATE_EDIT: 'one-to-one.tooltip-template-edit',
  TOOLTIP_TEMPLATE_DELETE: 'one-to-one.tooltip-template-delete',
  TOOLTIP_TEMPLATE_DELETE_DEFAULT: 'one-to-one.tooltip-template-delete-default',
  PLACEHOLDER_TEMPLATE_SEARCH: 'one-to-one.placeholder-template-search',
  TEMPLATE_INFORMATION: 'one-to-one.template-information',
  TEMPLATE_INFORMATION_DESCRIPTION: 'one-to-one.template-information-description',
  TEMPLATE_TALKING_POINTS: 'one-to-one.template-talking-points',
  TEMPLATE_TALKING_POINTS_DESCRIPTION: 'one-to-one.template-talking-points-description',
  TALKING_POINT_REQUIRED: 'one-to-one.talking-point-required',
  TEMPLATES: {
    NAME: 'one-to-one.templates.name',
    DESCRIPTION: 'one-to-one.templates.description',
    ACCESS: 'one-to-one.templates.access',
    ACCESS_OPTIONS: {
      EMPLOYEES: 'one-to-one.templates.access-options.employees',
      MANAGERS: 'one-to-one.templates.access-options.managers',
      ADMINS: 'one-to-one.templates.access-options.admins'
    },
    CREATOR: 'one-to-one.templates.creator',
    DEFAULT: 'one-to-one.templates.default',
    DEFAULT_TEMPLATE: 'one-to-one.templates.default-template',
    DEFAULT_OPTIONS: {
      DEFAULT: 'one-to-one.templates.default-options.default',
      NOT_DEFAULT: 'one-to-one.templates.default-options.not-default'
    },
    TALKING_POINT: 'one-to-one.templates.talking-point',
    CANNOT_RESTRICT_ACCESS_DEFAULT: 'one-to-one.templates.cannot-restrict-access-default',
  },
  MANAGE_TEMPLATES: 'one-to-one.manage-templates',
  MANAGE_TEMPLATES_DESCRIPTION: 'one-to-one.manage-templates-description',
  MANAGE_TEMPLATES_DESCRIPTION_SHORT: 'one-to-one.manage-templates-description-short',
  TEMPLATES_HEADER_CREATING: 'one-to-one.templates-header-creating',
  TEMPLATES_HEADER_EDITING: 'one-to-one.templates-header-editing',
  CHANGE_TIMEZONE: 'one-to-one.change-timezone',
  TIMEZONE_MESSAGE: 'one-to-one.timezone-message',
  TALKING_POINT_TEMPLATE: 'one-to-one.talking-point-template',
  JOIN_CALL: 'one-to-one.join-call',
  TRANSFER_SCHEDULE: 'one-to-one.transfer-schedule',
  USERS_SCHEDULES: 'one-to-one.users-schedules',
  USERS_SCHEDULES_DESCRIPTION: 'one-to-one.users-schedules-description',
  MARK_MEETING_AS_COMPLETED: 'one-to-one.mark-meeting-as-completed',
  MARK_MEETING_AS_REOPENED: 'one-to-one.mark-meeting-as-reopened',

  CALENDAR_REBUILD_SUCCESS: 'one-to-one.calendar-rebuild-success',
  SCHEDULE_TRANSFER_SUCCESS: 'one-to-one.schedule-transfer-success',
  MEETING_RESCHEDULE_SUCCESS: 'one-to-one.meeting-reschedule-success',
  MEETING_STATUS_UPDATE_SUCCESS: 'one-to-one.meeting-status-update-success',
  MEETING_STATUS_COMPLETED_SUCCESS: 'one-to-one.meeting-status-completed-success',
  MEETING_STATUS_CANCELLED_SUCCESS: 'one-to-one.meeting-status-cancelled-success',
  SHARED_NOTES_UPDATE_SUCCESS: 'one-to-one.shared-notes-update-success',
  PRIVATE_NOTES_UPDATE_SUCCESS: 'one-to-one.private-notes-update-success',
  CONFIRMATION_FILE_DELETE: 'one-to-one.confirmation-file-delete', // 'Are you sure you want to delete this file?'
  FILE_UPLOAD_SUCCESS: 'one-to-one.file-upload-success',
  FILE_DELETE_SUCCESS: 'one-to-one.file-delete-success',
  CONFIRMATION_REBUILD_CALENDAR: 'one-to-one.prompt-rebuild-calendar', // 'Are you sure you want to recreate the calendar events?'
  EXPLANATION_REBUILD_CALENDAR: 'one-to-one.explanation-rebuild-calendar', // 'This will delete all existing calendar events and recreate them. It can take a few minutes to complete.'
  MEETING_COUNT_SINGULAR: 'one-to-one.meeting-count-singular', // 1 Meeting
  MEETING_COUNT_PLURAL: 'one-to-one.meeting-count-plural', // {count} Meetings
  MEETING_COUNT(count: number): string {
    return count === 1 ? this.MEETING_COUNT_SINGULAR : this.MEETING_COUNT_PLURAL;
  },
  LATEST_MEETING_ON: 'one-to-one.latest-meeting-on', // Latest meeting on {timestamp}
  LATEST_MEETING_ON_OTHER_TIMEZONE: 'one-to-one.latest-meeting-on-other-timezone', // {date} in {timezone}
  CTA_WRITE_NOTE: 'one-to-one.cta-write-note', // Write a note...
  SHARED_NOTES_COUNT_SINGULAR: 'one-to-one.shared-notes-count-singular', // 1 person has shared notes
  SHARED_NOTES_COUNT_PLURAL: 'one-to-one.shared-notes-count-plural', // {count} people have shared notes
  SHARED_NOTES_COUNT(count: number): string {
    return count === 1 ? this.SHARED_NOTES_COUNT_SINGULAR : this.SHARED_NOTES_COUNT_PLURAL;
  },
  PROMPT_CHANGE_TALKING_POINT_TEMPLATE: 'one-to-one.prompt-change-talking-point-template',
  CONFIRMATION_COMPLETE_MEETING: 'one-to-one.confirmation-complete-meeting',
  EXPLANATION_COMPLETE_MEETING: 'one-to-one.explanation-complete-meeting',
  SENTIMENT_SCALE: SentimentScaleMessages.SENTIMENT_SCALE,
  SENTIMENT_SCALE_DESCRIPTION: SentimentScaleMessages.MEETING_SENTIMENT_SCALE_DESCRIPTION,
  MEETING_SENTIMENT_SCALE_SCORE_UPDATE_SUCCESS: 'one-to-one.meeting-sentiment-scale-score-update-success',
  JUST_NEXT_MEETING_TITLE_WHOLE_SCHEDULE: 'one-to-one.just-next-meeting-title-whole-schedule',
  JUST_NEXT_MEETING_TITLE_NEXT_MEETING: 'one-to-one.just-next-meeting-title-next-meeting',
  JUST_NEXT_MEETING_EXPLANATION: 'one-to-one.just-next-meeting-explanation',
  JUST_NEXT_MEETING_CONFIRMATION: 'one-to-one.just-next-meeting-confirmation',
  GLOBAL_DETAILS: 'one-to-one.global-details',
  GLOBAL_DETAILS_DESCRIPTION: 'one-to-one.global-details-description',
  MEETING_SPECIFIC_DETAILS: 'one-to-one.meeting-specific-details',
  MEETING_SPECIFIC_DETAILS_DESCRIPTION: 'one-to-one.meeting-specific-details-description',
};
