export enum ErrorCode {
  TEST_ERROR_CODE = 'TEST_ERROR_CODE',
  TEST_ERROR_CODE_BLACKLISTED = 'TEST_ERROR_CODE_BLACKLISTED',
  
  GOAL_INVALID_OWNERS = 'GOAL_INVALID_OWNERS',

  REGISTER_EMAIL_USED_CONFIRMED = 'REGISTER_EMAIL_USED_CONFIRMED',
  REGISTER_EMAIL_USED_UNCONFIRMED = 'REGISTER_EMAIL_USED_UNCONFIRMED',

  CURRENT_USER_NOT_FOUND = 'CURRENT_USER_NOT_FOUND',

  TASK_NOT_FOUND = 'TASK_NOT_FOUND',
  TASK_NO_PERMISSION_TO_UPDATE = 'TASK_NO_PERMISSION_TO_UPDATE',
  TASK_CANNOT_UPDATE_ARCHIVED = 'TASK_CANNOT_UPDATE_ARCHIVED',
  TASK_INVALID_GOAL_ALIGNMENT = 'TASK_INVALID_GOAL_ALIGNMENT',
  TASK_INVALID_ONE_TO_ONE_ALIGNMENT = 'TASK_INVALID_ONE_TO_ONE_ALIGNMENT',
  TASK_ALIGNMENT_ID_NOT_ALLOWED = 'TASK_ALIGNMENT_ID_NOT_ALLOWED',
  TASK_ALIGNMENT_ITEM_NOT_SET = 'TASK_ALIGNMENT_ITEM_NOT_SET',
  TASK_ALIGNED_GOAL_NOT_FOUND = 'TASK_ALIGNED_GOAL_NOT_FOUND',
  TASK_ALIGNED_ONE_TO_ONE_NOT_FOUND = 'TASK_ALIGNED_ONE_TO_ONE_NOT_FOUND',
  TASK_OWNER_NOT_FOUND = 'TASK_OWNER_NOT_FOUND',
  TASK_NO_OWNERS_SET = 'TASK_NO_OWNERS_SET',

  PROFILE_DETAILS_DESCRIPTION_NULL = 'PROFILE_DETAILS_DESCRIPTION_NULL',
  PROFILE_DETAILS_DESCRIPTION_MIN_LENGTH = 'PROFILE_DETAILS_DESCRIPTION_MIN_LENGTH',
  PROFILE_DETAILS_DESCRIPTION_MAX_LENGTH = 'PROFILE_DETAILS_DESCRIPTION_MAX_LENGTH',
  PROFILE_DETAILS_DESCRIPTION_MAX_LENGTH_RAW = 'PROFILE_DETAILS_DESCRIPTION_MAX_LENGTH_RAW',
  PROFILE_DETAILS_GENDER_NULL = 'PROFILE_DETAILS_GENDER_NULL',
  PROFILE_DETAILS_GENDER_INVALID = 'PROFILE_DETAILS_GENDER_INVALID',
  PROFILE_DETAILS_BIRTHDAY_REMINDER_NULL = 'PROFILE_DETAILS_BIRTHDAY_REMINDER_NULL',
  PROFILE_DETAILS_DATE_OF_BIRTH_IN_THE_FUTURE = 'PROFILE_DETAILS_DATE_OF_BIRTH_IN_THE_FUTURE',

  LOCATION_DETAILS_STREET_MAX_LENGTH = 'LOCATION_DETAILS_STREET_MAX_LENGTH',
  LOCATION_DETAILS_CITY_MAX_LENGTH = 'LOCATION_DETAILS_CITY_MAX_LENGTH',
  LOCATION_DETAILS_DISTRICT_MAX_LENGTH = 'LOCATION_DETAILS_DISTRICT_MAX_LENGTH',
  LOCATION_DETAILS_POSTAL_CODE_MAX_LENGTH = 'LOCATION_DETAILS_POSTAL_CODE_MAX_LENGTH',
  LOCATION_DETAILS_COUNTRY_MAX_LENGTH = 'LOCATION_DETAILS_COUNTRY_MAX_LENGTH',
  LOCATION_DETAILS_TIMEZONE_MAX_LENGTH = 'LOCATION_DETAILS_TIMEZONE_MAX_LENGTH',
  LOCATION_DETAILS_SITE_NOT_FOUND = 'LOCATION_DETAILS_SITE_NOT_FOUND',

  CONTACT_INFORMATION_PREFERRED_CONTACT_METHOD_NULL = 'CONTACT_INFORMATION_PREFERRED_CONTACT_METHOD_NULL',
  CONTACT_INFORMATION_PREFERRED_CONTACT_METHOD_INVALID = 'CONTACT_INFORMATION_PREFERRED_CONTACT_METHOD_INVALID',
  CONTACT_INFORMATION_PHONE_NUMBER_MAX_LENGTH = 'CONTACT_INFORMATION_PHONE_NUMBER_MAX_LENGTH',
  CONTACT_INFORMATION_LINKEDIN_MAX_LENGTH = 'CONTACT_INFORMATION_LINKEDIN_MAX_LENGTH',
  CONTACT_INFORMATION_TWITTER_HANDLE_MAX_LENGTH = 'CONTACT_INFORMATION_TWITTER_HANDLE_MAX_LENGTH',
  CONNECTION_SETTINGS_NOTIFY_VIA_EMAIL_NULL = 'CONNECTION_SETTINGS_NOTIFY_VIA_EMAIL_NULL',
  CONNECTION_SETTINGS_NOTIFY_VIA_SLACK_NULL = 'CONNECTION_SETTINGS_NOTIFY_VIA_SLACK_NULL',
  CONNECTION_SETTINGS_NOTIFY_VIA_TEAMS_NULL = 'CONNECTION_SETTINGS_NOTIFY_VIA_TEAMS_NULL',

  INTEREST_NOT_FOUND = 'INTEREST_NOT_FOUND',
  INTEREST_NOT_UNIQUE = 'INTEREST_NOT_UNIQUE',
  INTEREST_COMPANY_ID_NULL = 'INTEREST_COMPANY_ID_NULL',
  INTEREST_CREATOR_ID_NULL = 'INTEREST_CREATOR_ID_NULL',
  INTEREST_CREATOR_NAME_MAX_LENGTH = 'INTEREST_CREATOR_NAME_MAX_LENGTH',
  INTEREST_NAME_NULL = 'INTEREST_NAME_NULL',
  INTEREST_NAME_MIN_LENGTH = 'INTEREST_NAME_MIN_LENGTH',
  INTEREST_NAME_MAX_LENGTH = 'INTEREST_NAME_MAX_LENGTH',
  INTEREST_CATEGORY_NULL = 'INTEREST_CATEGORY_NULL',
  INTEREST_CATEGORY_MIN_LENGTH = 'INTEREST_CATEGORY_MIN_LENGTH',
  INTEREST_CATEGORY_MAX_LENGTH = 'INTEREST_CATEGORY_MAX_LENGTH',
  INTEREST_INTEREST_TYPE_NULL = 'INTEREST_INTEREST_TYPE_NULL',
  INTEREST_INTEREST_TYPE_INVALID = 'INTEREST_INTEREST_TYPE_INVALID',
  INTEREST_APPROVED_NULL = 'INTEREST_APPROVED_NULL',
  
  CHANGE_PASSWORD_FORMAT_INVALID_FOR_OLD = 'CHANGE_PASSWORD_FORMAT_INVALID_FOR_OLD',
  CHANGE_PASSWORD_FORMAT_INVALID_FOR_NEW = 'CHANGE_PASSWORD_FORMAT_INVALID_FOR_NEW',
  CHANGE_PASSWORD_CURRENT_PASSWORD_INCORRECT = 'CHANGE_PASSWORD_CURRENT_PASSWORD_INCORRECT',


  COMPETENCY_COMPANY_ID_NULL = 'COMPETENCY_COMPANY_ID_NULL',
  COMPETENCY_NAME_NULL = 'COMPETENCY_NAME_NULL',
  COMPETENCY_NAME_MIN_LENGTH = 'COMPETENCY_NAME_MIN_LENGTH',
  COMPETENCY_NAME_MAX_LENGTH = 'COMPETENCY_NAME_MAX_LENGTH',
  COMPETENCY_DESCRIPTION_NULL = 'COMPETENCY_DESCRIPTION_NULL',
  COMPETENCY_DESCRIPTION_MIN_LENGTH = 'COMPETENCY_DESCRIPTION_MIN_LENGTH',
  COMPETENCY_DESCRIPTION_MAX_LENGTH = 'COMPETENCY_DESCRIPTION_MAX_LENGTH',
  COMPETENCY_DESCRIPTION_MAX_LENGTH_RAW = 'COMPETENCY_DESCRIPTION_MAX_LENGTH_RAW',
  COMPETENCY_CATEGORY_NULL = 'COMPETENCY_CATEGORY_NULL',
  COMPETENCY_CATEGORY_MIN_LENGTH = 'COMPETENCY_CATEGORY_MIN_LENGTH',
  COMPETENCY_CATEGORY_MAX_LENGTH = 'COMPETENCY_CATEGORY_MAX_LENGTH',
  COMPETENCY_NOT_FOUND = 'COMPETENCY_NOT_FOUND',
  COMPETENCY_NOT_FOUND_MULTIPLE = 'COMPETENCY_NOT_FOUND_MULTIPLE',
  COMPETENCY_NOT_UNIQUE = 'COMPETENCY_NOT_UNIQUE',

  // NOTE: Entity for these is called "Position" to prevent conflict w/ user roles
  ROLE_COMPANY_ID_NULL = 'ROLE_COMPANY_ID_NULL',
  ROLE_NAME_NULL = 'ROLE_NAME_NULL',
  ROLE_NAME_MIN_LENGTH = 'ROLE_NAME_MIN_LENGTH',
  ROLE_NAME_MAX_LENGTH = 'ROLE_NAME_MAX_LENGTH',
  ROLE_GRADE_NULL = 'ROLE_GRADE_NULL',
  ROLE_GRADE_MAX_LENGTH = 'ROLE_GRADE_MAX_LENGTH',
  ROLE_DESCRIPTION_NULL = 'ROLE_DESCRIPTION_NULL',
  ROLE_DESCRIPTION_MIN_LENGTH = 'ROLE_DESCRIPTION_MIN_LENGTH',
  ROLE_DESCRIPTION_MAX_LENGTH = 'ROLE_DESCRIPTION_MAX_LENGTH',
  ROLE_DESCRIPTION_MAX_LENGTH_RAW = 'ROLE_DESCRIPTION_MAX_LENGTH_RAW',
  ROLE_NOT_FOUND = 'ROLE_NOT_FOUND',
  ROLE_COMPETENCY_NOT_FOUND = 'ROLE_COMPETENCY_NOT_FOUND',
  ROLE_NOT_FOUND_MULTIPLE = 'ROLE_NOT_FOUND_MULTIPLE',
  ROLE_NOT_UNIQUE = 'ROLE_NOT_UNIQUE',
  ROLE_SKILL_NOT_FOUND = 'ROLE_SKILL_NOT_FOUND',

  QUALIFICATION_COMPANY_ID_NULL = 'QUALIFICATION_COMPANY_ID_NULL',
  QUALIFICATION_CREATOR_ID_NULL = 'QUALIFICATION_CREATOR_ID_NULL',
  QUALIFICATION_NAME_NULL = 'QUALIFICATION_NAME_NULL',
  QUALIFICATION_NAME_MIN_LENGTH = 'QUALIFICATION_NAME_MIN_LENGTH',
  QUALIFICATION_NAME_MAX_LENGTH = 'QUALIFICATION_NAME_MAX_LENGTH',
  QUALIFICATION_PROVIDER_NULL = 'QUALIFICATION_PROVIDER_NULL',
  QUALIFICATION_PROVIDER_MIN_LENGTH = 'QUALIFICATION_PROVIDER_MIN_LENGTH',
  QUALIFICATION_PROVIDER_MAX_LENGTH = 'QUALIFICATION_PROVIDER_MAX_LENGTH',
  QUALIFICATION_DESCRIPTION_NULL = 'QUALIFICATION_DESCRIPTION_NULL',
  QUALIFICATION_DESCRIPTION_MIN_LENGTH = 'QUALIFICATION_DESCRIPTION_MIN_LENGTH',
  QUALIFICATION_DESCRIPTION_MAX_LENGTH = 'QUALIFICATION_DESCRIPTION_MAX_LENGTH',
  QUALIFICATION_DESCRIPTION_MAX_LENGTH_RAW = 'QUALIFICATION_DESCRIPTION_MAX_LENGTH_RAW',
  QUALIFICATION_RECEIVED_DATE_NULL = 'QUALIFICATION_RECEIVED_DATE_NULL',
  QUALIFICATION_RECEIVED_DATE_IN_FUTURE = 'QUALIFICATION_RECEIVED_DATE_IN_FUTURE',
  QUALIFICATION_NOT_FOUND = 'QUALIFICATION_NOT_FOUND',
  QUALIFICATION_NO_PERMISSION_TO_EDIT = 'QUALIFICATION_NO_PERMISSION_TO_EDIT',

  CAREER_PATHWAY_COMPANY_ID_NULL = 'CAREER_PATHWAY_COMPANY_ID_NULL',
  CAREER_PATHWAY_CREATOR_ID_NULL = 'CAREER_PATHWAY_CREATOR_ID_NULL',
  CAREER_PATHWAY_NAME_MAX_LENGTH = 'CAREER_PATHWAY_NAME_MAX_LENGTH',
  CAREER_PATHWAY_ROLES_NULL = 'CAREER_PATHWAY_ROLES_NULL',
  CAREER_PATHWAY_ROLES_MIN_LENGTH = 'CAREER_PATHWAY_ROLES_MIN_LENGTH',
  CAREER_PATHWAY_NOT_FOUND = 'CAREER_PATHWAY_NOT_FOUND',
  CAREER_PATHWAY_ROLE_NOT_FOUND = 'CAREER_PATHWAY_ROLE_NOT_FOUND',
  CAREER_PATHWAY_NO_PERMISSION_TO_EDIT = 'CAREER_PATHWAY_NO_PERMISSION_TO_EDIT',
  CAREER_PATHWAY_NO_PERMISSION_TO_CHANGE_SELECTION = 'CAREER_PATHWAY_NO_PERMISSION_TO_CHANGE_SELECTION',


  QUESTION_TEMPLATE_COMPANY_ID_NULL = 'QUESTION_TEMPLATE_COMPANY_ID_NULL',
  QUESTION_TEMPLATE_CREATOR_ID_NULL = 'QUESTION_TEMPLATE_CREATOR_ID_NULL',
  QUESTION_TEMPLATE_NAME_NULL = 'QUESTION_TEMPLATE_NAME_NULL',
  QUESTION_TEMPLATE_NAME_MIN_LENGTH = 'QUESTION_TEMPLATE_NAME_MIN_LENGTH',
  QUESTION_TEMPLATE_NAME_MAX_LENGTH = 'QUESTION_TEMPLATE_NAME_MAX_LENGTH',
  QUESTION_TEMPLATE_CREATED_TIMESTAMP_NULL = 'QUESTION_TEMPLATE_CREATED_TIMESTAMP_NULL',
  QUESTION_TEMPLATE_CREATED_TIMESTAMP_IN_FUTURE = 'QUESTION_TEMPLATE_CREATED_TIMESTAMP_IN_FUTURE',
  QUESTION_TEMPLATE_NOT_FOUND = 'QUESTION_TEMPLATE_NOT_FOUND',
  QUESTION_TEMPLATE_NO_PERMISSION_TO_EDIT = 'QUESTION_TEMPLATE_NO_PERMISSION_TO_EDIT',
  QUESTION_TEMPLATE_NAME_EXISTS = 'QUESTION_TEMPLATE_NAME_EXISTS',
  QUESTION_TEMPLATE_CANT_DELETE_IN_USE = 'QUESTION_TEMPLATE_CANT_DELETE_IN_USE',

  ROLE_APPLICATION_CONFIGURATION_COMPANY_ID_NULL = 'ROLE_APPLICATION_CONFIGURATION_COMPANY_ID_NULL',
  ROLE_APPLICATION_CONFIGURATION_CREATOR_ID_NULL = 'ROLE_APPLICATION_CONFIGURATION_CREATOR_ID_NULL',
  ROLE_APPLICATION_CONFIGURATION_NOT_FOUND = 'ROLE_APPLICATION_CONFIGURATION_NOT_FOUND',
  ROLE_APPLICATION_CONFIGURATION_NO_PERMISSION_TO_EDIT = 'ROLE_APPLICATION_CONFIGURATION_NO_PERMISSION_TO_EDIT',
  ROLE_APPLICATION_CONFIGURATION_NO_PERMISSION_TO_CHANGE_SELECTION = 'ROLE_APPLICATION_CONFIGURATION_NO_PERMISSION_TO_CHANGE_SELECTION',

  ROLE_APPLICATION_COMPANY_ID_NULL = 'ROLE_APPLICATION_COMPANY_ID_NULL',
  ROLE_APPLICATION_APPLICANT_ID_NULL = 'ROLE_APPLICATION_APPLICANT_ID_NULL',
  ROLE_APPLICATION_NOT_FOUND = 'ROLE_APPLICATION_NOT_FOUND',
  ROLE_APPLICATION_NO_PERMISSION_TO_EDIT = 'ROLE_APPLICATION_NO_PERMISSION_TO_EDIT',
  ROLE_APPLICATION_PRIMARY_REVIEWER_CANT_BE_MANAGER = 'ROLE_APPLICATION_PRIMARY_REVIEWER_CANT_BE_MANAGER',
  ROLE_APPLICATION_QUESTION_NOT_FOUND = 'ROLE_APPLICATION_QUESTION_NOT_FOUND',
  ROLE_APPLICATION_PRIMARY_REVIEWER_ID_NULL = 'ROLE_APPLICATION_PRIMARY_REVIEWER_ID_NULL',
  ROLE_APPLICATION_APPLICANT_CANNOT_BE_PRIMARY_REVIEWER = 'ROLE_APPLICATION_APPLICANT_CANNOT_BE_PRIMARY_REVIEWER',
  ROLE_APPLICATION_PRIMARY_REVIEWER_CANNOT_BE_REVIEWER = 'ROLE_APPLICATION_PRIMARY_REVIEWER_CANNOT_BE_REVIEWER',
  ROLE_APPLICATION_APPLICANT_CANNOT_BE_REVIEWER = 'ROLE_APPLICATION_APPLICANT_CANNOT_BE_REVIEWER',
  ROLE_APPLICATION_APPLICANT_ALREADY_APPLIED_THIS_ROLE = 'ROLE_APPLICATION_APPLICANT_ALREADY_APPLIED_THIS_ROLE',

  LOGIN_ACCOUNT_CONNECTED_TO_GOOGLE = 'LOGIN_ACCOUNT_CONNECTED_TO_GOOGLE',
  LOGIN_ACCOUNT_CONNECTED_TO_MICROSOFT = 'LOGIN_ACCOUNT_CONNECTED_TO_MICROSOFT',
  LOGIN_REFRESH_TOKEN_EXPIRED_GOOGLE = 'LOGIN_REFRESH_TOKEN_EXPIRED_GOOGLE',
  LOGIN_REFRESH_TOKEN_EXPIRED_MICROSOFT = 'LOGIN_REFRESH_TOKEN_EXPIRED_MICROSOFT',

  SENTIMENT_SCALE_CANNOT_MODIFY_BECAUSE_USED = 'SENTIMENT_SCALE_CANNOT_MODIFY_BECAUSE_USED',
}
