import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Globals } from '@app/shared/globals/globals';
import { TasksMessages } from '../../locale/tasks.messages';
import { CommonMessages } from '@app/constants/common.messages';
import { ButtonType } from '@app/shared/components/inputs/button/button.component';
import { CreateTaskDto } from '@app/domain/task/model/create-task.dto';
import { Task } from '@app/domain/task/model/task.model';
import { PillType } from '@app/shared/components/pill/pill.component';
import { TaskConstants } from '@app/constants/task.constants';
import { TaskAlignmentType } from '../../model/task-alignment-type.enum';
import { UserMinimal } from '@app/models/user/user-minimal.model';
import { IMyDate } from 'angular-mydatepicker';
import { DateUtils } from '@app/shared/utils/date.utils';
import { OneToOneScheduleDetailsView } from '@app/domain/one_to_one/model/one-to-one-schedule.model';
import { forkJoin } from 'rxjs';
import { OneToOneAPIService } from '@app/domain/one_to_one/api/one-to-one-api.service';
import { Goal } from '@app/models/goals/goal.model';
import { GoalsAPIService } from '@app/shared/api/goals.api.service';
import { CompanyFeatures } from '@app/models/company-features.model';
import { TaskCreatedFrom } from '../../model/task-created-from.model';
import { Role } from '@app/domain/role/model/role.model';
import { RoleBusinessService } from '@app/domain/role/service/role-business.service';
import moment from 'moment';

export interface DefaultTaskAlignment {
  type: TaskAlignmentType;
  id: number;
}

export const initTaskForm = (createdFrom: TaskCreatedFrom, task?: Task, defaultAlignment?: DefaultTaskAlignment, defaultOwners?: UserMinimal[]): FormGroup => {
  const formGroup = new FormGroup({
    id: new FormControl(null, []),
    title: new FormControl('', [Validators.required, Validators.minLength(TaskConstants.TITLE_MIN_LENGTH), Validators.maxLength(TaskConstants.TITLE_MAX_LENGTH)]),
    description: new FormControl('', [Validators.maxLength(TaskConstants.DESCRIPTION_MAX_LENGTH)]),
    dueDate: new FormControl(null, []),
    alignmentType: new FormControl(TaskAlignmentType.TODO, [Validators.required]),
    alignmentId: new FormControl(null, []),
    owners: new FormControl([], []),
    linkedPositionIds: new FormControl([], []),
    createdFrom: new FormControl(TaskCreatedFrom.OVERVIEW, [])
  });

  if (defaultAlignment) {
    formGroup.controls.alignmentType.setValue(defaultAlignment.type, { emitEvent: false });
    formGroup.controls.alignmentId.setValue(defaultAlignment.id, { emitEvent: false });
  }

  if (createdFrom && !task) {
    formGroup.controls.createdFrom.setValue(createdFrom, { emitEvent: false });
  }

  if (defaultOwners) {
    formGroup.controls.owners.setValue(defaultOwners, { emitEvent: false });
  }

  if (task) {
    formGroup.controls.id.setValue(task.id, { emitEvent: false });
    formGroup.controls.title.setValue(task.title, { emitEvent: false });
    formGroup.controls.description.setValue(task.description, { emitEvent: false });
    formGroup.controls.dueDate.setValue(task.dueDate, { emitEvent: false });
    formGroup.controls.alignmentType.setValue(task.alignmentType, { emitEvent: false });
    formGroup.controls.alignmentId.setValue(task.alignmentId, { emitEvent: false });
    formGroup.controls.owners.setValue(task.owners, { emitEvent: false });
    formGroup.controls.linkedPositionIds.setValue(task.linkedPositionIds, { emitEvent: false });
    formGroup.controls.createdFrom.setValue(task.createdFrom, { emitEvent: false });
  }

  return formGroup;
};

export const parseTaskFormValueToCreateDto = (taskFormValue: Task): CreateTaskDto => {
  return {
    title: taskFormValue.title,
    description: taskFormValue.description,
    dueDate: taskFormValue.dueDate,
    alignmentType: taskFormValue.alignmentType,
    alignmentId: taskFormValue.alignmentId,
    ownerIds: taskFormValue.owners.map(o => o.id),
    linkedPositionIds: taskFormValue.linkedPositionIds,
    createdFrom: taskFormValue.createdFrom
  };
};

export enum TaskCreatePageViews {
  MAIN = 'MAIN'
}

@Component({
  selector: 'app-task-create',
  templateUrl: './task-create.component.html',
  styleUrls: ['./task-create.component.scss']
})
export class TaskCreateComponent implements OnInit, OnChanges {
  public readonly eTaskCreatePageViews = TaskCreatePageViews;
  public readonly eTaskAlignmentType = TaskAlignmentType;
  public readonly eCompanyFeatures = CompanyFeatures;
  public readonly eCommonMessages = CommonMessages;
  public readonly eTasksMessages = TasksMessages;
  public readonly eTaskConstants = TaskConstants;
  public readonly eButtonType = ButtonType;
  public readonly ePillType = PillType;

  @Input() submitted: boolean;
  @Input() formCreate: FormGroup;
  @Input() canAlign: boolean;
  @Input() canChangeAlignment: boolean;

  @Output() onFormCancel: EventEmitter<boolean>;
  @Output() onFormSubmit: EventEmitter<boolean>;
  @Output() requestCloseModal: EventEmitter<boolean>;

  public formChanged: boolean;

  selectedView: TaskCreatePageViews;
  ownersCustomOptions: UserMinimal[];
  dueDateDisableUntil: IMyDate;
  oneToOneSchedules: OneToOneScheduleDetailsView[];
  controlTaskAlignedGoal: FormControl;
  controlTaskAlignedRoles: FormControl;

  loadingSchedules: boolean;

  get controlTaskId(): FormControl {
    return this.formCreate.controls.id as FormControl;
  }

  get controlTaskTitle(): FormControl {
    return this.formCreate.controls.title as FormControl;
  }

  get controlTaskDescription(): FormControl {
    return this.formCreate.controls.description as FormControl;
  }

  get controlTaskDueDate(): FormControl {
    return this.formCreate.controls.dueDate as FormControl;
  }

  get controlTaskAlignmentType(): FormControl {
    return this.formCreate.controls.alignmentType as FormControl;
  }

  get controlTaskAlignmentId(): FormControl {
    return this.formCreate.controls.alignmentId as FormControl;
  }

  get controlTaskOwners(): FormControl {
    return this.formCreate.controls.owners as FormControl;
  }

  get controlLinkedPositionIds(): FormControl {
    return this.formCreate.controls.linkedPositionIds as FormControl;
  }

  constructor(
    public globals: Globals,
    private oneToOneAPIService: OneToOneAPIService,
    private goalsAPIService: GoalsAPIService,
    private roleBusinessService: RoleBusinessService
  ) {
    this.formChanged = false;
    this.submitted = false;
    this.canAlign = true;
    this.canChangeAlignment = true;
    this.loadingSchedules = false;

    this.ownersCustomOptions = [];
    this.oneToOneSchedules = [];

    this.selectedView = TaskCreatePageViews.MAIN;

    this.onFormCancel = new EventEmitter<boolean>();
    this.onFormSubmit = new EventEmitter<boolean>();
    this.requestCloseModal = new EventEmitter<boolean>();

    this.dueDateDisableUntil = DateUtils.convertDateMomentToPickerFormat(moment().subtract(1, 'day'));

    this.formCreate = initTaskForm(undefined);
    this.controlTaskAlignedGoal = this.initTaskAlignedGoalForm();
    this.controlTaskAlignedRoles = this.initTaskAlignedRolesForm();
  }

  ngOnInit(): void {
    this.ownersCustomOptions = [ this.globals.user ];
  }

  ngOnChanges(changes: SimpleChanges): void {
    const changesFormCreate = changes['formCreate'];
    if (changesFormCreate) {
      this.formChanged = false;
      this.initEventListeners();
    }
  }

  initEventListeners(): void {
    this.controlTaskAlignedRoles = this.initTaskAlignedRolesForm();
    this.controlTaskAlignmentType.valueChanges.subscribe(alignmentType => this.onAlignmentTypeChanged(alignmentType));
    this.controlTaskAlignmentId.valueChanges.subscribe(alignmentId => this.onAlignmentIdChanged(alignmentId));

    this.controlTaskAlignmentType.updateValueAndValidity();
    this.controlTaskAlignmentId.updateValueAndValidity();

    this.formCreate.valueChanges.subscribe(() => {
      this.formChanged = true;
    });
  }

  onAlignmentTypeChanged(alignmentType: TaskAlignmentType): void {
    // Reset inner control for goal alignment
    this.controlTaskAlignedGoal = this.initTaskAlignedGoalForm();
    this.controlTaskAlignedRoles = this.initTaskAlignedRolesForm();

    switch(alignmentType) {
      case TaskAlignmentType.ONE_TO_ONE:
        this.getOneToOneAlignmentData();
        break;
      case TaskAlignmentType.TODO :
      case TaskAlignmentType.GOAL:
      default:
        break;
    }
  }

  onAlignmentIdChanged(alignmentId: string): void {
    console.debug('Alignment ID changed', alignmentId);
    this.controlTaskAlignedGoal = this.initTaskAlignedGoalForm();
    this.controlTaskAlignedRoles = this.initTaskAlignedRolesForm();
  }

  getOneToOneAlignmentData(): void {
    this.loadingSchedules = true;

    forkJoin([
      this.oneToOneAPIService.getOneToOneSchedulesForUserMe(),
      this.oneToOneAPIService.getOneToOneSchedulesForManagerMe()
    ])
      .toPromise()
      .then(([scheduleEmployee, scheduleManager]) => {
        this.oneToOneSchedules = [...scheduleEmployee, ...scheduleManager];
      })
      .finally(() => {
        this.loadingSchedules = false;
      });
  }

  onViewChangeRequested(view: TaskCreatePageViews): void {
    this.selectedView = view;
  }

  onCancelCreate(): void {
    this.onFormCancel.emit(true);
  }

  onConfirmCreate(): void {
    this.onFormSubmit.emit(true);
  }

  closeCreateModal() {
    this.requestCloseModal.emit(true);
  }

  openHelpArticle() {
    const newWindow = window.open('https://help.frankli.io/en/articles/6723117', '_blank');
    
    if (newWindow) {
      newWindow.focus();
    }
  }

  initTaskAlignedGoalForm(goal?: Goal): FormControl {
    const formControl = new FormControl(null, []);

    if (goal) {
      formControl.setValue(goal, { emitEvent: false });
    } else if (this.controlTaskAlignmentType.value === TaskAlignmentType.GOAL && this.controlTaskAlignmentId.value) {
      this.goalsAPIService.getGoalById(this.controlTaskAlignmentId.value)
        .toPromise()
        .then(goal => {
          formControl.setValue(goal, { emitEvent: false });
        });
    }

    formControl.valueChanges.subscribe(goal => {
      this.controlTaskAlignmentId.setValue(goal ? goal.id : null);
    });

    return formControl;
  }

  initTaskAlignedRolesForm(roles?: Role[]): FormControl {
    const formControl = new FormControl([], []);

    if (roles) {
      formControl.setValue(roles, { emitEvent: false });
    } else if (this.controlLinkedPositionIds.value && this.controlLinkedPositionIds.value.length > 0) {
      this.roleBusinessService.get(this.controlLinkedPositionIds.value, null, null, null)
        .toPromise()
        .then(res => {
          formControl.setValue(res, { emitEvent: false });
        });
    }

    formControl.valueChanges.subscribe(res => {
      if (res) {
        this.controlLinkedPositionIds.setValue(res.map(r => r.id));
      }
    });

    return formControl;
  }
}
