import { Injectable } from '@angular/core';
import { Company } from '@app/models/company.model';
import { CustomWindow } from '@app/models/custom-window.model';
import { User } from '@app/models/user/user.model';
import { environment } from 'environments/environment';
import { Globals } from '../globals/globals';

declare var window: CustomWindow;

// NOTE: Found these through browser inspector on intercom. No documnetation for what this object should be so these might not work
interface IntercomAvatar {
  initials?: string;
  color?: string;
  image_urls?: {
    mini: string;
    mini_x2: string;
    normal: string;
    normal_x2: string;
    large: string;
  },
  image_url: string;
}

interface IntercomCompany {
  id: string;
  name: string;
  // plan: string;
  // monthly_spend: number;
  'Company State': string;
}

interface IntercomSettingsMinimal {
  app_id: string;
  environment: string;
}

interface IntercomSettings extends IntercomSettingsMinimal {
  name: string;
  email: string;
  user_id: number;
  avatar: IntercomAvatar;
  company: IntercomCompany;

  // Custom props
  'Access Level': string;
  'User State': string;
  // 'First Time': string
}

interface IntercomSettings {
}

@Injectable({
  providedIn: 'root'
})
export class IntercomService {
  initialized: boolean = false;

  constructor(
    public globals: Globals
  ) {
  }

  init(): void {
    if (!environment.intercom.enabled) {
      return;
    }

    // if (this.initialized) {
    //   this.update();
    //   return;
    // }

    const loggedIn = (this.globals.user && this.globals.company);

    if (loggedIn) {
      this.initLoggedIn(this.globals.user, this.globals.company);
    } else {
      this.initAnonymous();
    }

    this.initialized = true;
  }

  initAnonymous(): void {
    const settings: IntercomSettingsMinimal = {
      app_id: environment.intercom.id,
      environment: window.location.hostname
    };
    window.Intercom('boot', settings);
    console.log('Intercom initialized anonymously');
  }

  initLoggedIn(user: User, company: Company): void {
    let roles = '';
    roles = user.roles.map(r => r.name).join('').trim();

    const settings: IntercomSettings = {
      app_id: environment.intercom.id,
      name: user.firstName + ' ' + user.lastName,
      email: user.email,
      user_id: user.id,
      avatar: {
        image_url: user.imageUrl
      },
      company: {
        id: company.id.toString(),
        name: company.name,
        'Company State': company.state.toString()
      },
      'Access Level': roles,
      'User State': user.userState,
      environment: window.location.hostname
    };
    window.Intercom('boot', settings);
    console.log('Intercom initialized');
  }

  update(): void {
    window.Intercom('update');
  }

  hideWidget(): void {
    window.Intercom('hide');
  }

  showWidget(): void {
    window.Intercom('show');
  }

  showMessages(): void {
    window.Intercom('showMessages');
  }

  initOnShowCallback(): void {
    window.Intercom('onShow', () => {
      console.log('Intercom intialized')
    });
  }

  initUnreadCountChanged(): void {
    window.Intercom('onUnreadCountChange', (unreadCount: number) => {
    });
  }

  trackEvent(eventName: string, metadata?: any): void {
    if (metadata) {
      window.Intercom('trackEvent', eventName, metadata);
      return;
    }
    
    window.Intercom('trackEvent', eventName);
  }

  getVisitorId(): string {
    return window.Intercom('getVisitorId');
  }

  startTour(tourId: number): void {
    window.Intercom('startTour', tourId);
  }
}
