<div class="container-sidebar-reviews-peer-reviews">
  <app-state [state]="state">
    <ng-container *ngIf="!state.loading && !state.error">
      <ng-container *ngIf="peerReviews.length > 0">
        <div class="request-list">
          <ng-container *ngFor="let peerReview of peerReviews; index as index;">
            <app-evaluation-feedback-request-display [cycle]="cycle" [request]="peerReview"></app-evaluation-feedback-request-display>
          </ng-container>
        </div>
      </ng-container>
      <ng-container *ngIf="summary">
        <app-review-step-summary-display [summary]="summary" [authorUser]="usersManager"></app-review-step-summary-display>
      </ng-container>
    </ng-container>
  </app-state>
</div>
