<div class="container-profile-praise">
  <app-state [state]="state">
    <ng-container *ngIf="!state.loading && !state.error">
      <h4 class="standard-heading p-bottom-15">
        <ng-container *ngIf="isCurrentUserProfile; else templateHeadingOtherUser;">
          <span>Your praise</span>
          <span class="m-right-5"></span>
          <app-table-action-icon [boxSize]="'20px'" [iconSize]="'10px'" [tooltipText]="'Edit praise list'" [icon]="'fa-edit'" [hoverColor]="eIconHoverColor.YELLOW" [routerLink]="'/feedback/history/praise/profile/' + userProfile.id"></app-table-action-icon>
        </ng-container>
        <ng-template #templateHeadingOtherUser>
          <span>Praise for {{userProfile.firstName}}</span>
        </ng-template>
      </h4>

      <ng-container *ngIf="(praiseList.length > 0); else templateNoPraise">
        <div class="praise-list">
          <ng-container *ngFor="let praise of praiseList; index as i">
            <div class="praise-item">
              <div class="area-profile-picture">
                <div class="wrapper-profile-photo">
                  <app-profile-photo [width]="'40px'" [src]="praise.senderUser.imageUrl" [firstName]="praise.senderUser.firstName" [lastName]="praise.senderUser.lastName"></app-profile-photo>
                </div>
              </div>
              <div class="area-praise-contents">
                <div class="area-person-timestamp">
                  <div class="standard-description">{{praise.senderUser.firstName + ' ' + praise.senderUser.lastName}}:</div>
                  <div class="standard-description">{{praise.timestamp | dateAgo}}</div>
                </div>

                <ng-container *ngIf="praise.id !== editingId; else templateEditingPraise">
                  <div class="area-praise-message">
                    <app-rich-display [text]="praise.message"></app-rich-display>
                  </div>

                  <div class="area-values">
                    <ng-container *ngFor="let v of praise.values">
                      <span class="praise-value-item">{{v.text | titlecase}}</span>
                    </ng-container>
                  </div>

                  <div class="area-reactions">
                    <span id="reactionStart" class="clickable" (mouseenter)="mouseInPop = true" (mouseleave)="mouseInPop = false" [popover]="reactionTemplate" [delay]="300" [popoverContext]="{praise:praise}" #pop="bs-popover" placement="top" outsideClick="true" container="body" (onShown)="hideOtherPopovers($event, praise)" triggers="touchstart mouseover">
                      <span *ngIf="!userHasReacted(praise)" (click)="toggleReactionMain(praise, eReactionType.LIKE, $event)"><span class="fal fa-thumbs-up"></span> Like</span>
                      <span *ngIf="userHasReacted(praise, eReactionType.LIKE)" (click)="toggleReaction(praise, eReactionType.LIKE, $event)"><span class="fas fa-thumbs-up"></span> Liked</span>
                      <span *ngIf="userHasReacted(praise, eReactionType.CELEBRATE)" (click)="toggleReaction(praise, eReactionType.CELEBRATE, $event)"><span class="fas fa-hands-wash"></span> Celebrated</span>
                      <span *ngIf="userHasReacted(praise, eReactionType.LOVE)" (click)="toggleReaction(praise, eReactionType.LOVE, $event)"><span class="fas fa-heart"></span> Loved</span>
                    </span>
                    <ng-container *ngIf="praise.reactions.length > 0">
                      <b> | </b>
                      <span class="clickable reaction-group-reactions text-nowrap"
                        (click)="loadReactionUserModal(praise.reactions)"
                        [title]="'This praise has ' + praise.reactions?.length + ' reactions'">
                        <span *ngIf="getUniqueReactions(praise.reactions).has(eReactionType.LIKE)"
                          class="fas fa-thumbs-up like-icon"></span>
                        <span *ngIf="getUniqueReactions(praise.reactions).has(eReactionType.CELEBRATE)"
                          class="fas fa-hands-wash celebrate-icon"></span>
                        <span *ngIf="getUniqueReactions(praise.reactions).has(eReactionType.LOVE)"
                          class="fas fa-heart love-icon"></span>
                        <span id="reactionLink">{{ praise.reactions?.length }}</span>
                      </span>
                    </ng-container>
                  </div>

                  <div class="area-actions">
                    <ng-container *ngIf="praise.senderUser.id === globals.user.id">
                      <app-table-action-icon [icon]="'fa-edit'" [hoverColor]="eIconHoverColor.YELLOW" (click)="editPraise(praise)" [tooltipText]="'Edit praise'"></app-table-action-icon>
                    </ng-container>
                    <ng-container *ngIf="isCurrentUserProfile">
                      <app-table-action-icon [icon]="'fa-eye-slash'" [hoverColor]="eIconHoverColor.GREEN" (click)="hidePraise(praise)" [tooltipText]="'Hide praise'"></app-table-action-icon>
                    </ng-container>
                    <ng-container *ngIf="praise.senderUser.id === globals.user.id">
                      <app-table-action-icon [icon]="'fa-times'" [hoverColor]="eIconHoverColor.RED" (click)="removePraise(praise)" [tooltipText]="'Remove praise'"></app-table-action-icon>
                    </ng-container>
                  </div>
                </ng-container>
                <ng-template #templateEditingPraise>
                  <div class="card content m-top-15">
                    <div class="frankli-form-group frankli-form-group-filled" [class.frankli-form-group-invalid]="submittedPraise && editForm.controls.editMessage.invalid">
                      <app-editor-response [formControl]="editForm.controls.editMessage" [toolbar]="editorToolbar" [enforceCharacterLimit]="false" [columnIndent]="false" [height]="150" [maxCharacters]="largeEditorMaxLengthSoft" [minlength]="largeEditorMinLength" [placeholder]="'Please write here (Max ' + largeEditorMaxLengthSoft + ' characters)'"></app-editor-response>
  
                      <div class="frankli-form-group-alert">
                        <ng-container *ngIf="submittedPraise">
                          <div *ngIf="editForm.controls.editMessage.hasError('required')">Please provide a little detail here</div>
                          <div *ngIf="editForm.controls.editMessage.hasError('softmin')">Your submission must be at least {{largeEditorMinLength}} characters</div>
                        </ng-container>
                        <div *ngIf="editForm.controls.editMessage.hasError('softmax')">Your submission must be {{largeEditorMaxLengthSoft}} characters or fewer</div>
                        <div *ngIf="editForm.controls.editMessage.hasError('maxlength')">Too much formatting</div>
                      </div>
                    </div>

                    <ng-container *ngIf="availableValues.length > 0">
                      <div class="edit-values">
                        <div class="standard-description">Which Company values did they demonstrate?</div>
    
                        <div class="edit-values-list">
                          <ng-container *ngFor="let value of availableValues">
                            <div class="clickable noselect">
                              <app-pill [type]="value.selected ? ePillType.GREEN : ePillType.YELLOW" (click)="toggleValue(value)">{{value.text}}</app-pill>
                            </div>
                          </ng-container>
                        </div>
                      </div>
                    </ng-container>
  
                    <div class="text-center">
                      <app-button (onClick)="cancelEditing()" [type]="eButtonType.GRAY_INVERTED">Discard</app-button>
                      <span class="m-right-5"></span>
                      <app-button (onClick)="savePraise(praise)" [type]="eButtonType.GREEN">Save</app-button>
                    </div>
                  </div>
                </ng-template>
              </div>
            </div>
          </ng-container>

          <ng-container *ngIf="praiseList.length >= MAX_PRAISE_SHOWN">
            <div class="prompt-view-all">
              <a class="clickable" (click)="viewAllPraise()">View All Praise</a>
            </div>
          </ng-container>
        </div>
      </ng-container>

      <ng-template #templateNoPraise>
        <div *ngIf="isCurrentUserProfile; else praiseOther;">
          <app-empty-state [height]="'150px'" [imageSrc]="'/assets/img/NoPraise.svg'"
            [message]="'No praise left yet for ' + userProfile.firstName + '. Be the first and start a movement!'"
            [secondaryButtonShown]="eButtonType.GREEN_INVERTED"
            [secondaryButtonText]="'Praise ' + userProfile.firstName"
            (secondaryButtonPressed)="givePraise()"></app-empty-state>
        </div>

        <ng-template #praiseOther>
          <app-empty-state [height]="'150px'" [imageSrc]="'assets/img/WaitingPraise.svg'" [message]="'All is quiet here at the minute'"></app-empty-state>
        </ng-template>
      </ng-template>

      <div class="prompt-give-praise">
        <ng-container *ngIf="isCurrentUserProfile; else promptGivePraiseOther;">
          <span>Do you know someone who deserves some recognition for their hard work, support, mentoring or just a good person?</span>
          <button class="btn-frankli-round btn-frankli-green-inverted pull-right" [routerLink]="'feedback/give/praise'" [queryParams]="{source: 'profile'}">Praise someone</button>
        </ng-container>
        <ng-template #promptGivePraiseOther>
          <span>Does {{userProfile.firstName}} deserve some recognition for their hard work, support, mentoring or just being a good person?</span>
          <button class="btn-frankli-round btn-frankli-green-inverted pull-right" (click)="givePraise()">Praise {{userProfile.firstName}}</button>
        </ng-template>
      </div>

      <!-- Reaction Template -->
      <ng-template #reactionTemplate let-praise='praise'>
        <div class="popover-reaction-icons" [class.popover-mobile]="globals.onMobile" (mouseenter)="mouseInPop = true"
          (mouseleave)="mouseInPop = false">
          <span class="popover-reaction-icon-container" (click)="toggleReaction(praise, eReactionType.LIKE, $event)">
            <span class="expand-on-hover fa-thumbs-up like-icon" title="Like"
              [ngClass]="userHasReacted(praise, eReactionType.LIKE) ? 'fas' : 'fal'"></span>
          </span>
          <span class="popover-reaction-icon-container" (click)="toggleReaction(praise, eReactionType.CELEBRATE, $event)">
            <span class="expand-on-hover fa-hands-wash celebrate-icon" title="Celebrate"
              [ngClass]="userHasReacted(praise, eReactionType.CELEBRATE) ? 'fas' : 'fal'"></span>
          </span>
          <span class="popover-reaction-icon-container" (click)="toggleReaction(praise, eReactionType.LOVE, $event)">
            <span class="expand-on-hover fa-heart love-icon" title="Love"
              [ngClass]="userHasReacted(praise, eReactionType.LOVE) ? 'fas' : 'fal'"></span>
          </span>
        </div>
      </ng-template>

      <!-- Reaction user modal -->
      <app-modal #reactionUserModal [background]="'light'" [width]="'20%'">
        <div class="modal-body-content">
          <h5 class="text-center">Reactions</h5>
          <!-- Tabs -->
          <ul class="nav nav-justified text-center">
            <li title="All" class="nav-link clickable tab-option" [class.tab-selected]="modalTab === eModalTab.ALL"
              (click)="changeTab(eModalTab.ALL)">
              <span>All {{ reactionUsers.length }}</span>
            </li>
            <li title="Like" class="nav-link clickable tab-option like-icon"
              [class.tab-selected]="modalTab === eModalTab.LIKE" (click)="changeTab(eModalTab.LIKE)">
              <span class="fas fa-thumbs-up"></span>
              <span> {{ getReactionUsersForType(eReactionType.LIKE).length }}</span>
            </li>
            <li title="Celebrate" class="nav-link clickable tab-option celebrate-icon"
              [class.tab-selected]="modalTab === eModalTab.CELEBRATE" (click)="changeTab(eModalTab.CELEBRATE)">
              <span class="fas fa-hands-wash"></span>
              <span> {{ getReactionUsersForType(eReactionType.CELEBRATE).length}}</span>
            </li>
            <li title="Love" class="nav-link clickable tab-option love-icon"
              [class.tab-selected]="modalTab === eModalTab.LOVE" (click)="changeTab(eModalTab.LOVE)">
              <span class="fas fa-heart"></span>
              <span> {{ getReactionUsersForType(eReactionType.LOVE).length }}</span>
            </li>
          </ul>
          <div class="user-reaction-list">
            <div class="m-top-10" *ngFor="let reactionUser of reactionUsersDisplay">
              <span class="owner-img">
                <!-- TODO: Add firstName and lastName -->
                <app-profile-photo class="profile-picture" [src]="reactionUser.user.imageUrl"></app-profile-photo>
              </span>
              <span class="lh-40 m-left-5">{{reactionUser.user.firstName}} {{reactionUser.user.lastName}}</span>
            </div>
            <div *ngIf="reactionUsersDisplay.length === 0">
              <div class="p-top-20 p-bottom-20 text-center">
                <span>No reactions</span>
              </div>
            </div>
          </div>
        </div>
      </app-modal>
    </ng-container>
  </app-state>
</div>
