import { Injectable } from '@angular/core';
import { FrankliHttpErrorResponse } from '@app/models/exception/frankli-http-error-response.model';
import { User } from '@app/models/user/user.model';
import * as Sentry from '@sentry/angular';
import { BrowserTracing } from '@sentry/tracing';
import { Span, Transaction } from '@sentry/types';
import { environment } from 'environments/environment';
import { Globals } from '../globals/globals';
import { appVersion } from '@env/version';

interface TransactionItem {
  instance: Transaction,
  spans: {
    [spanName: string]: Span
  }
}

interface TransactionsReference {
  [transactionName: string]: TransactionItem
}


@Injectable()
export class SentryService {

  transactions: TransactionsReference;

  constructor(
    public globals: Globals
  ) {
    this.transactions = {};
  }

  static initSentry(): void {
    if (!environment.sentry.enabled) {
      console.log('Sentry is not enabled on this environment');
      return;
    }

    // const tracingRegex = new RegExp(/(?:http(?:s?):\/{2})(?:(?:localhost:[0-9]*)|(?:\w\.frankli\.io))\/api\/.*/g);
    // const tracingRegex = new RegExp(/\/?(?:api)\/?/g);
    const environmentDomain = environment.baseUrl.split('//')[1].split(':')[0];

    Sentry.init({
      dsn: environment.sentry.key,
      environment: environment.sentry.environment,
      release: appVersion,
      // See https://docs.sentry.io/platforms/javascript/guides/angular/configuration/integrations/plugin/ incase we want to add more integrations
      integrations: [
        // Registers and configures the Tracing integration,
        // which automatically instruments your application to monitor its
        // performance, including custom Angular routing instrumentation
        new BrowserTracing({
          tracingOrigins: [environmentDomain],
          routingInstrumentation: Sentry.routingInstrumentation,
        }),
        // new Dedupe(),
        // Captures all Console API calls and redirects them to Sentry using the SDK's captureMessage or captureException call depending on the log level.
        // It then retriggers to preserve default native behavior.
        // new CaptureConsole({
        //   levels: ['error']
        // })
        // Sends a video along with the request. Shouldn't be using this for every error to avoid paying a bunch for it
        // new SentryRRWeb({
        // }),
      ],
      ignoreErrors: [
        'Non-Error exception captured', // Filters out status 403 api responses from sentry
        'Non-Error promise rejection captured' // Filters out errors being spammed to sentry by Outlook's crawlers
      ],
      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: (environment.production ? 0.2 : 0.1),
      // TODO: Either get rid of version.json and use this OR do an XMLHttpRequest above to get the contents of that file and populate it here
      // release: "frankli@" + process.env.npm_package_version
    });
  }

  // Runs when a user logs in
  initAuthenticatedUser(user: User): void {
    if (!environment.sentry.enabled) {
      console.log('Sentry is not enabled on this environment');
      return;
    }

    if (user && user.id) {
      Sentry.configureScope((scope) => {
        scope.setUser({
          id: `${user.id}`,
          email: user.email
        });
  
        scope.setTags({
          'Access Level': this.globals.getHighestAccessLevel(),
          'Company Name': this.globals.company.name
        });
      });
    }
  }

  // NOTE: (Daire) I haven't tested any of this and I have no idea what to use it for yet
  // #region - CUSTOM TRANSACTIONS
  startNewTransaction(transactionName: string, description?: string): void {
    const transaction = Sentry.startTransaction({
      name: transactionName,
      description: description
    });

    const transactionItem = {
      instance: transaction, 
      spans: {}
    };

    this.transactions[transactionName] = transactionItem;
  }

  finishTransaction(transactionName: string): void {
    const transactionExists = Object.hasOwnProperty.call(this.transactions, transactionName);
    if (!transactionExists) {
      return console.error('Tried to finish a transaction but it didn\'t exist');
    }

    const transaction = this.transactions[transactionName];

    transaction.instance.finish();

    delete this.transactions[transactionName];
  }
  // #endregion

  // #region - SPANS
  startNewSpan(transactionName: string, spanName: string): void {
    // const transaction = Sentry.getCurrentHub().getScope().getTransaction();

    const transactionExists = Object.hasOwnProperty.call(this.transactions, transactionName);
    if (!transactionExists) {
      return console.error('Tried to capture a new span when no transaction was running');
    }

    const transaction = this.transactions[transactionName];

    const spanAlreadyExists = Object.hasOwnProperty.call(transaction.spans, spanName);
    if (spanAlreadyExists) {
      return console.error('Tried to capture a new span but it already exists');
    }

    const span = transaction.instance.startChild({
      op: spanName
    });

    this.transactions[transactionName].spans[spanName] = span;
  }

  finishSpan(transactionName: string, spanName: string): void {
    const transactionExists = Object.hasOwnProperty.call(this.transactions, transactionName);
    if (!transactionExists) {
      return console.error('Tried to finish a span but the transaction didn\'t exist');
    }

    const transaction = this.transactions[transactionName];

    const spanExists = Object.hasOwnProperty.call(transaction.spans, spanName);
    if (!spanExists) {
      return console.error('Tried to finish a span but it didn\'t exist');
    }

    this.transactions[transactionName].spans[spanName].finish();
    delete this.transactions[transactionName].spans[spanName];
  }
  // #endregion

  captureFeedback(err: FrankliHttpErrorResponse): void {
    if (!environment.sentry.enabled) { return; }
    
    // const messageId = Sentry.captureException(err);
    Sentry.setTag('error_code', err.error.errorCode);
    const messageId = Sentry.captureMessage(err.error.message);
    Sentry.setTag('error_code', null);
    Sentry.showReportDialog({ eventId: messageId });
  }
}