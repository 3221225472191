<div class="state-loading" [style.lineHeight]="height">
  <div>
    <div id="animation">
      <div>
        <div class="loader-icon loader-icon-1"></div>
        <div class="loader-icon loader-icon-2"></div>
        <div class="loader-icon loader-icon-3"></div>
      </div>
    </div>
    <div id="message">
      <span *ngIf="showMessage">{{currentMessage}}</span>
    </div>
  </div>
</div>
