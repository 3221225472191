import { ChildFilter } from '@app/models/api/child-filter.model';
import { FilterOperator } from '@app/models/api/filter-operator.enum';
import { CriterionOperator } from '@app/models/api/criterion-operator.enum';
import { UserState } from '@app/models/user/user.model';

export const notArchivedChildFilter: ChildFilter = {
  operator: FilterOperator.AND,
  filterCriteria: [
    {
      field: 'roles',
      operator: CriterionOperator.NOT_CONTAINS,
      value: 'ACCESS_REVOKED'
    },
    {
      field: 'roles',
      operator: CriterionOperator.NOT_CONTAINS,
      value: 'ARCHIVED'
    }
  ]
}

export const onboardedNotArchivedChildFilter: ChildFilter = {
  operator: FilterOperator.AND,
  filterCriteria: [
    ...notArchivedChildFilter.filterCriteria,
    {
      field: 'userState',
      operator: CriterionOperator.NOT_IN,
      values: [UserState.PENDING, UserState.INVITED]
    }
  ]
}