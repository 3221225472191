import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { FileAPIService } from '../api/file.api.service';
import { HttpClient } from '@angular/common/http';

@Pipe({ name: 'secure' })
export class SecurePipe implements PipeTransform {
  constructor(private fileAPIService: FileAPIService, private http: HttpClient
  ) { }

  transform(url: string): Observable<string> {
    return this.fileAPIService.getAndConvert(url);
  }
}
