import { Injectable } from '@angular/core';
import { CommonMessages } from '@app/constants/common.messages';
import { TasksMessages } from '../locale/tasks.messages';
import { TaskAlignmentType } from '../model/task-alignment-type.enum';
import { TaskStatus } from '../model/task-status.enum';
import { Task } from '../model/task.model';

@Injectable()
export class TaskUtilsService {

  public static getTaskStatusFilter(task?: Task): string {
    if (task) {
      if (task.archived) {
        return CommonMessages.ARCHIVED;
      }
  
      switch (task.status) {
        case TaskStatus.COMPLETE:
          return TasksMessages.STATUS_COMPLETE;
        case TaskStatus.IN_PROGRESS:
          return TasksMessages.STATUS_IN_PROGRESS;
        case TaskStatus.NOT_STARTED:
          return TasksMessages.STATUS_NOT_STARTED;
      }
    }

    return CommonMessages.UNKNOWN;
  }

  public static getTaskAlignmentTypeFilter(alignmentType?: TaskAlignmentType): string {
    switch (alignmentType) {
      case TaskAlignmentType.GOAL :
        return TasksMessages.ALIGNMENT_GOAL;
      case TaskAlignmentType.ONE_TO_ONE :
        return TasksMessages.ALIGNMENT_ONE_TO_ONE;
      case TaskAlignmentType.TODO :
        return TasksMessages.ALIGNMENT_TODO;
      case TaskAlignmentType.MILESTONE :
        return TasksMessages.ALIGNMENT_MILESTONE;
    }

    return CommonMessages.UNKNOWN;
  }

  public static sortByCreator(tasks: Task[]): Task[] {
    return tasks.sort((task1, task2) => {
      const t1OwnerName = `${task1.creator.firstName} ${task1.creator.lastName}`;
      const t2OwnerName = `${task2.creator.firstName} ${task2.creator.lastName}`;
      return t1OwnerName.localeCompare(t2OwnerName);
    });
  }

  public static sortByTitle(tasks: Task[]): Task[] {
    return tasks.sort((task1, task2) => {
      const t1Title = task1.title;
      const t2Title = task2.title;
      return t1Title.localeCompare(t2Title);
    });
  }

  public static sortByOwners(tasks: Task[]): Task[] {
    return tasks.sort((task1, task2) => {
      const t1Owners = task1.owners;
      const t2Owners = task2.owners;
      return t1Owners.length - t2Owners.length;
    });
  }

  public static sortByDueDate(tasks: Task[]): Task[] {
    return tasks.sort((task1, task2) => {
      const t1DueDate = (task1.dueDate ? new Date(task1.dueDate).getTime() : Infinity);
      const t2DueDate = (task2.dueDate ? new Date(task2.dueDate).getTime() : Infinity);
      return t1DueDate - t2DueDate;
    });
  }

  public static sortByAlignmentType(tasks: Task[]): Task[] {
    return tasks.sort((task1, task2) => {
      const t1AlignmentType = task1.alignmentType;
      const t2AlignmentType = task2.alignmentType;
      return t1AlignmentType.localeCompare(t2AlignmentType);
    });
  }

  public static sortByStatus(tasks: Task[]): Task[] {
    return tasks.sort((task1, task2) => {
      const t1Status = TaskUtilsService.getTaskStatusFilter(task1);
      const t2Status = TaskUtilsService.getTaskStatusFilter(task2);
      return t1Status.localeCompare(t2Status);
    });
  }

  public static getTooltipArchive(task: Task): string {
    switch(task.alignmentType) {
      case TaskAlignmentType.TODO :
        return TasksMessages.ARCHIVE_TODO;
      case TaskAlignmentType.ONE_TO_ONE :
        return TasksMessages.ARCHIVE_ACTION_POINT;
      case TaskAlignmentType.MILESTONE :
        return TasksMessages.ARCHIVE_MILESTONE;
      case TaskAlignmentType.GOAL :
      default :
        return TasksMessages.ARCHIVE_TASK;
    }
  }
}