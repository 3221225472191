import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Globals } from '@app/shared/globals/globals';
import moment from 'moment';
import { DatePickerMessages } from '@app/shared/utils/date-picker.messages';


declare var $: any;

type SideBySideOption = (boolean | 'force');

@Component({
  selector: 'app-date-picker-old-component',
  templateUrl: './date-picker-old.component.html',
  styleUrls: ['./date-picker-old.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => DatePickerOldComponent),
    multi: true,
  }],
})

// NOTE
// - Format for _value / value must always be of this.format's type
export class DatePickerOldComponent implements ControlValueAccessor, OnInit, OnChanges, OnDestroy, AfterViewInit {
  // TODO: Add the rest of the properties from https://getdatepicker.com/4/
  @Input() index !: number;
  @Input() disabled: boolean;
  @Input() emptyText: string;
  @Input() inputInvalid !: boolean | undefined;
  @Input() outputTime: boolean;
  @Input() horizontalPosition: 'auto' | 'left' | 'right';
  @Input() verticalPosition: 'auto' | 'top' | 'bottom'; // TODO: Top still has the styling as if it's on the bottom (arrow)
  @Input() invalid: boolean;
  @Input() displayFormat?: string;
  @Input() viewMode: 'default' | 'inline' | 'no-reset';
  @Input() sideBySide: SideBySideOption;
  @Input() format?: string;
  @Input() stepping: number;
  @Input() minDate?: Date | moment.Moment | string;
  @Input() maxDate?: Date | moment.Moment | string;
  @Input() disabledDates: (Date | moment.Moment | string)[];
  @Input() editing = false;

  // NOTE:  This causes the default date to be set when opening the picker and will override the formControl value
  @Input() useCurrent: 'day' | 'month' | 'year' | boolean;

  @Input() daysOfWeekDisabled: number[];
  @Input() timezone: string;
  @Input() ignoreReadonly: boolean;

  // default to null as lots of components depend on this value being returned
  @Input() clearValue: string | null = null;
  @Input() canClearValue: boolean = true;

  @ViewChild('inputField') inputField!: ElementRef;

  @Output() focusoutEmit: EventEmitter<any> = new EventEmitter();

  eDatePickerMessages = DatePickerMessages;
  _value: string | null;

  onChange = (_: any) => {};
  onTouched = () => {};

  get value(): string | null {
    return this._value;
  }

  set value(v: string | null) {
    if (v !== this._value) {
      this._value = this.getTimeString(v);
      this.setDatePicker(v);
      this.onChange(v);
    }
  }

  get valueFormatted(): string {
    if (this.displayFormat) {
      if (this.value) {
        let valueMoment = moment(this.value);
        if (!valueMoment.isValid()) {
          valueMoment = moment(this.value, this.displayFormat);
        }

        if (valueMoment.isValid()) {
          return valueMoment.format(this.displayFormat);
        }
      }
    }

    if (this.value) {
      return this.value;
    }

    return '';
  }

  constructor(
    private cdRef: ChangeDetectorRef,
    public globals: Globals
  ) {
    this._value = null!;
    this.disabled = false;
    this.invalid = false;
    this.sideBySide = false;
    this.outputTime = true;
    this.ignoreReadonly = true;
    this.viewMode = 'default';
    this.verticalPosition = 'auto';
    this.horizontalPosition = 'right';
    this.emptyText = '';
    this.format = undefined;
    this.displayFormat = 'DD/MM/YYYY';
    this.stepping = 5;
    this.useCurrent = false;
    this.disabledDates = [];
    this.daysOfWeekDisabled = [];
    this.timezone = 'UTC';
  }

  // Init default values
  public ngOnInit() {
    if (!this.format) {
      this.format = (this.outputTime === true) ? 'YYYY-MM-DD[T00:00:00]' : 'YYYY-MM-DD';
    }
  }

  ngOnDestroy() {
    this.tryDestroyPicker();
  }

  ngAfterViewInit() {
    this.initPicker();
  }

  ngOnChanges(changes: SimpleChanges) {
    const disabledDates = changes['disabledDates'];
    if (disabledDates && !disabledDates.firstChange) {
      // Dumb hack to stop same dates being seen as different
      const prevValuesString = disabledDates.previousValue.map((pv: (Date | moment.Moment | string)) => moment(pv).toString()).join(' ');
      const currValuesString = disabledDates.currentValue.map((cv: (Date | moment.Moment | string)) => moment(cv).toString()).join(' ');

      if (prevValuesString !== currValuesString) {
        const cachedValue = this._value;

        this.tryDestroyPicker();
        setTimeout(() => {
          this.initPicker();

          setTimeout(() => {
            this.value = cachedValue;
          }, 1);
        }, 1);
      }
    }
  }

  tryDestroyPicker() {
    if (this.inputField && this.inputField.nativeElement) {
      const datePicker = $(this.inputField.nativeElement);
      if (datePicker.data('DateTimePicker')) {
        datePicker.data('DateTimePicker').destroy();
      }
    }
  }

  initPicker() {
    if (this.inputField) {
      // an index is added to the selector for when there is more than one instance of the component on the page
      const sideBySide = this.getSideBySideValue(this.sideBySide);
      const datePicker = $(this.inputField.nativeElement);
      datePicker.datetimepicker({
        icons: {
          time: 'fal fa-clock',
          date: 'fal fa-calendar',
          up: 'fal fa-chevron-up',
          down: 'fal fa-chevron-down',
          previous: 'fal fa-chevron-left',
          next: 'fal fa-chevron-right',
          today: 'fal fa-screenshot',
          clear: 'fal fa-trash',
          close: 'fal fa-remove',
        },
        inline: (this.viewMode === 'inline'),
        keepOpen: (this.viewMode === 'inline'),
        sideBySide: sideBySide,
        stepping: 5,
        disabledDates: this.disabledDates.map(d => moment(d)),
        ignoreReadonly: this.ignoreReadonly,
        useCurrent: false
        // debug: true // TODO: This will keep the picker open and allows you to inspect element on it
      });

      datePicker.data('DateTimePicker').locale(moment().locale());
      datePicker.data('DateTimePicker').format(this.format);
      datePicker.data('DateTimePicker').widgetPositioning({ horizontal: this.horizontalPosition, vertical: this.verticalPosition });
      datePicker.data('DateTimePicker').daysOfWeekDisabled(this.daysOfWeekDisabled);
      datePicker.data('DateTimePicker').timeZone = this.timezone;

      // Set default value
      if (this.value) {
        datePicker.data('DateTimePicker').date(moment(this.value).toDate());
      }

      // Min/Max date
      if (this.minDate && moment(this.minDate).isValid()) {
        datePicker.data('DateTimePicker').minDate(moment(this.minDate))
      }
      if (this.maxDate && moment(this.maxDate).isValid()) {
        datePicker.data('DateTimePicker').maxDate(moment(this.maxDate))
      }

      // Default
      if (this.useCurrent) {
        datePicker.data('DateTimePicker').useCurrent(this.useCurrent);
      }

      // Event handlers
      datePicker.on('dp.change', (e: any) => {
        this.value = e.target.value;
      });

      // don't allow copying, pasting, drag and droppping or typing
      datePicker.bind('copy paste drop keydown', (e: any) => {
        e.preventDefault();
        e.stopPropagation();
      });

      // if (this.viewMode === 'inline') {
      //   datePicker.toggle();
      // }
    }
  }

  getSideBySideValue(value: SideBySideOption) {
    if (value === false) {
      return false;
    }

    if (value === 'force') {
      return true;
    }
    
    if ($(window).width() < 500) {
      return false;
    }

    return value;
  }

  writeValue(value: any): void {
    this._value = this.getTimeString(value);
    this.setDatePicker(value);
  }

  registerOnChange(fn: (_: any) => {}): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }

  getTimeString(dateIn: moment.Moment | Date | string | null) {
    if (dateIn === null) {
      return null;
    }

    const dateMoment = moment(dateIn);
    if (dateMoment.isValid()) {
      return dateMoment.format(this.format);
    } else {
    }

    return null;
  }

  public clearDate() {
    if (this.inputField && $(this.inputField).data('DateTimePicker')) {
      $(this.inputField).data('DateTimePicker').clear();
    }
    this.value = this.clearValue!;
  }

  setDatePicker(date: Date | moment.Moment | string | null) {
    if (this.inputField) {
      const pickerElement = $(this.inputField.nativeElement);
      if (pickerElement.data('DateTimePicker')) {
        const dateParsed = ((date !== null && date !== undefined) ? moment(date) : null);
        if (dateParsed) {
          pickerElement.data('DateTimePicker').date(dateParsed.format(this.format));
        } else {
          pickerElement.data('DateTimePicker').date(null);
        }
      }
    }
  }

  public getTitleText(): string {
    const value = (this.value ? this.value : this.emptyText);
    return value;
  }

  public focusInput() {
    setTimeout(() => {
      this.cdRef.detectChanges();
      this.inputField.nativeElement.focus()
    }, 10);
  }

  emitFocusout() { this.focusoutEmit.emit(null) }
}
