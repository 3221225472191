import { MockService } from '@app/mock/api/mock-service';
import { HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { GoalAnalyticsFilterParams, GoalAnalyticsItem } from '@app/models/analytics/goal-analytics.model';
import { OneToOneAnalyticsFilterParams, OneToOneAnalyticsItem } from '@app/models/analytics/one-to-one-analytics.model';
import { SurveyAnalytics } from '@app/models/analytics/survey-analytics-model';
import { ReviewAnalytics } from '@app/models/analytics/review-analytics.model';
import { sanitizeUrl } from '@app/shared/utils/helpers';
import { mockGoalAnalytics } from '@app/mock/api/data/mockGoalAnalytics';
import { mockOneToOneAnalytics } from '@app/mock/api/data/mockOneToOneAnalytics';
import { mockSurveyAnalytics } from '@app/mock/api/data/mockSurveyAnalytics';
import { mockReviewAnalytics, mockReviewAnalyticsAlternate } from '@app/mock/api/data/mockReviewAnalytics';
import { IAnalyticsAPI } from '@app/shared/api/interfaces/analytics-api.model';

@Injectable()
export class AnalyticsMockService implements MockService, IAnalyticsAPI {

  handleRoute(req: HttpRequest<any>): any {
    // Don't use urlWithParams here because we don't care about the time period for mock analytics
    const url = sanitizeUrl(req.url);

    switch (true) {
      case url.endsWith('api/statistics/goals'):
        return this.getGoalAnalytics(req.body);
      case url.endsWith('api/statistics/one-to-ones'):
        return this.getOneToOneAnalytics(req.body);
      case url.endsWith('api/statistics/surveys'):
        return this.getSurveyAnalyticsOverview();
      case url.endsWith('api/statistics/evaluations/selected-cycles'):
        return this.getReviewAnalyticsForSelectedCycles(req.body);
      case url.endsWith('api/statistics/evaluations'):
        return this.getReviewAnalyticsForPeriod(new Date(req.params.get('periodStart')!), null);
    }
  }

  // GOAL ANALYTICS

  getGoalAnalytics(filterParams: GoalAnalyticsFilterParams): Observable<GoalAnalyticsItem> {
    return of(mockGoalAnalytics);
  }

  getGoalReportGoalData(filterParams: GoalAnalyticsFilterParams): Observable<Blob> {
    return undefined;
  }

  getGoalReportGoalCounts(filterParams: GoalAnalyticsFilterParams): Observable<Blob> {
    return undefined;
  }

  // 1:1 ANALYTICS

  getOneToOneAnalytics(filterParams: OneToOneAnalyticsFilterParams): Observable<OneToOneAnalyticsItem> {
    return of(mockOneToOneAnalytics);
  }

  getOneToOneOverviewReport(filterParams: OneToOneAnalyticsFilterParams): Observable<Blob> {
    return undefined;
  }

  // REVIEW ANALYTCS

  getReviewAnalyticsForPeriod(periodStart: Date, periodEnd: Date): Observable<ReviewAnalytics> {
    const startMonthIsEven: boolean = ((periodStart.getMonth() % 2) === 0);
    return of(startMonthIsEven ? mockReviewAnalytics : mockReviewAnalyticsAlternate);
  }

  getReviewAnalyticsForSelectedCycles(selectedCycleIds: number[]): Observable<ReviewAnalytics> {
    const selectedIsEven: boolean = ((selectedCycleIds.length % 2) === 0);
    return of(selectedIsEven ? mockReviewAnalytics : mockReviewAnalyticsAlternate);
  }

  // SURVEY ANALYTICS

  getSurveyAnalyticsOverview(): Observable<SurveyAnalytics> {
    return of(mockSurveyAnalytics);
  }

  getSurveyOverviewReport(): Observable<Blob> {
    return undefined;
  }
}