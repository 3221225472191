import {AbstractControl, FormArray, FormGroup, ValidationErrors, ValidatorFn} from '@angular/forms';
import moment from 'moment';
import {HtmlPipe} from '../pipes/html.pipe';
import { passwordValidator } from './password.validator';

export class FrankliValidators {
  // Date must be greater than the one provided
  public static minDate(date: Date): ValidatorFn {
    if (!date) {
      return () => null;
    }

    date.setHours(0, 0, 0, 0);

    return (control: AbstractControl): (ValidationErrors | null) => {
      if (control.value !== null) {
        const controlDate = new Date(control.value);
        controlDate.setHours(0, 0, 0, 0);

        const minDateTime = date.getTime();
        const controlDateTime = controlDate.getTime();

        if (controlDateTime < minDateTime) {
          return { mindate: true };
        }
      }

      return null;
    };
  }

  // Date must be less than the one provided
  public static maxDate(date: Date): ValidatorFn {
    if (!date) {
      return () => null;
    }

    date.setHours(0, 0, 0, 0);

    return (control: AbstractControl): (ValidationErrors | null) => {
      if (control.value !== null) {
        const controlDate = new Date(control.value);
        controlDate.setHours(0, 0, 0, 0);

        const minDateTime = date.getTime();
        const controlDateTime = controlDate.getTime();

        if (controlDateTime > minDateTime) {
          return { maxdate: true };
        }
      }

      return null;
    };
  }

  // Checks for min array length
  public static minLengthArray(min: number): ValidatorFn {
    return (control: AbstractControl): (ValidationErrors | null) => {
      if (control.value !== null) {
        const controlValue = control.value as any[];
        if (controlValue.length < min) {
          return { minlength: true };
        }
      }

      return null;
    };
  }

  // Checks for max array length
  public static maxLengthArray(max: number): ValidatorFn {
    return (control: AbstractControl): (ValidationErrors | null) => {
      if (control.value !== null) {
        const controlValue = control.value as any[];
        if (controlValue.length > max) {
          return { maxlength: true };
        }
      }

      return null;
    };
  }

  // Applies to form groups
  // Returns endBeforeStart error if end date is before start
  // Form controls must be called startDate and endDate
  public static startDateEndDateCompare(): ValidatorFn {
    return (control: AbstractControl): (ValidationErrors | null) => {
      const group = control as FormGroup;
      const startDate = new Date(group.controls.startDate.value);
      const endDate = new Date(group.controls.endDate.value);

      if (startDate.getTime() > endDate.getTime()) {
        return { endBeforeStart: true };
      }

      return null;
    };
  }

  public static goalCompletionValidator(): ValidatorFn {
    return (control: AbstractControl): (ValidationErrors | null) => {
      const group = control as FormGroup;
      const measureStartValue = group.controls.measureStartValue;
      const measureGoalValue = group.controls.measureGoalValue;

      if (measureStartValue && measureGoalValue && Number(measureStartValue.value) === Number(measureGoalValue.value)) {
        return { goalSetToEqual: true };
      }

      return null;
    };
  }

  public static softMinValidation(min: number): ValidatorFn {
    return (control: AbstractControl): (ValidationErrors | null) => {
      const value = control.value;

      if (value) {
        const htmlPipe = new HtmlPipe();
        const valueNoTags = htmlPipe.transform(value);

        if (valueNoTags.length < min) {
          return {
            softmin: true,
          };
        }
      }

      return null;
    };
  }

  public static softMaxValidation(max: number): ValidatorFn {
    return (control: AbstractControl): (ValidationErrors | null) => {
      const value = control.value;

      if (value) {
        const htmlPipe = new HtmlPipe();
        const valueNoTags = htmlPipe.transform(value);

        if (valueNoTags.length > max) {
          return {
            softmax: true,
          };
        }
      }

      return null;
    };
  }

  public static isOneOf(options: readonly any[]): ValidatorFn {
    return (control: AbstractControl): (ValidationErrors | null) => {
      const value = control.value;

      if (value) {
        const valueValid = options.includes(value);

        if (!valueValid) {
          return {
            isoneof: true,
          };
        }
      }

      return null;
    };
  }

  public static matchExactValue(matchValue: string): ValidatorFn {
    const error = { matchExact: matchValue };
    return (control: AbstractControl): (ValidationErrors | null) => {
      const value: string = control.value;

      if (value && (value === matchValue)) {
        return null;
      }

      return error;
    };
  }

  public static realNumberValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value) {
        return null;
      }
      const forbiddenCharacterExp = /^[+-]?([0-9]*[.])?[0-9]+$/;
      const forbidden = !forbiddenCharacterExp.test(control.value);
      return forbidden ? {forbiddenCharacter: {value: control.value}} : null;
    };
  }

  public static dateCannotBeInPast(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value) {
        return null;
      }

      const date = control.value as Date;
      const dateTimeEndOfDay = moment(date).endOf('day');
      const now = moment();
      
      if (dateTimeEndOfDay.isBefore(now)) {
        return {
          dateinpast: true
        };
      }

      return null;
    };
  }

  public static passwordValidator = passwordValidator;

  public static validatorMinOptions(min: number): ValidatorFn {
    return (c: AbstractControl): { [key: string]: boolean } => {
      if (c.value.length >= min) {
        return null!;
      }

      return { 'minOptions': true };
    };
  }

  public static validatorMaxOptions(max: number): ValidatorFn {
    return (c: AbstractControl): { [key: string]: boolean } => {
      if (c.value.length <= max) {
        return null!;
      }

      return { 'maxOptions': true };
    };
  }

  public static validatorNotWhitespace(): ValidatorFn {
    return (c: AbstractControl): { [key: string]: boolean } => {
      if (c.value.trim().length > 0) {
        return null!;
      }

      return { 'validatorNotWhitespace': true };
    };
  }

  // Main use for this would be for saving drafts.
  // Validators that are required for create and save would be added as normal
  // Optional values for the draft would be wrapped in this
  // TODO: Test if this works
  public static validatorEnabledByProp(property: boolean, validator: ValidatorFn): ValidatorFn {
    if (property) {
      return validator;
    } else {
      return (c: AbstractControl): { [key: string]: boolean } => {
        return null;
      };
    }
  }

  public static validatorDisabledByProp(property: boolean, validator: ValidatorFn): ValidatorFn {
    if (property) {
      return (c: AbstractControl): { [key: string]: boolean } => {
        return null;
      };
    } else {
      return validator;
    }
  }

  public static valueMustBeEqualTo(desiredValue: string): ValidatorFn {
    return (control: AbstractControl): (ValidationErrors | null) => {
      const value = control.value;

      if (value !== desiredValue) {
        return {
          mustBeEqualTo: desiredValue,
        };
      }

      return null;
    };
  }

  public static formArrayControlValuesMustBeUnique(controlName: string): ValidatorFn {
    return (control: AbstractControl): (ValidationErrors | null) => {
      const formArray = control as FormArray;
      const values = formArray.controls.map((c) => c.get(controlName).value);
      const uniqueValues = new Set(values);

      if (values.length !== uniqueValues.size) {
        return {
          uniqueFormArrayControl: controlName,
        };
      }

      return null;
    };
  }
}
