import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {EmployeeSidebarComponent} from './sidebar.component';
import {Angulartics2Module} from 'angulartics2';
import {FormsModule} from '@angular/forms';
import {SharedModule} from '../shared.module';
import {SidebarSetupComponent} from './sidebar-setup/sidebar-setup.component';
import {TranslateModule} from '@ngx-translate/core';

@NgModule({
  imports: [
    RouterModule,
    CommonModule,
    Angulartics2Module,
    FormsModule,
    SharedModule,
    TranslateModule,
  ],
  declarations: [
    EmployeeSidebarComponent,
    SidebarSetupComponent
  ],
  exports: [
    EmployeeSidebarComponent
  ]
})

export class NavigationSidebarModule {
}
