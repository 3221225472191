import { Site } from '@app/models/site.model';
import { mockAddresses } from '@app/mock/api/data/mockAddresses';
import { clone } from '@app/shared/utils/helpers';

export const mockOfficeLocations: Site[] = [
  {
    id: 1,
    name: 'Frankli HQ',
    officeAddress: clone(mockAddresses[0])
  },
  {
    id: 2,
    name: 'Dublin Office',
    officeAddress: clone(mockAddresses[0])
  },
  {
    id: 3,
    name: 'Galway Office',
    officeAddress: clone(mockAddresses[0])
  },
  {
    id: 4,
    name: 'London Office',
    officeAddress: clone(mockAddresses[0])
  },
  {
    id: 5,
    name: 'Manchester Office',
    officeAddress: clone(mockAddresses[0])
  },
  {
    id: 6,
    name: 'Wellington Office',
    officeAddress: clone(mockAddresses[0])
  },
  {
    id: 7,
    name: 'Working Remotely',
    officeAddress: null
  },
];