<div (click)="onContainerClicked($event)" class="modal fade" tabindex="-1" [ngClass]="{'in': visibleAnimate}"
  [ngStyle]="{'display': visible ? 'block' : 'none', 'opacity': visibleAnimate ? 1 : 0}"
  [class.side-panel]="sidePanel"
  [class.modal-background-dark]="background === 'dark'"
  [class.modal-background-light]="background === 'light'"
  [class.modal-background-none]="background === 'none'"
  >
  <div class="modal-dialog" [class.p-mobile]="globals.onMobile">
    <div class="modal-content" [style.width]="width" [style.minWidth]="minWidth" [style.max-width]="maxWidth">
      <div class="modal-body">
        <div class="modal-close" (click)="tryClose()" *ngIf="showCloseButton">
          <span class="fal fa-times"></span>
        </div>
        <ng-content></ng-content>
      </div>
    </div>
    <div class="modal-dismiss" (click)="tryClose()">

    </div>
  </div>
</div>