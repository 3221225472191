import { AuthResponseType } from './auth-response-type.enum';
import { AuthType } from './auth-type.enum';
import { UserInit, UserInitServerSide } from './user-init.model';

export interface AuthResponseServerSide {
  type: AuthResponseType;
  message: string;
  validAuthTypes: Array<AuthType>;
  userInit: UserInitServerSide | null;
}

export class AuthResponse {
  type: AuthResponseType;
  message: string;
  validAuthTypes: Array<AuthType>;
  userInit: UserInit | null;
  
  constructor(authResponseServerSide: AuthResponseServerSide){
    this.type = authResponseServerSide.type;
    this.message = authResponseServerSide.message;
    this.validAuthTypes = authResponseServerSide.validAuthTypes;
    this.userInit = (authResponseServerSide.userInit === null) ? null : new UserInit(authResponseServerSide.userInit);
  }
}