import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Company, CompanyFeature, CompanyServerSide, UpdateCompanyFiscalYearDto} from 'app/models/company.model';
import {OrgChartUserDto} from 'app/models/user/org-chart-user.model';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {CreateDepartmentDto, Department} from '@app/models/department.model';
import {UpdateCompanyFeaturesDto} from '@app/models/company/update-company-features-dto';
import {CompanyService} from '@app/shared/api/interfaces/company/company.service';
import {Page} from '@app/models/api/page.model';
import {ApiUtils} from '@app/shared/utils/api.utils';
import {SortingParams} from '@app/models/api/sorting-params.model';
import {PagingParams} from '@app/models/api/paging-params.model';
import {CreateSiteDto, Site} from '@app/models/site.model';
import {UserMinimal} from '@app/models/user/user-minimal.model';
import {ProvisionedOrganisationalUnit} from '@models/ProvisionedOrganisationalUnit';
import {ProvisionedOfficeLocation} from '@models/ProvisionedOfficeLocation';

@Injectable()
export class CompanyAPIService implements CompanyService {

  private readonly BASE_URL = '/api/company';

  constructor(
      private http: HttpClient
  ) {
  }

  // #region - COMPANY
  getMyCompany() {
    const url = `${this.BASE_URL}/me`;
    return this.http.get<CompanyServerSide>(url).pipe(map(company => new Company(company)));
  }

  getById(id: number) {
    const url = `${this.BASE_URL}/${id}`;
    return this.http.get<CompanyServerSide>(url).pipe(map(company => new Company(company)));
  }

  delete(companyId: number): Observable<void> {
    const url = `${this.BASE_URL}/${companyId}`;
    return this.http.delete<void>(url);
  }
  // #endregion

  // #region - FEATURES
  updateFeatures(features: Array<CompanyFeature>): Observable<Array<CompanyFeature>> {
    const url = `${this.BASE_URL}/features`;
    return this.http.post<Array<CompanyFeature>>(url, features);
  }

  updateFeaturesForId(companyId: number, dto: UpdateCompanyFeaturesDto): Observable<Array<CompanyFeature>> {
    const url = `${this.BASE_URL}/${companyId}/features`;
    return this.http.post<Array<CompanyFeature>>(url, dto);
  }
  // #endregion

  // #region - DEPARTMENTS
  getDepartmentByType(type: string) {
    const url = `${this.BASE_URL}/department?type=${type}`; // TODO: Add queryParams as HttpParams - don't build into url string
    return this.http.get<Array<Department>>(url);
  }

  getAllDepartments() {
    return this.getDepartmentByType('Department');
  }

  searchDepartments(searchArgument: string, pagingParams: PagingParams, sortingParams: SortingParams): Observable<Page<Department>> {
    const url = `${this.BASE_URL}/department/search`;
    const params = ApiUtils.createPageAndSortParams(pagingParams, sortingParams)
      .append('searchArgument', searchArgument);

    return this.http.get<Page<Department>>(url, { params });
  }

  /**
   * Creates new department
   * @param createDepartmentDto
   */
  createDepartment(createDepartmentDto: CreateDepartmentDto): Observable<Department> {
    const url = `${this.BASE_URL}/department`;
    return this.http.post<Department>(url, createDepartmentDto);
  }

  /**
   * Updates existing department, provided it exists
   * @param departmentId
   * @param createDepartmentDto
   */
  updateDepartment(departmentId: number, createDepartmentDto: CreateDepartmentDto): Observable<Department> {
    const url = `${this.BASE_URL}/department/${departmentId}`;
    return this.http.put<Department>(url, createDepartmentDto);
  }

  /**
   * Deletes existing department, provided it exists and there is at least one remaining
   * @param departmentId
   */
  deleteDepartment(departmentId: number): Observable<Department> {
    const url = this.BASE_URL + `/department/${departmentId}`;
    return this.http.delete<Department>(url);
  }

  /**
   * Deletes multiple organizational units by id, provided they exist
   * @param departmentIds
   */
  deleteDepartmentsMultiple(departmentIds: number[]): Observable<Department[]> {
    const url = `${this.BASE_URL}/department/delete`;
    return this.http.post<Department[]>(url, departmentIds);
  }
  // #endregion

  // #region - SITES
  searchOfficeLocations(searchArgument: string, pagingParams: PagingParams, sortingParams: SortingParams): Observable<Page<Site>> {
    const url = `${this.BASE_URL}/site/search`;
    const params = ApiUtils.createPageAndSortParams(pagingParams, sortingParams)
      .append('searchArgument', searchArgument);
    return this.http.get<Page<Site>>(url, { params });
  }

  getAllSites() {
    const url = `${this.BASE_URL}/site`;
    return this.http.get<Array<Site>>(url);
  }

  /**
   * Creates an office location
   * @param createSiteDto
   */
  createSite(createSiteDto: CreateSiteDto): Observable<Site> {
    const url = `${this.BASE_URL}/site`;
    return this.http.post<Site>(url, createSiteDto);
  }

  /**
   * Updates an office location by id, provided it exists
   * @param createSiteDto
   */
  updateSite(siteId: number, createSiteDto: CreateSiteDto): Observable<Site> {
    const url = `${this.BASE_URL}/site/${siteId}`;
    return this.http.put<Site>(url, createSiteDto);
  }

  /**
   * Deletes an office location by id, provided it exists
   * @param officeLocation
   */
  deleteSite(id: number): Observable<Site> {
    const url = `${this.BASE_URL}/site/${id}`;
    return this.http.delete<Site>(url);
  }

  /**
   * Deletes multiple office locations by id, provided they exist
   * @param siteIds
   */
  deleteSitesMultiple(siteIds: number[]): Observable<Site[]> {
    const url = `${this.BASE_URL}/site/delete`;
    return this.http.post<Site[]>(url, siteIds);
  }
  // #endregion

  getCompanyOrgChart(): Observable<Array<OrgChartUserDto>> {
    const url = '/api/company/org-chart';
    return this.http.get<Array<OrgChartUserDto>>(url);
  }

  updateFiscalYear(updateCompanyFiscalYearDto: UpdateCompanyFiscalYearDto): Observable<Company> {
    const url = `${this.BASE_URL}/fiscal-year`;
    return this.http.put<CompanyServerSide>(url, updateCompanyFiscalYearDto).pipe(map(company => new Company(company)));
  }

  // updateCompanyWording(companyWording: CompanyWording): Observable<CompanyWording> {
  //   const url = `${this.BASE_URL}/company-wording`;
  //   return this.http.put<CompanyWording>(url, companyWording);
  // }

  getAllUsersAssignedToSite(siteId: number): Observable<UserMinimal[]> {
    const url = `${this.BASE_URL}/site/${siteId}/users`;
    return this.http.get<UserMinimal[]>(url);
  }

  getAllUsersAssignedToDepartment(departmentId: number): Observable<UserMinimal[]> {
    const url = `${this.BASE_URL}/department/${departmentId}/users`;
    return this.http.get<UserMinimal[]>(url);
  }

  getAllProvisionedOrganisationalUnits(): Observable<ProvisionedOrganisationalUnit[]> {
    const url = `${this.BASE_URL}/department/externally-provisioned`;
    return this.http.get<ProvisionedOrganisationalUnit[]>(url);
  }

  getProvisionedOrganisationalUnitByOrganisationalUnitId(organisationalUnitId: number): Observable<ProvisionedOrganisationalUnit> {
    const url = `${this.BASE_URL}/department/externally-provisioned/${organisationalUnitId}`;
    return this.http.get<ProvisionedOrganisationalUnit>(url);
  }

  getAllProvisionedOfficeLocations(): Observable<ProvisionedOfficeLocation[]> {
    const url = `${this.BASE_URL}/site/externally-provisioned`;
    return this.http.get<ProvisionedOfficeLocation[]>(url);
  }

  getProvisionedOfficeLocationByOfficeLocationId(officeLocationId: number): Observable<ProvisionedOfficeLocation> {
    const url = `${this.BASE_URL}/site/externally-provisioned/${officeLocationId}`;
    return this.http.get<ProvisionedOfficeLocation>(url);
  }

  getDepartments(ids: number[], name: string): Observable<Department[]> {
    const url = `${this.BASE_URL}/department/getbyid`;

    let params = new HttpParams();

    if (ids) {
      ids.forEach(id => {
        params = params.append('id', id);
      });
    }

    if (name && name.length > 0) {
      params = params.append('name', name);
    }

    return this.http.get<Department[]>(url, { params: params });
  }

  getSites(ids: number[], name: string): Observable<Site[]> {
    const url = `${this.BASE_URL}/site/getbyid`;

    let params = new HttpParams();

    if (ids) {
      ids.forEach(id => {
        params = params.append('id', id);
      });
    }

    if (name && name.length > 0) {
      params = params.append('name', name);
    }

    return this.http.get<Site[]>(url, { params: params });
  }
}
