<!-- TODO: Show more properties here -->
<div class="site-display" [class.can-select]="canSelect" [class.is-selected]="selected" *ngIf="site" (click)="selectSite()">
  <div class="row m-left-0 m-right-0">
    <div class="col-data">
    
      <div class="top-row">
        <span class="title">{{site.name}}</span>
      </div>
    </div>

    <div class="col-checkbox" *ngIf="canSelect">
      <div class="check-container">
        <span class="fal fa-fw fa-check icon-selected"></span>
        <span class="fal fa-fw fa-times icon-deselect"></span>
      </div>
    </div>
  </div>
</div>
