/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-types */
import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { SkillRatingOption } from '@app/domain/skill_rating/model/skill-rating-option.model';

interface StarValue {
  selection: number;
  comment: string;
}

@Component({
  selector: 'app-star-scale',
  templateUrl: './star-scale.component.html',
  styleUrls: ['./star-scale.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => StarScaleComponent),
    multi: true,
  }]
})
export class StarScaleComponent implements ControlValueAccessor, OnInit {

  @Input() disabled: boolean;
  @Input() display: boolean;
  @Input() showTooltips: boolean;
  @Input() starCount: number;
  @Input() starFontSize: string;
  @Input() iconUsed: string;
  @Input() optionMetadata: SkillRatingOption[];
  @Input() showLegend: boolean;

  hoveredIndex: number;
  metadata: {
    [index: number]: SkillRatingOption
  };

  selection?: number;
  _value: StarValue | number;

  onChange = (_: any) => {};
  onTouched = () => {};

  constructor() {
    this.disabled = false;
    this.display = false;
    this.showTooltips = false;
    this.showLegend = false;
    this._value = -1;
    this.starCount = 5;
    this.hoveredIndex = -1;
    this.starFontSize = '1em';
    this.iconUsed = 'fa-star';
    this.optionMetadata = [];
    this.metadata = {};
  }

  get value(): (number | StarValue) {
    return this._value;
  }

  set value(v: (number | StarValue)) {
    if (v !== this._value) {
      this._value = v;
      this.selection = +v;
      this.onChange(v);
    }
  }

  ngOnInit(): void {
    this.metadata = this.getMetadata(this.optionMetadata);
  }

  getMetadata(options: SkillRatingOption[]): { [index: number]: SkillRatingOption } {
    const map: { [index: number]: SkillRatingOption } = {};

    if (options && options.length > 0) {
      options.forEach(option => {
        map[option.scoreValue] = option;
      });
    }

    return map;
  }

  // this will populate the value with the one set in the FormControl / ngModel
  writeValue(value: any): void {
    this.value = value;
  }

  // Register callbacks
  registerOnChange(fn: (_: any) => {}): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  onClickStar(index: number): void {
    if (this.disabled) { return; }
    if (this.display) { return; }
    this.value = index;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  onMouseEnter(index: number): void {
    if (this.disabled) { return; }
    if (this.display) { return; }
    this.hoveredIndex = index;
  }

  onMouseLeave(): void {
    if (this.disabled) { return; }
    if (this.display) { return; }
    this.hoveredIndex = -1;
  }

  shouldShowTooltipForIndex(index: number): boolean {
    if (!this.showTooltips) { return false; }
    if (!this.metadata) { return false; }
    const metadataItem = this.metadata[index];
    if (!metadataItem) { return false; }
    if (!metadataItem.name) { return false; }
    return true;
  }

}
