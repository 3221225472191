import { Injectable } from '@angular/core';
import { CreateRoleApplicationConfiguration } from '@app/domain/role_application_configuration/model/create-role-application-configuration.model';
import { RoleApplicationConfiguration } from '@app/domain/role_application_configuration/model/role-application-configuration.model';
import { RoleApplicationConfigurationAPIService } from '@app/domain/role_application_configuration/api/role-application-configuration-api.service';
import { NotifyUtils } from '@app/shared/utils/notify.utils';
import { Observable } from 'rxjs';
import { Page } from '@app/models/api/page.model';
import { PagingParams } from '@app/models/api/paging-params.model';
import { SortingParams } from '@app/models/api/sorting-params.model';
import { RoleApplicationMessages } from '@app/domain/role_application/locale/role-application.messages';

@Injectable({
  providedIn: 'root'
})
export class RoleApplicationConfigurationBusinessService {

  updating: number[];
  updatingUsers: number[];
  uploadingFile: boolean;

  constructor(
    private roleApplicationConfigurationAPI: RoleApplicationConfigurationAPIService,
    private notifyUtils: NotifyUtils
  ) {
    this.updating = [];
    this.updatingUsers = [];
    this.uploadingFile = false;
  }

  private startUpdating(ids: number[]) {
    this.updating = [...this.updating, ...ids];
  }

  private stopUpdating(ids: number[]) {
    this.updating = this.updating.filter(x => !ids.includes(x));
  }

  private startUpdatingUsers(ids: number[]) {
    this.updatingUsers = [...this.updatingUsers, ...ids];
  }

  private stopUpdatingUsers(ids: number[]) {
    this.updatingUsers = this.updatingUsers.filter(x => !ids.includes(x));
  }

  get(
    ids: number[]
  ): Observable<RoleApplicationConfiguration[]> {
    return this.roleApplicationConfigurationAPI
      .get(ids);
  }

  getPaginated(
    pagingParams: PagingParams,
    sortingParams: SortingParams,
    ids: number[]
  ): Observable<Page<RoleApplicationConfiguration>> {
    return this.roleApplicationConfigurationAPI.getPaginated(pagingParams, sortingParams, ids);
  }

  create(createDto: CreateRoleApplicationConfiguration): Promise<RoleApplicationConfiguration> {
    console.log(createDto);
    return this.roleApplicationConfigurationAPI.create(createDto).toPromise()
      .then(res => {
        this.notifyUtils.notify(RoleApplicationMessages.SUCCESS_CREATE_CONFIGURATION);
        return res;
      });
  }

  update(id: number, updateDto: CreateRoleApplicationConfiguration): Promise<RoleApplicationConfiguration> {
    if (this.updating.includes(id)) { return new Promise((resolve, reject) => reject()); }
    this.startUpdating([id]);

    return this.roleApplicationConfigurationAPI.update(id, updateDto).toPromise()
      .then(res => {
        this.notifyUtils.notify(RoleApplicationMessages.SUCCESS_UPDATE);
        return res;
      })
      .finally(() => {
        this.stopUpdating([id]);
      });
  }

  delete(id: number): Promise<RoleApplicationConfiguration> {
    if (this.updating.includes(id)) { return new Promise((resolve, reject) => reject()); }
    this.startUpdating([id]);

    return this.roleApplicationConfigurationAPI.delete(id).toPromise()
      .then(res => {
        this.notifyUtils.notify(RoleApplicationMessages.SUCCESS_DELETE);
        return res;
      })
      .finally(() => {
        this.stopUpdating([id]);
      });
  }

  getApplicableConfigurationForRole(roleId: number): Observable<RoleApplicationConfiguration> {
    return this.roleApplicationConfigurationAPI.getApplicableConfigurationForRole(roleId);
  }
}
