<ng-container *ngIf="longRunningTaskService.isTrackingTasks">
  <div class="container-long-running-task-progress-bar">
    <div class="long-running-task-progress-bar-inner" (click)="onClickProgressBar()">
      <div>These types of requests can take some time to complete. Click here to view them.</div>
      
      <app-progress-bar [currentValue]="progressBarValue"></app-progress-bar>
    </div>
  
    <div class="long-running-task-progress-bar-modal">
      <app-modal #modalDetails>
        <ng-container *ngIf="modalDetails?.visible">
          <app-long-running-task-overview></app-long-running-task-overview>
        </ng-container>
      </app-modal>
    </div>
  </div>
</ng-container>
