import { Component, Input, OnInit } from '@angular/core';
import { SentimentScaleOption } from '../../model/sentiment-scale-option.model';

@Component({
  selector: 'app-sentiment-scale-option-display',
  templateUrl: './sentiment-scale-option-display.component.html',
  styleUrls: ['./sentiment-scale-option-display.component.scss']
})
export class SentimentScaleOptionDisplayComponent implements OnInit {

  @Input() option: SentimentScaleOption;
  @Input() showNumbers: boolean;
  @Input() showName: boolean;
  @Input() showDescription: boolean;
  @Input() showIndent: boolean;
  @Input() metadataInTooltip: boolean;
  @Input() lastUpdated?: Date;

  shouldInvertNumber: boolean;

  constructor() {
    this.option = undefined;
    this.showNumbers = true;
    this.showName = true;
    this.showIndent = true;
    this.showDescription = true;
    this.metadataInTooltip = false;
  }

  ngOnInit(): void {
    this.shouldInvertNumber = this.checkShouldInvertNumber();
  }

  // If the colour is dark, the number should be inverted
  // If the colour is light, the number should not be inverted
  checkShouldInvertNumber(): boolean {
    if (!this.option) { return false; }
    const rgb = this.hexToRgb(this.option.colour);
    const brightness = this.getBrightness(rgb.r, rgb.g, rgb.b);
    // return brightness < 128;
    // return brightness < 150;
    return brightness < 200;
  }

  hexToRgb(hex: string): { r: number, g: number, b: number } {
    const bigint = parseInt(hex.substring(1), 16);
    return {
      r: (bigint >> 16) & 255,
      g: (bigint >> 8) & 255,
      b: bigint & 255
    };
  }

  getBrightness(r: number, g: number, b: number): number {
    return Math.round(((r * 299) + (g * 587) + (b * 114)) / 1000);
  }

}
