<div class="comment-area">
  <ng-container *ngIf="commentCount > 0">
    <div class="text-center p-top-10 p-bottom-10" *ngIf="showButton">
      <button class="btn-frankli-round btn-frankli-small" [ngClass]="dropdown ? 'btn-frankli-cancel' : 'btn-frankli-green-inverted'" (click)="toggleDropdown()">{{dropdown ? 'Hide comments' : ('View comments (' + commentCount + ')')}}</button>
    </div>
  
    <div class="comments-container" [class.limit-height-scroll]="limitHeightScroll" *ngIf="dropdown">
      <ng-container *ngFor="let response of fullAnswers">
        <div class="comment-item avoid-breaks-print" *ngIf="response.comment">
          <div class="row m-left-0 m-right-0">
            <!-- Text Values -->
            <div *ngIf="isTextAnswer">
              <strong>{{response.textAnswer}}</strong>
            </div>
            <!-- Number Values -->
            <div class="col-md-12 p-left-0 p-right-0" *ngIf="!isTextAnswer">
              <span class="response-icon" [ngClass]="'response-icon-' + response.textAnswer + (enps?'-enps':'')">
                {{response.textAnswer}}
              </span>
              <span class="m-left-10">{{response.comment ? response.comment : 'No comment'}}</span>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </ng-container>
  <ng-template #noComments>

  </ng-template>
</div>
