import { GoalAnalyticsItem } from '@app/models/analytics/goal-analytics.model';
import { GoalPriority } from '@app/models/goals/goal-priority.model';
import { GoalStatus } from '@app/models/goals/goal-status.model';

export const mockGoalAnalytics: GoalAnalyticsItem = {
  goals: 43,
  goalsActive: 40,
  goalsCompleted: 3,
  goalsArchived: 0,
  averageGoalProgress: 64.3,
  usersWithGoals: [],
  usersWithoutGoals: [],
  priorities: {
    [GoalPriority.NOT_SET]: 5,
    [GoalPriority.P1]: 25,
    [GoalPriority.P2]: 6,
    [GoalPriority.P3]: 5,
    [GoalPriority.P4]: 2,
    [GoalPriority.P5]: 0
  },
  status: {
    [GoalStatus.OFF_TRACK]: 2,
    [GoalStatus.PROGRESSING]: 5,
    [GoalStatus.ON_TRACK]: 36
  },
  monthlyGoals: {
    '1:23': 11,
    '2:23': 1,
    '3:23': 6,
    '4:23': 4,
    '5:23': 1,
    '6:23': 8,
    '7:23': 1,
    '8:23': 0,
    '9:23': 2,
    '10:23': 4,
    '11:23': 2,
    '12:23': 3
  },
  departmentBreakdown: {
    'Sales': 10,
    'Marketing': 6,
    'Engineering': 14,
    'Finance': 2,
    'Operations': 11
  },
  siteBreakdown: {
    'HQ': 28,
    'London': 8,
    'New York': 7
  },
  managerBreakdown: {
    'John Smith': 5,
    'Jane Doe': 6,
    'Bob Smith': 4,
    'Joe Bloggs': 7,
    'Sally Bloggs': 5,
    'Fred Bloggs': 5,
    'Tom Bloggs': 4,
    'Harry Bloggs': 3,
    'Mary Bloggs': 4,
    'Sue Bloggs': 0
  }
};
