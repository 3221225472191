import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-hamburger-menu',
  templateUrl: './hamburger-menu.component.html',
  styleUrls: ['./hamburger-menu.component.scss']
})
export class HamburgerMenuComponent {

  @Input() opened: boolean;
  @Input() invertColours: boolean;

  constructor() {
    this.opened = false;
    this.invertColours = false;
  }

}
