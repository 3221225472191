import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IOneToOneAPI } from '@app/domain/one_to_one/api/one-to-one-api.model';
import { map } from 'rxjs/operators';
import { ApiUtils } from '../../../shared/utils/api.utils';
import { PagingParams } from '@app/models/api/paging-params.model';
import { SortingParams } from '@app/models/api/sorting-params.model';
import { Page } from '@app/models/api/page.model';
import { ParentFilter } from '@app/models/api/parent-filter.model';
import { CreateOneToOneSchedule } from '../model/create-one-to-one-schedule.model';
import { OneToOneDirectReportInfo } from '../model/one-to-one-direct-report-info.model';
import { OneToOneMeetingFile } from '../model/one-to-one-meeting-file.model';
import { OneToOneMeetingStatus } from '../model/one-to-one-meeting-status.model';
import { OneToOneMeeting } from '../model/one-to-one-meeting.model';
import { OneToOneSchedule, OneToOneScheduleDetailsView, OneToOneScheduleMinimal } from '../model/one-to-one-schedule.model';
import { PostReviewCycleOneToOneView } from '../model/post-review-cycle-one-to-one-view.model';
import { RescheduleOneToOneMeeting } from '../model/reschedule-one-to-one-meeting.model';
import { TalkingPointTemplate } from '../model/talking-point-template.model';
import { TalkingPoint } from '../model/talking-point.model';
import { TransferOneToOneSchedule } from '../model/transfer-one-to-one-schedule.model';
import { FilteredPagedAndSortedRequest } from '@app/models/api/paging-sorting-api-request.model';
import { OneToOneNoteMinimal } from '../model/one-to-one-note.model';
import { CreateMeetingSentimentScaleScore, MeetingSentimentScaleScore, MeetingSentimentScaleScoreMinimal } from '../model/meeting-sentiment-scale-score.model';
import { ScheduleSentimentScaleTimeline } from '@app/shared/components/information-sidebar/tabs/information-sidebar-sentiment-scale-timeline/information-sidebar-sentiment-scale-timeline.component';
import { ChartTargetUser } from '@app/domain/sentiment_scale/page/sentiment-scale-reporting/sentiment-scale-reporting.component';

@Injectable()
export class OneToOneAPIService implements IOneToOneAPI {
  public readonly BASE_URL = 'api/one-to-one';

  headers = new HttpHeaders({
    'Content-Type': 'application/json',
  });
  html_headers = new HttpHeaders({
    'Content-Type': 'text/html',
  });

  constructor(private http: HttpClient) {
  }

  createOneToOneSchedule(createDto: CreateOneToOneSchedule): Observable<OneToOneSchedule> {
    const url = `${this.BASE_URL}/schedule`;
    return this.http.post<OneToOneSchedule>(url, createDto)
      .pipe(map(s => OneToOneResponseParsing.parseOneToOneSchedule(s)));
  }

  updateOneToOneSchedule(id: number, updateDto: CreateOneToOneSchedule): Observable<OneToOneSchedule> {
    const url = `${this.BASE_URL}/schedule/${id}`;
    return this.http.put<OneToOneSchedule>(url, updateDto)
      .pipe(map(s => OneToOneResponseParsing.parseOneToOneSchedule(s)));
  }

  getOneToOneSchedulesForOverview(filter: ParentFilter, paging: PagingParams, sorting: SortingParams): Observable<Page<OneToOneScheduleMinimal>> {
    const url = `${this.BASE_URL}/schedules/overview`;

    const body = new FilteredPagedAndSortedRequest(filter, paging, sorting);

    return this.http.post<Page<OneToOneScheduleMinimal>>(url, body);
  }

  getOneToOneSchedulesForUserMe(): Observable<OneToOneScheduleDetailsView[]> {
    const url = `${this.BASE_URL}/schedule/me`;
    return this.http.get<OneToOneScheduleDetailsView[]>(url);
  }

  getOneToOneScheduleById(scheduleId: number): Observable<OneToOneScheduleMinimal> {
    const url = `${this.BASE_URL}/schedules/${scheduleId}`;
    return this.http.get<OneToOneScheduleMinimal>(url);
  }

  getOneToOneMeetingById(scheduleId: number, meetingId: number): Observable<OneToOneMeeting> {
    const url = `${this.BASE_URL}/schedules/${scheduleId}/meetings/${meetingId}`;
    return this.http.get<OneToOneMeeting>(url);
  }

  getOneToOneScheduleDetailsById(scheduleId: number): Observable<OneToOneScheduleDetailsView> {
    const url = `${this.BASE_URL}/schedules/${scheduleId}/details`;
    return this.http.get<OneToOneScheduleDetailsView>(url);
  }

  getOneToOneScheduleByIdManaged(scheduleId: number): Observable<OneToOneSchedule> {
    const url = `${this.BASE_URL}/schedule/managed/${scheduleId}`;
    return this.http.get<OneToOneSchedule>(url)
      .pipe(map(s => OneToOneResponseParsing.parseOneToOneSchedule(s)));
  }

  getOneToOneSchedulesByManagerId(managerId: number): Observable<OneToOneSchedule[]> {
    const url = `${this.BASE_URL}/schedule/manager/${managerId}`;
    return this.http.get<OneToOneSchedule[]>(url);
  }

  getOneToOneDirectReportInfo(parentFilter: ParentFilter): Observable<OneToOneDirectReportInfo[]> {
    const url = `${this.BASE_URL}/schedule/manage/direct`;
    return this.http.post<OneToOneDirectReportInfo[]>(url, parentFilter);
  }

  // TODO: Have params in this order so they're consistent -> scheduleId, meetingId, talkingPoint
  addOneToOneMeetingTalkingPoint(talkingPoint: TalkingPoint, scheduleId: number, meetingId: number): Observable<OneToOneMeeting> {
    const url = `${this.BASE_URL}/${scheduleId}/meeting/${meetingId}/update/talking-point`;
    return this.http.put<OneToOneMeeting>(url, talkingPoint, {headers: this.headers})
      .pipe(map(m => OneToOneResponseParsing.parseOneToOneMeeting(m)));
  }

  removeOneToOneMeetingTalkingPoint(scheduleId: number, meetingId: number, talkingPoint: TalkingPoint): Observable<TalkingPoint[]> {
    const url = `${this.BASE_URL}/${scheduleId}/meeting/${meetingId}/remove/talking-point`;
    return this.http.put<TalkingPoint[]>(url, talkingPoint, {headers: this.headers});
  }

  bumpTalkingPoint(scheduleId: number, meetingId: number, talkingPointId: number): Observable<OneToOneMeeting> {
    const url = `${this.BASE_URL}/schedules/${scheduleId}/meeting/${meetingId}/talking-point/${talkingPointId}/bump`;
    return this.http.get<OneToOneMeeting>(url)
      .pipe(map(m => OneToOneResponseParsing.parseOneToOneMeeting(m)));
  }

  actionTalkingPoint(scheduleId: number, meetingId: number, talkingPointId: number, checked: boolean): Observable<TalkingPoint> {
    const url = `${this.BASE_URL}/schedules/${scheduleId}/meeting/${meetingId}/talking-point/${talkingPointId}/action`;
    return this.http.put<TalkingPoint>(url, checked);
  }

  commentOneToOneMeetingTalkingPoint(scheduleId: number, meetingId: number, talkingPointId: number, newComment: string): Observable<OneToOneMeeting> {
    console.info('Posting comment to talking point: ' + newComment);
    console.info('Comment length: ' + newComment.length);
    const url = `${this.BASE_URL}/${scheduleId}/meeting/${meetingId}/talking-point/${talkingPointId}/comment`;
    return this.http.put<OneToOneMeeting>(url, newComment)
      .pipe(map(m => OneToOneResponseParsing.parseOneToOneMeeting(m)));
  }

  // TODO: Have params in this order so they're consistent -> scheduleId, meetingId, talkingPoints
  reorderMeetingTalkingPoints(talkingPoints: TalkingPoint[], scheduleId: number, meetingId: number): Observable<TalkingPoint[]> {
    const url = `${this.BASE_URL}/${scheduleId}/meeting/${meetingId}/reorder/talking-points`;
    return this.http.post<TalkingPoint[]>(url, talkingPoints, {headers: this.headers});
  }

  getOneToOneSchedulesForManagerMe(): Observable<OneToOneScheduleDetailsView[]> {
    const url = `${this.BASE_URL}/schedules`;
    return this.http.get<OneToOneScheduleDetailsView[]>(url);
  }

  getArchivedOneToOneSchedulesForUserMe(): Observable<OneToOneSchedule[]> {
    const url = `${this.BASE_URL}/schedules/archived/user/me`;
    return this.http.get<OneToOneSchedule[]>(url);
  }

  getIncompleteFromPreviousTalkingPoints(scheduleId: number, meetingId: number): Observable<TalkingPoint[]> {
    const url = `${this.BASE_URL}/${scheduleId}/meeting/${meetingId}/prev/talking-point`;
    return this.http.get<TalkingPoint[]>(url);
  }

  pauseSchedule(scheduleId: number): Observable<OneToOneSchedule> {
    const url = `${this.BASE_URL}/schedule/${scheduleId}/pause`;
    return this.http.put<OneToOneSchedule>(url, null, {headers: this.headers})
      .pipe(map(s => OneToOneResponseParsing.parseOneToOneSchedule(s)));
  }

  resumeSchedule(scheduleId: number): Observable<OneToOneSchedule> {
    const url = `${this.BASE_URL}/schedule/${scheduleId}/resume`;
    return this.http.put<OneToOneSchedule>(url, null, {headers: this.headers})
      .pipe(map(s => OneToOneResponseParsing.parseOneToOneSchedule(s)));
  }

  archiveSchedule(scheduleId: number): Observable<OneToOneSchedule> {
    const url = `${this.BASE_URL}/schedule/${scheduleId}/archive`;
    return this.http.put<OneToOneSchedule>(url, null, {headers: this.headers})
      .pipe(map(s => OneToOneResponseParsing.parseOneToOneSchedule(s)));
  }

  deleteSchedule(scheduleId: number): Observable<OneToOneSchedule> {
    const url = `${this.BASE_URL}/schedule/${scheduleId}`;
    return this.http.delete<OneToOneSchedule>(url, {headers: this.headers})
      .pipe(map(s => OneToOneResponseParsing.parseOneToOneSchedule(s)));
  }

  // TODO: This is it's own thing - move it to it's own API
  // #region - TALKING POINT TEMPLATES
  getTalkingPointTemplateById(id: number): Observable<TalkingPointTemplate> {
    const url = `${this.BASE_URL}/template/${id}`;
    return this.http.get<TalkingPointTemplate>(url);
  }

  getTalkingPointTemplateByName(name: string): Observable<TalkingPointTemplate> {
    const url = `${this.BASE_URL}/template/name/${name}`;
    return this.http.get<TalkingPointTemplate>(url);
  }

  getDefaultTalkingPointTemplate(): Observable<TalkingPointTemplate> {
    const url = `${this.BASE_URL}/template/default`;
    return this.http.get<TalkingPointTemplate>(url);
  }

  getTalkingPointTemplates(): Observable<TalkingPointTemplate[]> {
    const url = `${this.BASE_URL}/template`;
    return this.http.get<TalkingPointTemplate[]>(url);
  }

  // TODO: Use create DTO
  createTalkingPointTemplate(talkingPointTemplate: TalkingPointTemplate): Observable<TalkingPointTemplate> {
    const url = `${this.BASE_URL}/template`;
    return this.http.put<TalkingPointTemplate>(url, talkingPointTemplate);
  }

  // TODO: Use create DTO
  updateTalkingPointTemplate(id: number, talkingPointTemplate: TalkingPointTemplate): Observable<TalkingPointTemplate> {
    const url = `${this.BASE_URL}/template/${id}`;
    return this.http.patch<TalkingPointTemplate>(url, talkingPointTemplate);
  }

  deleteTalkingPointTemplateById(id: number): Observable<TalkingPointTemplate> {
    const url = `${this.BASE_URL}/template/${id}`;
    return this.http.delete<TalkingPointTemplate>(url);
  }
  // #endregion

  getOldestOneToOneMeeting(): Observable<OneToOneMeeting> {
    const url = `${this.BASE_URL}/schedule/meeting/oldest`;
    return this.http.get<OneToOneMeeting>(url);
  }

  getScheduleForCycleIdAndMeManager(cycleId: number): Observable<OneToOneSchedule> {
    const url = `${this.BASE_URL}/schedule/cycle/${cycleId}`;
    return this.http.get<OneToOneSchedule>(url)
      .pipe(map(s => OneToOneResponseParsing.parseOneToOneSchedule(s)));
  }

  getScheduleForDirectReportAndCycleId(userId: number, cycleId: number): Observable<OneToOneSchedule> {
    const url = `${this.BASE_URL}/schedule/${userId}/cycle/${cycleId}`;
    return this.http.get<OneToOneSchedule>(url)
      .pipe(map(s => OneToOneResponseParsing.parseOneToOneSchedule(s)));
  }

  getPostReviewMeetingsByCycleId(cycleId: number): Observable<PostReviewCycleOneToOneView[]> {
    const url = `${this.BASE_URL}/meetings/post-review/${cycleId}`;
    return this.http.get<PostReviewCycleOneToOneView[]>(url);
  }

  getSidebarOneToOneSchedules(userIds: number[]): Observable<OneToOneScheduleDetailsView[]> {
    const url = `${this.BASE_URL}/sidebar`;

    let params = new HttpParams();

    if (userIds && userIds.length > 0) {
      params = params.append('userIds', userIds.join(','));
    }

    return this.http.get<OneToOneScheduleDetailsView[]>(url, { params: params });
  }

  searchTalkingPointTemplates(pagingParams: PagingParams, sortingParams: SortingParams, name: string): Observable<Page<TalkingPointTemplate>> {
    const url = `${this.BASE_URL}/template/search`;

    let params = ApiUtils.createPageAndSortParams(pagingParams, sortingParams);

    if (name && name.length > 0) {
      params = params.append('name', name);
    }

    return this.http.get<Page<TalkingPointTemplate>>(url, { params: params });
  }

  rebuildCalendarEventsForScheduleId(scheduleId: number): Observable<OneToOneSchedule> {
    const url = `${this.BASE_URL}/rebuild-calendar-events/schedule/${scheduleId}/`;
    return this.http.post<OneToOneSchedule>(url, null);
  }

  rebuildCalendarEventsForUserId(userId: number): Observable<boolean> {
    const url = `${this.BASE_URL}/rebuild-calendar-events/user/${userId}/`;
    return this.http.post<boolean>(url, null);
  }

  transferScheduleToNewManager(scheduleId: number, transferOneToOneSchedule: TransferOneToOneSchedule): Observable<OneToOneSchedule> {
    const url = `${this.BASE_URL}/schedule/${scheduleId}/transfer`;
    return this.http.post<OneToOneSchedule>(url, transferOneToOneSchedule);
  }

  getSchedulesForUserAsFrankliAdmin(companyId: number, userId: number): Observable<OneToOneSchedule[]> {
    const url = `${this.BASE_URL}/schedules/admin`;

    const params = new HttpParams()
      .append('companyId', companyId)
      .append('userId', userId);

    return this.http.get<OneToOneSchedule[]>(url, { params: params })
      .pipe(map(res => res.map(s => OneToOneResponseParsing.parseOneToOneSchedule(s))));
  }

  getSchedulesManagedByUserId(userId: number): Observable<OneToOneScheduleDetailsView[]> {
    const url = `${this.BASE_URL}/schedules/${userId}/managing`;

    return this.http.get<OneToOneScheduleDetailsView[]>(url);
  }

  // TALKING POINT COMMENTS

  addTalkingPointComment(scheduleId: number, meetingId: number, talkingPointId: number, commentParsed: string): Observable<TalkingPoint> {
    const url = `${this.BASE_URL}/schedules/${scheduleId}/meeting/${meetingId}/talking-point/${talkingPointId}/comment`;
    return this.http.put<TalkingPoint>(url, commentParsed);
  }

  // SHARED NOTES

  addMeetingSharedNotes(scheduleId: number, meetingId: number, sharedNotes: string): Observable<OneToOneMeeting> {
    const url = `${this.BASE_URL}/schedules/${scheduleId}/meeting/${meetingId}/shared-notes`;
    return this.http.put<OneToOneMeeting>(url, sharedNotes);
  }

  // PRIVATE NOTES

  getMeetingPrivateNotes(scheduleId: number, meetingId: number): Observable<OneToOneNoteMinimal> {
    const url = `${this.BASE_URL}/schedules/${scheduleId}/meeting/${meetingId}/private-notes`;
    return this.http.get<OneToOneNoteMinimal>(url);
  }

  addMeetingPrivateNotes(scheduleId: number, meetingId: number, privateNoteContent: string): Observable<OneToOneNoteMinimal> {
    const url = `${this.BASE_URL}/schedules/${scheduleId}/meeting/${meetingId}/private-notes`;
    return this.http.put<OneToOneNoteMinimal>(url, privateNoteContent);
  }

  // FILES

  getMeetingFiles(scheduleId: number, meetingId: number): Observable<OneToOneMeetingFile[]> {
    const url = `${this.BASE_URL}/schedules/${scheduleId}/meeting/${meetingId}/files`;
    return this.http.get<OneToOneMeetingFile[]>(url);
  }

  addMeetingFile(scheduleId: number, meetingId: number, file: File): Observable<OneToOneMeetingFile> {
    const url = `${this.BASE_URL}/schedules/${scheduleId}/meeting/${meetingId}/files`;

    const formData = new FormData();
    formData.append('file', file);
    return this.http.post<OneToOneMeetingFile>(url, formData);
  }

  deleteMeetingFile(scheduleId: number, meetingId: number, fileName: string): Observable<void> {
    const url = `${this.BASE_URL}/schedules/${scheduleId}/meeting/${meetingId}/files/${fileName}`;
    return this.http.delete<void>(url);
  }

  getOneToOneSchedulesLinkedToReviews(): Observable<OneToOneScheduleDetailsView[]> {
    const url = `${this.BASE_URL}/schedules/linked-to-reviews`;
    return this.http.get<OneToOneScheduleDetailsView[]>(url);
  }
  
  rescheduleMeeting(scheduleId: number, meetingId: number, rescheduleOneToOneMeeting: RescheduleOneToOneMeeting): Observable<OneToOneMeeting> {
    const url = `${this.BASE_URL}/schedules/${scheduleId}/meeting/${meetingId}/reschedule`;
    return this.http.put<OneToOneMeeting>(url, rescheduleOneToOneMeeting);
  }

  updateMeetingStatus(scheduleId: number, meetingId: number, status: OneToOneMeetingStatus): Observable<OneToOneMeeting> {
    const url = `${this.BASE_URL}/schedules/${scheduleId}/meeting/${meetingId}/status`;

    const body = {
      status: status
    };

    return this.http.put<OneToOneMeeting>(url, body);
  }

  getMeetingSentimentScaleScores(scheduleId: number, meetingId: number): Observable<MeetingSentimentScaleScore[]> {
    const url = `${this.BASE_URL}/schedules/${scheduleId}/meeting/${meetingId}/sentiment-scale-score`;
    return this.http.get<MeetingSentimentScaleScore[]>(url);
  }

  updateMeetingSentimentScaleScore(scheduleId: number, meetingId: number, score: CreateMeetingSentimentScaleScore): Observable<MeetingSentimentScaleScore> {
    const url = `${this.BASE_URL}/schedules/${scheduleId}/meeting/${meetingId}/sentiment-scale-score`;
    return this.http.put<MeetingSentimentScaleScore>(url, score);
  }

  searchForMeetingByContents(scheduleId: number, searchTerm: string): Observable<number[]> {
    const url = `${this.BASE_URL}/schedules/${scheduleId}/advanced-search`;

    let httpParams = new HttpParams();
    if (searchTerm) {
      httpParams = httpParams.append('query', searchTerm);
    }

    return this.http.get<number[]>(url, { params: httpParams });
  }

  getSentimentTimelineForSchedule(scheduleId: number): Observable<ScheduleSentimentScaleTimeline> {
    const url = `${this.BASE_URL}/schedules/${scheduleId}/sentiment-scale-timeline`;
    return this.http.get<ScheduleSentimentScaleTimeline>(url);
  }

  getLinkedScheduleIdWithUserForCycle(_cycleId: number, _userId: number): Observable<number> {
    const url = `${this.BASE_URL}/schedules/cycle/${_cycleId}/user/${_userId}`;
    return this.http.get<number>(url);
  }

  searchMeetingSentimentScaleScores(filter: ParentFilter, paging: PagingParams, sorting: SortingParams): Observable<Page<MeetingSentimentScaleScoreMinimal>> {
    const url = `${this.BASE_URL}/schedules/sentiment-scale-scores`;
    const body = new FilteredPagedAndSortedRequest(filter, paging, sorting);
    return this.http.post<Page<MeetingSentimentScaleScoreMinimal>>(url, body);
  }

  getChartData(filter: ParentFilter, paging: PagingParams, sorting: SortingParams): Observable<ChartTargetUser[]> {
    const url = `${this.BASE_URL}/schedules/sentiment-scale-chart`;
    const body = new FilteredPagedAndSortedRequest(filter, paging, sorting);
    return this.http.post<ChartTargetUser[]>(url, body);
  }

  getMeetingsByIdIn(meetingIds: number[]): Observable<OneToOneMeeting[]> {
    const url = `${this.BASE_URL}/meetings/in`;

    let params = new HttpParams();
    if (meetingIds && meetingIds.length > 0) {
      params = params.append('meetingIds', meetingIds.join(','));
    }

    return this.http.get<OneToOneMeeting[]>(url, { params: params });
  }

}

// TODO: Move to constructor of OneToOneSchedule class
class OneToOneResponseParsing {
  public static parseOneToOneSchedule(schedule: OneToOneSchedule): OneToOneSchedule {
    if (schedule) {
      schedule.talkingPointsRecurring = this.sortTalkingPoints(schedule.talkingPointsRecurring);

      if (schedule.meetingList) {
        schedule.meetingList = schedule.meetingList.map(m => this.parseOneToOneMeeting(m));
      }

    }
    return schedule;
  }

  public static parseOneToOneMeeting(meeting: OneToOneMeeting): OneToOneMeeting {
    meeting.talkingPoints = this.sortTalkingPoints(meeting.talkingPoints);
    return meeting;
  }

  public static sortTalkingPoints(talkingPoints: TalkingPoint[]): TalkingPoint[] {
    return talkingPoints.sort((a, b) => {
      if (a.orderIndex === undefined || b.orderIndex === undefined) {
        return 0;
      }

      return (a.orderIndex - b.orderIndex);
    });
  }
}
