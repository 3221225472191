import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {SidebarTabs} from '../navbar.component';
import {Router} from '@angular/router';
import {DomSanitizer, SafeStyle} from '@angular/platform-browser';
import {FrankliNotification} from '@app/models/notifications/frankli-notification.model';
import {NotificationAPIService} from '@app/shared/api/events.api.service';
import {HttpErrorResponse} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {Globals} from '@app/shared/globals/globals';
import {NavbarMessages} from '../navbar.messages';

@Component({
  selector: 'app-navbar-notifications-component',
  templateUrl: 'navbar-notifications.component.html',
  styleUrls: ['navbar-notifications.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class NavbarNotificationsComponent {
  @Input() notificationsShown: boolean = false;
  @Input() connected: boolean = false;
  @Input() sidebarTab: SidebarTabs = SidebarTabs.ACTIONS;
  @Input() notifications: Array<FrankliNotification> = new Array<FrankliNotification>();
  @Input() actions: Array<FrankliNotification> = new Array<FrankliNotification>();
  @Input() markingAsRead: Array<number> = new Array<number>();

  @Output() markAllAsRead = new EventEmitter<void>();
  @Output() markAsRead = new EventEmitter<FrankliNotification>();
  @Output() markAsViewed = new EventEmitter<FrankliNotification>();

  eSidebarTabs = SidebarTabs;
  eNavbarMessages = NavbarMessages;

  isMock = environment.mock.enabled;

  constructor(
    public globals: Globals,
    private router: Router,
    private domSanitizer: DomSanitizer,
    private notificationAPIService: NotificationAPIService
  ) { }

  public getNotificationViewportHeight(): SafeStyle {
    // This height calulation comes from .side-panel .side-panel-content { max-height }
    const maxHeight = window.innerHeight - 162;
    // The 73 here is the height of the notification div in the list
    const maxValue = this.notifications.length * 73;

    let styleString = '';
    if (maxValue > maxHeight) {
      styleString = 'height:' + maxHeight + 'px;';
    } else {
      styleString = 'height:' + maxValue + 'px;';
    }
    return this.domSanitizer.bypassSecurityTrustStyle(styleString);
  }

  public getTaskViewportHeight(): SafeStyle {
    // This height calulation comes from .side-panel .side-panel-content { max-height }
    const maxHeight = window.innerHeight - 162;
    // This height calulation comes from .side-panel .side-panel-content { max-height }
    const maxValue = this.actions.length * 73;

    let styleString = '';
    if (maxValue > maxHeight) {
      styleString = 'height:' + maxHeight + 'px;';
    } else {
      styleString = 'height:' + maxValue + 'px;';
    }
    return this.domSanitizer.bypassSecurityTrustStyle(styleString);
  }

  public navigateNotification(n: FrankliNotification) {
    this.markAsViewed.emit(n);

    // Record click
    this.notificationAPIService.recordClick(n.id).subscribe(notification => {
      // notification clicked
    }, (failure: HttpErrorResponse) => {
      console.error(failure.error)
    });

    // switch (n.eventType) {
    //   case 'PRAISE_RECEIVED':
    //     this.router.navigate([n.url], { queryParams: { type: 'praise' } })
    //     break;
    //   case 'FEEDBACK_RECEIVED':
    //     this.router.navigate([n.url], { queryParams: { type: 'feedback' } })
    //     break;
    //   case 'IDEA_RECEIVED':
    //     this.router.navigate([n.url], { queryParams: { type: 'ideas' } })
    //     break;
    //   case 'COMPLETED_SURVEY':
    //     this.router.navigate([n.url])
    //     break;
    //   default:
    //     this.router.navigateByUrl(n.url);
    // }
    this.router.navigateByUrl(n.url);

  }

  public sendMarkAsRead(notification: FrankliNotification): void {
    this.markAsRead.emit(notification);
  }

  public sendMarkAllAsRead(): void {
    this.markAllAsRead.emit();
  }

  public navigateNotificationUser(id: number) {
    this.notificationsShown = false;
    this.router.navigate([`/profile/${id}`])
  }

  public isMarkingAsRead(notification: FrankliNotification): boolean {
    return this.markingAsRead.includes(notification.id);
  }

  public trackByNotifications(index: number, item: FrankliNotification): number {
    return item.id;
  }

  public trackByTasks(index: number, item: FrankliNotification): number {
    return item.id;
  }

}
