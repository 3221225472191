import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {TriNetConfiguration} from '@models/integrations/trinet/tri-net-configuration';
import {CreateTriNetConfiguration} from '@models/integrations/trinet/create-tri-net-configuration';
import {TriNetConfigurationService} from 'app/shared/api/interfaces/tri-net-configuration.service';
import {UpdateTriNetConfiguration} from '@models/integrations/trinet/update-tri-net-configuration';

@Injectable()
export class TriNetConfigurationApiService implements TriNetConfigurationService {
    private readonly baseUrl = 'api/tri-net/configuration';

    constructor(private readonly http: HttpClient) {
    }

    getTriNetConfiguration(): Observable<TriNetConfiguration> {
        const url = `${this.baseUrl}`;
        return this.http.get<TriNetConfiguration>(url);
    }

    createTriNetConfiguration(createTriNetConfiguration: CreateTriNetConfiguration): Observable<TriNetConfiguration> {
        const url = `${this.baseUrl}`;
        return this.http.post<TriNetConfiguration>(url, createTriNetConfiguration);
    }

    updateTriNetConfiguration(updateTriNetConfiguration: UpdateTriNetConfiguration): Observable<TriNetConfiguration> {
        const url = `${this.baseUrl}`;
        return this.http.patch<TriNetConfiguration>(url, updateTriNetConfiguration);
    }

    deleteTriNetConfiguration(): Observable<void> {
        const url = `${this.baseUrl}`;
        return this.http.delete<void>(url);
    }
}