import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SurveyQuestionAnswered } from '@app/models/survey/question-answered.model';

@Component({
  selector: 'app-survey-reporting-comments',
  templateUrl: './survey-reporting-comments.component.html',
  styleUrls: ['./survey-reporting-comments.component.scss']
})
export class SurveyReportingCommentsComponent implements OnInit {
  @Input() showButton: boolean;
  @Input() dropdown: boolean;
  @Input() fullAnswers: SurveyQuestionAnswered[];
  @Input() commentCount: number;
  @Input() enps: boolean;
  @Input() isTextAnswer: boolean;
  @Input() limitHeightScroll: boolean;

  @Output() dropdownToggled: EventEmitter<boolean>;

  constructor() {
    this.limitHeightScroll = true;
    this.isTextAnswer = false;
    this.showButton = true;
    this.dropdown = false;
    this.enps = false;

    this.fullAnswers = [];
    this.commentCount = 0;

    this.dropdownToggled = new EventEmitter<boolean>();
  }

  ngOnInit(): void {
  }

  toggleDropdown() {
    this.dropdownToggled.emit(!this.dropdown);
  }
}
