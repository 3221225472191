import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { GoalType } from '@app/models/goals/goal-type.enum';
import { Globals } from '@app/shared/globals/globals';
import { GoalUtils } from '@app/shared/utils/goal.utils';

@Component({
  selector: 'app-goal-type-display',
  templateUrl: './goal-type-display.component.html',
  styleUrls: ['./goal-type-display.component.scss']
})
export class GoalTypeDisplayComponent implements OnChanges {
  public readonly eGoalType = GoalType;

  @Input() type: GoalType | null;

  typeTooltip: string;
  typeIconClass: string;

  constructor(
    public globals: Globals
  ) {
    this.type = GoalType.PERSONAL_OPERATIONAL;
    this.typeTooltip = '';
    this.typeIconClass = 'fa-question';
  }

  ngOnChanges(changes: SimpleChanges): void {
    const type = changes['type'];
    if (type) {
      this.onTypeChanged(type.currentValue);
    }
  }

  onTypeChanged(type: GoalType | null): void {
    if (type === null) {
      this.typeIconClass = 'fa-question';
      this.typeTooltip = '';
      return;
    }

    this.typeIconClass = GoalUtils.getIconClassForGoalType(type);
    this.typeTooltip = GoalUtils.getMessageCodeForGoalType(type, this.globals.terminology);
  }
}
