import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {VideoLibraryService} from './interfaces/video-library.service';
import {CreateHelpVideoDto} from '@app/models/video-library/create-help-video.dto';
import {IHelpVideo} from '@app/models/video-library/help-video.model';
import {map} from 'rxjs/operators';

@Injectable()
export class VideoLibraryAPIService implements VideoLibraryService {
  private readonly BASE_URL = 'api/video-library';

  constructor(
    private http: HttpClient
  ) { }

  createVideo(createHelpVideoDto: CreateHelpVideoDto): Observable<IHelpVideo> {
    const url = `${this.BASE_URL}/`;
    return this.http.post<IHelpVideo>(url, createHelpVideoDto);
  }

  updateVideo(id: number, createHelpVideoDto: CreateHelpVideoDto): Observable<IHelpVideo> {
    const url = `${this.BASE_URL}/${id}`;
    return this.http.patch<IHelpVideo>(url, createHelpVideoDto);
  }

  deleteVideo(id: number): Observable<IHelpVideo> {
    const url = `${this.BASE_URL}/${id}`;
    return this.http.delete<IHelpVideo>(url);
  }

  getVideoById(id: number): Observable<IHelpVideo> {
    const url = `${this.BASE_URL}/${id}`;
    return this.http.get<IHelpVideo>(url);
  }

  getAllVideos(): Observable<IHelpVideo[]> {
    const url = `${this.BASE_URL}/`;
    return this.http.get<IHelpVideo[]>(url);
  }

  markVideoAsWatched(id: number): Observable<IHelpVideo> {
    const url = `${this.BASE_URL}/${id}/watched`;
    return this.http.post<IHelpVideo>(url, true);
  }

  markVideoAsNotWatched(id: number): Observable<IHelpVideo> {
    const url = `${this.BASE_URL}/${id}/watched`;
    return this.http.post<IHelpVideo>(url, false);
  }

  getThumbnailForVimeoVideoId(vimeoId: string): Observable<string> {
    const url = `${this.BASE_URL}/thumbnail/${vimeoId}`;
    return this.http.get(url)
    .pipe(
      map((res: any) => {
        const data: any[] = res.data;
        if (data) {
          const activeThumbnails = data.filter((d: any) => d.active);
          if (activeThumbnails && activeThumbnails.length > 0) {
            return activeThumbnails[0].base_link;
          }
        }

        return res;
      })
    );
  }
}
