<div class="container-sidebar-action-points">
  <app-state [state]="state">
    <ng-container *ngIf="!state.loading && !state.error;">
      <ng-container *ngIf="dataAll.length > 0; else templateNoData;">
        <div class="area-action-points-lists">
          <ng-container *ngIf="dataNewlyAdded.length > 0">
            <div class="area-action-points-new">
              <div class="p-bottom-5">
                <h5 class="standard-heading" translate>{{eOneToOneMessages.NEWLY_ADDED_ACTION_POINTS}}</h5>
              </div>
              
              <div class="text-right p-bottom-5" *ngIf="dataNewlyAdded.length > 1">
                <div class="d-inline-block text-left">
                  <app-sorting-dropdown [control]="controlSortNew" [sortingOptions]="sortingOptions"></app-sorting-dropdown>
                </div>
              </div>
      
              <div class="data-list">
                <ng-container *ngFor="let task of dataNewlyAdded; index as index;">
                  <app-task-display [task]="task" [actionsShown]="['view', 'edit', 'unlink', 'archive', 'status']" (onClickAction)="onTaskAction($event, task)"></app-task-display>
                </ng-container>
              </div>
            </div>
          </ng-container>
  
          <ng-container *ngIf="dataForReview.length > 0">
            <div class="area-action-points-review">
              <div class="p-bottom-5">
                <h5 class="standard-heading" translate>{{eOneToOneMessages.ACTION_POINTS_TO_REVIEW}}</h5>
              </div>

              <div class="text-right p-bottom-5" *ngIf="dataForReview.length > 1">
                <div class="d-inline-block text-left">
                  <app-sorting-dropdown [control]="controlSortExisting" [sortingOptions]="sortingOptions"></app-sorting-dropdown>
                </div>
              </div>
      
              <div class="data-list">
                <ng-container *ngFor="let task of dataForReview; index as index;">
                  <app-task-display [task]="task" [actionsShown]="['view', 'edit', 'unlink', 'archive', 'status']" (onClickAction)="onTaskAction($event, task)"></app-task-display>
                </ng-container>
              </div>
            </div>
          </ng-container>
  
          <div class="text-center">
            <app-button [type]="eButtonType.GREEN_INVERTED" (onClick)="startCreateTask()">{{eOneToOneMessages.ADD_ACTION_POINT | translate}}</app-button>
          </div>
        </div>
      </ng-container>

      <ng-template #templateNoData>
        <app-empty-state [height]="'200px'" [imageSrc]="'assets/img/empty-state/empty-state-tasks.svg'" [message]="'No action points'" [secondaryButtonShown]="true" [secondaryButtonText]="eOneToOneMessages.ADD_ACTION_POINT" (secondaryButtonPressed)="startCreateTask()"></app-empty-state>
      </ng-template>

      <app-modal #modal [showCloseButton]="false" [sidePanel]="true" [confirmClosing]="confirmModalClose" [confirmCloseModalSettings]="confirmCloseSettings" (onConfirmCloseModalResponse)="onCancelCreate($event)">
        <ng-container [ngSwitch]="modalType">
          <app-task-create *ngSwitchCase="eModalType.CREATE" [formCreate]="formCreate" [submitted]="state.submitted" (onFormCancel)="onCancelCreate()" (onFormSubmit)="onSubmitCreate($event)" (requestCloseModal)="onCancelCreate($event)" #componentCreate></app-task-create>
          <app-task-details-component *ngSwitchCase="eModalType.DETAILS" [task]="taskViewing" (requestCloseModal)="onCancelCreate()" (onTaskUpdated)="taskUpdated($event)"></app-task-details-component>
        </ng-container>
      </app-modal>
    </ng-container>
  </app-state>
</div>