import { Component, Input, OnInit } from '@angular/core';
import { IdeaEffect } from '@app/models/feedback/idea-effect.model';
import { Idea } from '@app/models/feedback/idea.model';
import { UserAPIService } from '@app/shared/api/user.api.service';
import { UserMinimal } from '@app/models/user/user-minimal.model';

@Component({
  selector: 'app-feedback-idea-display',
  templateUrl: './feedback-idea-display.component.html',
  styleUrls: ['./feedback-idea-display.component.scss']
})
export class FeedbackIdeaDisplayComponent implements OnInit {
  @Input() idea: Idea;

  @Input() showSourceUser: boolean;
  @Input() showDestinationUser: boolean;

  sourceUser?: UserMinimal;
  destinationUser?: UserMinimal;

  eIdeaEffect = IdeaEffect;

  constructor(
    private userAPIService: UserAPIService
  ) {
    this.idea = undefined!;
    this.destinationUser = undefined;

    this.showSourceUser = true;
    this.showDestinationUser = true;
  }

  ngOnInit() {
    if (!this.idea) { return; }

    if (this.showSourceUser) {
      this.sourceUser = this.idea.senderUser;
    }
    if (this.showDestinationUser) {
      this.destinationUser = this.idea.destinationUser;
    }
  }

}
