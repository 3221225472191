import { OneToOneFrequency } from '@app/domain/one_to_one/model/one-to-one-frequency.model';
import { OneToOneMeetingLength } from '@app/domain/one_to_one/model/one-to-one-meeting-length.model';
import { OneToOneAnalyticsItem } from '@app/models/analytics/one-to-one-analytics.model';
import { mockUsers } from '@app/mock/api/data/mockUsers';
import { OneToOneMeetingStatus } from '@app/domain/one_to_one/model/one-to-one-meeting-status.model';

export const mockOneToOneAnalytics: OneToOneAnalyticsItem = {
  schedules: 14,
  schedulesActive: 14,
  scheduled: 172,
  completed: 4,
  autoCompleted: 1,
  entityUsers: 35,
  countUsersWithSchedulesWithManager: 4,
  totalHoursScheduled: 89.75,
  totalHoursCompleted: 3,
  talkingPointsActioned: 19,
  talkingPointsUnActioned: 641,
  actionPointsCreated: 46,
  actionPointsActioned: 28,
  actionPointsUnActioned: 18,
  usersWithSchedules: mockUsers.slice(0, 5),
  usersWithoutSchedules: mockUsers.slice(5, 10),
  lengths: {
    [OneToOneMeetingLength.FIFTEEN_MINUTES]: 1,
    [OneToOneMeetingLength.THIRTY_MINUTES]: 9,
    [OneToOneMeetingLength.FORTY_FIVE_MINUTES]: 3,
    [OneToOneMeetingLength.SIXTY_MINUTES]: 1
  },
  frequencies: {
    [OneToOneFrequency.Daily]: 0,
    [OneToOneFrequency.Weekly]: 4,
    [OneToOneFrequency.Fortnightly]: 3,
    [OneToOneFrequency.Monthly]: 7,
    [OneToOneFrequency.Quarterly]: 0,
    [OneToOneFrequency.Annually]: 0,
    [OneToOneFrequency.Individual]: 0
  },
  monthlyMeetings: {
    '1:2021': {
      [OneToOneMeetingStatus.CANCELLED]: 0,
      [OneToOneMeetingStatus.IN_PROGRESS]: 0,
      [OneToOneMeetingStatus.MISSED]: 0,
      [OneToOneMeetingStatus.REOPENED]: 0,
      [OneToOneMeetingStatus.SCHEDULED]: 0,
      [OneToOneMeetingStatus.COMPLETED]: 1, 
      [OneToOneMeetingStatus.AUTO_COMPLETED]: 0,
    },
    '11:2021': {
      [OneToOneMeetingStatus.CANCELLED]: 0,
      [OneToOneMeetingStatus.IN_PROGRESS]: 0,
      [OneToOneMeetingStatus.MISSED]: 0,
      [OneToOneMeetingStatus.REOPENED]: 0,
      [OneToOneMeetingStatus.SCHEDULED]: 29,
      [OneToOneMeetingStatus.COMPLETED]: 0, 
      [OneToOneMeetingStatus.AUTO_COMPLETED]: 0,
    },
    '7:2021': {
      [OneToOneMeetingStatus.CANCELLED]: 0,
      [OneToOneMeetingStatus.IN_PROGRESS]: 0,
      [OneToOneMeetingStatus.MISSED]: 0,
      [OneToOneMeetingStatus.REOPENED]: 0,
      [OneToOneMeetingStatus.SCHEDULED]: 22,
      [OneToOneMeetingStatus.COMPLETED]: 0, 
      [OneToOneMeetingStatus.AUTO_COMPLETED]: 1,
    },
    '9:2021': {
      [OneToOneMeetingStatus.CANCELLED]: 0,
      [OneToOneMeetingStatus.IN_PROGRESS]: 0,
      [OneToOneMeetingStatus.MISSED]: 0,
      [OneToOneMeetingStatus.REOPENED]: 0,
      [OneToOneMeetingStatus.SCHEDULED]: 29,
      [OneToOneMeetingStatus.COMPLETED]: 0, 
      [OneToOneMeetingStatus.AUTO_COMPLETED]: 0,
    },
    '4:2021': {
      [OneToOneMeetingStatus.CANCELLED]: 0,
      [OneToOneMeetingStatus.IN_PROGRESS]: 0,
      [OneToOneMeetingStatus.MISSED]: 0,
      [OneToOneMeetingStatus.REOPENED]: 0,
      [OneToOneMeetingStatus.SCHEDULED]: 0,
      [OneToOneMeetingStatus.COMPLETED]: 0, 
      [OneToOneMeetingStatus.AUTO_COMPLETED]: 0,
    },
    '8:2021': {
      [OneToOneMeetingStatus.CANCELLED]: 0,
      [OneToOneMeetingStatus.IN_PROGRESS]: 0,
      [OneToOneMeetingStatus.MISSED]: 0,
      [OneToOneMeetingStatus.REOPENED]: 0,
      [OneToOneMeetingStatus.SCHEDULED]: 27,
      [OneToOneMeetingStatus.COMPLETED]: 0, 
      [OneToOneMeetingStatus.AUTO_COMPLETED]: 0,
    },
    '10:2021': {
      [OneToOneMeetingStatus.CANCELLED]: 0,
      [OneToOneMeetingStatus.IN_PROGRESS]: 0,
      [OneToOneMeetingStatus.MISSED]: 0,
      [OneToOneMeetingStatus.REOPENED]: 0,
      [OneToOneMeetingStatus.SCHEDULED]: 29,
      [OneToOneMeetingStatus.COMPLETED]: 0, 
      [OneToOneMeetingStatus.AUTO_COMPLETED]: 0,
    },
    '12:2021': {
      [OneToOneMeetingStatus.CANCELLED]: 0,
      [OneToOneMeetingStatus.IN_PROGRESS]: 0,
      [OneToOneMeetingStatus.MISSED]: 0,
      [OneToOneMeetingStatus.REOPENED]: 0,
      [OneToOneMeetingStatus.SCHEDULED]: 29,
      [OneToOneMeetingStatus.COMPLETED]: 0, 
      [OneToOneMeetingStatus.AUTO_COMPLETED]: 0,
    },
    '3:2021': {
      [OneToOneMeetingStatus.CANCELLED]: 0,
      [OneToOneMeetingStatus.IN_PROGRESS]: 0,
      [OneToOneMeetingStatus.MISSED]: 0,
      [OneToOneMeetingStatus.REOPENED]: 0,
      [OneToOneMeetingStatus.SCHEDULED]: 0,
      [OneToOneMeetingStatus.COMPLETED]: 1, 
      [OneToOneMeetingStatus.AUTO_COMPLETED]: 1,
    },
    '5:2021': {
      [OneToOneMeetingStatus.CANCELLED]: 0,
      [OneToOneMeetingStatus.IN_PROGRESS]: 0,
      [OneToOneMeetingStatus.MISSED]: 0,
      [OneToOneMeetingStatus.REOPENED]: 0,
      [OneToOneMeetingStatus.SCHEDULED]: 0,
      [OneToOneMeetingStatus.COMPLETED]: 1, 
      [OneToOneMeetingStatus.AUTO_COMPLETED]: 0,
    },
    '2:2021': {
      [OneToOneMeetingStatus.CANCELLED]: 0,
      [OneToOneMeetingStatus.IN_PROGRESS]: 0,
      [OneToOneMeetingStatus.MISSED]: 0,
      [OneToOneMeetingStatus.REOPENED]: 0,
      [OneToOneMeetingStatus.SCHEDULED]: 0,
      [OneToOneMeetingStatus.COMPLETED]: 1, 
      [OneToOneMeetingStatus.AUTO_COMPLETED]: 0,
    },
    '6:2021': {
      [OneToOneMeetingStatus.CANCELLED]: 0,
      [OneToOneMeetingStatus.IN_PROGRESS]: 0,
      [OneToOneMeetingStatus.MISSED]: 0,
      [OneToOneMeetingStatus.REOPENED]: 0,
      [OneToOneMeetingStatus.SCHEDULED]: 7,
      [OneToOneMeetingStatus.COMPLETED]: 0, 
      [OneToOneMeetingStatus.AUTO_COMPLETED]: 0,
    }
  }
};
