import moment from 'moment';
import { SessionEventType } from './session-event-type.model';
import { SessionEventServerSide } from './session-event-serverside.model';

export class SessionEvent {
  public id: number;
  public userId: number;
  public type: SessionEventType;
  public timestamp: Date;

  constructor(sessionEventServerSide: SessionEventServerSide) {
    this.id = sessionEventServerSide.id;
    this.userId = sessionEventServerSide.userId;
    this.type = sessionEventServerSide.type;
    this.timestamp = moment.utc(sessionEventServerSide.timestamp).toDate();
  }
}
