<ng-container *ngIf="data && data.items &&  ((numberOfTabsShown > 1) || alwaysShowTabs)">
  <!-- TODO: Base this off of screen width too -->
  <ng-container *ngIf="(!(globals.onMobile || forceMobileView) || vertical); else stateMobile">
    <div class="nav-tab-label" *ngIf="showLabel && !(globals.onMobile && vertical)"translate>{{data.label}}</div>

    <div class="nav-tab-outer" [class.nav-tab-outer-vertical]="vertical">
      <ng-container *ngFor="let tab of data.items; first as isFirst; last as isLast;">
        <div class="nav-tab-item" *ngIf="tab.condition()" [tooltip]="tab.tooltip | translate" [triggers]="globals.tooltipTriggers" [class.cannot-click]="(numberOfTabsShown === 1)" (click)="selectTab(tab)" [class.tab-selected]="(selected === tab.identifier) || isRouteActive(tab)" [routerLink]="tab.routeData?.path" routerLinkActive="tab-selected" angulartics2On="click" [angularticsAction]="tab.amplitude.action" [angularticsCategory]="tab.amplitude.category" [attr.data-intercom-target]="tab.intercomTarget">
          <ng-container *ngIf="data.iconsEnabled && tab.icon">
            <ng-container *ngIf="tab.icon.includes('assets/img/'); else templateFontAwesomeIcon;">
              <img class="nav-tab-icon-img" [src]="tab.icon"/>
            </ng-container>
            <ng-template #templateFontAwesomeIcon>
              <span class="fa-fw" [class.fal]="!(tab.icon.includes('fas') || tab.icon.includes('far'))" [ngClass]="tab.icon"></span>
            </ng-template>
            <span class="m-right-5"></span>
          </ng-container>
          <span *ngIf="!(globals.onMobile && vertical)" translate>{{tab.title}}</span>
          <ng-container *ngIf="tab.counter">
            <ng-container *ngIf="tab.counter(); let counter;">
              <span class="m-right-5"></span>
              <div class="tab-counter" [class.tab-counter-alt]="alternateTabCounter">{{counter}}</div>
            </ng-container>
          </ng-container>
          <ng-container *ngIf="tab.description">
            <br/>
            <small class="text-nowrap">{{tab.description}}</small>
          </ng-container>
        </div>
      </ng-container>
    </div>
  </ng-container>

  <ng-template #stateMobile>
    <div class="p-left-10 p-right-10 p-top-10 p-bottom-10">
      <div class="frankli-form-group frankli-form-group-filled">
        <label for="nav-tab" translate>{{data.label}}</label>
        <select id="nav-tab" class="form-control" [(ngModel)]="selectedMobile" (ngModelChange)="selectTabMobile($event)">
          <ng-container *ngFor="let tab of data.items">
            <option *ngIf="tab.condition()" [value]="tab.identifier" translate>{{tab.title}}</option>
          </ng-container>
        </select>
      </div>
    </div>
  </ng-template>
</ng-container>
