import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EmployeeComponentsModule } from './employee-components/employee-components.module';

@NgModule({
  imports: [
    CommonModule,
    EmployeeComponentsModule
  ]
})
export class EmployeeModule { }
