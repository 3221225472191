import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable()
export class ErrorService {
  private subjectStatus = new Subject<number>();
  private updateStatus = new Subject<boolean>();

  constructor() {
    this.updateStatus.next(false);
  }

  getStatus(): Observable<number> {
    return this.subjectStatus.asObservable();
  }

  sendStatus(status: number): void {
    this.subjectStatus.next(status);
  }

  getUpdateStatus(): Observable<boolean> {
    return this.updateStatus.asObservable();
  }

  setUpdateStatus(updateStatus: boolean): void {
    this.updateStatus.next(updateStatus);
  }
}
