<div class="container-dynamic-scale" [class.display-mode]="display">
  <div class="area-scale-options">
    <ng-container *ngIf="!editing; else templateEditing;">
      <div class="container-options-list">
        <ng-container *ngFor="let option of optionsOrdered; index as optionIndex;">
          <ng-container *ngIf="!display || (display && value === option.answerValue)">
            <app-dynamic-scale-option [index]="optionIndex" [option]="option" [editing]="editing" [submitted]="submitted" [selected]="value === option.answerValue" [disabled]="disabled" (onClickAction)="onClickAction($event, option, optionIndex)"></app-dynamic-scale-option>
          </ng-container>
        </ng-container>
      </div>
    </ng-container>
    
    <ng-template #templateEditing>
      <div class="container-drag-drop" cdkDropList #dynamicScaleOptionsList="cdkDropList" [cdkDropListData]="optionsEdit" (cdkDropListDropped)="onDropDraggableOption($event)">
        <ng-container *ngFor="let optionEdit of optionsEditArray; index as optionIndex;">
          <div class="wrapper-drag-drop-item" cdkDrag [cdkDragData]="optionEdit">
            <div class="drag-handle-overlap" cdkDragHandle></div>
            <app-dynamic-scale-option [index]="optionIndex" [optionEdit]="optionEdit" [editing]="editing" [submitted]="submitted" [disabled]="disabled" (onClickAction)="onClickActionEdit($event, optionEdit, optionIndex)"></app-dynamic-scale-option>
          </div>
        </ng-container>
  
        <div class="p-top-15 text-center">
          <app-button [type]="eButtonType.GREEN_INVERTED" (onClick)="onClickAddOption()">
            <span>Add option</span>
          </app-button>
        </div>
  
        <div class="frankli-form-group-alert" *ngIf="submitted && optionsEdit.invalid">
          <div *ngIf="optionsEdit.hasError('minlength')">Must have at least two options</div>
          <div *ngIf="optionsEdit.hasError('uniqueFormArrayControl')">The values of each option must be unique</div>
        </div>
      </div>
    </ng-template>
  </div>
</div>
