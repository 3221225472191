import { Injectable } from '@angular/core';
import { MockService } from '@app/mock/api/mock-service';
import { ScimUserService } from '@app/shared/api/interfaces/scim-user.service';
import { ScimUser } from '@app/models/user/scim-user.model';
import { HttpRequest } from '@angular/common/http';
import { sanitizeUrl } from '@app/shared/utils/helpers';
import { Observable, of } from 'rxjs';

@Injectable()
export class ScimUserMockService implements MockService, ScimUserService {
  handleRoute(req: HttpRequest<any>): any {
    const url = sanitizeUrl(req.urlWithParams);

    switch (true) {
      case url.endsWith('api/company'):
        return this.getScimUsersForCompany();
    }
  }

  getScimUsersForCompany(): Observable<ScimUser[]> {
    return of([]); // No need to mock data for active directory in public sandbox
  }

  getScimUserByUserId(userId: number): Observable<ScimUser> {
    return of(null);
  }

  getTeamsCallURIForUserByUserId(userId: number): Observable<URL> {
    return of(null);
  }
}