import { HostListener, Injectable } from '@angular/core';
import { CustomWindow } from '@app/models/custom-window.model';

declare const window: CustomWindow;

@Injectable()
export class PdfExportService {

  public exportingPDF: boolean;

  constructor() {
    this.exportingPDF = false;
    window.onafterprint = () => {
      this.onWindowAfterPrint();
    };
  }

  public startExport(fileName: string): void {
    if (this.exportingPDF) { return; }
    this.exportingPDF = true;

    const oldTitle = document.title;
    document.title = fileName;

    setTimeout(() => {
      window.print();
      document.title = oldTitle;
    }, 1);
  }

  @HostListener('window:afterprint')
  private onWindowAfterPrint(): void {
    this.exportingPDF = false;
  }
}
