import {Component, Input, OnInit} from '@angular/core';

// TODO: Translate
const DEFAULT_LOADING_MESSAGES = [
  'Loading your info...',
  'Getting everything ready...',
  'Loading... Won\'t be a minute',
  'Putting everything together...',
  'We\'ll  be right with you...'
];

@Component({
  selector: 'app-loading-state',
  templateUrl: './loading-state.component.html',
  styleUrls: ['./loading-state.component.scss']
})
export class LoadingStateComponent implements OnInit {
  @Input() showMessage: boolean;
  @Input() messages: string[];
  @Input() height: string;

  currentMessage: string;

  constructor() {
    this.showMessage = true;
    this.messages = DEFAULT_LOADING_MESSAGES;
    this.height = '100vh';
    this.currentMessage = '';
  }

  ngOnInit(): void {
    this.currentMessage = this.messages[0];

    this.startCyclingMessages();
  }

  startCyclingMessages(): void {
    setTimeout(() => {
      const index = Math.floor((Math.random() * this.messages.length));
      this.currentMessage = this.messages[index];
    }, 250);
  }

}
