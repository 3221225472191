<ng-container *ngIf="(requests.length > 0); else templateNoTeamRequests;">
  <div class="peer-review-list" [class.limit-height]="limitHeight">
    <ng-container *ngFor="let review of requests; index as index;">
      <div class="peer-review-item">
        <app-peer-reviews-item-team [request]="review" (onDecline)="onRequestDeclined($event)" (onApprove)="onRequestApproved($event)"></app-peer-reviews-item-team>
      </div>
    </ng-container>
  </div>
</ng-container>
<ng-template #templateNoTeamRequests>
  <app-empty-state [height]="'300px'" [imageSrc]="'assets/img/evaluations/No_Peer_360.svg'" [message]="'No requests received'"></app-empty-state>
</ng-template>
