import {Component, OnDestroy, OnInit} from '@angular/core';
import {UserAPIService} from '@app/shared/api/user.api.service';
import {ActivatedRoute} from '@angular/router';
import {Globals} from '@app/shared/globals/globals';
import {BreadcrumbService} from 'app/shared/breadcrumbs/breadcrumbs.service';
import {Breadcrumb} from 'app/models/breadcrumb.model';
import { CompanyFeatures } from '@app/models/company-features.model';
import {RoleName} from '@app/models/user-role.model';
import {FormControl, Validators} from '@angular/forms';
import {UserMinimal} from '@app/models/user/user-minimal.model';
import {debounceTime} from 'rxjs/operators';
import {State} from '@app/shared/utils/state.model';
import {PersonDetails} from '@app/shared/components/person-display/person-details';
import {PeopleDirectoryEntityCount} from '@app/models/user/people-directory-entity-count.model';
import {Page} from '@app/models/api/page.model';
import {PagingParams} from '@app/models/api/paging-params.model';
import {SortingParams} from '@app/models/api/sorting-params.model';
import {FilterOperator} from '@app/models/api/filter-operator.enum';
import {ChildFilter} from '@app/models/api/child-filter.model';
import {ParentFilter} from '@app/models/api/parent-filter.model';
import {SortDirection} from '@app/models/api/sort-direction.enum';
import {CriterionOperator} from '@app/models/api/criterion-operator.enum';
import {PeopleDirectoryCountOverview} from '@app/models/user/people-directory-count-overview';
import {CustomWindow} from '@app/models/custom-window.model';
import {CommonMessages} from '@app/constants/common.messages';
import {BulkUploadAPIService} from 'app/shared/api/bulk-upload.api.service';
import moment from 'moment';
import { TerminologyEntity } from '@app/domain/terminology/model/terminology-entity.enum';
import { ProfileMessages } from '@app/domain/user/locale/profile.messages';

// #region - INTERFACES, TYPES, ENUMS
interface PageUser extends UserMinimal, PersonDetails {
  roleName: string;
  cardColor: string;
  fullName: string;
  organisationalUnitName: string;
  officeLocationName: string;
  positionName: string;
}

interface Teams {
  department?: PeopleDirectoryEntityCount;
  site?: PeopleDirectoryEntityCount;
  manager?: PeopleDirectoryEntityCount;
  managerMe?: PeopleDirectoryEntityCount;
}

interface Colours {
  ADMIN: string,
  MANAGER: string,
  USER: string
}

interface PageFilters {
  search: FormControl,
  sitesSearch: FormControl,
  departmentsSearch: FormControl,
  managersSearch: FormControl,
  sites: {
    [siteId: number]: boolean;
  },
  departments: {
    [deptId: number]: boolean;
  },
  managers: {
    [deptId: number]: boolean;
  },
  admins: boolean,
  frankliAdmins: boolean,
  all: boolean
}
// #endregion

declare let window: CustomWindow;

@Component({
  selector: 'app-people-directory',
  templateUrl: './people-directory.component.html',
  styleUrls: ['./people-directory.component.scss'],
})
export class PeopleDirectoryComponent implements OnInit, OnDestroy {

  public readonly eTerminologyEntity = TerminologyEntity;
  public readonly eProfileMessages = ProfileMessages;
  public readonly eCommonMessages = CommonMessages;
  public readonly eFeatures = CompanyFeatures;

  componentState = new State(false);
  searchState = new State(false);

  pagedUsers: Page<UserMinimal>;
  pageUsers: PageUser[];
  overviewCounts: PeopleDirectoryCountOverview;

  myTeams: Teams = {
    department: undefined,
    site: undefined,
    manager: undefined,
    managerMe: undefined
  };

  pageParams: PagingParams = {
    pageSize: 30,
    pageNumber: 0
  };
  sortingParams: SortingParams = {
    sortAttributes: ['firstName', 'lastName'],
    sortDirection: SortDirection.ASC
  };
  peopleFilter: ChildFilter = {
    operator: FilterOperator.AND,
    filterCriteria: []
  };
  parentFilter: ParentFilter = {
    operator: FilterOperator.AND,
    childFilters: [this.peopleFilter]
  };

  categoryExpanded: {
    [title: string]: boolean;
  };

  canExport: boolean;
  sidebarOpen = true;
  breadcrumb: Breadcrumb;
  filters: PageFilters = {
    search: new FormControl('', [Validators.required]),
    sitesSearch: new FormControl('', [Validators.required]),
    departmentsSearch: new FormControl('', [Validators.required]),
    managersSearch: new FormControl('', [Validators.required]),
    sites: {},
    departments: {},
    managers: {},
    admins: false,
    frankliAdmins: false,
    all: true
  };

  colours: Colours = {
    ADMIN: '#FB946E', // Red for admin
    MANAGER: '#FFC200', // Yellow for managers
    USER: '#30747F' // Teal for users
  };

  constructor(
    public globals: Globals,
    public route: ActivatedRoute,
    private userAPIService: UserAPIService,
    private bulkUploadAPIService: BulkUploadAPIService,
    private breadcrumbService: BreadcrumbService
  ) {
    this.breadcrumb = this.breadcrumbService.init(this.route);
    this.canExport = this.globals.hasRole(RoleName.ADMIN) || this.globals.hasRole(RoleName.FRANKLI_ADMIN);

    this.categoryExpanded = {};
    this.categoryExpanded[this.globals.getTerminology(TerminologyEntity.DEPARTMENT_PLURAL)] = false;
    this.categoryExpanded['Sites'] = false;
    this.categoryExpanded['Managers'] = false;
  }

  // #region - LIFECYCLE HOOKS
  ngOnInit() {
    this.getData();
    this.filters.search.valueChanges.pipe(debounceTime(500)).subscribe(() => this.updateSearchAndFilters());
  }

  ngOnDestroy() {
    this.breadcrumbService.remove(this.breadcrumb);
  }
  // #endregion

  // #region - DATA GETS
  getData() {
    this.componentState.setLoading();
    this.userAPIService.getPeopleDirectoryCountOverview().subscribe(countOverview => {
      this.overviewCounts = countOverview;
      this.getMyTeams();
      this.updateSearchAndFilters();
    }, error => this.componentState.setFailure(error.error),
    () => this.componentState.setSuccess());
  }

  searchUsers() {
    this.searchState.setLoading();
    this.userAPIService.searchUsersPaginated(this.pageParams, this.sortingParams, this.parentFilter).subscribe(pagedUsers => {
      this.pagedUsers = pagedUsers;
      this.mapPagedUsersToPageUsers();
    }, error => this.searchState.setFailure(error.error),
    () => this.searchState.setSuccess());
  }

  changePageNumber(pageNumber: number) {
    this.pageParams.pageNumber = pageNumber;
    this.searchUsers();
  }

  changePageSize(pageSize: number) {
    this.pageParams.pageSize = pageSize;
    this.pageParams.pageNumber = 0;
    this.searchUsers();
  }
  // #endregion

  mapPagedUsersToPageUsers(): void {
    this.pageUsers = this.pagedUsers.content.map(pagedUser => {
      const pageUser = pagedUser as PageUser;
      pageUser.id = pagedUser.id;
      pageUser.fullName = `${pageUser.firstName} ${pageUser.lastName}`;
      pageUser.cardColor = this.getCardColour(pageUser);
      pageUser.roleName = this.getImgTitle(pageUser);
      pageUser.organisationalUnitName = pageUser.organisationalUnitId ? this.overviewCounts.departmentCounts.find(d => d.id === pageUser.organisationalUnitId).name : null;
      pageUser.officeLocationName = pageUser.officeLocationId ? this.overviewCounts.siteCounts.find(s => s.id === pageUser.officeLocationId).name : null;
      pageUser.positionName = pageUser.positionId ? this.overviewCounts.positionCounts.find(p => p.id === pageUser.positionId).name : null;
      return pageUser;
    });
  }

  getMyTeams() {
    // Manager
    if (this.globals.user.managerId && (this.globals.user.managerId !== this.globals.user.id)) {
      this.myTeams.manager = this.overviewCounts.managerCounts.find(m => (m.id === this.globals.user.managerId));
    }

    // Department
    if (this.globals.user.organisationalUnit && this.globals.user.organisationalUnit.id) {
      this.myTeams.department = this.overviewCounts.departmentCounts.find(d => d.id === this.globals.user.organisationalUnit!.id);
    }

    // Sites
    if (this.globals.user.officeLocation && this.globals.user.officeLocation.id) {
      this.myTeams.site = this.overviewCounts.siteCounts.find(s => (s.id === this.globals.user.officeLocation!.id));
    }

    const managerMe = this.overviewCounts.managerCounts.find(m => m.id === this.globals.user.id);
    if (managerMe) {
      this.myTeams.managerMe = managerMe;
    }
  }

  // #region - FILTERS
  updateSearchAndFilters() {
    // Construct filters for backend here
    this.peopleFilter.filterCriteria = [];
    this.pageParams.pageNumber = 0;
    this.pageParams.pageSize = 30;
    this.addFilters();
    this.searchUsers();
  }

  addFilters(): void {
    const selectedSites = this.getSelectedFilterPropIDs('sites');
    const selectedDepartments = this.getSelectedFilterPropIDs('departments');
    const selectedManagers = this.getSelectedFilterPropIDs('managers');
    const selectedAdmins = this.filters.admins;
    const selectedFrankliAdmins = this.filters.frankliAdmins;

    if (selectedSites.length > 0 ) {
      this.peopleFilter.filterCriteria.push({
        field: 'officeLocationId',
        operator: CriterionOperator.IN,
        values: selectedSites.map(siteId => siteId === -1 ? null : String(siteId))
      });
    }

    if (selectedDepartments.length > 0) {
      this.peopleFilter.filterCriteria.push({
        field: 'organisationalUnitId',
        operator: CriterionOperator.IN,
        values: selectedDepartments.map(departmentId => departmentId === -1 ? null : String(departmentId))
      });
    }

    if (selectedManagers.length > 0) {
      this.peopleFilter.filterCriteria.push({
        field: 'managerId',
        operator: CriterionOperator.IN,
        values: selectedManagers.map(managerId => String(managerId))
      });
    }

    if (selectedAdmins) {
      this.peopleFilter.filterCriteria.push({
        field: 'roles',
        operator: CriterionOperator.CONTAINS,
        value: 'ADMIN'
      });
    } else if (selectedFrankliAdmins) {
      this.peopleFilter.filterCriteria.push({
        field: 'roles',
        operator: CriterionOperator.CONTAINS,
        value: 'FRANKLI_ADMIN'
      });
    } else {
      this.peopleFilter.filterCriteria.push({
        field: 'roles',
        operator: CriterionOperator.NOT_CONTAINS,
        value: 'ARCHIVED',
      });
    }

    const searchArg = (this.filters.search.value as string).trim().toLowerCase();
    if (searchArg.length > 0) {
      this.peopleFilter.filterCriteria.push({
        field: 'name',
        operator: CriterionOperator.LIKE,
        value: searchArg
      });
    }
  }

  getSelectedFilterPropIDs(arrayProp: ('departments' | 'sites' | 'managers')) {
    const selectedIDs: number[] = [];
    for (const itemID in this.filters[arrayProp]) {
      if (Object.hasOwnProperty.call(this.filters[arrayProp], itemID)) {
        const checked = this.filters[arrayProp][itemID];
        if (checked) {
          selectedIDs.push(Number(itemID));
        }
      }
    }

    return selectedIDs;
  }

  filterSelected(event: MouseEvent, arrayProp: ('departments' | 'sites' | 'managers'), id: number) {
    // If control key is held, keep all other filters as is and toggle clicked one
    if (event.ctrlKey || event.metaKey) {
      this.toggleItemSelected(arrayProp, id);
      this.updateSearchAndFilters();
      return;
    }

    const filterSelected = this.checkFilterSelected(arrayProp, id);
    if (filterSelected) {
      this.clearFilters();
    } else {
      this.clearFilters();
      this.setFilterSelected(arrayProp, id, true);
    }
    this.updateSearchAndFilters();
  }

  toggleItemSelected(arrayProp: ('departments' | 'sites' | 'managers'), id: number) {
    if (Object.hasOwnProperty.call(this.filters[arrayProp], id)) {
      const currentValue = this.checkFilterSelected(arrayProp, id);
      this.setFilterSelected(arrayProp, id, !currentValue);
    } else {
      this.setFilterSelected(arrayProp, id, true);
    }
  }

  disableAllFiltersInCategory(arrayProp: ('departments' | 'sites' | 'managers')) {
    for (const id in this.filters[arrayProp]) {
      if (Object.hasOwnProperty.call(this.filters[arrayProp], id)) {
        this.setFilterSelected(arrayProp, +id, false);
      }
    }
  }

  checkFilterSelected(arrayProp: ('departments' | 'sites' | 'managers'), id: number): boolean {
    if (this.filters[arrayProp] && Object.hasOwnProperty.call(this.filters[arrayProp], id)) {
      return this.filters[arrayProp][id];
    }

    return false;
  }

  setFilterSelected(arrayProp: ('departments' | 'sites' | 'managers'), id: number, value: boolean) {
    if (value) {
      this.filters.all = false;
    }
    this.filters[arrayProp][id] = value;
  }

  toggleAdminFilterSelected() {
    this.filters.admins = !this.filters.admins;
    this.filters.frankliAdmins = false;
    this.filters.all = false;

    this.updateSearchAndFilters();
  }

  toggleFrankliAdminFilterSelected() {
    this.filters.frankliAdmins = !this.filters.frankliAdmins;
    this.filters.admins = false;
    this.filters.all = false;

    this.updateSearchAndFilters();
  }

  toggleAllFilterSelected() {
    this.clearFilters();
    this.updateSearchAndFilters();
  }

  clearCategoriesFilters() {
    const filterCategories: ('departments' | 'sites' | 'managers')[] = ['departments', 'sites', 'managers'];
    filterCategories.forEach(category => this.disableAllFiltersInCategory(category));
  }

  clearFilters() {
    this.filters.admins = false;
    this.filters.frankliAdmins = false;
    this.filters.all = true;
    this.clearCategoriesFilters();
  }

  // #endregion
  exportPeopleToXLSX() {
    this.bulkUploadAPIService.exportAllUsersToXLSX().subscribe((data: Blob) => {
      const blob = new Blob([data], {type: 'xlsx'});

      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob);
        return;
      }

      const downloadURL = window.URL.createObjectURL(data);
      const link = document.createElement('a');
      link.href = downloadURL;
      link.download = `frankli_active_people_${moment().format('DD-MM-yyyy')}.xlsx`;
      link.click();
    });
  }

  toggleSidebarCollapsed() {
    this.sidebarOpen = !this.sidebarOpen;
  }

  getCardColour(user: PageUser) {
    // METHOD UNO:
    // Cycles every X so colour index can be determined by [index % X] gives the index;
    // const colourIndex = index % this.colours.length;
    // const colour = this.colours[colourIndex];
    // return colour

    // METHOD DOS:
    // Colours are assigned based on role
    if (user.roles && user.roles.length > 0) {
      const rolesMapped = user.roles.map(r => r.name);
      if (rolesMapped.includes(RoleName.ADMIN) || rolesMapped.includes(RoleName.FRANKLI_ADMIN)) { // Admin
        return this.colours.ADMIN;
      }

      if (rolesMapped.includes(RoleName.MANAGER)) { // Manager
        return this.colours.MANAGER;
      }
    }

    return this.colours.USER;
  }

  getImgTitle(user: PageUser) {
    if (user.roles && user.roles.length > 0) {
      const rolesMapped = user.roles.map(r => r.name);
      if (rolesMapped.includes(RoleName.ADMIN) || rolesMapped.includes(RoleName.FRANKLI_ADMIN)) { // Admin
        return 'Admin';
      }

      if (rolesMapped.includes(RoleName.MANAGER)) { // Manager
        return 'Manager';
      }
    }

    return 'User';
  }

  toggleCategoryExpanded(title: string) {
    const currentValue = this.categoryExpanded[title] || false;
    const newValue = !currentValue;

    this.categoryExpanded[title] = newValue;
    return newValue;
  }
}
