import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Page } from '@app/models/api/page.model';
import { PagingParams } from '@app/models/api/paging-params.model';
import { SortingParams } from '@app/models/api/sorting-params.model';
import { GoalTagOverview } from '@app/models/goals/goal-tag-overview';
import { TagType } from '@app/domain/tag/model/tag-type.model';
import { CreateTagDto } from '@app/models/tags/create-tag.model';
import { Tag } from '@app/domain/tag/model/tag.model';
import { ApiUtils } from '@app/shared/utils/api.utils';
import { Observable } from 'rxjs';
import { TagService } from './tag-api.model';

@Injectable()
export class TagAPIService implements TagService {
  private readonly BASE_URL = 'api/tag';

  constructor(
    private http: HttpClient
  ) { }

  getTagOverviews(pagingParams: PagingParams, sortingParams: SortingParams, tagName?: string): Observable<Page<GoalTagOverview>> {
    const url = this.BASE_URL + '/overview';
    let params = ApiUtils.createPageAndSortParams(pagingParams, sortingParams);
    if (tagName) {
      params = params.append('tagName', tagName);
    }
    return this.http.get<Page<GoalTagOverview>>(url, { params });
  }

  create(createTagDto: CreateTagDto): Observable<Tag> {
    const url = `${this.BASE_URL}`;
    return this.http.post<Tag>(url, createTagDto);
  }

  update(id: number, createTagDto: CreateTagDto): Observable<Tag> {
    const url = `${this.BASE_URL}/${id}`;
    return this.http.put<Tag>(url, createTagDto);
  }

  delete(id: number): Observable<Tag> {
    const url = `${this.BASE_URL}/${id}`;
    return this.http.delete<Tag>(url);
  }

  deleteMultiple(tagIds: number[]): Observable<Tag[]> {
    const url = `${this.BASE_URL}/delete-multiple`;
    return this.http.post<Tag[]>(url, tagIds);
  }

  get(ids: number[], text: string, type: TagType[]): Observable<Tag[]> {
    const url = `${this.BASE_URL}`;
    let params = new HttpParams();

    if (text) {
      params = params.append('text', text);
    }

    if (type) {
      type.forEach(id => {
        params = params.append('type', id);
      });
    }

    return this.http.get<Tag[]>(url, { params: params });
  }

  search(sarg: string): Observable<Tag[]> {
    const url = `${this.BASE_URL}/search`;
    let params = new HttpParams();

    if (sarg) {
      params = params.append('sarg', sarg);
    }

    return this.http.get<Tag[]>(url, { params: params });
  }
}