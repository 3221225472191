export const CareerMessages = {
  CAREERS: 'career.careers',
  CAREERS_DESCRIPTION: 'career.careers-description',
  MANAGE_CAREERS: 'career.manage-careers',
  CAREERS_HEADING: 'career.careers-heading',
  MY_CAREER: 'career.my-career',
  MY_CAREER_DESCRIPTION: 'career.my-career-description',
  JOB_LISTINGS: 'career.job-listings',
  JOB_LISTINGS_DESCRIPTION: 'career.job-listings-description',
  CTA_CONFIGURE: 'career.cta-configure',
  MY_CURRENT_ROLE: 'career.my-current-role',
  EMPTY_STATE_MY_CURRENT_ROLE: 'career.empty-state-my-current-role',
  MY_QUALIFICATIONS: 'career.my-qualifications',
  EMPTY_STATE_MY_QUALIFICATIONS: 'career.empty-state-my-qualifications',
  MY_SKILLS: 'career.my-skills',
  EMPTY_STATE_MY_SKILLS: 'career.empty-state-my-skills',
  MY_DEVELOPMENTAL_GOALS: 'career.my-developmental-goals',
  EMPTY_STATE_MY_DEVELOPMENTAL_GOALS: 'career.empty-state-my-developmental-goals',
  CAREER_ADMINS: 'career.career-admins',
  CAREER_ADMINS_DESCRIPTION: 'career.career-admins-description',
  CURRENT_CAREER_ADMINS: 'career.current-career-admins',
  MANAGE_CAREER_ADMINS_SUCCESS: 'career.manage-career-admins-success',
  MILESTONES: 'Milestones'
};
