import {MockService} from '@app/mock/api/mock-service';
import {UserService} from '@app/shared/api/interfaces/user.service';
import {Observable, of} from 'rxjs';
import {User, UserServerSide, UserState} from '@app/models/user/user.model';
import {mockUsers} from '@app/mock/api/data/mockUsers';
import {mockCompanies} from '@app/mock/api/data/mockCompanies';
import {AdministrateUserDto} from '@app/models/user/administrate-user.dto';
import {UserRole} from '@app/models/user-role.model';
import { Interest } from '@app/domain/interest/model/interest.model';
import {ProfileDetailsDto} from '@app/domain/settings/model/profile-details.model';
import {CreateUserDto} from '@app/models/user/create-user.dto';
import {UserMinimal} from '@app/models/user/user-minimal.model';
import {Site} from '@app/models/site.model';
import {LocationDetailsDto} from '@app/domain/settings/model/location-details.model';
import {ChangePasswordDto} from '@app/models/user/change-password.dto';
import {mockUserRoles} from '@app/mock/api/data/mockUserRoles';
import {Globals} from '@app/shared/globals/globals';
import {Injectable} from '@angular/core';
import {HttpRequest} from '@angular/common/http';
import {sanitizeUrl} from '@app/shared/utils/helpers';
import {AdministrateActiveDirectoryUserDto} from '@app/models/user/administrate-active-directory-user.dto';
import {Page} from '@app/models/api/page.model';
import {PagingParams} from '@app/models/api/paging-params.model';
import {ParentFilter} from '@app/models/api/parent-filter.model';
import {SortingParams} from '@app/models/api/sorting-params.model';
import {PeopleDirectoryCountOverview} from '@app/models/user/people-directory-count-overview';
import {UserSimilar} from '@app/models/user/user-similar.model';
import {UserInit} from '@app/models/auth/user-init.model';
import {ResetPasswordResponse} from '@app/models/auth/reset-password-response.model';
import {ProvisionedUser} from '@app/models/user/provisioned-user.model';
import { PasswordReset } from '@app/models/user/password-reset.model';
import { ContactInformationDto } from '@app/domain/settings/model/contact-information.model';
import { mockInterests } from '@app/domain/interest/mock/interests.mock';
import { EmployeeHierarchy } from '@app/models/user/employee-hierarchy.model';
import { mockTerminology } from '../../data/mockTerminology';

@Injectable()
export class UserMockService implements MockService, UserService {

  constructor(public globals: Globals) { }

  handleRoute(req: HttpRequest<any>): any {
    const url = sanitizeUrl(req.urlWithParams);

    switch (true) {
      case url.endsWith('api/user/me'):
        return this.getMe();
      case url.endsWith('api/user/all'):
        return this.getAllUsers();
      case url.endsWith('api/user/all/minimal'):
        return this.getAllUsersMinimal();
      case url.endsWith('api/user/all/manager'):
        return this.getOtherUsersAndManagers();
      case url.endsWith('api/user/all/admin'):
        return this.getAllUsersAdmin();
      case url.endsWith('api/user/other'):
        return this.getAllOtherUsers();
      case url.endsWith('api/user/init'):
        return this.getUserInit();
      case url.endsWith('api/user/similar'):
        return this.getUsersSimilar(null);
      case url.endsWith('api/user/onboarded'):
        return this.getAllUsersOnboarded();
      case url.endsWith('api/user/onboarded/count'):
        return this.countAllUsersOnboarded();
      case url.endsWith('api/user/evaluation'):
        return this.getUsersOnboardedNotInCycles();
      case url.endsWith('api/user/roles'):
        return this.getAllRoles();
      case url.endsWith('api/user/manager/me'):
        return this.getUsersByManagerMe();
      case url.endsWith('api/user/search/paginated/not-in-evaluation'):
        return this.searchUsersPaginatedNotInActiveEvaluationCycle(null, null, null);
      case url.endsWith('api/user/search/paginated'):
        return this.searchUsersPaginated(null, null, null);
      case url.endsWith('api/user/socialise'):
        return this.getUsersSocialise();
      case url.match(/api\/user\/manager\/\d+$/) !== null:
        const managerId = +url.split('/')[3];
        return this.getUsersByManagerId(managerId);
      case url.match(/api\/user\/\d+$/) !== null:
        const userId = +url.split('/')[2];
        return this.getById(userId);
      case url.match(/api\/user\/interests\/\d+$/) !== null:
        const interestId = +url.split('/')[3];
        return this.getUsersByInterestId(interestId);
    }
  }

  getPeopleDirectoryCountOverview(): Observable<PeopleDirectoryCountOverview> {
    return of(null); // TODO: fix before releasing new demo environment
  }

  searchUsersPaginated(pagingParams: PagingParams, sortingParams: SortingParams, parentFilter: ParentFilter): Observable<Page<UserMinimal>> {
    // TODO: fix before releasing new demo environment
    const results = [];
    return of({
      first: true,
      last: false,
      empty: false,
      number: 1,
      numberOfElements: results.length,
      totalElements: results.length,
      size: results.length,
      content: results,
      pageable: null,
      totalPages: 1
    });
  }

  searchUsersPaginatedNotInActiveEvaluationCycle(pagingParams: PagingParams, sortingParams: SortingParams, parentFilter: ParentFilter): Observable<Page<UserMinimal>> {
    return of(null); // TODO: fix before releasing new demo environment
  }

  getAllUsers(): Observable<Array<User>> {
    return of(mockUsers);
  }

  getById(id: number): Observable<User> {
    return of(mockUsers.find(u => u.id === id));
  }

  getUserInit(): Observable<UserInit> {
    return of({
      user: mockUsers[0],
      company: mockCompanies[0],
      accessToken: 'mock_token', 
      secondaryManagerAny: {feedback: true, goals: true, talkingPointTemplates: true, reviews: true},
      terminologyCollection: mockTerminology
    });
  }

  getUsersSimilar(pagingParams: PagingParams): Observable<Page<UserSimilar>> {
    // TODO: Fix for next demo release
    return of(null);
    // return mockUsers.filter(u => u.id !== this.globals.user.id).map(u => ({
    //     ...u,
    //     interests: [...u.personalInterests, ...u.professionalInterests]
    // }));
  }

  countAllUsersOnboarded(): Observable<number> {
    return of(mockUsers.length);
  }

  getAllOtherUsers(): Observable<Array<User>> {
    return of(mockUsers.filter(u => u.id !== this.globals.user.id));
  }

  getAllRoles(): Observable<Array<UserRole>> {
    return of(mockUserRoles);
  }

  getAllUsersAdmin(): Observable<Array<User>> {
    return of(mockUsers);
  }

  getAllUsersMinimal(): Observable<Array<UserMinimal>> {
    return of(mockUsers.map(u => UserMockService.toUserMinimal(u)));
  }

  getAllUsersOnboarded(): Observable<Array<User>> {
    return of(mockUsers.filter(u => u.userState === UserState.FULL));
  }

  getMe(): Observable<User> {
    return of(mockUsers.find(u => u.id === this.globals.user.id));
  }

  getOtherUsersAndManagers(): Observable<Array<User>> {
    return of(mockUsers.filter(u => u.id !== this.globals.user.id));
  }

  getUsersByInterestId(id: number): Observable<Array<User>> {
    return of(mockUsers.filter(u => u.personalInterests.map(i => i.id).includes(id)));
  }

  getUsersByManagerId(managerId: number): Observable<Array<User>> {
    return of(mockUsers.filter(u => u.managerId === managerId));
  }

  getUsersByManagerMe(): Observable<Array<User>> {
    return of(mockUsers.filter(u => u.managerId === this.globals.user.id));
  }

  getUsersBySecondaryManagerMe(): Observable<User[]> {
    return of([]);
  }

  getUsersOnboardedNotInCycles(): Observable<Array<User>> {
    return of(mockUsers);
  }

  getUsersSocialise(): Observable<Array<User>> {
    return of(mockUsers.filter(u => u.interestsSocialise.map(i => i.id).some(id => this.globals.user.interestsSocialise.map(i => i.id).includes(id))));
  }

  // No-Ops listed below
  getUserHash(): any {
    return of(undefined);
  }

  administrateUser(userId: number, userAdminstrateDto: AdministrateUserDto): any {
    return of(undefined);
  }

  administrateActiveDirectoryUser(userId: number, administrateActiveDirectoryDto: AdministrateActiveDirectoryUserDto): Observable<User> {
    return of(undefined);
  }

  archiveUser(userId: number): Observable<User> {
    return of(undefined);
  }

  create(user: CreateUserDto): Observable<UserServerSide> {
    return of(undefined);
  }

  createAllBulkUploadUsers(users: Array<CreateUserDto>): Observable<Array<UserServerSide>> {
    return of(undefined);
  }

  createBulkUploadUser(user: CreateUserDto): Observable<UserServerSide> {
    return of(undefined);
  }

  frankliAdminExportAllAdmins(): any {
    return of(undefined);
  }

  frankliAdminExportAllManagers(): any {
    return of(undefined);
  }

  inviteAllPendingUsers(): Observable<Array<User>> {
    return of(undefined);
  }

  inviteUser(userId: number): Observable<User> {
    return of(undefined);
  }

  resetUserPassword(email: string): Observable<ResetPasswordResponse> {
    return of(undefined);
  }

  revokeAccess(userId: number): Observable<User> {
    return of(undefined);
  }

  unarchiveUser(userId: number, role: UserRole): Observable<User> {
    return of(undefined);
  }

  unrevokeAccessUser(userId: number, role: UserRole): Observable<User> {
    return of(undefined);
  }

  updateLocationDetails(user: LocationDetailsDto): Observable<User> {
    return of(undefined);
  }

  updatePassword(passwordReset: PasswordReset): Observable<any>  {
    return of(undefined);
  }

  updatePasswordManual(changePasswordDto: ChangePasswordDto): Observable<User> {
    return of(undefined);
  }

  updatePersonalDetails(user: ContactInformationDto): Observable<User> {
    return of(undefined);
  }

  updateInterests(interests: Array<Interest>): Observable<User> {
    return of(this.globals.user);
  }

  updateProfileDetails(user: ProfileDetailsDto): Observable<User> {
    return of(undefined);
  }

  updateProfilePicture(file: any, fileExtension: string): Observable<User> {
    return of(undefined);
  }

  updateUserInterestsCoach(interests: Array<Interest>): any {
    return of(undefined);
  }

  updateUserInterestsMentor(interests: Array<Interest>): any {
    return of(undefined);
  }

  getUserInterestsSocialise(): Observable<Interest[]> {
    return of(mockInterests);
  }

  getUsersWithCommonSocialiseInterests(): Observable<User[]> {
    return of(mockUsers);
  }

  updateUserInterestsSocialise(interestIds: number[]): Observable<User> {
    return of(undefined);
  }

  updateUserCity(city: string): Observable<User> {
    return of(undefined);
  }

  updateUserOfficeLocation(officeLocation: Site | null): Observable<User> {
    return of(undefined);
  }

  userRefresh(): any {
    return of(undefined);
  }

  getEveryMinimalUserInCompany(): Observable<Array<UserMinimal>> {
    return of(mockUsers.map(u => UserMockService.toUserMinimal(u)));
  }

  getDirectReportsForUser(userId: number): Observable<UserMinimal[]> {
    return of(undefined);
  }

  setTimezone(user?: User): Observable<User> {
    return of(mockUsers[0]);
  }

  doFirstTimeLogin(): Observable<boolean> {
    return of(false);
  }

  getAllProvisionedUsers(): Observable<ProvisionedUser[]> {
    throw new Error('Method not implemented.');
  }

  getProvisionedUserByUserId(userId: number): Observable<ProvisionedUser> {
    throw new Error('Method not implemented.');
  }

  private static toUserMinimal(user: UserServerSide): UserMinimal {
    return {
      ...user,
      organisationalUnitId: user.organisationalUnit.id,
      officeLocationId: user.officeLocation.id,
      positionId: user.position.id
    };
  }

  isSlackAccountLinked(): Observable<boolean> {
    return of(false);
  }

  isTeamsAccountLinked(): Observable<boolean> {
    return of(false);
  }

  getFull(ids: number[], firstName: string, lastName: string, name: string): Observable<User[]> {
    return of(mockUsers);
  }

  getMinimal(ids: number[], firstName: string, lastName: string, name: string): Observable<User[]> {
    return of(mockUsers);
  }

  getUsersWithDirectReports(): Observable<UserMinimal[]> {
    return of(mockUsers);
  }

  exportUsersToCSVById(userIds: number[]): Observable<Blob> {
    return of(undefined);
  }

  findAllDescendantsForUserId(userId: number): Observable<EmployeeHierarchy[]> {
    return of([]);
  }

  getManagerForUser(userId: number): Observable<UserMinimal> {
    return of(undefined);
  }

}
