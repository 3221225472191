import { Component, Input } from '@angular/core';
import { LongRunningTaskEvent } from '../../model/long-running-task-event.model';
import { LongRunningTaskEventType } from '../../model/long-running-task-event-type.model';
import { LongRunningTaskAPIService } from '../../api/long-running-task-api.service';
import { LongRunningTaskService } from '../../service/long-running-task.service';
import { LongRunningTask } from '../../model/long-running-task.model';

@Component({
  selector: 'app-long-running-task-event-display',
  templateUrl: './long-running-task-event-display.component.html',
  styleUrls: ['./long-running-task-event-display.component.scss']
})
export class LongRunningTaskEventDisplayComponent {

  public readonly eLongRunningTaskEventType = LongRunningTaskEventType;

  @Input() parentTask: LongRunningTask;
  @Input() event: LongRunningTaskEvent;

  public get canRetry(): boolean {
    if (this.event.eventType !== LongRunningTaskEventType.EVENT_FAILED) { return; }
    if (!this.event.metadata) { return false; }
    return true;
  }

  constructor(
    private longRunningTaskService: LongRunningTaskService,
    private longRunningTaskAPIService: LongRunningTaskAPIService
  ) {
    this.event = undefined;
  }

  onClick(): void {
    switch(this.event.eventType) {
      case LongRunningTaskEventType.EVENT_FAILED:
        this.longRunningTaskAPIService.retry(this.event.id)
          .toPromise()
          .then(() => {
            setTimeout(() => {
              this.longRunningTaskService.syncDataForTask(this.event.parentTaskId);
            }, 200);
          });
        break;
      case LongRunningTaskEventType.EVENT_INFO:
      case LongRunningTaskEventType.EVENT_SUCCESS:
      case LongRunningTaskEventType.TASK_STARTED:
      case LongRunningTaskEventType.TASK_COMPLETED:
      case LongRunningTaskEventType.TASK_FAILED:
      default:
        break;
    }
  }
}
