import { CommonMessages } from '@app/constants/common.messages';

export const SentimentScaleMessages = {
  SENTIMENT_SCALE: 'sentiment-scale.sentiment-scale',
  SENTIMENT_SCALES: 'sentiment-scale.sentiment-scales',
  SENTIMENT_SCALES_DESCRIPTION: 'sentiment-scale.sentiment-scales-description',

  SENTIMENT_SCALES_REPORTING: 'sentiment-scale.sentiment-scales-reporting',
  SENTIMENT_SCALES_REPORTING_DESCRIPTION: 'sentiment-scale.sentiment-scales-reporting-description',

  SENTIMENT_SCALES_EMPTY_STATE: 'sentiment-scale.sentiment-scales-empty-state',
  SENTIMENT_SCALE_OPTIONS_EMPTY_STATE: 'sentiment-scale.sentiment-scale-options-empty-state',
  
  MEETING_SENTIMENT_SCALE: 'sentiment-scale.meeting-sentiment-scale',
  MEETING_SENTIMENT_SCALE_DESCRIPTION: 'sentiment-scale.meeting-sentiment-scale-description',
  MEETING_SENTIMENT_EMPTY_STATE: 'sentiment-scale.meeting-sentiment-empty-state',

  CREATE_SENTIMENT_SCALE: 'sentiment-scale.create-sentiment-scale',
  CREATE_SENTIMENT_SCALE_DESCRIPTION: 'sentiment-scale.create-sentiment-scale-description',
  EDIT_SENTIMENT_SCALE: 'sentiment-scale.edit-sentiment-scale',
  EDIT_SENTIMENT_SCALE_DESCRIPTION: 'sentiment-scale.edit-sentiment-scale-description',
  ADD_OPTION: CommonMessages.ADD_OPTION,

  CREATE_SUCCESS_TOAST: 'sentiment-scale.create-success-toast',
  EDIT_SUCCESS_TOAST: 'sentiment-scale.edit-success-toast',
  ARCHIVE_SUCCESS_TOAST: 'sentiment-scale.archive-success-toast',
  UNARCHIVE_SUCCESS_TOAST: 'sentiment-scale.unarchive-success-toast',

  // Properties
  NAME: CommonMessages.NAME,
  DESCRIPTION: CommonMessages.DESCRIPTION,
  OPTION_SCORE: 'sentiment-scale.option-score',
  OPTION_ORDER_INDEX: 'sentiment-scale.option-order-index',
  OPTION_COLOUR: 'sentiment-scale.option-colour',
  SOURCE_USER: 'sentiment-scale.source-user',
  TARGET_USER: 'sentiment-scale.target-user',

  SHOW_NUMBERS: 'sentiment-scale.show-numbers',
  SHOW_NUMBERS_PROMPT: 'sentiment-scale.show-numbers-prompt',

  SENTIMENT_SCALE_TEMPLATES: 'sentiment-scale.sentiment-scale-templates',
  USE_TEMPLATE_PROMPT: 'sentiment-scale.use-template-prompt',
  USE_TEMPLATE_DESCRIPTION: 'sentiment-scale.use-template-description',
  SHOW_TEMPLATES: 'sentiment-scale.show-templates',
  SENTIMENT_SCALE_TEMPLATES_DESCRIPTION: 'sentiment-scale.sentiment-scale-templates-description',
};
