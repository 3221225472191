<div class="progress-bar-container" [class.has-hover-state]="showTextOnHover" [class.has-background]="showBackground" [ngClass]="'progress-bar-' + style">
  <div class="progress-bar-regular">
    <div class="progress-bar-outer">
      <div class="progress-bar-fill" [style.width.%]="widthPercentage" [class.progress-bar-full]="widthPercentage >= 95">
        <div class="previous-progress-difference" *ngIf="previousValue !== undefined" [class.progress-increase]="valueHasIncreased" [style.width.%]="widthPercentageDifference" [class.round-left]="widthPercentageDifference >= 95" [class.round-right]="widthPercentage >= 95" [tooltip]="(valueHasIncreased ? '+' : '-') + valueDifferencePercent + '%'" placement="right" [triggers]="globals.tooltipTriggers"></div>
      </div>
    </div>
    <div class="progress-percent" [class.percent-consistent-width]="consistentWidthValue" *ngIf="showUnit">
      <span class="progress-percent-value">{{(replacePercentWithValue ? currentValue : widthPercentage) | number: unitFormat}}</span>
      <span class="progress-percent-unit">&nbsp;{{unitBar}}</span>
        <div class="progress-percent-overflow" *ngIf="showOverflowText && overflowPercentage" [class.negative-overflow]="overflowPercentage < 0" [tooltip]="overflowTooltip">
          <span class="overflow-positive">+</span>
          <span>{{overflowPercentage | number: '1.0-2'}}%</span>
        </div>
    </div>
  </div>
  <div class="progress-bar-hover">
    <span *ngIf="showDetailUnitBeforeValue">{{unitDetail}}&nbsp;</span>
      <span class="display-current">{{currentValue | number: unitFormat}}</span>
    <span>/</span>
      <span class="display-target">{{targetValue | number: unitFormat}}</span>
    <span *ngIf="!showDetailUnitBeforeValue">&nbsp;{{unitDetail}}</span>
  </div>
</div>