/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/ban-types */

import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import moment from 'moment';

@Component({
  selector: 'app-year-picker',
  templateUrl: './year-picker.component.html',
  styleUrls: ['./year-picker.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => YearPickerComponent),
    multi: true,
  }],
})
export class YearPickerComponent implements OnInit, ControlValueAccessor {

  @Input() yearsAvailable: number;
  @Input() disabled: boolean;

  years: number[];
  page: number;
  pageSize: number;
  minPage: number;
  maxPage: number;
  popupOpen: boolean;

  _value!: Date | null;

  get value() {
    return this._value;
  }

  set value(val: Date | null) {
    if (this.disabled) { return; }

    this._value = val;
    this.onChange(val);
  }

  get valueYear(): number {
    if (!this.value) { return null; }
    return this.value.getFullYear();
  }

  constructor() {
    this.years = [];
    this.page = 1;
    this.pageSize = 25;
    this.yearsAvailable = 100;
    this.popupOpen = false;
  }

  onChange = (_: any) => { };
  onTouched = () => { };

  writeValue(obj: any): void {
    this.value = new Date(obj);
  }

  registerOnChange(fn: (_: any) => {}): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  ngOnInit() { 
    this.years = this.getYearOptions(this.yearsAvailable);
    this.minPage = 1;
    this.maxPage = this.getMaxPage();
    this.page = this.getMaxPage();
  }

  getYearOptions(yearsAvailable: number): number[] {
    const today = new Date();
    const currentYear = today.getFullYear();
    const rangeStart = currentYear - yearsAvailable;
    const years = [];
    for (let year = (rangeStart + 1); year <= currentYear; year++) {
      years.push(year);
    }

    return years;
  }

  getMinPage(): number {
    const minYear = Math.min(...this.years);
    const index = this.getIndexOfYear(minYear);
    return this.getPageOfIndex(index);
  }

  getMaxPage(): number {
    const maxYear = Math.max(...this.years);
    const index = this.getIndexOfYear(maxYear);
    const page = this.getPageOfIndex(index);
    return page;
  }

  getIndexOfYear(year: number): number {
    return this.years.findIndex(y => y === year);
  }

  getPageOfIndex(index: number): number {
    return Math.ceil((index + 1) / this.pageSize);
  }

  onSelectYear(year: number): void {
    const yearMoment = moment().year(year).startOf('year');
    this.value = yearMoment.toDate();
  }

  indexIsOnPage(index: number, page: number): boolean {
    const itemPage = this.getPageOfIndex(index);
    return (itemPage === page);
  }

  onClickInput(): void {
    if (this.popupOpen) { return; }
    this.popupOpen = true;
  }

  onClickPreviousPage(): void {
    if (this.page === this.minPage) { return; }
    this.page -= 1;
  }

  onClickNextPage(): void {
    if (this.page === this.maxPage) { return; }
    this.page += 1;
  }
}
