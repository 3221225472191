<div class="container-schedule-cycle-question-banks">
  <div class="content">
    <h1 class="standard-heading">Define your questions</h1>
    <div class="standard-description">Set which questions will be asked for each step included</div>
  </div>

  <app-state [state]="state">
    <ng-container *ngIf="!state.loading && !state.error">
      <app-nav-tabs [selected]="tabOpen" [data]="tabData" [alternateTabCounter]="true" (tabChanged)="openTab($event)"></app-nav-tabs>

      <!-- Question bank and template bank -->
      <div class="content">
        <div class="questions-form" [class.question-bank-expanded]="questionBank.open" cdkDropListGroup>
          <!-- Cycle questions -->
          <div class="area-question-list">
            <div class="area-question-list-inner">
              <div class="question-details-heading">
                <h2 class="standard-heading">{{questionBank.open ? 'Questions in this cycle' : '&nbsp;'}}</h2>
                <ng-container *ngIf="resetButtonShown">
                  <app-button (onClick)="resetQuestions()" [small]="true" [type]="eButtonType.GREEN_INVERTED" [tooltipText]="draftCycle ? 'Revert to saved questions' : 'Revert to default cycle questions'">
                    <span class="fal fa-fw fa-history"></span>
                    <span class="m-right-5"></span>
                    <span>Reset questions</span>
                  </app-button>
                </ng-container>
              </div>
  
              <!-- Question List -->
              <ng-container *ngIf="!state.questionsLoading;">
                <div class="question-list" cdkDropList #cycleList="cdkDropList" [cdkDropListConnectedTo]="[bankList]" [cdkDropListData]="pageQuestions[tabOpen]" (cdkDropListDropped)="dropDraggableCycle($event)" [cdkDropListEnterPredicate]="enableDropping">
                  <ng-container *ngIf="(pageQuestions[tabOpen] && pageQuestions[tabOpen].length > 0); else noQuestions;">
                    <ng-container *ngFor="let question of pageQuestions[tabOpen]; index as questionIndex; first as isFirst; last as isLast; trackBy: trackQuestionList">
                      <ng-container [ngTemplateOutlet]="addIcon" [ngTemplateOutletContext]="{ index: questionIndex }" *ngIf="isFirst"></ng-container>
  
                      <div class="question-item" cdkDrag [cdkDragData]="question">
                        <!-- Question Index -->
                        <div class="area-question-index">
                          <span>Q{{questionIndex + 1}}</span>
                        </div>
                  
                        <!-- Question details -->
                        <div class="area-question-card">
                          <app-schedule-cycle-question-item [question]="question" (onClickAction)="onClickQuestionAction($event, question)"></app-schedule-cycle-question-item>
                        </div>
                      </div>
  
                      <ng-container [ngTemplateOutlet]="addIcon" [ngTemplateOutletContext]="{ index: (questionIndex + 1), isLast: isLast }"></ng-container>
                    </ng-container>
                  </ng-container>
                  <ng-template #noQuestions>
                    <app-empty-state [height]="'300px'" [message]="'No questions added for this step. Drag one in from the question bank or add a new one.'" [primaryButtonShown]="true" [primaryButtonText]="'Add a new question'" (primaryButtonPressed)="startAddQuestion(eEditingFrom.CYCLE_LIST)"></app-empty-state>
                  </ng-template>
                </div>
              </ng-container>
            </div>
          </div>
  
          <!-- Question bank -->
          <div class="area-question-bank">
            <div class="p-bottom-30">
              <h2 class="standard-heading">{{questionBank.open ? 'Question bank' : '&nbsp;'}}</h2>
            </div>
  
            <div class="card question-bank-card">
              <div class="area-question-bank-tabs">
                <!-- Collapse -->
                <div class="area-question-bank-collapse" (click)="toggleQuestionBank()">
                  <span class="question-bank-toggle" [tooltip]="questionBank.open ? 'Collapse question bank' : 'Expand question bank'" [triggers]="globals.tooltipTriggers" placement="top" container="body">
                    <span class="fal fa-fw fa-chevron-left frankli-view expand-on-hover sidebar-toggle" [class.sidebar-expanded]="questionBank.open"></span>
                  </span>
                </div>
    
                <!-- Tabs -->
                <div class="area-question-bank-nav">
                  <app-nav-tabs [selected]="tabOpenBank" [data]="tabDataBank" (tabChanged)="openBankTab($event)"></app-nav-tabs>
                </div>
              </div>
  
              <div class="question-bank-list" cdkDropList cdkDropListSortingDisabled #bankList="cdkDropList" [cdkDropListConnectedTo]="[cycleList]" [cdkDropListData]="questionBank.questions[tabOpenBank][tabOpen]" (cdkDropListDropped)="dropDraggableBank($event)" [cdkDropListEnterPredicate]="disableDropping">
                <ng-container *ngIf="(questionBank.questions[tabOpenBank][tabOpen].length > 0); else noBankQuestions;">
                  <ng-container *ngFor="let question of questionBank.questions[tabOpenBank][tabOpen]; index as i; trackBy: trackQuestionList">
                    <div class="wrapper-question-bank-item" cdkDrag [cdkDragData]="question">
                      <app-schedule-cycle-question-bank-item [question]="question" [bankTabOpen]="tabOpenBank" (onClickAction)="onClickQuestionActionBank($event, question)"></app-schedule-cycle-question-bank-item>
                    </div>
                  </ng-container>
                </ng-container>
              </div>
    
              <ng-template #noBankQuestions>
                <app-empty-state [height]="'300px'" [message]="'No questions in this bank'"></app-empty-state>
              </ng-template>
            </div>
          </div>
        </div>
      
        <!-- No questions for steps warning -->
        <div class="frankli-form-group-invalid" #validationErrors>
          <div class="frankli-form-group-alert">
            <ng-container *ngIf="submitted">
              <div *ngIf="checkStepEnabled(eEvaluationModuleType.SELF_REFLECTION) && (formQuestions.controls.reflectionQuestions.value.length < 1)">You must have at least 1 self reflection question</div>
              <div *ngIf="checkStepEnabled(eEvaluationModuleType.PEER_REVIEW) && (formQuestions.controls.peerQuestions.value.length < 1)">You must have at least 1 peer review question</div>
              <div *ngIf="checkStepEnabled(eEvaluationModuleType.MANAGER_REVIEW) && (formQuestions.controls.managerQuestions.value.length < 1)">You must have at least 1 manager review question</div>
              <div *ngIf="checkStepEnabled(eEvaluationModuleType.UPWARD_REVIEW) && (formQuestions.controls.upwardQuestions.value.length < 1)">You must have at least 1 upward review question</div>
            </ng-container>
          </div>
        </div>
      </div>
    
      <!-- Navigation forward and back -->
      <div class="area-navigation-buttons">
        <app-button [type]="eButtonType.GRAY_INVERTED" (onClick)="previousStep()">
          <span>Previous</span>
        </app-button>
        <app-button [type]="eButtonType.GREEN_INVERTED" (onClick)="saveDraft()">
          <span>Save Draft</span>
        </app-button>
        <app-button [type]="eButtonType.GREEN" (onClick)="nextStep()">
          <span>Next</span>
        </app-button>
      </div>
    
      <!-- Undo alerts -->
      <ng-container>
        <ng-container [ngTemplateOutlet]="undoAlert" [ngTemplateOutletContext]="{ step: eEvaluationModuleType.SELF_REFLECTION }"></ng-container>
        <ng-container [ngTemplateOutlet]="undoAlert" [ngTemplateOutletContext]="{ step: eEvaluationModuleType.PEER_REVIEW }"></ng-container>
        <ng-container [ngTemplateOutlet]="undoAlert" [ngTemplateOutletContext]="{ step: eEvaluationModuleType.MANAGER_REVIEW }"></ng-container>
        <ng-container [ngTemplateOutlet]="undoAlert" [ngTemplateOutletContext]="{ step: eEvaluationModuleType.UPWARD_REVIEW }"></ng-container>
        <ng-template #undoAlert let-step="step">
          <div class="alert-undo" [ngClass]="undo[step].shown ? 'alert-undo-visible' : 'alert-undo-hidden'">
            <span>
              <span>Question removed</span>
              <app-button (onClick)="restoreQuestion(step)">
                <span class="fal fa-fw fa-undo"></span>
                <span class="m-right-5"></span>
                <span>Undo</span>
              </app-button>
            </span>
          </div>
        </ng-template>
      </ng-container>
    
      <!-- Add question -->
      <app-modal #modal [background]="'light'" [width]="'50%'">
        <ng-container *ngIf="modal.visible">
          <ng-container [ngSwitch]="modalActive">
            <ng-container *ngSwitchCase="eModalType.ADD_QUESTION">
              <app-schedule-cycle-add-question [modalActive]="eModalType.ADD_QUESTION" [addQuestionForm]="addQuestionForm" (onClickAction)="onClickActionCreateModal($event)"></app-schedule-cycle-add-question>
            </ng-container>
            <ng-container *ngSwitchCase="eModalType.EDIT_QUESTION">
              <app-schedule-cycle-add-question [modalActive]="eModalType.EDIT_QUESTION" [addQuestionForm]="addQuestionForm" (onClickAction)="onClickActionCreateModal($event)"></app-schedule-cycle-add-question>
            </ng-container>
          </ng-container>
        </ng-container>
      </app-modal>

      <ng-template #addIcon let-index="index" let-isLast="isLast">
        <div class="add-row" [class.add-row-always-visible]="isLast" (click)="startAddQuestion(eEditingFrom.CYCLE_LIST, index)">
          <span class="add-icon">
            <span class="fal fa-fw fa-plus add-icon"></span>
          </span>
          <span class="add-text">Add a new question</span>
        </div>
      </ng-template>
    </ng-container>
  </app-state>
</div>
