import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AssessmentGroupInfo } from '@app/models/evaluation/assessment-group-info.model';
import { EvaluationCycleQuestion } from '@app/models/evaluation/evaluation-cycle-question.model';
import { EvaluationCycle } from '@app/models/evaluation/evaluation-cycle.model';
import { EvaluationFeedbackRequestState } from '@app/models/evaluation/evaluation-feedback-request-state.model';
import { EvaluationFeedbackRequestType } from '@app/models/evaluation/evaluation-feedback-request-type.model';
import { EvaluationFeedbackRequest } from '@app/models/evaluation/evaluation-feedback-request.model';
import { EvaluationFeedback } from '@app/models/evaluation/evaluation-feedback.model';
import { CycleStepMap } from '@app/models/evaluation/evaluation-module-type.model';
import { ManagerReviewAccessState } from '@app/models/evaluation/manager-review-access-state.model';
import { EvaluationCycleAPIService } from '@app/shared/api/evaluation-cycle.api.service';

@Component({
  selector: 'app-evaluation-feedback-request-display',
  templateUrl: './evaluation-feedback-request-display.component.html',
  styleUrls: ['./evaluation-feedback-request-display.component.scss']
})
export class EvaluationFeedbackRequestDisplayComponent implements OnInit {

  public readonly eEvaluationFeedbackRequestState = EvaluationFeedbackRequestState;

  @Input() cycle: EvaluationCycle;
  @Input() request: EvaluationFeedbackRequest;
  @Input() assessmentGroups: CycleStepMap<AssessmentGroupInfo[]>;
  @Input() startExpanded: boolean;

  @Output() onDropdownOpened: EventEmitter<number>;

  response?: EvaluationFeedback;

  dropdownOpen: boolean;

  constructor(
    private evaluationCycleAPIService: EvaluationCycleAPIService
  ) {
    this.request = undefined;
    this.assessmentGroups = undefined;
    this.startExpanded = false;

    this.response = undefined;
    this.dropdownOpen = false;

    this.onDropdownOpened = new EventEmitter<number>();
  }

  ngOnInit(): void {
    if (this.startExpanded) {
      this.expandDropdown();
    }
  }

  getData(): Promise<EvaluationFeedback> {
    if (!this.cycle) return Promise.resolve(undefined);

    switch(this.request.type) {
      case EvaluationFeedbackRequestType.PEER_REVIEW:
        return this.getResponse(this.cycle.peerReviewQuestions);
      case EvaluationFeedbackRequestType.UPWARD_REVIEW:
        return this.getResponse(this.cycle.upwardReviewQuestions);
      case EvaluationFeedbackRequestType.MANAGER_REVIEW:
        return this.getResponse(this.cycle.managerReviewQuestions, true);
    }

  }

  getResponse(questions: EvaluationCycleQuestion[], checkManagerReviewAccess = false): Promise<EvaluationFeedback> {
    return this.evaluationCycleAPIService.getEvaluationUserByCycleIdAndUserId(this.cycle.id, this.request.subjectUserId).toPromise()
      .then(user => {
        if (checkManagerReviewAccess) {
          if (!user.managerReviewAccess) return null;
          if (user.managerReviewAccess.state !== ManagerReviewAccessState.SHARED) return null;
        }

        const response: EvaluationFeedback = user.responsesAboutUser.find(res => res.request.id == this.request.id);
        if (!response) return response;

        response.questionsAnswered = response.questionsAnswered.map(q => {
          q.question = questions.find(pq => pq.id === q.questionId);
          return q;
        });

        this.response = response;

        return response;
      });
  }

  toggleDropdown(): void {
    if (this.dropdownOpen) {
      this.collapseDropdown();
    } else {
      this.expandDropdown();
    }
  }

  expandDropdown(): void {
    if (!this.response) {
      this.getData().then(() => {
        this.onDropdownOpened.emit(this.request.id);
        this.dropdownOpen = true;
      });

      return;
    }

    this.onDropdownOpened.emit(this.request.id);
    this.dropdownOpen = true;
  }

  collapseDropdown(): void {
    this.dropdownOpen = false;
  }
}
