import { Task } from '@app/domain/task/model/task.model';
import moment from 'moment';
import { mockUsers } from '../../../mock/api/data/mockUsers';
import { TaskAlignmentType } from '@app/domain/task/model/task-alignment-type.enum';
import { TaskStatus } from '@app/domain/task/model/task-status.enum';
import { TaskCreatedFrom } from '../model/task-created-from.model';

export const mockTasks: Task[] = [
  {
    id: 1,
    title: 'Onboard team onto Frankli',
    description: '',
    dueDate: moment().add('5', 'days').toDate(),
    creator: mockUsers[0],
    creationDate: new Date(),
    completionDate: null,
    status: TaskStatus.NOT_STARTED,
    alignmentType: TaskAlignmentType.TODO,
    alignmentId: null,
    archived: false,
    owners: [],
    activity: [],
    comments: [],
    linkedPositionIds: [],
    createdFrom: TaskCreatedFrom.OVERVIEW
  },
  {
    id: 2,
    title: 'Set-up virtual remote event for entire team',
    description: '',
    dueDate: moment().add('7', 'days').toDate(),
    creator: mockUsers[0],
    creationDate: new Date(),
    completionDate: null,
    status: TaskStatus.NOT_STARTED,
    alignmentType: TaskAlignmentType.TODO,
    alignmentId: null,
    archived: false,
    owners: [],
    activity: [],
    comments: [],
    linkedPositionIds: [],
    createdFrom: TaskCreatedFrom.OVERVIEW
  },
  {
    id: 3,
    title: 'Review and agree all company OKRs before setting live',
    description: '',
    dueDate: moment().add('10', 'days').toDate(),
    creator: mockUsers[0],
    creationDate: new Date(),
    completionDate: null,
    status: TaskStatus.NOT_STARTED,
    alignmentType: TaskAlignmentType.TODO,
    alignmentId: null,
    archived: false,
    owners: [],
    activity: [],
    comments: [],
    linkedPositionIds: [],
    createdFrom: TaskCreatedFrom.OVERVIEW
  },
  {
    id: 4,
    title: 'Speak to your team about updating their goals weekly',
    description: '',
    dueDate: moment().add('1', 'week').toDate(),
    creator: mockUsers[0],
    creationDate: new Date(),
    completionDate: null,
    status: TaskStatus.NOT_STARTED,
    alignmentType: TaskAlignmentType.TODO,
    alignmentId: null,
    archived: false,
    owners: [],
    activity: [],
    comments: [],
    linkedPositionIds: [],
    createdFrom: TaskCreatedFrom.OVERVIEW
  },
  {
    id: 5,
    title: 'Follow up on training budget',
    description: '',
    dueDate: moment().add('1', 'week').toDate(),
    creator: mockUsers[0],
    creationDate: new Date(),
    completionDate: null,
    status: TaskStatus.NOT_STARTED,
    alignmentType: TaskAlignmentType.TODO,
    alignmentId: null,
    archived: false,
    owners: [],
    activity: [],
    comments: [],
    linkedPositionIds: [],
    createdFrom: TaskCreatedFrom.OVERVIEW
  }
];