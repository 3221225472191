import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonMessages } from '@app/constants/common.messages';
import { GoalsMessages } from '@app/goals/goals.messages';
import { GoalActivity } from '@app/models/goals/goal-activity.model';
import { GoalActivityType } from '@app/models/goals/goal-activity-type.model';
import { GoalKeyResultMeasureUnitPlacement } from '@app/models/goals/goal-key-result-measure-unit-placement.model';
import { GoalKeyResultType } from '@app/models/goals/goal-key-result-type.model';
import { GoalPriority } from '@app/models/goals/goal-priority.model';
import { GoalStatus } from '@app/models/goals/goal-status.model';
import { IconHoverColor } from '@app/shared/components/inputs/table-action-icon/table-action-icon.component';
import { Globals } from '@app/shared/globals/globals';
import { TerminologyEntity } from '@app/domain/terminology/model/terminology-entity.enum';

@Component({
  selector: 'app-goals-individual-activity-item',
  templateUrl: './goals-individual-activity-item.component.html',
  styleUrls: ['./goals-individual-activity-item.component.scss']
})
export class GoalsIndividualActivityItemComponent {

  public readonly eGoalKeyResultMeasureUnitPlacement = GoalKeyResultMeasureUnitPlacement;
  public readonly eGoalKeyResultType = GoalKeyResultType;
  public readonly eTerminologyEntity = TerminologyEntity;
  public readonly eGoalActivityType = GoalActivityType;
  public readonly eCommonMessages = CommonMessages;
  public readonly eIconHoverColor = IconHoverColor;
  public readonly eGoalsMessages = GoalsMessages;
  public readonly eGoalPriority = GoalPriority;
  public readonly eGoalStatus = GoalStatus;

  @Input() activity: GoalActivity;

  @Output() onClickDelete: EventEmitter<MouseEvent>;

  constructor(
    public globals: Globals
  ) {
    this.activity = undefined!;
    this.onClickDelete = new EventEmitter<MouseEvent>();
  }

  deleteComment(event: MouseEvent): void {
    this.onClickDelete.emit(event);
  }

  parseUnitPlacementToMessageCode(unitPlacement: GoalKeyResultMeasureUnitPlacement): string {
    switch (unitPlacement) {
      case GoalKeyResultMeasureUnitPlacement.BEFORE:
        return GoalsMessages.BEFORE;
      case GoalKeyResultMeasureUnitPlacement.AFTER:
        return GoalsMessages.AFTER;
    }
  }
}
