export class NavbarMessages {
    public static readonly NOTIFICATIONS = 'navbar.notifications' //Notifications
    public static readonly ACTIONS = 'navbar.actions' // Actions
    public static readonly NO_NOTIFICATIONS_MESSAGE = 'navbar.no-notifications' // You currently have no notifications
    public static readonly NO_ACTIONS_MESSAGE = 'navbar.no-actions' // You currently have no actions
    public static readonly MARK_ALL_AS_READ = 'navbar.mark-all-as-read' // Mark all as read
    public static readonly VIEW_PROFILE = 'navbar.view-profile' // View profile
    public static readonly EDIT_PROFILE = 'navbar.edit-profile' // Edit profile
    public static readonly CHANGE_PASSWORD = 'navbar.change-password' // Change password
    public static readonly NOTIFICATION_SETTINGS = 'navbar.notification-settings' // Notification settings
    public static readonly LOGOUT = 'navbar.logout' // Log out
    public static readonly SEARCH_PLACEHOLDER = 'navbar.search-placeholder' // Search people or Interests
    public static readonly HELP_CENTER = 'navbar.help-center' // Help center
    public static readonly TOGGLE_NAVIGATION = 'navbar.toggle-navigation' // Help center
    public static readonly CREATE = 'navbar.create-menu.create' // Create
    public static readonly GOAL = 'navbar.create-menu.goal' // Goal
    public static readonly ONE_TO_ONE_MEETING = 'navbar.create-menu.one-to-one' // 1:1 Meeting
    public static readonly PRAISE = 'navbar.create-menu.praise' // Praise
    public static readonly IDEA = 'navbar.create-menu.idea' // Idea
    public static readonly PEER_FEEDBACK = 'navbar.create-menu.peer-feedback' // Peer Feedback
    public static readonly REQUEST_FEEDBACK = 'navbar.create-menu.request-feedback' // Request Feedback
    public static readonly UNPROMPTED_FEEDBACK = 'navbar.create-menu.unprompted-feedback' // Unprompted Feedback
    public static readonly SURVEY = 'navbar.create-menu.survey' // Survey
    public static readonly NEW_PERSON = 'navbar.create-menu.new-person' // New Person
    public static readonly WHATS_NEW = 'navbar.whats-new' // What's new
    public static readonly TASK = 'navbar.create-menu.task' // What's new
    
}