import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import moment from 'moment';

@Pipe({
  name: 'dateAgo',
  pure: false
})
export class DateAgoPipe extends DatePipe implements PipeTransform {
  previousValue!: Date | string;
  previousArgs!: any;
  resultCached!: string;

  transform(value: Date | string, args?: any): any {
    let showAgo = true;

    if (args === 'noAgo') {
      showAgo = false
    }

    // Skip calculation if value hasn't changed
    if ((value === this.previousValue) && this.resultCached && (args === this.previousArgs)) {
      return this.resultCached;
    }
    this.previousValue = value;
    this.previousArgs = args;

    const date = moment(value);
    const now = moment();

    let result: number;
    let span: string;

    if (value) {
      // time since message was sent in seconds
      const delta =  now.diff(date, 'seconds');

      // format string
      if (delta < 60) { // sent in last minute
        return 'Just now'
      } else if (delta < 3600) { // sent in last hour
        result = Math.floor(delta / 60)
        span = 'minute'
      } else if (delta < 86400) { // sent on last day
        result = Math.floor(delta / 3600)
        span = 'hour'
      } else if (delta < 604800) { // sent more than one day ago
        result = Math.floor(delta / 86400)
        span = 'day'
      } else if (delta < 2592000) { // sent more than one week ago
        result = Math.floor(delta / 604800)
        span = 'week'
      } else if (delta < 31536000) { // sent more than one month ago
        result = Math.floor(delta / 2592000)
        span = 'month'
      } else { // sent more than one year ago
        result = Math.floor(delta / 31536000)
        span = 'year'
      }

      // add s for in case of multiples
      if (result > 1) {
        span += 's'
      }

      const resultString = `${result} ${span} ${showAgo ? 'ago' : ''}`;

      this.resultCached = resultString;
      return resultString;
    }

    return value
  }
}
