<router-outlet></router-outlet>
<ng-container *ngIf="route.children.length === 0">
  <!-- Success -->
  <ng-container *ngIf="state.isSuccessful()">
    <div class="content">
      <!-- Stats -->
      <ng-container *ngIf="statsState.isSuccessful()">
        <app-goal-stats [stats]="goalStats"></app-goal-stats>
      </ng-container>
      <ng-container *ngIf="statsState.isLoading()">
        <div class="card text-center">
          <div class="content">
            <span class="fas fa-spin fa-spinner fa-3x fa-fw"></span>
          </div>
        </div>
      </ng-container>

      <!-- Table -->
      <div class="card">
        <!-- Universal filter and column toggle -->
        <div class="p-bottom-20 p-top-20 p-left-20 p-right-20">
          <div class="p-bottom-15" *ngIf="globals.hasRole(eRoleName.GOAL_COMPANY)">
            <span class="align-ceo-button" (click)="toggleViewAsAdmin()">
              <label class="m-right-5" translate>{{eGoalsMessages.VIEW_AS_ADMIN}}</label>
              <input type="checkbox" [checked]="viewAsAdmin.value">
            </span>
          </div>

          <div class="row m-left-0 m-right-0">
            <div class="col-universal-filter">
              <app-dynamic-filter [filterCategories]="filterCategories" [searchProperties]="searchProperties"
                (filtersChanged)="updateFilter($event)"></app-dynamic-filter>
            </div>
            <div class="col-column-toggle">
              <app-column-toggle-new [columnInfos]="columnInfos" [alwaysEnabled]="[eTableColumn.NAME]"
                #columnToggle></app-column-toggle-new>
            </div>
          </div>
        </div>

        <!-- Search Success -->
        <ng-container *ngIf="searchState.isSuccessful()">
          <ng-container *ngIf="directReports.length > 0; else noSearchResults">
            <!-- Table -->
            <div class="scroll-x">
              <table width="100%">
                <!-- Table headings -->
                <tr class="frankli-table frankli-table-small frankli-table-header noselect">
                  <th></th> <!-- Spacing for dropdown -->
                  <th *ngIf="columnToggle.getColumnEnabled(eTableColumn.NAME)" translate>
                    {{eCommonMessages.NAME}}
                  </th>
                  <th *ngIf="columnToggle.getColumnEnabled(eTableColumn.NAME)"></th>
                  <th class="text-center" *ngIf="columnToggle.getColumnEnabled(eTableColumn.GOAL_COUNT)" translate>
                    {{eGoalsMessages.GOAL_COUNT}}
                  </th>
                  <th class="text-center" *ngIf="columnToggle.getColumnEnabled(eTableColumn.ACTIVE_GOAL_COUNT)"
                    translate>
                    {{eGoalsMessages.ACTIVE_GOAL_COUNT}}
                  </th>
                  <th class="text-center" *ngIf="columnToggle.getColumnEnabled(eTableColumn.COMPLETE_GOAL_COUNT)"
                    translate>
                    {{eGoalsMessages.COMPLETE_GOAL_COUNT}}
                  </th>
                  <th *ngIf="columnToggle.getColumnEnabled(eTableColumn.AVERAGE_COMPLETION)" translate>
                    {{eGoalsMessages.AVERAGE_COMPLETION}}
                  </th>
                  <th class="text-center" *ngIf="columnToggle.getColumnEnabled(eTableColumn.ACTIONS)" translate>
                    {{eCommonMessages.ACTIONS}}
                  </th>
                </tr>

                <!-- Table Data -->
                <app-goal-overview-manager-row *ngFor="let report of directReports"
                  (onOpenUserGoals)="openUserGoals($event)" (onLoadChildren)="loadChildren($event)"
                  (onOpenUserGoalCreate)="openUserGoalCreate($event)" [report]="report"
                  [enabledColumnKeys]="columnToggle.getEnabledColumnKeys()">
                </app-goal-overview-manager-row>
              </table>
            </div>

            <app-pagination-server
              *ngIf="!columnToggle.checkAllDisabled() && searchState.isSuccessful() && goalsOverviewPage"
              [page]="goalsOverviewPage" [pageSize]="pagingParams.pageSize" (pageSizeChanged)="changePageSize($event)"
              (pageNumberChanged)="changePageNumber($event)"></app-pagination-server>
          </ng-container>
        </ng-container>
        <!-- Search Loading -->
        <ng-container *ngIf="searchState.isLoading()">
          <div class="p-top-15 p-bottom-15 text-center">
            <span class="fas fa-spin fa-spinner fa-3x fa-fw"></span>
          </div>
        </ng-container>

        <!-- No searches found -->
        <ng-template #noSearchResults>
          <app-empty-state [height]="'calc(100vh - 500px)'"
            [message]="eGoalsMessages.TEAM_GOALS_EMPTY_STATE | translate"></app-empty-state>
        </ng-template>
      </div>
    </div>
  </ng-container>

  <!-- Loading -->
  <ng-container *ngIf="state.isLoading()">
    <div class="card">
      <app-loading-state [height]="'calc(100vh - 150px)'" [showMessage]="false"></app-loading-state>
    </div>
  </ng-container>

  <!-- Failure -->
  <ng-container *ngIf="state.isFailure()">
    <div class="card">
      <div class="card">
        <app-error-state [height]="'calc(100vh - 500px)'" [message]="state.error.message"></app-error-state>
      </div>
    </div>
  </ng-container>

  <!-- User Goals Modal -->
  <ng-container *ngIf="viewingUser">
    <app-user-goals-modal #userGoalsModal [owner]="viewingUser"
      (modalHidden)="onGoalsModalHidden($event)"></app-user-goals-modal>
  </ng-container>

  <ng-container *ngIf="creatingGoal">
    <app-modal [showCloseButton]="false" [minWidth]="'200px'" [maxWidth]="'900px'" [width]="'80%'" [sidePanel]="true"
      [confirmClosing]="goalCreateComponent.formChanged" [confirmCloseModalSettings]="confirmCloseSettings"
      (onConfirmCloseModalResponse)="onCloseCreateResponse($event)" #userGoalCreateModal>
      <app-goal-create [initGoalOwnerIds]="[creatingUser]" [modal]="modalCreate" (requestCloseModal)="hideCreateModal()" #goalCreateComponent></app-goal-create>
    </app-modal>
  </ng-container>
</ng-container>