import { Address } from '@app/models/address.model';

export const mockAddresses: Address[] = [
  {
    id: 1,
    street: '10 John Street',
    city: 'Sligo',
    district: 'Sligo',
    postalCode: 'F12 X57',
    country: 'Ireland',
    timezone: 'Europe/Dublin'
  }
];