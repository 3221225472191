/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */

import { Injectable } from '@angular/core';
import { MockService } from '@app/mock/api/mock-service';
import { HttpRequest } from '@angular/common/http';
import { IOneToOneAPI } from '@app/domain/one_to_one/api/one-to-one-api.model';
import { Task } from '@app/domain/task/model/task.model';
import { OneToOneDirectReportInfo } from '@app/domain/one_to_one/model/one-to-one-direct-report-info.model';
import { TalkingPoint } from '@app/domain/one_to_one/model/talking-point.model';
import { OneToOneMeetingFile } from '@app/domain/one_to_one/model/one-to-one-meeting-file.model';
import { OneToOneMeeting, OneToOneMeetingDetailsView, OneToOneMeetingMinimal } from '@app/domain/one_to_one/model/one-to-one-meeting.model';
import { TalkingPointTemplate } from '@app/domain/one_to_one/model/talking-point-template.model';
import { OneToOneSchedule, OneToOneScheduleDetailsView, OneToOneScheduleMinimal } from '@app/domain/one_to_one/model/one-to-one-schedule.model';
import { Observable, of } from 'rxjs';
import { Globals } from '@app/shared/globals/globals';
import { sanitizeUrl } from '@app/shared/utils/helpers';
import { OneToOneStatus } from '@app/domain/one_to_one/model/one-to-one-status.model';
import { mockTalkingPointTemplates } from '@app/domain/one_to_one/mock/talking-point-templates.mock';
import { mockOneToOneMeetings } from '@app/domain/one_to_one/mock/one-to-one-meetings.mock';
import { mockUsers } from '@app/mock/api/data/mockUsers';
import { OneToOneMeetingStatus } from '@app/domain/one_to_one/model/one-to-one-meeting-status.model';
import { CreateOneToOneSchedule } from '@app/domain/one_to_one/model/create-one-to-one-schedule.model';
import { RescheduleOneToOneMeeting } from '@app/domain/one_to_one/model/reschedule-one-to-one-meeting.model';
import { PagingParams } from '@app/models/api/paging-params.model';
import { SortingParams } from '@app/models/api/sorting-params.model';
import { Page } from '@app/models/api/page.model';
import { ParentFilter } from '@app/models/api/parent-filter.model';
import { mockOneToOneSchedules } from '../mock/one-to-one-schedules.mock';
import { TransferOneToOneSchedule } from '../model/transfer-one-to-one-schedule.model';
import { OneToOneNoteMinimal } from '../model/one-to-one-note.model';
import { CreateMeetingSentimentScaleScore, MeetingSentimentScaleScore, MeetingSentimentScaleScoreMinimal } from '../model/meeting-sentiment-scale-score.model';
import { ScheduleSentimentScaleTimeline } from '@app/shared/components/information-sidebar/tabs/information-sidebar-sentiment-scale-timeline/information-sidebar-sentiment-scale-timeline.component';
import { ChartTargetUser } from '@app/domain/sentiment_scale/page/sentiment-scale-reporting/sentiment-scale-reporting.component';

@Injectable()
export class OneToOneAPIServiceMock implements MockService, IOneToOneAPI {
  constructor(private readonly globals: Globals) { }

  handleRoute(req: HttpRequest<any>): any {
    const url = sanitizeUrl(req.urlWithParams);
    const urlSegments = url.split('/');

    switch (true) {
      case url.endsWith('api/one-to-one/schedule/me'):
        return this.getOneToOneSchedulesForUserMe();
      case url.match(/api\/one-to-one\/schedule\/managed\/\d+$/) !== null:
        return this.getOneToOneScheduleByIdManaged(+urlSegments[4]);
      case url.match(/api\/one-to-one\/schedule\/manager\/\d+$/) !== null:
        return this.getOneToOneSchedulesByManagerId(+urlSegments[4]);
      case url.endsWith('api/one-to-one/schedule/manage/direct'):
        return this.getOneToOneDirectReportInfo(null);
      case url.match(/api\/one-to-one\/schedules\/\d+$/) !== null:
        return this.getOneToOneScheduleById(+urlSegments[3]);
      case url.endsWith('api/one-to-one/schedules'):
        return this.getOneToOneSchedulesForManagerMe();
      case url.endsWith('api/one-to-one/schedules/archived/user/me'):
        return this.getArchivedOneToOneSchedulesForUserMe();
      case url.match(/api\/one-to-one\/\d+\/meeting\/\d+\/prev\/talking-point/) !== null:
        return this.getIncompleteFromPreviousTalkingPoints(+urlSegments[2], +urlSegments[4]);
      case url.match(/api\/one-to-one\/template\/\d+$/) !== null && req.method === 'GET':
        return this.getTalkingPointTemplateById(+urlSegments[3]);
      case url.match(/api\/one-to-one\/template\/name\/\D+$/) !== null:
        return this.getTalkingPointTemplateByName(urlSegments[4]);
      case url.endsWith('api/one-to-one/template/default'):
        return this.getDefaultTalkingPointTemplate();
      case url.endsWith('api/one-to-one/template') && req.method === 'GET':
        return this.getTalkingPointTemplates();
      case url.match(/api\/one-to-one\/meeting\/\d+\/file/) !== null:
        return this.getFilesByMeetingId(+urlSegments[3]);
      case url.endsWith('api/one-to-one/schedule/meeting/oldest'):
        return this.getOldestOneToOneMeeting();
      case url.match(/api\/one-to-one\/schedule\/cycle\/\d+$/) !== null:
        return this.getScheduleForCycleIdAndMeManager(+urlSegments[4]);
      case url.match(/api\/one-to-one\/schedule\/\d+\/cycle\/\d+$/) !== null:
        return this.getScheduleForDirectReportAndCycleId(+urlSegments[3], +urlSegments[5]);
      case url.match(/api\/one-to-one\/meetings\/post-review\/\d+$/) !== null:
        return this.getPostReviewMeetingsByCycleId(0);
    }
  }

  getOneToOneSchedulesForOverview(filter: ParentFilter, paging: PagingParams, sorting: SortingParams): Observable<Page<OneToOneScheduleMinimal>> {
    return of(undefined);
  }

  getScheduleForCycleIdAndMeManager(cycleId: number): Observable<OneToOneSchedule> {
    return of(mockOneToOneSchedules.find(s => s.evaluationCycleId === cycleId && s.manager.id === this.globals.user.managerId && s.participants[0].id === this.globals.user.id) || null);
  }

  getScheduleForDirectReportAndCycleId(userId: number, cycleId: number): Observable<OneToOneSchedule> {
    return of(mockOneToOneSchedules.find(s => s.evaluationCycleId === cycleId && s.manager.id === this.globals.user.id && s.participants[0].id === userId) || null);
  }

  getArchivedOneToOneSchedulesForUserMe(): Observable<OneToOneSchedule[]> {
    return of(mockOneToOneSchedules.filter(s => s.participants[0].id === this.globals.user.id && s.status === OneToOneStatus.ARCHIVED));
  }

  getDefaultTalkingPointTemplate(): Observable<TalkingPointTemplate> {
    return of(mockTalkingPointTemplates.find(t => t.defaultTemplate));
  }

  getFilesByMeetingId(meetingId: number): Observable<OneToOneMeetingFile[]> {
    return of([]);
  }

  getIncompleteFromPreviousTalkingPoints(scheduleId: number, meetingId: number): Observable<TalkingPoint[]> {
    return of([]);
    // return mockOneToOneMeetings.find(m => m.id === meetingId).talkingPoints.filter(tp => !tp.actioned);
  }

  getOldestOneToOneMeeting(): Observable<OneToOneMeeting> {
    return of(mockOneToOneMeetings.sort((a, b) =>
      new Date(b.meetingTimestamp).getTime() - new Date(a.meetingTimestamp).getTime())
      .find(g => g !== undefined));
  }

  getOneToOneDirectReportInfo(parentFilter: ParentFilter): Observable<OneToOneDirectReportInfo[]> {
    const oneToOneDirectReportInfos: OneToOneDirectReportInfo[] = [];

    const myDirectReports = mockUsers.filter(u => u.id !== this.globals.user.id && u.managerId === this.globals.user.id);
    for (const directReport of myDirectReports) {
      let meetingCount = 0;
      let lastMeetingDate: Date;
      const directReportSchedules = mockOneToOneSchedules.filter(s => s.manager.id === directReport.id || s.participants[0].id === directReport.id);
      for (const schedule of directReportSchedules) {
        const completedMeetings = schedule.meetingList.filter(m => [OneToOneMeetingStatus.COMPLETED, OneToOneMeetingStatus.AUTO_COMPLETED].includes(m.status));
        meetingCount += completedMeetings.length;
        if (completedMeetings.length > 0) {
          const lastMeetingDateInSchedule = new Date(Math.max(...completedMeetings.map(m => new Date(m.meetingTimestamp).getTime())));
          if (!lastMeetingDate || lastMeetingDateInSchedule > lastMeetingDate) {
            lastMeetingDate = lastMeetingDateInSchedule;
          }
        }
      }

      const drDirectReports = mockUsers.filter(u => u.id !== directReport.id && u.managerId === directReport.id);
      oneToOneDirectReportInfos.push({
        user: directReport,
        directScheduleList: [],
        directReports: drDirectReports,
        lastMeeting: lastMeetingDate || null,
        meetings: meetingCount,
        // Count how many meetings of each status there are in each meeting list of each schedule
        // Use the following model:
        // statuses: {
        //   [OneToOneMeetingStatus.SCHEDULED]: number;
        //   [OneToOneMeetingStatus.CANCELLED]: number;
        //   [OneToOneMeetingStatus.MISSED]: number;
        //   [OneToOneMeetingStatus.IN_PROGRESS]: number;
        //   [OneToOneMeetingStatus.AUTO_COMPLETED]: number;
        //   [OneToOneMeetingStatus.COMPLETED]: number;
        //   [OneToOneMeetingStatus.REOPENED]: number;
        // }
        statuses: directReportSchedules.flatMap(s => s.meetingList).reduce((acc, m) => {
          if (acc[m.status]) {
            acc[m.status]++;
          } else {
            acc[m.status] = 1;
          }

          return acc;
        }, {
          [OneToOneMeetingStatus.SCHEDULED]: 0,
          [OneToOneMeetingStatus.CANCELLED]: 0,
          [OneToOneMeetingStatus.MISSED]: 0,
          [OneToOneMeetingStatus.IN_PROGRESS]: 0,
          [OneToOneMeetingStatus.AUTO_COMPLETED]: 0,
          [OneToOneMeetingStatus.COMPLETED]: 0,
          [OneToOneMeetingStatus.REOPENED]: 0
        })
      });
    }
    return of(oneToOneDirectReportInfos);
  }

  getOneToOneScheduleById(scheduleId: number): Observable<OneToOneScheduleMinimal> {
    // const oneToOneSchedule = mockOneToOneSchedules.find(s => s.id === scheduleId);
    return of(null);
  }

  getOneToOneMeetingById(meetingId: number): Observable<OneToOneMeeting> {
    return of(null);
  }

  getOneToOneScheduleByIdManaged(scheduleId: number): Observable<OneToOneSchedule> {
    return of(mockOneToOneSchedules.find(s => s.id === scheduleId));
  }

  getOneToOneSchedulesByManagerId(managerId: number): Observable<OneToOneSchedule[]> {
    return of(mockOneToOneSchedules.filter(s => s.manager.id === managerId));
  }

  getOneToOneSchedulesForManagerMe(): Observable<OneToOneScheduleDetailsView[]> {
    return of([]);
  }

  getOneToOneSchedulesForUserMe(): Observable<OneToOneScheduleDetailsView[]> {
    return of([]);
  }

  getTalkingPointTemplateById(id: number): Observable<TalkingPointTemplate> {
    return of(mockTalkingPointTemplates.find(t => t.id === id));
  }

  getTalkingPointTemplateByName(name: string): Observable<TalkingPointTemplate> {
    return of(mockTalkingPointTemplates.find(t => t.name.toLowerCase() === name.toLowerCase()));
  }

  getTalkingPointTemplates(): Observable<TalkingPointTemplate[]> {
    return of(mockTalkingPointTemplates);
  }

  // No Ops listed below
  actionTalkingPoint(scheduleId: number, meetingId: number, talkingPointId: number, checked: boolean): Observable<TalkingPoint> {
    return of(undefined);
  }

  addOneToOneMeetingTalkingPoint(talkingPoints: TalkingPoint, scheduleId: number, meetingId: number): Observable<OneToOneMeeting> {
    return of(undefined);
  }

  archiveSchedule(scheduleId: number): Observable<OneToOneSchedule> {
    return of(mockOneToOneSchedules[0]);
  }

  bumpTalkingPoint(scheduleId: number, meetingId: number, talkingPointId: number): Observable<OneToOneMeeting> {
    return of(undefined);
  }

  commentOneToOneMeetingTalkingPoint(scheduleId: number, meetingId: number, talkingPointId: number, newComment: string): Observable<OneToOneMeeting> {
    return undefined;
  }

  reorderMeetingTalkingPoints(talkingPoints: TalkingPoint[], scheduleId: number, meetingId: number): Observable<TalkingPoint[]> {
    return of(talkingPoints);
  }

  createOneToOneSchedule(createDto: CreateOneToOneSchedule): Observable<OneToOneSchedule> {
    return of(mockOneToOneSchedules[0]);
  }

  createTalkingPointTemplate(talkingPointTemplate: TalkingPointTemplate): Observable<TalkingPointTemplate> {
    return undefined;
  }

  deleteFileByMeetingIdAndFileName(meetingId: number, filename: string): Observable<OneToOneMeetingFile> {
    return undefined;
  }

  deleteSchedule(scheduleId: number): Observable<OneToOneSchedule> {
    return of(mockOneToOneSchedules[0]);
  }

  deleteTalkingPointTemplateById(id: number): Observable<TalkingPointTemplate> {
    return undefined;
  }

  pauseSchedule(scheduleId: number): Observable<OneToOneSchedule> {
    return of(mockOneToOneSchedules[0]);
  }

  resumeSchedule(scheduleId: number): Observable<OneToOneSchedule> {
    return of(mockOneToOneSchedules[0]);
  }

  removeOneToOneMeetingTalkingPoint(scheduleId: number, meetingId: number, talkingPoint: TalkingPoint): Observable<TalkingPoint[]> {
    return undefined;
  }

  updateOneToOneMeetingPrivateNotes(scheduleId: number, privateNotes: string, meetingId: number, autosave: boolean): Observable<OneToOneMeeting> {
    return undefined;
  }

  updateOneToOneMeetingSharedNotes(scheduleId: number, sharedNotes: string, meetingId: number, autosave: boolean): Observable<OneToOneMeeting> {
    return undefined;
  }

  updateMeetingStatus(scheduleId: number, meetingId: number, status: OneToOneMeetingStatus): Observable<OneToOneMeeting> {
    return undefined;
  }

  updateOneToOneSchedule(id: number, updateDto: CreateOneToOneSchedule): Observable<OneToOneSchedule> {
    return of(mockOneToOneSchedules[0]);
  }

  updateTalkingPointTemplate(id: number, talkingPointTemplate: TalkingPointTemplate): Observable<TalkingPointTemplate> {
    return undefined;
  }

  uploadFileForMeeting(meetingId: number, file: File): Observable<OneToOneMeetingFile> {
    return undefined;
  }

  getPostReviewMeetingsByCycleId(cycleId: number) {
    return [];
  }

  getSidebarOneToOneSchedules(userIds: number[]): Observable<OneToOneScheduleDetailsView[]> {
    return of([]);
  }

  searchTalkingPointTemplates(pagingParams: PagingParams, sortingParams: SortingParams, name: string): Observable<Page<TalkingPointTemplate>> {
    return of(undefined);
  }

  rebuildCalendarEventsForScheduleId(scheduleId: number): Observable<OneToOneSchedule> {
    return of(mockOneToOneSchedules[0]);
  }

  rebuildCalendarEventsForUserId(userId: number): Observable<boolean> {
    return of(true);
  }

  transferScheduleToNewManager(scheduleId: number, transferOneToOneSchedule: TransferOneToOneSchedule): Observable<OneToOneSchedule> {
    return of(mockOneToOneSchedules[0]);
  }

  getSchedulesForUserAsFrankliAdmin(companyId: number, userId: number): Observable<OneToOneSchedule[]> {
    return of([]);
  }

  getSchedulesManagedByUserId(userId: number): Observable<OneToOneScheduleDetailsView[]> {
    return of([]);
  }

  addTalkingPointComment(scheduleId: number, meetingId: number, talkingPointId: number, commentParsed: string): Observable<TalkingPoint> {
    return of(undefined);
  }

  addMeetingSharedNotes(scheduleId: number, meetingId: number, sharedNote: string): Observable<OneToOneMeeting> {
    return of(undefined);
  }

  getMeetingPrivateNotes(scheduleId: number, meetingId: number): Observable<OneToOneNoteMinimal> {
    return of(undefined);
  }

  addMeetingPrivateNotes(scheduleId: number, meetingId: number, privateNoteContent: string): Observable<OneToOneNoteMinimal> {
    return of(undefined);
  }

  getMeetingFiles(scheduleId: number, meetingId: number): Observable<OneToOneMeetingFile[]> {
    return of([]);
  }

  addMeetingFile(scheduleId: number, meetingId: number, file: File): Observable<OneToOneMeetingFile> {
    return of(undefined);
  }

  deleteMeetingFile(scheduleId: number, meetingId: number, fileName: string): Observable<void> {
    return of(undefined);
  }

  getOneToOneSchedulesLinkedToReviews(): Observable<OneToOneScheduleDetailsView[]> {
    return of([]);
  }
  
  rescheduleMeeting(scheduleId: number, meetingId: number, rescheduleOneToOneMeeting: RescheduleOneToOneMeeting): Observable<OneToOneMeeting> {
    return undefined;
  }

  getMeetingSentimentScaleScores(scheduleId: number, meetingId: number): Observable<MeetingSentimentScaleScore[]> {
    return of([]);
  }

  updateMeetingSentimentScaleScore(scheduleId: number, meetingId: number, score: CreateMeetingSentimentScaleScore): Observable<MeetingSentimentScaleScore> {
    return of(undefined);
  }

  searchForMeetingByContents(scheduleId: number, searchTerm: string): Observable<number[]> {
    return of([]);
  }

  getSentimentTimelineForSchedule(scheduleId: number): Observable<ScheduleSentimentScaleTimeline> {
    return of(undefined);
  }

  getLinkedScheduleIdWithUserForCycle(_cycleId: number, _userId: number): Observable<number> {
    return of(undefined);
  }

  searchMeetingSentimentScaleScores(filter: ParentFilter, paging: PagingParams, sorting: SortingParams): Observable<Page<MeetingSentimentScaleScoreMinimal>> {
    return of(undefined);
  }

  getMeetingsByIdIn(meetingIds: number[]): Observable<OneToOneMeeting[]> {
    return of([]);
  }

  getChartData(filter: ParentFilter, paging: PagingParams, sorting: SortingParams): Observable<ChartTargetUser[]> {
    return of([]);
  }

}
