import { Component, Input } from '@angular/core';
import { PageScheduleSentimentScaleTimelineMeeting } from '../information-sidebar-sentiment-scale-timeline.component';

@Component({
  selector: 'app-information-sidebar-sentiment-scale-timeline-meeting-item',
  templateUrl: './information-sidebar-sentiment-scale-timeline-meeting-item.component.html',
  styleUrls: ['./information-sidebar-sentiment-scale-timeline-meeting-item.component.scss']
})
export class InformationSidebarSentimentScaleTimelineMeetingItemComponent {

  @Input() meetingId: number;
  @Input() meetingData: PageScheduleSentimentScaleTimelineMeeting;
  @Input() uniqueUserIds: number[];
  @Input() isLast: boolean;

  constructor() {
    this.meetingId = undefined;
    this.meetingData = undefined;
    this.uniqueUserIds = [];
    this.isLast = false;
  }

}
