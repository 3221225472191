import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import { Role } from '@app/domain/role/model/role.model';
import {CreateRoleDto} from '@app/domain/role/model/create-role.model';
import {RoleService} from '@app/domain/role/api/role-api.model';
import {Page} from '@app/models/api/page.model';
import {UserMinimal} from '@app/models/user/user-minimal.model';
import { ProvisionedPosition } from '@app/domain/role/model/provisioned-position.model';
import { ApiUtils } from '@app/shared/utils/api.utils';
import { PagingParams } from '@app/models/api/paging-params.model';
import { SortingParams } from '@app/models/api/sorting-params.model';

@Injectable()
export class RoleAPIService implements RoleService {
  private readonly BASE_URL = 'api/position';

  constructor(
    private http: HttpClient
  ) { }

  get(ids: number[], name: string, grade: string, competencyIds: number[]): Observable<Role[]> {
    const url = `${this.BASE_URL}`;

    let params = new HttpParams();

    if (ids) {
      ids.forEach(id => {
        params = params.append('id', id);
      });
    }

    if (name && name.length > 0) {
      params = params.append('name', name);
    }

    if (grade && grade.length > 0) {
      params = params.append('grade', grade);
    }

    if (competencyIds) {
      competencyIds.forEach(id => {
        params = params.append('competencyId', id);
      });
    }

    return this.http.get<Role[]>(url, { params: params });
  }

  getPaginated(pagingParams: PagingParams, sortingParams: SortingParams, ids: number[], name: string, grade: string, competencyIds: number[]): Observable<Page<Role>> {
    const url = `${this.BASE_URL}/paged`;

    let params = ApiUtils.createPageAndSortParams(pagingParams, sortingParams);

    if (ids) {
      ids.forEach(id => {
        params = params.append('id', id);
      });
    }

    if (name && name.length > 0) {
      params = params.append('name', name);
    }

    if (grade && grade.length > 0) {
      params = params.append('grade', grade);
    }

    if (competencyIds) {
      competencyIds.forEach(id => {
        params = params.append('competencyId', id);
      });
    }

    return this.http.get<Page<Role>>(url, { params: params });
  }

  create(createPositionDto: CreateRoleDto): Observable<Role> {
    const url = `${this.BASE_URL}`;
    return this.http.post<Role>(url, createPositionDto);
  }

  update(id: number, updatePositionDto: CreateRoleDto): Observable<Role> {
    const url = `${this.BASE_URL}/${id}`;
    return this.http.put<Role>(url, updatePositionDto);
  }

  delete(id: number): Observable<Role> {
    const url = `${this.BASE_URL}/${id}`;
    return this.http.delete<Role>(url);
  }

  deleteMultiple(ids: number[]): Observable<Role[]> {
    const url = `${this.BASE_URL}/delete`;
    return this.http.post<Role[]>(url, ids);
  }

  importRoles(file: File): Observable<Array<Role>> {
    const url = `${this.BASE_URL}/import`;

    const formData: FormData = new FormData();
    formData.append('file', file, file.name);

    return this.http.post<Array<Role>>(url, formData);
  }

  getAllUsersAssignedToRole(roleId: number): Observable<UserMinimal[]> {
    const url = `${this.BASE_URL}/${roleId}/users`;
    return this.http.get<UserMinimal[]>(url);
  }

  getAllProvisionedPositions(): Observable<ProvisionedPosition[]> {
    const url = `${this.BASE_URL}/externally-provisioned`;
    return this.http.get<ProvisionedPosition[]>(url);
  }

  getProvisionedPositionByPositionId(positionId: number): Observable<ProvisionedPosition> {
    const url = `${this.BASE_URL}/externally-provisioned/${positionId}`;
    return this.http.get<ProvisionedPosition>(url);
  }

  searchRoles(searchTerm: string): Observable<Role[]> {
    const url = `${this.BASE_URL}/search`;

    let params = new HttpParams();

    if (searchTerm) {
      params = params.append('query', searchTerm);      
    }

    return this.http.get<Role[]>(url, { params: params });
  }
}
