import {CommonMessages} from '@app/constants/common.messages';
import {GoalsMessages} from '@app/goals/goals.messages';
import {GoalPriority} from '@app/models/goals/goal-priority.model';
import {GoalStatus} from '@app/models/goals/goal-status.model';
import {GoalType} from '@app/models/goals/goal-type.enum';
import {GoalVisibility} from '@app/models/goals/goal-visibility.model';
import {Goal} from '@app/models/goals/goal.model';
import {RoundProgress} from '@app/models/analytics/round-progress.model';
import {GoalKeyResult} from '@app/models/goals/goal-key-result.model';
import { GoalDistribution } from '@app/models/goals/goal-distribution.model';
import { TerminologyCollection } from '@app/domain/terminology/model/terminology-collection.model';
import { TerminologyEntity } from '@app/domain/terminology/model/terminology-entity.enum';

export interface ProgressValue {
  valueRaw: number;
  valueCapped: number;
  valueCappedRounded: string;
  overflowValue: number;
  overflowRaw: number;
  overflowRounded: string;
}

export class GoalUtils {
  // Calculates the raw progress value of a key result
  static getProgressRaw = (goalValue: number, currentValue: number, startValue: number): number => {
    if (isNaN(goalValue) || isNaN(currentValue) || isNaN(startValue)) {
      return 0;
    }

    return ((startValue - currentValue) / (startValue - goalValue)) * 100;
  };

  static getKeyResultProgress = (keyResult: GoalKeyResult): number => {
    let progress: number;

    progress = GoalUtils.getProgressRaw(keyResult.measureGoalValue, keyResult.measureCurrentValue, keyResult.measureStartValue);
    progress = GoalUtils.getProgressCapped(progress);

    return progress;
  };

  // Limits the progress value to between 0 and 100
  static getProgressCapped = (progressRaw: number): number => {
    if (progressRaw > 100) {
      return 100;
    }

    if (progressRaw < 0) {
      return 0;
    }

    return progressRaw;
  };

  static getProgressOverflow = (progressRaw: number, reversed: boolean, overflowValue: number): number => {
    if (overflowValue === 0) {
      return 0;
    }

    if (progressRaw >= 100) {
      return progressRaw - 100;
    }

    return progressRaw;
  };

  static getProgressOverflowValue = (reversed: boolean, goalValue: number, currentValue: number, startValue: number): number => {
    const goal = Number(goalValue);
    const current = Number(currentValue);
    const start = Number(startValue);

    if (reversed) {
      if (current > start) {
        return ((start - current) / (start - goal)) * 100;
      }

      if (current < goal) {
        return ((goal - current) / (start - goal)) * 100;
      }

      return 0;
    } else {
      if (current < start) {
        return ((current - start) / (goal - start)) * 100;
      }

      if (current > goal) {
        return ((current - goal) / (goal - start)) * 100;
      }

      return 0;
    }
  };

  static getRoundProgress(progress: number): RoundProgress {
    let left = 0;
    let right = 0;
    if (progress > 0) {
      if (progress <= 50) {
        right = (progress / 100 * 360);
      } else {
        right = 180;
        left = ((progress - 50) / 100 * 360);
      }
    }
    return {
      left: `rotate(${left}deg)`,
      right: `rotate(${right}deg)`
    };
  }

  // TODO: store these on the key result so we don't have to do function calls in the template
  static getProgressData = (reversed: boolean, goalValue: number, currentValue: number, startValue: number): ProgressValue => {
    const progressRaw = GoalUtils.getProgressRaw(goalValue, currentValue, startValue);
    const progressCapped = GoalUtils.getProgressCapped(progressRaw);
    const overflowValue = GoalUtils.getProgressOverflowValue(reversed, goalValue, currentValue, startValue);
    const progressOverflow = GoalUtils.getProgressOverflow(progressRaw, reversed, overflowValue);

    const output = {
      valueRaw: progressRaw,
      valueCapped: progressCapped,
      valueCappedRounded: progressCapped.toFixed(2),
      overflowValue: overflowValue,
      overflowRaw: progressOverflow,
      overflowRounded: progressOverflow.toFixed(2)
    };

    return output;
  };

  public static getMessageCodeForGoalStatus(goalStatus: GoalStatus): string {
    switch (goalStatus) {
      case GoalStatus.OFF_TRACK:
        return GoalsMessages.OFF_TRACK;
      case GoalStatus.PROGRESSING:
        return GoalsMessages.PROGRESSING;
      case GoalStatus.ON_TRACK:
        return GoalsMessages.ON_TRACK;
    }

    return CommonMessages.UNKNOWN;
  }

  public static getMessageCodeForGoalType(goalType: GoalType | null, terminologyCollection: TerminologyCollection): string {
    switch (goalType) {
      case GoalType.COMPANY:
        return CommonMessages.COMPANY;
      case GoalType.DEPARTMENT:
        return terminologyCollection[TerminologyEntity.DEPARTMENT];
      case GoalType.OFFICE_LOCATION:
        return terminologyCollection[TerminologyEntity.SITE];
      case GoalType.PERSONAL_DEVELOPMENTAL:
        return GoalsMessages.PERSONAL_DEVELOPMENTAL;
      case GoalType.PERSONAL_OPERATIONAL:
        return GoalsMessages.PERSONAL_OPERATIONAL;
      case GoalType.TEAM:
        return terminologyCollection[TerminologyEntity.TEAM];
      case null:
        return 'None';
    }

    return CommonMessages.UNKNOWN;
  }

  public static getMessageCodeForGoalPriority(goalPriority: GoalPriority | null): string {
    switch(goalPriority) {
      case GoalPriority.NOT_SET:
        return 'X';
      case GoalPriority.P1:
        return GoalsMessages.PRIORITY_P1;
      case GoalPriority.P2:
        return GoalsMessages.PRIORITY_P2;
      case GoalPriority.P3:
        return GoalsMessages.PRIORITY_P3;
      case GoalPriority.P4:
        return GoalsMessages.PRIORITY_P4;
      case GoalPriority.P5:
        return GoalsMessages.PRIORITY_P5;
    }

    return CommonMessages.UNKNOWN;
  }

  public static getMessageCodeForGoalVisibility(goalVisibility: GoalVisibility): string {
    switch (goalVisibility) {
      case GoalVisibility.PUBLIC:
        return GoalsMessages.PUBLIC;
      case GoalVisibility.PRIVATE:
        return GoalsMessages.PRIVATE;
    }

    return CommonMessages.UNKNOWN;
  }

  public static getLongMessageCodeForGoalPriority(goalPriority: GoalPriority | null): string {
    switch(goalPriority) {
      case GoalPriority.NOT_SET:
        return CommonMessages.NOT_SET;
      case GoalPriority.P1:
        return GoalsMessages.PRIORITY_P1_TEXT;
      case GoalPriority.P2:
        return GoalsMessages.PRIORITY_P2_TEXT;
      case GoalPriority.P3:
        return GoalsMessages.PRIORITY_P3_TEXT;
      case GoalPriority.P4:
        return GoalsMessages.PRIORITY_P4_TEXT;
      case GoalPriority.P5:
        return GoalsMessages.PRIORITY_P5_TEXT;
    }

    return CommonMessages.UNKNOWN;
  }

  public static getIconClassForGoalType(goalType: GoalType | null): string {
    switch (goalType) {
      case GoalType.COMPANY:
        return 'fa-building';
      case GoalType.DEPARTMENT:
        return 'fa-users';
      case GoalType.OFFICE_LOCATION:
        return 'fa-business-time';
      case GoalType.PERSONAL_DEVELOPMENTAL:
        return 'fa-graduation-cap';
      case GoalType.PERSONAL_OPERATIONAL:
        return 'fa-user';
      case GoalType.TEAM:
        return 'fa-user-friends';
      case null:
        return 'fa-times';
    }
    
    return 'fa-question-circle';
  }

  public static getIconClassForGoalVisibility(goalVisibility: GoalVisibility): string {
    switch (goalVisibility) {
      case GoalVisibility.PUBLIC:
      // return 'fa-eye';
        return 'fa-unlock';
      case GoalVisibility.PRIVATE:
      // return 'fa-eye-slash';
        return 'fa-lock';
    }
    
    return 'fa-question-circle';
  }

  public static getIconClassForGoalDistribution(goalDistribution: GoalDistribution): string {
    switch (goalDistribution) {
      case GoalDistribution.NONE:
        return 'fa-times';
      case GoalDistribution.OWNERS:
        return 'fa-users';
      case GoalDistribution.COMPANY:
        return 'fa-building';
    }
    
    return 'fa-question-circle';
  }

  public static getIconClassForGoalPriority(goalPriority: GoalPriority | null): string {
    switch (goalPriority) {
      case GoalPriority.NOT_SET:
        return 'fa-times';
      case GoalPriority.P1:
        return 'fa-chevron-double-up';
      case GoalPriority.P2:
        return 'fa-chevron-up';
      case GoalPriority.P3:
        return 'fa-horizontal-rule';
      case GoalPriority.P4:
        return 'fa-chevron-down';
      case GoalPriority.P5:
        return 'fa-chevron-double-down';
    }
    
    return 'fa-question-circle';
  }

  public static getStatusTitle(goal: Goal): string {
    if (goal.archived) {
      return GoalsMessages.ARCHIVED;
    }

    if (goal.complete === true) {
      return GoalsMessages.COMPLETE;
    }

    switch(goal.status) {
      case GoalStatus.OFF_TRACK:
        return GoalsMessages.OFF_TRACK;
      case GoalStatus.ON_TRACK:
        return GoalsMessages.ON_TRACK;
      case GoalStatus.PROGRESSING:
        return GoalsMessages.PROGRESSING;
      default:
        return CommonMessages.UNKNOWN;
    }

    return CommonMessages.UNKNOWN;
  }

  public static getStatusHighlight(goal: Goal): string {
    // Archived
    if (goal.archived) {
      return 'goal-status-highlight-archived';
    }

    // Complete
    if (goal.complete === true) {
      return 'goal-status-highlight-complete';
    }

    switch (goal.status) {
      case GoalStatus.OFF_TRACK:
        return 'goal-status-highlight-off-track';
      case GoalStatus.PROGRESSING:
        return 'goal-status-highlight-progressing';
      case GoalStatus.ON_TRACK:
        return 'goal-status-highlight-on-track';
    }

    return '';
  }

  public static getDefaultVisibilityForGoalType(goalType: GoalType | null): GoalVisibility {
    switch (goalType) {
      case GoalType.PERSONAL_DEVELOPMENTAL:
      case GoalType.PERSONAL_OPERATIONAL:
        return GoalVisibility.PRIVATE;
      default:
        return GoalVisibility.PUBLIC;
    }
  }

  public static getAllowedAlignmentTypesForGoalType(goalType: GoalType | null): GoalType[] {
    switch (goalType) {
      case GoalType.COMPANY:
        return [GoalType.COMPANY];
      case GoalType.DEPARTMENT:
      case GoalType.OFFICE_LOCATION:
      case GoalType.TEAM:
        return [GoalType.COMPANY, GoalType.DEPARTMENT, GoalType.OFFICE_LOCATION];
      case GoalType.PERSONAL_DEVELOPMENTAL:
      case GoalType.PERSONAL_OPERATIONAL:
        return [GoalType.COMPANY, GoalType.DEPARTMENT, GoalType.OFFICE_LOCATION, GoalType.TEAM];
      default:
        return [];
    }
  }
}
