import { ISODateString } from '@app/shared/utils/date-string.util';
import { PollQuestion } from 'app/models/poll/poll-question.model';
import moment from 'moment';
import { PollResponse, PollResponseServerSide } from './poll-response.model';

export interface PollServerSide {
  id: number;
  publishedDate: ISODateString;
  expirationDate: ISODateString;
  expired: boolean;
  completed: boolean;
  recipients: Array<number>;
  questions: Array<PollQuestion>;
  responses: Array<PollResponseServerSide>;
}
export class Poll {
  id: number;
  publishedDate: Date;
  expirationDate: Date;
  expired: boolean;
  completed: boolean;
  recipients: Array<number>;
  questions: Array<PollQuestion>;
  responses: Array<PollResponse>;

  constructor(pollServerSide: PollServerSide) {
    this.id = pollServerSide.id;
    this.publishedDate = moment.utc(pollServerSide.publishedDate).toDate();
    this.expirationDate = moment.utc(pollServerSide.expirationDate).toDate();
    this.expired = pollServerSide.expired;
    this.completed = pollServerSide.completed;
    this.recipients = pollServerSide.recipients;
    this.questions = pollServerSide.questions;
    this.responses = pollServerSide.responses.map(pollResponseServerSide => new PollResponse(pollResponseServerSide));
  }
}