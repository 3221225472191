import { ISODateString } from '@app/shared/utils/date-string.util';
import moment from 'moment';

export interface CompanyTrialServerSide {
  startDate: ISODateString;
  endDate: ISODateString;
}

export class CompanyTrial {
  startDate: Date;
  endDate: Date;

  constructor(companyTrialServerSide: CompanyTrialServerSide){
    this.startDate = moment.utc(companyTrialServerSide.startDate).toDate();
    this.endDate = moment.utc(companyTrialServerSide.endDate).toDate();
  }
}
