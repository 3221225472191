import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonMessages } from '@app/constants/common.messages';
import { IconHoverColor } from '@app/shared/components/inputs/table-action-icon/table-action-icon.component';
import { PillType } from '@app/shared/components/pill/pill.component';
import { QualificationMessages } from '../../locale/qualification.messages';
import { Qualification } from '../../model/qualification.model';

@Component({
  selector: 'app-qualification-display',
  templateUrl: './qualification-display.component.html',
  styleUrls: ['./qualification-display.component.scss']
})
export class QualificationDisplayComponent {

  public readonly eQualificationMessages = QualificationMessages;
  public readonly eIconHoverColor = IconHoverColor;
  public readonly eCommonMessages = CommonMessages;
  public readonly ePillType = PillType;

  @Input() qualification: Qualification;
  @Input() actionsShown: string[]; // valid options are 'delete' and 'edit'

  @Output() onClickAction: EventEmitter<string>;

  get isExpired(): boolean {
    if (!this.qualification) { return false; }
    if (!this.qualification.expirationDate) { return false; }
    return new Date(this.qualification.expirationDate) < new Date();
  }

  constructor() {
    this.onClickAction = new EventEmitter<string>();
    this.actionsShown = [];
  }

  clickAction(action: string): void {
    this.onClickAction.emit(action);
  }
}
