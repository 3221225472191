<div class="goal-display" [class.is-selected]="selected" [class.can-select]="canSelect" *ngIf="goal" (click)="selectGoal()">
    <span class="goal-status" [ngClass]="status" [title]="status | underscoreToSpace | titlecase"></span>

    <div class="row m-left-0 m-right-0">
        <div class="col-data">
            <div class="top-row">
                <span class="title">{{goal.title}}</span>
                <span class="goal-end-date">
                <app-pill [type]="ePillType.YELLOW" [text]="goal.endDate | momentPipe: DATE_FORMAT"></app-pill></span>
            </div>
        </div>

        <div class="col-checkbox" *ngIf="canSelect">
            <div class="check-container">
                <span class="fal fa-fw fa-check" *ngIf="!selected"></span>
                <span class="fal fa-fw fa-times" *ngIf="selected"></span>
            </div>
        </div>
    </div>
</div>
