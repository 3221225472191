<ng-container *ngIf="user && qualifications.length > 0">
  <div class="container-qualification-sidebar-display">
    <div class="card no-margin">
      <div class="display-user-details">
        <app-profile-photo [src]="user.imageUrl" [firstName]="user.firstName" [lastName]="user.lastName" [width]="'40px'"></app-profile-photo>
      </div>
      <div class="qualifications-list">
        <ng-container *ngFor="let qualification of qualifications; index as index;">
          <div class="qualification-item">
            <app-qualification-display [qualification]="qualification"></app-qualification-display>
          </div>
        </ng-container>
      </div>
    </div>
    </div>
</ng-container>