import { Component, Input, OnChanges, SimpleChanges, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-rich-display',
  templateUrl: './rich-display.component.html',
  styleUrls: ['./rich-display.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class RichDisplayComponent implements OnChanges {
  @Input() text: string;
  visible: boolean;
  constructor() {
    this.text = '';
    this.visible = true;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.text && changes.currentValue !== changes.previousValue) {
      this.reloadUI();
    }
  }

  reloadUI(): void {
    this.visible = false;
    setTimeout(() => {
      this.visible = true;
    }, 1);
  }

}
