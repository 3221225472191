import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

export interface ButtonGroupOption {
  value: any;
  friendlyText: string;
  iconClasses?: string;
  color?: string;
  disabled?: () => boolean;
  tooltip?: string;
}

type ButtonGroupStyle = ('light' | 'dark');

@Component({
  selector: 'app-button-group',
  templateUrl: './button-group.component.html',
  styleUrls: ['./button-group.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => ButtonGroupComponent),
    multi: true,
  }]
})
export class ButtonGroupComponent implements OnInit, ControlValueAccessor {
  @Input() options: ButtonGroupOption[];
  @Input() disabled: boolean;
  @Input() style: ButtonGroupStyle;
  @Input() consistentWidths: boolean;

  onChange = (_: any) => {};
  onTouched = () => {};

  _value: any;

  get value(): any {
    return this._value;
  }

  set value(val: any) {
    if (!this.disabled) {
      this._value  = val;
      this.onChange(val);
    }
  }

  constructor() {
    this.disabled = false;
    this.consistentWidths = false;
    this.options = [];
    this.style = 'light';
  }

  ngOnInit(): void {
  }

  // #region - ControlValueAccessor stuff
  writeValue(value: any) {
    this.value = value;
  }

  registerOnChange(fn: (_: any) => {}): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }
  // #endregion

  onSelectOption(option: ButtonGroupOption): void {
    if (this.value === option.value) { return; } // Prevent re-selecting already selected value
    if (option.disabled !== undefined && option.disabled()) { return; } // Prevent selecting disabled options
    this.value = option.value;
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }
}
