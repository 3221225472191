import { GoalTemplate } from '@app/models/goals/templates/goal-template.model';
import { GoalKeyResultType } from '@app/models/goals/goal-key-result-type.model';
import { mockUsers } from './mockUsers';

export const mockGoalTemplates: GoalTemplate[] = [
  {
    id: 1,
    title: 'Increase financial forecast in UK',
    keyResults: [
      {
        id: 1,
        result: 'Better reporting',
        type: GoalKeyResultType.BINARY,
        measureUnit: null,
        measureUnitPlacement: null,
        orderIndex: 0
      },
      {
        id: 2,
        result: 'Hire new CFO',
        type: GoalKeyResultType.BINARY,
        measureUnit: null,
        measureUnitPlacement: null,
        orderIndex: 1
      }
    ],
    creator: mockUsers[0],
    tags: []
  },
  {
    id: 2,
    title: 'Hire a new marketing team',
    keyResults: [
      {
        id: 3,
        result: 'Finalise job description',
        type: GoalKeyResultType.BINARY,
        measureUnit: null,
        measureUnitPlacement: null,
        orderIndex: 0
      },
    ],
    creator: mockUsers[0],
    tags: []
  },
  {
    id: 3,
    title: 'Limit down time to 30 minutes a month',
    keyResults: [
      {
        id: 4,
        result: 'Run diagnostic tests',
        type: GoalKeyResultType.BINARY,
        measureUnit: null,
        measureUnitPlacement: null,
        orderIndex: 0
      }
    ],
    creator: mockUsers[0],
    tags: []
  }
];
