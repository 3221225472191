<div class="text-picker-container" [class.text-picker-disabled]="state.disabled">
  <div class="search-input-container" [class.picker-has-value]="_value && (_value.length > 0)">
    <div class="value-preview" [class.can-select-multiple]="true || canSelectMultiple" (click)="searchInput.focus()">
      <ng-container *ngIf="_value">
        <ng-container *ngFor="let textItem of _value">
          <div class="text-item-selected" [tooltip]="canSelectMultiple ? (eCommonMessages.REMOVE | translate) : ''" [triggers]="globals.tooltipTriggers" (click)="removeSelectedItem(textItem, $event)">
            <span>{{textItem}}</span>
          </div>
        </ng-container>
      </ng-container>
      <input type="text" class="search-input" [class.has-value]="_value.length > 0" [class.search-invisible]="!state.dropdownOpen"
        [formControl]="searchControl" (keyup.enter)="trySelectFirst()" (keyup.backspace)="onKeyupBackspace()" (keyUp.escape)="onKeyupEscape()" (focus)="onFocusSearch()"
        (blur)="onBlurSearch()" [placeholder]="(_value.length === 0) ? (searchPlaceholder | translate) : ''" #searchInput/>
    </div>
    <span class="icon-input-search fal fa-fw fa-search" *ngIf="state.dropdownOpen"></span>
  </div>
</div>
