import { CompanyFeatures } from "@app/models/company-features.model";
import { InterestType } from '@app/domain/interest/model/interest-type.model';
import { CareerMessages } from "@app/domain/career/locale/career.messages";
import { CareerPathwayMessages } from "@app/domain/career_pathway/locale/career-pathway.messages";
import { QuestionTemplateMessages } from "@app/domain/question_template/locale/question-template.messages";
import { RoleApplicationMessages } from '@app/domain/role_application/locale/role-application.messages';
import { SentimentScaleMessages } from '@app/domain/sentiment_scale/locale/sentiment-scale.messages';
import { OneToOneMessages } from '@app/domain/one_to_one/locale/one-to-one.messages';
import { TerminologyEntity } from '@app/domain/terminology/model/terminology-entity.enum';

export class AdminMessages {
  public static readonly ADMIN_DASHBOARD = 'admin.admin-dashboard';
  public static readonly OTHER_SETTINGS = 'admin.other-settings';

  public static readonly SITES = {
    MANAGE_SITES: 'admin.sites.manage-sites',
    MANAGE_SITES_DESCRIPTION: 'admin.sites.manage-sites-description',
    MANAGE_SITES_DESCRIPTION_SHORT: 'admin.sites.manage-sites-description-short',
    MANAGE_SITES_BULK_ACTIONS_DESCRIPTION: 'admin.sites.manage-sites-bulk-actions-description',
    SITE_NAME: 'admin.sites.site-name',
    STREET: 'admin.sites.street',
    CITY: 'admin.sites.city',
    COUNTRY: 'admin.sites.country',
    POSTAL_CODE: 'admin.sites.postal-code',
    TIME_ZONE: 'admin.sites.time-zone',
    ADDRESS: 'admin.sites.address',
    COPY_SITE: 'admin.sites.copy-site',
    EDIT_SITE: 'admin.sites.edit-site',
    DELETE_SITE: 'admin.sites.delete-site',
    CONFIRMATION_DELETE_SITE_SINGLE: 'admin.sites.confirmation-delete-site-single',
    CONFIRMATION_DELETE_SITE_MULTIPLE: 'admin.sites.confirmation-delete-site-multiple',
    SUCCESS_DELETE_SITE_SINGLE: 'admin.sites.success-delete-site-single',
    SUCCESS_DELETE_SITE_MULTIPLE: 'admin.sites.success-delete-site-multiple',
    HEADER_CREATING: 'admin.sites.header-creating',
    HEADER_EDITING: 'admin.sites.header-editing',
    COUNTRY_INVALID: 'admin.sites.country-invalid',
    TIME_ZONE_INVALID: 'admin.sites.time-zone-invalid',
    SUCCESS_SITE_CREATE: 'admin.sites.success-site-create',
    SUCCESS_SITE_UPDATE: 'admin.sites.success-site-update',
    MANAGE_EMPTY_STATE: 'admin.sites.manage-empty-state',
    ASSIGNED_PEOPLE: 'admin.sites.assigned-people'
  };

  public static readonly DEPARTMENTS = {
    MANAGE_DEPARTMENTS: 'admin.departments.manage-departments',
    MANAGE_DEPARTMENTS_DESCRIPTION: 'admin.departments.manage-departments-description',
    MANAGE_DEPARTMENTS_DESCRIPTION_SHORT: 'admin.departments.manage-departments-description-short',
    MANAGE_DEPARTMENTS_BULK_ACTIONS_DESCRIPTION: 'admin.departments.manage-departments-bulk-actions-description',
    DEPARTMENT_NAME: 'admin.departments.department-name',
    LEVEL: 'admin.departments.level',
    PARENT_DEPARTMENT: 'admin.departments.parent-department',
    DEPARTMENT_TYPE: 'admin.departments.department-type',
    DEPARTMENT_LEAD: 'admin.departments.department-lead',
    FEEDBACK_RECIPIENT: 'admin.departments.feedback-recipient',
    COPY_DEPARTMENT: 'admin.departments.copy-department',
    EDIT_DEPARTMENT: 'admin.departments.edit-department',
    DELETE_DEPARTMENT: 'admin.departments.delete-department',
    CONFIRMATION_DELETE_DEPARTMENT_SINGLE: 'admin.departments.confirmation-delete-department-single',
    CONFIRMATION_DELETE_DEPARTMENT_MULTIPLE: 'admin.departments.confirmation-delete-department-multiple',
    SUCCESS_DELETE_DEPARTMENT_SINGLE: 'admin.departments.success-delete-department-single',
    SUCCESS_DELETE_DEPARTMENT_MULTIPLE: 'admin.departments.success-delete-department-multiple',
    HEADER_CREATING: 'admin.departments.header-creating',
    HEADER_EDITING: 'admin.departments.header-editing',
    SUCCESS_DEPARTMENT_CREATE: 'admin.departments.success-department-create',
    SUCCESS_DEPARTMENT_UPDATE: 'admin.departments.success-department-update',
    MANAGE_EMPTY_STATE: 'admin.departments.manage-empty-state',
    ASSIGNED_PEOPLE: 'admin.departments.assigned-people'
  };

  public static readonly COMPANY_VIEW = {
    MANAGE_PEOPLE: 'admin.company-view.manage-people',
    MANAGE_PEOPLE_DESCRIPTION: 'admin.company-view.manage-people-description',
    MANAGE_PEOPLE_DESCRIPTION_SHORT: 'admin.company-view.manage-people-description-short',
    MANAGE_PEOPLE_BULK_ACTIONS_DESCRIPTION: 'admin.company-view.manage-people-bulk-actions-description',
    NEW_PERSON: 'admin.company-view.new-person',
    ADD_PEOPLE_FROM_FILE: 'admin.company-view.add-people-from-file',
    UPDATE_PEOPLE_FROM_FILE: 'admin.company-view.update-people-from-file',
    ARCHIVE_PEOPLE_FROM_FILE: 'admin.company-view.archive-people-from-file',
    EXPORT_TO_CSV: 'admin.company-view.export-to-csv',
    EXPORT_TO_XLSX: 'admin.company-view.export-to-xlsx',
    MANAGE_EMPTY_STATE: 'admin.company-view.manage-empty-state',
    MANAGE_EMPTY_STATE_SEARCH: 'admin.company-view.manage-empty-state-search',
    NAME: 'admin.company-view.name',
    ROLE: 'admin.company-view.role',
    DEPARTMENT: 'admin.company-view.department',
    SITE: 'admin.company-view.site',
    ACCESS_LEVEL: 'admin.company-view.access-level',
    START_DATE: 'admin.company-view.start-date',
    PENDING_INVITATIONS_HEADING: 'admin.company-view.pending-invitations-heading',
    PENDING_INVITATIONS_DESCRIPTION: 'admin.company-view.pending-invitations-description',
    PENDING_INVITATIONS_DECLINE: 'admin.company-view.pending-invitations-decline',
    PENDING_INVITATIONS_CONFIRM: 'admin.company-view.pending-invitations-confirm',
    BULK_MUST_SELECT_ONE: 'admin.company-view.bulk-must-select-one',
    MANAGED_BY_FRANKLI: 'admin.company-view.managed-by-frankli',
    MANAGED_BY_ACTIVE_DIRECTORY: 'admin.company-view.managed-by-active-directory',
    MANAGED_BY_PEOPLE_HR: 'admin.company-view.managed-by-people-hr',
    MANAGED_BY_TRI_NET: 'admin.company-view.managed-by-tri-net',
    MANAGED_BY_SAGE_HR: 'admin.company-view.managed-by-sage-hr',
    MANAGED_BY_MERGE_DEV: 'admin.company-view.managed-by-merge-dev',
    MANAGER: 'admin.company-view.manager',
    STATE: 'admin.company-view.state',
    MANAGED_BY: 'admin.company-view.managed-by',
    TYPE_CONFIRM_PROMPT: 'admin.company-view.type-confirm-prompt',
    BULK_INVITE: {
      HEADING: 'admin.company-view.bulk-invite.heading',
      PREVIEW_DESCRIPTION: 'admin.company-view.bulk-invite.preview-description',
      CONFIRM_DESCRIPTION: 'admin.company-view.bulk-invite.confirm-description',
      NONE_TO_INVITE: 'admin.company-view.bulk-invite.none-to-invite',
      USER_WILL_BE_INVITED: 'admin.company-view.bulk-invite.user-will-be-invited',
      USER_WILL_BE_INVITED_AGAIN: 'admin.company-view.bulk-invite.user-will-be-invited-again',
      USER_ALREADY_INVITED: 'admin.company-view.bulk-invite.user-already-invited',
      SUCCESS_SELECTED_INVITED: 'admin.company-view.bulk-invite.success-selected-invited'
    },
    BULK_ARCHIVE: {
      HEADING: 'admin.company-view.bulk-archive.heading',
      PREVIEW_DESCRIPTION: 'admin.company-view.bulk-archive.preview-description',
      CONFIRM_DESCRIPTION: 'admin.company-view.bulk-archive.confirm-description',
      NONE_TO_ARCHIVE: 'admin.company-view.bulk-archive.none-to-archive',
      SUCCESS_MESSAGE: 'admin.company-view.bulk-archive.success-message',
      USER_WILL_BE_ARCHIVED: 'admin.company-view.bulk-archive.user-will-be-archived',
      USER_ALREADY_ARCHIVED: 'admin.company-view.bulk-archive.user-already-archived',
      CANNOT_ARCHIVE_ACTIVE_DIRECTORY: 'admin.company-view.bulk-archive.cannot-archive-active-directory',
      CANNOT_ARCHIVE_PEOPLE_HR: 'admin.company-view.bulk-archive.cannot-archive-people-hr',
      CANNOT_ARCHIVE_MANAGING_PEOPLE: 'admin.company-view.bulk-archive.cannot-archive-managing-people',
      CANNOT_ARCHIVE_TRI_NET: 'admin.company-view.bulk-archive.cannot-archive-tri-net',
      CANNOT_ARCHIVE_SAGE_HR: 'admin.company-view.bulk-archive.cannot-archive-sage-hr',
    },
    BULK_EDIT: {
      HEADING: 'admin.company-view.bulk-edit.heading',
      EDIT_DESCRIPTION: 'admin.company-view.bulk-edit.edit-description',
      PREVIEW_DESCRIPTION: 'admin.company-view.bulk-edit.preview-description',
      CONFIRM_DESCRIPTION: 'admin.company-view.bulk-edit.confirm-description',
      PROCESSING_DESCRIPTION: 'admin.company-view.bulk-edit.processing-description',
      FIELDS_DISABLED_HRIS: 'admin.company-view.bulk-edit.fields-disabled-hris',
      WORKFLOW_DISABLED_HRIS: 'admin.company-view.bulk-edit.workflow-disabled-hris',
      PLACEHOLDER_MANAGER_SEARCH: 'admin.company-view.bulk-edit.placeholder-manager-search',
      MANAGER_DISABLED_PEOPLE_IN_REVIEWS: 'admin.company-view.bulk-edit.manager-disabled-people-in-reviews',
      NO_MANAGER: 'admin.company-view.bulk-edit.no-manager',
      NO_SITE: 'admin.company-view.bulk-edit.no-site',
      NO_DEPARTMENT: 'admin.company-view.bulk-edit.no-department',
      NO_ROLE: 'admin.company-view.bulk-edit.no-role',
      NO_START_DATE: 'admin.company-view.bulk-edit.no-start-date',
      WARNING_ACCESS_LEVEL_MIN: 'admin.company-view.bulk-edit.warning-access-level-min',
      PREVIEW_EMPTY_STATE: 'admin.company-view.bulk-edit.preview-empty-state',
      PROCESSING: 'admin.company-view.bulk-edit.processing',
      FAILED: 'admin.company-view.bulk-edit.failed',
      COMPLETED: 'admin.company-view.bulk-edit.completed',
      TOTAL: 'admin.company-view.bulk-edit.total',
      WARNING_MANAGER_CIRCULAR_DEPENDENCY: 'admin.company-view.bulk-edit.warning-manager-circular-dependency',
      WARNING_ERRORS_PROCESSING: 'admin.company-view.bulk-edit.warning-errors-processing',
      SUCCESS_EDITS_PROCESSED: 'admin.company-view.bulk-edit.success-edits-processed',
      SUCCESS_EDITS_PROCESSED_ARCHIVING: 'admin.company-view.bulk-edit.success-edits-processed-archiving'
    },
    CHANGE_MANAGERS: {
      HEADING: 'admin.company-view.change-managers.heading',
      PREVIEW_DESCRIPTION: 'admin.company-view.change-managers.preview-description',
      CONFIRM_DESCRIPTION: 'admin.company-view.change-managers.confirm-description',
      SUCCESS_MESSAGE: 'admin.company-view.change-managers.success-message',
      WARNING_CIRCULAR_DEPENDENCY: 'admin.company-view.change-managers.warning-manager-circular-dependency',
      WARNING_MANAGER_BEING_ARCHIVED: 'admin.company-view.change-managers.warning-manager-being-archived',
      MANAGED_BY_USER: 'admin.company-view.change-managers.managed-by-user' // name
    },
    WARNING_REVOKE_ACCESS: 'admin.company-view.warning-revoke-access',
    CONFIRM_REVOKE_ACCESS: 'admin.company-view.confirm-revoke-access',
    SUCCESS_REVOKE_ACCESS: 'admin.company-view.success-revoke-access',
    WARNING_INVITE_SINGLE: 'admin.company-view.warning-invite-single',
    CONFIRM_INVITE_SINGLE: 'admin.company-view.confirm-invite-single',
    SUCCESS_INVITE_SINGLE: 'admin.company-view.success-invite-single',
    WARNING_INVITE_EVERYONE: 'admin.company-view.warning-invite-everyone',
    CONFIRM_INVITE_EVERYONE: 'admin.company-view.confirm-invite-everyone',
    SUCCESS_INVITE_EVERYONE: 'admin.company-view.success-invite-everyone',
    WARNING_UNARCHIVE_SINGLE: 'admin.company-view.warning-unarchive-single',
    SUCCESS_UNARCHIVE_SINGLE: 'admin.company-view.success-unarchive-single',
    SUCCESS_ARCHIVE_SINGLE: 'admin.company-view.success-archive-single',
    WARNING_REVOKE_ACCESS_SINGLE: 'admin.company-view.warning-revoke-access-single',
    SUCCESS_REVOKE_ACCESS_SINGLE: 'admin.company-view.success-revoke-access-single',
    WARNING_RESTORE_ACCESS_SINGLE: 'admin.company-view.warning-restore-access-single',
    SUCCESS_RESTORE_ACCESS_SINGLE: 'admin.company-view.success-restore-access-single',
    INVITE_LATER: 'admin.company-view.invite-later',
    SEND_INVITE: 'admin.company-view.send-invite',
    RESEND_INVITE: 'admin.company-view.resend-invite',
    EDIT_DETAILS: 'admin.company-view.edit-details',
    VIEW_GOALS: 'admin.company-view.view-goals',
    VIEW_SCHEDULES: 'admin.company-view.view-schedules',
    ARCHIVE_USER: 'admin.company-view.archive-user',
    UNARCHIVE_USER: 'admin.company-view.unarchive-user',
    REVOKE_ACCESS: 'admin.company-view.revoke-access',
    RESTORE_ACCESS: 'admin.company-view.restore-access',
    WARNING_ARCHIVE_USER: 'admin.company-view.warning-archive-user',
    WARNING_ARCHIVE_REPORTS: 'admin.company-view.warning-archive-reports',
    WARNING_ARCHIVE_HRIS: 'admin.company-view.warning-archive-hris',
    WARNING_UNARCHIVE_HRIS: 'admin.company-view.warning-unarchive-hris',
    PROMPT_ARCHIVE_EDIT_REPORTS: 'admin.company-view.prompt-archive-edit-reports',
    SELECT_AND_EDIT: 'admin.company-view.select-and-edit'
  };

  public static readonly MANAGE_FEATURES = {
    MANAGE_FEATURES: 'admin.manage-features.manage-features',
    MANAGE_FEATURES_DESCRIPTION: 'admin.manage-features.manage-features-description',
    MANAGE_FEATURES_DESCRIPTION_SHORT: 'admin.manage-features.manage-features-description-short',
    MANAGE_FEATURES_SAVED: 'admin.manage-features.manage-features-saved',
    UNSAVED_CHANGES_DESCRIPTION: 'admin.manage-features.unsaved-changes-description',
    FEATURE_NAMES: {
      [CompanyFeatures.ANALYTICS]: 'admin.manage-features.feature-names.analytics',
      [CompanyFeatures.CONNECT]: 'admin.manage-features.feature-names.connect',
      [CompanyFeatures.CONNECT_COACHING]: 'admin.manage-features.feature-names.connect-coaching',
      [CompanyFeatures.CONNECT_MENTORING]: 'admin.manage-features.feature-names.connect-mentoring',
      [CompanyFeatures.CONNECT_SOCIALISE]: 'admin.manage-features.feature-names.connect-socialise',
      [CompanyFeatures.FEEDBACK]: 'admin.manage-features.feature-names.feedback',
      [CompanyFeatures.FEEDBACK_IDEAS]: 'admin.manage-features.feature-names.feedback-ideas',
      [CompanyFeatures.FEEDBACK_PRAISE]: 'admin.manage-features.feature-names.feedback-praise',
      [CompanyFeatures.FEEDBACK_UNPROMPTED]: 'admin.manage-features.feature-names.feedback-unprompted',
      [CompanyFeatures.FEEDBACK_PEER]: 'admin.manage-features.feature-names.feedback-peer',
      [CompanyFeatures.PEER_FEEDBACK_MANAGER_ACCESS]: 'admin.manage-features.feature-names.feedback-peer-manager-access',
      [CompanyFeatures.FEEDBACK_REQUESTS]: 'admin.manage-features.feature-names.feedback-requests',
      [CompanyFeatures.FEEDBACK_EXTERNAL_REQUESTS]: 'admin.manage-features.feature-names.feedback-requests-external',
      [CompanyFeatures.FEEDBACK_BANK]: 'admin.manage-features.feature-names.feedback-bank',
      [CompanyFeatures.EVALUATION_CYCLES]: 'admin.manage-features.feature-names.review-cycles',
      [CompanyFeatures.PEER_NOMINATIONS]: 'admin.manage-features.feature-names.peer-nominations',
      [CompanyFeatures.GOALS]: 'admin.manage-features.feature-names.goals',
      [CompanyFeatures.GOAL_PRIORITY]: 'admin.manage-features.feature-names.goal-priority',
      [CompanyFeatures.GOAL_TEMPLATE]: 'admin.manage-features.feature-names.goal-templates',
      [CompanyFeatures.GOAL_OFFICE_LOCATION]: 'admin.manage-features.feature-names.goal-type-site',
      [CompanyFeatures.ORG_CHART]: 'admin.manage-features.feature-names.org-chart',
      [CompanyFeatures.ORG_CHART_GOALS_INFO]: 'admin.manage-features.feature-names.org-chart-goals-info',
      [CompanyFeatures.HELP]: 'admin.manage-features.feature-names.help',
      [CompanyFeatures.PEOPLE_DIRECTORY]: 'admin.manage-features.feature-names.people-directory',
      [CompanyFeatures.SURVEYS]: 'admin.manage-features.feature-names.surveys',
      [CompanyFeatures.ENPS]: 'admin.manage-features.feature-names.surveys-enps',
      [CompanyFeatures.ONE_TO_ONE]: OneToOneMessages.MEETINGS,
      [CompanyFeatures.ONE_TO_ONE_SENTIMENT_SCALE]: SentimentScaleMessages.MEETING_SENTIMENT_SCALE,
      [CompanyFeatures.COMPETENCIES]: 'admin.manage-features.feature-names.competencies',
      [CompanyFeatures.GRADES]: 'admin.manage-features.feature-names.grades',
      [CompanyFeatures.TODOS]: 'admin.manage-features.feature-names.tasks',
      [CompanyFeatures.SECONDARY_MANAGER]: 'admin.manage-features.feature-names.secondary-manager',
      [CompanyFeatures.SKILLS]: 'admin.manage-features.feature-names.skills',
      [CompanyFeatures.QUALIFICATIONS]: 'admin.manage-features.feature-names.qualifications',
      [CompanyFeatures.CAREERS]: CareerMessages.CAREERS,
      [CompanyFeatures.CAREER_PATHWAYS]: CareerPathwayMessages.CAREER_PATHWAYS,
      [CompanyFeatures.QUESTION_TEMPLATES]: QuestionTemplateMessages.QUESTION_TEMPLATES,
      [CompanyFeatures.ROLE_APPLICATIONS]: RoleApplicationMessages.ROLE_APPLICATIONS,
      [CompanyFeatures.CUSTOM_CAREER_PATHWAYS]: CareerPathwayMessages.CUSTOM_CAREER_PATHWAYS,
      [CompanyFeatures.COMPANY_DASHBOARD_CARD]: 'admin.manage-features.feature-names.company-dashboard-card',
      [CompanyFeatures.SKILL_RATING]: 'admin.manage-features.feature-names.skill-rating',
    },
    FEATURE_DESCRIPTIONS: {
      [CompanyFeatures.ANALYTICS]: 'admin.manage-features.feature-descriptions.analytics',
      [CompanyFeatures.CONNECT]: 'admin.manage-features.feature-descriptions.connect',
      [CompanyFeatures.CONNECT_COACHING]: 'admin.manage-features.feature-descriptions.connect-coaching',
      [CompanyFeatures.CONNECT_MENTORING]: 'admin.manage-features.feature-descriptions.connect-mentoring',
      [CompanyFeatures.CONNECT_SOCIALISE]: 'admin.manage-features.feature-descriptions.connect-socialise',
      [CompanyFeatures.FEEDBACK]: 'admin.manage-features.feature-descriptions.feedback',
      [CompanyFeatures.FEEDBACK_IDEAS]: 'admin.manage-features.feature-descriptions.feedback-ideas',
      [CompanyFeatures.FEEDBACK_PRAISE]: 'admin.manage-features.feature-descriptions.feedback-praise',
      [CompanyFeatures.FEEDBACK_UNPROMPTED]: 'admin.manage-features.feature-descriptions.feedback-unprompted',
      [CompanyFeatures.FEEDBACK_PEER]: 'admin.manage-features.feature-descriptions.feedback-peer',
      [CompanyFeatures.PEER_FEEDBACK_MANAGER_ACCESS]: 'admin.manage-features.feature-descriptions.feedback-peer-manager-access',
      [CompanyFeatures.FEEDBACK_REQUESTS]: 'admin.manage-features.feature-descriptions.feedback-requests',
      [CompanyFeatures.FEEDBACK_EXTERNAL_REQUESTS]: 'admin.manage-features.feature-descriptions.feedback-requests-external',
      [CompanyFeatures.FEEDBACK_BANK]: 'admin.manage-features.feature-descriptions.feedback-bank',
      [CompanyFeatures.EVALUATION_CYCLES]: 'admin.manage-features.feature-descriptions.review-cycles',
      [CompanyFeatures.PEER_NOMINATIONS]: 'admin.manage-features.feature-descriptions.peer-nominations',
      [CompanyFeatures.GOALS]: 'admin.manage-features.feature-descriptions.goals',
      [CompanyFeatures.GOAL_PRIORITY]: 'admin.manage-features.feature-descriptions.goal-priority',
      [CompanyFeatures.GOAL_TEMPLATE]: 'admin.manage-features.feature-descriptions.goal-templates',
      [CompanyFeatures.GOAL_OFFICE_LOCATION]: 'admin.manage-features.feature-descriptions.goal-type-site',
      [CompanyFeatures.ORG_CHART]: 'admin.manage-features.feature-descriptions.org-chart',
      [CompanyFeatures.ORG_CHART_GOALS_INFO]: 'admin.manage-features.feature-descriptions.org-chart-goals-info',
      [CompanyFeatures.HELP]: 'admin.manage-features.feature-descriptions.help',
      [CompanyFeatures.PEOPLE_DIRECTORY]: 'admin.manage-features.feature-descriptions.people-directory',
      [CompanyFeatures.SURVEYS]: 'admin.manage-features.feature-descriptions.surveys',
      [CompanyFeatures.ENPS]: 'admin.manage-features.feature-descriptions.surveys-enps',
      [CompanyFeatures.ONE_TO_ONE]: 'admin.manage-features.feature-descriptions.one-to-ones',
      [CompanyFeatures.ONE_TO_ONE_SENTIMENT_SCALE]: SentimentScaleMessages.MEETING_SENTIMENT_SCALE_DESCRIPTION,
      [CompanyFeatures.COMPETENCIES]: 'admin.manage-features.feature-descriptions.competencies',
      [CompanyFeatures.GRADES]: 'admin.manage-features.feature-descriptions.grades',
      [CompanyFeatures.TODOS]: 'admin.manage-features.feature-descriptions.tasks',
      [CompanyFeatures.SECONDARY_MANAGER]: 'admin.manage-features.feature-descriptions.secondary-manager',
      [CompanyFeatures.SKILLS]: 'admin.manage-features.feature-descriptions.skills',
      [CompanyFeatures.QUALIFICATIONS]: 'admin.manage-features.feature-descriptions.qualifications',
      [CompanyFeatures.CAREERS]: CareerMessages.CAREERS_DESCRIPTION,
      [CompanyFeatures.CAREER_PATHWAYS]: CareerPathwayMessages.CAREER_PATHWAYS_DESCRIPTION,
      [CompanyFeatures.QUESTION_TEMPLATES]: QuestionTemplateMessages.QUESTION_TEMPLATES_DESCRIPTION,
      [CompanyFeatures.ROLE_APPLICATIONS]: RoleApplicationMessages.ROLE_APPLICATIONS_DESCRIPTION,
      [CompanyFeatures.CUSTOM_CAREER_PATHWAYS]: CareerPathwayMessages.CUSTOM_CAREER_PATHWAYS_DESCRIPTION,
      [CompanyFeatures.COMPANY_DASHBOARD_CARD]: 'admin.manage-features.feature-descriptions.company-dashboard-card',
      [CompanyFeatures.SKILL_RATING]: 'admin.manage-features.feature-descriptions.skill-rating',
    }
  };

  public static readonly CREATE_USER = {
    OPEN_TIPS: 'admin.create-user.open-tips',
    HEADER_CREATING: 'admin.create-user.header-creating',
    HEADER_EDITING: 'admin.create-user.header-editing',
    HEADER_UNARCHIVING: 'admin.create-user.header-unarchiving',
    HEADER_RESTORING: 'admin.create-user.header-restoring',
    EMAIL: 'admin.create-user.email',
    FIRST_NAME: 'admin.create-user.first-name',
    LAST_NAME: 'admin.create-user.last-name',
    START_DATE: 'admin.create-user.start-date',
    DEPARTMENT: 'admin.create-user.department',
    MANAGER: 'admin.create-user.manager',
    ACCESS_LEVEL: 'admin.create-user.access-level',
    ROLE: 'admin.create-user.role',
    SECONDARY_MANAGER: 'admin.create-user.secondary-manager',
    SECONDARY_MANAGER_PERMISSIONS: 'admin.create-user.secondary-manager-permissions',
    SECONDARY_MANAGER_PERMISSIONS_DESCRIPTION: 'admin.create-user.secondary-manager-permissions-description',
    SUCCESS_CREATE_USER: 'admin.create-user.success-create-user',
    SUCCESS_UPDATE_USER: 'admin.create-user.success-update-user',
    WARNING_INVITE_NEW_USER: 'admin.create-user.warning-invite-new-user',
    SECONDARY_MANAGER_VALIDATION_SAME_AS_MANAGER: 'admin.create-user.secondary-manager-validation-same-as-manager',
    SECONDARY_MANAGER_SEARCH_PROMPT: 'admin.create-user.secondary-manager-search-prompt',
    RESTORING_ACCESS: 'admin.create-user.restoring-access',
    WARNING_CHANGING_MANAGER_DURING_REVIEW: 'admin.create-user.warning-changing-manager-during-review',
    WARNING_CHANGING_MANAGER_DURING_REVIEW_UPWARD: 'admin.create-user.warning-changing-manager-during-review-upward',
    WARNING_EMAIL_NOT_UNIQUE_HEADING: 'admin.create-user.warning-email-not-unique-heading',
    WARNING_EMAIL_NOT_UNIQUE_ARCHIVED: 'admin.create-user.warning-email-not-unique-archived',
    WARNING_EMAIL_NOT_UNIQUE_REVOKED: 'admin.create-user.warning-email-not-unique-revoked',
    WARNING_EMAIL_NOT_UNIQUE_REGULAR: 'admin.create-user.warning-email-not-unique-regular'
  };

  public static readonly SECONDARY_PERMISSION_DISABLED_COMPANY: 'admin.secondary-permission-disabled-company';

  public static readonly SECONDARY_PERMISSIONS = {
    MANAGE_SECONDARY_PERMISSIONS: 'admin.secondary-permissions.manage-secondary-permissions', // secondaryManager
    MANAGE_SECONDARY_PERMISSIONS_DESCRIPTION: 'admin.secondary-permissions.manage-secondary-permissions-description', // secondaryManagerPlural
    MANAGE_SECONDARY_PERMISSIONS_DESCRIPTION_SHORT: 'admin.secondary-permissions.manage-secondary-permissions-description-short', // secondaryManagerPlural
    FEEDBACK_TITLE: 'admin.secondary-permissions.feedback-title',
    FEEDBACK_DESCRIPTION: 'admin.secondary-permissions.feedback-description',
    TALKING_POINT_TEMPLATES_TITLE: 'admin.secondary-permissions.talking-point-templates-title',
    TALKING_POINT_TEMPLATES_DESCRIPTION: 'admin.secondary-permissions.talking-point-templates-description',
    GOALS_TITLE: 'admin.secondary-permissions.goals-title',
    GOALS_DESCRIPTION: 'admin.secondary-permissions.goals-description',
    REVIEWS_TITLE: 'admin.secondary-permissions.reviews-title',
    REVIEWS_DESCRIPTION: 'admin.secondary-permissions.reviews-description',
    SUCCESS_UPDATE_COMPANY_PERMISSIONS: 'admin.secondary-permissions.success-update-company-permissions'
  };

  public static readonly TAGS = {
    MANAGE_TAGS: 'admin.tags.manage-tags',
    MANAGE_TAGS_DESCRIPTION: 'admin.tags.manage-tags-description',
    MANAGE_TAGS_DESCRIPTION_SHORT: 'admin.tags.manage-tags-description-short',
    CONFIRMATION_DELETE_TAG_SINGLE: 'admin.tags.confirmation-delete-tag-single',
    CONFIRMATION_DELETE_TAG_MULTIPLE: 'admin.tags.confirmation-delete-tag-multiple',
    SUCCESS_DELETE_TAG_SINGLE: 'admin.tags.success-delete-tag-single',
    SUCCESS_DELETE_TAG_MULTIPLE: 'admin.tags.success-delete-tag-multiple',
    SUCCESS_TAG_CREATE: 'admin.tags.success-tag-create',
    SUCCESS_TAG_UPDATE: 'admin.tags.success-tag-update',
    TAG_NAME: 'admin.tags.tag-name',
    TAG_TYPE: 'admin.tags.tag-type',
    TAG_DESCRIPTION: 'admin.tags.tag-description',
    COPY_TAG: 'admin.tags.copy-tag',
    EDIT_TAG: 'admin.tags.edit-tag',
    DELETE_TAG: 'admin.tags.delete-tag',
    EMPTY_STATE: 'admin.tags.empty-state',
    MANAGE_TAGS_BULK_ACTIONS_DESCRIPTION: 'admin.tags.manage-tags-bulk-actions-description',
    CATEGORY: 'admin.tags.category',
    COMPANY_VALUE: 'admin.tags.company-value',
    CREATOR: 'admin.tags.creator',
    CATEGORY_EXPLANATION: 'admin.tags.category-explanation',
    COMPANY_VALUE_EXPLANATION: 'admin.tags.company-value-explanation'
  };

  public static readonly ROLES = {
    MANAGE_ROLES: 'admin.roles.manage-roles',
    MANAGE_ROLES_DESCRIPTION: 'admin.roles.manage-roles-description',
    MANAGE_ROLES_DESCRIPTION_SHORT: 'admin.roles.manage-roles-description-short',
    MANAGE_EMPTY_STATE: 'admin.roles.manage-empty-state',
    MANAGE_ROLES_BULK_ACTIONS_DESCRIPTION: 'admin.roles.manage-roles-bulk-actions-description',
    CONFIRMATION_DELETE_ROLE_SINGLE: 'admin.roles.confirmation-delete-role-single',
    CONFIRMATION_DELETE_ROLE_MULTIPLE: 'admin.roles.confirmation-delete-role-multiple',
    SUCCESS_DELETE_ROLE_SINGLE: 'admin.roles.success-delete-role-single',
    SUCCESS_DELETE_ROLE_MULTIPLE: 'admin.roles.success-delete-role-multiple',
    SUCCESS_ROLE_CREATE: 'admin.roles.success-role-create',
    SUCCESS_ROLE_UPDATE: 'admin.roles.success-role-update',
    ROLE_NAME: 'admin.roles.role-name',
    ROLE_DESCRIPTION: 'admin.roles.role-description',
    ROLE_GRADE: 'admin.roles.role-grade',
    ROLE_COMPETENCIES: 'admin.roles.role-competencies',
    ROLE_SKILLS: 'admin.roles.role-skills',
    COPY_ROLE: 'admin.roles.copy-role',
    EDIT_ROLE: 'admin.roles.edit-role',
    DELETE_ROLE: 'admin.roles.delete-role',
    HEADER_CREATING: 'admin.roles.header-creating',
    HEADER_EDITING: 'admin.roles.header-editing',
    ASSIGNED_PEOPLE: 'admin.roles.assigned-people',
    IMPORT_FILE_DESCRIPTION: 'admin.roles.import-file-explanation',
    SUCCESS_IMPORT_ROLES: 'admin.roles.success-import-roles'
  };

  public static readonly COMPETENCIES = {
    MANAGE_COMPETENCIES: 'admin.competencies.manage-competencies',
    MANAGE_COMPETENCIES_DESCRIPTION: 'admin.competencies.manage-competencies-description',
    MANAGE_COMPETENCIES_DESCRIPTION_SHORT: 'admin.competencies.manage-competencies-description-short',
    MANAGE_EMPTY_STATE: 'admin.competencies.manage-empty-state',
    MANAGE_COMPETENCIES_BULK_ACTIONS_DESCRIPTION: 'admin.competencies.manage-competencies-bulk-actions-description',
    CONFIRMATION_DELETE_COMPETENCY_SINGLE: 'admin.competencies.confirmation-delete-competency-single',
    CONFIRMATION_DELETE_COMPETENCY_MULTIPLE: 'admin.competencies.confirmation-delete-competency-multiple',
    SUCCESS_DELETE_COMPETENCY_SINGLE: 'admin.competencies.success-delete-competency-single',
    SUCCESS_DELETE_COMPETENCY_MULTIPLE: 'admin.competencies.success-delete-competency-multiple',
    SUCCESS_COMPETENCY_CREATE: 'admin.competencies.success-competency-create',
    SUCCESS_COMPETENCY_UPDATE: 'admin.competencies.success-competency-update',
    COMPETENCY_NAME: 'admin.competencies.competency-name',
    COMPETENCY_DESCRIPTION: 'admin.competencies.competency-description',
    COMPETENCY_CATEGORY: 'admin.competencies.competency-category',
    COPY_COMPETENCY: 'admin.competencies.copy-competency',
    EDIT_COMPETENCY: 'admin.competencies.edit-competency',
    DELETE_COMPETENCY: 'admin.competencies.delete-competency',
    HEADER_CREATING: 'admin.competencies.header-creating',
    HEADER_EDITING: 'admin.competencies.header-editing',
    SUCCESS_IMPORT_COMPETENCIES: 'admin.competencies.success-import-competencies',
    CATEGORY_WILL_BE_CREATED: 'admin.competencies.category-will-be-created'
  };

  public static readonly BULK_ACTIONS = {
    BULK_CREATE_TITLE: 'admin.bulk-actions.bulk-create-title',
    BULK_CREATE_DESCRIPTION: 'admin.bulk-actions.bulk-create-description',
    BULK_UPDATE_TITLE: 'admin.bulk-actions.bulk-update-title',
    BULK_UPDATE_DESCRIPTION: 'admin.bulk-actions.bulk-update-description',
    BULK_ARCHIVE_TITLE: 'admin.bulk-actions.bulk-archive-title',
    BULK_ARCHIVE_DESCRIPTION: 'admin.bulk-actions.bulk-archive-description',
    WARNING_DEPARTMENT_NOT_EXISTS: 'admin.bulk-actions.warning-department-not-exists',
    WARNING_DEPARTMENT_MAX_LENGTH: 'admin.bulk-actions.warning-department-max-length',
    WARNING_SITE_NOT_EXISTS: 'admin.bulk-actions.warning-site-not-exists', // TODO: Put in json
    WARNING_SITE_MAX_LENGTH: 'admin.bulk-actions.warning-site-max-length', // TODO: Put in json
    WARNING_ROLE_REQUIRED: 'admin.bulk-actions.warning-role-required',
    WARNING_ROLE_NOT_EXISTS: 'admin.bulk-actions.warning-role-not-exists',
    WARNING_ROLE_MAX_CHARACTERS: 'admin.bulk-actions.warning-role-max-characters',
    WARNING_GRADE_NOT_EXISTS: 'admin.bulk-actions.warning-grade-not-exists',
    WARNING_GRADE_MAX_CHARACTERS: 'admin.bulk-actions.warning-grade-max-characters',
    WARNING_CIRCULAR_DEPENDENCY: 'admin.bulk-actions.warning-circular-dependency',
    WARNING_ACCESS_LEVEL_TOO_LOW: 'admin.bulk-actions.warning-access-level-too-low',
    WARNING_CHANGE_MANAGER_MID_CYCLE: 'admin.bulk-actions.warning-change-manager-mid-cycle',
    WARNING_FIRST_NAME_REQUIRED: 'admin.bulk-actions.warning-first-name-required',
    WARNING_FIRST_NAME_MAX_CHARACTERS: 'admin.bulk-actions.warning-first-name-max-characters',
    WARNING_LAST_NAME_REQUIRED: 'admin.bulk-actions.warning-last-name-required',
    WARNING_LAST_NAME_MAX_CHARACTERS: 'admin.bulk-actions.warning-last-name-max-characters',
    WARNING_EMAIL_REQUIRED: 'admin.bulk-actions.warning-email-required',
    WARNING_EMAIL_MAX_CHARACTERS: 'admin.bulk-actions.warning-email-max-characters',
    WARNING_EMAIL_FORMAT_SPACES: 'admin.bulk-actions.warning-email-format-spaces',
    WARNING_EMAIL_FORMAT: 'admin.bulk-actions.warning-email-format',
    WARNING_EMAIL_DUPLICATES: 'admin.bulk-actions.warning-email-duplicates',
    WARNING_EMAIL_EXISTS: 'admin.bulk-actions.warning-email-exists',
    WARNING_EMAIL_NOT_EXISTS: 'admin.bulk-actions.warning-email-not-exists',
    WARNING_START_DATE_REQUIRED: 'admin.bulk-actions.warning-start-date-required',
    WARNING_START_DATE_FORMAT: 'admin.bulk-actions.warning-start-date-format',
    WARNING_ACCESS_LEVEL_REQUIRED: 'admin.bulk-actions.warning-access-level-required',
    WARNING_ACCESS_LEVEL_INVALID: 'admin.bulk-actions.warning-access-level-invalid',
    WARNING_MANAGER_EMAIL_MAX_CHARACTERS: 'admin.bulk-actions.warning-manager-email-max-characters',
    WARNING_MANAGER_EMAIL_FORMAT: 'admin.bulk-actions.warning-manager-email-format',
    WARNING_MANAGER_EMAIL_NOT_EXISTS: 'admin.bulk-actions.warning-manager-email-not-exists',
    THINGS_TO_NOTE: 'admin.bulk-actions.things-to-note',
    THINGS_TO_NOTE_MAX_FILE_SIZE: 'admin.bulk-actions.things-to-note-max-file-size',
    THINGS_TO_NOTE_DEPARTMENT_OPTIONAL: 'admin.bulk-actions.things-to-note-department-optional',
    THINGS_TO_NOTE_SITE_OPTIONAL: 'admin.bulk-actions.things-to-note-site-optional',
    THINGS_TO_NOTE_MANAGER_EMAIL_OPTIONAL: 'admin.bulk-actions.things-to-note-manager-email-optional',
    THINGS_TO_NOTE_GRADE_OPTIONAL: 'admin.bulk-actions.things-to-note-grade-optional',
    FILE_BEING_PROCESSED: 'admin.bulk-actions.file-being-processed',
    FILE_BEING_PROCESSED_ALREADY: 'admin.bulk-actions.file-being-processed-already',
    FILE_PROCESSING_ERROR: 'admin.bulk-actions.file-processing-error',
    FILE_PROCESSING_SUCCESS: 'admin.bulk-actions.file-processing-success',
    RETURN_COMPANY_VIEW: 'admin.bulk-actions.return-company-view',
    GO_TO_COMPANY_VIEW: 'admin.bulk-actions.go-to-company-view',
    NOTIFIED_WHEN_DONE: 'admin.bulk-actions.notified-when-done',
    FIX_ISSUES: 'admin.bulk-actions.fix-issues',
    LABEL_ERRORS: 'admin.bulk-actions.label-errors',
    LABEL_WARNINGS: 'admin.bulk-actions.label-warnings',
    WARNING_EMPTY_FILE: 'admin.bulk-actions.warning-empty-file',
    PROMPT_EMPTY_FILE: 'admin.bulk-actions.prompt-empty-file',
    WARNING_PROCESSING_FAILED: 'admin.bulk-actions.warning-processing-failed',
    PROMPT_PROCESSING_FAILED: 'admin.bulk-actions.prompt-processing-failed',
    PROMPT_REVERT_ROW: 'admin.bulk-actions.prompt-revert-row',
    WARNING_REVERT_ROW: 'admin.bulk-actions.warning-revert-row',
    REVERT_ROW: 'admin.bulk-actions.revert-row',
    PROMPT_DELETE_ROW: 'admin.bulk-actions.prompt-delete-row',
    WARNING_DELETE_ROW: 'admin.bulk-actions.warning-delete-row',
    DELETE_ROW: 'admin.bulk-actions.delete-row',
    SUBMITTED_INVALID: 'admin.bulk-actions.submitted-invalid',
    SHOW_VALID: 'admin.bulk-actions.show-valid',
    SHOW_WARNINGS: 'admin.bulk-actions.show-warnings',
    TO_PROCEED_PLEASE_TYPE: 'admin.bulk-actions.to-proceed-please-type', // word
    TO_PROCEED_PLEASE_TYPE_INVALID: 'admin.bulk-actions.to-proceed-please-type-invalid', // word
    NO_ROWS_PREVIEW: 'admin.bulk-actions.no-rows-preview',
    ALL_ROWS_VALID: 'admin.bulk-actions.all-rows-valid',
    PREVIOUS_VALUE: 'admin.bulk-actions.previous-value',
    PREVIOUS_VALUE_EMPTY: 'admin.bulk-actions.previous-value-empty',
    NEW_VALUE: 'admin.bulk-actions.new-value',
    NEW_VALUE_OTHER: 'admin.bulk-actions.new-value-other',
    HEADING_PREVIEW_CREATE: 'admin.bulk-actions.heading-preview-create',
    DESCRIPTION_PREVIEW_CREATE: 'admin.bulk-actions.description-preview-create',
    HEADING_PREVIEW_UPDATE: 'admin.bulk-actions.heading-preview-update',
    DESCRIPTION_PREVIEW_UPDATE: 'admin.bulk-actions.description-preview-update',
    HEADING_PREVIEW_ARCHIVE: 'admin.bulk-actions.heading-preview-archive',
    DESCRIPTION_PREVIEW_ARCHIVE: 'admin.bulk-actions.description-preview-archive',
  };

  public static readonly INTERESTS = {
    MANAGE_INTERESTS: 'admin.interests.manage-interests',
    MANAGE_INTERESTS_DESCRIPTION: 'admin.interests.manage-interests-description',
    MANAGE_INTERESTS_DESCRIPTION_SHORT: 'admin.interests.manage-interests-description-short',
    MANAGE_EMPTY_STATE: 'admin.interests.manage-empty-state',
    MANAGE_INTERESTS_BULK_ACTIONS_DESCRIPTION: 'admin.interests.manage-interests-bulk-actions-description',
    CONFIRMATION_DELETE_INTEREST_SINGLE: 'admin.interests.confirmation-delete-interest-single',
    CONFIRMATION_DELETE_INTEREST_MULTIPLE: 'admin.interests.confirmation-delete-interest-multiple',
    SUCCESS_DELETE_INTEREST_SINGLE: 'admin.interests.success-delete-interest-single',
    SUCCESS_DELETE_INTEREST_MULTIPLE: 'admin.interests.success-delete-interest-multiple',
    SUCCESS_INTEREST_CREATE: 'admin.interests.success-interest-create',
    SUCCESS_INTEREST_UPDATE: 'admin.interests.success-interest-update',
    INTEREST_NAME: 'admin.interests.interest-name',
    INTEREST_TYPE: 'admin.interests.interest-type',
    INTEREST_CATEGORY: 'admin.interests.interest-category',
    INTEREST_CREATOR: 'admin.interests.interest-creator',
    COPY_INTEREST: 'admin.interests.copy-interest',
    EDIT_INTEREST: 'admin.interests.edit-interest',
    DELETE_INTEREST: 'admin.interests.delete-interest',
    HEADER_CREATING: 'admin.interests.header-creating',
    HEADER_EDITING: 'admin.interests.header-editing',
    PEOPLE_USING_THIS_INTEREST: 'admin.interests.people-using-this-interest',
    IMPORT_FILE_EXPLANATION: 'admin.interests.import-file-explanation',
    SUCCESS_IMPORT_INTERESTS: 'admin.interests.success-import-interests',
    SUCCESS_PENDING_INTEREST_ACCEPTED: 'admin.interests.success-pending-interest-accepted',
    SUCCESS_PENDING_INTEREST_DECLINED: 'admin.interests.success-pending-interest-declined',
    INTEREST_STATUS: 'admin.interests.interest-status',
    APPROVED: 'admin.interests.approved',
    PENDING: 'admin.interests.pending',
    WARNING_INTEREST_PENDING: 'admin.interests.warning-interest-pending',
    DECLINE_INTEREST: 'admin.interests.decline-interest',
    APPROVE_INTEREST: 'admin.interests.approve-interest',
    PENDING_INTERESTS_HEADING: 'admin.interests.pending-interests-heading',
    PENDING_INTERESTS_DESCRIPTION: 'admin.interests.pending-interests-description',
    PENDING_INTERESTS_DECLINE: 'admin.interests.pending-interests-decline',
    PENDING_INTERESTS_CONFIRM: 'admin.interests.pending-interests-confirm',
    CONFIRMATION_APPROVE_INTEREST_SINGLE: 'admin.interests.confirmation-approve-interest-single',
    CONFIRMATION_DECLINE_INTEREST_SINGLE: 'admin.interests.confirmation-decline-interest-single',
    CATEGORY_WILL_BE_CREATED: 'admin.interests.category-will-be-created',
    CATEGORY_PLACEHOLDER: {
      [InterestType.PERSONAL]: 'admin.interests.category-placeholder.personal',
      [InterestType.PROFESSIONAL]: 'admin.interests.category-placeholder.professional'
    }
  };

  public static readonly FISCAL_YEAR = {
    MANAGE_FISCAL_YEAR: 'admin.fiscal-year.manage-fiscal-year',
    MANAGE_FISCAL_YEAR_DESCRIPTION: 'admin.fiscal-year.manage-fiscal-year-description',
    MANAGE_FISCAL_YEAR_DESCRIPTION_SHORT: 'admin.fiscal-year.manage-fiscal-year-description-short',
    SUCCESS_UPDATE_FISCAL_YEAR: 'admin.fiscal-year.success-update-fiscal-year',
  };

  public static readonly TERMINOLOGY = {
    MANAGE_TERMINOLOGY: 'admin.terminology.manage-terminology',
    MANAGE_TERMINOLOGY_DESCRIPTION: 'admin.terminology.manage-terminology-description',
    MANAGE_TERMINOLOGY_DESCRIPTION_SHORT: 'admin.terminology.manage-terminology-description-short',
    WILL_BE_REPLACED_WITH: 'admin.terminology.will-be-replaced-with',
    SUCCESS_UPDATE_TERMINOLOGY: 'admin.terminology.success-update-terminology',
    ORIGINAL_WORD: 'admin.terminology.original-word',
    ORIGINAL_WORDS: {
      [TerminologyEntity.DEPARTMENT]: 'admin.terminology.original-words.' + TerminologyEntity.DEPARTMENT,
      [TerminologyEntity.DEPARTMENT_PLURAL]: 'admin.terminology.original-words.' + TerminologyEntity.DEPARTMENT_PLURAL,
      [TerminologyEntity.TEAM]: 'admin.terminology.original-words.' + TerminologyEntity.TEAM,
      [TerminologyEntity.TEAM_PLURAL]: 'admin.terminology.original-words.' + TerminologyEntity.TEAM_PLURAL,
      [TerminologyEntity.SECONDARY]: 'admin.terminology.original-words.' + TerminologyEntity.SECONDARY,
      [TerminologyEntity.SECONDARY_MANAGER]: 'admin.terminology.original-words.' + TerminologyEntity.SECONDARY_MANAGER,
      [TerminologyEntity.SECONDARY_MANAGER_PLURAL]: 'admin.terminology.original-words.' + TerminologyEntity.SECONDARY_MANAGER_PLURAL,
      [TerminologyEntity.SITE]: 'admin.terminology.original-words.' + TerminologyEntity.SITE,
      [TerminologyEntity.SITE_PLURAL]: 'admin.terminology.original-words.' + TerminologyEntity.SITE_PLURAL,
      [TerminologyEntity.SKILL]: 'admin.terminology.original-words.' + TerminologyEntity.SKILL,
      [TerminologyEntity.SKILL_PLURAL]: 'admin.terminology.original-words.' + TerminologyEntity.SKILL_PLURAL,
      [TerminologyEntity.COMPETENCY]: 'admin.terminology.original-words.' + TerminologyEntity.COMPETENCY,
      [TerminologyEntity.COMPETENCY_PLURAL]: 'admin.terminology.original-words.' + TerminologyEntity.COMPETENCY_PLURAL,
    }
  };
}