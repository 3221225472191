export class FeedbackMessages {
  public static readonly FEEDBACK = 'feedback.feedback';
  public static readonly MANAGE_FEEDBACK = 'feedback.manage-feedback';
  public static readonly PRAISE = 'feedback.praise';
  public static readonly IDEAS = 'feedback.ideas';
  public static readonly WROTE_PRAISE = 'feedback.wrote-praise';
  public static readonly SUGGESTED_IDEA = 'feedback.suggested-idea';
  public static readonly MORALE = 'feedback.morale';
  public static readonly PRODUCTIVITY = 'feedback.productivity';
  public static readonly REVENUE = 'feedback.revenue';
  public static readonly CUSTOMER_SERVICE = 'feedback.customer-service';
  public static readonly HEALTH_AND_SAFETY = 'feedback.health-and-safety';
  public static readonly QUALITY = 'feedback.quality';
  public static readonly WOULD_AFFECT = 'feedback.would-affect';
  public static readonly MANAGE_UNPROMPTED = 'feedback.manage-unprompted';
  public static readonly MANAGE_UNPROMPTED_DESCRIPTION = 'feedback.manage-unprompted-description';
  public static readonly MANAGE_UNPROMPTED_DESCRIPTION_SHORT = 'feedback.manage-unprompted-description-short';
  public static readonly OPTION_WONT_BE_VISIBLE = 'feedback.option-wont-be-visible';
  public static readonly OPTION_SENT_TO_MANAGER = 'feedback.option-sent-to-manager';
  public static readonly EDIT_UNPROMPTED_OPTION = 'feedback.edit-unprompted-option';
  public static readonly RECIPIENT = 'feedback.recipient';
  public static readonly RESET_UNPROMPTED_OPTION = 'feedback.reset-unprompted-option';
  public static readonly WARNING_RECIPIENT_CLEARED = 'feedback.warning-recipient-cleared';
  public static readonly SUCCESS_RECIPIENT_UPDATED = 'feedback.success-recipient-updated';
  public static readonly FEEDBACK_WILL_BE_SENT_TO = 'feedback.feedback-will-be-sent-to'; // {{name}}
}