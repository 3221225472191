<div class="picker-container" [class.picker-inline]="inline">
  <div class="input-container">
    <input class="form-control picker-input" [placeholder]="placeholder" [name]="name" [formControl]="innerFormControl" [options]="config" #datePicker="angular-mydatepicker" [class.hidden]="inline" angular-mydatepicker [locale]="locale" (calendarToggle)="onCalendarToggle($event)" (focus)="onFocusInput()" #pickerInput>
    <input class="form-control picker-input-display" [placeholder]="placeholder" [ngModel]="dateDisplay"/>
  </div>
  <ng-container *ngIf="!inline">
    <div class="picker-open-area" (click)="openPicker()"></div>
    <div class="picker-undo" *ngIf="showResetButton">
      <span class="fal fa-fw fa-undo" (click)="resetDate()"></span>
    </div>
    <div class="picker-undo" *ngIf="showClearButton && innerFormControl.value">
      <span class="fal fa-fw fa-times" (click)="clearDate()"></span>
    </div>
  </ng-container>
</div>
