<div class="container-qualification-select">
  <app-state [state]="state">
    <ng-container>
      <div class="library-filter-row">
        <div class="container-uni-filter">
          <div [class.hidden]="((universalFilterData.searchControl.value == '') && (qualifications.length <= 1))">
            <app-universal-filter [newUI]="true"
              [searchControl]="universalFilterData.searchControl" [filterOptions]="universalFilterData.filterOptions"
              [searchProps]="universalFilterData.searchProps" [defaultFilters]="universalFilterData.defaultFilters"
              (resultEmit)="filterEmit($event)" (categoriesChanged)="filterCategoriesChanged($event)"></app-universal-filter>
          </div>
        </div>
        <app-button [type]="eButtonType.GREEN_INVERTED" (onClick)="onClickAddQualification()" *ngIf="qualificationsDisplay.length > 0">
          <span translate>{{ eQualificationMessages.ADD_QUALIFICATION }}</span>
        </app-button>
      </div>
    
      <ng-container *ngIf="qualificationsDisplay.length > 0; else templateNoDisplay;">
        <div class="container-library">
          <ng-container *ngFor="let qualification of qualificationsDisplay">
            <app-qualification-display [qualification]="qualification" [actionsShown]="['edit', 'delete']" (onClickAction)="onClickItemAction($event, qualification)"></app-qualification-display>
          </ng-container>
        </div>
      </ng-container>
      <ng-template #templateNoDisplay>
        <app-empty-state [height]="'300px'" [imageSrc]="'assets/img/empty-state/empty-state-qualifications-add.svg'" [message]="eQualificationMessages.EMPTY_STATE" [tertiaryButtonShown]="true" [tertiaryButtonText]="eQualificationMessages.ADD_QUALIFICATION" (tertiaryButtonPressed)="onClickAddQualification()"></app-empty-state>
      </ng-template>
    
      <app-pagination-new-component [arrayData]="qualificationsFiltered" [arrayDisplay]="qualificationsDisplay" #pagination></app-pagination-new-component>
    
      <app-modal
        [showCloseButton]="false" [minWidth]="'477px'" [maxWidth]="'900px'" [width]="'80%'"
        [sidePanel]="true" [confirmClosing]="templateCreateComponent?.formChanged"
        [confirmCloseModalSettings]="confirmCloseSettings"
        (modalHidden)="onModalHidden()"
        (onConfirmCloseModalResponse)="onCloseCreateResponse($event)" #modalCreate>
        <ng-container *ngIf="modalCreate.visible">
          <app-qualification-create
            [formCreate]="formCreate" [submitted]="state.submitted"
            (requestCloseModal)="hideCreateModal()" (requestOpenModal)="showCreateModal()"
            (onFormCancel)="cancelCreate()" (onFormSubmit)="onSubmit()"
            #templateCreateComponent>
          </app-qualification-create>
        </ng-container>
      </app-modal>
    </ng-container>
  </app-state>
</div>
