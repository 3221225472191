import { Injectable } from '@angular/core';
import { Page } from '@app/models/api/page.model';
import { PagingParams } from '@app/models/api/paging-params.model';
import { SortingParams } from '@app/models/api/sorting-params.model';
import { DateUtils } from '@app/shared/utils/date.utils';
import { NotifyUtils } from '@app/shared/utils/notify.utils';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { QualificationAPIService } from '../api/qualification-api.service';
import { QualificationMessages } from '../locale/qualification.messages';
import { CreateQualificationDto } from '../model/create-qualification.model';
import { Qualification } from '../model/qualification.model';
import { QualificationSidebarData } from '../model/qualification-sidebar-data.model';

@Injectable({
  providedIn: 'root'
})
export class QualificationBusinessService {

  updating: number[];
  uploadingFile: boolean;

  constructor(
    private qualificationAPI: QualificationAPIService,
    private notifyUtils: NotifyUtils
  ) {
    this.updating = [];
    this.uploadingFile = false;
  }

  private startUpdating(ids: number[]) {
    this.updating = [...this.updating, ...ids];
  }

  private stopUpdating(ids: number[]) {
    this.updating = this.updating.filter(x => !ids.includes(x));
  }

  private sortQualifications(qualifications: Qualification[]): Qualification[] {
    return qualifications.sort((a, b) => {
      let timeA = 0;
      if (a.receivedDate) {
        timeA = new Date(a.receivedDate).getTime();
      }
      let timeB = 0;
      if (b.receivedDate) {
        timeB = new Date(b.receivedDate).getTime();
      }
      return timeB - timeA;
    });
  }

  get(
    ids: number[],
    name: string,
    creatorIds: number[]
  ): Observable<Qualification[]> {
    return this.qualificationAPI
      .get(ids, name, creatorIds)
      .pipe(
        map(qualifications => this.sortQualifications(qualifications))
      );
  }

  getPaginated(
    pagingParams: PagingParams,
    sortingParams: SortingParams,
    ids: number[],
    name: string,
    creatorIds: number[]
  ): Observable<Page<Qualification>> {
    return this.qualificationAPI.getPaginated(pagingParams, sortingParams, ids, name, creatorIds);
  }

  create(createDto: CreateQualificationDto): Promise<Qualification> {
    createDto.receivedDate = DateUtils.setTimezoneToUTCButKeepDate(createDto.receivedDate);

    return this.qualificationAPI.create(createDto).toPromise()
      .then(res => {
        this.notifyUtils.notify(QualificationMessages.SUCCESS_CREATE);
        return res;
      });
  }

  update(id: number, updateDto: CreateQualificationDto): Promise<Qualification> {
    if (this.updating.includes(id)) { return new Promise((resolve, reject) => reject()); }
    this.startUpdating([id]);

    updateDto.receivedDate = DateUtils.setTimezoneToUTCButKeepDate(updateDto.receivedDate);

    return this.qualificationAPI.update(id, updateDto).toPromise()
      .then(res => {
        this.notifyUtils.notify(QualificationMessages.SUCCESS_UPDATE);
        return res;
      })
      .finally(() => {
        this.stopUpdating([id]);
      });
  }

  delete(id: number): Promise<Qualification> {
    if (this.updating.includes(id)) { return new Promise((resolve, reject) => reject()); }
    this.startUpdating([id]);

    return this.qualificationAPI.delete(id).toPromise()
      .then(res => {
        this.notifyUtils.notify(QualificationMessages.SUCCESS_DELETE);
        return res;
      })
      .finally(() => {
        this.stopUpdating([id]);
      });
  }
  
  getSidebarData(userIds: number[]): Observable<QualificationSidebarData[]> {
    return this.qualificationAPI.getSidebarData(userIds);
  }

}
