<div class="container-sentiment-scale-overview">
  <router-outlet></router-outlet>
  <ng-container *ngIf="activatedRoute.children.length === 0">
    <div class="card">
      <div class="content">
        <app-state [state]="state">
          <ng-container *ngIf="!state.loading && !state.error">
            <app-header [title]="eSentimentScaleMessages.SENTIMENT_SCALES" [description]="eSentimentScaleMessages.SENTIMENT_SCALES_DESCRIPTION"></app-header>

            <hr class="row m-top-0"/>

            <ng-container *ngIf="data.master.length > 0">
              <div class="area-filters">
                <app-universal-filter [filterOptions]="filterData.filterOptions" [searchProps]="filterData.searchProps" [defaultFilters]="filterData.defaultFilters" [newUI]="true" (resultEmit)="filterEmit($event)"></app-universal-filter>
                <app-button [type]="eButtonType.GREEN_INVERTED" (onClick)="startCreate()">{{eSentimentScaleMessages.CREATE_SENTIMENT_SCALE | translate}}</app-button>
              </div>
            </ng-container>

            <div class="area-scale-data">
              <ng-container *ngIf="data.master.length > 0; else templateEmptyState;">
                <div class="scales-list">
                  <ng-container *ngFor="let scale of data.display; index as i; first as isFirst; last as isLast;">
                    <app-sentiment-scale-display [scale]="scale" [actionsShown]="['edit', 'archive']" (onClickAction)="onAction($event, scale)"></app-sentiment-scale-display>
                  </ng-container>
                </div>

                <app-pagination-new-component [arrayData]="data.filtered" [arrayDisplay]="data.display" #pagination></app-pagination-new-component>
              </ng-container>
              <ng-template #templateEmptyState>
                <app-empty-state [height]="'300px'" [message]="eSentimentScaleMessages.SENTIMENT_SCALES_EMPTY_STATE" [primaryButtonShown]="true" [primaryButtonText]="eSentimentScaleMessages.CREATE_SENTIMENT_SCALE" (primaryButtonPressed)="startCreate()"></app-empty-state>
              </ng-template>
            </div>
          </ng-container>
        </app-state>
      </div>
    </div>
  </ng-container>
</div>
