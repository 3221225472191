import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AssessmentGroupInfo } from 'app/models/evaluation/assessment-group-info.model';

@Component({
  selector: 'app-one-to-five-scale-edit',
  templateUrl: './one-to-five-scale-edit.component.html',
  styleUrls: ['./one-to-five-scale-edit.component.scss']
})
export class OneToFiveScaleEditComponent {
  @Input() assessmentGroups: AssessmentGroupInfo[] = [];
  @Output() savedEmit: EventEmitter<AssessmentGroupInfo[]> = new EventEmitter<AssessmentGroupInfo[]>();

  saveFields() {
    this.savedEmit.emit(this.assessmentGroups);
  }
}
