import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { PollQuestionAnswered } from '@app/models/poll/poll-question-answered.model';
import { PollQuestion } from '@app/models/poll/poll-question.model';
import { PossibleAnswer } from '@app/models/survey/possible-answer.model';
import { SurveyQuestionAnswered } from '@app/models/survey/question-answered.model';
import { SurveyQuestion } from '@app/models/survey/question.model';
import { PillType } from 'app/shared/components/pill/pill.component';

class ChartQuestion {
  id: number;
  questionText: string;
  possibleAnswers: PossibleAnswer[];

  constructor(id: number, question: string, possibleAnswers: PossibleAnswer[]) {
    this.id = id;
    this.questionText = question;
    this.possibleAnswers = possibleAnswers;
  }
}

class ChartQuestionAnswered {
  id?: number;
  possibleAnswerIds?: number[];
  textAnswer?: string;
  comment?: string;

  constructor(id: number, possibleAnswerIDs: number[], textAnswer: string, comment: string) {
    this.id = id;
    this.possibleAnswerIds = possibleAnswerIDs;
    this.textAnswer = textAnswer;
    this.comment = comment;
  }
}

interface ChartResults {
  [answerRank: string]: {
    id: number;
    name: string;
    voteCount: number;
    percentageOfTotal: number;
  }
}

@Component({
  selector: 'app-multiple-choice-reporting',
  templateUrl: './multiple-choice-reporting.component.html',
  styleUrls: ['./multiple-choice-reporting.component.scss']
})
export class MultipleChoiceReportingComponent implements OnChanges {
  public readonly ePillType = PillType;
  @Input() question: (SurveyQuestion | PollQuestion) = undefined!
  @Input() answers: (SurveyQuestionAnswered | PollQuestionAnswered)[] = []; // TODO: Use length of this in percent calc

  timer: any;

  answersResults: ChartResults = {}
  resultsKeys: string[] = [];

  ngOnChanges() {
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.parseData();
    }, 250);
  }

  parseData() {
    const questionParsed = this.parseQuestionToCommon()!;
    const answersParsed = this.parseAnswersToCommon();
    const results = this.calculateResults(questionParsed, answersParsed);

    this.answersResults = results.results;
    this.resultsKeys = results.keys.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()));
  }

  // #region - Parsing the questions and answers because the models dont match up
  parseQuestionToCommon(): (ChartQuestion | undefined) {
    if (this.question) {
      // FIXME: Type checking wasnt working here and I have no idea how to fix it
      // - (this.question instanceof PollQuestion)
      // - (typeof(this.question) === PollQuestion.toString())
      if (this.question['questionText']) {
        const q = this.question as PollQuestion;
        return new ChartQuestion(q.id, q.questionText, q.possibleAnswers)
      } else if (this.question['surveyQuestion']) {
        const q = this.question as SurveyQuestion;
        return new ChartQuestion(q.id, q.surveyQuestion, q.surveyQuestionPossibleAnswers);
      }
    }

    return undefined;
  }

  parseAnswersToCommon(): ChartQuestionAnswered[] {
    if (this.answers && this.answers.length > 0) {
      // FIXME: Type checking not working properly the normal way as with above
      if (this.answers[0]['possibleAnswerIds']) {
        const a = this.answers as PollQuestionAnswered[];
        return a.map(x => new ChartQuestionAnswered(x.id!, x.possibleAnswerIds!, x.textAnswer!, x.comment!))
      } else if (this.answers[0]['possibleAnswerId']) {
        const a = this.answers as SurveyQuestionAnswered[];
        return a.map(x => new ChartQuestionAnswered(x.id!, [x.possibleAnswerId!], x.textAnswer!, x.comment!))
      }
    }

    return [];
  }
  // #endregion

  calculateResults(question: ChartQuestion, answers: ChartQuestionAnswered[]): { keys: string[], results: ChartResults } {
    const output: ChartResults = {};
    let totalAnswers = 0;
    const keys: string[] = [];

    // Get answerRank, id, name
    if (question) {
      question.possibleAnswers.forEach(pa => {
        output[pa.answerRank] = {
          id: pa.id,
          name: pa.possibleAnswer,
          voteCount: 0,
          percentageOfTotal: 0
        }
        keys.push(pa.answerRank.toString());
      })

      // Get stats for each answer
      answers.forEach(a => {
        if (a.textAnswer) {
          const x = a.textAnswer.split(',');
          x.forEach((y: string) => {
            if (output.hasOwnProperty(y)) {
              output[y].voteCount++;
            }
          })
          totalAnswers++;
        }
      })

      // Calculate percentages
      for (const key in output) {
        if (output.hasOwnProperty(key)) {
          output[key].percentageOfTotal = ((output[key].voteCount / totalAnswers) * 100);
        }
      }
    }

    return {
      keys: keys,
      results: output
    };
  }
}
