import { MockService } from '@app/mock/api/mock-service';
import { CalendarService } from '@app/shared/api/interfaces/calendar.service';
import { Calendar } from '@app/domain/one_to_one/model/calendar.model';
import { HttpRequest } from '@angular/common/http';
import { sanitizeUrl } from '@app/shared/utils/helpers';
import { CalendarConnectionStatus } from '@app/domain/one_to_one/model/calendar-connected.model';
import { Observable, of } from 'rxjs';

export class CalendarMockService implements MockService, CalendarService {
  handleRoute(req: HttpRequest<any>): any {
    const url = sanitizeUrl(req.urlWithParams);
    switch (true) {
      case url.endsWith('api/calendar/availability'):
        return this.getAvailability([''], '', '', '');
      case url.endsWith('api/calendar/connected'):
        return this.isConnected();
    }
  }

  getAvailability(emails: string[], startDateTime: string, endDateTime: string, timezone: string): Observable<Calendar[]> {
    return of([]);
  }

  isConnected(): Observable<CalendarConnectionStatus> {
    return of({connected: false, provider: null});
  }
}
