import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { CommonMessages } from '@app/constants/common.messages';
import { OneToOneMessages } from '@app/domain/one_to_one/locale/one-to-one.messages';
import { OneToOneMeetingStatus } from '@app/domain/one_to_one/model/one-to-one-meeting-status.model';
import { OneToOneScheduleDetailsView } from '@app/domain/one_to_one/model/one-to-one-schedule.model';
import { DefaultTaskAlignment, TaskCreateComponent, initTaskForm, parseTaskFormValueToCreateDto } from '@app/domain/task/component/create-task/task-create.component';
import { TasksMessages } from '@app/domain/task/locale/tasks.messages';
import { CreateTaskDto } from '@app/domain/task/model/create-task.dto';
import { TaskAlignmentType } from '@app/domain/task/model/task-alignment-type.enum';
import { TaskCreatedFrom } from '@app/domain/task/model/task-created-from.model';
import { Task } from '@app/domain/task/model/task.model';
import { TaskBusinessService } from '@app/domain/task/service/task-business.service';
import { TaskUtilsService } from '@app/domain/task/service/task-utils.service';
import { IState } from '@app/models/state/state.model';
import { ButtonType } from '@app/shared/components/inputs/button/button.component';
import { Globals } from '@app/shared/globals/globals';
import { ModalComponent } from '@app/shared/modal/modal.component';
import { PaginationNewComponent } from '@app/shared/pagination/pagination-new/pagination-new.component';
import { deepClone } from '@app/shared/utils/helpers';
import { SwalUtils } from '@app/shared/utils/swal.utils';
import { SweetAlertOptions } from 'sweetalert2';
import moment from 'moment';
import { Subject } from 'rxjs';

interface PageState extends IState {
  submitted: boolean;
  submitting: boolean;
}

enum ModalType {
  CREATE = 'CREATE',
  DETAILS = 'DETAILS'
}

type TabEntity = Task;

@Component({
  selector: 'app-information-sidebar-action-points',
  templateUrl: './information-sidebar-action-points.component.html',
  styleUrls: ['./information-sidebar-action-points.component.scss']
})
export class InformationSidebarActionPointsComponent implements OnInit, OnDestroy {
  public readonly eOneToOneMessages = OneToOneMessages;
  public readonly eButtonType = ButtonType;
  public readonly eModalType = ModalType;
  public readonly confirmCloseSettings: SweetAlertOptions = {
    title: CommonMessages.UNSAVED_CHANGES,
    text: CommonMessages.UNSAVED_CHANGES_WARNING,
    confirmButtonText: CommonMessages.STAY,
    cancelButtonText: CommonMessages.LEAVE
  };

  private ngUnsubscribe$: Subject<void> = new Subject<void>();
  
  @ViewChild('modal') modal?: ModalComponent;
  @ViewChild('pagination') pagination?: PaginationNewComponent;
  @ViewChild('componentCreate') componentCreate?: TaskCreateComponent;

  @Input() userIds: number[];
  @Input() schedule: OneToOneScheduleDetailsView;
  @Input() meetingId?: number;

  state: PageState;
  dataNewlyAdded: TabEntity[];
  dataForReview: TabEntity[];
  taskViewing: Task;
  modalType: ModalType;

  formCreate: FormGroup;
  defaultAlignment: DefaultTaskAlignment;

  get dataAll(): TabEntity[] {
    return [...this.dataNewlyAdded, ...this.dataForReview];
  }

  constructor(
    public globals: Globals,
    private swalUtils: SwalUtils,
    private taskBusinessService: TaskBusinessService
  ) {
    this.userIds = [];

    this.taskViewing = undefined;
    this.defaultAlignment = undefined;

    this.state = {
      loading: true,
      error: false,
      errorMessage: '',
      submitted: false,
      submitting: false
    };

    this.dataNewlyAdded = [];
    this.dataForReview = [];

    this.formCreate = initTaskForm(TaskCreatedFrom.ONE_TO_ONE);
  }

  ngOnInit(): void {
    if (this.schedule) {
      this.defaultAlignment = {
        type: TaskAlignmentType.ONE_TO_ONE,
        id: this.schedule.id
      };

      this.formCreate = initTaskForm(TaskCreatedFrom.ONE_TO_ONE, undefined, this.defaultAlignment, [this.globals.user]);
    }

    this.getData();
  }
  
  ngOnDestroy(): void {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.unsubscribe();
  }
  
  getData(): void {
    if (!this.userIds) { return; }
    if (this.userIds.length === 0) { return; }
    if (!this.schedule.id) { return; }

    
    this.taskBusinessService.getTasks(null, [], this.userIds, [], [TaskAlignmentType.ONE_TO_ONE], [this.schedule.id], false)
      .subscribe(data => {
        data = this.sortData(data);
        this.populateData(data);
        this.state.loading = false;    
      });
  }

  sortData(data: TabEntity[]): TabEntity[] {
    // Order by name
    return data.sort((a, b) => {
      const sortStringA = `${a.title}`;
      const sortStringB = `${b.title}`;
      return sortStringA.localeCompare(sortStringB);
    });
  }

  populateData(data?: TabEntity[]): void {
    const dataForPeriod = this.getActionPointDataForPeriod(data);

    this.dataNewlyAdded = dataForPeriod[0];
    this.dataForReview = dataForPeriod[1];

    this.refreshPagination();
  }

  refreshPagination(): void {
    if (this.pagination) {
      this.pagination.update();
    }
  }

  onTaskAction(event: string, task: Task): void {
    switch (event) {
      case 'view':
        this.startViewTask(task);
        break;
      case 'edit':
        this.startEditTask(task);
        break;
      case 'unlink':
        this.startUnlinkTask(task);
        break;
      case 'archive':
        this.startArchiveTask(task);
        break;
    }
  }

  startViewTask(task: Task): void {
    this.taskViewing = task;
    this.showModal(ModalType.DETAILS);
  }
  
  showModal(modalType: ModalType): void {
    this.modalType = modalType;
    if (this.modal) {
      this.modal.show();
    }
  }

  hideModal(): void {
    if (this.modal) {
      this.modal.hide();
    }
  }

  startCreateTask(): void {
    this.formCreate = initTaskForm(TaskCreatedFrom.OVERVIEW, undefined, this.defaultAlignment, [this.globals.user]);
    this.showModal(ModalType.CREATE);
  }

  onCancelCreate(): void {
    this.formCreate = initTaskForm(TaskCreatedFrom.ONE_TO_ONE, undefined, this.defaultAlignment, [this.globals.user]);
    this.hideModal();
  }

  startEditTask(task: Task): void {
    const taskCopy = JSON.parse(JSON.stringify(task));
    this.formCreate = initTaskForm(TaskCreatedFrom.ONE_TO_ONE, taskCopy);
    this.showModal(ModalType.CREATE);
  }

  onSubmitCreate(): void {
    this.state.submitted = true;
    if (this.formCreate.invalid) { return; }

    const createDto: CreateTaskDto = parseTaskFormValueToCreateDto(this.formCreate.value);
    const taskId = this.formCreate.value.id;

    if (taskId) {
      this.doEdit(taskId, createDto);
    } else {
      this.doCreate(createDto);
    }
  }

  doCreate(createDto: CreateTaskDto): void {
    this.taskBusinessService.createNewTask(createDto)
      .then(() => {
        this.getData();
        this.hideModal();
      });
  }

  doEdit(id: number, updateDto: CreateTaskDto): void {
    this.taskBusinessService.updateExistingTask(id, updateDto)
      .then(() => {
        this.getData();
        this.hideModal();
      });
  }


  startArchiveTask(task: Task): void {
    if (this.taskBusinessService.updating.includes(task.id)) { return; }

    const buttonText = TaskUtilsService.getTooltipArchive(task);

    this.swalUtils.displayWarningConfirmationSwal({
      text: CommonMessages.ACTION_CANNOT_BE_UNDONE,
      confirmButtonText: buttonText
    }).then(result => {
      if (result.isConfirmed) {
        this.taskBusinessService.archiveTask(task.id)
          .then(() => {
            this.taskUpdated();
            this.hideModal();
          });
      }
    });
  }

  startUnlinkTask(task: Task): void {
    this.swalUtils.displayWarningConfirmationSwal({
      text: TasksMessages.WARNING_UNLINK_TASK_ONE_TO_ONE,
      confirmButtonText: TasksMessages.UNLINK_TASK
    }).then((result) => {
      if (result.isConfirmed) {
        this.taskBusinessService.removeTaskAlignment(task)
          .then(() => {
            this.getData();
          });
      }
    });
  }

  taskUpdated(): void {
    this.getData();
  }

  getActionPointDataForPeriod(actionPointsInput: Task[]): [Task[], Task[]] {
    if (!this.meetingId) {
      return [actionPointsInput, []];
    }

    const currentMeetingIndex = this.schedule.meetingList.findIndex(m => m.id === this.meetingId);
    if (currentMeetingIndex === -1) {
      return [actionPointsInput, []];
    }
    const currentMeeting = this.schedule.meetingList[currentMeetingIndex];
    const currentMeetingCompletion = currentMeeting.completedTimestamp? currentMeeting.completedTimestamp : moment().toDate();
    const currentMeetingCompleted = [OneToOneMeetingStatus.COMPLETED, OneToOneMeetingStatus.AUTO_COMPLETED].includes(currentMeeting.status);

    let previousMeeting = undefined;
    try {
      previousMeeting = this.schedule.meetingList![currentMeetingIndex - 1];
    } catch (e) {
      previousMeeting = undefined;
    }

    let actionPoints = deepClone(actionPointsInput);

    actionPoints = actionPoints.filter(ap => {
      // Don't show action points created after the current meeting was completed
      if(currentMeetingCompleted && moment(ap.creationDate).isAfter(moment(currentMeetingCompletion))) { return false; }

      return true;
    });

    // Sort action points by completed then due date
    actionPoints = actionPoints.sort((a,b) => {
      if (b.archived) { return -3; }
      if (a.completionDate && b.completionDate) {
        return moment(a.completionDate).diff(moment(b.completionDate));
      } else if (a.completionDate) {
        return -1;
      } else if (b.completionDate) {
        return 1;
      } else if (a.dueDate && b.dueDate) {
        return moment(a.dueDate).diff(moment(b.dueDate));
      } else if (a.dueDate) {
        return -1;
      } else if (b.dueDate) {
        return 1;
      } else {
        return 0;
      }
    });

    // Sort action points by due date
    actionPoints = actionPoints.sort((a,b) => {
      if (b.archived) { return -3; }
      if (a.dueDate && b.dueDate) {
        return moment(a.dueDate).diff(moment(b.dueDate));
      } else if (a.dueDate) {
        return -1;
      } else if (b.dueDate) {
        return 1;
      } else {
        return 0;
      }
    });

    // Only show action points that are created after the previous meeting and before the current meeting
    const actionPointsNew = actionPoints
      .filter(ap => {
        if (!previousMeeting) { return true; }

        // Don't show action points created before the previous meeting
        if(moment(ap.creationDate).isBefore(moment(previousMeeting.meetingTimestamp))) { return false; }

        return true;
      });

    const actionPointsExisting = actionPoints
      .filter((ap) => {
        // Don't show action points that are already in the other list
        // ALSO FUNCTIONS AS: Don't show action points created after the previous meeting
        if (actionPointsNew.includes(ap)) { return false; }

        // Don't show action points that are completed before the previous meeting
        if (ap.completionDate && moment(ap.completionDate).isBefore(moment(previousMeeting.meetingTimestamp))) { return false; }

        return true;
      });

    return [actionPointsNew, actionPointsExisting];
  }
}
