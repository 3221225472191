import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthAPIService } from '@app/shared/auth/auth.api.service';
import { Globals } from '@app/shared/globals/globals';
import { Angulartics2Amplitude } from 'angulartics2/amplitude';
import { SessionLogService } from '@app/shared/session-log/session-log.service';
import { environment } from 'environments/environment';
import { SessionEventType } from '@app/models/session-log/session-event-type.model';
import { SSOProvider } from '@app/models/auth/sso-provider.enum';
import { I18nService } from '@app/i18n/i18n.service';
import { UserAPIService } from '@app/shared/api/user.api.service';
import { SwalUtils } from 'app/shared/utils/swal.utils';
import { AuthMessages } from 'app/auth/auth.messages';
import { TranslateService } from '@ngx-translate/core';
import { FrankliHttpErrorResponse } from '@app/models/exception/frankli-http-error-response.model';
import { ErrorCode } from '@app/models/exception/error-codes.model';

@Component({
  selector: 'app-callback',
  templateUrl: './callback.component.html',
  styleUrls: ['./callback.component.scss']
})
export class CallbackComponent implements OnInit {

  constructor(
      private route: ActivatedRoute,
      private authService: AuthAPIService,
      private router: Router,
      public globals: Globals,
      private angulartics2Amplitude: Angulartics2Amplitude,
      private sessionLogService: SessionLogService,
      private i18nService: I18nService,
      private userAPIService: UserAPIService,
      private swalUtils: SwalUtils
  ) { }

  ngOnInit(): void {
    const code = this.route.snapshot.queryParamMap.get('code');
    const state = this.route.snapshot.queryParamMap.get('state') as SSOProvider;

    if (code === null || state === null) {
      this.handleError('Something went wrong when trying to sign you in.');
      return;
    }

    const provider = SSOProvider[state];
    this.authService.authenticateSSO({ authorizationCode: code, provider: SSOProvider[state] })
      .toPromise()
      .then(userInit => {
        console.info("Callback: ", userInit);
        localStorage.setItem('access_token', userInit.accessToken);
        localStorage.removeItem('refresh_microsoft_token');
        this.globals.init(userInit);
        this.globals.ssoProvider = provider;
        this.globals.welcomeViewed = false;
        this.i18nService.language = this.globals.user.configuration.preferredLocale;
        this.sessionLogService.log(SessionEventType.SESSION_START_LOGIN);

        if (environment.production) {
          this.angulartics2Amplitude.setUsername(this.globals.user.email);
        }

        this.userAPIService.setTimezone(this.globals.user).subscribe(
          (user) => {
            this.globals.updateUser(user);
            this.router.navigateByUrl('/');
          }
        );
      })
      .catch((error: FrankliHttpErrorResponse) => {
        if (error.status === 410) {
          this.swalUtils.displayWarning({
            title: AuthMessages.SSO_GOOGLE_INVALIDATED,
            text: AuthMessages.SSO_GOOGLE_INVALIDATED_DESCRIPTION,
            confirmButtonText: AuthMessages.RETURN_TO_LOGIN
          }).then(() => {
            this.router.navigateByUrl('/');
          });
          return;
        } else if (error.error) {
          switch (error.error.errorCode) {
            case ErrorCode.LOGIN_ACCOUNT_CONNECTED_TO_GOOGLE:
              this.swalUtils.displayWarning({
                text: error.error.message,
                confirmButtonText: AuthMessages.RETURN_TO_LOGIN
              }).then(() => {
                this.router.navigateByUrl('/');
              });
              return;
            case ErrorCode.LOGIN_ACCOUNT_CONNECTED_TO_MICROSOFT:
              this.swalUtils.displayWarning({
                text: error.error.message,
                confirmButtonText: AuthMessages.RETURN_TO_LOGIN
              }).then(() => {
                this.router.navigateByUrl('/');
              });
              return;
            case ErrorCode.LOGIN_REFRESH_TOKEN_EXPIRED_MICROSOFT:
              localStorage.setItem('refresh_microsoft_token', 'true');
              break;
          }
        }
        
        const errorMessage = typeof error.error === 'string' ? error.error : error.error.message;

        this.handleError(errorMessage);
      });
  }

  private handleError(message: string) {
    localStorage.setItem('sso_error', `${Date.now()}: ${message}`);
    this.router.navigateByUrl('/');
  }

}
