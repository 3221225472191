import { Injectable } from '@angular/core';
import { CreateCareerPathwayDto } from '@app/domain/career_pathway/model/create-career-pathway.model';
import { CareerPathway } from '@app/domain/career_pathway/model/career-pathway.model';
import { CareerPathwayAPIService } from '@app/domain/career_pathway/api/career-pathway-api.service';
import { NotifyUtils } from '@app/shared/utils/notify.utils';
import { Observable } from 'rxjs';
import { CareerPathwayMessages } from '../locale/career-pathway.messages';
import { Page } from '@app/models/api/page.model';
import { PagingParams } from '@app/models/api/paging-params.model';
import { SortingParams } from '@app/models/api/sorting-params.model';
import { CareerPathwayNode } from '../model/career-pathway-node.model';

@Injectable({
  providedIn: 'root'
})
export class CareerPathwaysBusinessService {

  updating: number[];
  updatingUsers: number[];
  uploadingFile: boolean;

  constructor(
    private careerPathwayAPI: CareerPathwayAPIService,
    private notifyUtils: NotifyUtils
  ) {
    this.updating = [];
    this.updatingUsers = [];
    this.uploadingFile = false;
  }

  private startUpdating(ids: number[]) {
    this.updating = [...this.updating, ...ids];
  }

  private stopUpdating(ids: number[]) {
    this.updating = this.updating.filter(x => !ids.includes(x));
  }

  private startUpdatingUsers(ids: number[]) {
    this.updatingUsers = [...this.updatingUsers, ...ids];
  }

  private stopUpdatingUsers(ids: number[]) {
    this.updatingUsers = this.updatingUsers.filter(x => !ids.includes(x));
  }

  get(
    ids: number[],
    name: string,
    roleIds: number[],
    showPrivate: boolean
  ): Observable<CareerPathway[]> {
    return this.careerPathwayAPI.get(ids, name, roleIds, showPrivate);
  }

  getPaginated(
    pagingParams: PagingParams,
    sortingParams: SortingParams,
    ids: number[],
    name: string,
    roleIds: number[],
    showPrivate: boolean
  ): Observable<Page<CareerPathway>> {
    return this.careerPathwayAPI.getPaginated(pagingParams, sortingParams, ids, name, roleIds, showPrivate);
  }

  create(createDto: CreateCareerPathwayDto): Promise<CareerPathway> {
    return this.careerPathwayAPI.create(createDto).toPromise()
      .then(res => {
        this.notifyUtils.notify(CareerPathwayMessages.SUCCESS_CREATE);
        return res;
      });
  }

  update(id: number, updateDto: CreateCareerPathwayDto): Promise<CareerPathway> {
    if (this.updating.includes(id)) { return new Promise((resolve, reject) => reject()); }
    this.startUpdating([id]);

    return this.careerPathwayAPI.update(id, updateDto).toPromise()
      .then(res => {
        this.notifyUtils.notify(CareerPathwayMessages.SUCCESS_UPDATE);
        return res;
      })
      .finally(() => {
        this.stopUpdating([id]);
      });
  }

  makePublic(id: number): Promise<CareerPathway> {
    if (this.updating.includes(id)) { return new Promise((resolve, reject) => reject()); }
    this.startUpdating([id]);

    return this.careerPathwayAPI.makePublic(id).toPromise()
      .then(res => {
        this.notifyUtils.notify(CareerPathwayMessages.SUCCESS_MAKE_PUBLIC);
        return res;
      })
      .finally(() => {
        this.stopUpdating([id]);
      });
  }

  delete(id: number): Promise<CareerPathway> {
    if (this.updating.includes(id)) { return new Promise((resolve, reject) => reject()); }
    this.startUpdating([id]);

    return this.careerPathwayAPI.delete(id).toPromise()
      .then(res => {
        this.notifyUtils.notify(CareerPathwayMessages.SUCCESS_DELETE);
        return res;
      })
      .finally(() => {
        this.stopUpdating([id]);
      });
  }

  getSelectedCareerPathwayForUserId(userId: number): Observable<CareerPathway> {
    return this.careerPathwayAPI.getSelectedCareerPathwayForUserId(userId);
  }

  setSelectedCareerPathwayForUserId(userId: number, careerPathwayId: number): Promise<CareerPathway> {
    if (this.updatingUsers.includes(careerPathwayId)) { return new Promise((resolve, reject) => reject()); }
    this.startUpdatingUsers([careerPathwayId]);

    return this.careerPathwayAPI.setSelectedCareerPathwayForUserId(userId, careerPathwayId).toPromise()
      .then(res => {
        this.notifyUtils.notify(CareerPathwayMessages.SUCCESS_UPDATE_MY_PATHWAY);
        return res;
      })
      .finally(() => {
        this.stopUpdatingUsers([careerPathwayId]);
      });
  }

  getCareerPathwayNodeByCurrentRoleId(roleId: number): Observable<CareerPathwayNode> {
    return this.careerPathwayAPI.getCareerPathwayNodeByCurrentRoleId(roleId);
  }

  getMostRelevantPathwayForMe(): Observable<CareerPathway> {
    return this.careerPathwayAPI.getMostRelevantPathwayForMe();
  }
}
