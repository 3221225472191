<!------------------------------------------------------------
  Since org chart is fullscreen, all controls will need to be 
  positioned absolutly, on top of the org chart
-------------------------------------------------------------->

<div class="org-chart-container" [class.fullscreen]="state.fullscreen">
  <div class="org-chart-heading">
    <h1 class="standard-heading">Organisational chart</h1>
    <div class="standard-description">This chart can be used to quickly browse reporting lines in your company and figure out who reports to who.</div>
  </div>
  
  <!-- Controls -->
  <div class="control-fullscreen" (click)="toggleFullscreen()">
    <div class="org-chart-control text-center" [title]="(state.fullscreen ? 'Exit Fullscreen' : 'Enter Fullscreen')">
      <span class="fal fa-fw fa-2x" [ngClass]="(state.fullscreen ? 'fa-compress' : 'fa-expand' )"></span>
    </div>
  </div>

  <!-- Zoom controls are internal in the org chart, others are overlayed here -->
  <div class="control-help" title="Show cheatsheet" (click)="showOrgchartHelp()">
    <span class="fal fa-fw fa-question-circle fa-2x"></span>
  </div>

  <!-- TODO: Move these inside the library -->
  <div class="org-chart-controls">
    <div class="org-chart-control" (click)="expandAll()" [title]="'Expand All'">
      <span class="fal fa-2x fa-expand-arrows-alt"></span>
    </div>
    <div class="org-chart-control" (click)="collapseAll()" [title]="'Collapse All'">
      <span class="fal fa-2x fa-compress-arrows-alt"></span>
    </div>
  </div>

  <div class="org-chart-wrapper">
    <div class="state-wrapper">
      <app-state [state]="state" [stateHeight]="'600px'" [errorButtonText]="'Try again'" [errorButtonShown]="true" (errorButtonPressed)="getData()"></app-state>
    </div>
    <div class="tree-container" id="tree" #treeContainer></div>
  </div>

  <!-- Help modal -->
  <app-modal #modal [background]="'light'">
    <div class="modal-body-content">
      <div class="p-bottom-5">
        <h2 class="standard-heading">Using the org chart</h2>
      </div>
      <div class="alert alert-danger m-bottom-15" *ngIf="isIE()">
        Unfortunately peoples images are not supported on Internet Explorer 11 or lower.
        If you wish to view images, please use a more modern browser such as Microsoft Edge, Chrome, Firefox, Safari
        or Opera
      </div>

      <table width="100%">
        <tr>
          <td width="1em">
            <strong class="m-right-5 text-nowrap v-align-top">Show direct reports</strong>
          </td>
          <td>
            <span>Click the&nbsp;</span>
            <span class="fas fa-fw fa-plus-circle" style="color: #30747F;"></span>
            <span>&nbsp;icon below a person on the org chart</span>
          </td>
        </tr>
        <tr>
          <td>
            <strong class="m-right-5 text-nowrap v-align-top">Hide direct reports</strong>
          </td>
          <td>
            <span>Click the&nbsp;</span>
            <span class="fas fa-fw fa-minus-circle" style="color: #D3D3D3"></span>
            <span>&nbsp;icon below a person on the org chart</span>
          </td>
        </tr>
        <tr>
          <td colspan="2">
            <span>&nbsp;</span>
          </td>
        </tr>
        <tr>
          <td>
            <strong class="m-right-5 text-nowrap v-align-top">Reset zoom</strong>
          </td>
          <td>
            <span>Click the&nbsp;</span>
            <span class="fal fa-fw fa-sync-alt"></span>
            <span>&nbsp;icon</span>
          </td>
        </tr>
        <tr>
          <td>
            <strong class="m-right-5 text-nowrap v-align-top">Zoom in</strong>
          </td>
          <td>
            <span>Click the&nbsp;</span>
            <span class="fal fa-fw fa-search-plus"></span>
            <span>&nbsp;icon or scroll up</span>
          </td>
        </tr>
        <tr>
          <td>
            <strong class="m-right-5 text-nowrap v-align-top">Zoom out</strong>
          </td>
          <td>
            <span>Click the&nbsp;</span>
            <span class="fal fa-fw fa-search-minus"></span>
            <span>&nbsp;icon or scroll down</span>
          </td>
        </tr>
        <tr>
          <td colspan="2">
            <span>&nbsp;</span>
          </td>
        </tr>
        <tr>
          <td>
            <strong class="m-right-5 text-nowrap v-align-top">Expand all</strong>
          </td>
          <td>
            <span>Click the&nbsp;</span>
            <span class="fal fa-fw fa-expand-arrows-alt"></span>
            <span>&nbsp;icon in the sidebar</span>
          </td>
        </tr>
        <tr>
          <td>
            <strong class="m-right-5 text-nowrap v-align-top">Collapse all</strong>
          </td>
          <td>
            <span>Click the&nbsp;</span>
            <span class="fal fa-fw fa-compress-arrows-alt"></span>
            <span>&nbsp;icon in the sidebar</span>
          </td>
        </tr>
      </table>
    </div>
  </app-modal>
</div>