<ng-container *ngIf="value; else noValue">
    <app-goal-search-item [goal]="value" [canSelect]="true" [selected]="true" (onSelect)="doDeselectGoal(value)"></app-goal-search-item>
</ng-container>
<ng-template #noValue>
    <div class="search-container">
        <div class="search-input no-selection">
            <input type="text" class="form-control" [placeholder]="placeholder | translate" [formControl]="searchControl" [class.input-no-results]="searchResults.length === 0 && searchControl.value && !searchRunning" (focus)="onFocusSearch()" (blur)="onBlurSearch()" #searchInput/>
            <ng-container *ngIf="showResultsFloating">
                <div class="search-results">
                    <ng-container *ngFor="let result of searchResults">
                        <div class="search-results-item">
                            <app-goal-search-item [goal]="result" [canSelect]="true" (onSelect)="doSelectGoal(result)"></app-goal-search-item>
                        </div>
                    </ng-container>
                </div>
            </ng-container>
        </div>
        <span class="search-icon">
            <span class="fal fa-fw fa-search"></span>
        </span>
    </div>

    <ng-container *ngIf="!showResultsFloating">
        <div class="search-results-list">
            <ng-container *ngFor="let result of searchResults">
                <div class="search-results-item">
                    <app-goal-search-item [goal]="result" [canSelect]="true" (onSelect)="doSelectGoal(result)"></app-goal-search-item>
                </div>
            </ng-container>
        </div>
    </ng-container>
</ng-template>