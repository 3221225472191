import {Component, OnInit} from '@angular/core';
import {environment} from '../../environments/environment';
import {SessionEventType} from '@app/models/session-log/session-event-type.model';
import {AuthAPIService} from '@app/shared/auth/auth.api.service';
import {SessionLogService} from '@app/shared/session-log/session-log.service';
import {Globals} from '@app/shared/globals/globals';
import {Router} from '@angular/router';
import {Angulartics2Amplitude} from 'angulartics2/amplitude';
import {UserInit} from '@app/models/auth/user-init.model';
import {SSOProvider} from '@app/models/auth/sso-provider.enum';
import {I18nService} from '@app/i18n/i18n.service';
import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { MicrosoftTeamsService } from '@app/domain/microsoft/service/microsoft-teams.service';
import { IState } from '@app/models/state/state.model';
import { app } from '@microsoft/teams-js';

@Component({
  selector: 'app-teams-gateway',
  templateUrl: './teams-gateway.component.html',
  styleUrls: ['./teams-gateway.component.scss']
})
export class TeamsGatewayComponent implements OnInit {
  state: IState;
  showDemoPrompt = false;

  constructor(
    private router: Router,
    private angulartics2Amplitude: Angulartics2Amplitude,
    public globals: Globals,
    private authService: AuthAPIService,
    private sessionLogService: SessionLogService,
    private i18nService: I18nService
  ) {
    this.state = {
      loading: true,
      error: false,
      errorMessage: ''
    };
  }

  ngOnInit(): void {
    // MicrosoftTeamsService.notifyApplicationLoaded();
    MicrosoftTeamsService.notifySuccess();
    MicrosoftTeamsService.getAuthenticationToken()
      .then(res => this.handleSuccessfulAuthenticationWithTeams(res))
      .catch(err => {
        this.handleError(err, app.FailedReason.AuthFailed);
      });
  }

  private handleSuccessfulAuthenticationWithTeams(accessToken: string) {
    this.authService.authenticateSSOTeams(accessToken)
      .subscribe(
        (userInit: UserInit) => this.onAuthSuccess(userInit),
        (error: HttpErrorResponse) => this.onAuthFailure(error)
      );
  }

  private onAuthSuccess(userInit: UserInit) {
    localStorage.setItem('access_token', userInit.accessToken);
    this.globals.init(userInit);
    this.globals.welcomeViewed = false;
    this.globals.ssoProvider = SSOProvider.MICROSOFT;
    this.globals.usingTeams = true;
    this.i18nService.language = this.globals.user.configuration.preferredLocale;
    if (environment.production) {
      this.angulartics2Amplitude.setUsername(this.globals.user.email);
    }
    this.sessionLogService.log(SessionEventType.SESSION_START_LOGIN);
    this.handleDeeplinkFromTeams();
    this.state.loading = false;
  }

  private onAuthFailure(error: HttpErrorResponse): void {
    if (error.status === HttpStatusCode.NotFound) {
      this.showDemoPrompt = true;
    }

    this.handleError(error.error, app.FailedReason.AuthFailed);
  }

  private handleDeeplinkFromTeams() {
    MicrosoftTeamsService.getContext()
      .then(context => {
        if (!context) { return this.router.navigate(['/']); }
        if (!context.actionInfo) { return this.router.navigate(['/']); }
        if (!context.actionInfo.actionId) { return this.router.navigate(['/']); }

        const url = context.actionInfo.actionId;
        this.router.navigate([url]);
      });
  }

  private handleError(message: string, failedReason: app.FailedReason = app.FailedReason.Other) {
    MicrosoftTeamsService.notifyLoadingFailure(message, failedReason);
    localStorage.removeItem('access_token');
    this.doError(message);
  }

  doError(message: string): void {
    this.state.loading = false;
    this.state.error = true;
    this.state.errorMessage = message;
  }
}
