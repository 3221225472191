import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FAQItem } from 'app/models/FAQ-item.model';

@Injectable()
export class FAQAPIService {
  http: HttpClient;
  constructor(private _http: HttpClient) {
    this.http = _http;
  }

  getFAQs() {
    const url = 'api/faq/all';
    return this.http.get<Array<FAQItem>>(url);
  }

}
