<div class="container-navbar">
  <div #navbar class="area-navbar">
    <div class="row-navbar-icons">
      <div class="display-navbar-search">
        <span class="fal fa-fw fa-search"></span>
        <input type="search" class="form-control" [placeholder]="eNavbarMessages.SEARCH_PLACEHOLDER | translate" [(ngModel)]="searchValue" maxlength="100" angulartics2On="click" angularticsAction="ClickSearchNavbar" angularticsCategory="Support" [attr.data-intercom-target]="'Search Bar'" (keyup)="checkEnter($event)" />
      </div>

      <div class="display-frankli-logo">
        <img src="assets/img/logos/frankli-logo-text-dark.svg" alt="Frankli" class="logo-with-text"/>
        <img src="assets/img/logos/frankli-logo.svg" alt="Frankli" class="logo-icon-only"/>
      </div>

      <div class="navbar-spacer"></div>

      <ng-container *ngIf="canSeeUniversalCreate">
        <div class="display-navbar-universal-create dropdown">
          <a class="clickable dropdown-toggle" data-toggle="dropdown">
            <button class="btn-frankli-round btn-frankli-small btn-frankli-green-inverted universal-create-button"
              angulartics2On="click" angularticsAction="ClickCreateButtonUCB" angularticsCategory="Navbar"
              [attr.data-intercom-target]="'Universal Create'">
              <span class="fal fa-fw fa-plus"></span>
              <span calss="m-right-5"></span>
              <span>{{eNavbarMessages.CREATE | translate}}</span>
            </button>
          </a>
          <ul class="dropdown-menu dropdown-with-icons universal-create-dropdown">
            <ng-container *ngIf="globals.hasFeature(eCompanyFeatures.GOALS)">
              <li>
                <a [routerLink]="['/goals']" [queryParams]="{action: 'create'}" angulartics2On="click"
                  angularticsAction="ClickCreateGoalsUCB" angularticsCategory="Navbar"
                  [attr.data-intercom-target]="'Create Goals'">
                  <span class="fal fa-fw fa-bullseye"></span>
                  <span class="m-right-5"></span>
                  <span>{{eNavbarMessages.GOAL | translate}}</span>
                </a>
              </li>
            </ng-container>
            <ng-container *ngIf="globals.hasFeature(eCompanyFeatures.ONE_TO_ONE)">
              <li>
                <a [routerLink]="['/one-to-one/create']" angulartics2On="click" angularticsAction="ClickOneToOneUCB"
                  angularticsCategory="Navbar" [attr.data-intercom-target]="'Create 1:1'">
                  <span class="fal fa-fw fa-calendar-day"></span>
                  <span class="m-right-5"></span>
                  <span>{{eNavbarMessages.ONE_TO_ONE_MEETING | translate}}</span>
                </a>
              </li>
            </ng-container>
            <ng-container *ngIf="globals.hasFeature(eCompanyFeatures.TODOS)">
              <li>
                <a [routerLink]="['/tasks']" [queryParams]="{action: 'create'}" angulartics2On="click" angularticsAction="ClickGoalUCB"
                  angularticsCategory="Navbar" [attr.data-intercom-target]="'Create Task'">
                  <span class="fal fa-fw fa-tasks"></span>
                  <span class="m-right-5"></span>
                  <span>{{eNavbarMessages.TASK | translate}}</span>
                </a>
              </li>
              <li class="divider"></li>
            </ng-container>
            <ng-container *ngIf="globals.hasFeature(eCompanyFeatures.FEEDBACK)">
              <li *ngIf="globals.hasFeature(eCompanyFeatures.FEEDBACK_PRAISE)">
                <a [routerLink]="['/feedback/give/praise']" angulartics2On="click" angularticsAction="ClickPraiseUCB"
                  angularticsCategory="Navbar" [attr.data-intercom-target]="'Create Praise'">
                  <span class="fal fa-fw fa-thumbs-up"></span>
                  <span class="m-right-5"></span>
                  <span>{{eNavbarMessages.PRAISE | translate}}</span>
                </a>
              </li>
              <li *ngIf="globals.hasFeature(eCompanyFeatures.FEEDBACK_IDEAS)">
                <a [routerLink]="['/feedback/give/ideas']" angulartics2On="click" angularticsAction="ClickIdeasUCB"
                  angularticsCategory="Navbar" [attr.data-intercom-target]="'Create Ideas'">
                  <span class="fal fa-fw fa-lightbulb"></span>
                  <span class="m-right-5"></span>
                  <span>{{eNavbarMessages.IDEA | translate}}</span>
                </a>
              </li>
              <li *ngIf="globals.hasFeature(eCompanyFeatures.FEEDBACK_PEER)">
                <a [routerLink]="['/feedback/give/peers']" angulartics2On="click"
                  angularticsAction="ClickPeerFeedbackUCB" angularticsCategory="Navbar"
                  [attr.data-intercom-target]="'Create Peer Feedback'">
                  <span class="fal fa-fw fa-user-check"></span>
                  <span class="m-right-5"></span>
                  <span>{{eNavbarMessages.PEER_FEEDBACK | translate}}</span>
                </a>
              </li>
              <li *ngIf="globals.hasFeature(eCompanyFeatures.FEEDBACK_REQUESTS)">
                <a [routerLink]="['/feedback/request']" angulartics2On="click"
                  angularticsAction="ClickRequestFeedbackUCB" angularticsCategory="Navbar"
                  [attr.data-intercom-target]="'Create Feedback Request'">
                  <span class="fal fa-fw fa-undo"></span>
                  <span class="m-right-5"></span>
                  <span>{{eNavbarMessages.REQUEST_FEEDBACK | translate}}</span>
                </a>
              </li>
              <li *ngIf="globals.hasFeature(eCompanyFeatures.FEEDBACK_UNPROMPTED)">
                <a [routerLink]="['/feedback/give/unprompted']" angulartics2On="click"
                  angularticsAction="ClickUnpromptedFeedbackUCB" angularticsCategory="Navbar"
                  [attr.data-intercom-target]="'Create Unprompted Feedback'">
                  <span class="fal fa-fw fa-bullhorn"></span>
                  <span class="m-right-5"></span>
                  <span>{{eNavbarMessages.UNPROMPTED_FEEDBACK | translate}}</span>
                </a>
              </li>
              <li class="divider"></li>
            </ng-container>
            <ng-container
              *ngIf="globals.hasFeature(eCompanyFeatures.SURVEYS) && (globals.hasRole(eRoleName.FRANKLI_ADMIN) || globals.hasRole(eRoleName.ADMIN) || globals.hasRole(eRoleName.MANAGER))">
              <li>
                <a [routerLink]="['/surveys/overview/sent']" angulartics2On="click" angularticsAction="ClickSurveyUCB"
                  angularticsCategory="Navbar" [attr.data-intercom-target]="'Create Survey'">
                  <span class="fal fa-fw fa-clipboard"></span>
                  <span class="m-right-5"></span>
                  <span>{{eNavbarMessages.SURVEY | translate}}</span>
                </a>
              </li>
            </ng-container>
            <ng-container *ngIf="globals.hasMinimumAccessLevelOf(eRoleName.ADMIN)">
              <li>
                <a [routerLink]="['/admin/dashboard/company/company-view']" angulartics2On="click"
                  angularticsAction="ClickPersonUCB" angularticsCategory="Navbar"
                  [attr.data-intercom-target]="'Add People'">
                  <span class="fal fa-fw fa-user"></span>
                  <span class="m-right-5"></span>
                  <span>{{eNavbarMessages.NEW_PERSON | translate}}</span>
                </a>
              </li>
            </ng-container>
          </ul>
        </div>
      </ng-container>

      <!-- <div class="display-navbar-help">
        <a [title]="eNavbarMessages.HELP_CENTER | translate" target="_blank" href="http://help.frankli.io" angulartics2On="click" angularticsAction="ClickHelpCenterNav" angularticsCategory="Navbar" [attr.data-intercom-target]="'Open Help Center'" class="no-external-icon">
          <span class="fal fa-fw fa-question-circle"></span>
        </a>
      </div> -->

      <div class="display-navbar-locale">
        <app-language-selector></app-language-selector>
      </div>

      <ng-container *ngIf="newFeatures && (newFeatures.unreadCount > 0 && newFeatures.all.length > 0)">
        <div class="display-navbar-announcements">
          <a class="clickable" title="New Features" angulartics2On="click" angularticsAction="ClickNewFeaturesNotificationNav"
            angularticsCategory="Navbar" data-intercom-target="View new features" (click)="openNewFeatures()">
            <span class="fal fa-fw fa-gift frankli-view"></span>
            <span class="m-right-5"></span>
            <span class="new-feature-count-text">{{(newFeatures.unreadCount > 9) ? '9+' : newFeatures.unreadCount}}</span>
            <span></span>
          </a>
        </div>
      </ng-container>

      <div class="display-navbar-notifications">
        <a href="#" class="dropdown-toggle" data-toggle="dropdown" (click)="openNotificationsPanel()">
          <span class="fal fa-fw fa-bell"></span>
          <span class="notification-counter" *ngIf="(notifications.length + actions.length) > 0">
            <ng-container *ngIf="(notifications.length + actions.length) < 9; else templateLotsOfNotifications;">
              <span>{{(notifications.length + actions.length)}}</span>
            </ng-container>
            <ng-template #templateLotsOfNotifications>
              <span>9+</span>
            </ng-template>
          </span>
        </a>
      </div>

      <div class="display-navbar-user-and-dropdown">
        <ng-container *ngIf="globals.user">
          <div class="display-navbar-current-user" angulartics2On="click" angularticsAction="ClickProfileNavbar" angularticsCategory="Navbar" [attr.data-intercom-target]="'View Profile'" [routerLink]="['/profile']">
            <span class="owner-img">
              <app-profile-photo [src]="globals.user.imageUrl" [firstName]="globals.user.firstName" [lastName]="globals.user.lastName"></app-profile-photo>
            </span>
            <!-- <span class="m-right-10"></span> -->
            <!-- <span>{{globals.user.firstName}}</span> -->
          </div>
        </ng-container>
  
        <div class="display-navbar-more-options dropdown">
          <a class="navbar-user clickable dropdown-toggle" data-toggle="dropdown">
            <span class="fal fa-fw fa-chevron-down"></span>
          </a>
          <ul class="dropdown-menu dropdown-with-icons">
            <li>
              <a [routerLink]="['/profile']" angulartics2On="click" angularticsAction="ViewProfileDDL"
                angularticsCategory="Navbar" [attr.data-intercom-target]="'View Profile Dropdown'">
                <span class="fal fa-fw fa-user"></span>
                <span class="m-right-5"></span>
                <span>{{eNavbarMessages.VIEW_PROFILE | translate}}</span>
              </a>
            </li>
            <li class="divider"></li>
            <li>
              <a [routerLink]="['/settings']" angulartics2On="click" angularticsAction="EditProfileDDL"
                angularticsCategory="Navbar" [attr.data-intercom-target]="'Edit Profile Dropdown'">
                <span class="fal fa-fw fa-cog"></span>
                <span class="m-right-5"></span>
                <span>{{eSettingsMessages.SETTINGS | translate}}</span>
              </a>
            </li>
            <ng-container *ngIf="newFeatures.all.length > 0">
              <li class="divider"></li>
              <li class="clickable" (click)="openNewFeatures()">
                <a angulartics2On="click" angularticsAction="ClickNewFeaturesNavbar" angularticsCategory="Support"
                  [attr.data-intercom-target]="'New Features Dropdown'">
                  <span class="fal fa-fw fa-gift"></span>
                  <span class="m-right-5"></span>
                  <span>{{eNavbarMessages.WHATS_NEW | translate}}</span>
                </a>
              </li>
            </ng-container>
            <li class="divider"></li>
              <li class="clickable" (click)="logout()" *ngIf="!isMock && !globals.usingTeams">
                <a angulartics2On="click" angularticsAction="LogoutNavbar" angularticsCategory="Support"
                  [attr.data-intercom-target]="'Click LogOut'">
                  <span class="fal fa-fw fa-times-circle"></span>
                  <span class="m-right-5"></span>
                  <span>{{eNavbarMessages.LOGOUT | translate}}</span>
                </a>
              </li>
          </ul>
        </div>
      </div>

      <div class="display-navbar-mobile-hamburger-menu">
        <app-hamburger-menu [opened]="sidebarService.sidebarOpen" (click)="sidebarToggle()"></app-hamburger-menu>
      </div>

    </div>
  </div>
  
  <!-- #region - Notifications/Task Popup -->
  <app-navbar-notifications-component [notificationsShown]="notificationsShown" [connected]="connected"
    [notifications]="notifications" [actions]="actions" [markingAsRead]="markingAsRead"
    (markAllAsRead)="onMarkAllAsRead($event)" (markAsRead)="onMarkAsRead($event)" (markAsViewed)="onMarkAsViewed($event)">
  </app-navbar-notifications-component>
  <!-- #endregion -->
  
  <!-- New features modal -->
  <ng-container *ngIf="newFeatures && newFeatures.all.length > 0">
    <app-modal [width]="'60%'" [minWidth]="'400px'" #newFeaturesModal>
      <div class="text-left">
        <app-new-features-modal [features]="newFeatures.all"></app-new-features-modal>
      </div>
    </app-modal>
  </ng-container>
</div>