/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Input } from '@angular/core';
import { CommonMessages } from '@app/constants/common.messages';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {

  public readonly eCommonMessages = CommonMessages;

  @Input() title: string;
  @Input() titleParams: any;

  @Input() description: string;
  @Input() descriptionParams: any;

  @Input() helpLink?: string;

  constructor() {
    this.title = 'Default title',
    this.description = 'This is the default description';
    this.helpLink = undefined;
  }

}
