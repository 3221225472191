import { Mentor } from '@app/models/connect/mentor.model';
import { mockUsers } from '@app/mock/api/data/mockUsers';

export const mockMentors: Mentor[] = [
  {
    id: 1,
    companyId: 1,
    enabled: true,
    userId: mockUsers[0].id,
    user: mockUsers[0]
  },
  {
    id: 2,
    companyId: 1,
    enabled: true,
    userId: mockUsers[1].id,
    user: mockUsers[1]
  },
  {
    id: 3,
    companyId: 1,
    enabled: true,
    userId: mockUsers[2].id,
    user: mockUsers[2]
  },
  {
    id: 4,
    companyId: 1,
    enabled: true,
    userId: mockUsers[3].id,
    user: mockUsers[3]
  },
  {
    id: 5,
    companyId: 1,
    enabled: true,
    userId: mockUsers[4].id,
    user: mockUsers[4]
  },
  {
    id: 6,
    companyId: 1,
    enabled: true,
    userId: mockUsers[5].id,
    user: mockUsers[5]
  },
  {
    id: 7,
    companyId: 1,
    enabled: true,
    userId: mockUsers[6].id,
    user: mockUsers[6]
  },
  {
    id: 8,
    companyId: 1,
    enabled: true,
    userId: mockUsers[7].id,
    user: mockUsers[7]
  },
  {
    id: 9,
    companyId: 1,
    enabled: true,
    userId: mockUsers[8].id,
    user: mockUsers[8]
  },
  {
    id: 10,
    companyId: 1,
    enabled: true,
    userId: mockUsers[9].id,
    user: mockUsers[9]
  }
];