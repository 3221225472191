<div class="container-profile-interests">
  <div class="area-interests-professional">
    <div class="list-interests">
      <h4 class="standard-heading">Professional interests</h4>

      <ng-container *ngIf="interestsProfessional.length > 0; else templateNoProfessional;">
        <ng-container *ngFor="let interest of interestsProfessional; index as index;">
          <ng-container *ngIf="index < professionalInterestsVisible">
            <div class="item-interest">
              <div class="item-interest-name">{{interest.name}}</div>
              <div class="item-interest-users">
                <app-person-picture-list [users]="interest.sharedByUsers" [imageSizePx]="30"></app-person-picture-list>
              </div>
            </div>
          </ng-container>
        </ng-container>
      </ng-container>
      <ng-template #templateNoProfessional>
        <app-empty-state [height]="'200px'" [message]="'No professional interests seleected'"></app-empty-state>
      </ng-template>
    </div>

    <ng-container *ngIf="interestsProfessional.length > professionalInterestsVisible">
      <div class="prompt-show-more-interests" (click)="showMoreProfessionalInterests()">
        <span>Show more...</span>
      </div>
    </ng-container>
  </div>

  <div class="area-interests-personal">    
    <div class="list-interests">
      <h4 class="standard-heading">Personal interests</h4>

      <ng-container *ngIf="interestsProfessional.length > 0; else templateNoPersonal;">
        <ng-container *ngFor="let interest of interestsPersonal; index as index;">
          <ng-container *ngIf="index < personalInterestsVisible">
            <div class="item-interest">
              <div class="item-interest-name">{{interest.name}}</div>
              <div class="item-interest-users">
                <app-person-picture-list [users]="interest.sharedByUsers" [imageSizePx]="30"></app-person-picture-list>
              </div>
            </div>
          </ng-container>
        </ng-container>
      </ng-container>
      <ng-template #templateNoPersonal>
        <app-empty-state [height]="'200px'" [message]="'No professional interests seleected'"></app-empty-state>
      </ng-template>
    </div>

    <ng-container *ngIf="interestsPersonal.length > personalInterestsVisible">
      <div class="prompt-show-more-interests" (click)="showMorePersonalInterests()">
        <span>Show more...</span>
      </div>
    </ng-container>
  </div>
</div>