import { Injectable } from '@angular/core';
import { AdminMessages } from '@app/admin/admin.messages';
import { CreateInterestDto } from '@app/domain/interest/model/create-interest.model';
import { InterestType } from '@app/domain/interest/model/interest-type.model';
import { Interest } from '@app/domain/interest/model/interest.model';
import { UserMinimal } from '@app/models/user/user-minimal.model';
import { InterestAPIService } from '@app/domain/interest/api/interest-api.service';
import { UserAPIService } from '@app/shared/api/user.api.service';
import { Globals } from '@app/shared/globals/globals';
import { NotifyUtils } from '@app/shared/utils/notify.utils';
import { Observable } from 'rxjs';
import { SettingsMessages } from '../../settings/locale/settings.messages';

@Injectable({
  providedIn: 'root'
})
export class InterestsBusinessService {

  private updating: number[];
  private updatingInterests: boolean;
  private updatingProfessional: boolean;
  private uploadingFile: boolean;
  private downloadingCSV: boolean;

  constructor(
    public globals: Globals,
    private interestAPI: InterestAPIService,
    private userAPI: UserAPIService,
    private notifyUtils: NotifyUtils
  ) {
    this.updating = [];
    this.updatingInterests = false;
    this.updatingProfessional = false;
    this.uploadingFile = false;
    this.downloadingCSV = false;
  }

  private startUpdating(ids: number[]) {
    this.updating = [...this.updating, ...ids];
  }

  private stopUpdating(ids: number[]) {
    this.updating = this.updating.filter(x => !ids.includes(x));
  }

  create(createDto: CreateInterestDto): Promise<Interest> {
    return this.interestAPI.create(createDto).toPromise()
      .then(res => {
        this.notifyUtils.notify(AdminMessages.INTERESTS.SUCCESS_INTEREST_CREATE);
        return res;
      });
  }

  update(id: number, createDto: CreateInterestDto): Promise<Interest> {
    if (this.updating.includes(id)) { return new Promise((resolve, reject) => reject()); }
    this.startUpdating([id]);

    return this.interestAPI.update(id, createDto).toPromise()
      .then(res => {
        this.notifyUtils.notify(AdminMessages.INTERESTS.SUCCESS_INTEREST_UPDATE);
        return res;
      })
      .finally(() => {
        this.stopUpdating([id]);
      });
  }

  delete(id: number): Promise<Interest> {
    if (this.updating.includes(id)) { return new Promise((resolve, reject) => reject()); }
    this.startUpdating([id]);

    return this.interestAPI.delete(id).toPromise()
      .then(res => {
        if (res.approved) {
          this.notifyUtils.notify(AdminMessages.INTERESTS.SUCCESS_DELETE_INTEREST_SINGLE);
        } else {
          this.notifyUtils.notify(AdminMessages.INTERESTS.SUCCESS_PENDING_INTEREST_DECLINED);
        }

        return res;
      })
      .finally(() => {
        this.stopUpdating([id]);
      });
  }

  deleteMultiple(ids: number[]): Promise<Interest[]> {
    if (this.updating.some(id => ids.includes(id))) { return new Promise((resolve, reject) => reject()); }
    this.startUpdating(ids);

    return this.interestAPI.deleteMultiple(ids).toPromise()
      .then(res => {
        this.notifyUtils.notify(AdminMessages.INTERESTS.SUCCESS_DELETE_INTEREST_MULTIPLE);
        return res;
      })
      .finally(() => {
        this.stopUpdating(ids);
      });
  }

  approve(id: number): Promise<Interest> {
    if (this.updating.includes(id)) { return new Promise((resolve, reject) => reject()); }
    this.startUpdating([id]);

    return this.interestAPI.approvePendingInterest(id).toPromise()
      .then(res => {
        this.notifyUtils.notify(AdminMessages.INTERESTS.SUCCESS_PENDING_INTEREST_ACCEPTED);
        return res;
      })
      .finally(() => {
        this.stopUpdating([id]);
      });
  }

  importFile(file: File): Promise<Interest[]> {
    if (this.uploadingFile) { return new Promise((resolve, reject) => reject()); }
    this.uploadingFile = true;

    return this.interestAPI.importInterests(file).toPromise()
      .then(res => {
        this.notifyUtils.notify(AdminMessages.INTERESTS.SUCCESS_IMPORT_INTERESTS);
        return res;
      })
      .finally(() => {
        this.uploadingFile = false;
      });
  }

  getInterests(
    ids: number[],
    name: string,
    categories: string[],
    interestType: InterestType[],
    approved: boolean
  ): Observable<Interest[]> {
    return this.interestAPI.get(ids, name, categories, interestType, approved);
  }

  getAllUsersUsingInterest(id: number): Observable<UserMinimal[]> {
    return this.interestAPI.getAllUsersUsingInterest(id);
  }

  setInterests(interests: Interest[]): Promise<Interest[]> {
    if (this.updatingInterests) { return new Promise((resolve, reject) => reject()); }
    this.updatingInterests = true;

    return this.userAPI.updateInterests(interests).toPromise()
      .then(res => {
        this.notifyUtils.notify(SettingsMessages.SUCCESS_PROFILE_UPDATED);
        this.globals.user.personalInterests = res.personalInterests;
        this.globals.user.professionalInterests = res.professionalInterests;
        return [...res.personalInterests, ...res.professionalInterests];
      })
      .finally(() => {
        this.updatingInterests = false;
      });
  }

  downloadCSV(): Promise<Blob> {
    if (this.downloadingCSV) { return; }
    this.downloadingCSV = true;

    this.interestAPI.downloadCSV()
      .toPromise()
      .then(res => {
        const url = window.URL.createObjectURL(res);
        const a = document.createElement('a');
        a.href = url;
        a.download = `frankli-interests-${new Date().toISOString().split('T')[0]}.csv`;
        a.click();
      })
      .finally(() => {
        this.downloadingCSV = false;
      });
  }

}
