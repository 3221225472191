import {EvaluationCycleModule} from '@app/models/evaluation/evaluation-cycle-module.model';
import {EvaluationModuleType} from '@app/models/evaluation/evaluation-module-type.model';
import moment from 'moment';
import {EvaluationModuleState} from 'app/models/evaluation/evaluation-module-state.model';
import {ReviewSubjectAccessType} from "@models/evaluation/review-subject-access-type.model";

export const mockMidYearReviewModules: EvaluationCycleModule[] = [
    {
        id: 1,
        startDateTime: moment().month(4).startOf('month').toDate(),
        endDateTime: moment().month(6).endOf('month').toDate(),
        state: EvaluationModuleState.ACTIVATED,
        evaluationModuleType: EvaluationModuleType.SELF_REFLECTION,
        reviewSubjectAccessType: ReviewSubjectAccessType.LEGACY,
        stepNumber: 1
    },
    {
        id: 2,
        startDateTime: moment().month(4).startOf('month').toDate(),
        endDateTime: moment().month(6).endOf('month').toDate(),
        state: EvaluationModuleState.ACTIVATED,
        evaluationModuleType: EvaluationModuleType.MANAGER_REVIEW,
        reviewSubjectAccessType: ReviewSubjectAccessType.LEGACY,
        stepNumber: 1
    }
];

export const mockEndYearReviewModules: EvaluationCycleModule[] = [
    {
        id: 1,
        startDateTime: moment().subtract(1, 'year').month(7).startOf('month').toDate(),
        endDateTime: moment().subtract(1, 'year').month(11).endOf('month').toDate(),
        state: EvaluationModuleState.CLOSED,
        evaluationModuleType: EvaluationModuleType.SELF_REFLECTION,
        reviewSubjectAccessType: ReviewSubjectAccessType.LEGACY,
        stepNumber: 1
    },
    {
        id: 2,
        startDateTime: moment().subtract(1, 'year').month(7).startOf('month').toDate(),
        endDateTime: moment().subtract(1, 'year').month(11).endOf('month').toDate(),
        state: EvaluationModuleState.CLOSED,
        evaluationModuleType: EvaluationModuleType.MANAGER_REVIEW,
        reviewSubjectAccessType: ReviewSubjectAccessType.LEGACY,
        stepNumber: 1
    }
];

export const mockEvaluationCycleModules: EvaluationCycleModule[] = [
    ...mockMidYearReviewModules
];
