import moment from 'moment';

export class AbsorbLmsToken {
  id: number;
  username: string;
  password: string;
  privateKey: string;
  connected: boolean;
  lastUpdated: Date;
  error: string | null;
  syncing: boolean;
  lastSynced: Date | null;

  constructor(evaluagentTokenServerSide: AbsorbLmsTokenServerSide) {
    if (!evaluagentTokenServerSide) { return undefined; }

    this.id = evaluagentTokenServerSide.id;
    this.username = evaluagentTokenServerSide.username;
    this.password = evaluagentTokenServerSide.password;
    this.privateKey = evaluagentTokenServerSide.privateKey;
    this.connected = evaluagentTokenServerSide.connected;
    this.lastUpdated = moment.utc(evaluagentTokenServerSide.lastUpdated).toDate();
    this.error = evaluagentTokenServerSide.error;
    this.syncing = evaluagentTokenServerSide.syncing;
    this.lastSynced = evaluagentTokenServerSide.lastSynced ? moment.utc(evaluagentTokenServerSide.lastSynced).toDate() : null;
  }
}

export interface AbsorbLmsTokenServerSide {
  id: number;
  username: string;
  password: string;
  privateKey: string;
  connected: boolean;
  lastUpdated: string;
  error: string | null;
  syncing: boolean;
  lastSynced: string | null;
}

export interface UpdateAbsorbLmsToken {
  privateKey: string;
  username: string;
  password: string;
}
