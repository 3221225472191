import { Component, EventEmitter, Input, Output } from '@angular/core';
import { EditingFrom, ModalType } from '../administrate-schedule-cycle-question-banks.component';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { SurveyQuestionType } from '@app/models/survey-question-type.enum';
import { ButtonType } from '@app/shared/components/inputs/button/button.component';
import { EvaluationCycleQuestion } from '@app/models/evaluation/evaluation-cycle-question.model';
import { QuestionBankQuestion } from '@app/models/evaluation/question-bank-question.model';
import { DynamicScalePossibleAnswer } from '@app/models/evaluation/dynamic-scale-possible-answer.model';
import { FrankliValidators } from '@app/shared/validators/validators';

enum ComponentAction {
  CANCEL = 'CANCEL',
  SUBMIT = 'SUBMIT'
}

const DEFAULT_ANSWER_COLOR = '#CCCCCC';

const DEFAULT_ANSWER_OPTIONS: DynamicScalePossibleAnswer[] = [
  {
    id: null,
    answerIndex: 0,
    answerValue: '1',
    colour: DEFAULT_ANSWER_COLOR,
    labelTitle: 'Option 1',
    labelDescription: ''
  },
  {
    id: null,
    answerIndex: 1,
    answerValue: '2',
    colour: DEFAULT_ANSWER_COLOR,
    labelTitle: 'Option 2',
    labelDescription: ''
  },
  {
    id: null,
    answerIndex: 2,
    answerValue: '3',
    colour: DEFAULT_ANSWER_COLOR,
    labelTitle: 'Option 3',
    labelDescription: ''
  },
  {
    id: null,
    answerIndex: 3,
    answerValue: '4',
    colour: DEFAULT_ANSWER_COLOR,
    labelTitle: 'Option 4',
    labelDescription: ''
  },
  {
    id: null,
    answerIndex: 4,
    answerValue: '5',
    colour: DEFAULT_ANSWER_COLOR,
    labelTitle: 'Option 5',
    labelDescription: ''
  }
];

export const initFormAddReviewQuestion = (editingFrom?: EditingFrom) => {
  const formGroup = new FormGroup({
    text: new FormControl('', [Validators.required, Validators.maxLength(255)]),
    type: new FormControl(null, [Validators.required]),
    index: new FormControl(null, []),
    editingFrom: new FormControl(EditingFrom.CYCLE_LIST, []),
    options: initFormArrayOptionItems()
  });

  if (editingFrom) {
    formGroup.controls.editingFrom.setValue(editingFrom, { emitEvent: false } );
  }

  formGroup.controls.type.valueChanges.subscribe((type: SurveyQuestionType) => {
    switch (type) {
      case SurveyQuestionType.DYNAMIC_SCALE:
        formGroup.controls.options.setValidators([
          FrankliValidators.minLengthArray(2),
          FrankliValidators.formArrayControlValuesMustBeUnique('answerValue')
        ]);
        formGroup.controls.options.updateValueAndValidity();
        break;
      default:
        formGroup.controls.options.setValidators([]);
        formGroup.controls.options.updateValueAndValidity();
        break;
    }
  });

  return formGroup;
};

export const initFormAddReviewQuestionEC = (question: EvaluationCycleQuestion, editingFrom?: EditingFrom): FormGroup => {
  const formGroup = initFormAddReviewQuestion(editingFrom);

  if (question) {
    formGroup.controls.text.setValue(question.questionText, { emitEvent: false } );
    formGroup.controls.type.setValue(question.questionBankQuestion.surveyQuestionType, { emitEvent: false } );
    formGroup.controls.index.setValue(question.orderIndex, { emitEvent: false } );
    formGroup.controls.options = initFormArrayOptionItems(question.questionBankQuestion.possibleAnswers);
  }

  return formGroup;
};

export const initFormAddReviewQuestionQB = (question: QuestionBankQuestion, editingFrom?: EditingFrom): FormGroup => {
  const formGroup = initFormAddReviewQuestion(editingFrom);

  if (question) {
    formGroup.controls.text.setValue(question.questionText, { emitEvent: false } );
    formGroup.controls.type.setValue(question.surveyQuestionType, { emitEvent: false } );
    formGroup.controls.options = initFormArrayOptionItems(question.possibleAnswers);
  }

  return formGroup;
};

export const initFormGroupOptionItem = (possibleAnswer?: DynamicScalePossibleAnswer): FormGroup => {
  const formGroup = new FormGroup({
    id: new FormControl(null, []),
    answerIndex: new FormControl(0, []),
    answerValue: new FormControl('1', [Validators.required]),
    colour: new FormControl(DEFAULT_ANSWER_COLOR, [Validators.required]),
    labelTitle: new FormControl('', [Validators.required, Validators.maxLength(255)]),
    labelDescription: new FormControl('', [Validators.maxLength(2000)])
  });

  if (possibleAnswer) {
    formGroup.controls.id.setValue(possibleAnswer.id, { emitEvent: false } );
    formGroup.controls.answerIndex.setValue(possibleAnswer.answerIndex, { emitEvent: false } );
    formGroup.controls.answerValue.setValue(possibleAnswer.answerValue, { emitEvent: false } );
    formGroup.controls.colour.setValue(possibleAnswer.colour, { emitEvent: false } );
    formGroup.controls.labelTitle.setValue(possibleAnswer.labelTitle, { emitEvent: false } );
    formGroup.controls.labelDescription.setValue(possibleAnswer.labelDescription, { emitEvent: false } );
  }

  return formGroup;
};

export const initFormArrayOptionItems = (possibleAnswers?: DynamicScalePossibleAnswer[]): FormArray => {
  const formArray = new FormArray([]);

  if (possibleAnswers && possibleAnswers.length > 0) {
    possibleAnswers.forEach((possibleAnswer: DynamicScalePossibleAnswer) => {
      formArray.push(initFormGroupOptionItem(possibleAnswer));
    });
  } else {
    DEFAULT_ANSWER_OPTIONS.forEach((possibleAnswer: DynamicScalePossibleAnswer) => {
      formArray.push(initFormGroupOptionItem(possibleAnswer));
    });
  }

  return formArray;
};

@Component({
  selector: 'app-schedule-cycle-add-question',
  templateUrl: './schedule-cycle-add-question.component.html',
  styleUrls: ['./schedule-cycle-add-question.component.scss']
})
export class ScheduleCycleAddQuestionComponent {
  public readonly CUSTOMIZABLE_QUESTION_TYPES = [ SurveyQuestionType.DYNAMIC_SCALE ];
  public readonly eSurveyQuestionType = SurveyQuestionType;
  public readonly eComponentAction = ComponentAction;
  public readonly eButtonType = ButtonType;
  public readonly eModalType = ModalType;

  @Input() modalActive: ModalType;
  @Input() addQuestionForm: FormGroup;

  @Output() onClickAction: EventEmitter<ComponentAction>;

  submitted: boolean;
  customizingScale: boolean;

  get canCustomizeQuestionType(): boolean {
    switch (this.modalActive) {
      case ModalType.ADD_QUESTION:
        return this.CUSTOMIZABLE_QUESTION_TYPES.includes(this.controlType.value);
      case ModalType.EDIT_QUESTION:
        if (this.addQuestionForm.controls.editingFrom.value === EditingFrom.FRANKLI_BANK) {
          return this.CUSTOMIZABLE_QUESTION_TYPES.includes(this.controlType.value);
        }

        return false;
    }
  }

  get controlText(): FormControl {
    return this.addQuestionForm.controls.text as FormControl;
  }

  get controlType(): FormControl {
    return this.addQuestionForm.controls.type as FormControl;
  }

  get controlIndex(): FormControl {
    return this.addQuestionForm.controls.index as FormControl;
  }

  get formAnswerOptions(): FormArray {
    return this.addQuestionForm.controls.options as FormArray;
  }

  constructor() {
    this.modalActive = null;
    this.customizingScale = false;
    this.onClickAction = new EventEmitter<ComponentAction>();
    this.addQuestionForm = initFormAddReviewQuestion();
  }


  navigatePrevious(): void {
    return this.doAction(ComponentAction.CANCEL);
  }

  navigateNext(event?: KeyboardEvent): void {
    if (event) {
      event.preventDefault();
    }

    this.submitted = true;

    const currentStepIsValid = this.checkIsCurrentStepValid();
    if(!currentStepIsValid) {
      return;
    }

    return this.doAction(ComponentAction.SUBMIT);
  }

  checkIsCurrentStepValid(): boolean {
    if (this.canCustomizeQuestionType) {
      return this.controlText.valid && this.controlType.valid && this.formAnswerOptions.valid;
    } else {
      return this.controlText.valid && this.controlType.valid;
    }
  }

  setQuestionType(type: SurveyQuestionType) {
    this.addQuestionForm.controls.type.setValue(type);
  }

  setModalType(type: ModalType): void {
    this.modalActive = type;
  }

  doAction(action: ComponentAction, event?: Event): void {
    if (event) {
      event.preventDefault();
    }

    this.onClickAction.emit(action);
  }

  startEditOptions(): void {
    if (!this.canCustomizeQuestionType) { return; }
    this.customizingScale = true;
  }

  leaveEditOptions(): void {
    this.customizingScale = false;
  }
}
