import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { EvaluationFeedbackRequestState } from '@app/models/evaluation/evaluation-feedback-request-state.model';
import { EvaluationFeedbackRequest } from '@app/models/evaluation/evaluation-feedback-request.model';
import { Globals } from '@app/shared/globals/globals';
import { ModalComponent } from '@app/shared/modal/modal.component';

enum StepClass {
  RED = 'step-red',
  YELLOW = 'step-yellow',
  GREEN = 'step-green',
  GRAY = 'step-regular'
}

interface StepState {
  class: StepClass;
  iconClass: string;
  tooltipText: string;
}

const DEFAULT_STEP_STATE: StepState = {
  class: StepClass.GRAY,
  iconClass: '',
  tooltipText: ''
};

@Component({
  selector: 'app-peer-review-progress-bar',
  templateUrl: './peer-review-progress-bar.component.html',
  styleUrls: ['./peer-review-progress-bar.component.scss']
})
export class PeerReviewProgressBarComponent implements OnChanges {
  public readonly eEvaluationFeedbackRequestState = EvaluationFeedbackRequestState;

  @Input() request: EvaluationFeedbackRequest;
  @Input() canViewDeclineReason: boolean;
  
  @ViewChild('declineReasonModal') declineReasonModal: ModalComponent;

  stepSent: StepState;
  stepManager: StepState;
  stepPeer: StepState;
  stepEdit: StepState;
  stepSubmit: StepState;

  get canClickManager(): boolean {
    if (!this.request) { return false; }
    if (!this.canViewDeclineReason) { return false; }
    return (this.request.state === EvaluationFeedbackRequestState.DECLINED_MANAGER);
  }

  get canClickPeer(): boolean {
    if (!this.request) { return false; }
    if (!this.canViewDeclineReason) { return false; }
    return (this.request.state === EvaluationFeedbackRequestState.DECLINED_PEER);
  }

  constructor(
    public globals: Globals
  ) {
    this.canViewDeclineReason = true;
  }

  ngOnChanges(changes: SimpleChanges): void {
    const changesRequest = changes['request'];

    if (changesRequest) {
      this.updateStatus();
    }
  }

  updateStatus(): void {
    this.stepSent = this.getStatusSent();
    this.stepManager = this.getStatusManager();
    this.stepPeer = this.getStatusPeer();
    this.stepEdit = this.getStatusEdit();
    this.stepSubmit = this.getStatusSubmit();
  }

  getStatusSent(): StepState {
    switch (this.request.state) {
      case EvaluationFeedbackRequestState.PENDING_MANAGER:
      case EvaluationFeedbackRequestState.PENDING_PEER:
      case EvaluationFeedbackRequestState.DECLINED_PEER:
      case EvaluationFeedbackRequestState.APPROVED:
      case EvaluationFeedbackRequestState.COMPLETED:
      case EvaluationFeedbackRequestState.DECLINED_MANAGER:
        return {
          class: StepClass.GREEN,
          iconClass: 'fal fa-paper-plane',
          tooltipText: 'Invite has been sent'
        };
      case EvaluationFeedbackRequestState.EXPIRED:
        return {
          class: StepClass.RED,
          iconClass: 'fal fa-paper-plane',
          tooltipText: 'Request has expired'
        };
    }
  }

  getStatusManager(): StepState {
    if (this.request && this.request.state) {
      switch (this.request.state) {
        case EvaluationFeedbackRequestState.PENDING_MANAGER:
          return {
            class: StepClass.YELLOW,
            iconClass: 'fal fa-check',
            tooltipText: 'Waiting for the sender\'s manager to approve'
          };
        case EvaluationFeedbackRequestState.PENDING_PEER:
        case EvaluationFeedbackRequestState.DECLINED_PEER:
        case EvaluationFeedbackRequestState.APPROVED:
        case EvaluationFeedbackRequestState.COMPLETED:
          return {
            class: StepClass.GREEN,
            iconClass: 'fal fa-check',
            tooltipText: 'Request was approved by senders manager'
          };
        case EvaluationFeedbackRequestState.DECLINED_MANAGER:
          return {
            class: StepClass.RED,
            iconClass: 'fal fa-times',
            tooltipText: 'Request was declined by senders manager'
          };
        case EvaluationFeedbackRequestState.EXPIRED:
          return {
            class: StepClass.RED,
            iconClass: 'fal fa-check',
            tooltipText: 'Request has expired'
          };
      }
    }

    return DEFAULT_STEP_STATE;
  }

  getStatusPeer(): StepState {
    if (this.request && this.request.state) {
      switch (this.request.state) {
        case EvaluationFeedbackRequestState.PENDING_PEER:
          return {
            class: StepClass.YELLOW,
            iconClass: 'fal fa-check-double',
            tooltipText: 'Waiting for the recipient to approve'
          };
        case EvaluationFeedbackRequestState.APPROVED:
        case EvaluationFeedbackRequestState.COMPLETED:
          return {
            class: StepClass.GREEN,
            iconClass: 'fal fa-check-double',
            tooltipText: 'Request was approved by the recipient'
          };
        case EvaluationFeedbackRequestState.DECLINED_PEER:
          return {
            class: StepClass.RED,
            iconClass: 'fal fa-times',
            tooltipText: 'Request was declined by the recipient'
          };
        case EvaluationFeedbackRequestState.EXPIRED:
          return {
            class: StepClass.RED,
            iconClass: 'fal fa-check-double',
            tooltipText: 'Request has expired'
          };
        case EvaluationFeedbackRequestState.PENDING_MANAGER:
        case EvaluationFeedbackRequestState.DECLINED_MANAGER:
          return {
            class: StepClass.GRAY,
            iconClass: 'fal fa-check-double',
            tooltipText: ''
          };
      }
    }

    return DEFAULT_STEP_STATE;
  }

  getStatusEdit(): StepState {
    if (this.request && this.request.state) {
      switch (this.request.state) {
        case EvaluationFeedbackRequestState.APPROVED:
          return {
            class: StepClass.YELLOW,
            iconClass: 'fal fa-pen',
            tooltipText: 'Waiting for the recipient to respond'
          };
        case EvaluationFeedbackRequestState.COMPLETED:
          return {
            class: StepClass.GREEN,
            iconClass: 'fal fa-pen',
            tooltipText: 'The recipient has started writing their response'
          };
        case EvaluationFeedbackRequestState.EXPIRED:
          return {
            class: StepClass.RED,
            iconClass: 'fal fa-pen',
            tooltipText: 'Request has expired'
          };
        case EvaluationFeedbackRequestState.PENDING_PEER:
        case EvaluationFeedbackRequestState.DECLINED_PEER:
        case EvaluationFeedbackRequestState.PENDING_MANAGER:
        case EvaluationFeedbackRequestState.DECLINED_MANAGER:
          return {
            class: StepClass.GRAY,
            iconClass: 'fal fa-pen',
            tooltipText: ''
          };
      }
    }

    return DEFAULT_STEP_STATE;
  }

  getStatusSubmit(): StepState {
    if (this.request && this.request.state) {
      switch (this.request.state) {
        case EvaluationFeedbackRequestState.COMPLETED:
          return {
            class: StepClass.GREEN,
            iconClass: 'fal fa-envelope',
            tooltipText: 'Response was submitted by the recipient'
          };
        case EvaluationFeedbackRequestState.EXPIRED:
          return {
            class: StepClass.RED,
            iconClass: 'fal fa-envelope',
            tooltipText: 'Request has expired'
          };
        case EvaluationFeedbackRequestState.APPROVED:
        case EvaluationFeedbackRequestState.PENDING_PEER:
        case EvaluationFeedbackRequestState.DECLINED_PEER:
        case EvaluationFeedbackRequestState.PENDING_MANAGER:
        case EvaluationFeedbackRequestState.DECLINED_MANAGER:
          return {
            class: StepClass.GRAY,
            iconClass: 'fal fa-envelope',
            tooltipText: ''
          };
      }
    }

    return DEFAULT_STEP_STATE;
  }

  onClickBubble(step: 'sent' | 'manager' | 'peer' | 'edit' | 'submit'): void {
    switch(step) {
      case 'manager':
        if (this.canClickManager) {
          this.showDeclineReasonModal();
        }
        break;
      case 'peer':
        if (this.canClickPeer) {
          this.showDeclineReasonModal();
        }
        break;
      default:
        return;
    }
  }

  showDeclineReasonModal(): void {
    if (this.declineReasonModal) {
      this.declineReasonModal.show();
    }
  }

  hideDeclineReasonModal(): void {
    if (this.declineReasonModal) {
      this.declineReasonModal.hide();
    }
  }
}
