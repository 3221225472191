import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Competency } from '@app/domain/competency/model/competency.model';
import { CompetencyBusinessService } from '@app/domain/competency/service/competency-business.service';
import { SkillRatingAPIService } from '@app/domain/skill_rating/api/skill-rating-api.service';
import { SkillRatingConfiguration } from '@app/domain/skill_rating/model/skill-rating-configuration.model';
import { SkillRatingType } from '@app/domain/skill_rating/model/skill-rating-type.model';
import { SkillRating } from '@app/domain/skill_rating/model/skill-rating.model';
import { TerminologyEntity } from '@app/domain/terminology/model/terminology-entity.enum';
import { CompanyFeatures } from '@app/models/company-features.model';
import { IState } from '@app/models/state/state.model';
import { Globals } from '@app/shared/globals/globals';
import { PaginationNewComponent } from '@app/shared/pagination/pagination-new/pagination-new.component';
import { forkJoin } from 'rxjs';

interface PageCompetency extends Competency {
  skillRating: SkillRating;
}

type TabEntity = PageCompetency;

@Component({
  selector: 'app-information-sidebar-competencies',
  templateUrl: './information-sidebar-competencies.component.html',
  styleUrls: ['./information-sidebar-competencies.component.scss']
})
export class InformationSidebarCompetenciesComponent implements OnInit {

  public readonly eTerminologyEntity = TerminologyEntity;
  public readonly eCompanyFeatures = CompanyFeatures;
  
  @ViewChild('pagination') pagination?: PaginationNewComponent;

  @Input() userIds: number[];
  @Input() userIdsManaging: number[];

  state: IState;
  data: {
    master: TabEntity[];
    filtered: TabEntity[];
    display: TabEntity[];
  };

  skillRatings: SkillRating[];
  skillRatingConfiguration: SkillRatingConfiguration;
  isManagingSingleUser: boolean;

  constructor(
    public globals: Globals,
    private competencyBusinessService: CompetencyBusinessService,
    private skillRatingAPIService: SkillRatingAPIService
  ) {
    this.userIds = [];
    this.skillRatings = [];
    this.userIdsManaging = [];

    this.isManagingSingleUser = false;

    this.state = {
      loading: true,
      error: false,
      errorMessage: ''
    };

    this.data = {
      master: [],
      filtered: [],
      display: []
    };
  }

  ngOnInit(): void {
    this.getData();
  }
  
  getData(): void {
    if (!this.userIds) { return; }
    if (this.userIds.length === 0) { return; }

    if (this.userIds.length > 1) {
      this.getAllCompetenciesData();
      return;
    }

    this.isManagingSingleUser = this.userIdsManaging.includes(this.userIds[0]);
    if (this.globals.hasFeature(CompanyFeatures.SKILL_RATING)) {
      this.getSkillRatingData(this.userIds[0])
        .then(() => this.getCompetenciesDataForUser(this.userIds[0]));
    } else {
      this.getCompetenciesDataForUser(this.userIds[0]);
    }
  }

  getSkillRatingData(userId: number): Promise<void> {
    return forkJoin([
      this.skillRatingAPIService.getForUser(userId),
      this.skillRatingAPIService.getConfiguration()
    ]).toPromise()
      .then(([skillRatings, skillRatingConfiguration]) => {
        this.skillRatings = skillRatings;
        this.skillRatingConfiguration = skillRatingConfiguration;
      });
  }

  getCompetenciesDataForUser(userId: number): Promise<void> {
    return this.competencyBusinessService.getForUserId(userId)
      .then(competencies => {
        let dataParsed = this.parseCompetencyToPageCompetencyMultiple(competencies, this.skillRatings);
        dataParsed = this.limitRatingsToScale(dataParsed, this.skillRatingConfiguration);
        dataParsed = this.sortData(dataParsed);
        this.populateData(dataParsed);

        this.state.loading = false;
      });
  }

  getAllCompetenciesData(): Promise<void> {
    return this.competencyBusinessService.getCompetencies(null, null, null)
      .toPromise()
      .then(competencies => {
        let dataParsed = this.parseCompetencyToPageCompetencyMultiple(competencies, this.skillRatings);
        dataParsed = this.limitRatingsToScale(dataParsed, this.skillRatingConfiguration);
        dataParsed = this.sortData(dataParsed);
        this.populateData(dataParsed);

        this.state.loading = false;
      });
  }

  sortData(data: TabEntity[]): TabEntity[] {
    return data
      // Order by name
      .sort((a, b) => a.name.localeCompare(b.name))
      // Order by score
      .sort(this.sortBySkillRatingScore);
  }

  limitRatingsToScale(items: PageCompetency[], skillRatingConfiguration: SkillRatingConfiguration): PageCompetency[] {
    return items.map(item => {
      if (!item.skillRating) return item;

      if (item.skillRating.scoreValue > (skillRatingConfiguration.scoreCount - 1)) {
        item.skillRating.scoreValue = (skillRatingConfiguration.scoreCount - 1);
      }

      return item;
    });
  }

  sortBySkillRatingScore(a: PageCompetency, b: PageCompetency): number {
    if (!a.skillRating && !b.skillRating) return 0;
    if (!a.skillRating) return 1;
    if (!b.skillRating) return -1;

    return b.skillRating.scoreValue - a.skillRating.scoreValue;
  }

  populateData(data?: TabEntity[]): void {
    if (!data) { data = this.data.master; }
    this.data.master = data;
    this.data.filtered = data;

    // this.universalFilterData.searchProps = ['name'];
    // this.universalFilterData.filterOptions = data.map(i => this.getFilterOptionsForBrowseRole(i));

    this.refreshPagination();
  }

  refreshPagination(): void {
    if (this.pagination) {
      this.pagination.update();
    }
  }

  parseCompetencyToPageCompetencyMultiple(data: Competency[], ratings: SkillRating[]): PageCompetency[] {
    return data.map(competency => this.parseCompetencyToPageCompetency(competency, ratings));
  }

  parseCompetencyToPageCompetency(competency: Competency, ratings: SkillRating[]): PageCompetency {
    const output: PageCompetency = competency as PageCompetency;
  
    if (output) {
      output.skillRating = ratings.find(rating => rating.skillRatingType === SkillRatingType.COMPETENCY && rating.targetEntityId === competency.id);
    }
  
    return output;
  }

}
