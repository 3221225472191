<div class="container-long-running-task-display" *ngIf="task">
  <div class="long-running-task-details-heading">
    <h4 class="standard-heading">
      <ng-container [ngSwitch]="task.taskType">
        <span *ngSwitchCase="eLongRunningTaskType.TEST_TASK">Test task</span>
        <span *ngSwitchCase="eLongRunningTaskType.GOAL_DISTRIBUTION">Goal distribution</span>
        <span *ngSwitchDefault>Unknown</span>
      </ng-container>
      <span>&nbsp;on {{task.createdAt | date: 'dd MMM YYYY HH:mm'}}</span>
    </h4>
    <ng-container *ngIf="canRemoveTask">
      <div (click)="onClickRemoveEvent()">
        <app-table-action-icon [icon]="'fa-times'" [tooltipText]="'Hide this job'" [hoverColor]="eIconHoverColor.RED" [boxSize]="'26px'" [iconSize]="'12px'"></app-table-action-icon>
      </div>
    </ng-container>
  </div>
  <div class="progress-event-bar">
    <app-progress-bar [currentValue]="task.getHighestProgress()"></app-progress-bar>
  </div>
  <ng-container *ngIf="task.hasEvents()">
    <div class="progress-event-header" (click)="toggleDropdownOpen()">
      <span class="progress-event-header-dropdown-indicator">
        <app-table-dropdown-toggle [opened]="dropdownOpen" [size]="'18px'"></app-table-dropdown-toggle>
      </span>
      <span class="progress-event-header-text">{{dropdownOpen ? 'Hide' : 'Show'}} events</span>
      <span class="text-danger" *ngIf="task.getHasErroredEvents()">
        <span class="m-right-5"></span>
        <span>(There were errors during this job)</span>
      </span>
    </div>
    <div class="progress-event-list" *ngIf="dropdownOpen">
      <div class="progress-event-list-inner">
        <ng-container *ngFor="let event of task.events">
          <app-long-running-task-event-display [parentTask]="task.id" [event]="event"></app-long-running-task-event-display>
        </ng-container>
      </div>
    </div>
  </ng-container>
</div>
