import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TeamTaskPageUser, TeamTaskTableColumn } from '../../pages/team-tasks/team-tasks.component';
import { PillType } from '@app/shared/components/pill/pill.component';
import { TasksMessages } from '../../locale/tasks.messages';
import { Globals } from '@app/shared/globals/globals';
import { TerminologyEntity } from '@app/domain/terminology/model/terminology-entity.enum';

@Component({
  selector: 'app-team-tasks-row',
  templateUrl: './team-tasks-row.component.html',
  styleUrls: ['./team-tasks-row.component.scss'],
  host: {
    style: 'display: contents' // Used to flatten the component for the tree structure to look right
  }
})
export class TeamTasksRowComponent  {

  public readonly eTerminologyEntity = TerminologyEntity;
  public readonly eTableColumn = TeamTaskTableColumn;
  public readonly eTasksMessages = TasksMessages;
  public readonly ePillType = PillType;

  @Input() report: TeamTaskPageUser;
  @Input() enabledColumnKeys: number[];
  @Input() depth: number;

  @Output() onLoadChildren: EventEmitter<TeamTaskPageUser>;
  @Output() onOpenUserTasks: EventEmitter<TeamTaskPageUser>;

  constructor(
    public globals: Globals
  ) {
    this.report = undefined;

    this.enabledColumnKeys = [];

    this.depth = 0;

    this.onLoadChildren = new EventEmitter<TeamTaskPageUser>();
    this.onOpenUserTasks = new EventEmitter<TeamTaskPageUser>();
  }

  loadChildren(pageUser: TeamTaskPageUser): void {
    this.onLoadChildren.emit(pageUser);
  }

  openUserTasks(pageUser: TeamTaskPageUser): void {
    this.onOpenUserTasks.emit(pageUser);
  }

  getColspanForEmptyState(): number {
    const columnKeys = [
      TeamTaskTableColumn.TASK_COUNT,
      TeamTaskTableColumn.TASK_COUNT_COMPLETE,
      TeamTaskTableColumn.TASK_COUNT_IN_PROGRESS,
      TeamTaskTableColumn.TASK_COUNT_NOT_STARTED,
      TeamTaskTableColumn.TASK_COUNT_OVERDUE,
      TeamTaskTableColumn.AVERAGE_COMPLETION
    ];

    // Count how many column keys are in enabledColumnKeys
    const enabledColumnKeys = columnKeys.filter(columnKey => this.enabledColumnKeys.includes(columnKey));

    return enabledColumnKeys.length;
  }
}
