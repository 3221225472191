import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

@Pipe({
  name: 'momentPipe'
})
export class MomentPipe implements PipeTransform {
  previousValue!: Date | moment.Moment;
  previousArgs!: any[];
  resultCached!: string;

  transform(value: Date|moment.Moment, ...args: any[]): any {
    // Skip calculation if value hasn't changed
    if ((value === this.previousValue) && this.resultCached && (args = this.previousArgs)) {
      return this.resultCached;
    }
    this.previousValue = value;
    this.previousArgs = args;

    const [format] = args;
    const result = moment(value).format(format);

    this.resultCached = result;
    return result;
  }
}
