import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { OneToOneMeetingMinimal } from '@app/domain/one_to_one/model/one-to-one-meeting.model';
import { OneToOneScheduleDetailsView } from '@app/domain/one_to_one/model/one-to-one-schedule.model';
import { OneToOneBusinessService } from '@app/domain/one_to_one/service/one-to-one-business.service';
import { SentimentScaleOption } from '@app/domain/sentiment_scale/model/sentiment-scale-option.model';
import { SentimentScale } from '@app/domain/sentiment_scale/model/sentiment-scale.model';
import { SentimentScaleBusinessService } from '@app/domain/sentiment_scale/service/sentiment-scale-business.service';
import { IState } from '@app/models/state/state.model';
import { UserMinimal } from '@app/models/user/user-minimal.model';
import { UserAPIService } from '@app/shared/api/user.api.service';
import { Globals } from '@app/shared/globals/globals';
import { DateUtils } from '@app/shared/utils/date.utils';
import { forkJoin } from 'rxjs';

export interface ScheduleSentimentScaleTimeline {
  [meetingId: string]: ScheduleSentimentScaleTimelineMeeting;
}

export interface ScheduleSentimentScaleTimelineMeeting {
  meetingId: string;
  sentimentScaleId: number;
  usesNewScale: boolean;
  participantAnswers: {
    [userId: string]: ScheduleSentimentScaleTimelineMeetingScore;
  }
}

export interface ScheduleSentimentScaleTimelineMeetingScore {
  userId: number;
  answerId: number;
  lastUpdated: Date;
}

// Page versions of the interfaces

export interface PageScheduleSentimentScaleTimeline extends ScheduleSentimentScaleTimeline {
  [meetingId: string]: PageScheduleSentimentScaleTimelineMeeting;
}

export interface PageScheduleSentimentScaleTimelineMeeting extends ScheduleSentimentScaleTimelineMeeting {
  meeting: OneToOneMeetingMinimal;
  sentimentScale: SentimentScale;
  participantAnswers: {
    [userId: string]: PageScheduleSentimentScaleTimelineMeetingScore
  }
}

export interface PageScheduleSentimentScaleTimelineMeetingScore extends ScheduleSentimentScaleTimelineMeetingScore {
  user: UserMinimal;
}

@Component({
  selector: 'app-information-sidebar-sentiment-scale-timeline',
  templateUrl: './information-sidebar-sentiment-scale-timeline.component.html',
  styleUrls: ['./information-sidebar-sentiment-scale-timeline.component.scss']
})
export class InformationSidebarSentimentScaleTimelineComponent implements OnInit {

  @Input() schedule: OneToOneScheduleDetailsView;
  @ViewChild('chartOuter') chartOuter?: HTMLDivElement;

  data: PageScheduleSentimentScaleTimeline;
  state: IState;
  uniqueUsers: UserMinimal[];

  userYearAverages: { [userId: number]: SentimentScaleOption };
  latestMeetingSentimentScale: SentimentScale;
  currentFiscalYearStart: Date;
  firstMeetingIdOfFiscalYear: number;

  get uniqueMeetingIds(): number[] {
    if (!this.data) { return []; }
    return Object.keys(this.data).map(meetingId => +meetingId);
  }

  get uniqueUserIds(): number[] {
    return this.uniqueUsers.map(u => u.id);
  }

  constructor(
    public globals: Globals,
    private oneToOneBusinessService: OneToOneBusinessService,
    private sentimentScaleBusinessService: SentimentScaleBusinessService,
    private userApiService: UserAPIService
  ) {
    this.latestMeetingSentimentScale = undefined;
    this.schedule = undefined;
    this.data = undefined;
    this.firstMeetingIdOfFiscalYear = undefined;

    this.uniqueUsers = [];
    this.userYearAverages = {};

    this.state = {
      loading: true,
      error: false,
      errorMessage: ''
    };

    // At start of year (Jan 1st at 00:00)
    this.currentFiscalYearStart = this.getDefaultFiscalYear();
  }

  ngOnInit(): void {
    this.getData();
  }

  getData(): void {
    if (!this.schedule) { return; }
    this.currentFiscalYearStart = DateUtils.getStartOfFiscalYear(this.globals.company.fiscalYear);

    this.firstMeetingIdOfFiscalYear = this.getFirstMeetingIdOfFiscalYear();

    this.oneToOneBusinessService.getSentimentTimelineForSchedule(this.schedule.id)
      .toPromise()
      .then(data => {
        const uniqueSentimentScaleIds = this.getUniqueSentimentScaleIds(data);
        const uniqueUsers = this.getUniqueUserIds(this.schedule);

        forkJoin([
          this.userApiService.getUsersByIds(uniqueUsers),
          this.sentimentScaleBusinessService.getByIdIn(uniqueSentimentScaleIds)
        ])
          .toPromise()
          .then(([users, sentimentScales]) => {
            this.uniqueUsers = users;
            this.data = this.parseResponse(data, sentimentScales, users);
            this.userYearAverages = this.getUserYearAverages(this.data, this.uniqueUsers);
            this.state.loading = false;
            setTimeout(() => {
              this.tryScrollToChartEnd();
            }, 100);
          });
      });
  }

  getUniqueSentimentScaleIds(data: ScheduleSentimentScaleTimeline): number[] {
    const result = new Set<number>();

    for (const meetingId in data) {
      if (!Object.hasOwnProperty.call(data, meetingId)) { continue; }
      const meetingData = data[meetingId];
      if (!meetingData.sentimentScaleId) { continue; }
      result.add(meetingData.sentimentScaleId);
    }

    return Array.from(result);
  }

  getUniqueUserIds(schedule: OneToOneScheduleDetailsView): number[] {
    const result = new Set<number>();

    result.add(schedule.manager.id);
    schedule.participants.forEach(p => result.add(p.id));

    return Array.from(result);
  }

  parseResponse(data: ScheduleSentimentScaleTimeline, sentimentScales: SentimentScale[], users: UserMinimal[]): PageScheduleSentimentScaleTimeline {
    const result: PageScheduleSentimentScaleTimeline = {};

    let currentMeetingScaleId: number = null;

    for (const meetingId in data) {
      if (!Object.hasOwnProperty.call(data, meetingId)) { continue; }

      const meetingData = data[meetingId];

      const meeting = this.schedule.meetingList.find(m => m.id === +meetingId);
      if (!meeting) { continue; }

      const output: PageScheduleSentimentScaleTimelineMeeting = {
        ...meetingData,
        meeting: meeting,
        sentimentScale: null,
        usesNewScale: currentMeetingScaleId !== meetingData.sentimentScaleId,
        participantAnswers: {}
      };

      currentMeetingScaleId = meetingData.sentimentScaleId;

      const sentimentScale = sentimentScales.find(s => s.id === meetingData.sentimentScaleId);
      output['sentimentScale'] = sentimentScale;

      const participantAnswers: { [userId: string]: PageScheduleSentimentScaleTimelineMeetingScore } = {};
      const scheduleManagerAnswer = meetingData.participantAnswers[this.schedule.manager.id];
      if (scheduleManagerAnswer) {
        participantAnswers[this.schedule.manager.id] = {
          ...scheduleManagerAnswer,
          user: users.find(u => u.id === this.schedule.manager.id),
          answerId: scheduleManagerAnswer.answerId,
          lastUpdated: scheduleManagerAnswer.lastUpdated
        };
      } else {
        participantAnswers[this.schedule.manager.id] = {
          userId: this.schedule.manager.id,
          user: users.find(u => u.id === this.schedule.manager.id),
          answerId: null,
          lastUpdated: null
        };
      }

      for (const participantUser of this.schedule.participants) {
        const participantUserId = participantUser.id;
        const participantAnswerOutput: PageScheduleSentimentScaleTimelineMeetingScore = {
          userId: participantUserId,
          user: users.find(u => u.id === participantUserId),
          answerId: null,
          lastUpdated: null
        };

        const participantAnswerFound = meetingData.participantAnswers[participantUserId];
        if (participantAnswerFound) {
          participantAnswerOutput['answerId'] = participantAnswerFound.answerId;
          participantAnswerOutput['lastUpdated'] = participantAnswerFound.lastUpdated;
        }

        participantAnswers[participantUserId] = participantAnswerOutput;
      }
      output['participantAnswers'] = participantAnswers;

      result[meetingId] = output;
    }

    return result;
  }

  // getUserYearAverages(data: PageScheduleSentimentScaleTimeline, users: UserMinimal[]): { [userId: number]: SentimentScaleOption } {
  //   const result: { [userId: number]: SentimentScaleOption } = {};

  //   const meetingsThisyear = Object.values(data).filter(m => {
  //     if (!m.meeting.meetingTimestamp) { return false; }
  //     return new Date(m.meeting.meetingTimestamp).getFullYear() === new Date().getFullYear();
  //   });

  //   const latestMeeting = meetingsThisyear.reduce((acc, m) => {
  //     if (!acc) { return m; }
  //     if (!m.meeting.meetingTimestamp) { return acc; }
  //     const accDate = new Date(acc.meeting.meetingTimestamp);
  //     const mDate = new Date(m.meeting.meetingTimestamp);
  //     return accDate > mDate ? acc : m;
  //   });

  //   this.latestMeetingSentimentScale = latestMeeting.sentimentScale;
  //   const meetingsThisYearUsingSentimentScale = meetingsThisyear.filter(m => m.sentimentScaleId === latestMeeting.sentimentScaleId);

  //   // Count how many times each option occured and pick whichever one is most common
  //   // If two options are equally common, pick the one with lower score value (Low is better)
  //   for (const userId of this.uniqueUserIds) {
  //     const user = users.find(u => u.id === userId);
  //     if (!user) { continue; }

  //     const userMeetings = meetingsThisYearUsingSentimentScale.filter(m => {
  //       return m.participantAnswers[userId].answerId !== null;
  //     });

  //     const userAnswerOptions = userMeetings.map(m => {
  //       const answerId = m.participantAnswers[userId].answerId;
  //       const sentimentScale = m.sentimentScale;
  //       if (!sentimentScale) { return null; }
  //       if (!answerId) { return null; }
  //       return sentimentScale.options.find(o => o.id === answerId);
  //     });

  //     const userAnswerOptionsFiltered = userAnswerOptions.filter(o => o !== null);
  //     if (userAnswerOptionsFiltered.length === 0) {
  //       result[userId] = null;
  //       continue;
  //     }

  //     const userAnswerOptionsCount = userAnswerOptionsFiltered.reduce((acc, o) => {
  //       if (!acc[o.id]) {
  //         acc[o.id] = 1;
  //       } else {
  //         acc[o.id]++;
  //       }
  //       return acc;
  //     }, {});

  //     const userAnswerOptionsSorted = Object.keys(userAnswerOptionsCount).sort((a, b) => {
  //       const aCount = userAnswerOptionsCount[a];
  //       const bCount = userAnswerOptionsCount[b];
  //       if (aCount > bCount) { return -1; }
  //       if (aCount < bCount) { return 1; }
  //       const aOption = userAnswerOptionsFiltered.find(o => o.id === +a);
  //       const bOption = userAnswerOptionsFiltered.find(o => o.id === +b);
  //       if (!aOption || !bOption) { return 0; }
  //       return aOption.score < bOption.score ? -1 : 1;
  //     });

  //     const userMostCommonOptionId = +userAnswerOptionsSorted[0];
  //     const userMostCommonOption = userAnswerOptionsFiltered.find(o => o.id === userMostCommonOptionId);

  //     result[userId] = userMostCommonOption;
  //   }

  //   return result;
  // }

  /**
   * This will iterate over each meeting and answer in those meetings to get an average score for each user. The average score will be expressed as a percentage so that it maps to any scale. eg an average of 4 on a 5 point scale will be 80%.
   * This will then me shown as the nearest value on the sentiment scale of the most recent meeting.
   * @param data 
   * @param users 
   */
  getUserYearAverages(data: PageScheduleSentimentScaleTimeline, users: UserMinimal[]): { [userId: number]: SentimentScaleOption } {
    const result: { [userId: number]: SentimentScaleOption } = {};

    const meetingsThisFiscalYear = Object.values(data).filter(m => {
      if (m.sentimentScaleId == null) { return false; }
      if (!m.meeting.meetingTimestamp) { return false; }
      // return new Date(m.meeting.meetingTimestamp).getFullYear() === new Date().getFullYear();
      return new Date(m.meeting.meetingTimestamp) >= this.currentFiscalYearStart;
    });
    if (meetingsThisFiscalYear.length === 0) { return result; }

    const latestMeeting = meetingsThisFiscalYear.reduce((acc, m) => {
      if (!acc) { return m; }
      if (!m.meeting.meetingTimestamp) { return acc; }
      const accDate = new Date(acc.meeting.meetingTimestamp);
      const mDate = new Date(m.meeting.meetingTimestamp);
      return accDate > mDate ? acc : m;
    });
    if (!latestMeeting) { return result; }

    this.latestMeetingSentimentScale = latestMeeting.sentimentScale;

    for (const userId of this.uniqueUserIds) {
      const user = users.find(u => u.id === userId);
      if (!user) { continue; }

      const userMeetings = meetingsThisFiscalYear.filter(m => {
        return m.participantAnswers[userId].answerId !== null;
      });

      const userAnswersPercentages = userMeetings.map(m => {
        const answerId = m.participantAnswers[userId].answerId;
        if (!answerId) { return null; }

        const sentimentScale = m.sentimentScale;
        if (!sentimentScale) { return null; }

        const answer = sentimentScale.options.find(o => o.id === answerId);
        if (!answer) { return null; }

        return (answer.score / sentimentScale.options.length);
      });

      if (userAnswersPercentages.length === 0) {
        result[userId] = null;
        continue;
      }

      // Calculate the average percentage of the user's answers
      const userAveragePercentage = userAnswersPercentages.reduce((acc, p) => acc + p, 0) / userAnswersPercentages.length;

      // We want to find the closest matching scale option to the user's average
      const matchingScaleIndex = Math.ceil(userAveragePercentage * this.latestMeetingSentimentScale.options.length);

      // The percentage based mapping system will always be 1-indexed, so we need to adjust the index to match the 0-indexed array
      const matchingScaleIndexAdjusted = matchingScaleIndex - 1;

      // Get the matching scale option
      const matchingScale = this.latestMeetingSentimentScale.options[matchingScaleIndexAdjusted];

      result[userId] = matchingScale;
    }

    return result;
  }

  tryScrollToChartEnd(): void {
    if (!this.chartOuter) { return; }
    this.chartOuter.scrollTop = this.chartOuter.scrollHeight;
  }

  getDefaultFiscalYear(): Date {
    const result = new Date();
    result.setMonth(0);
    result.setDate(1);
    result.setHours(0);
    result.setMinutes(0);
    result.setSeconds(0);
    result.setMilliseconds(0);
    return result;
  }

  getFirstMeetingIdOfFiscalYear(): number {
    if (!this.schedule) { return null; }
    if (!this.schedule.meetingList) { return null; }

    const firstMeeting = this.schedule.meetingList.find(m => {
      if (!m.meetingTimestamp) { return false; }
      return new Date(m.meetingTimestamp) >= this.currentFiscalYearStart;
    });

    if (!firstMeeting) { return null; }
    return firstMeeting.id;
  }
}
