import { Injectable } from '@angular/core';
import { CustomJQuery } from '@app/models/custom-jquery.model';
import { TranslateService } from '@ngx-translate/core';

declare let $: CustomJQuery;

interface INotifyPlacementParams {
  from?: string;
  align?: string;
}
interface INotifyDataParams {
  icon?: string;
  message?: string;
}
interface INotifyDisplayParams {
  type?: string;
  placement?: INotifyPlacementParams;
}

interface INotifyOtherParms {
  newest_on_top?: boolean;
  type?: string;
}

@Injectable()
export class NotifyUtils {

  constructor(
    private translateService: TranslateService
  ) {}

  notify = (message: string) => {
    if(!this.checkCanNotify()) { return; }
    message = this.tryTranslateMessage(message);

    $.notify(message);
  };

  notifyWithType = (message: string, type: string) => {
    if(!this.checkCanNotify()) { return; }
    message = this.tryTranslateMessage(message);

    $.notify(message, {
      type: type
    });
  };

  notifyWithParms = (message: string, params: INotifyOtherParms) => {
    if(!this.checkCanNotify()) { return; }
    message = this.tryTranslateMessage(message);

    $.notify(message, params);
  };

  notifyIcon = (data: INotifyDataParams, display: INotifyDisplayParams) => {
    if(!this.checkCanNotify()) { return; }
    if (data) {
      data.message = this.tryTranslateMessage(data.message);
    }

    
    $.notify(data, display);
  };

  notifyClose = () => {
    $.notifyClose();
  };

  checkCanNotify(): boolean {
    if (!$) { return false; }
    if (!$.notify) { return false; }
    return true;
  }

  private tryTranslateMessage(message: string): string {

    if (!message) { return ''; }
    if (typeof message !== 'string') { return ''; }
    if (message.length === 0) { return ''; }
    return this.translateService.instant(message);
  }
}
