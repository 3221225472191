import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {Page} from '@app/models/api/page.model';
import { CreateTaskCommentDto } from '../model/create-task-comment.dto';
import { CreateTaskDto } from '../model/create-task.dto';
import { TaskAlignmentType } from '../model/task-alignment-type.enum';
import { TaskComment, TaskCommentServerSide } from '../model/task-comment.model';
import { TaskStatus } from '../model/task-status.enum';
import { TaskServerSide, Task } from '../model/task.model';
import { ITaskAPI } from './task-api.model';
import { UpdateTaskStatusDto } from '../model/update-task-status.dto';
import { TaskActivity, TaskActivityServerSide } from '../model/task-activity.model';
import { TeamTaskReportOverview } from '../model/team-task-report-overview.model';
import { PagingParams } from '@app/models/api/paging-params.model';
import { ParentFilter } from '@app/models/api/parent-filter.model';
import { ApiUtils } from '@app/shared/utils/api.utils';
import { TaskStatsScope, TaskStats } from '../component/task-stats/task-stats.component';

@Injectable()
export class TaskAPIService implements ITaskAPI {
  private readonly BASE_URL: string = 'api/task';

  constructor(
      private http: HttpClient
  ) {
  }

  create(todo: CreateTaskDto): Observable<Task> {
    const url = `${this.BASE_URL}`;
    return this.http.post<TaskServerSide>(url, todo).pipe(map(t => new Task(t)));
  }

  update(id: number, updateTodoDto: CreateTaskDto): Observable<Task> {
    const url = `${this.BASE_URL}/${id}`;
    return this.http.put<TaskServerSide>(url, updateTodoDto).pipe(map(t => new Task(t)));
  }

  updateStatus(id: number, updateTaskStatusDto: UpdateTaskStatusDto): Observable<Task> {
    const url = `${this.BASE_URL}/${id}/status`;

    return this.http.post<TaskServerSide>(url, updateTaskStatusDto).pipe(map(t => new Task(t)));
  }

  archive(id: number): Observable<Task> {
    const url = `${this.BASE_URL}/${id}`;
    return this.http.delete<TaskServerSide>(url).pipe(map(t => new Task(t)));
  }

  getTasks(
    title: string,
    creatorIds: number[],
    ownerIds: number[],
    statuses: TaskStatus[],
    alignmentTypes: TaskAlignmentType[],
    alignmentIds: number[],
    archived: boolean | null
  ): Observable<Task[]> {
    const url = `${this.BASE_URL}`;

    let params = new HttpParams();

    if (title && title.length > 0) {
      params = params.append('title', title);
    }

    if (creatorIds) {
      creatorIds.forEach(id => {
        params = params.append('creatorId', id);
      });
    }

    if (ownerIds) {
      ownerIds.forEach(id => {
        params = params.append('ownerId', id);
      });
    }

    if (statuses) {
      statuses.forEach(status => {
        params = params.append('status', status);
      });
    }

    if (alignmentTypes) {
      alignmentTypes.forEach(type => {
        params = params.append('alignmentType', type);
      });
    }

    if (alignmentIds) {
      alignmentIds.forEach(id => {
        params = params.append('alignmentId', id);
      });
    }

    if (archived !== null) {
      params = params.append('archived', false);
    }

    return this.http.get<TaskServerSide[]>(url, { params: params }).pipe(map(response => response.map(t => new Task(t))));
  }

  getTasksPaginated(
    page: number,
    size: number,
    creatorIds: number[],
    ownerIds: number[],
    statuses: TaskStatus[],
    alignmentTypes: TaskAlignmentType[],
    alignmentIds: number[],
    archived: boolean | null
  ): Observable<Page<Task[]>> {
    const url = `${this.BASE_URL}/paged`;

    let params = new HttpParams();

    params = params.append('page', page);
    params = params.append('size', size);

    creatorIds.forEach(id => {
      params = params.append('creatorId', id);
    });

    ownerIds.forEach(id => {
      params = params.append('ownerId', id);
    });

    statuses.forEach(status => {
      params = params.append('status', status);
    });

    alignmentTypes.forEach(type => {
      params = params.append('alignmentType', type);
    });

    alignmentIds.forEach(id => {
      params = params.append('alignmentId', id);
    });

    if (archived !== null) {
      params = params.append('archived', false);
    }

    return this.http.get<Page<TaskServerSide[]>>(url, { params: params }).pipe(map(response => {
      return {
        ...response,
        content: response.content.map(tasks => tasks.map(task => new Task(task)))
      };
    }));
  }

  getTaskActivity(taskId: number): Observable<TaskActivity[]> {
    const url = `${this.BASE_URL}/${taskId}/activity`;
    return this.http.get<TaskActivityServerSide[]>(url).pipe(map(tasks => tasks.map(a => new TaskActivity(a))));
  }

  getTaskComments(taskId: number): Observable<TaskComment[]> {
    const url = `${this.BASE_URL}/${taskId}/comments`;
    return this.http.get<TaskCommentServerSide[]>(url).pipe(map(comments => comments.map(c => new TaskComment(c))));
  }

  // Creator

  getCreatedTasks(): Observable<Task[]> {
    const url = `${this.BASE_URL}/created`;
    return this.http.get<TaskServerSide[]>(url).pipe(map(response => response.map(t => new Task(t))));
  }

  getCreatedTasksById(id: number): Observable<Task> {
    const url = `${this.BASE_URL}/created/${id}`;
    return this.http.get<TaskServerSide>(url).pipe(map(t => new Task(t)));
  }

  // Comments

  createTaskCommentByTaskId(taskId: number, createTaskCommentDto: CreateTaskCommentDto): Observable<TaskComment> {
    const url = `${this.BASE_URL}/${taskId}/comment`;
    return this.http.post<TaskCommentServerSide>(url, createTaskCommentDto)
      .pipe(map(comment => new TaskComment(comment)));
  }

  deleteTaskCommentByTaskIdAndCommentId(taskId: number, commentId: number): Observable<TaskComment> {
    const url = `${this.BASE_URL}/${taskId}/comment/${commentId}`;
    return this.http.delete<TaskCommentServerSide>(url)
      .pipe(map(comment => new TaskComment(comment)));
  }

  getOwnedTasksWithPositionAlignments(): Observable<Task[]> {
    const url = `${this.BASE_URL}/linked-to-positions`;
    return this.http.get<TaskServerSide[]>(url).pipe(map(response => response.map(t => new Task(t))));
  }

  getTeamTasksOverview(): Observable<TeamTaskReportOverview[]> {
    const url = `${this.BASE_URL}/manage/direct/admin`;
    return this.http.get<TeamTaskReportOverview[]>(url);
  }

  getTeamTaskReportOverview(rootUserId: number, depth: number, viewAsAdmin: boolean): Observable<TeamTaskReportOverview[]> {
    const url = `${this.BASE_URL}/manage/direct`;
    let params = new HttpParams();

    params = params.append('rootUserId', rootUserId.toString());
    params = params.append('depth', depth.toString());

    if (viewAsAdmin) {
      params = params.append('viewAsAdmin', 'true');
    }

    return this.http.get<TeamTaskReportOverview[]>(url, { params });
  }

  exportTeamTaskReportOverview(rootUserId: number, depth: number, viewAsAdmin: boolean): Observable<Blob> {
    const url = `${this.BASE_URL}/manage/direct/export`;
    let params = new HttpParams();

    params = params.append('rootUserId', rootUserId.toString());
    params = params.append('depth', depth.toString());

    if (viewAsAdmin) {
      params = params.append('viewAsAdmin', 'true');
    }

    console.info('Sending request')
    return this.http.get<Blob>(url, { params });
  }

  getSecondarySubordinatesTasksOverview(): Observable<TeamTaskReportOverview[]> {
    const url = `${this.BASE_URL}/manage/secondary`;
    return this.http.get<TeamTaskReportOverview[]>(url);
  }

  searchTeamTaskReportOverviews(pageParams: PagingParams, parentFilter: ParentFilter, viewAsAdmin: boolean): Observable<Page<TeamTaskReportOverview>> {
    const url = `${this.BASE_URL}/manage/direct`;
    let params = ApiUtils.createPageParams(pageParams);

    if (viewAsAdmin) {
      params = params.append('viewAsAdmin', 'true');
    }

    return this.http.post<Page<TeamTaskReportOverview>>(url, parentFilter, { params });
  }

  getStatsForScope(scope: TaskStatsScope): Observable<TaskStats> {
    const url = `${this.BASE_URL}/stats`;
    const params = new HttpParams().append('scope', scope);
    return this.http.get<TaskStats>(url, { params });
  }

}
