import { app, authentication } from '@microsoft/teams-js';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MicrosoftTeamsService {
  static initialized = false;

  static initalizeApplication(): Promise<void> {
    return app.initialize()
      .then(() => {
        console.info('Teams service initialised');
        MicrosoftTeamsService.initialized = true;
      })
      .catch(err => {
        console.warn('Failed to initialize Microsoft Teams library', err);
      });
  }

  static notifyApplicationLoaded(): void {
    if (MicrosoftTeamsService.initialized) {
      app.notifyAppLoaded();
    }
  }

  static notifySuccess(): void {
    app.notifySuccess();
  }

  static notifyLoadingFailure(message: string, reason: app.FailedReason): void {
    app.notifyFailure({
      reason: reason,
      message: message
    });
  }

  static getAuthenticationToken(authTokenRequest?: authentication.AuthTokenRequest): Promise<string> {
    return authentication.getAuthToken(authTokenRequest);
  }

  static getContext(): Promise<app.Context> {
    return app.getContext();
  }
}
