<div class="goal-create-key-results-container" [class.parent-dropdown-closed]="!parentDropdownOpen" [class.has-calendar-open]="calendarOpen">
  <ng-container *ngIf="parentDropdownOpen; else templateDisplayValues">
    <ng-container *ngIf="(keyResultList.length > 0); else noKeyResultsTemplate">
      <div class="key-result-list-container" cdkDropList (cdkDropListDropped)="dropDraggableKeyResult($event)">
        <ng-container *ngFor="let keyResultGroup of keyResultList; index as index; first as isFirst; last as isLast">
          <ng-container *ngIf="isFirst" [ngTemplateOutlet]="addRowTemplate" [ngTemplateOutletContext]="{index: index, visibleByDefault: false}"></ng-container>

          <div class="key-result-item" cdkDrag  [cdkDragData]="keyResultGroup">
            <div *cdkDragPreview></div>

            <div class="drag-handle" cdkDragHandle>
              <span class="fal fa-fw fa-ellipsis-v"></span>
            </div>
            
            <div class="key-result-form-container">
              <app-goal-create-key-result-item [keyResultForm]="keyResultGroup" [minDueDate]="minDueDate" [maxDueDate]="maxDueDate" (calendarToggle)="onCalendarToggle($event, index, isLast)" [submitted]="submitted" [submittedDraft]="submittedDraft"></app-goal-create-key-result-item>
            </div>
      
            <div class="remove-result-icon" [tooltip]="eGoalsMessages.GOAL_CREATE_KEY_RESULTS_REMOVE_KEY_RESULT | translate" placement="left" (click)="onRemoveKeyResult(index)">
              <span class="fal fa-fw fa-times"></span>
            </div>
          </div>
      
          <ng-container [ngTemplateOutlet]="addRowTemplate" [ngTemplateOutletContext]="{index: (index + 1), visibleByDefault: isLast}"></ng-container>
        </ng-container>
      </div>
    </ng-container>
    <ng-template #noKeyResultsTemplate>
      <app-empty-state [height]="'200px'" [message]="eGoalsMessages.GOAL_CREATE_KEY_RESULTS_EMPTY_STATE" [imageSrc]="'assets/img/NoSurveys.svg'" [secondaryButtonShown]="true" [secondaryButtonText]="eGoalsMessages.GOAL_CREATE_KEY_RESULTS_ADD_KEY_RESULT" (secondaryButtonPressed)="onAddRowClick(0)"></app-empty-state>
    </ng-template>
  </ng-container>
  <ng-template #templateDisplayValues>
    <ng-container *ngIf="keyResultList.length > 0">
      <div class="p-15">
        <div class="display-values" (click)="setParentDropdown(true)" [class.display-invalid]="((submitted || submittedDraft) && controlKeyResults.invalid)">
          <div class="display-values-text">
            <ng-container *ngIf="(keyResultList.length === 1); else templateDisplayMultipleKRs">
              <div translate>{{eGoalsMessages.GOAL_CREATE_KEY_RESULTS_COUNT_SINGLE}}</div>
            </ng-container>
            <ng-template #templateDisplayMultipleKRs>
              <div translate [translateParams]="{ count: keyResultList.length }">{{eGoalsMessages.GOAL_CREATE_KEY_RESULTS_COUNT_MULTIPLE}}</div>
            </ng-template>
          </div>
          <span class="fal fa-fw fa-check"></span>
        </div>
      </div>
    </ng-container>
  </ng-template>

  <ng-container *ngIf="submitted && controlKeyResults.invalid && keyResultList.length === 0">
    <div class="frankli-form-group-alert p-top-15 p-bottom-15">
      <div *ngIf="controlKeyResults.hasError('minlength')" translate>{{eGoalsMessages.GOAL_CREATE_KEY_RESULTS_VALIDATION_MINIMUM}}</div>
    </div>
  </ng-container>
</div>

<ng-template let-index="index" let-visibleByDefault="visibleByDefault" #addRowTemplate>
  <div class="add-row" [class.start-visible]="visibleByDefault" (click)="onAddRowClick(index)">
    <span class="fal fa-fw fa-plus m-right-5"></span>
    <span translate>{{eGoalsMessages.GOAL_CREATE_KEY_RESULTS_ADD_KEY_RESULT}}</span>
  </div>
</ng-template>
