<div id="loader" class="loading-animation">
  <div class="loader-container">
    <div class="loader-icon loader-icon-1">
    </div>
    <div class="loader-icon loader-icon-2">
    </div>
    <div class="loader-icon loader-icon-3">
    </div>
  </div>
</div>
<div class="message-container">
  <span id="msg"></span>
</div>   