<ng-container *ngIf="newUI; else oldUI">
  <ng-container *ngIf="(searchProps?.length > 0) || (filterOptions?.length > 0)">
    <div class="container-universal-filter">
      <ng-container *ngIf="searchProps?.length > 0">
        <div class="search-box-container">
          <input type="search" autocomplete="off" class="form-control search-box-control" [placeholder]="placeholderText" [formControl]="searchControl" maxlength="100"/>
          <div class="search-box-icon">
            <ng-container *ngIf="(showClearSearch && searchControl.value && searchControl.value.length > 0); else templateRegularSearchIcon;">
              <span class="offset-clear-search-icon">
                <app-table-action-icon [icon]="'fa-times'" [boxSize]="'24px'" [iconSize]="'12px'" (click)="resetSearch()"></app-table-action-icon>
              </span>
            </ng-container>
            <ng-template #templateRegularSearchIcon>
              <span class="fal fa-fw fa-search"></span>
            </ng-template>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="filterOptions?.length > 0">
        <div class="category-list">
          <ng-container *ngFor="let key of categoryKeys">
            <app-universal-filter-category-item [key]="key" [properties]="categories[key.key]" [categoriesVisible]="categoriesVisible" (onPropertyToggled)="onCategoryPropertyToggled(key, $event)" [cachedDateFilter]="cachedDateFilter"></app-universal-filter-category-item>
          </ng-container>
        </div>
        <ng-container *ngIf="propertiesSelectedVisible > 0">
          <app-button [small]="true" [type]="eButtonType.NAVY" (onClick)="resetFilterAndSearch()">
            <span translate>{{eUniversalFilterMessages.RESET}}</span>
          </app-button>
        </ng-container>
      </ng-container>
    </div>
  </ng-container>
</ng-container>

<ng-template #oldUI>
  <div class="round-search" role="search">
    <div class="input-group">
      <span class="input-group-addon">
        <!-- Button w/ icon -->
        <button type="button"
          id="dropdownMenuButton" class="dropdown-toggle"
          data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <span class="fal fa-fw fa-caret-down" *ngIf="propertiesSelected === 0"></span>
          <span class="filters-selected" *ngIf="propertiesSelected > 0">{{propertiesSelected}}</span>
        </button>

        <!-- Dropdown -->
        <!-- TODO: Mobile version of this dropdown that stacks on the z-index -->
        <div class="dropdown-menu universal-filter-dropdown" aria-labelledby="dropdownMenuButton">
          <div class="menu-item clickable check-container caret-right reset-filter" (click)="resetFilter($event)">
            <button type="button" class="btn-reset-filter" translate>{{eUniversalFilterMessages.RESET}}</button>
          </div>
          <div *ngFor="let key of categoryKeys; trackBy: trackByFnKeys" class="menu-item check-container caret-right noselect default" (click)="preventClick($event)">
            <label>{{key.name | translate | underscoreToSpace}} <span class="amountSelected"
                *ngIf="getCategorySelectedCount(key.key); let count">({{count}})</span></label>
            <div class="dropdown-submenu">
              <!-- TODO: May need to add an option here for AND and OR instead of defaulting in code -->
              <div class="check-container clickable" *ngFor="let val of categories[key.key]; trackBy: trackByFnCategories; index as i"
                (click)="toggleProp($event, key, i)">
                <input type="checkbox" class="clickable" [checked]="val.enabled" />
                <label class="m-left-5 clickable">{{val.name | translate | underscoreToSpace}}</label>
              </div>
            </div>
          </div>
        </div>
      </span>

      <!-- Search box -->
      <input type="search" class="form-control nav-search-bg" [placeholder]="placeholderText"
        [formControl]="searchControl" maxlength="100" />
    </div>
  </div>
</ng-template>