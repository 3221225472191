import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {CompanySetUpStepType} from '@app/models/company/company-set-up-steps/company-set-up-step-type.enum';
import {
  CompanySetUpStep,
  CompanySetUpStepServerSide
} from '@app/models/company/company-set-up-steps/company-set-up-step.model';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {SetUpStepsService} from '../interfaces/company/set-up-steps.service';

@Injectable()
export class SetUpStepsAPIService implements SetUpStepsService {

  private readonly BASE_URL = '/api/set-up-steps';

  constructor(
      private http: HttpClient
  ) {
  }

  getSetUpSteps(): Observable<CompanySetUpStep[]> {
    const url = `${this.BASE_URL}/all`;
    return this.http.get<CompanySetUpStepServerSide[]>(url).pipe(
        map(steps => steps
            .map(group => new CompanySetUpStep(group))
            .sort((a, b) => a.stepOrder - b.stepOrder)
        )
    );
  }

  skipCompanySetUpStepType(type: CompanySetUpStepType): Observable<CompanySetUpStep> {
    const url = `${this.BASE_URL}/skip/${type}`;
    return this.http.post<CompanySetUpStepServerSide>(url, null).pipe(
        map(step => new CompanySetUpStep(step))
    );
  }

  unskipCompanySetUpStepType(type: CompanySetUpStepType): Observable<CompanySetUpStep> {
    const url = `${this.BASE_URL}/unskip/${type}`;
    return this.http.post<CompanySetUpStepServerSide>(url, null).pipe(
        map(step => new CompanySetUpStep(step))
    );
  }

  completeCompanySetUpStepType(type: CompanySetUpStepType): Observable<CompanySetUpStep> {
    const url = `${this.BASE_URL}/complete/${type}`;
    return this.http.post<CompanySetUpStepServerSide>(url, null).pipe(
        map(step => new CompanySetUpStep(step))
    );
  }
}
