export enum CriterionOperator {
  GREATER_THAN = 'GREATER_THAN',
  GREATER_THAN_EQ = 'GREATER_THAN_EQ',
  LESS_THAN = 'LESS_THAN',
  LESS_THAN_EQ = 'LESS_THAN_EQ',
  BETWEEN = 'BETWEEN',
  EQUALS = 'EQUALS',
  LIKE = 'LIKE',
  NOT_EQ = 'NOT_EQ',
  IN = 'IN',
  NOT_IN = 'NOT_IN',
  CONTAINS = 'CONTAINS',
  NOT_CONTAINS = 'NOT_CONTAINS'
}