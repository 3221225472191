export const QualificationMessages = {
  QUALIFICATION: 'qualification.qualification',
  QUALIFICATIONS: 'qualification.qualifications',
  QUALIFICATIONS_DESCRIPTION: 'qualification.qualifications-description',
  NAME: 'qualification.name',
  NAME_PLACEHOLDER: 'qualification.name-placeholder',
  PROVIDER: 'qualification.provider',
  PROVIDER_PLACEHOLDER: 'qualification.provider-placeholder',
  DESCRIPTION: 'qualification.description',
  RECEIVED_DATE: 'qualification.received-date',
  EXPIRATION_DATE: 'qualification.expiration-date',
  ADD_QUALIFICATION: 'qualification.add-qualification',
  SUCCESS_CREATE: 'qualification.success-create',
  SUCCESS_UPDATE: 'qualification.success-update',
  SUCCESS_DELETE: 'qualification.success-delete',
  HEADER_CREATING: 'qualification.header-creating',
  HEADER_UPDATING: 'qualification.header-updating',
  TOOLTIP_EDIT: 'qualification.tooltip-edit',
  TOOLTIP_DELETE: 'qualification.tooltip-delete',
  CONFIRMATION_DELETE: 'qualification.confirmation-delete',
  EMPTY_STATE: 'qualification.empty-state',
  EMPTY_STATE_OTHER_USER: 'qualification.empty-state-other-user'
};
