<div class="goal-picker-container" [class.goal-picker-disabled]="state.disabled">
  <!-- Fancy looking preview -->
  <ng-container *ngIf="hasValueSelected">
    <div class="goal-picker-selected-value" [class.can-select-multiple]="canSelectMultiple" (click)="onClickSelectionPreview()">
      <ng-container *ngIf="_value; let goal">
        <ng-container *ngFor="let selectedGoal of _value">
          <app-goal-display [goal]="selectedGoal" [showIcon]="true" [showProgress]="false" [canDeselect]="true" [selected]="true"></app-goal-display>
        </ng-container>
      </ng-container>
    </div>
  </ng-container>

  <!-- Actual input with search and results -->
  <ng-container *ngIf="!hasValueSelected || state.showingPicker">
    <button class="goal-picker-form-container" [class.picker-has-value]="hasSearchArguments" type="button">
      <div class="area-search-control">
        <div class="search-input-wrapper">
          <ng-container *ngFor="let searchParameterGroup of searchParametersArray; index as index;">
            <span class="search-parameter-item">
              <span class="search-parameter-icon fal fa-fw" [ngClass]="slashCommandMetadata[searchParameterGroup.controls.key.value].icon" [tooltip]="slashCommandMetadata[searchParameterGroup.controls.key.value].label" placement="bottom" container="body"></span>
              <ng-container [ngSwitch]="searchParameterGroup.controls.key.value">
                <ng-container *ngSwitchCase="eGoalPickerSlashCommand.TYPE">
                  <select class="search-parameter-select" [formControl]="searchParameterGroup.controls.value">
                    <option [value]="null" *ngIf="searchParameterGroup.controls.value.value === null" translate>{{eCommonMessages.SELECT_PROMPT}}</option>
                    <ng-container *ngFor="let goalType of optionsGoalTypes">
                      <ng-container *ngIf="allowedGoalTypes.includes(goalType) && (goalType !== eGoalType.OFFICE_LOCATION || globals.hasFeature(eCompanyFeatures.GOAL_OFFICE_LOCATION))">
                        <ng-container [ngSwitch]="goalType">
                          <option *ngSwitchCase="eGoalType.PERSONAL_OPERATIONAL" [ngValue]="goalType" translate>{{eGoalsMessages.PERSONAL_OPERATIONAL}}</option>
                          <option *ngSwitchCase="eGoalType.PERSONAL_DEVELOPMENTAL" [ngValue]="goalType" translate>{{eGoalsMessages.PERSONAL_DEVELOPMENTAL}}</option>
                          <option *ngSwitchCase="eGoalType.TEAM" [ngValue]="goalType" translate>{{eGoalsMessages.TEAM_GOAL | translate: { teamWording: globals.getTerminology(eTerminologyEntity.TEAM) } }}</option>
                          <option *ngSwitchCase="eGoalType.DEPARTMENT" [ngValue]="goalType" translate>{{eGoalsMessages.DEPARTMENT_GOAL | translate: { departmentWording: globals.getTerminology(eTerminologyEntity.DEPARTMENT) } }}</option>
                          <option *ngSwitchCase="eGoalType.OFFICE_LOCATION" [ngValue]="goalType" [innerHTML]="eGoalsMessages.SITE_GOAL| translate:{ site: globals.getTerminology(eTerminologyEntity.SITE) }"></option>
                          <option *ngSwitchCase="eGoalType.COMPANY" [ngValue]="goalType" translate>{{eCommonMessages.COMPANY}}</option>
                        </ng-container>
                      </ng-container>
                    </ng-container>
                  </select>
                </ng-container>
                <ng-container *ngSwitchCase="eGoalPickerSlashCommand.STATUS">
                  <select class="search-parameter-select" [formControl]="searchParameterGroup.controls.value">
                    <option [value]="null" *ngIf="searchParameterGroup.controls.value.value === null" translate>{{eCommonMessages.SELECT_PROMPT}}</option>
                    <ng-container *ngFor="let goalStatus of optionsGoalStatuses">
                      <option [ngValue]="goalStatus" translate>{{eGoalUtils.getMessageCodeForGoalStatus(goalStatus)}}</option>
                    </ng-container>
                  </select>
                </ng-container>
                <ng-container *ngSwitchCase="eGoalPickerSlashCommand.PRIORITY">
                  <select class="search-parameter-select" [formControl]="searchParameterGroup.controls.value">
                    <option [value]="null" *ngIf="searchParameterGroup.controls.value.value === null" translate>{{eCommonMessages.SELECT_PROMPT}}</option>
                    <ng-container *ngFor="let goalPriority of optionsGoalPriorities">
                      <option [ngValue]="goalPriority" translate>{{(goalPriority === eGoalPriority.NOT_SET) ? eGoalUtils.getLongMessageCodeForGoalPriority(goalPriority) : eGoalUtils.getMessageCodeForGoalPriority(goalPriority) }}</option>
                    </ng-container>
                  </select>
                </ng-container>
                <ng-container *ngSwitchDefault>
                  <input class="search-parameter-value" [formControl]="searchParameterGroup.controls.value" [size]="searchParameterGroup.controls.value.value ? searchParameterGroup.controls.value.value.length : 1" (keydown)="onKeydownSearchArgument($event, index)" #searchParameterInput/>
                </ng-container>
              </ng-container>
              <span class="search-parameter-clear" (click)="removeSearchParameter(index)">
                <span class="fal fa-fw fa-times"></span>
              </span>
            </span>
          </ng-container>
          <input class="search-input" [formControl]="searchControl" (keyup)="onKeyupSearchControl($event)" [placeholder]="searchPlaceholder | translate" [size]="searchControl.value ? searchControl.value.length : 1" #searchInput/>
        </div>
        <div class="search-input-icon">
          <!-- Show clear icon if a value has been entered -->
          <ng-container *ngIf="hasSearchArguments; else templateSearchIcon;">
            <span class="fal fa-fw fa-times clickable" (click)="clearSearchArgument()"></span>
          </ng-container>
          <ng-template #templateSearchIcon>
            <span class="icon-input-search fal fa-fw fa-search"></span>
          </ng-template>
        </div>
      </div>

      <div class="area-search-results">
        <ng-container *ngIf="slashCommandsSuggesting && slashCommandsSuggesting.length > 0; else templateShowSearchResults;">
          <div class="display-slash-command-container">
            <h6 class="standard-heading p-bottom-5">Search parameters</h6>
            <div class="display-slash-command-list">
              <ng-container *ngFor="let slashCommand of slashCommandsSuggesting">
                <div class="display-slash-command-item" (click)="onClickSlashCommand(slashCommand)">
                  <h6 class="standard-heading">{{slashCommandMetadata[slashCommand].label}}</h6>
                  <div class="standard-description">{{slashCommandMetadata[slashCommand].description}}</div>
                </div>
              </ng-container>
            </div>
          </div>
        </ng-container>

        <ng-template #templateShowSearchResults>
          <div class="display-search-results-container">

            <ng-container *ngIf="!state.loading; else templateStateLoading;">
              <ng-container *ngIf="!state.searchRunning; else templateSearchRunning;">
                <ng-container *ngIf="(results.length > 0) || (searchControl.value !== '' && searchControl.value !== null) || (searchControl.value === '' && results.length > 0); else templateNoSearchValue;">
                  <ng-container *ngIf="(results.length > 0); else templateNoSearchResults;">
                    <h6 class="standard-heading p-bottom-5">Search results</h6>

                    <div class="search-result-list">
                      <ng-container *ngFor="let searchResult of results">
                        <div class="search-result-item" (click)="selectItem(searchResult)">
                          <app-goal-display [goal]="searchResult" [showIcon]="true" [showProgress]="false" [canSelect]="true" [selected]="selectedValueIds.includes(searchResult.id)"></app-goal-display>
                        </div>
                      </ng-container>
                    </div>
                  </ng-container>
                </ng-container>
              </ng-container>
            </ng-container>
      
            <ng-template #templateNoSearchValue>
              <div class="text-center p-15">
                <span translate>{{eCommonMessages.START_TYPING_TO_SEARCH_OR_ENTER}}</span>
              </div>
            </ng-template>
      
            <ng-template #templateNoSearchResults>
              <div class="text-center p-15">
                <span translate>{{eCommonMessages.NO_SEARCH_GOALS}}</span>
              </div>
            </ng-template>
      
            <ng-template #templateSearchRunning>
              <div class="text-center p-15">
                <span class="fas fa-spin fa-spinner fa-fw"></span>
              </div>
            </ng-template>
      
            <ng-template #templateStateLoading>
              <div class="text-center p-15">
                <span class="fas fa-spin fa-spinner fa-fw"></span>
              </div>
            </ng-template>
          </div>
        </ng-template>
      </div>
    </button>
  </ng-container>
</div>
