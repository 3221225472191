import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';
import { GoalsMessages } from '@app/goals/goals.messages';
import { GoalType } from '@app/models/goals/goal-type.enum';
import { Goal } from '@app/models/goals/goal.model';
import { GoalsAPIService } from '@app/shared/api/goals.api.service';
import { Globals } from '@app/shared/globals/globals';
import { CommonGoalCreateFormPart } from '../../goal-create-views/goal-create-main/goal-create-main.component';
import { GoalUtils } from '@app/shared/utils/goal.utils';
import { TerminologyEntity } from '@app/domain/terminology/model/terminology-entity.enum';

@Component({
  selector: 'app-goal-create-part-alignment',
  templateUrl: './goal-create-part-alignment.component.html',
  styleUrls: ['./goal-create-part-alignment.component.scss']
})
export class GoalCreatePartAlignmentComponent implements OnInit, CommonGoalCreateFormPart {

  public readonly eTerminologyEntity = TerminologyEntity;
  public readonly eGoalsMessages = GoalsMessages;
  public readonly eGoalType = GoalType;

  @Input() controlAlignment: FormControl;
  @Input() controlType: FormControl;
  @Input() submitted: boolean;
  @Input() submittedDraft: boolean;
  @Input() parentDropdownOpen: boolean;

  @Output() onSetParentDropdown: EventEmitter<boolean>;

  controlAlignmentInner: FormControl;

  get goalItemSelected(): Goal {
    return this.controlAlignmentInner.value;
  }

  validGoalTypes: GoalType[];

  constructor(
    private goalsAPIService: GoalsAPIService,
    public globals: Globals
  ) {
    this.controlAlignment = new FormControl(null, []);
    this.controlAlignmentInner = this.initControlInner();
    this.controlType = new FormControl(GoalType.PERSONAL_DEVELOPMENTAL, []);
    this.submitted = false;
    this.submittedDraft = false;
    this.parentDropdownOpen = true;
    this.onSetParentDropdown = new EventEmitter<boolean>();
  }

  ngOnInit(): void {
    this.validGoalTypes = this.getGoalsValidForAlignment(this.controlType.value);

    this.controlType.valueChanges.subscribe((value: GoalType) => {
      this.validGoalTypes = this.getGoalsValidForAlignment(value);

      // If the selected goal type is not valid for the current alignment, reset the alignment
      if (!this.validGoalTypes.includes(this.controlAlignmentInner.value?.type)) {
        this.controlAlignment.setValue(null);
      }
    });

    if (this.controlAlignment.value && !this.goalItemSelected) {
      this.getSelectedGoalData(this.controlAlignment.value);
    }
  }

  initControlInner(): FormControl {
    const formControl = new FormControl(null, []);
    formControl.valueChanges.subscribe(value => {
      this.controlAlignment.setValue(value ? value.id : null);
    });
    return formControl;
  }

  getSelectedGoalData(goalId: number): void {
    this.goalsAPIService.getGoalById(goalId).subscribe(goal => {
      this.controlAlignmentInner.setValue(goal, { emitEvent: false });
    });
  }

  setParentDropdown(expanded: boolean): void {
    this.onSetParentDropdown.emit(expanded);
  }

  onClickPreview(): void {
    if (!this.parentDropdownOpen) {
      this.setParentDropdown(true);
      return;
    }

    this.controlAlignment.setValue(null);
  }

  focus(): void {
    // TODO:
  }

  getGoalsValidForAlignment(type: GoalType): GoalType[] {
    return GoalUtils.getAllowedAlignmentTypesForGoalType(type);
  }
}
