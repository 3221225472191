import { Component, Input, Output, SimpleChanges, EventEmitter, OnChanges } from '@angular/core';
import { Site } from '@app/models/site.model';
import { PillType } from '../../pill/pill.component';

@Component({
  selector: 'app-site-display',
  templateUrl: './site-display.component.html',
  styleUrls: ['./site-display.component.scss']
})
export class SiteDisplayComponent implements OnChanges {
  public readonly ePillType = PillType;
  public readonly DATE_FORMAT = 'D MMM YYYY';
  
  @Input() site!: Site;
  @Input() showProgress: boolean;

  @Input() canSelect: boolean;
  @Input() selected: boolean;

  @Output() onSelect: EventEmitter<boolean>;

  constructor() {
    this.selected = false;
    this.canSelect = false;
    this.showProgress = true;
    this.onSelect = new EventEmitter<boolean>();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const site = changes['site'];
    if (site) {
      // TODO: Any required internal calculations here
    }
  }

  selectSite(): void {
    if (this.canSelect) {
      this.onSelect.emit(true);
    }
  }
}
