import { Component, ElementRef, EventEmitter, forwardRef, Input, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { CommonMessages } from '@app/constants/common.messages';
import { CompanyFeatures } from '@app/models/company-features.model';
import { Tag } from '@app/domain/tag/model/tag.model';
import { TagType } from '@app/domain/tag/model/tag-type.model';
import { Globals } from '@app/shared/globals/globals';
import { debounceTime, map } from 'rxjs/operators';
import { TagBusinessService } from '@app/domain/tag/service/tag-business.service';
import { TagMessages } from '@app/domain/tag/locale/tag.messages';

@Component({
  selector: 'app-tag-picker-multiple',
  templateUrl: './tag-picker-multiple.component.html',
  styleUrls: ['./tag-picker-multiple.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => TagPickerMultipleComponent),
    multi: true,
  }]
})
export class TagPickerMultipleComponent implements ControlValueAccessor {
  public readonly eCompanyFeatures = CompanyFeatures;
  public readonly eCommonMessages = CommonMessages;
  public readonly eTagMessages = TagMessages;
  public readonly eTagType = TagType;

  @Input() disabled: boolean;
  @Input() maxResultsShown: number;
  @Input() customOptions: Tag[];
  @Input() tagType?: TagType;
  
  searchRunning: boolean;
  searchControl: FormControl;
  searchResults: Tag[];
  @Output() onResultsChanged: EventEmitter<Tag[]>;

  @ViewChild('searchInput') searchInput?: ElementRef<HTMLInputElement>;

  onChange = (_: any) => {};
  onTouched = () => {};

  _value: Tag[];

  get value(): Tag[] {
    return this._value;
  }

  set value(val: Tag[]) {
    if (!this.disabled) {
      this._value = val;
      this.onChange(val);
    }
  }

  constructor(
    public globals: Globals,
    private tagBusinessService: TagBusinessService
  ) {
    this.disabled = false;
    this.searchRunning = false;

    this._value = [];
    this.searchResults = [];
    this.customOptions = [];

    this.maxResultsShown = 3;

    this.searchInput = undefined;
    this.tagType = undefined;

    this.onResultsChanged = new EventEmitter<Tag[]>();

    this.searchControl = this.initSearchControl();
  }

  // #region - VALUE ACCESSOR STUFF
  writeValue(value: any) {
    this.value = value;
  }

  // Register callbacks
  registerOnChange(fn: (_: any) => {}): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }
  // #endregion

  // #region - SEARCHING
  initSearchControl(): FormControl {
    const searchControl = new FormControl('', []);

    searchControl.valueChanges
      .pipe(
        map(sarg => {
          this.searchRunning = true;
          sarg = sarg.toLocaleLowerCase();    
          return sarg;
        }),
        debounceTime(500)
      )
      .subscribe(sarg => this.onSearchChange(sarg));

    return searchControl;
  }

  onSearchChange(sarg: string): void {
    if (sarg === '') {
      this.updateSearchResults([]);
      return;
    }

    this.tagBusinessService.get(null, sarg, [this.tagType])
      .subscribe(
        (tags) => {
          let matches: Tag[] = [];

          const customMatches = this.customOptions.filter(co => {
            if (co.text.includes(sarg)) {
              return co;
            }
          });

          const results = [
            ...customMatches,
            ...tags
          ];

          results.forEach(u => {
            const tagSelected = this.value.map(v => v.id).includes(u.id);
            const tagBeingSelected = matches.map(v => v.id).includes(u.id);

            if (!tagSelected && !tagBeingSelected) {
              matches = [...matches, u];
            }
          });

          this.updateSearchResults(matches);
          this.searchRunning = false;
        }
      );
  }

  updateSearchResults(results: Tag[]): void {
    results = results.slice(0, this.maxResultsShown);

    this.searchResults = results;
    this.onResultsChanged.emit(results);
  }
  // #endregion

  // #region - SELECTION AND DESELECTION
  onClickTag(tag: Tag, action: 'add' | 'remove'): void {
    switch (action) {
      case 'add':
        this.doSelectTag(tag);
        break;
      case 'remove':
        this.doDeselectTag(tag);
        break;
    }
  }

  doSelectTag(tag: Tag): void {
    if (!this.value.includes(tag)) {
      this.value = [...this.value, tag];
      this.searchControl.setValue('', { emitEvent: false });
      this.onSearchChange('');
      this.focusSearchInput();
    }
  }

  doDeselectTag(tag: Tag): void {
    this.value = this.value.filter(v => (v.id !== tag.id));
  }
  // #endregion

  focusSearchInput(): void {
    if (this.searchInput) {
      this.searchInput.nativeElement.focus();
    }
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
