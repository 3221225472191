import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CommonMessages } from '@app/constants/common.messages';
import { GoalsMessages } from '@app/goals/goals.messages';
import { GoalKeyResultMeasureUnitPlacement } from '@app/models/goals/goal-key-result-measure-unit-placement.model';
import { GoalKeyResultType } from '@app/models/goals/goal-key-result-type.model';
import { GoalStatus } from '@app/models/goals/goal-status.model';
import { ButtonGroupOption } from '@app/shared/components/inputs/button-group/button-group.component';
import { ButtonType } from '@app/shared/components/inputs/button/button.component';
import { GoalUtils } from '@app/shared/utils/goal.utils';
import { NotifyUtils } from '@app/shared/utils/notify.utils';
import { FrankliValidators } from '@app/shared/validators/validators';
import { TranslateService } from '@ngx-translate/core';
import { GoalKeyResult } from 'app/models/goals/goal-key-result.model';
import { GoalProgressDto } from 'app/models/goals/goal.dto';
import { Goal } from 'app/models/goals/goal.model';
import { GoalsAPIService } from 'app/shared/api/goals.api.service';

interface State {
  loading: boolean;
  submitted: boolean;
  submitting: boolean;
}

interface ProgressValue {
  valueRaw: number;
  valueCapped: number;
  valueCappedRounded: string;
  overflowRaw: number;
  overflowRounded: string;
}
@Component({
  'selector': 'app-goals-individual-update-component',
  'templateUrl': './goals-individual-update.component.html',
  'styleUrls': ['./goals-individual-update.component.scss']
})
export class GoalsIndividualUpdateComponent implements OnInit {
  public readonly eGoalKeyResultType = GoalKeyResultType;
  public readonly eGoalKeyResultMeasureUnitPlacement = GoalKeyResultMeasureUnitPlacement;
  public readonly editorMaxLengthSoft = 800;
  public readonly editorMaxLengthHard = 1000;
  public readonly editorToolbar = 'undo redo | formatselect | bold italic underline strikethrough | help';
  public readonly eGoalsMessages = GoalsMessages;
  public readonly eGoalStatus = GoalStatus;
  public readonly eGoalUtils = GoalUtils;
  public readonly eCommonMessages = CommonMessages;
  public readonly eButtonType = ButtonType;

  @Input() goal: Goal;
  @Input() showControls: boolean;
  @Input() editing: boolean;
  @Output() refresh: EventEmitter<boolean>;
  @Output() completeEmit: EventEmitter<boolean>;

  // Update Form //
  formUpdate: FormGroup;

  state: State;

  formStatusOptions: ButtonGroupOption[];

  get controlProgress(): FormControl {
    return this.formUpdate.controls.progress as FormControl;
  }

  get controlStatus(): FormControl {
    return this.formUpdate.controls.status as FormControl;
  }

  get controlKeyResults(): FormArray {
    return this.formUpdate.controls.keyResults as FormArray;
  }

  get controlKeyResultsList(): FormGroup[] {
    return this.controlKeyResults.controls as FormGroup[];
  }

  constructor(
    private formBuilder: FormBuilder,
    private goalsAPIService: GoalsAPIService,
    private notifyUtils: NotifyUtils
  ) {
    this.goal = undefined!;

    this.editing = false;
    this.showControls = false;

    this.formStatusOptions = [
      {
        value: GoalStatus.OFF_TRACK,
        friendlyText: GoalsMessages.OFF_TRACK,
        color: '#FF5630'
      },
      {
        value: GoalStatus.PROGRESSING,
        friendlyText: GoalsMessages.PROGRESSING,
        color: '#FFAB00'
      },
      {
        value: GoalStatus.ON_TRACK,
        friendlyText: GoalsMessages.ON_TRACK,
        color: '#36B37E'
      }
    ];

    this.state = {
      loading: true,
      submitted: false,
      submitting: false
    };

    this.refresh = new EventEmitter<boolean>();
    this.completeEmit = new EventEmitter<boolean>();

    this.formUpdate = this.initFormUpdate();
  }

  ngOnInit() {
    this.formUpdate = this.initFormUpdate(this.goal);
    this.state.loading = false;
  }

  initFormUpdate(goal?: Goal): FormGroup {
    let formGroup = this.formBuilder.group({
      progress: new FormControl('', [FrankliValidators.softMaxValidation(this.editorMaxLengthSoft), Validators.maxLength(this.editorMaxLengthHard)]),
      status: new FormControl(GoalStatus.ON_TRACK, [Validators.required]),
      keyResults: new FormArray([], []) // TODO: Validators needed?
    });

    if (goal) {
      formGroup = this.populateFormWithGoalData(formGroup, goal);
    }

    return formGroup;
  }

  populateFormWithGoalData(formGroup: FormGroup, goal: Goal): FormGroup {
    formGroup.patchValue(goal);

    const formKeyResults = formGroup.controls.keyResults as FormArray;
    formKeyResults.clear();
    goal.keyResults.forEach(kr => {
      const formGroup = this.initFormKeyResultUpdate(kr);
      formKeyResults.push(formGroup);
    });

    return formGroup;
  }

  initFormKeyResultUpdate(keyResult?: GoalKeyResult): FormGroup {
    let formGroup = this.formBuilder.group({
      id: new FormControl(null, []),
      result: new FormControl('', [Validators.required, Validators.maxLength(255)]),
      type: new FormControl(null, [Validators.required]),
      measureCurrentValue: new FormControl(0, [Validators.required, FrankliValidators.realNumberValidator()]),
      measureStartValue: new FormControl(0, [Validators.required, FrankliValidators.realNumberValidator()]),
      measureGoalValue: new FormControl(1, [Validators.required, FrankliValidators.realNumberValidator()]),
      endDate: new FormControl(null, []),
      reversed: new FormControl(false, []),
      measureUnit: new FormControl(null, []),
      measureUnitPlacement: new FormControl(null, []),
      orderIndex: new FormControl(0, [])
    });

    if (keyResult) {
      formGroup = this.populateFormWithKeyResultData(formGroup, keyResult);
    }

    return formGroup;
  }

  populateFormWithKeyResultData(formGroup: FormGroup, keyResult: GoalKeyResult): FormGroup {
    formGroup.patchValue(keyResult);
    return formGroup;
  }

  validateResultValues(): boolean {
    const formArray = this.formUpdate.get('keyResults') as FormArray;
    formArray.controls.forEach((control) => {
      const formGroup = control as FormGroup;
      if (isNaN(formGroup.controls.measureCurrentValue.value)) { return false; }
      if (isNaN(formGroup.controls.measureStartValue.value)) { return false; }
      if (isNaN(formGroup.controls.measureGoalValue.value)) { return false; }
    });

    return true;
  }

  // TODO: Prevent this posting until actual data has been changed
  updateGoal() {
    this.state.submitted = true;
  
    if (this.state.submitting) {
      return;
    }

    if (this.formUpdate.invalid) {
      return;
    }

    const changesMade = this.unsavedChanges();
    if (!changesMade) {
      return;
    }

    this.state.submitting = true;

    const goalProgressDto = this.parseFormToGoalProgressDto(this.formUpdate);

    this.goalsAPIService.updateProgress(this.goal.id, goalProgressDto)
      .subscribe((response: Goal) => {
        this.notifyUtils.notify(GoalsMessages.SUCCESS_GOAL_UPDATE);

        this.onSubmitFinished();

        this.refresh.emit(true);
      }, (error: HttpErrorResponse) => { // TODO: handle this properly
        this.onSubmitFinished();
      });
  }
  
  onSubmitFinished(): void {
    this.state.submitted = false;
    this.state.submitting = false;
  }

  parseFormToGoalProgressDto(formGroup: FormGroup): GoalProgressDto {
    return formGroup.value as GoalProgressDto;
  }

  setGoalStatus(status: GoalStatus) {
    this.controlStatus.setValue(status);
  }

  unsavedChanges(): boolean {
    if (this.goal.status !== this.controlStatus.value) {
      return true;
    }

    if (this.controlProgress.value && this.controlProgress.value.trim().length > 0) {
      return true;
    }

    const changesMadeToKeyResults = this.controlKeyResultsList.some(resultControl => {
      const krMatched = this.goal.keyResults.find(kr => (resultControl.controls.id.value === kr.id));

      if (!krMatched) {
        return false;
      }

      const resultValueChanged = (krMatched.measureCurrentValue !== resultControl.controls.measureCurrentValue.value);

      return resultValueChanged;
    });

    if (changesMadeToKeyResults) {
      return true;
    }

    return false;
  }

  completeGoal() {
    if (!this.goal.complete) {
      this.completeEmit.emit(true);
    }
  }
}
