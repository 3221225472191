<div class="container-sidebar-tasks">
  <app-state [state]="state">
    <ng-container *ngIf="!state.loading && !state.error;">
      <ng-container *ngIf="data.master.length > 0; else templateNoData;">
        <div class="data-list">
          <ng-container *ngFor="let task of data.display; index as index;">
            <app-task-display [task]="task" [actionsShown]="['view', 'edit', 'unlink', 'archive']" (onClickAction)="onTaskAction($event, task)"></app-task-display>
          </ng-container>
        </div>

        <app-pagination-new-component [arrayData]="data.filtered" [arrayDisplay]="data.display" #pagination></app-pagination-new-component>

        <app-modal #modal [showCloseButton]="false" [sidePanel]="true" [confirmClosing]="confirmModalClose" [confirmCloseModalSettings]="confirmCloseSettings" (onConfirmCloseModalResponse)="onCancelCreate($event)">
          <ng-container [ngSwitch]="modalType">
            <app-task-create *ngSwitchCase="eModalType.CREATE" [formCreate]="formCreate" [submitted]="state.submitted" (onFormCancel)="onCancelCreate()" (onFormSubmit)="onSubmitCreate($event)" (requestCloseModal)="onCancelCreate($event)" #componentCreate></app-task-create>
            <app-task-details-component *ngSwitchCase="eModalType.DETAILS" [task]="taskViewing" (requestCloseModal)="onCancelCreate()" (onTaskUpdated)="taskUpdated($event)"></app-task-details-component>
          </ng-container>
        </app-modal>
      </ng-container>

      <ng-template #templateNoData>
        <app-empty-state [height]="'200px'" [imageSrc]="'assets/img/empty-state/empty-state-tasks.svg'" [message]="'No tasks'"></app-empty-state>
      </ng-template>
    </ng-container>
  </app-state>
</div>