export enum ScheduleLocationType {
  IN_PERSON = 'IN_PERSON',
  ZOOM = 'ZOOM',
  SLACK = 'SLACK',
  MICROSOFT_TEAMS = 'MICROSOFT_TEAMS',
  SKYPE = 'SKYPE',
  GOOGLE_MEET = 'GOOGLE_MEET',
  WEBEX = 'WEBEX',
  OTHER = 'OTHER'
}
