import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OneToOneStatus } from '@app/domain/one_to_one/model/one-to-one-status.model';
import { SwalUtils } from '@app/shared/utils/swal.utils';
import { Breadcrumb } from 'app/models/breadcrumb.model';
import { OneToOneSchedule, OneToOneScheduleBase, OneToOneScheduleMinimal } from '@app/domain/one_to_one/model/one-to-one-schedule.model';
import { BreadcrumbService } from 'app/shared/breadcrumbs/breadcrumbs.service';
import { Globals } from 'app/shared/globals/globals';
import { Subject } from 'rxjs';
import { UserMinimal } from '@app/models/user/user-minimal.model';
import { PaginationNewComponent } from '@app/shared/pagination/pagination-new/pagination-new.component';
import { OneToOneMessages } from '@app/domain/one_to_one/locale/one-to-one.messages';
import { CommonMessages } from '@app/constants/common.messages';
import { IconHoverColor } from '@app/shared/components/inputs/table-action-icon/table-action-icon.component';
import { DateUtils } from '@app/shared/utils/date.utils';
import { OneToOneBusinessService } from '../../service/one-to-one-business.service';
import { IState } from '@app/models/state/state.model';
import { OneToOneFrequency } from '../../model/one-to-one-frequency.model';
import { SSOProvider } from '@app/models/auth/sso-provider.enum';
import { ModalComponent } from '@app/shared/modal/modal.component';
import { FilterCategory } from '@app/shared/dynamic-filter/interfaces/filter-category';
import { SelectedFilter } from '@app/shared/dynamic-filter/interfaces/selected-filter';
import { ParentFilter } from '@app/models/api/parent-filter.model';
import { FilterOperator } from '@app/models/api/filter-operator.enum';
import { PagingParams } from '@app/models/api/paging-params.model';
import moment from 'moment';
import { SortingParams } from '@app/models/api/sorting-params.model';
import { SortDirection } from '@app/models/api/sort-direction.enum';
import { SortingOption } from '@app/shared/components/inputs/sorting-dropdown/sorting-dropdown.component';
import { FormControl } from '@angular/forms';
import { OneToOneCategory } from '@app/shared/dynamic-filter/categories/one-to-one-category';
import { OneToOneUtilsService } from '../../service/one-to-one-utils.service';
import { takeUntil } from 'rxjs/operators';

enum TableColumn {
  MANAGED_BY = 'MANAGED_BY',
  PURPOSE = 'PURPOSE',
  PARTICIPANT = 'PARTICIPANT',
  TALKING_POINTS = 'TALKING_POINTS',
  ACTION_POINTS = 'ACTION_POINTS',
  NEXT_MEETING = 'NEXT_MEETING',
  FREQUENCY = 'FREQUENCY',
  ACTIONS = 'ACTIONS'
}

interface PageState extends IState {
  scheduleTransferring?: OneToOneSchedule;
}

@Component({
  selector: 'app-one-to-one-overview',
  templateUrl: './one-to-one-overview.component.html',
  styleUrls: ['./one-to-one-overview.component.scss'],
})
export class OneToOneOverviewComponent implements OnInit, OnDestroy {
  private readonly ngUnsubscribe$ = new Subject<void>();
  private readonly onRequestMade$ = new Subject<void>();

  public readonly eOneToOneFrequency = OneToOneFrequency;
  public readonly eOneToOneMessages = OneToOneMessages;
  public readonly eCommonMessages = CommonMessages;
  public readonly eOneToOneStatus = OneToOneStatus;
  public readonly eIconHoverColor = IconHoverColor;
  public readonly eTableColumn = TableColumn;
  public readonly eSSOProvider = SSOProvider;
  public readonly searchProperties: string[] = ['purpose_title' ];

  @ViewChild('pagination') pagination?: PaginationNewComponent;
  @ViewChild('modalTransferSchedule') modalTransferSchedule?: ModalComponent;

  data: {
    master: OneToOneScheduleMinimal[],
    filtered: OneToOneScheduleMinimal[],
    display: OneToOneScheduleMinimal[]
  };

  breadcrumb: Breadcrumb;
  state: PageState;
  stateList: IState;

  filterCategories: FilterCategory[];
  selectedFilters: SelectedFilter[];
  parentFilter: ParentFilter;
  pageParams: PagingParams;

  sortingParams: SortingParams;
  controlSort: FormControl;
  sortingOptions: SortingOption[];

  constructor(
    public globals: Globals,
    public route: ActivatedRoute,
    private router: Router,
    private swalUtils: SwalUtils,
    private breadcrumbService: BreadcrumbService,
    private oneToOneBusinessService: OneToOneBusinessService
  ) {
    this.state = {
      loading: true,
      error: false,
      errorMessage: '',
      scheduleTransferring: undefined
    };

    this.stateList = {
      loading: true,
      error: false,
      errorMessage: ''
    };
    
    this.filterCategories = [];
    this.selectedFilters = [];

    this.parentFilter = {
      operator: FilterOperator.AND,
      childFilters: [],
    };
    this.pageParams = {
      pageSize: 25,
      pageNumber: 0,
    };
    this.sortingParams = {
      sortAttributes: [
        'startDateTime'
      ],
      sortDirection: SortDirection.ASC
    };

    this.data = {
      master: [],
      filtered: [],
      display: []
    };

    this.controlSort = this.initControlSort();
    this.sortingOptions = this.getSortingOptions();
    this.filterCategories = this.getFilterCategories();
    this.selectedFilters = this.getSelectedFilters();

    this.breadcrumb = this.breadcrumbService.initWithLabel(this.route, false, OneToOneMessages.OVERVIEW_BREADCRUMB);
  }

  ngOnInit(): void {
    this.state.loading = false;
  }

  ngOnDestroy(): void {
    this.breadcrumbService.remove(this.breadcrumb);
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.unsubscribe();
    this.onRequestMade$.unsubscribe();
  }

  getData(): void {
    this.stateList.loading = true;
    this.oneToOneBusinessService.getOverviewPageData(this.parentFilter, this.pageParams, this.sortingParams)
      // Stop the request if another request is made
      .pipe(takeUntil(this.onRequestMade$))
      .toPromise()
      .then(schedules => {
        this.populateData(schedules.content);
        this.state.loading = false;
        this.stateList.loading = false;
      });
  }

  populateData(data?: OneToOneScheduleMinimal[]): void {
    if (!data) { data = this.data.master; }
    this.data.master = data;
    this.data.filtered = data;
  }

  doError(message: string): void {
    this.state.loading = false;
    this.state.error = true;
    this.state.errorMessage = message;
  }

  refreshPagination(): void {
    if (this.pagination) {
      this.pagination.update();
    }
  }

  getFilterCategories(): FilterCategory[] {
    return [
      OneToOneCategory.statusCategory(),
      OneToOneCategory.frequencyCategory(),
      OneToOneCategory.locationTypeCategory(),
      OneToOneCategory.meetingLengthCategory(),
      OneToOneCategory.talkingPointTemplateCategory()
    ];
  }

  getSelectedFilters() {
    const selectedFilters: SelectedFilter[] = [
      {
        category: OneToOneCategory.statusCategory(),
        option: { displayName: OneToOneUtilsService.getMessageCodeForOneToOneStatus(OneToOneStatus.ACTIVE), value: OneToOneStatus.ACTIVE }
      }
    ];

    return selectedFilters;
  }

  getStatusFilter(schedule: OneToOneScheduleBase): string {
    if (schedule.status === OneToOneStatus.ARCHIVED) {
      return CommonMessages.ARCHIVED;
    }

    if (schedule.status === OneToOneStatus.COMPLETED) {
      return OneToOneMessages.COMPLETED;
    }

    if (schedule.status === OneToOneStatus.PAUSED) {
      return OneToOneMessages.PAUSED;
    }

    return OneToOneMessages.ACTIVE;
  }

  pauseSchedule(schedule: OneToOneSchedule): void {
    this.oneToOneBusinessService.pause(schedule.id)
      .then((newSchedule: OneToOneSchedule) => {
        this.scheduleUpdated(newSchedule);
      });
  }

  resumeSchedule(schedule: OneToOneSchedule): void {
    this.oneToOneBusinessService.resume(schedule.id)
      .then((newSchedule: OneToOneSchedule) => {
        this.scheduleUpdated(newSchedule);
      });
  }

  unarchiveSchedule(schedule: OneToOneSchedule): void {
    this.oneToOneBusinessService.unarchive(schedule.id)
      .then((newSchedule: OneToOneSchedule) => {
        this.scheduleUpdated(newSchedule);
      });
  }

  tryArchiveSchedule(schedule: OneToOneSchedule): void {
    if (this.oneToOneBusinessService.isModifyingSchedule(schedule.id)) { return; }

    this.swalUtils.displayWarningConfirmationSwal({
      title: CommonMessages.WARNING_PROMPT,
      text: OneToOneMessages.ARCHIVE_WARNING
    }).then((result) => {
      if (result.isConfirmed) {
        this.archiveSchedule(schedule);
      }
    });
  }
  
  archiveSchedule(schedule: OneToOneSchedule): void {
    this.oneToOneBusinessService.archive(schedule.id!)
      .then((newSchedule: OneToOneSchedule) => {
        this.scheduleUpdated(newSchedule);
      });
  }

  tryDeleteSchedule(schedule: OneToOneSchedule): void {
    if (this.oneToOneBusinessService.isModifyingSchedule(schedule.id)) { return; }

    this.swalUtils.displayWarningConfirmationSwal({
      title: CommonMessages.WARNING_PROMPT,
      text: OneToOneMessages.DELETE_WARNING
    }).then((result) => {
      if (result.value) {
        this.deleteSchedule(schedule);
      }
    });
  }

  deleteSchedule(schedule: OneToOneScheduleBase): void {
    this.oneToOneBusinessService.delete(schedule.id!)
      .then(() => {
        this.scheduleDeleted(schedule);
      });
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  scheduleUpdated(_schedule: OneToOneScheduleBase): void {
    this.getData();
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  scheduleDeleted(_schedule: OneToOneScheduleBase): void {
    this.getData();
  }

  openOneToOneCreate(): void {
    this.router.navigate(['/one-to-one/create']);
  }

  getTimeInTimezone(meetingTime: Date | string | moment.Moment, timezone: string): string {
    return DateUtils.parseDateTimeToTimezone(meetingTime, timezone).format('Do MMM HH:mm');
  }

  openHelpArticle(): void {
    const newWindow = window.open('https://help.frankli.io/en/articles/2884989', '_blank');

    if (newWindow) {
      newWindow.focus();
    }
  }

  rebuildCalendarSchedule(schedule: OneToOneSchedule): void {
    if (!schedule) { return; }
    if (!schedule.id) { return; }

    this.swalUtils.displayWarningConfirmationSwal({
      title: OneToOneMessages.CONFIRMATION_REBUILD_CALENDAR,
      text: OneToOneMessages.EXPLANATION_REBUILD_CALENDAR
    }).then(res => {
      if (res.isConfirmed) {
        this.oneToOneBusinessService.rebuildCalendarEventsForScheduleId(schedule.id);
      }
    });
  }

  startTransferSchedule(schedule: OneToOneSchedule): void {
    if (!schedule) { return; }
    if (!schedule.id) { return; }

    this.state.scheduleTransferring = schedule;

    setTimeout(() => {
      this.modalTransferSchedule.show();
    }, 1);
  }

  onModalHiddenTransfer(): void {
    this.state.scheduleTransferring = undefined;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onTransferSuccess(_scheduleId: number): void {
    this.state.scheduleTransferring = undefined;
    this.getData();
  }

  onClickAction(event: string, schedule: OneToOneSchedule): void {
    switch (event) {
      case 'view':
        this.viewSchedule(schedule);
        break;
      case 'edit':
        this.startEditSchedule(schedule);
        break;
      case 'recreateCalendar':
        this.rebuildCalendarSchedule(schedule);
        break;
      case 'pause':
        this.pauseSchedule(schedule);
        break;
      case 'resume':
        this.resumeSchedule(schedule);
        break;
      case 'transfer':
        this.startTransferSchedule(schedule);
        break;
      case 'archive':
        this.tryArchiveSchedule(schedule);
        break;
      case 'unarchive':
        this.unarchiveSchedule(schedule);
        break;
      case 'delete':
        this.tryDeleteSchedule(schedule);
        break;
    }
  }
  
  viewSchedule(schedule: OneToOneSchedule): void {
    this.router.navigate(['/one-to-one/', schedule.id]);
  }

  startEditSchedule(schedule: OneToOneSchedule): void {
    this.router.navigate(['/one-to-one/edit/', schedule.id]);
  }

  updateFilter($event: ParentFilter) {
    this.parentFilter = $event;
    this.onRequestMade$.next();
    this.getData();
  }

  initControlSort(column?: TableColumn): FormControl {
    const formControl = new FormControl(TableColumn.NEXT_MEETING, []);

    if (column) {
      formControl.setValue(column, { emitEvent: false });
    }

    formControl.valueChanges.subscribe(column => this.onSortChanged(column));

    return formControl;
  }

  onSortChanged(column: TableColumn): void {
    switch (column) {
      case TableColumn.MANAGED_BY:
        this.sortingParams.sortAttributes = [ 'manager.firstName' ];
        break;
      case TableColumn.PURPOSE:
        this.sortingParams.sortAttributes = [ 'purposeTitle' ];
        break;
      case TableColumn.NEXT_MEETING:
        this.sortingParams.sortAttributes = [ 'startDateTime' ];
        break;
      case TableColumn.FREQUENCY:
        this.sortingParams.sortAttributes = [ 'frequency' ];
        break;
      case TableColumn.ACTIONS:
      default:
        return;
    }

    this.getData();
  }

  getSortingOptions(): SortingOption[] {
    return [
      {
        value: TableColumn.MANAGED_BY,
        label: OneToOneMessages.MANAGED_BY
      },
      {
        value: TableColumn.PURPOSE,
        label: OneToOneMessages.PURPOSE
      },
      {
        value: TableColumn.NEXT_MEETING,
        label: OneToOneMessages.NEXT_MEETING
      },
      {
        value: TableColumn.FREQUENCY,
        label: OneToOneMessages.FREQUENCY
      }
    ];
  }

  isChangingSchedule(scheduleId: number): boolean {
    return this.oneToOneBusinessService.isModifyingSchedule(scheduleId);
  }
}
