import { Component, Input } from '@angular/core';
import { AbsorbLmsAPIService } from '@app/domain/absorblms/api/absorblms-api.service';
import { AbsorbLmsEnrollmentStatus } from '@app/domain/absorblms/model/absorblms-enrollment-status.model';
import { AbsorbLmsEnrollment } from '@app/domain/absorblms/model/absorblms-enrollment.model';
import { Globals } from '@app/shared/globals/globals';

@Component({
  selector: 'app-absorblms-enrollment-display',
  templateUrl: './absorblms-enrollment-display.component.html',
  styleUrls: ['./absorblms-enrollment-display.component.scss']
})
export class AbsorblmsEnrollmentDisplayComponent {

  public readonly eAbsorbLmsEnrollmentStatus = AbsorbLmsEnrollmentStatus;

  @Input() enrollment: AbsorbLmsEnrollment;

  get canSeeActions(): boolean {
    return this.globals.user.id === this.enrollment.frankliUserId;
  }

  constructor(
    public globals: Globals,
    private absorbLmsAPIService: AbsorbLmsAPIService
  ) { }

  showEnrollment(): void {
    this.absorbLmsAPIService.showEnrollment(this.enrollment.id)
      .toPromise()
      .then(enrollment => {
        this.enrollment.hidden = enrollment.hidden;
      });
  }

  hideEnrollment(): void {
    this.absorbLmsAPIService.hideEnrollment(this.enrollment.id)
      .toPromise()
      .then(enrollment => {
        this.enrollment.hidden = enrollment.hidden;
      });
  }
}
