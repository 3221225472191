import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { CommonMessages } from '@app/constants/common.messages';
import { TerminologyEntity } from '@app/domain/terminology/model/terminology-entity.enum';
import { SecondaryManager } from '@app/models/company/company-secondary-manager/secondary-manager.model';
import { Site } from '@app/models/site.model';
import { PreferredContactMethod } from '@app/models/user/preferred-contact-method.model';
import { User } from '@app/models/user/user.model';
import { UserAPIService } from '@app/shared/api/user.api.service';
import { PillType } from '@app/shared/components/pill/pill.component';
import { Globals } from '@app/shared/globals/globals';
import { NotifyUtils } from '@app/shared/utils/notify.utils';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-profile-basic-info',
  templateUrl: './profile-basic-info.component.html',
  styleUrls: ['./profile-basic-info.component.scss']
})
export class ProfileBasicInfoComponent implements OnInit {

  public readonly eTerminologyEntity = TerminologyEntity;
  public readonly ePillType = PillType;

  @Input() user: User;
  @Input() secondaryManager?: SecondaryManager;

  @Output() userChanged: EventEmitter<User> = new EventEmitter<User>();

  userCurrentTime?: string;
  userTimezone?: string;
  userManager?: User;

  get preferredContactMethodFriendlyName(): string {
    if (!this.user) return 'Unknown';
    if (!this.user.preferredContactMethod) return 'Unknown';
    switch (this.user.preferredContactMethod) {
      case PreferredContactMethod.NONE:
        return 'No preference';
      case PreferredContactMethod.IN_PERSON:
        return 'In person';
      case PreferredContactMethod.EMAIL:
        return 'Via email';
      case PreferredContactMethod.PHONE:
        return 'On the phone';
      case PreferredContactMethod.TEXT:
        return 'Via text message';
      case PreferredContactMethod.MICROSOFT_TEAMS:
        return 'On Microsoft Teams';
      case PreferredContactMethod.SLACK:
        return 'On Slack';
      case PreferredContactMethod.TWITTER:
        return 'On Twitter';
      case PreferredContactMethod.LINKEDIN:
        return 'On LinkedIn';
    }
  }

  constructor(
    public globals: Globals,
    public notifyUtils: NotifyUtils,
    private userAPIService: UserAPIService,
    private translateService: TranslateService
  ) {
    this.userCurrentTime = undefined;
    this.userTimezone = undefined;
    this.userManager = undefined;
  }

  ngOnInit(): void {
    this.getData();
  }

  getData(): void {
    if (!this.user) { return; }

    this.userTimezone = this.getUserTimezone(this.user);
    this.startLoopRefreshUserTime();

    this.getManager();
  }

  getUserTimezone(user: User): string | undefined {
    if (!user.homeAddress) {
      return undefined;
    }

    if (!user.homeAddress.timezone) {
      return undefined;
    }

    return user.homeAddress.timezone || undefined;
  }

  startLoopRefreshUserTime() {
    this.userCurrentTime = this.getUserCurrentTime();

    const time = new Date();
    const secondsRemaining = (60 - time.getSeconds()) * 1000;

    setTimeout(() => {
      this.userCurrentTime = this.getUserCurrentTime();

      setInterval(() => {
        this.userCurrentTime = this.getUserCurrentTime();
      }, 60000);
    }, secondsRemaining + 100);
  }

  getUserCurrentTime(): string {
    try {
      return moment().tz(this.userTimezone).format('H:mm a');
    } catch {
      return this.translateService.instant(CommonMessages.UNKNOWN);
    }
  }

  initFormSite(site?: Site): FormControl {
    const formControl = new FormControl(null, []);

    if (site) {
      formControl.setValue(site);
    }

    formControl.valueChanges
      .pipe(debounceTime(1000))
      .subscribe(val => {
        if (val) {
          this.onSiteChanged(val);
        }
      });

    return formControl;
  }

  initFormCity(city?: string): FormControl {
    const formControl = new FormControl(null, []);

    if (city) {
      formControl.setValue(city);
    }

    formControl.valueChanges
      .pipe(debounceTime(1000))
      .subscribe(val => {
        if (val) {
          this.onCityChanged(val);
        }
      });

    return formControl;
  }

  onSiteChanged(site: Site): void {
    this.userAPIService.updateUserOfficeLocation(site).subscribe(user => {
      this.notifyUtils.notify('Site updated');
      this.userChanged.emit(user);
    });
  }

  onCityChanged(city: string): void {
    this.userAPIService.updateUserCity(city).subscribe(user => {
      this.userChanged.emit(user);
    });
  }

  getManager(): void {
    if (!this.user) { return; }
    if (!this.user.managerId) { return; }
    if (this.user.managerId === this.user.id) { return; }

    this.userAPIService.getById(this.user.managerId).toPromise()
      .then(user => {
        this.userManager = user;
      });
  }
}
