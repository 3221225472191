import { Injectable } from '@angular/core';
import { MockService } from '@app/mock/api/mock-service';
import { SettingsService } from '@app/shared/api/interfaces/settings.service';
import { HttpRequest } from '@angular/common/http';
import { sanitizeUrl } from '@app/shared/utils/helpers';

@Injectable()
export class SettingsMockService implements MockService, SettingsService {
    handleRoute(req: HttpRequest<any>): any {
        const url = sanitizeUrl(req.urlWithParams);

        switch (true) {
            case url.endsWith('api/settings/spring.servlet.multipart.max-file-size'):
                return this.getMaxFileUploadSize();
        }
    }

    getMaxFileUploadSize(): string {
        return '50MB';
    }

    getAllSettings(): any {
        return undefined;
    }
}