<div class="progress-outer">
  <div class="progress-center" [style.height.px]="size">
    <div class="progress progress-round" [style.width.px]="size" [style.height.px]="size">
      <span class="progress-left">
        <span class="progress-bar border-primary" [style.transform]="roundProgress.left" [style.border-width.px]="thickness"
         [style.border-top-right-radius.px]="size" [style.border-bottom-right-radius.px]="size" [class.transition-delay]="progressNumber > 50"></span>
      </span>
      <span class="progress-right">
        <span class="progress-bar border-primary" [style.transform]="roundProgress.right" [style.border-width.px]="thickness"
         [style.border-top-left-radius.px]="size" [style.border-bottom-left-radius.px]="size" [class.transition-delay]="progressNumber < 50"></span>
      </span>
      <div class="progress-background-circle" [style.border-width.px]="thickness"></div>
      <div class="progress-value-background-circle" [style.width.px]="size - (thickness * 2)" [style.height.px]="size - (thickness * 2)" [style.top.px]="thickness" [style.left.px]="thickness"></div>
      <div class="progress-value rounded-circle" [style.line-height.px]="size" [style.font-size.px]="fontSize" [style.color]="textColor" *ngIf="showValue">
        <span>{{(progressNumber ? (progressNumber | number: '0.0-0') : '0')}}%</span>
      </div>
    </div>
  </div>
</div>