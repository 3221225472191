<div class="multi-choice" [class.multi-disabled]="disabled">
  <!-- Multi-select info pill -->
  <div class="text-left m-bottom-10" [ngSwitch]="multiple">
    <app-pill *ngSwitchCase="true" [text]="'You may select multiple options'" [type]="ePillType.BLUE"></app-pill>
    <app-pill *ngSwitchCase="false" [text]="'You may select a single option'" [type]="ePillType.YELLOW"></app-pill>
  </div>

  <!-- Option list -->
  <div class="choice-option" [class.selected]="isSelected(choice.answerRank)" *ngFor="let choice of answers; index as i" (click)="select(choice.answerRank)">
    <div class="choice-index">
      <span>Option {{i + 1}}</span>
    </div>
    <div class="choice-answer">
      <span>{{choice.possibleAnswer}}</span>
    </div>
  </div>
</div>