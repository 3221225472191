import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

@Pipe({
  name: 'dateFormat'
})
export class DateFormatPipe implements PipeTransform {
  previousValue!: Date | string;
  resultCached!: string;

  constructor() {
      this.previousValue = '';
      this.resultCached = '';
  }

  transform(value: Date | string, args: any): string {
      try {
          if (value === this.previousValue) {
              return this.resultCached;
          }

          this.previousValue = value;

          const date = moment(value);
          const result = date.format(args);

          this.resultCached = result;
          return result;
      } catch {
          return '';
      }
  }
}
