<ng-container *ngIf="!state.loading; else templateStateLoading;">
  <ng-container *ngIf="!state.error; else templateStateError;">
    <div>Loaded successfully</div>
  </ng-container>
</ng-container>
<ng-template #templateStateLoading>
  <div id="loader" class="loading-animation">
    <div class="loader-container">
      <div class="loader-icon loader-icon-1">
      </div>
      <div class="loader-icon loader-icon-2">
      </div>
      <div class="loader-icon loader-icon-3">
      </div>
    </div>
  </div>
  <div class="message-container">
    <span>Signing you in from Teams...</span>
  </div>
</ng-template>
<ng-template #templateStateError>
  <div class="error-message-container">
    <img src="assets/img/swal-icons/frankli-warning-icon.svg" alt="warning-icon" />
    <ng-container *ngIf="!showDemoPrompt; else demoPrompt">
      <div>
        <span>We ran into trouble signing you in from Teams, click the</span>
        <span class="m-right-5"></span>
        <span class="fas fa-redo-alt"></span>
        <span class="m-right-5"></span>
        <span>button in Teams to try again.</span>
      </div>
      <div>Reason - {{state.errorMessage}}</div>
      <div>
        <span>Feel free to visit the help</span>
        <span class="m-right-5"></span>
        <a href="https://help.frankli.io" target="_blank">here</a>
        <span class="m-right-5"></span>
        <span>, where you can view various how-to articles and get in contact with a member of the team if you need any additional support.</span>
      </div>
    </ng-container>
    <ng-template #demoPrompt>
      <span>You need an active Frankli subscription to access this app. You can</span>
      <span class="m-right-5"></span>
      <a href="https://www.frankli.io/demo-request" target="_blank">book a demo</a>
      <span class="m-right-5"></span>
      <span>with us at a time that suits you to get started!</span>
    </ng-template>
  </div>
</ng-template>