<div class="container-task-activity">
  <app-state [state]="state">
    <ng-container *ngIf="!state.loading && !state.error">
      <!-- Add comment form -->
      <ng-container *ngIf="showCommentForm">
        <div class="frankli-form-group frankli-form-group-filled" [class.frankli-form-group-invalid]="state.submitted && commentControl.invalid">
          <label>
            <span class="fal fa-fw fa-comment"></span>
            <span translate>{{eTasksMessages.QUESTION_POST_A_COMMENT}}</span>
          </label>
          <input class="form-control" [formControl]="commentControl" (keyup.enter)="onCommentSubmit()">
          <div class="p-relative">
            <small translate>{{eTasksMessages.COMMENT_SUBMIT_PROMPT}}</small>
            <small class="character-count" [class.text-danger]="commentControl.value.length > 1000">{{commentControl.value.length}} / 1000</small>
          </div>
          <div class="frankli-form-group-alert">
            <div *ngIf="commentControl.hasError('maxlength')" translate [translateParams]="{maxValue: 1000}">{{eCommonMessages.FORM_MAX_CHARACTER_VALUE}}</div>
            <ng-container *ngIf="state.submitted">
              <div *ngIf="commentControl.hasError('required')" translate>{{eCommonMessages.FORM_PROVIDE_DETAIL}}</div>
              <div *ngIf="commentControl.hasError('minlength')" translate [translateParams]="{minValue: 5}">{{eCommonMessages.FORM_MIN_CHARACTER_VALUE}}</div>
            </ng-container>
          </div>
        </div>
      </ng-container>

      <!-- Activity list -->
      <ng-container *ngFor="let logItem of activity">
        <div class="task-activity-card" [ngClass]="'card-' + logItem.type">
          <div class="task-activity-image">
            <app-profile-photo [src]="logItem.user.imageUrl" [firstName]="logItem.user.firstName" [lastName]="logItem.user.lastName"></app-profile-photo>
          </div>
    
          <div class="task-activity-description">
            <ng-container [ngSwitch]="logItem.type">
              <ng-container *ngSwitchCase="eAuditLogType.ACTIVITY">
                <app-task-activity-display [activity]="logItem.activityData!"></app-task-activity-display>
              </ng-container>
              <div *ngSwitchCase="eAuditLogType.COMMENT">
                <app-task-comment-display [comment]="logItem.commentData!"></app-task-comment-display>
              </div>
            </ng-container>
          </div>
    
          <div class="task-activity-date">{{logItem.timestamp | momentPipe: 'D MMM YYYY HH:mm'}}</div>
    
          <div class="delete-activity-icon frankli-close" *ngIf="logItem.type === eAuditLogType.COMMENT"
            (click)="onCommentDelete(logItem)">
            <span class="fal fa-fw fa-times"></span>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </app-state>
</div>