<div class="container-sidebar-zendesk">
  <app-state [state]="state">
    <ng-container *ngIf="!state.loading && !state.error;">
      <div class="card card-yellow">
        <div class="content">
          <span class="fal fa-fw fa-info-circle"></span>
          <span class="m-right-15"></span>
          <span class="context-text">
            <ng-container *ngIf="userStatus; else templateNoUserStatus;">
              <span>Last updated {{userStatus.lastSync | dateAgo | lowercase}} </span>
            </ng-container>
            <ng-template #templateNoUserStatus>
              <span>We will get your data from Zendesk soon...</span>
            </ng-template>
          </span>
        </div>
      </div>

      <ng-container *ngIf="dates && dates.length > 0; else templateNoData;">
        <!-- Filters -->
        <ng-container *ngIf="dates && dates.length > 1">
          <div class="p-bottom-15">
            <div class="frankli-form-group frankli-form-group-filled">
              <label for="month">Month</label>
              <select id="month" name="month" class="form-control" [formControl]="controlMonth">
                <option [ngValue]="null" selected *ngIf="controlMonth.value === null">Please select...</option>
                <option *ngFor="let dt of dates" [ngValue]="dt">{{ dt | momentPipe: 'MMMM YYYY'}}</option>
              </select>
            </div>
          </div>
        </ng-container>

        <!-- Data Output -->
        <div class="metric-list">
          <div class="metric-item">
            <div class="metric-item-top">
              <div class="metric-value">{{ticketsSolved}}</div>
              <div class="metric-icon">
                <span class="fal fa-fw fa-calendar-check"></span>
              </div>
            </div>
            <div class="metric-title">Solved in the past month</div>
          </div>
          <div class="metric-item">
            <div class="metric-item-top">
              <div class="metric-value">{{csatScore}}</div>
              <div class="metric-icon">
                <span class="fal fa-fw fa-calendar-range"></span>
              </div>
            </div>
            <div class="metric-title">CSAT score for the current 90 days</div>
          </div>
          <div class="metric-item">
            <div class="metric-item-top">
              <div class="metric-value">{{csatScorePrevious}}</div>
              <div class="metric-icon">
                <span class="fal fa-fw fa-calendar-clock"></span>
              </div>
            </div>
            <div class="metric-title">CSAT score previous 90 days</div>
          </div>
        </div>
      </ng-container>

      <ng-template #templateNoData>
        <app-empty-state [height]="'200px'"></app-empty-state>
      </ng-template>
    </ng-container>
  </app-state>
</div>
