<div class="display-key-results">
  <div class="scroll-x">
    <table width="100%">
      <!-- Headings -->
      <tr class="headers-row">
        <th class="kr-header" *ngIf="showIndex"></th>
        <th class="kr-header" *ngIf="showTitle" translate>{{eGoalMessages.KEY_RESULT}}</th>
        <th class="kr-header" *ngIf="showDueDate" translate>{{eGoalMessages.DUE_DATE}}</th>
        <th class="kr-header" *ngIf="showResultType" translate>{{eGoalMessages.MEASURE_TYPE}}</th>
        <th class="kr-header" *ngIf="showUnit" translate>{{eGoalMessages.UNIT}}</th>
        <th class="kr-header" *ngIf="showUnitPlacement" translate>{{eGoalMessages.UNIT_PLACEMENT}}</th>
        <th class="kr-header" *ngIf="showProgress" translate>{{eGoalMessages.PROGRESS}}</th>
      </tr>
    
      <!-- Key Results -->
      <tr class="key-result-row" *ngFor="let result of keyResults; index as index">
        <td *ngIf="showIndex">
          <div class="kr-index">{{index + 1}}.</div>
        </td>
        <td *ngIf="showTitle">
          <ng-container *ngIf="result.result; else templateNoData">
            <div class="kr-title">{{result.result}}</div>
          </ng-container>
        </td>
        <td *ngIf="showDueDate">
          <app-pill *ngIf="result.endDate; else templateNoDueDate">
            <span>{{result.endDate | date }}</span>
          </app-pill>
          <ng-template #templateNoDueDate>
            <div class="no-data" translate>{{eCommonMessages.NOT_SET}}</div>
          </ng-template>
        </td>
        <td *ngIf="showResultType">
          <ng-container [ngSwitch]="result.type">
            <div *ngSwitchCase="eGoalKeyResultType.BINARY" translate>{{eGoalMessages.COMPLETE_INCOMPLETE}}</div>
            <div *ngSwitchCase="eGoalKeyResultType.NUMERIC" translate>{{eGoalMessages.NUMERIC}}</div>
            <div *ngSwitchDefault translate>{{eCommonMessages.UNKNOWN}}</div>
          </ng-container>
        <td *ngIf="showUnit">
          <ng-container *ngIf="result.measureUnit; else templateNoData">
            <div>{{result.measureUnit}}</div>
          </ng-container>
        </td>
        <td *ngIf="showUnitPlacement">
          <ng-container *ngIf="(result.type === eGoalKeyResultType.NUMERIC) && result.measureUnit; else templateNoData">
            <ng-container [ngSwitch]="result.measureUnitPlacement">
              <div *ngSwitchCase="eGoalKeyResultMeasureUnitPlacement.BEFORE" translate>{{eGoalMessages.BEFORE}}</div>
              <div *ngSwitchCase="eGoalKeyResultMeasureUnitPlacement.AFTER" translate>{{eGoalMessages.AFTER}}</div>
              <div *ngSwitchDefault translate>{{eCommonMessages.UNKNOWN}}</div>
            </ng-container>
          </ng-container>
        </td>
        <td *ngIf="showProgress">
          <app-progress-bar [startValue]="result.measureStartValue" [targetValue]="result.measureGoalValue" [currentValue]="result.measureCurrentValue" [unitDetail]="result.measureUnit" [showTextOnHover]="showDetailedProgress" [showDetailUnitBeforeValue]="result.measureUnitPlacement === eGoalKeyResultMeasureUnitPlacement.BEFORE"></app-progress-bar>
        </td>
      </tr>
    </table>
  </div>
</div>

<ng-template #templateNoData>
  <div class="no-data" translate>{{eCommonMessages.NOT_SET}}</div>
</ng-template>