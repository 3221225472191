import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { IdeaEffect } from '@app/models/feedback/idea-effect.model';
import { OneToOneMeetingStatus } from '@app/domain/one_to_one/model/one-to-one-meeting-status.model';
import { OneToOneStatus } from '@app/domain/one_to_one/model/one-to-one-status.model';
import { CalendarAPIService } from '@app/shared/api/calendar.api.service';
import { Breadcrumb } from 'app/models/breadcrumb.model';
import { CompanyFeatures } from '@app/models/company-features.model';
import { OneToOneMeeting } from '@app/domain/one_to_one/model/one-to-one-meeting.model';
import { OneToOneScheduleDetailsView, OneToOneScheduleMinimal } from '@app/domain/one_to_one/model/one-to-one-schedule.model';
import { ScheduleLocationType } from '@app/domain/one_to_one/model/schedule-location-type.model';
import { TalkingPointMinimal } from '@app/domain/one_to_one/model/talking-point.model';
import { UserAPIService } from 'app/shared/api/user.api.service';
import { BreadcrumbService } from 'app/shared/breadcrumbs/breadcrumbs.service';
import { Globals } from 'app/shared/globals/globals';
import { Subject, forkJoin, of } from 'rxjs';
import { EvaluationModuleType } from '@app/models/evaluation/evaluation-module-type.model';
import { SurveyQuestionType } from '@app/models/survey-question-type.enum';
import { SwalUtils } from '@app/shared/utils/swal.utils';
import { OneToOneMessages } from '@app/domain/one_to_one/locale/one-to-one.messages';
import { CommonMessages } from '@app/constants/common.messages';
import { FeedbackMessages } from '@app/feedback/feedback.messages';
import { GoalsMessages } from '@app/goals/goals.messages';
import { GoalStatus } from '@app/models/goals/goal-status.model';
import { GoalUtils } from '@app/shared/utils/goal.utils';
import { TasksMessages} from '@app/domain/task/locale/tasks.messages';
import { PillType } from '@app/shared/components/pill/pill.component';
import { ButtonType } from '@app/shared/components/inputs/button/button.component';
import { IconHoverColor } from '@app/shared/components/inputs/table-action-icon/table-action-icon.component';
import { IState } from '@app/models/state/state.model';
import { OneToOneBusinessService } from '../../service/one-to-one-business.service';
import { InformationSidebarTab } from '@app/shared/components/information-sidebar/information-sidebar.component';
import { ScimUserApiService } from '@app/shared/api/scim-user.api.service';
import { NotificationService } from '@app/shared/notifications/notification.service';
import { FrankliNotification } from '@app/models/notifications/frankli-notification.model';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { FrankliNotificationType } from '@app/models/notifications/frankli-event-type.enum';
import { EmployeeHierarchy } from '@app/models/user/employee-hierarchy.model';
import { OneToOneMeetingViewComponent } from '../one-to-one-meeting-view/one-to-one-meeting-view.component';
import { CalendarConnectionStatus } from '../../model/calendar-connected.model';
import moment from 'moment';
import 'moment-timezone';
import { UserMinimal } from '@app/models/user/user-minimal.model';
import { EvaluationsMessages } from '@app/domain/review_cycle/locale/evaluations.messages';

interface PageState extends IState {
  loadingButton: boolean;
  changingMeeting: boolean;
}

const TEAMS_MEETING_LINK_URL = 'https://teams.microsoft.com/l/meetup-join/';
const REFRESH_NOTIFICATION_TYPES = [FrankliNotificationType.ONE_TO_ONE_MEETING_STARTED, FrankliNotificationType.ONE_TO_ONE_MEETING_COMPLETED];

@Component({
  selector: 'one-to-one-schedule-view',
  templateUrl: './one-to-one-schedule-view.component.html',
  styleUrls: ['./one-to-one-schedule-view.component.scss'],
})
export class OneToOneScheduleView implements OnInit, OnDestroy {
  public readonly eInformationSidebarTab = InformationSidebarTab;
  public readonly eOneToOneMeetingStatus = OneToOneMeetingStatus;
  public readonly eEvaluationModuleType = EvaluationModuleType;
  public readonly eScheduleLocationType = ScheduleLocationType;
  public readonly eEvaluationsMessages = EvaluationsMessages;
  public readonly eSurveyQuestionType = SurveyQuestionType;
  public readonly eOneToOneMessages = OneToOneMessages;
  public readonly eFeedbackMessages = FeedbackMessages;
  public readonly eCommonMessages = CommonMessages;
  public readonly eOneToOneStatus = OneToOneStatus;
  public readonly eIconHoverColor = IconHoverColor;
  public readonly eGoalsMessages = GoalsMessages;
  public readonly eTasksMessages = TasksMessages;
  public readonly eFeatures = CompanyFeatures;
  public readonly eGoalStatus = GoalStatus;
  public readonly eButtonType = ButtonType;
  public readonly eIdeaEffect = IdeaEffect;
  public readonly eGoalUtils = GoalUtils;
  public readonly ePillType = PillType;
  public readonly currentUserTimezone = moment.tz.guess();

  private readonly ngUnsubscribe$ = new Subject<void>();

  @ViewChild('meetingView') meetingView?: OneToOneMeetingViewComponent;

  breadcrumb: Breadcrumb;
  state: PageState;
  schedule!: OneToOneScheduleDetailsView;
  sidebarTabs: InformationSidebarTab[];
  sidebarUserIds: number[];
  descendants: EmployeeHierarchy[];
  shouldOpenReschedule: boolean;
  calendarConnectionStatus: CalendarConnectionStatus;

  locationTypeFormatted: string;
  locationDetailsIsUrl: boolean;

  meeting: OneToOneMeeting;
  meetingPrevious?: OneToOneMeeting;

  meetingNavigationControl!: FormControl;

  controlAdvancedSearch: FormControl;
  advancedSearchRunning: boolean;
  meetingIdsHighlighted: number[];

  userIdsManaging: number[];

  get shouldShowCallOnTeamsButton(): boolean {
    if (!this.schedule) { return false; }
    if (this.schedule.status === OneToOneStatus.PAUSED) { return false; }
    if (this.schedule.locationType !== ScheduleLocationType.MICROSOFT_TEAMS) { return false; }
    return true;
  }

  get locationIsMicrosoftTeamsUrl(): boolean {
    if (!this.schedule) { return false; }
    if (this.schedule.locationType !== ScheduleLocationType.MICROSOFT_TEAMS) { return false; }
    if (!this.schedule.location.includes(TEAMS_MEETING_LINK_URL)) { return false; }
    return true;
  }

  get userIsManager(): boolean {
    if (!this.globals.user) { return false; }
    if (!this.schedule) { return false; }
    if (this.globals.user.id !== this.schedule.manager.id) { return false; }
    return true;
  }

  get otherUserSingleParticipant(): UserMinimal {
    if (this.schedule.participants.length !== 1) { return null; }
    if (this.schedule.manager.id === this.globals.user.id) {
      return this.schedule.participants[0];
    } else {
      return this.schedule.manager;
    }
  }

  constructor(
    public route: ActivatedRoute,
    public globals: Globals,
    public router: Router,
    private oneToOneBusinessService: OneToOneBusinessService,
    private notificationService: NotificationService,
    private calendarAPIService: CalendarAPIService,
    private scimUserApiService: ScimUserApiService,
    private breadcrumbService: BreadcrumbService,
    private userAPIService: UserAPIService,
    private swalUtils: SwalUtils
  ) {
    this.state = {
      loading: true,
      error: false,
      changingMeeting: false,
      loadingButton: false,
      errorMessage: ''
    };

    this.sidebarTabs = [];
    this.sidebarUserIds = [];
    this.descendants = [];
    this.userIdsManaging = [];

    this.shouldOpenReschedule = false;
    this.locationDetailsIsUrl = false;

    this.calendarConnectionStatus = undefined;
    this.locationTypeFormatted = undefined;

    this.meetingNavigationControl = this.initMeetingNavigationControl();

    this.controlAdvancedSearch = this.initControlAdvancedSearch();
    this.advancedSearchRunning = true;
    this.meetingIdsHighlighted = [];

    this.breadcrumb = this.breadcrumbService.initWithLabel(this.route, false, OneToOneMessages.VIEW_SCHEDULE_BREADCRUMB);

    // subscribing to the notification
    this.notificationService.actionNotification$
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe((notification: FrankliNotification) => {
        if (!notification) { return; }
        if (!this.schedule) { return; } // Schedule must exist to refresh
        if (!this.meeting) { return; } // Meeting must exist to check it
        if (notification.eventForeignId !== this.meeting.id) { return; } // Notification must be about this meeting
        if (!REFRESH_NOTIFICATION_TYPES.includes(notification.eventType)) { return; }

        this.getScheduleData(this.schedule.id);
      });

    this.route.paramMap.subscribe(params => this.onQueryParamChange(params));
  }

  ngOnInit(): void {
    this.getDescendants();
    this.getCalendarConnctedStatus();
  }

  getDescendants(): void {
    this.userAPIService.findAllDescendantsForUserId(this.globals.user.id)
      .toPromise()
      .then(descendants => {if (descendants) this.descendants = descendants;});
  }

  onQueryParamChange(params: ParamMap): void {
    const paramScheduleId = params.get('scheduleId');
    if (!paramScheduleId) {
      this.doError('Invalid Schedule ID');
      return;
    }

    const paramMeetingId = params.get('meetingId');

    this.shouldOpenReschedule = this.route.snapshot.queryParams.reschedule;

    const scheduleId: number = parseInt(paramScheduleId, 0);

    const meetingId: number = paramMeetingId ? parseInt(paramMeetingId, 0) : null;
    this.getScheduleData(scheduleId, meetingId);
  }

  ngOnDestroy(): void {
    this.breadcrumbService.remove(this.breadcrumb);
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.unsubscribe();
  }

  getScheduleData(scheduleId: number, meetingId?: number): void {
    if (!scheduleId) {
      return this.doError('Invalid Schedule ID');
    }

    this.oneToOneBusinessService.getOneToOneScheduleDetailsById(scheduleId)
      .toPromise()
      .then(schedule => {
        if (!schedule) {
          return this.doError('Failed to find schedule data');
        }

        this.schedule = schedule;

        this.refreshSidebarProperties(schedule);

        this.locationTypeFormatted = this.getLocationTypeFormatted(schedule.locationType);
        this.locationDetailsIsUrl = this.checkIsLocationUrl(schedule.location);

        this.userIdsManaging = this.getScheduleUsersManagedByCurrentUser(schedule);

        const meetingIdValid = (meetingId && schedule.meetingList.some(m => m.id === meetingId));
        if (meetingIdValid) {
          this.meetingNavigationControl.setValue(meetingId);
        } else {
          const lastMeeting = this.schedule.meetingList[this.schedule.meetingList.length - 1];
          this.meetingNavigationControl.setValue(lastMeeting.id);
        }
      })
      .catch(err => {
        this.doError(err);
      });
  }

  getScheduleUsersManagedByCurrentUser(schedule: OneToOneScheduleDetailsView): number[] {
    const userIdsManaged: number[] = [];

    schedule.participants.forEach(participant => {
      if (participant.managerId === this.globals.user.id) {
        userIdsManaged.push(participant.id);
      }
    });

    return userIdsManaged;
  }

  getLatestMeetingId(schedule: OneToOneScheduleMinimal): number {
    if (!schedule) { return null; }

    if (schedule.nextMeeting) {
      return schedule.nextMeeting.id;
    }

    if (schedule.lastMeeting) {
      return schedule.lastMeeting.id;
    }

    return null;
  }

  initMeetingNavigationControl(): FormControl {
    const formControl = new FormControl(null, []);

    formControl.valueChanges.subscribe((meetingIndex: number) => this.getMeetingById(meetingIndex));

    return formControl;
  }

  initControlAdvancedSearch(): FormControl {
    const formControl = new FormControl('', []);

    formControl.valueChanges
      .pipe(debounceTime(500))
      .pipe(distinctUntilChanged())
      .subscribe((search: string) => {
        this.advancedSearchRunning = true;
        this.oneToOneBusinessService.searchForMeetingByContents(this.schedule.id, search)
          .then(result => {
            this.meetingIdsHighlighted = result;
          })
          .finally(() => {
            this.advancedSearchRunning = false;
          });
      });

    return formControl;
  }

  getMeetingById(meetingId: number): void {
    if (!this.schedule) { return; }
    if (!meetingId) { return; }

    const meetingIndexCurrent = this.schedule.meetingList.findIndex(m => m.id === meetingId);
    if (meetingIndexCurrent < 0) { return; }

    const meetingIndexPrevious = meetingIndexCurrent - 1;
    let meetingPreviousId: number = null;
    try {
      meetingPreviousId = this.schedule.meetingList[meetingIndexPrevious].id;
    } catch (ex) {
      console.debug('failed to get previous meeting id');
    }

    forkJoin([
      this.oneToOneBusinessService.getMeetingById(this.schedule.id, meetingId),
      meetingPreviousId ? this.oneToOneBusinessService.getMeetingById(this.schedule.id, meetingPreviousId) : of(null as OneToOneMeeting)
    ])
      .toPromise()
      .then(([meetingCurrent, meetingPrevious]) => {
        this.meeting = meetingCurrent;

        if (meetingPrevious) {
          this.meetingPrevious = meetingPrevious;
        } else {
          this.meetingPrevious = undefined;
        }

        this.state.loading = false;

        this.checkShouldOpenReschedule();
      });
  }

  checkShouldOpenReschedule(): void {
    setTimeout(() => {
      if (!this.shouldOpenReschedule) { return; }
      if (!this.meetingView) { return; }
      this.meetingView.tryRescheduleMeeting();
    }, 1);
  }

  doError(message: string): void {
    this.state.error = true;
    this.state.loading = false;
    this.state.errorMessage = message;
  }

  getLocationTypeFormatted(locationType: ScheduleLocationType): string {
    if (locationType === ScheduleLocationType.IN_PERSON) {
      return OneToOneMessages.IN_PERSON;
    } else if (locationType === ScheduleLocationType.OTHER) {
      return OneToOneMessages.OTHER;
    }

    // Capitalize and replace any '_' with ' '
    return locationType.charAt(0) + locationType.slice(1).toLowerCase().replace(/_/g, ' ');
  }

  checkIsLocationUrl(location: string): boolean {
    try {
      const url = new URL(location);
      return !!url;
    } catch (_exception) {
      return false;
    }
  }

  navigateToLocationDetailsUrl(location: string): void {
    if (!(location.includes('http:') || location.includes('https:'))) {
      location = `http://${location}`;
    }

    window.open(location, '_blank', 'noopener noreferrer');
  }

  getCalendarConnctedStatus(): void {
    this.calendarAPIService.isConnected()
      .toPromise()
      .then(calendarConnectionStatus => {
        this.calendarConnectionStatus = calendarConnectionStatus;
      });
  }

  tryCallOnTeams(): void {
    if (!this.shouldShowCallOnTeamsButton) { return; }

    // If location points at a microsoft URL, navigate to that
    if (this.schedule.location.includes('https://teams.microsoft.com')) {
      window.open(this.schedule.location, '_blank');
      return;
    }

    if (this.schedule.participants.length > 1) {
      this.swalUtils.displayErrorSwal({
        title: 'Group calls are not supported',
        text: 'You can only start a call with one other person'
      });
      return;
    }

    // Otherwise, get the other users teams URI and open that
    const participantId = this.schedule.participants[0].id;
    this.scimUserApiService.getTeamsCallURIForUserByUserId(participantId).subscribe(teamsUrl => {
      // Open up the chat if we couldn't find their UPN
      // let urlValue = 'https://teams.microsoft.com/l/chat/0/0';

      if (!teamsUrl) {
        this.swalUtils.displayErrorSwal({
          title: 'Unable to start call',
          text: 'Failed to find a the participant on Teams'
        });
        return;
      }

      // Start a call directly if we were able to find their UPN
      const urlValue = teamsUrl.toString();
      window.open(urlValue, '_blank');
    });
  }

  canSeeSidebarTab(tab: InformationSidebarTab): boolean {
    // return false;
    switch(tab) {
      // These are always enabled
      case InformationSidebarTab.SCHEDULE_CALENDAR:
        return true;
      // These are permanently disabled for now
      // case InformationSidebarTab.QUALIFICATIONS:
      case InformationSidebarTab.ACTION_POINTS:
      case InformationSidebarTab.ONE_TO_ONES:
      case InformationSidebarTab.FEEDBACK_UNPROMPTED:
      case InformationSidebarTab.FEEDBACK_PEER:
      case InformationSidebarTab.FEEDBACK_REQUESTS:
      case InformationSidebarTab.FEEDBACK_EXTERNAL:
        return false;
      case InformationSidebarTab.QUALIFICATIONS:
        if (!this.globals.hasFeature(CompanyFeatures.QUALIFICATIONS)) { return false; }
        return true;
      case InformationSidebarTab.SKILLS:
        if (!this.globals.hasFeature(CompanyFeatures.SKILLS)) { return false; }
        if (this.schedule.participants.length != 1) { return false; }
        return true;
      case InformationSidebarTab.GOALS:
        if (!this.globals.hasFeature(CompanyFeatures.GOALS)) { return false; }
        return true;
      case InformationSidebarTab.TASKS:
        if (!this.globals.hasFeature(CompanyFeatures.TODOS)) { return false; }
        if (this.schedule.participants.length > 1) { return false; } // Not visible on team meetings
        return true;
      case InformationSidebarTab.FEEDBACK_PRAISE:
        if (!this.globals.hasFeature(CompanyFeatures.FEEDBACK_PRAISE)) { return false; }
        return true;
      case InformationSidebarTab.FEEDBACK_IDEAS:
        if (!this.globals.hasFeature(CompanyFeatures.FEEDBACK_IDEAS)) { return false; }
        return true;
      case InformationSidebarTab.COMPETENCIES:
        if (!this.globals.hasFeature(CompanyFeatures.COMPETENCIES)) { return false; }
        return true;
      case InformationSidebarTab.REVIEWS:
        if (!this.globals.hasFeature(CompanyFeatures.EVALUATION_CYCLES)) { return false; }
        if (this.schedule.participants.length > 1) { return false; }
        if (!this.schedule.evaluationCycleId) { return false; }
        return true;
      case InformationSidebarTab.VINCERE:
        if (!this.globals.hasFeature(CompanyFeatures.VINCERE)) { return false; }
        if (this.schedule.participants.length !== 1) { return false; }
        if (this.otherUserSingleParticipant.managerId === this.globals.user.id) { return true; }
        if (this.descendants.some(x => x.descendantId === this.otherUserSingleParticipant.id)) { return true; }
        return false;
      case InformationSidebarTab.ZENDESK:
        if (!this.globals.hasFeature(CompanyFeatures.ZENDESK)) { return false; }
        if (this.schedule.participants.length !== 1) { return false; }
        if (this.otherUserSingleParticipant.managerId === this.globals.user.id) { return true; }
        if (this.descendants.some(x => x.descendantId === this.otherUserSingleParticipant.id)) { return true; }
        return false;
      case InformationSidebarTab.EVALUAGENT:
        if (!this.globals.hasFeature(CompanyFeatures.EVALUAGENT)) { return false; }
        if (this.schedule.participants.length !== 1) { return false; }
        if (this.otherUserSingleParticipant.managerId === this.globals.user.id) { return true; }
        if (this.descendants.some(x => x.descendantId === this.otherUserSingleParticipant.id)) { return true; }
        return false;
      case InformationSidebarTab.ABSORBLMS:
        if (!this.globals.hasFeature(CompanyFeatures.ABSORBLMS)) { return false; }
        if (this.schedule.participants.length !== 1) { return false; }
        if (this.otherUserSingleParticipant.managerId === this.globals.user.id) { return true; }
        if (this.descendants.some(x => x.descendantId === this.otherUserSingleParticipant.id)) { return true; }
        return false;
      case InformationSidebarTab.SENTIMENT_SCALE_TIMELINE:
        if (!this.globals.hasFeature(CompanyFeatures.ONE_TO_ONE_SENTIMENT_SCALE)) { return false; } // Must have feature enabled
        if (!this.schedule.sentimentScaleId) { return false; } // Schedule must have a sentiment scale
        // if (!this.userIsManager) { return false; } // Must be manager
        return true;
      default:
        return true;
    }
  }

  onMeetingUpdatedReceived(meeting: OneToOneMeeting): void {
    switch (meeting.status) {
      // Refresh the data and focus the same meeting
      case OneToOneMeetingStatus.REOPENED:
        this.getScheduleData(this.schedule.id, meeting.id);
        break;
      // Refresh the data and focus the latest meeting
      case OneToOneMeetingStatus.MISSED:
      case OneToOneMeetingStatus.SCHEDULED:
      case OneToOneMeetingStatus.IN_PROGRESS:
      case OneToOneMeetingStatus.CANCELLED:
      case OneToOneMeetingStatus.COMPLETED:
      case OneToOneMeetingStatus.AUTO_COMPLETED:
      default:
        this.getScheduleData(this.schedule.id);
        break;
    }
  }

  onSharedNotesUpdatedReceived(meeting: OneToOneMeeting): void {
    if (!this.meeting) { return; }
    if (!meeting) { return; }

    this.meeting.sharedNotes = meeting.sharedNotes;
  }

  getSidebarTabs(): InformationSidebarTab[] {
    const tabs: InformationSidebarTab[] = [];

    Object.keys(InformationSidebarTab).forEach((key) => {
      const tab = InformationSidebarTab[key];
      if (this.canSeeSidebarTab(tab)) {
        tabs.push(tab);
      }
    });

    return tabs;
  }

  onTalkingPointUpdated(talkingPoint: TalkingPointMinimal): void {
    if (!talkingPoint) { return; }

    const indexSchedule = this.schedule.talkingPointsRecurring.findIndex(tp => tp.id === talkingPoint.id);
    if (indexSchedule > -1) {
      this.schedule.talkingPointsRecurring[indexSchedule] = talkingPoint;
    }
  }

  refreshSidebarProperties(schedule: OneToOneScheduleDetailsView): void {
    this.sidebarTabs = this.getSidebarTabs();

    // If it's a Team meeting, show all peoples data but if it's a one to one meeting, show only the participant's data
    if (schedule.participants.length > 1) {
      this.sidebarUserIds = [schedule.manager.id, ...schedule.participants.map(p => p.id)];
    } else {
      this.sidebarUserIds = [schedule.participants[0].id];
    }
  }
}
