<div class="user-picker-container" [class.user-picker-disabled]="state.disabled">
  <div class="search-input-container" [class.picker-has-value]="_value && (_value.length > 0)">
    <div class="value-preview" [class.can-select-multiple]="canSelectMultiple" (click)="searchInput.focus()">
      <ng-container *ngIf="_value">
        <ng-container *ngFor="let user of _value">
          <div class="user-item-selected" [tooltip]="(canSelectMultiple && !state.disabled) ? (eCommonMessages.REMOVE | translate) : ''" [triggers]="globals.tooltipTriggers" (click)="removeSelectedItem(user, $event)">
            <div class="user-item-display">
              <ng-container *ngIf="!hideAvatarForIds.includes(user.id)">
                <app-profile-photo [width]="'30px'" [src]="user.imageUrl || null" [firstName]="user.firstName" [lastName]="user.lastName"></app-profile-photo>
              </ng-container>
              <span>{{user.firstName}} {{user.lastName}}</span>
            </div>
          </div>
        </ng-container>
      </ng-container>
      <input class="search-input" [class.has-value]="_value.length > 0" [class.search-invisible]="!state.dropdownOpen" [class.search-floats]="!canSelectMultiple"
        [formControl]="searchControl" (keyup.enter)="trySelectFirst()" (keyup.backspace)="onKeyupBackspace()" (keyUp.escape)="onKeyupEscape()" (focus)="onFocusSearch()"
        (blur)="onBlurSearch()" [placeholder]="(_value.length === 0) ? (searchPlaceholder | translate) : ''" #searchInput/>
    </div>
    <span class="icon-input-search fal fa-fw fa-search" *ngIf="state.dropdownOpen"></span>
  </div>

  <div class="search-results-container" *ngIf="state.dropdownOpen && !state.disabled">
    <div class="user-list">
      <ng-container *ngIf="!state.loading; else templateStateLoading;">
        <ng-container *ngIf="!state.searchRunning; else templateSearchRunning;">
          <ng-container *ngIf="(results.length > 0) || (searchControl.value !== '' && searchControl.value !== null) || (searchControl.value === '' && results.length > 0); else templateNoSearchValue;">
            <ng-container *ngIf="(results.length > 0); else templateNoSearchResults;">
              <div class="user-result-item" *ngFor="let user of results" (click)="selectItem(user)">
                <div class="user-item-display">
                  <ng-container *ngIf="!hideAvatarForIds.includes(user.id)">
                    <app-profile-photo [width]="'30px'" [src]="user.imageUrl || null" [firstName]="user.firstName" [lastName]="user.lastName"></app-profile-photo>
                  </ng-container>
                  <span>{{user.firstName}} {{user.lastName}}</span>
                </div>
              </div>
            </ng-container>
          </ng-container>
        </ng-container>
      </ng-container>

      <ng-template #templateNoSearchValue>
        <div class="text-center p-15">
          <span translate>{{eCommonMessages.START_TYPING_TO_SEARCH_OR_ENTER}}</span>
        </div>
      </ng-template>

      <ng-template #templateNoSearchResults>
        <div class="text-center p-15">
          <span translate>{{eCommonMessages.NO_SEARCH_USERS}}</span>
        </div>
      </ng-template>

      <ng-template #templateSearchRunning>
        <div class="text-center p-15">
          <span class="fas fa-spin fa-spinner fa-fw"></span>
        </div>
      </ng-template>

      <ng-template #templateStateLoading>
        <div class="text-center p-15">
          <span class="fas fa-spin fa-spinner fa-fw"></span>
        </div>
      </ng-template>
    </div>
  </div>
</div>
