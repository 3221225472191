import { Routes } from '@angular/router';
import { UserProfileComponent } from '../page/user-profile/user-profile.component';
import { GoalsIndividualComponent } from 'app/goals/goals-components/goals-individual/goals-individual.component';
import { ProfilePraiseFullComponent } from '../component/profile-praise-full/profile-praise-full.component';

export const ProfileRoutes: Routes = [
  {
    path: '', component: UserProfileComponent, data: { label: 'My Profile' }, children: [
      { path: 'goals/individual/:id', component: GoalsIndividualComponent, data: { label: 'Goal' } },
      {
        path: 'connect',
        loadChildren: () => import('../../../connect/connect.module').then(m => m.ConnectModule)
      },
      {
        path: 'feedback',
        loadChildren: () => import('app/feedback/feedback.module').then(m => m.FeedbackModule)
      }
    ]
  },
  {
    path: ':id', component: UserProfileComponent, data: { label: 'Profile' }, children: [
      { path: 'goals/individual/:id', component: GoalsIndividualComponent, data: { label: 'Goal' } },
      { path: 'individual/:id', component: GoalsIndividualComponent, data: { label: 'Goal' } },
      {
        path: 'connect',
        loadChildren: () => import('../../../connect/connect.module').then(m => m.ConnectModule)
      },
      {
        path: 'feedback',
        loadChildren: () => import('app/feedback/feedback.module').then(m => m.FeedbackModule)
      },
      { path: 'praise', component: ProfilePraiseFullComponent, data: { label: 'Praise' } }
    ]
  }
];

