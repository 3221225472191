<ng-container *ngIf="properties && properties.length > 0">
  <div class="category-container" [class.property-selected]="hasSomethingSelected"
    [class.mobile-touch-events]="globals.onMobile">
    <div class="category-text-container">
      <!-- <span *ngIf="(categoriesVisible === 1) && (propertiesEnabled.length === 0)">Search by</span> -->

      <span class="m-right-5">{{key.name | translate | underscoreToSpace}}{{(propertiesEnabled.length > 0) ? ':' :
        ''}}</span>

      <ng-container *ngIf="propertiesEnabled.length > 0;">
        <span>{{propertiesEnabled[0].name | translate}}</span>

        <ng-container *ngIf="propertiesEnabled.length > 1;">
          <span class="count-extra-properties">
            <span>+{{propertiesEnabled.length - 1}}</span>
          </span>
        </ng-container>
      </ng-container>

      <span class="dropdown-icon">
        <span class="fal fa-fw fa-chevron-down"></span>
      </span>
    </div>

    <div class="category-dropdown">
      <ng-container *ngIf="!hasDatePicker; else templateHasDatePicker">
        <ng-container *ngFor="let property of properties; index as index">
          <ng-container *ngIf="!property.isDatePicker">
            <div class="property-item" (click)="toggleProperty($event, property, index)">
              <input class="property-checkbox" type="checkbox" [checked]="property.enabled" />
              <span class="property-name m-left-5">{{property.name | translate}}</span>
            </div>
          </ng-container>
        </ng-container>
      </ng-container>
      <ng-template #templateHasDatePicker>
        <div class="area-date-picker">
          <app-button-group [formControl]="controlTab" [options]="buttonGroupOptions" [style]="'dark'" [consistentWidths]="true"></app-button-group>
          <ng-container *ngIf="pickerVisible">
            <app-date-picker [formControl]="controlDatePicker" [inline]="true"></app-date-picker>
          </ng-container>
          <ng-container *ngIf="controlDatePicker.value">
            <div class="text-right">
              <app-button [small]="true" [type]="eButtonType.NAVY" (onClick)="resetDate()">Reset</app-button>
            </div>
          </ng-container>
        </div>
      </ng-template>
    </div>
  </div>
</ng-container>