import { Injectable } from '@angular/core';
import { NotifyUtils } from '@app/shared/utils/notify.utils';
import { Observable } from 'rxjs';
import { QuestionTemplateMessages } from '../locale/question-template.messages';
import { Page } from '@app/models/api/page.model';
import { PagingParams } from '@app/models/api/paging-params.model';
import { SortingParams } from '@app/models/api/sorting-params.model';
import { QuestionTemplateAPIService } from '../api/question-template-api.service';
import { QuestionTemplate } from '../model/question-template.model';
import { CreateQuestionTemplate } from '../model/create-question-template.model';
import { FrankliErrorResponse } from '@app/models/exception/frankli-error-response.model';
import { FrankliHttpErrorResponse } from '@app/models/exception/frankli-http-error-response.model';
import { QuestionTemplateUpdateResponse } from '../model/question-template-update-response.model';

@Injectable({
  providedIn: 'root'
})
export class QuestionTemplateBusinessService {

  updating: number[];
  uploadingFile: boolean;

  constructor(
    private questionTemplateAPI: QuestionTemplateAPIService,
    private notifyUtils: NotifyUtils
  ) {
    this.updating = [];
    this.uploadingFile = false;
  }

  private startUpdating(ids: number[]) {
    this.updating = [...this.updating, ...ids];
  }

  private stopUpdating(ids: number[]) {
    this.updating = this.updating.filter(x => !ids.includes(x));
  }

  get(
    ids: number[],
    name: string,
    creatorIds: number[]
  ): Observable<QuestionTemplate[]> {
    return this.questionTemplateAPI
      .get(ids, name, creatorIds);
  }

  getPaginated(
    pagingParams: PagingParams,
    sortingParams: SortingParams,
    ids: number[],
    name: string,
    creatorIds: number[]
  ): Observable<Page<QuestionTemplate>> {
    return this.questionTemplateAPI.getPaginated(pagingParams, sortingParams, ids, name, creatorIds);
  }

  create(createDto: CreateQuestionTemplate): Promise<QuestionTemplate> {
    return this.questionTemplateAPI.create(createDto).toPromise()
      .then(res => {
        this.notifyUtils.notify(QuestionTemplateMessages.SUCCESS_CREATE);
        return res;
      });
  }

  update(id: number, updateDto: CreateQuestionTemplate): Promise<QuestionTemplateUpdateResponse> {
    if (this.updating.includes(id)) { return new Promise((resolve, reject) => reject()); }
    this.startUpdating([id]);

    return this.questionTemplateAPI.update(id, updateDto).toPromise()
      .then(res => {
        this.notifyUtils.notify(QuestionTemplateMessages.SUCCESS_UPDATE);
        return res;
      })
      .finally(() => {
        this.stopUpdating([id]);
      });
  }

  updateApplicationsUsingTemplate(id: number): Promise<number> {
    if (this.updating.includes(id)) { return new Promise((resolve, reject) => reject()); }
    this.startUpdating([id]);

    return this.questionTemplateAPI.updateApplicationsUsingTemplate(id).toPromise()
      .then(res => {
        this.notifyUtils.notify(`${res} applications updated`);
        return res;
      })
      .finally(() => {
        this.stopUpdating([id]);
      });
  }

  delete(id: number): Promise<QuestionTemplate> {
    if (this.updating.includes(id)) { return new Promise((resolve, reject) => reject()); }
    this.startUpdating([id]);

    return this.questionTemplateAPI.delete(id).toPromise()
      .then(res => {
        this.notifyUtils.notify(QuestionTemplateMessages.SUCCESS_DELETE);
        return res;
      })
      .finally(() => {
        this.stopUpdating([id]);
      });
  }
}
