import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Goal } from 'app/models/goals/goal.model';
import { GoalsAPIService } from 'app/shared/api/goals.api.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { forkJoin, Subject, Subscription } from 'rxjs';
import { Globals } from 'app/shared/globals/globals';
import { RoleName } from '@app/models/user-role.model';
import { CompanyFeatures } from '@app/models/company-features.model';
import { GoalsModalComponent } from '../goals-modal/goals-modal.component';
import { Breadcrumb } from 'app/models/breadcrumb.model';
import { BreadcrumbService } from 'app/shared/breadcrumbs/breadcrumbs.service';
import { GoalOverviewPages } from '@app/models/goals/goal-overview-pages.model';
import { NavTabData } from '@app/shared/components/nav-tabs/nav-tabs.component';
import { GoalCreateFormMode } from '@app/models/goals/goal-create-form-mode.model';
import { ModalComponent } from '@app/shared/modal/modal.component';
import { GoalCreateService } from '../goals-create-old/goals-create.service';
import { GoalType } from '@app/models/goals/goal-type.enum';
import { GoalCreateComponent, GoalCreatePageViews } from '../goal-create/goal-create.component';
import Swal, { SweetAlertOptions, SweetAlertResult } from 'sweetalert2';
import { GoalsMessages } from '@app/goals/goals.messages';
import { TranslateService } from '@ngx-translate/core';
import { takeUntil } from 'rxjs/operators';
import { GoalStats } from '@app/models/goals/goal-stats';
import { GoalStatsScope } from '@app/models/goals/goal-stats-scope';
import { State } from '@app/shared/utils/state.model';
import { CompanyAPIService } from '@app/shared/api/company/company.api.service';
import { GoalDraft } from '@app/models/goals/goal-draft.model';
import { FormControl, Validators } from '@angular/forms';
import { DepartmentGoalOverview } from '@app/models/goals/department-goal-overview.model';
import { OfficeLocationGoalOverview } from '@app/models/goals/office-location-goal-overview.model';
import { User } from '@app/models/user/user.model';
import { GoalTableColumn } from '@app/goals/goals-components/goal-search-table/goal-table-column';
import { FilterCategoryBase } from '@app/shared/dynamic-filter/interfaces/filter-category-base';
import { SelectedFilter } from '@app/shared/dynamic-filter/interfaces/selected-filter';
import { GoalCategory } from '@app/shared/dynamic-filter/categories/goal-category';
import { CommonMessages } from '@app/constants/common.messages';
import { GoalUtils } from '@app/shared/utils/goal.utils';
import { GoalSearchTableComponent } from '@app/goals/goals-components/goal-search-table/goal-search-table.component';
import { IState } from '@app/models/state/state.model';
import { TerminologyEntity } from '@app/domain/terminology/model/terminology-entity.enum';

enum OverviewFilter {
  ACTIVE = 'ACTIVE',
  COMPLETE = 'COMPLETE',
  ARCHIVED = 'ARCHIVED',
  ALL = 'ALL',
}

interface PageState extends IState {
  tabChanging: boolean;
}

@Component({
  selector: 'app-goals-overview',
  templateUrl: './goals-overview.component.html',
  styleUrls: ['./goals-overview.component.css'],
})
export class GoalsOverviewComponent implements OnInit, OnDestroy {
  public readonly eGoalCreateFormMode = GoalCreateFormMode;
  public readonly eRoleName = RoleName;
  public readonly eCompanyFeatures = CompanyFeatures;
  public readonly eGoalOverviewPages = GoalOverviewPages;
  public readonly eOverviewFilter = OverviewFilter;
  public readonly eGoalsMessages = GoalsMessages;

  private readonly ngUnsubscribe$ = new Subject<void>();

  @ViewChild('modalCreateOld') modalCreateOld?: GoalsModalComponent;
  @ViewChild('modalCreate') modalCreate?: ModalComponent;
  initOldCreateModal = false;

  @ViewChild('goalCreateComponent') goalCreateComponent?: GoalCreateComponent;
  @ViewChild('goalSearchTable') goalSearchTable?: GoalSearchTableComponent;

  departmentOverViewList: DepartmentGoalOverview[];
  officeLocationOverViewList: OfficeLocationGoalOverview[];

  createType?: GoalType;
  modalCreateShown: boolean;
  modalCreateOldShown: boolean;

  officeLocationId: number | null;

  goals: Goal[];
  goalsActive: Goal[];
  goalsComplete: Goal[];
  goalsArchived: Goal[];
  goalsSaved: GoalDraft[];

  departmentId: number | null;

  managerFilter: FormControl;
  managerList: User[];

  show: boolean;

  createHidden = false;
  canCreateGoal = false;
  tabSelected: GoalOverviewPages = GoalOverviewPages.MY_GOALS;
  subscriptions: Subscription[] = [];
  breadcrumb: Breadcrumb;
  navTabData: NavTabData;
  createView: GoalCreatePageViews;
  goalStats: GoalStats = {
    numberOfActiveGoals: 0,
    numberOfGoalsCompletedInLast3Months: 0,
    averageCompletionPercentage: 0,
    numberOfGoalsDueInNext30Days: 0
  };
  componentState: State;
  statsState: State;
  disabledColumns: GoalTableColumn[];
  hiddenFilterCategories: FilterCategoryBase[];
  selectedFilters: SelectedFilter[];

  confirmCloseSettings: SweetAlertOptions;

  state: PageState;

  constructor(
    public globals: Globals,
    public route: ActivatedRoute,
    private router: Router,
    private goalCreateService: GoalCreateService,
    private goalsAPIService: GoalsAPIService,
    private breadcrumbService: BreadcrumbService,
    private companyAPIService: CompanyAPIService,
    private translateService: TranslateService
  ) {
    this.breadcrumb = this.breadcrumbService.init(this.route);

    this.statsState = new State(false);

    this.goals = [];
    this.goalsActive = [];
    this.goalsComplete = [];
    this.goalsArchived = [];
    this.goalsSaved = [];
    this.managerList = [];
    this.departmentOverViewList = [];
    this.officeLocationOverViewList = [];

    this.officeLocationId = null;
    this.departmentId = null;

    this.createType = undefined;
    this.navTabData = undefined!;

    this.modalCreateOldShown = false;
    this.modalCreateShown = false;
    this.show = false;
    this.disabledColumns = [];
    this.hiddenFilterCategories = [];
    this.selectedFilters = [];

    this.state = {
      tabChanging: false,
      loading: true,
      error: false,
      errorMessage: ''
    };

    this.createView = GoalCreatePageViews.MAIN;

    this.managerFilter = new FormControl(this.globals.user.managerId, [Validators.required]);

    this.confirmCloseSettings = {
      title: CommonMessages.UNSAVED_CHANGES,
      text: CommonMessages.UNSAVED_CHANGES_WARNING,
      confirmButtonText: CommonMessages.STAY,
      cancelButtonText: CommonMessages.LEAVE
    };

    this.updateTabData();

    this.router.events.pipe(takeUntil(this.ngUnsubscribe$)).subscribe(event => {
      if (event instanceof NavigationEnd) {
        if (this.route.children.length === 0) {
          // Refresh goals when routed back to the overview
          this.refreshGoalStats();
          setTimeout(() => {
            this.state.tabChanging = false;
          }, 1);
        }
      }
    });
  }

  // #region - LIFECYCLE HOOKS
  ngOnInit() {
    this.initSubscriptions();
    this.setupGoalSearch();
  }

  ngOnDestroy() {
    this.breadcrumbService.remove(this.breadcrumb);
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.unsubscribe();
  }

  initSubscriptions(): void {
    this.route.queryParamMap
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(queryParams => {
        const action = queryParams.get('action');
        const tab = queryParams.get('tab');
        this.doTabQueryParam(tab);
        this.doActionQueryParam(action);
      });

    this.goalCreateService.getGoalSaved()
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(() => {
        this.tabSelected = GoalOverviewPages.DRAFTS;
      });

    this.goalCreateService.getRefreshGoals()
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(() => {
        if (this.goalSearchTable) {
          this.goalSearchTable.searchGoals();
        }
      });

    this.translateService.onLangChange
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(() => {
        this.updateTabData();
      });
  }

  updateTabData(): void {
    forkJoin([
      this.translateService.get(GoalsMessages.TEAM_GOAL_PLURAL, { teamWording: this.globals.getTerminology(TerminologyEntity.TEAM) }),
      this.translateService.get(GoalsMessages.TEAM_GOALS_TOOLTIP, { teamWording: this.globals.getTerminology(TerminologyEntity.TEAM) }),
      this.translateService.get(GoalsMessages.DEPARTMENT_GOALS, { departmentWording: this.globals.getTerminology(TerminologyEntity.DEPARTMENT) }),
      this.translateService.get(GoalsMessages.DEPARTMENT_GOALS_TOOLTIP, { departmentWording: this.globals.getTerminology(TerminologyEntity.DEPARTMENT) }),
      this.translateService.get(GoalsMessages.SITE_GOAL_PLURAL, { site: this.globals.getTerminology(TerminologyEntity.SITE) }),
      this.translateService.get(GoalsMessages.SITE_GOALS_TOOLTIP, { site: this.globals.getTerminology(TerminologyEntity.SITE) }),
    ])
      .toPromise()
      .then(
        ([teamGoals, teamGoalsTooltip, departmentGoals, departmentGoalsTooltip, siteGoals, siteGoalsTooltip]: string[]) => {
          this.navTabData = this.getNavTabData(teamGoals, teamGoalsTooltip, departmentGoals, departmentGoalsTooltip, siteGoals, siteGoalsTooltip);
        }
      );
  }

  doTabQueryParam(tab: string | null): void {
    if (tab === null) {
      this.tabSelected = GoalOverviewPages.MY_GOALS;
      return;
    }

    let page = Number(tab) as GoalOverviewPages;

    // Prevent navigation to pages that are locked by role/feature
    switch (page) {
      case GoalOverviewPages.ADMIN_GOAL:
        if (!this.globals.hasRole(RoleName.GOAL_COMPANY)) {
          page = GoalOverviewPages.MY_GOALS;
        }
        break;
      case GoalOverviewPages.OFFICE_LOCATION:
        if (!this.globals.hasFeature(CompanyFeatures.GOAL_OFFICE_LOCATION)) {
          page = GoalOverviewPages.MY_GOALS;
        }
        break;
    }

    this.tabSelected = page;

    this.setupGoalSearch();
  }

  setupGoalSearch() {
    this.disabledColumns = this.initializeDisabledColumns();
    this.hiddenFilterCategories = this.initializeHiddenFilterCategories();
    this.selectedFilters = this.initializeSelectedFilters();
    this.canCreateGoal = this.checkCanCreateGoal();
    this.state.loading = false;
  }

  checkCanCreateGoal(): boolean {
    return !(this.tabSelected === GoalOverviewPages.COMPANY && !this.globals.hasRole(RoleName.GOAL_COMPANY));
  }

  doActionQueryParam(action: string | null): void {
    switch (action) {
      case 'create':
        this.openGoalCreate();
        break;
      case 'create-template':
        this.openGoalCreateTemplate();
        break;
      case 'create-copy':
        this.openGoalCreateCopying();
        break;
      default:
        break;
    }
  }
  
  getNavTabData(teamGoals: string, teamGoalsTooltip: string, departmentGoals: string, departmentGoalsTooltip: string, siteGoals: string, siteGoalsTooltip: string): NavTabData {
    return {
      label: GoalsMessages.GOAL_TYPE,
      iconsEnabled: false,
      items: [
        {
          identifier: GoalOverviewPages.MY_GOALS,
          title: GoalsMessages.MY_GOALS,
          condition: () => true,
          amplitude: {
            action: 'ClickNavMyGoals',
            category: 'Goals'
          },
          intercomTarget: 'View My Goals'
        },
        {
          identifier: GoalOverviewPages.PERSONAL,
          title: GoalsMessages.PERSONAL_GOALS,
          condition: () => true,
          amplitude: {
            action: 'ClickViewPersonalGoals',
            category: 'Goals'
          },
          intercomTarget: 'View Personal Goals'
        },
        {
          identifier: GoalOverviewPages.TEAM,
          title: teamGoals,
          condition: () => true,
          amplitude: {
            action: 'ClickViewTeamGoals',
            category: 'Goals'
          },
          intercomTarget: 'View Team Goals'
        },
        {
          identifier: GoalOverviewPages.DEPARTMENT,
          title: departmentGoals,
          condition: () => true,
          amplitude: {
            action: 'ClickViewDepartmentGoals',
            category: 'Goals'
          },
          intercomTarget: 'View Department Goals'
        },
        {
          identifier: GoalOverviewPages.OFFICE_LOCATION,
          title:  siteGoals,
          condition: () => this.globals.hasFeature(CompanyFeatures.GOAL_OFFICE_LOCATION),
          amplitude: {
            action: 'ClickViewSiteGoals',
            category: 'Goals'
          },
          intercomTarget: 'View Site Goals'
        },
        {
          identifier: GoalOverviewPages.COMPANY,
          title: GoalsMessages.COMPANY_GOALS,
          condition: () => true,
          amplitude: {
            action: 'ClickViewCompanyGoals',
            category: 'Goals'
          },
          intercomTarget: 'View Company Goals'
        },
        {
          identifier: GoalOverviewPages.ADMIN_GOAL,
          title: GoalsMessages.GOAL_ADMIN,
          condition: () => this.globals.hasRole(RoleName.GOAL_COMPANY),
          amplitude: {
            action: 'ClickViewGoalAdmin',
            category: 'Goals'
          },
          intercomTarget: 'View Goal Admin'
        },
        {
          identifier: GoalOverviewPages.DRAFTS,
          title: GoalsMessages.DRAFT_GOALS,
          condition: () => true,
          amplitude: {
            action: 'ClickViewDraftGoals',
            category: 'Goals'
          },
          intercomTarget: 'View Draft Goals'
        },
        {
          identifier: GoalOverviewPages.TAGS,
          title: GoalsMessages.GOAL_TAGS,
          condition: () => true,
          amplitude: {
            action: 'ClickViewGoalTags',
            category: 'Goals'
          },
          intercomTarget: 'View Goal Tags'
        }
      ]
    };
  }

  onTabChanged(tab: GoalOverviewPages) {
    this.state.tabChanging = true;
    this.createType = undefined;
    setTimeout(() => {
      this.router.navigate([
        'goals'
      ], {
        queryParams: {
          tab: tab
        }
      });
    }, 1);
  }

  refreshGoalStats() {
    this.statsState.setLoading();
    const scope = this.getGoalStatsScopeForCurrentOverviewTab();
    this.goalsAPIService.getGoalStatsForScope(scope).subscribe(goalStats => {
      this.goalStats = goalStats;
      this.statsState.setSuccess();
    });
  }

  getGoalStatsScopeForCurrentOverviewTab(): GoalStatsScope {
    switch (this.tabSelected) {
      case GoalOverviewPages.MY_GOALS:
        return GoalStatsScope.OWNED;
      case GoalOverviewPages.PERSONAL:
        return GoalStatsScope.PERSONAL;
      case GoalOverviewPages.TEAM:
        return GoalStatsScope.TEAM;
      case GoalOverviewPages.DEPARTMENT:
        return GoalStatsScope.DEPARTMENT;
      case GoalOverviewPages.OFFICE_LOCATION:
        return GoalStatsScope.OFFICE_LOCATION;
      case GoalOverviewPages.COMPANY:
        return GoalStatsScope.COMPANY;
      case GoalOverviewPages.ADMIN_GOAL:
      default:
        return GoalStatsScope.ALL;
    }
  }

  openGoalCreateOld() {
    this.modalCreateOldShown = false;

    switch (this.tabSelected) {
      case GoalOverviewPages.COMPANY:
        this.createType = GoalType.COMPANY;
        break;
      case GoalOverviewPages.DEPARTMENT:
        this.createType = GoalType.DEPARTMENT;
        break;
      case GoalOverviewPages.OFFICE_LOCATION:
        this.createType = GoalType.OFFICE_LOCATION;
        break;
      default:
        this.createType = undefined;
        break;
    }

    this.modalCreateOldShown = true;

    setTimeout(() => {
      if (this.modalCreateOld) {
        this.modalCreateOld.show();
      }
    }, 100);
  }

  openGoalCreate() {
    this.createView = GoalCreatePageViews.MAIN;
    this.modalCreateShown = false;

    switch (this.tabSelected) {
      case GoalOverviewPages.COMPANY:
        this.createType = GoalType.COMPANY;
        break;
      case GoalOverviewPages.DEPARTMENT:
        this.createType = GoalType.DEPARTMENT;
        break;
      case GoalOverviewPages.OFFICE_LOCATION:
        this.createType = GoalType.OFFICE_LOCATION;
        break;
      default:
        this.createType = undefined;
        break;
    }

    this.modalCreateShown = true;

    setTimeout(() => {
      this.showCreateModal();
    }, 100);
  }

  openGoalCreateTemplate(): void {
    this.createView = GoalCreatePageViews.TEMPLATES;
    this.modalCreateShown = true;

    setTimeout(() => {
      this.showCreateModal();
    }, 100);
  }

  openGoalCreateCopying(): void {
    this.createView = GoalCreatePageViews.COPYING;
    this.modalCreateShown = true;

    setTimeout(() => {
      this.showCreateModal();
    }, 100);
  }

  showCreateModal(): void {
    this.createHidden = false;
    setTimeout(() => {
      if (this.modalCreate) {
        this.modalCreate.show();
      }
    }, 1);
  }

  hideCreateModal(): void {
    if (this.modalCreate) {
      this.modalCreate?.hide();
    }

    setTimeout(() => {
      this.createHidden = true;
      
      setTimeout(() => {
        this.createHidden = false;
      }, 1);
    }, 1);
  }

  onCloseCreateResponse(result: SweetAlertResult): void {
    if (result.isDismissed && result.dismiss && (result.dismiss === Swal.DismissReason.cancel)) {
      this.hideCreateModal();
    }
  }

  private initializeDisabledColumns(): GoalTableColumn[] {
    switch (this.tabSelected) {
      case GoalOverviewPages.DEPARTMENT:
        return [GoalTableColumn.SITE];
      case GoalOverviewPages.OFFICE_LOCATION:
        return [GoalTableColumn.DEPARTMENT];
      default:
        return [GoalTableColumn.DEPARTMENT, GoalTableColumn.SITE];
    }
  }

  private initializeHiddenFilterCategories() {
    const hiddenCategories: FilterCategoryBase[] = [];
    if (![GoalOverviewPages.MY_GOALS, GoalOverviewPages.ADMIN_GOAL, GoalOverviewPages.DRAFTS].includes(this.tabSelected)) {
      hiddenCategories.push(GoalCategory.typeCategoryBase());
    }

    if (![GoalOverviewPages.ADMIN_GOAL, GoalOverviewPages.DRAFTS, GoalOverviewPages.DEPARTMENT].includes(this.tabSelected)) {
      hiddenCategories.push(GoalCategory.departmentCategoryBase());
    }

    if (![GoalOverviewPages.ADMIN_GOAL, GoalOverviewPages.DRAFTS, GoalOverviewPages.OFFICE_LOCATION].includes(this.tabSelected)) {
      hiddenCategories.push(GoalCategory.officeLocationCategoryBase());
    }

    if ([GoalOverviewPages.MY_GOALS, GoalOverviewPages.PERSONAL].includes(this.tabSelected)) {
      hiddenCategories.push(GoalCategory.ownerCategoryBase());
    }

    if (this.tabSelected !== GoalOverviewPages.ADMIN_GOAL) {
      hiddenCategories.push(GoalCategory.visibilityCategoryBase());
    }

    if (!this.globals.hasFeature(CompanyFeatures.GOAL_PRIORITY)) {
      hiddenCategories.push(GoalCategory.priorityCategoryBase());
    }
    return hiddenCategories;
  }

  private initializeSelectedFilters() {
    const selectedFilters: SelectedFilter[] = [
      {
        category: GoalCategory.completeCategory(),
        option: { displayName: CommonMessages.NO, value: 'false' }
      },
      {
        category: GoalCategory.archivedCategory(),
        option: { displayName: CommonMessages.NO, value: 'false' }
      }
    ];

    switch (this.tabSelected) {
      case GoalOverviewPages.MY_GOALS:
        selectedFilters.push({
          category: GoalCategory.ownerCategoryBase(),
          permanent: true,
          option: {
            displayName: `${this.globals.user.firstName} ${this.globals.user.lastName}`,
            value: this.globals.user.id.toString()
          }
        });
        break;
      case GoalOverviewPages.PERSONAL:
        selectedFilters.push({
          category: GoalCategory.typeCategoryBase(),
          permanent: true,
          option: {
            displayName: GoalUtils.getMessageCodeForGoalType(GoalType.PERSONAL_OPERATIONAL, this.globals.terminology),
            value: GoalType.PERSONAL_OPERATIONAL
          }
        });
        selectedFilters.push({
          category: GoalCategory.typeCategoryBase(),
          permanent: true,
          option: {
            displayName: GoalUtils.getMessageCodeForGoalType(GoalType.PERSONAL_DEVELOPMENTAL, this.globals.terminology),
            value: GoalType.PERSONAL_DEVELOPMENTAL
          }
        });
        selectedFilters.push({
          category: GoalCategory.ownerCategoryBase(),
          option: {
            displayName: `${this.globals.user.firstName} ${this.globals.user.lastName}`,
            value: this.globals.user.id.toString()
          }
        });
        break;
      case GoalOverviewPages.DEPARTMENT:
        selectedFilters.push({
          category: GoalCategory.typeCategoryBase(),
          permanent: true,
          option: {
            displayName: GoalUtils.getMessageCodeForGoalType(GoalType.DEPARTMENT, this.globals.terminology),
            value: GoalType.DEPARTMENT
          }
        });
        if (this.globals.user.organisationalUnit) {
          selectedFilters.push({
            category: GoalCategory.departmentCategoryBase(),
            option: {
              displayName: this.globals.user.organisationalUnit.name,
              value: this.globals.user.organisationalUnit.id.toString()
            }
          });
        }
        break;
      case GoalOverviewPages.OFFICE_LOCATION:
        selectedFilters.push({
          category: GoalCategory.typeCategoryBase(),
          permanent: true,
          option: {
            displayName: GoalUtils.getMessageCodeForGoalType(GoalType.OFFICE_LOCATION, this.globals.terminology),
            value: GoalType.OFFICE_LOCATION
          }
        });
        if (this.globals.user.officeLocation && this.globals.user.officeLocation.id) {
          selectedFilters.push({
            category: GoalCategory.officeLocationCategoryBase(),
            option: {
              displayName: this.globals.user.officeLocation.name,
              value: this.globals.user.officeLocation.id.toString()
            }
          });
        }
        break;
      case GoalOverviewPages.COMPANY:
        selectedFilters.push({
          category: GoalCategory.typeCategoryBase(),
          permanent: true,
          option: {
            displayName: GoalUtils.getMessageCodeForGoalType(GoalType.COMPANY, this.globals.terminology),
            value: GoalType.COMPANY
          }
        });
        break;
      case GoalOverviewPages.TEAM:
        selectedFilters.push({
          category: GoalCategory.typeCategoryBase(),
          permanent: true,
          option: {
            displayName: GoalUtils.getMessageCodeForGoalType(GoalType.TEAM, this.globals.terminology),
            value: GoalType.TEAM
          }
        });
        selectedFilters.push({
          category: GoalCategory.ownerCategoryBase(),
          option: {
            displayName: `${this.globals.user.firstName} ${this.globals.user.lastName}`,
            value: this.globals.user.id.toString()
          }
        });
        break;
    }
    return selectedFilters;
  }
}
