import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BreadcrumbService } from 'app/shared/breadcrumbs/breadcrumbs.service';
import { Breadcrumb } from 'app/models/breadcrumb.model';
import { forkJoin, Observable, of } from 'rxjs';
import { GoalsAPIService } from '@app/shared/api/goals.api.service';
import { Globals } from '@app/shared/globals/globals';
import { CompanyFeatures } from '@app/models/company-features.model';
import { ColumnToggleComponent } from '@app/shared/column-toggle/column-toggle.component';
import { PaginationNewComponent } from '@app/shared/pagination/pagination-new/pagination-new.component';
import { GoalDirectReportOverview } from '@app/models/goals/goal-direct-report-overview.model';
import { FormControl } from '@angular/forms';
import { RoleName } from '@app/models/user-role.model';
import { PillType } from '@app/shared/components/pill/pill.component';
import { GoalOverviewManagerPageUser } from '@app/goals/goals-components/goal-overview-manager/goal-overview-manager-page-user';
import { GoalOverviewManagerTableColumn } from '@app/goals/goals-components/goal-overview-manager/goal-overview-manager-table-column';
import { GoalType } from '@app/models/goals/goal-type.enum';
import { UserGoalsModalComponent } from '@app/goals/goals-components/user-goals-modal/user-goals-modal.component';
import { UserMinimal } from '@app/models/user/user-minimal.model';
import { FilterCategory } from '@app/shared/dynamic-filter/interfaces/filter-category';
import { ParentFilter } from '@app/models/api/parent-filter.model';
import { State } from '@app/shared/utils/state.model';
import { SearchMode } from '@app/shared/dynamic-filter/types/search-mode';
import { FilterType } from '@app/shared/dynamic-filter/types/filter-type';
import { GoalStats } from '@app/models/goals/goal-stats';
import { GoalStatsScope } from '@app/models/goals/goal-stats-scope';
import { PagingParams } from '@app/models/api/paging-params.model';
import { Page } from '@app/models/api/page.model';
import { GoalsMessages } from '@app/goals/goals.messages';
import { CommonMessages } from '@app/constants/common.messages';
import { ColumnInfo } from '@app/shared/column-toggle-new/column.info.model';
import { ModalComponent } from '@app/shared/modal/modal.component';
import { SweetAlertResult } from 'sweetalert2';
import { TerminologyEntity } from '@app/domain/terminology/model/terminology-entity.enum';

interface TeamGoalsRequest {
  teamGoalsOverview: Observable<GoalDirectReportOverview[]>;
  secondaryGoalsOverview: Observable<GoalDirectReportOverview[]>;
}

interface TeamGoalsResponse {
  teamGoalsOverview: GoalDirectReportOverview[];
  secondaryGoalsOverview: GoalDirectReportOverview[];
}

@Component({
  selector: 'app-goal-overview-manager',
  templateUrl: './goal-overview-manager.component.html',
  styleUrls: ['./goal-overview-manager.component.scss']
})
export class GoalOverviewManagerComponent implements OnInit, OnDestroy {
  public readonly eTableColumn = GoalOverviewManagerTableColumn;
  public readonly eCommonMessages = CommonMessages;
  public readonly eGoalsMessages = GoalsMessages;
  public readonly eGoalType = GoalType;
  public readonly eRoleName = RoleName;

  @ViewChild('columnToggle') columnToggle?: ColumnToggleComponent;
  @ViewChild('pagination') pagination?: PaginationNewComponent;
  @ViewChild('userGoalsModal') userGoalsModal?: UserGoalsModalComponent;
  @ViewChild('userGoalCreateModal') userGoalCreateModal?: ModalComponent;

  state: State = new State(false);
  statsState: State = new State(false);
  searchState: State = new State(false);
  pillType = PillType.GREEN;
  filterCategories: FilterCategory[] = [];
  searchProperties: string[] = ['name'];
  columnTitles: string[] = Object.keys(GoalOverviewManagerTableColumn).map(c => GoalOverviewManagerTableColumn[c]);
  directReports: GoalOverviewManagerPageUser[] = [];
  viewAsAdmin: FormControl = new FormControl(false, []);
  viewingUser?: UserMinimal = undefined;
  creatingUser?: UserMinimal = undefined;
  creatingGoal?: boolean = false;
  goalStats: GoalStats = {
    averageCompletionPercentage: 0,
    numberOfActiveGoals: 0,
    numberOfGoalsCompletedInLast3Months: 0,
    numberOfGoalsDueInNext30Days: 0
  };
  pagingParams: PagingParams = {
    pageSize: 15,
    pageNumber: 0
  };

  columnInfos: ColumnInfo[] = [
    {
      index: GoalOverviewManagerTableColumn.NAME,
      titleMessageCode: CommonMessages.NAME
    },
    {
      index: GoalOverviewManagerTableColumn.GOAL_COUNT,
      titleMessageCode: GoalsMessages.GOAL_COUNT
    },
    {
      index: GoalOverviewManagerTableColumn.ACTIVE_GOAL_COUNT,
      titleMessageCode: GoalsMessages.ACTIVE_GOAL_COUNT
    },
    {
      index: GoalOverviewManagerTableColumn.COMPLETE_GOAL_COUNT,
      titleMessageCode: GoalsMessages.COMPLETE_GOAL_COUNT
    },
    {
      index: GoalOverviewManagerTableColumn.AVERAGE_COMPLETION,
      titleMessageCode: GoalsMessages.AVERAGE_COMPLETION
    },
    {
      index: GoalOverviewManagerTableColumn.ACTIONS,
      titleMessageCode: CommonMessages.ACTIONS
    }
  ];

  goalsOverviewPage?: Page<GoalDirectReportOverview>;

  breadcrumb: Breadcrumb;
  parentFilter: ParentFilter;

  constructor(
    public route: ActivatedRoute,
    public globals: Globals,
    private breadcrumbService: BreadcrumbService,
    private goalsAPIService: GoalsAPIService
  ) {
    this.breadcrumb = this.breadcrumbService.init(this.route);
    this.viewAsAdmin.valueChanges.subscribe(() => {
      this.initialiseStats();
      this.initialiseTree();
    });
  }

  ngOnInit() {
    this.populateUniversalFilter();
    this.initialiseStats();
    this.initialiseTree();
  }

  ngOnDestroy() {
    this.breadcrumbService.remove(this.breadcrumb);
  }

  initialiseStats() {
    this.statsState.setLoading();
    const statsScope = this.viewAsAdmin.value ? GoalStatsScope.ALL : GoalStatsScope.REPORTING_LINE;
    this.goalsAPIService.getGoalStatsForScope(statsScope).subscribe(stats => {
      this.goalStats = stats;
      this.statsState.setSuccess();
    });
  }

  initialiseTree() {
    this.searchState.setLoading();

    const request: TeamGoalsRequest = {
      teamGoalsOverview: this.viewAsAdmin.value ?
        this.goalsAPIService.getTeamGoalsOverviewAdmin() :
        this.goalsAPIService.getGoalDirectReportOverview(this.globals.user.id, 0),
      secondaryGoalsOverview: this.userIsSecondaryManager() ?
        this.goalsAPIService.getSecondarySubordinatesGoalsOverview() :
        of(null)
    };

    forkJoin(request).subscribe((response: TeamGoalsResponse) => {
      let subordinates = new Array<GoalOverviewManagerPageUser>();
      let secondarySubordinates = new Array<GoalOverviewManagerPageUser>();
      if (this.globals.hasMinimumAccessLevelOf(RoleName.ADMIN) || this.globals.hasMinimumAccessLevelOf(RoleName.MANAGER)) {
        subordinates = this.parseSubordinates(response.teamGoalsOverview);
      }

      if (response.secondaryGoalsOverview) {
        secondarySubordinates = this.parseSecondarySubordinates(response.secondaryGoalsOverview);
      }

      this.directReports = subordinates.concat(secondarySubordinates);

      this.searchState.setSuccess();
    });
  }

  parseSubordinates(teamGoalsOverview : GoalDirectReportOverview[]){
    const subordinates = teamGoalsOverview.map(u => this.parseRootUser(u));
    return this.sortByName(subordinates);
  }

  parseSecondarySubordinates(secondaryGoalsOverview: GoalDirectReportOverview[]){
    const secondaryUsers = secondaryGoalsOverview.map(x => this.parseSecondarySubordinate(x));
    return this.sortByName(secondaryUsers);
  }

  sortByName(array: GoalOverviewManagerPageUser[]){
    return array.sort((a, b) => {
      const nameA = `${a.user.firstName} ${a.user.lastName}`;
      const nameB = `${b.user.firstName} ${b.user.lastName}`;
      const secondaryMatch = (a === b)? 0 : a? -1 : 1;
      return secondaryMatch || ((nameA > nameB) ? 1 : -1);
    });
  }

  parseSecondarySubordinate(goalDirectReportOverview: GoalDirectReportOverview): GoalOverviewManagerPageUser {
    const pageUser = goalDirectReportOverview as GoalOverviewManagerPageUser;

    pageUser.dropdownOpen = false;
    pageUser.secondary = true;
    pageUser.directReports = [];

    return pageUser;
  }

  parseRootUser(goalDirectReportOverview: GoalDirectReportOverview): GoalOverviewManagerPageUser {
    const rootUser = goalDirectReportOverview as GoalOverviewManagerPageUser;

    rootUser.dropdownOpen = false;
    rootUser.secondary = false;
    rootUser.directReports = [];

    return rootUser;
  }

  convertReportArrayToPageUser(teamGoalsOverview: GoalDirectReportOverview[]): GoalOverviewManagerPageUser[] {
    return teamGoalsOverview.map(tg => this.convertReportToPageUser(tg));
  }

  convertReportToPageUser(user: GoalDirectReportOverview): GoalOverviewManagerPageUser {
    const report = user as GoalOverviewManagerPageUser;

    if (report) {
      report.dropdownOpen = false;
      report.directReports = [];
    }

    return report;
  }

  openUserGoals(pageUser: GoalOverviewManagerPageUser) {
    this.viewingUser = pageUser.user;
    setTimeout(() => {
      this.userGoalsModal.show();
    }, 1);
  }

  // called when the goal create modal is shown
  openUserGoalCreate(pageUser: GoalOverviewManagerPageUser) {
    this.creatingUser = pageUser.user;
    this.creatingGoal = true;
    setTimeout(() => {
      this.userGoalCreateModal.show();
    }, 1);
  }

  userIsManager(){
    return this.globals.hasRole(RoleName.MANAGER);
  }

  userIsSecondaryManager(){
    return this.globals.hasFeature(CompanyFeatures.SECONDARY_MANAGER) && this.globals.hasRole(RoleName.SECONDARY_MANAGER);
  }
  
  toggleViewAsAdmin() { // Doing this to fix chrome checkbox bug
    const currentValue = this.viewAsAdmin.value as boolean;
    this.viewAsAdmin.setValue(!currentValue);
  }

  loadChildren(pageUser: GoalOverviewManagerPageUser) {
    if (pageUser.hasDirectReports) {
      pageUser.dropdownOpen = !pageUser.dropdownOpen;
      if (pageUser.directReports.length === 0) {
        pageUser.childrenLoading = true;
        this.goalsAPIService.getGoalDirectReportOverview(pageUser.user.id, 1).subscribe(directReportOverviews => {
          pageUser.directReports = this.parseSubordinates(directReportOverviews);
          pageUser.childrenLoading = false;
        });
      }
    }
  }

  searchReports() {
    this.searchState.setLoading();
    const subscription = this.viewAsAdmin.value ?
      this.goalsAPIService.searchGoalDirectReportOverviewsAsAdmin(this.pagingParams, this.parentFilter) :
      this.goalsAPIService.searchGoalDirectReportOverviews(this.pagingParams, this.parentFilter);

    subscription.subscribe(reportsPage => {
      this.goalsOverviewPage = reportsPage;
      this.directReports = this.parseSubordinates(this.goalsOverviewPage.content);
      this.searchState.setSuccess();
    });
  }

  updateFilter(parentFilter: ParentFilter) {
    this.parentFilter = parentFilter;
    if (this.parentFilter.childFilters.length > 0) {
      this.searchReports();
    } else {
      this.initialiseTree();
    }
  }

  changePageSize($event: number) {
    this.pagingParams.pageSize = $event;
    this.searchReports();
  }

  changePageNumber($event: number) {
    this.pagingParams.pageNumber = $event;
    this.searchReports();
  }

  populateUniversalFilter() {
    const departmentCategory: FilterCategory = {
      displayName: this.globals.getTerminology(TerminologyEntity.DEPARTMENT),
      fieldName: 'organisationalUnitId',
      searchMode: SearchMode.DEPARTMENT,
      type: FilterType.COMBINED,
      options: []
    };

    const siteCategory: FilterCategory = {
      displayName: this.globals.getTerminology(TerminologyEntity.SITE),
      fieldName: 'officeLocationId',
      searchMode: SearchMode.OFFICE_LOCATION,
      type: FilterType.COMBINED,
      options: []
    };

    const positionCategory: FilterCategory = {
      displayName: CommonMessages.POSITION,
      fieldName: 'positionId',
      searchMode: SearchMode.POSITION,
      type: FilterType.COMBINED,
      options: []
    };

    const activeGoalsCategory: FilterCategory = {
      displayName: GoalsMessages.ACTIVE_GOALS,
      fieldName: 'activeCount',
      searchMode: SearchMode.NONE,
      type: FilterType.RANGE,
      options: [
        {
          displayName: CommonMessages.NONE,
          range: {
            max: '0'
          }
        },
        {
          displayName: '1 - 5',
          range: {
            min: '1',
            max: '5'
          }
        },
        {
          displayName: '6 - 10',
          range: {
            min: '6',
            max: '10'
          }
        },
        {
          displayName: '11 - 20',
          range: {
            min: '11',
            max: '20'
          }
        },
        {
          displayName: '21 - 30',
          range: {
            min: '21',
            max: '30'
          }
        },
        {
          displayName: '31+',
          range: {
            min: '31',
          }
        }
      ]
    };

    const completeGoalsCategory: FilterCategory = {
      displayName: GoalsMessages.COMPLETE_GOALS,
      fieldName: 'completeCount',
      searchMode: SearchMode.NONE,
      type: FilterType.RANGE,
      options: [
        {
          displayName: CommonMessages.NONE,
          range: {
            max: '0'
          }
        },
        {
          displayName: '1 - 5',
          range: {
            min: '1',
            max: '5'
          }
        },
        {
          displayName: '6 - 10',
          range: {
            min: '6',
            max: '10'
          }
        },
        {
          displayName: '11 - 20',
          range: {
            min: '11',
            max: '20'
          }
        },
        {
          displayName: '21 - 30',
          range: {
            min: '21',
            max: '30'
          }
        },
        {
          displayName: '31+',
          range: {
            min: '31',
          }
        }
      ]
    };

    const averageCompletionCategory: FilterCategory = {
      displayName: GoalsMessages.AVERAGE_COMPLETION,
      fieldName: 'averageCompletionPercentage',
      searchMode: SearchMode.NONE,
      type: FilterType.RANGE,
      options: [
        {
          displayName: '0%',
          range: {
            max: '0'
          }
        },
        {
          displayName: '1 - 20%',
          range: {
            min: '1',
            max: '20'
          }
        },
        {
          displayName: '21 - 40%',
          range: {
            min: '21',
            max: '40'
          }
        },
        {
          displayName: '41 - 60%',
          range: {
            min: '41',
            max: '60'
          }
        },
        {
          displayName: '61 - 80%',
          range: {
            min: '61',
            max: '80'
          }
        },
        {
          displayName: '81 - 100%',
          range: {
            min: '81',
            max: '100'
          }
        }
      ]
    };

    this.filterCategories = [departmentCategory,siteCategory, positionCategory, activeGoalsCategory, completeGoalsCategory, averageCompletionCategory];
  }

  // called when the view goals modal is hidden
  onGoalsModalHidden(): void {
    this.viewingUser = undefined;
  }

  // called when the close button at the top right of the goal create modal is clicked
  hideCreateModal(): void {
    if (this.userGoalCreateModal) {
      this.userGoalCreateModal.hide();
    }

    setTimeout(() => {
      this.creatingGoal = false;
      
      setTimeout(() => {
        this.creatingGoal = true;
      }, 1);
    }, 1);
  }

  onCloseCreateResponse(result: SweetAlertResult): void {
    if(result.isConfirmed){
      this.hideCreateModal();
    }
  }
}
