import { Component, EventEmitter, Input, OnInit, Output, TrackByFunction } from '@angular/core';
import { Goal } from 'app/models/goals/goal.model';
import { GoalsAPIService } from 'app/shared/api/goals.api.service';
import { GoalCommentDto } from 'app/models/goals/goal.dto';
import { Globals } from 'app/shared/globals/globals';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { GoalActivity } from '@app/models/goals/goal-activity.model';
import { GoalActivityType } from '@app/models/goals/goal-activity-type.model';
import { GoalKeyResultType } from '@app/models/goals/goal-key-result-type.model';
import { GoalKeyResultMeasureUnitPlacement } from '@app/models/goals/goal-key-result-measure-unit-placement.model';
import { SwalUtils } from '@app/shared/utils/swal.utils';
import { NotifyUtils } from '@app/shared/utils/notify.utils';
import { GoalsMessages } from '@app/goals/goals.messages';
import { CommonMessages } from '@app/constants/common.messages';
import { IconHoverColor } from '@app/shared/components/inputs/table-action-icon/table-action-icon.component';
import { TerminologyEntity } from '@app/domain/terminology/model/terminology-entity.enum';

interface State {
  loading: boolean;
  submitted: boolean;
  submitting: boolean;
}
@Component({
  'selector': 'app-goals-individual-activity-component',
  'templateUrl': './goals-individual-activity.component.html',
  'styleUrls': ['./goals-individual-activity.component.scss']
})
export class GoalsIndividualActivityComponent implements OnInit {

  public readonly eGoalKeyResultMeasureUnitPlacement = GoalKeyResultMeasureUnitPlacement;
  public readonly eGoalKeyResultType = GoalKeyResultType;
  public readonly eTerminologyEntity = TerminologyEntity;
  public readonly eGoalActivityType = GoalActivityType;
  public readonly eIconHoverColor = IconHoverColor;
  public readonly eCommonMessages = CommonMessages;
  public readonly eGoalsMessages = GoalsMessages;

  public readonly commentMaxLength = 255;

  @Input() goal !: Goal;
  @Output() refresh: EventEmitter<boolean> = new EventEmitter();

  form: FormGroup;

  activity: GoalActivity[];

  state: State;

  commentBeingDeletedId?: number;

  get controlComment(): FormControl {
    return this.form.controls.comment as FormControl;
  }

  constructor(
    public globals: Globals,
    private goalsAPIService: GoalsAPIService,
    private formBuilder: FormBuilder,
    private notifyUtils: NotifyUtils,
    private swalUtils: SwalUtils
  ) {
    this.state = {
      loading: true,
      submitted: false,
      submitting: false
    };

    this.form = this.initForm();

    this.commentBeingDeletedId = undefined;

    this.activity = [];
  }

  ngOnInit() {
    this.activity = this.goal.activity.sort((a, b) => {
      const dateA = new Date(a.date);
      const dateB = new Date(b.date);
      return dateB.getTime() - dateA.getTime();
    });

    this.state.loading = false;
  }

  initForm(): FormGroup {
    const formGroup = this.formBuilder.group({
      comment: new FormControl('', [Validators.required, Validators.maxLength(this.commentMaxLength)])
    });

    return formGroup;
  }

  addComment() {
    this.state.submitted = true;

    if (this.state.submitting) { return; }

    if (this.form.invalid) { return; }

    this.state.submitting = true;

    const goalCommentDto = this.parseFormToGoalCommentDto(this.form);

    this.goalsAPIService.addComment(this.goal.id, goalCommentDto).subscribe((goal: Goal) => {
      this.goal.activity = goal.activity.sort((a, b) => {
        const dateA = new Date(a.date);
        const dateB = new Date(b.date);
        return dateB.getTime() - dateA.getTime();
      });
      this.notifyUtils.notify(GoalsMessages.SUCCESS_COMMENT_ADD);
      this.state.submitting = false;
      this.state.submitted = false;
      this.form = this.initForm();
    }, () => {
      this.state.submitting = false;
    });
  }

  parseFormToGoalCommentDto(formGroup: FormGroup): GoalCommentDto {
    const goalCommentDto: GoalCommentDto = {
      id: null,
      comment: formGroup.controls.comment.value
    };

    return goalCommentDto;
  }

  deleteComment(id: number) {
    this.swalUtils.displayWarningConfirmationSwal({
      text: CommonMessages.ACTION_CANNOT_BE_UNDONE,
      showCancelButton: true,
      confirmButtonColor: '#30747F',
      confirmButtonText: GoalsMessages.ACTIVITY_COMMENT_DELETE_PROMPT
    }).then((result) => {
      if (result.isConfirmed) {
        this.commentBeingDeletedId = id;
        const goalCommentDto = new GoalCommentDto(id, null);
        this.goalsAPIService.deleteComment(this.goal.id, goalCommentDto).subscribe((goal: Goal) => {
          this.goal.activity = goal.activity.sort((a, b) => {
            const dateA = new Date(a.date);
            const dateB = new Date(b.date);
            return dateB.getTime() - dateA.getTime();
          });
          this.notifyUtils.notify(GoalsMessages.SUCCESS_COMMENT_DELETE);
          this.commentBeingDeletedId = undefined;
        }, () => {
          this.commentBeingDeletedId = undefined;
        });
      }
    });
  }

  unsavedChanges() {
    if (this.controlComment.value.trim().length > 0) {
      return true;
    }

    return false;
  }

  resetInput() {
    if (!this.state.submitting) {
      this.form = this.initForm();
    }
  }

  trackByFn(): TrackByFunction<GoalActivity> {
    return (index: number, activity: GoalActivity): number => {
      return activity.id;
    };
  }
}
