import {Component, ElementRef, forwardRef, Input, OnInit, ViewChild} from '@angular/core';
import {ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR} from '@angular/forms';
import {CommonMessages} from '@app/constants/common.messages';
import { Role } from '@app/domain/role/model/role.model';
import { RoleBusinessService } from '@app/domain/role/service/role-business.service';
import { CompanyFeatures } from '@app/models/company-features.model';
import { IState } from '@app/models/state/state.model';
import {Globals} from '@app/shared/globals/globals';
import { debounceTime, map } from 'rxjs/operators';

interface PageState extends IState {
  searchRunning: boolean;
  dropdownOpen: boolean;
  disabled: boolean;
  searchIsEmpty: boolean;
}

type PickerValue = (Role | Role[]);

@Component({
  selector: 'app-role-picker',
  templateUrl: './role-picker.component.html',
  styleUrls: ['./role-picker.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => RolePickerComponent),
    multi: true,
  }],
})
export class RolePickerComponent implements OnInit, ControlValueAccessor {
  public readonly eCommonMessages = CommonMessages;

  @ViewChild('searchInput') searchInput?: ElementRef<HTMLInputElement>;

  @Input() searchPlaceholder: string;  
  @Input() customOptions: Role[];
  @Input() customOptionsOnly: boolean;
  @Input() canSelectMultiple: boolean;
  @Input() blacklistedIds: number[];
  @Input() showClearIcon: boolean;
  @Input() loseFocusAfterSelection: boolean;
  @Input() clearSearchAfterSelection: boolean;

  state: PageState;
  searchControl: FormControl;
  results: Role[];
  _value: Role[];
  searchIsFocused: boolean;

  onChange = (_: any) => {};
  onTouched = () => {};

  get value(): PickerValue {
    return (this.canSelectMultiple ? this._value : this._value[0]);
  }

  set value(v: PickerValue) {
    if (this.state.disabled) { return; }
    this.writeValue(v);
    this.onChange(v);
  }

  get canSeeGrades(): boolean {
    if (!this.globals) { return false; }
    if (!this.globals.hasFeature(CompanyFeatures.GRADES)) { return false; }
    return true;
  }

  constructor(
    public globals: Globals,
    private roleBusinessService: RoleBusinessService
  ) {
    this.searchControl = new FormControl();
    this._value = [];
    this.results = [];
    this.blacklistedIds = [];

    this.showClearIcon = true;
    this.searchPlaceholder = CommonMessages.SEARCH_BY_NAME;

    this.customOptions = [];
    this.customOptionsOnly = false;
    this.canSelectMultiple = false;

    this.state = {
      loading: true,
      error: false,
      errorMessage: '',
      searchRunning: false,
      dropdownOpen: false,
      disabled: false,
      searchIsEmpty: true
    };
    this.searchIsFocused = false;
    this.loseFocusAfterSelection = true;
    this.clearSearchAfterSelection = true;
  }

  ngOnInit(): void {
    this.state.loading = false;
    this.searchControl.valueChanges
      .pipe(
        map(sarg => {
          this.state.searchRunning = true;
          if (sarg) {
            sarg = sarg.trim().toLowerCase();
          }
          return sarg;
        }),
        debounceTime(500)
      )
      .subscribe(sarg => this.trySearch(sarg));
  }

  trySearch(sarg: string): void {
    this.state.searchRunning = true;

    this.state.searchIsEmpty = !(sarg && sarg.length > 0);

    if (this.customOptionsOnly) {
      this.doSearchCustomOnly(this.customOptions, sarg);
      return;
    } else {
      this.doSearchRegular(this.customOptions, sarg);
    }
  }
  
  doSearchCustomOnly(customOptions: Role[], sarg: string): void {
    let results = customOptions;
    if (sarg) {
      results = results.filter(s => s.name.toLowerCase().includes(sarg)); // Only matches to search
    }
    results = results.slice(0, 10); // Only first 10
    const selectedRoleIds = this._value.map(v => v.id);
    results = results.filter(r => !selectedRoleIds.includes(r.id)); // Not already selected
    results = results.filter(r => !this.blacklistedIds.includes(r.id)); // Not blacklisted

    this.results = results;
    this.state.searchRunning = false;
    this.state.loading = false;
  }

  doSearchRegular(customOptions: Role[], sarg: string): void {
    let matchingCustomResults = [];
    if (sarg) {
      matchingCustomResults = customOptions.filter(s => s.name.toLowerCase().includes(sarg));
    }

    this.roleBusinessService.searchRoles(sarg)
      .then(results => {
        results = [...matchingCustomResults, ...results ];
        const selectedRoleIds = this._value.map(v => v.id);
        results = results.filter(r => !selectedRoleIds.includes(r.id));
        results = results.filter(r => !this.blacklistedIds.includes(r.id));
        this.results = results.slice(0, 10);
      })
      .finally(() => {
        this.state.searchRunning = false;
        this.state.loading = false;
      });
  }

  selectItem(role: Role): void {
    if (this.state.disabled) { return; }

    if (this.canSelectMultiple) {
      this.addItemToMultiplePickerValue(role);
    } else {
      this.value = role;
      this.state.dropdownOpen = false;
    }

    this.blurSearchIfToggled();
    this.clearSearchIfToggled();

    this.onTouched();
    this.searchControl.updateValueAndValidity();
  }

  blurSearchIfToggled(): void {
    if (!this.loseFocusAfterSelection) {
      setTimeout(() => {
        this.focusSearchInput();
      }, 5);
    } else {
      this.blurSearchInput();
    }
  }

  clearSearchIfToggled(): void {
    if (this.clearSearchAfterSelection) {
      this.searchControl.patchValue('');
    }
  }

  addItemToMultiplePickerValue(role: Role): void {
    const selectedIds = this._value.map(r => r.id);
    if (selectedIds.includes(role.id)) { return; }
    this.value = [...this._value, role];
  }

  removeSelectedItem(role: Role, event?: MouseEvent): void {
    if (!this.canSelectMultiple) { return; }
    if (event) {
      event.stopPropagation();
    }
    this.value = this._value.filter(r => r.id !== role.id);
  }

  writeValue(v: PickerValue): void {
    if (this.canSelectMultiple) {
      v = v || [];
      this._value = v as Role[];
    } else {
      if (v) {
        this._value = [v as Role];
      } else {
        this._value = [];
      }
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.state.disabled = isDisabled;
    if (isDisabled) {
      this.searchControl.disable();
    } else {
      this.searchControl.enable();
    }
  }

  onFocusSearch(): void {
    this.searchIsFocused = true;
    if (this.state.disabled) { return; }

    this.state.dropdownOpen = true;
  }

  onBlurSearch(): void {
    this.searchIsFocused = false;

    setTimeout(() => {
      if (this.searchIsFocused) { return; }
      this.state.dropdownOpen = false;
    }, 200);
  }

  trySelectFirst() {
    if (this.results.length === 0) {
      if (!this.searchControl.value) { return this.searchControl.updateValueAndValidity(); }
      return;
    } // Can't select first if there are zero results

    this.selectItem(this.results[0]);

    if (!this.canSelectMultiple) {
      this.blurSearchInput();
    }
  }

  focusSearchInput(): void {
    if (!this.searchInput) { return; }
    this.searchInput.nativeElement.focus();
    this.state.dropdownOpen = true;
  }

  blurSearchInput(): void {
    if (!this.searchInput) { return; }
    this.searchInput.nativeElement.blur();
    this.state.dropdownOpen = false;
  }

  onKeyupBackspace(): void {
    if (this.searchControl.value) { return; }
    if (!this.canSelectMultiple) { return; }
    if (!this.state.searchIsEmpty) { return; }
    if (this.state.searchRunning) { return; }

    // Get value
    const value = this.value as Role[];

    if (!value) { return; }
    if (value.length === 0) { return; }

    value.pop();

    this.value = value;
    this.searchControl.updateValueAndValidity();
  }

  onKeyupEscape(): void {
    this.blurSearchInput();
  }

  clearInput(): void {
    const customOptionWithNullId = this.customOptions.find(c => c.id === null);

    if (customOptionWithNullId) {
      if (this.canSelectMultiple) {
        this.value = [ customOptionWithNullId ];
      } else {
        this.value = customOptionWithNullId;
      }
    } else {
      if (this.canSelectMultiple) {
        this.value = [];
      } else {
        this.value = null;
      }
    }

    // this.searchControl.updateValueAndValidity();
  }
}
