import { Component, EventEmitter, Input, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { QuestionControlService } from '@app/shared/survey-components/services/question-control.service';
import { FormGroup } from '@angular/forms';
import { QuestionBase } from '@app/shared/survey-components/dynamic-form/question-base';
import { DynamicFormQuestionComponent } from './dynamic-form-question/dynamic-form-question.component';

@Component({
  selector: 'app-dynamic-form',
  templateUrl: './dynamic-form.component.html',
  styleUrls: ['./dynamic-form.component.css']
})
export class DynamicFormComponent implements OnInit {
  @ViewChildren('formQuestion') formQuestions?: QueryList<DynamicFormQuestionComponent>;
  @Input() disabled = false;
  @Input() questions: Array<QuestionBase<string>> = new Array<QuestionBase<string>>();
  @Output() formSubmitted = new EventEmitter<FormGroup>();
  @Input() isSubmitted: boolean;
  @Input() showSubmitButton: boolean;

  form!: FormGroup;

  constructor(private questionControlService: QuestionControlService) {
    this.isSubmitted = false;
    this.showSubmitButton = true;
  }

  ngOnInit() {
    this.form = this.questionControlService.toFormGroup(this.questions);
    if (this.disabled) {
      this.form.disable();
    }
  }

  onSubmit() {
    this.isSubmitted = true;
    this.formSubmitted.emit(this.form);
  }

  public getAnswers(): string[] {
    const answers: string[] = [];

    if (this.formQuestions) {
      this.formQuestions
      .toArray()
      .forEach((question) => {
        answers.push(question.form.value);
      })
    }

    return answers;
  }

  public checkAnyTouched(): boolean {
    let touched = false;

    if (this.formQuestions) {
      this.formQuestions
      .toArray()
      .forEach((question) => {
        if (question.form.touched) {
          touched = true;
        }
      })
    }

    return touched;
  }

  public checkAnyDirty(): boolean {
    let dirty = false;

    if (this.formQuestions) {
      this.formQuestions
      .toArray()
      .forEach((question) => {
        if (question.form.dirty) {
          dirty = true;
          return;
        }
      })
    }

    return dirty;
  }

  public focusFirstInvalidInput(): boolean {
    let matchFound = false;

    if (this.formQuestions) {
      this.formQuestions
      .toArray()
      .forEach((q, i) => {
        if (!matchFound) {
          const question = q.form.get(q.question.key);
          if (question && question.invalid) {
            matchFound = true;
            q.elRef.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
            return;
          }
        }
      });
    }

    return matchFound;
  }
}
