<div class="chart-container">
  <div class="chart-container-inner" *ngIf="data; else noData">
    <ng-container *ngFor="let score of dataParsed;">
      <div class="col-likert-score">
        <div class="progress-vertical-container">
          <div class="progress-vertical-bar" [style.backgroundColor]="score.color" [style.height]="score.height" [tooltip]="score.tooltip" [triggers]="globals.tooltipTriggers" placement="top" container="body">
          </div>
        </div>
        <div class="score-label-container" [tooltip]="score.label" [triggers]="globals.tooltipTriggers" placement="bottom" container="body">
          <div class="score-label-box" [style.color]="score.color" [style.backgroundColor]="score.colorAlt">
            {{score.text}}
          </div>
        </div>
      </div>
    </ng-container>
  </div>

  <ng-template #noData>
    <div>No Data</div>
  </ng-template>
</div>