import { Injectable } from '@angular/core';
import { MockService } from '@app/mock/api/mock-service';
import { HttpRequest } from '@angular/common/http';
import { MentoringRequestService } from '@app/shared/api/interfaces/connect/mentoring-request.service';
import { ManagerDeclineMentoringRequest } from '@app/models/connect/mentoring-request/manager-decline-mentoring-request.model';
import { MentorDeclineMentoringRequest } from '@app/models/connect/mentoring-request/mentor-decline-mentoring-request.model';
import { ResubmitMentoringRequest } from '@app/models/connect/mentoring-request/resubmit-mentoring-request.model';
import { MentoringRequest } from '@app/models/connect/mentoring-request/mentoring-request.model';
import { CreateMentoringRequest } from '@app/models/connect/mentoring-request/create-mentoring-request.model';
import { Observable } from 'rxjs';
import { Globals } from '@app/shared/globals/globals';
import { sanitizeUrl } from '@app/shared/utils/helpers';
import { mockMentoringRequests } from '@app/mock/api/data/mockMentoringRequests';

@Injectable()
export class MentoringRequestMockService implements MockService, MentoringRequestService {
    constructor(private readonly globals: Globals) { }

    handleRoute(req: HttpRequest<any>): any {
        const url = sanitizeUrl(req.urlWithParams);

        switch (true) {
            case url.endsWith('api/connect/mentoring-requests/manager'):
                return this.getMentoringRequestsByManagerMe();
            case url.match(/api\/connect\/mentoring-requests\/\d+$/) !== null:
                const mentoringRequestId = +url.split('/')[3];
                return this.getMentoringRequestById(mentoringRequestId);
            case url.match(/api\/connect\/mentoring-requests\/mentor\/\d+$/) !== null:
                const mentorId = +url.split('/')[4];
                return this.getMentoringRequestsByMentorId(mentorId);
            case url.match(/api\/connect\/mentoring-requests\/mentee\/\d+$/) !== null:
                const menteeId = +url.split('/')[4];
                return this.getMentoringRequestsByMenteeId(menteeId);
        }
    }

    getMentoringRequestById(id: number): Observable<MentoringRequest> | MentoringRequest {
        return mockMentoringRequests.find(m => m.id === id);
    }

    getMentoringRequestsByManagerMe(): Observable<Array<MentoringRequest>> | MentoringRequest[] {
        return mockMentoringRequests.filter(m => m.menteeManagerId === this.globals.user.id);
    }

    getMentoringRequestsByMenteeId(menteeId: number): Observable<Array<MentoringRequest>> | MentoringRequest[] {
        return mockMentoringRequests.filter(m => m.menteeId === menteeId);
    }

    getMentoringRequestsByMentorId(mentorId: number): Observable<Array<MentoringRequest>> | MentoringRequest[] {
        return mockMentoringRequests.filter(m => m.mentorId === mentorId);
    }

    // No Ops listed below
    closeMentoringRequest(id: number): Observable<MentoringRequest> {
        return undefined;
    }

    createMentoringRequest(createMentoringRequestDto: CreateMentoringRequest): Observable<MentoringRequest> {
        return undefined;
    }

    managerApproveMentoringRequest(id: number): Observable<MentoringRequest> {
        return undefined;
    }

    managerDeclineMentoringRequest(id: number, managerDeclineMentoringRequest: ManagerDeclineMentoringRequest): Observable<MentoringRequest> {
        return undefined;
    }

    mentorApproveMentoringRequest(id: number): Observable<MentoringRequest> {
        return undefined;
    }

    mentorDeclineMentoringRequest(id: number, mentorDeclineMentoringRequest: MentorDeclineMentoringRequest): Observable<MentoringRequest> {
        return undefined;
    }

    resubmitMentoringRequest(id: number, resubmitMentoringRequest: ResubmitMentoringRequest): Observable<MentoringRequest> {
        return undefined;
    }

}