import { AfterViewChecked, Component, Input } from '@angular/core';

@Component({
  selector: 'app-progress-bar-round',
  templateUrl: './progress-bar-round.component.html',
  styleUrls: ['./progress-bar-round.component.scss']
})
export class ProgressBarRoundComponent implements AfterViewChecked {
  @Input() textColor: string;
  @Input() progress: number;
  @Input() size: number;
  @Input() thickness: number;
  @Input() fontSize: number;
  @Input() showValue: boolean;

  get progressNumber(): number {
    if (isNaN(this.progress)) {
      return 0;
    }

    return this.progress;
  }

  roundProgress: {
    left: string;
    right: string;
  };
  constructor() {
    this.textColor = '#333';
    this.progress = 0;
    this.size = 100;
    this.thickness = 12;
    this.fontSize = 24;
    this.roundProgress = {
      left: 'rotate(0)',
      right: 'rotate(0)'
    };
    this.showValue = true;
  }

  ngAfterViewChecked(): void {
    this.roundProgress = this.getRoundProgress(this.progress);
  }

  getRoundProgress(progress: number): {
    left: string;
    right: string;
  } {
    if (isNaN(progress)) {
      return {
        left: 'rotate(0)',
        right: 'rotate(0)'
      };
    }

    let left = 0;
    let right = 0;
    if (progress > 0) {
      if (progress <= 50) {
        right = (progress / 100 * 360);
      } else {
        right = 180;
        left = ((progress - 50) / 100 * 360);
      }
    }

    return {
      left: `rotate(${left}deg)`,
      right: `rotate(${right}deg)`
    };
  }
}
