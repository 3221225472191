import { Injectable } from '@angular/core';
import { MockService } from '@app/mock/api/mock-service';
import { HttpRequest } from '@angular/common/http';
import { ConnectQuestionService } from '@app/shared/api/interfaces/connect/connect-question.service';
import { ConnectQuestionCategory } from '@app/models/connect/type/connect-question-category.enum';
import { ConnectQuestion } from '@app/models/connect/question/connect-question.model';
import { Observable } from 'rxjs';
import { sanitizeUrl } from '@app/shared/utils/helpers';
import { mockConnectQuestions } from '@app/mock/api/data/mockConnectQuestions';

@Injectable()
export class ConnectQuestionMockService implements MockService, ConnectQuestionService {
    handleRoute(req: HttpRequest<any>): any {
        const url = sanitizeUrl(req.urlWithParams);
        switch (true) {
            case url.match(/api\/connect\/questions\?category=\D+$/) !== null:
                const category = req.params.get('category') as ConnectQuestionCategory;
                return this.getConnectQuestionsByCategory(category);
        }
    }

    getConnectQuestionsByCategory(category: ConnectQuestionCategory): Observable<Array<ConnectQuestion>> | ConnectQuestion[] {
        return mockConnectQuestions.filter(q => q.category === category);
    }

}