import { Component, Input, OnInit } from '@angular/core';
import { TaskComment } from '@app/domain/task/model/task-comment.model';
import { TasksMessages } from '@app/domain/task/locale/tasks.messages';

@Component({
  selector: 'app-task-comment-display',
  templateUrl: './task-comment-display.component.html',
  styleUrls: ['./task-comment-display.component.scss']
})
export class TaskCommentDisplayComponent implements OnInit {

  public readonly eTasksMessages = TasksMessages;

  @Input() comment: TaskComment;

  constructor() {
    this.comment = undefined!;
  }

  ngOnInit(): void {
  }

}
