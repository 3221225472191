<div class="goal-create-alignment-container" [class.parent-dropdown-closed]="!parentDropdownOpen">
  <ng-container *ngIf="parentDropdownOpen; else templateDisplayValues">
    <div class="p-15">
      <ng-container *ngIf="controlType.value">
        <div class="card card-yellow alignment-description-text" [ngSwitch]="controlType.value">
          <div class="content">
            <span class="fal fa-fw fa-question-circle m-right-5"></span>
            <span *ngSwitchCase="eGoalType.PERSONAL_DEVELOPMENTAL" translate>{{eGoalsMessages.GOAL_CREATE_ALIGNMENT_EXPLANATION_PERSONAL_DEVELOPMENTAL}}</span>
            <span *ngSwitchCase="eGoalType.PERSONAL_OPERATIONAL" translate>{{eGoalsMessages.GOAL_CREATE_ALIGNMENT_EXPLANATION_PERSONAL_OPERATIONAL}}</span>
            <span *ngSwitchCase="eGoalType.DEPARTMENT" translate [translateParams]="{ departmentWording: globals.getTerminology(eTerminologyEntity.DEPARTMENT) }">{{eGoalsMessages.GOAL_CREATE_ALIGNMENT_EXPLANATION_DEPARTMENT}}</span>
            <span *ngSwitchCase="eGoalType.COMPANY" translate>{{eGoalsMessages.GOAL_CREATE_ALIGNMENT_EXPLANATION_COMPANY}}</span>
            <span *ngSwitchCase="eGoalType.OFFICE_LOCATION" translate [translateParams]="{ siteWording: globals.getTerminology(eTerminologyEntity.SITE) }">{{eGoalsMessages.GOAL_CREATE_ALIGNMENT_EXPLANATION_OFFICE_LOCATION}}</span>
            <span *ngSwitchCase="eGoalType.TEAM" translate [translateParams]="{ teamWording: globals.getTerminology(eTerminologyEntity.TEAM) }">{{eGoalsMessages.GOAL_CREATE_ALIGNMENT_EXPLANATION_TEAM}}</span>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="controlType.value !== eGoalType.COMPANY">
        <div class="frankli-form-group-placeholder-shown frankli-form-group-filled">
          <app-goal-picker [formControl]="controlAlignmentInner" [allowedGoalTypes]="validGoalTypes"></app-goal-picker>
        </div>
      </ng-container>
    </div>
  </ng-container>
  <ng-template #templateDisplayValues>
    <ng-container *ngIf="goalItemSelected">
        <div class="p-15">
          <div class="goal-selected" (click)="onClickPreview()">
            <div class="goal-selected-item">
              <app-goal-display [goal]="goalItemSelected" [canSelect]="true" [selected]="true" [showIcon]="true" [showProgress]="false"></app-goal-display>
            </div>
          </div>
        </div>
    </ng-container>
  </ng-template>
</div>