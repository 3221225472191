<ng-container *ngIf="!state.loading && !state.error">
  <div class="outer-card">
    <div class="container-scorecard-average-display">
      <div class="scorecard-item-top">
        <ng-container *ngIf="iqsMonth !== -1; else no_iqs">
          <div class="scorecard-average" title="IQS">{{ iqsMonth }}</div>
        </ng-container>
        <ng-template #no_iqs>
          <div class="scorecard-average" title="IQS">(no data)</div>
        </ng-template>
        <div class="scorecard-icon">
          <span class="fal fa-fw fa-clipboard-list"></span>
        </div>
      </div>
      <ng-container *ngIf="true">
        <div class="scorecard-pass-rate">Current month IQS</div>
      </ng-container>
    </div>
  </div>
  <div class="outer-card">
    <div class="container-scorecard-average-display">
      <div class="scorecard-item-top">
        <ng-container *ngIf="iqsTwoMonths !== null && iqsTwoMonths > 0; else no_data">
          <div class="scorecard-average" title="IQS">{{ iqsTwoMonths }}</div>
        </ng-container>
        <ng-template #no_data>
          <div class="scorecard-average" title="IQS">(no data)</div>
        </ng-template>
        <div class="scorecard-icon">
          <span class="fal fa-fw fa-clipboard-list-check"></span>
        </div>
      </div>
      <ng-container *ngIf="true">
        <div class="scorecard-pass-rate">Previous month IQS</div>
      </ng-container>
    </div>
  </div>
  <div class="outer-card">
    <div class="container-scorecard-average-display">
      <div class="scorecard-item-top">
        <ng-container *ngIf="delta !== null && iqsTwoMonths > 0; else no_delta">
          <div class="scorecard-average" title="IQS">{{ delta }}</div>
        </ng-container>
        <ng-template #no_delta>
          <div class="scorecard-average" title="IQS">(no data)</div>
        </ng-template>
        <div class="scorecard-icon">
          <span class="fal fa-fw fa-clipboard-list-check"></span>
        </div>
      </div>
      <div class="scorecard-pass-rate">Month to month change</div>
    </div>
  </div>
</ng-container>

<!-- #region - Alt states -->
<!-- 
  I would have used ng-templates for these but it didn't look very nice 
  If we decide to move the loading and error screens to be per card, we can use ng-templates
  For example: if we wanted to display an error message when the user has no IQS for 
  either month and therefore the delta cannot be calculated
-->

<ng-container *ngIf="state.loading">
  <div class="p-15 text-center">
    <span class="fal fa-spin fa-spinner fa-2x fa-fw"></span>
  </div>
</ng-container>

<ng-container *ngIf="state.error">
  <div class="p-15 text-center">
    <img class="icon-padding new-icon-height" src="/assets/img/Error.svg" />
    <br />
    <span>An error occurred loading this information or it may not be available</span>
  </div>
</ng-container>