import { TitleCasePipe } from '@angular/common';
import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { TableColumn } from 'app/models/col-toggle-model';
import { of } from 'rxjs';
import { Globals } from '../globals/globals';
import { TerminologyEntity } from '@app/domain/terminology/model/terminology-entity.enum';

@Component({
  selector: 'app-column-toggle',
  templateUrl: './column-toggle.component.html',
  styleUrls: ['./column-toggle.component.scss']
})
export class ColumnToggleComponent implements OnInit, OnChanges {

  @Input() titles: string[] = [];
  @Input() alwaysEnabled: string[] = [];
  @Input() startDisabled: string[] = [];

  columns!: TableColumn[];
  
  constructor(
    public globals: Globals,
    private titleCasePipe: TitleCasePipe
  ) {
    this.columns = [];
  }

  ngOnInit() {
    this.setColumns(this.titles);
  }

  ngOnChanges() {
    this.setColumns(this.titles);
  }

  setColumns(titles: string[]) {
    this.columns = [];
    titles.forEach(t => {
      this.columns.push({
        key: t,
        title: t === 'DEPARTMENT'? this.titleCasePipe.transform(this.globals.getTerminology(TerminologyEntity.DEPARTMENT)) : t,
        enabled: this.checkIfStartsDisabled(t)
      });
    });
  }

  checkIfStartsDisabled(key: string): boolean {
    if (this.startDisabled.length > 0) {
      if (this.startDisabled.includes(key)) {
        return false;
      }
    }

    return true;
  }

  toggleColumn(event: any, key: string) {
    event.stopPropagation();

    if (!this.alwaysEnabled.includes(key)) {
      this.columns.forEach(c => {
        if (c.key === key) {
          c.enabled = !c.enabled;
        }
      });
    }
  }

  public getColumnsEnabled(): string[] {
    return this.columns.filter(c => c.enabled).map(c => c.key);
  }

  public getColumnEnabled(key: string) {
    if (this.alwaysEnabled.includes(key)) { return true; }

    const col = this.columns.find(c => c.key === key);
    if (col) {
      return col.enabled;
    } else {
      return false;
    }
  }

  subAllColumns() {
    return of(this.columns);
  }

  checkAllEnabled() {
    return !(this.columns.map(c => c.enabled).includes(false));
  }

  checkAllDisabled() {
    return !(this.columns.map(c => c.enabled).includes(true));
  }

  toggleAll(event: any) {
    event.stopPropagation();
    if (this.checkAllEnabled() === false) {
      this.columns.forEach(c => {
        c.enabled = true;
      });
    } else {
      this.columns.forEach(c => {
        if (!this.alwaysEnabled.includes(c.key)) {
          c.enabled = false;
        }
      });
    }
  }

  public getTotalEnabledColumns(): number {
    return this.columns.filter(c => c.enabled === true).length;
  }

  public getCountEnabledFromArray(columns: string[]): number {
    let counter = 0;

    columns.forEach(c => {
      if (this.getColumnEnabled(c)) {
        counter++;
      }
    });

    return counter;
  }
}
