export const TasksMessages = {
  TASKS: 'tasks.tasks',
  TASKS_DESCRIPTION: 'tasks.tasks-description',

  // TASK MESSAGES
  CREATE_TASK: 'tasks.create-task',
  UPDATE_TASK: 'tasks.update-task',
  VIEW_TASK: 'tasks.view-task',
  EDIT_TASK: 'tasks.edit-task',
  ARCHIVE_TASK: 'tasks.archive-task',

  // TODO MESSAGES
  CREATE_TODO: 'tasks.create-todo',
  UPDATE_TODO: 'tasks.update-todo',
  VIEW_TODO: 'tasks.view-todo',
  EDIT_TODO: 'tasks.edit-todo',
  ARCHIVE_TODO: 'tasks.archive-todo',

  // ACTION POINT MESSAGES
  CREATE_ACTION_POINT: 'tasks.create-action-point',
  UPDATE_ACTION_POINT: 'tasks.update-action-point',
  VIEW_ACTION_POINT: 'tasks.view-action-point',
  EDIT_ACTION_POINT: 'tasks.edit-action-point',
  ARCHIVE_ACTION_POINT: 'tasks.archive-action-point',

  // MILESTONE MESSAGES
  CREATE_MILESTONE: 'tasks.create-milestone',
  UPDATE_MILESTONE: 'tasks.update-milestone',
  VIEW_MILESTONE: 'tasks.view-milestone',
  EDIT_MILESTONE: 'tasks.edit-milestone',
  ARCHIVE_MILESTONE: 'tasks.archive-milestone',

  TITLE: 'tasks.title',
  DUE_DATE: 'tasks.due-date',
  CREATE_DATE: 'tasks.create-date',
  COMPLETE_DATE: 'tasks.complete-date',
  DESCRIPTION: 'tasks.description',
  TASK_OWNERS: 'tasks.task-owners',

  TITLE_VALIDATION_REQUIRED: 'tasks.task-create.title-validation-required',
  OWNERS_VALIDATION_REQUIRED: 'tasks.task-create.owners-validation-required',
  GOAL_VALIDATION_REQUIRED: 'tasks.task-create.goal-validation-required',
  SCHEDULE_VALIDATION_REQUIRED: 'tasks.task-create.schedule-validation-required',
  SCHEDULE_VALIDATION_EMPTY: 'tasks.task-create.schedule-validation-empty',

  CREATED_BY: 'tasks.created-by',
  TITLE_AND_DESCRIPTION: 'tasks.title-and-description',
  ALIGNMENT: 'tasks.alignment',
  STATUS: 'tasks.status',
  ACTIONS: 'tasks.actions',

  EMPTY_STATE: 'tasks.empty-state',
  EMPTY_STATE_SEARCH: 'tasks.empty-state-search',
 
  STATUS_NOT_STARTED: 'tasks.status-options.not-started',
  STATUS_IN_PROGRESS: 'tasks.status-options.in-progress',
  STATUS_COMPLETE: 'tasks.status-options.complete',

  ALIGNMENT_GOAL: 'tasks.alignment-type.goal',
  ALIGNMENT_ONE_TO_ONE: 'tasks.alignment-type.one-to-one',
  ALIGNMENT_TODO: 'tasks.alignment-type.todo',
  ALIGNMENT_MILESTONE: 'tasks.alignment-type.milestone',

  SUCCESS_TASK_CREATED: 'tasks.success-task-created',
  SUCCESS_TASK_UPDATED: 'tasks.success-task-updated',
  SUCCESS_COMMENT_ADDED: 'tasks.success-comment-added',
  SUCCESS_COMMENT_DELETED: 'tasks.success-comment-deleted',

  ALIGN_TO_A_GOAL: 'tasks.align-to-a-goal',
  ALIGN_TO_A_ONE_TO_ONE: 'tasks.align-to-a-one-to-one',
  ALIGN_TO_A_ROLE: 'tasks.align-to-a-role',

  DELETE_COMMENT: 'tasks.delete-comment',
  ACTIVITY: 'tasks.activity',

  QUESTION_POST_A_COMMENT: 'tasks.question-post-a-comment',
  COMMENT_SUBMIT_PROMPT: 'tasks.comment-submit-prompt',

  ACTIVITY_TASK_CREATED: 'tasks.activity-output.user-created-task',
  ACTIVITY_TASK_UPDATED: 'tasks.activity-output.user-updated-task',
  ACTIVITY_TASK_UPDATED_STATUS: 'tasks.activity-output.user-updated-status-task',
  ACTIVITY_TASK_ARCHIVED: 'tasks.activity-output.user-archived-task',

  ACTIVITY_EDITED_TITLE: 'tasks.activity-output.edited-title',
  ACTIVITY_EDITED_DESCRIPTION: 'tasks.activity-output.edited-description',
  ACTIVITY_EDITED_ALIGNMENT_TYPE: 'tasks.activity-output.edited-alignment-type',
  ACTIVITY_EDITED_ALIGNMENT_ID: 'tasks.activity-output.edited-alignment-id',
  ACTIVITY_EDITED_DUE_DATE: 'tasks.activity-output.edited-due-date',
  ACTIVITY_EDITED_OWNERS_REMOVED: 'tasks.activity-output.edited-owners-removed',
  ACTIVITY_EDITED_OWNERS_ADDED: 'tasks.activity-output.edited-owners-added',

  ACTIVITY_TASK_COMMENTED: 'tasks.activity-output.user-commented-task',

  WARNING_UNLINK_TASK_GOAL: 'tasks.warning-unlink-task-goal',
  WARNING_UNLINK_TASK_ONE_TO_ONE: 'tasks.warning-unlink-task-one-to-one',
  WARNING_UNLINK_TASK_ROLE_APPLICATION: 'tasks.warning-unlink-task-role-application',
  SUCCESS_UNLINK_TASK: 'tasks.success-unlink-task',
  UNLINK_TASK: 'tasks.unlink-task',

  WARNING_ARCHIVE_TASK: 'tasks.warning-archive-task',
  SUCCESS_ARCHIVE_TASK: 'tasks.success-archive-task',

  PLACEHOLDER_TASK_DESCRIPTION: 'tasks.placeholder-task-description',
  PLACEHOLDER_TASK_SEARCH: 'tasks.placeholder-task-search',

  TASK_OWNERS_VALIDATION_REQUIRED: 'tasks.task-owners-validation-required',

  VIEW_AS_ADMIN: 'tasks.view-as-admin',
  TASK_COUNT: 'tasks.task-count',
  TASK_COUNT_COMPLETE: 'tasks.task-count-complete',
  TASK_COUNT_IN_PROGRESS: 'tasks.task-count-in-progress',
  TASK_COUNT_NOT_STARTED: 'tasks.task-count-not-started',
  TASK_COUNT_OVERDUE: 'tasks.task-count-overdue',
  ACTIVE_TASKS: 'tasks.active-tasks',
  AVERAGE_COMPLETION: 'tasks.average-completion',
  DUE_IN_THE_NEXT_30_DAYS: 'tasks.due-in-the-next-30-days',
  COMPLETED_IN_THE_PAST_3_MONTHS: 'tasks.completed-in-the-past-3-months'
};
