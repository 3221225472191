import { Injectable } from '@angular/core';

interface IIconDictionary {
    [index: string]: string;
}

export const VINCERE_METRIC_ICONS: IIconDictionary = {
    'jobs logged': 'fa-briefcase',
    'cv sends': 'fa-file-certificate',
    '1st interviews': 'fa-comments',
    '2nd interviews': 'fa-comments',
    'placements made': 'fa-check',
    'kit calls': 'fa-phone',
    'bd calls': 'fa-phone',
    'client meetings': 'fa-handshake',
    'candidate meetings': 'fa-handshake',
    'candidates added': 'fa-user-plus'
}

@Injectable()
export class VincereUtils {
    getIconForMetricName(metricName: string): string {
        metricName = metricName.trim().toLocaleLowerCase();

        if (!VINCERE_METRIC_ICONS[metricName]) {
            console.error(`No icon found for vincere metric "${metricName}"`)
            return 'fa-bullseye';
        } else return VINCERE_METRIC_ICONS[metricName];
    }
}