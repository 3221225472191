import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { CommonMessages } from '@app/constants/common.messages';
import { GoalsMessages } from '@app/goals/goals.messages';
import { CommonGoalCreateFormPart } from '../../goal-create-views/goal-create-main/goal-create-main.component';
import { GoalCreateValidationLimits } from '../../goal-create.component';

@Component({
  selector: 'app-goal-create-part-development-needs',
  templateUrl: './goal-create-part-development-needs.component.html',
  styleUrls: ['./goal-create-part-development-needs.component.scss']
})
export class GoalCreatePartDevelopmentNeedsComponent implements OnInit, CommonGoalCreateFormPart {
  public readonly eGoalsMessages = GoalsMessages;
  public readonly eGoalCreateValidationLimits = GoalCreateValidationLimits;
  public readonly eCommonMessages = CommonMessages;

  @Input() controlDevelopmentNeeds: FormControl;
  @Input() submitted: boolean;
  @Input() submittedDraft: boolean;
  @Input() parentDropdownOpen: boolean;

  @Output() onSetParentDropdown: EventEmitter<boolean>;

  constructor() {
    
    this.controlDevelopmentNeeds = new FormControl('', [Validators.minLength(GoalCreateValidationLimits.developmentNeeds.min), Validators.maxLength(GoalCreateValidationLimits.developmentNeeds.max)]);
    this.submitted = false;
    this.submittedDraft = false;
    this.parentDropdownOpen = true;
    this.onSetParentDropdown = new EventEmitter<boolean>();
  }

  ngOnInit(): void {
  }

  setParentDropdown(expanded: boolean): void {
    this.onSetParentDropdown.emit(expanded);
  }

  focus(): void {
    // TODO:
  }
}
