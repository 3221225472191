<ng-container *ngIf="parentDropdownOpen || (!parentDropdownOpen && (controlPriority.value !== eGoalPriority.NOT_SET))">
  <div class="goal-create-priority-container" [class.parent-dropdown-closed]="!parentDropdownOpen">
    <div class="priority-item-list">
      <ng-container *ngFor="let dataItem of dataParsed; index as index">
        <div class="priority-item" [class.priority-item-selected]="controlPriority.value === dataItem.priority" (click)="changeSelection(dataItem.priority)">
          <div class="row m-left-0 m-right-0">
            <div class="col-icon">
              <ng-container *ngIf="(dataItem.priority !== eGoalPriority.NOT_SET); else notSetIconTemplate;">
                <span translate>{{dataItem.iconText}}</span>
              </ng-container>
              <ng-template #notSetIconTemplate>
                <span class="fal fa-fw" [ngClass]="dataItem.icon"></span>
              </ng-template>
            </div>
            <div class="col-details">
              <span translate>{{dataItem.friendlyText}}</span>
            </div>
            <div class="col-checkmark">
              <span class="fal fa-fw fa-check icon-selected"></span>
              <span class="fal fa-fw fa-times icon-deselect"></span>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</ng-container>