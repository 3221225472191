import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonMessages } from '@app/constants/common.messages';
import { GoalsMessages } from '@app/goals/goals.messages';
import { GoalKeyResultMeasureUnitPlacement } from '@app/models/goals/goal-key-result-measure-unit-placement.model';
import { GoalKeyResultType } from '@app/models/goals/goal-key-result-type.model';
import { Goal } from '@app/models/goals/goal.model';
import { GoalsAPIService } from '../api/goals.api.service';
import { GoalUtils } from '../utils/goal.utils';

@Component({
  selector: 'app-goal-dropdown',
  templateUrl: './goal-dropdown.component.html',
  styleUrls: ['./goal-dropdown.component.scss']
})
export class GoalDropdownComponent implements OnInit {
  public readonly eGoalKeyResultType = GoalKeyResultType;
  public readonly eGoalKeyResultMeasureUnitPlacement = GoalKeyResultMeasureUnitPlacement;
  public readonly eGoalUtils = GoalUtils;
  public readonly eGoalsMessages = GoalsMessages;
  public readonly eCommonMessages = CommonMessages;

  @Input() goal?: Goal;

  alignedGoal: Goal;
  alignedGoals: Goal[];

  constructor(
    private goalsAPIService: GoalsAPIService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
    this.goal = undefined;
    this.alignedGoal = undefined!;
    this.alignedGoals = [];
  }

  ngOnInit() {
    this.getAlignedGoal();
    this.getAlignedGoals();
  }

  getAlignedGoal() {
    if (this.goal && this.goal.alignment) {
      this.goalsAPIService.getGoalById(this.goal.alignment).subscribe(goal => {
        this.alignedGoal = Goal.getGoalCompletionPercentage(goal);
      });
    }
  }

  getAlignedGoals() {
    if (this.goal) {
      this.goalsAPIService.getAlignedGoals(this.goal.id).subscribe(goals => {
        this.alignedGoals = Goal.getGoalArrayCompletionPercentage(goals);
      })
    }
  }

  navigateToGoal(id: number) {
    this.router.navigate(['/goals/individual/', id], { relativeTo: this.activatedRoute })
  }
}
