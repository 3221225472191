import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { GoalsModalComponent } from '@app/goals/goals-components/goals-modal/goals-modal.component';
import { TalkingPointTemplate } from '@app/domain/one_to_one/model/talking-point-template.model';
import { TalkingPoint } from '@app/domain/one_to_one/model/talking-point.model';
import { RoleName } from '@app/models/user-role.model';
import { OneToOneAPIService } from '@app/domain/one_to_one/api/one-to-one-api.service';
import { Globals } from '@app/shared/globals/globals';
import { OneToOneMessages } from '@app/domain/one_to_one/locale/one-to-one.messages';
import { IState } from '@app/models/state/state.model';

@Component({
  selector: 'app-template-modal',
  templateUrl: './template-modal.component.html',
  styleUrls: ['./template-modal.component.scss']
})
export class TemplateModalComponent implements OnInit {
  public readonly eOneToOneMessages = OneToOneMessages;

  @Input() talkingPoints: TalkingPoint[];
  @Input() isSecondaryManager: boolean;

  @Output() onSelectTemplate: EventEmitter<TalkingPointTemplate>;

  @ViewChild('modal') modal?: GoalsModalComponent;

  templates: TalkingPointTemplate[];
  templatesFiltered: TalkingPointTemplate[];

  searchForm: FormControl; // TODO: Replace with universal filter component

  state: IState;

  constructor(
    private oneToOneAPIService: OneToOneAPIService, // TODO: Replace with business service
    public globals: Globals
  ) {
    this.isSecondaryManager = false;

    this.talkingPoints = [];
    this.templates = [];
    this.templatesFiltered = [];

    this.searchForm = this.initSearchForm();

    this.state = {
      loading: true,
      error: false,
      errorMessage: ''
    };

    this.onSelectTemplate = new EventEmitter<TalkingPointTemplate>();
  }

  ngOnInit(): void {
    this.getData();
  }

  initSearchForm(): FormControl {
    const formControl = new FormControl('', []);

    formControl.valueChanges.subscribe((val: string) => this.doSearch(val));

    return formControl;
  }

  doSearch(sarg: string) {
    if (!sarg) {
      this.templatesFiltered = this.templates;
      return;
    }

    if (sarg.length === 0) {
      this.templatesFiltered = this.templates;
      return;
    }

    this.templatesFiltered = this.templates.filter(t => t.name.toLocaleLowerCase().includes(sarg.toLocaleLowerCase()));
  }

  getData(): void {
    this.oneToOneAPIService.getTalkingPointTemplates()
      .toPromise()
      .then(templates => {
        // Only show templates that are allowed for the current user
        templates = templates.filter(t => {
          const frankliAdmin = this.globals.hasRole(RoleName.FRANKLI_ADMIN);
          const adminAllowed = t.allowAdmin && this.globals.hasRole(RoleName.ADMIN);
          const managerAllowed = t.allowManager && (this.globals.hasRole(RoleName.MANAGER) || this.isSecondaryManager);
          const employeeAllowed = t.allowEmployee && this.globals.hasRole(RoleName.USER);
          return frankliAdmin || adminAllowed || managerAllowed || employeeAllowed;
        });

        // Sort talking points in each template by their order index
        templates = templates.map(t => {
          t.talkingPoints = t.talkingPoints.sort((a, b) => a.orderIndex - b.orderIndex);
          return t;
        });

        // Sort the default talking point template to the top
        templates = templates.sort((a, b) => (a.defaultTemplate === b.defaultTemplate) ? 0 : a.defaultTemplate ? -1 : 1);

        this.templates = templates;
        this.templatesFiltered = templates;

        this.state.loading = false;
      })
      .catch(failure => this.doError(failure.message));
  }

  doError(message: string) {
    this.state.loading = false;
    this.state.error = true;
    this.state.errorMessage = message;
  }

  selectTemplate(template: TalkingPointTemplate) {
    if (!template) { return; }
    this.onSelectTemplate.emit(template);
    this.hideModal();
  }

  showModal(): void {
    if (!this.modal) { return; }
    this.modal.show();
  }

  hideModal(): void {
    if (!this.modal) { return; }
    this.modal.hide();
  }
}
