import { Component, EventEmitter, Input, Output } from '@angular/core';
import { GoalOverviewManagerPageUser } from '@app/goals/goals-components/goal-overview-manager/goal-overview-manager-page-user';
import { GoalOverviewManagerTableColumn } from '@app/goals/goals-components/goal-overview-manager/goal-overview-manager-table-column';
import { Globals } from '@app/shared/globals/globals';
import { PillType } from '@app/shared/components/pill/pill.component';
import { GoalsMessages } from '@app/goals/goals.messages';
import { TerminologyEntity } from '@app/domain/terminology/model/terminology-entity.enum';

@Component({
  selector: 'app-goal-overview-manager-row',
  templateUrl: './goal-overview-manager-row.component.html',
  styleUrls: ['./goal-overview-manager-row.component.scss'],
  host: {
    style: 'display: contents' // Used to flatten the component for the tree structure to look right
  }
})
export class GoalOverviewManagerRowComponent {

  public readonly eTableColumn = GoalOverviewManagerTableColumn;
  public readonly eTerminologyEntity = TerminologyEntity;
  public readonly eGoalsMessages = GoalsMessages;
  public readonly ePillType = PillType;

  @Input() report: GoalOverviewManagerPageUser;
  @Input() enabledColumnKeys: number[];
  @Input() depth: number;

  @Output() onLoadChildren: EventEmitter<GoalOverviewManagerPageUser>;
  @Output() onOpenUserGoals: EventEmitter<GoalOverviewManagerPageUser>;
  @Output() onOpenUserGoalCreate: EventEmitter<GoalOverviewManagerPageUser>;

  constructor(
    public globals: Globals
  ) {
    this.report = undefined;

    this.enabledColumnKeys = [];

    this.depth = 0;

    this.onLoadChildren = new EventEmitter<GoalOverviewManagerPageUser>();
    this.onOpenUserGoals = new EventEmitter<GoalOverviewManagerPageUser>();
    this.onOpenUserGoalCreate = new EventEmitter<GoalOverviewManagerPageUser>();
  }

  loadChildren(pageUser: GoalOverviewManagerPageUser): void {
    this.onLoadChildren.emit(pageUser);
  }

  openUserGoals(pageUser: GoalOverviewManagerPageUser): void {
    this.onOpenUserGoals.emit(pageUser);
  }
  
  openUserGoalCreate(pageUser: GoalOverviewManagerPageUser): void {
    this.onOpenUserGoalCreate.emit(pageUser);
  }
}
