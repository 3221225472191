import { ReviewAnalytics } from '@app/models/analytics/review-analytics.model';
import { SurveyQuestionType } from '@app/models/survey-question-type.enum';

export const mockReviewAnalytics: ReviewAnalytics = {
  assessmentGroup: {
    [SurveyQuestionType.ONE_TO_THREE]: {
      values: {
        1: {
          count: 0,
          calibrationCount: 0
        },
        2: {
          count: 0,
          calibrationCount: 0
        },
        3: {
          count: 0,
          calibrationCount: 0
        }
      }
    },
    [SurveyQuestionType.ONE_TO_FOUR]: {
      values: {
        1: {
          count: 0,
          calibrationCount: 0
        },
        2: {
          count: 0,
          calibrationCount: 0
        },
        3: {
          count: 0,
          calibrationCount: 0
        },
        4: {
          count: 0,
          calibrationCount: 0
        }
      }
    },
    [SurveyQuestionType.ONE_TO_FIVE]: {
      values: {
        1: {
          count: 0,
          calibrationCount: 0
        },
        2: {
          count: 0,
          calibrationCount: 0
        },
        3: {
          count: 2,
          calibrationCount: 0
        },
        4: {
          count: 4,
          calibrationCount: 0
        },
        5: {
          count: 5,
          calibrationCount: 0
        }
      }
    }
  },
  assessmentScoreDist: {
    [SurveyQuestionType.ONE_TO_THREE]: {
      departments: {},
      sites: {},
      managers: {}
    },
    [SurveyQuestionType.ONE_TO_FOUR]: {
      departments: {},
      sites: {},
      managers: {}
    },
    [SurveyQuestionType.ONE_TO_FIVE]: {
      departments: {
        92: {
          entityName: 'People & Culture',
          reflectionAverageScore: 5,
          managerAverageScore: 5,
          upwardAverageScore: 5,
          varianceBetweenScores: 0
        },
        93: {
          entityName: 'Projects',
          reflectionAverageScore: 4.5,
          managerAverageScore: 5,
          upwardAverageScore: 5,
          varianceBetweenScores: 0.5,
        },
        261: {
          entityName: 'Finance',
          reflectionAverageScore: 4.5,
          managerAverageScore: 4,
          upwardAverageScore: 5,
          varianceBetweenScores: 0.5,
        },
        263: {
          entityName: 'Business Development',
          reflectionAverageScore: 4,
          managerAverageScore: 5,
          upwardAverageScore: 5,
          varianceBetweenScores: 1,
        },
        298: {
          entityName: 'Interior Design Team',
          reflectionAverageScore: 4,
          managerAverageScore: 4,
          upwardAverageScore: 5,
          varianceBetweenScores: 0,
        },
        310: {
          entityName: 'Facilities',
          reflectionAverageScore: 4,
          managerAverageScore: 4,
          upwardAverageScore: 5,
          varianceBetweenScores: 0,
        },
        311: {
          entityName: 'Customer Experience',
          reflectionAverageScore: 4,
          managerAverageScore: 4,
          upwardAverageScore: 5,
          varianceBetweenScores: 0,
        },
        351: {
          entityName: 'Learning & Development',
          reflectionAverageScore: 3,
          managerAverageScore: 3,
          upwardAverageScore: 5,
          varianceBetweenScores: 0,
        }
      },
      sites: {
        90: {
          entityName: 'Dublin',
          reflectionAverageScore: 5,
          managerAverageScore: 5,
          upwardAverageScore: 5,
          varianceBetweenScores: 0,
        },
        93: {
          entityName: 'London Office',
          reflectionAverageScore: 4,
          managerAverageScore: 4.4,
          upwardAverageScore: 5,
          varianceBetweenScores: 0.40000000000000036,
        },
        95: {
          entityName: 'Working remotely',
          reflectionAverageScore: 4,
          managerAverageScore: 3,
          upwardAverageScore: 5,
          varianceBetweenScores: 1,
        },
        422: {
          entityName: 'Toronto Office',
          reflectionAverageScore: 4.5,
          managerAverageScore: 4.5,
          upwardAverageScore: 5,
          varianceBetweenScores: 0,
        },
        725: {
          entityName: 'Fairmont, USA',
          reflectionAverageScore: 4,
          managerAverageScore: 4,
          upwardAverageScore: 5,
          varianceBetweenScores: 0,
        }
      },
      managers: {}
    }
  },
  participation: {
    totalNoResponse: 23,
    peopleNotInCycles: [
      {
        id: 298,
        managerId: 292,
        firstName: 'Noah',
        lastName: 'Ryan',
        email: 'noah-test-13@frankli.io',
        imageUrl: '../../assets/img/user-images/male-2.jpeg'
      },
      {
        id: 302,
        managerId: 302,
        firstName: 'Faye',
        lastName: 'Gallagher',
        email: 'faye-test-13@frankli.io',
        imageUrl: '../../assets/img/user-images/female-6.jpeg'
      },
      {
        id: 305,
        managerId: 306,
        firstName: 'Jamie',
        lastName: 'Sweeney',
        email: 'jamie-test-13@frankli.io',
        imageUrl: 'https://franklibetabucket.s3-eu-west-1.amazonaws.com/305/7c31e0b9-d2db-4271-b202-118b4de6047a.jpeg'
      },
      {
        id: 313,
        managerId: 302,
        firstName: 'Lyndsie',
        lastName: 'Britney',
        email: 'lyndsie-test-13@frankli.io',
        imageUrl: '../../assets/img/user-images/female-12.jpeg'
      },
      {
        id: 316,
        managerId: 303,
        firstName: 'Sabrina',
        lastName: 'Marzellano',
        email: 'sabrina-test-13@frankli.io',
        imageUrl: '../../assets/img/user-images/female-15.jpeg'
      },
      {
        id: 318,
        managerId: 301,
        firstName: 'Bobb',
        lastName: 'Higgens',
        email: 'bobby-test-13@frankli.io/sza',
        imageUrl: 'https://franklibetabucket.s3-eu-west-1.amazonaws.com/318/b8a2aa90-87dd-417b-9a3b-82c8ac588953.jpg'
      },
      {
        id: 1543,
        managerId: 292,
        firstName: 'Tyler',
        lastName: 'James',
        email: 'ikzanittrrkfmwmlmc@ttirv.net',
        imageUrl: 'https://franklibetabucket.s3-eu-west-1.amazonaws.com/1543/9a41884e-02fd-45dd-a88e-5f0ea3c0c6fc.jpg'
      },
      {
        id: 1644,
        managerId: 302,
        firstName: 'Sean',
        lastName: 'Lyons',
        email: 'oiy12154@zzrgg.com',
        imageUrl: 'https://franklibetabucket.s3-eu-west-1.amazonaws.com/1644/36cb2fb3-3aa7-4292-aee3-7cee41cd162f.jpeg'
      }
    ],
    totalResponded: 14,
    reviewsCompleted: 13,
    reviewsShared: 13,
    peerReviewsSent: 0,
    peerReviewsCompleted: 0,
    upwardReviewsSent: 0,
    upwardReviewsCompleted: 0,
    peopleInCycles: 37
  }
};

export const mockReviewAnalyticsAlternate: ReviewAnalytics = {
  assessmentGroup: {
    [SurveyQuestionType.ONE_TO_THREE]: {
      values: {
        1: {
          count: 2,
          calibrationCount: -2
        },
        2: {
          count: 6,
          calibrationCount: 3
        },
        3: {
          count: 2,
          calibrationCount: 0
        }
      }
    },
    [SurveyQuestionType.ONE_TO_FOUR]: {
      values: {
        1: {
          count: 1,
          calibrationCount: 0
        },
        2: {
          count: 6,
          calibrationCount: 2
        },
        3: {
          count: 8,
          calibrationCount: 3
        },
        4: {
          count: 3,
          calibrationCount: -1
        }
      }
    },
    [SurveyQuestionType.ONE_TO_FIVE]: {
      values: {
        1: {
          count: 1,
          calibrationCount: 0
        },
        2: {
          count: 3,
          calibrationCount: 2
        },
        3: {
          count: 6,
          calibrationCount: 1
        },
        4: {
          count: 3,
          calibrationCount: 0
        },
        5: {
          count: 1,
          calibrationCount: -3
        }
      }
    }
  },
  assessmentScoreDist: {
    [SurveyQuestionType.ONE_TO_THREE]: {
      departments: {},
      sites: {},
      managers: {}
    },
    [SurveyQuestionType.ONE_TO_FOUR]: {
      departments: {},
      sites: {},
      managers: {}
    },
    [SurveyQuestionType.ONE_TO_FIVE]: {
      departments: {
        92: {
          entityName: 'People & Culture',
          reflectionAverageScore: 5,
          managerAverageScore: 5,
          upwardAverageScore: 5,
          varianceBetweenScores: 0
        },
        93: {
          entityName: 'Projects',
          reflectionAverageScore: 4.5,
          managerAverageScore: 5,
          upwardAverageScore: 5,
          varianceBetweenScores: 0.5,
        },
        261: {
          entityName: 'Finance',
          reflectionAverageScore: 4.5,
          managerAverageScore: 4,
          upwardAverageScore: 5,
          varianceBetweenScores: 0.5,
        },
        263: {
          entityName: 'Business Development',
          reflectionAverageScore: 4,
          managerAverageScore: 5,
          upwardAverageScore: 5,
          varianceBetweenScores: 1,
        },
        298: {
          entityName: 'Interior Design Team',
          reflectionAverageScore: 4,
          managerAverageScore: 4,
          upwardAverageScore: 5,
          varianceBetweenScores: 0,
        },
        310: {
          entityName: 'Facilities',
          reflectionAverageScore: 4,
          managerAverageScore: 4,
          upwardAverageScore: 5,
          varianceBetweenScores: 0,
        },
        311: {
          entityName: 'Customer Experience',
          reflectionAverageScore: 4,
          managerAverageScore: 4,
          upwardAverageScore: 5,
          varianceBetweenScores: 0,
        },
        351: {
          entityName: 'Learning & Development',
          reflectionAverageScore: 3,
          managerAverageScore: 3,
          upwardAverageScore: 5,
          varianceBetweenScores: 0,
        }
      },
      sites: {
        90: {
          entityName: 'Dublin',
          reflectionAverageScore: 5,
          managerAverageScore: 5,
          upwardAverageScore: 5,
          varianceBetweenScores: 0,
        },
        93: {
          entityName: 'London Office',
          reflectionAverageScore: 4,
          managerAverageScore: 4.4,
          upwardAverageScore: 5,
          varianceBetweenScores: 0.40000000000000036,
        },
        95: {
          entityName: 'Working remotely',
          reflectionAverageScore: 4,
          managerAverageScore: 3,
          upwardAverageScore: 5,
          varianceBetweenScores: 1,
        },
        422: {
          entityName: 'Toronto Office',
          reflectionAverageScore: 4.5,
          managerAverageScore: 4.5,
          upwardAverageScore: 5,
          varianceBetweenScores: 0,
        },
        725: {
          entityName: 'Fairmont, USA',
          reflectionAverageScore: 4,
          managerAverageScore: 4,
          upwardAverageScore: 5,
          varianceBetweenScores: 0,
        }
      },
      managers: {}
    }
  },
  participation: {
    totalNoResponse: 25,
    peopleNotInCycles: [
      {
        id: 298,
        managerId: 292,
        firstName: 'Noah',
        lastName: 'Ryan',
        email: 'noah-test-13@frankli.io',
        imageUrl: '../../assets/img/user-images/male-2.jpeg'
      },
      {
        id: 302,
        managerId: 302,
        firstName: 'Faye',
        lastName: 'Gallagher',
        email: 'faye-test-13@frankli.io',
        imageUrl: '../../assets/img/user-images/female-6.jpeg'
      },
      {
        id: 305,
        managerId: 306,
        firstName: 'Jamie',
        lastName: 'Sweeney',
        email: 'jamie-test-13@frankli.io',
        imageUrl: 'https://franklibetabucket.s3-eu-west-1.amazonaws.com/305/7c31e0b9-d2db-4271-b202-118b4de6047a.jpeg'
      },
      {
        id: 313,
        managerId: 302,
        firstName: 'Lyndsie',
        lastName: 'Britney',
        email: 'lyndsie-test-13@frankli.io',
        imageUrl: '../../assets/img/user-images/female-12.jpeg'
      },
      {
        id: 316,
        managerId: 303,
        firstName: 'Sabrina',
        lastName: 'Marzellano',
        email: 'sabrina-test-13@frankli.io',
        imageUrl: '../../assets/img/user-images/female-15.jpeg'
      },
      {
        id: 318,
        managerId: 301,
        firstName: 'Bobb',
        lastName: 'Higgens',
        email: 'bobby-test-13@frankli.io/sza',
        imageUrl: 'https://franklibetabucket.s3-eu-west-1.amazonaws.com/318/b8a2aa90-87dd-417b-9a3b-82c8ac588953.jpg'
      }
    ],
    totalResponded: 10,
    reviewsCompleted: 8,
    reviewsShared: 5,
    peerReviewsSent: 0,
    peerReviewsCompleted: 0,
    upwardReviewsSent: 0,
    upwardReviewsCompleted: 0,
    peopleInCycles: 30
  }
};
