import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { VincereTokenServerSide } from '@app/models/integrations/vincere/vincere-token-server-side.model';
import { VincereToken } from '@app/models/integrations/vincere/vincere-token.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class VincereAPIService {

  private readonly BASE_URL: string = 'api/vincere';

  constructor(private http: HttpClient) {
  }

  getToken(): Observable<VincereToken | null> {
    const url = `${this.BASE_URL}/token`;
    return this.http.get<VincereTokenServerSide | null>(url).pipe(
      map(vincereAPIKey => vincereAPIKey === null ? null : new VincereToken(vincereAPIKey))
    );
  }

  addApiKey(token: VincereTokenServerSide): Observable<VincereToken> {
    const url = `${this.BASE_URL}/api-key`;
    return this.http.post<VincereTokenServerSide>(url, token).pipe(map(vincereAPIKey => new VincereToken(vincereAPIKey)));
  }

  clientToken(clientToken: string): Observable<null> {
    const url = `${this.BASE_URL}/client-token`;
    return this.http.post<null>(url, clientToken);
  }

  removeApiKey(): Observable<null> {
    const url = `${this.BASE_URL}/token`;
    return this.http.delete<null>(url);
  }

}
