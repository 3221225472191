export const BrowseRoleMessages = {
  BROWSE_ROLES: 'browse-role.browse-roles',
  BROWSE_ROLES_DESCRIPTION: 'browse-role.browse-roles-description',
  BROWSE_ROLES_DESCRIPTION_SHORT: 'browse-role.browse-roles-description-short',
  PINNED: 'browse-role.pinned',
  IS_PINNED: 'browse-role.is-pinned',
  NOT_PINNED: 'browse-role.not-pinned',
  ON_PATHWAY: 'browse-role.on-pathway',
  IS_ON_PATHWAY: 'browse-role.is-on-pathway',
  NOT_ON_PATHWAY: 'browse-role.not-on-pathway',
  TOOLTIP_PIN: 'browse-role.tooltip-pin',
  TOOLTIP_UNPIN: 'browse-role.tooltip-unpin',
  SUCCESS_PIN: 'browse-role.success-pin',
  SUCCESS_UNPIN: 'browse-role.success-unpin',
  HEADER_DETAILS: 'browse-role.header-details',
  SCHEDULE_A_ONE_TO_ONE: 'browse-role.schedule-a-one-to-one',
  PINNED_ROLES: 'browse-role.pinned-roles',
  EMPTY_STATE_PINNED: 'browse-role.empty-state-pinned'
};
