<!-- Filter and Column Toggle -->
<div class="p-bottom-20 p-top-20 p-left-20 p-right-20">
  <div class="row m-left-0 m-right-0">
    <div class="col-universal-filter">
      <app-dynamic-filter [filterCategories]="filterCategories" [searchProperties]="searchProperties" [selectedFilters]="selectedFilters" [hiddenCategoriesClientside]="hiddenFiltersClientside" (filtersChanged)="updateFilter($event)"></app-dynamic-filter>
    </div>
    <div class="col-column-toggle">
      <app-column-toggle-new [columnInfos]="columnInfo" [alwaysEnabled]="alwaysEnabledColumns" [startDisabled]="startDisabledColumns" (onColumnToggled)="updateHiddenFilters()" #columnToggle></app-column-toggle-new>
    </div>
  </div>
</div>

<div *ngIf="searchState.isSuccessful()">
  <ng-container *ngIf="goalsFiltered.length > 0; else stateEmpty">
    <div class="scroll-x">
      <table width="100%">
        <!-- Table headings -->
        <tr class="frankli-table frankli-table-header frankli-table-header-large noselect">
          <!-- State ribbon -->
          <th width="1em" class="goal-status-col" *ngIf="columnToggle.getColumnEnabled(eTableColumn.STATUS)"></th>

          <!-- Dropdown -->
          <th width="1em" class="col-dropdown-indicator"></th>

          <!-- Owners -->
          <th width="1em" class="text-left" *ngIf="columnToggle.getColumnEnabled(eTableColumn.OWNERS)" translate>
            {{eGoalsMessages.OWNERS}}
          </th>

          <!-- Priority -->
          <th width="1em" class="col-priority-display" (click)="doSort(eTableColumn.PRIORITY)"
              [ngClass]="(sort.column === eTableColumn.PRIORITY) ? (sort.ascending ? 'sorting-ascending' : 'sorting-descending') : 'sorting'"
              *ngIf="columnToggle.getColumnEnabled(eTableColumn.PRIORITY) && globals.hasFeature(eFeature.GOAL_PRIORITY)" translate>
            {{eGoalsMessages.PRIORITY}}
          </th>

          <!-- Type -->
          <th width="1em" class="text-center" (click)="doSort(eTableColumn.TYPE)"
              [ngClass]="(sort.column === eTableColumn.TYPE) ? (sort.ascending ? 'sorting-ascending' : 'sorting-descending') : 'sorting'"
              *ngIf="columnToggle.getColumnEnabled(eTableColumn.TYPE)" translate>
              {{eGoalsMessages.TYPE}}
          </th>

          <!-- Goal Title -->
          <th class="text-left" (click)="doSort(eTableColumn.TITLE)"
              [ngClass]="(sort.column === eTableColumn.TITLE) ? (sort.ascending ? 'sorting-ascending' : 'sorting-descending') : 'sorting'"
              *ngIf="columnToggle.getColumnEnabled(eTableColumn.TITLE)" translate>
              {{eGoalsMessages.TITLE}}
          </th>

          <!-- Department -->
          <th width="1em" class="text-left" (click)="doSort(eTableColumn.DEPARTMENT)"
              [ngClass]="(sort.column === eTableColumn.DEPARTMENT) ? (sort.ascending ? 'sorting-ascending' : 'sorting-descending') : 'sorting'"
              *ngIf="columnToggle.getColumnEnabled(eTableColumn.DEPARTMENT)" translate>
            {{globals.getTerminology(eTerminologyEntity.DEPARTMENT) | titlecase}}
          </th>

          <!-- Site -->
          <th width="1em" class="text-left" (click)="doSort(eTableColumn.SITE)"
              [ngClass]="(sort.column === eTableColumn.SITE) ? (sort.ascending ? 'sorting-ascending' : 'sorting-descending') : 'sorting'"
              *ngIf="columnToggle.getColumnEnabled(eTableColumn.SITE)" translate>
              {{globals.getTerminology(eTerminologyEntity.SITE) | titlecase}}
          </th>

          <!-- Progress bar -->
          <th width="200px" class="text-right" *ngIf="columnToggle.getColumnEnabled(eTableColumn.PROGRESS)" translate>
              {{eGoalsMessages.PROGRESS}}
          </th>

          <!-- End Date -->
            <th width="1em" class="text-right" (click)="doSort(eTableColumn.DUE_DATE)"
                [ngClass]="(sort.column === eTableColumn.DUE_DATE) ? (sort.ascending ? 'sorting-ascending' : 'sorting-descending') : 'sorting'"
                *ngIf="columnToggle.getColumnEnabled(eTableColumn.DUE_DATE)" translate>
                {{eGoalsMessages.DUE_DATE}}
            </th>
        </tr>

        <!-- Table contents -->
        <ng-container *ngFor="let goal of goalsFiltered; index as i; trackBy: trackGoalsDisplayed">
          <!-- Primary goal info -->
          <!-- [contextmenu]="getContextMenuOptions(goal)" [contextmenuwidth]="150" -->
          <tr class="frankli-table frankli-table-hover frankli-table-single-line">

            <!-- Status highlight -->
            <td class="p-relative goal-status-col" *ngIf="columnToggle.getColumnEnabled(eTableColumn.STATUS)">
              <div class="goal-status-highlight" [title]="eGoalUtils.getStatusTitle(goal) | translate" [ngClass]="eGoalUtils.getStatusHighlight(goal)"></div>
            </td>

            <!-- Dropdown -->
            <td class="col-dropdown-indicator" (click)="toggleDropdown(i)">
              <app-table-dropdown-toggle [opened]="opened[i]"></app-table-dropdown-toggle>
            </td>

            <!-- Owners -->
            <td *ngIf="columnToggle.getColumnEnabled(eTableColumn.OWNERS)">
              <app-person-picture-list [users]="goal.owners" [maxListed]="5"></app-person-picture-list>
            </td>

            <!-- Priority -->
            <td class="col-priority-display" *ngIf="columnToggle.getColumnEnabled(eTableColumn.PRIORITY) && globals.hasFeature(eFeature.GOAL_PRIORITY)" (click)="navigateToGoal(goal.id)">
              <app-goal-priority-display [priority]="goal.priority"></app-goal-priority-display>
            </td>

            <!-- Type -->
            <td class="text-center" *ngIf="columnToggle.getColumnEnabled(eTableColumn.TYPE)" (click)="navigateToGoal(goal.id)">
              <app-goal-type-display [type]="goal.type"></app-goal-type-display>
            </td>

            <!-- Title -->
            <td *ngIf="columnToggle.getColumnEnabled(eTableColumn.TITLE)" (click)="navigateToGoal(goal.id)">
              <span class="wrap-title">{{goal.title}}</span>
            </td>

            <!-- Department -->
            <td *ngIf="columnToggle.getColumnEnabled(eTableColumn.DEPARTMENT)" (click)="navigateToGoal(goal.id)">{{goal.department ? goal.department.name : 'N/A'}}</td>

            <!-- Site -->
            <td *ngIf="columnToggle.getColumnEnabled(eTableColumn.SITE)" (click)="navigateToGoal(goal.id)">{{goal.officeLocation ? goal.officeLocation.name : 'N/A'}}</td>

            <!-- Progress bar -->
            <td *ngIf="columnToggle.getColumnEnabled(eTableColumn.PROGRESS)" (click)="navigateToGoal(goal.id)">
              <app-progress-bar [currentValue]="goal.completionPercentage" [showTextOnHover]="false"></app-progress-bar>
            </td>

            <!-- Due Date -->
            <ng-container *ngIf="columnToggle.getColumnEnabled(eTableColumn.DUE_DATE)">
              <td class="area-due-date" [class.is-overdue]="eDateUtils.getDaysRemainingTotal(goal.endDate) < 0 && goal.complete === false && goal.archived === false" (click)="navigateToGoal(goal.id)">
                <div class="date-line-height">
                  <span>{{goal.endDate | momentPipe: 'Do MMMM YYYY'}}</span>
                  <span *ngIf="goal.complete === false && goal.archived === false">
                    <br/>
                    <span>({{goal.dueDuration}})</span>
                  </span>
                </div>
              </td>
            </ng-container>
          </tr>

          <!-- Dropdown contents -->
          <tr *ngIf="opened[i]">
            <td colspan="50">
              <app-goal-dropdown [goal]="goal"></app-goal-dropdown>
            </td>
          </tr>
        </ng-container>
      </table>
    </div>
    <app-pagination-server *ngIf="searchState.isSuccessful()" [page]="goalsPage!" [pageSize]="pagingParams.pageSize" (pageSizeChanged)="changePageSize($event)" (pageNumberChanged)="changePageNumber($event)"></app-pagination-server>
  </ng-container>

  <ng-template #stateEmpty>
    <app-empty-state [height]="'300px'" [message]="eGoalsMessages.EMPTY_STATE_SEARCH" [primaryButtonText]="eGoalsMessages.CREATE_A_GOAL" [primaryButtonShown]="canCreateGoal ? true : false" (primaryButtonPressed)="showGoalCreate()"></app-empty-state>
  </ng-template>
</div>

<ng-container *ngIf="searchState.isLoading()">
  <app-loading-state [showMessage]="false" [height]="'300px'"></app-loading-state>
</ng-container>

