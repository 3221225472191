import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Idea } from '@app/models/feedback/idea.model';
import { IState } from '@app/models/state/state.model';
import { IdeaAPIService } from '@app/shared/api/idea.api.service';
import { PaginationNewComponent } from '@app/shared/pagination/pagination-new/pagination-new.component';

type TabEntity = Idea;

@Component({
  selector: 'app-information-sidebar-feedback-ideas',
  templateUrl: './information-sidebar-feedback-ideas.component.html',
  styleUrls: ['./information-sidebar-feedback-ideas.component.scss']
})
export class InformationSidebarFeedbackIdeasComponent implements OnInit {
  
  @ViewChild('pagination') pagination?: PaginationNewComponent;

  @Input() userIds: number[];

  state: IState;
  data: {
    master: TabEntity[];
    filtered: TabEntity[];
    display: TabEntity[];
  };

  constructor(
    private ideaApiService: IdeaAPIService
  ) {
    this.userIds = [];

    this.state = {
      loading: true,
      error: false,
      errorMessage: ''
    };

    this.data = {
      master: [],
      filtered: [],
      display: []
    };
  }

  ngOnInit(): void {
    this.getData();
  }
  
  getData(): void {
    // // If the other user is managing the schedule, get all of your own ideas
    // if (schedule.manager.id === userOther.id) {
    //   this.ideaAPIService.getIdeasSubmitted();
    // }

    // // If you are the other user's manager or secondary manager, get all of their ideas
    // if ((this.user.id === userOther.managerId) || (secondaryManager && secondaryManager.feedback)) {
    //   this.ideaAPIService.getIdeaSubmittedByUserId(this.userOther.id);
    // }

    // Otherwise nothing
    this.ideaApiService.getSidebarIdeas(this.userIds)
      .subscribe(data => {
        data = this.sortData(data);
        this.populateData(data);
        this.state.loading = false;
      });
  }

  sortData(data: TabEntity[]): TabEntity[] {
    // Order by timestamp
    return data.sort((a, b) => {
      const dateA = new Date(a.timestamp);
      const dateB = new Date(b.timestamp);
      return dateB.getTime() - dateA.getTime();
    });
  }

  populateData(data?: TabEntity[]): void {
    if (!data) { data = this.data.master; }
    this.data.master = data;
    this.data.filtered = data;

    // this.universalFilterData.searchProps = ['name'];
    // this.universalFilterData.filterOptions = data.map(i => this.getFilterOptionsForBrowseRole(i));

    this.refreshPagination();
  }

  refreshPagination(): void {
    if (this.pagination) {
      this.pagination.update();
    }
  }
}
