import {Component, Input, OnInit} from '@angular/core';
import {EvaluationCycleModule} from '@app/models/evaluation/evaluation-cycle-module.model';
import {EvaluationModuleState} from '@app/models/evaluation/evaluation-module-state.model';
import {EvaluationModuleType} from '@app/models/evaluation/evaluation-module-type.model';
import { ReviewSubjectAccessType } from '@app/models/evaluation/review-subject-access-type.model';
import { EvaluationUtils } from '@app/shared/utils/evaluation.utils';
import { PillType } from '../../pill/pill.component';

@Component({
  selector: 'app-cycle-step-display',
  templateUrl: './cycle-step-display.component.html',
  styleUrls: ['./cycle-step-display.component.scss']
})
export class CycleStepDisplayComponent implements OnInit {
  public readonly eEvaluationModuleState = EvaluationModuleState;
  public readonly eEvaluationModuleType = EvaluationModuleType;
  public readonly eReviewSubjectAccessType = ReviewSubjectAccessType;
  public readonly eEvaluationUtils = EvaluationUtils;
  public readonly ePillType = PillType;

  @Input() modules: EvaluationCycleModule[];
  @Input() calibrationEnabled: boolean;
  @Input() isOpenCycle: boolean;

  constructor() {
  }

  ngOnInit(): void {
  }

}
