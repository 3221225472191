import { ISODateString } from '@app/shared/utils/date-string.util';
import moment from 'moment';
import { PollFrequency } from './poll-frequency.enum';
import { PollQuestion } from './poll-question.model';

export interface PollReceivedServerSideDto {
  seriesId: number;
  pollId: number;
  name: string;
  description: string | null;
  timezone: string; // TODO(shane): add to backend model
  publishedDate: ISODateString;
  expirationDate: ISODateString;
  expired: boolean;
  questions: Array<PollQuestion>;
  creatorId: number | null;
  frequency: PollFrequency;
  complete: boolean;
  totalRecipients: number;
}

export class PollReceivedDto {
  seriesId: number;
  pollId: number;
  name: string;
  description: string | null;
  timezone: string;
  publishedDate: Date;
  expirationDate: Date;
  expired: boolean;
  questions: Array<PollQuestion>;
  creatorId: number | null; // null means creator not shown
  frequency: PollFrequency;
  complete: boolean;
  totalRecipients: number;

  constructor(pollReceivedServerSideDto: PollReceivedServerSideDto) {
    this.seriesId = pollReceivedServerSideDto.seriesId;
    this.pollId = pollReceivedServerSideDto.pollId;
    this.name = pollReceivedServerSideDto.name;
    this.description = pollReceivedServerSideDto.description;
    this.timezone = pollReceivedServerSideDto.timezone;
    this.publishedDate = moment.utc(pollReceivedServerSideDto.publishedDate).toDate();
    this.expirationDate = moment.utc(pollReceivedServerSideDto.expirationDate).toDate();
    this.expired = pollReceivedServerSideDto.expired;
    this.questions = pollReceivedServerSideDto.questions;
    this.creatorId = pollReceivedServerSideDto.creatorId;
    this.frequency = pollReceivedServerSideDto.frequency;
    this.complete = pollReceivedServerSideDto.complete;
    this.totalRecipients = pollReceivedServerSideDto.totalRecipients;
  }

}