import { Component, Input, OnInit } from '@angular/core';
import { Globals } from '@app/shared/globals/globals';

interface ChartData {
  value: string; // Raw value
  text: string; // Formatted value
  label: string; // Strongly Disagree, Neutral, Agree, etc.
  width?: number; // Relative width as percentage
  percentOfTotal?: string;
  widthRounded?: string; // Width but rounded
  color?: string; // Bar color
  colorAlt?: string; // Background color
  styleClass?: string; // Class used
}

interface WidthData {
  value: string;
  text: string;
  label: string;
  percentOfTotal: string;
  customLabel?: string;
}

interface WidthDataGroup {
  label: string;
  customLabel?: string;
  value: string;
  text: string;
  percentOfTotal: string;
  children: WidthData[];
}

const MIN_BAR_WIDTH = 0.9;

@Component({
  selector: 'app-one-to-ten-reporting-distribution-chart',
  templateUrl: './one-to-ten-reporting-distribution-chart.component.html',
  styleUrls: ['./one-to-ten-reporting-distribution-chart.component.scss']
})
export class OneToTenReportingDistributionChartComponent implements OnInit {
  @Input() data?: WidthData[];
  @Input() numberOfResponses!: number;
  @Input() isENPS?: boolean;

  dataParsed: ChartData[];
  labels = [];
  customLabels = [];

  constructor(
    public globals: Globals
  ) {
    this.dataParsed = [];
    this.numberOfResponses = 0;
    this.isENPS = true;
  }

  ngOnInit() {
    if (!this.numberOfResponses) {
      this.numberOfResponses = 0;
    }

    this.dataParsed = this.parseData(this.data);
    this.labels = ['N/A', 'Not likely', 'Not likely', 'Quite unlikely', 'Neutral', 'Neutral', 'Neutral', 'Likely', 'Quite likely', 'Strongly likely', 'Very likely'];
  }

  parseData(widths?: WidthData[]): ChartData[] {
    if (!widths) {
      return [];
    }

    const dataUsing = this.parseWidthsToChartData(widths);

    return dataUsing;
  }

  parseWidthsToChartData(widths: WidthData[]): ChartData[] {
    
    if (!this.isENPS)
      this.reparseDataForNonENPS(widths);
    
    this.getCustomLabels(widths);
    
    const groups = this.groupByLabels(widths);
    const emptyCells = this.getChartNoResponses(groups);
    return groups.map(d => {
      const width = this.getChartWidth(+d.value, this.numberOfResponses, emptyCells);
      const output: ChartData = {
        value: d.value, // Raw value
        text: d.text, // Formatted value
        label: this.isENPS ? d.label : this.changeLabelsForNonENPS(d.text, d.label),
        percentOfTotal: ((d.percentOfTotal ? +d.percentOfTotal : 0).toFixed(0)),
        width: width,
        widthRounded: this.getChartWidthRounded(width),
        color: this.getChartColor(d.label),
        colorAlt: this.getChartColorAlt(d.label),
        styleClass: this.getStyleClass(d.label)
      };
      return output;
    });
  }

  reparseDataForNonENPS(groups: WidthData[]){
    const array1 = groups.filter(obj => parseInt(obj.text) >= 1 && parseInt(obj.text) <= 3);
    const array2 = groups.filter(obj => parseInt(obj.text) >= 4 && parseInt(obj.text) <= 7);
    const array3 = groups.filter(obj => parseInt(obj.text) >= 8 && parseInt(obj.text) <= 10);

    array2.forEach(obj => {
      obj.label = 'Neutral';
    });

    array3.forEach(obj => {
      obj.label = 'Promoter';
    });
      
    return [array1, array2, array3];
  }

  // Get all the unique labels for the chart
  getCustomLabels(widths: WidthData[]) {
    widths.forEach(x => {
      if (!this.customLabels.includes(x.customLabel)) {
        this.customLabels.push(x.customLabel);
      }
    });
  }

  changeLabelsForNonENPS(text: string, label: string): string{
    
    const i = parseInt(text);
    
    if (i < 4 && label.includes('Detractor'))
      return this.customLabels[1];
    else if (i > 6 && label.includes('Promoter'))
      return this.customLabels[3];
    else
      return this.customLabels[2];
  }

  groupByLabels(widths: WidthData[]): WidthDataGroup[] {
    const detractors: WidthData[] = [];
    const neutrals: WidthData[] = [];
    const promoters: WidthData[] = [];
    let totalValue = 0;
    
    widths.forEach(w => {
      totalValue += (+w.value);
      switch (w.label) {
        case 'Promoter':
          promoters.push(w);
          break;
        case 'Neutral':
          neutrals.push(w);
          break;
        case 'Detractor':
          detractors.push(w);
          break;
      }
    });

    const detractorsParsed = this.parseGroupSingle(detractors, totalValue);
    const neutralsParsed = this.parseGroupSingle(neutrals, totalValue);
    const promotersParsed = this.parseGroupSingle(promoters, totalValue);

    return [
      detractorsParsed,
      neutralsParsed,
      promotersParsed
    ];
  }

  parseGroupSingle(widths: WidthData[], total: number): WidthDataGroup {
    const output: WidthDataGroup = {
      label: '',
      customLabel: undefined,
      text: '',
      percentOfTotal: '',
      value: '',
      children: []
    };

    let totalText = '';
    let totalPercent = 0;
    let totalValue = 0;

    widths.forEach((child, index) => {
      if (index === 0) {
        output.label = child.label,
        output.customLabel = child.customLabel,
        output.text = child.text;
      }
      
      totalText += (' ' + child.text);
      totalPercent += (+child.percentOfTotal);
      totalValue += (+child.value);

      output.children.push(child);
    });

    output.text = totalText;
    output.value = totalValue.toString();
    output.percentOfTotal = totalPercent.toString();
 
    return output;
  }

  getChartMaxValue(data: ChartData[]): number {
    let maxValue = 0;

    data.forEach(d => {
      if (+d.value > maxValue) {
        maxValue = +d.value;
      }
    });

    return maxValue;
  }

  getChartNoResponses(chartData: any[]): number {
    let noResponses = 0;

    chartData.forEach(d => {
      if (d.value === '0') {
        noResponses++;
      }
    });

    return noResponses;
  }


  getChartColor(label: string): string {
    switch (label) {
      // case 'Strongly Agree':
      //   return '#008168';
      case 'Promoter':
        return '#30747F';
      case 'Neutral':
        return '#FFC200';
      case 'Detractor':
        return '#FB946E';
      // case 'Strongly Disagree':
      //   return '#d0352c';
      case 'No Answer':
        return 'lightgrey';
      default:
        return '#AAAAAA';
    }
  }

  getChartColorAlt(label: string): string {
    switch (label) {
      // case 'Strongly Agree':
      //   return '#D8ECE9';
      case 'Promoter':
        return '#D8ECE9';
      case 'Neutral':
        return '#FEF5E2';
      case 'Detractor':
        return '#FFEEF2';
      // case 'Strongly Disagree':
      //   return '#FFEEF2';
      case 'No Answer':
        return '#FFEEF2';
      default:
        return '#EEEEEE';
    }
  }

  getStyleClass(label: string): string {
    switch (label) {
      // case 'Strongly Agree':
      //   return 'bar-strongly-agree';
      case 'Promoter':
        return 'bar-agree';
      case 'Neutral':
        return 'bar-neutral';
      case 'Detractor':
        return 'bar-disagree';
      // case 'Strongly Disagree':
      //   return 'bar-strongly-disagree';
      case 'No Answer':
        return 'bar-no-answer';
      default:
        return '';
    }
  }

  getChartWidth(value: number, totalAnswers: number, emptyCells: number): number {
    if (totalAnswers === 0) {
      return 10;
    }

    if (value === 0) {
      return MIN_BAR_WIDTH;
    }

    // Get empty cell offset
    const emptyCellOffset = (MIN_BAR_WIDTH * emptyCells); // 0.9 * 3

    // Get chart width minus offset
    const chartWidthRemaining = (100 - emptyCellOffset);

    // Get value ratio to totalAnswers as deciaml
    const valueDecimal = (value / totalAnswers);

    // Get width
    const finalWidth = (valueDecimal * chartWidthRemaining);
    return finalWidth;
  }

  getChartWidthRounded(width: number): string {
    return width.toFixed(0);
  }
}
