import { IdeaEffect } from '@app/models/feedback/idea-effect.model';
import moment from 'moment';
import { mockGoalTags } from '@app/mock/api/data/mockGoalTags';
import { Idea } from '@app/models/feedback/idea.model';
import { mockUsers } from '@app/mock/api/data/mockUsers';

export const mockIdeas: Idea[] = [
  {
    id: 1,
    message: 'Is it possible to give our fire wardens high vis jackets?',
    senderUser: mockUsers[1],
    destinationUser: mockUsers[0],
    timestamp: moment().subtract(1, 'week').toDate(),
    ideaEffect: IdeaEffect.HEALTH_AND_SAFETY,
    values: [mockGoalTags[0]]
  },
  {
    id: 2,
    message: 'Stand up desks for all employees.',
    senderUser: mockUsers[2],
    destinationUser: mockUsers[0],
    timestamp: moment().subtract(1, 'week').toDate(),
    ideaEffect: IdeaEffect.HEALTH_AND_SAFETY,
    values: [mockGoalTags[0]]
  },
  {
    id: 3,
    message: 'Floors are slippy in reception. Watch out.',
    senderUser: mockUsers[2],
    destinationUser: mockUsers[0],
    timestamp: moment().subtract(1, 'week').toDate(),
    ideaEffect: IdeaEffect.HEALTH_AND_SAFETY,
    values: [mockGoalTags[0]]
  },
  {
    id: 4,
    message: 'It would be great to define a small budget for team level reward & recognition vouchers that could easily be approved.',
    senderUser: mockUsers[1],
    destinationUser: mockUsers[0],
    timestamp: moment().subtract(1, 'week').toDate(),
    ideaEffect: IdeaEffect.MORALE,
    values: [mockGoalTags[2]]
  },
  {
    id: 5,
    message: 'Develop a new quality check sheet that incorporates all supplier info.',
    senderUser: mockUsers[3],
    destinationUser: mockUsers[0],
    timestamp: moment().subtract(1, 'week').toDate(),
    ideaEffect: IdeaEffect.QUALITY,
    values: [mockGoalTags[1]]
  },
  {
    id: 6,
    message: 'Cut down on costs by reducing spend on coffee.',
    senderUser: mockUsers[4],
    destinationUser: mockUsers[0],
    timestamp: moment().subtract(1, 'week').toDate(),
    ideaEffect: IdeaEffect.REVENUE,
    values: [mockGoalTags[2]]
  },
  {
    id: 7,
    message: 'I believe we can create a dedicate support services online via intercom and bill our customers for out of hours support.',
    senderUser: mockUsers[6],
    destinationUser: mockUsers[0],
    timestamp: moment().subtract(1, 'week').toDate(),
    ideaEffect: IdeaEffect.REVENUE,
    values: [mockGoalTags[1]]
  },
  {
    id: 8,
    message: 'I think we should hold regular show and tells across our team to showcase the great work happening.',
    senderUser: mockUsers[5],
    destinationUser: mockUsers[0],
    timestamp: moment().subtract(1, 'week').toDate(),
    ideaEffect: IdeaEffect.PRODUCTIVITY,
    values: [mockGoalTags[0]]
  }
];