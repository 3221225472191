import { Component, Input, OnInit } from '@angular/core';

import { I18nService } from './i18n.service';
import { BsDropdownConfig } from 'ngx-bootstrap/dropdown';
import { LanguageSelectorMessages } from './language-selector.messages';
import { SwalUtils } from '@app/shared/utils/swal.utils';

const PREVIEW_LANGUAGES = [ 'es-latam', 'de' ];

@Component({
  selector: 'app-language-selector',
  templateUrl: './language-selector.component.html',
  styleUrls: ['./language-selector.component.scss'],
  providers: [{ provide: BsDropdownConfig, useValue: { isAnimated: true, autoClose: true } }]
})
export class LanguageSelectorComponent implements OnInit {
  public readonly eLanguageSelectorMessages = LanguageSelectorMessages;

  flagClass: string;
  previewLanguages: string[];

  @Input() showSelectedLanguageName: boolean;
  @Input() alignDropdownToRight: boolean;

  constructor(
    private i18nService: I18nService,
    private swalUtils: SwalUtils
  ) {
    this.flagClass = 'flag-icon-gb';
    this.previewLanguages = PREVIEW_LANGUAGES;
    this.showSelectedLanguageName = true;
    this.alignDropdownToRight = false;
  }

  ngOnInit(): void {
    this.flagClass = this.i18nService.getFlagClassForLanguage(this.i18nService.language);
  }

  setLanguage(language: string) {
    this.i18nService.language = language;
    this.flagClass = this.i18nService.getFlagClassForLanguage(language);

    this.swalUtils.displaySuccessConfirmationSwal(
      {
        title: LanguageSelectorMessages.CONFIRM_PAGE_RELOAD_TITLE,
        text: LanguageSelectorMessages.CONFIRM_PAGE_RELOAD_TEXT,
        confirmButtonText: LanguageSelectorMessages.CONFIRM_PAGE_RELOAD_CONFIRM_BUTTON,
        cancelButtonText: LanguageSelectorMessages.CONFIRM_PAGE_RELOAD_CANCEL_BUTTON
      }
    ).then((result) => {
      if (result.isConfirmed) {
        location.reload();
      }
    });
  }

  get currentLanguage(): string {
    return this.i18nService.language;
  }

  get languages(): string[] {
    return this.i18nService.supportedLanguages;
  }

  getFlagClassForLanguage(lang: string): string {
    return this.i18nService.getFlagClassForLanguage(lang);
  }
}
