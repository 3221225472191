import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { DateService } from '../utils/date.service';
import { Observable } from 'rxjs';
import { FrankliNotification, FrankliNotificationServerside } from '@app/models/notifications/frankli-notification.model';
import { FrankliNotificationType } from '@app/models/notifications/frankli-event-type.enum';
import { FrankliEventStatus } from '@app/models/notifications/frankli-event-status.enum';
import { NotificationService } from '@app/shared/api/interfaces/events.service';

@Injectable()
export class NotificationAPIService implements NotificationService {
  private readonly BASE_URL = '/api/notifications';

  constructor(
    private http: HttpClient,
    private dateService: DateService
  ) { }

  getEvents(): Observable<Array<FrankliNotification>> {
    const url = `${this.BASE_URL}`;

    const params = new HttpParams()
        .set('status', FrankliEventStatus.NEW);

    return this.http.get<Array<FrankliNotificationServerside>>(url, {params: params}).pipe(map(notifications => {
      return notifications.map(notification => {
        return new FrankliNotification(notification);
      });
    }));
  }

  getEventsByType(type: FrankliNotificationType): Observable<Array<FrankliNotification>> {
    const url = `${this.BASE_URL}`;

    const params = new HttpParams()
        .set('type', type);

    return this.http.get<Array<FrankliNotificationServerside>>(url, {params: params}).pipe(map(notifications => {
      return notifications.map(notification => {
        return new FrankliNotification(notification);
      });
    }));
  }

  getEventByTypeAndEventForeignId(type: FrankliNotificationType, foreignId: number): Observable<FrankliNotification | null> {
    const url = `${this.BASE_URL}/${foreignId}`;

    const params = new HttpParams()
        .set('type', type);

    return this.http.get<FrankliNotificationServerside | null>(url, {params: params}).pipe(map(notification => {
      if (notification === null) {
        return null;
      }
      return new FrankliNotification(notification);
    }));
  }

  markAllAsRead(): Observable<Array<FrankliNotification>> {
    const url = `${this.BASE_URL}`;

    return this.http.post<Array<FrankliNotificationServerside>>(url, null).pipe(map(notifications => {
      return notifications.map(notification => new FrankliNotification(notification));
    }));
  }

  markAsRead(notificationId: number): Observable<FrankliNotification> {
    const url = `${this.BASE_URL}/${notificationId}`;

    return this.http.post<FrankliNotificationServerside>(url, null).pipe(map(notification => {
      return new FrankliNotification(notification);
    }));
  }

  recordClick(notificationId: number): Observable<FrankliNotification> {
    const url = `${this.BASE_URL}/${notificationId}/click`;

    return this.http.post<FrankliNotificationServerside>(url, null).pipe(map(notification => {
      return new FrankliNotification(notification);
    }));
  }

  frankliAdminSendTestInformNotification(): Observable<FrankliNotification> {
    const url = `${this.BASE_URL}/test/inform`;

    return this.http.post<FrankliNotificationServerside>(url, null).pipe(map(notification => {
      return new FrankliNotification(notification);
    }));
  }

  frankliAdminSendTestTaskNotification(): Observable<FrankliNotification> {
    const url = `${this.BASE_URL}/test/task`;

    return this.http.post<FrankliNotificationServerside>(url, null).pipe(map(notification => {
      return new FrankliNotification(notification);
    }));
  }

}
