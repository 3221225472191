import { FilterCategory } from '@app/shared/dynamic-filter/interfaces/filter-category';
import { FilterType } from '@app/shared/dynamic-filter/types/filter-type';
import { GoalType } from '@app/models/goals/goal-type.enum';
import { SearchMode } from '@app/shared/dynamic-filter/types/search-mode';
import { onboardedNotArchivedChildFilter } from '@app/shared/utils/api-filter/common-user-filters';
import { GoalsMessages } from '@app/goals/goals.messages';
import { CommonMessages } from '@app/constants/common.messages';
import { GoalUtils } from '@app/shared/utils/goal.utils';
import { GoalStatus } from '@app/models/goals/goal-status.model';
import { GoalPriority } from '@app/models/goals/goal-priority.model';
import { FilterCategoryBase } from '@app/shared/dynamic-filter/interfaces/filter-category-base';
import { TranslateService } from '@ngx-translate/core';
import { GoalVisibility } from '@models/goals/goal-visibility.model';
import { TerminologyCollection } from '@app/domain/terminology/model/terminology-collection.model';
import { TerminologyEntity } from '@app/domain/terminology/model/terminology-entity.enum';

export class GoalCategory {
  public static typeCategoryBase = (): FilterCategoryBase => ({
    fieldName: 'type',
    searchMode: SearchMode.NONE,
    type: FilterType.COMBINED
  });

  public static typeCategory = (terminologyCollection: TerminologyCollection): FilterCategory => ({
    ...GoalCategory.typeCategoryBase(),
    displayName: GoalsMessages.TYPE,
    options: Object.keys(GoalType).map(type => ({
      displayName: GoalUtils.getMessageCodeForGoalType(GoalType[type], terminologyCollection),
      value: type
    }))
  });

  public static statusCategoryBase = (): FilterCategoryBase => ({
    fieldName: 'status',
    searchMode: SearchMode.NONE,
    type: FilterType.COMBINED
  });

  public static statusCategory = (): FilterCategory => ({
    ...GoalCategory.statusCategoryBase(),
    displayName: GoalsMessages.STATUS,
    options: Object.keys(GoalStatus).map(status => ({
      displayName: GoalUtils.getMessageCodeForGoalStatus(GoalStatus[status]),
      value: status
    }))
  });

  public static visibilityCategoryBase = (): FilterCategoryBase => ({
    fieldName: 'visibility',
    searchMode: SearchMode.NONE,
    type: FilterType.COMBINED
  });

  public static visibilityCategory = (): FilterCategory => ({
    ...GoalCategory.visibilityCategoryBase(),
    displayName: GoalsMessages.VISIBILITY,
    options: Object.keys(GoalVisibility).map(visibility => ({
      displayName: GoalUtils.getMessageCodeForGoalVisibility(GoalVisibility[visibility]),
      value: visibility
    }))
  });


  public static completeCategoryBase = (): FilterCategoryBase => ({
    fieldName: 'complete',
    searchMode: SearchMode.NONE,
    type: FilterType.COMBINED
  });

  public static completeCategory = (): FilterCategory => ({
    ...GoalCategory.completeCategoryBase(),
    displayName: GoalsMessages.COMPLETE,
    options: [
      {
        displayName: CommonMessages.YES,
        value: 'true'
      },
      {
        displayName: CommonMessages.NO,
        value: 'false'
      }
    ]
  });

  public static archivedCategoryBase = (): FilterCategoryBase => ({
    fieldName: 'archived',
    searchMode: SearchMode.NONE,
    type: FilterType.COMBINED
  });

  public static archivedCategory = (): FilterCategory => ({
    ...GoalCategory.archivedCategoryBase(),
    displayName: GoalsMessages.ARCHIVED,
    options: [
      {
        displayName: CommonMessages.YES,
        value: 'true'
      },
      {
        displayName: CommonMessages.NO,
        value: 'false'
      }
    ]
  });

  public static ownerCategoryBase = (): FilterCategoryBase => ({
    fieldName: 'owners',
    searchMode: SearchMode.USER,
    type: FilterType.INDIVIDUAL
  });

  public static ownerCategory = (): FilterCategory => ({
    ...GoalCategory.ownerCategoryBase(),
    displayName: GoalsMessages.OWNERS,
    searchFilters: [onboardedNotArchivedChildFilter],
    options: []
  });

  public static priorityCategoryBase = (): FilterCategoryBase => ({
    fieldName: 'priority',
    searchMode: SearchMode.NONE,
    type: FilterType.COMBINED
  });

  public static priorityCategory = (translateService: TranslateService): FilterCategory => ({
    ...GoalCategory.priorityCategoryBase(),
    displayName: GoalsMessages.PRIORITY,
    options: Object.keys(GoalPriority).map((priority) => {
      const codeShort = translateService.instant(GoalUtils.getMessageCodeForGoalPriority(GoalPriority[priority]));
      const codeLong = translateService.instant(GoalUtils.getLongMessageCodeForGoalPriority(GoalPriority[priority]));

      const displayName = ((priority === GoalPriority.NOT_SET) ? codeLong : `${codeShort} - ${codeLong}`);
      return {
        displayName: displayName,
        value: priority
      };
    })
  });

  public static departmentCategoryBase = (): FilterCategoryBase => ({
    fieldName: 'departmentId',
    searchMode: SearchMode.DEPARTMENT,
    type: FilterType.COMBINED
  });

  public static departmentCategory = (label: string): FilterCategory => ({
    ...GoalCategory.departmentCategoryBase(),
    displayName: label,
    options: []
  });

  public static officeLocationCategoryBase = (): FilterCategoryBase => ({
    fieldName: 'officeLocationId',
    searchMode: SearchMode.OFFICE_LOCATION,
    type: FilterType.COMBINED
  });

  public static officeLocationCategory = (terminologyCollection: TerminologyCollection): FilterCategory => ({
    ...GoalCategory.officeLocationCategoryBase(),
    displayName: terminologyCollection[TerminologyEntity.SITE],
    options: []
  });
}
