<div class="rich-text-editor" [ngClass]="columnIndent ? 'row' : ''">
  <div [ngClass]="columnIndent ? 'col-xs-10 col-xs-offset-1' : ''">
    <div class="editor-toggle" [class.editor-hidden]="loading">
      <ng-container *ngIf="!display; else templateDisplay">
        <div [class.error-border]="(wordcount > maxCharacters) || showErrorBorder">
          <!-- TODO: May need to re-add if weant to test editors -->
          <!-- [id]="id" -->
          <div #editor></div>
        </div>
        <div class="text-right noselect" [class.text-danger]="(wordcount > maxCharacters) || warnWordcount || showErrorBorder">
          {{wordcount}} / {{maxCharacters}}
        </div>
      </ng-container>

      <!-- Display -->
      <ng-template #templateDisplay>
        <app-rich-display [text]="value"></app-rich-display>
      </ng-template>
    </div>

    <!-- Loading state -->
    <div *ngIf="loading">
      <div class="empty-state-container" [style.height.px]="height">
        <span class="spinner-container">
          <span class="fas fa-spin fa-spinner fa-2x fa-fw"></span>
        </span>
      </div>
    </div>
  </div>
</div>
