import { Component, Input } from '@angular/core';
import { EvaluagentEvaluationObject } from '@app/domain/evaluagent/model/evaluagent-evaluation-object.model';
import { EvaluagentScorecardAverage } from '@app/domain/evaluagent/model/evaluagent-scorecard-average.model';

@Component({
  selector: 'app-evaluagent-scorecard-average-display',
  templateUrl: './evaluagent-scorecard-average-display.component.html',
  styleUrls: ['./evaluagent-scorecard-average-display.component.scss']
})
export class EvaluagentScorecardAverageDisplayComponent {

  @Input() scorecard: EvaluagentScorecardAverage;
  @Input() showEvaluationsList: boolean;
  @Input() evaluations: EvaluagentEvaluationObject[];
  @Input() passRate: number;

  evaluationsListExpanded: boolean;

  constructor() {
    this.scorecard = undefined;
    this.showEvaluationsList = false;
    this.evaluations = [];
    this.evaluationsListExpanded = false;
    this.passRate = 0;
  }

  toggleEvaluationsListExpanded(): void {
    this.evaluationsListExpanded = !this.evaluationsListExpanded;
  }
}
