import { Component, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Breadcrumb } from '@app/models/breadcrumb.model';
import { IState } from '@app/models/state/state.model';
import { BreadcrumbService } from '@app/shared/breadcrumbs/breadcrumbs.service';
import { Globals } from '@app/shared/globals/globals';
import { SentimentScaleMessages } from '../../locale/sentiment-scale.messages';
import { RouteGuardUnsavedChanges } from '@app/shared/utils/unsaved-changes.model';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { SentimentScale } from '../../model/sentiment-scale.model';
import { CommonMessages } from '@app/constants/common.messages';
import { ButtonType } from '@app/shared/components/inputs/button/button.component';
import { CreateSentimentScaleOptionComponent, CreateSentimentScaleOptionComponentAction } from '../../component/create-sentiment-scale-option/create-sentiment-scale-option.component';
import { CreateSentimentScale } from '../../model/create-sentiment-scale.model';
import { SentimentScaleBusinessService } from '../../service/sentiment-scale-business.service';
import { FrankliValidators } from '@app/shared/validators/validators';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { ModalComponent } from '@app/shared/modal/modal.component';

interface PageState extends IState {
  submitted: boolean;
  submitting: boolean;
}

const EXAMPLE_SCALE_OPTIONS: SentimentScale[] = [
  {
    id: null,
    archived: false,
    name: 'Sentiment 5 part scale',
    description: 'Default sentiment 5 part scale',
    options: [
      {
        id: null,
        orderIndex: 0,
        name: 'Outperforming role',
        description: 'Mastery of skills at the current level has been proven, and overall performance indicates readiness for growth. There is a clear ability to perform and thrive at the next level.',
        score: 5,
        colour: '#54C6BB'
      },
      {
        id: null,
        orderIndex: 1,
        name: 'Outgrowing Role',
        description: 'The skills and outcomes of the work are starting to reflect the next career level.',
        score: 4,
        colour: '#FEBC56'
      },
      {
        id: null,
        orderIndex: 2,
        name: 'Meeting Role Requirements',
        description: 'The expectations of the role are being fully met, and the high bar set is achieved. It\'s time to polish current skills or begin looking for ways to stretch and grow.',
        score: 3,
        colour: '#F75757'
      },
      {
        id: null,
        orderIndex: 3,
        name: 'Room to Grow',
        description: 'Overall, the current performance is good, and there is still room to grow into the expectations of the role. The next 6 months will focus on structured growth conversations and mastering core skills or behaviors.',
        score: 2,
        colour: '#254DC6'
      },
      {
        id: null,
        orderIndex: 4,
        name: 'Underperforming / Concerns',
        description: 'I believe there are areas where improvements are needed, and an improvement plan would be beneficial.',
        score: 1,
        colour: '#012139'
      }
    ],
    showNumbers: true
  },
  {
    id: null,
    archived: false,
    name: 'Sentiment 4 part scale',
    description: 'Default sentiment 4 part scale',
    options: [
      {
        id: null,
        orderIndex: 0,
        name: 'Outgrowing Role',
        description: 'Skills and work outcomes are starting to reflect the next career level. Mastery of skills at the current level has been proven, and overall performance indicates readiness for growth, showing the ability to perform and thrive at the next level.',
        score: 4,
        colour: '#54C6BB'
      },
      {
        id: null,
        orderIndex: 1,
        name: 'Meeting Role Requirements',
        description: 'All expectations of the role are being met, and the high bar set is achieved. It\'s time to polish current skills or begin looking for ways to stretch and grow.',
        score: 3,
        colour: '#FEBC56'
      },
      {
        id: null,
        orderIndex: 2,
        name: 'Room to Grow',
        description: 'Overall performance is good, with continued growth into the role\'s expectations. The next 6 months will focus on structured growth conversations and mastering core skills or behaviors.',
        score: 2,
        colour: '#F75757'
      },
      {
        id: null,
        orderIndex: 3,
        name: 'Underperforming / Concerns',
        description: 'There are areas where improvement is needed, and an improvement plan would be beneficial.',
        score: 1,
        colour: '#254DC6'
      }
    ],
    showNumbers: true
  },
  {
    id: null,
    archived: false,
    name: 'Sentiment 3 part scale',
    description: 'Default sentiment 3 part scale',
    options: [
      {
        id: null,
        orderIndex: 0,
        name: 'Outgrowing Role',
        description: 'Skills and work outcomes are starting to reflect the next career level. Mastery of skills at the current level has been proven, and overall performance indicates readiness for growth.',
        score: 3,
        colour: '#54C6BB'
      },
      {
        id: null,
        orderIndex: 1,
        name: 'Room to Grow',
        description: 'Overall performance is good, with continued growth into the role\'s expectations. The next 6 months will focus on structured growth conversations and mastering core skills or behaviors.',
        score: 2,
        colour: '#F75757'
      },
      {
        id: null,
        orderIndex: 2,
        name: 'Underperforming / Concerns',
        description: 'There are areas where improvement is needed, and an improvement plan would be beneficial.',
        score: 1,
        colour: '#254DC6'
      }
    ],
    showNumbers: true
  }
];

@Component({
  selector: 'app-create-sentiment-scale',
  templateUrl: './create-sentiment-scale.component.html',
  styleUrls: ['./create-sentiment-scale.component.scss']
})
export class CreateSentimentScaleComponent implements OnDestroy, RouteGuardUnsavedChanges {

  public static initForm(sentimentScale?: SentimentScale): FormGroup {
    const formGroup = new FormGroup({
      id: new FormControl(null),
      name: new FormControl('', [ Validators.required, Validators.maxLength(255) ]),
      description: new FormControl('', [ Validators.maxLength(1000) ]),
      options: new FormArray([],
        [FrankliValidators.minLengthArray(2), FrankliValidators.maxLengthArray(10)]
      ),
      showNumbers: new FormControl(true, [])
    });

    if (sentimentScale) {
      formGroup.controls.id.setValue(sentimentScale.id);
      formGroup.controls.name.setValue(sentimentScale.name);
      formGroup.controls.description.setValue(sentimentScale.description);
      formGroup.controls.showNumbers.setValue(sentimentScale.showNumbers);

      sentimentScale.options.forEach((option, index) => {
        const formGroupOption = CreateSentimentScaleOptionComponent.initForm(index, option);
        (formGroup.get('options') as FormArray).push(formGroupOption);
      });
    }

    return formGroup;
  }

  public static parseFormToCreateDto(form: FormGroup): CreateSentimentScale {
    return {
      name: form.get('name').value,
      description: form.get('description').value,
      options: (form.get('options') as FormArray).controls.map((option, index) => CreateSentimentScaleOptionComponent.parseFormToCreateDto(option as FormGroup, index)),
      showNumbers: form.get('showNumbers').value
    };
  }

  public readonly eSentimentScaleMessages = SentimentScaleMessages;
  public readonly eEXAMPLE_SCALE_OPTIONS = EXAMPLE_SCALE_OPTIONS;
  public readonly eCommonMessages = CommonMessages;
  public readonly eButtonType = ButtonType;

  @ViewChild('modal') modal?: ModalComponent;

  breadcrumb: Breadcrumb;
  state: PageState;
  form: FormGroup;

  get isEditing(): boolean {
    return this.controlId.value !== null;
  }

  get controlId(): FormControl {
    return this.form.get('id') as FormControl;
  }

  get controlName(): FormControl {
    return this.form.get('name') as FormControl;
  }

  get controlDescription(): FormControl {
    return this.form.get('description') as FormControl;
  }

  get controlOptions(): FormArray {
    return this.form.get('options') as FormArray;
  }

  get controlOptionsArray(): FormGroup[] {
    return this.controlOptions.controls as FormGroup[];
  }

  get controlShowNumbers(): FormControl {
    return this.form.get('showNumbers') as FormControl;
  }

  constructor(
    public globals: Globals,
    public activatedRoute: ActivatedRoute,
    private router: Router,
    private breadcrumbService: BreadcrumbService,
    private sentimentScaleBusinessService: SentimentScaleBusinessService
  ) {
    this.state = {
      loading: true,
      error: false,
      errorMessage: '',
      submitted: false,
      submitting: false
    };

    this.form = CreateSentimentScaleComponent.initForm();

    this.breadcrumb = this.breadcrumbService.init(this.activatedRoute);

    // The URL will either be /create or /edit/:id so we need to check if we are editing or creating.
    // If editing, we need to get the data of the sentiment scale being edited to populate the form.
    this.activatedRoute.params.subscribe(params => {
      this.getData(params.id);
    });
  }

  ngOnDestroy(): void {
    this.breadcrumbService.remove(this.breadcrumb);
  }

  getData(paramId?: number): void {
    if (!paramId) {
      this.state.loading = false;
      return;
    }

    this.sentimentScaleBusinessService.getOne(paramId)
      .toPromise()
      .then(scaleEditing => {
        this.form = CreateSentimentScaleComponent.initForm(scaleEditing);
        this.state.loading = false;
      });
  }

  unsavedChanges(): boolean {
    if (this.state.submitting) { return true; }

    return this.form.dirty;
  }

  addScaleOption(): void {
    const index = this.controlOptionsArray.length;
    const formGroupOption = CreateSentimentScaleOptionComponent.initForm(index);
    this.controlOptions.push(formGroupOption);
    this.form.markAsDirty();
    this.updateScores();
  }

  cancelCreate(): void {
    this.router.navigate(['/admin/dashboard/one-to-ones/sentiment-scales']);
  }

  onClickSave(): void {
    this.state.submitted = true;

    if (this.form.invalid) { return; } // Cannot submit invalid form
    if (this.state.submitting) { return; } // Cannot submit if a request is in progress

    this.state.submitting = true;

    const createDto = CreateSentimentScaleComponent.parseFormToCreateDto(this.form);
    
    if (this.isEditing) {
      const scaleId = this.controlId.value;
      this.doEdit(scaleId, createDto);
    } else {
      this.doCreate(createDto);
    }
  }

  doCreate(createDto: CreateSentimentScale): void {
    this.sentimentScaleBusinessService.create(createDto)
      .then(() => {
        this.state.submitted = false;
        this.form = CreateSentimentScaleComponent.initForm(); // Reset form
        this.state.submitting = false;
        this.form.markAsPristine();
        this.cancelCreate();
      })
      .catch(() => {
        this.state.submitting = false;
      });
  }

  doEdit(id: number, updateDto: CreateSentimentScale): void {
    this.sentimentScaleBusinessService.update(id, updateDto)
      .then(() => {
        this.state.submitted = false;
        this.form = CreateSentimentScaleComponent.initForm(); // Reset form
        this.state.submitting = false;
        this.form.markAsPristine();
        this.cancelCreate();
      })
      .catch(() => {
        this.state.submitting = false;
      });
  }

  clickOptionAction(action: string, index: number): void {
    switch (action) {
      case CreateSentimentScaleOptionComponentAction.REMOVE:
        this.removeOption(index);
        break;
    }
  }

  removeOption(index: number): void {
    this.controlOptions.removeAt(index);
    this.form.markAsDirty();

    this.updateScores();
  }

  dropDraggableOption(event: CdkDragDrop<FormGroup[]>): void {
    // Must be moved within the same container
    if (event.previousContainer !== event.container) {
      return;
    }

    const currentControl = this.controlOptions.at(event.previousIndex);
    this.controlOptions.removeAt(event.previousIndex);
    this.controlOptions.insert(event.currentIndex, currentControl);
  }

  showModal(): void {
    if (!this.modal) { return; }
    this.modal.show();
  }

  hideModal(): void {
    if (!this.modal) { return; }
    this.modal.hide();
  }


  onClickTemplate(exampleScale: SentimentScale): void {
    this.form = CreateSentimentScaleComponent.initForm(exampleScale);
    this.hideModal();
  }

  updateScores(): void {
    // Iterate over each option and set the scores in descending order
    this.controlOptionsArray.forEach((option, index) => {
      option.get('score').setValue(this.controlOptionsArray.length - index);
    });
  }
}
