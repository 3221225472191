<div class="container-sidebar-reviews">
  <app-state [state]="state">
    <ng-container *ngIf="!state.loading && !state.error;">
      <div class="container-button-group">
        <app-button-group [formControl]="controlTab" [options]="buttonGroupOptions" [style]="'dark'" [consistentWidths]="true"></app-button-group>
      </div>

      <div class="container-response-preview">
        <ng-container [ngSwitch]="controlTab.value">
          <ng-container *ngSwitchCase="eEvaluationModuleType.SELF_REFLECTION">
            <app-information-sidebar-reviews-self-reflection [cycle]="evaluationCycle" [assessmentGroupsFiltered]="assessmentGroupsFiltered" [userIds]="userIds" [userIdsManaging]="userIdsManaging"></app-information-sidebar-reviews-self-reflection>
          </ng-container>

          <ng-container *ngSwitchCase="eEvaluationModuleType.MANAGER_REVIEW">
            <app-information-sidebar-reviews-manager-review [cycle]="evaluationCycle" [assessmentGroupsFiltered]="assessmentGroupsFiltered" [userIds]="userIds" [userIdsManaging]="userIdsManaging"></app-information-sidebar-reviews-manager-review>
          </ng-container>

          <ng-container *ngSwitchCase="eEvaluationModuleType.PEER_REVIEW">
            <app-information-sidebar-reviews-peer-reviews [cycle]="evaluationCycle" [userIds]="userIds" [userIdsManaging]="userIdsManaging"></app-information-sidebar-reviews-peer-reviews>
          </ng-container>

          <ng-container *ngSwitchCase="eEvaluationModuleType.UPWARD_REVIEW">
            <app-information-sidebar-reviews-upward-reviews [cycle]="evaluationCycle" [assessmentGroupsFiltered]="assessmentGroupsFiltered" [userIds]="userIds" [userIdsManaging]="userIdsManaging"></app-information-sidebar-reviews-upward-reviews>
          </ng-container>
        </ng-container>
      </div>
    </ng-container>

    <ng-template #templateNoData>
      <app-empty-state [height]="'200px'" [imageSrc]="'assets/img/empty-state/empty-state-reviews.svg'" [message]="'No reviews'"></app-empty-state>
    </ng-template>
  </app-state>
</div>
