import { Component, Input, OnInit } from '@angular/core';
import { Interest } from '@app/domain/interest/model/interest.model';
import { Page } from '@app/models/api/page.model';
import { UserSimilar } from '@app/models/user/user-similar.model';
import { User } from '@app/models/user/user.model';
import { UserAPIService } from '@app/shared/api/user.api.service';
import { Globals } from '@app/shared/globals/globals';

interface PageInterest extends Interest {
  sharedByUsers: UserSimilar[];
}

@Component({
  selector: 'app-profile-interests',
  templateUrl: './profile-interests.component.html',
  styleUrls: ['./profile-interests.component.scss']
})
export class ProfileInterestsComponent implements OnInit {

  @Input() user: User;

  interestsPersonal: PageInterest[];
  interestsProfessional: PageInterest[];

  professionalInterestsVisible: number;
  personalInterestsVisible: number;

  similarUsers: UserSimilar[];

  constructor(
    public globals: Globals,
    private userAPIService: UserAPIService,
  ) {
    this.interestsProfessional = [];
    this.interestsPersonal = [];
    this.similarUsers = [];

    this.professionalInterestsVisible = 4;
    this.personalInterestsVisible = 4;
  }

  ngOnInit(): void {
    this.getSimilarUsers()
      .then(() => this.getData());
  }

  getData(): void {
    this.interestsPersonal = this.parseInterestsToPageInterests(this.user.personalInterests);
    this.interestsProfessional = this.parseInterestsToPageInterests(this.user.professionalInterests);
  }

  getSimilarUsers(): Promise<Page<UserSimilar>> {
    return this.userAPIService.getUsersSimilar({ pageNumber: 0, pageSize: 60 })
      .toPromise()
      .then(pagedUsers => {
        this.similarUsers = pagedUsers.content.filter(u => u.id !== this.user.id);
        return pagedUsers;
      });
  }

  parseInterestsToPageInterests(interests: Interest[]): PageInterest[] {
    return interests.map(interest => this.parseInterestToPageInterest(interest));
  }

  parseInterestToPageInterest(interest: Interest): PageInterest {
    const pageInterest: PageInterest = interest as PageInterest;

    if (pageInterest) {
      pageInterest.sharedByUsers = this.getUsersWhoShareInterest(pageInterest);
    }

    return pageInterest;
  }

  getUsersWhoShareInterest(interest: Interest): UserSimilar[] {
    return this.similarUsers.filter(user => user.interests.some(i => i.id === interest.id));
  }

  showMorePersonalInterests(): void {
    this.personalInterestsVisible += 4;
  }

  showMoreProfessionalInterests(): void {
    this.professionalInterestsVisible += 4;
  }

}
