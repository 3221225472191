import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ISkillRatingAPI } from './skill-rating-api.model';
import { CreateSkillRating } from '../model/create-skill-rating.model';
import { SkillRatingType } from '../model/skill-rating-type.model';
import { ISkillRatingServerside, SkillRating } from '../model/skill-rating.model';
import { CreateSkillRatingConfiguration } from '../model/create-skill-rating-configuration.model';
import { ISkillRatingConfigurationServerside, SkillRatingConfiguration } from '../model/skill-rating-configuration.model';

@Injectable()
export class SkillRatingAPIService implements ISkillRatingAPI {
  public readonly BASE_URL = 'api/skill-rating';

  constructor(private http: HttpClient) {
  }

  getAll(): Observable<SkillRating[]> {
    const url = `${this.BASE_URL}/`;
    return this.http.get<ISkillRatingServerside[]>(url).pipe(map(s => s.map(s => new SkillRating(s))));
  }

  getForUser(userId: number): Observable<SkillRating[]> {
    const url = `${this.BASE_URL}/by-user/${userId}`;
    return this.http.get<ISkillRatingServerside[]>(url).pipe(map(s => s.map(s => new SkillRating(s))));
  }

  getByTypeAndTargetId(skillRatingType: SkillRatingType, targetId: number): Observable<SkillRating[]> {
    const url = `${this.BASE_URL}/by-type/${skillRatingType}/${targetId}`;
    return this.http.get<ISkillRatingServerside[]>(url).pipe(map(s => s.map(s => new SkillRating(s))));
  }

  create(createDto: CreateSkillRating): Observable<SkillRating> {
    const url = `${this.BASE_URL}/`;
    return this.http.post<ISkillRatingServerside>(url, createDto).pipe(map(s => new SkillRating(s)));
  }

  delete(id: number): Observable<SkillRating> {
    const url = `${this.BASE_URL}/${id}`;
    return this.http.delete<ISkillRatingServerside>(url).pipe(map(s => new SkillRating(s)));
  }

  getConfiguration(): Observable<SkillRatingConfiguration> {
    const url = `${this.BASE_URL}/configuration`;
    return this.http.get<ISkillRatingConfigurationServerside>(url).pipe(map(s => {
      if (s === null) {
        return SkillRatingConfiguration.DEFAULT;
      } else {
        return new SkillRatingConfiguration(s);
      }
    }));
  }

  updateConfiguration(createDto: CreateSkillRatingConfiguration): Observable<SkillRatingConfiguration> {
    const url = `${this.BASE_URL}/configuration`;
    return this.http.post<ISkillRatingConfigurationServerside>(url, createDto).pipe(map(s => new SkillRatingConfiguration(s)));
  }

}
