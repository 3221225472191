import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { Goal } from 'app/models/goals/goal.model';
import { GoalTemplate } from '@app/models/goals/templates/goal-template.model';
import { GoalDraft } from '@app/models/goals/goal-draft.model';

@Injectable()
export class GoalCreateService {
  private subjectCreated = new Subject<string>();
  private subjectReset = new Subject<string>();

  private subjectRefreshGoals = new Subject<boolean>();
  private subjectGoalUpdated = new Subject<Goal>();
  private subjectGoalSaved = new Subject<GoalDraft>();
  private subjectGoalCopied = new Subject<Goal>();
  private subjectGoalTemplateCopied = new Subject<GoalTemplate>();
  private subjectGoalDraftEditStarted = new Subject<GoalDraft>();

  // #region - INIT
  getInit(): Observable<string> {
    return this.subjectCreated.asObservable();
  }

  sendInit(): void {
    this.subjectCreated.next('INIT');
  }
  // #endregion

  // #region - RESET
  getReset(): Observable<string> {
    return this.subjectReset.asObservable();
  }

  sendReset(): void {
    this.subjectReset.next('RESET');
  }
  // #endregion

  // #region - UPDATED
  getGoalUpdated(): Observable<Goal> {
    return this.subjectGoalUpdated.asObservable();
  }

  sendGoalUpdated(goal: Goal): void {
    this.subjectGoalUpdated.next(goal);
  }
  // #endregion

  // #region - SAVED
  getGoalSaved(): Observable<GoalDraft> {
    return this.subjectGoalSaved.asObservable();
  }

  sendGoalSaved(goalDraft: GoalDraft): void {
    this.subjectGoalSaved.next(goalDraft);
  }
  // #endregion

  // #region - GOAL COPIED
  getGoalCopied() {
    return this.subjectGoalCopied.asObservable();
  }

  sendGoalCopied(goalCopied: Goal) {
    this.subjectGoalCopied.next(goalCopied);
  }
  // #endregion

  // #region - TEMPLATE COPIED
  getGoalTemplateCopied() {
    return this.subjectGoalTemplateCopied.asObservable();
  }

  sendGoalTemplateCopied(templateCopied: GoalTemplate) {
    this.subjectGoalTemplateCopied.next(templateCopied);
  }
  // #endregion

  // #region - START EDIT DRAFT
  getGoalDraftEdited() {
    return this.subjectGoalDraftEditStarted.asObservable();
  }

  sendGoalDraftEdited(draftEdited: GoalDraft) {
    this.subjectGoalDraftEditStarted.next(draftEdited);
  }
  // #endregion

  // #region - REFRESH EVENT
  getRefreshGoals(): Observable<boolean> {
    return this.subjectRefreshGoals.asObservable();
  }

  sendRefreshGoals(): void {
    this.subjectRefreshGoals.next(true);
  }
  // #endregion
}
