import { AfterViewInit, Component, ElementRef, HostListener, OnDestroy, ViewChild } from '@angular/core';
import { CompanyAPIService } from '@app/shared/api/company/company.api.service';
import { Router } from '@angular/router';
import { OrgChartUserDto } from 'app/models/user/org-chart-user.model';
import { forkJoin, Observable, Subject, timer } from 'rxjs';
import { debounce, map, takeUntil, tap } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { Globals } from 'app/shared/globals/globals';
import { ModalComponent } from 'app/shared/modal/modal.component';
import { FileAPIService } from 'app/shared/api/file.api.service';
import { UserAPIService } from '@app/shared/api/user.api.service';
import { User } from '@app/models/user/user.model';
import { SidebarService } from '@app/shared/sidebar/sidebar.service';
import { Treeviz } from 'assets/treeviz/frankli-treeviz';
import { IState } from '@app/models/state/state.model';

class OrgChartNodeData {
  constructor(
    public id: number,
    public managerId: number | null,
    public collapsed: boolean,
    public children: number,
    public name: string,
    public imageURL: string,
    public image: string | null,
    public position: string,
    public officeLocation: string,
    public goalsShown: boolean,
    public goalsPersonal: GoalStatus | null,
    public goalsDepartment: GoalStatus | null,
    public goalsCompany: GoalStatus | null,
    public goalTotalProgress: number | null,
    public onPictureClick: Function,
    public onToggleChildren: Function
  ) { }
}

interface OrgChartNode {
  data: OrgChartNodeData
}

interface AvatarImage {
  url: string;
  image: string;
}

class GoalStatus {
  constructor(
    public total: number,
    public progress: number
  ) { }
}

interface PageState extends IState {
  fullscreen: boolean;
}

@Component({
  selector: 'app-org-chart',
  templateUrl: './org-chart.component.html',
  styleUrls: ['./org-chart.component.scss']
})
export class OrgChartComponent implements OnDestroy, AfterViewInit {
  @ViewChild('modal') modal!: ModalComponent;
  @ViewChild('treeContainer') chartContainer?: ElementRef<HTMLDivElement>;

  private ngUnsubscribe: Subject<void> = new Subject<void>();

  state: PageState;

  orgChartUsers: OrgChartUserDto[];
  chart: any | null;

  animating: boolean;
  imagePromises = [];
  fileReaderPromises = [];
  imagesCache = new Array<AvatarImage>();

  // maintain root
  root: OrgChartNodeData;

  orgChartNodes: Array<OrgChartNodeData>;
  resize: Subject<any>;

  users: User[];
  currentUserPath: number[];

  firstInit: boolean;

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.resize.next();
  }

  constructor(
    private companyAPIService: CompanyAPIService,
    private userAPIService: UserAPIService,
    private router: Router,
    public globals: Globals,
    private fileAPIService: FileAPIService,
    public sidebarService: SidebarService
  ) {
    this.currentUserPath = [];
    this.orgChartUsers = [];
    this.orgChartNodes = [];
    this.users = [];

    this.animating = false;
    this.firstInit = true;

    this.chart = null;

    this.resize = new Subject();

    this.root = new OrgChartNodeData(
      0,
      null,
      false,
      99, // any non zero number will do fine here
      '',
      '',
      null,
      '',
      '',
      false,
      null,
      null,
      null,
      null,
      () => { },
      () => { }
    );

    this.state = {
      loading: true,
      error: false,
      errorMessage: '',
      fullscreen: false
    };

    this.sidebarService.$miniSidebarToggled
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(res => {
        if (res.previous !== res.next) {
          this.resize.next();
        }
      });
  }

  // #region - LIFECYCLE HOOKS
  ngAfterViewInit() {
    this.getData();
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
  // #endregion

  getData() {
    this.state.loading = true;

    this.userAPIService.getAllUsers().subscribe(res => {
      this.users = res;
      this.currentUserPath = this.getUserBranch(this.globals.user);

      this.companyAPIService.getCompanyOrgChart()
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(orgChartUsers => {
          this.orgChartUsers = orgChartUsers;
          this.checkImages();
          this.initResize();

          if (this.imagePromises.length === 0) {
            return this.resize.next();
          }

          forkJoin(this.imagePromises).subscribe(() => {
            if (this.fileReaderPromises.length === 0) {
              return this.resize.next();
            }

            forkJoin(this.fileReaderPromises).subscribe(() => {
              return this.resize.next();
            });
          });
        }, (err: HttpErrorResponse) => this.doError(err.message));
    }, (err: HttpErrorResponse) => this.doError(err.message));
  }

  doError(message: string): void {
    this.state.loading = false;
    this.state.error = true;
    this.state.errorMessage = message;
  }

  initResize(): void {
    this.resize
      .pipe(
        takeUntil(this.ngUnsubscribe),
        tap(() => {
          this.orgChartNodes = this.parseOrgChartUsers(this.orgChartUsers);
          this.firstInit = false;
        }),
        debounce(() => timer(500))
      )
      .subscribe(e => {
        if (this.chartContainer) {
          this.chartContainer.nativeElement.innerHTML = '';
        }

        this.generateChart(this.orgChartNodes);
      });
  }

  // #region - IMAGE CHECKING
  // Start checking images
  checkImages() {
    this.imagePromises = [];
    this.fileReaderPromises = [];
    this.checkOrgChartUsersImages(this.orgChartUsers);
  }

  // Recursively check images
  checkOrgChartUsersImages(users: Array<OrgChartUserDto>) {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const vm = this;
    users.forEach(user => {

      if (user.userInfo.imageURL.indexOf('/api/files/get/') >= 0) {
        if (!this.imagesCache.some(x => x.url === user.userInfo.imageURL)) {
          const filePromise = this.fileAPIService.get(user.userInfo.imageURL).pipe(
            map((response) => {
              const imageProcessPromise = Observable.create((observer: any) => {
                const reader = new FileReader();
                reader.readAsDataURL(response);
                reader.onloadend = function () {
                  const avatarImage = { url: user.userInfo.imageURL, image: reader.result } as AvatarImage;
                  vm.imagesCache.push(avatarImage);
                  observer.next(reader.result);
                  observer.complete();
                };
              });
              this.fileReaderPromises.push(imageProcessPromise);
            })
          );
          this.imagePromises.push(filePromise);
        }
      }
      if (user.children !== null) {
        this.checkOrgChartUsersImages(user.children);
      }
    });
  }
  // #endregion

  /**
   * Recursively builds a list of nodes for the chart, requires a root node to be passed to start
   * @param parent
   * @param child
   * @param nodes
   */
  private parseChild(parentNode: OrgChartNodeData, currentNode: OrgChartUserDto, nodes: Array<OrgChartNodeData>) {
    // Create tree node
    let goalCompletionPercentage = 0;
    if (currentNode.goalInfo !== null) {
      goalCompletionPercentage = currentNode.goalInfo.total;
    }

    currentNode.collapsed = this.firstInit ? !this.currentUserPath.includes(currentNode.userId) : currentNode.collapsed;

    // src.indexOf('/api/files/get/')
    const node = new OrgChartNodeData(
      currentNode.userId,
      parentNode.id,
      currentNode.collapsed,
      (currentNode.children === null ? 0 : currentNode.children.length),
      currentNode.userInfo.name,
      currentNode.userInfo.imageURL,
      null,
      currentNode.userInfo.position,
      currentNode.userInfo.location,
      (currentNode.goalInfo !== null),
      (currentNode.goalInfo === null) ? null : currentNode.goalInfo.personal,
      (currentNode.goalInfo === null) ? null : currentNode.goalInfo.department,
      (currentNode.goalInfo === null) ? null : currentNode.goalInfo.company,
      goalCompletionPercentage,
      () => this.navigateToProfile(currentNode.userId),
      () => {
        this.orgChartUsers.forEach(user => {
          this.toggleChildren(currentNode.userId, user);
        });
        this.refreshChart(this.parseOrgChartUsers(this.orgChartUsers));
      }
    );

    if (node.imageURL.indexOf('/api/files/get/') >= 0) {
      const image = this.imagesCache.find(x => x.url == node.imageURL);
      if (image) {
        node.image = image.image;
      }
    }

    nodes.push(node);

    // if current node is expanded and has children
    if (currentNode.collapsed === false && currentNode.children !== null) {
      currentNode.children.forEach(c => {
        this.parseChild(node, c, nodes);
      });
    }
  }

  /**
   * Wrapper for parseChild adding a root node and handling multiple root users
   * @param users
   */
  private parseOrgChartUsers(users: Array<OrgChartUserDto>): Array<OrgChartNodeData> {
    const nodes = new Array<OrgChartNodeData>();
    // library requires root node needed for multiple root users (no manager)
    nodes.push(this.root);

    users.forEach(node => {
      // node.collapsed = this.firstInit ? !this.currentUserPath.includes(node.userId) : true;

      this.parseChild(this.root, node, nodes);
    });

    return nodes;
  }

  /**
   * Generates the initial chart
   * @param nodes
   */
  private generateChart(nodes: Array<OrgChartNodeData>, retryCount = 1): void {
    const isIE = this.isIE();

    if (!this.chartContainer) {
      if (retryCount >= 5) {
        return this.doError('Failed to display chart after 5 retries. Please refresh and try again.');
      }

      setTimeout(() => {
        this.generateChart(nodes, retryCount + 1);
      }, 250);
      return;
    }

    this.chart = Treeviz.create({
      htmlId: 'tree',
      idKey: 'id',
      hasFlatData: true,
      relationnalField: 'managerId',
      hasPan: true,
      hasZoom: true,
      nodeWidth: 250,
      nodeHeight: 175,
      mainAxisNodeSpacing: 1,
      isHorizontal: false,
      renderNode: (node: OrgChartNode) => {
        return node.data;
      },
      linkWidth: (nodeData: OrgChartNodeData) => 5,
      linkShape: 'orthogonal',
      linkColor: (nodeData: OrgChartNodeData) => {
        if (nodeData.managerId === 0) {
          return ''; // Hides root node
        } else if (nodeData.managerId === this.globals.user.id) {
          return '#30747F'; // Direct Reports
        } else {
          return '#566684';
        }
      },
      onNodeClick: (node: OrgChartNode) => { },
      onNodeMouseEnter: (node: OrgChartNode) => {},
      onNodeMouseLeave: (node: OrgChartNode) => {},
      hasPanAndZoom: true,
      isInternetExplorer: isIE,
      marginTop: -150,
      minZoomScale: 0.5,
      maxZoomScale: 2
      // duration: undefined!,
      // marginBottom: 0,
      // marginLeft: 0,
      // marginRight: 0,
      // secondaryAxisNodeSpacing: undefined!
    });
    // as Partial<ITreeConfig>);

    this.state.loading = false;

    this.refreshChart(nodes);
  }

  /**
   * Returns true if browser is Internet Explorer
   */
  public isIE(): boolean {
    return (
      (navigator.appName === 'Microsoft Internet Explorer')
      || ((navigator.appName === 'Netscape') && (new RegExp('Trident/.*rv:([0-9]{1,}[\.0-9]{0,})').exec(navigator.userAgent) != null))
    );
  }

  /**
   * Refresh the chart
   * @param nodes
   */
  private refreshChart(nodes: Array<OrgChartNodeData>, retryCount = 1): void {
    setTimeout(() => {
      if (!this.chartContainer) {
        if (retryCount >= 5) {
          return this.doError('Failed to display chart after 5 retries. Please refresh and try again.');
        }

        this.refreshChart(nodes, (retryCount + 1));
      }

      this.chart.refresh(nodes);
      this.animating = false;
    }, 1);
  }

  /**
   * Recursively searches for an org chart user with the specified id and toggle their collapse property
   * @param userId
   * @param orgChartUser
   */
  private toggleChildren(userId: number, orgChartUser: OrgChartUserDto) {
    if (orgChartUser.userId === userId) {
      orgChartUser.collapsed = !orgChartUser.collapsed;
      return;
    }

    if (orgChartUser.children !== null) {
      orgChartUser.children.forEach(child => {
        this.toggleChildren(userId, child);
      });
    }
  }

  /**
   * Recursively expandsall children
   */
  private setAll(orgChartUser: OrgChartUserDto, collapsed: boolean) {
    orgChartUser.collapsed = collapsed;
    if (orgChartUser.children !== null) {
      orgChartUser.children.forEach(child => {
        this.setAll(child, collapsed);
      });
    }
  }

  private navigateToProfile(id: number): void {
    this.router.navigateByUrl('/profile/' + id);
  }

  private zoom() {
    // TODO: Orgchart uses d3 zooming events, need to figure how to
    // hook into those with a button press.
    // something to do with resetting d3.zoomIdentity if you want to reset the zoom
  }

  public zoomIn(): void {
    this.zoom();
  }

  public zoomOut(): void {
    this.zoom();
  }

  public zoomDefault(): void {
    this.zoom();
  }

  setToCurrentNodeCollapsed(orgChartUser: OrgChartUserDto) {
    orgChartUser.collapsed = !this.currentUserPath.includes(orgChartUser.userId);

    if (orgChartUser.children !== null) {
      orgChartUser.children.forEach(child => {
        this.setToCurrentNodeCollapsed(child);
      });
    }
  }

  expandToCurrent(): void {
    this.orgChartUsers.forEach(user => {
      this.setToCurrentNodeCollapsed(user);
    });

    const nodes = this.parseOrgChartUsers(this.orgChartUsers);
    this.refreshChart(nodes);
  }


  /* Expand all users */
  public expandAll(): void {
    this.orgChartUsers.forEach(user => {
      this.setAll(user, false);
    });
    const nodes = this.parseOrgChartUsers(this.orgChartUsers);
    this.refreshChart(nodes);
  }

  /**
   * Collapse all users
   */
  public collapseAll(): void {
    this.orgChartUsers.forEach(user => {
      this.setAll(user, true);
    });
    const nodes = this.parseOrgChartUsers(this.orgChartUsers);
    this.refreshChart(nodes);
  }

  public showOrgchartHelp(): void {
    this.modal.show();
  }

  toggleFullscreen() {
    this.state.fullscreen = !this.state.fullscreen;
    this.resize.next();
  }

  // Get a path of userIDs from the current user to the top of the tree
  getUserBranch(user: User): number[] {
    let ids = [user.id];

    if ((user.managerId !== undefined) && (user.managerId !== null) && (user.id !== user.managerId)) {
      ids.push(user.managerId);

      const manager = this.users.find(u => u.id === user.managerId);
      if (manager) {
        ids = [...ids, ...this.getUserBranch(manager)];
      }
    }

    return ids;
  }
}
