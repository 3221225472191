import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonMessages } from '@app/constants/common.messages';
import { InterestType } from '@app/domain/interest/model/interest-type.model';
import { Interest } from '@app/domain/interest/model/interest.model';
import { Globals } from '@app/shared/globals/globals';

const TypeIcon = {
  [InterestType.PERSONAL]: 'fal fa-fw fa-mug-hot',
  [InterestType.PROFESSIONAL]: 'fas fa-fw fa-briefcase'
};

export enum InterestDisplayType {
  GRAY = 'GRAY',
  GREEN = 'GREEN',
  YELLOW = 'YELLOW',
  RED = 'RED'
}

@Component({
  selector: 'app-interest-display',
  templateUrl: './interest-display.component.html',
  styleUrls: ['./interest-display.component.scss']
})
export class InterestDisplayComponent {
  public readonly eInterestDisplayType = InterestDisplayType;
  public readonly eCommonMessages = CommonMessages;
  public readonly eTypeIcon = TypeIcon;

  @Input() interest: Interest;
  @Input() canDeselect: boolean;
  @Input() showIcon: boolean;
  @Input() type: InterestDisplayType;
  @Output() onClickAction: EventEmitter<string>;

  constructor(
    public globals: Globals
  ) {
    this.interest = undefined;
    this.canDeselect = false;
    this.showIcon = true;
    this.type = InterestDisplayType.GREEN;
    this.onClickAction = new EventEmitter<string>();
  }

  triggerAction(action: string): void {
    this.onClickAction.emit(action);
  }
}
