import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class ScheduledEventAPIService {

  private readonly BASE_URL = 'api/cron';

  constructor(private http: HttpClient) {
  }

  runCron(): Observable<null> {
    const url = `${this.BASE_URL}/run`;

    return this.http.post<null>(url, null);
  }

}
