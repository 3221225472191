<div class="container-talking-points">
  <!-- Talking points display -->
  <div class="area-talking-points-list" cdkDropList #listPrimary="cdkDropList" [cdkDropListData]="talkingPoints" (cdkDropListDropped)="dropDraggableTalkingPoint($event)">
    <ng-container *ngIf="(talkingPoints && talkingPoints.length > 0); else noTalkingPoints">
      <ng-container *ngFor="let talkingPoint of talkingPoints; index as index;">
        <div class="wrapper-talking-point-item" cdkDrag [cdkDragData]="point">
          <app-talking-point-item [talkingPoint]="talkingPoint" [scheduleManager]="schedule?.manager" [scheduleParticipants]="scheduleParticipants" [editing]="editPoint === talkingPoint"
            (onTalkingPointAction)="onTalkingPointActionEmit($event, index, talkingPoint)" [canReorder]="canReorder"
            [canAddOrRemove]="canAddOrRemove" [canComment]="canComment" [canAction]="canAction"
            [canEdit]="creatingSchedule" [creatingSchedule]="creatingSchedule" [displayComments]="displayComments">
            <div class="area-drag-handle" cdkDragHandle></div>
          </app-talking-point-item>
        </div>
      </ng-container>
    </ng-container>
  </div>

  <!-- Add talking point button and form -->
  <ng-container *ngIf="canAddOrRemove">
    <div class="area-create-talking-point-form">
      <div class="frankli-form-group frankli-form-group-placeholder-shown input-group" [class.frankli-form-group-invalid]="(state.submitted && controlCreateTitle.invalid) || controlCreateTitle.hasError('maxlength')">
        <span class="input-group-addon box-left">
          <span class="fal fa-fw fa-plus text-info"></span>
        </span>
        <input class="form-control" type="text" (keyup)="checkEnter($event)" [formControl]="controlCreateTitle" [placeholder]="eOneToOneMessages.TYPE_TALKING_POINT_PLACEHOLDER | translate">
        <ng-container *ngIf="controlCreateTitle.value">
          <span class="input-group-addon clickable" [title]="eOneToOneMessages.RESET_TEXT | translate" (click)="clearCreateTitle()">
            <span class="fal fa-undo"></span>
          </span>
        </ng-container>
      </div>
      <div class="text-left">
        <small>{{(controlCreateTitle) ? (eOneToOneMessages.ADD_TALKING_POINT_PROMPT | translate) : '&nbsp;'}}</small>
      </div>
      <div class="frankli-form-group-alert margin-top-create-alerts">
        <ng-container *ngIf="state.submitted && controlCreateTitle.invalid">
          <div *ngIf="controlCreateTitle.hasError('required')" translate>{{eCommonMessages.FORM_REQUIRED}}</div>
          <div *ngIf="controlCreateTitle.hasError('minlength')" translate [translateParams]="{ minCharacters: TP_MIN_LENGTH, maxCharacters: TP_MAX_LENGTH }"> {{eCommonMessages.FORM_CHARACTER_LENGTH_BETWEEN}}</div>
          <div *ngIf="controlCreateTitle.hasError('maxLength')" translate [translateParams]="{ minCharacters: TP_MIN_LENGTH, maxCharacters: TP_MAX_LENGTH }"> {{eCommonMessages.FORM_CHARACTER_LENGTH_BETWEEN}}</div>
        </ng-container>
      </div>
    </div>
  </ng-container>
  
  <!-- Previous Talking Points Display -->
  <ng-container *ngIf="false && showPreviousTalkingPoints && (talkingPointsPreviousCanBump && talkingPointsPreviousCanBump.length > 0)">
    <div class="frankli-form-group frankli-form-group-filled p-top-10 p-bottom-5">
      <label>
        <span class="fal fa-history"></span>
        <span class="m-right-5"></span>
        <span>{{eOneToOneMessages.INCOMPLETE_FROM_PREVIOUS | translate}}</span>
      </label>
    </div>
    <div class="area-talking-points-list" cdkDropList #listPrevious="cdkDropList"
      [cdkDropListData]="talkingPointsPreviousCanBump" [cdkDropListConnectedTo]="[listPrimary]">
      <ng-container *ngIf="(talkingPointsPreviousCanBump && talkingPointsPreviousCanBump.length > 0); else noTalkingPoints">
        <ng-container *ngFor="let point of talkingPointsPreviousCanBump; index as index; trackBy: trackByTalkingPointId;">
          <div class="wrapper-talking-point-item" cdkDrag [cdkDragData]="point">
            <app-talking-point-item [talkingPoint]="point" (onTalkingPointAction)="onTalkingPointActionEmit($event, index, point)" [canBump]="canBump">
              <div class="talking-point-drag-handle" cdkDragHandle></div>
            </app-talking-point-item>
          </div>
        </ng-container>
      </ng-container>
    </div>
  </ng-container>

  <ng-template #noTalkingPoints>
    <app-empty-state [height]="'200px'" [message]="eOneToOneMessages.NO_TALKING_POINTS"></app-empty-state>
  </ng-template>
</div>