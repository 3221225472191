export const UserConstants = {
  FIRSTNAME: {
    MIN: 1,
    MAX: 50
  },
  LASTNAME: {
    MIN: 1,
    MAX: 50
  },
  DESCRIPTION: {
    MIN: 1,
    SOFTMAX: 255,
    MAX: 1000
  },
  EMAIL: {
    MIN: 10,
    MAX: 320,
    PATTERN: /^(.*).@(.*).\..+$/
  },
  BIRTHDAY: {
    MIN: null
  }
};
