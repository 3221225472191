<div class="user-picker-multiple-container" *ngIf="state.isSuccessful">
  <ng-container *ngIf="value">
    <ng-container *ngFor="let user of value">
      <ng-container [ngTemplateOutlet]="userPillTemplate" [ngTemplateOutletContext]="{ user: user, position: getPosition(user), icon: 'fa-times', iconColor: '#FB946E', cursor: 'pointer', action: 'remove'}"></ng-container>
    </ng-container>
  </ng-container>
  <!-- TODO:
    Make this fill the remaining space and wrap if there's not enough.
    Might have to track widths of each selected user pill with ViewChildren.
  -->
  <div class="search-input" [class.no-selection]="(!value || (value.length === 0))">
    <input type="text" autocomplete="off" class="form-control" [placeholder]="((value && value.length > 0) ? '' : (placeholder | translate))" [formControl]="searchControl" [class.input-no-results]="(searchResults.length === 0) && searchControl.value && !searchRunning" #searchInput/>
    <div class="search-results">
      <ng-container *ngFor="let result of searchResults">
        <div class="search-results-item">
          <ng-container [ngTemplateOutlet]="userPillTemplate" [ngTemplateOutletContext]="{ user: result, position: getPosition(result), icon: 'fa-plus', iconColor: '#149189', cursor: 'pointer', action: 'add'}"></ng-container>
        </div>
      </ng-container>
    </div>
  </div>
  <span class="search-icon">
    <span class="fal fa-fw fa-search"></span>
  </span>
</div>

<ng-template let-user="user" let-position="position" let-icon="icon" let-iconColor="iconColor" let-cursor="cursor" let-action="action" #userPillTemplate>
  <div class="user-display-container" [style.cursor]="cursor" (click)="onClickUser(user, action)">
    <app-user-display [user]="user" [position]="position" [showIcon]="true" [iconClass]="icon" [iconColor]="iconColor"></app-user-display>
  </div>
</ng-template>
