import { FrankliActivity } from '@app/models/company/frankli-activity/frankli-activity.model';
import moment from 'moment';

export const mockFrankliActivity: FrankliActivity[] = [
  {
    userId: 2,
    companyId: 1,
    frankliActivityType: 'FEEDBACK_UNPROMPTED_SENT',
    activityForeignId: 1,
    timestamp: moment().subtract(1, 'week').toDate()
  },
  {
    userId: 4,
    companyId: 1,
    frankliActivityType: 'FEEDBACK_UNPROMPTED_SENT',
    activityForeignId: 2,
    timestamp: moment().subtract(1, 'week').toDate()
  },
  {
    userId: 1,
    companyId: 1,
    frankliActivityType: 'FEEDBACK_UNPROMPTED_SENT',
    activityForeignId: 3,
    timestamp: moment().subtract(1, 'week').toDate()
  },
  {
    userId: 1,
    companyId: 1,
    frankliActivityType: 'FEEDBACK_UNPROMPTED_SENT',
    activityForeignId: 4,
    timestamp: moment().subtract(1, 'week').toDate()
  }
];