import { Component, Input, OnInit } from '@angular/core';
import { FeedbackUpload } from '@app/models/feedback/feedback-upload.model';

@Component({
  selector: 'app-feedback-upload-display',
  templateUrl: './feedback-upload-display.component.html',
  styleUrls: ['./feedback-upload-display.component.scss']
})
export class FeedbackUploadDisplayComponent implements OnInit {
  @Input() feedback: FeedbackUpload;

  @Input() showSourceUser: boolean;
  @Input() showDestinationUser: boolean;

  constructor() {
    this.feedback = undefined!;

    this.showSourceUser = true;
    this.showDestinationUser = true;
  }

  ngOnInit() {
  }

}
