import {Pipe, PipeTransform} from '@angular/core';
import moment from 'moment';
import 'moment-timezone';

@Pipe({
  name: 'momentTzPipe'
})
export class MomentTimezonePipe implements PipeTransform {
  previousValue!: string;
  previousArgs!: any[];
  resultCached!: string;

  transform(value: string, ...args: any[]): any {
    // Skip calculation if value hasn't changed
    if ((value === this.previousValue) && this.resultCached && (args = this.previousArgs)) {
      return this.resultCached;
    }
    this.previousValue = value;
    this.previousArgs = args;

    const [format] = args;
    const result = moment.tz(value).format(format);

    this.resultCached = result;
    return result;
  }
}
