import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ILongRunningTaskServerside, LongRunningTask } from '@app/domain/long_running_task/model/long-running-task.model';
import { map } from 'rxjs/operators';
import { LongRunningTaskService } from '@app/domain/long_running_task/service/long-running-task.service';

@Injectable()
export class StatusAPIService {

  private readonly BASE_URL = '/api/status';

  constructor(
    private http: HttpClient,
    private longRunningTaskService: LongRunningTaskService
  ) {
  }

  getStatus(): Observable<null> {
    const url = `${this.BASE_URL}/`;
    return this.http.get<null>(url);
  }

  sendTestStatus(statusToSend: number): Observable<void> {
    const url = `${this.BASE_URL}/send-test/${statusToSend}`;
    return this.http.get<void>(url);
  }

  sendTestStatusEC(statusToSend: number): Observable<void> {
    const url = `${this.BASE_URL}/send-test-ec/${statusToSend}`;
    return this.http.get<void>(url);
  }

  testServerSideEventEmitters(): Observable<LongRunningTask> {
    const url = `${this.BASE_URL}/test-long-running-task`;
    return this.http.get<ILongRunningTaskServerside>(url)
      .pipe(
        map(task => new LongRunningTask(task)),
        map(task => {
          if (task.id) {
            this.longRunningTaskService.startTrackingTask(task.id);
          }
          return task;
        })
      );
  }

  getAvailableZoneIds(): Observable<string[]> {
    const url = `${this.BASE_URL}/available-zoneIds`;
    return this.http.get<string[]>(url);
  }

  sendTestSentryError(): Observable<void> {
    const url = `${this.BASE_URL}/test-sentry-error`;
    return this.http.get<void>(url);
  }

}
