<ng-container *ngIf="option">
  <div class="container-sentiment-scale-option" [class.show-indent]="showIndent">
    <ng-container *ngIf="!metadataInTooltip; else templateMetadataInTooltip;">
      <div class="area-score" [style.background-color]="option.colour" [class.invert-number]="shouldInvertNumber">
        <ng-container *ngIf="showNumbers">
          <span>{{option.score}}</span>
        </ng-container>
      </div>
    </ng-container>
    <ng-template #templateMetadataInTooltip>
      <div class="area-score" [style.background-color]="option.colour" [class.invert-number]="shouldInvertNumber" [tooltip]="templateDetailsTooltip" container="body">
        <ng-container *ngIf="showNumbers">
          <span>{{option.score}}</span>
        </ng-container>
      </div>
      <ng-template #templateDetailsTooltip>
        <div class="p-15 text-left">
          <h5 class="standard-heading">({{option.score}}) {{option.name}}</h5>
          <div class="standard-description">{{option.description}}</div>
          <ng-container *ngIf="lastUpdated">
            <div class="text-info"><small>Answer last updated: {{lastUpdated | momentPipe: 'lll'}}</small></div>
          </ng-container>
        </div>
      </ng-template>
    </ng-template>

    <ng-container *ngIf="showName || showDescription">
      <div class="area-details">
        <ng-container *ngIf="showName">
          <h5 class="standard-heading">{{option.name}}</h5>
        </ng-container>
        <ng-container *ngIf="showDescription">
          <div class="standard-description">{{option.description}}</div>
        </ng-container>
        <ng-container *ngIf="lastUpdated">
          <div class="text-info"><small>{{lastUpdated}}</small></div>
        </ng-container>
      </div>
    </ng-container>
  </div>
</ng-container>