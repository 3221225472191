<ng-container *ngIf="!state.loading; else stateLoading">
  <div class="modal-content-area">
    <div class="p-top-10 p-bottom-20 p-left-15 p-right-15">
      <ng-container *ngIf="linkData[selectedType.value].raw && universalFilterData">
        <app-universal-filter [filterOptions]="universalFilterData.filterOptions" [searchProps]="universalFilterData.searchProps" (resultEmit)="universalFilterEmit($event)"></app-universal-filter>
      </ng-container>
    </div>
  
    <ng-container [ngSwitch]="selectedType.value">
      <ng-container *ngSwitchCase="FeatureLinking.GOALS">
        <ng-container *ngIf="((linkData[FeatureLinking.GOALS].raw) && (linkData[FeatureLinking.GOALS].raw.length > 0)); else noOptions">
          <ng-container *ngIf="((linkData[FeatureLinking.GOALS].filtered) && (linkData[FeatureLinking.GOALS].filtered.length > 0)); else noOptions">
            <div class="goal-list">
              <ng-container *ngFor="let goal of linkData[FeatureLinking.GOALS].display; index as i">
                <div class="goal-item">
                  <app-goal-display [goal]="goal" (onSelect)="onGoalSelect(goal)" [selected]="goal.selected" [canSelect]="true"></app-goal-display>
                </div>
              </ng-container>
            </div>
          </ng-container>
          <div class="p-top-20">
            <app-pagination-new-component [arrayData]="linkData[FeatureLinking.GOALS].filtered" [arrayDisplay]="linkData[FeatureLinking.GOALS].display" #pagination></app-pagination-new-component>
          </div>
        </ng-container>
      </ng-container>
    </ng-container>
  </div>

  <div class="bottom-buttons">
    <button class="btn-frankli-round btn-frankli-green" (click)="finishRichLink()" [disabled]="selectedCount === 0" translate>{{eCommonMessages.ADD_SELECTED}}</button>
  </div>
</ng-container>

<!-- #region - ALTERNATE PAGE STATES -->
<ng-template #stateLoading>
  <app-loading-state [showMessage]="false"></app-loading-state>
</ng-template>

<ng-template #noOptions>
  <div class="text-center">
    <img class="icon-padding new-icon-height" src="/assets/img/NoSearchFound.svg" />
    <br/>
    <span>You don't have anything you can link to. Try again once a goal exists.</span>
    <div class="text-center p-top-15">
      <button class="btn-frankli-round btn-frankli-green" (click)="cancelRichLink()">Ok</button>
    </div>
  </div>
</ng-template>
<!-- #endregion -->