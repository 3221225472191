import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { CommonMessages } from '@app/constants/common.messages';
import { GoalsMessages } from '@app/goals/goals.messages';
import { GoalPriority } from '@app/models/goals/goal-priority.model';
import { Globals } from '@app/shared/globals/globals';
import { GoalUtils } from '@app/shared/utils/goal.utils';
import { PillType } from '../../pill/pill.component';

@Component({
  selector: 'app-goal-priority-display',
  templateUrl: './goal-priority-display.component.html',
  styleUrls: ['./goal-priority-display.component.scss']
})
export class GoalPriorityDisplayComponent implements OnChanges {
  public readonly eGoalPriority = GoalPriority;
  public readonly eGoalsMessages = GoalsMessages;
  public readonly eCommonMessages = CommonMessages;
  public readonly ePillType = PillType;

  @Input() priority: GoalPriority;

  priorityIcon: string;
  priorityTitleShort: string;
  priorityTooltip: string;

  constructor(
    public globals: Globals
  ) {
    this.priority = GoalPriority.NOT_SET;
    this.priorityTooltip = '';
    this.priorityTitleShort = '';
    this.priorityIcon = '';
  }

  ngOnChanges(changes: SimpleChanges): void { 
    const priority = changes['priority'];
    if (priority) {
      this.onTypeChanged(priority.currentValue);
    }
  }

  onTypeChanged(priority: GoalPriority | null): void {
    if (priority === null) {
      this.priorityTooltip = '';
      return;
    }

    this.priorityTooltip = GoalUtils.getLongMessageCodeForGoalPriority(priority);
    this.priorityTitleShort = GoalUtils.getMessageCodeForGoalPriority(priority);
    this.priorityIcon = GoalUtils.getIconClassForGoalPriority(priority);
  }
}
