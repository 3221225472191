import {Injectable} from '@angular/core';
import {CalendarConnectionStatus} from '@app/domain/one_to_one/model/calendar-connected.model';
import {CalendarAPIService} from '@app/shared/api/calendar.api.service';
import {UserAPIService} from '@app/shared/api/user.api.service';
import {Globals} from '@app/shared/globals/globals';
import {NotifyUtils} from '@app/shared/utils/notify.utils';
import {Observable} from 'rxjs';
import {ConnectionSettingsAPIService} from '../../api/connection-settings/connection-settings-api.service';
import {SettingsMessages} from '../../locale/settings.messages';
import {ConnectionSettingsDto} from '../../model/connection-settings.model';
import {AuthAPIService} from '@app/shared/auth/auth.api.service';
import {CompanyFeatures} from "@models/company-features.model";

@Injectable({
  providedIn: 'root'
})
export class ConnectionSettingsBusinessService {

  private updatingConnectionSettings: boolean;

  constructor(
    private connectionSettingsAPIService: ConnectionSettingsAPIService,
    private userAPIService: UserAPIService,
    private calendarAPIService: CalendarAPIService,
    private authAPIService: AuthAPIService,
    private notifyUtils: NotifyUtils,
    public globals: Globals
  ) {
    this.updatingConnectionSettings = false;
  }

  get(): Observable<ConnectionSettingsDto> {
    return this.connectionSettingsAPIService.get();
  }

  update(userConnectionSettings: ConnectionSettingsDto): Promise<ConnectionSettingsDto> {
    if (this.updatingConnectionSettings) {
      return new Promise((resolve, reject) => reject());
    }
    this.updatingConnectionSettings = true;

    return this.connectionSettingsAPIService.update(userConnectionSettings).toPromise()
      .then(res => {
        console.log(res);
        this.notifyUtils.notify(SettingsMessages.SUCCESS_PROFILE_UPDATED);
        this.globals.user.configuration.notificationsViaEmailEnabled = res.notificationsViaEmailEnabled;
        this.globals.user.configuration.notificationsViaSlackEnabled = res.notificationsViaSlackEnabled;
        this.globals.user.configuration.notificationsViaTeamsEnabled = res.notificationsViaTeamsEnabled;
        return res;
      })
      .then(res => {
        this.isCalendarConnected().subscribe(resp => {
          if (resp.connected && resp.provider === 'GOOGLE' && this.globals.hasFeature(CompanyFeatures.SSO_GOOGLE)) {
            if (!userConnectionSettings.googleLinked) {
              this.authAPIService.revokeMyGoogleAccess().subscribe(response => {
                if (response === 1) {
                  this.notifyUtils.notify('Google Account Access Revoked successfully');
                  this.authAPIService.logout();
                }
              });
            }
          }
        });

        return res;
      })
      .finally(() => {
        this.updatingConnectionSettings = false;
      });
  }

  isSlackAccountLinked(): Observable<boolean> {
    return this.userAPIService.isSlackAccountLinked();
  }

  isTeamsAccountLinked(): Observable<boolean> {
    return this.userAPIService.isTeamsAccountLinked();
  }

  isCalendarConnected(): Observable<CalendarConnectionStatus> {
    return this.calendarAPIService.isConnected();
  }
}
