<div class="p-relative">
    <button
            class="btn-frankli-round btn-frankli-small btn-frankli-green-inverted col-toggle-button dropdown-toggle"
            type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        <span class="fal fa-fw fa-columns"></span>
    </button>
    <div class="dropdown-menu col-toggle-dropdown" aria-labelledby="dropdownMenuButton">
        <button class="no-styles">
            <div class="text-center"><h5 translate>{{eColumnToggleNewMessages.DISPLAYED_COLUMNS}}</h5></div>
            <div class="clickable check-container" (click)="toggleAll($event)">
                <input type="checkbox" [checked]="checkAllEnabled()"/>
                <label translate>{{eCommonMessages.ALL}}</label>
            </div>
            <hr/>
            <ng-container *ngFor="let c of columns">
                <div class="clickable check-container" (click)="toggleColumn($event, c.key)">
                    <input [id]="c.key" type="checkbox" [checked]="c.enabled" [disabled]="alwaysEnabled.includes(c.key)"/>
                    <label>{{c.titleMessageCode | translate}}</label>
                </div>
            </ng-container>
        </button>
    </div>
</div>