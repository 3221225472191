import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { AbsorbLmsAPIService } from '@app/domain/absorblms/api/absorblms-api.service';
import { AbsorbLmsEnrollmentStatus } from '@app/domain/absorblms/model/absorblms-enrollment-status.model';
import { AbsorbLmsEnrollment } from '@app/domain/absorblms/model/absorblms-enrollment.model';
import { OneToOneScheduleDetailsView } from '@app/domain/one_to_one/model/one-to-one-schedule.model';
import { IState } from '@app/models/state/state.model';
import { PaginationNewComponent } from '@app/shared/pagination/pagination-new/pagination-new.component';

type TabEntity = AbsorbLmsEnrollment;

@Component({
  selector: 'app-information-sidebar-absorblms',
  templateUrl: './information-sidebar-absorblms.component.html',
  styleUrls: ['./information-sidebar-absorblms.component.scss']
})
export class InformationSidebarAbsorblmsComponent implements OnInit {
  
  @ViewChild('pagination') pagination?: PaginationNewComponent;

  @Input() userIds: number[];

  state: IState;
  data: {
    master: TabEntity[];
    filtered: TabEntity[];
    display: TabEntity[];
  };

  formGroup: FormGroup;

  dataEnrollmentStatuses: AbsorbLmsEnrollmentStatus[];

  get controlStatus(): FormControl {
    return this.formGroup.controls.status as FormControl;
  }

  constructor(
    private absorbLmsApiService: AbsorbLmsAPIService
  ) {
    this.state = {
      loading: true,
      error: false,
      errorMessage: ''
    };

    this.data = {
      master: [],
      filtered: [],
      display: []
    };

    this.dataEnrollmentStatuses = Object.values(AbsorbLmsEnrollmentStatus);
    this.formGroup = this.initForm(null);
  }

  ngOnInit(): void {
    this.getData(this.controlStatus.value);
  }


  initForm(status: AbsorbLmsEnrollmentStatus): FormGroup {
    const formGroup = new FormGroup({
      status: new FormControl(null)
    });

    if (status) {
      formGroup.controls.status.setValue(status, { emitEvent: false });
    }

    formGroup.valueChanges.subscribe(val => {
      this.getData(val.status);
    });

    return formGroup;
  }
  
  getData(status: AbsorbLmsEnrollmentStatus): void {
    const userId = this.userIds[0];
    if (!userId) { return; }

    this.absorbLmsApiService.getDataForUser(userId)
      .toPromise()
      .then(userData => {
        this.dataEnrollmentStatuses = this.getUniqueEnrollmentStatusesFromUserData(userData.enrollments);
        const enrollments = this.filterEnrollments(userData.enrollments, status);
        this.populateData(enrollments);
      })
      .finally(() => {
        this.state.loading = false;
      });
  }

  getQueryUserId(schedule: OneToOneScheduleDetailsView, userIds: number[]): number {
    try {
      if (schedule) {
        if (schedule.participants.length === 1) {
          return schedule.participants[0].id;
        }
      } {
        return userIds[0];
      }
    } catch (e) {
      console.error('[AbsorbLMS sidebar] Error getting query paramater userId:', e);
      return null;
    }
  }

  getUniqueEnrollmentStatusesFromUserData(enrollments: AbsorbLmsEnrollment[]): AbsorbLmsEnrollmentStatus[] {
    const statuses: AbsorbLmsEnrollmentStatus[] = [];
    enrollments.forEach(enrollment => {
      if (!statuses.includes(enrollment.status)) {
        statuses.push(enrollment.status);
      }
    });
    return statuses;
  }
  
  filterEnrollments(enrollments: AbsorbLmsEnrollment[], status: AbsorbLmsEnrollmentStatus): AbsorbLmsEnrollment[] {
    if (!status) { return enrollments; }
    return enrollments.filter(enrollment => enrollment.status === status);
  }

  populateData(data?: TabEntity[]): void {
    if (!data) { data = this.data.master; }
    this.data.master = data;
    this.data.filtered = data;

    // this.universalFilterData.searchProps = ['name'];
    // this.universalFilterData.filterOptions = data.map(i => this.getFilterOptionsForBrowseRole(i));

    this.refreshPagination();
  }

  refreshPagination(): void {
    if (this.pagination) {
      this.pagination.update();
    }
  }
}
