import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IMicrosoftGraphAPI } from './microsoft-graph-api.model';

@Injectable()
export class MicrosoftGraphAPIService implements IMicrosoftGraphAPI {
  private readonly BASE_URL: string = 'api/microsoftgraph';

  constructor(
    private http: HttpClient
  ) { }

  getSubscriptionsForUserId(userId: number): Observable<any> {
    const url = `${this.BASE_URL}/subscription/user/${userId}`;
    return this.http.get(url);
  }

  deleteSubscriptionById(subscriptionId: string): Observable<any> {
    const url = `${this.BASE_URL}/subscription/${subscriptionId}`;
    return this.http.delete(url);
  }
}
