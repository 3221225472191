import { User, UserServerSide } from '@app/models/user/user.model';
import { ISODateString } from '@app/shared/utils/date-string.util';
import moment from 'moment';
import { TaskActivity, TaskActivityServerSide } from './task-activity.model';
import { TaskAlignmentType } from './task-alignment-type.enum';
import { TaskComment, TaskCommentServerSide } from './task-comment.model';
import { TaskCreatedFrom } from './task-created-from.model';
import { TaskStatus } from './task-status.enum';

export interface TaskServerSide {
  id: number;
  creator: UserServerSide;
  title: string;
  description: string | null;
  dueDate: ISODateString | null;
  creationDate: ISODateString;
  completionDate: ISODateString | null;
  status: TaskStatus;
  alignmentType: TaskAlignmentType;
  alignmentId: number | null;
  archived: boolean;
  owners: UserServerSide[];
  activity: TaskActivityServerSide[];
  comments: TaskCommentServerSide[];
  linkedPositionIds: number[];
  createdFrom: TaskCreatedFrom;
}

export class Task {
  id: number;
  creator: User;
  title: string;
  description: string | null;
  dueDate: Date | null;
  creationDate: Date;
  completionDate: Date | null;
  status: TaskStatus;
  alignmentType: TaskAlignmentType;
  alignmentId: number | null;
  archived: boolean;
  owners: User[];
  activity: TaskActivity[];
  comments: TaskComment[];
  linkedPositionIds: number[];
  createdFrom: TaskCreatedFrom;

  constructor(taskServerSide: TaskServerSide) {
    this.id = taskServerSide.id;
    this.creator = new User(taskServerSide.creator);
    this.title = taskServerSide.title;
    this.description = taskServerSide.description;
    this.dueDate = taskServerSide.dueDate === null ? null : moment.utc(taskServerSide.dueDate).toDate();
    this.creationDate = moment.utc(taskServerSide.creationDate).toDate();
    this.completionDate = taskServerSide.completionDate === null ? null : moment.utc(taskServerSide.completionDate).toDate();
    this.status = taskServerSide.status;
    this.alignmentType = taskServerSide.alignmentType;
    this.alignmentId = taskServerSide.alignmentId;
    this.archived = taskServerSide.archived;
    this.owners = taskServerSide.owners.map(o => new User(o));
    this.activity = taskServerSide.activity.map(t => new TaskActivity(t));
    this.comments = taskServerSide.comments.map(c => new TaskComment(c));
    this.linkedPositionIds = taskServerSide.linkedPositionIds;
    this.createdFrom = taskServerSide.createdFrom;
  }

}