import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Angulartics2Module } from 'angulartics2';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { GoalCreatePartTypeComponent } from './goal-create-part-type/goal-create-part-type.component';
import { GoalCreatePartDetailsComponent } from './goal-create-part-details/goal-create-part-details.component';
import { GoalCreatePartKeyResultsComponent } from './goal-create-part-key-results/goal-create-part-key-results.component';
import { GoalCreatePartOwnersComponent } from './goal-create-part-owners/goal-create-part-owners.component';
import { GoalCreatePartAlignmentComponent } from './goal-create-part-alignment/goal-create-part-alignment.component';
import { GoalCreatePartPriorityComponent } from './goal-create-part-priority/goal-create-part-priority.component';
import { GoalCreatePartTasksComponent } from './goal-create-part-tasks/goal-create-part-tasks.component';
import { GoalCreatePartTagsComponent } from './goal-create-part-tags/goal-create-part-tags.component';
import { SharedModule } from '@app/shared/shared.module';
import { GoalCreateKeyResultItemComponent } from './goal-create-part-key-results/goal-create-key-result-item/goal-create-key-result-item.component';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { UserAPIService } from '@app/shared/api/user.api.service';
import { GoalCreateTaskItemComponent } from './goal-create-part-tasks/goal-create-task-item/goal-create-task-item.component';
import { GoalCreatePartDevelopmentNeedsComponent } from './goal-create-part-development-needs/goal-create-part-development-needs.component';
import { GoalCreatePartSiteComponent } from './goal-create-part-site/goal-create-part-site.component';
import { GoalCreatePartDepartmentComponent } from './goal-create-part-department/goal-create-part-department.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { TranslateModule } from '@ngx-translate/core';

const components = [
  GoalCreatePartTypeComponent,
  GoalCreatePartDetailsComponent,
  GoalCreatePartKeyResultsComponent,
  GoalCreatePartOwnersComponent,
  GoalCreatePartAlignmentComponent,
  GoalCreatePartPriorityComponent,
  GoalCreatePartTagsComponent,
  GoalCreatePartTasksComponent,
  GoalCreateKeyResultItemComponent,
  GoalCreateTaskItemComponent,
  GoalCreatePartDevelopmentNeedsComponent,
  GoalCreatePartSiteComponent,
  GoalCreatePartDepartmentComponent
]

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    Angulartics2Module,
    SharedModule,
    TooltipModule,
    DragDropModule,
    TranslateModule
  ],
  providers: [
    UserAPIService
  ],
  declarations: components,
  exports: components
})
export class GoalCreateFormPartsModule { }

