import { Injectable } from '@angular/core';
import { AdminMessages } from '@app/admin/admin.messages';
import { TagType } from '@app/domain/tag/model/tag-type.model';
import { CreateTagDto } from '@app/models/tags/create-tag.model';
import { Tag } from '@app/domain/tag/model/tag.model';
import { Globals } from '@app/shared/globals/globals';
import { NotifyUtils } from '@app/shared/utils/notify.utils';
import { Observable } from 'rxjs';
import { TagAPIService } from './tag-api.service';
import { SwalUtils } from '@app/shared/utils/swal.utils';

@Injectable({
  providedIn: 'root'
})
export class TagBusinessService {

  updating: number[];

  constructor(
    public globals: Globals,
    private tagAPI: TagAPIService,
    private notifyUtils: NotifyUtils,
    private swalUtils: SwalUtils
  ) {
    this.updating = [];
  }

  private startUpdating(ids: number[]) {
    this.updating = [...this.updating, ...ids];
  }

  private stopUpdating(ids: number[]) {
    this.updating = this.updating.filter(x => !ids.includes(x));
  }

  get(
    ids: number[],
    name: string,
    type: TagType[]
  ): Observable<Tag[]> {
    return this.tagAPI.get(ids, name, type);
  }

  create(createDto: CreateTagDto): Promise<Tag> {
    return this.tagAPI.create(createDto).toPromise()
      .then(res => {
        this.notifyUtils.notify(AdminMessages.TAGS.SUCCESS_TAG_CREATE);
        return res;
      });
  }

  update(id: number, updateDto: CreateTagDto): Promise<Tag> {
    if (this.updating.includes(id)) { return new Promise((resolve, reject) => reject()); }
    this.startUpdating([id]);

    return this.tagAPI.update(id, updateDto).toPromise()
      .then(res => {
        this.notifyUtils.notify(AdminMessages.TAGS.SUCCESS_TAG_UPDATE);
        return res;
      })
      .finally(() => {
        this.stopUpdating([id]);
      });
  }

  delete(id: number): Promise<Tag> {
    if (this.updating.includes(id)) { return new Promise((resolve, reject) => reject()); }
    this.startUpdating([id]);

    return this.tagAPI.delete(id).toPromise()
      .then(res => {
        this.notifyUtils.notify(AdminMessages.TAGS.SUCCESS_DELETE_TAG_SINGLE);

        if (this.globals.user.position && this.globals.user.position.id === id) {
          this.globals.user.position = null;
        }

        return res;
      })
      .finally(() => {
        this.stopUpdating([id]);
      });
  }

  deleteMultiple(ids: number[]): Promise<Tag[]> {
    if (this.updating.some(id => ids.includes(id))) { return new Promise((resolve, reject) => reject()); }
    this.startUpdating(ids);

    return this.tagAPI.deleteMultiple(ids).toPromise()
      .then(res => {
        this.notifyUtils.notify(AdminMessages.TAGS.SUCCESS_DELETE_TAG_MULTIPLE);

        if (this.globals.user.position && ids.includes(this.globals.user.position.id)) {
          this.globals.user.position = null;
        }

        return res;
      })
      .finally(() => {
        this.stopUpdating(ids);
      });
  }
}
