import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FilterCategory } from '@app/shared/dynamic-filter/interfaces/filter-category';
import { FilterOption } from '@app/shared/dynamic-filter/interfaces/filter-option';
import { SelectedFilter } from '@app/shared/dynamic-filter/interfaces/selected-filter';
import { SearchMode } from '@app/shared/dynamic-filter/types/search-mode';
import { FormControl } from '@angular/forms';
import { Observable, of, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, mergeMap, startWith, takeUntil } from 'rxjs/operators';
import { UserAPIService } from '@app/shared/api/user.api.service';
import { staticUserPagingParams, staticUserSortingParams } from '@app/shared/utils/api-filter/static-user-params';
import { ParentFilter } from '@app/models/api/parent-filter.model';
import { FilterOperator } from '@app/models/api/filter-operator.enum';
import { ChildFilter } from '@app/models/api/child-filter.model';
import { CriterionOperator } from '@app/models/api/criterion-operator.enum';
import { Globals } from '@app/shared/globals/globals';
import { CompanyAPIService } from '@app/shared/api/company/company.api.service';
import { staticDepartmentPagingParams, staticDepartmentSortingParams } from '@app/shared/utils/api-filter/static-department-params';
import { staticOfficeLocationPagingParams, staticOfficeLocationSortingParams } from '@app/shared/utils/api-filter/static-office-location-params';
import { staticPositionPagingParams, staticPositionSortingParams } from '@app/shared/utils/api-filter/static-position-params';
import { UniversalFilterMessages } from 'app/shared/universal-filter/universal-filter.messages';
import { RoleBusinessService } from '@app/domain/role/service/role-business.service';
import { OneToOneAPIService } from '@app/domain/one_to_one/api/one-to-one-api.service';
import { staticTalkingPointTemplatePagingParams, staticTalkingPointTemplateSortingParams } from '@app/shared/utils/api-filter/static-talking-point-template-params';
import { DateRangePickerValue } from '@app/shared/utils/date-range-picker/date-range-picker.component';
import moment from 'moment';
import { SentimentScaleAPIService } from '@app/domain/sentiment_scale/api/sentiment-scale-api.service';

const SEARCH_MODES_WITH_CUSTOM_CONTROLS = [
  SearchMode.DATE,
  SearchMode.DATE_RANGE
];

@Component({
  selector: 'app-dynamic-filter-category-item',
  templateUrl: './dynamic-filter-category-item.component.html',
  styleUrls: ['./dynamic-filter-category-item.component.scss']
})
export class DynamicFilterCategoryItemComponent implements OnInit, OnDestroy {
  public readonly eUniversalFilterMessages = UniversalFilterMessages;
  public readonly eSearchMode = SearchMode;

  private readonly ngUnsubscribe$ = new Subject<void>();

  @Input() category: FilterCategory;
  @Input() selectedFiltersForCategory: SelectedFilter[];
  @Output() onOptionToggled: EventEmitter<FilterOption>;

  searchControl: FormControl;
  dateRangePickerControl: FormControl;
  searchPlaceholder: string;
  displayedOptions: Observable<FilterOption[]>;
  searchChildFilter: ChildFilter;
  parentFilter: ParentFilter;
  searchRunning: boolean;

  pageSizeDisplay = 0;
  totalElements = 0;

  get hasCustomControl(): boolean {
    if (!this.category) { return false; }
    if (this.category.searchMode === SearchMode.NONE) { return false; }
    if (!SEARCH_MODES_WITH_CUSTOM_CONTROLS.includes(this.category.searchMode)) { return false; }
    return true;
  }

  get isValueSelected(): boolean {
    if (!this.category) { return false; }
    switch(this.category.searchMode) {
      case SearchMode.DATE_RANGE:
        return this.dateRangePickerHasValueSelected;
      default:
        if (!this.selectedFiltersForCategory) { return false; }
        if (this.selectedFiltersForCategory.length === 0) { return false; }
        return true;
    }
  }

  get dateRangePickerHasValueSelected(): boolean {
    if (!this.dateRangePickerControl) { return false; }
    if (!this.dateRangePickerControl.value) { return false; }
    if (!this.dateRangePickerControl.value.start) { return false; }
    if (this.dateRangePickerControl.value.start == null) { return false; }
    if (!this.dateRangePickerControl.value.end) { return false; }
    if (this.dateRangePickerControl.value.end == null) { return false; }
    return true;
  }

  get hasSearchBox(): boolean {
    if (!this.category) { return false; }
    if (this.category.searchMode === SearchMode.NONE) { return false; }
    return true;
  }

  constructor(
    private userApiService: UserAPIService,
    private companyApiService: CompanyAPIService,
    private roleBusinessService: RoleBusinessService,
    private oneToOneAPIService: OneToOneAPIService,
    private sentimentScaleAPIService: SentimentScaleAPIService,
    public globals: Globals
  ) {
    this.searchPlaceholder = 'Search here...';

    this.selectedFiltersForCategory = [];
    this.displayedOptions = new Subject<FilterOption[]>();

    this.searchControl = new FormControl(null);

    this.onOptionToggled = new EventEmitter<FilterOption>();

    this.category = undefined!;

    this.searchChildFilter = {
      operator: FilterOperator.AND,
      filterCriteria: []
    };
    this.parentFilter = {
      operator: FilterOperator.AND,
      childFilters: [this.searchChildFilter]
    };

    this.searchRunning = true;

    this.dateRangePickerControl = this.initDateRangePickerControl();
  }

  ngOnInit() {
    this.displayedOptions = this.searchControl.valueChanges
      .pipe(
        startWith((this.category ? this.category.options : [])),
        takeUntil(this.ngUnsubscribe$),
        debounceTime(500),
        map(sarg => this.parseSearchArgument(sarg)),
        mergeMap((sarg) => {
          this.searchRunning = false;
          return this.search(sarg);
        })
      );

    setTimeout(() => {
      this.searchControl.setValue(''); // Perform a search if there are no options just to populate the dropdown a bit
    }, 1);
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.unsubscribe();
  }

  toggleOption(option: FilterOption): void {
    this.onOptionToggled.emit(option);
  }

  filterIsSelected(category: FilterCategory, option: FilterOption): boolean {
    return this.selectedFiltersForCategory.some(sf => sf.option.value === option.value && sf.category.fieldName === category.fieldName && sf.option.range === option.range);
  }

  search(searchArgument: string): Observable<FilterOption[]> {
    switch (this.category.searchMode) {
      case SearchMode.LOCAL:
        return this.searchLocal(searchArgument);
      case SearchMode.USER:
        return this.searchUsers(searchArgument);
      case SearchMode.DEPARTMENT:
        return this.searchDepartments(searchArgument);
      case SearchMode.OFFICE_LOCATION:
        return this.searchOfficeLocations(searchArgument);
      case SearchMode.POSITION:
        return this.searchPositions(searchArgument);
      case SearchMode.TALKING_POINT_TEMPLATE:
        return this.searchTalkingPointTemplates(searchArgument);
      case SearchMode.SENTIMENT_SCALE:
        return this.searchSentimentScales(searchArgument);
      case SearchMode.NONE:
      default:
        return of(this.category.options);
    }
  }

  searchLocal(searchArgument: string): Observable<FilterOption[]> {
    const results = this.category.options.filter(option => option.displayName.toLowerCase().includes(searchArgument));
    this.pageSizeDisplay = results.length;
    this.totalElements = this.category.options.length;
    return of(results);
  }

  searchUsers(searchArgument: string): Observable<FilterOption[]> {
    this.searchChildFilter.filterCriteria = [
      {
        field: 'name',
        operator: CriterionOperator.LIKE,
        value: searchArgument
      }
    ];

    if (this.category.searchFilters) {
      this.parentFilter.childFilters = [this.searchChildFilter, ...this.category.searchFilters];
    }

    return this.userApiService.searchUsersPaginated(staticUserPagingParams, staticUserSortingParams, this.parentFilter)
      .pipe(
        map(userPage => {
          this.pageSizeDisplay = userPage.pageable.pageSize;
          this.totalElements = userPage.totalElements;
          return userPage.content.map(user => ({
            displayName: `${user.firstName} ${user.lastName}`,
            value: user.id.toString()
          }));
        })
      );
  }

  searchDepartments(searchArgument: string): Observable<FilterOption[]> {
    return this.companyApiService.searchDepartments(searchArgument, staticDepartmentPagingParams, staticDepartmentSortingParams)
      .pipe(
        map(departmentPage => {
          this.pageSizeDisplay = departmentPage.pageable.pageSize;
          this.totalElements = departmentPage.totalElements;
          return departmentPage.content.map(department => ({
            displayName: `${department.name}`,
            value: department.id.toString()
          }));
        })
      );
  }

  searchOfficeLocations(searchArgument: string): Observable<FilterOption[]> {
    return this.companyApiService.searchOfficeLocations(searchArgument, staticOfficeLocationPagingParams, staticOfficeLocationSortingParams)
      .pipe(
        map(officeLocationPage => {
          this.pageSizeDisplay = officeLocationPage.pageable.pageSize;
          this.totalElements = officeLocationPage.totalElements;
          return officeLocationPage.content.map(officeLocation => ({
            displayName: `${officeLocation.name}`,
            value: officeLocation.id.toString()
          }));
        })
      );
  }

  searchPositions(searchArgument: string): Observable<FilterOption[]> {
    return this.roleBusinessService.getPaginated(staticPositionPagingParams, staticPositionSortingParams, null, searchArgument, null, null)
      .pipe(
        map(positionPage => {
          this.pageSizeDisplay = positionPage.pageable.pageSize;
          this.totalElements = positionPage.totalElements;
          return positionPage.content.map(position => ({
            displayName: `${position.name}`,
            value: position.id.toString()
          }));
        })
      );
  }

  searchTalkingPointTemplates(searchArgument: string): Observable<FilterOption[]> {
    return this.oneToOneAPIService.searchTalkingPointTemplates(staticTalkingPointTemplatePagingParams, staticTalkingPointTemplateSortingParams, searchArgument)
      .pipe(
        map(talkingPointTemplatePage => {
          this.pageSizeDisplay = talkingPointTemplatePage.pageable.pageSize;
          this.totalElements = talkingPointTemplatePage.totalElements;
          return talkingPointTemplatePage.content.map(talkingPointTemplate => ({
            displayName: `${talkingPointTemplate.name}`,
            value: talkingPointTemplate.id.toString()
          }));
        })
      );
  }

  searchSentimentScales(searchArgument: string): Observable<FilterOption[]> {
    return this.sentimentScaleAPIService.search(searchArgument, true)
      .pipe(
        map(sentimentScalePage => {
          this.pageSizeDisplay = sentimentScalePage.pageable.pageSize;
          this.totalElements = sentimentScalePage.totalElements;
          return sentimentScalePage.content.map(sentimentScale => ({
            displayName: `${sentimentScale.name}`,
            value: sentimentScale.id.toString()
          }));
        })
      );
  }

  parseSearchArgument(rawSearchArgument: string): string {
    let searchArgument: string = rawSearchArgument;
    if (!searchArgument) {
      searchArgument = '';
    }
    return searchArgument.trim().toLowerCase();
  }

  initDateRangePickerControl(min?: Date, max?: Date): FormControl {
    if (!min) {
      min = moment().subtract(6, 'month').toDate();
    }

    if (!max) {
      max = moment().toDate();
    }

    const formControl = new FormControl({
      start: min,
      end: max,
      label: 'Default Range'
    }, []);

    formControl.valueChanges
      .pipe(distinctUntilChanged())
      .subscribe(dateRange => this.onDateRangeChanged(dateRange));

    return formControl;
  }

  onDateRangeChanged(dateRange: DateRangePickerValue): void {
    // Parse date range start and end to UTC
    dateRange.start = moment(dateRange.start).utc().toDate();
    dateRange.end = moment(dateRange.end).utc().toDate();

    if (!this.category) { return; }
    if (this.category.searchMode !== SearchMode.DATE_RANGE) { return; }
    const option: FilterOption = {
      displayName: 'timePeriod',
      range: {
        min: dateRange.start.toISOString(),
        max: dateRange.end.toISOString()
      }
    };

    this.onOptionToggled.emit(option);
  }
}
