import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { EvaluationCycle } from '@app/models/evaluation/evaluation-cycle.model';
import { ReviewStepSummary } from '@app/models/evaluation/peer-review-summary/review-step-summary.model';
import { UserMinimal } from '@app/models/user/user-minimal.model';

@Component({
  selector: 'app-review-step-summary-display',
  templateUrl: './review-step-summary-display.component.html',
  styleUrls: ['./review-step-summary-display.component.scss']
})
export class ReviewStepSummaryDisplayComponent implements OnInit {

  @Input() cycle: EvaluationCycle;
  @Input() summary: ReviewStepSummary;
  @Input() authorUser: UserMinimal;
  @Input() startExpanded: boolean;

  @Output() onDropdownOpened: EventEmitter<number>;

  dropdownOpen: boolean;

  constructor(
  ) {
    this.cycle = undefined;
    this.summary = undefined;
    this.startExpanded = false;

    this.dropdownOpen = false;

    this.onDropdownOpened = new EventEmitter<number>();
  }

  ngOnInit(): void {
    if (this.startExpanded) {
      this.expandDropdown();
    }
  }

  toggleDropdown(): void {
    if (this.dropdownOpen) {
      this.collapseDropdown();
    } else {
      this.expandDropdown();
    }
  }

  expandDropdown(): void {
    this.onDropdownOpened.emit(this.summary.id);
    this.dropdownOpen = true;
  }

  collapseDropdown(): void {
    this.dropdownOpen = false;
  }
}
