import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { OneToOneMeeting } from '@app/domain/one_to_one/model/one-to-one-meeting.model';
import { Globals } from '@app/shared/globals/globals';
import { OneToOneMessages } from '@app/domain/one_to_one/locale/one-to-one.messages';
import { CommonMessages } from '@app/constants/common.messages';
import { OneToOneNoteMinimal } from '../../model/one-to-one-note.model';
import { OneToOneScheduleDetailsView } from '../../model/one-to-one-schedule.model';
import { ButtonType } from '@app/shared/components/inputs/button/button.component';
import { IState } from '@app/models/state/state.model';
import { OneToOneBusinessService } from '../../service/one-to-one-business.service';
import { IconHoverColor } from '@app/shared/components/inputs/table-action-icon/table-action-icon.component';
import { OneToOneMeetingStatus } from '../../model/one-to-one-meeting-status.model';
import { OneToOneStatus } from '../../model/one-to-one-status.model';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

interface PageState extends IState {
  viewingNotes: boolean;
  addingNote: boolean;
  noteAlreadyAdded: boolean;
}

const editorConfig = {
  toolbar: 'undo redo | formatselect bold italic underline strikethrough | numlist bullist | outdent indent | alignleft aligncenter alignright | link btnFrankliLink',
  plugins: 'wordcount link autolink frankliRichLink'
};

@Component({
  selector: 'app-private-notes',
  templateUrl: './private-notes.component.html',
  styleUrls: ['./private-notes.component.scss']
})
export class PrivateNotesComponent implements OnChanges {
  public readonly eOneToOneMessages = OneToOneMessages;
  public readonly eIconHoverColor = IconHoverColor;
  public readonly eCommonMessages = CommonMessages;
  public readonly eEditorConfig = editorConfig;
  public readonly eButtonType = ButtonType;
  public readonly MAX_CHARACTERS = 5000;
  
  @Input() schedule: OneToOneScheduleDetailsView;
  @Input() meeting: OneToOneMeeting;
  
  state: PageState;
  controlNotes: FormControl;
  privateNotes: OneToOneNoteMinimal;

  get canAddNotes(): boolean {
    if (!this.schedule) { return false; }
    if (this.schedule.status === OneToOneStatus.ARCHIVED) { return false; }
    if (!this.meeting) { return false; }
    if (this.meeting.status === OneToOneMeetingStatus.MISSED) { return false; }
    if (this.meeting.status === OneToOneMeetingStatus.CANCELLED) { return false; }
    return true;
  }

  constructor(
    public globals: Globals,
    private oneToOneBusinessService: OneToOneBusinessService
  ) {
    this.schedule = undefined;
    this.meeting = undefined;

    this.state = {
      loading: true,
      error: false,
      errorMessage: '',
      viewingNotes: true,
      addingNote: false,
      noteAlreadyAdded: false
    };

    this.controlNotes = this.initControlNotes();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.meeting) {
      this.onChangeMeeting(changes.meeting.currentValue);
    }
  }

  onChangeMeeting(meeting: OneToOneMeeting): void {
    if (!meeting) { return; }

    this.getPrivateNotes();
  }

  getPrivateNotes(): void {
    this.oneToOneBusinessService.getMeetingPrivateNotes(this.schedule.id, this.meeting.id)
      .then(privateNotes => {
        this.privateNotes = privateNotes;
        
        if (privateNotes) {
          this.controlNotes = this.initControlNotes(privateNotes.contents);
          this.state.noteAlreadyAdded = true;
        } else {
          this.controlNotes = this.initControlNotes();
          this.state.noteAlreadyAdded = false;
        }

        this.state.loading = false;
      });
  }

  initControlNotes(notesContent?: string): FormControl {
    const formControl = new FormControl('', [Validators.maxLength(10000)]);

    if (notesContent) {
      formControl.setValue(notesContent);
    }

    formControl.valueChanges
      .pipe(debounceTime(2000))
      .pipe(distinctUntilChanged())
      .subscribe(() => {
        this.submitAddingNote(true);
      });

    return formControl;
  }

  submitAddingNote(autosave = false): void {
    if (this.controlNotes.pristine) {
      this.state.addingNote = false;
      return;
    }
    const newNotes = this.controlNotes.value;
    if (newNotes === '') { return; }

    this.oneToOneBusinessService.addMeetingPrivateNotes(this.schedule.id, this.meeting.id, newNotes)
      .then(privateNote => {
        this.privateNotes = privateNote;
        this.state.noteAlreadyAdded = true;

        if (!autosave) {
          this.state.addingNote = false;
        }

        setTimeout(() => {
          this.controlNotes.markAsPristine();
        }, 100);
      });
  }

  toggleNotesList(): void {
    this.state.viewingNotes = !this.state.viewingNotes;
  }

  startAddingNote(): void {
    this.state.addingNote = true;
  }

  cancelAddingNote(): void {
    this.state.addingNote = false;
  }

}
