<div class="container-sidebar-schedule-calendar">
  <app-state [state]="state">
    <ng-container *ngIf="!state.loading && !state.error;">
      <ng-container *ngIf="schedule; else templateNoData;">
        <div class="container-button-group">
          <app-button-group [formControl]="controlTab" [options]="buttonGroupOptions" [style]="'dark'" [consistentWidths]="true"></app-button-group>
        </div>

        <ng-container [ngSwitch]="controlTab.value">
          <ng-container *ngSwitchCase="eScheduleTabType.CALENDAR">
            <div class="wrapper-date-picker">
              <app-date-picker [formControl]="controlDatePicker" [inline]="true" [markDates]="markedDates" [enableDates]="meetingDates" [disableSince]="DISABLE_SINCE"></app-date-picker>
              <!-- TODO: Add legend for this -->
            </div>
          </ng-container>
          <ng-container *ngSwitchCase="eScheduleTabType.LIST">
            <div class="schedule-meeting-list">
              <ng-container *ngFor="let meetingItem of schedule.meetingList">
                <div class="schedule-meeting-item" [class.schedule-meeting-item-selected]="meetingNavigationControl.value === meetingItem.id" (click)="onClickMeetingItem(meetingItem)">
                  <ng-container *ngIf="schedule.frequency !== eOneToOneFrequency.Flexible; else templateMeetingDateFlexible;">
                    <div>{{meetingItem.meetingTimestamp | momentPipe: 'dddd, DD MMMM YYYY @ H:mm'}}</div>
                  </ng-container>
                  <ng-template #templateMeetingDateFlexible>
                    <ng-container *ngIf="meetingItem.meetingTimestamp; else templateFlexibleMeetingIncomplete;">
                      <div>{{meetingItem.meetingTimestamp | momentPipe: 'dddd, DD MMMM YYYY @ H:mm'}}</div>
                    </ng-container>
                    <ng-template #templateFlexibleMeetingIncomplete>
                      <div>{{eOneToOneMessages.NEXT_MEETING | translate}}</div>
                    </ng-template>
                  </ng-template>
                </div>
              </ng-container>
            </div>
          </ng-container>
        </ng-container>
      </ng-container>

      <ng-template #templateNoData>
        <app-empty-state [height]="'200px'" [imageSrc]="'assets/img/empty-state/empty-state-one-to-ones.svg'" [message]="'No schedule provided'"></app-empty-state>
      </ng-template>
    </ng-container>
  </app-state>
</div>
