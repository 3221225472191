import { Deadline } from '@app/models/deadlines/deadline.model';
import { DeadlineType } from '@app/models/deadlines/deadline-type.model';
import moment from 'moment';
import { mockGoals } from '@app/mock/api/data/mockGoals';
import { mockMidYearReviewModules } from '@app/mock/api/data/mockEvaluationCycleModules';
import { mockOneToOneSchedules } from '@app/domain/one_to_one/mock/one-to-one-schedules.mock';

export const mockDeadlines: Deadline[] = [
  {
    id: mockOneToOneSchedules[0].id,
    type: DeadlineType.ONE_TO_ONE,
    message: '1:1 meeting with ' + mockOneToOneSchedules[0].participants[0].firstName + ' ' + mockOneToOneSchedules[0].participants[0].lastName + ' at ' + moment(mockOneToOneSchedules[0].startDateTime).format('HH:mm'),
    dueDate: moment(mockOneToOneSchedules[0].startDateTime).toDate()
  },
  {
    id: mockGoals[0].id,
    type: DeadlineType.GOAL,
    message: mockGoals[0].title,
    dueDate: mockGoals[0].endDate
  },
  {
    id: mockGoals[5].id,
    type: DeadlineType.GOAL,
    message: mockGoals[5].title,
    dueDate: mockGoals[5].endDate
  },
  {
    type: DeadlineType.EVALUATION_MANAGER_REVIEW_INCOMPLETE,
    message: 'manager led review of Maxine Stewart',
    dueDate: new Date(mockMidYearReviewModules[1].endDateTime),
  },
  {
    type: DeadlineType.EVALUATION_MANAGER_REVIEW_INCOMPLETE,
    message: 'manager led review of Norma White',
    dueDate: new Date(mockMidYearReviewModules[1].endDateTime),
  }
]