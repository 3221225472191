import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Inject, Injectable } from '@angular/core';
import { Globals } from '../globals/globals';
import { CompanyFeatures } from "@app/models/company-features.model";
import { DOCUMENT } from '@angular/common';

@Injectable()
export class FeatureGuard implements CanActivate {

  constructor(
    public globals: Globals,
    private router: Router,
    @Inject(DOCUMENT) private document: Document
  ) { }

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const feature = <CompanyFeatures>route.data['feature'];
    if (this.globals.hasFeature(feature)) {
      return true;
    } else {
      if (this.globals.company.featureLocked) {
        switch (route.data.feature) {
          case CompanyFeatures.ORG_CHART:
          case CompanyFeatures.PEOPLE_DIRECTORY:
            this.document.location.href = 'https://www.frankli.io/platform';
            break;
          case CompanyFeatures.FEEDBACK:
          case CompanyFeatures.ONE_TO_ONE:
          case CompanyFeatures.CONNECT:
            this.document.location.href = 'https://www.frankli.io/platform/employee-development';
            break;
          case CompanyFeatures.GOALS:
          case CompanyFeatures.EVALUATION_CYCLES:
            this.document.location.href = 'https://www.frankli.io/platform/performance-management';
            break;
          case CompanyFeatures.SURVEYS:
            this.document.location.href = 'https://www.frankli.io/platform/employee-engagement';
            break;
          default:
            this.router.navigate(['/feature-locked']).then();
        }
        return false;
      }
      this.router.navigate(['/not-enabled']).then();
      return false;
    }
  }
}
