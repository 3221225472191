<ng-container *ngIf="!state.loading">
  <div class="container-one-to-one-meeting-view">
    <ng-container *ngIf="meeting">
      <ng-container *ngIf="userIsScheduleManager && meeting?.transferMessage">
        <div class="card card-yellow">
          <div class="content">
            <span class="fal fa-fw fa-info-circle"></span>
            <span class="m-right-10"></span>
            <span class="d-inline-block">
              <div>This 1:1 schedule was transferred to a new manager at this point. The previous manager left the following message: "{{meeting.transferMessage}}"</div>
            </span>
          </div>
        </div>
      </ng-container>
    
      <!-- Meeting actions -->
      <ng-container *ngIf="canSeeActions">
        <div class="area-meeting-actions">
          <ng-container *ngIf="!canActionMeeting && !state.rescheduling && meeting.meetingTimestamp">
            <div class="actions-overlay-message">
              <div translate [translateParams]="{ meetingTimestamp: (meeting.meetingTimestamp | momentPipe: 'dddd[,] MMM Do [at] HH:mm') }">{{eOneToOneMessages.SCHEDULED_FOR_DATE}}</div>
            </div>
          </ng-container>

          <ng-container *ngIf="canActionMeeting">
            <ng-container *ngIf="!state.rescheduling; else templateReschedulingMeeting;">
                <ng-container [ngSwitch]="meeting.status">
                  <ng-container *ngSwitchCase="eOneToOneMeetingStatus.MISSED">
                    <app-button [type]="eButtonType.GREEN_INVERTED" [small]="true" (onClick)="tryReopenMeeting()" [tooltipText]="eOneToOneMessages.REOPEN_TOOLTIP | translate" [loading]="oneToOneBusinessService.isModifyingMeeting(meeting.id)">
                      <span class="fal fa-fw fa-arrow-rotate-left"></span>
                      <span class="m-right-5"></span>
                      <span translate>{{eOneToOneMessages.MARK_MEETING_AS_REOPENED}}</span>
                    </app-button>
                  </ng-container>
            
                  <ng-container *ngSwitchCase="eOneToOneMeetingStatus.IN_PROGRESS">
                    <app-button [type]="eButtonType.GREEN_INVERTED" (onClick)="tryCompleteMeeting()" [tooltipText]="eOneToOneMessages.COMPLETE_MEETING_TOOLTIP | translate" [loading]="oneToOneBusinessService.isModifyingMeeting(meeting.id)">
                      <span class="fal fa-fw fa-check-circle"></span>
                      <span class="m-right-5"></span>
                      <span translate>{{eOneToOneMessages.MARK_MEETING_AS_COMPLETED}}</span>
                    </app-button>
                  </ng-container>
            
                  <ng-container *ngSwitchCase="eOneToOneMeetingStatus.REOPENED">
                    <app-button [type]="eButtonType.GREEN_INVERTED" (onClick)="tryCompleteMeeting()" [tooltipText]="eOneToOneMessages.MARK_AS_COMPLETED_TOOLTIP | translate" [loading]="oneToOneBusinessService.isModifyingMeeting(meeting.id)">
                      <span class="fal fa-fw fa-check-circle"></span>
                      <span class="m-right-5"></span>
                      <span translate>{{eOneToOneMessages.MARK_MEETING_AS_COMPLETED}}</span>
                    </app-button>
                  </ng-container>  
            
                  <ng-container *ngSwitchCase="eOneToOneMeetingStatus.SCHEDULED">
                    <ng-container *ngIf="showStartButton; else overlayMessageNoStart">
                      <app-button [type]="eButtonType.GREEN" (onClick)="tryStartMeeting()" [tooltip]="tooltipStartMeeting" [loading]="oneToOneBusinessService.isModifyingMeeting(meeting.id)">
                        <span class="fal fa-fw fa-play"></span>
                        <span class="m-right-5"></span>
                        <span translate>{{eOneToOneMessages.START_MEETING}}</span>
                      </app-button>
                      <ng-template #tooltipStartMeeting>
                        <div class="text-left d-inline-block">
                          <div translate>{{eOneToOneMessages.START_MEETING_TOOLTIP}}</div>
                          <ng-container *ngIf="!isFlexible; else templateStartTooltipFlexibleSchedule;">
                            <div translate>{{eOneToOneMessages.CURRENTLY_SCHEDULED}}</div>
                            <div translate>{{eOneToOneMessages.START_ACTION_PROMPT}}</div>
                          </ng-container>
                          <ng-template #templateStartTooltipFlexibleSchedule>
                            <div translate>{{eOneToOneMessages.START_ACTION_PROMPT_FLEXIBLE}}</div>
                          </ng-template>
                        </div>
                      </ng-template>
                    </ng-container>
                    <ng-template #overlayMessageNoStart>
                      <div class="actions-overlay-message">
                        <div translate [translateParams]="{ meetingTimestamp: (meeting.meetingTimestamp | momentPipe: 'dddd[,] MMM Do [at] HH:mm') }">{{eOneToOneMessages.SCHEDULED_FOR_DATE}}</div>
                      </div>
                    </ng-template>
          
                    <!-- <div class="meeting-tertiary-action-icons"> -->
                      <ng-container *ngIf="!isFlexible">
                        <app-table-action-icon [boxSize]="'30px'" [iconSize]="'16px'" [icon]="'fa-times'" [hoverColor]="eIconHoverColor.RED" [tooltipText]="eOneToOneMessages.CANCEL_MEETING_TOOLTIP" (click)="tryCancelMeeting()" ></app-table-action-icon>
                        <app-table-action-icon [boxSize]="'30px'" [iconSize]="'16px'" [icon]="'fa-history'" [hoverColor]="eIconHoverColor.NAVY" [flipIcon]="true" [tooltipText]="eOneToOneMessages.RESCHEDULE_MEETING_TOOLTIP"  (click)="tryRescheduleMeeting()"></app-table-action-icon>
                      </ng-container>
                    <!-- </div> -->
                  </ng-container>
                </ng-container>
            </ng-container>
            <ng-template #templateReschedulingMeeting>
              <div class="area-reschedule-form">
                <div class="frankli-form-group frankli-form-group-filled" [class.frankli-form-group-invalid]="state.submitted && controlReschedule.invalid" (click)="showAvailabilityModal($event)">
                  <label for="meeting-time">
                    <span translate>{{eOneToOneMessages.MEETING_TIME}}</span>
                    <span *ngIf="currentUserTimezone !== schedule.timezone">&nbsp;({{schedule.timezone | underscoreToSpace}})</span>
                  </label>
        
                  <div class="reschedule-picker-container" [class.reschedule-picker-disabled]="calendarConnectionStatus && calendarConnectionStatus.connected">
                    <app-date-picker-old-component [formControl]="controlReschedule"
                      [displayFormat]="'HH:mm ddd, D MMM YYYY'" [format]="'YYYY-MM-DD HH:mm'"
                      [emptyText]="'Click to choose date/time'" [horizontalPosition]="'auto'"
                      [verticalPosition]="'bottom'" [minDate]="dateToday" [sideBySide]="true"
                      [viewMode]="'no-reset'" [disabledDates]="datesOfOtherMeetings">
                    </app-date-picker-old-component>
                  </div>
                  <ng-container *ngIf="currentUserTimezone !== schedule.timezone">
                    <div class="text-info">This meeting would be on {{localMeetingTime}} for you</div>
                  </ng-container>
        
                  <div class="frankli-form-group-alert">
                    <ng-container *ngIf="state.submitted && controlReschedule.invalid">
                      <div *ngIf="controlReschedule.hasError('required')" translate>{{eCommonMessages.FORM_REQUIRED}}</div>
                      <!-- <div *ngIf="controlReschedule.hasError('isInPast')" translate>{{eOneToOneMessages.MEETING_TIME_IN_PAST}}</div> -->
                    </ng-container>
                  </div>
                </div>
        
                <div class="area-reschedule-actions">
                  <app-button [type]="eButtonType.GRAY_INVERTED" [loading]="rescheduleSubmitting" (onClick)="cancelRescheduleMeeting()">
                    <span translate>{{eCommonMessages.CANCEL}}</span>
                  </app-button>
                  <app-button [type]="eButtonType.GREEN" [loading]="rescheduleSubmitting" (onClick)="submitRescheduleMeeting()">
                    <span translate>{{eCommonMessages.SAVE}}</span>
                  </app-button>
                </div>
              </div>
            </ng-template>
          </ng-container>

          <ng-container *ngIf="canEditSchedule">
            <app-table-action-icon [boxSize]="'30px'" [iconSize]="'16px'" [icon]="'fa-edit'" [hoverColor]="eIconHoverColor.YELLOW" [tooltipText]="'Edit this meetings schedule'" (click)="tryEditSchedule()"></app-table-action-icon>
          </ng-container>
        </div>

        <hr class="row m-top-0"/>
      </ng-container>
  
      <div class="area-meeting-sections">
        <div class="area-talking-points">
          <h4 class="standard-heading p-bottom-15">
            <span>{{eOneToOneMessages.TALKING_POINTS | translate}}</span>
            <!-- TODO: Add tooltip here with explanation of what talking points are -->
            <!-- <span class="m-right-5"></span>
            <span class="fal fa-fw fa-info-circle"></span> -->
          </h4>
      
          <app-talking-points
            [meeting]="meeting"
            [meetingPrevious]="meetingPrevious"
            [talkingPoints]="meeting.talkingPoints"
            [canReorder]="false"
            [schedule]="schedule"
            (singleChangeEvent)="onTalkingPointChanged($event)"
            (onAction)="onTalkingPointsAction($event)"
            ></app-talking-points>
        </div>
    
    
        <!-- TODO: Add tooltip here with explanation of what shared notes are -->
        <div class="area-shared-notes">
          <h4 class="standard-heading p-bottom-15">
            <span>{{eOneToOneMessages.SHARED_NOTES | translate}}</span>
            <!-- <span class="m-right-5"></span>
            <span class="fal fa-fw fa-info-circle"></span> -->
          </h4>
    
          <app-shared-notes [schedule]="schedule" [meeting]="meeting" (onNotesUpdated)="onSharedNotesUpdatedReceived($event)"></app-shared-notes>
        </div>
    
        <!-- TODO: Add tooltip here with explanation of what private notes are -->
        <div class="area-private-notes">
          <h4 class="standard-heading p-bottom-15">
            <span>{{eOneToOneMessages.PRIVATE_NOTES | translate}}</span>
            <!-- <span class="m-right-5"></span>
            <span class="fal fa-fw fa-info-circle"></span> -->
          </h4>
    
          <app-private-notes [schedule]="schedule" [meeting]="meeting"></app-private-notes>
        </div>
    
        <!-- TODO: Add tooltip here with explanation of what action points are -->
        <div class="area-action-points">
          <h4 class="standard-heading p-bottom-15">
            <span>{{eOneToOneMessages.ACTION_POINTS | translate}}</span>
            <!-- <span class="m-right-5"></span>
            <span class="fal fa-fw fa-info-circle"></span> -->
          </h4>
    
          <app-action-points [schedule]="schedule" [meeting]="meeting" [meetingPrevious]="meetingPrevious"></app-action-points>
        </div>
    
        <!-- TODO: Add tooltip here with explanation of what meeting files are -->
        <div class="area-meeting-files">
          <h4 class="standard-heading p-bottom-15">
            <span>{{eOneToOneMessages.FILES | translate}}</span>
            <!-- <span class="m-right-5"></span>
            <span class="fal fa-fw fa-info-circle"></span> -->
          </h4>
    
          <app-meeting-files [schedule]="schedule" [meeting]="meeting" (onMeetingUpdated)="onMeetingUpdatedReceived($event)"></app-meeting-files>
        </div>
  
        <ng-container *ngIf="canSeeSentimentScaleArea">
          <div class="area-sentiment-scale">
            <h4 class="standard-heading p-bottom-15">
              <span>{{eOneToOneMessages.SENTIMENT_SCALE | translate}}</span>
            </h4>
    
            <!-- (onMeetingUpdated)="onMeetingUpdatedReceived($event)" -->
             <app-meeting-sentiment-scale [schedule]="schedule" [meeting]="meeting"></app-meeting-sentiment-scale>
          </div>
        </ng-container>
      </div>
  
      <ng-container *ngIf="calendarConnectionStatus && calendarConnectionStatus.connected && schedule && state.rescheduling">
        <app-availability-modal #availabilityModal [otherUsers]="schedule.participants" [timezone]="schedule.timezone" [meetingTime]="controlReschedule.value" [meetingLength]="schedule.meetingLength" [showMeetingLength]="false" (meetingTimeChanges)="availabilityTimeChanged($event)" (meetingLengthChanges)="availabilityLengthChanged($event)"></app-availability-modal>
      </ng-container>
    </ng-container>
  </div>
</ng-container>
