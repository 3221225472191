import { Component, ElementRef, EventEmitter, forwardRef, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { CompanyFeatures } from '@app/models/company-features.model';
import { UserMinimal } from '@app/models/user/user-minimal.model';
import { UserAPIService } from '@app/shared/api/user.api.service';
import { Globals } from '@app/shared/globals/globals';
import { debounceTime, map } from 'rxjs/operators';
import { staticUserPagingParams, staticUserSortingParams } from '@app/shared/utils/api-filter/static-user-params';
import { CriterionOperator } from '@app/models/api/criterion-operator.enum';
import { ChildFilter } from '@app/models/api/child-filter.model';
import { FilterOperator } from '@app/models/api/filter-operator.enum';
import { ParentFilter } from '@app/models/api/parent-filter.model';
import { onboardedNotArchivedChildFilter } from '@app/shared/utils/api-filter/common-user-filters';
import { State } from '@app/shared/utils/state.model';
import { CommonMessages } from '@app/constants/common.messages';
import { Role } from '@app/domain/role/model/role.model';
import { RoleBusinessService } from '@app/domain/role/service/role-business.service';

@Component({
  selector: 'app-user-picker-multiple',
  templateUrl: './user-picker-multiple.component.html',
  styleUrls: ['./user-picker-multiple.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => UserPickerMultipleComponent),
    multi: true,
  }]
})
export class UserPickerMultipleComponent implements OnInit, ControlValueAccessor {
  public readonly eCompanyFeatures = CompanyFeatures;

  @Input() disabled: boolean;
  @Input() maxResultsShown: number;
  @Input() customOptions: UserMinimal[];
  @Input() placeholder: string;
  @Input() useCustomOptionsOnly: boolean;
  @Input() maxSelection: number | null;
  @Input() ignoredUserIds: number[];

  state: State = new State(false);
  positions: Role[] = [];
  searchRunning: boolean;
  searchControl: FormControl;
  searchResults: UserMinimal[];
  @Output() onResultsChanged: EventEmitter<UserMinimal[]>;

  @ViewChild('searchInput') searchInput?: ElementRef<HTMLInputElement>;

  onChange = (_: any) => {};
  onTouched = () => {};

  _value: UserMinimal[];

  get value(): UserMinimal[] {
    return this._value;
  }

  set value(val: UserMinimal[]) {
    if (!this.disabled) {
      this._value = this.parseValue(val);
      this.onChange(val);
    }
  }

  searchChildFilter: ChildFilter = {
    operator: FilterOperator.AND,
    filterCriteria: []
  };
  parentFilter: ParentFilter = {
    operator: FilterOperator.AND,
    childFilters: [onboardedNotArchivedChildFilter, this.searchChildFilter]
  };

  constructor(
    public globals: Globals,
    private userAPIService: UserAPIService,
    private roleBusinessService: RoleBusinessService
  ) {
    this.placeholder = CommonMessages.SEARCH_PEOPLE;

    this.disabled = false;
    this.searchRunning = false;
    this.useCustomOptionsOnly = false;

    this._value = [];
    this.searchResults = [];
    this.customOptions = [];
    this.ignoredUserIds = [];

    this.maxResultsShown = 5;

    this.searchInput = undefined;

    this.maxSelection = null;

    this.onResultsChanged = new EventEmitter<UserMinimal[]>();

    this.searchControl = this.initSearchControl();
  }

  ngOnInit(): void {
    this.state.setLoading();
    this.roleBusinessService.get(null, null, null, null).subscribe(positions => {
      this.positions = positions;
      this.state.setSuccess();
    });
  }

  // #region - VALUE ACCESSOR STUFF
  writeValue(value: UserMinimal[]) {
    this._value = this.parseValue(value);
  }

  // Register callbacks
  registerOnChange(fn: (_: any) => {}): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }
  // #endregion

  // #region - SEARCHING
  initSearchControl(): FormControl {
    const searchControl = new FormControl('', []);

    searchControl.valueChanges
      .pipe(
        map(sarg => {
          this.searchRunning = true;
          sarg = sarg.toLocaleLowerCase();
          return sarg;
        }),
        debounceTime(500)
      )
      .subscribe(sarg => this.onSearchChange(sarg));

    return searchControl;
  }

  onSearchChange(sarg: string): void {
    if (sarg === '') {
      this.updateSearchResults([]);
      return;
    }

    if (this.useCustomOptionsOnly) {
      this.doInternalSearch(sarg);
    } else {
      this.doApiSearch(sarg);
    }
  }

  doInternalSearch(sarg: string): void {
    const selectedIds = this.value.map(v => v.id);

    const matches = this.customOptions
      .filter(co => {
        const matchesSearchArgument = this.getSearchStringForUser(co).includes(sarg);
        if (!matchesSearchArgument) return false;

        const alreadySelected = selectedIds.includes(co.id);
        if (alreadySelected) return false;

        const isBlackListed = this.ignoredUserIds.includes(co.id);
        if (isBlackListed) return false;

        return true;
      });

    this.updateSearchResults(matches);
    this.searchRunning = false;
  }

  doApiSearch(sarg: string): void {
    this.searchChildFilter.filterCriteria = [
      {
        field: 'name',
        operator: CriterionOperator.LIKE,
        value: sarg
      },
      {
        field: 'id',
        operator: CriterionOperator.NOT_IN,
        values: this.value.map(su => String(su.id))
      }
    ];
    this.userAPIService.searchUsersPaginated(staticUserPagingParams, staticUserSortingParams, this.parentFilter).subscribe(
      (userPage) => {
        let matches: UserMinimal[] = [];

        const customMatches = this.customOptions.filter(co => {
          const searchString = this.getSearchStringForUser(co);
          if (searchString.includes(sarg)) {
            return co;
          }
        });

        const results = [
          ...userPage.content,
          ...customMatches
        ];

        const selectedIds = this.value.map(v => v.id);

        results.forEach(u => {
          const userSelected = selectedIds.includes(u.id);
          const userBeingSelected = matches.map(v => v.id).includes(u.id);
          const userBlackListed = this.ignoredUserIds.includes(u.id);

          if (!userSelected && !userBeingSelected && !userBlackListed) {
            matches = [...matches, u];
          }
        });

        this.updateSearchResults(matches);
        this.searchRunning = false;
      }
    );
  }

  updateSearchResults(results: UserMinimal[]): void {
    results = results.slice(0,this.maxResultsShown);

    this.searchResults = results;
    this.onResultsChanged.emit(results);
  }

  getSearchStringForUser(user: UserMinimal): string {
    return `${user.firstName} ${user.lastName}`.toLocaleLowerCase();
  }
  // #endregion

  // #region - SELECTION AND DESELECTION
  onClickUser(user: UserMinimal, action: 'add' | 'remove'): void {
    switch (action) {
      case 'add':
        if ((this.maxSelection !== null) && (this.value.length >= this.maxSelection)) {
          alert('Too many people selected');
        } else {
          this.doSelectUser(user);
        }
        break;
      case 'remove':
        this.doDeselectUser(user);
        break;
    }
  }

  doSelectUser(user: UserMinimal): void {
    if (!this.value.includes(user)) {
      this.value = [...this.value, user];
      this.searchControl.setValue('', { emitEvent: false });
      this.onSearchChange('');
      this.focusSearchInput();
      this.refreshMaxSelection();
    }
  }

  doDeselectUser(user: UserMinimal): void {
    this.value = this.value.filter(v => (v.id !== user.id));
    this.refreshMaxSelection();
  }
  // #endregion

  focusSearchInput(): void {
    if (!this.searchInput) { return; }

    this.searchInput.nativeElement.focus();
  }

  // TODO: Types - UserMinimal or User
  getPosition(userMinimal: any): Role | undefined {
    if (userMinimal.positionId) {
      return this.positions.find(p => p.id === userMinimal.positionId);
    }

    if (userMinimal.position) {
      return userMinimal.position;
    }
    
    return undefined;
  }

  parseValue(newVal: UserMinimal[]): UserMinimal[] {
    if (newVal === null || newVal === undefined) {
      return [];
    }

    return newVal;
  }

  refreshMaxSelection(): void {
    if ((this.maxSelection !== null) && (this.value.length >= this.maxSelection)) {
      this.searchControl.disable();
    } else {
      this.searchControl.enable();
    }
  }
  
  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
