<div class="goal-create-type-container" [class.parent-dropdown-closed]="!parentDropdownOpen">
  <div class="type-item-list">
    <ng-container *ngFor="let dataItem of dataParsed; index as index">
      <div class="type-item" [class.type-item-selected]="controlType.value === dataItem.type" (click)="changeSelection(dataItem.type)">
        <div class="row m-left-0 m-right-0">
          <div class="col-icon">
            <span class="fal fa-fw" [ngClass]="dataItem.icon"></span>
          </div>
          <div class="col-details">
            <span translate>{{dataItem.friendlyText}}</span>
          </div>
          <div class="col-checkmark">
            <span class="fal fa-fw fa-check icon-selected"></span>
            <span class="fal fa-fw fa-times icon-deselect"></span>
          </div>
        </div>
      </div>
    </ng-container>
  </div>

  <ng-container *ngIf="submitted && controlType.invalid">
    <div class="frankli-form-group-alert">
      <div *ngIf="controlType.hasError('required')" translate>{{eGoalsMessages.GOAL_CREATE_TYPE_VALIDATION_REQUIRED}}</div>
    </div>
  </ng-container>
</div>