import { SortingParams } from '@app/models/api/sorting-params.model';
import { SortDirection } from '@app/models/api/sort-direction.enum';
import { PagingParams } from '@app/models/api/paging-params.model';

export const staticGoalSortingParams: SortingParams = {
    sortAttributes: ['title'],
    sortDirection: SortDirection.ASC
}

export const staticGoalPagingParams: PagingParams = {
    pageSize: 20,
    pageNumber: 0
}