<div class="container-profile-absorblms-certificates">
  <div class="card no-margin">
    <div class="p-top-15 p-left-15 p-right-15">
      <h4 class="standard-heading">
        <img class="image-absorb-logo" src="assets/img/integrations/absorblms.svg" title="Absorb LMS" alt="Absorb LMS logo"/>
        <span class="m-right-10"></span>
        <span>Certificates earned</span>
      </h4>
    </div>

    <ng-container *ngIf="certificates.length > 0; else templateNoCertificates;">
      <div class="certificates-list">
        <ng-container *ngFor="let certificate of certificates">
          <div class="certificate-item">
            <app-absorblms-certificate-display [certificate]="certificate"></app-absorblms-certificate-display>
          </div>
        </ng-container>
      </div>
    </ng-container>
    <ng-template #templateNoCertificates>
      <app-empty-state [height]="'300px'" [message]="'No certificates found'"></app-empty-state>
    </ng-template>
  </div>
</div>