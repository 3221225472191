import { Injectable } from '@angular/core';
import { UserAPIService } from '@app/shared/api/user.api.service';
import { EmailAPIService } from '@app/shared/api/email.api.service';
import { environment } from '../../environments/environment';
import { HttpErrorResponse } from '@angular/common/http';
import { getParameterByName } from '@app/shared/utils/helpers';
import { FrankliHttpErrorResponse } from '@app/models/exception/frankli-http-error-response.model';
import { SwalUtils } from '@app/shared/utils/swal.utils';
import { SweetAlertResult } from 'sweetalert2';

@Injectable()
export class StartUpService {
  constructor(
      private userAPIService: UserAPIService,
      private emailAPIService: EmailAPIService,
      private swalUtils: SwalUtils
  ) {
  }

  /**
   * Populate globals before app init (if user session exists)
   */
  load(): Promise<void> {
    return new Promise((resolve: any) => {

      // JWT Access token
      const token = localStorage.getItem('access_token');

      // Check for referrer token and remove it from url and history
      // We want to remove it from history so user doesn't bookmark the url etc and inflate click numbers
      const referrerType = getParameterByName('referrer-type');
      const referrerToken = getParameterByName('referrer-token');
      if (referrerType !== null && referrerToken !== null) {
        let url = window.location.href;
        url = url.replace('referrer-type=' + referrerType, '');
        url = url.replace('referrer-token=' + referrerToken, '');
        window.history.replaceState({}, document.title, url);
      }

      if (token || environment.mock.enabled) {
        console.info('User session exists, fetching user data');
        this.userAPIService.getUserInit()
          .toPromise()
          .then(() => {
            if (referrerType !== null && referrerToken !== null) {
              if (referrerType === 'email') {
                // If failure, fail silently
                this.emailAPIService.createClick(referrerToken!).subscribe();
              } else {
                // Add more referrer types later
              }
            }
            resolve(true);
          })
          .catch((err: HttpErrorResponse) => {
            if (err.status === 403) {
              localStorage.removeItem('access_token');
              resolve(true);
              return;
            }

            this.displaySwalForFailedRequest(err).then(res => {
              if (res.isConfirmed) {
                resolve(true);
              }
            });
          });
      } else {
        if (referrerType !== null && referrerToken !== null) {
          if (referrerType === 'email') {
            // If failure, fail silently
            this.emailAPIService.createAnonymousClick(referrerToken!).subscribe();
          } else {
            // Add more referrer types later
          }
        }
        resolve(true);
      }
    });
  }

  public displaySwalForFailedRequest(errorResponse: FrankliHttpErrorResponse): Promise<SweetAlertResult<any>> {
    let mainText = '';
    let codeDisplay = '';
    let html = '';

    if (errorResponse.error) {
      mainText = typeof errorResponse.error === 'string' ? errorResponse.error : errorResponse.error.message;

      if (this.hasErrorCode(errorResponse)) {
        codeDisplay = `<small class="error-code">ERROR CODE: ${errorResponse.error.errorCode}</small> `;
      } else {
        codeDisplay = '';
      }

      html = `<div>${mainText}</div>${codeDisplay}`;
    }

    return this.swalUtils.displayErrorSwal({
      title: 'Sorry, We Encountered an Error',
      html: html,
      confirmButtonText: 'OK'
    });
  }

  private hasErrorCode(error: FrankliHttpErrorResponse): boolean {
    if (!error) { return false; }
    if (!error.error) { return false; }
    if (typeof error.error === 'string') { return false; }
    return !!error.error.errorCode;
  }
}

export function startUpServiceFactory(startUpService: StartUpService): () => void {
  return () => startUpService.load();
}
