<div class="user-picker-container" [class.disabled]="disabled">
    <div class="search-container">
        <div class="user-item no-border value-preview" [class.preview-transparent]="dropdownOpen" *ngIf="_value" [title]="_value.firstName + ' ' + _value.lastName">
            <div class="user-col-pic" *ngIf="_value.imageUrl; let image">
        <span class="pic-container">
          <app-profile-photo [src]="image" [firstName]="_value.firstName" [lastName]="_value.lastName"></app-profile-photo>
        </span>
            </div>
            <div class="user-col-name" [class.p-left-no-image]="!value.imageUrl">
                <span class="name-container">{{_value.firstName}} {{_value.lastName}}</span>
            </div>
        </div>
        <input class="form-control search-input" [class.has-value]="_value" [class.search-invisible]="!dropdownOpen" [formControl]="searchControl" (keyup.enter)="trySelectFirst()" (focus)="dropdownOpen = !disabled" (blur)="hideDropdownDelayed()" [placeholder]="searchPlaceholder"/>
    </div>

    <div class="user-list" *ngIf="((customOptions && customOptions.length > 0) || (searchControl.value || state.isLoading())) && (dropdownOpen && !disabled)">

        <!-- Custom Options -->
        <div class="user-item user-item-hover clickable" *ngFor="let o of customOptionsListed" [title]="o.firstName + ' ' + o.lastName" (click)="selectUser(o)">
            <div class="user-col-pic">
        <span class="pic-container">
          <app-profile-photo *ngIf="o.imageUrl" [src]="o.imageUrl" [firstName]="o.firstName" [lastName]="o.lastName"></app-profile-photo>
        </span>
            </div>
            <div class="user-col-name">
                <span class="name-container">{{o.firstName}} {{o.lastName}}</span>
            </div>
        </div>

        <!-- Search from user pool or api -->
        <ng-container *ngIf="state.isSuccessful()">
            <div *ngIf="(usersListed.length > 0) || (customOptions && customOptionsListed.length > 0); else noSearch">
                <div class="user-item user-item-hover clickable" *ngFor="let u of usersListed" [title]="u.firstName + ' ' + u.lastName" (click)="selectUser(u)">
                    <div class="user-col-pic">
                        <span class="pic-container">
                            <app-profile-photo [src]="u.imageUrl" [firstName]="u.firstName" [lastName]="u.lastName"></app-profile-photo>
                        </span>
                    </div>
                    <div class="user-col-name">
                        <span class="name-container">{{u.firstName}} {{u.lastName}}</span>
                    </div>
                </div>
            </div>
            <ng-template #noSearch>
                <div class="text-center p-15">
                    <ng-container *ngIf="searchControl.value; else noSearchValue">
                        <ng-container *ngIf="!state.isLoading(); else loadingState">
                            <span>No people found</span>
                        </ng-container>
                        <ng-template #loadingState>
                            <span class="fas fa-fw fa-spinner fa-spin"></span>
                        </ng-template>
                    </ng-container>
                    <ng-template #noSearchValue>
                        <span>Start typing in someone's name</span>
                    </ng-template>
                </div>
            </ng-template>
        </ng-container>
        <ng-container *ngIf="state.isLoading()">
            <div class="text-center p-15">
                <span class="fas fa-spin fa-spinner fa-fw"></span>
            </div>
        </ng-container>
    </div>
</div>
