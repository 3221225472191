export enum OneToOneFrequency {
  Daily = 'Daily',
  Weekly = 'Weekly',
  Fortnightly = 'Fortnightly',
  Monthly = 'Monthly',
  Quarterly = 'Quarterly',
  Annually = 'Annually',
  Individual = 'Individual',
  Flexible = 'Flexible',
  Custom = 'Custom'
}
