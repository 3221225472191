import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { QualificationSidebarData } from '@app/domain/qualification/model/qualification-sidebar-data.model';
import { QualificationBusinessService } from '@app/domain/qualification/service/qualification-business.service';
import { IState } from '@app/models/state/state.model';
import { PaginationNewComponent } from '@app/shared/pagination/pagination-new/pagination-new.component';

type TabEntity = QualificationSidebarData;

@Component({
  selector: 'app-information-sidebar-qualifications',
  templateUrl: './information-sidebar-qualifications.component.html',
  styleUrls: ['./information-sidebar-qualifications.component.scss']
})
export class InformationSidebarQualificationsComponent implements OnInit {
  
  @ViewChild('pagination') pagination?: PaginationNewComponent;

  @Input() userIds: number[];

  state: IState;
  data: TabEntity[];

  get hasData(): boolean {
    if (!this.data) { return false; }
    if (this.data.length === 0) { return false; }
    // At least one item in the data array has to have a qualification in the list
    return this.data.some((item: TabEntity) => item.user && item.qualifications.length > 0);
  }

  constructor(
    private qualificationBusinessService: QualificationBusinessService
  ) {
    this.userIds = [];

    this.state = {
      loading: true,
      error: false,
      errorMessage: ''
    };

    this.data = [];
  }

  ngOnInit(): void {
    this.getData();
  }
  
  getData(): void {
    if (!this.userIds) { return; }
    if (this.userIds.length === 0) { return; }

    this.qualificationBusinessService.getSidebarData(this.userIds)
      .subscribe(data => {
        data = this.sortData(data);
        this.data = data;
        this.state.loading = false;
      });
  }

  sortData(data: TabEntity[]): TabEntity[] {
    // Order by name
    return data.sort((a, b) => {
      const sortStringA = `${a.user.firstName}${a.user.lastName}`;
      const sortStringB = `${b.user.lastName}${b.user.lastName}`;
      return sortStringA.localeCompare(sortStringB);
    });
  }
}
