import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { CommonMessages } from '@app/constants/common.messages';
import { IState } from '@app/models/state/state.model';
import { UserMinimal } from '@app/models/user/user-minimal.model';
import { Globals } from '@app/shared/globals/globals';
import { SwalUtils } from '@app/shared/utils/swal.utils';
import { forkJoin } from 'rxjs';
import { TasksMessages } from '../../locale/tasks.messages';
import { TaskActivity } from '../../model/task-activity.model';
import { TaskComment } from '../../model/task-comment.model';
import { TaskBusinessService } from '../../service/task-business.service';

interface PageState extends IState {
  submitted: boolean;
  submitting: boolean;
}

enum AuditLogType {
  COMMENT = 'COMMENT',
  ACTIVITY = 'ACTIVITY'
}

interface AuditLogItem {
  type: AuditLogType;
  timestamp: Date;
  user: UserMinimal;
  activityData?: TaskActivity;
  commentData?: TaskComment;
}

@Component({
  selector: 'app-task-activity',
  templateUrl: './task-activity.component.html',
  styleUrls: ['./task-activity.component.scss']
})
export class TaskActivityComponent implements OnInit {

  public readonly eTasksMessages = TasksMessages;
  public readonly eAuditLogType = AuditLogType;

  @Input() taskId: number;
  @Input() showCommentForm: boolean;

  @Output() taskUpdated: EventEmitter<boolean>;

  activity: AuditLogItem[];
  state: PageState;
  commentControl: FormControl;

  constructor(
    public globals: Globals,
    private swalUtils: SwalUtils,
    private taskBusinessService: TaskBusinessService
  ) {
    this.taskId = undefined;
    this.showCommentForm = true;

    this.activity = [];

    this.state = {
      loading: true,
      error: false,
      errorMessage: '',
      submitted: false,
      submitting: false
    };
    
    this.taskUpdated = new EventEmitter<boolean>();

    this.commentControl = this.initCommentControl();
  }

  ngOnInit(): void {
    this.getData();
  }

  getData(): void {
    if (!this.taskId) { return; }

    forkJoin([
      this.taskBusinessService.getTaskActivity(this.taskId),
      this.taskBusinessService.getTaskComments(this.taskId)
    ]).subscribe(([activity, comments]) => {
      const activityParsed = activity.map(a => this.parseActivityToAuditLogItem(a));
      const commentsParsed = comments.map(c => this.parseCommentToAuditLogItem(c));
      let auditLog = [...activityParsed, ...commentsParsed];
      auditLog = this.sortAuditLog(auditLog);
      this.activity = auditLog;
      this.state.loading = false;
    });
  }

  initCommentControl(comment?: string): FormControl {
    const formControl = new FormControl('', [Validators.required, Validators.minLength(5), Validators.maxLength(1000)]);

    if (comment) {
      formControl.setValue(comment, { emitEvent: false });
    }

    return formControl;
  }

  onCommentSubmit(): void {
    this.state.submitted = true;

    if (this.commentControl.invalid) { return; }
    if (this.state.submitting) { return; }
    this.state.submitting = true;

    this.taskBusinessService.commentOnTask(this.taskId, this.commentControl.value)
      .then(() => {
        this.commentControl = this.initCommentControl();
        this.taskUpdated.emit(true);
        this.state.submitted = false;
      })
      .finally(() => {
        this.state.submitting = false;
      });
  }

  onCommentDelete(comment: AuditLogItem): void {
    const commentId = comment.commentData!.id;

    if (this.state.submitting) {
      return;
    }
    this.state.submitting = true;

    this.swalUtils.displayWarningConfirmationSwal({
      // title: 'Delete this comment?',
      text: CommonMessages.ACTION_CANNOT_BE_UNDONE,
      confirmButtonText: TasksMessages.DELETE_COMMENT
    }).then((result) => {
      if (result.isConfirmed) {
        this.taskBusinessService.deleteCommentOnTask(this.taskId, commentId)
          .then(() => {
            this.taskUpdated.emit(true);
          })
          .finally(() => {
            this.state.submitting = false;
          });
      }
    });
  }

  parseCommentToAuditLogItem(comment: TaskComment): AuditLogItem {
    return {
      type: AuditLogType.COMMENT,
      commentData: comment,
      user: comment.creator,
      timestamp: comment.timestamp
    };
  }

  parseActivityToAuditLogItem(activity: TaskActivity): AuditLogItem {
    return {
      type: AuditLogType.ACTIVITY,
      activityData: activity,
      user: activity.user,
      timestamp: activity.timestamp
    };
  }

  // Sort audit log by timestamp. Timestamp may be null
  sortAuditLog(auditLogItems: AuditLogItem[]): AuditLogItem[] {
    return auditLogItems.sort((a, b) => {
      const aTimestamp = a.timestamp || new Date(0);
      const bTimestamp = b.timestamp || new Date(0);

      return aTimestamp.getTime() - bTimestamp.getTime();
    });
  }
}
