import { PagingParams } from './paging-params.model';
import { ParentFilter } from './parent-filter.model';
import { SortingParams } from './sorting-params.model';

export class FilteredPagedAndSortedRequest {
  filter: ParentFilter;
  paging: PagingParams;
  sorting: SortingParams;

  constructor(
    filter: ParentFilter,
    paging: PagingParams,
    sorting: SortingParams
  ) {
    this.filter = filter;
    this.paging = paging;
    this.sorting = sorting;
  }
}
