import { Component, ElementRef, HostListener, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { UserMinimal } from '@app/models/user/user-minimal.model';
import { User } from '@app/models/user/user.model';
import { Globals } from '@app/shared/globals/globals';

const TIMER_DELAY = 400;

@Component({
  selector: 'app-person-picture-list',
  templateUrl: './person-picture-list.component.html',
  styleUrls: ['./person-picture-list.component.scss']
})
export class PersonPictureListComponent implements OnInit, OnDestroy {
  @Input() users: User[];
  @Input() maxListed: number;
  @Input() openProfileOnClick: boolean;
  @Input() imageSizePx: number;
  @Input() showTitles: boolean;
  @ViewChild('listContainer') listContainer?: ElementRef<HTMLDivElement>;

  screen: {
      width: number,
      height: number
  };

  processing: boolean;
  initialised: boolean;

  title: string;
  amountRemoved: number;

  @HostListener('window:resize', ['$event'])
  onResize() {
    if (!this.initialised) { return; }

    this.screen = {
      width: window.innerWidth,
      height: window.innerHeight
    };

    this.checkOverflow();
  }

  get letterAvatarFontSize(): string {
    return `${Math.round(this.imageSizePx * 0.66666)}px`;
  }

  constructor(
    public globals: Globals,
    private router: Router
  ) {
    this.showTitles = true;
    this.users = [];
    this.maxListed = 5;
    this.openProfileOnClick = true;
    this.screen = {
      width: window.innerWidth,
      height: window.innerHeight
    };
    this.imageSizePx = 40;
    this.processing = false;
    this.initialised = false;
    this.title = '';
    this.amountRemoved = 0;
  }

  ngOnInit(): void {
    this.checkOverflow();
  }

  ngOnDestroy(): void {
    this.listContainer = null;
    this.users = [];
    this.processing = true;
  }

  checkOverflow(): void {
    if (!this.listContainer || this.processing) {
      setTimeout(() => this.checkOverflow(), (this.initialised ? 10 : TIMER_DELAY));
      return;
    }

    this.processing = true;

    this.title = '';
    this.amountRemoved = 0;
    this.refreshOverflowRecursive(this.listContainer.nativeElement);
  }

  refreshOverflowRecursive(container: HTMLDivElement): void {
    const overflowCount = this.getOverflowCount(container);
    if (overflowCount > 1) {
      for (let index = 1; index < overflowCount; index++) {
        this.removeLastChild(container);
      }

      this.refreshOverflowRecursive(container);
    } else {
      if (this.amountRemoved > 0) {
        this.removeLastChild(container);
        this.addOverflowCircleToContainer(container);
      }

      this.endRefresh();
    }
  }

  endRefresh(): void {
    this.processing = false;
    this.initialised = true;
  }
  
  removeLastChild(container: HTMLDivElement): void {
    const lastChild = container.children[(container.children.length - 1)];
    if (!lastChild) { return; }

    const personsName = lastChild.getAttribute('title');
    if (this.title.includes(personsName)) { return; }

    this.title += `${personsName}\n`;
    this.amountRemoved = this.amountRemoved + 1;

    container.removeChild(lastChild);
  }

  getOverflowCount(container: HTMLDivElement): number {
    if (this.maxListed !== 0) {
      const maxOffset = (this.maxListed + 1);
      const difference = (container.children.length - maxOffset);

      if (difference > 0) {
        return difference;
      }
    }

    if (container.scrollWidth > container.offsetWidth) { 
      const diff = (container.scrollWidth - container.offsetWidth);
      const toRemove = Math.floor(diff / 30);
      return toRemove;
    }

    return 0;
  }

  addOverflowCircleToContainer(container: HTMLDivElement): HTMLDivElement {
    // Create a new element to add
    const overflowCircle = document.createElement('span');
    overflowCircle.title = this.title;
    overflowCircle.textContent = `+${this.amountRemoved}`;
    overflowCircle.style.fontWeight = 'bold';
    overflowCircle.style.borderRadius = '50%';
    overflowCircle.style.background = '#30747F';
    overflowCircle.style.padding = '4px';
    overflowCircle.style.color = 'white';
    overflowCircle.style.margin = '0';
    overflowCircle.style.marginLeft = '-10px';
    overflowCircle.style.fontSize = '12px';
    overflowCircle.style.boxShadow = '0px 0px 4px 1px rgba(0, 0, 0, 0.2)';
    overflowCircle.classList.add('list-overflow-circle');
    // TODO: Add fancy tooltips somehow (see context menu directive for how)
    // overflowCircle.setAttribute('_ngcontent-wgg-c213=', '');
    // overflowCircle.setAttribute('placement', 'bottom');
    // overflowCircle.setAttribute('tabindex','0');
    // overflowCircle.setAttribute('ng-reflect-placement', 'bottom');
    // overflowCircle.setAttribute('ng-reflect-tooltip', title);
    // overflowCircle.setAttribute('ng-reflect-triggers', this.globals.tooltipTriggers);
    container.appendChild(overflowCircle);
    return container;
  }

  onClickUser(user: UserMinimal): void {
    if (this.openProfileOnClick) {
      this.router.navigate(['/profile', user.id]);
    }
  }

  getMarginForImage(index: number): string {
    if (index === 0) { return '0'; }
    return `-${(this.imageSizePx / 2)}px`;
  }
}
