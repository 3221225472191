<div class="frankli-sidebar" [class.sidebar-collapsed]="sidebarService.sidebarMini" [class.sidebar-open]="sidebarService.sidebarOpen">
  <div class="frankli-sidebar-inner">
    <div class="frankli-sidebar-header">
      <ng-container [ngSwitch]="sidebarService.sidebarMini">
        <img src="assets/img/logos/frankli-logo-text-white.svg" alt="Frankli" *ngSwitchCase="false"/>
        <img src="assets/img/logos/frankli-logo.svg" alt="Frankli" *ngSwitchCase="true"/>
      </ng-container>
    </div>

    <!-- Mobile menu -->
    <div class="frankli-sidebar-bubbles">
      <!-- <app-hamburger-menu [opened]="sidebarService.sidebarOpen" [invertColours]="true" (click)="sidebarToggle()"></app-hamburger-menu> -->

      <!-- Profile picture + dropdown options -->
      <div class="user-options-mobile dropdown">
        <div class="clickable dropdown-toggle" data-toggle="dropdown">
          <div class="sidebar-user-img">
            <span class="owner-img" *ngIf="globals.user">
              <app-profile-photo [src]="globals.user.imageUrl" [firstName]="globals.user.firstName" [lastName]="globals.user.lastName"></app-profile-photo>
            </span>
            <div class="dropdown-indicator">
              <span class="fal fa-fw fa-chevron-down"></span>
            </div>
          </div>
        </div>

        <ul class="dropdown-menu dropdown-with-icons">
          <li>
            <a [routerLink]="['/profile']" [attr.data-intercom-target]="'View Profile'">
              <span class="fal fa-user"></span> {{eNavbarMessages.VIEW_PROFILE | translate}}
            </a>
          </li>
          <li class="divider"></li>
          <li>
            <a [routerLink]="['/settings']" [attr.data-intercom-target]="'Edit Profile'">
              <span class="fal fa-cog"></span> {{eSettingsMessages.SETTINGS | translate}}
            </a>
          </li>
          <li class="divider"></li>
          <li (click)="logout()" *ngIf="!isMock && !globals.usingTeams">
              <a angulartics2On="click" angularticsAction="LogoutNavbar"
                  [attr.data-intercom-target]="'Logout Navbar'"
                  angularticsCategory="Support">
                  <span class="fal fa-times-circle"></span> {{eNavbarMessages.LOGOUT | translate}}
              </a>
          </li>
        </ul>
      </div>

      <div class="sidebar-language-select" *ngIf="!isMock && !globals.usingTeams">
        <div class="sidebar-language-select-inner">
          <app-language-selector [showSelectedLanguageName]="false" [alignDropdownToRight]="true"></app-language-selector>
        </div>
      </div>
    </div>

    <div class="frankli-sidebar-search">
      <div class="input-group search-container">
        <span class="input-group-addon search-icon">
          <span class="fal fa-search"></span>
        </span>
        <input type="search" class="form-control nav-search-bg" placeholder="Search People or Interests"
          [(ngModel)]="searchValue" maxlength="100" angulartics2On="click" angularticsAction="ClickSearchNavbar"
          angularticsCategory="Support" [attr.data-intercom-target]="'Search Bar'" (keyup)="checkEnter($event)" />
      </div>
    </div>
  
    <div class="frankli-sidebar-wrapper" [class.setup-nudge-wrapper]="!setupComplete && (globals.hasRole(eRoleName.ADMIN) || globals.hasRole(eRoleName.FRANKLI_ADMIN))">
      <!-- Employee -->
      <ul class="frankli-sidebar-group">
        <ng-container *ngFor="let menuItem of employeeMenuItems; let i = index;" [ngTemplateOutlet]="navigationRow" [ngTemplateOutletContext]="{ menuItem: menuItem, index: i, type: 'employee' }"></ng-container>
      </ul>
  
      <!-- Manager -->
      <ng-container *ngIf="(this.hasAccess.manager || this.hasAccess.secondaryManager) && (managerMenuItems.length > 0)">
        <hr class="line"/>
        <ul class="frankli-sidebar-group">
          <ng-container *ngFor="let menuItem of managerMenuItems; let i = index;" [ngTemplateOutlet]="navigationRow" [ngTemplateOutletContext]="{ menuItem: menuItem, index: i, type: 'manager' }"></ng-container>
        </ul>
      </ng-container>
  
      <!-- Admin -->
      <ng-container *ngIf="this.hasAccess.admin && (adminMenuItems.length > 0)">
        <hr class="line"/>
        <ul class="frankli-sidebar-group">
          <ng-container *ngFor="let menuItem of adminMenuItems; let i = index;" [ngTemplateOutlet]="navigationRow" [ngTemplateOutletContext]="{ menuItem: menuItem, index: i, type: 'admin' }"></ng-container>
        </ul>
      </ng-container>
  
      <!-- General -->
      <ng-container *ngIf="generalMenuItems && (generalMenuItems.length > 0)">
        <hr class="line"/>
        <ul class="frankli-sidebar-group">
          <ng-container *ngFor="let menuItem of generalMenuItems; let i = index;" [ngTemplateOutlet]="navigationRow" [ngTemplateOutletContext]="{ menuItem: menuItem, index: i, type: 'employee' }"></ng-container>
        </ul>
      </ng-container>

      <!-- Frankli admin-->
      <ng-container *ngIf="this.hasAccess.frankliAdmin">
        <hr class="line"/>
        <ul class="frankli-sidebar-group">
          <ng-container *ngFor="let menuItem of frankliAdminMenuItems" [ngTemplateOutlet]="navigationRow" [ngTemplateOutletContext]="{ menuItem: menuItem }"></ng-container>
        </ul>
      </ng-container>
    </div>
  
    <ng-template let-menuItem="menuItem" #navigationRow>
      <li class="menu-item-outer" routerLinkActive="active" [title]="sidebarService.sidebarMini ? menuItem.title : ''" *ngIf="!(globals.onMobile && menuItem.hideOnMobile) && (!menuItem.locked || (menuItem.locked && this.hasAccess.admin))">
    
        <!--If is a single link-->
        <ng-container *ngIf="!menuItem.children; else templateMenuItemHasChildren;">
          <a class="menu-item" [attr.data-intercom-target]="menuItem.title" [routerLink]="[menuItem.path]">
            <div class="menu-item-icon">
              <i class="{{menuItem.iconType}}"></i>
            </div>
            <div class="menu-item-text">
              <span class="menu-item-title">{{menuItem.title}}</span>
              <ng-container *ngIf="menuItem.locked">
                <span class="menu-item-locked fal fa-lock"></span>
              </ng-container>
              <ng-container *ngIf="menuItem.isBeta">
                <div class="menu-item-preview">
                  <app-pill [minWidth]="0">
                    <small>Preview</small>
                  </app-pill>
                </div>
              </ng-container>
            </div>
          </a>
        </ng-container>

        <ng-template #templateMenuItemHasChildren>
          <a class="menu-item" [attr.aria-expanded]="menuItem.expanded" [attr.data-intercom-target]="menuItem.title" data-toggle="collapse" href="#{{menuItem.title}}-employee">
            <div class="menu-item-icon">
              <i class="{{menuItem.iconType}}"></i>
            </div>
            <div class="menu-item-text">
              <span>{{menuItem.title}}</span>
            </div>
            <b class="caret"></b>
          </a>

          <div id="{{menuItem.title}}-employee" [ngClass]="menuItem.expanded ? 'collapse in' : 'collapse'" [attr.aria-expanded]="menuItem.expanded">
            <ul class="frankli-sidebar-submenu">
              <ng-container *ngFor="let childItem of menuItem.children" [ngTemplateOutlet]="navigationRow" [ngTemplateOutletContext]="{ menuItem: childItem }"></ng-container>
            </ul>
          </div>
        </ng-template>
      </li>
    </ng-template>

    <ng-container *ngIf="!setupComplete && (globals.hasRole(eRoleName.ADMIN) || globals.hasRole(eRoleName.FRANKLI_ADMIN)) && globals && globals.company && globals.company.setUpSteps">
      <app-sidebar-setup [setUpSteps]="globals.company.setUpSteps"></app-sidebar-setup>
    </ng-container>

    <ng-container *ngIf="!globals.usingTeams">
      <div class="sidebar-collapse-toggle" (click)="sidebarService.sidebarMini = !sidebarService.sidebarMini" [title]="sidebarService.sidebarMini ? 'Expand sidebar' : 'Collapse sidebar'">
        <span class="fal frankli-view expand-on-hover" [ngClass]="(sidebarService.sidebarMini ? 'fa-fw fa-chevron-right' : 'fa-chevron-left')"></span>
      </div>
    </ng-container>
  </div>
</div>