import { Observable, OperatorFunction } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { IEvaluagentAPI } from './evaluagent-api.model';
import { EvaluagentToken, EvaluagentTokenServerSide, UpdateEvaluagentToken } from '../model/evaluagent-token.model';
import { map } from 'rxjs/operators';
import { EvaluagentEvaluationObject, EvaluagentEvaluationObjectServerSide } from '../model/evaluagent-evaluation-object.model';
import { EvaluagentScorecardAverage } from '../model/evaluagent-scorecard-average.model';

@Injectable()
export class EvaluagentAPIService implements IEvaluagentAPI {

  private readonly BASE_URL = 'api/evaluagent';

  constructor(private http: HttpClient) {
  }

  getCredentials(): Observable<EvaluagentToken> {
    const url = `${this.BASE_URL}/credentials`;

    return this.http.get<EvaluagentTokenServerSide>(url)
      .pipe(this.parseToken());
  }

  updateCredentials(token: UpdateEvaluagentToken): Observable<EvaluagentToken> {
    const url = `${this.BASE_URL}/credentials`;

    return this.http.post<EvaluagentTokenServerSide>(url, token)
      .pipe(this.parseToken());
  }

  removeCredentials(): Observable<null> {
    const url = `${this.BASE_URL}/credentials`;

    return this.http.delete<null>(url);
  }

  getEvaluationsByUserId(userId: number, dateRangeStart: Date, dateRangeEnd: Date): Observable<EvaluagentEvaluationObject[]> {
    const url = `${this.BASE_URL}/evaluations/user/${userId}`;

    const params = new HttpParams()
      .append('start', dateRangeStart.toISOString())
      .append('end', dateRangeEnd.toISOString());

    return this.http.get<EvaluagentEvaluationObjectServerSide[]>(url, { params })
      .pipe(this.parseEvaluationObject());
  }

  getScorecardAverages(dateRangeStart: Date, dateRangeEnd: Date): Observable<EvaluagentScorecardAverage[]> {
    const url = `${this.BASE_URL}/scorecard-averages`;

    const params = new HttpParams()
      .append('start', dateRangeStart.toISOString())
      .append('end', dateRangeEnd.toISOString());

    return this.http.get<EvaluagentScorecardAverage[]>(url, { params });
  }

  runManualSync(): Observable<boolean> {
    const url = `${this.BASE_URL}/manual-sync`;
    return this.http.get<boolean>(url);
  }

  getOldestEvaluationPublishDate(): Observable<Date> {
    const url = `${this.BASE_URL}/oldest-evaluation-date`;
    return this.http.get<string>(url)
      .pipe(map((date: string) => {
        if (!date) { return null; }
        return new Date(date);
      }));
  }

  parseToken(): OperatorFunction<EvaluagentTokenServerSide, EvaluagentToken> {
    return map((token: EvaluagentTokenServerSide) => {
      if (!token) { return undefined; }
      return new EvaluagentToken(token);
    });
  }

  parseEvaluationObject(): OperatorFunction<EvaluagentEvaluationObjectServerSide[], EvaluagentEvaluationObject[]> {
    return map((evaluationObject: EvaluagentEvaluationObjectServerSide[]) => {
      if (!evaluationObject) { return undefined; }
      return evaluationObject.map(evaluationObject => new EvaluagentEvaluationObject(evaluationObject));
    });
  }
}
