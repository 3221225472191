import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { CommonMessages } from '@app/constants/common.messages';
import { OneToOneMeeting } from '@app/domain/one_to_one/model/one-to-one-meeting.model';
import { OneToOneNoteMinimal } from '@app/domain/one_to_one/model/one-to-one-note.model';
import { OneToOneSchedule } from '@app/domain/one_to_one/model/one-to-one-schedule.model';
import { OneToOneBusinessService } from '@app/domain/one_to_one/service/one-to-one-business.service';
import { Globals } from '@app/shared/globals/globals';

@Component({
  selector: 'app-one-to-one-meeting-display',
  templateUrl: './one-to-one-meeting-display.component.html',
  styleUrls: ['./one-to-one-meeting-display.component.scss']
})
export class OneToOneMeetingDisplayComponent implements OnChanges {

  public readonly eCommonMessages = CommonMessages;

  @Input() schedule: OneToOneSchedule;
  @Input() meeting: OneToOneMeeting;
  @Input() managerId: number;
  @Input() participantId: number;

  @Input() showTalkingPoints: boolean;
  @Input() showSharedNotes: boolean;
  @Input() showPrivateNotes: boolean;

  privateNotes: OneToOneNoteMinimal;

  constructor(
    public globals: Globals,
    private oneToOneBusinessService: OneToOneBusinessService
  ) {
    this.meeting = undefined;
    this.managerId = undefined;
    this.participantId = undefined;
    this.showTalkingPoints = true;
    this.showSharedNotes = true;
    this.showPrivateNotes = true;
  }

  ngOnChanges(changes: SimpleChanges): void {
    const changesMeeting = changes['meeting'];
    if (changesMeeting) {
      this.refreshData();
    }
  }

  refreshData(): void {
    if (!this.schedule) { return; }
    if (!this.meeting) { return; }

    this.oneToOneBusinessService.getMeetingPrivateNotes(this.schedule.id, this.meeting.id)
      .then(privateNotes => {
        this.privateNotes = privateNotes;
      });
  }

}
