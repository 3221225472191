export interface UserRole {
    id: number;
    name: RoleName;
}

export enum RoleName {
    FRANKLI_ADMIN = 'FRANKLI_ADMIN',
    ADMIN = 'ADMIN',
    MANAGER = 'MANAGER',
    HR = 'HR',
    USER = 'USER',
    ACCESS_REVOKED = 'ACCESS_REVOKED',
    GOAL_COMPANY = 'GOAL_COMPANY',
    ARCHIVED = 'ARCHIVED',
    COMPANY_CHAMPION = 'COMPANY_CHAMPION', 
    SURVEY_COMPANY = 'SURVEY_COMPANY', 
    SECONDARY_MANAGER = 'SECONDARY_MANAGER',
    CAREER_COMPANY = 'CAREER_COMPANY',
}
