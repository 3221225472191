import { Component, OnInit } from '@angular/core';
import { AuthAPIService } from '../auth/auth.api.service';
import { SwalUtils } from '../utils/swal.utils';

@Component({
  selector: 'app-revoked',
  template: '',
})
export class RevokedComponent implements OnInit {

  constructor(
    private authService: AuthAPIService,
    private swalUtils: SwalUtils
  ) { }

  ngOnInit() {
    this.swalUtils.displayWarning({
      title: 'Access Revoked',
      text: 'Contact your manager to find out more'
    }).then(() => {
      this.authService.logout();
    });
  }

}
