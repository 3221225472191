<div class="goal-display-container" [class.is-selected]="selected" [class.can-select]="canSelect" [class.can-deselect]="canDeselect" [class.clickable]="canSelect || canDeselect || canOpen" *ngIf="goal" (click)="onClickGoal()">
  <div class="display-goal-status" [ngClass]="status" [title]="status | underscoreToSpace | titlecase"></div>

  <div class="area-goal-data">
    <div class="area-top-row">
      <ng-container *ngIf="showIcon">
        <span class="fal fa-fw display-goal-type" [ngClass]="goalIcon" [tooltip]="goalIconTooltip.code | translate: goalIconTooltip.params" [triggers]="globals.tooltipTriggers" placement="bottom"></span>
      </ng-container>

      <span class="display-goal-title-and-site-department">
        <div class="display-goal-title">{{goal.title}}</div>
        <ng-container *ngIf="goal.type != null && [eGoalType.OFFICE_LOCATION, eGoalType.DEPARTMENT].includes(goal.type)">
          <div class="display-goal-site" *ngIf="goal.type === eGoalType.OFFICE_LOCATION && goal.officeLocation">
            <span class="display-subtitle-label">{{globals.getTerminology(eTerminologyEntity.SITE)}}:&nbsp;</span>
            <span class="display-subtitle-value">{{goal.officeLocation.name}}</span>
          </div>
          <div class="display-goal-department" *ngIf="goal.type === eGoalType.DEPARTMENT && goal.department">
            <span class="display-subtitle-label">{{globals.getTerminology(eTerminologyEntity.DEPARTMENT)}}:&nbsp;</span>
            <span class="display-subtitle-value">{{goal.department.name}}</span>
          </div>
        </ng-container>
      </span>

      <div class="display-goal-owners">
        <ng-container *ngIf="showOwners">
          <app-person-picture-list [users]="goal.owners"></app-person-picture-list>
        </ng-container>
      </div>
      <span class="display-goal-end-date">
        <ng-container *ngIf="goal.endDate !== null">
          <app-pill [type]="ePillType.YELLOW" [text]="goal.endDate | momentPipe: DATE_FORMAT"></app-pill>
        </ng-container>
      </span>
    </div>

    <ng-container *ngIf="showProgress && (goal.completionPercentage !== null)">
      <div class="area-bottom-row">
        <ng-container *ngIf="(goalPrevious !== undefined); else templateProgressNormal;">
          <app-progress-bar [startValue]="0" [currentValue]="goal.completionPercentage || 0" [previousValue]="goalPrevious.completionPercentage" [targetValue]="100" [showBackground]="true"></app-progress-bar>
        </ng-container>
        <ng-template #templateProgressNormal>
          <app-progress-bar [startValue]="0" [currentValue]="goal.completionPercentage || 0" [targetValue]="100" [showBackground]="true"></app-progress-bar>
        </ng-template>
      </div>
    </ng-container>
  </div>

  <ng-container *ngIf="canSelect">
    <div class="area-select-checkbox">
      <div class="check-container">
        <span class="fal fa-fw fa-check"></span>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="canDeselect && selected">
    <div class="area-deselect-checkbox">
      <div class="times-container">
        <span class="fal fa-fw fa-times"></span>
      </div>
    </div>
  </ng-container>
</div>