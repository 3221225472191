<div class="dropdown-display-container" [class.dropdown-expanded]="expanded">
  <div class="dropdown-display-header" (click)="onClickHeader($event)">
    <div class="col-details">
      <div class="dropdown-title">
        <ng-container *ngIf="iconClass">
          <span class="title-icon">
            <span class="fal fa-fw" [ngClass]="iconClass"></span>
          </span>
        </ng-container>
        <span translate>{{title}}</span>
      </div>
      <div class="dropdown-description" translate>{{description}}</div>
    </div>
    <div class="col-caret">
      <span class="fal fa-fw fa-caret-right"></span>
    </div>
  </div>
   <!-- [style.padding-bottom]="overflowBottomDisplay" [style.margin-bottom]="'-' + overflowBottomDisplay" -->
  <div class="dropdown-display-contents" [style.minHeight]="minContentHeightClosed">
    <ng-content></ng-content>
  </div>
</div>
