import { Injectable } from '@angular/core';
import { NotifyUtils } from '@app/shared/utils/notify.utils';
import { Observable } from 'rxjs';
import { RoleApplicationMessages } from '@app/domain/role_application/locale/role-application.messages';
import { RoleApplicationAPIService } from '../api/role-application-api.service';
import { RoleApplication } from '../model/role-application.model';
import { RoleApplicationActivity } from '../model/role-application-activity.model';
import { RoleApplicationQuestion } from '../model/role-application-question.model';
import { UpdateRoleApplicationQuestion } from '../model/update-role-application-question.model';
import { RoleApplicationState } from '../model/role-application-state.model';
import { RoleApplicationUpdateReviewers } from '../model/role-application-update-reviewers.model';
import { RoleApplicationAddPrimaryReviewer } from '../model/role-application-add-primary-reviewer.model';
import { RoleApplicationSettings } from '../model/role-application-settings.model';
import { ParentFilter } from '@app/models/api/parent-filter.model';
import { Page } from '@app/models/api/page.model';

@Injectable({
  providedIn: 'root'
})
export class RoleApplicationBusinessService {

  updating: number[];
  updatingUsers: number[];
  uploadingFile: boolean;
  updatingSettings: boolean;

  constructor(
    private roleApplicationAPI: RoleApplicationAPIService,
    private notifyUtils: NotifyUtils
  ) {
    this.updating = [];
    this.updatingUsers = [];
    this.uploadingFile = false;
    this.updatingSettings = false;
  }

  private startUpdating(ids: number[]) {
    this.updating = [...this.updating, ...ids];
  }

  private stopUpdating(ids: number[]) {
    this.updating = this.updating.filter(x => !ids.includes(x));
  }

  private startUpdatingUsers(ids: number[]) {
    this.updatingUsers = [...this.updatingUsers, ...ids];
  }

  private stopUpdatingUsers(ids: number[]) {
    this.updatingUsers = this.updatingUsers.filter(x => !ids.includes(x));
  }

  get(
    ids: number[],
    applicantIds: number[],
    reviewerIds: number[],
    states: RoleApplicationState[],
    includeArchived: boolean
  ): Observable<RoleApplication[]> {
    return this.roleApplicationAPI.get(ids, applicantIds, reviewerIds, states, includeArchived);
  }

  // getPaginated(
  //   pagingParams: PagingParams,
  //   sortingParams: SortingParams,
  //   ids: number[],
  //   applicantIds: number[],
  //   reviewerIds: number[],
  //   states: RoleApplicationState[]
  // ): Observable<Page<RoleApplication>> {
  //   return this.roleApplicationAPI.getPaginated(pagingParams, sortingParams, ids, applicantIds, reviewerIds, states);
  // }

  create(roleId: number): Promise<RoleApplication> {
    return this.roleApplicationAPI.create(roleId).toPromise()
      .then(res => {
        this.notifyUtils.notify(RoleApplicationMessages.SUCCESS_CREATE_APPLICATION);
        return res;
      });
  }

  delete(id: number): Promise<RoleApplication> {
    if (this.updating.includes(id)) { return new Promise((resolve, reject) => reject()); }
    this.startUpdating([id]);

    return this.roleApplicationAPI.delete(id).toPromise()
      .then(res => {
        this.notifyUtils.notify(RoleApplicationMessages.SUCCESS_DELETE);
        return res;
      })
      .finally(() => {
        this.stopUpdating([id]);
      });
  }

  discard(id: number): Promise<RoleApplication> {
    if (this.updating.includes(id)) { return new Promise((resolve, reject) => reject()); }
    this.startUpdating([id]);

    return this.roleApplicationAPI.discard(id).toPromise()
      .then(res => {
        this.notifyUtils.notify(RoleApplicationMessages.SUCCESS_DISCARD);
        return res;
      })
      .finally(() => {
        this.stopUpdating([id]);
      });
  }

  submit(id: number): Promise<RoleApplication> {
    if (this.updating.includes(id)) { return new Promise((resolve, reject) => reject()); }
    this.startUpdating([id]);

    return this.roleApplicationAPI.submit(id).toPromise()
      .then(res => {
        this.notifyUtils.notify(RoleApplicationMessages.SUCCESS_SUBMIT);
        return res;
      })
      .finally(() => {
        this.stopUpdating([id]);
      });
  }

  decline(id: number, declineReason: string): Promise<RoleApplication> {
    if (this.updating.includes(id)) { return new Promise((resolve, reject) => reject()); }
    this.startUpdating([id]);

    return this.roleApplicationAPI.decline(id, declineReason).toPromise()
      .then(res => {
        this.notifyUtils.notify(RoleApplicationMessages.SUCCESS_REJECT);
        return res;
      })
      .finally(() => {
        this.stopUpdating([id]);
      });
  }

  approve(id: number): Promise<RoleApplication> {
    if (this.updating.includes(id)) { return new Promise((resolve, reject) => reject()); }
    this.startUpdating([id]);

    return this.roleApplicationAPI.approve(id).toPromise()
      .then(res => {
        this.notifyUtils.notify(RoleApplicationMessages.SUCCESS_FINALIZE);
        return res;
      })
      .finally(() => {
        this.stopUpdating([id]);
      });
  }

  reopen(id: number, reopenReason: string): Promise<RoleApplication> {
    if (this.updating.includes(id)) { return new Promise((resolve, reject) => reject()); }
    this.startUpdating([id]);

    return this.roleApplicationAPI.reopen(id, reopenReason).toPromise()
      .then(res => {
        this.notifyUtils.notify(RoleApplicationMessages.SUCCESS_REJECT);
        return res;
      })
      .finally(() => {
        this.stopUpdating([id]);
      });
  }

  award(id: number, sendBackToManager: boolean): Promise<RoleApplication> {
    if (this.updating.includes(id)) { return new Promise((resolve, reject) => reject()); }
    this.startUpdating([id]);

    return this.roleApplicationAPI.award(id, sendBackToManager).toPromise()
      .then(res => {
        this.notifyUtils.notify(RoleApplicationMessages.SUCCESS_FINALIZE);
        return res;
      })
      .finally(() => {
        this.stopUpdating([id]);
      });
  }

  awardApprovedByAdmin(id: number): Promise<RoleApplication> {
    if (this.updating.includes(id)) { return new Promise((resolve, reject) => reject()); }
    this.startUpdating([id]);

    return this.roleApplicationAPI.awardApprovedByAdmin(id).toPromise()
      .then(res => {
        this.notifyUtils.notify(RoleApplicationMessages.SUCCESS_FINALIZE_APPROVED);
        return res;
      })
      .finally(() => {
        this.stopUpdating([id]);
      });
  }

  updateReviewers(id: number, roleApplicationAddReviewers: RoleApplicationUpdateReviewers): Promise<RoleApplication> {
    if (this.updating.includes(id)) { return new Promise((resolve, reject) => reject()); }
    this.startUpdating([id]);

    return this.roleApplicationAPI.updateReviewers(id, roleApplicationAddReviewers).toPromise()
      .then(res => {
        this.notifyUtils.notify(RoleApplicationMessages.SUCCESS_ADD_REVIEWERS);
        return res;
      })
      .finally(() => {
        this.stopUpdating([id]);
      });
  }

  addPrimaryReviewer(id: number, roleApplicationAddPrimaryReviewer: RoleApplicationAddPrimaryReviewer): Promise<RoleApplication> {
    if (this.updating.includes(id)) { return new Promise((resolve, reject) => reject()); }
    this.startUpdating([id]);

    return this.roleApplicationAPI.updatePrimaryReviewer(id, roleApplicationAddPrimaryReviewer).toPromise()
      .then(res => {
        this.notifyUtils.notify(RoleApplicationMessages.SUCCESS_ADD_PRIMARY_REVIEWER);
        return res;
      })
      .finally(() => {
        this.stopUpdating([id]);
      });
  }

  getActivityForApplicationId(applicationId: number): Observable<RoleApplicationActivity[]> {
    return this.roleApplicationAPI.getActivityForApplicationId(applicationId);
  }

  getApplicantQuestions(applicationId: number): Observable<RoleApplicationQuestion[]> {
    return this.roleApplicationAPI.getApplicantQuestions(applicationId);
  }

  getPrimaryReviewerQuestions(applicationId: number): Observable<RoleApplicationQuestion[]> {
    return this.roleApplicationAPI.getPrimaryReviewerQuestions(applicationId);
  }

  updateApplicantQuestions(applicationId: number, updatedQuestions: UpdateRoleApplicationQuestion[]) {
    if (this.updating.includes(applicationId)) { return new Promise((resolve, reject) => reject()); }
    this.startUpdating([applicationId]);

    return this.roleApplicationAPI.updateApplicantQuestions(applicationId, updatedQuestions).toPromise()
      .then(res => {
        this.notifyUtils.notify(RoleApplicationMessages.SUCCESS_ANSWERS_SAVED);
        return res;
      })
      .finally(() => {
        this.stopUpdating([applicationId]);
      });
  }

  updatePrimaryReviewerQuestions(applicationId: number, updatedQuestions: UpdateRoleApplicationQuestion[]) {
    if (this.updating.includes(applicationId)) { return new Promise((resolve, reject) => reject()); }
    this.startUpdating([applicationId]);

    return this.roleApplicationAPI.updatePrimaryReviewerQuestions(applicationId, updatedQuestions).toPromise()
      .then(res => {
        this.notifyUtils.notify(RoleApplicationMessages.SUCCESS_ANSWERS_SAVED);
        return res;
      })
      .finally(() => {
        this.stopUpdating([applicationId]);
      });
  }

  addCommentToRoleApplication(applicationId: number, commentText: string): Promise<RoleApplicationActivity[]> {
    if (this.updating.includes(applicationId)) { return new Promise((resolve, reject) => reject()); }
    this.startUpdating([applicationId]);

    return this.roleApplicationAPI.addCommentToApplication(applicationId, commentText).toPromise()
      .then(res => {
        this.notifyUtils.notify(RoleApplicationMessages.SUCCESS_COMMENT_ADDED);
        return res;
      })
      .finally(() => {
        this.stopUpdating([applicationId]);
      });
  }

  getApplicationsCreatedByMyReports(): Observable<RoleApplication[]> {
    return this.roleApplicationAPI.getApplicationsCreatedByMyReports();
  }

  getRoleApplicationSettings(): Observable<RoleApplicationSettings> {
    return this.roleApplicationAPI.getRoleApplicationSettings();
  }

  updateRoleApplicationSettings(roleApplicationSettings: RoleApplicationSettings): Promise<RoleApplicationSettings> {
    if(this.updatingSettings) { return new Promise((resolve, reject) => reject()); }
    this.updatingSettings = true;

    return this.roleApplicationAPI.updateRoleApplicationSettings(roleApplicationSettings).toPromise()
      .then(res => {
        this.notifyUtils.notify(RoleApplicationMessages.SUCCESS_SETTINGS_UPDATED);
        return res;
      })
      .finally(() => {
        this.updatingSettings = false;
      });
  }

  getFiltered(parentFilter: ParentFilter): Observable<Page<RoleApplication>> {
    return this.roleApplicationAPI.getFiltered(parentFilter);
  }

  getFilteredExport(parentFilter: ParentFilter): Observable<Blob> {
    return this.roleApplicationAPI.getFilteredExport(parentFilter);
  }
}
