<div class="container-role-display" *ngIf="role">
  <div class="display-role-name">
    <h4 class="standard-heading">
      <span>{{role.name}}</span>
      <ng-container *ngIf="role.grade && globals.hasFeature(eCompanyFeatures.GRADES)">
        <span class="m-right-5"></span>
        <span>({{role.grade}})</span>
      </ng-container>
    </h4>
  </div>

  <div class="display-role-description">
    <div class="standard-description">
      <ng-container *ngIf="(role.description && role.description.length > 0); else templateNoRoleDescription;">
        <app-rich-display [text]="role.description"></app-rich-display>
      </ng-container>
      <ng-template #templateNoRoleDescription>
        <!-- <app-rich-display [text]="eRoleMessages.NO_DESCRIPTION | translate"></app-rich-display> -->
      </ng-template>
    </div>
  </div>

  <div class="display-role-competencies p-top-15" *ngIf="globals.hasFeature(eCompanyFeatures.COMPETENCIES)">
    <hr class="m-top-0 m-bottom-15"/>

    <h5 class="standard-heading" translate [translateParams]="globals.terminology">{{eRoleMessages.COMPETENCIES_REQUIRED}}</h5>
    <div class="competencies-list">
      <ng-container *ngIf="competenciesCompared && competenciesCompared.length > 0; else templateNoCompetencies">
        <ng-container *ngFor="let competency of competenciesCompared">
          <div class="competency-item noselect" [tooltip]="((enableComparison && !isSkillSelected(skill)) || competency.description) ? tooltipCompetency : ''" [triggers]="globals.tooltipTriggers" placement="bottom" container="body">
            <app-pill [type]="isCompetencySelected(competency) ? ePillType.GREEN : ePillType.YELLOW">
              <strong>{{competency.name}}</strong>
            </app-pill>
          </div>
          <ng-template #tooltipCompetency>
            <div class="text-left">
              <div class="p-bottom-10" *ngIf="enableComparison && !isCompetencySelected(competency)">
                <app-pill [displayFullWidth]="true">
                  <div class="text-wrap">This {{globals.getTerminology(eTerminologyEntity.COMPETENCY) | lowercase }} is not associated with your current role.</div>
                </app-pill>
              </div>
              <ng-container *ngIf="competency.description">
                <app-rich-display [text]="competency.description"></app-rich-display>
              </ng-container>
            </div>
          </ng-template>
        </ng-container>
      </ng-container>
    </div>

    <ng-template #templateNoCompetencies>
      <div translate>{{eCommonMessages.NONE_SELECTED}}</div>
    </ng-template>
  </div>

  <div class="display-role-skills p-top-15" *ngIf="globals.hasFeature(eCompanyFeatures.SKILLS)">
    <hr class="m-top-0 m-bottom-15"/>

    <h5 class="standard-heading" translate [translateParams]="globals.terminology">{{eRoleMessages.SKILLS_REQUIRED}}</h5>
    <ng-container *ngIf="skillsCompared && skillsCompared.length > 0; else templateNoSkills">
      <div class="skills-list">
        <ng-container *ngFor="let skill of skillsCompared">
          <div class="skill-item noselect" [tooltip]="enableComparison && !isSkillSelected(skill) ? tooltipSkill : ''" [triggers]="globals.tooltipTriggers" placement="bottom" container="body">
            <app-interest-display [interest]="skill" [type]="isSkillSelected(skill) ? eInterestDisplayType.GREEN : eInterestDisplayType.YELLOW" [showIcon]="false"></app-interest-display>
          </div>
          <ng-template #tooltipSkill>
            <div class="text-left">
                <app-pill [displayFullWidth]="true">
                  <div class="text-wrap">This {{globals.getTerminology(eTerminologyEntity.SKILL)}} is not associated with your current role and has not been added to your profile.</div>
                </app-pill>
            </div>
          </ng-template>
        </ng-container>
      </div>
    </ng-container>

    <ng-template #templateNoSkills>
      <div class="p-left-15 p-right-15 p-top-5 p-bottom-5" translate>{{eCommonMessages.NONE_SELECTED}}</div>
    </ng-template>
  </div>

  <div class="display-role-department p-top-15">
    <hr class="m-top-0 m-bottom-15"/>

    <h5 class="standard-heading" translate [translateParams]="{ department: globals.getTerminology(eTerminologyEntity.DEPARTMENT) }">{{eRoleMessages.ASSOCIATED_DEPARTMENT}}</h5>
    <div class="department-list">
      <ng-container *ngIf="role.department; else templateNoDepartment">
        <div class="p-left-15 p-right-15 p-top-5 p-bottom-5">{{role.department.name}}</div>
      </ng-container>
    </div>

    <ng-template #templateNoDepartment>
      <div class="p-left-15 p-right-15 p-top-5 p-bottom-5" translate>{{eCommonMessages.NONE_SELECTED}}</div>
    </ng-template>
  </div>

  <div class="display-role-assigned-people p-top-15" *ngIf="showUsersInRole">
    <hr class="m-top-0 m-bottom-15"/>

    <h5 class="standard-heading" translate>{{eRoleMessages.PEOPLE_IN_ROLE}}</h5>

    <div class="p-top-10">
      <ng-container *ngIf="(roleUsers && (roleUsers.length > 0)); else templateNoUsers">
        <app-person-picture-list [users]="roleUsers" [maxListed]="10"></app-person-picture-list>
      </ng-container>
    </div>

    <ng-template #templateNoUsers>
      <div translate>{{eCommonMessages.NONE}}</div>
    </ng-template>
  </div>
</div>
