<div class="container-sidebar-absorblms">
  <app-state [state]="state">
    <ng-container *ngIf="!state.loading && !state.error;">
      <div class="area-filters p-bottom-15">
        <div class="frankli-form-group frankli-form-group-filled">
          <label for="enrollmentStatus">Status</label>
          <select id="enrollmentStatus" class="form-control" [formControl]="controlStatus">
            <option [ngValue]="null">Show all</option>
            <ng-container *ngFor="let enrollmentStatus of dataEnrollmentStatuses">
              <option [ngValue]="enrollmentStatus">{{enrollmentStatus | underscoreToSpace | titlecase}}</option>
            </ng-container>
          </select>
        </div>
      </div>

      <ng-container *ngIf="data.master.length > 0; else templateNoData;">
        <div class="data-list">
          <ng-container *ngFor="let enrollment of data.display; index as index;">
            <app-absorblms-enrollment-display [enrollment]="enrollment"></app-absorblms-enrollment-display>
          </ng-container>
        </div>

        <app-pagination-new-component [arrayData]="data.filtered" [arrayDisplay]="data.display" #pagination></app-pagination-new-component>
      </ng-container>

      <ng-template #templateNoData>
        <app-empty-state [height]="'200px'" [message]="'No enrollments found'"></app-empty-state>
      </ng-template>
    </ng-container>
  </app-state>
</div>
