<div class="goal-create-development-needs-container">
  <div class="p-15">
    <ng-container *ngIf="parentDropdownOpen; else templateDisplayValues">
      <div class="frankli-form-group frankli-form-group-filled frankli-form-group-placeholder-shown">
        <!-- <label for="goalDevelopmentNeeds">Development Needs</label> -->
        <textarea id="goalDevelopmentNeeds" [formControl]="controlDevelopmentNeeds" class="form-control noresize" [placeholder]="eGoalsMessages.GOAL_CREATE_DEVELOPMENT_NEEDS_PLACEHOLDER | translate"></textarea>
        <div class="frankli-form-group-alert">
          <div *ngIf="controlDevelopmentNeeds.hasError('minlength')" translate [translateParams]="{ minValue: eGoalCreateValidationLimits.developmentNeeds.min }">{{eCommonMessages.FORM_MIN_CHARACTER_VALUE}}</div>
          <div *ngIf="controlDevelopmentNeeds.hasError('maxlength')" translate [translateParams]="{ minValue: eGoalCreateValidationLimits.developmentNeeds.max }">{{eCommonMessages.FORM_MAX_CHARACTER_VALUE}}</div>
        </div>
      </div>
    </ng-container>
    <ng-template #templateDisplayValues>
      <div class="display-values" (click)="setParentDropdown(true)">
        <div class="text-development" [class.display-invalid]="submitted && controlDevelopmentNeeds.invalid">
          <ng-container *ngIf="controlDevelopmentNeeds.value; else noValue">
            <span translate>{{controlDevelopmentNeeds.value}}</span>
          </ng-container>
          <ng-template #noValue>
            <span translate>{{eCommonMessages.NOT_SET}}</span>
          </ng-template>
        </div>
        <span class="display-check">
          <span class="fal fa-fw fa-check"></span>
        </span>
      </div>
    </ng-template>
  </div>
</div>
