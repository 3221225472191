<div class="sidebar-question-item-container" *ngIf="question">
  <div class="top-row">
    <span>Q{{index + 1}}.</span>
    <span class="m-right-5"></span>
    <span>{{question}}</span>
  </div>
  <div class="question-response">
    <div class="question-response-inner">
      <ng-container [ngSwitch]="questionType">
        <app-likert-scale *ngSwitchCase="eSurveyQuestionType.LIKERT_SCALE" [ngModel]="answer" [columnIndent]="false" [disabled]="true" [display]="true" [circleSize]="40" [wrapWidth]="200" [circleBorderWidth]="3" [circleIconSize]="20" [labelFontSize]="14"></app-likert-scale>
        <app-likert-scale *ngSwitchCase="eSurveyQuestionType.LIKERT_SCALE_COMMENT" [ngModel]="[answer, comment] | likertComment" [commentEnabled]="true" [columnIndent]="false" [disabled]="true" [display]="true" [circleSize]="40" [wrapWidth]="200" [circleBorderWidth]="3" [circleIconSize]="20" [labelFontSize]="14"></app-likert-scale>
        <app-dynamic-scale *ngSwitchCase="eSurveyQuestionType.DYNAMIC_SCALE" [ngModel]="answer" [options]="options" [display]="true"></app-dynamic-scale>
        <app-rich-display *ngSwitchCase="eSurveyQuestionType.TEXT" [text]="answer"></app-rich-display>
        <ng-container *ngIf="assessmentGroups">
          <app-one-to-four-scale *ngSwitchCase="eSurveyQuestionType.ONE_TO_FOUR" [assessmentGroups]="assessmentGroups[eSurveyQuestionType.ONE_TO_FOUR]" [(ngModel)]="answer" [display]="true"></app-one-to-four-scale>
          <app-one-to-three-scale *ngSwitchCase="eSurveyQuestionType.ONE_TO_THREE" [assessmentGroups]="assessmentGroups[eSurveyQuestionType.ONE_TO_THREE]" [(ngModel)]="answer" [display]="true"></app-one-to-three-scale>
          <app-one-to-five-scale *ngSwitchCase="eSurveyQuestionType.ONE_TO_FIVE" [assessmentGroups]="assessmentGroups[eSurveyQuestionType.ONE_TO_FIVE]" [(ngModel)]="answer" [display]="true"></app-one-to-five-scale>
        </ng-container>
      </ng-container>
    </div>
  </div>
</div>