import { Injectable } from '@angular/core';
import { BrowseRole } from '@app/domain/browse_role/model/browse-role.model';
import { BrowseRoleAPIService } from '@app/domain/browse_role/api/browse-role-api.service';
import { NotifyUtils } from '@app/shared/utils/notify.utils';
import { Observable } from 'rxjs';
import { Page } from '@app/models/api/page.model';
import { PagingParams } from '@app/models/api/paging-params.model';
import { SortingParams } from '@app/models/api/sorting-params.model';
import { BrowseRoleMessages } from '../locale/browse-role.messages';

@Injectable({
  providedIn: 'root'
})
export class BrowseRolesBusinessService {

  updating: number[];
  uploadingFile: boolean;

  constructor(
    private browseRoleAPI: BrowseRoleAPIService,
    private notifyUtils: NotifyUtils
  ) {
    this.updating = [];
    this.uploadingFile = false;
  }

  private startUpdating(ids: number[]) {
    this.updating = [...this.updating, ...ids];
  }

  private stopUpdating(ids: number[]) {
    this.updating = this.updating.filter(x => !ids.includes(x));
  }

  get(
    ids: number[],
    name: string,
  ): Observable<BrowseRole[]> {
    return this.browseRoleAPI
      .get(ids, name);
  }

  getPaginated(
    pagingParams: PagingParams,
    sortingParams: SortingParams,
    ids: number[],
    name: string,
  ): Observable<Page<BrowseRole>> {
    return this.browseRoleAPI.getPaginated(pagingParams, sortingParams, ids, name);
  }

  getPinnedRoles(): Observable<BrowseRole[]> {
    return this.browseRoleAPI.getPinnedRoles();
  }

  markRoleAsPinned(roleId: number): Promise<BrowseRole[]> {
    if (this.updating.includes(roleId)) { return new Promise((resolve, reject) => reject()); }
    this.startUpdating([roleId]);

    return this.browseRoleAPI.markRoleAsPinned(roleId)
      .toPromise()
      .then(res => {
        this.notifyUtils.notify(BrowseRoleMessages.SUCCESS_PIN);
        return res;
      })
      .finally(() => {
        this.stopUpdating([roleId]);
      });
  }

  unmarkRoleAsPinned(roleId: number): Promise<BrowseRole[]> {
    if (this.updating.includes(roleId)) { return new Promise((resolve, reject) => reject()); }
    this.startUpdating([roleId]);

    return this.browseRoleAPI.unmarkRoleAsPinned(roleId)
      .toPromise()
      .then(res => {
        this.notifyUtils.notify(BrowseRoleMessages.SUCCESS_UNPIN);
        return res;
      })
      .finally(() => {
        this.stopUpdating([roleId]);
      });
  }
}
