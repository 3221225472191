export enum GoalOverviewPages {
  MY_GOALS,
  PERSONAL,
  TEAM,
  DEPARTMENT,
  OFFICE_LOCATION,
  COMPANY,
  ADMIN_GOAL,
  DRAFTS,
  TAGS
}