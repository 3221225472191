import { Component, Input } from '@angular/core';
import { NewFeature } from '@app/models/new-feature/new-feature.model';

@Component({
  selector: 'app-new-features-modal',
  templateUrl: './new-features-modal.component.html',
  styleUrls: ['./new-features-modal.component.scss']
})
export class NewFeaturesModalComponent {
  @Input() features: NewFeature[];

  constructor() {
    this.features = [];
  }
}
