import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { SidebarTasksComponent } from './sidebar-tasks/sidebar-tasks.component';
import { SharedModule } from '@app/shared/shared.module';
import { TaskComponentsModule } from '@app/domain/task/module/task-components.module';

const components = [
  SidebarTasksComponent
];

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    SharedModule,
    TaskComponentsModule
  ],
  providers: [],
  declarations: components,
  exports: components
})
export class SidebarModule {
}
