import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {NavbarComponent} from './navbar.component';
import {FormsModule} from '@angular/forms';
import {Angulartics2Module} from 'angulartics2';
import {PipesModule} from '../pipes/pipes.module';
import {SharedModule} from '../shared.module';
import {NavbarNotificationsComponent} from './navbar-notifications/navbar-notifications.component';
import {ScrollingModule} from '@angular/cdk/scrolling';
import {NewFeaturesModalComponent} from '../new-features-modal/new-features-modal.component';
import {I18nModule} from '@app/i18n/i18n.module';
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';
import {TranslateModule} from '@ngx-translate/core';

const components = [
    NavbarComponent,
    NavbarNotificationsComponent,
    NewFeaturesModalComponent
]
@NgModule({
    imports: [RouterModule, CommonModule, FormsModule, Angulartics2Module, SharedModule, PipesModule, ScrollingModule, I18nModule, BsDropdownModule, TranslateModule],
    declarations: components,
    exports: components
})

export class NavbarModule { }
