<div class="activity-item-container" *ngIf="activity">
  <div class="activity-intro">
    <ng-container [ngSwitch]="activity.type">
      <span *ngSwitchCase="eTaskActivityType.CREATED" [innerHTML]="eTasksMessages.ACTIVITY_TASK_CREATED | translate: {user: (activity.user.firstName + ' ' + activity.user.lastName)}"></span>
      <span *ngSwitchCase="eTaskActivityType.UPDATED" [innerHTML]="eTasksMessages.ACTIVITY_TASK_UPDATED | translate: {user: (activity.user.firstName + ' ' + activity.user.lastName)}"></span>
      <span *ngSwitchCase="eTaskActivityType.UPDATED_STATUS" [innerHTML]="eTasksMessages.ACTIVITY_TASK_UPDATED_STATUS | translate: {user: (activity.user.firstName + ' ' + activity.user.lastName)}"></span>
      <span *ngSwitchCase="eTaskActivityType.ARCHIVED" [innerHTML]="eTasksMessages.ACTIVITY_TASK_ARCHIVED | translate: {user: (activity.user.firstName + ' ' + activity.user.lastName)}"></span>
    </ng-container>
  </div>

  <div class="activity-detail-container">
    <ng-container [ngSwitch]="activity.type">
      <div *ngSwitchCase="eTaskActivityType.UPDATED">
        <ng-container [ngTemplateOutlet]="templateEditUpdate"></ng-container>
      </div>
      <div *ngSwitchCase="eTaskActivityType.UPDATED_STATUS">
        <ng-container [ngTemplateOutlet]="templateStatusUpdate"></ng-container>
      </div>
    </ng-container>
    
    <ng-template #templateEditUpdate>
      <div class="p-top-10">
        <div class="edit-item" *ngIf="activity.titlePrevious && activity.titleCurrent">
          <div translate>{{eTasksMessages.ACTIVITY_EDITED_TITLE}}</div>
          <div class="activity-comment">
            <strong>{{ activity.titlePrevious }}</strong>
            <span class="fal fa-arrow-right"></span>
            <strong>{{ activity.titleCurrent }}</strong>
          </div>
        </div>
        <div class="edit-item" *ngIf="activity.descriptionPrevious && activity.descriptionCurrent">
          <div translate>{{eTasksMessages.ACTIVITY_EDITED_DESCRIPTION}}</div>
          <div class="activity-comment">
            <strong>{{ activity.descriptionPrevious }}</strong>
            <span class="fal fa-arrow-right"></span>
            <strong>{{ activity.descriptionCurrent }}</strong>
          </div>
        </div>
        <div class="edit-item" *ngIf="activity.alignmentTypePrevious && activity.alignmentTypeCurrent">
          <div translate>{{eTasksMessages.ACTIVITY_EDITED_ALIGNMENT_TYPE}}</div>
          <div class="activity-comment">
            <strong>{{ activity.alignmentTypePrevious }}</strong>
            <span class="fal fa-arrow-right"></span>
            <strong>{{ activity.alignmentTypeCurrent }}</strong>
          </div>
        </div>
        <div class="edit-item" *ngIf="activity.alignmentIdPrevious && activity.alignmentIdCurrent">
          <div translate>{{eTasksMessages.ACTIVITY_EDITED_ALIGNMENT_ID}}</div>
          <!-- TODO: Show Goal or schedule name if possible -->
          <!-- <div class="activity-comment">
            <strong>{{ activity.alignmentIdPrevious }}</strong>
            <span class="fal fa-arrow-right"></span>
            <strong>{{ activity.alignmentIdCurrent }}</strong>
          </div> -->
        </div>
        <div class="edit-item" *ngIf="activity.dueDatePrevious || activity.dueDateCurrent">
          <div translate>{{eTasksMessages.ACTIVITY_EDITED_DUE_DATE}}</div>
          <div class="activity-comment">
            <strong>{{ activity.dueDatePrevious ? (activity.dueDatePrevious | momentPipe: 'DD MMM YYYY') : (eCommonMessages.NOT_SET | translate)}}</strong>
            <span class="fal fa-arrow-right"></span>
            <strong>{{ activity.dueDateCurrent ? (activity.dueDateCurrent | momentPipe: 'DD MMM YYYY') : (eCommonMessages.NOT_SET | translate)}}</strong>
          </div>
        </div>
        <div class="edit-item" *ngIf="activity.ownersRemoved && (activity.ownersRemoved.length > 0)">
          <div translate>{{eTasksMessages.ACTIVITY_EDITED_OWNERS_REMOVED}}</div>
          <div class="activity-comment">
            <ng-container *ngFor="let owner of activity.ownersRemoved">
              <span class="owner-img clickable m-right-5" [routerLink]="['/profile/', owner.id]">
                <!-- TODO: Add firstName and lastName -->
                <app-profile-photo [src]="owner.imageUrl"></app-profile-photo>
              </span>
              <strong>{{owner.firstName}} {{owner.lastName}}</strong>
            </ng-container>
          </div>
        </div>
        <div class="edit-item" *ngIf="activity.ownersAdded && (activity.ownersAdded.length > 0)">
          <div translate>{{eTasksMessages.ACTIVITY_EDITED_OWNERS_ADDED}}</div>
          <div class="activity-comment">
            <ng-container *ngFor="let owner of activity.ownersAdded">
              <span class="owner-img clickable m-right-5" [routerLink]="['/profile/', owner.id]">
                <!-- TODO: Add firstName and lastName -->
                <app-profile-photo [src]="owner.imageUrl"></app-profile-photo>
              </span>
              <strong>{{owner.firstName}} {{owner.lastName}}</strong>
            </ng-container>
          </div>
        </div>
      </div>
    </ng-template>

    <ng-template #templateStatusUpdate>
      <div class="p-top-10">
        <div class="edit-item">
          <div class="update-label">
            <span class="fal fa-fw fa-flag"></span>
            <span translate>{{eTasksMessages.STATUS}}</span>
          </div>
          <div>
            <ng-container [ngTemplateOutlet]="templateStatusDisplay" [ngTemplateOutletContext]="{ status: activity.statusPrevious }"></ng-container>
            <span class="fal fa-arrow-right"></span>
            <ng-container [ngTemplateOutlet]="templateStatusDisplay" [ngTemplateOutletContext]="{ status: activity.statusCurrent }"></ng-container>
          </div>
        </div>
      </div>
    </ng-template>
  </div>


  <ng-template #templateStatusDisplay let-status="status">
    <ng-container [ngSwitch]="status">
      <strong *ngSwitchCase="eTaskStatus.NOT_STARTED" translate>{{eTasksMessages.STATUS_NOT_STARTED}}</strong>
      <strong *ngSwitchCase="eTaskStatus.IN_PROGRESS" translate>{{eTasksMessages.STATUS_IN_PROGRESS}}</strong>
      <strong *ngSwitchCase="eTaskStatus.COMPLETE" translate>{{eTasksMessages.STATUS_COMPLETE}}</strong>
    </ng-container>
  </ng-template>
</div>
