import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { SurveyQuestionType } from '@app/models/survey-question-type.enum';
import { QuestionBankQuestionCategory } from '@app/models/evaluation/question-bank-question-category.enum';
import { EvaluationCycleModule } from 'app/models/evaluation/evaluation-cycle-module.model';
import { CycleStepMap, EvaluationModuleType } from 'app/models/evaluation/evaluation-module-type.model';
import { CdkDragDrop, copyArrayItem, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { EvaluationCycleQuestion } from '@app/models/evaluation/evaluation-cycle-question.model';
import { CreateQuestionBankQuestion, QuestionBankQuestion, UpdateQuestionBankQuestion } from '@app/models/evaluation/question-bank-question.model';
import { EvaluationUtils } from '@app/shared/utils/evaluation.utils';
import { ModalComponent } from '@app/shared/modal/modal.component';
import { QuestionBankApiService } from '@app/shared/api/question-bank.api.service';
import { Globals } from '@app/shared/globals/globals';
import { EvaluationCycle } from '@app/models/evaluation/evaluation-cycle.model';
import { NavTabData, NavTabItemData } from '@app/shared/components/nav-tabs/nav-tabs.component';
import { NotifyUtils } from '@app/shared/utils/notify.utils';
import { SwalUtils } from '@app/shared/utils/swal.utils';
import { IState } from '@app/models/state/state.model';
import { ButtonType } from '@app/shared/components/inputs/button/button.component';
import { IconHoverColor } from '@app/shared/components/inputs/table-action-icon/table-action-icon.component';
import { deepClone } from '@app/shared/utils/helpers';
import { PillType } from '@app/shared/components/pill/pill.component';
import { initFormAddReviewQuestion, initFormAddReviewQuestionEC, initFormAddReviewQuestionQB } from './schedule-cycle-add-question/schedule-cycle-add-question.component';
import { DynamicScalePossibleAnswer } from '@app/models/evaluation/dynamic-scale-possible-answer.model';

// #region - INTERFACES, ENUMS, TYPES
export enum BankTab {
  FRANKLI = 'FRANKLI',
  COMPANY = 'COMPANY'
}

export enum ModalType {
  ADD_QUESTION = 'ADD_QUESTION',
  EDIT_QUESTION = 'EDIT_QUESTION',
}

export enum EditingFrom {
  COMPANY_BANK = 'COMPANY_BANK',
  FRANKLI_BANK = 'FRANKLI_BANK',
  CYCLE_LIST = 'CYCLE_LIST'
}

interface UndoItem {
  q: EvaluationCycleQuestion | undefined,
  i: number | undefined,
  shown: boolean
}

interface PageState extends IState {
  archiving: boolean;
  loading: boolean;
  submitted: boolean;
  submitting: boolean;
  questionsLoading: boolean;
}
// #endregion

const DEFAULT_UNDO_ITEM = {
  q: undefined,
  i: undefined,
  shown: false,
};

@Component({
  selector: 'app-administrate-schedule-cycle-question-banks',
  templateUrl: './administrate-schedule-cycle-question-banks.component.html',
  styleUrls: ['./administrate-schedule-cycle-question-banks.component.scss'],
})
export class AdministrateScheduleCycleQuestionBanksComponent implements OnInit {
  public readonly eEvaluationModuleType = EvaluationModuleType;
  public readonly eSurveyQuestionType = SurveyQuestionType;
  public readonly eIconHoverColor = IconHoverColor;
  public readonly eEditingFrom = EditingFrom;
  public readonly eButtonType = ButtonType;
  public readonly eModalType = ModalType;
  public readonly ePillType = PillType;
  public readonly eBankTab = BankTab;

  @Output() previousStepEmit: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() nextStepEmit: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() saveDraftEmit: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input() form!: FormGroup;
  @Input() stepsEnabled: EvaluationCycleModule[];
  @Input() submitted: boolean;
  @Input() allSelfReflectionQuestionBankQuestions: QuestionBankQuestion[];
  @Input() allPeerReviewQuestionBankQuestions: QuestionBankQuestion[];
  @Input() allManagerReviewQuestionBankQuestions: QuestionBankQuestion[];
  @Input() allUpwardReviewQuestionBankQuestions: QuestionBankQuestion[];
  @Input() draftCycle?: EvaluationCycle;
  @Input() previousCycleQuestions: CycleStepMap<EvaluationCycleQuestion[]>;

  @ViewChild('validationErrors') validationErrorsElement?: ElementRef<HTMLDivElement>;
  @ViewChild('modal') modal?: ModalComponent;

  tabOpen: EvaluationModuleType;
  tabData: NavTabData;
  tabOpenBank: BankTab;
  tabDataBank: NavTabData;

  addQuestionForm: FormGroup;
  editingQuestion?: (EvaluationCycleQuestion | QuestionBankQuestion);

  undo: CycleStepMap<UndoItem>;
  undoTimer!: NodeJS.Timer;

  typesEnabled: EvaluationCycleModule[];

  state: PageState;

  questionBank: {
    open: boolean;
    questions: {
      [tabName: string]: CycleStepMap<QuestionBankQuestion[]>
    }
  };

  pageQuestions: {
    [stepName: string]: EvaluationCycleQuestion[];
  };

  modalActive: ModalType;

  resetButtonShown: boolean;

  get formQuestions(): FormGroup {
    return this.form.controls.questions as FormGroup;
  }

  get formModules(): FormGroup {
    return this.form.controls.modules as FormGroup;
  }

  constructor(
    private questionBankApiService: QuestionBankApiService,
    public globals: Globals,
    private swalUtils: SwalUtils,
    private notifyUtils: NotifyUtils
  ) {
    this.tabOpen = EvaluationModuleType.SELF_REFLECTION;
    this.tabOpenBank = BankTab.FRANKLI;
    this.modalActive = ModalType.ADD_QUESTION;

    this.submitted = false;
    this.resetButtonShown = false;
    this.submitted = false;

    this.state = {
      loading: true,
      error: false,
      errorMessage: '',
      submitted: false,
      submitting: false,
      archiving: false,
      questionsLoading: false
    };

    this.stepsEnabled = [];
    this.allSelfReflectionQuestionBankQuestions = [];
    this.allPeerReviewQuestionBankQuestions = [];
    this.allManagerReviewQuestionBankQuestions = [];
    this.allUpwardReviewQuestionBankQuestions = [];
    this.typesEnabled = [];

    this.undo = {
      [EvaluationModuleType.SELF_REFLECTION]: DEFAULT_UNDO_ITEM,
      [EvaluationModuleType.PEER_REVIEW]: DEFAULT_UNDO_ITEM,
      [EvaluationModuleType.MANAGER_REVIEW]: DEFAULT_UNDO_ITEM,
      [EvaluationModuleType.UPWARD_REVIEW]: DEFAULT_UNDO_ITEM,
    };

    this.questionBank = {
      open: true,
      questions: {
      }
    };

    this.questionBank.questions[BankTab.FRANKLI] = {
      [EvaluationModuleType.SELF_REFLECTION]: [],
      [EvaluationModuleType.PEER_REVIEW]: [],
      [EvaluationModuleType.MANAGER_REVIEW]: [],
      [EvaluationModuleType.UPWARD_REVIEW]: []
    };

    this.questionBank.questions[BankTab.COMPANY] = {
      [EvaluationModuleType.SELF_REFLECTION]: [],
      [EvaluationModuleType.PEER_REVIEW]: [],
      [EvaluationModuleType.MANAGER_REVIEW]: [],
      [EvaluationModuleType.UPWARD_REVIEW]: []
    };

    this.pageQuestions = {
      [EvaluationModuleType.SELF_REFLECTION]: [],
      [EvaluationModuleType.PEER_REVIEW]: [],
      [EvaluationModuleType.MANAGER_REVIEW]: [],
      [EvaluationModuleType.UPWARD_REVIEW]: []
    };

    this.tabData = { label: 'Step', items: [] };
    this.tabDataBank = { label: 'Source', items: [] };

    this.addQuestionForm = initFormAddReviewQuestion();
  }

  // #region - LIFECYCLE HOOKS
  ngOnInit() {
    this.getData();
  }
  // #endregion

  // #region - DATA GETS
  getData() {
    this.pageQuestions = this.parseStepQuestions(this.formQuestions);

    // Get enabled steps sorted by date and open first step
    this.typesEnabled = this.getStepsEnabledNamesSorted();
    if (this.typesEnabled.length > 0) {
      this.openTabByModule(this.typesEnabled[0].evaluationModuleType);
    }

    this.initQuestionBank();

    this.tabData = this.getTabData();
    this.tabDataBank = this.getTabDataBank();

    this.state.loading = false;
  }

  getStepsEnabledNamesSorted(): EvaluationCycleModule[] {
    return this.stepsEnabled.sort((a, b) => new Date(a.startDateTime).getTime() - new Date(b.startDateTime).getTime());
  }

  // #endregion

  // #region - DATA PARSING
  initQuestionBank() {
    const steps = [EvaluationModuleType.SELF_REFLECTION, EvaluationModuleType.PEER_REVIEW, EvaluationModuleType.MANAGER_REVIEW, EvaluationModuleType.UPWARD_REVIEW];
    this.questionBank.questions[BankTab.FRANKLI] = {};
    this.questionBank.questions[BankTab.COMPANY] = {};
    steps.forEach(step => {
      const questionBankQuestionsExisting = this.getExistingBankQuestionsForStep(step);
      const existingQuestionsForStep = this.getExistingQuestionsForStep(step);

      this.questionBank.questions[BankTab.FRANKLI][step] = this.getBankQuestions(questionBankQuestionsExisting, true, existingQuestionsForStep);
      this.questionBank.questions[BankTab.COMPANY][step] = this.getBankQuestions(questionBankQuestionsExisting, false, existingQuestionsForStep);
    });
  }

  getBankQuestions(questions: QuestionBankQuestion[], isDefaultQuestion: boolean, stepQuestions: EvaluationCycleQuestion[]): QuestionBankQuestion[] {
    return questions
      .filter(q => !stepQuestions.map(sq => sq.questionBankQuestion.id).includes(q.id))
      .filter(q => !q.assessmentQuestion)
      .filter(q => (isDefaultQuestion !== null) ? (q.defaultQuestion === isDefaultQuestion) : true);
  }

  getExistingBankQuestionsForStep(step: EvaluationModuleType): QuestionBankQuestion[] {
    switch (step) {
      case EvaluationModuleType.SELF_REFLECTION:
        return this.allSelfReflectionQuestionBankQuestions;
      case EvaluationModuleType.PEER_REVIEW:
        return this.allPeerReviewQuestionBankQuestions;
      case EvaluationModuleType.MANAGER_REVIEW:
        return this.allManagerReviewQuestionBankQuestions;
      case EvaluationModuleType.UPWARD_REVIEW:
        return this.allUpwardReviewQuestionBankQuestions;
      default: return [];
    }
  }

  getExistingQuestionsForStep(step: EvaluationModuleType): EvaluationCycleQuestion[] {
    switch (step) {
      case EvaluationModuleType.SELF_REFLECTION:
        return this.formQuestions.controls.reflectionQuestions.value;
      case EvaluationModuleType.PEER_REVIEW:
        return this.formQuestions.controls.peerQuestions.value;
      case EvaluationModuleType.MANAGER_REVIEW:
        return this.formQuestions.controls.managerQuestions.value;
      case EvaluationModuleType.UPWARD_REVIEW:
        return this.formQuestions.controls.upwardQuestions.value;
      default: return [];
    }
  }

  parseStepQuestions(form: FormGroup): {[stepName: string]: EvaluationCycleQuestion[]} {
    // String indexed output
    const output: {[stepName: string]: EvaluationCycleQuestion[]} = {};

    // Remove assessment questions
    output[EvaluationModuleType.SELF_REFLECTION] = this.filterAssessmentQuestionsFromArray(form.controls.reflectionQuestions.value);
    output[EvaluationModuleType.PEER_REVIEW] = this.filterAssessmentQuestionsFromArray(form.controls.peerQuestions.value);
    output[EvaluationModuleType.MANAGER_REVIEW] = this.filterAssessmentQuestionsFromArray(form.controls.managerQuestions.value);
    output[EvaluationModuleType.UPWARD_REVIEW] = this.filterAssessmentQuestionsFromArray(form.controls.upwardQuestions.value);

    return output;
  }

  filterAssessmentQuestionsFromArray(questions: EvaluationCycleQuestion[]): EvaluationCycleQuestion[] {
    return questions.filter((q: EvaluationCycleQuestion) => !q.assessmentQuestion);
  }
  // #endregion

  // #region - ADDING QUESTIONS
  submitAddQuestion() {
    this.submitted = true;

    if (this.state.submitting) { return console.error('Submtiting already'); }
    if (this.addQuestionForm.invalid) { return console.error('Form is invalid'); }

    this.state.submitting = true;

    const text = this.addQuestionForm.controls.text.value as string;
    const type = this.addQuestionForm.controls.type.value as SurveyQuestionType;
    const index = this.addQuestionForm.controls.index.value as number;
    const source = this.addQuestionForm.controls.editingFrom.value as EditingFrom;
    let options = this.addQuestionForm.controls.options.value as DynamicScalePossibleAnswer[];
    options = options.map((option, index) => {
      option.answerIndex = index;
      return option;
    });

    // Cannot add questions to the Frankli bank
    if (source === EditingFrom.FRANKLI_BANK) {
      console.error('Cannot add questions to the Frankli question bank');
      this.state.submitting = false;
      return;
    }

    const addFunction = this.getAddFunction(source);

    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const comp = this;

    if (addFunction) {
      switch (this.tabOpen) {
        case EvaluationModuleType.SELF_REFLECTION:
          addFunction(comp, text, type, QuestionBankQuestionCategory.SELF_REFLECTION, EvaluationModuleType.SELF_REFLECTION, 'reflectionQuestions', options, index);
          break;
        case EvaluationModuleType.PEER_REVIEW:
          addFunction(comp, text, type, QuestionBankQuestionCategory.PEER_REVIEW, EvaluationModuleType.PEER_REVIEW, 'peerQuestions', options, index);
          break;
        case EvaluationModuleType.MANAGER_REVIEW:
          addFunction(comp, text, type, QuestionBankQuestionCategory.MANAGER_REVIEW, EvaluationModuleType.MANAGER_REVIEW, 'managerQuestions', options, index);
          break;
        case EvaluationModuleType.UPWARD_REVIEW:
          addFunction(comp, text, type, QuestionBankQuestionCategory.UPWARD_REVIEW, EvaluationModuleType.UPWARD_REVIEW, 'upwardQuestions', options, index);
          break;
      }
    }
  }

  getAddFunction(editingFrom: EditingFrom) {
    switch (editingFrom) {
      case EditingFrom.COMPANY_BANK:
        return this.doAddBankQuestion;
      case EditingFrom.CYCLE_LIST:
        return this.doAddCycleQuestion;
      case EditingFrom.FRANKLI_BANK:
      default:
        return undefined;
    }
  }

  // NOTE: Could probably merge these
  private doAddCycleQuestion(component: AdministrateScheduleCycleQuestionBanksComponent, text: string, type: SurveyQuestionType, category: QuestionBankQuestionCategory, step: EvaluationModuleType, formControlName: string, options: DynamicScalePossibleAnswer[], index?: number) {
    // Create question object
    const newQuestion: CreateQuestionBankQuestion = {
      questionText: text,
      surveyQuestionType: type,
      category: category,
      possibleAnswers: []
    };

    if (type === SurveyQuestionType.DYNAMIC_SCALE) {
      newQuestion.possibleAnswers = options;
    }

    // Get existing questions
    const bankQuestionsFrankli = component.questionBank.questions[BankTab.FRANKLI][step];
    const bankQuestionsStep = component.questionBank.questions[BankTab.COMPANY][step];
    const cycleQuestionsStep = component.formQuestions.controls[formControlName].value as EvaluationCycleQuestion[];

    // Check if the question already exists in the Frankli question bank
    const alreadyExistsInFrankliBank = bankQuestionsFrankli.some(q => q.questionText === newQuestion.questionText);
    if (alreadyExistsInFrankliBank) {
      component.addQuestionForm.setErrors({
        'duplicateQuestionFrankliBank': true,
      });
      component.state.submitting = false;
      return;
    }

    // Check if question already exists in the company question bank
    const alreadyExistsInBank = bankQuestionsStep.some(q => q.questionText === newQuestion.questionText);
    if (alreadyExistsInBank) {
      component.addQuestionForm.setErrors({
        'duplicateQuestionCompanyBank': true,
      });
      component.state.submitting = false;
      return;
    }

    // Check if the question already exists in the cycle
    const alreadyExistsInCycle = cycleQuestionsStep.some(q => q.questionText === newQuestion.questionText);
    if (alreadyExistsInCycle) {
      component.addQuestionForm.setErrors({
        'duplicateQuestionCycleStep': true,
      });
      component.state.submitting = false;
      return;
    }

    // Add to question bank
    component.questionBankApiService.createQuestionBankQuestion(newQuestion).subscribe(res => {
      const newQuestionCycle = EvaluationUtils.createEvaluationCycleQuestionFromQuestionBankQuestion(res, cycleQuestionsStep.length);

      // Add to arrays - Cycle and bank
      // component.questionBank.questions[BankTab.COMPANY][step].push(res);

      if (index !== null && index !== undefined) {
        cycleQuestionsStep.splice(index, 0, newQuestionCycle);
      } else {
        cycleQuestionsStep.push(newQuestionCycle);
      }

      component.addParentQuestionForStep(res, step);

      component.emitQuestionUpdate(step, cycleQuestionsStep, true);

      // Reset variables and form
      component.submitted = false;
      component.state.submitting = false;
      component.addQuestionForm.reset();
      component.closeModal();
    }, (err: HttpErrorResponse) => {
      console.error(err);
    });
  }

  // NOTE: Not used since we removed the button but might be reintroduced later
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  private doAddBankQuestion(component: AdministrateScheduleCycleQuestionBanksComponent, text: string, type: SurveyQuestionType, category: QuestionBankQuestionCategory, step: EvaluationModuleType, formControlName: string, options: DynamicScalePossibleAnswer[], index: number) {
    // Create question object
    const newQuestion: CreateQuestionBankQuestion = {
      questionText: text,
      surveyQuestionType: type,
      category: category,
      possibleAnswers: []
    };

    if (type === SurveyQuestionType.DYNAMIC_SCALE) {
      newQuestion.possibleAnswers = options;
    }

    // Get existing questions
    const bankQuestionsFrankli = component.questionBank.questions[BankTab.FRANKLI][step];
    const bankQuestionsStep = component.questionBank.questions[BankTab.COMPANY][step];
    const cycleQuestionsStep = component.formQuestions.controls[formControlName].value as EvaluationCycleQuestion[];

    // Check if the question already exists in the Frankli question bank
    const alreadyExistsInFrankliBank = bankQuestionsFrankli.some(q => q.questionText === newQuestion.questionText);
    if (alreadyExistsInFrankliBank) {
      component.addQuestionForm.setErrors({
        'duplicateQuestionFrankliBank': true,
      });
      component.state.submitting = false;
      return;
    }

    // Check if question already exists in the company question bank
    const alreadyExistsInBank = bankQuestionsStep.some(q => q.questionText === newQuestion.questionText);
    if (alreadyExistsInBank) {
      component.addQuestionForm.setErrors({
        'duplicateQuestionCompanyBank': true,
      });
      component.state.submitting = false;
      return;
    }

    const alreadyExistsInCycle = cycleQuestionsStep.some(q => q.questionText === newQuestion.questionText);
    if (alreadyExistsInCycle) {
      component.addQuestionForm.setErrors({
        'duplicateQuestionCycleStep': true,
      });
      component.state.submitting = false;
      return;
    }

    // Add to question bank
    component.questionBankApiService.createQuestionBankQuestion(newQuestion).subscribe(res => {
      // Add to arrays - Cycle and bank
      component.questionBank.questions[BankTab.COMPANY][step].push(res);

      this.addParentQuestionForStep(res, step);
      component.emitQuestionUpdate(step, cycleQuestionsStep, true);

      // Reset variables and form
      component.submitted = false;
      component.state.submitting = false;
      component.addQuestionForm.reset();
      component.closeModal();
    }, (err: HttpErrorResponse) => {
      console.error(err);
    });
  }
  // #endregion

  // #region - EDITING QUESTIONS
  submitEditQuestion() {
    this.submitted = true;

    if (this.state.submitting) { return console.error('Submtiting already'); }
    if (this.addQuestionForm.invalid) { return console.error('Form is invalid'); }

    this.state.submitting = true;

    const oldQuestion = this.editingQuestion;
    if (!oldQuestion) { return console.error('No question to edit selected'); }

    const text = this.addQuestionForm.controls.text.value as string;
    const source = this.addQuestionForm.controls.editingFrom.value as EditingFrom;
    let options = this.addQuestionForm.controls.options.value as DynamicScalePossibleAnswer[];
    options = options.map((option, index) => {
      option.answerIndex = index;
      return option;
    });

    const questionCategory: QuestionBankQuestionCategory = this.getQuestionBankQuestionCategoryFromStep(this.tabOpen);
    const questionControl: string = this.getControlNameFromStep(this.tabOpen);

    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const comp = this;

    switch (source) {
      case EditingFrom.COMPANY_BANK:
        return this.doEditCompanyBankQuestion(comp, oldQuestion, text, questionCategory, this.tabOpen);
      case EditingFrom.FRANKLI_BANK:
        return this.doEditFrankliBankQuestion(comp, oldQuestion, text, questionCategory, this.tabOpen, questionControl, options);
      case EditingFrom.CYCLE_LIST:
        return this.doEditCycleQuestion(comp, oldQuestion, text, questionCategory, this.tabOpen, questionControl);
      default:
        return undefined;
    }
  }

  private getQuestionBankQuestionCategoryFromStep(step: EvaluationModuleType): QuestionBankQuestionCategory {
    switch (step) {
      case EvaluationModuleType.SELF_REFLECTION:
        return QuestionBankQuestionCategory.SELF_REFLECTION;
      case EvaluationModuleType.PEER_REVIEW:
        return QuestionBankQuestionCategory.PEER_REVIEW;
      case EvaluationModuleType.MANAGER_REVIEW:
        return QuestionBankQuestionCategory.MANAGER_REVIEW;
      case EvaluationModuleType.UPWARD_REVIEW:
        return QuestionBankQuestionCategory.UPWARD_REVIEW;
      default:
        return undefined;
    }
  }

  private getControlNameFromStep(step: EvaluationModuleType): string {
    switch (step) {
      case EvaluationModuleType.SELF_REFLECTION:
        return 'reflectionQuestions';
      case EvaluationModuleType.PEER_REVIEW:
        return 'peerQuestions';
      case EvaluationModuleType.MANAGER_REVIEW:
        return 'managerQuestions';
      case EvaluationModuleType.UPWARD_REVIEW:
        return 'upwardQuestions';
      default:
        return undefined;
    }
  }

  private doEditCompanyBankQuestion(component: AdministrateScheduleCycleQuestionBanksComponent, oldQuestionIn: (QuestionBankQuestion | EvaluationCycleQuestion), text: string, category: QuestionBankQuestionCategory, step: EvaluationModuleType): void {
    // Get existing questions
    const oldQuestion = oldQuestionIn as QuestionBankQuestion;
    const bankQuestionsStep = component.questionBank.questions[BankTab.COMPANY][step];
    const questionIndex = bankQuestionsStep.findIndex(q => q === oldQuestion);

    // Create question object
    const updatedQuestion: UpdateQuestionBankQuestion = {
      questionText: text,
      archived: false,
      possibleAnswers: oldQuestion.possibleAnswers
    };

    // Add to question bank
    component.questionBankApiService.updateQuestionBankQuestion(oldQuestion.id, updatedQuestion).subscribe(res => {
      // Replace in question bank array
      component.questionBank.questions[BankTab.COMPANY][step][questionIndex] = res;
      component.replaceParentQuestionBankQuestionForStep(res, step);

      // Reset variables and form
      component.submitted = false;
      component.state.submitting = false;
      component.addQuestionForm.reset();
      component.closeModal();
      component.pageQuestions = component.parseStepQuestions(component.formQuestions);
    }, (err: HttpErrorResponse) => {
      console.error(err);
    });
  }

  private doEditFrankliBankQuestion(component: AdministrateScheduleCycleQuestionBanksComponent, oldQuestionIn: (QuestionBankQuestion | EvaluationCycleQuestion), text: string, category: QuestionBankQuestionCategory, step: EvaluationModuleType, formControlName: string, options: DynamicScalePossibleAnswer[]): void {
    // Get existing questions
    const oldQuestion = oldQuestionIn as QuestionBankQuestion;

    // Create question object
    const newQuestion: CreateQuestionBankQuestion = {
      questionText: text,
      surveyQuestionType: oldQuestion.surveyQuestionType,
      category: category,
      possibleAnswers: []
    };

    if (oldQuestion.surveyQuestionType === SurveyQuestionType.DYNAMIC_SCALE) {
      newQuestion.possibleAnswers = options;
    }

    // Add to question bank
    component.questionBankApiService.createQuestionBankQuestion(newQuestion).subscribe(res => {
      // Add to question bank array
      component.questionBank.questions[BankTab.COMPANY][step].push(res);

      component.addParentQuestionForStep(res, step);

      // Reset variables and form
      component.submitted = false;
      component.state.submitting = false;
      component.addQuestionForm.reset();
      component.closeModal();
      component.pageQuestions = component.parseStepQuestions(component.formQuestions);
      component.tabOpenBank = BankTab.COMPANY;
    }, (err: HttpErrorResponse) => {
      console.error(err);
    });
  }

  private doEditCycleQuestion(component: AdministrateScheduleCycleQuestionBanksComponent, oldQuestionIn: (QuestionBankQuestion | EvaluationCycleQuestion), text: string, category: QuestionBankQuestionCategory, step: EvaluationModuleType, formControlName: string): void {
    // Get existing questions
    const oldQuestion = oldQuestionIn as EvaluationCycleQuestion;
    const cycleQuestionsStep = component.formQuestions.controls[formControlName].value as EvaluationCycleQuestion[];
    const questionIndex = cycleQuestionsStep.findIndex(q => q.questionText === oldQuestion.questionText);

    // Create question object
    const updatedQuestion: EvaluationCycleQuestion = {
      id: oldQuestion.id,
      questionBankQuestion: oldQuestion.questionBankQuestion,
      questionText: text,
      orderIndex: oldQuestion.orderIndex,
      assessmentQuestion: oldQuestion.assessmentQuestion
    };

    // Update question list
    cycleQuestionsStep[questionIndex] = updatedQuestion;
    component.emitQuestionUpdate(step, cycleQuestionsStep, true);

    // Reset variables and form
    component.submitted = false;
    component.state.submitting = false;
    component.addQuestionForm.reset();
    component.closeModal();
  }
  // #endregion

  // #region - REMOVING QUESTIONS
  removeQuestion(question: EvaluationCycleQuestion, tab?: EvaluationModuleType) {
    const workingTab = tab ? tab : this.tabOpen;

    switch (workingTab) {
      case EvaluationModuleType.SELF_REFLECTION:
        return this.doRemoveQuestion(question, 'reflectionQuestions', workingTab);
      case EvaluationModuleType.PEER_REVIEW:
        return this.doRemoveQuestion(question, 'peerQuestions', workingTab);
      case EvaluationModuleType.MANAGER_REVIEW:
        return this.doRemoveQuestion(question, 'managerQuestions', workingTab);
      case EvaluationModuleType.UPWARD_REVIEW:
        return this.doRemoveQuestion(question, 'upwardQuestions', workingTab);
    }
  }

  doRemoveQuestion(question: EvaluationCycleQuestion, formControlName: string, step: EvaluationModuleType) {
    const questions = this.formQuestions.controls[formControlName].value as EvaluationCycleQuestion[];
    const questionIndex = questions.findIndex(q => q === question);
    if (questionIndex > -1) {
      const questionsFiltered = questions.filter(q => q !== question);
      this.undo[step] = {
        q: question,
        i: questionIndex,
        shown: true,
      };

      const bankTab = (question.questionBankQuestion.defaultQuestion ? BankTab.FRANKLI : BankTab.COMPANY);
      this.questionBank.questions[bankTab][step].push(question.questionBankQuestion);

      clearTimeout(this.undoTimer);
      this.undoTimer = setTimeout(() => this.hideUndo(step), 10000);
      this.emitQuestionUpdate(step, questionsFiltered, true);
    }
  }
  // #endregion

  // #region - ARCHIVING QUESTIONS
  archiveQuestion(question: QuestionBankQuestion) {
    // Abort if already archiving
    if (this.state.archiving) {
      return;
    }

    // Abort if no question
    if (!question) {
      return;
    }

    const step = this.tabOpen;
    this.state.archiving = true;

    this.swalUtils.displayWarningConfirmationSwal({
      title: 'Are you sure?',
      text: 'This question will not be available for future cycles',
      confirmButtonText: 'Delete Question'
    }).then((result) => {
      if (result.value) {
        const updatedQuestion: UpdateQuestionBankQuestion = {
          questionText: question.questionText,
          archived: true,
          possibleAnswers: []
        };
        this.questionBankApiService.updateQuestionBankQuestion(question.id, updatedQuestion).subscribe(() => {
          // Filter from question bank
          this.questionBank.questions[BankTab.COMPANY][step] = this.questionBank.questions[BankTab.COMPANY][step].filter(q => q.id !== question.id);
          this.removeParentQuestionForStep(question, step);

          this.notifyUtils.notify('Question successfully deleted');
          this.state.archiving = false;
        }, () => {
          this.state.archiving = false;
        });
      } else {
        this.state.archiving = false;
      }
    });
  }
  // #endregion

  // #region - UNDOING/RESTORING QUESTIONS
  hideUndo(tab: EvaluationModuleType) {
    const empty = {
      q: undefined,
      i: undefined,
      shown: false,
    };

    switch (tab) {
      case EvaluationModuleType.SELF_REFLECTION:
        this.undo[EvaluationModuleType.SELF_REFLECTION] = empty;
        break;
      case EvaluationModuleType.PEER_REVIEW:
        this.undo[EvaluationModuleType.PEER_REVIEW] = empty;
        break;
      case EvaluationModuleType.MANAGER_REVIEW:
        this.undo[EvaluationModuleType.MANAGER_REVIEW] = empty;
        break;
      case EvaluationModuleType.UPWARD_REVIEW:
        this.undo[EvaluationModuleType.UPWARD_REVIEW] = empty;
        break;
    }
  }

  restoreQuestion(tab: EvaluationModuleType) {
    switch (tab) {
      case EvaluationModuleType.SELF_REFLECTION:
        this.doRestoreQuestion(tab, 'reflectionQuestions');
        break;
      case EvaluationModuleType.PEER_REVIEW:
        this.doRestoreQuestion(tab, 'peerQuestions');
        break;
      case EvaluationModuleType.MANAGER_REVIEW:
        this.doRestoreQuestion(tab, 'managerQuestions');
        break;
      case EvaluationModuleType.UPWARD_REVIEW:
        this.doRestoreQuestion(tab, 'upwardQuestions');
        break;
    }
  }

  doRestoreQuestion(step: EvaluationModuleType, formControlName: string) {
    if (this.formQuestions.controls[formControlName] && this.formQuestions.controls[formControlName].value) {
      const questions = this.formQuestions.controls[formControlName].value;
      const undoItem = this.undo[step];

      if (!undoItem) { return; }

      const questionRestoring = undoItem.q;

      if (questionRestoring) {
        const bankTab = questionRestoring.questionBankQuestion.defaultQuestion ? BankTab.FRANKLI : BankTab.COMPANY;

        questions.splice(undoItem.i, 0, questionRestoring);
        this.questionBank.questions[bankTab][step] = this.questionBank.questions[bankTab][step].filter(q => (q.id !== questionRestoring.questionBankQuestion.id));
        this.emitQuestionUpdate(step, questions, true);
      }

      // Clear undo cache
      this.undo[step] = {
        q: undefined,
        i: undefined,
        shown: false,
      };
    }
  }
  // #endregion

  // #region - STEP TABS
  getTabData(): NavTabData {
    const stepsActive = this.typesEnabled.map(t => t.evaluationModuleType);

    const tabData: {
      [key in EvaluationModuleType]: NavTabItemData
    } = {
      [EvaluationModuleType.SELF_REFLECTION]: {
        identifier: EvaluationModuleType.SELF_REFLECTION,
        title: 'Self Reflection Questions',
        condition: () => stepsActive.includes(EvaluationModuleType.SELF_REFLECTION),
        counter: () => this.pageQuestions[EvaluationModuleType.SELF_REFLECTION].length,
        amplitude: {
          action: 'ClickViewReflectionQuestions',
          category: 'CreateCycle'
        },
        intercomTarget: 'View Reflection Questions',
        tooltip: 'Everyone in the cycle will be asked to answer these'
      },
      [EvaluationModuleType.PEER_REVIEW]: {
        identifier: EvaluationModuleType.PEER_REVIEW,
        title: 'Peer Review Questions',
        condition: () => stepsActive.includes(EvaluationModuleType.PEER_REVIEW),
        counter: () => this.pageQuestions[EvaluationModuleType.PEER_REVIEW].length,
        amplitude: {
          action: 'ClickViewPeerQuestions',
          category: 'CreateCycle'
        },
        intercomTarget: 'View Peer Questions',
        tooltip: 'These questions will be answered by peers'
      },
      [EvaluationModuleType.MANAGER_REVIEW]: {
        identifier: EvaluationModuleType.MANAGER_REVIEW,
        title: 'Manager Review Questions',
        condition: () => stepsActive.includes(EvaluationModuleType.MANAGER_REVIEW),
        counter: () => this.pageQuestions[EvaluationModuleType.MANAGER_REVIEW].length,
        amplitude: {
          action: 'ClickViewManagerQuestions',
          category: 'CreateCycle'
        },
        intercomTarget: 'View Manager Questions',
        tooltip: 'These questions will be answered by managers'
      },
      [EvaluationModuleType.UPWARD_REVIEW]: {
        identifier: EvaluationModuleType.UPWARD_REVIEW,
        title: 'Upward Review Questions',
        condition: () => stepsActive.includes(EvaluationModuleType.UPWARD_REVIEW),
        counter: () => this.pageQuestions[EvaluationModuleType.UPWARD_REVIEW].length,
        amplitude: {
          action: 'ClickViewUpwardQuestions',
          category: 'CreateCycle'
        },
        intercomTarget: 'View Upward Questions',
        tooltip: 'These questions will be answered by direct reports'
      }
    };

    return {
      label: 'Step',
      items: stepsActive.map(stepType => tabData[stepType])
    };
  }

  getTabDataBank(): NavTabData {
    //                   <ul class="nav nav-justified noselect">
    //                     <li (click)="openBankTab(eBankTab.COMPANY)" tooltip="A list of questions created by your company" [triggers]="globals.tooltipTriggers" placement="top" container="body">
    //                       <a class="tab-link clickable" [class.tab-selected]="tabOpenBank === eBankTab.COMPANY">
    //                         <span>Your Questions</span>
    //                       </a>
    //                     </li>
    //                     <li (click)="openBankTab(eBankTab.FRANKLI)" tooltip="A list of questions created by Frankli" [triggers]="globals.tooltipTriggers" placement="top" container="body">
    //                       <a class="tab-link clickable" [class.tab-selected]="tabOpenBank === eBankTab.FRANKLI">
    //                         <img src="assets/img/logo.png" class="frankli-bank-logo">
    //                         <span class="m-right-5"></span>
    //                         <span>Questions</span>
    //                       </a>
    //                     </li>
    //                   </ul>

    return {
      label: 'Source',
      iconsEnabled: true,
      items: [
        {
          identifier: BankTab.COMPANY,
          title: 'Your questions',
          // icon: 'fa-question-circle',
          icon: '',
          condition: () => true,
          amplitude: {
            action: 'ClickViewQuestionBankYourQuestions',
            category: 'CreateCycle'
          },
          intercomTarget: 'View your questions',
          tooltip: 'A list of questions created by your company'
        },
        {
          identifier: BankTab.FRANKLI,
          title: 'Questions',
          icon: 'assets/img/logo.png',
          condition: () => true,
          amplitude: {
            action: 'ClickViewQuestionBankYourQuestions',
            category: 'CreateCycle'
          },
          intercomTarget: 'View your questions',
          tooltip: 'A list of questions created by Frankli'
        }
      ]
    };
  }

  openTab(t: EvaluationModuleType) {
    this.tabOpen = t;
  }

  previousStep(): void {
    let index = -1;

    index = this.typesEnabled.findIndex(t => t.evaluationModuleType === this.tabOpen);

    if (index > -1) {
      this.tryNextStep(index - 1);
    }
  }

  nextStep(): void {
    let index = -1;

    index = this.typesEnabled.findIndex(t => t.evaluationModuleType === this.tabOpen);

    if (index > -1) {
      this.tryNextStep(index + 1);
    }
  }

  saveDraft(): void {
    this.saveDraftEmit.emit(true);
  }

  checkStepValid(): boolean {
    const stepFormControl = this.getStepFormControl(this.tabOpen);
    if (stepFormControl) {
      return stepFormControl.valid;
    }

    return true;
  }

  getStepFormControl(tab: EvaluationModuleType): (FormControl | undefined) {
    switch (tab) {
      case EvaluationModuleType.SELF_REFLECTION:
        return this.formQuestions.controls['reflectionQuestions'] as FormControl;
      case EvaluationModuleType.PEER_REVIEW:
        return this.formQuestions.controls['peerQuestions'] as FormControl;
      case EvaluationModuleType.MANAGER_REVIEW:
        return this.formQuestions.controls['managerQuestions'] as FormControl;
      default:
        return undefined;
    }
  }

  focusValidationErrors() {
    if (this.validationErrorsElement) {
      this.validationErrorsElement.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }

  tryNextStep(index: number) {
    const stepValid = this.checkStepValid();

    // If out of steps, next page
    if (index === -1) {
      this.previousStepEmit.emit(true);
      return;
    }
    
    if ((index + 1) > this.typesEnabled.length) {
      this.nextStepEmit.emit(true);

      if (!stepValid) {
        this.focusValidationErrors();
      }

      return;
    }

    if (!stepValid) {
      this.nextStepEmit.emit(true);
      this.focusValidationErrors();
      return;
    }

    const nextType = this.typesEnabled[index];
    this.openTabByModule(nextType.evaluationModuleType as EvaluationModuleType);
  }

  openTabByModule(module: EvaluationModuleType) {
    this.tabOpen = module;
    this.scrollToTop();
  }

  scrollToTop() {
    this.state.questionsLoading = true;
    setTimeout(() => {
      this.state.questionsLoading = false;
    }, 1);
  }
  // #endregion

  // #region - QUESTION BANK
  toggleQuestionBank() {
    this.questionBank.open = !this.questionBank.open;
  }

  openBankTab(tab: BankTab) {
    this.tabOpenBank = tab;
  }
  // #endregion

  // #region - DRAGGING AND DROPPING
  // NOTE: Don't think this will ever fire? because of the cdkDropListEnterPredicate set on the bank DropList
  dropDraggableBank(event: CdkDragDrop<QuestionBankQuestion[]>) {
    if (event.previousContainer === event.container) { // If same container
      // moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else { // If not the same container
      transferArrayItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex); // This will remove the item from the source array instead
      // copyArrayItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex);
    }
    this.formQuestions.markAsDirty();
  }

  dropDraggableCycle(event: CdkDragDrop<EvaluationCycleQuestion[] | QuestionBankQuestion[]>) {
    if (event.previousContainer === event.container) { // If same container
      const from = event.container.data as EvaluationCycleQuestion[];
      moveItemInArray(from, event.previousIndex, event.currentIndex);
    } else { // If not the same container
      // Map and copy the question
      const dataFromMapped = EvaluationUtils.createEvaluationCycleQuestionsFromQuestionBankQuestions(event.previousContainer.data as QuestionBankQuestion[]);
      const dataTo = event.container.data as EvaluationCycleQuestion[];
      copyArrayItem(dataFromMapped, dataTo, event.previousIndex, event.currentIndex);

      // Filter the original one out
      const movingItem = event.previousContainer.data[event.previousIndex];
      this.questionBank.questions[this.tabOpenBank][this.tabOpen] = this.questionBank.questions[this.tabOpenBank][this.tabOpen].filter((item) => item !== movingItem);
    }

    // Update values in parent
    this.emitQuestionUpdate(this.tabOpen, this.pageQuestions[this.tabOpen], false);

    // Mark form as dirty so the draft will save
    this.formQuestions.markAsDirty();
  }

  enableDropping() {
    return true;
  }

  disableDropping() {
    return false;
  }
  // #endregion

  // #region - MODAL
  openModal(type?: ModalType) {
    if (type) {
      this.modalActive = type;
    }

    if (this.modal) {
      this.modal.show();
    }
  }

  closeModal() {
    if (this.modal) {
      this.modal.hide();
    }
  }
  // #endregion

  // #region - PARENT QUESTION BANK ARRAYS
  addParentQuestionForStep(question: QuestionBankQuestion, step: EvaluationModuleType) {
    switch (step) {
      case EvaluationModuleType.SELF_REFLECTION:
        this.allSelfReflectionQuestionBankQuestions.push(question);
        break;
      case EvaluationModuleType.PEER_REVIEW:
        this.allPeerReviewQuestionBankQuestions.push(question);
        break;
      case EvaluationModuleType.MANAGER_REVIEW:
        this.allManagerReviewQuestionBankQuestions.push(question);
        break;
      case EvaluationModuleType.UPWARD_REVIEW:
        this.allUpwardReviewQuestionBankQuestions.push(question);
        break;
    }
  }

  removeParentQuestionForStep(question: QuestionBankQuestion, step: EvaluationModuleType) {
    switch (step) {
      case EvaluationModuleType.SELF_REFLECTION:
        this.allSelfReflectionQuestionBankQuestions = this.allSelfReflectionQuestionBankQuestions.filter(q => q.id !== question.id);
        break;
      case EvaluationModuleType.PEER_REVIEW:
        this.allPeerReviewQuestionBankQuestions = this.allPeerReviewQuestionBankQuestions.filter(q => q.id !== question.id);
        break;
      case EvaluationModuleType.MANAGER_REVIEW:
        this.allManagerReviewQuestionBankQuestions = this.allManagerReviewQuestionBankQuestions.filter(q => q.id !== question.id);
        break;
      case EvaluationModuleType.UPWARD_REVIEW:
        this.allUpwardReviewQuestionBankQuestions = this.allUpwardReviewQuestionBankQuestions.filter(q => q.id !== question.id);
        break;
    }
  }

  replaceParentQuestionBankQuestionForStep(question: QuestionBankQuestion, step: EvaluationModuleType) {
    switch (step) {
      case EvaluationModuleType.SELF_REFLECTION:
        this.allSelfReflectionQuestionBankQuestions = this.allSelfReflectionQuestionBankQuestions.map(q => {
          if (q.id === question.id) {
            return question;
          }

          return q;
        });
        break;
      case EvaluationModuleType.PEER_REVIEW:
        this.allPeerReviewQuestionBankQuestions = this.allPeerReviewQuestionBankQuestions.map(q => {
          if (q.id === question.id) {
            return question;
          }

          return q;
        });
        break;
      case EvaluationModuleType.MANAGER_REVIEW:
        this.allManagerReviewQuestionBankQuestions = this.allManagerReviewQuestionBankQuestions.map(q => {
          if (q.id === question.id) {
            return question;
          }

          return q;
        });
        break;
      case EvaluationModuleType.UPWARD_REVIEW:
        this.allUpwardReviewQuestionBankQuestions = this.allUpwardReviewQuestionBankQuestions.map(q => {
          if (q.id === question.id) {
            return question;
          }

          return q;
        });
        break;
    }
  }
  // #endregion

  emitQuestionUpdate(tab: EvaluationModuleType, data: EvaluationCycleQuestion[], refreshUI?: boolean) {
    this.resetButtonShown = true;

    if (refreshUI) {
      this.pageQuestions[tab] = data;
    }

    switch (tab) {
      case EvaluationModuleType.SELF_REFLECTION:
        this.formQuestions.controls.reflectionQuestions.setValue(data);
        this.formQuestions.controls.reflectionQuestions.markAsDirty();
        break;
      case EvaluationModuleType.PEER_REVIEW:
        this.formQuestions.controls.peerQuestions.setValue(data);
        this.formQuestions.controls.peerQuestions.markAsDirty();
        break;
      case EvaluationModuleType.MANAGER_REVIEW:
        this.formQuestions.controls.managerQuestions.setValue(data);
        this.formQuestions.controls.managerQuestions.markAsDirty();
        break;
      case EvaluationModuleType.UPWARD_REVIEW:
        this.formQuestions.controls.upwardQuestions.setValue(data);
        this.formQuestions.controls.upwardQuestions.markAsDirty();
        break;
    }
  }

  resetQuestions() {
    const step = this.tabOpen;

    this.swalUtils.displayWarningConfirmationSwal({
      title: 'Are you sure?',
      text: (this.draftCycle ? 'This will reset the current step\'s questions to the last ones saved' : 'This will reset the current step\'s questions to the default'),
      imageUrl: 'assets/img/swal-icons/frankli-warning-icon.svg',
      imageWidth: 140,
      imageHeight: 140,
      showCancelButton: true,
      confirmButtonColor: '#30747F',
      confirmButtonText: 'Reset Questions',
    }).then((result) => {
      if (result.isConfirmed) {
        // Clear undo actions
        this.undo = {
          [EvaluationModuleType.SELF_REFLECTION]: DEFAULT_UNDO_ITEM,
          [EvaluationModuleType.PEER_REVIEW]: DEFAULT_UNDO_ITEM,
          [EvaluationModuleType.MANAGER_REVIEW]: DEFAULT_UNDO_ITEM,
          [EvaluationModuleType.UPWARD_REVIEW]: DEFAULT_UNDO_ITEM,
        };

        // if using a draft, reset to that
        if (this.draftCycle) {
          this.resetQuestionsDraft(step, this.draftCycle);
        // Otherwise, reset to our questions
        } else {
          this.resetQuestionsNormal(step);
        }

        // Reset question bank
        this.initQuestionBank();
      }
    });
  }

  resetQuestionsDraft(step: EvaluationModuleType, draftCycle: EvaluationCycle) {
    const questions: EvaluationCycleQuestion[] = this.getDraftCycleStepQuestions(step, draftCycle);
    this.emitQuestionUpdate(step, questions, true);
  }

  resetQuestionsNormal(step: EvaluationModuleType) {
    let questions: EvaluationCycleQuestion[] = deepClone(this.previousCycleQuestions[step]);

    if (!questions || questions.length === 0) {
      let questionBankQuestions: QuestionBankQuestion[] = [];
      questionBankQuestions = this.getExistingBankQuestionsForStep(step); // Get all questions available for step
      questionBankQuestions = this.getBankQuestions(questionBankQuestions, true, []); // Get questions
      questions = EvaluationUtils.createEvaluationCycleQuestionsFromQuestionBankQuestions(questionBankQuestions); // Parse to EvaluationCycleQuestions
    }
    
    this.emitQuestionUpdate(step, questions, true); // Emit and apply updates

    // Reset current tab to our questions
    this.formQuestions.markAsDirty();
  }

  checkStepEnabled(stepName: EvaluationModuleType): boolean {
    return this.stepsEnabled.map(s => s.evaluationModuleType).includes(stepName);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  trackQuestionList(index: number, item: EvaluationCycleQuestion) {
    return index;
  }

  getDraftCycleStepQuestions(step: EvaluationModuleType, draftCycle: EvaluationCycle): EvaluationCycleQuestion[] {
    switch (step) {
      case EvaluationModuleType.SELF_REFLECTION:
        return draftCycle.selfReflectionQuestions;
      case EvaluationModuleType.PEER_REVIEW:
        return draftCycle.peerReviewQuestions;
      case EvaluationModuleType.MANAGER_REVIEW:
        return draftCycle.managerReviewQuestions;
      case EvaluationModuleType.UPWARD_REVIEW:
        return draftCycle.upwardReviewQuestions;
      default:
        return [];
    }
  }

  onClickQuestionAction(action: string, question: EvaluationCycleQuestion): void {
    switch (action) {
      case 'EDIT':
        this.startEditEvaluationCycleQuestion(question, EditingFrom.CYCLE_LIST);
        break;
      case 'DELETE':
        this.removeQuestion(question);
        break;
    }
  }

  onClickQuestionActionBank(action: string, question: QuestionBankQuestion): void {
    switch (action) {
      case 'EDIT':
        this.startEditQuestionBankQuestion(question, EditingFrom.COMPANY_BANK);
        break;
      case 'DELETE':
        this.archiveQuestion(question);
        break;
    }
  }

  onClickActionCreateModal(action: string): void {
    switch (action) {
      case 'CANCEL':
        this.closeModal();
        break;
      case 'SUBMIT':
        this.onModalSubmit();
        break;
    }
  }

  onModalSubmit(): void {
    switch (this.modalActive) {
      case ModalType.ADD_QUESTION:
        this.submitAddQuestion();
        break;
      case ModalType.EDIT_QUESTION:
        this.submitEditQuestion();
        break;
    }
  }

  startAddQuestion(editingFrom: EditingFrom, index: number): void {
    this.addQuestionForm = initFormAddReviewQuestion();

    if (index !== null && index !== undefined) {
      this.addQuestionForm.controls.index.setValue(index);
    }
    this.addQuestionForm.controls.editingFrom.setValue(editingFrom);

    this.openModal(ModalType.ADD_QUESTION);
  }

  startEditEvaluationCycleQuestion(question: EvaluationCycleQuestion, source: EditingFrom): void {
    this.editingQuestion = question;
    this.addQuestionForm = initFormAddReviewQuestionEC(question, source);

    this.openModal(ModalType.EDIT_QUESTION);
  }

  startEditQuestionBankQuestion(question: QuestionBankQuestion, source: EditingFrom): void {
    this.editingQuestion = question;
    this.addQuestionForm = initFormAddReviewQuestionQB(question, source);

    this.openModal(ModalType.EDIT_QUESTION);
  }
}
