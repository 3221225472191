<ng-container *ngIf="arrayData.length > 0">
  <div class="row pagination-padding">
    <div class="col-sm-6">
      <div class="dataTables_info" id="datatables_info" role="status" aria-live="polite" translate [translateParams]="{ pageAmountStart: displayAmountStart, pageAmountEnd: displayAmountEnd, pageAmountTotal: displayAmountTotal }">
        {{ePaginationNewMessages.PAGE_INFO}}
      </div>
    </div>
    <div class="col-sm-6 amount-select" *ngIf="arrayData.length > 5">
      <span>{{ePaginationNewMessages.AMOUNT_TO_DISPLAY | translate}}: </span>
      <span class="frankli-form-group frankli-form-group-filled">
        <select class="form-control" [(ngModel)]="amountToDisplay" (ngModelChange)="onChangeAmountToDisplay($event)">
          <option *ngFor="let amount of amountsToDisplay" [ngValue]="amount">{{amount}}</option>
          <option [ngValue]="'All'" translate>{{eCommonMessages.ALL}}</option>
        </select>
      </span>
    </div>
  </div>
  
  <div class="dataTables_paginate pagination-controller text-center">
    <ul class="pagination">
      <li (click)="firstPage()" [class.disabled]="currentPage === 1" class="clickable noselect">
        <a translate>{{ePaginationNewMessages.FIRST}}</a>
      </li>
      <li (click)="previousPage()" [class.disabled]="currentPage === 1" class="clickable noselect">
        <a><span class="fal fa-angle-left"></span></a>
      </li>
  
      <ng-container *ngFor="let page of pages">
        <ng-container *ngIf="(page <= 3) || (page >= (pages.length - 2)); else templateMiddlePageDisplay;">
          <li (click)="goToPage(page)" [class.active]="currentPage === page" class="clickable noselect">
            <a>{{page}}</a>
          </li>
        </ng-container>
        <ng-template #templateMiddlePageDisplay>
          <ng-container *ngIf="page === 4 && (currentPage >= 10)">
            <span class="fal fa-ellipsis-h"></span>
          </ng-container>

          <ng-container *ngIf="currentPage < 10; else templatePageAboveTen;">
            <ng-container *ngIf="page <= 10">
              <li (click)="goToPage(page)" [class.active]="currentPage === page" class="clickable noselect">
                <a>{{page}}</a>
              </li>
            </ng-container>
          </ng-container>
          <ng-template #templatePageAboveTen>
            <ng-container *ngIf="currentPage > (pages.length - 10); else templatePageMiddle;">
              <ng-container *ngIf="page >= (pages.length - 10)">
                <li (click)="goToPage(page)" [class.active]="currentPage === page" class="clickable noselect">
                  <a>{{page}}</a>
                </li>
              </ng-container>
            </ng-container>
            <ng-template #templatePageMiddle>
              <ng-container *ngIf="((page >= (currentPage - 3)) && (page <= (currentPage + 3)))">
                <li (click)="goToPage(page)" [class.active]="currentPage === page" class="clickable noselect">
                  <a>{{page}}</a>
                </li>
              </ng-container>
            </ng-template>
          </ng-template>

          <ng-container *ngIf="(page === (pages.length - 3)) && (currentPage <= (pages.length - 9))">
            <span class="fal fa-ellipsis-h"></span>
          </ng-container>
        </ng-template>
      </ng-container>
  
      <li (click)="nextPage()" [class.disabled]="currentPage === getLastPage()" class="clickable noselect">
        <a><span class="fal fa-angle-right"></span></a>
      </li>
      <li (click)="lastPage()" [class.disabled]="currentPage === getLastPage()" class="clickable noselect">
        <a translate>{{ePaginationNewMessages.LAST}}</a>
      </li>
    </ul>
  </div>
</ng-container>