import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { SurveyQuestion } from 'app/models/survey/question.model';

@Component({
  selector: 'app-single-choice',
  templateUrl: './single-choice.component.html',
  styleUrls: ['./single-choice.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => SingleChoiceComponent),
    multi: true
  }]
})
export class SingleChoiceComponent implements ControlValueAccessor {
  @Input() question!: SurveyQuestion;
  @Input() disabled: boolean;
  selection: any;

  onChange = (_: any) => {};
  onTouched = () => {};

  constructor() {
    this.disabled = false;
  }

  propagateChange = (_: any) => { };

  writeValue(value: any) {
    if (value !== undefined) {
      this.selection = value;
    } else {
      value = 0;
    }
  }

  // Register callbacks
  registerOnChange(fn: (_: any) => {}): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  select(event: any) {
    if (!this.disabled) {
      this.selection = event.target.value;
      this.propagateChange(this.selection);
    }
  }
}
