import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UserMinimal } from '@app/models/user/user-minimal.model';
import { TaskBusinessService } from '../../service/task-business.service';
import { Task } from '../../model/task.model';
import { IState } from '@app/models/state/state.model';
import { TasksMessages } from '../../locale/tasks.messages';
import { FilterCreation, FilterMethod, FilterOption } from '@app/models/universal-filter-option.model';
import { DefaultFilter } from '@app/shared/universal-filter/universal-filter.component';
import { FormControl } from '@angular/forms';
import { TaskUtilsService } from '../../service/task-utils.service';
import { PaginationNewComponent } from '@app/shared/pagination/pagination-new/pagination-new.component';
import { SortingOption } from '@app/shared/components/inputs/sorting-dropdown/sorting-dropdown.component';

enum TableColumn {
  CREATOR = 'CREATOR',
  TITLE = 'TITLE',
  OWNERS = 'OWNERS',
  DUE_DATE = 'DUE_DATE',
  ALIGNMENT = 'ALIGNMENT',
  STATUS = 'STATUS',
  ACTIONS = 'ACTIONS'
}

interface UniversalFilterData {
  searchProps: string[];
  filterOptions: FilterOption[];
  defaultFilters: DefaultFilter;
  lastFilterResult: number[];
  placeholder: string;
}

export interface FilteredTaskListAction {
  action: string;
  task: Task;
}

@Component({
  selector: 'app-filtered-task-list',
  templateUrl: './filtered-task-list.component.html',
  styleUrls: ['./filtered-task-list.component.scss']
})
export class FilteredTaskListComponent implements OnInit {
  public readonly eTasksMessages = TasksMessages;

  @Input() owner: UserMinimal;
  @Output() actionClicked: EventEmitter<FilteredTaskListAction>;

  @ViewChild('pagination') pagination?: PaginationNewComponent;

  
  state: IState;
  universalFilterData: UniversalFilterData;
  tasks: {
    master: Task[];
    filtered: Task[];
    display: Task[];
  };
  controlSort: FormControl;
  sortingOptions: SortingOption[];

  constructor(
    private taskBusinessService: TaskBusinessService
  ) {
    this.owner = undefined;
    this.actionClicked = new EventEmitter<FilteredTaskListAction>();

    this.tasks = {
      master: [],
      filtered: [],
      display: []
    };

    this.state = {
      loading: true,
      error: false,
      errorMessage: ''
    };

    this.universalFilterData = {
      searchProps: [],
      filterOptions: [],
      defaultFilters: {
        [TasksMessages.STATUS]: [
          TasksMessages.STATUS_NOT_STARTED,
          TasksMessages.STATUS_IN_PROGRESS
        ]
      },
      lastFilterResult: [],
      placeholder: TasksMessages.PLACEHOLDER_TASK_SEARCH
    };

    this.sortingOptions = this.getSortingOptions();
    this.controlSort = this.initControlSort();
  }

  ngOnInit(): void {
    this.getData();
  }

  getData(): void {
    if (!this.owner) { return; }

    this.taskBusinessService.getTasks(null, [], [this.owner.id], [], [], [], null)
      .toPromise()
      .then(tasks => {
        this.populateData(tasks);
        this.state.loading = false;
      })
      .catch(err => this.doError(err.error));
  }

  doError(message: string): void {
    this.state.loading = false;
    this.state.error = true;
    this.state.errorMessage = message;
  }

  populateData(data?: Task[]): void {
    if (!data) { data = this.tasks.master; }
    this.tasks.master = data;
    this.tasks.filtered = data;

    this.universalFilterData.searchProps = [ TasksMessages.TITLE, TasksMessages.DESCRIPTION ];
    this.universalFilterData.defaultFilters = {
      [TasksMessages.STATUS]: [
        TasksMessages.STATUS_NOT_STARTED,
        TasksMessages.STATUS_IN_PROGRESS
      ]
    };
    this.universalFilterData.filterOptions = data.map(task => this.getFilterOptionsForTask(task));

    this.controlSort.setValue(TableColumn.TITLE);
  }

  getFilterOptionsForTask(task: Task): FilterOption {
    return {
      id: task.id,
      properties: {
        [TasksMessages.TITLE]: { value: task.title, filterMethod: FilterMethod.OR },
        [TasksMessages.DESCRIPTION]: { value: task.description || '', filterMethod: FilterMethod.OR },
        [TasksMessages.CREATED_BY]: { value: FilterCreation.getUserName(task.creator), filterMethod: FilterMethod.OR },
        [TasksMessages.TASK_OWNERS]: { value: FilterCreation.getRangeFilter(task.owners.length), filterMethod: FilterMethod.OR },
        [TasksMessages.STATUS]: { value: TaskUtilsService.getTaskStatusFilter(task), filterMethod: FilterMethod.OR },
        [TasksMessages.ALIGNMENT]: { value: TaskUtilsService.getTaskAlignmentTypeFilter(task.alignmentType), filterMethod: FilterMethod.OR },
      }
    };
  }
  
  initControlSort(column?: TableColumn): FormControl {
    const formControl = new FormControl(null, []);

    if (column) {
      formControl.setValue(column, { emitEvent: false });
    }

    formControl.valueChanges.subscribe(column => this.onSortChanged(column));

    return formControl;
  }

  onSortChanged(column: TableColumn): void {
    switch (column) {
      case TableColumn.CREATOR:
        this.tasks.filtered = TaskUtilsService.sortByCreator(this.tasks.filtered);
        break;
      case TableColumn.TITLE:
        this.tasks.filtered = TaskUtilsService.sortByTitle(this.tasks.filtered);
        break;
      case TableColumn.OWNERS:
        this.tasks.filtered = TaskUtilsService.sortByOwners(this.tasks.filtered);
        break;
      case TableColumn.DUE_DATE:
        this.tasks.filtered = TaskUtilsService.sortByDueDate(this.tasks.filtered);
        break;
      case TableColumn.ALIGNMENT:
        this.tasks.filtered = TaskUtilsService.sortByAlignmentType(this.tasks.filtered);
        break;
      case TableColumn.STATUS:
        this.tasks.filtered = TaskUtilsService.sortByStatus(this.tasks.filtered);
        break;
      case TableColumn.ACTIONS:
      default:
        return;
    }

    this.refreshPagination();
  }

  filterEmit(ids: number[], doRefresh = true): void {
    this.universalFilterData.lastFilterResult = ids;
    this.tasks.filtered = this.tasks.master
      .filter(p => ids.includes(p.id));

    this.controlSort.updateValueAndValidity();
    if (doRefresh) {
      this.refreshPagination();
    }
  }

  refreshPagination(): void {
    if (this.pagination) {
      this.pagination.update();
    }
  }

  onTaskAction(event: string, task: Task): void {
    this.actionClicked.emit({ action: event, task });
  }

  getSortingOptions(): SortingOption[] {
    return [
      {
        value: TableColumn.CREATOR,
        label: 'Creator'
      },
      {
        value: TableColumn.TITLE,
        label: 'Title'
      },
      {
        value: TableColumn.OWNERS,
        label: 'Owners'
      },
      {
        value: TableColumn.DUE_DATE,
        label: 'Due Date'
      },
      {
        value: TableColumn.ALIGNMENT,
        label: 'Alignment'
      },
      {
        value: TableColumn.STATUS,
        label: 'Status'
      }
    ];
  }

}
