import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { OneToOneMeeting, OneToOneMeetingMinimal } from '../../model/one-to-one-meeting.model';
import { OneToOneMeetingStatus } from '../../model/one-to-one-meeting-status.model';
import { OneToOneMessages } from '../../locale/one-to-one.messages';
import { OneToOneScheduleDetailsView } from '../../model/one-to-one-schedule.model';
import { TalkingPoint } from '../../model/talking-point.model';
import { Globals } from '@app/shared/globals/globals';
import { OneToOneStatus } from '../../model/one-to-one-status.model';
import { OneToOneFrequency } from '../../model/one-to-one-frequency.model';
import { ButtonType } from '@app/shared/components/inputs/button/button.component';
import { IconHoverColor } from '@app/shared/components/inputs/table-action-icon/table-action-icon.component';
import { IState } from '@app/models/state/state.model';
import { FormControl, Validators } from '@angular/forms';
import { CalendarConnectionStatus } from '../../model/calendar-connected.model';
import { CommonMessages } from '@app/constants/common.messages';
import { OneToOneBusinessService } from '../../service/one-to-one-business.service';
import { RescheduleOneToOneMeeting } from '../../model/reschedule-one-to-one-meeting.model';
import { Router } from '@angular/router';
import { SwalUtils } from '@app/shared/utils/swal.utils';
import { TalkingPointAction, TalkingPointActionAction, TalkingPointActionBump, TalkingPointActionComment, TalkingPointActionPayloadAction, TalkingPointActionPayloadComment, TalkingPointActionType } from '../../component/talking-points/talking-points.component';
import moment from 'moment';
import { AvailabilityModalComponent } from '../../component/availability-modal/availability-modal.component';
import { CompanyFeatures } from '@app/models/company-features.model';

interface PageState extends IState {
  rescheduling: boolean;
  submitted: boolean;
  submitting: boolean;
}

@Component({
  selector: 'app-one-to-one-meeting-view',
  templateUrl: './one-to-one-meeting-view.component.html',
  styleUrls: ['./one-to-one-meeting-view.component.scss']
})
export class OneToOneMeetingViewComponent implements OnChanges {

  public readonly eOneToOneMeetingStatus = OneToOneMeetingStatus;
  public readonly eOneToOneMessages = OneToOneMessages;
  public readonly eCompanyFeatures = CompanyFeatures;
  public readonly eIconHoverColor = IconHoverColor;
  public readonly eCommonMessages = CommonMessages;
  public readonly eButtonType = ButtonType;

  public readonly currentUserTimezone = moment.tz.guess();

  @ViewChild('availabilityModal') availabilityModal?: AvailabilityModalComponent;

  @Input() readonly schedule: OneToOneScheduleDetailsView;
  @Input() meeting: OneToOneMeeting;
  @Input() meetingPrevious?: OneToOneMeeting;
  @Input() calendarConnectionStatus: CalendarConnectionStatus;

  @Output() onMeetingUpdated: EventEmitter<OneToOneMeeting>;
  @Output() onSharedNotesUpdated: EventEmitter<OneToOneMeeting>;
  @Output() onTalkingPointUpdated: EventEmitter<TalkingPoint>;

  talkingPointsPrevious: TalkingPoint[];
  localMeetingTime: string;

  controlReschedule: FormControl;
  dateToday: Date;
  datesOfOtherMeetings: Date[];

  state: PageState;

  get userIsScheduleManager(): boolean {
    if (!this.schedule) { return false; }
    if (!this.globals.user) { return false; }
    if (!this.schedule.manager) { return false; }
    return this.globals.user.id === this.schedule.manager.id;
  }

  get canSeeActions(): boolean {
    if (!this.schedule) { return false; } // Schedule must exist
    if (!this.meeting) { return false; } // Meeting must exist
    if (this.schedule.status !== OneToOneStatus.ACTIVE) { return false; } // Schedule must be active
    return true;
  }

  get canActionMeeting(): boolean {
    if (!this.schedule) { return false; } // Schedule must exist
    if (!this.meeting) { return false; } // Meeting must exist
    if (this.schedule.status !== OneToOneStatus.ACTIVE) { return false; } // Schedule must be active
    if (this.schedule.participants.length === 1) {
      if (!this.userIsScheduleManager) { return false; } // User must be manager
    }

    return true;
  }

  get canEditSchedule(): boolean {
    if (this.state.rescheduling) { return false; }
    return true;
  }

  get canReschedule(): boolean {
    if (!this.canActionMeeting) { return false; } // Must be able to action meeting
    if (this.meeting.status !== OneToOneMeetingStatus.SCHEDULED) { return false; } // Meeting must be in a scheduled state
    return true;
  }

  get showStartButton(): boolean {
    if (this.schedule.frequency === OneToOneFrequency.Flexible) { return true; } // Always show if meeting is flexible
    if (!this.meeting) { return false; }
    if (!this.meeting.meetingTimestamp) { return true; }

    return moment().add(30, 'minutes').isSameOrAfter(moment.tz(this.meeting.meetingTimestamp, this.schedule.timezone));
  }

  get scheduleParticipantIds(): number[] {
    if (!this.schedule) { return []; }
    if (!this.schedule.participants) { return []; }
    return this.schedule.participants.map(p => p.id);
  }

  get canSeeSentimentScaleArea(): boolean {
    if (!this.schedule) { return false; }
    if (this.schedule.participants.length > 1) { return false; }
    if (!this.meeting) { return false; }
    if (!this.meeting.sentimentScaleId) { return false; }
    // We can show this panel for older meetings even if the feature has been disabled
    if (!this.globals.hasFeature(CompanyFeatures.ONE_TO_ONE_SENTIMENT_SCALE)) {
      if (this.meeting.status === OneToOneMeetingStatus.SCHEDULED) { return false; }
    }

    return true;
  }

  constructor(
    public globals: Globals,
    public router: Router,
    private oneToOneBusinessService: OneToOneBusinessService,
    private swalUtils: SwalUtils
  ) {
    this.schedule = undefined;
    this.meeting = undefined;

    this.datesOfOtherMeetings = [];
    this.talkingPointsPrevious = [];
    this.localMeetingTime = undefined;

    this.dateToday = new Date();

    this.state = {
      loading: true,
      error: false,
      errorMessage: '',
      rescheduling: false,
      submitted: false,
      submitting: false
    };

    this.controlReschedule = this.initControlReschedule();

    this.onMeetingUpdated = new EventEmitter<OneToOneMeeting>();
    this.onSharedNotesUpdated = new EventEmitter<OneToOneMeeting>();
    this.onTalkingPointUpdated = new EventEmitter<TalkingPoint>();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.meeting && changes.meeting.currentValue) {
      this.onMeetingChange(changes.meeting.currentValue);
    }
  }

  onMeetingChange(meeting: OneToOneMeetingMinimal): void {
    this.state.loading = true;
    this.localMeetingTime = this.getLocalMeetingTime(meeting.meetingTimestamp);
    this.datesOfOtherMeetings = this.getDisabledRescheduleDates(meeting.meetingTimestamp);
    setTimeout(() => {
      this.state.loading = false;
    }, 1);
  }

  getDisabledRescheduleDates(meetingTimestamp: Date): Date[] {
    const currentMeetingMoment = moment(meetingTimestamp);

    return this.schedule.meetingList
      .map(m => {
        if (!m.meetingTimestamp) {
          return moment().toDate();
        }

        return moment(m.meetingTimestamp).toDate();
      })
      .filter(d => !moment(d).isSame(currentMeetingMoment));
  }

  getLocalMeetingTime(meetingTimestamp: Date): string {
    return moment(meetingTimestamp).tz(this.schedule.timezone, true).local().format('dddd[,] MMM Do [at] HH:mm');
  }
  
  onMeetingUpdatedReceived(meeting: OneToOneMeeting): void {
    this.onMeetingUpdated.emit(meeting);
  }

  onSharedNotesUpdatedReceived(meeting: OneToOneMeeting): void {
    this.onSharedNotesUpdated.emit(meeting);
  }

  onTalkingPointChanged(talkingPoint: TalkingPoint): void {
    if (!talkingPoint) { return; }

    const indexMeeting = this.meeting.talkingPoints.findIndex(tp => tp.id === talkingPoint.id);
    if (indexMeeting > -1) {
      this.meeting.talkingPoints[indexMeeting] = talkingPoint;
    }

    this.onTalkingPointUpdated.emit(talkingPoint);
  }

  onTalkingPointsAction(event: TalkingPointAction): void {
    if (!event) { return; }

    switch (event.type) {
      case TalkingPointActionType.BUMP:
        this.onBumpTalkingPoint(event);
        break;
      case TalkingPointActionType.COMMENT:
        this.onCommentOnTalkingPoint(event);
        break;
      case TalkingPointActionType.ACTION:
        this.onActionTalkingPoint(event);
        break;
    }
  }

  onBumpTalkingPoint(event: TalkingPointActionBump): void {
    if (!event) { return; }
    if (!event.payload) { return; }

    const talkingPointId = event.payload.id;

    this.oneToOneBusinessService.bumpOneToOneMeetingTalkingPoint(this.schedule.id, this.meeting.id, talkingPointId)
      .then(meeting => {
        this.onMeetingUpdatedReceived(meeting);
        if (this.meetingPrevious) {
          this.meetingPrevious.talkingPoints = this.meetingPrevious.talkingPoints.filter(tp => tp.id !== talkingPointId);
        }
      })
      .catch(err => {
        throw err;
      });
  }

  onCommentOnTalkingPoint(event: TalkingPointActionComment): void {
    if (!event) { return; }
    if (!event.payload) { return; }

    const payload: TalkingPointActionPayloadComment = event.payload;

    this.oneToOneBusinessService.addTalkingPointComment(this.schedule.id, this.meeting.id, payload.talkingPointId, payload.comment)
      .then(talkingPoint => {
        const indexMeeting = this.meeting.talkingPoints.findIndex(tp => tp.id === talkingPoint.id);
        if (indexMeeting > -1) {
          this.meeting.talkingPoints[indexMeeting].comments = talkingPoint.comments;
        }
      })
      .catch(err => {
        throw err;
      });
  }

  onActionTalkingPoint(event: TalkingPointActionAction): void {
    if (!event) { return; }
    if (!event.payload) { return; }

    const payload: TalkingPointActionPayloadAction = event.payload;

    this.oneToOneBusinessService.actionOneToOneMeetingTalkingPoint(this.schedule.id, this.meeting.id, payload.talkingPointId, payload.checked)
      .then(talkingPoint => {
        this.onTalkingPointChanged(talkingPoint);
      });
  }

  initControlReschedule(meetingTimestamp?: Date): FormControl {
    const formControl = new FormControl(null, [ Validators.required ]);

    if (meetingTimestamp) {
      formControl.setValue(meetingTimestamp);
    }

    return formControl;
  }

  tryRescheduleMeeting(): void {
    if (!this.canReschedule) { return; }

    this.state.rescheduling = true;

    setTimeout(() => {
      this.controlReschedule = this.initControlReschedule(this.meeting.meetingTimestamp);
    }, 1);
  }

  cancelRescheduleMeeting(): void {
    this.state.rescheduling = false;
  }

  submitRescheduleMeeting(): void {
    if (!this.controlReschedule.valid) { return; }

    this.state.submitting = true;

    const newMeetingTimestamp = moment(this.controlReschedule.value).toISOString();
    const rescheduleRequest: RescheduleOneToOneMeeting = {
      meetingTimestamp: newMeetingTimestamp
    };

    this.oneToOneBusinessService.rescheduleMeeting(this.schedule.id, this.meeting.id, rescheduleRequest)
      .then(meeting => {
        this.onMeetingUpdated.emit(meeting);
        this.state.rescheduling = false;
      });
  }

  tryEditSchedule(): void {
    this.router.navigate(['/one-to-one/edit', this.schedule.id]);
  }

  tryCancelMeeting(): void {
    const messageCode = ((this.schedule.frequency !== OneToOneFrequency.Individual) ? OneToOneMessages.CANCEL_MEETING_SERIES : OneToOneMessages.CANCEL_MEETING_INDIVIDUAL);

    this.swalUtils.displayWarningConfirmationSwal({
      title: OneToOneMessages.CANCEL_MEETING,
      text: messageCode,
      confirmButtonText: OneToOneMessages.CANCEL_MEETING,
      cancelButtonText: OneToOneMessages.KEEP_MEETING
    })
      .then((val) => {
        if (val.isConfirmed) {
          this.cancelMeeting();
        }
      });
  }

  cancelMeeting(): void {
    this.oneToOneBusinessService.updateMeetingStatus(this.schedule.id, this.meeting.id, OneToOneMeetingStatus.CANCELLED)
      .then(meeting => {
        this.onMeetingUpdated.emit(meeting);
      });
  }

  tryReopenMeeting(): void {
    this.oneToOneBusinessService.updateMeetingStatus(this.schedule.id, this.meeting.id, OneToOneMeetingStatus.REOPENED)
      .then(meeting => {
        this.onMeetingUpdated.emit(meeting);
      });
  }

  tryCompleteMeeting(): void {
    this.swalUtils.displayWarningConfirmationSwal({
      title: OneToOneMessages.CONFIRMATION_COMPLETE_MEETING,
      text: OneToOneMessages.EXPLANATION_COMPLETE_MEETING,
      confirmButtonText: OneToOneMessages.MARK_AS_COMPLETED
    }).then((val) => {
      if (val.isConfirmed) {
        this.completeMeeting();
      }
    });
  }

  completeMeeting(): void {
    this.oneToOneBusinessService.updateMeetingStatus(this.schedule.id, this.meeting.id, OneToOneMeetingStatus.COMPLETED)
      .then(meeting => {
        // Don't prompt to change if it's an individual meeting
        if (this.schedule.frequency === OneToOneFrequency.Individual) {
          return meeting;
        }

        return this.swalUtils.displaySuccessConfirmationSwal({
          title: OneToOneMessages.MEETING_STATUS_COMPLETED_SUCCESS,
          text: OneToOneMessages.PROMPT_CHANGE_TALKING_POINT_TEMPLATE,
          cancelButtonText: CommonMessages.NO_CHANGE,
          confirmButtonText: OneToOneMessages.EDIT_SCHEDULE
        }).then((val) => {
          if (val.isConfirmed) {
            this.router.navigate(['/one-to-one/edit', this.schedule.id]);
          } else {
            return meeting;
          }
        });
      })
      .then(meeting => {
        this.onMeetingUpdated.emit(meeting);
      });
  }

  tryStartMeeting(): void {
    this.oneToOneBusinessService.updateMeetingStatus(this.schedule.id, this.meeting.id, OneToOneMeetingStatus.IN_PROGRESS)
      .then(meeting => {
        this.onMeetingUpdated.emit(meeting);
      });
  }

  showAvailabilityModal(event?: MouseEvent): void {
    if (!this.calendarConnectionStatus.connected) { return; }
    if (!this.availabilityModal) { return; }
    event.preventDefault();
    this.availabilityModal.show();
  }
  
  availabilityTimeChanged(val: Date) {
    this.controlReschedule.setValue(val);
  }
  
}
