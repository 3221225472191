import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { VincereMetricResult } from '@app/models/integrations/vincere/vincere-metric-result.model';
import { VincereUserStatusType } from '@app/models/integrations/vincere/vincere-user-status-type.model';
import { VincereUserStatus } from '@app/models/integrations/vincere/vincere-user-status.model';
import { IState } from '@app/models/state/state.model';
import { VincereMetricAPIService } from '@app/shared/api/vincere-metric-api.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { OneToOneScheduleDetailsView } from '@app/domain/one_to_one/model/one-to-one-schedule.model';
import moment from 'moment';

@Component({
  selector: 'app-information-sidebar-vincere',
  templateUrl: './information-sidebar-vincere.component.html',
  styleUrls: ['./information-sidebar-vincere.component.scss']
})
export class InformationSidebarVincereComponent implements OnInit, OnDestroy {

  readonly eVincereUserStatusType = VincereUserStatusType;

  private readonly ngUnsubscribe$ = new Subject<void>();

    @Input() userIds: number[];
    @Input() schedule: OneToOneScheduleDetailsView;

    userIdUsing: number;

    dates: Date[];
    metrics: VincereMetricResult[];
    userStatus!: VincereUserStatus;

    form: FormGroup;
    state: IState;

    get monthControl(): FormControl {
      return this.form.controls.month as FormControl;
    }

    constructor(
        private vincereMetricAPIService: VincereMetricAPIService
    ) {
      this.userIds = [];
      this.schedule = undefined;

      this.dates = [];
      this.metrics = [];
      this.userStatus = undefined;

      this.state = {
        loading: true,
        error: false,
        errorMessage: ''
      };

      this.form = this.initForm();
    }

    ngOnInit(): void {
      this.getData();
    }

    ngOnDestroy(): void {
      this.ngUnsubscribe$.next();
      this.ngUnsubscribe$.unsubscribe();
    }
    
    getData(): void {
      const userId = this.getQueryUserId(this.schedule, this.userIds);
      this.userIdUsing = userId;
      if (!userId) { return; }

      this.vincereMetricAPIService.getDatesForUser(this.userIdUsing).subscribe(dates => {
        this.dates = dates;

        if (this.dates.length > 0) {
          this.form.setValue({
            month: this.dates[0],
          });
        } else {
          this.state.loading = false;
        }
      });
      this.getStatus();
      // TODO: Default this to the current year + month
    }

    getQueryUserId(schedule: OneToOneScheduleDetailsView, userIds: number[]): number {
      if (schedule) {
        if (schedule.participants.length === 1) {
          return schedule.participants[0].id;
        }
      } {
        return userIds[0];
      }
    }

    initForm() {
      const formGroup = new FormGroup({
        month: new FormControl(null, [])
      });

      formGroup.valueChanges.subscribe(() => this.getMetrics(this.monthControl.value));

      return formGroup;
    }

    getStatus(): void {
      this.vincereMetricAPIService.getUserStatus(this.userIdUsing)
        .pipe(takeUntil(this.ngUnsubscribe$))
        .subscribe(userStatus => {
          this.userStatus = userStatus;
        });

    }

    getMetrics(date): void {
      const dateMoment = moment(date);
      const month = (dateMoment.month() + 1); // These are 0 indexed in moment
      const year = dateMoment.year();

      this.vincereMetricAPIService.getUserMetrics(this.userIdUsing, year, month)
        .pipe(takeUntil(this.ngUnsubscribe$))
        .subscribe(result => {
          this.metrics = result.sort((a, b) => a.vincereMetric.orderIndex - b.vincereMetric.orderIndex);
          this.state.loading = false;
        });
    }

}
