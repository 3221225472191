import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { TitleCasePipe } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { CreateGoalDraftKeyResultDto } from '@app/models/goals/create-goal-draft-key-result.dto';
import { CreateGoalKeyResultDto } from '@app/models/goals/create-goal-key-result.dto';
import { CreateGoalDto } from '@app/models/goals/create-goal.dto';
import { GoalCreateFormMode } from '@app/models/goals/goal-create-form-mode.model';
import { GoalDraft } from '@app/models/goals/goal-draft.model';
import { GoalKeyResultMeasureUnitPlacement } from '@app/models/goals/goal-key-result-measure-unit-placement.model';
import { GoalKeyResultType } from '@app/models/goals/goal-key-result-type.model';
import { GoalType } from '@app/models/goals/goal-type.enum';
import { NotifyUtils } from '@app/shared/utils/notify.utils';
import { FrankliValidators } from '@app/shared/validators/validators';
import { CompanyFeatures } from '@app/models/company-features.model';
import { Site } from '@app/models/site.model';
import { GoalKeyResult } from 'app/models/goals/goal-key-result.model';
import { Tag } from '@app/domain/tag/model/tag.model';
import { GoalTemplate } from '@app/models/goals/templates/goal-template.model';
import { Goal } from 'app/models/goals/goal.model';
import { Department } from '@app/models/department.model';
import { RoleName } from '@app/models/user-role.model';
import { User } from 'app/models/user/user.model';
import { CompanyAPIService } from '@app/shared/api/company/company.api.service';
import { GoalsAPIService } from 'app/shared/api/goals.api.service';
import { UserAPIService } from 'app/shared/api/user.api.service';
import { Globals } from 'app/shared/globals/globals';
import { forkJoin, Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, finalize, takeUntil } from 'rxjs/operators';
import { GoalCreateService } from './goals-create.service';
import { CriterionOperator } from '@app/models/api/criterion-operator.enum';
import { staticUserPagingParams, staticUserSortingParams } from '@app/shared/utils/api-filter/static-user-params';
import { ChildFilter } from '@app/models/api/child-filter.model';
import { FilterOperator } from '@app/models/api/filter-operator.enum';
import { ParentFilter } from '@app/models/api/parent-filter.model';
import { onboardedNotArchivedChildFilter } from '@app/shared/utils/api-filter/common-user-filters';
import { UpdateGoalDraft } from '@app/models/goals/update-goal-draft';
import { GoalTemplateKeyResult } from '@app/models/goals/templates/goal-template-key-result.model';
import { GoalPriority } from '@app/models/goals/goal-priority.model';
import { GoalStatus } from '@app/models/goals/goal-status.model';
import { GoalVisibility } from '@app/models/goals/goal-visibility.model';
import { DropdownOption } from '@app/models/utils.model';
import { GoalsModalComponent } from '../goals-modal/goals-modal.component';
import { GoalDistribution } from '@app/models/goals/goal-distribution.model';
import moment from 'moment';
import { TagBusinessService } from '@app/domain/tag/service/tag-business.service';
import { TerminologyEntity } from '@app/domain/terminology/model/terminology-entity.enum';

interface PoolSelectorFilter<T> {
  value: string;
  all: T[]
  filtered: T[];
}

interface SelectorFilter<T> {
  value: string;
  filtered: T[];
}

@Component({
  selector: 'app-goals-create-old',
  templateUrl: './goals-create-old.component.html',
  styleUrls: ['./goals-create-old.component.scss']
})
export class GoalsCreateOldComponent implements OnInit, OnDestroy {

  private readonly ngUnsubscribe$: Subject<void> = new Subject<void>();

  public readonly eGoalCreateFormMode = GoalCreateFormMode;
  public readonly eTerminologyEntity = TerminologyEntity;
  public readonly eValuesGoalPriority = GoalPriority;
  public readonly eGoalVisibility = GoalVisibility;
  public readonly eGoalPriority = GoalPriority;
  public readonly eFeature = CompanyFeatures;
  public readonly eGoalStatus = GoalStatus;
  public readonly eRoleName = RoleName;
  public readonly eGoalType = GoalType;

  @Input() modal !: GoalsModalComponent;
  @Input() mode: GoalCreateFormMode = GoalCreateFormMode.CREATE;
  @Input() goal !: Goal;
  @Input() draftGoal: GoalDraft | null = null;
  @Input() userId: number | null = null;
  @Input() startType?: GoalType = undefined;


  valuesGoalType: DropdownOption[];
  valuesGoalVisibility: GoalVisibility[];
  alignedGoals: Goal[];

  tagFilter: PoolSelectorFilter<Tag>;

  ownerFilter: SelectorFilter<User>;

  departments: Department[];
  officeLocations: Site[];
  goals: Goal[];
  alignmentLoading: boolean;

  tagsSelected: Tag[];
  ownersSelected: User[];

  templates: GoalTemplate[];
  templatesTitle: string[];
  templatesResult: string[];

  form!: FormGroup;
  keyResultsControls: FormGroup[];

  submitted: boolean;
  createLoading: boolean;
  saveLoading: boolean;

  loading: boolean;
  error: boolean;

  minEndDate: moment.Moment;

  boxUserFilterValueChanged = new Subject<string>();
  searchChildFilter: ChildFilter = {
    operator: FilterOperator.AND,
    filterCriteria: []
  };
  parentFilter: ParentFilter = {
    operator: FilterOperator.AND,
    childFilters: [onboardedNotArchivedChildFilter, this.searchChildFilter]
  };

  constructor(
    public globals: Globals,
    private formBuilder: FormBuilder,
    private goalsAPIService: GoalsAPIService,
    private userAPIService: UserAPIService,
    private companyAPIService: CompanyAPIService,
    private goalCreateService: GoalCreateService,
    private titleCasePipe: TitleCasePipe,
    private notifyUtils: NotifyUtils,
    private tagBusinessService: TagBusinessService
  ) {
    this.minEndDate = moment();

    this.valuesGoalVisibility = [];
    this.keyResultsControls = [];
    this.templatesResult = [];
    this.officeLocations = [];
    this.templatesTitle = [];
    this.ownersSelected = [];
    this.valuesGoalType = [];
    this.alignedGoals = [];
    this.tagsSelected = [];
    this.departments = [];
    this.templates = [];
    this.goals = [];

    this.alignmentLoading = true;
    this.loading = true;

    this.createLoading = false;
    this.saveLoading = false;
    this.submitted = false;
    this.error = false;

    this.tagFilter = {
      value: '',
      all: new Array<Tag>(),
      filtered: new Array<Tag>()
    };
    this.ownerFilter = {
      value: '',
      filtered: new Array<User>()
    };

    this.boxUserFilterValueChanged.pipe(debounceTime(500), distinctUntilChanged()).subscribe((sarg) => {
      if (sarg.trim() === '') {
        this.ownerFilter.filtered = [];
      } else {
        this.searchChildFilter.filterCriteria = [
          {
            field: 'name',
            operator: CriterionOperator.LIKE,
            value: sarg
          },
          {
            field: 'id',
            operator: CriterionOperator.NOT_IN,
            values: this.ownersSelected.map(su => String(su.id))
          }
        ];
        this.userAPIService.searchUsersPaginated(staticUserPagingParams, staticUserSortingParams, this.parentFilter).subscribe(users => {
          this.ownerFilter.filtered = users.content as User[];
        });
      }
    });

    this.initForm();
  }

  ngOnInit() {
    this.resetForm();
    this.initServiceSubscriptions();
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.unsubscribe();
  }

  initServiceSubscriptions() {
    this.goalCreateService.getReset().pipe(takeUntil(this.ngUnsubscribe$)).subscribe(() => this.resetForm());
    this.goalCreateService.getGoalCopied().pipe(takeUntil(this.ngUnsubscribe$)).subscribe(goal => this.onCopy(goal));
    this.goalCreateService.getGoalTemplateCopied().pipe(takeUntil(this.ngUnsubscribe$)).subscribe(template => this.onCopyTemplate(template));
  }

  initForm() {
    this.form = this.formBuilder.group({
      id: new FormControl(null, []),
      goalTitle: new FormControl('', [Validators.required, Validators.maxLength(255)]),
      goalType: new FormControl(null, [Validators.required]),
      goalDepartment: new FormControl(null, []),
      goalOfficeLocation: new FormControl(null, []),
      goalAlignment: new FormControl(null, []),
      developmentNeeds: new FormControl('', [Validators.maxLength(255)]),
      endDate: new FormControl('', [Validators.required]),
      visibility: new FormControl(null, [Validators.required, this.isValidVisibilityForGoalType()]),
      priority: new FormControl('', [Validators.required]),
      distribute: new FormControl(false, []),
    }, {
      validators: this.departmentValidator()
    });

    this.keyResultsControls = [ this.initKeyResult() ];

    this.form.controls.goalTitle.valueChanges
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(() => {
        const template = this.templates.find(t => t.title === this.form.controls.goalTitle.value.trim());
        if (template !== undefined) {
          this.templatesResult = template.keyResults.map(t => t.result);
        }
      });

    this.form.controls.goalType.valueChanges
      .pipe(takeUntil(this.ngUnsubscribe$), debounceTime(250))
      .subscribe((value: GoalType) => {
        // Alignment
        /*
          PERSONAL_DEVELOPMENTAL - none
          PERSONAL_OPERATIONAL - TEAM, DEPARTMENT, OFFICE_LOCATION, COMPANY
          TEAM - DEPARTMENT, OFFICE_LOCATION, COMPANY
          DEPARTMENT - OFFICE_LOCATION, COMPANY
          OFFICE_LOCATION - DEPARTMENT, COMPANY
          COMPANY - none
        */

        const subscriptions: Array<Observable<Array<Goal>>> = new Array<Observable<Array<Goal>>>();
        let alignmentSubscription: Observable<Goal> | null = null;
        this.alignmentLoading = true;

        // Alignment
        if (this.mode === GoalCreateFormMode.EDIT_DRAFT && this.draftGoal !== null) {
          this.form.controls.goalAlignment.setValue(this.draftGoal.alignment);
          if (this.draftGoal.alignment !== null) {
            alignmentSubscription = this.goalsAPIService.getGoalById(this.draftGoal.alignment);
          }
        } else {
          this.form.controls.goalAlignment.setValue(null);
        }

        const currentUserIsOwner = this.ownersSelected.some(u => u.id === this.globals.user.id);

        switch (value) {
          case GoalType.PERSONAL_OPERATIONAL:
          case GoalType.PERSONAL_DEVELOPMENTAL:
            this.setVisibilityOptions([GoalVisibility.PUBLIC, GoalVisibility.PRIVATE]);

            // // if user not in list, add them
            if (currentUserIsOwner === false && this.globals.hasRole(RoleName.GOAL_COMPANY) === false) {
              this.addOwner(this.globals.user);
            }

            subscriptions.push(this.goalsAPIService.getTeamGoalsActive());
            subscriptions.push(this.goalsAPIService.getDepartmentGoalsActive());
            if (this.globals.hasFeature(CompanyFeatures.GOAL_OFFICE_LOCATION)) {
              subscriptions.push(this.goalsAPIService.getOfficeLocationGoalsActive());
            }
            subscriptions.push(this.goalsAPIService.getCompanyGoalsActive());
            break;
          case GoalType.TEAM:
            this.setVisibilityOptions([GoalVisibility.PUBLIC, GoalVisibility.PRIVATE]);

            subscriptions.push(this.goalsAPIService.getDepartmentGoalsActive());
            if (this.globals.hasFeature(CompanyFeatures.GOAL_OFFICE_LOCATION)) {
              subscriptions.push(this.goalsAPIService.getOfficeLocationGoalsActive());
            }
            subscriptions.push(this.goalsAPIService.getCompanyGoalsActive());
            break;
          case GoalType.COMPANY:
            this.setVisibilityOptions([GoalVisibility.PUBLIC, GoalVisibility.PRIVATE]);
            break;
          case GoalType.DEPARTMENT:
            this.form.controls.visibility.setValue(GoalVisibility.PUBLIC);
            this.setVisibilityOptions([GoalVisibility.PUBLIC]);

            // eslint-disable-next-line no-case-declarations
            let d: number | null = null;
            if (this.mode === GoalCreateFormMode.EDIT_DRAFT && this.draftGoal !== null) {
              if (this.draftGoal.department !== null) {
                if (this.departments.some(department => department.id === this.draftGoal!.department!.id)) {
                  d = this.draftGoal.department.id;
                }
              }
            } else {
              if (this.globals.user.organisationalUnit !== null) {
                if (this.departments.some(department => department.id === this.globals.user.organisationalUnit!.id)) {
                  d = this.globals.user.organisationalUnit.id;
                }
              }
            }
            this.form.controls.goalDepartment.setValue(d);

            if (this.globals.hasFeature(CompanyFeatures.GOAL_OFFICE_LOCATION)) {
              subscriptions.push(this.goalsAPIService.getOfficeLocationGoalsActive());
            }
            subscriptions.push(this.goalsAPIService.getCompanyGoalsActive());
            break;
          case GoalType.OFFICE_LOCATION:
            this.form.controls.visibility.setValue(GoalVisibility.PUBLIC);
            this.setVisibilityOptions([GoalVisibility.PUBLIC]);

            // eslint-disable-next-line no-case-declarations
            let o: number | null = null;
            if (this.mode === GoalCreateFormMode.EDIT_DRAFT && this.draftGoal !== null) {
              if (this.draftGoal.officeLocation !== null) {
                o = this.draftGoal.officeLocation.id!;
              }
            } else {
              if (this.globals.user.officeLocation !== null) {
                o = this.globals.user.officeLocation.id!;
              }
            }
            this.form.controls.goalOfficeLocation.setValue(o);

            subscriptions.push(this.goalsAPIService.getDepartmentGoalsActive());
            subscriptions.push(this.goalsAPIService.getCompanyGoalsActive());
            break;
        }

        if (subscriptions.length > 0) {
          forkJoin(subscriptions).pipe(takeUntil(this.form.controls.goalType.valueChanges)).subscribe((response) => {
            this.goals = new Array<Goal>().concat(...response); // flatten array of arrays of goals

            // If aligned goal hasn't already been pulled in
            if (alignmentSubscription !== null && !this.goals.some(g => g.id === this.draftGoal!.alignment)) {

              alignmentSubscription.pipe(finalize(() => {
                // If aligned goal isn't in list then it isn't valid so remove
                if (this.form.controls.goalAlignment.value !== null && !this.goals.some(g => {
                  return g.id === this.form.controls.goalAlignment.value;
                })) {
                  this.form.controls.goalAlignment.setValue(null);
                }

                if (this.goals.length === 0) {
                  this.form.controls.goalAlignment.disable();
                } else {
                  this.form.controls.goalAlignment.enable();
                }

                this.alignmentLoading = false;
              })).subscribe(alignedGoal => {
                this.goals.unshift(alignedGoal);
              });
            } else {
              // If aligned goal isn't in list then it isn't valid so remove
              if (this.form.controls.goalAlignment.value !== null && !this.goals.some(g => {
                return g.id === this.form.controls.goalAlignment.value;
              })) {
                this.form.controls.goalAlignment.setValue(null);
              }

              if (this.goals.length === 0) {
                this.form.controls.goalAlignment.disable();
              } else {
                this.form.controls.goalAlignment.enable();
              }

              this.alignmentLoading = false;
            }

          }, () => {
            this.alignmentLoading = false;
          });
        } else {
          this.alignmentLoading = false;
        }
      });
  }

  isValidVisibilityForGoalType(): ValidatorFn {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const comp = this;

    return (control: AbstractControl) => {
      const validValues = comp.valuesGoalVisibility as GoalVisibility[];
      const selectedValue = control.value as GoalVisibility;

      if (validValues.includes(selectedValue)) {
        return null;
      }

      return {
        visbilityvalid: true,
      };
    };
  }

  initKeyResultGoal(result: GoalKeyResult): FormGroup {
    const group = this.initKeyResult();

    if (result) {
      group.controls.result.setValue(result.result);
      group.controls.type.setValue(result.type);
      group.controls.measureStartValue.setValue(result.measureStartValue);
      group.controls.measureGoalValue.setValue(result.measureGoalValue);
      group.controls.measureUnit.setValue(result.measureUnit);
      group.controls.measureUnitPlacement.setValue(result.measureUnitPlacement);
    }

    return group;
  }

  initKeyResultTemplate(result: GoalTemplateKeyResult): FormGroup {
    const group = this.initKeyResult();

    if (result) {
      group.controls.result.setValue(result.result);
      group.controls.type.setValue(result.type);
      group.controls.measureUnit.setValue(result.measureUnit);
      group.controls.measureUnitPlacement.setValue(result.measureUnitPlacement);
    }

    return group;
  }

  initKeyResult(): FormGroup {
    return this.formBuilder.group({
      id: new FormControl(null, []),
      result: new FormControl('', [Validators.required, Validators.maxLength(255)]),
      type: new FormControl(GoalKeyResultType.BINARY, [Validators.required]),
      measureStartValue: new FormControl(0, [Validators.required, Validators.pattern(/^(\d{1,22})(.\d{1,2})?$/)]),
      measureGoalValue: new FormControl(1, [Validators.required, Validators.pattern(/^(\d{1,22})(.\d{1,2})?$/)]),
      endDate: new FormControl(null, []),
      measureUnit: new FormControl('', []),
      measureUnitPlacement: new FormControl(GoalKeyResultMeasureUnitPlacement.AFTER, [])
    }, {
      validators: [FrankliValidators.goalCompletionValidator()]
    });
  }

  onCopy(goal: Goal) {
    this.form.controls.goalTitle.setValue(goal.title);
    this.form.controls.priority.setValue(goal.priority);

    if (this.canSetGoalType(goal.type)) {
      this.form.controls.goalType.setValue(goal.type);
    }

    this.form.controls.visibility.setValue(goal.visibility.toString());

    // Get an array of formGroups
    this.keyResultsControls = goal.keyResults.map(kr => this.initKeyResultGoal(kr));
  }

  onCopyTemplate(template: GoalTemplate) {
    this.form.controls.goalTitle.setValue(template.title);

    // Get an array of formGroups
    this.keyResultsControls = template.keyResults.map(kr => this.initKeyResultTemplate(kr));
  }

  canSetGoalType(type: GoalType): boolean {
    switch (type) {
      case GoalType.COMPANY:
        return this.globals.hasRole(RoleName.GOAL_COMPANY);
      case GoalType.DEPARTMENT:
        return (!!this.globals.user.organisationalUnit);
      case GoalType.OFFICE_LOCATION:
        return (this.globals.user.officeLocation! && this.globals.hasFeature(CompanyFeatures.GOAL_OFFICE_LOCATION));
      default:
        return true;
    }
  }

  resetForm(): void {
    this.submitted = false;
    this.createLoading = false;
    this.saveLoading = false;
    this.loading = true;

    this.tagFilter.value = '';
    this.tagFilter.all = new Array<Tag>();
    this.tagFilter.filtered = new Array<Tag>();
    this.tagsSelected = new Array<Tag>();

    this.ownerFilter.value = '';
    this.ownerFilter.filtered = new Array<User>();
    this.ownersSelected = new Array<User>();

    this.valuesGoalVisibility = new Array<GoalVisibility>();
    this.valuesGoalVisibility.push(GoalVisibility.PUBLIC);
    this.valuesGoalVisibility.push(GoalVisibility.PRIVATE);

    // Add goal types to the list
    this.valuesGoalType = new Array<DropdownOption>();
    this.valuesGoalType.push({
      value: GoalType.PERSONAL_OPERATIONAL,
      name: 'Personal Operational'
    });
    this.valuesGoalType.push({
      value: GoalType.PERSONAL_DEVELOPMENTAL,
      name: 'Personal Developmental'
    });
    this.valuesGoalType.push({
      value: GoalType.TEAM,
      name: this.globals.getTerminology(TerminologyEntity.TEAM)
    });

    // If user has an office location or is a goal admin AND Site goals are enabled
    if ((this.globals.user.officeLocation !== null || this.globals.user.roles.some(role => role.name === RoleName.GOAL_COMPANY)) && this.globals.hasFeature(CompanyFeatures.GOAL_OFFICE_LOCATION)) {
      this.valuesGoalType.push({
        value: GoalType.OFFICE_LOCATION,
        name: this.globals.getTerminology(TerminologyEntity.SITE)
      });
    }

    // if user has a department or is a goal admin
    if (this.globals.user.organisationalUnit !== null || this.globals.user.roles.some(role => role.name === RoleName.GOAL_COMPANY)) {
      this.valuesGoalType.push({
        value: GoalType.DEPARTMENT,
        name: this.globals.getTerminology(TerminologyEntity.DEPARTMENT)
      });
    }

    // if user is a goal admin
    if (this.globals.user.roles.some(role => role.name === RoleName.GOAL_COMPANY)) {
      this.valuesGoalType.push({
        value: GoalType.COMPANY,
        name: 'Company'
      });
    }

    this.keyResultsControls = [ this.initKeyResult() ];
    this.form.controls.endDate.setValue(null);
    this.form.controls.goalTitle.setValue('');
    // end - Dates

    this.form.controls.visibility.setValue(null);
    this.form.controls.priority.setValue(GoalPriority.NOT_SET);
    this.form.controls.goalType.setValue(this.startType);

    forkJoin([
      this.tagBusinessService.get(null, null, null),
      this.companyAPIService.getDepartmentByType('Department'),
      this.companyAPIService.getAllSites(),
      this.goalsAPIService.getGoalTemplates()
    ]).subscribe(([tags, orgs, officeLocations, templates]) => {
      // tags
      this.tagFilter.all = new Array<Tag>();
      tags.forEach(t => {
        this.tagFilter.all.push(t);
      });
      this.tagsSelected = [];

      // user list
      this.ownersSelected = [];

      if (this.mode !== GoalCreateFormMode.EDIT_DRAFT) {
        this.addOwner(this.globals.user);
      }

      // departments
      this.departments.length = 0;
      orgs.forEach(o => {
        this.departments.push(o);
      });

      // office locations
      this.officeLocations = [];
      officeLocations.forEach(o => {
        this.officeLocations.push(o);
      });

      this.templates = templates;
      this.templatesTitle = templates.map(t => t.title);

      if (this.mode === GoalCreateFormMode.EDIT_DRAFT) {
        this.resetFormDraft();
        this.loading = false;
      } else {
        // if creating goal for a specific user
        if (this.userId !== null) {
          this.form.controls.goalType.setValue(GoalType.PERSONAL_OPERATIONAL);
          this.ownersSelected.forEach(o => this.removeOwner(o));
          this.userAPIService.getById(this.userId).subscribe(user => {
            if (user !== undefined) {
              this.addOwner(user);
            }
          });
        }
        this.loading = false;
      }

    }, () => {
      this.error = true;
      this.loading = false;
    });


  }

  resetFormDraft(): void {
    if (!this.draftGoal) {
      return;
    }

    // title
    if (this.draftGoal.title !== null) {
      this.form.controls.goalTitle.setValue(this.draftGoal.title);
    }

    // type
    if (this.draftGoal.type !== null) {
      this.form.controls.goalType.setValue(this.draftGoal.type);

      if (this.draftGoal.type === GoalType.COMPANY) {
        if (!this.valuesGoalType.some(type => type.value === GoalType.COMPANY)) {
          this.form.controls.goalType.setValue(null);
        }
      } else if (this.draftGoal.type === GoalType.DEPARTMENT && this.draftGoal.department !== null) {
        this.form.controls.goalDepartment.setValue(this.draftGoal.department.id);
      }
    }

    this.form.controls.endDate.setValue(this.draftGoal.endDate);

    this.form.controls.visibility.setValue(this.draftGoal.visibility);

    if (this.draftGoal.priority !== null) {
      this.form.controls.priority.setValue(this.draftGoal.priority);
    }

    if (this.draftGoal.developmentNeeds !== null) {
      this.form.controls.developmentNeeds.setValue(this.draftGoal.developmentNeeds);
    }

    this.draftGoal!.owners.forEach(g => this.addOwner(g));

    this.tagFilter.all.forEach(t => {
      this.draftGoal!.tags.forEach(g => {
        if (t.id === g.id) {
          this.addTag(t);
        }
      });
    });

    if (this.draftGoal.keyResults.length > 0) {
      // Get an array of formGroups
      const keyResultControls = this.draftGoal.keyResults.map(() => this.initKeyResult());

      // Replace existing with a new FormArray made of the above FormGroups
      this.keyResultsControls = keyResultControls;

      // Populate key results with values from copied goal
      this.draftGoal.keyResults.forEach((kr, i) => {
        const formGroup = this.keyResultsControls[i] as FormGroup;

        setTimeout(() => {
          formGroup.controls.id.setValue(kr.id);
          formGroup.controls.result.setValue(kr.result);
          formGroup.controls.type.setValue(kr.type);
          formGroup.controls.measureStartValue.setValue(kr.measureStartValue);
          formGroup.controls.measureGoalValue.setValue(kr.measureGoalValue);
          formGroup.controls.endDate.setValue(kr.endDate);
          formGroup.controls.measureUnit.setValue(kr.measureUnit);
          formGroup.controls.measureUnitPlacement.setValue(kr.measureUnitPlacement);
        }, 250);
      });
    } else {
      this.keyResultsControls = [this.initKeyResult()];
    }

    if (this.globals.hasRole(RoleName.GOAL_COMPANY)) {
      this.form.controls.distribute.setValue(this.draftGoal.distribute);
    }
  }

  addKeyResult(): void {
    this.keyResultsControls.push(this.initKeyResult());
  }

  removeKeyResult(i: number): void {
    this.keyResultsControls = this.keyResultsControls.filter((item, index) => index !== i);
  }

  setVisibilityOptions(visibility: Array<GoalVisibility>): void {
    while (this.valuesGoalVisibility.length) {
      this.valuesGoalVisibility.pop();
    }
    visibility.forEach(v => {
      this.valuesGoalVisibility.push(v);
    });
  }

  formDateToDateObject(control: AbstractControl): Date | null {
    let date = control.value;
    if (date !== null) {
      // ensure date is in utc format to avoid timezone issues
      date = moment.utc(date).toDate();
    }

    return date;
  }

  addTag(tag: Tag): void {
    if (!this.tagsSelected.includes(tag)) {
      // add tag to selected
      this.tagsSelected.push(tag);
      // remove tag from list
      this.tagFilter.all = this.tagFilter.all.filter(t => t.id !== tag.id);
      this.clearTagFilter();
    }
  }

  removeTag(tag: Tag): void {
    // add tag to all
    this.tagFilter.all.push(tag);
    // remove from selected
    this.tagsSelected = this.tagsSelected.filter(t => {
      return t !== tag;
    });
    this.filterTags(this.tagFilter.value);
  }

  filterTags(e: string): void {
    if (this.tagFilter.value.trim() === '') {
      this.tagFilter.filtered = [];
    } else {
      this.tagFilter.filtered = this.tagFilter.all.filter(tag => {
        return tag.text.toLocaleLowerCase().includes(e.toLocaleLowerCase());
      }).sort((a, b) => {
        return +(a.text.toLocaleLowerCase() > b.text.toLocaleLowerCase());
      });
    }
  }

  clearTagFilter(): void {
    this.tagFilter.value = '';
    this.filterTags(this.tagFilter.value);
  }

  addOwner(user: User) {
    if (!this.ownersSelected.includes(user)) {
      this.ownersSelected.push(user);
      this.clearOwnerFilter();
    }
  }

  /**
   * Removes a user from `ownerSelected` and adds the user `ownerFilter.all`
   */
  removeOwner(user: User): void {
    if (!user) {
      return;
    }

    this.ownersSelected = this.ownersSelected.filter(u => {
      return u.id !== user.id;
    });

    this.filterOwners('');
  }

  filterOwners(sarg: string): void {
    this.boxUserFilterValueChanged.next(sarg);
  }

  clearOwnerFilter() {
    this.ownerFilter.value = '';
    this.filterOwners('');
  }

  createGoal(): void {
    this.submitted = true;
    const isValid = this.isValid();

    if (!isValid) {
      return;
    }

    this.createLoading = true;

    const createGoalDto = this.buildCreateDto();
    this.goalsAPIService.createGoal(createGoalDto)
      .toPromise()
      .then(() => {
        this.notifyUtils.notify('Your goal has been successfully created');

        this.goalCreateService.sendRefreshGoals();

        this.resetForm();
        this.modal.hide();
      })
      .catch(() => {
        this.createLoading = false;
      });
  }

  // NOTE: Doesn't add owners because they're added later during distribution
  buildCreateDto(): CreateGoalDto {
    const type = this.form.controls.goalType.value;
    return {
      draftId: this.form.controls.id.value,
      type: type,
      title: this.form.controls.goalTitle.value,
      visibility: this.form.controls.visibility.value,
      alignmentId: this.form.controls.goalAlignment.value,
      priority: this.form.controls.priority.value.toUpperCase(),
      developmentNeeds: this.form.controls.developmentNeeds.value,
      tagIds: this.tagsSelected.map(t => t.id),
      departmentId: this.form.controls.goalDepartment.value,
      officeLocationId: this.form.controls.goalOfficeLocation.value,
      endDate: this.formDateToDateObject(this.form.controls.endDate)!,
      keyResults: this.keyResultsControls.map((group, index) => {
        const goalKeyResult: CreateGoalKeyResultDto = {
          id: group.controls.id.value,
          result: group.controls.result.value,
          type: group.controls.type.value,
          measureStartValue: group.controls.measureStartValue.value,
          measureGoalValue: group.controls.measureGoalValue.value,
          measureUnit: group.controls.measureUnit.value,
          measureUnitPlacement: group.controls.measureUnitPlacement.value,
          orderIndex: index,
          endDate: this.formDateToDateObject(group.controls.endDate),
        };
        return goalKeyResult;
      }),
      ownerIds: this.ownersSelected.map(o => o.id),
      distribute: (this.form.controls.distribute.value ? GoalDistribution.OWNERS : GoalDistribution.NONE),
      tasks: []
    };
  }

  saveDraft() {
    this.saveLoading = true;
    this.submitted = true;

    const createDto = this.buildCreateDto();

    createDto.keyResults = createDto.keyResults.filter(keyResult => keyResult.result.trim() !== ''); // strip out all key results with no name

    // FIXME: Add to form validators
    // const validResults = this.checkDraftKeyResultsValid(createDto.keyResults);
    // if (!validResults) {
    //   this.swalUtils.displayErrorSwal({
    //     title: 'Key result targets not valid',
    //     text: 'Target values must be a valid whole number',
    //     imageUrl: 'assets/img/swal-icons/frankli-error-icon.svg',
    //     imageWidth: 140,
    //     imageHeight: 140,
    //     confirmButtonColor: '#30747F'
    //   });
    //   this.saveLoading = false;
    //   return;
    // }

    const distributing = this.form.controls.distribute.value;

    // FIXME: Re-implement
    // if (this.mode === GoalCreateFormMode.EDIT_DRAFT && this.draftGoal !== null) {
    //   this.saveDraftGoalFromExisting(createDto, distributing, this.draftGoal.id!);
    // } else {
    //   this.saveDraftGoalFromNew(createDto, distributing);
    // }
  }

  checkDraftKeyResultsValid(keyResults: GoalKeyResult[]): boolean {
    for (let i = 0; i < keyResults.length; i++) {
      if (isNaN(keyResults[i].measureStartValue) || keyResults[i].measureStartValue.toString().trim().length === 0) {
        return false;
      } else {
        if (keyResults[i].measureStartValue % 1 !== 0) {
          return false;
        }
      }

      if (isNaN(keyResults[i].measureGoalValue) || keyResults[i].measureGoalValue.toString().trim().length === 0) {
        return false;
      } else {
        if (keyResults[i].measureGoalValue % 1 !== 0) {
          return false;
        }
      }
    }

    return true;
  }

  parseKeyResultsToDraftCreateDto(keyResults: GoalKeyResult[]): CreateGoalDraftKeyResultDto[] {
    return keyResults
      .map(kr => new CreateGoalDraftKeyResultDto(
        kr.result,
        kr.endDate,
        kr.type,
        kr.measureGoalValue,
        kr.measureStartValue,
        kr.measureUnit,
        kr.measureUnitPlacement,
        kr.orderIndex
      ));
  }

  saveDraftGoalFromExisting(goal: Goal, distribute: boolean, draftGoalId: number): void {
    const updateDraftGoal: UpdateGoalDraft = {
      alignment: goal.alignment,
      department: goal.department,
      developmentNeeds: goal.developmentNeeds,
      distribute: distribute,
      endDate: goal.endDate,
      id: draftGoalId,
      keyResults: goal.keyResults,
      officeLocation: goal.officeLocation,
      ownerIds: goal.owners.map(o => o.id),
      priority: goal.priority,
      tags: goal.tags,
      title: goal.title,
      type: goal.type,
      visibility: goal.visibility
    };

    if (updateDraftGoal.id !== null) {
      this.goalsAPIService.updateDraftGoalById(updateDraftGoal.id, updateDraftGoal).subscribe(response => {
        this.notifyUtils.notify('Your draft has been successfully updated');
        this.goalCreateService.sendGoalSaved(response);
        this.resetForm();
        this.modal.hide();
      }, () => {
        this.saveLoading = false;
      });
    }
  }

  saveDraftGoalFromNew(goal: Goal, distribute: GoalDistribution) {
    const createGoalDraftDto = new CreateGoalDto(
      goal.title,
      goal.type,
      goal.department === null ? null : goal.department.id,
      goal.officeLocation === null ? null : goal.officeLocation.id,
      goal.endDate,
      goal.visibility,
      goal.priority,
      goal.tags.map(t => t.id),
      goal.owners.map(o => o.id),
      this.parseKeyResultsToDraftCreateDto(goal.keyResults),
      goal.alignment,
      goal.developmentNeeds,
      distribute
    );

    this.goalsAPIService.createDraftGoal(createGoalDraftDto).subscribe(() => {
      this.resetForm();
      this.notifyUtils.notify('Your draft has been successfully created');
      this.modal.hide();
    }, () => {
      this.saveLoading = false;
    });
  }

  isValid() {
    if (this.form.valid === true) {
      if (
        this.form.controls.visibility.value !== null
        && this.form.controls.goalType.value !== null
        && (this.keyResultsControls.length > 0)
      ) {
        // check goal end date after key result end date

        if (this.validateEndDates() === false) {
          return false;
        }

        return this.isValidOwners();
      }
    }
    return false;
  }

  validateEndDates(): boolean {
    const resultsCount = this.keyResultsControls.length;
    for (let i = 0; i < resultsCount; i++) {
      const resultEndDate = this.keyResultsControls[i].value.endDate;
      const goalEndDate = this.form.controls.endDate.value;

      if (resultEndDate !== null) {
        const g = moment(goalEndDate);
        const r = moment(resultEndDate);

        if (g.diff(r, 'days') < 0) {
          return false;
        }
      }
    }
    return true;
  }

  isValidOwners(): boolean {
    if (this.ownersSelected.length > 0) {
      if (this.form.controls.goalType.value === GoalType.PERSONAL_OPERATIONAL || this.form.controls.goalType.value === GoalType.PERSONAL_DEVELOPMENTAL) {
        if (this.ownersSelected.some(u => u.id === this.globals.user.id) === true) {
          return true;
        } else if (this.ownersSelected.some(u => u.managerId === this.globals.user.id) === true) {
          return true;
        } else if (this.globals.hasRole(RoleName.GOAL_COMPANY)) {
          return true;
        }

      } else {
        return true;
      }
    }

    return false;
  }

  getGoalAlignmentInfoText(type: GoalType) {
    switch (type) {
      case GoalType.PERSONAL_DEVELOPMENTAL:
      case GoalType.PERSONAL_OPERATIONAL:
        return `Personal goals can be aligned to '${this.globals.getTerminology(TerminologyEntity.TEAM)}' goals, '${this.globals.getTerminology(TerminologyEntity.SITE)}' goals, '${this.globals.getTerminology(TerminologyEntity.DEPARTMENT)}' goals and 'Company' goals`;
      case GoalType.TEAM:
        return `${this.titleCasePipe.transform(this.globals.getTerminology(TerminologyEntity.TEAM))} goals can be aligned to '${this.globals.getTerminology(TerminologyEntity.SITE)}' goals, '${this.globals.getTerminology(TerminologyEntity.DEPARTMENT)}' goals and 'Company' goals`;
      case GoalType.OFFICE_LOCATION:
        return `${this.globals.getTerminology(TerminologyEntity.SITE)} goals can be aligned to '${this.globals.getTerminology(TerminologyEntity.DEPARTMENT)}' goals and 'Company' goals`;
      case GoalType.DEPARTMENT:
        return `${this.titleCasePipe.transform(this.globals.getTerminology(TerminologyEntity.DEPARTMENT))} goals can be aligned to '${this.globals.getTerminology(TerminologyEntity.SITE)}' goals and 'Company' goals`;
      case GoalType.COMPANY:
        return 'Company goals cannot be aligned to other goals';
      default:
        return '';
    }
  }

  /**
   * Return true if the goal type is NOT a 'Department Goal' or the Goal Type IS 'Department Goal' and a valid department has been selected
   */
  departmentValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const form = control as FormGroup;
      const goalType = form.controls.goalType as FormControl;
      const goalDepartment = form.controls.goalDepartment as FormControl;

      // If goal is not a department goal, return valid
      if (goalType.value !== GoalType.DEPARTMENT) {
        return null;
      }

      // Invalid if null
      if (goalDepartment.value === null) {
        return { departmentRequired: { value: goalDepartment.value } };
      }

      // Invalid if archived
      if (!this.departments.some(department => department.id === goalDepartment.value)) {
        return { departmentRequired: { value: goalDepartment.value } };
      }

      return null;
    };
  }

  dropKeyResult(event: CdkDragDrop<FormGroup[]>) {
    moveItemInArray(event.previousContainer.data, event.previousIndex, event.currentIndex);
  }

  insertKeyResultAtIndex(index: number) {
    const old = this.keyResultsControls;
    this.keyResultsControls = [...old.slice(0, index), this.initKeyResult(), ...old.slice(index)];
  }
}
