import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-emotion-scale',
  templateUrl: './emotion-scale.component.html',
  styleUrls: ['./emotion-scale.component.css'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => EmotionScaleComponent),
    multi: true
  }]
})
export class EmotionScaleComponent implements ControlValueAccessor, OnInit {
  @Input() disabled: boolean;
  selection: number;
  emotions = ['No rating', 'Sad', 'Neutral', 'Happy'];
  propagateChange = (_: any) => {};

  ngOnInit () { this.selection = this.disabled ? 2 : undefined; }

  writeValue(value: any) {
    if (value !== undefined) {
    this.selection = value;
    } else {
      this.selection = 0;
    }
  }

  registerOnChange(fn) {
    this.propagateChange = fn;
  }

  registerOnTouched() {}

  select(index: number) {
    if (!this.disabled) {
      this.selection = index;
      this.propagateChange(this.selection);
    }
  }

}
