import { Component, EventEmitter, Input, Output } from '@angular/core';
import { EvaluationFeedbackRequest } from '@app/models/evaluation/evaluation-feedback-request.model';

@Component({
  selector: 'app-peer-reviews-team',
  templateUrl: './peer-reviews-team.component.html',
  styleUrls: ['./peer-reviews-team.component.scss']
})
export class PeerReviewsTeamComponent {

  @Input() requests: EvaluationFeedbackRequest[];
  @Input() limitHeight: boolean;

  @Output() onApprove: EventEmitter<EvaluationFeedbackRequest>;
  @Output() onDecline: EventEmitter<EvaluationFeedbackRequest>;

  constructor() {
    this.requests = [];
    this.limitHeight = true;
    this.onApprove = new EventEmitter<EvaluationFeedbackRequest>();
    this.onDecline = new EventEmitter<EvaluationFeedbackRequest>();
  }

  onRequestApproved(request: EvaluationFeedbackRequest): void {
    this.onApprove.emit(request);
  }

  onRequestDeclined(request: EvaluationFeedbackRequest): void {
    this.onDecline.emit(request);
  }
}
