import { ChildFilter } from '@app/models/api/child-filter.model';
import { FilterOperator } from '@app/models/api/filter-operator.enum';
import { CriterionOperator } from '@app/models/api/criterion-operator.enum';

export const activeGoalChildFilter: ChildFilter = {
    operator: FilterOperator.AND,
    filterCriteria: [
        {
            field: 'archived',
            operator: CriterionOperator.EQUALS,
            value: 'false'
        },
        {
            field: 'complete',
            operator: CriterionOperator.EQUALS,
            value: 'false'
        }
    ]
}