<div [formGroup]="customFrequencyForm">
<div class="row p-bottom-10">
  <div class="col-repeat-number">
    <div class="frankli-form-group frankli-form-group-filled" [class.frankli-form-group-invalid]="(frequencyCount.invalid && submitted)">
      <label for="repeat-every" translate>{{eOneToOneMessages.REPEAT_EVERY}}</label>
      <input type="number" placeholder="" id="repeat-every" class="form-control" [formControl]="frequencyCount" min="1">
    </div>

    <div class="frankli-form-group-alert">
      <div *ngIf="frequencyCount.invalid && submitted && frequencyCount.hasError('required')" translate>{{eCommonMessages.FORM_REQUIRED}}</div>
      <div *ngIf="frequencyCount.invalid && submitted && frequencyCount.hasError('pattern')" translate>{{eCommonMessages.FORM_INVALID_VALUE}}</div>
      <div *ngIf="frequencyCount.invalid && submitted && frequencyCount.hasError('min')" translate [translateParams]="{ minValue: 0 }">{{eCommonMessages.FORM_MIN_VALUE}}</div>
    </div>
  </div>

  <div class="col-repeat-freq">
    <div class="frankli-form-group frankli-form-group-filled">
      <label for="repeat-every-frequency">&nbsp;</label>
      <select class="form-control" id="repeat-every-frequency" [formControl]="frequencyPeriod">
        <option *ngFor="let freq of eFrequencyPeriod | keyvalue : keepOrder" [ngValue]="freq.value">{{getFrequencyMessageCode(freq.value, frequencyCount.value && (frequencyCount.value > 1)) | translate}}</option>
      </select>
    </div>

    <div class="frankli-form-group-alert">
      
    </div>
  </div>

  <div class="col-freq-extra">
    <div class="col-freq-extra-divider" *ngIf="(frequencyPeriod.value === 'WEEK') || (frequencyPeriod.value === eFrequencyPeriod.MONTH)">
      <div class="frankli-form-group frankli-form-group-filled">
        <label>&nbsp;</label>
        <span>-</span>
      </div>

      <div class="frankli-form-group-alert"></div>
    </div>

    <ng-container [ngSwitch]="frequencyPeriod.value">
      <!-- Week aditional options --> 
      <ng-container *ngSwitchCase="eFrequencyPeriod.WEEK">
        <div class="col-freq-extra-data">
          <div class="frankli-form-group frankli-form-group-filled">
            <label>&nbsp;</label>
            <div class="day-list">
              <ng-container *ngFor="let day of daysOrder;let i=index;">
                <div class="day-item-col">
                  <div class="day-item" [class.day-selected]="isDaySelected(i)" (click)="toggleDay(i)">
                    <span>{{getDayMessageCode(day) | translate | slice:0:3}}</span>
                  </div>
                </div>
              </ng-container>
              <div class="frankli-form-group-alert p-top-10">
                  <div *ngIf="!daysValid && submitted" translate>{{eOneToOneMessages.MUST_SELECT_DAY}}</div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>

      <!-- Month aditional options --> 
      <ng-container *ngSwitchCase="eFrequencyPeriod.MONTH">
        <div class="col-freq-month-options">
          <div class="frankli-form-group frankli-form-group-filled">
            <label for="month-option">&nbsp;</label>
            <!-- TODO: Form options for this -->
              <select class="form-control" id="month-option" [formControl]="frequencyMonthOption">
                  <option [ngValue]="eFrequencyMonthlyOption.ON_MONTH_DAY" translate
                          [translateParams]="{ day: getDayText() }">{{eOneToOneMessages.MONTH_ON_DAY}}</option>
                  <option [ngValue]="eFrequencyMonthlyOption.ON_WEEK_DAY" translate
                          [translateParams]="{ date: getMonthlyText() }">{{eOneToOneMessages.MONTH_ON_DATE}}</option>
              </select>
          </div>

          <div class="frankli-form-group-alert"></div>
        </div>
      </ng-container>
    </ng-container>
  </div>
</div>

<div class="row p-top-10">
  <!-- Ends options-->
  <div class="col-freq-on">
    <div class="frankli-form-group frankli-form-group-filled">
        <label translate>{{eOneToOneMessages.SCHEDULE_ENDS}}</label>
        <select class="form-control" [formControl]="frequencyEnds">
            <option [ngValue]="eFrequencyEnd.NEVER" translate>{{eOneToOneMessages.NEVER}}</option>
            <option [ngValue]="eFrequencyEnd.ON" translate>{{eOneToOneMessages.ON}}</option>
            <option [ngValue]="eFrequencyEnd.AFTER" translate>{{eOneToOneMessages.AFTER}}</option>
        </select>
    </div>

    <div class="frankli-form-group-alert"></div>
  </div>

  <ng-container [ngSwitch]="frequencyEnds.value">
    <ng-container *ngSwitchCase="eFrequencyEnd.ON">
      <div class="col-freq-dates-on">
        <div class="frankli-form-group frankli-form-group-filled">
          <label>&nbsp;</label>
          <app-date-picker [formControl]="frequencyEndsOn" [displayFormat]="'D MMM YYYY'"></app-date-picker>
        </div>

        <div class="frankli-form-group-alert">
            <div *ngIf="submitted && !validOnDate()" translate>{{eOneToOneMessages.MUST_BE_AFTER_MEETING}}</div>
        </div>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="eFrequencyEnd.AFTER">
      <div class="col-freq-dates-after">
        <div class="frankli-form-group frankli-form-group-filled" [class.frankli-form-group-invalid]="(frequencyEndsAfter.invalid && submitted)">
          <label>&nbsp;</label>
          <input type="number" placeholder="" id="ends-after" class="form-control" [formControl]="frequencyEndsAfter" min="1"/>
        </div>

        <div class="frankli-form-group-alert">
          <div *ngIf="frequencyEndsAfter.invalid && submitted && frequencyEndsAfter.hasError('required')" translate>{{eCommonMessages.FORM_REQUIRED}}</div>
          <div *ngIf="frequencyEndsAfter.invalid && submitted && frequencyEndsAfter.hasError('pattern')" translate>{{eCommonMessages.FORM_INVALID_VALUE}}</div>
          <div *ngIf="frequencyEndsAfter.invalid && submitted && frequencyEndsAfter.hasError('min')" translate [translateParams]="{minValue: 0}">{{eCommonMessages.FORM_MIN_VALUE}}</div>
        </div>
      </div>
      <div class="col-freq-dates-after-label">
        <div class="frankli-form-group frankli-form-group-filled">
          <label>&nbsp;</label>
            <span translate>{{eOneToOneMessages.OCCURRENCES}}</span>
        </div>

        <div class="frankli-form-group-alert"></div>
      </div>
    </ng-container>
  </ng-container>
</div>
</div>
<div class="row">
  <div class="p-left-10 p-right-10 p-bottom-10">
    <div class="kr-edit-warning">
      <!-- <div class="icon-hide" title="hide this warning" >
        <span class="fal fa-fw fa-times expand-on-hover"></span>
      </div> -->
      <div class="warning-text">
        <span>{{frequencyDisplay}}</span>
        <br/>
      </div>
    </div>
  </div>
  <!-- <div class="col-freq-display">
    <h6 class="text-warning p-top-5"></h6>
  </div> -->
</div>