<!-- Stats panels -->
<div class="task-stats-container" *ngIf="stats">
  <div class="card no-margin stat-item">
    <div class="stat-icon-container">
      <span class="fal fa-bullseye"></span>
    </div>
    <span class="stats-text">
      <span>
        <span>
          <b>{{stats.numberOfActiveTasks}}</b>
        </span>
        <span class="m-right-5"></span>
        <span translate>{{eTaskMessages.ACTIVE_TASKS}}</span>
      </span>
    </span>
  </div>

  <div class="card no-margin stat-item">
    <div class="round-progress-container">
      <app-progress-bar-round [progress]="stats.averageCompletionPercentage" [showValue]="false" [thickness]="5" [size]="60"></app-progress-bar-round>
    </div>
    <span class="stats-text">
      <span>
        <span>
          <b>{{stats.averageCompletionPercentage | number : '1.2-2'}}%</b>
        </span>
        <span class="m-right-5"></span>
        <span translate>{{eTaskMessages.TASK_COUNT_COMPLETE}}</span>
      </span>
    </span>
  </div>

  <div class="card no-margin stat-item">
    <div class="stat-icon-container">
      <span class="fal fa-calendar-alt"></span>
    </div>
    <span class="stats-text">
      <span>
        <span>
          <b>{{stats.numberOfTasksDueInNext30Days}}</b>
        </span>
        <span class="m-right-5"></span>
        <span translate>{{eTaskMessages.DUE_IN_THE_NEXT_30_DAYS}}</span>
      </span>
    </span>
  </div>

  <div class="card no-margin stat-item">
    <div class="stat-icon-container">
      <span class="fal fa-check-circle"></span>
    </div>
    <span class="stats-text">
      <span>
        <span>
          <b>{{stats.numberOfTasksCompletedInLast3Months}}</b>
        </span>
        <span class="m-right-5"></span>
        <span translate>{{eTaskMessages.COMPLETED_IN_THE_PAST_3_MONTHS}}</span>
      </span>
    </span>
  </div>
</div>