import { Component, Input, OnInit } from '@angular/core';
import { CommonMessages } from '@app/constants/common.messages';
import { EvaluationCycle } from '@app/models/evaluation/evaluation-cycle.model';
import { EvaluationFeedbackRequestState } from '@app/models/evaluation/evaluation-feedback-request-state.model';
import { EvaluationFeedbackRequestType } from '@app/models/evaluation/evaluation-feedback-request-type.model';
import { EvaluationFeedbackRequest } from '@app/models/evaluation/evaluation-feedback-request.model';
import { EvaluationModuleType } from '@app/models/evaluation/evaluation-module-type.model';
import { ReviewStepSummary } from '@app/models/evaluation/peer-review-summary/review-step-summary.model';
import { ReviewSubjectAccessType } from '@app/models/evaluation/review-subject-access-type.model';
import { IState } from '@app/models/state/state.model';
import { SurveyQuestionType } from '@app/models/survey-question-type.enum';
import { UserMinimal } from '@app/models/user/user-minimal.model';
import { EvaluationCycleAPIService } from '@app/shared/api/evaluation-cycle.api.service';
import { UserAPIService } from '@app/shared/api/user.api.service';
import { Globals } from '@app/shared/globals/globals';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-information-sidebar-reviews-peer-reviews',
  templateUrl: './information-sidebar-reviews-peer-reviews.component.html',
  styleUrls: ['./information-sidebar-reviews-peer-reviews.component.scss']
})
export class InformationSidebarReviewsPeerReviewsComponent implements OnInit {

  public readonly eEvaluationFeedbackRequestState = EvaluationFeedbackRequestState;
  public readonly eEvaluationModuleType = EvaluationModuleType;
  public readonly eSurveyQuestionType = SurveyQuestionType;
  public readonly eCommonMessages = CommonMessages;

  @Input() cycle: EvaluationCycle;
  @Input() userIds: number[];
  @Input() userIdsManaging: number[];
  
  state: IState;
  peerReviews: EvaluationFeedbackRequest[];
  summary: ReviewStepSummary;
  usersManager: UserMinimal;

  constructor(
    public globals: Globals,
    private evaluationCycleAPIService: EvaluationCycleAPIService,
    private userAPIService: UserAPIService
  ) {
    this.cycle = undefined;
    this.userIds = [];
    this.userIdsManaging = [];

    this.state = {
      loading: true,
      error: false,
      errorMessage: ''
    };

    this.peerReviews = [];
  }

  ngOnInit(): void {
    this.getData();
  }

  doError(message: string): void {
    this.state.loading = false;
    this.state.error = true;
    this.state.errorMessage = message;
  }

  getData(): void {
    if (!this.cycle || !this.userIds || this.userIds.length === 0) return this.doError('No data');

    const peerReviewStep = this.cycle.modules.find(step => step.evaluationModuleType === EvaluationModuleType.PEER_REVIEW);
    if (!peerReviewStep) return this.doError('No peer review step found');

    switch(peerReviewStep.reviewSubjectAccessType) {
      case ReviewSubjectAccessType.LEGACY:
      case ReviewSubjectAccessType.SUBJECT_ACCESS:
        this.getAllRequests();
        break;
      case ReviewSubjectAccessType.MANAGER_APPROVAL:
        this.getSharedRequests();
        break;
      case ReviewSubjectAccessType.MANAGER_SUMMARY:
        this.getSummaryData();
        break;
    }
  }

  getAllRequests(): void {
    this.evaluationCycleAPIService.getEvaluationUserByCycleIdAndUserId(this.cycle.id, this.userIds[0])
      .toPromise()
      .then(evaluationUser => {
        let peerReviews = evaluationUser.requestsAboutUser.filter(request => request.type === EvaluationFeedbackRequestType.PEER_REVIEW && [EvaluationFeedbackRequestState.APPROVED, EvaluationFeedbackRequestState.COMPLETED, EvaluationFeedbackRequestState.PENDING_PEER].includes(request.state));
        if (peerReviews.length === 0) {
          this.doError('No peer reviews found');
        }
        peerReviews = this.sortRequests(peerReviews);
        this.peerReviews = peerReviews;
        this.state.loading = false;
      });
  }

  getSharedRequests(): void {
    this.evaluationCycleAPIService.getPeersNominatedCompletedSharedForUserId(this.cycle.id, this.userIds[0])
      .toPromise()
      .then(feedbackRequests => {
        if (feedbackRequests.length === 0) {
          this.doError('No peer reviews found');
        }
        feedbackRequests = this.sortRequests(feedbackRequests);
        this.peerReviews = feedbackRequests;
        this.state.loading = false;
      });
  }

  getSummaryData(): void {
    forkJoin([
      this.evaluationCycleAPIService.getPeerReviewSummaryForUserInCycle(this.cycle.id, this.userIds[0]),
      this.userAPIService.getManagerForUser(this.userIds[0])
    ])
      .toPromise()
      .then(([summary, usersManager]) => {
        if (!summary) return this.doError('Summary not submitted');
        this.summary = summary;
        this.usersManager = usersManager;
        this.state.loading = false;
      });
  }

  sortRequests(upwardReviews: EvaluationFeedbackRequest[]): EvaluationFeedbackRequest[] {
    // Sort by status, completed at the top
    return upwardReviews.sort((a, b) => {
      if (a.state === EvaluationFeedbackRequestState.COMPLETED && b.state !== EvaluationFeedbackRequestState.COMPLETED) {
        return -1;
      } else if (a.state !== EvaluationFeedbackRequestState.COMPLETED && b.state === EvaluationFeedbackRequestState.COMPLETED) {
        return 1;
      } else {
        return 0;
      }
    });
  }

}
