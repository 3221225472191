import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { SessionLogHour, SessionLogYear } from '@app/models/session-log/session-event-overview.modal';

@Component({
  selector: 'app-session-log-table-component',
  templateUrl: './session-log-table.component.html',
  styleUrls: ['./session-log-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush // For performance reasons
})
export class SessionLogChartComponent {

  // Our ranges for highlighting user values
  public readonly RANGE_100: number = 500;
  public readonly RANGE_80: number = 400;
  public readonly RANGE_60: number = 300;
  public readonly RANGE_40: number = 200;
  public readonly RANGE_20: number = 1;

  @Input()
  data: Array<SessionLogYear> = new Array<SessionLogYear>();

  @Input()
  showUserCount: boolean = true;

  @Output()
  cellClicked: EventEmitter<SessionLogHour> = new EventEmitter<SessionLogHour>();

  public sendClickEvent(hour: SessionLogHour): void {
    this.cellClicked.next(hour);
  }

}
