<div class="goal-create-owners-container" [class.parent-dropdown-closed]="!parentDropdownOpen">
  <ng-container *ngIf="parentDropdownOpen; else templateDisplayValues">
    <!-- Distribution -->
    <ng-container *ngIf="distributionOptions.length > 1">
      <div class="frankli-form-group frankli-form-group-filled" [class.frankli-form-group-invalid]="submitted && controlDistribution.invalid">
        <label for="goalDistribution">
          <span translate>{{eGoalsMessages.GOAL_CREATE_DISTRIBUTION_TITLE}}</span>
          <span class="m-right-5"></span>
          <span class="fal fa-fw fa-question-circle" [tooltip]="tooltipDistribution" placement="bottom" container="body"></span>
        </label>
        <app-button-group [formControl]="controlDistribution" [options]="distributionOptions" [consistentWidths]="true"></app-button-group>
        <div class="frankli-form-group-alert">
          <ng-container *ngIf="submitted">
            <div *ngIf="controlDistribution.hasError('required')" translate>{{eGoalsMessages.GOAL_CREATE_DETAILS_VALIDATION_DISTRIBUTION_REQUIRED}}</div>
          </ng-container>
        </div>
  
        <ng-template #tooltipDistribution>
          <div class="text-left">
            <div class="p-bottom-10" [innerHtml]="eGoalsMessages.GOAL_CREATE_DISTRIBUTION_EXPLANATION | translate"></div>
            <div class="p-bottom-5">
              <strong translate>{{eGoalsMessages.GOAL_CREATE_DISTRIBUTION_NONE}}</strong>
              <div translate>{{eGoalsMessages.GOAL_CREATE_DISTRIBUTION_EXPLANATION_NONE}}</div>
            </div>
            <div class="p-bottom-5">
              <strong translate>{{eGoalsMessages.GOAL_CREATE_DISTRIBUTION_OWNERS}}</strong>
              <div translate>{{eGoalsMessages.GOAL_CREATE_DISTRIBUTION_EXPLANATION_OWNERS}}</div>
            </div>
            <div class="p-bottom-5">
              <strong translate>{{eGoalsMessages.GOAL_CREATE_DISTRIBUTION_COMPANY}}</strong>
              <div translate>{{eGoalsMessages.GOAL_CREATE_DISTRIBUTION_EXPLANATION_COMPANY}}</div>
            </div>
          </div>
        </ng-template>
      </div>
    </ng-container>

    <!-- Distribution explanation -->
    <div class="p-bottom-15">
      <div class="card card-yellow no-margin">
        <div class="content text-distribution-explanation">
          <span class="fal fa-fw fa-info-circle"></span>
          <span class="m-right-5"></span>
          <ng-container [ngSwitch]="controlDistribution.value">
            <span *ngSwitchCase="eGoalDistribution.NONE" translate>{{eGoalsMessages.GOAL_CREATE_DISTRIBUTION_EXPLANATION_NONE}}</span>
            <span *ngSwitchCase="eGoalDistribution.OWNERS" translate>{{eGoalsMessages.GOAL_CREATE_DISTRIBUTION_EXPLANATION_OWNERS}}</span>
            <span *ngSwitchCase="eGoalDistribution.COMPANY" translate>{{eGoalsMessages.GOAL_CREATE_DISTRIBUTION_EXPLANATION_COMPANY}}</span>
          </ng-container>
        </div>
      </div>
    </div>

    <!-- Owners -->
    <ng-container *ngIf="canSelectOwners">
      <div class="frankli-form-group frankli-form-group-filled">
        <div class="p-bottom-5">
          <label class="m-bottom-0" for="goalOwners">
            <span translate>{{eGoalsMessages.GOAL_CREATE_OWNERS_TITLE}}</span>
          </label>
          <div class="standard-description" translate>{{eGoalsMessages.GOAL_CREATE_OWNERS_SECOND_DESCRIPTION}}</div>
        </div>
        <app-user-picker-multiple [formControl]="controlOwners" [customOptions]="customOptions" #userPicker></app-user-picker-multiple>
      </div>
      <ng-container [ngTemplateOutlet]="controlOwnerAlerts"></ng-container>
    </ng-container>
  </ng-container>
  <ng-template #templateDisplayValues>
    <ng-container *ngIf="controlOwners.value && controlOwners.value.length > 0">
      <div class="display-values" (click)="setParentDropdown(true)">
        <div class="owner-count">
          <ng-container *ngIf="controlDistribution.value !== eGoalDistribution.COMPANY">
            <ng-container *ngIf="controlOwners.value.length > 0; else templateNoOwnersSelected;">
              <ng-container *ngIf="controlOwners.value.length === 1; else templateMultipleOwnersSelected">
                <span translate>{{eGoalsMessages.GOAL_CREATE_OWNERS_COUNT_SINGLE}}</span>
              </ng-container>
              <ng-template #templateMultipleOwnersSelected>
                <span translate [translateParams]="{ count: controlOwners.value.length }">{{eGoalsMessages.GOAL_CREATE_OWNERS_COUNT_MULTIPLE}}</span>
              </ng-template>
            </ng-container>
            <ng-template #templateNoOwnersSelected>
              <span translate>{{eGoalsMessages.GOAL_CREATE_OWNERS_COUNT_NONE}}</span>
            </ng-template>
          </ng-container>
  
          <ng-container [ngSwitch]="controlDistribution.value">
            <!-- <span *ngSwitchCase="eGoalDistribution.NONE" translate>{{eGoalsMessages.GOAL_CREATE_DISTRIBUTION_EXPLANATION_NONE}}</span> -->
            <ng-container *ngSwitchCase="eGoalDistribution.OWNERS">
              <span class="m-right-5"></span>
              <span>(</span>
              <span class="text-lowercase" translate>{{eGoalsMessages.GOAL_CREATE_DISTRIBUTION_EXPLANATION_OWNERS}}</span>
              <span>)</span>
            </ng-container>
            <ng-container *ngSwitchCase="eGoalDistribution.COMPANY">
              <span translate>{{eGoalsMessages.GOAL_CREATE_DISTRIBUTION_EXPLANATION_COMPANY}}</span>
            </ng-container>
          </ng-container>
        </div>
        <span class="display-check">
          <span class="fal fa-fw fa-check"></span>
        </span>
      </div>
    </ng-container>
    <ng-container [ngTemplateOutlet]="controlOwnerAlerts"></ng-container>
  </ng-template>

  <ng-template #controlOwnerAlerts>
    <ng-container *ngIf="submitted && controlOwners.invalid && controlOwners.value.length === 0">
      <div class="frankli-form-group-alert">
        <div *ngIf="controlOwners.hasError('minlength')" translate>{{eGoalsMessages.GOAL_CREATE_OWNERS_VALIDATION_MINIMUM}}</div>
      </div>
    </ng-container>
  </ng-template>
</div>
